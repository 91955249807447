import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../../shared-state";
import * as Types from "./types";
import * as AddComponentMenu from "../add-component-menu";

export const init = (sharedState: SharedState.State): Types.State => {
  return {
    selectedItem: undefined,
    addComponentMenuState: AddComponentMenu.init(sharedState)
  };
};

export const Action = ctorsUnion({
  selectedItem: (item: Types.Item | undefined) => ({ item }),
  dispatchAddComponentMenu: (action: AddComponentMenu.Action) => ({ action })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: Types.State,
  _sharedState: SharedState.State
): Types.State {
  switch (action.type) {
    case "selectedItem":
      return {
        ...state,
        selectedItem: action.item
      };
    case "dispatchAddComponentMenu":
      return {
        ...state,
        addComponentMenuState: AddComponentMenu.update(
          action.action,
          state.addComponentMenuState
        )
      };
    default:
      return exhaustiveCheck(action, true);
  }
}
