export type ProductAction = MergeProductEntities | Dummy;

export interface MergeProductEntities {
  readonly type: "query-product/MergeProductEntities";
  readonly payload: { readonly [key: string]: any };
}

export function mergeProductEntities(entities: {
  readonly [key: string]: any;
}): MergeProductEntities {
  return {
    type: "query-product/MergeProductEntities",
    payload: entities
  };
}

export interface Dummy {
  readonly type: "query-product/Dummy";
}
