import * as React from "react";
import * as Types from "./types";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { Root, StyledTable, SelectedDiv, StyledTr } from "./elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { OverlayLoader, S2, P2, CheckBox } from "@genesys/ui-elements";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import { AmountFormatSelector } from "../../amount-format-selector";
import { getAirflows, getTotalWallLength, getPadHeights } from "./functions";

export interface Props {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly dispatch: Dispatch<Action>;
}

export function EccSizeSelectorView({
  state,
  sharedState,
  operatingCases,
  properties,
  dispatch
}: Props): JSX.Element {
  if (!state.data) {
    return <OverlayLoader />;
  }

  const wallLenghtX = PropertyValueSet.getAmount<Quantity.Length>(
    "walllengthx",
    properties
  );
  const wallLenghtY = PropertyValueSet.getAmount<Quantity.Length>(
    "walllengthy",
    properties
  );
  const wallSections = PropertyValueSet.getInteger("wallsections", properties);

  if (!wallLenghtX || !wallLenghtY || !wallSections) {
    return (
      <div>
        {!wallLenghtX && "walllengthx undefined"}
        {!wallLenghtY && "walllengthy undefined"}
        {!wallSections && "wallsections undefined"}
      </div>
    );
  }

  const totalWallLength = getTotalWallLength(
    wallLenghtX,
    wallLenghtY,
    wallSections
  );

  if (!totalWallLength) {
    return <div>{!totalWallLength && "totalwalllength undefined"}</div>;
  }

  const airFlows: Types.AirFlows = getAirflows(operatingCases);

  const padheights: ReadonlyArray<Amount.Amount<Quantity.Length>> =
    getPadHeights(state);

  const sizes: Array<Types.Size> = padheights.map(p => {
    const faceArea = Amount.create(
      Amount.valueAs(Units.Meter, p) *
        Amount.valueAs(Units.Meter, totalWallLength),
      Units.SquareMeter
    );
    return {
      padHeight: p,
      faceArea: faceArea,
      minVelocity: Amount.create(
        Amount.valueAs(Units.StandardCubicMeterPerSecond, airFlows.minAirFlow) /
          Amount.valueAs(Units.SquareMeter, faceArea),
        Units.MeterPerSecond
      )!,
      maxVelocity: Amount.create(
        Amount.valueAs(Units.StandardCubicMeterPerSecond, airFlows.maxAirFlow) /
          Amount.valueAs(Units.SquareMeter, faceArea),
        Units.MeterPerSecond
      )!
    };
  });

  const sizeSelectionMaxVelocityRow = (
    state.data.product.ecc.minMaxFaceVelocity?.rows || []
  ).find(s =>
    PropertyFilter.isValid(
      properties,
      PropertyFilter.fromStringOrEmpty(s!.propertyFilter!)
    )
  )!;

  const sizeSelectionMaxVelocityValues = PropertyValueSet.fromStringOrError(
    () => PropertyValueSet.Empty,
    sizeSelectionMaxVelocityRow.values
  );

  const minVelocity = PropertyValueSet.getAmount<Quantity.Velocity>(
    "MinWarningLimit",
    sizeSelectionMaxVelocityValues
  );

  const maxVelocity = PropertyValueSet.getAmount<Quantity.Velocity>(
    "MaxWarningLimit",
    sizeSelectionMaxVelocityValues
  );

  if (!minVelocity || !maxVelocity) {
    return (
      <div>
        {!minVelocity && "minVelocity undefined"}
        {!maxVelocity && "minVelocity undefined"}
      </div>
    );
  }

  const validSizes = sizes.filter(
    s =>
      Amount.greaterOrEqualTo(s.minVelocity, minVelocity) &&
      Amount.lessOrEqualTo(s.maxVelocity, maxVelocity)
  );

  const fieldGroup = "SizeSelector";
  const faceAreaAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "Area",
    "Area"
  );
  const minFaceVelocityAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "MinVelocity",
    "Velocity"
  );
  const maxFaceVelocityAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "MaxVelocity",
    "Velocity"
  );

  const selectedSize = state.selectedPadHeight
    ? Amount.create(
        Amount.valueAs(Units.Millimeter, state.selectedPadHeight),
        Units.Millimeter,
        0
      )
    : undefined;

  if (!selectedSize && validSizes.length) {
    dispatch(Action.setSize(validSizes[0].padHeight));
  }

  return (
    <Root>
      {!validSizes.length && (
        <div>
          {sharedState.translate(LanguageTexts.noSizesFoundForVelocity())}
        </div>
      )}

      {validSizes.length && (
        <StyledTable>
          <thead>
            <tr>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(
                    LanguageTexts.dynamicText("p_ECC_padheight", "ECC")
                  )}
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.faceArea())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldName="Area"
                    fieldGroup={fieldGroup}
                    conversionParameters={undefined}
                    amountFormat={faceAreaAmountFormat}
                    translate={sharedState.translate}
                    onFormatCleared={() =>
                      dispatch(Action.FormatCleared(fieldGroup, "Area"))
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "Area",
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.minVelocity())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldGroup={fieldGroup}
                    fieldName="MinVelocity"
                    conversionParameters={undefined}
                    amountFormat={minFaceVelocityAmountFormat}
                    translate={sharedState.translate}
                    onFormatCleared={() =>
                      dispatch(Action.FormatCleared(fieldGroup, "MinVelocity"))
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "MinVelocity",
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.maxVelocity())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldGroup={fieldGroup}
                    fieldName="MaxVelocity"
                    conversionParameters={undefined}
                    amountFormat={maxFaceVelocityAmountFormat}
                    translate={sharedState.translate}
                    onFormatCleared={() =>
                      dispatch(Action.FormatCleared(fieldGroup, "MaxVelocity"))
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "MaxVelocity",
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </S2>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {validSizes.map(s => (
              <StyledTr
                isSelected={
                  selectedSize !== undefined &&
                  Amount.equals(selectedSize, s.padHeight)
                }
                key={Amount.valueAs(Units.Millimeter, s.padHeight)}
                onClick={() => dispatch(Action.setSize(s.padHeight))}
              >
                <td>
                  <P2 weight="normal" color="secondary">
                    {Amount.valueAs(Units.Millimeter, s.padHeight) + " mm"}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.faceArea),
                      faceAreaAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.minVelocity),
                      minFaceVelocityAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.maxVelocity),
                      maxFaceVelocityAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  {selectedSize !== undefined &&
                    Amount.equals(selectedSize, s.padHeight) && (
                      <SelectedDiv>
                        <P2 weight="normal" color="dark">
                          {sharedState.translate(LanguageTexts.selected())}
                        </P2>
                        <CheckBox
                          isChecked={true}
                          children=""
                          onClick={() => {
                            /*  */
                          }}
                        />
                      </SelectedDiv>
                    )}
                </td>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </Root>
  );
}
