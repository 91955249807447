import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { Unit } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as ValueSourceQueries from "@genesys/client-core/lib/value-source-queries";
import { clientConfig } from "../config";
import * as SharedState from "../shared-state";
import { promiseCmd } from "../promise-effect-manager";
import { CalculateProperties } from "./types";

export type State = {
  readonly properties: PropertyValueSet.PropertyValueSet;
};

export const init = (properties: PropertyValueSet.PropertyValueSet): State => {
  return {
    properties: properties
  };
};

export const Action = ctorsUnion({
  updateProperties: (
    properties: PropertyValueSet.PropertyValueSet,
    changedPropertyNames: ReadonlyArray<string>
  ) => ({
    properties,
    changedPropertyNames
  }),
  calculatedPropertiesReceived: (
    properties: PropertyValueSet.PropertyValueSet
  ) => ({
    properties
  }),
  propertyFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ) => ({
    fieldGroup,
    fieldName,
    unit,
    decimalCount
  }),
  propertyFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  calculateProperties: CalculateProperties,
  beforePropertiesUpdatedHook: (
    changeProperties: PropertyValueSet.PropertyValueSet
  ) => PropertyValueSet.PropertyValueSet = x => x
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "updateProperties": {
      const afterHookProperties = beforePropertiesUpdatedHook(
        action.properties
      );

      if (calculateProperties === "SkipCalculateProperties") {
        return [{ ...state, properties: afterHookProperties }];
      }
      return [
        { ...state, properties: afterHookProperties },
        calculateProperties.type === "Component"
          ? promiseCmd<Action, PropertyValueSet.PropertyValueSet>(async () => {
              const calculatedProperties =
                await ValueSourceQueries.getCalculatedProperties(
                  sharedState.debugSettings.includeServerLog,
                  calculateProperties.systemId,
                  calculateProperties.componentId,
                  afterHookProperties,
                  clientConfig.graphqlEndpoint,
                  { type: "bearer", accessToken: sharedState.accessToken },
                  action.changedPropertyNames[0]
                );
              return calculatedProperties;
            }, Action.calculatedPropertiesReceived)
          : promiseCmd<Action, PropertyValueSet.PropertyValueSet>(async () => {
              const calculatedProperties =
                await ValueSourceQueries.getCalculatedPropertiesForAccessory(
                  sharedState.debugSettings.includeServerLog,
                  calculateProperties.systemId,
                  calculateProperties.parentComponentId,
                  calculateProperties.productId,
                  afterHookProperties,
                  clientConfig.graphqlEndpoint,
                  { type: "bearer", accessToken: sharedState.accessToken },
                  action.changedPropertyNames[0]
                );
              return calculatedProperties;
            }, Action.calculatedPropertiesReceived)
      ];
    }
    case "calculatedPropertiesReceived": {
      return [{ ...state, properties: action.properties }];
    }
    case "propertyFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "propertyFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
