import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchResultsContainer = styled.div`
  border-top: 1px solid ${Colors.searchFilterBorderColor};
  padding: 10px;
  flex: auto;
  overflow: auto;
`;
