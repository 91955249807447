import * as Navigation from "../../../navigation-effect-manager";
import * as Main from "../../../main";
import * as InitialSettings from "../../../initial-settings";
import * as DebugSettings from "../../../debug-settings";
import * as GQLTypes from "../../../graphql-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as VersionChecker from "../../../version-checker";
import * as TermsAndCondtions from "../../../terms-and-conditions";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { promiseCmd } from "../../../promise-effect-manager";
import { clientConfig } from "../../../config";
import { State, UpdateFunction } from "../types";
import { Action } from "../actions";
import { buildSharedState } from "../state-builders";
import { userQuery } from "../../queries";
import { handleSharedStateActions } from "./handle-shared-state-actions";
import { handleUserQuery } from "./handle-user-query";
import {
  queryTranslations,
  allowedLangauages,
  limitedLanguagePath
} from "../tools";
import ReactGA from "react-ga4";

export const handleDispatchTermsAndConditions: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "dispatchTermsAndConditions") {
    return [state];
  }
  const sharedState = buildSharedState(state);
  if (!sharedState || !state.termsAndConditionsState) {
    return [state];
  }
  const [termsAndConditionsState, termsAccepted, termsAndConditionsCmd] =
    TermsAndCondtions.update(
      action.action,
      state.termsAndConditionsState,
      sharedState
    );
  return [
    {
      ...state,
      termsAndConditionsState: termsAccepted
        ? undefined
        : termsAndConditionsState
    },
    Cmd.map(Action.dispatchTermsAndConditions, termsAndConditionsCmd)
  ];
};

export const handleInitialQueriesReceived: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "initialQueriesReceived") {
    return [state];
  }
  const fieldDefaults = ScreenAmounts.createFieldsDefaultsMap(
    action.productQuery.product.fieldDefaults
  );
  const quantityDefaults = ScreenAmounts.createQuantityDefaultsMap(
    action.productQuery.product.quantityDefaults
  );
  const { genesysNo, pricingNo, moistureLoadNo } =
    action.productQuery.product.genesysNumberPrefixes;
  const genesysPrefixes = { genesysNo, pricingNo, moistureLoadNo };
  const [newState, cmds] = handleUserQuery(
    {
      ...state,
      fieldDefaults,
      quantityDefaults,
      genesysPrefixes,
      routes: action.productQuery.product.routes
    },
    action.userQuery
  );
  return [newState, cmds];
};

export const handleDispatchMain: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "dispatchMain") {
    return [state];
  }
  const sharedState = buildSharedState(state);
  if (!sharedState || !state.mainState) {
    return [state];
  }
  const [newMainState, cmd, sharedStateActions] = Main.update(
    action.action,
    state.mainState,
    sharedState
  );

  const [newState, handleSharedStateActionCmds] = handleSharedStateActions(
    sharedStateActions || [],
    state
  );
  return [
    {
      ...newState,
      mainState: newMainState
    },
    Cmd.batch<Action>([
      ...handleSharedStateActionCmds,
      Cmd.map(Action.dispatchMain, cmd)
    ])
  ];
};

export const handleDispatchInitialSettings: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "dispatchInitialSettings") {
    return [state];
  }
  const sharedState = buildSharedState(state);
  if (!sharedState) {
    return [state];
  }
  const [newInitialSettingsState, initialSettingsCmd, sharedStateActions] =
    InitialSettings.update(
      action.action,
      state.initialSettingsState!,
      sharedState
    );
  const [newState, handleSharedStateActionCmds] = handleSharedStateActions(
    sharedStateActions || [],
    state
  );
  return [
    { ...newState, initialSettingsState: newInitialSettingsState },
    Cmd.batch<Action>([
      ...handleSharedStateActionCmds,
      Cmd.map(Action.dispatchInitialSettings, initialSettingsCmd)
    ])
  ];
};

export const handleDispatchVersionChecker: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "dispatchVersionChecker") {
    return [state];
  }
  const sharedState = buildSharedState(state);
  if (!sharedState || !state.mainState) {
    return [state];
  }
  const [versionCheckerState, cmd] = VersionChecker.update(
    action.action,
    state.versionCheckerState,
    sharedState
  );

  return [
    {
      ...state,
      versionCheckerState
    },
    Cmd.map(Action.dispatchVersionChecker, cmd)
  ];
};

export const handleUrlChanged: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "UrlChanged") {
    return [state];
  }
  const oldSharedState = buildSharedState(state);

  if (!oldSharedState || !state.mainState) {
    return [state];
  }

  // ReactGA.pageview(action.url.host + action.url.path + action.url.query);
  ReactGA.send({
    hitType: "pageview",
    page: action.url.host + action.url.path + action.url.query
  });

  const isNotLangCode = state.user?.settings.language !== "lngcod";
  let newState = { ...state };

  if (isNotLangCode) {
    const currentPath = action.url.path;
    const onLimitedLangRoute = limitedLanguagePath.some(x =>
      currentPath.startsWith(x)
    );

    const selectedLang = onLimitedLangRoute
      ? allowedLangauages.find(x => x === state.user!.settings.language) ||
        "en-US"
      : state.user!.settings.language;

    const hasTranslations = state.translationTexts![selectedLang] !== undefined;

    newState = { ...state, selectedLang };

    if (!hasTranslations) {
      return [
        {
          ...newState,
          queriesLoading: state.queriesLoading.concat("translationQuery")
        },
        promiseCmd<Action, GQLTypes.TranslationQuery>(
          async () => {
            const res = await queryTranslations(oldSharedState, selectedLang);
            return res;
          },
          res => Action.requestNewTranslationOnUrlChange(action.url, res)
        )
      ];
    }
  }

  const newSharedState = buildSharedState(newState);

  const [mainState, mainCmd, sharedStateActions] = Main.update(
    Main.Action.UrlChanged(action.url),
    newState.mainState!,
    newSharedState!
  );

  const [newState2, handleSharedStateActionCmds] = handleSharedStateActions(
    sharedStateActions || [],
    newState
  );

  return [
    { ...newState2, mainState },
    Cmd.batch<Action>([
      ...handleSharedStateActionCmds,
      Cmd.map(Action.dispatchMain, mainCmd)
    ])
  ];
};

export const handleRequestNewTranslationOnUrlChange: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "requestNewTranslationOnUrlChange") {
    return [state];
  }
  const newState1: State = {
    ...state,
    queriesLoading: state.queriesLoading.filter(q => q !== "translationQuery"),
    translationTexts: {
      ...state.translationTexts,
      [state.selectedLang]: action.data.product.language?.texts || {}
    }
  };
  const sharedState = buildSharedState(newState1);
  if (!sharedState || !state.mainState) {
    return [state];
  }

  const [mainState, mainCmd, sharedStateActions] = Main.update(
    Main.Action.UrlChanged(action.url),
    state.mainState,
    sharedState
  );

  const [newState, handleSharedStateActionCmds] = handleSharedStateActions(
    sharedStateActions || [],
    newState1
  );
  return [
    { ...newState, mainState, url: action.url },
    Cmd.batch<Action>([
      ...handleSharedStateActionCmds,
      Cmd.map(Action.dispatchMain, mainCmd)
    ])
  ];
};
export const handleUrlRequested: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "UrlRequested") {
    return [state];
  }
  switch (action.urlRequest.type) {
    case "InternalUrlRequest":
      return [state, Navigation.pushUrl(action.urlRequest.url)];
    case "ExternalUrlRequest":
      return [state, Navigation.pushUrl(action.urlRequest.url.path)];
    default:
      return exhaustiveCheck(action.urlRequest);
  }
};

export const handleGetNextMessage: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "getNextMessage") {
    return [state];
  }
  return [
    {
      ...state,
      alertMessagesQueue: state.alertMessagesQueue.slice(1)
    }
  ];
};

export const handleSharedActionMutationCompleted: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "sharedStateActionMutationCompleted") {
    return [state];
  }
  if (action.reloadUserData) {
    const sharedState = buildSharedState(state);
    if (!sharedState) {
      return [state];
    }
    return [
      {
        ...state,
        queriesLoading: state.queriesLoading.concat("userQuery")
      },
      sharedState.graphQL.queryUserCmd<
        GQLTypes.RootUserQuery,
        GQLTypes.RootUserQueryVariables,
        Action
      >(userQuery, {}, Action.userQueryReceived)
    ];
  }
  return [state];
};

export const handleUserQueryReceived: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "userQueryReceived") {
    return [state];
  }
  return handleUserQuery(state, action.data);
};

export const handleTranslationQueryReceived: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "translationQueryReceived") {
    return [state];
  }
  const newState1: State = {
    ...state,
    selectedLang: action.lang,
    queriesLoading: state.queriesLoading.filter(q => q !== "translationQuery"),
    translationTexts: {
      ...state.translationTexts,
      [action.lang]: action.data.product.language?.texts || {}
    }
  };
  const sharedState = buildSharedState(newState1);
  if (!sharedState) {
    return [state];
  }

  let termsAndConditionsState: TermsAndCondtions.State | undefined = undefined;
  let termsAndConditionsCmd: Cmd<TermsAndCondtions.Action> | undefined =
    undefined;

  if (!state.user?.hasAcceptedTerms) {
    const [ts, cmd] = TermsAndCondtions.init(sharedState);
    termsAndConditionsState = ts;
    termsAndConditionsCmd = cmd;
  }
  const [initMainState, initMainCmd, sharedStateActions] = Main.init(
    sharedState,
    state.url
  );
  const newState2: State = {
    ...newState1,
    termsAndConditionsState,
    debugSettings: {
      ...state.debugSettings,
      includeServerLog:
        DebugSettings.canSeeDebugSettings(sharedState) &&
        (clientConfig.environment === "Develop" ||
          clientConfig.environment === "Localhost")
    },
    mainState: state.mainState === undefined ? initMainState : state.mainState
  };
  const [newStateAfterSharedStateActions, handleSharedStateActionCmds] =
    handleSharedStateActions(sharedStateActions || [], newState2);
  return [
    newStateAfterSharedStateActions,
    Cmd.batch<Action>([
      Cmd.map(Action.dispatchMain, initMainCmd),
      Cmd.map(Action.dispatchTermsAndConditions, termsAndConditionsCmd),
      ...handleSharedStateActionCmds
    ])
  ];
};

export const handleRecentAndFavoritesQueryLoaded: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "recentAndFavoritesLoaded") {
    return [state];
  }
  return [
    {
      ...state,
      user: {
        ...state.user!,
        lastOpenedSystems: action.recentSystems,
        favoritesSystems: action.favoriteSystems
      }
    }
  ];
};

export const handleAccessTokenRefreshed: UpdateFunction = (
  action: Action,
  state: State
) => {
  if (action.type !== "accessTokenRefreshed") {
    return [state];
  }
  const accessToken = action.user?.access_token;
  if (accessToken === undefined) {
    throw new Error("Error getting new access token for user");
  }
  return [{ ...state, accessToken }];
};
