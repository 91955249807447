import * as GraphQL from "graphql";
import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLRequest from "@genesys/graphql-request";
import { getObjectToId } from "../graphql-entity-state";

interface UpdateFromServerResultSucess {
  readonly type: "UpdateFromServerResultSucess";
  readonly entities: GraphQLEntityCache.EntityCache;
}

interface UpdateFromServerResultFailure {
  readonly type: "UpdateFromServerResultFailure";
  readonly error: any;
}

type UpdateFromServerResult =
  | UpdateFromServerResultSucess
  | UpdateFromServerResultFailure;

export async function updateFromServer<TVariables extends {} = {}>(
  queryWithRequiredFields: GraphQL.DocumentNode,
  variables: TVariables,
  includeServerLog: boolean,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string }
): Promise<UpdateFromServerResult> {
  try {
    const data = await GraphQLRequest.request<any, TVariables>(
      queryWithRequiredFields,
      variables,
      includeServerLog,
      endpoint,
      authorization
    );

    const normalizedEntities = GraphQLEntityCache.normalize(
      queryWithRequiredFields,
      variables,
      {
        data
      },
      getObjectToId
    );

    return {
      type: "UpdateFromServerResultSucess",
      entities: normalizedEntities
    };
  } catch (e) {
    console.error(e);
    return {
      type: "UpdateFromServerResultFailure",
      error: new Error(`Error fetching data: ${e.message}`)
    };
  }
}
