import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const saveOperatingCasesMutation = gql`
  mutation SaveOperatingCases($input: OperatingCasesInputType!) {
    saveOperatingCases(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
