import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemSummary from "../system-summary";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { P1, OverlayLoader } from "@genesys/ui-elements";
import { Root } from "./elements";
import { ModalComponent } from "../shared-manager-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { MainContentView, TopBarView } from "./components";

export function SystemManagerView({
  dispatch,
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  if (!state.queryProductResult) {
    return <OverlayLoader />;
  }

  if (state.shouldUpdateSearch) {
    dispatch(Action.search());
  }

  return (
    <Root>
      <Modal state={state} sharedState={sharedState} dispatch={dispatch} />
      <TopBarView state={state} sharedState={sharedState} dispatch={dispatch} />
      <MainContentView
        state={state}
        sharedState={sharedState}
        dispatch={dispatch}
      />
    </Root>
  );
}

function Modal({
  dispatch,
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element | null {
  if (!state.Modal) {
    return null;
  }

  const mode = state.Modal.mode;

  switch (mode) {
    case "confirmSystemsDelete": {
      const systemFileIds = state.Modal.systemFileIds || [];
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.confirmation())}
          content={sharedState.translate(
            LanguageTexts.deleteAllSelectedMsg(systemFileIds.length)
          )}
          onClose={() => dispatch(Action.closeModal())}
          onConfirm={() => {
            dispatch(
              Action.deleteSystems(systemFileIds, {
                content: "System(s) Succesfully deleted",
                messageType: "success",
                timeout: 2000
              })
            );
            dispatch(Action.closeModal());
          }}
        />
      );
    }
    case "mustSelectAllRevisions": {
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.error())}
          content={sharedState.translate(
            LanguageTexts.dynamicText("must_check_all_revisions")
          )}
          onClose={() => dispatch(Action.closeModal())}
        />
      );
    }
    case "confirmLabelDelete": {
      const modal = state.Modal;
      if (!modal.label) {
        return null;
      }
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.confirmation())}
          content={sharedState.translate(
            LanguageTexts.deleteLabelMsg(state.Modal.label.name)
          )}
          onClose={() => dispatch(Action.closeModal())}
          onConfirm={() => {
            dispatch(Action.deleteLabel(modal.label!.id));
            dispatch(Action.closeModal());
          }}
        />
      );
    }
    case "help-about-searching": {
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.helpmenu())}
          content={
            <>
              <P1 weight="bold">Search logic:</P1>
              <P1 weight="normal">
                One or more search conditions of any type (see syntax) can be
                used. Search conditions are combined and all supplied conditions
                must be fulfilled to get a hit.
              </P1>
              <P1 weight="bold">Search syntax:</P1>
              <P1 weight="normal">
                label:xxx - Systems with specified label attached gn:G00000x -
                System with specified Genesys number owner:XxxXxx - Systems
                created by sepecified login name type:XXX - Systems of specified
                system type xxxxxxx - String without prefix searches in file
                name, system revision comments and labels, genesys number but
                can be significantly slower
              </P1>
              <P1 weight="bold">Examples: </P1>
              <P1 weight="normal">
                'label:test label:project1 type:mlp' - Searches all systems with
                label 'test' AND label 'project1' that have system type 'MLP'
              </P1>
            </>
          }
          onClose={() => dispatch(Action.closeModal())}
        />
      );
    }

    case "system-summary": {
      if (!state.systemSummaryState) {
        return null;
      }
      return (
        <SystemSummary.View
          sharedState={sharedState}
          state={state.systemSummaryState}
          dispatch={Dispatch.map(Action.dispatchSystemSummary, dispatch)}
          onClose={() => dispatch(Action.closeModal())}
        />
      );
    }
    default:
      exhaustiveCheck(mode, true);
  }
}
