import { Amount, Quantity } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import { ConversionParameters } from "../types";

export function volumeFlowConversions<TQuantityTo extends Quantity.Quantity>(
  fromAmount: Amount.Amount<Quantity.VolumeFlow>,
  to: Quantity.Quantity,
  conversionParameters: ConversionParameters
): Amount.Amount<TQuantityTo> {
  if (
    conversionParameters.density === undefined &&
    (conversionParameters.humidityRatio === undefined ||
      conversionParameters.temperature === undefined ||
      conversionParameters.pressure === undefined)
  ) {
    throw new Error(
      "Required conversion parameters for massFlowConversions is undefined"
    );
  }

  const useDensity = conversionParameters.density !== undefined;
  switch (to) {
    case "MassFlow": {
      if (useDensity) {
        return Physics.FlowConversion.volumeFlowToMassFlowUsingDensity(
          conversionParameters.density!,
          fromAmount
        ) as Amount.Amount<TQuantityTo>;
      }

      return Physics.FlowConversion.volumeFlowToMassFlow(
        conversionParameters.temperature!,
        conversionParameters.humidityRatio!,
        conversionParameters.pressure!,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    default: {
      throw new Error(`Can't convert ${fromAmount.unit.quantity} to ${to}`);
    }
  }
}

export function massFlowConversions<TQuantityTo extends Quantity.Quantity>(
  fromAmount: Amount.Amount<Quantity.MassFlow>,
  to: Quantity.Quantity,
  conversionParameters: ConversionParameters
): Amount.Amount<TQuantityTo> {
  if (
    conversionParameters.density === undefined &&
    (conversionParameters.humidityRatio === undefined ||
      conversionParameters.temperature === undefined ||
      conversionParameters.pressure === undefined)
  ) {
    throw new Error(
      "Required conversion parameters for massFlowConversions is undefined"
    );
  }

  const useDensity = conversionParameters.density !== undefined;
  switch (to) {
    case "VolumeFlow": {
      if (useDensity) {
        return Physics.FlowConversion.massFlowToVolumeFlowUsingDensity(
          conversionParameters.density!,
          fromAmount
        ) as Amount.Amount<TQuantityTo>;
      }

      return Physics.FlowConversion.massFlowToVolumeFlow(
        conversionParameters.temperature!,
        conversionParameters.humidityRatio!,
        conversionParameters.pressure!,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    default: {
      throw new Error(`Can't convert ${fromAmount.unit.quantity} to ${to}`);
    }
  }
}
