import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { Unit, Quantity } from "@genesys/uom";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import {
  ListPresets,
  ChartPreset,
  ClimateCoolingDataType
} from "../../../../energy-result-visualizer";
import * as SharedState from "../../../../shared-state";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";
import * as GraphQlTypes from "../../../../graphql-types";
import * as System from "../../../system";
import * as Types from "./types";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";

const userQuery = gql`
  query SystemMCompareResultUser($systemId: ID!) {
    user {
      system(id: $systemId) {
        ...EnergyResultSystem
        energySummary {
          energyAnnualConsumption {
            ...CompleteEnergyParam
          }
          energyAnnualCost {
            ...CompleteEnergyCost
          }
          energyEmission {
            ...CompleteEnergyParam
          }
          energyAnnualExtra {
            ...CompleteEnergyParam
          }
          lccCost {
            ...CompleteEnergyCost
          }
        }
        mCompareSummary {
          energyAnnualConsumption {
            ...CompleteEnergyParam
          }
          energyAnnualCost {
            ...CompleteEnergyCost
          }
          energyEmission {
            ...CompleteEnergyParam
          }
          energyAnnualExtra {
            ...CompleteEnergyParam
          }
          lccCost {
            ...CompleteEnergyCost
          }
        }
      }
    }
  }
  ${Fragments.energyResultSystem}
  ${Fragments.completeEnergyParam}
  ${Fragments.completeEnergyCost}
`;

const productQuery = gql`
  query SystemMCompareResultProduct($systemTypeInput: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemTypeInput) {
        id
        energyTotals {
          ...EnergyResultEnergyTotalItem
        }
        energyList {
          ...EnergyResultEnergyListItem
        }
        energyChartPresets {
          ...EnergyResultChartPresets
        }
      }
    }
  }
  ${Fragments.energyResultEnergyTotalItem}
  ${Fragments.energyResultEnergyListItem}
  ${Fragments.energyResultChartPresets}
`;

export type State =
  | {
      readonly productQueryData:
        | GraphQlTypes.SystemMCompareResultProduct
        | undefined;
      readonly userQueryData: GraphQlTypes.SystemMCompareResultUser | undefined;
      readonly resultVisualizer: Types.BinVisualizer;
      readonly listPreset: ListPresets;
      readonly selectedClimateCoolingDataType:
        | ClimateCoolingDataType
        | undefined;
      readonly selectedChartPreset: string;
      readonly energyChartPresets: ReadonlyArray<ChartPreset>;
    }
  | undefined;

export const init = (
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?] => {
  const systemTypeId = system.file.systemTypeId;

  if (
    system.status < SystemStatusEnum.SystemStatus.MCompareCalculationSuccess
  ) {
    return [undefined];
  }

  return [
    {
      productQueryData: undefined,
      userQueryData: undefined,
      listPreset: "bins",
      resultVisualizer: "List",
      selectedClimateCoolingDataType: undefined,
      selectedChartPreset: "",
      energyChartPresets: []
    },
    Cmd.batch<Action>([
      sharedState.graphQL.queryProductCmd<
        GraphQlTypes.SystemMCompareResultProduct,
        GraphQlTypes.SystemMCompareResultProductVariables,
        Action
      >(
        productQuery,
        {
          systemTypeInput: {
            systemTypeId: systemTypeId
          }
        },
        Action.productQueryReceived
      ),
      sharedState.graphQL.queryUserCmd<
        GraphQlTypes.SystemMCompareResultUser,
        GraphQlTypes.SystemMCompareResultUserVariables,
        Action
      >(
        userQuery,
        {
          systemId: system.id
        },
        Action.userQueryReceived
      )
    ])
  ];
};

export const Action = ctorsUnion({
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  productQueryReceived: (data: GraphQlTypes.SystemMCompareResultProduct) => ({
    data
  }),
  userQueryReceived: (data: GraphQlTypes.SystemMCompareResultUser) => ({
    data
  }),
  setBinVisualizer: (binVisualizer: Types.BinVisualizer) => ({ binVisualizer }),
  setChartPreset: (chartPreset: string) => ({ chartPreset }),
  setClimateCoolingType: (dataType: ClimateCoolingDataType) => ({ dataType }),
  setListPreset: (listPreset: ListPresets) => ({ listPreset })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  if (state === undefined) {
    return [state];
  }
  switch (action.type) {
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }

    case "productQueryReceived": {
      const energyChartPresets =
        action.data.product.systemType.energyChartPresets.map(p =>
          SharedEnergyTools.parseEnergyChartPresets(p)
        );
      return [
        {
          ...state,
          energyChartPresets,
          selectedChartPreset: energyChartPresets[0].id,
          productQueryData: action.data
        }
      ];
    }

    case "userQueryReceived": {
      return [
        {
          ...state,
          userQueryData: action.data
        }
      ];
    }

    case "setBinVisualizer": {
      return [
        {
          ...state,
          resultVisualizer: action.binVisualizer
        }
      ];
    }

    case "setClimateCoolingType": {
      return [
        {
          ...state,
          selectedClimateCoolingDataType: action.dataType
        }
      ];
    }

    case "setChartPreset": {
      return [
        {
          ...state,
          selectedChartPreset: action.chartPreset
        }
      ];
    }

    case "setListPreset": {
      return [
        {
          ...state,
          listPreset: action.listPreset
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
