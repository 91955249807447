import styled from "styled-components";
import { zIndex, P2, PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  padding: 30px;
`;

export const SupplyFlowSelectorsContainer = styled.div`
  display: flex;
`;

export const CheckBoxContainer = styled.div`
  margin-top: -10px;
  margin-left: 10px;
`;

export const ModelOptionsTable = styled.table`
  th,
  td {
    height: 30px;
    padding: 0 8px;
    text-align: center;
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;

export const PopupRoot = styled.div`
  display: flex;
  p {
    margin: 0 3px;
  }
`;

export const PopupContainer = styled.div`
  position: relative;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 80px;
  top: -10px;
  z-index: ${zIndex.Dropdown};
  background-color: white;
  border-radius: 12px;
  border: 2px solid
    ${(props: {
      readonly borderColor: "blue" | "red" | "yellow";
      readonly width: "wide" | "narrow";
    }) =>
      props.borderColor === "blue"
        ? PrimaryColors.muntersBlue
        : props.borderColor === "red"
        ? PrimaryColors.red
        : "#F0C530"};
  padding: 8px 8px 0 8px;

  ${(props: {
    readonly borderColor: "blue" | "red" | "yellow";
    readonly width: "wide" | "narrow";
  }) => (props.width === "narrow" ? "width: 200px" : "max-width: 600px")};

  p {
    word-wrap: break-word;
  }
`;

export const P2WithCursor = styled(P2)`
  cursor: pointer;
`;

export const PaddingContainer = styled.div`
  padding-bottom: 8px;

  div {
    padding-bottom: 4px;
  }
`;

export const CasesContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: inherit;
  overflow: auto;
`;

export const PropertyNamesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  margin-right: 20px;
  white-space: nowrap;
  z-index: ${zIndex.Modal + zIndex.OperatingCasePropertyNames};
  background-color: inherit;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin: 0 20px 20px 0;
  white-space: nowrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: white;

  h3 {
    color: white;
  }
`;

export const PropertiesSelectorJsxContainer = styled.div`
  position: relative;
  padding-bottom: 20px;

  table {
    min-width: 238px;
    tbody {
      tr:nth-child(1) {
        td {
          height: 0;
          h3 {
            display: none;
          }
        }
      }
      tr {
        td {
          height: 30px;
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 3 : 1}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 155px;
          max-width: 155px;
          padding-left: 10px;

          > div {
            max-width: 155px;
          }
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 4 : 2}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 58px;
          padding: 0 10px 0 0;

          * {
            max-width: 58px;
          }

          div {
            div {
              div {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
`;
