import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as PsychrometricChart from "../../../psychrometric-chart";

export type State = {
  readonly chartPreset: PsychrometricChart.ChartPreset;
  readonly operatingCaseId: string;
  readonly psychrometricChartState: PsychrometricChart.State;
};

export const init = (
  operatingCaseId: string,
  systemId: string,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  const initProps: PsychrometricChart.InitProps = {
    source: "operatingCase",
    operatingCaseId: operatingCaseId,
    si: true,
    systemId: systemId,
    type: "mollier",
    accessToken: sharedState.accessToken,
    limits: false
  };

  const [psychrometricChartState, psychrometricChartCmd] =
    PsychrometricChart.init(initProps);

  return [
    {
      chartPreset: "mollier_SI",
      operatingCaseId: operatingCaseId,
      psychrometricChartState: psychrometricChartState
    },
    Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
  ];
};

export const Action = ctorsUnion({
  setOperatingCase: (operatingCaseId: string, systemId: string) => ({
    operatingCaseId,
    systemId
  }),
  setChartPreset: (
    chartPreset: PsychrometricChart.ChartPreset,
    systemId: string
  ) => ({
    chartPreset,
    systemId
  }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "setOperatingCase": {
      const { chartType, measureSystem } = PsychrometricChart.infoFromPreset(
        state.chartPreset
      );

      const initProps: PsychrometricChart.InitProps = {
        source: "operatingCase",
        operatingCaseId: action.operatingCaseId,
        si: measureSystem === "SI",
        systemId: action.systemId,
        type: chartType,
        accessToken: sharedState.accessToken,
        limits: false
      };

      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(initProps);

      return [
        {
          ...state,
          operatingCaseId: action.operatingCaseId,
          psychrometricChartState: psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }
    case "setChartPreset": {
      const { chartType, measureSystem } = PsychrometricChart.infoFromPreset(
        action.chartPreset
      );

      const initProps: PsychrometricChart.InitProps = {
        source: "operatingCase",
        operatingCaseId: state.operatingCaseId,
        si: measureSystem === "SI",
        systemId: action.systemId,
        type: chartType,
        accessToken: sharedState.accessToken,
        limits: false
      };

      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(initProps);

      return [
        {
          ...state,
          chartPreset: action.chartPreset,
          psychrometricChartState: psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }
    case "dispatchPsychrometricChart": {
      const [PsychrometricChartState, PsychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.psychrometricChartState,
          sharedState
        );
      return [
        {
          ...state,
          psychrometricChartState: PsychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, PsychrometricChartCmd),
        sharedStateCmd
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
