export const epxSize = "epxsize";
export const iteLoad = "iteload";
export const iteLoadPercent = "iteloadpercent";
export const numberOfNormalUnits = "numberofnunits";
export const numberOfRedundantUnits = "redundancy";
export const numberOfUnitsTotal = "numberofunitstotal";
export const numberOfNormalUnitsOperating = "numberofnunitsoperating";
export const numberOfRedundantUnitsOperating = "redundancyoperating";
export const totalNumberOfUnitsOperating = "totalnumberofunitsoperating";

export const targetPowerPerUnit = "targetpower";

export const unitAirflow = "unitairflow";
export const totalDataCenterAirflow = "totaldatacenterairflow";

export const supplyTargetTemperature = "supplytargettemperature";
export const returnAirTemperature = "returnairtemperature";
export const returnAirHumidity = "returnairhumidity";

export const outdoorAirTemperature = "outdoorairtemperature";
export const outdoorAirHumidity = "outdoorairhumidity";

export const caseName = "casename";
export const sortNo = "sortno";
export const atmosphericPressure = "atmosphericpressure";
export const altitude = "altitude";
