import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  H1,
  StandardButton,
  H3,
  Spinner,
  DropDownButton
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { clientConfig, getModalBorderColor } from "../../config";
import { State, Action } from "../state";
import { createSummaryItemsDef } from "../definitions";
import { SummaryItemGroup } from "./summary-item-group";
import { getVisibleColumns } from "./helper-functions";
import { ResultView } from "./result-view";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  > h1 {
    margin-right: 5px;
  }
`;

const ContentContainer = styled.div`
  padding: 15px;
  overflow-y: auto;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 5px;
`;

const SummaryItemsContainer = styled.div`
  display: flex;
  > div {
    margin-right: 10px;
  }
`;

const ResultContainer = styled.div`
  margin-top: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-height: 75px;
`;

interface Props {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onClose: () => void;
}

export function View(props: Props) {
  const { onClose } = props;
  return (
    <Modal onClose={onClose} maxWidth={1400}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <HeaderContainer>
          <TitleContainer>
            <H1>Systems Summary</H1>
          </TitleContainer>
          <ModalButton {...props} />
        </HeaderContainer>
      </ModalHeader>
      <ContentContainer>
        <Content {...props} />
      </ContentContainer>
    </Modal>
  );
}

function Content(props: Props) {
  const { state, sharedState, dispatch } = props;
  const systems = state.systems;
  if (!systems || state.isSaving) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const selectedSummaryItems = state.selectedSummaryItems;
  const summaryItems = createSummaryItemsDef(
    selectedSummaryItems,
    sharedState.screenAmounts.getAmountFormat
  );

  const visbibleColumns = getVisibleColumns(
    summaryItems,
    state.selectedSummaryItems
  );

  return (
    <>
      <StyledH3> Summary Items</StyledH3>
      <SummaryItemsContainer>
        {summaryItems.map(summaryItem => {
          return (
            <SummaryItemGroup
              key={summaryItem.type}
              dispatch={dispatch}
              selectedSummaryItems={state.selectedSummaryItems}
              summaryItem={summaryItem}
            />
          );
        })}
      </SummaryItemsContainer>
      <ResultContainer>
        <ResultView
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
          visbibleColumns={visbibleColumns}
        />
      </ResultContainer>
    </>
  );
}

function ModalButton(props: Props) {
  const { state, sharedState, dispatch, onClose } = props;

  const translate = sharedState.translate;
  if (state.systemsToBeSaved.size > 0) {
    let options = [
      {
        value: translate(LanguageTexts.save()),
        onClickHandler: () => {
          dispatch(Action.saveUpdatedSystems());
        }
      },
      {
        value: translate(LanguageTexts.close()),
        onClickHandler: onClose
      }
    ];
    return (
      <DropDownButton
        disabled={state.isSaving}
        buttonType="Primary-2"
        options={options}
      />
    );
  }

  return (
    <StandardButton buttonType="Primary-2" size="Large" onClick={onClose}>
      {translate(LanguageTexts.close())}
    </StandardButton>
  );
}
