import * as React from "react";
import * as SharedState from "../../shared-state";
import * as UseCaseAndBuildingConfig from "./use-case-and-building-config";
import * as PropertiesSelector from "../../properties-selector";
import * as GraphQLTypes from "../../graphql-types";
import * as LoadTypes from "./load-types";
import * as Result from "./result";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { CalculationInput } from "./result";
import { PropertyValueSet } from "@genesys/property";
// import { State } from "../state";
import { Quantity, Unit } from "@genesys/uom";
import { PropertyDefinitions, SystemOverrides } from "../tools";
import { Tabs } from "./tabs";

const Container = styled.div`
  border-top: 2px solid rgba(221, 228, 240, 0.3);
  height: 80vh;
  overflow-y: hidden;
  overflow: initial;
  max-width: 1440px;

  /* > div {
    width: 50%;
  } */
`;

const TabsContainer = styled.div`
  /* display: flex;
  justify-content: center; */
`;
const MainContentContainer = styled.div`
  margin-top: 20px;
  height: 90%;
  overflow: auto;

  div {
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      margin-right: 20px;
      opacity: 0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dde4f0;
      opacity: 1;
      border-radius: 5.5px;
      margin-right: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

interface Props {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly isDisabled: boolean;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly disableOpcButtons: boolean;
  readonly calculationInput: CalculationInput | undefined;
  readonly isCalculating: boolean;
  readonly propertyDefinitions: PropertyDefinitions;
  readonly propertiesSelectorState: PropertiesSelector.State;
  readonly currentSystemOverrides: SystemOverrides;
  readonly moistureLoadClaimValue: number;
  readonly calcResult:
    | GraphQLTypes.SaveMoistureLoadInputsAndCalculate["saveMoistureLoadInputsAndCalculate"]
    | undefined;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
  readonly dispatch: Dispatch<Action>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}

export function View(props: Props) {
  const { state, sharedState, isCalculating, calcResult, dispatch } = props;
  return (
    <Container>
      <TabsContainer>
        <Tabs
          translate={sharedState.translate}
          isCalculating={isCalculating}
          hasCalResult={!!calcResult}
          currentPageTab={state.currentTab}
          changePageTab={tab => dispatch(Action.changePageTab(tab))}
        />
      </TabsContainer>
      <MainContentContainer>
        <ContentSelector currentTab={state.currentTab} {...props} />
      </MainContentContainer>
    </Container>
  );
}

interface ContentSelectorProps extends Props {
  readonly currentTab: string;
}

function ContentSelector({ currentTab, ...props }: ContentSelectorProps) {
  switch (currentTab) {
    case "building-and-system-config":
      return <BuildingAndSystemConfigContent {...props} />;
    case "moisture-heat-load-types":
      return <LoadTypesContent {...props} />;
    case "result":
      return <ResultContent {...props} />;
    default:
      return null;
  }
}

function BuildingAndSystemConfigContent(props: Props) {
  const {
    state,
    sharedState,
    isDisabled,
    propertyDefinitions,
    propertiesSelectorState,
    currentSystemOverrides,
    dispatch,
    propertieSelectorDispatch
  } = props;
  return (
    <UseCaseAndBuildingConfig.View
      isDisabled={isDisabled}
      currentSystemOverrides={currentSystemOverrides}
      propertySelectorState={propertiesSelectorState!}
      propertieSelectorDispatch={propertieSelectorDispatch}
      propertyDefinitions={propertyDefinitions}
      sharedState={sharedState}
      state={state.useCaseAndBuildingConfigState!}
      dispatch={Dispatch.map(Action.dispatchUseCaseAndBuildingConfig, dispatch)}
    />
  );
}

function LoadTypesContent(props: Props) {
  const {
    state,
    sharedState,
    disableOpcButtons,
    climateSettings,
    isDisabled,
    propertiesSelectorState,
    currentSystemOverrides,
    propertyDefinitions,
    propertieSelectorDispatch,
    dispatch
  } = props;
  return (
    <LoadTypes.View
      disableOpcButtons={disableOpcButtons}
      climateSettings={climateSettings}
      currentSystemOverrides={currentSystemOverrides}
      isDisabled={isDisabled}
      propertySelectorState={propertiesSelectorState}
      propertieSelectorDispatch={propertieSelectorDispatch}
      propertyDefinitions={propertyDefinitions!}
      sharedState={sharedState}
      state={state.loadTypesContentState!}
      dispatch={Dispatch.map(Action.dispatchLoadTypesContent, dispatch)}
    />
  );
}

function ResultContent(props: Props) {
  const {
    state,
    sharedState,
    isCalculating,
    moistureLoadItem,
    disableOpcButtons,
    climateSettings,
    calculationInput,
    moistureLoadClaimValue,
    calcResult,
    onFormatChanged,
    onFormatCleared,
    dispatch
  } = props;

  return (
    <Result.View
      isCalculating={isCalculating}
      moistureLoadItem={moistureLoadItem}
      disableOpcButtons={disableOpcButtons}
      climateSettings={climateSettings}
      onFormatChanged={onFormatChanged}
      onFormatCleared={onFormatCleared}
      calculationInput={calculationInput!}
      state={state.resultState}
      sharedState={sharedState}
      dispatch={Dispatch.map(Action.dispatchResult, dispatch)}
      moistureLoadClaimValue={moistureLoadClaimValue}
      calcResult={calcResult!}
    />
  );
}
