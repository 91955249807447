import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { PropertyInfo } from "../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

/*
TODO: 
- Ligger textIds med p_SSG_casename etc och skräpar i munter_texts nu när vi inte har en riktigt product för detta
*/

type ProductData = {
  readonly id: string;
  readonly properties: ReadonlyArray<PropertyInfo>;
};

const productId = "SSGOPC";

export function getOpcProductData(
  newProperties: PropertyValueSet.PropertyValueSet,
  translate: LanguageTexts.Translate
) {
  const getText = (name: string, value: number) =>
    translate(LanguageTexts.productPropertyValue(productId, name, value));

  const returnAirVisibilityFilter =
    PropertyValueSet.getInteger("airsource", newProperties) === 2
      ? PropertyFilter.Empty
      : PropertyFilter.fromString("1=0")!;
  const customAirVisibilityFilter =
    PropertyValueSet.getInteger("airsource", newProperties) === 3
      ? PropertyFilter.Empty
      : PropertyFilter.fromString("1=0")!;

  const opcProductData: ProductData = {
    id: productId,
    properties: [
      {
        name: "sortno",
        quantity: "Discrete",
        group: "hidden",
        sortNo: 0,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.fromString("1=0")!,
        items: [
          {
            id: "527de958-d9fd-444a-8fc9-009727989fce",
            rangeFilter: PropertyFilter.Empty,
            validationFilter: PropertyFilter.Empty,
            value: { type: "integer", value: 0 },
            sortNo: 0,
            image: undefined,
            descriptionValuesTexts: [],
            text: "0"
          },
          {
            id: "e4036537-1b5c-4fd6-9a9f-3c33da39e829",
            rangeFilter: PropertyFilter.Empty,
            validationFilter: PropertyFilter.Empty,
            value: { type: "integer", value: 1 },
            sortNo: 1,
            image: undefined,
            descriptionValuesTexts: [],
            text: "1"
          }
        ],
        descriptionTexts: [],
        valueSources: [],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "atmosphericpressure",
        quantity: "Pressure",
        group: "hidden",
        sortNo: 10,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.fromString("1=0")!,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "4b3830a5-cce4-4cc6-bac9-72311d37f517",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "ClimatePressure",
            value: 0,
            parameters: ""
          },
          {
            id: "14a7bdb2-85b4-4286-ad84-7fa3617d7c99",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "Constant",
            value: 1,
            parameters: "101325:Pascal"
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "casename",
        quantity: "Text",
        group: "mainselections",
        sortNo: 50,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "outdoordata",
        quantity: "Discrete",
        group: "mainselections",
        sortNo: 20,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [
          {
            id: "d22d3484-1ba8-45d2-9a5e-3e0f0c200326",
            rangeFilter: PropertyFilter.Empty,
            validationFilter: PropertyFilter.Empty,
            value: { type: "integer", value: 0 },
            sortNo: 0,
            image: undefined,
            descriptionValuesTexts: [],
            text: getText("outdoordata", 0)
          },
          {
            id: "87a3dcfe-e98b-420a-9912-92b483c4595f",
            rangeFilter: PropertyFilter.Empty,
            validationFilter: PropertyFilter.Empty,
            value: { type: "integer", value: 1 },
            sortNo: 1,
            image: undefined,
            descriptionValuesTexts: [],
            text: getText("outdoordata", 1)
          },
          {
            id: "846f0e91-e5e3-46d4-b2a2-a576916b69ac",
            rangeFilter: PropertyFilter.Empty,
            validationFilter: PropertyFilter.Empty,
            value: { type: "integer", value: 3 },
            sortNo: 3,
            image: undefined,
            descriptionValuesTexts: [],
            text: getText("outdoordata", 3)
          }
        ],
        descriptionTexts: [],
        valueSources: [],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "outdoorairtemperature",
        quantity: "Temperature",
        group: "mainselections",
        sortNo: 30,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "8ac74165-e029-4cbb-81eb-0faf783183b3",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=0")!,
            propertyValueSourceId: "ClimateData",
            value: 0,
            parameters: "summertemperature"
          },
          {
            id: "80b0d66a-6673-409b-870c-902c88a5a69e",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=1")!,
            propertyValueSourceId: "ClimateData",
            value: 1,
            parameters: "wintertemperature"
          },
          {
            id: "9e008f65-a187-4435-aad0-24af1460e090",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=3")!,
            propertyValueSourceId: "User",
            value: 2,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "outdoorairhumidity",
        quantity: "HumidityRatio",
        group: "mainselections",
        sortNo: 40,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "9ar74125-e049-4cbb-81eb-0faf783183b3",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=0")!,
            propertyValueSourceId: "ClimateData",
            value: 0,
            parameters: "summerhumidity"
          },
          {
            id: "10e0d66e-6653-419b-870c-902c88a5a69e",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=1")!,
            propertyValueSourceId: "ClimateData",
            value: 1,
            parameters: "winterhumidity"
          },
          {
            id: "6g008f65-b137-4435-agd0-24af1460e090",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("outdoordata=3")!,
            propertyValueSourceId: "User",
            value: 2,
            parameters: ""
          }
        ],
        conversionParameters: ["outdoorairtemperature", "atmosphericpressure"],
        defaultValues: []
      },
      {
        name: "returnairtemperature",
        quantity: "Temperature",
        group: "mainselections",
        sortNo: 50,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: returnAirVisibilityFilter,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "24b0249a-c20f-46db-962e-a18cc439ea21",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "678fc32d-dbda-487c-9c25-44354bd0c7f1",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "returnairhumidity",
        quantity: "HumidityRatio",
        group: "mainselections",
        sortNo: 60,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: returnAirVisibilityFilter,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "9c7fd779-fe5e-4c56-b96d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "2dcdd776-14f3-40a9-a79b-8e3d2d2d5af0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: ["returnairtemperature", "atmosphericpressure"],
        defaultValues: []
      },
      {
        name: "customairtemperature",
        quantity: "Temperature",
        group: "mainselections",
        sortNo: 70,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: customAirVisibilityFilter,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "74p0249a-n40f-46db-962e-a18cc439ea21",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "672fc37d-dpda-487c-9h25-44354bd0c7f1",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "customairhumidity",
        quantity: "HumidityRatio",
        group: "mainselections",
        sortNo: 80,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: customAirVisibilityFilter,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "1k7fs779-fi5e-4c26-b96d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "2dcdd776-14f3-41o9-a79b-8e3d2d2d6bf0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: ["customairtemperature", "atmosphericpressure"],
        defaultValues: []
      },
      {
        name: "processinletexternalstatic",
        quantity: "Pressure",
        group: "mainselections",
        sortNo: 90,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "3r5fd879-fe5e-4c56-b96d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "9tcdy776-24f3-40a9-a79b-8e3d2d2d5af0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "processoutletexternalstatic",
        quantity: "Pressure",
        group: "mainselections",
        sortNo: 100,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "5y5fd279-fe5e-4c56-b96d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "2tcdb773-24r3-40a9-a79b-8e3d2d2d5af0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "reactinletexternalstatic",
        quantity: "Pressure",
        group: "mainselections",
        sortNo: 110,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "0y5fy279-fm5e-4s54-b16d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "6tldb773-21r3-40t9-a79b-8e3d2d2d5af0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "reactoutletexternalstatic",
        quantity: "Pressure",
        group: "mainselections",
        sortNo: 120,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "0y5iy229-fm8e-4c54-b16d-5b62c69cf4ae",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "2tldx773-21p3-41t9-a79b-8e3d2d2d5af0",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "supplyoutletairflow",
        quantity: "MassFlow",
        group: "mainselections",
        sortNo: 130,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "e1d4efaa-c7b4-42a9-b4fb-2cbcd40c65ac",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "f5461558-33a4-4228-a3ed-0780fb456814",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      },
      {
        name: "supplytargethumidity",
        quantity: "HumidityRatio",
        group: "mainselections",
        sortNo: 140,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        descriptionTexts: [],
        valueSources: [
          {
            id: "g2d6efaa-y4b4-42a9-b4fb-2cbcd40c65ac",
            claimFilter: "",
            propertyFilter: PropertyFilter.fromString("sortno!=0")!,
            propertyValueSourceId: "InheritedFromFirstOpCase",
            value: 0,
            parameters: ""
          },
          {
            id: "u5441558-2ta4-4228-a3ad-0280fb456814",
            claimFilter: "",
            propertyFilter: PropertyFilter.Empty,
            propertyValueSourceId: "User",
            value: 1,
            parameters: ""
          }
        ],
        conversionParameters: [],
        defaultValues: []
      }
    ]
  };

  return opcProductData;
}

//tslint:disable-next-line
