import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../../shared-state";
import { BaseState } from "../../step-state-base";
import { PropertyValueSet } from "@genesys/property";
import * as DataCenterSizeSelector from "../../../../size-selectors/data-center-size-selector";

export type State =
  | ({
      readonly ok: boolean;
      readonly dataCenterSizeSelectorState: DataCenterSizeSelector.State;
    } & BaseState)
  | undefined;

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [State, Cmd<Action>?] => {
  const [
    dataCenterSizeSelectorState,
    dataCenterSizeSelectorCmd
  ] = DataCenterSizeSelector.init(sharedState, base.systemTypeId);
  return [
    {
      ...base,
      ok: false,
      dataCenterSizeSelectorState: dataCenterSizeSelectorState
    },
    Cmd.map(
      Action.dispatchDataCenterSizeSelectorActions,
      dataCenterSizeSelectorCmd
    )
  ];
};

export const Action = ctorsUnion({
  dispatchDataCenterSizeSelectorActions: (
    action: DataCenterSizeSelector.Action
  ) => ({
    action
  }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "dispatchDataCenterSizeSelectorActions": {
      if (!state?.dataCenterSizeSelectorState) {
        return [state];
      }

      const [
        dataCenterSizeSelectorState,
        dataCenterSizeSelectorCmd,
        sharedStateAction,
        didSizeChanged
      ] = DataCenterSizeSelector.update(
        action.action,
        state.dataCenterSizeSelectorState
      );

      const size = DataCenterSizeSelector.getSize(dataCenterSizeSelectorState);

      if (didSizeChanged && size) {
        return [
          {
            ...state,
            dataCenterSizeSelectorState: dataCenterSizeSelectorState,
            ok: true,
            newProperties: PropertyValueSet.setInteger(
              "epxsize",
              size,
              state.newProperties
            )
          }
        ];
      } else {
        return [
          {
            ...state,
            dataCenterSizeSelectorState: dataCenterSizeSelectorState
          },
          Cmd.map(
            Action.dispatchDataCenterSizeSelectorActions,
            dataCenterSizeSelectorCmd
          ),
          sharedStateAction ? [sharedStateAction] : []
        ];
      }
    }
    case "dummy":
      return [state];
    default:
      exhaustiveCheck(action, true);
  }
}
