import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const saveAndCalcMoistureLoadMutation = gql`
  mutation SaveMoistureLoadInputsAndCalculate(
    $moistureLoadinputs: MoistureLoadInputType!
    $moistureLoadId: ID!
  ) {
    saveMoistureLoadInputsAndCalculate(
      inputSettings: $moistureLoadinputs
      moistureLoadId: $moistureLoadId
    ) {
      logTraceId
      success
      moistureLoadSettingsErrors {
        resultGroup
        moistureLoadSettings
        errors
      }

      result {
        ...MoistureLoadResultFragment
      }
    }
  }
  ${Fragments.moistureLoadResultFragment}
`;
