import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as KnownProperties from "./known-properties";
import * as ChartAxisType from "@genesys/shared/lib/enums/chart-axis-type";
import { PropertyValueSet } from "@genesys/property";
import { AmountFormat } from "@genesys/shared/lib/screen-amounts";
import { Quantity, Unit } from "@genesys/uom";

export type ListPresets = "months" | "bins";

export interface BinCaseResult {
  readonly binData: PropertyValueSet.PropertyValueSet;
  readonly results: PropertyValueSet.PropertyValueSet;
}

export interface EnergyResult {
  readonly binResults: ReadonlyArray<BinCaseResult>;
  readonly presentationPerfParams: ReadonlyArray<string>;
  readonly presentationTotalPerfParams: ReadonlyArray<string>;
  readonly name?: LanguageTexts.TextDefinition;
}

export type EnergyResultTable = {
  readonly formattedHeader: ReadonlyArray<EnergyListColumn>;
  readonly formattedRows: Array<Array<string>>;
  readonly totalRow: Array<string>;
  readonly averageRow: Array<string>;
};

export interface EnergyListColumn {
  readonly internalName: string;
  readonly name: string;
  readonly format: AmountFormat | undefined;
}

export interface ResultQuantity {
  readonly propertyName: string;
  readonly internalName: string;
  readonly displayName: string;
  readonly format: AmountFormat;
}
export interface ChartPreset {
  readonly id: string;
  readonly name: string;
  readonly y1Label: string;
  readonly y1AxisType: ChartAxisType.ChartAxisType;
  readonly y1PerfParams: Array<PerfParamColor>;
  readonly y1UnitSi: Unit.Unit<Quantity.Quantity>;
  readonly y1UnitIp: Unit.Unit<Quantity.Quantity>;
  readonly y2Label: string;
  readonly y2AxisType: ChartAxisType.ChartAxisType;
  readonly y2PerfParams: Array<PerfParamColor>;
  readonly y2UnitSi: Unit.Unit<Quantity.Quantity>;
  readonly y2UnitIp: Unit.Unit<Quantity.Quantity>;
}

interface PerfParamColor {
  readonly name: string;
  readonly color: string;
}

export enum MonthEnum {
  January = "january",
  February = "february",
  March = "march",
  April = "april",
  May = "may",
  June = "june",
  July = "july",
  August = "august",
  September = "september",
  October = "october",
  November = "november",
  December = "december"
}

export type ClimateCoolingDataType =
  | "DB/MCWB"
  | "WB/MCDB"
  | "DP/MCDB"
  | "h/MCDB";

export const months = [
  MonthEnum.January,
  MonthEnum.February,
  MonthEnum.March,
  MonthEnum.April,
  MonthEnum.May,
  MonthEnum.June,
  MonthEnum.July,
  MonthEnum.August,
  MonthEnum.September,
  MonthEnum.October,
  MonthEnum.November,
  MonthEnum.December
];
export const propertiesRequiredForMonthlyView = [
  KnownProperties.binTimeJanuary,
  KnownProperties.binTimeFebruary,
  KnownProperties.binTimeMarch,
  KnownProperties.binTimeApril,
  KnownProperties.binTimeMay,
  KnownProperties.binTimeJune,
  KnownProperties.binTimeJuly,
  KnownProperties.binTimeAugust,
  KnownProperties.binTimeSeptember,
  KnownProperties.binTimeOctober,
  KnownProperties.binTimeNovember,
  KnownProperties.binTimeDecember
];
