import * as Reselect from "reselect";
import * as ReactRedux from "react-redux";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as ContainerComponent from "./container-component";

interface RootState {
  readonly ui: {
    readonly propertiesSelector: GenesysPropertiesSelectorTypes.State;
  };
}

const mapStateToProps = Reselect.createSelector(
  (state: RootState, props: ContainerComponent.OwnProps) =>
    state.ui.propertiesSelector[props.uniqueId],
  (settings): ContainerComponent.StateProps => {
    return {
      closedGroups: (settings && settings.closedGroups) || {},
      activatedQuantitySelectors:
        (settings && settings.activatedQuantitySelectors) || {}
    };
  }
);

// tslint:disable-next-line:variable-name
export const PropertiesSelectorContainer = ReactRedux.connect(mapStateToProps)(
  ContainerComponent.ContainerComponent
);
