import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { isValid } from "../property-filter-helpers";

interface ValueEssential {
  readonly rangeFilter?: string | null | PropertyFilter.PropertyFilter;
}

interface PropertyEssential {
  readonly items: ReadonlyArray<ValueEssential>;
}

interface ProductDataEssential {
  readonly id: string;
  readonly rangeFilter?:
    | string
    | null
    | undefined
    | PropertyFilter.PropertyFilter;
  readonly properties: ReadonlyArray<PropertyEssential>;
}

export function getValidProductsForRange<
  TProduct extends {
    readonly id: string;
    readonly rangeFilter?:
      | string
      | null
      | undefined
      | PropertyFilter.PropertyFilter;
  }
>(
  products: ReadonlyArray<TProduct>,
  rangeProperties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<TProduct> {
  return products.filter(p =>
    isValid(p.rangeFilter, PropertyFilter.Empty, rangeProperties)
  );
}

export function getProductDataMapFilteredForRange<
  TProductData extends ProductDataEssential
>(
  productDataMap: { readonly [key: string]: TProductData },
  productId: string,
  rangeProperties: PropertyValueSet.PropertyValueSet
): TProductData {
  return filterProductForRange(productDataMap[productId], rangeProperties);
}

export function filterProductForRange<
  TProductData extends ProductDataEssential
>(
  productData: TProductData,
  rangeProperties: PropertyValueSet.PropertyValueSet
): TProductData {
  const isNewOrSys =
    productData.id.endsWith("NEW") || productData.id.endsWith("SYS");
  if (
    !isNewOrSys &&
    rangeProperties &&
    productData.rangeFilter &&
    !isValid(productData.rangeFilter, PropertyFilter.Empty, rangeProperties)
  ) {
    throw new Error(
      "FilterForRange: Trying to filter a product: " +
        productData.id +
        " that should not exist according to rangefilter = " +
        productData.rangeFilter +
        "."
    );
  }

  const filteredProperties = productData.properties.reduce(
    (soFar, property) => {
      const values = property.items.filter(v =>
        isValid(v.rangeFilter, PropertyFilter.Empty, rangeProperties)
      );
      return soFar.concat({
        ...property,
        items: values
      });
    },
    [] as ReadonlyArray<PropertyEssential>
  );

  return Object.assign({}, productData, { properties: filteredProperties });
}
