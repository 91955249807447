import styled from "styled-components";
import * as React from "react";

interface ReadOnlyTextAreaProps {
  readonly containerHeight: number;
}

// tslint:disable-next-line
const UnStyledTextArea: React.FunctionComponent<
  ReadOnlyTextAreaProps & React.HTMLProps<HTMLTextAreaElement>
> = props => {
  const { containerHeight, ...textAreaProps } = props;

  return <textarea {...textAreaProps} />;
};

// tslint:disable-next-line
export const ReadOnlyTextArea = styled(UnStyledTextArea)`
  width: 100%;
  height: ${props => props.containerHeight}px;
  resize: none;
  overflow: auto;
`;
