import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchControlsPagingButtonContainer = styled.button`
  height: 30px;
  border: 1px solid ${Colors.searchFilterBorderColor};
  border-radius: 3px;
  background-color: ${Colors.searchBackground};
  width: 30px;
  vertical-align: bottom;
  margin-right: 5px;

  img {
    position: absolute;
    top: 25%;
    left: 38%;
  }

  position: relative;

  &:disabled {
    opacity: 0.5;

    &:hover {
      border: 1px solid ${Colors.searchFilterBorderColor};
    }
  }

  &:hover {
    border-color: ${Colors.searchFilterBorderHoverColor};
    border-width: 2px;
  }
`;
