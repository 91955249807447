import { exhaustiveCheck } from "ts-exhaustive-check";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import { promiseCmd } from "../../../promise-effect-manager";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import {
  PidDiagramUserQuery,
  PidDiagramProductQuery,
  PidDiagramUserQueryVariables,
  PidDiagramProductQueryVariables,
  GetPidDiagramBuildStateUserQuery,
  GetPidDiagramBuildStateUserQueryVariables,
  BuildPidDiagramMutation,
  BuildPidDiagramMutationVariables,
  PidDiagramDownloadResponseCodeEnum,
  PidDiagramCheckStateResponseCodeEnum,
  DownloadPidDiagramUserQuery,
  DownloadPidDiagramUserQueryVariables
} from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import {
  Format,
  SystemInfo,
  BuildStateResponse,
  DownloadResponse,
  RequestBuildResponse,
  DiagramType
} from "./types";
import {
  productQuery,
  userQuery,
  buildMutation,
  downloadQuery,
  getBuildStateQuery
} from "./queries";
import {
  getPdfUrl,
  parsePidDiagramBuildResponseCodeEnum,
  parsePidDiagramCheckStateResponseCodeEnum,
  parsePidDiagramDownloadResponseCodeEnum
} from "./functions";

export type AbstractImageState = {
  readonly type: "abstract-image";
  readonly isLoading: boolean;
  readonly selectedFormat: Format;
  readonly systemInfo: SystemInfo | undefined;
  readonly symbols: ReadonlyArray<FlowDiagram.Types.SymbolDefinition>;
  readonly flowDiagramProducts: ReadonlyArray<FlowDiagram.Types.Product>;
};

export type EContactState = {
  readonly type: "e-contact";
  readonly isLoading: boolean;
  readonly isBuilding: boolean;
  readonly url: string | undefined;
  readonly stateDescription: string;
};

export type State = AbstractImageState | EContactState;

export const init = (
  diagramType: DiagramType,
  sharedState: SharedState.State,
  systemId: string,
  systemTypeId: string,
  sysProperties: PropertyValueSet.PropertyValueSet
): [State, Cmd<Action>] => {
  switch (diagramType) {
    case "abstract-image": {
      return [
        {
          type: "abstract-image",
          selectedFormat: { fileExtension: "svg", apiValue: "SVG" },
          isLoading: false,
          flowDiagramProducts: [],
          symbols: [],
          systemInfo: undefined
        },
        promiseCmd(
          async () => {
            const userQueryResult = await sharedState.graphQL.queryUser<
              PidDiagramUserQuery,
              PidDiagramUserQueryVariables
            >(userQuery, {
              systemId: systemId
            });

            const productQueryResult = await sharedState.graphQL.queryProduct<
              PidDiagramProductQuery,
              PidDiagramProductQueryVariables
            >(productQuery, {
              systemTypeInput: {
                systemTypeId: systemTypeId
              }
            });

            return {
              userQueryResult,
              productQueryResult
            };
          },
          res =>
            Action.parseData(
              res.userQueryResult,
              res.productQueryResult,
              sysProperties
            )
        )
      ];
    }
    case "e-contact": {
      return [
        {
          type: "e-contact",
          isLoading: true,
          isBuilding: false,
          url: undefined,
          stateDescription: ""
        },
        sharedState.graphQL.queryUserCmd<
          GetPidDiagramBuildStateUserQuery,
          GetPidDiagramBuildStateUserQueryVariables,
          Action
        >(
          getBuildStateQuery,
          {
            input: {
              systemId: systemId
            }
          },
          data =>
            Action.handleBuildStateResponse({
              queryData: data,
              systemId: systemId
            })
        )
      ];
    }
    default:
      return exhaustiveCheck(diagramType, true);
  }
};

export const Action = ctorsUnion({
  // AbstractImage
  parseData: (
    userQueryResult: PidDiagramUserQuery,
    productQueryResult: PidDiagramProductQuery,
    sysProperties: PropertyValueSet.PropertyValueSet
  ) => ({ userQueryResult, productQueryResult, sysProperties }),
  setFormat: (format: string) => ({ format }),
  setIsLoading: (isLoading: boolean) => ({ isLoading }),
  // eContact
  requestBuild: (systemId: string) => ({ systemId }),
  handleRequestBuildResponse: (response: RequestBuildResponse) => ({
    response
  }),
  getBuildState: (systemId: string) => ({ systemId }),
  handleBuildStateResponse: (response: BuildStateResponse) => ({ response }),
  handleDownloadResponse: (response: DownloadResponse) => ({ response })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "parseData": {
      if (state.type !== "abstract-image") {
        return [state];
      }

      const productsForRange = ProductData.getValidProductsForRange(
        action.productQueryResult.product.systemType.allProducts,
        action.sysProperties
      );

      const flowDiagramProducts = productsForRange.map(p =>
        FlowDiagram.Parser.parseProduct(p)
      );

      const symbols = action.productQueryResult.product.systemType.symbols;

      const systemFlowDiagram = FlowDiagram.Parser.parseSystem(
        action.userQueryResult.user.system
      );

      const systemInfo: SystemInfo = {
        id: action.userQueryResult.user.system.id,
        name: action.userQueryResult.user.system.systemFile.name,
        flowDiagram: systemFlowDiagram
      };

      return [
        {
          ...state,
          flowDiagramProducts: flowDiagramProducts,
          symbols: symbols,
          systemInfo: systemInfo
        }
      ];
    }
    case "setFormat": {
      if (state.type !== "abstract-image") {
        return [state];
      }

      const selectedFormat: Format =
        action.format === "SVG"
          ? { fileExtension: "svg", apiValue: "SVG" }
          : { fileExtension: "dxf", apiValue: "DXF2D" };

      return [{ ...state, selectedFormat: selectedFormat }];
    }
    case "setIsLoading": {
      if (state.type !== "abstract-image") {
        return [state];
      }
      return [{ ...state, isLoading: action.isLoading }];
    }
    case "requestBuild": {
      if (state.type !== "e-contact") {
        return [state];
      }
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          BuildPidDiagramMutation,
          BuildPidDiagramMutationVariables,
          Action
        >(
          buildMutation,
          {
            input: {
              systemId: action.systemId
            }
          },
          data =>
            Action.handleRequestBuildResponse({
              queryData: data
            })
        )
      ];
    }
    case "handleRequestBuildResponse": {
      if (state.type !== "e-contact") {
        return [state];
      }
      const { stateDescription, isBuilding } =
        parsePidDiagramBuildResponseCodeEnum(
          action.response.queryData.buildPidDiagram.responseCode
        );

      return [
        {
          ...state,
          isLoading: false,
          isBuilding: isBuilding,
          stateDescription: stateDescription
        }
      ];
    }
    case "getBuildState": {
      if (state.type !== "e-contact") {
        return [state];
      }
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GetPidDiagramBuildStateUserQuery,
          GetPidDiagramBuildStateUserQueryVariables,
          Action
        >(
          getBuildStateQuery,
          {
            input: {
              systemId: action.systemId
            }
          },
          data =>
            Action.handleBuildStateResponse({
              queryData: data,
              systemId: action.systemId
            })
        )
      ];
    }
    case "handleBuildStateResponse": {
      if (state.type !== "e-contact") {
        return [state];
      }
      const responseCode: PidDiagramCheckStateResponseCodeEnum =
        action.response.queryData.user.getPidDiagramBuildState.responseCode;

      const { stateDescription, isBuilding } =
        parsePidDiagramCheckStateResponseCodeEnum(responseCode);

      const isBuildCompleted =
        responseCode === PidDiagramCheckStateResponseCodeEnum.BUILT;

      if (isBuildCompleted) {
        return [
          {
            ...state,
            isBuilding: false,
            stateDescription: stateDescription
          },
          sharedState.graphQL.queryUserCmd<
            DownloadPidDiagramUserQuery,
            DownloadPidDiagramUserQueryVariables,
            Action
          >(
            downloadQuery,
            {
              input: {
                systemId: action.response.systemId
              }
            },
            data =>
              Action.handleDownloadResponse({
                queryData: data
              })
          )
        ];
      } else {
        return [
          {
            ...state,
            isLoading: false,
            isBuilding: isBuilding,
            stateDescription: stateDescription
          }
        ];
      }
    }
    case "handleDownloadResponse": {
      if (state.type !== "e-contact") {
        return [state];
      }
      var responseCode: PidDiagramDownloadResponseCodeEnum =
        action.response.queryData.user.downloadPidDiagram.responseCode;

      const stateDescription =
        parsePidDiagramDownloadResponseCodeEnum(responseCode);

      const isPdfAvailable =
        responseCode === PidDiagramDownloadResponseCodeEnum.OK;

      const url = isPdfAvailable
        ? getPdfUrl(action.response.queryData)
        : undefined;

      return [
        {
          ...state,
          url: url,
          isLoading: false,
          stateDescription: stateDescription
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
