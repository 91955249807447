import * as React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "../../styled-components";
import { Img } from "../../atoms/img";

interface Margin {
  readonly marginLeft?: string;
  readonly marginRight?: string;
  readonly marginTop?: string;
  readonly marginBottom?: string;
}

const StyledToolTip = styled(
  ({
    className,

    ...props
  }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className, tooltip: className }} />
  )
)<Margin>(({ marginLeft, marginRight, marginBottom, marginTop }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "white",
    boxShadow: " 0 0 18px 0 rgb(0 0 0 / 10%)",
    borderRadius: "5px"
  },
  [`& .${tooltipClasses.popper}`]: {
    borderRadius: "5px",
    marginLeft,
    marginRight,
    marginTop,
    marginBottom
  }
}));

const SystemImg = styled(Img)<{
  readonly maxWidth: string;
  readonly maxHeight: string;
}>`
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  display: block;
  margin: auto;
  object-fit: contain;
`;

const FakeImage = styled.div`
  display: none;
`;

type DisplayType = "dashboard" | "system-manager";

export function ImageDisplayer({
  accessToken,
  effectiveSystemId,
  children,
  routeEndpoint,
  type = "dashboard"
}: {
  readonly type?: "dashboard" | "system-manager";
  readonly accessToken: string;
  readonly effectiveSystemId: string;
  readonly routeEndpoint: string;
  readonly children: React.ReactNode;
  readonly margin?: Margin;
}) {
  const systemPreviewImage = (
    <SystemImg
      {...getHeightAndWidth(type)}
      accessToken={accessToken}
      src={`${routeEndpoint}/internal/SystemPreviewApi/${effectiveSystemId}?format=SVG&diagramtype=unitconfiguration`}
    />
  );
  return (
    <>
      <StyledToolTip title={systemPreviewImage}>
        <div>
          {children}
          <FakeImage>{systemPreviewImage}</FakeImage>
          {/**To speed up the time it takes for the pictures to load when hovered over */}
        </div>
      </StyledToolTip>
    </>
  );
}

function getHeightAndWidth(type: DisplayType): {
  readonly maxWidth: string;
  readonly maxHeight: string;
} {
  if (type === "system-manager") {
    return {
      maxHeight: "214px",
      maxWidth: "460px;"
    };
  }

  return {
    maxHeight: "130px",
    maxWidth: "245px;"
  };
}
