import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Quantity, Unit } from "@genesys/uom";
import { getNewPoint, getCalculatedPoints } from "./functions";
import * as PropertiesSelector from "../../properties-selector";
import { Point } from "./types";
import * as PsychrometricChart from "../../psychrometric-chart";

export type State = {
  readonly points: ReadonlyArray<Point>;
  readonly chartSettings: PropertiesSelector.State;
  readonly pressure: PropertyValueSet.PropertyValueSet;
  readonly airflow: PropertyValueSet.PropertyValueSet;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
  readonly waitingForDownload: boolean;
};

export const init = (
  userMeasureSystem: string,
  psychrometricChartSettings: SharedState.PsychrometricChartSettings
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      points: [
        {
          index: 0,
          state: PropertiesSelector.init(getNewPoint())
        }
      ],
      chartSettings: PropertiesSelector.init(
        createInitialChartSettings(
          userMeasureSystem,
          psychrometricChartSettings
        )
      ),
      pressure: PropertyValueSet.fromString("pressure=101325:Pascal"),
      airflow: PropertyValueSet.fromString(
        "airflow=2990:StandardCubicMeterPerHour"
      ),
      psychrometricChartState: undefined,
      waitingForDownload: false
    }
  ];
};

export const Action = ctorsUnion({
  setPressure: (pvs: PropertyValueSet.PropertyValueSet) => ({ pvs }),
  setAirFlow: (pvs: PropertyValueSet.PropertyValueSet) => ({ pvs }),
  deletePoint: (index: number) => ({ index }),
  addPoint: () => ({}),
  generateChart: () => ({}),
  dispatchPointSelector: (
    index: number,
    action: PropertiesSelector.Action
  ) => ({ index, action }),
  dispatchChartSettings: (action: PropertiesSelector.Action) => ({ action }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  setWaitingForDownloading: (waiting: boolean) => ({
    waiting
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "setPressure": {
      const calculatedPoints = getCalculatedPoints(
        state.points,
        action.pvs,
        state.airflow
      );

      return [{ ...state, pressure: action.pvs, points: calculatedPoints }];
    }
    case "setAirFlow": {
      const calculatedPoints = getCalculatedPoints(
        state.points,
        state.pressure,
        action.pvs
      );

      return [{ ...state, airflow: action.pvs, points: calculatedPoints }];
    }
    case "deletePoint": {
      return [
        {
          ...state,
          points: state.points
            .slice(0, action.index)
            .concat(state.points.slice(action.index + 1))
        }
      ];
    }
    case "addPoint": {
      return [
        {
          ...state,
          points: [
            ...state.points,
            {
              index: state.points.length,
              state: PropertiesSelector.init(getNewPoint())
            }
          ]
        }
      ];
    }
    case "generateChart": {
      const chartSettingsPvs = PropertiesSelector.getSelectedProperties(
        state.chartSettings
      );

      const type =
        PropertyValueSet.getInteger("charttype", chartSettingsPvs) !== 0
          ? "mollier"
          : "psychrometric";

      const si = PropertyValueSet.getInteger("ipsi", chartSettingsPvs) !== 0;

      const points = state.points.map(point => ({
        temperature: PropertyValueSet.getAmount<Quantity.Temperature>(
          "temperature",
          PropertiesSelector.getSelectedProperties(point.state)
        )!,
        humidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
          "humidity",
          PropertiesSelector.getSelectedProperties(point.state)
        )!
      }));

      const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
        "pressure",
        state.pressure
      )!;
      const limits =
        PropertyValueSet.getInteger("limits", chartSettingsPvs) !== 0;
      const humidityMax = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "humiditymax",
        chartSettingsPvs
      )!;
      const temperatureMin = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperaturemin",
        chartSettingsPvs
      )!;
      const temperatureMax = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperaturemax",
        chartSettingsPvs
      )!;

      const initProps: PsychrometricChart.InitProps = {
        source: "custom",
        accessToken: sharedState.accessToken,
        type: type,
        si: si,
        points: points,
        pressure: pressure,
        limits: limits,
        humidityMax: humidityMax,
        temperatureMin: temperatureMin,
        temperatureMax: temperatureMax,
        binCases: [],
        binFields: []
      };

      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(initProps);

      return [
        { ...state, psychrometricChartState: psychrometricChartState },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        [
          SharedState.Action.setPsychrometricChartSettings({
            chartType: type,
            humidityMax: PropertyValue.toString(
              PropertyValue.fromAmount(humidityMax)
            ),
            limits: limits ? "manual" : "smart",
            temperatureMin: PropertyValue.toString(
              PropertyValue.fromAmount(temperatureMin)
            ),
            temperatureMax: PropertyValue.toString(
              PropertyValue.fromAmount(temperatureMax)
            )
          })
        ]
      ];
    }

    case "dispatchPointSelector": {
      const dispatchedPoint = state.points.find(p => p.index === action.index);
      if (!dispatchedPoint) {
        return [state];
      }

      const oldState = dispatchedPoint.state;

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        oldState,
        sharedState,
        "SkipCalculateProperties"
      );

      const newPoints = state.points.map(p =>
        p.index === action.index
          ? {
              index: p.index,
              state: propertiesSelectorState
            }
          : p
      );

      const calculatedPoints = getCalculatedPoints(
        newPoints,
        state.pressure,
        state.airflow
      );

      return [
        { ...state, points: calculatedPoints },
        Cmd.map(
          cmdAction => Action.dispatchPointSelector(action.index, cmdAction),
          propertiesSelectorCmd
        ),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dispatchChartSettings": {
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.chartSettings,
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        { ...state, chartSettings: propertiesSelectorState },
        Cmd.map(Action.dispatchChartSettings, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dispatchPsychrometricChart": {
      if (!state.psychrometricChartState) {
        return [state];
      }
      const [PsychrometricChartState, PsychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.psychrometricChartState,
          sharedState
        );
      return [
        {
          ...state,
          psychrometricChartState: PsychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, PsychrometricChartCmd),
        [sharedStateCmd]
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }
    case "setWaitingForDownloading": {
      const chartSettingsPvs = PropertiesSelector.getSelectedProperties(
        state.chartSettings
      );

      const type =
        PropertyValueSet.getInteger("charttype", chartSettingsPvs) !== 0
          ? "mollier"
          : "psychrometric";

      const limits =
        PropertyValueSet.getInteger("limits", chartSettingsPvs) !== 0;
      const humidityMax = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "humiditymax",
        chartSettingsPvs
      )!;
      const temperatureMin = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperaturemin",
        chartSettingsPvs
      )!;
      const temperatureMax = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperaturemax",
        chartSettingsPvs
      )!;
      return [
        {
          ...state,
          waitingForDownload: action.waiting
        },
        undefined,
        [
          SharedState.Action.setPsychrometricChartSettings({
            chartType: type,
            humidityMax: PropertyValue.toString(
              PropertyValue.fromAmount(humidityMax)
            ),
            limits: limits ? "manual" : "smart",
            temperatureMin: PropertyValue.toString(
              PropertyValue.fromAmount(temperatureMin)
            ),
            temperatureMax: PropertyValue.toString(
              PropertyValue.fromAmount(temperatureMax)
            )
          })
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function createInitialChartSettings(
  userMeasureSystem: string,
  psychrometricChartSettings: SharedState.PsychrometricChartSettings
): PropertyValueSet.PropertyValueSet {
  const defaultSettings = PropertyValueSet.fromString(
    "showpower=1;charttype=0;ipsi=1;humiditymax=40:GramPerKilogram;temperaturemin=0:Celsius;temperaturemax=40:Celsius"
  );

  const humidityMax = PropertyValue.fromString(
    psychrometricChartSettings.humidityMax
  );
  const temperatureMin = PropertyValue.fromString(
    psychrometricChartSettings.temperatureMin
  );
  const temperatureMax = PropertyValue.fromString(
    psychrometricChartSettings.temperatureMax
  );

  let settings = PropertyValueSet.setInteger(
    "charttype",
    psychrometricChartSettings.chartType === "mollier" ? 1 : 0,
    defaultSettings
  );
  settings = PropertyValueSet.setInteger(
    "limits",
    psychrometricChartSettings.limits === "manual" ? 1 : 0,
    settings
  );
  settings = humidityMax
    ? PropertyValueSet.set("humiditymax", humidityMax, settings)
    : settings;
  settings = temperatureMin
    ? PropertyValueSet.set("temperaturemin", temperatureMin, settings)
    : settings;
  settings = temperatureMax
    ? PropertyValueSet.set("temperaturemax", temperatureMax, settings)
    : settings;

  if (userMeasureSystem.toLowerCase() === "si") {
    return PropertyValueSet.setInteger("ipsi", 1, settings);
  }
  return PropertyValueSet.setInteger("ipsi", 0, settings);
}
