import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import * as Authorization from "@genesys/shared/lib/authorization";
import { H1, StandardButton } from "@genesys/ui-elements";
import {
  SelectionLimitationsView,
  RequirementsView,
  SuggestedOptionsView
} from "./components";
import {
  Root,
  Navigation,
  Tab,
  PagesContainer,
  StepNavigationContainer
} from "./elements";
import * as Types from "./types";
import { hasVariantsGenerationDataChanged } from "./system-variant-functions";

/*
TODOS
------

- LanguageTexts

*/

export function SystemSelectionGuideView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  if (
    !(
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.developer
      ) ||
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseSystemSelectionGuide
      )
    )
  ) {
    return <div>unauthorized</div>;
  }

  return (
    <Root>
      <Navigation>
        <Tab
          isActive={state.activePage === "limitations"}
          onClick={() => dispatch(Action.setActivePage("limitations"))}
        >
          <H1 weight="normal" color="dark">
            Selection Limitations
          </H1>
        </Tab>
        <Tab
          isActive={state.activePage === "requirements"}
          onClick={() => dispatch(Action.setActivePage("requirements"))}
        >
          <H1 weight="normal" color="dark">
            Requirements
          </H1>
        </Tab>
        <Tab
          isActive={state.activePage === "suggestions"}
          onClick={() => {
            dispatch(Action.setActivePage("suggestions"));
            if (
              state.systemVariantsGroups.length === 0 ||
              hasVariantsGenerationDataChanged(state)
            ) {
              // Timeout is set so that the page changes before the generation
              // as otherwise the transition will be janky
              setTimeout(() => {
                dispatch(Action.generateSystemVariants());
              }, 500);
            }
          }}
        >
          <H1 weight="normal" color="dark">
            Suggested Options
          </H1>
        </Tab>
      </Navigation>
      <PagesContainer>
        {state.activePage === "limitations" && (
          <SelectionLimitationsView
            state={state}
            sharedState={sharedState}
            dispatch={dispatch}
          />
        )}
        {state.activePage === "requirements" && (
          <RequirementsView
            state={state}
            sharedState={sharedState}
            dispatch={dispatch}
          />
        )}
        {state.activePage === "suggestions" && (
          <SuggestedOptionsView
            state={state}
            sharedState={sharedState}
            dispatch={dispatch}
          />
        )}
      </PagesContainer>

      <StepNavigationButtons state={state} dispatch={dispatch} />
    </Root>
  );
}

function StepNavigationButtons({
  state,
  dispatch
}: {
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const nextValue: Types.Page | undefined =
    state.activePage === "limitations"
      ? "requirements"
      : state.activePage === "requirements"
      ? "suggestions"
      : undefined;
  const previousValue: Types.Page | undefined =
    state.activePage === "suggestions"
      ? "requirements"
      : state.activePage === "requirements"
      ? "limitations"
      : undefined;

  return (
    <StepNavigationContainer>
      <StandardButton
        buttonType="Primary-2"
        size="Large"
        onClick={() => {
          if (previousValue) {
            dispatch(Action.setActivePage(previousValue));
          }
        }}
        disabled={previousValue === undefined}
      >
        Previous Step
      </StandardButton>

      <StandardButton
        buttonType="Primary-2"
        size="Large"
        onClick={() => {
          if (nextValue) {
            dispatch(Action.setActivePage(nextValue));
            if (
              nextValue === "suggestions" &&
              (state.systemVariantsGroups.length === 0 ||
                hasVariantsGenerationDataChanged(state))
            ) {
              // Timeout is set so that the page changes before the generation
              // as otherwise the transition will be janky
              setTimeout(() => {
                dispatch(Action.generateSystemVariants());
              }, 500);
            }
          }
        }}
        disabled={nextValue === undefined}
      >
        Next Step
      </StandardButton>
    </StepNavigationContainer>
  );
}
