import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setActiveAmountProfileMutation = gql`
  mutation SetActiveAmountProfile($amountProfileId: String!) {
    setActiveAmountProfile(amountProfileId: $amountProfileId) {
      id
      selectedAmountFieldProfile {
        ...CompleteAmountFieldProfile
      }
    }
  }
  ${Fragments.completeAmountFieldProfile}
`;
