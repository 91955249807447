import gql from "graphql-tag";

export const createMoistureLoadMutation = gql`
  mutation CreateMoistureLoad($input: CreateMoistureLoadInputType!) {
    createMoistureLoad(inputs: $input) {
      id
      moistureLoadId
      revisionNo
      moistureLoadNo
    }
  }
`;
