import * as React from "react";
import { Action } from "./state";
import * as Types from "./types";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import * as HumSizeSelector from "./steps/size-selectors/hum-size-selector-step";
import * as EccSizeSelector from "./steps/size-selectors/ecc-size-selector-step";
import * as DhuSizeSelector from "./steps/size-selectors/dhu-size-selector-step";
import * as DataCenterSizeSelector from "./steps/size-selectors/data-center-size-selector-step";
import * as SystemSettings from "./steps/system-settings";
import * as InitialConfiguration from "./steps/initial-configuration";
import * as OperatingCaseSelector from "./steps/operating-cases";
import * as StepsRegistry from "./steps-registry";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import {
  StandardButton,
  SquarePattern,
  OverlayLoader
} from "@genesys/ui-elements";
import { v4 } from "uuid";
import { PropertyValueSet } from "@genesys/property";
import {
  Container,
  StepContainer,
  ControllerContainer,
  Background,
  ProgressBarContainer,
  CreatingSystemLoaderOverlay
} from "./elements";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ProgressBar } from "./progress-bar";

export interface WizardViewProps {
  readonly dispatch: Dispatch<Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
}

export function WizardView(props: WizardViewProps): JSX.Element {
  const stepRef = React.useRef<any>(null);

  React.useEffect(() => {
    // tslint:disable-next-line
    props.state.scroll &&
      stepRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.state.activeSteps.filter(s => s.state).length]);

  const currentStep =
    props.state.activeSteps[props.state.activeSteps.length - 1];

  const systemTypeSettings =
    currentStep.state && currentStep.state.systemTypeId
      ? StepsRegistry.steps(
          currentStep.state.systemTypeId,
          currentStep.state.newProperties
        )
      : undefined;

  const lastStep = systemTypeSettings
    ? props.state.activeSteps.length - 1 >= systemTypeSettings.steps.length
    : undefined;

  const next = () => props.dispatch(Action.next());
  const finish = () =>
    createFinish(
      props.dispatch,
      currentStep.state!,
      systemTypeSettings!,
      props.sharedState
    );

  return (
    <>
      {props.state.creatingSystem && (
        <CreatingSystemLoaderOverlay>
          <OverlayLoader />
        </CreatingSystemLoaderOverlay>
      )}
      <Background>
        <SquarePattern />
      </Background>
      <Container>
        <ProgressBarContainer>
          <ProgressBar
            translate={props.sharedState.translate}
            currentStepIndex={props.state.activeSteps.length - 1}
            canFinish={!!lastStep && currentStep.state!.ok}
            finish={finish}
            steps={[
              props.state.activeSteps[0].type,
              ...(systemTypeSettings
                ? systemTypeSettings.steps
                : props.state.activeSteps.length > 1
                ? StepsRegistry.steps(
                    props.state.activeSteps[props.state.activeSteps.length - 2]
                      .state!.systemTypeId,
                    props.state.activeSteps[props.state.activeSteps.length - 2]
                      .state!.newProperties
                  ).steps
                : [])
            ]}
          />
        </ProgressBarContainer>
        {props.state.activeSteps.map((s, ix) => (
          <StepContainer
            key={s.type}
            ref={ix === props.state.activeSteps.length - 1 ? stepRef : null}
          >
            {step(s, props.dispatch, props.sharedState)}
          </StepContainer>
        ))}
        <ControllerContainer>
          {currentStep.state && systemTypeSettings ? (
            <Controllers
              currentStepState={currentStep.state}
              lastStep={!!lastStep}
              sharedState={props.sharedState}
              finish={finish}
              next={next}
            />
          ) : (
            <></>
          )}
        </ControllerContainer>
      </Container>
    </>
  );
}

function createFinish(
  dispatch: Dispatch<Action>,
  currentStepState: Types.Step["state"],
  systemTypeSettings: Types.SystemTypeRegistryEntry,
  sharedState: SharedState.State
): void {
  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  return dispatch(
    Action.finish({
      input: [
        {
          id: v4(),
          moistureLoadId: currentStepState?.moistureLoadId,
          addMCompare: systemTypeSettings.addMCompare,
          labels: currentStepState?.assignedLabels,
          climateData: PropertyValueSet.toString(
            currentStepState!.climateSettings
          ),
          currencyCode: sharedState.user.settings.currency,
          generateBinCases: systemTypeSettings.generateBinCases,
          name: currentStepState!.name,
          newProperties: PropertyValueSet.toString(
            currentStepState!.newProperties
          ),
          operatingCases: currentStepState!.operatingCases.map(oc =>
            PropertyValueSet.toString(oc)
          ),
          salesOrganisationId:
            sharedState.user.settings.defaultSalesOrganisation ||
            (primarySalesOrganisationFromClaim &&
              primarySalesOrganisationFromClaim[0]) ||
            sharedState.user.salesOrganisations[0].id,
          systemTypeId: currentStepState!.systemTypeId,
          targetStatus: systemTypeSettings.targetStatus,
          templateComponents: Object.keys(
            currentStepState!.divergentTemplateComponentMap
          ).map(id => ({
            id: id,
            properties: PropertyValueSet.toString(
              PropertyValueSet.keepProperties(
                [
                  ...(currentStepState!.template.components.find(
                    c => c.id === id
                  )?.visibleProperties ?? [])
                ],
                currentStepState!.divergentTemplateComponentMap[id].properties
              )
            )
          }))
        }
      ]
    })
  );
}

function Controllers(props: {
  readonly sharedState: SharedState.State;
  readonly currentStepState: Types.Step["state"];
  readonly lastStep: boolean;
  readonly finish: () => void;
  readonly next: () => void;
}): JSX.Element {
  return props.lastStep ? (
    <>
      <StandardButton
        buttonType="Primary-2"
        size="Large"
        disabled={!props.currentStepState!.ok}
        onClick={props.finish}
      >
        <span>
          {props.sharedState.translate(LanguageTexts.create()) +
            " & " +
            props.sharedState.translate(LanguageTexts.calculate())}
        </span>
      </StandardButton>
    </>
  ) : (
    <>
      <StandardButton
        buttonType="Primary-2"
        size="Large"
        disabled={!props.currentStepState!.ok}
        onClick={props.next}
      >
        <span>{props.sharedState.translate(LanguageTexts.continueText())}</span>
      </StandardButton>
    </>
  );
}

function step(
  step: Types.Step,
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State
) {
  switch (step.type) {
    case "system-settings":
      return (
        <SystemSettings.SystemSettings
          dispatch={Dispatch.map(Action.dispatchSystemSettings, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "data-center-size-selector":
      return (
        <DataCenterSizeSelector.DataCenterSizeSelectorStepView
          dispatch={Dispatch.map(
            Action.dispatchDataCenterSizeSelector,
            dispatch
          )}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "dhu-size-selector":
      return (
        <DhuSizeSelector.DhuSizeSelectorStepView
          dispatch={Dispatch.map(Action.dispatchDhuSizeSelector, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "hum-size-selector":
      return (
        <HumSizeSelector.HumSizeSelectorStepView
          dispatch={Dispatch.map(Action.dispatchHumSizeSelector, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "ecc-size-selector":
      return (
        <EccSizeSelector.EccSizeSelectorStepView
          dispatch={Dispatch.map(Action.dispatchEccSizeSelector, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "initial-configuration":
      return (
        <InitialConfiguration.InitialConfiguration
          dispatch={Dispatch.map(Action.dispatchInitialConfiguration, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    case "operating-cases":
      return (
        <OperatingCaseSelector.OperatingCases
          dispatch={Dispatch.map(Action.dispatchOperatingCases, dispatch)}
          sharedState={sharedState}
          state={step.state}
        />
      );
    default:
      exhaustiveCheck(step, true);
  }
}
