import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQlTypes from "../../../graphql-types";
import styled from "styled-components";
import { Spinner, ReadOnlyTable } from "@genesys/ui-elements";
import { AmountFormatSelector } from "../../../amount-format-selector";
import { getValue } from "@genesys/shared/lib/product-properties";
import { State, Action, ErrorStatus } from "./state";
import { Dispatch } from "@typescript-tea/core";
import {
  Opc,
  SurfaceTempForAirPoint,
  CondensationAnalysisForOpc
} from "./types";

const SpinnerContainer = styled.div`
  height: 557px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 20px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8f9bb3;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
`;

const DisClaimerContainer = styled.div`
  margin-top: 10px;
`;

const CondensationClarificationContainer = styled.div`
  margin-top: 10px;
`;

const TableContainer = styled.div`
  margin-top: 30px;
`;

const Error = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const HighRiskLevel = styled.div`
  background-color: red;
  color: white;
  padding: 2px;
`;

const LowRiskLevel = styled.div`
  background-color: yellow;
  padding: 2px;
`;

const dewPointName = "DewPoint";

export function View({
  state,
  sharedState,
  systemTypeId,
  surrounding,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemTypeId: string;
  readonly surrounding: number;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.errorStatus !== "none") {
    return (
      <Container>
        <Error> {getErorMessage(state.errorStatus)}</Error>
      </Container>
    );
  }
  if (state.data === undefined) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }
  const fieldGroup = "CasingCondensationRiskTable." + systemTypeId;

  const headers = getHeaders(state.data, sharedState, fieldGroup);
  if (headers.length === 0) {
    return <div>No condensation analysis found on any opc</div>;
  }

  const translate = sharedState.translate;

  const clarification = translate(
    LanguageTexts.globalPropertyName("condensationanalysisclarification")
  );
  const disclaimer = translate(
    LanguageTexts.globalPropertyName("condensationanalysisdisclaimer")
  );
  const placement = translate(
    LanguageTexts.systemTypeProperty(systemTypeId, "systemplacement")
  );
  const placementValue = translate(
    LanguageTexts.systemTypePropertyValue(
      systemTypeId,
      "systemplacement",
      surrounding
    )
  );

  const rows = getRows(
    state.data,
    name => headers.find(x => x.fieldName === name)?.format
  );

  return (
    <Container>
      <label style={{ fontWeight: "bold" }}>{placement}</label>:{" "}
      <label>{placementValue}</label>
      <CondensationClarificationContainer>
        <p>{clarification}</p>
      </CondensationClarificationContainer>
      <TableContainer>
        <ReadOnlyTable
          alignHeader="center"
          rows={rows.map(x => ({
            name: x[0].value,
            results: formatRowResult(x.slice(1))
          }))}
          headers={headers.map(x => ({
            header: x.displayValue,
            amountFormatSelector: createAmountFormatSelector(
              x.format,
              fieldGroup,
              x.fieldName,
              translate,
              dispatch
            ),
            visible: true
          }))}
        />
      </TableContainer>
      <DisClaimerContainer>
        <p style={{ fontSize: "11px", paddingLeft: "10px" }}>{disclaimer}</p>
      </DisClaimerContainer>
    </Container>
  );
}
function createAmountFormatSelector(
  format: ScreenAmounts.AmountFormat | undefined,
  listFieldGroup: string,
  name: string,
  translate: LanguageTexts.Translate,
  dispatch: Dispatch<Action>
) {
  return () => {
    if (format === undefined) {
      return null;
    }

    return (
      <>
        [
        <AmountFormatSelector
          type="AmountFormatSelectorProps"
          fieldName={name}
          amountFormat={format}
          fieldGroup={listFieldGroup}
          conversionParameters={undefined}
          translate={translate}
          onFormatCleared={() =>
            dispatch(Action.onFormatCleared(listFieldGroup, name))
          }
          onFormatChanged={(unit, decimalCount) =>
            dispatch(
              Action.onFormatChanged(listFieldGroup, name, unit, decimalCount)
            )
          }
        />
        ]
      </>
    );
  };
}

function extractTemperatureValues(
  surfaceTemps: ReadonlyArray<SurfaceTempForAirPoint>,
  getFormat: (name: string) => ScreenAmounts.AmountFormat | undefined
) {
  return surfaceTemps.map(surfaceTemp => {
    const format = getFormat("temperature");
    const pv = surfaceTemp.temp;
    const value = getValue(pv, format!);
    return {
      value,
      condensationRisk: surfaceTemp.riskLevel
    };
  });
}

function extractDewPointValue(
  condensationAnalysis: CondensationAnalysisForOpc,
  getFormat: (name: string) => ScreenAmounts.AmountFormat | undefined
) {
  const dewPointFormat = getFormat(dewPointName);
  return getValue(condensationAnalysis.dewPointTemperature, dewPointFormat!);
}

interface Row {
  readonly value: string;
  readonly condensationRisk?: GraphQlTypes.RiskLevel;
}

function getRows(
  data: ReadonlyArray<Opc>,
  getFormat: (name: string) => ScreenAmounts.AmountFormat | undefined
): Array<Array<Row>> {
  return data.reduce((accumulator, current) => {
    const condensationAnalysis = current.condensationAnalysisForOpc;
    if (!condensationAnalysis) {
      return accumulator;
    }
    const temperatureValues = extractTemperatureValues(
      condensationAnalysis.surfaceTempsForAirPoint,
      getFormat
    );
    const dewPoint = extractDewPointValue(condensationAnalysis, getFormat);
    const opcName = current.customCaseName || current.caseName;
    const opcCell = {
      value: opcName
    };
    const dewPointCell = {
      value: dewPoint
    };
    return [...accumulator, [opcCell, dewPointCell, ...temperatureValues]];
  }, [] as Array<Array<Row>>);
}

function getDewPointHeader(sharedState: SharedState.State, fieldGroup: string) {
  const createAmountFormat = sharedState.screenAmounts.getAmountFormat;

  return {
    displayValue: "Dew Point",
    fieldName: dewPointName,
    format: createAmountFormat(fieldGroup, dewPointName, "DewPointTemperature")
  };
}

function getTemperatureHeader(
  sharedState: SharedState.State,
  fieldGroup: string
) {
  const createAmountFormat = sharedState.screenAmounts.getAmountFormat;

  return {
    displayValue: " ",
    fieldName: "temperature",
    format: createAmountFormat(fieldGroup, "temperature", "Temperature")
  };
}

function getLabels(surfaceTemps: ReadonlyArray<SurfaceTempForAirPoint>) {
  return surfaceTemps.map(surfaceTemp => surfaceTemp.airPoint.label);
}

function getHeaders(
  data: ReadonlyArray<Opc>,
  sharedState: SharedState.State,
  fieldGroup: string
) {
  const randomOpc = data.find(x => x.condensationAnalysisForOpc);
  if (!randomOpc) {
    return [];
  }

  const surfaceTemps =
    randomOpc.condensationAnalysisForOpc?.surfaceTempsForAirPoint || [];
  const dewPointHeader = getDewPointHeader(sharedState, fieldGroup);
  const tempHeader = getTemperatureHeader(sharedState, fieldGroup);
  const labels = getLabels(surfaceTemps);

  return [
    tempHeader,
    dewPointHeader,
    ...labels.map(label => ({
      displayValue: label,
      fieldName: label,
      format: undefined
    }))
  ];
}

function formatRowResult(row: Array<Row>) {
  return row.map(cell => {
    if (cell.condensationRisk === GraphQlTypes.RiskLevel.HIGH) {
      return <HighRiskLevel>{cell.value}</HighRiskLevel>;
    } else if (cell.condensationRisk === GraphQlTypes.RiskLevel.LOW) {
      return <LowRiskLevel>{cell.value}</LowRiskLevel>;
    } else {
      return cell.value;
    }
  });
}

function getErorMessage(status: ErrorStatus) {
  switch (status) {
    case "not-calulated": {
      return "Pls calulate system first";
    }

    case "ope-not-calcualted": {
      return "Pls calulate energy cases first";
    }

    default:
      return "";
  }
}
