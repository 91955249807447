import gql from "graphql-tag";

export const calculateQuery = gql`
  query calculateDryCapUserQuery($input: DryCapCalculateInputType!) {
    user {
      calculateDryCap(input: $input) {
        hasResults
        processOutletFlow
        processOutletTemperature
        processOutletHumidity
        processOutletPressure
        processAvailableStaticPressure
        reactOutletFlow
        reactAvailableStaticPressure
        reactHeaterPower
        totalPower
        moistureRemoval
      }
    }
  }
`;
