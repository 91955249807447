import { PropertyValueSet } from "@genesys/property";
import {
  deleteMCompareComponentMutation,
  saveBinSelectionAndCases,
  saveEnergySettings
} from "@genesys/client-core/lib/graphql-mutations";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";

export async function saveBinSelectionsAndEnergy(
  sharedState: SharedState.State,
  systemId: string,
  binSelections: PropertyValueSet.PropertyValueSet,
  binOperatingCases: ReadonlyArray<{
    readonly binIndex: number;
    readonly settings: PropertyValueSet.PropertyValueSet;
    readonly binData: PropertyValueSet.PropertyValueSet;
  }>,
  energySettings: {
    readonly [key: string]: string;
  }
) {
  const promise1 = sharedState.graphQL.queryUser<
    GraphQLTypes.SaveBinSelectionAndCases,
    GraphQLTypes.SaveBinSelectionAndCasesVariables
  >(saveBinSelectionAndCases, {
    input: {
      systemId,
      binSelections: PropertyValueSet.toString(binSelections),
      binOperatingCases: binOperatingCases.map(bin => ({
        binIndex: bin.binIndex,
        settings: PropertyValueSet.toString(bin.settings),
        binData: PropertyValueSet.toString(bin.binData)
      }))
    }
  });

  const promise2 = sharedState.graphQL.queryUser<
    GraphQLTypes.SaveEnergySettings,
    GraphQLTypes.SaveEnergySettingsVariables
  >(saveEnergySettings, {
    input: {
      energySettings: Object.keys(energySettings).map(e => ({
        settingName: e,
        value: energySettings[e]
      }))
    }
  });
  await Promise.all([promise1, promise2]);
  await sharedState.graphQL.queryUser<
    GraphQLTypes.DeleteMCompareComponent,
    GraphQLTypes.DeleteMCompareComponentVariables
  >(deleteMCompareComponentMutation, {
    input: {
      systemId
    }
  });
}
