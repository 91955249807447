import * as System from "../../../system";
import * as SharedState from "../../../../shared-state";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as Product from "../../../product";
import * as PropertiesSelector from "../../../../properties-selector";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import * as GraphQlTypes from "../../../../graphql-types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import * as Types from "./types";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import { Amount, Units, Format, Quantity } from "@genesys/uom";

export function getCompetitorSettings(
  system: System.System
): PropertyValueSet.PropertyValueSet | undefined {
  const mcs = system.components.find(c => c.productId.endsWith("MCS"));
  if (mcs) {
    return mcs.properties;
  }
  return undefined;
}

export function getPropertiesDefinitions(
  mcsProductData: Product.Product,
  productData: Product.Product,
  sysProperties: PropertyValueSet.PropertyValueSet | undefined
) {
  const excludedCaseSettings = [
    KnownProperties.outdoorAirTemperature,
    KnownProperties.outdoorTemperature,
    KnownProperties.outdoorsTemp,
    KnownProperties.outdoorAirHumidity,
    KnownProperties.outdoorHumidity,
    KnownProperties.outdoorsHum,
    KnownProperties.caseName,
    KnownProperties.outdoorData
  ];

  const filteredProductData = ProductDataTools.filterProductForRange(
    mcsProductData,
    sysProperties || PropertyValueSet.Empty
  );

  const filteredProductDataForOpe = ProductDataTools.filterProductForRange(
    productData,
    sysProperties || PropertyValueSet.Empty
  );

  const competitorSystemPropertiesDef: ReadonlyArray<PropertiesSelector.PropertyInfo> =
    filteredProductData.properties;

  const operatingcaseSelectorPropertiesDef =
    filteredProductDataForOpe.properties.filter(
      obj => !excludedCaseSettings.includes(obj.name)
    );

  const energyEmissionPropertiesDef: ReadonlyArray<PropertiesSelector.PropertyInfo> =
    [
      {
        name: KnownProperties.CO2EmissionElectric,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      }
    ];

  return {
    competitorSystemPropertiesDef,
    operatingcaseSelectorPropertiesDef,
    energyEmissionPropertiesDef
  };
}

export function getCaseSettings(
  system: System.System
): PropertyValueSet.PropertyValueSet {
  let caseSettings = PropertyValueSet.Empty;
  const opeComponents = system.components
    .filter(c => c.productId.endsWith("OPE"))
    .map(c => ({
      ...c,
      properties: c.properties
    }))
    .sort((a, b) =>
      (PropertyValueSet.hasProperty(KnownProperties.binId, a.properties)
        ? PropertyValueSet.getInteger(KnownProperties.binId, a.properties)!
        : 99) <
      (PropertyValueSet.hasProperty(KnownProperties.binId, b.properties)
        ? PropertyValueSet.getInteger(KnownProperties.binId, b.properties)!
        : 99)
        ? -1
        : 1
    );
  const firstOpeComponent = opeComponents[0];
  if (firstOpeComponent) {
    caseSettings = firstOpeComponent.properties;
  } else {
    const opcComponents = system.components
      .filter(c => c.productId.endsWith("OPC"))
      .map(c => ({
        ...c,
        properties: c.properties
      }))
      .sort((a, b) =>
        (PropertyValueSet.hasProperty(KnownProperties.sortNo, a.properties)
          ? PropertyValueSet.getInteger(KnownProperties.sortNo, a.properties)!
          : 99) <
        (PropertyValueSet.hasProperty(KnownProperties.sortNo, b.properties)
          ? PropertyValueSet.getInteger(KnownProperties.sortNo, b.properties)!
          : 99)
          ? -1
          : 1
      );

    const firstOpcComponent = opcComponents[0];

    if (firstOpcComponent) {
      caseSettings = firstOpcComponent.properties;
    }

    const binCases = system.operatingCases
      .filter(oc => oc.caseType === CaseTypeEnum.CaseType.Bin)
      .map(oc => ({
        ...oc,
        settings: PropertyValueSet.fromString(oc.settings || "")
      }))
      .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1));
    const firstBinCase = binCases[0];
    if (firstBinCase) {
      caseSettings = PropertyValueSet.merge(
        firstBinCase.settings,
        caseSettings
      );
    }

    const metaDataProperties = PropertyValueSet.getPropertyNames(
      caseSettings
    ).filter(p => p.startsWith("_source") || p.startsWith("acc_"));
    caseSettings = PropertyValueSet.removeProperties(
      metaDataProperties,
      caseSettings
    );
  }

  return caseSettings;
}

export function getDefaultEnergyEmissionProperties(
  binSelections: PropertyValueSet.PropertyValueSet,
  defaultElectricEmissionFactor: number | undefined
): PropertyValueSet.PropertyValueSet {
  const getEmissionFactorPropertyValue = (
    propertyName: string,
    defaultAmountValue: Amount.Amount<unknown>
  ) => {
    let emissionFactor =
      PropertyValueSet.get(propertyName, binSelections) ??
      PropertyValue.fromAmount(defaultAmountValue);
    return emissionFactor;
  };

  let properties = PropertyValueSet.Empty;

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionElectric,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionElectric,
      Amount.create(
        defaultElectricEmissionFactor ?? 10,
        Units.GramPerKiloWattHour
      )
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionGas,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionGas,
      Amount.create(50.17, Units.GramPerMegaJoule)
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionSteam,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionSteam,
      Amount.create(62.72, Units.GramPerMegaJoule)
    ),
    properties
  );

  return properties;
}

export function getSettings(
  energySettings: ReadonlyArray<SharedState.EnergySetting>,
  system: System.System
) {
  const energyUserSettings = buildSettingsDictionary(energySettings);

  const climateSettings = system.climateSettings || "";

  return {
    energyUserSettings,
    climateSettings
  };
}

export function getCostEnergyItems(
  energyItems: ReadonlyArray<
    GraphQlTypes.MCompareInputQueryProduct["product"]["systemType"]["energyTotals"][0]
  >
): ReadonlyArray<Types.EnergyItem> {
  if (!energyItems.length) {
    return [];
  }

  const noCostTypes = [
    ...energyItems.filter(
      e => e.costType !== GraphQlTypes.EnergyCostType.NO_COST
    )
  ]
    .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
    .reduce(
      (
        a: Array<
          GraphQlTypes.MCompareInputQueryProduct["product"]["systemType"]["energyTotals"][0]
        >,
        b
      ) => {
        if (!a.some(s => s.costType === b.costType)) {
          a.push(b);
        }
        return a;
      },
      []
    )
    .reduce((a: Array<Types.EnergyItem>, b) => {
      a.push({
        name: formatCostType(b.costType),
        defaultAmount: Amount.create(
          0.1,
          Format.getUnitsForQuantity(b.quantityId as Quantity.Quantity)[0]
        ),
        cost: true
      });
      a.push({
        name: formatCostType(b.costType) + KnownProperties.priceInc,
        defaultAmount: Amount.create(0, Units.One)
      });
      return a;
    }, []);

  return noCostTypes;
}

export function getStaticEnergyItems(): ReadonlyArray<Types.EnergyItem> {
  return [
    {
      name: KnownProperties.maintenanceCost,
      defaultAmount: Amount.create(0, Units.One),
      cost: true
    },
    {
      name: KnownProperties.maintenanceCostPriceInc,
      defaultAmount: Amount.create(0, Units.One)
    },
    {
      name: KnownProperties.disposalCost,
      defaultAmount: Amount.create(0, Units.One),
      cost: true
    },
    {
      name: KnownProperties.lccYears,
      defaultAmount: Amount.create(20, Units.Year)
    },
    {
      name: KnownProperties.systemPrice,
      defaultAmount: Amount.create(0, Units.One),
      cost: true
    },
    {
      name: KnownProperties.interest,
      defaultAmount: Amount.create(0, Units.One)
    }
  ];
}

export function getEnergyPriceDefaultProperties(
  energyItems: ReadonlyArray<Types.EnergyItem>,
  systemTypeId: string,
  userEnergySettings: { readonly [key: string]: string },
  binSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let properties = PropertyValueSet.Empty;

  const userSettings = PropertyValueSet.fromString(
    userEnergySettings[KnownProperties.energyPriceInput + systemTypeId] || ""
  );

  return energyItems.reduce((a, b) => {
    const fromBin = PropertyValueSet.get(b.name, binSelections);
    if (fromBin !== undefined) {
      return PropertyValueSet.set(b.name, fromBin, a);
    }

    const userSetting = PropertyValueSet.get(b.name, userSettings);

    return PropertyValueSet.set(
      b.name,
      userSetting ? userSetting : PropertyValue.fromAmount(b.defaultAmount),
      a
    );
  }, properties);
}

export function getOperationTime(
  binSelection: PropertyValueSet.PropertyValueSet
): OperationTimeGen2.OperationTime {
  const parse = (time: string) =>
    PropertyValueSet.getText(time, binSelection)!
      .split("")
      .reduce(
        (a, b, ix) => ({ ...a, [ix + 1]: b === "1" }),
        {} as OperationTimeGen2.Values
      );

  if (!PropertyValueSet.isEmpty(binSelection)) {
    const settings = PropertyValueSet.getText(
      KnownProperties.binOperationTime,
      binSelection
    );
    if (settings && settings !== "custom") {
      return getOperationTimePreset(settings);
    }
    return {
      months: parse(KnownProperties.binMonths),
      monday: parse(KnownProperties.binMonday),
      tuesday: parse(KnownProperties.binTuesday),
      wednesday: parse(KnownProperties.binWednesday),
      thursday: parse(KnownProperties.binThursday),
      friday: parse(KnownProperties.binFriday),
      saturday: parse(KnownProperties.binSaturday),
      sunday: parse(KnownProperties.binSunday)
    };
  }

  return OperationTimeGen2.presets.allDay;
}

export function getDataType(
  binSelection: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet
): Types.DataType {
  return (PropertyValueSet.getText(
    KnownProperties.climateCoolingDataType,
    binSelection
  ) ||
    PropertyValueSet.getText(
      KnownProperties.climateCoolingDataType,
      climateSettings
    ))! as Types.DataType;
}

export function getBinSize(
  binSelection: PropertyValueSet.PropertyValueSet,
  userEnergySettings?: { readonly [key: string]: string }
): Types.BinSize {
  return (
    (PropertyValueSet.getInteger(
      KnownProperties.binSize,
      binSelection
    ) as Types.BinSize) ||
    (userEnergySettings &&
      userEnergySettings[KnownProperties.binSize] &&
      // tslint:disable-next-line:radix
      (parseInt(userEnergySettings[KnownProperties.binSize]) as number)) ||
    2
  );
}

export function getBinCasesFromSystem(
  system: System.System
): ReadonlyArray<SharedEnergyTools.BinCase> | undefined {
  const binCases = system.operatingCases.filter(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );

  if (binCases.length === 0) {
    return undefined;
  }

  return binCases.map(bin => {
    const pvs = PropertyValueSet.fromString(bin.binData || "");
    const midPointTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
      KnownProperties.airMidPointTemperature,
      pvs
    );
    const midPointWetTemp = PropertyValueSet.getAmount<Quantity.WetTemperature>(
      KnownProperties.airMidPointWetTemperature,
      pvs
    );
    const midPointDewPointTemp =
      PropertyValueSet.getAmount<Quantity.DewPointTemperature>(
        KnownProperties.airMidPointDewPointTemperature,
        pvs
      );

    const midPointSpecificEnthalpy =
      PropertyValueSet.getAmount<Quantity.SpecificEnthalpy>(
        KnownProperties.airMidPointSpecificEnthalpy,
        pvs
      );
    return {
      binId: PropertyValueSet.getInteger(KnownProperties.id, pvs)!,
      midPointTemp: midPointTemp,
      midPointWetTemp: midPointWetTemp,
      midPointDewPointTemp: midPointDewPointTemp,
      midPointSpecificEnthalpy: midPointSpecificEnthalpy,
      binTime: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.time,
        pvs
      )!,
      averageTemperature: PropertyValueSet.getAmount<Quantity.Temperature>(
        KnownProperties.airTemperature,
        pvs
      )!,
      averageHumidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        KnownProperties.airHumidity,
        pvs
      )!,
      windSpeed: PropertyValueSet.getAmount<Quantity.Velocity>(
        KnownProperties.wind,
        pvs
      )!,
      binPressure: PropertyValueSet.getAmount<Quantity.Pressure>(
        KnownProperties.binPressure,
        pvs
      )!
    } as SharedEnergyTools.BinCase;
  });
}

export function isMonthlyPossible(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): boolean {
  return binCases.every(
    br =>
      br.binTimeJanuary !== undefined &&
      br.binTimeFebruary !== undefined &&
      br.binTimeMarch !== undefined &&
      br.binTimeApril !== undefined &&
      br.binTimeMay !== undefined &&
      br.binTimeJune !== undefined &&
      br.binTimeJuly !== undefined &&
      br.binTimeAugust !== undefined &&
      br.binTimeSeptember !== undefined &&
      br.binTimeOctober !== undefined &&
      br.binTimeNovember !== undefined &&
      br.binTimeDecember !== undefined
  );
}

export function getBinType(
  binSelection: PropertyValueSet.PropertyValueSet
): Types.BinType {
  return (
    (PropertyValueSet.getText(
      KnownProperties.binType,
      binSelection
    ) as Types.BinType) || "Generated"
  );
}

function buildSettingsDictionary(
  energySettings: ReadonlyArray<{
    readonly settingName: string;
    readonly settingValue: string;
  }>
): { readonly [key: string]: string } {
  const dictionary = energySettings.reduce(
    // tslint:disable-next-line
    (a: { [key: string]: string }, b) => {
      a[b.settingName.toLowerCase()] = b.settingValue;
      return a;
    },
    {}
  );

  return { ...dictionary };
}

function getOperationTimePreset(name: string): OperationTimeGen2.OperationTime {
  const map: {
    readonly [key: string]: OperationTimeGen2.OperationTime;
  } = {
    allday: OperationTimeGen2.presets.allDay,
    factory: OperationTimeGen2.presets.factoryHours,
    office: OperationTimeGen2.presets.officeHours,
    twoshift: OperationTimeGen2.presets.twoShiftHours
  };

  return map[name];
}

function formatCostType(cost: string): string {
  return cost.replace(
    /([A-Z]+)_?/g,
    (_, g1) => g1[0].toUpperCase() + g1.substr(1).toLowerCase()
  );
}

// tslint:disable-next-line
