import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import {
  State as SharedState,
  Action as SharedStateAction
} from "../../shared-state";
import * as PropertiesSelector from "../../properties-selector";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { getInitialInputPvs } from "./product-data/product-data";
import {
  CalculateDryCapUserQuery,
  CalculateDryCapUserQueryVariables
} from "../../graphql-types";
import { calculateQuery } from "./queries";
import { PropertyValueSet } from "@promaster-sdk/property";
import {
  getDryCapModelEnumValue,
  getRelativeAndDewPointHumidity,
  getInputPvsWithUpdatedHumidities
} from "./functions";
import { PropertyValue } from "@genesys/property";
import {
  model,
  processInletHumidity,
  processInletPressure,
  processInletTemperature,
  processOutletHumidity,
  processOutletPressure,
  processOutletTemperature,
  processOutletRelativeHumidity,
  processOutletDewPointTemperature
} from "./product-data/known-properties";
import { Quantity } from "uom-units";

export type State = {
  readonly isLoading: boolean;
  readonly hasCalculationFailed: boolean;
  readonly inputSelectorState: PropertiesSelector.State | undefined;
  readonly resultSelectorState: PropertiesSelector.State | undefined;
  readonly summarySelectorState: PropertiesSelector.State | undefined;
};

export const init = (
  sharedState: SharedState
): readonly [State, Cmd<Action>?, SharedStateAction?] => {
  const initalState = {
    isLoading: false,
    hasCalculationFailed: false,
    inputSelectorState: undefined,
    resultSelectorState: undefined,
    summarySelectorState: undefined
  };

  const canSeeDryCapClaims = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.canSeeDryCap
  );

  if (canSeeDryCapClaims === undefined) {
    return [initalState];
  }

  const propertiesSelectorState = PropertiesSelector.init(
    getInitialInputPvs(canSeeDryCapClaims)
  );

  return [
    {
      ...initalState,
      inputSelectorState: propertiesSelectorState
    }
  ];
};

export const Action = ctorsUnion({
  dispatchInputSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchResultSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchSummarySelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  calculate: () => ({}),
  parseResult: (result: CalculateDryCapUserQuery) => ({ result })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedStateAction | undefined>?
] {
  switch (action.type) {
    case "dispatchInputSelector": {
      if (state.inputSelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.inputSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      const effectiveSelectorState =
        action.action.type === "updateProperties"
          ? PropertiesSelector.init(
              getInputPvsWithUpdatedHumidities(
                action.action.changedPropertyNames[0],
                PropertiesSelector.getSelectedProperties(
                  propertiesSelectorState
                )
              )
            )
          : propertiesSelectorState;

      const hasInputValuesChanged = action.action.type === "updateProperties";

      const resultSelectorState = hasInputValuesChanged
        ? undefined
        : state.resultSelectorState;
      const summarySelectorState = hasInputValuesChanged
        ? undefined
        : state.summarySelectorState;

      return [
        {
          ...state,
          inputSelectorState: effectiveSelectorState,
          resultSelectorState: resultSelectorState,
          summarySelectorState: summarySelectorState
        },
        Cmd.map(Action.dispatchInputSelector, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dispatchResultSelector": {
      if (state.resultSelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.resultSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          resultSelectorState: propertiesSelectorState
        },
        Cmd.map(Action.dispatchInputSelector, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dispatchSummarySelector": {
      if (state.summarySelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.summarySelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          summarySelectorState: propertiesSelectorState
        },
        Cmd.map(Action.dispatchSummarySelector, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "calculate": {
      if (state.inputSelectorState === undefined) {
        return [state];
      }

      const inputPvs = PropertiesSelector.getSelectedProperties(
        state.inputSelectorState
      );

      const getModelValue = () =>
        getDryCapModelEnumValue(PropertyValueSet.getInteger(model, inputPvs)!);

      const getPvString = (name: string) =>
        PropertyValue.toString(PropertyValueSet.getValue(name, inputPvs));

      return [
        { ...state, isLoading: true, resultSelectorState: undefined },
        sharedState.graphQL.queryUserCmd<
          CalculateDryCapUserQuery,
          CalculateDryCapUserQueryVariables,
          Action
        >(
          calculateQuery,
          {
            input: {
              model: getModelValue(),
              processInletHumidity: getPvString(processInletHumidity),
              processInletPressure: getPvString(processInletPressure),
              processInletTemperature: getPvString(processInletTemperature)
            }
          },
          Action.parseResult
        )
      ];
    }
    case "parseResult": {
      if (state.inputSelectorState === undefined) {
        return [state];
      }

      const results = action.result.user.calculateDryCap;

      if (results.hasResults) {
        const resultsPvs = Object.entries(results).reduce(
          (soFar, [propertyName, value]) => {
            const lowerCaseName = propertyName.toLocaleLowerCase();

            if (value === true || value === false) {
              // Its the hasResult value which we don´t care about at this stage
              return soFar;
            } else {
              return PropertyValueSet.set(
                lowerCaseName,
                PropertyValue.fromString(value)!,
                soFar
              );
            }
          },
          PropertyValueSet.Empty
        );

        const processOutPressure =
          PropertyValueSet.getAmount<Quantity.Pressure>(
            processOutletPressure,
            resultsPvs
          )!;

        const processOutTemperature =
          PropertyValueSet.getAmount<Quantity.Temperature>(
            processOutletTemperature,
            resultsPvs
          )!;

        const processOutHumidity =
          PropertyValueSet.getAmount<Quantity.HumidityRatio>(
            processOutletHumidity,
            resultsPvs
          )!;

        const { relativeHumidity, dewPointTemperature } =
          getRelativeAndDewPointHumidity(
            processOutPressure,
            processOutTemperature,
            processOutHumidity
          );

        const modifiedResultsPvs = PropertyValueSet.setAmount(
          processOutletRelativeHumidity,
          relativeHumidity,
          PropertyValueSet.setAmount(
            processOutletDewPointTemperature,
            dewPointTemperature,
            resultsPvs
          )
        );

        const resultSelectorState = PropertiesSelector.init(modifiedResultsPvs);
        const summarySelectorState = PropertiesSelector.init(
          PropertyValueSet.merge(
            PropertiesSelector.getSelectedProperties(state.inputSelectorState),
            modifiedResultsPvs
          )
        );

        return [
          {
            ...state,
            isLoading: false,
            hasCalculationFailed: false,
            resultSelectorState: resultSelectorState,
            summarySelectorState: summarySelectorState
          }
        ];
      } else {
        return [{ ...state, isLoading: false, hasCalculationFailed: true }];
      }
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
