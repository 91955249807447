import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { Amount, Quantity } from "@genesys/uom";

import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import { CaseType } from "./types";
import * as KnownProperties from "./known-properties";
import * as KnownUserSettings from "./known-user-settings";
import {
  climateDataTypeToInteger,
  ClimateDataType
} from "./climate-data-types";
import {
  annualOccurenceToInteger,
  ClimateAnnualOccurence
} from "./climate-annual-occurences";

function* rangeTo(numElements: number): IterableIterator<number> {
  for (let i = 0; i < numElements; ++i) {
    yield i;
  }
}

function* generatorMap<TIn, TOut>(
  generator: IterableIterator<TIn>,
  func: (element: TIn) => TOut
): IterableIterator<TOut> {
  for (const element of generator) {
    yield func(element);
  }
}

const makeOption = (i: number) => ({
  sort_no: i,
  value: {
    type: "integer" as "integer",
    value: i
  },
  property_filter: PropertyFilter.Empty
});

const makeValues = (numValues: number) =>
  Array.from(generatorMap(rangeTo(numValues), makeOption));

export const locationProductDefinitions = (
  numCountries: number,
  numRegionsInSelectedCountry: number,
  numLocationsInSelectedRegion: number
): Array<GenesysPropertiesSelectorTypes.Property> => {
  return [
    {
      sort_no: 0,
      name: KnownUserSettings.selectedClimateDataSourceIndex,
      group: "location",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: makeValues(2)
    },
    {
      sort_no: 1,
      name: KnownUserSettings.selectedCountryIndex,
      group: "location",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: makeValues(numCountries)
    },
    {
      sort_no: 2,
      name: KnownUserSettings.selectedRegionIndex,
      group: "location",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: makeValues(numRegionsInSelectedCountry)
    },
    {
      sort_no: 3,
      name: KnownProperties.locationIndex,
      group: "location",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: makeValues(numLocationsInSelectedRegion)
    },

    {
      sort_no: 4,
      name: KnownProperties.manualData.latitudeN,
      group: "location",
      quantity: "Text",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: []
    },
    {
      sort_no: 5,
      name: KnownProperties.manualData.longitudeW,
      group: "location",
      quantity: "Text",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: []
    },
    {
      sort_no: 6,
      name: KnownProperties.manualData.altitude,
      group: "location",
      quantity: "Length",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: []
    },
    {
      sort_no: 7,
      name: KnownProperties.manualData.atmosphericPressure,
      group: "location",
      quantity: "Pressure",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: []
    },
    {
      sort_no: 7,
      name: KnownProperties.manualData.customPressure,
      group: "location",
      quantity: "Discrete",
      selector_type: "Checkbox",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: makeValues(2)
    }
  ];
};

export const locationReadOnlyProperties = (
  selectedProperties: PropertyValueSet.PropertyValueSet,
  customAtmosphericPressure: boolean
) => [
  ...(PropertyFilter.isValid(
    selectedProperties,
    PropertyFilter.fromString(
      KnownUserSettings.selectedClimateDataSourceIndex + "=1"
    )!
  )
    ? [
        KnownUserSettings.selectedCountryIndex,
        KnownUserSettings.selectedRegionIndex,
        KnownProperties.locationIndex
      ]
    : [KnownProperties.manualData.altitude]),
  KnownProperties.manualData.latitudeN,
  KnownProperties.manualData.longitudeW,
  customAtmosphericPressure
    ? ""
    : KnownProperties.manualData.atmosphericPressure
];

export const caseProductDefinitions = (
  showWindVelocity: boolean,
  caseType: CaseType,
  dataTypes: ReadonlyArray<ClimateDataType>,
  annualOccurences: ReadonlyArray<ClimateAnnualOccurence>,
  temperature: Amount.Amount<Quantity.Temperature>,
  pressure: Amount.Amount<Quantity.Pressure>
): Array<GenesysPropertiesSelectorTypes.Property> => {
  return [
    {
      sort_no: 13,
      name:
        caseType === "cooling"
          ? KnownProperties.coolingDataTypeInteger
          : KnownProperties.heatingDataTypeInteger,
      group: caseType,
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: dataTypes.map(dataType =>
        makeOption(climateDataTypeToInteger(dataType))
      )
    },
    {
      sort_no: 14,
      name:
        caseType === "cooling"
          ? KnownProperties.coolingAnnualOccurenceInteger
          : KnownProperties.heatingAnnualOccurenceInteger,
      group: caseType,
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: annualOccurences.map(annualOccurence =>
        makeOption(annualOccurenceToInteger(annualOccurence))
      )
    },
    {
      sort_no: 15,
      name:
        caseType === "cooling"
          ? KnownProperties.manualData.summerTemperature
          : KnownProperties.manualData.winterTemperature,
      group: caseType,
      quantity: "Temperature",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: []
    },
    {
      sort_no: 16,
      name:
        caseType === "cooling"
          ? KnownProperties.manualData.summerHumidity
          : KnownProperties.manualData.winterHumidity,
      group: caseType,
      quantity: "HumidityRatio",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: [],
      conversionParameters: {
        temperature: temperature,
        pressure: pressure
      }
    },
    {
      sort_no: 17,
      name:
        caseType === "cooling"
          ? KnownProperties.manualData.summerWindSpeed
          : KnownProperties.manualData.winterWindSpeed,
      group: caseType,
      quantity: "Velocity",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: showWindVelocity
        ? PropertyFilter.Empty
        : PropertyFilter.fromString("1=0")!,
      value: []
    }
  ];
};

export const caseReadOnlyProperties = (
  caseType: CaseType,
  selectedProperties: PropertyValueSet.PropertyValueSet
) => [
  ...(PropertyFilter.isValid(
    selectedProperties,
    PropertyFilter.fromString(
      KnownUserSettings.selectedClimateDataSourceIndex + "=1"
    )!
  )
    ? [
        caseType === "cooling"
          ? KnownProperties.coolingDataTypeInteger
          : KnownProperties.heatingDataTypeInteger,
        caseType === "cooling"
          ? KnownProperties.coolingAnnualOccurenceInteger
          : KnownProperties.heatingAnnualOccurenceInteger
      ]
    : [
        caseType === "cooling"
          ? KnownProperties.manualData.summerTemperature
          : KnownProperties.manualData.winterTemperature,
        caseType === "cooling"
          ? KnownProperties.manualData.summerHumidity
          : KnownProperties.manualData.winterHumidity,
        caseType === "cooling"
          ? KnownProperties.manualData.summerWindSpeed
          : KnownProperties.manualData.winterWindSpeed
      ])
];
