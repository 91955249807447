import * as KnownProperties from "../known-properties";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { getIfIntegerChanged } from "../helper-functions";
import { OperatingCaseGroupEnum } from "../../../graphql-types";

export const manualInputProperty = KnownProperties.roomControlManualInput;

export const overridableProperties = [
  KnownProperties.rooomTemperatureControl,
  KnownProperties.roomMinTemperature,
  KnownProperties.roomMaxTemperature,
  KnownProperties.roomTemperature,
  KnownProperties.roomHumidityControl,
  KnownProperties.roomHumidityControlType,
  KnownProperties.roomHumidityAbsolute,
  KnownProperties.roomHumidityRelative,
  KnownProperties.roomHumidityDewPoint,
  KnownProperties.roomMinHumidityAbsolute,
  KnownProperties.roomMinHumidityRelative,
  KnownProperties.roomMinHumidityDewPoint,
  KnownProperties.roomMaxHumidityAbsolute,
  KnownProperties.roomMaxHumidityRelative,
  KnownProperties.roomMaxHumidityDewPoint
];

export const properties = [
  KnownProperties.roomControlManualInput,
  KnownProperties.rooomTemperatureControl,
  KnownProperties.roomMinTemperature,
  KnownProperties.roomMaxTemperature,
  KnownProperties.roomTemperature,
  KnownProperties.roomHumidityControl,
  KnownProperties.roomHumidityControlType,
  KnownProperties.roomHumidityAbsolute,
  KnownProperties.roomHumidityRelative,
  KnownProperties.roomHumidityDewPoint,
  KnownProperties.roomMinHumidityAbsolute,
  KnownProperties.roomMinHumidityRelative,
  KnownProperties.roomMinHumidityDewPoint,
  KnownProperties.roomMaxHumidityAbsolute,
  KnownProperties.roomMaxHumidityRelative,
  KnownProperties.roomMaxHumidityRelativeAdjusted,
  KnownProperties.roomMaxHumidityDewPoint
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;
  const temperatureControl = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.rooomTemperatureControl
  );
  if (temperatureControl !== undefined) {
    defaults = PropertyValueSet.merge(
      useRoomTemperatureControlDefaults(
        temperatureControl,
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  const humidityControl = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.roomHumidityControl
  );
  if (humidityControl !== undefined) {
    defaults = PropertyValueSet.merge(
      useRoomHumidityControlDefaults(humidityControl),
      defaults
    );
  }

  const humidityControlType = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.roomHumidityControlType
  );

  if (humidityControlType !== undefined) {
    defaults = PropertyValueSet.merge(
      useRoomHumidityControlTypeDefaults(
        humidityControlType,
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  return defaults;
}

function useRoomHumidityControlDefaults(
  propertyValue: number | undefined
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.roomHumidityControlType]: PropertyValue.fromInteger(0)
      };
    case 1:
    case 2:
    case 3:
      return {
        [KnownProperties.roomHumidityControlType]: PropertyValue.fromInteger(1)
      };
    case undefined:
      return PropertyValueSet.Empty;
    default:
      throw new Error("Invalid value.");
  }
}
function useRoomTemperatureControlDefaults(
  propertyValue: number | undefined,
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 1:
    case 2:
    case 3:
      return {
        [KnownProperties.roomMaxHumidityRelativeAdjusted]:
          PropertyValue.fromInteger(0)
      };
    case 0:
      return {
        [KnownProperties.roomMaxHumidityRelativeAdjusted]:
          PropertyValueSet.getInteger(
            KnownProperties.roomHumidityControl,
            current
          ) === 2 &&
          PropertyValueSet.getInteger(
            KnownProperties.roomHumidityControlType,
            current
          ) === 2
            ? PropertyValue.fromInteger(1)
            : PropertyValue.fromInteger(0)
      };
    case undefined:
      return PropertyValueSet.Empty;
    default:
      throw new Error("Invalid value.");
  }
}

function useRoomHumidityControlTypeDefaults(
  propertyValue: number | undefined,
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
    case 3:
      return {
        [KnownProperties.roomMaxHumidityRelativeAdjusted]:
          PropertyValue.fromInteger(0)
      };

    case 2:
      return {
        [KnownProperties.roomMaxHumidityRelativeAdjusted]:
          PropertyValueSet.getInteger(
            KnownProperties.roomHumidityControl,
            current
          ) === 2
            ? PropertyValue.fromInteger(1)
            : PropertyValue.fromInteger(0)
      };

    case undefined:
      return PropertyValueSet.Empty;
    default:
      throw new Error("Invalid value.");
  }
}
