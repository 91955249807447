import * as React from "react";
import * as SharedState from "../shared-state";
import * as Routes from "../routes";
import { Dispatch } from "@typescript-tea/core";
import {
  MenuButton,
  MainLogoYellow,
  MainLogo,
  MainLogoGreen,
  MainLogoPurple
} from "@genesys/ui-elements";
import {
  MenuBarContainer,
  DropDownContainer,
  TopOuter,
  DropDownMenu,
  FixedContainer,
  FlexItemContainer,
  StyledUl
} from "./elements";
import { State, Action } from "./state";
import { clientConfig, Environment } from "../config";
import { getPermissions } from "./tools";
import { MenuItem, SubDropDownkMenuItem } from "./types";
import {
  getHeaderMenuItems,
  getMainMenuMenuItems,
  getHelpAndUserMenuItems
} from "./menu-items";

export const MainMenuView = ({
  dispatch,
  logoClicked,
  state,
  sharedState,
  activeRoute
}: {
  readonly dispatch: Dispatch<Action>;
  readonly logoClicked: () => void;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly activeRoute: Routes.MainLocation["type"];
}) => {
  const permissions = getPermissions(sharedState);
  const environmentStyle = environmentStyles[clientConfig.environment];

  const renderDropdownMenu = (
    label: string,
    isOpen: boolean,
    toggleDropdown: () => void,
    items: ReadonlyArray<SubDropDownkMenuItem>,
    routes: ReadonlyArray<Routes.MainLocation["type"]>
  ) => (
    <DropDownContainer
      onMouseEnter={toggleDropdown}
      onMouseLeave={toggleDropdown}
    >
      <MenuButton
        children={label}
        hasMenu
        isOpen={isOpen}
        fontSize="17px"
        isActive={routes.includes(activeRoute)}
      />
      {isOpen && (
        <DropDownMenu onClick={toggleDropdown}>
          {items
            .filter(x => x.isVisable)
            .map(item => (
              <a key={item.href} href={item.href}>
                <MenuButton
                  icon={item.icon}
                  fontSize="17px"
                  children={capitalizeFirstLetter(item.label)}
                  hasMenu={false}
                />
              </a>
            ))}
        </DropDownMenu>
      )}
    </DropDownContainer>
  );

  const renderMenuItems = (
    items: ReadonlyArray<MenuItem>,
    extraSpacing = false
  ) => (
    <StyledUl>
      {items
        .filter(x => x.isVisable)
        .map((item, index) => (
          <div
            key={index}
            style={{ marginRight: extraSpacing ? "15px" : undefined }}
          >
            {item.type === "link" && (
              <a
                href={item.href}
                target={item.openInNewTab ? "_blank" : undefined}
              >
                <MenuButton
                  children={capitalizeFirstLetter(item.label)}
                  hasMenu={false}
                  fontSize="17px"
                  isActive={item.routes.includes(activeRoute)}
                />
              </a>
            )}
            {item.type === "custom" && item.content}
            {item.type === "dropdown" &&
              renderDropdownMenu(
                item.label,
                item.isOpen,
                item.toggleDropdown,
                item.menuItems,
                item.routes
              )}
          </div>
        ))}
    </StyledUl>
  );

  const headerItems = getHeaderMenuItems(
    logoClicked,
    environmentStyle,
    sharedState,
    activeRoute
  );
  const mainMenuItems = getMainMenuMenuItems(
    sharedState,
    state,
    dispatch,
    permissions
  );

  const helpAndUserMenuItems = getHelpAndUserMenuItems(
    sharedState,
    activeRoute
  );

  const renderHelpAndUserMenu = () => (
    <ul
      style={{
        display: "flex",
        alignItems: "center",
        listStyleType: "none",
        margin: 0,
        padding: 0
      }}
    >
      {helpAndUserMenuItems.map(item => (
        <li key={item.name}>{item.type === "custom" && item.content}</li>
      ))}
    </ul>
  );

  return (
    <TopOuter>
      <FixedContainer>
        <MenuBarContainer>
          {renderMenuItems(headerItems)}
          <FlexItemContainer>
            {renderMenuItems(mainMenuItems, true)}
          </FlexItemContainer>
          {renderHelpAndUserMenu()}
        </MenuBarContainer>
        {clientConfig.environment !== "Production" && (
          <div
            style={{
              backgroundColor: environmentStyle.borderColor,
              height: "8px"
            }}
          />
        )}
      </FixedContainer>
    </TopOuter>
  );
};

type EnvironmentVariables = {
  readonly borderColor: string;
  readonly logo: JSX.Element;
};

const environmentStyles: { [key in Environment]: EnvironmentVariables } = {
  Localhost: { borderColor: "#A020F0", logo: <MainLogoPurple width="130px" /> },
  Develop: { borderColor: "#46D580", logo: <MainLogoGreen width="130px" /> },
  Staging: { borderColor: "#F0C530", logo: <MainLogoYellow width="130px" /> },
  Production: { borderColor: "", logo: <MainLogo width="130px" /> }
};

function capitalizeFirstLetter(str: string) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
