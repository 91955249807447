export enum SystemStatus {
  Created = 0,

  ConfigureSystemFailed = 5,
  ConfigureSystemSuccess = 10,

  AutoComponentsFailed = 15,
  AutoComponentsSuccess = 20,

  AutoCasesFailed = 22,
  AutoCasesSuccess = 23,

  ValidateConfigurationFailed = 25,
  ValidateConfigurationSuccess = 30,

  DesignCalculationFailed = 35,
  DesignCalculationSuccess = 40,

  ValidateDesignFailed = 45,
  ValidateDesignSuccess = 50,

  RatingCalculationFailed = 55,
  RatingCalculationSuccess = 60,

  GenerateCasingsFailed = 65,
  GenerateCasingsSuccess = 70,

  ValidateFinalFailed = 71,
  ValidateFinalSuccess = 72,

  SoundFailed = 73,
  SoundSuccess = 74,

  PostProcessingFailed = 77,
  PostProcessingSuccess = 78,

  PriceCalculationFailed = 79,
  PriceCalculationSuccess = 80,

  EnergyCalculationPending = 81,
  EnergyCalculationFailed = 82,
  EnergyCalculationSuccess = 83,

  ValidateMCompareFailed = 84,
  ValidateMCompareSuccess = 85,

  MCompareCalculationFailed = 86,
  MCompareCalculationSuccess = 87,

  LockFailed = 89,
  LockSuccess = 90,

  OrderFailed = 95,
  OrderSuccess = 100
}
