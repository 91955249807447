import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GQLCache from "gql-cache";
import {
  CachePatch,
  DeleteEntity,
  InvalidateField,
  UpdateField,
  InsertElement,
  RemoveEntityElement
} from "gql-cache-patch";
import * as GraphQLTypes from "./graphql-types";
import { cachePatchesKey } from "./types";

export function getCachePatchesFromResponse(
  response: any,
  objectToId: GQLCache.GetObjectId
): ReadonlyArray<CachePatch> {
  const serverCachePatches = extractCachePatches(response);
  const cachePatches = serverToClientCachePatches(
    serverCachePatches,
    objectToId
  );

  return cachePatches;
}
function extractCachePatches(
  response: any
): ReadonlyArray<GraphQLTypes.CachePatchType> {
  const extractedCachePatches: Array<GraphQLTypes.CachePatchType> = [];
  for (const rootFieldKey of Object.keys(response)) {
    const cachePatches = response[rootFieldKey][cachePatchesKey];
    if (cachePatches !== undefined && Array.isArray(cachePatches)) {
      extractedCachePatches.push(...cachePatches);
    }
  }

  return extractedCachePatches;
}

function serverToClientCachePatches(
  serverCachePatches: ReadonlyArray<GraphQLTypes.CachePatchType>,
  objectToId: GQLCache.GetObjectId
): ReadonlyArray<CachePatch> {
  return serverCachePatches.map(i => serverToClientInstruction(i, objectToId));
}

function serverToClientInstruction(
  serverInstruction: GraphQLTypes.CachePatchType,
  objectToId: GQLCache.GetObjectId
): CachePatch {
  switch (serverInstruction.__typename) {
    case "DeleteEntityCachePatchType": {
      const deleteEntityCachePatch: DeleteEntity = {
        type: "DeleteEntity",
        id: objectToId({
          id: serverInstruction.deletedId,
          __typename: serverInstruction.typename
        })!
      };
      return deleteEntityCachePatch;
    }
    case "InvalidateFieldCachePatchType": {
      const invalidateCachePatch: InvalidateField = {
        type: "InvalidateField",
        id: objectToId({
          id: serverInstruction.id || undefined,
          __typename: serverInstruction.typename
        })!,
        fieldName: serverInstruction.fieldName
      };
      return invalidateCachePatch;
    }
    case "UpdateFieldCachePatchType": {
      const updaterFieldCachePatch: UpdateField = {
        type: "UpdateField",
        id: objectToId({
          id: serverInstruction.id || undefined,
          __typename: serverInstruction.typename
        })!,
        fieldName: serverInstruction.fieldName,
        newValue: serverEntityFieldValueToEntityFieldValue(
          serverInstruction.entityFieldValue
        )
      };
      return updaterFieldCachePatch;
    }
    case "InsertElementCachePatchType": {
      const insertElementCachePatch: InsertElement = {
        type: "InsertElement",
        id: objectToId({
          id: serverInstruction.id || undefined,
          __typename: serverInstruction.typename
        })!,
        fieldName: serverInstruction.fieldName,
        index: serverInstruction.index,
        newValue: serverEntityFieldValueToEntityFieldValue(
          serverInstruction.entityFieldValue
        )
      };
      return insertElementCachePatch;
    }
    case "RemoveEntityElementCachePatchType": {
      const removeEntityElement: RemoveEntityElement = {
        type: "RemoveEntityElement",
        id: serverInstruction.id,
        fieldName: serverInstruction.fieldName,
        entityId: serverInstruction.entityId
      };
      return removeEntityElement;
    }
    default: {
      exhaustiveCheck(serverInstruction, true);
      throw new Error(
        `Unhandled Server CachePatch type: ${JSON.stringify(serverInstruction)}`
      );
    }
  }
}

function serverEntityFieldValueToEntityFieldValue(
  entityFieldValue: GraphQLTypes.EntityFieldValueType
): GQLCache.EntityFieldValue {
  if (entityFieldValue.stringValue !== null) {
    return entityFieldValue.stringValue!;
  }

  if (entityFieldValue.booleanValue !== null) {
    return entityFieldValue.booleanValue!;
  }

  if (entityFieldValue.intValue !== null) {
    return entityFieldValue.intValue!;
  }

  if (entityFieldValue.floatValue !== null) {
    return entityFieldValue.floatValue!;
  }

  if (entityFieldValue.entityFieldValueArray !== null) {
    return (entityFieldValue.entityFieldValueArray || []).map(
      serverEntityFieldValueToEntityFieldValue
    );
  }

  throw new Error(
    `Can't go here. Unknown field on entity fieldValue: ${JSON.stringify(
      entityFieldValue
    )}`
  );
}
