import { exhaustiveCheck } from "ts-exhaustive-check";
import { DryCapModel } from "../../graphql-types";
import { Quantity } from "uom-units";
import * as Calculations from "@munters/calculations";
import { Amount } from "@genesys/uom";
import {
  processInletHumidity,
  processInletPressure,
  processInletTemperature,
  processInletRelativeHumidity,
  processInletWetTemperature
} from "./product-data/known-properties";
import { PropertyValueSet } from "@promaster-sdk/property";

// I wish GraphQL enums were not text...
export function getDryCapModelIntValue(dryCapModel: DryCapModel): number {
  switch (dryCapModel) {
    case DryCapModel.M_90_L:
      return 0;
    case DryCapModel.M_120_L:
      return 1;
    case DryCapModel.M_160_L:
      return 2;
    case DryCapModel.M_170_L:
      return 3;
    case DryCapModel.M_190_Y:
      return 4;
    case DryCapModel.M_200_L:
      return 5;
    case DryCapModel.M_210_X:
      return 6;
    case DryCapModel.MC_150_X:
      return 7;
    case DryCapModel.MC_150_Y:
      return 8;
    case DryCapModel.MCS_300:
      return 9;
    case DryCapModel.MCS_300_C:
      return 10;
    case DryCapModel.MG_50:
      return 11;
    case DryCapModel.MG_90:
      return 12;
    case DryCapModel.MH_270:
      return 13;
    default:
      return exhaustiveCheck(dryCapModel, true);
  }
}

export function getDryCapModelEnumValue(dryCapModel: number): DryCapModel {
  switch (dryCapModel) {
    case 0:
      return DryCapModel.M_90_L;
    case 1:
      return DryCapModel.M_120_L;
    case 2:
      return DryCapModel.M_160_L;
    case 3:
      return DryCapModel.M_170_L;
    case 4:
      return DryCapModel.M_190_Y;
    case 5:
      return DryCapModel.M_200_L;
    case 6:
      return DryCapModel.M_210_X;
    case 7:
      return DryCapModel.MC_150_X;
    case 8:
      return DryCapModel.MC_150_Y;
    case 9:
      return DryCapModel.MCS_300;
    case 10:
      return DryCapModel.MCS_300_C;
    case 11:
      return DryCapModel.MG_50;
    case 12:
      return DryCapModel.MG_90;
    case 13:
      return DryCapModel.MH_270;
    default: {
      throw new Error("Unknown dryCapModel");
    }
  }
}

export function getRelativeAndDewPointHumidity(
  pressure: Amount.Amount<Quantity.Pressure>,
  temperature: Amount.Amount<Quantity.Temperature>,
  humidity: Amount.Amount<Quantity.HumidityRatio>
): {
  relativeHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  dewPointTemperature: Amount.Amount<Quantity.DewPointTemperature>;
} {
  const relativeHumidity =
    Calculations.Physics.RP1485.AmountConversions.humidityRatioToRelativeHumidity(
      pressure,
      temperature,
      humidity
    );

  const dewPointTemperature =
    Calculations.Physics.RP1485.AmountConversions.humidityRatioToDewPointTemperature(
      pressure,
      humidity
    );

  return { relativeHumidity, dewPointTemperature };
}

export function getInputPvsWithUpdatedHumidities(
  changedProperty: string,
  pvs: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const reference =
    changedProperty === processInletHumidity
      ? "humidity-ratio"
      : changedProperty === processInletRelativeHumidity
      ? "relative-humidity"
      : changedProperty === processInletWetTemperature
      ? "dew-point-temperature"
      : "temperature";

  const pressureInput = PropertyValueSet.getAmount<Quantity.Pressure>(
    processInletPressure,
    pvs
  )!;
  const temperatureInput = PropertyValueSet.getAmount<Quantity.Temperature>(
    processInletTemperature,
    pvs
  )!;
  const humidityRatioInput = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    processInletHumidity,
    pvs
  )!;
  const relativeHumidityInput =
    PropertyValueSet.getAmount<Quantity.RelativeHumidity>(
      processInletRelativeHumidity,
      pvs
    )!;
  const wetTemperatureInput =
    PropertyValueSet.getAmount<Quantity.WetTemperature>(
      processInletWetTemperature,
      pvs
    )!;

  const { humidityRatio, relativeHumidity, wetTemperature } =
    getSynchronizedHumidities(
      reference,
      humidityRatioInput,
      relativeHumidityInput,
      wetTemperatureInput,
      pressureInput,
      temperatureInput
    );

  return PropertyValueSet.setAmount(
    processInletHumidity,
    humidityRatio,
    PropertyValueSet.setAmount(
      processInletRelativeHumidity,
      relativeHumidity,
      PropertyValueSet.setAmount(
        processInletWetTemperature,
        wetTemperature,
        pvs
      )
    )
  );
}

function getSynchronizedHumidities(
  reference:
    | "humidity-ratio"
    | "relative-humidity"
    | "dew-point-temperature"
    | "temperature",
  humidityRatioInput: Amount.Amount<Quantity.HumidityRatio>,
  relativeHumidityInput: Amount.Amount<Quantity.RelativeHumidity>,
  wetTemperatureInput: Amount.Amount<Quantity.WetTemperature>,
  pressure: Amount.Amount<Quantity.Pressure>,
  temperature: Amount.Amount<Quantity.Temperature>
): {
  humidityRatio: Amount.Amount<Quantity.HumidityRatio>;
  relativeHumidity: Amount.Amount<Quantity.RelativeHumidity>;
  wetTemperature: Amount.Amount<Quantity.WetTemperature>;
} {
  switch (reference) {
    case "humidity-ratio": {
      const relativeHumidity =
        Calculations.Physics.RP1485.AmountConversions.humidityRatioToRelativeHumidity(
          pressure,
          temperature,
          humidityRatioInput
        );

      const wetTemperature =
        Calculations.Physics.RP1485.AmountConversions.humidityRatioToWetTemperature(
          pressure,
          temperature,
          humidityRatioInput
        );

      return {
        humidityRatio: humidityRatioInput,
        relativeHumidity: relativeHumidity,
        wetTemperature: wetTemperature
      };
    }
    case "relative-humidity": {
      const humidityRatio =
        Calculations.Physics.RP1485.AmountConversions.relativeHumidityToHumidityRatio(
          pressure,
          temperature,
          relativeHumidityInput
        );
      const wetTemperature =
        Calculations.Physics.RP1485.AmountConversions.relativeHumidityToWetTemperature(
          pressure,
          temperature,
          relativeHumidityInput
        );
      return {
        humidityRatio: humidityRatio,
        relativeHumidity: relativeHumidityInput,
        wetTemperature: wetTemperature
      };
    }
    case "dew-point-temperature": {
      const humidityRatio =
        Calculations.Physics.RP1485.AmountConversions.wetTemperatureToHumidityRatio(
          pressure,
          temperature,
          wetTemperatureInput
        );
      const relativeHumidity =
        Calculations.Physics.RP1485.AmountConversions.wetTemperatureToRelativeHumidity(
          pressure,
          temperature,
          wetTemperatureInput
        );
      return {
        humidityRatio: humidityRatio,
        relativeHumidity: relativeHumidity,
        wetTemperature: wetTemperatureInput
      };
    }
    case "temperature": {
      const relativeHumidity =
        Calculations.Physics.RP1485.AmountConversions.humidityRatioToRelativeHumidity(
          pressure,
          temperature,
          humidityRatioInput
        );

      const wetTemperature =
        Calculations.Physics.RP1485.AmountConversions.humidityRatioToWetTemperature(
          pressure,
          temperature,
          humidityRatioInput
        );

      return {
        humidityRatio: humidityRatioInput,
        relativeHumidity: relativeHumidity,
        wetTemperature: wetTemperature
      };
    }
    default:
      return exhaustiveCheck(reference, true);
  }
}
