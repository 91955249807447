import { PropertyFilter } from "@genesys/property";
import * as PropertiesSelector from "../react-properties-selector";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface State {
  readonly [selectorId: string]: Settings;
}

export interface Settings {
  readonly closedGroups: ClosedGroups;
  readonly activatedQuantitySelectors: ActivatedQuantitySelectors;
}

export interface ClosedGroups {
  readonly [groupName: string]: true;
}

export interface ActivatedQuantitySelectors {
  readonly [propertyName: string]: true;
}

export type TranslatePropertyName = (
  propertyName: string,
  translate: LanguageTexts.Translate
) => string;

export type TranslatePropertyValue = (
  propertyName: string,
  propertyValue: number,
  translate: LanguageTexts.Translate
) => string;
export interface CustomTranslation {
  readonly propertyName?: TranslatePropertyName;
  readonly propertyValue?: TranslatePropertyValue;
  // readonly groupName: (groupName: string) => string;
}

export interface GenesysProperty {
  readonly conversionParameters?: QuantityConversion.ConversionParameters;
  readonly fieldName?: string;
}

export type Property = GenesysProperty & PropertiesSelector.Property;

export interface RenderProperty {
  readonly sortNo: number;
  readonly propertyName: string;
  readonly fieldName: string;
  readonly group: string;
}

export const NO_GROUP = "NO_GROUP";
export interface RenderGroup {
  readonly key: string;
  readonly translatedName: string;
  readonly isCollapsed: boolean;
  readonly properties: ReadonlyArray<RenderProperty>;
  readonly onToggle: () => void;
}
export interface RenderSection {
  readonly key: string;
  readonly groups: ReadonlyArray<RenderGroup>;
}

export interface LayoutRendererExtraProps {
  readonly uniqueId: string;
  readonly productProperties: ReadonlyArray<Property>;
  readonly propertyFormats: PropertiesSelector.PropertyFormats;
  readonly hidePropertyNames: boolean;
  readonly sections: ReadonlyArray<RenderSection>;
  readonly onDelete: () => void;
  readonly fieldGroup: string;
  readonly showDelete: boolean;
}

export interface ValueSource {
  readonly value: number;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly propertyValueSourceId: string;
}
