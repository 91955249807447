import { exhaustiveCheck } from "ts-exhaustive-check";
import { presets } from "./predefined-presets";
import * as SharedState from "../shared-state";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

export type State = OperationTime | undefined;

export type Values = {
  readonly [row: string]: boolean;
};

export interface OperationTime {
  readonly months: Values;
  readonly monday: Values;
  readonly tuesday: Values;
  readonly wednesday: Values;
  readonly thursday: Values;
  readonly friday: Values;
  readonly saturday: Values;
  readonly sunday: Values;
}

export interface Label {
  readonly name: string;
  readonly id: string;
}

export const init = (initial?: OperationTime): [State] => {
  return [initial || presets.officeHours];
};

export const Action = ctorsUnion({
  setOperationTime: (operationTime: OperationTime) => ({ operationTime }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "setOperationTime": {
      return [action.operationTime];
    }

    case "dummy": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
