import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../../../graphql-types";
import { Cmd } from "@typescript-tea/core";
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { clientConfig } from "../config";
import { promiseCmd } from "../promise-effect-manager";
import { SetUserTermsAndConditionsStatus } from "@genesys/client-core/lib/graphql-mutations";
import { UserManager } from "oidc-client";

interface BlobSuccess {
  readonly success: true;
  readonly flieBlob: Blob;
}

interface BlobFailed {
  readonly success: false;
}

export type BlobResponse = BlobFailed | BlobSuccess;

export interface State {
  readonly blobResponse: BlobResponse | undefined;
  readonly processing: boolean;
}

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const routeEndpoint =
    clientConfig.genesysBackend + "/internal/terms-and-conditions/get-latest";
  return [
    { blobResponse: undefined, processing: false },
    promiseCmd(async () => {
      const response = await fetch(routeEndpoint, {
        headers: {
          Authorization: `Bearer ${sharedState.accessToken}`
        }
      });

      if (response.ok) {
        const blob = await response.blob();

        return { success: true, flieBlob: blob };
      }
      return { success: false };
    }, Action.fileBlobRecieved)
  ];
};

export const Action = ctorsUnion({
  fileBlobRecieved: (res: BlobResponse) => ({ res }),
  termsAccepted: () => ({}),
  termsDeclined: () => ({}),
  mutationComplete: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

type TermsAccepted = Boolean;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  TermsAccepted,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action>?
] {
  switch (action.type) {
    case "fileBlobRecieved": {
      return [
        {
          ...state,
          blobResponse: action.res
        },
        false
      ];
    }

    case "termsDeclined": {
      return [
        {
          ...state,
          processing: true,
          blobResponse: undefined
        },
        false,
        promiseCmd(
          async () => {
            const manager = new UserManager({
              authority: clientConfig.oidcAuthority,
              client_id: clientConfig.oidcClientId,
              redirect_uri: clientConfig.oidcRedirectUri,
              post_logout_redirect_uri: window.location.origin,
              response_type: clientConfig.oidcResponseType,
              scope: clientConfig.oidcScope,
              monitorSession: false,
              loadUserInfo: false
            });

            await sharedState.graphQL.queryUser<
              GraphQLTypes.SetUserTermsAndConditionsStatus,
              GraphQLTypes.SetUserTermsAndConditionsStatusVariables
            >(SetUserTermsAndConditionsStatus, {
              hasAccepted: false
            });

            await manager.signoutRedirect();

            return {};
          },
          () => undefined
        )
      ];
    }

    case "termsAccepted": {
      return [
        {
          ...state,
          blobResponse: undefined
        },
        false,
        promiseCmd<Action, {}>(async () => {
          await sharedState.graphQL.queryUser<
            GraphQLTypes.SetUserTermsAndConditionsStatus,
            GraphQLTypes.SetUserTermsAndConditionsStatusVariables
          >(SetUserTermsAndConditionsStatus, {
            hasAccepted: true
          });

          return {};
        }, Action.mutationComplete)
      ];
    }

    case "mutationComplete": {
      return [state, true];
    }
    default:
      exhaustiveCheck(action, true);
  }
}
