import * as Guid from "../guid";
import moment from "moment";

export type ExchangeRateTable = Array<ExchangeRateTableRow>;

export interface ExchangeRateTableRow {
  readonly currency: string;
  readonly exchangeRate: number;
  readonly exchangeRateTemplate: Guid.Guid;
  readonly validFrom: moment.Moment;
}

export function getTodaysRate(
  fromCurrency: string,
  toCurrency: string,
  exchangeRateTemplate: Guid.Guid,
  exchangeRateTable: ExchangeRateTable
): number {
  const now = moment();

  const template = exchangeRateTable.filter(t =>
    Guid.guidEquals(t.exchangeRateTemplate, exchangeRateTemplate)
  );
  if (!template) {
    throw new Error("No template found");
  }

  const fromRate = template
    .filter(t => t.currency === fromCurrency)
    .reduce(
      (a: ExchangeRateTableRow | undefined, b) =>
        a === undefined || (b.validFrom > a.validFrom && b.validFrom <= now)
          ? b
          : a,
      undefined
    );
  const toRate = template
    .filter(t => t.currency === toCurrency)
    .reduce(
      (a: ExchangeRateTableRow | undefined, b) =>
        a === undefined || (b.validFrom > a.validFrom && b.validFrom <= now)
          ? b
          : a,
      undefined
    );

  return fromRate!.exchangeRate / toRate!.exchangeRate;
}
