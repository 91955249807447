import * as React from "react";
import * as Redux from "redux";

// tslint:disable-next-line:variable-name
export type DispatchContextValue = Redux.Dispatch<any>;

// tslint:disable-next-line:variable-name
export const dispatchContext = React.createContext<DispatchContextValue>(
  (a: any) => a
);
