import * as React from "react";
import * as Sentry from "@sentry/browser";
import gql from "graphql-tag";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as GraphQLQuery from "../graphql-query";
import * as MutationsQueueUser from "../mutations-queue-user";
import * as Contexts from "../contexts";
import * as OptimisticCachePatch from "./optimistic-cache-instructions";

const userQuery = gql`
  query UserProfileProviderUserData {
    user {
      userProfile {
        id
        name
        userName
        lastLogin
        applicationClaims {
          id
          key
          context
          values
        }

        currencies {
          id
          name
          signBefore
          signAfter
        }

        userSettings {
          id
          culture {
            id
            locale {
              id
            }
            language {
              id
            }
            currency {
              id
            }
          }

          climate {
            id
            location {
              id
            }
          }

          wizardSettings {
            id
            lastCreatedSystemType
            lastSelectedLabels
          }

          sales {
            id
            defaultSalesOrganisation
          }
        }

        salesOrganisations {
          id
          name
        }
      }
    }
  }
`;

// tslint:disable-next-line:function-name
export function UserProfileProvider({
  children
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  return (
    <MutationsQueueUser.MutationsQueue>
      {({ mutationQueue, queueAndFlush }) => (
        <GraphQLQuery.QueryUserSimple<
          GraphQLTypes.UserProfileProviderUserData,
          GraphQLTypes.UserProfileProviderUserDataVariables
        >
          query={userQuery}
          variables={{}}
          mutationsQueue={mutationQueue}
        >
          {userData => {
            return (
              <UserProfileProviderInternal
                userData={userData!}
                children={children}
                queueAndFlush={queueAndFlush}
              />
            );
          }}
        </GraphQLQuery.QueryUserSimple>
      )}
    </MutationsQueueUser.MutationsQueue>
  );
}

interface UserProfileProviderProps {
  readonly userData: GraphQLTypes.UserProfileProviderUserData;
  readonly children: React.ReactNode;
  readonly queueAndFlush: MutationsQueueUser.QueueAndFlush;
}
interface UserProfileProviderState {
  readonly childProps: Contexts.UserProfileContextValue;
}

class UserProfileProviderInternal extends React.Component<
  UserProfileProviderProps,
  UserProfileProviderState
> {
  setCulture = (settings: {
    readonly currency?: string;
    readonly language?: string;
    readonly locale?: string;
  }) => {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: OptimisticCachePatch.setCultureQueuedMutation(
          settings,
          this.props.userData.user.userProfile.id
        )
      }
    ]);
  };

  setInitialSettings = (
    culture: {
      readonly currency: string;
      readonly language: string;
      readonly locale: string;
    },
    activeAmountProfileId: string,
    climate: {
      readonly countryName: string;
      readonly regionName: string;
      readonly locationId: string;
    }
  ) => {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: OptimisticCachePatch.setInitialSettings(
          culture,
          activeAmountProfileId,
          climate
        )
      }
    ]);
  };

  setDefaultSalesOrganisation = (
    defaultSalesOrganisationCode?: string | null
  ) => {
    const code: string | null =
      defaultSalesOrganisationCode !== undefined
        ? defaultSalesOrganisationCode
        : null;
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: OptimisticCachePatch.setDefaultSalesOrganisation(
          code,
          this.props.userData.user.userProfile.id
        )
      }
    ]);
  };

  resetUserSettings = () => {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: OptimisticCachePatch.resetUserSettings()
      }
    ]);
  };

  updateWizardSettings = (
    lastCreatedSystemType: string,
    lastSelectedLabels: ReadonlyArray<string>
  ) => {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: OptimisticCachePatch.updateWizardSettings(
          lastCreatedSystemType,
          lastSelectedLabels,
          this.props.userData.user.userProfile.id
        )
      }
    ]);
  };

  constructor(props: UserProfileProviderProps) {
    super(props);
    const { applicationClaims, currencies, name, userName, lastLogin } =
      props.userData.user.userProfile;
    this.state = {
      childProps: {
        name,
        userName,
        lastLogin,
        applicationClaims: Authorization.toObjectClaims(applicationClaims),
        currencies: currencies.map(c => ({
          id: c.id,
          name: c.name,
          signAfter: c.signAfter || undefined,
          signBefore: c.signBefore || undefined
        })),
        currency: "EUR",
        language: "en-GB",
        locale: "en-GB",
        lastCreatedSystemType: undefined,
        lastSelectedLabels: [],
        defaultSalesOrganisation: undefined,
        salesOrganisations: [],
        defaultLocationId: undefined,
        setCulture: this.setCulture,
        setInitialSettings: this.setInitialSettings,
        setDefaultSalesOrganisation: this.setDefaultSalesOrganisation,
        resetUserSettings: this.resetUserSettings,
        updateWizardSettings: this.updateWizardSettings
      }
    };
  }

  componentDidMount() {
    Sentry.setUser({
      username: this.state.childProps.userName
    });
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    nextProps: UserProfileProviderProps,
    prevState: UserProfileProviderState
  ): Partial<UserProfileProviderState> | null {
    const { salesOrganisations, userSettings } =
      nextProps.userData.user.userProfile;
    const { currency, language, locale } = userSettings.culture;
    const childProps: Contexts.UserProfileContextValue = {
      ...prevState.childProps,
      currency: (currency && currency.id) as string,
      language: (language && language.id) as string,
      locale: (locale && locale.id) as string,
      lastCreatedSystemType: valueOrUndefined(
        userSettings.wizardSettings.lastCreatedSystemType
      ),
      lastSelectedLabels: userSettings.wizardSettings.lastSelectedLabels,
      defaultSalesOrganisation: valueOrUndefined(
        userSettings.sales.defaultSalesOrganisation
      ),
      salesOrganisations: salesOrganisations,
      defaultLocationId:
        (userSettings.climate.location && userSettings.climate.location.id) ||
        undefined
    };

    return {
      childProps
    };
  }

  render() {
    return (
      <Contexts.UserProfileContext.Provider value={this.state.childProps}>
        {React.Children.only(this.props.children)}
      </Contexts.UserProfileContext.Provider>
    );
  }
}

function valueOrUndefined<T>(value: T | null): T | undefined {
  if (!value) {
    return undefined;
  }

  return value;
}
