import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { StandardButton, Spinner, P1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as SharedState from "../../../shared-state";
import * as System from "../../system";
import * as Product from "../../product";
import { State, Action } from "./state";
import { fluidCoilCodes } from "./fluid-coils";
import {
  Root,
  CenteringContainer,
  TopContainer,
  NotLockedSystemBox
} from "./elements";
import {
  OperatingCasesView,
  PerformanceView,
  FluidCoilsView,
  Navigation
} from "./components";

export function LockedSystemCalculationView({
  state,
  system,
  systemId,
  opcProduct,
  productData,
  sharedState,
  sysProperties,
  dispatch
}: {
  readonly state: State;
  readonly systemId: string;
  readonly system: System.System;
  readonly opcProduct: Product.Product;
  readonly sharedState: SharedState.State;
  readonly productData: Product.ProductData;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const isSystemLocked = system.status >= SystemStatus.LockSuccess;

  if (!isSystemLocked) {
    return (
      <NotLockedSystemBox>
        <P1 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.serviceCalculationNotLocked())}
        </P1>
      </NotLockedSystemBox>
    );
  }

  const showFluidCoils =
    system.components.find(component =>
      fluidCoilCodes.includes(component.productId.slice(-3))
    ) !== undefined;

  const fluidCoilsProductData: ReadonlyArray<Product.Product> =
    productData.products.filter(p => fluidCoilCodes.includes(p.id.slice(-3)));

  return (
    <Root>
      <TopContainer>
        <Navigation
          state={state}
          sharedState={sharedState}
          showFluidCoils={showFluidCoils}
          dispatch={dispatch}
        />
        <StandardButton
          size="Large"
          buttonType="Primary-2"
          disabled={state.isLoading}
          onClick={() => dispatch(Action.calculate(systemId))}
        >
          {sharedState.translate(LanguageTexts.calculate())}
        </StandardButton>
      </TopContainer>

      {state.isLoading && (
        <CenteringContainer>
          <Spinner />
        </CenteringContainer>
      )}

      {!state.isLoading && (
        <div>
          {state.selectedView === "operating-cases" && (
            <OperatingCasesView
              opcProduct={opcProduct}
              sharedState={sharedState}
              sysProperties={sysProperties}
              operatingCaseSelectorState={state.operatingCaseSelectorState}
              dispatch={dispatch}
            />
          )}

          {state.selectedView === "fluid-coils" && (
            <FluidCoilsView
              state={state}
              sharedState={sharedState}
              sysProperties={sysProperties}
              productsData={fluidCoilsProductData}
              dispatch={dispatch}
            />
          )}

          {state.selectedView === "performance" && (
            <PerformanceView
              state={state}
              system={system}
              productData={productData}
              sharedState={sharedState}
              sysProperties={sysProperties}
              dispatch={dispatch}
            />
          )}
        </div>
      )}
    </Root>
  );
}
