import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0 5px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  th {
    height: 30px;
    text-align: left;
    a {
      cursor: pointer;
    }
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 40px;
    a {
      cursor: pointer;
    }
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;

export const CheckBoxContainer = styled.div`
  margin-top: -10px;
`;

export const TableBody = styled.tbody`
  border: ${(props: { readonly isRevisionsExpanded: boolean }) =>
    props.isRevisionsExpanded ? "1px solid black" : "1px solid white"};
  tr:first-child {
    td {
      border-top: ${(props: { readonly isRevisionsExpanded: boolean }) =>
        props.isRevisionsExpanded ? "1px solid black" : "none"};
    }
  }
  tr:last-child {
    td {
      border-bottom: ${(props: { readonly isRevisionsExpanded: boolean }) =>
        props.isRevisionsExpanded ? "1px solid black" : "none"};
    }
  }
  tr {
    td:first-child {
      border-left: ${(props: { readonly isRevisionsExpanded: boolean }) =>
        props.isRevisionsExpanded ? "1px solid black" : "none"};
    }

    td:last-child {
      border-right: ${(props: { readonly isRevisionsExpanded: boolean }) =>
        props.isRevisionsExpanded ? "1px solid black" : "none"};
    }
  }

  td {
    position: relative;
  }
`;

export const StyledRow = styled.tr`
  background-color: ${(props: { readonly isActionsOpen: boolean }) =>
    props.isActionsOpen ? PrimaryColors.blue25 : ""};
`;
