// import styled from "styled-components";
import * as React from "react";

// const crossColor = "rgb(102, 102, 102)";

// tslint:disable-next-line
/* export const FatCrossCloseButton = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  opacity: 0.8;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: ${crossColor};
  }

  &:before {
    left: 55%;
    width: 20%;
    margin-left: -12%;
    height: 100%;
  }

  &:after {
    top: 55%;
    height: 20%;
    margin-top: -17%;
    width: 100%;
    margin-left: -47%;
  }

  &:hover {
    opacity: 1;
  }
`; */
// tslint:disable-next-line
export const FatCrossCloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      onClick={onClick}
      width="15"
      height="15"
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="5" x2="90" y1="5" y2="95" stroke="#939393" strokeWidth="20" />
      <line x1="5" x2="90" y1="95" y2="5" stroke="#939393" strokeWidth="20" />
    </svg>
  );
};
