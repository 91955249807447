import * as ComponentOrder from "@genesys/shared/lib/component-order";
import { PropertyValueSet } from "@genesys/property";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as Product from "../../product";
import * as System from "../../system";
import * as Accessories from "../accessories";
import { SystemAccessory } from "./types";

export function getInitialSystemAccessories(
  system: System.System,
  products: ReadonlyArray<Product.Product>
): ReadonlyArray<SystemAccessory> {
  const sysProperties = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const allProducts = ProductData.getValidProductsForRange(
    products,
    sysProperties
  ).filter(p => ProductData.filterProductForRange(p, sysProperties));

  const componentsThatCouldHaveAccessories = system.components.filter(
    c => (PropertyValueSet.toString(c.properties) || "").indexOf("acc_") > -1
  );

  const connectionPoints = allProducts.reduce(
    (soFar: ReadonlyArray<Product.BoxConnectionPoint>, product) =>
      soFar.concat(product.boxConnectionPoints),
    []
  );

  const sortedComponentsThatCouldHaveAccessories = sortComponents(
    system.components,
    componentsThatCouldHaveAccessories,
    system.airstreams,
    connectionPoints
  );

  const systemAccessories = sortedComponentsThatCouldHaveAccessories.map(c => {
    const [accessoriesState] = Accessories.init(c, system, products);
    return { parentComponent: c, state: accessoriesState };
  });

  return systemAccessories;
}

function sortComponents(
  allComponents: ReadonlyArray<System.Component>,
  componentsThatCouldHaveAccessories: ReadonlyArray<System.Component>,
  airstreams: ReadonlyArray<System.Airstream>,
  connectionPoints: ReadonlyArray<Product.BoxConnectionPoint>
): ReadonlyArray<System.Component> {
  const componentOrder = ComponentOrder.getComponentOrder(
    allComponents.map(c => ({
      id: c.id,
      productId: c.productId,
      accessoryToId: c.accessoryToId,
      properties: PropertyValueSet.toString(c.properties),
      sections: c.sections,
      sortNo: c.sortNo
    })),
    airstreams,
    connectionPoints
  );

  const sorted: Array<System.Component> = [];
  for (const id of componentOrder) {
    const component = componentsThatCouldHaveAccessories.find(c => c.id === id);
    if (component) {
      sorted.push(component);
    }
  }
  return sorted;
}
