import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as Product from "./product";

export function getInvalidPlacementPositions(
  sysProps: PropertyValueSet.PropertyValueSet,
  productSectionPlacementRules: ReadonlyArray<Product.PlacementRule>,
  productSectionIds: ReadonlyArray<string>
): ReadonlyArray<number> {
  const rules = productSectionPlacementRules.filter(
    psp =>
      !sysProps || PropertyFilter.isValid(sysProps, psp.rangePropertyFilter)
  );
  const errors: Array<number> = [];

  for (let i = -1; i < productSectionIds.length; ++i) {
    const ps1 = productSectionIds[i];
    const ps2 = productSectionIds[i + 1];
    if (!acceptsSectionPlacement(rules, ps1, ps2)) {
      errors.push(i < 0 ? 0 : i);
    }
  }

  return errors;
}

function acceptsSectionPlacement(
  rules: ReadonlyArray<Product.PlacementRule>,
  productSectionId1: string,
  productSectionId2: string
): boolean {
  return rules.some(
    r =>
      ((!r.productSectionId1 && !productSectionId1) ||
        r.productSectionId1 === productSectionId1) &&
      ((!r.productSectionId2 && !productSectionId2) ||
        r.productSectionId2 === productSectionId2)
  );
}
