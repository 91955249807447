import { Physics } from "@munters/calculations";
import { Amount, Quantity } from "@genesys/uom";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { MoistureLoadQueryResult, MlOpcaseResult } from "./types";
import { PropertyInfo } from "../../properties-selector";
import * as Guid from "@genesys/shared/lib/guid";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export const mlcNamesMap = new Map<string, string>();
//mlcoutdoortemperature
mlcNamesMap.set("outdoorairtemperature", "mlcoutdoortemperature");
mlcNamesMap.set("outdoortemperature", "mlcoutdoortemperature");
mlcNamesMap.set("outdoorstemp", "mlcoutdoortemperature");

//mlcoutdoorabshumidity
mlcNamesMap.set("outdoorairhumidity", "mlcoutdoorabshumidity");
mlcNamesMap.set("outdoorhumidity", "mlcoutdoorabshumidity");
mlcNamesMap.set("outdoorshum", "mlcoutdoorabshumidity");

//mlcindoortemperature
mlcNamesMap.set("returnairtemperature", "mlcindoortemperature");
mlcNamesMap.set("returntemp", "mlcindoortemperature");

//mlcindoorabshumidity
mlcNamesMap.set("returnairhumidity", "mlcindoorabshumidity");
mlcNamesMap.set("returnhum", "mlcindoorabshumidity");

//mlcfreshflow
mlcNamesMap.set("premixingboxairflow", "mlcfreshflow");

export function mapSpecialCases(
  caseResultNames: ReadonlyArray<string>,
  allOpcPropertyNames: ReadonlyArray<string>,
  currentNameMappings: {
    // tslint:disable-next-line
    [key: string]: string;
  }
) {
  const targetHumName = [
    "supplytargethumidity",
    "targethumidity",
    "targethum"
  ].find(x => allOpcPropertyNames.includes(x))!;

  const targetTempName = [
    "supplytargettemperature",
    "targettemperature",
    "targettemp"
  ].find(x => allOpcPropertyNames.includes(x))!;

  const supplyoutletName = ["supplyoutletairflow", "supplyoutletflow"].find(x =>
    allOpcPropertyNames.includes(x.trim())
  )!;

  // humidty
  if (caseResultNames.includes("mlcsystemtargethumidity")) {
    currentNameMappings[targetHumName] = "mlcsystemtargethumidity";
  } else {
    currentNameMappings[targetHumName] = "mlcindoorabshumidity";
  }

  if (caseResultNames.includes("mlcsystemtargettemperature")) {
    currentNameMappings[targetTempName] = "mlcsystemtargettemperature";
  } else {
    currentNameMappings[targetTempName] = "mlcindoortemperature";
  }

  if (caseResultNames.includes("mlcsystemflow")) {
    currentNameMappings[supplyoutletName] = "mlcsystemflow";
  } else {
    currentNameMappings[supplyoutletName] = "mlcreturnflow";
  }
}

export function convertMoistureLoadToOPC(
  operatingCasesFromTemplates: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  opcResultfromMl: ReadonlyArray<{
    readonly caseName: string;
    readonly settings: string;
  }>,
  propertyDefs: ReadonlyArray<Pick<PropertyInfo, "name" | "valueSources">>
) {
  let fallbackOperatingCase = operatingCasesFromTemplates[0];

  const allOpcPropertyNames = PropertyValueSet.getPropertyNames(
    fallbackOperatingCase
  );

  const propertyNamesWithoutValueSources = allOpcPropertyNames.filter(
    x => !x.startsWith("source_")
  );

  let valueSourcePvs = PropertyValueSet.filter(
    x => x.key.startsWith("source_"),
    fallbackOperatingCase
  );

  const namesMappingFound = getNamesMapping(allOpcPropertyNames);

  return opcResultfromMl.map((x, i) => {
    const caseResult = PropertyValueSet.fromString(x.settings);
    let newPvs = PropertyValueSet.Empty;
    newPvs = PropertyValueSet.setText("casename", x.caseName, newPvs);
    newPvs = PropertyValueSet.setInteger("sortno", i, newPvs);
    newPvs = PropertyValueSet.setInteger("outdoordata", 3, newPvs); // always 3??

    valueSourcePvs = adjustValueSource(valueSourcePvs, "outdoordata", 1);

    let propertiesToSkip = ["casename", "sortno", "outdoordata"];

    mapSpecialCases(
      PropertyValueSet.getPropertyNames(caseResult),
      allOpcPropertyNames,
      namesMappingFound
    );

    const settings = PropertyValueSet.merge(
      propertyNamesWithoutValueSources.reduce((soFar, currentPropertyName) => {
        if (propertiesToSkip.includes(currentPropertyName)) {
          propertiesToSkip = propertiesToSkip.filter(
            x => x !== currentPropertyName
          );
          return soFar;
        }
        const newPropertyValue = PropertyValueSet.getValue(
          namesMappingFound[currentPropertyName],
          caseResult
        );

        const propertyValueFromTemplates = PropertyValueSet.getAmount(
          currentPropertyName,
          fallbackOperatingCase
        )!;

        const propertyDef = propertyDefs.find(
          x => x.name === currentPropertyName
        );

        const canBeAdjusted =
          i === 0 ||
          (propertyDef &&
            valueCanBeManuallyAdjusted(
              propertyDef.valueSources,
              PropertyValueSet.merge(newPvs, fallbackOperatingCase)
            ));

        if (
          newPropertyValue &&
          newPropertyValue.type === "amount" &&
          canBeAdjusted
        ) {
          if (
            propertyValueFromTemplates.unit.quantity !==
            newPropertyValue.value.unit.quantity
          ) {
            const newAmount = convertAmount(
              newPropertyValue.value,
              propertyValueFromTemplates,
              fallbackOperatingCase,
              caseResult
            );

            soFar = PropertyValueSet.setAmount(
              currentPropertyName,
              newAmount,
              soFar
            );
          } else {
            soFar = PropertyValueSet.setAmount(
              currentPropertyName,
              newPropertyValue
                ? newPropertyValue.value
                : propertyValueFromTemplates,
              soFar
            );
          }

          valueSourcePvs = adjustValueSource(
            valueSourcePvs,
            currentPropertyName,
            1
          );
        } else {
          soFar = PropertyValueSet.set(
            currentPropertyName,
            PropertyValueSet.get(currentPropertyName, fallbackOperatingCase)!,
            soFar
          );
        }

        if (!canBeAdjusted) {
          valueSourcePvs = adjustValueSource(
            valueSourcePvs,
            currentPropertyName,
            0
          );
        }

        return soFar;
      }, newPvs),
      valueSourcePvs
    );

    if (i === 0) {
      fallbackOperatingCase = settings;
    }

    return {
      id: Guid.createGuid(),
      settings: settings
    };
  });
}

function adjustValueSource(
  valueSourcePvs: PropertyValueSet.PropertyValueSet,
  propertyName: string,
  newValue: number
): PropertyValueSet.PropertyValueSet {
  const valueSourceName = `source_${propertyName}`;
  return PropertyValueSet.setInteger(valueSourceName, newValue, valueSourcePvs);
}

function valueCanBeManuallyAdjusted(
  valueSources: ReadonlyArray<{
    readonly value: number;
    readonly propertyFilter: PropertyFilter.PropertyFilter;
    readonly propertyValueSourceId: string;
  }>,
  currentProperties: PropertyValueSet.PropertyValueSet
): boolean {
  if (valueSources.length === 0) {
    return true;
  }

  return valueSources
    .filter(x => PropertyFilter.isValid(currentProperties, x.propertyFilter))
    .some(vs => vs.propertyValueSourceId === "User");
}

function convertAmount(
  amountFrom: Amount.Amount<unknown>,
  amountTo: Amount.Amount<unknown>,
  fallbackOperatingCase: PropertyValueSet.PropertyValueSet,
  caseResult: PropertyValueSet.PropertyValueSet
) {
  let newAmount = amountFrom;

  if (amountTo.unit.quantity === "RelativeHumidity") {
    if (amountFrom.unit.quantity === "HumidityRatio") {
      const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
        "atmosphericpressure",
        fallbackOperatingCase
      )!;

      const temp =
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "mlcsystemtargettemperature",
          caseResult
        ) ||
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "mlcindoortemperature",
          caseResult
        )!;
      newAmount =
        Physics.RP1485.AmountConversions.humidityRatioToRelativeHumidity(
          pressure,
          temp,
          amountFrom as Amount.Amount<Quantity.HumidityRatio>
        );
    }
  } else if (amountTo.unit.quantity === "HumidityRatio") {
    if (amountFrom.unit.quantity === "RelativeHumidity") {
      const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
        "atmosphericpressure", // where does atmosphericpressure comefrom?
        fallbackOperatingCase
      )!;

      const temp =
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "mlcsystemtargettemperature",
          caseResult
        ) ||
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "mlcindoortemperature",
          caseResult
        )!;
      newAmount =
        Physics.RP1485.AmountConversions.relativeHumidityToHumidityRatio(
          pressure,
          temp,
          amountFrom as Amount.Amount<Quantity.RelativeHumidity>
        );
    }
  }

  return newAmount;
}

function getNamesMapping(allPropertyNames: readonly string[]) {
  let res: {
    // tslint:disable-next-line
    [key: string]: string;
  } = {};
  allPropertyNames.reduce((soFar, current) => {
    const key = mlcNamesMap.get(current);
    if (key) {
      soFar[current] = key;
    }

    return soFar;
  }, res);

  return res;
}

export function mapQueryResultToOpc(
  input: MoistureLoadQueryResult,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
): MlOpcaseResult {
  return {
    climateSettings: input.moistureloadInput.climateSettings,
    opcResult: input.moistureLoadResult.maxLoadResults.map(maxResult => {
      const results = maxResult.flowSuggestions.map(x => ({
        id: x.id,
        valid: x.isValid,
        propertyValueSet: PropertyValueSet.fromString(x.result),
        flowSourceId: x.flowSourceId
      }));

      const loadCase = PropertyValueSet.fromString(
        maxResult.operatingCaseResults
      );

      let selectedFlowIndex = results.findIndex(
        x => x.id === maxResult.selectedFlowId
      );

      if (selectedFlowIndex === -1) {
        selectedFlowIndex = 0;
      }

      return {
        caseName: translate(
          LanguageTexts.globalPropertyName(maxResult.caseName)
        ),
        settings: PropertyValueSet.toString(
          PropertyValueSet.merge(
            loadCase,
            results[selectedFlowIndex].propertyValueSet
          )
        )
      };
    })
  };
}
