import * as React from "react";
import * as PropertiesSelector from "../react-properties-selector";
import * as Contexts from "../contexts";
import { createSelectorInfoMap } from "../genesys-properties-selector-helpers";
import { GroupRenderer } from "./group-renderer";

export function productPropertiesLayoutRenderer(
  props: PropertiesSelector.LayoutRendererProps
): JSX.Element {
  const selectorInfoMap = createSelectorInfoMap(props.selectors);
  return (
    <Contexts.propertiesSelectorContext.Consumer>
      {({
        activatedQuantitySelectors,
        conversionParametersMap,
        filterPrettyPrint,
        groupFilter,
        showGroups,
        onQuantityChange,
        layoutRendererExtraProps,
        translatePropertyValue,
        valueSources
      }) => {
        if (layoutRendererExtraProps.sections.length === 0) {
          return <div />;
        }

        const groups = (
          groupFilter.size > 0
            ? layoutRendererExtraProps.sections[0].groups.filter(g =>
                groupFilter.has(g.key)
              )
            : layoutRendererExtraProps.sections[0].groups
        ).map(group => (
          <GroupRenderer
            key={`${layoutRendererExtraProps.uniqueId}-${group.key}`}
            filterPrettyPrint={filterPrettyPrint}
            renderGroup={group}
            showGroups={showGroups}
            fieldGroup={layoutRendererExtraProps.fieldGroup}
            hidePropertyNames={layoutRendererExtraProps.hidePropertyNames}
            rowRendererType={{
              type: "ProductPropertyRowRenderer",
              propertyFormats: layoutRendererExtraProps.propertyFormats,
              valueSourcesDict: valueSources || {},
              translatePropertyValue: translatePropertyValue
            }}
            selectorInfoMap={selectorInfoMap}
            activatedQuantitySelectors={activatedQuantitySelectors}
            onQuantityChange={onQuantityChange}
            conversionParametersMap={conversionParametersMap}
          />
        ));
        return <div>{groups}</div>;
      }}
    </Contexts.propertiesSelectorContext.Consumer>
  );
}
