import { Program, Cmd } from "@typescript-tea/core";
import { Logger } from "../types";
import { printBuffer, UpdateEntry } from "./update-entry";

// Use performance API if it's available in order to get better precision
const timer =
  typeof performance !== "undefined" &&
  performance !== null &&
  typeof performance.now === "function"
    ? performance
    : Date;

export interface ProgramLoggerOptions {
  readonly showDiff: boolean;
}

/**
 * Higher order program that wraps the passed program with a logger.
 * @param program The program to wrap.
 * @param loggerOptions Options for the logging.
 */
export function programWithLogger<Init, State, Action, View>(
  program: Program<Init, State, Action, View>,
  options: ProgramLoggerOptions = { showDiff: false },
  logger: Logger = console
): Program<Init, State, Action, View> {
  const logBuffer: UpdateEntry<Action, State>[] = [];
  return {
    ...program,
    update: (action, state) => {
      const started = timer.now();
      const startedTime = new Date();

      // Do the original change
      let change: readonly [State, Cmd<Action>?] | undefined = undefined;
      let error = undefined;
      try {
        change = program.update(action, state);
      } catch (e) {
        error = e;
      }
      const took = timer.now() - started;
      const cmd = change && change[1];

      const logEntry: UpdateEntry<Action, State> = {
        action,
        prevState: state,
        nextState: change && change[0],
        cmd,
        error,
        started,
        startedTime,
        took
      };
      logBuffer.push(logEntry);

      printBuffer(logBuffer, logger, options.showDiff);
      logBuffer.length = 0;

      if (error) {
        throw error;
      }

      return change!;
    }
  };
}
