import { PropertyFilter } from "@genesys/property";
import { PropertyValueSet } from "@genesys/property";
import { PropertyInfo, PropertyItem } from "../../../properties-selector";
import { DryCapModel } from "../../../graphql-types";
import { getDryCapModelIntValue } from "../functions";
import { ProductData } from "../types";

import {
  model,
  processInletHumidity,
  processInletRelativeHumidity,
  processInletWetTemperature,
  processInletPressure,
  processInletTemperature,
  moistureRemoval,
  processOutletFlow,
  processOutletHumidity,
  processOutletRelativeHumidity,
  processOutletDewPointTemperature,
  processOutletPressure,
  processOutletTemperature,
  reactHeaterPower,
  reactOutletFlow,
  processAvailableStaticPressure,
  reactAvailableStaticPressure,
  totalPower
} from "./known-properties";

const dryCapModelGroup = "drycapmodelgroup";
const processAirInGroup = "processairingroup";
const moistureRemovalGroup = "moistureremovalgroup";
const dryAirOutGroup = "dryairoutgroup";
const summaryGroup = "summarygroup";

function getInitialInputPvsString(
  canSeeDryCapClaims: ReadonlyArray<string>
): string {
  return `
  ${model}=${
    canSeeDryCapClaims.includes("1") || canSeeDryCapClaims.includes("10")
      ? getDryCapModelIntValue(DryCapModel.MG_50)
      : getDryCapModelIntValue(DryCapModel.M_90_L)
  };
  ${processInletTemperature}=20:Celsius;
  ${processInletHumidity}=7.29:GramPerKiloGram;
  ${processInletRelativeHumidity}=49.97495274:Percent;
  ${processInletWetTemperature}=13.77303415:Celsius;
  ${processInletPressure}=101325:Pascal;
  `.replace(/\s+/g, "");
}

export function getInitialInputPvs(
  canSeeDryCapClaims: ReadonlyArray<string>
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.fromString(
    getInitialInputPvsString(canSeeDryCapClaims)
  );
}

const modelValuesForClaimValue1: ReadonlyArray<PropertyItem> = [
  {
    sortNo: 0,
    text: "MG50",
    id: DryCapModel.MG_50,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MG_50)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 1,
    text: "MG90",
    id: DryCapModel.MG_90,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MG_90)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 2,
    text: "M160L",
    id: DryCapModel.M_160_L,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_160_L)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 3,
    text: "M170L",
    id: DryCapModel.M_170_L,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_170_L)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 4,
    text: "M190Y",
    id: DryCapModel.M_190_Y,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_190_Y)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 5,
    text: "M210X",
    id: DryCapModel.M_210_X,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_210_X)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 6,
    text: "MH270",
    id: DryCapModel.MH_270,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MH_270)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 7,
    text: "MCS300",
    id: DryCapModel.MCS_300,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MCS_300)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 8,
    text: "MCS300C",
    id: DryCapModel.MCS_300_C,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MCS_300_C)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  }
];

const modelValuesForClaimValue5: ReadonlyArray<PropertyItem> = [
  {
    sortNo: 9,
    text: "M90L",
    id: DryCapModel.M_90_L,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_90_L)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 10,
    text: "M120L",
    id: DryCapModel.M_120_L,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_120_L)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 11,
    text: "MC150Y",
    id: DryCapModel.MC_150_Y,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MC_150_Y)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 12,
    text: "MC150X",
    id: DryCapModel.MC_150_X,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.MC_150_X)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  },
  {
    sortNo: 13,
    text: "M200L",
    id: DryCapModel.M_200_L,
    value: {
      type: "integer",
      value: getDryCapModelIntValue(DryCapModel.M_200_L)
    },
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty,
    descriptionValuesTexts: []
  }
];

function getModelPropertyInfo(
  canSeeDryCapClaims: ReadonlyArray<string>
): PropertyInfo {
  let items: Array<PropertyItem> = [];

  if (canSeeDryCapClaims.includes("1") || canSeeDryCapClaims.includes("10")) {
    items = items.concat(modelValuesForClaimValue1);
  }
  if (canSeeDryCapClaims.includes("5") || canSeeDryCapClaims.includes("10")) {
    items = items.concat(modelValuesForClaimValue5);
  }

  const modelPropertyInfo: PropertyInfo = {
    name: model,
    quantity: "Discrete",
    group: dryCapModelGroup,
    sortNo: 0,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: items,
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: []
  };

  return modelPropertyInfo;
}

const processInletPropertyData: ReadonlyArray<PropertyInfo> = [
  {
    name: processInletTemperature,
    quantity: "Temperature",
    group: processAirInGroup,
    sortNo: 2,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: []
  },
  {
    name: processInletWetTemperature,
    quantity: "WetTemperature",
    group: processAirInGroup,
    sortNo: 3,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processInletTemperature, processInletPressure]
  },
  {
    name: processInletHumidity,
    quantity: "HumidityRatio",
    group: processAirInGroup,
    sortNo: 4,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processInletTemperature, processInletPressure]
  },
  {
    name: processInletRelativeHumidity,
    quantity: "RelativeHumidity",
    group: processAirInGroup,
    sortNo: 5,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processInletTemperature, processInletPressure]
  },

  {
    name: processInletPressure,
    quantity: "Pressure",
    group: processAirInGroup,
    sortNo: 6,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.fromString("1=0")!,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: []
  }
];

const processOutletPropertyData: ReadonlyArray<PropertyInfo> = [
  {
    name: processOutletTemperature,
    quantity: "Temperature",
    group: dryAirOutGroup,
    sortNo: 7,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: []
  },
  {
    name: processOutletHumidity,
    quantity: "HumidityRatio",
    group: dryAirOutGroup,
    sortNo: 8,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processOutletTemperature, processOutletPressure]
  },
  {
    name: processOutletRelativeHumidity,
    quantity: "RelativeHumidity",
    group: dryAirOutGroup,
    sortNo: 9,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processOutletTemperature, processOutletPressure]
  },
  {
    name: processOutletDewPointTemperature,
    quantity: "DewPointTemperature",
    group: dryAirOutGroup,
    sortNo: 10,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: [processOutletTemperature, processOutletPressure]
  },
  {
    name: processOutletPressure,
    quantity: "Pressure",
    group: dryAirOutGroup,
    sortNo: 11,
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.fromString("1=0")!,
    items: [],
    valueSources: [],
    defaultValues: [],
    descriptionTexts: [],
    conversionParameters: []
  }
];

const moistureRemovalPropertyInfo: PropertyInfo = {
  name: moistureRemoval,
  quantity: "MassFlow",
  group: moistureRemovalGroup,
  sortNo: 1,
  validationFilter: PropertyFilter.Empty,
  visibilityFilter: PropertyFilter.Empty,
  items: [],
  valueSources: [],
  defaultValues: [],
  descriptionTexts: [],
  conversionParameters: []
};

export function getInputProductData(
  canSeeDryCapClaims: ReadonlyArray<string>
): ProductData {
  return {
    id: "DRYCAP",
    properties: [
      getModelPropertyInfo(canSeeDryCapClaims),
      ...processInletPropertyData
    ]
  };
}

export const resultProductData: ProductData = {
  id: "DRYCAP",
  properties: [moistureRemovalPropertyInfo, ...processOutletPropertyData]
};

export function getSummaryProductData(
  canSeeDryCapClaims: ReadonlyArray<string>
): ProductData {
  return {
    id: "DRYCAP",
    properties: [
      getModelPropertyInfo(canSeeDryCapClaims),
      moistureRemovalPropertyInfo,
      ...processInletPropertyData,
      ...processOutletPropertyData,
      {
        name: processOutletFlow,
        quantity: "MassFlow",
        group: dryAirOutGroup,
        sortNo: 12,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: [
          processOutletTemperature,
          processOutletHumidity,
          processOutletPressure
        ]
      },
      {
        name: processAvailableStaticPressure,
        quantity: "Pressure",
        group: summaryGroup,
        sortNo: 13,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: []
      },
      {
        name: reactHeaterPower,
        quantity: "Power",
        group: summaryGroup,
        sortNo: 14,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: []
      },
      {
        name: totalPower,
        quantity: "Power",
        group: summaryGroup,
        sortNo: 15,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: []
      },
      {
        name: reactOutletFlow,
        quantity: "MassFlow",
        group: summaryGroup,
        sortNo: 16,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: []
      },
      {
        name: reactAvailableStaticPressure,
        quantity: "Pressure",
        group: summaryGroup,
        sortNo: 17,
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: PropertyFilter.Empty,
        items: [],
        valueSources: [],
        defaultValues: [],
        descriptionTexts: [],
        conversionParameters: []
      }
    ].map(x => ({ ...x, group: summaryGroup } as PropertyInfo))
  };
}
