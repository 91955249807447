import * as React from "react";
import gql from "graphql-tag";
import { Quantity, Unit, Amount, Serialize } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as GraphQLQuery from "../graphql-query";
import * as MutationsQueueUser from "../mutations-queue-user";
import * as Contexts from "../contexts";
import * as Elements from "../elements";
import {
  saveAmountFieldFormatQueuedMutation,
  deleteAmountFieldFormatQueuedMutation,
  updateAmountFieldProfileQueuedMutation,
  setActiveAmountFieldProfileQueuedMutation
} from "./mutations";

const productDataQuery = gql`
  query ScreenAmountProfileProviderProductData {
    product {
      genesysNumberPrefixes {
        id
        genesysNo
        pricingNo
      }

      fieldDefaults {
        id
        fieldGroup
        fieldName
        measureSystem
        unit
        decimalCount
      }

      quantityDefaults {
        id
        measureSystem
        quantity
        unit
        decimalCount
      }
    }
  }
`;

const userQuery = gql`
  query ScreenAmountProfileProviderUserData {
    user {
      userProfile {
        id

        userSettings {
          id
          amountFieldProfiles {
            id
            name
            measureSystem
            isEditable
            amountFieldFormats {
              id
              fieldGroup
              fieldName
              measureSystem
              unit
              decimalCount
            }
          }
          selectedAmountFieldProfile {
            id
            measureSystem
            amountFieldFormats {
              id
              fieldGroup
              fieldName
              measureSystem
              unit
              decimalCount
            }
          }
        }
      }
    }
  }
`;

// tslint:disable-next-line:function-name
export function ScreenAmountProfileProvider({
  children
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  return (
    <GraphQLQuery.QueryProduct<
      GraphQLTypes.ScreenAmountProfileProviderProductData,
      GraphQLTypes.ScreenAmountProfileProviderProductDataVariables
    >
      query={productDataQuery}
      variables={{}}
    >
      {productDataResult => (
        <MutationsQueueUser.MutationsQueue>
          {({ mutationQueue, queueAndFlush }) => (
            <GraphQLQuery.QueryUser<
              GraphQLTypes.ScreenAmountProfileProviderUserData,
              GraphQLTypes.ScreenAmountProfileProviderUserDataVariables
            >
              query={userQuery}
              variables={{}}
              mutationsQueue={mutationQueue}
            >
              {userResult => {
                if (
                  productDataResult.type === "ErrorResultProps" ||
                  userResult.type === "ErrorResultProps"
                ) {
                  return (
                    <p>
                      ScreenAmountProfileProvider: Error loading data queries
                    </p>
                  );
                }

                if (
                  productDataResult.type === "PartialResultProps" ||
                  userResult.type === "PartialResultProps"
                ) {
                  return <Elements.Loader />;
                }

                return (
                  <ScreenAmountProfileProviderInternal
                    productData={productDataResult.data}
                    userData={userResult.data}
                    children={children}
                    queueAndFlush={queueAndFlush}
                  />
                );
              }}
            </GraphQLQuery.QueryUser>
          )}
        </MutationsQueueUser.MutationsQueue>
      )}
    </GraphQLQuery.QueryProduct>
  );
}

interface ScreenAmountProfileProviderProps {
  readonly productData: GraphQLTypes.ScreenAmountProfileProviderProductData;
  readonly userData: GraphQLTypes.ScreenAmountProfileProviderUserData;
  readonly children: React.ReactNode;
  readonly queueAndFlush: MutationsQueueUser.QueueAndFlush;
}
interface ScreenAmountProfileProviderState {
  readonly childProps: Contexts.ScreenAmountProfileContextValue;
}

class ScreenAmountProfileProviderInternal extends React.Component<
  ScreenAmountProfileProviderProps,
  ScreenAmountProfileProviderState
> {
  getPropertyFormats(
    fieldGroup: string,
    properties: PropertyValueSet.PropertyValueSet
  ): ScreenAmounts.PropertyFormats {
    return ScreenAmounts.buildPropertyFormatsMap(
      this.state.childProps.quantityDefaults,
      this.state.childProps.fieldDefaults,
      this.state.childProps.userAmountFields,
      this.state.childProps.measureSystem,
      fieldGroup,
      properties
    );
  }

  getAmountFormat(
    fieldGroup: string,
    fieldName: string,
    amountOrQuantity: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
  ): ScreenAmounts.AmountFormat {
    return ScreenAmounts.getAmountFormat(
      this.state.childProps.quantityDefaults,
      this.state.childProps.fieldDefaults,
      this.state.childProps.userAmountFields,
      this.state.childProps.measureSystem,
      fieldGroup,
      fieldName,
      amountOrQuantity
    );
  }
  saveAmountFieldFormat(
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ): void {
    const unitString = Serialize.unitToString(unit);
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: saveAmountFieldFormatQueuedMutation(
          this.state.childProps.selectedAmountProfileId,
          this.state.childProps.measureSystem,
          fieldGroup,
          fieldName,
          decimalCount,
          unitString
        )
      }
    ]);
  }

  deleteAmountFieldFormat(fieldGroup: string, fieldName: string): void {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: deleteAmountFieldFormatQueuedMutation(
          this.state.childProps.selectedAmountProfileId,
          fieldGroup,
          fieldName
        )
      }
    ]);
  }

  updateAmountFieldProfile(
    id: string,
    name: string,
    measureSystem: number
  ): void {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: updateAmountFieldProfileQueuedMutation(
          id,
          name,
          measureSystem
        )
      }
    ]);
  }

  setActiveAmountFieldProfile(id: string): void {
    this.props.queueAndFlush([
      {
        type: "AddToQueueMutationDescription",
        mutation: setActiveAmountFieldProfileQueuedMutation(
          id,
          this.props.userData.user.userProfile.id
        )
      }
    ]);
  }
  constructor(props: ScreenAmountProfileProviderProps) {
    super(props);
    const { fieldDefaults, quantityDefaults } = props.productData.product;
    this.state = {
      childProps: {
        getPropertyFormats: this.getPropertyFormats.bind(this),
        getAmountFormat: this.getAmountFormat.bind(this),
        amountFieldProfiles: [],
        quantityDefaults:
          ScreenAmounts.createQuantityDefaultsMap(quantityDefaults),
        fieldDefaults: ScreenAmounts.createFieldsDefaultsMap(fieldDefaults),
        userAmountFields: {},
        measureSystem: 1,
        selectedAmountProfileId: ScreenAmounts.SIProfileId,
        saveAmountFieldFormat: this.saveAmountFieldFormat.bind(this),
        deleteAmountFieldFormat: this.deleteAmountFieldFormat.bind(this),
        updateAmountFieldProfile: this.updateAmountFieldProfile.bind(this),
        setActiveAmountFieldProfile: this.setActiveAmountFieldProfile.bind(this)
      }
    };
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    nextProps: ScreenAmountProfileProviderProps,
    prevState: ScreenAmountProfileProviderState
  ): Partial<ScreenAmountProfileProviderState> {
    const { amountFieldProfiles, selectedAmountFieldProfile } =
      nextProps.userData.user.userProfile.userSettings;

    const selectedAmountProfileId =
      (selectedAmountFieldProfile && selectedAmountFieldProfile.id) ||
      ScreenAmounts.SIProfileId;
    const measureSystem =
      (selectedAmountFieldProfile &&
        selectedAmountFieldProfile.measureSystem) ||
      1;

    return {
      childProps: {
        ...prevState.childProps,
        userAmountFields: ScreenAmounts.createUserAmountFieldsMap(
          selectedAmountFieldProfile.amountFieldFormats
        ),
        selectedAmountProfileId: selectedAmountProfileId,
        measureSystem,
        amountFieldProfiles: amountFieldProfiles
      }
    };
  }

  render() {
    return (
      <Contexts.ScreenAmountProfileContext.Provider
        value={this.state.childProps}
      >
        {React.Children.only(this.props.children)}
      </Contexts.ScreenAmountProfileContext.Provider>
    );
  }
}
