import * as React from "react";

interface Props {
  readonly onFileUploaded: (file: File) => void;
  readonly children: (onClick: () => void) => JSX.Element;
  readonly accept: string;
}
interface State {}

export class FileUploadContainer extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
  }
  onClick = (): void => {
    if (this.myRef.current) {
      this.myRef.current.click();
    }
  };

  onUpload = (evt: Event): void => {
    const targetElement = evt.target as HTMLInputElement;
    if (!targetElement.files || targetElement.files.length < 1) {
      return;
    }
    const file = targetElement.files[0];
    this.props.onFileUploaded(file);
  };

  render(): JSX.Element {
    // tslint:disable-next-line:variable-name
    return (
      <div>
        <input
          style={{ display: "none" }}
          ref={this.myRef}
          type="file"
          accept={this.props.accept}
          onChange={event => {
            this.onUpload(event as any);
            event.target.value = "";
          }}
        />
        {this.props.children(() => this.onClick())}
      </div>
    );
  }
}
