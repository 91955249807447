import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import { PropertyValueSet } from "@genesys/property";
import { Icon } from "@genesys/ui-elements";
import {
  OperationTimeFunc,
  OperationTimeUpdateFunc,
  LoadUpdateFunction,
  LoadCreatorFunc
} from "../types";

export function createLoad(
  sourceName: MoistureHeatLoadTypeEnum,
  icon: Icon | undefined,
  visible: (moistureLoadClaimValue: number) => boolean,
  moistureVisible: (moistureLoadClaimValue: number) => boolean,
  heatVisible: (moistureLoadClaimValue: number) => boolean,
  updateFunction: LoadUpdateFunction | undefined,
  operationalTime: {
    readonly onOperationTimeUpdate?: OperationTimeUpdateFunc;
    readonly getAllowedOperationTimes?: OperationTimeFunc;
    readonly getDefaultOperationTime?: OperationTimeFunc;
  },
  title: string,
  useHeatProperty: string | undefined,
  useMoistureProperty: string | undefined,
  description: string,
  properties: ReadonlyArray<string>,
  manualInputKey: string | undefined,
  overridableProperties: ReadonlyArray<string>,
  childLoads: ReadonlyArray<MoistureHeatLoadTypeEnum>
): LoadCreatorFunc {
  return (moistureLoadClaimValue: number) => {
    return {
      sourceName: sourceName,
      Icon: icon,
      screenAmountProfileFieldGroup: screenAmountProfileFieldGroup[sourceName],
      visible: visible(moistureLoadClaimValue),
      description: description,
      title: title,
      heatProperty: useHeatProperty,
      moistureProperty: useMoistureProperty,
      claims: {
        canUseHeat: heatVisible(moistureLoadClaimValue) && !!useHeatProperty,
        canUseMoisture:
          moistureVisible(moistureLoadClaimValue) && !!useMoistureProperty
      },
      properties: {
        manualInputKey,
        heatProperty: useHeatProperty,
        moistureProperty: useMoistureProperty,
        loadProperties: properties,
        overridableProperties
      },
      childLoads,
      operationalTime: {
        getAllowedOperationTimes: operationalTime.getAllowedOperationTimes,
        onOperationTimeUpdate: operationalTime.onOperationTimeUpdate,
        getDefaultOperationTime: operationalTime.getDefaultOperationTime
      },
      additionalInfo: {
        updateFunction,
        isHeatAdded: currentSettings =>
          isHeatSelected(useHeatProperty || "", currentSettings),
        isMoistureAdded: currentSettings =>
          isMoistureSelected(useMoistureProperty || "", currentSettings)
      }
    };
  };
}

const screenAmountProfileFieldGroup = {
  EVAPORATION_OF_WATER: "EvaporationOfWater",
  EMISSION_FROM_PEOPLE: "EmissionFromPeople",
  DIFFUSION_THROUGH_MATERIALS: "DiffusionThroughMaterials",
  INTENTIONAL_VENTILATION: "IntentionalVentilation",
  UNINTENDED_VENTILATION: "UnintendedVentilation",
  EMISSION_FROM_COMBUSTION: "EmissionFromCombustion",
  EVAPORATION_FROM_MATERIALS: "EvaporationFromMaterials",
  HEAT_TRANSMISSION: "HeatTransmission",
  STATIC_MOISTURE_LOAD: "StaticMoistureLoad",
  STATIC_HEAT_LOAD: "StaticHeatLoad",
  OPEN_DOOR: "OpenDoor"
};

function isMoistureSelected(
  moistureProperty: string,
  currentSettings: PropertyValueSet.PropertyValueSet
) {
  return (
    PropertyValueSet.getInteger(moistureProperty, currentSettings) === 1
    // &&
    // load.additionalInfo.hasMoistureClaim
  );
}

function isHeatSelected(
  heatProperty: string,
  currentSettings: PropertyValueSet.PropertyValueSet
) {
  return (
    PropertyValueSet.getInteger(heatProperty, currentSettings) === 1
    // &&
    // load.additionalInfo.hasHeatClaim
  );
}
