import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import { LabelManagerView } from "../label-manager/view";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  // Menu,
  RevisionLockMessageContainer,
  ConfirmDeleteContainer,
  PricingWizardContainer
} from "./elements";
import {
  H3,
  StandardButton,
  OverlayLoader
  // CircularProgressLoader
} from "@genesys/ui-elements";
import { PricingWizardView } from "../pricing-wizard";
import { Action } from "./state";
import { SystemActionRendererProps } from "./types";
import { InputAction } from "./components/input-action";

export function renderRenameContent(props: SystemActionRendererProps) {
  const { dispatch, sharedState, state, systemFile } = props;
  return (
    <Root>
      <InputAction
        placeHolderText="..."
        isDisabled={false}
        sharedState={sharedState}
        header={sharedState.translate(LanguageTexts.rename())}
        inputValue={state.input}
        onChange={e => dispatch(Action.setInput(e.target.value))}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onSave={() =>
          dispatch(
            Action.updateSystemFile({
              systemFile: {
                id: systemFile.id,
                labelIds: systemFile.labels.map(l => l.id),
                owner: systemFile.owner,
                name: state.input
              }
            })
          )
        }
      />
    </Root>
  );
}

export function editCommentContent(props: SystemActionRendererProps) {
  const { dispatch, sharedState, state, systemId } = props;
  return (
    <Root>
      <InputAction
        placeHolderText={sharedState.translate(LanguageTexts.comment()) + "..."}
        isDisabled={false}
        sharedState={sharedState}
        header={sharedState.translate(LanguageTexts.editComment())}
        inputValue={state.input}
        onChange={e => dispatch(Action.setInput(e.target.value))}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onSave={() =>
          dispatch(Action.editRevisionComment(systemId, state.input))
        }
      />
    </Root>
  );
}

export function assignLabelsContent(props: SystemActionRendererProps) {
  const { dispatch, sharedState, state, systemFile } = props;

  if (!state.labelManagerState) {
    return null;
  }

  return (
    <Root>
      <LabelManagerView
        labels={sharedState.user.labels}
        sharedState={sharedState}
        state={state.labelManagerState}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onAssign={selectedLabels => {
          dispatch(
            Action.updateSystemFile({
              systemFile: {
                id: systemFile.id,
                labelIds: selectedLabels.map(l => l.id),
                owner: systemFile.owner,
                name: systemFile.name
              }
            })
          );
        }}
        dispatch={Dispatch.map(Action.dispatchLabelManager, dispatch)}
      />
    </Root>
  );
}

export function createRevisionContent(props: SystemActionRendererProps) {
  const { dispatch, sharedState, state, systemFile, shouldOpenNewRevision } =
    props;

  const latestRevision = systemFile.systems.reduce(
    (a, b) => (b.revisionNo > a.revisionNo ? b : a),
    systemFile.systems[0]
  );

  const isLatestRevisionLocked =
    latestRevision.status >= SystemStatusEnum.SystemStatus.LockSuccess;

  if (!isLatestRevisionLocked) {
    return (
      <RevisionLockMessageContainer>
        <H3 color="dark" weight="normal">
          {sharedState.translate(LanguageTexts.latestRevNeedsToBeLocked())}
        </H3>

        <StandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={() => dispatch(Action.setMode("menu"))}
        >
          {sharedState.translate(LanguageTexts.ok())}
        </StandardButton>
      </RevisionLockMessageContainer>
    );
  }
  return (
    <Root>
      <InputAction
        placeHolderText={sharedState.translate(LanguageTexts.comment()) + "..."}
        isDisabled={false}
        sharedState={sharedState}
        header={sharedState.translate(LanguageTexts.createnewrevision())}
        inputValue={state.input}
        onChange={e => dispatch(Action.setInput(e.target.value))}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onSave={() =>
          dispatch(
            Action.createNewSystemRevision(
              systemFile.id,
              state.input,
              !!shouldOpenNewRevision
            )
          )
        }
      />
    </Root>
  );
}

export function confirmDeleteContent({
  dispatch,
  sharedState,
  systemFile
}: SystemActionRendererProps) {
  return (
    <Root>
      <ConfirmDeleteContainer>
        <H3 color="dark" weight="normal">
          {`${sharedState.translate(LanguageTexts.confirmdelete())} (${
            systemFile.name
          })`}
        </H3>
        <div>
          <StandardButton
            buttonType="Secondary-2"
            size="Small"
            onClick={() => dispatch(Action.setMode("menu"))}
          >
            {sharedState.translate(LanguageTexts.cancel())}
          </StandardButton>
          <StandardButton
            buttonType="Primary-2"
            size="Small"
            onClick={() => dispatch(Action.deleteSystems([systemFile.id]))}
          >
            {sharedState.translate(LanguageTexts.confirm())}
          </StandardButton>
        </div>
      </ConfirmDeleteContainer>
    </Root>
  );
}

export function createPricingContent({
  dispatch,
  sharedState,
  state,
  systemId,
  systemStatus
}: SystemActionRendererProps) {
  return (
    <Root>
      {systemStatus < SystemStatusEnum.SystemStatus.LockSuccess ? (
        <RevisionLockMessageContainer>
          <H3 color="dark" weight="normal">
            {sharedState.translate(
              LanguageTexts.dynamicText("systemnotlocked")
            )}
          </H3>
          <StandardButton
            buttonType="Secondary-2"
            size="Small"
            onClick={() => dispatch(Action.setMode("menu"))}
          >
            {sharedState.translate(LanguageTexts.ok())}
          </StandardButton>
        </RevisionLockMessageContainer>
      ) : state.pricingWizardState ? (
        <PricingWizardContainer>
          <PricingWizardView
            state={state.pricingWizardState}
            sharedState={sharedState}
            systemId={systemId}
            dispatch={Dispatch.map(Action.dispatchPricingWizard, dispatch)}
            onCancel={() => dispatch(Action.setMode("menu"))}
          />
        </PricingWizardContainer>
      ) : (
        <OverlayLoader />
      )}
    </Root>
  );
}

export function copyTransferContent({
  dispatch,
  sharedState,
  state,
  systemId
}: SystemActionRendererProps) {
  return (
    <Root>
      <InputAction
        placeHolderText=""
        isDisabled={state.input.length === 0}
        sharedState={sharedState}
        header={sharedState.translate(LanguageTexts.systemName())}
        inputValue={state.input}
        onChange={e => dispatch(Action.setInput(e.target.value))}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onSave={() => dispatch(Action.copySystemAndTransferToSelf(systemId))}
      />
    </Root>
  );
}

export function transferContent({
  dispatch,
  sharedState,
  state,
  systemFile
}: SystemActionRendererProps) {
  return (
    <Root>
      <InputAction
        placeHolderText="..."
        isDisabled={state.input.length === 0}
        sharedState={sharedState}
        header={sharedState.translate(LanguageTexts.transfertouser())}
        inputValue={state.input}
        onChange={e => dispatch(Action.setInput(e.target.value))}
        onCancel={() => dispatch(Action.setMode("menu"))}
        onSave={() =>
          dispatch(
            Action.updateSystemFile({
              systemFile: {
                id: systemFile.id,
                labelIds: systemFile.labels.map(l => l.id),
                owner: state.input,
                name: systemFile.name
              }
            })
          )
        }
      />
    </Root>
  );
}
