import gql from "graphql-tag";

export const productQuery = gql`
  query MultipleSystemsEditorProductQuery($dhu: GetProductInputType!) {
    product {
      dhu: product(input: $dhu) {
        minMaxFlow: productDataTable(tableName: "minMaxFlow") {
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
