import * as ComponentOrder from "@genesys/shared/lib/component-order";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as PropertiesSelector from "../../../properties-selector";
import * as Product from "../../product";
import * as System from "../../system";
import { PropertyGroup } from "./types";

export function getInitalGroupFilter(
  system: System.System,
  systemType: Product.SystemType
): ReadonlyArray<string> {
  const sysProperties = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const screenParameters = systemType.screens
    .filter(s =>
      PropertyFilterHelpers.isValid(
        s.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
    .find(s => s.screenType === "PROPERTY_GROUP")?.screenParameters;

  const groupFilter = !screenParameters
    ? []
    : getDefaultFilter(screenParameters);

  return groupFilter;
}

export function getInitialPropertyGroups(
  system: System.System,
  systemType: Product.SystemType,
  products: ReadonlyArray<Product.Product>
): ReadonlyArray<PropertyGroup> {
  const sysProperties = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const allProducts = ProductData.getValidProductsForRange(
    products,
    sysProperties
  ).filter(p => ProductData.filterProductForRange(p, sysProperties));

  const connectionPoints = allProducts.reduce(
    (soFar: ReadonlyArray<Product.BoxConnectionPoint>, product) =>
      soFar.concat(product.boxConnectionPoints),
    []
  );

  const excluded = getProductsToExclude(systemType, sysProperties);

  const componentsToShow = system.components.filter(
    c => !c.accessoryToId && !excluded.includes(c.productId)
  );

  const sortedComponents = sortComponents(
    system.components,
    componentsToShow,
    system.airstreams,
    connectionPoints
  );

  return sortedComponents.map(c => {
    return {
      component: c,
      state: PropertiesSelector.init(c.properties)
    };
  });
}

function getProductsToExclude(
  systemType: Product.SystemType,
  sysProperties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<string> {
  const validScreen = systemType.screens
    .filter(s =>
      PropertyFilterHelpers.isValid(
        s.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
    .find(s => s.screenType === "PROPERTY_GROUP");

  if (validScreen) {
    const screenParams = PropertyValueSet.fromString(
      validScreen.screenParameters
        ? validScreen.screenParameters.toLowerCase()
        : ""
    );

    const excludeList = PropertyValueSet.getText("exclude", screenParams);
    const productsToExclude = excludeList ? excludeList.split(",") : [];

    return productsToExclude;
  }

  return [];
}

function sortComponents(
  allComponents: ReadonlyArray<System.Component>,
  componentsThatCouldHaveAccessories: ReadonlyArray<System.Component>,
  airstreams: ReadonlyArray<System.Airstream>,
  connectionPoints: ReadonlyArray<Product.BoxConnectionPoint>
): ReadonlyArray<System.Component> {
  const componentOrder = ComponentOrder.getComponentOrder(
    allComponents.map(c => ({
      id: c.id,
      productId: c.productId,
      accessoryToId: c.accessoryToId,
      properties: PropertyValueSet.toString(c.properties),
      sections: c.sections,
      sortNo: c.sortNo
    })),
    airstreams,
    connectionPoints
  );

  const sorted: Array<System.Component> = [];
  for (const id of componentOrder) {
    const component = componentsThatCouldHaveAccessories.find(c => c.id === id);
    if (component) {
      sorted.push(component);
    }
  }
  return sorted;
}

function getDefaultFilter(screenParameters: string): ReadonlyArray<string> {
  const defaultFilterRegex = /groupstoshow="(.+?)"/;
  const matches = defaultFilterRegex.exec(screenParameters);
  if (!matches) {
    return [];
  }

  const groups = matches[1].split(",").map(s => s.trim());
  return groups;
}
