import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";
import styled from "styled-components";
import {
  HeadingContainer,
  TopContainer,
  SearchOptionsComponent,
  SearchOption
} from "../../shared-manager-components";

import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../state";
import { TableIcon, H1 } from "@genesys/ui-elements";

const SearchOptionsContainer = styled.div`
  margin-top: 24px;
`;

interface MlSearchFields {
  readonly mno: string;
  readonly label: string;
  readonly name: string;
  readonly status: string;
  readonly owner: string;
  readonly mlcorg: string;
}

const searchOptions: SearchOption<MlSearchFields>[] = [
  {
    type: "text",
    option: "mno",
    textId: "mno"
  },
  {
    type: "text",
    option: "label",
    textId: "label"
  },
  {
    type: "text",
    option: "name",
    textId: "name"
  },
  {
    type: "text",
    option: "owner",
    textId: "owner"
  },
  { textId: "mlcorg", option: "mlcorg", type: "text" }
  // {
  //   option: "status",
  //   statusOptions: [
  //     {
  //       value: "0",
  //       title: "Created"
  //     },
  //     {
  //       value: "80",
  //       title: "Calculated"
  //     },
  //     {
  //       value: "90",
  //       title: "Locked"
  //     }
  //   ],
  //   type: "status-search",
  //   textId: "name"
  // }
];

export function TopContentView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <TopContainer>
      <HeadingContainer>
        <TableIcon height="1.6rem" />
        <H1 weight="normal">
          {sharedState.translate(LanguageTexts.moistureLoadManager())}
        </H1>
      </HeadingContainer>

      <SearchOptionsContainer>
        <SearchOptionsComponent
          searchOptionValues={state.searchParams.searchFilterOptions}
          searchOptions={searchOptions}
          onToggleHelpModal={() => {
            dispatch(
              Action.openModal({ type: "help-about-searching", header: "Help" })
            );
          }}
          onSearchOptionsSearch={searchKey => {
            dispatch(
              Action.setSearchParams({
                ...state.searchParams,
                searchKey: searchKey
              })
            );

            dispatch(Action.search());
          }}
          onSearchFilterOptionsToggle={() => {
            dispatch(Action.toggleIsSearchFilterOptionsOpen());
          }}
          onSearchKeyChanged={searchKey =>
            dispatch(
              Action.setSearchParams({
                ...state.searchParams,
                searchKey: searchKey
              })
            )
          }
          onSearchFilterOptionsChange={newTextFieldOptions => {
            dispatch(Action.setSearchFilterOptions(newTextFieldOptions));
          }}
          onClearSearchOptions={() => {
            dispatch(
              Action.setSearchParams({
                ...state.searchParams,
                searchKey: "",
                searchFilterOptions: { textFieldOptions: {}, useDate: false }
              })
            );
          }}
          onScopeChange={scope => {
            dispatch(
              Action.setSearchParams({
                ...state.searchParams,
                scope: scope as GraphQLTypes.MoistureLoadSearchScope
              })
            );

            dispatch(Action.changePage(1));
          }}
          sharedState={sharedState}
          selectedScope={state.searchParams.scope}
          searchKey={state.searchParams.searchKey}
          onSearch={() => dispatch(Action.search())}
          isSearchFilterOptionsOpen={state.isSearchFilterOptionsOpen}
          scopes={[
            {
              value: GraphQLTypes.MoistureLoadSearchScope.MY,
              title: sharedState.translate(LanguageTexts.myMoistureLoad())
            },
            {
              value: GraphQLTypes.MoistureLoadSearchScope.ALL,
              title: sharedState.translate(LanguageTexts.allMoistureLoads())
            }
          ]}
        />
      </SearchOptionsContainer>
    </TopContainer>
  );
}
