import * as React from "react";
import * as SharedState from "../shared-state";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { OverlayLoader } from "@genesys/ui-elements";
import { MainContentView, ModalContent, TopContentView } from "./components";

const Container = styled.div``;

export function View({
  state,
  sharedState,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <Container>
      {state.useOverlayLoader && <OverlayLoader />}
      <ModalContent
        sharedState={sharedState}
        state={state}
        dispatch={dispatch}
      />
      <TopContentView
        sharedState={sharedState}
        state={state}
        dispatch={dispatch}
      />
      <MainContentView
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />
    </Container>
  );
}

// tslint:disable-next-line: max-file-line-count
