import styled from "styled-components";

export const Root = styled.div`
  display: flex;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ErrorBox = styled.div`
  padding: 200px;
`;
