import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Amount, Quantity, Unit } from "@genesys/uom";
import * as SharedState from "../shared-state";
import { productQuery, createNewSystem, moistureLoadQuery } from "./queries";
import * as GraphQlTypes from "../graphql-types";
import * as Types from "./types";
import * as PropertiesSelector from "../properties-selector";
import { defaultProperties } from "../wizard/steps/initial-configuration";
import { promiseCmd } from "../promise-effect-manager";
import { getInitialNewProperties, propertySetOptions } from "./data";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  getProductProperties,
  canUserCreateSystemType,
  parseNewMappings,
  buildNewPropertiesSet,
  parseFlowLimits,
  parseProcessSectorMinOutletHumidities,
  parseCalculationResult,
  calculateSystem,
  getOperatingCase,
  parseCaseFilter,
  parseOpcPreProcessRows,
  calculateDehumCapacitySystem
} from "./functions";
import {
  generateSystemVariantGroups,
  getSystemVariantsWithBestSystemSelectedToBeCalculated,
  getVariantWithNewQuantityForCalculatedDehumCapacity
} from "./system-variant-functions";
import {
  getMoistureLoadNoAndRevNo,
  mapQueryResultToOpc
} from "../moisture-load-calculation";
import * as KnownProperties from "../moisture-load-calculation/tools/known-properties";
import * as Navigation from "../navigation-effect-manager";
import {
  createNewProductProperties,
  getAllowedRanges,
  filterPropertiesForRange
} from "./data";

const allowedSystemTypesInDisplayOrder = ["MAB", "MLP", "MXN", "MXO", "MCD"];

export type State = {
  readonly operatingCaseSelectorState: PropertiesSelector.State | undefined;
  readonly climateSettings: PropertyValueSet.PropertyValueSet | undefined;
  readonly systemTypes: ReadonlyArray<Types.SystemType>;
  readonly newPropertiesSet: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly systemVariantsGroups: ReadonlyArray<Types.SystemVariantsGroup>;
  readonly isLoading: boolean;
  readonly showInvalidSystems: boolean;
  readonly isCalculatingSystems: boolean;
  readonly createdSystemUrl: string | undefined;
  readonly moistureLoadInfo: Types.MoistureLoadInfo | undefined;
  readonly selectedReactHeaterView: Types.ReactHeaterType;
  readonly isCondenserSelected: boolean;
};

export const init = (
  sharedState: SharedState.State,
  mno: string
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      operatingCaseSelectorState: undefined,
      climateSettings: undefined,
      systemTypes: [],
      newPropertiesSet: [],
      systemVariantsGroups: [],
      isLoading: false,
      showInvalidSystems: false,
      isCalculatingSystems: false,
      createdSystemUrl: undefined,
      moistureLoadInfo: undefined,
      selectedReactHeaterView: "electric",
      isCondenserSelected: false
    },
    promiseCmd(
      async () => {
        const productQueryResult = await sharedState.graphQL.queryUser<
          GraphQlTypes.DrySizeProductQuery,
          GraphQlTypes.DrySizeProductQueryVariables
        >(productQuery, {
          systemTypeInput: allowedSystemTypesInDisplayOrder.map(
            systemTypeId => ({
              systemTypeId: systemTypeId
            })
          )
        });

        const [moistureLoadNo, revisionNo] = getMoistureLoadNoAndRevNo(mno);

        const moistureLoadQueryResult = await sharedState.graphQL.queryUser<
          GraphQlTypes.DrySizeMoistureLoadQuery,
          GraphQlTypes.DrySizeMoistureLoadQueryVariables
        >(moistureLoadQuery, {
          moistureLoadNo: moistureLoadNo,
          revisionNo: revisionNo
        });

        return {
          ...productQueryResult,
          moistureLoadRes: moistureLoadQueryResult
        };
      },
      res => Action.dataLoaded(res)
    )
  ];
};

export const Action = ctorsUnion({
  dispatchOperatingCaseSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dataLoaded: (data: Types.ProductQueriesData) => ({
    data
  }),
  toogleCalculateSystem: (systemIdentifier: string) => ({ systemIdentifier }),
  toogleShowInvalidSystems: () => ({}),
  createSystem: (
    createSystemInput: GraphQlTypes.CreateNewSystemsVariables
  ) => ({
    createSystemInput
  }),
  calculateDehumCapacities: (
    calculationInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.CalculateNewSystemDehumidificationCapacityVariables;
    }>
  ) => ({
    calculationInputs
  }),
  DehumCapacityReceived: (
    systemIdentifier: string,
    result: GraphQlTypes.CalculateNewSystemDehumidificationCapacity,
    calculateSystemInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.CalculateNewSystemDehumidificationCapacityVariables;
    }>
  ) => ({ systemIdentifier, result, calculateSystemInputs }),
  calculateSystems: (
    calculationInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.DrySizeCalculateNewSystemVariables;
    }>
  ) => ({
    calculationInputs
  }),
  systemCalculationReceived: (
    systemIdentifier: string,
    result: GraphQlTypes.DrySizeCalculateNewSystem,
    calculateSystemInputs: ReadonlyArray<{
      readonly systemIdentifier: string;
      readonly createSystemInput: GraphQlTypes.DrySizeCalculateNewSystemVariables;
    }>
  ) => ({ systemIdentifier, result, calculateSystemInputs }),
  setCreatedSystemUrl: (createdSystemUrl: string | undefined) => ({
    createdSystemUrl
  }),
  redirect: (url: string) => ({ url }),
  setReactHeaterView: (reactHeaterView: Types.ReactHeaterType) => ({
    reactHeaterView
  }),
  setIsCondensorSelected: (isSelected: boolean) => ({ isSelected }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchOperatingCaseSelector": {
      if (state.operatingCaseSelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.operatingCaseSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      const systemVariantsGroups = state.systemVariantsGroups.map(svg => ({
        ...svg,
        systemVariants: svg.systemVariants.map(sv => ({
          ...sv,
          calculationResults: undefined
        }))
      }));

      return [
        {
          ...state,
          operatingCaseSelectorState: propertiesSelectorState,
          systemVariantsGroups: systemVariantsGroups
        },
        Cmd.map(Action.dispatchOperatingCaseSelector, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "dataLoaded": {
      // Probably better if it crashes if its undefined
      const moistureLoadCalculation =
        action.data.moistureLoadRes?.user.moistureLoadByMoistureLoadNo!;

      const getSystemConfiguration = (): Types.SystemConfiguration => {
        const systemConfiguration = PropertyValueSet.getInteger(
          KnownProperties.systemConfiguration,
          PropertyValueSet.fromString(
            moistureLoadCalculation.moistureloadInput!.settings
          )
        );

        switch (systemConfiguration) {
          case 0:
            return "closed-system";
          case 1:
            return "closed-system-with-make-up-air";
          case 2:
            return "open-system";
          default:
            throw Error("Unknown system configuration");
        }
      };

      const systemTypes: ReadonlyArray<Types.SystemType> =
        action.data.product.systemTypes
          .filter(st => canUserCreateSystemType(st, sharedState))
          .map(st => {
            return {
              id: st.id,
              name: st.name,
              caseFilters: st.systemTypeCaseFilter.map(parseCaseFilter),
              opcProperties: getProductProperties(st, "OPC", sharedState),
              newProperties: getProductProperties(st, "NEW", sharedState),
              sysProperties: getProductProperties(st, "SYS", sharedState),
              newMappings: parseNewMappings(st.new.newPropertiesMapping),
              opcTemplates: st.templates.map(t => ({
                ...t,
                components: t.components.filter(c =>
                  c.productId.endsWith("OPC")
                )
              })),
              sysTemplates: st.templates.map(t => ({
                ...t,
                components: t.components.filter(c =>
                  c.productId.endsWith("SYS")
                )
              })),
              flowLimits: parseFlowLimits(st.new.flowLimitationsSI),
              processSectorMinOutletHumidities:
                parseProcessSectorMinOutletHumidities(
                  st.dhu.processSectorMinOutletHumidityTable
                ),
              opcPreProcessRows: parseOpcPreProcessRows(st.new.opcPreProcess)
            };
          });

      const allowedRanges = getAllowedRanges(sharedState, systemTypes);

      const newProductProperties = createNewProductProperties(
        sharedState,
        allowedRanges
      );

      const newProductPropertiesWithFilteredRange = filterPropertiesForRange(
        newProductProperties,
        allowedRanges
      );

      const moistureLoadCaseResult = mapQueryResultToOpc(
        {
          moistureLoadResult: moistureLoadCalculation.moistureLoadResult!,
          moistureloadInput: moistureLoadCalculation.moistureloadInput!
        },
        sharedState.translate
      );

      const climateSettings = PropertyValueSet.fromString(
        moistureLoadCaseResult.climateSettings
      );

      const operatingCase = getOperatingCase(
        moistureLoadCaseResult,
        climateSettings,
        sharedState.translate
      );

      const systemTypesInOrder: ReadonlyArray<Types.SystemType> =
        allowedSystemTypesInDisplayOrder.reduce((soFar, current) => {
          const systemType = systemTypes.find(st => st.id === current);
          return systemType ? soFar.concat([systemType]) : soFar;
        }, [] as ReadonlyArray<Types.SystemType>);

      const systemConfiguration = getSystemConfiguration();

      const newProperties = PropertyValueSet.merge(
        getInitialNewProperties(systemConfiguration),
        defaultProperties(newProductPropertiesWithFilteredRange)
      );

      const newPropertiesSet = buildNewPropertiesSet(
        propertySetOptions,
        newProperties
      );

      const minimumTotalSystemFlow =
        PropertyValueSet.getAmount<Quantity.MassFlow>(
          "mlcsystemtotalflowminimum",
          PropertyValueSet.fromString(
            moistureLoadCalculation.moistureloadInput?.settings ?? ""
          )
        );

      const systemVariants: ReadonlyArray<Types.SystemVariantsGroup> =
        generateSystemVariantGroups(
          newPropertiesSet,
          systemTypesInOrder,
          operatingCase,
          climateSettings,
          systemConfiguration,
          minimumTotalSystemFlow,
          sharedState.translate
        );

      const variantGroupsWithOnePreSelectedSystem =
        getSystemVariantsWithBestSystemSelectedToBeCalculated(
          systemVariants,
          systemConfiguration,
          false
        );

      const operatingCaseSelectorState = PropertiesSelector.init(operatingCase);

      const moistureLoadInfo: Types.MoistureLoadInfo = {
        systemConfiguration: systemConfiguration,
        moistureLoadId: moistureLoadCalculation.id!,
        name: moistureLoadCalculation.moistureloadFile.name || "",
        labels: moistureLoadCalculation.moistureloadFile.labels,
        minimumTotalSystemFlow: minimumTotalSystemFlow
      };

      return [
        {
          ...state,
          operatingCaseSelectorState: operatingCaseSelectorState,
          climateSettings: climateSettings,
          systemTypes: systemTypesInOrder,
          newPropertiesSet: newPropertiesSet,
          moistureLoadInfo: moistureLoadInfo,
          systemVariantsGroups: variantGroupsWithOnePreSelectedSystem
        }
      ];
    }
    case "toogleCalculateSystem": {
      return [
        {
          ...state,
          systemVariantsGroups: state.systemVariantsGroups.map(svg => ({
            ...svg,
            systemVariants: svg.systemVariants.map(s => {
              if (s.identifier === action.systemIdentifier) {
                return { ...s, shouldCalculate: !s.shouldCalculate };
              } else {
                return s;
              }
            })
          }))
        }
      ];
    }
    case "toogleShowInvalidSystems": {
      return [{ ...state, showInvalidSystems: !state.showInvalidSystems }];
    }
    case "createSystem": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.WizardCreateNewSystems,
          GraphQlTypes.WizardCreateNewSystemsVariables,
          Action
        >(createNewSystem, action.createSystemInput, res => {
          const system = res.createNewSystems[0];
          return Action.setCreatedSystemUrl(
            `/system/${sharedState.genesysPrefix.genesysNo(
              system.genesysNo,
              system.revisionNo
            )}`
          );
        }),
        [
          SharedState.Action.updateCreateSystemType([
            (
              action.createSystemInput
                .input as GraphQlTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          ]),
          SharedState.Action.updateLastCreatedSystemType(
            (
              action.createSystemInput
                .input as GraphQlTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          )
        ]
      ];
    }
    case "calculateDehumCapacities": {
      if (action.calculationInputs.length === 0) {
        return [state];
      }

      const calculationInput = action.calculationInputs[0];
      const calculationInputsQueue = action.calculationInputs.slice(1);
      return [
        state,
        promiseCmd(
          async () => {
            const result = await calculateDehumCapacitySystem(
              calculationInput.createSystemInput,
              sharedState
            );
            return result;
          },
          res =>
            Action.DehumCapacityReceived(
              calculationInput.systemIdentifier,
              res,
              calculationInputsQueue
            )
        )
      ];
    }
    case "DehumCapacityReceived": {
      const pv = PropertyValue.fromString(
        action.result.user.calculateNewSystemDehumidificationCapacity
      );
      const dehumCapacityResult =
        pv !== undefined ? PropertyValue.getAmount<"MassFlow">(pv) : undefined;

      const systemVariantsGroups = state.systemVariantsGroups.reduce(
        (soFar, current) => {
          const isCalculatedSystem =
            current.systemVariants.find(
              sv => sv.identifier === action.systemIdentifier
            ) !== undefined;

          if (!isCalculatedSystem) {
            return [...soFar, current];
          } else {
            return soFar.concat([
              {
                ...current,
                systemVariants: current.systemVariants.map(sv => {
                  if (sv.identifier !== action.systemIdentifier) {
                    return sv;
                  } else {
                    const hasCapacityResult = dehumCapacityResult !== undefined;

                    const calculatedDehumCapacity: Types.CalculatedDehumCapacity =
                      hasCapacityResult
                        ? { type: "calculated", capacity: dehumCapacityResult }
                        : { type: "failed" };

                    const isCapacityLessThanLoad = hasCapacityResult
                      ? Amount.lessThan(
                          Amount.times(dehumCapacityResult, sv.quantity),
                          sv.totalMoistureLoad
                        )
                      : true;

                    const systemVariant: Types.SystemVariant = {
                      ...sv,
                      calculatedDehumCapacity: calculatedDehumCapacity,
                      shouldCalculate: isCapacityLessThanLoad
                        ? false
                        : sv.shouldCalculate
                    };

                    if (hasCapacityResult && isCapacityLessThanLoad) {
                      const systemType = state.systemTypes.find(
                        st => st.id === systemVariant.systemType
                      )!;

                      return getVariantWithNewQuantityForCalculatedDehumCapacity(
                        state.operatingCaseSelectorState!.properties,
                        systemType,
                        systemVariant,
                        state.moistureLoadInfo!.systemConfiguration!,
                        state.climateSettings!,
                        state.moistureLoadInfo?.minimumTotalSystemFlow,
                        sharedState.translate
                      );
                    } else {
                      return systemVariant;
                    }
                  }
                })
              }
            ]);
          }
        },
        [] as ReadonlyArray<Types.SystemVariantsGroup>
      );

      if (action.calculateSystemInputs.length === 0) {
        return [
          {
            ...state,
            systemVariantsGroups:
              getSystemVariantsWithBestSystemSelectedToBeCalculated(
                systemVariantsGroups,
                state.moistureLoadInfo!.systemConfiguration!,
                true
              )
          }
        ];
      } else {
        const calculationInput = action.calculateSystemInputs[0];
        const calculationInputsQueue = action.calculateSystemInputs.slice(1);

        return [
          { ...state, systemVariantsGroups: systemVariantsGroups },
          promiseCmd(
            async () => {
              const result = await calculateDehumCapacitySystem(
                calculationInput.createSystemInput,
                sharedState
              );
              return result;
            },
            res =>
              Action.DehumCapacityReceived(
                calculationInput.systemIdentifier,
                res,
                calculationInputsQueue
              )
          )
        ];
      }
    }
    case "calculateSystems": {
      const calculationInput = action.calculationInputs[0];
      const calculationInputsQueue = action.calculationInputs.slice(1);
      return [
        { ...state, isCalculatingSystems: true },
        promiseCmd(
          async () => {
            const result = await calculateSystem(
              calculationInput.createSystemInput,
              sharedState
            );
            return result;
          },
          res =>
            Action.systemCalculationReceived(
              calculationInput.systemIdentifier,
              res,
              calculationInputsQueue
            )
        )
      ];
    }
    case "systemCalculationReceived": {
      const systemTypeCaseFilter = state.systemTypes.find(
        st => st.id === action.result.product.systemType.id
      )!.caseFilters;

      const calculationResults = parseCalculationResult(
        action.result,
        sharedState,
        systemTypeCaseFilter
      );

      const systemVariantsGroups = state.systemVariantsGroups.reduce(
        (soFar, current) => {
          const hasCalculatedSystem =
            current.systemVariants.find(
              sv => sv.identifier === action.systemIdentifier
            ) !== undefined;

          if (!hasCalculatedSystem) {
            return soFar.concat([current]);
          } else {
            return soFar.concat([
              {
                ...current,
                systemVariants: current.systemVariants.map(sv => {
                  if (sv.identifier !== action.systemIdentifier) {
                    return sv;
                  } else {
                    return {
                      ...sv,
                      calculationResults: calculationResults
                    };
                  }
                })
              }
            ]);
          }
        },
        [] as ReadonlyArray<Types.SystemVariantsGroup>
      );

      if (action.calculateSystemInputs.length === 0) {
        return [
          {
            ...state,
            systemVariantsGroups: systemVariantsGroups,
            isCalculatingSystems: false
          }
        ];
      } else {
        const calculationInput = action.calculateSystemInputs[0];
        const calculationInputsQueue = action.calculateSystemInputs.slice(1);

        return [
          { ...state, systemVariantsGroups: systemVariantsGroups },
          promiseCmd(
            async () => {
              const result = await calculateSystem(
                calculationInput.createSystemInput,
                sharedState
              );
              return result;
            },
            res =>
              Action.systemCalculationReceived(
                calculationInput.systemIdentifier,
                res,
                calculationInputsQueue
              )
          )
        ];
      }
    }
    case "setCreatedSystemUrl": {
      return [
        {
          ...state,
          createdSystemUrl: action.createdSystemUrl,
          isLoading: false
        }
      ];
    }
    case "redirect": {
      return [state, Navigation.pushUrl(action.url)];
    }
    case "setReactHeaterView": {
      return [{ ...state, selectedReactHeaterView: action.reactHeaterView }];
    }
    case "setIsCondensorSelected": {
      return [{ ...state, isCondenserSelected: action.isSelected }];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

// tslint:disable-next-line
