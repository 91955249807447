import { exhaustiveCheck } from "ts-exhaustive-check";
import { User } from "./user";
import { home } from "./home";

// -- SUBSCRIPTIONS

export type OidcClientSub<A> = ListenUserSession<A> | AccessTokenRefreshed<A>;

export interface ListenUserSession<A> {
  readonly home: typeof home;
  readonly type: "ListenUserSession";
  readonly userChanged: (user: User | undefined) => A;
}

export function listenUserSession<A>(
  userChanged: (user: User | undefined) => A
): ListenUserSession<A> {
  return {
    home: "oidc-client",
    type: "ListenUserSession",
    userChanged
  };
}

export interface AccessTokenRefreshed<A> {
  readonly home: typeof home;
  readonly type: "AccessTokenRefreshed";
  readonly userChanged: (user: User | undefined) => A;
}

export function accessTokenRefreshed<A>(
  userChanged: (user: User) => A
): AccessTokenRefreshed<A> {
  return {
    home: "oidc-client",
    type: "AccessTokenRefreshed",
    userChanged
  };
}

export function mapSub<A1, A2>(
  actionMapper: (a: A1) => A2,
  sub: OidcClientSub<A1>
): OidcClientSub<A2> {
  switch (sub.type) {
    case "ListenUserSession": {
      const mappedSub: ListenUserSession<A2> = {
        ...sub,
        userChanged: (user: User | undefined) =>
          actionMapper(sub.userChanged(user))
      };
      return mappedSub;
    }
    case "AccessTokenRefreshed": {
      const mappedSub: AccessTokenRefreshed<A2> = {
        ...sub,
        userChanged: (user: User) => actionMapper(sub.userChanged(user))
      };
      return mappedSub;
    }
    default: {
      return exhaustiveCheck(sub, true);
    }
  }
}
