import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import * as Elements from "@genesys/ui-elements";
import { Action } from "./state";
import * as ClientConfig from "../config";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";

const StyledFieldset = styled.fieldset`
  border: 1px solid #d3d3d3;
  border-radius: 5px;

  font-size: 12px;

  padding: 10px;

  legend {
    padding: 10px;
  }
`;

const ClearCacheFieldSet = styled(StyledFieldset)`
  p {
    margin: 10px 0;
    font-weight: bold;
  }

  table {
    margin-top: 10px;
    td {
      padding: 10px;
      vertical-align: middle;
    }
  }
`;

const ButtonsContainer = styled.div`
  button {
    margin-right: 16px;
  }
`;

export function ClearCache({
  dispatch,
  showConfirmModal,
  serversCleared
}: {
  readonly dispatch: Dispatch<Action>;
  readonly showConfirmModal: Boolean;
  readonly serversCleared: ReadonlyArray<string>;
}): JSX.Element {
  const isProduction = ClientConfig.clientConfig.environment === "Production";

  const onClearClick = () => {
    if (isProduction) {
      dispatch(Action.setShowConfirmModal(true));
    } else {
      dispatch(Action.clearCache());
    }
  };
  const getModalBorderColor = ClientConfig.getModalBorderColor;

  return (
    <ClearCacheFieldSet>
      {showConfirmModal && (
        <Modal
          maxWidth={400}
          onClose={() => dispatch(Action.setShowConfirmModal(false))}
        >
          <ModalHeader
            borderBottom={getModalBorderColor(
              ClientConfig.clientConfig.environment
            )}
          >
            <Elements.P2 weight="bold">
              You are about to clear cache on production
            </Elements.P2>
          </ModalHeader>

          <ModalContent>
            <ButtonsContainer>
              <Elements.StandardButton
                buttonType="Secondary-2"
                size="Small"
                onClick={() => dispatch(Action.setShowConfirmModal(false))}
              >
                Cancel
              </Elements.StandardButton>
              <Elements.StandardButton
                buttonType="Primary-2"
                size="Small"
                onClick={() => {
                  dispatch(Action.clearCache());
                  dispatch(Action.setShowConfirmModal(false));
                }}
              >
                Clear
              </Elements.StandardButton>
            </ButtonsContainer>
          </ModalContent>
        </Modal>
      )}

      <legend>Clear cache</legend>
      <Elements.StandardButton
        buttonType="Primary-2"
        size="Small"
        onClick={() => onClearClick()}
        disabled={serversCleared.length > 0}
      >
        Clear cache
      </Elements.StandardButton>
      {serversCleared.length > 0 && (
        <p>
          All caches cleared.{" "}
          <a
            onClick={e => {
              e.stopPropagation();
            }}
            href="javascript:window.location.reload(true)"
          >
            Click here to reload
          </a>
        </p>
      )}
      <table>
        <tbody>
          {serversCleared.map((s, i) => (
            <tr key={i}>
              <td>{s}</td>
              <td>cleared</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ClearCacheFieldSet>
  );
}
