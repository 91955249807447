import * as React from "react";
import * as Product from "../product";
import * as System from "../system";
import * as Types from "../types";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";
import { H2 } from "@genesys/ui-elements/";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ProductMenu } from "./product-menu";
import { CenterContainer } from "../elements";
import { clientConfig, getModalBorderColor } from "../../config";
import { getComponentReplacements } from "../component-placement";

export interface ExchangeComponentDialogProps {
  readonly productData: Product.ProductData;
  readonly system: System.System;
  readonly exchangeComponent: Types.ExchangeComponent;
  readonly onSelect: (obj: {
    readonly componentId: string;
    readonly productId: string;
    readonly componentSectionPositions: ReadonlyArray<{
      readonly productSectionId: string;
      readonly beforeSectionId: string;
      readonly airstreamId: string;
    }>;
  }) => void;
  readonly onClose: () => void;
  readonly translate: LanguageTexts.Translate;
}

export function ExchangeComponentDialog(
  props: ExchangeComponentDialogProps
): JSX.Element {
  const component = props.system.components.find(
    c => c.id === props.exchangeComponent.componentId
  )!;

  const sysProperties = props.system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const products = getComponentReplacements(
    component,
    props.productData.products,
    props.productData.systemType.positionValidation,
    props.productData.systemType.placementRules,
    props.productData.systemType.sequenceRules,
    sysProperties,
    props.system.airstreams
  );

  const handle = (productId: string) =>
    props.onSelect({
      componentId: props.exchangeComponent.componentId,
      productId: productId,
      componentSectionPositions: component.sections.reduce(
        (a, b) => {
          const productSection = props.productData.products
            .find(p => p.id === productId)!
            .sections.find(
              ps => ps.id.split(".")[1] === b.productSectionId.split(".")[1]
            );
          const airstream =
            (b.systemAirStreamId &&
              props.system.airstreams.find(
                a => a.id === b.systemAirStreamId
              )) ||
            undefined;

          const afterSection =
            productSection &&
            airstream &&
            airstream.componentSections[
              airstream.componentSections.findIndex(x => x.id === b.id) + 1
            ];

          return afterSection
            ? a.concat({
                airstreamId: airstream!.id,
                beforeSectionId: afterSection.id,
                productSectionId: productSection!.id
              })
            : a;
        },
        [] as ReadonlyArray<{
          readonly productSectionId: string;
          readonly beforeSectionId: string;
          readonly airstreamId: string;
        }>
      )
    });

  return (
    <Modal maxWidth={770} onClose={props.onClose}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <H2>{props.translate(LanguageTexts.exchangeComponent())}</H2>
      </ModalHeader>
      <ModalContent>
        <CenterContainer>
          <ProductMenu
            columns={4}
            onSelect={handle}
            productData={props.productData}
            productInfo={products.map(p => ({
              productId: p.id,
              isThereAnyOpenPosition: true
            }))}
            translate={props.translate}
            system={props.system}
          />
        </CenterContainer>
      </ModalContent>
    </Modal>
  );
}
