import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Quantity, Amount, Unit } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import * as PropertiesSelector from "../../properties-selector";

export type State = {
  readonly output: PropertyValueSet.PropertyValueSet;
  readonly input: PropertiesSelector.State;
};

export const init = (): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const input = PropertiesSelector.init(
    calculateDensity(
      PropertyValueSet.fromString(
        "pressure=101325:Pascal;temperature=20:Celsius;humidity=7.29:GramPerKilogram;density=0:KilogramPerCubicMeter"
      )
    )
  );
  const output = PropertyValueSet.fromString(
    "humidity1=7.2937:GramPerKilogram;humidity2=7.2937:GramPerKilogram;humidity3=7.2937:GramPerKilogram;humidity4=7.2937:GramPerKilogram;humidity5=7.2937:GramPerKilogram"
  );
  return [
    {
      output: output,
      input: input
    }
  ];
};

export const Action = ctorsUnion({
  dispatchInput: (action: PropertiesSelector.Action) => ({
    action
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchInput": {
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.input,
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        {
          ...state,
          input: {
            properties: calculateDensity(
              PropertiesSelector.getSelectedProperties(propertiesSelectorState)
            )
          },
          output: getOutput(
            PropertyValueSet.getAmount(
              "humidity",
              PropertiesSelector.getSelectedProperties(propertiesSelectorState)
            ) as Amount.Amount<Quantity.HumidityRatio>
          )
        },
        Cmd.map(Action.dispatchInput, propertiesSelectorCmd),
        [propertiesSelectorSharedAction]
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function calculateDensity(
  pvs: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "pressure",
    pvs
  ) as Amount.Amount<Quantity.Pressure>;
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "temperature",
    pvs
  ) as Amount.Amount<Quantity.Temperature>;
  const humidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    "humidity",
    pvs
  ) as Amount.Amount<Quantity.HumidityRatio>;

  const density = Physics.RP1485.AshraeHb2009.calculateDensityOfMoistAirMixture(
    temperature,
    pressure,
    humidity
  );

  return PropertyValueSet.merge(
    PropertyValueSet.fromProperty("density", PropertyValue.fromAmount(density)),
    pvs
  );
}

function getOutput(
  humidity: Amount.Amount<Quantity.HumidityRatio>
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.setAmount(
    "humidity1",
    humidity,
    PropertyValueSet.Empty
  );
  pvs = PropertyValueSet.setAmount("humidity2", humidity, pvs);
  pvs = PropertyValueSet.setAmount("humidity3", humidity, pvs);
  pvs = PropertyValueSet.setAmount("humidity4", humidity, pvs);
  pvs = PropertyValueSet.setAmount("humidity5", humidity, pvs);
  return pvs;
}
