import * as GraphQLTypes from "../graphql-types";
import * as SystemActions from "../system-actions";

type Extends<T, U extends T> = U;
export interface SystemActionsState {
  readonly systemId: string;
  readonly state: SystemActions.State;
}

export interface SearchParams {
  readonly scope: GraphQLTypes.SystemSearchScope;
  readonly searchKey: string;
  readonly orderByColumns: ReadonlyArray<{
    readonly name: OrderByColumn;
    readonly isDescending: boolean;
  }>;
}

export type OrderByColumn = Extends<
  Columns,
  "g-no" | "date-changed" | "system-name" | "owner" | "system-type"
>;

// export type ExcelColumn = Extends<ColumnName, "favorites" | "actions-menu">;

export interface ColumnDefinition {
  readonly columnName: Columns;
  readonly excelHeaderName?: string;
}

export type Columns =
  | "g-no"
  | "system-name"
  | "system-type"
  | "favorites"
  | "owner"
  | "date-changed"
  | "labels"
  | "status"
  | "revisions"
  | "actions-menu";

export interface SearchFilterOptions {
  readonly textFieldOptions: { readonly [key: string]: string };
  readonly useDate: boolean;
}

export type SystemRow = (SystemFileRow | SystemRevisonRow) & {
  readonly isActionMenuOpen: boolean;
  readonly isRevisionExpanded: boolean;
  readonly isDeleted: boolean;
};

export type Modal =
  | ConfirmSystemsDelete
  | MustSelectAllRevisions
  | ConfirmLabelDelete
  | HelpAboutSearching
  | SystemSummary;

export type ViewMode = "table" | "grid";

export enum SystemsPerEnum {
  six = 6,
  twelve = 12,
  sixteen = 16,
  eightteen = 18,
  twentyFour = 24,
  thirtyTwo = 32,
  fourtyEight = 48,
  sixtyFour = 64,
  oneHundredAndTwentyEight = 128
}

interface ConfirmSystemsDelete {
  readonly mode: "confirmSystemsDelete";
  readonly systemFileIds: string[];
}

interface MustSelectAllRevisions {
  readonly mode: "mustSelectAllRevisions";
}
interface ConfirmLabelDelete {
  readonly mode: "confirmLabelDelete";
  readonly label: { readonly id: string; readonly name: string };
}
interface HelpAboutSearching {
  readonly mode: "help-about-searching";
}
interface SystemSummary {
  readonly mode: "system-summary";
}
interface SystemFile {
  readonly id: string;
  readonly name: string;
  readonly genesysNo: number;
  readonly latestSystem: SystemItem;
  readonly systemTypeId: string;
  readonly systemLabel?: string | null;
  readonly systems: ReadonlyArray<SystemItem>;
  readonly labels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly owner: User;
}
interface SystemItem {
  readonly id: string;
  readonly comment?: string | null;
  readonly revisionNo: number;
  readonly status: number;
  readonly statusDate: number;
  readonly systemLabel?: string | null;
  readonly lastModifiedBy: User;
}

interface SystemFileRow extends SystemFile {
  readonly type: "file";
}

interface SystemRevisonRow extends SystemItem {
  readonly type: "revision";
  readonly genesysNo: number;
  readonly systemTypeId: string;
  readonly systemFileId: string;
  readonly systems: ReadonlyArray<SystemItem>;
}

interface User {
  readonly id: string;
  readonly userName: string;
}
