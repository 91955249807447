import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as GraphQLTypes from "../../../../graphql-types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { ToolTipWrapper } from "@genesys/ui-elements";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import { Unit, Quantity } from "@genesys/uom";
import { MoistureLoadTable, OverflowTableContainer } from "../shared/table";

const Container = styled.div`
  width: 100%;
  > div {
    margin-bottom: 30px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;
const StyledDiv = styled.div`
  font-size: 13px;
  color: #959695;
`;

const fieldGroupName = "MoistureLoadResult.ListVisualizer";

export function View({
  monthlyResults,
  summaryResults,
  sharedState,
  resultsToShow,
  onFormatChanged,
  onFormatCleared
}: {
  readonly monthlyResults: ReadonlyArray<
    GraphQLTypes.MoistureLoadResultFragment["monthlyResults"][0]
  >;
  readonly summaryResults: ReadonlyArray<
    GraphQLTypes.MoistureLoadResultFragment["summaryResults"][0]
  >;
  readonly sharedState: SharedState.State;
  readonly resultsToShow: ReadonlyArray<string>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  // const cutOff = Math.floor(numberoFColumns / 2);

  const tableCut = 1;

  const [height, setHeight] = React.useState(10);

  const thRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    setHeight(thRef.current?.getBoundingClientRect().height!);
  });

  const monthlyColumn = monthlyResults
    .map(x => ({
      results: [
        sharedState.translate(
          LanguageTexts.globalPropertyName(x.resultName.nameResourceString)
        )
      ]
    }))
    .concat(
      summaryResults.map(x => ({
        isBold: true,
        results: [
          sharedState.translate(
            LanguageTexts.globalPropertyName(x.resultName.nameResourceString)
          )
        ]
      }))
    );

  return (
    <Container>
      <FlexContainer>
        <div>
          <MoistureLoadTable
            tableHeaderHeight={`${height}px`}
            useMaxContent
            headers={[
              {
                visible: true,
                header: sharedState.translate(
                  LanguageTexts.globalPropertyName("month")
                )
              }
            ]}
            rows={monthlyColumn}
          />
        </div>

        <OverflowTableContainer>
          <MoistureLoadTable
            thRef={thRef}
            headers={[
              ...createRestOfHeaders(
                [
                  ...summaryResults.map(x =>
                    PropertyValueSet.fromString(x.propertyValueSet)
                  ),
                  ...monthlyResults.map(x =>
                    PropertyValueSet.fromString(x.propertyValueSet)
                  )
                ],
                sharedState,
                tableCut,
                resultsToShow,
                onFormatChanged,
                onFormatCleared
              )
            ]}
            rows={createRows(
              monthlyResults,
              summaryResults,
              sharedState,
              tableCut,
              resultsToShow
            )}
          />
        </OverflowTableContainer>
      </FlexContainer>
    </Container>
  );
}

function convertResultToTableRows(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: ReadonlyArray<string>,
  sharedState: SharedState.State,
  isBold: boolean
): ReadonlyArray<{
  // readonly name: React.ReactNode;
  readonly isBold?: boolean;
  readonly isHidden?: boolean;
  readonly results: ReadonlyArray<string>;
}> {
  return results.map(res => {
    const results = distinctPropertyNames.map(propertyName => {
      const propertyValue = PropertyValueSet.getValue(propertyName, res);
      if (propertyValue !== undefined) {
        if (propertyValue.type === "amount") {
          return getValue(
            propertyValue,
            sharedState.screenAmounts.getAmountFormat(
              fieldGroupName,
              propertyName,
              PropertyValue.getAmount(propertyValue)!
            )
          );
        }
        return propertyValue.value.toString();
      }
      return "";
    });

    return {
      results: results,
      isBold
    };
  });
}

function createRestOfHeaders(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  sharedState: SharedState.State,
  columnStart: number,
  resultsToShow: ReadonlyArray<string>,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
) {
  const uniquePropertieValues = getUniquePropertyValues(results);
  const distinctPropertyNames = Object.keys(uniquePropertieValues)
    .slice(columnStart, undefined)
    .filter(x => resultsToShow.includes(x));

  return distinctPropertyNames.map(propertyName => {
    const name = sharedState.translate(
      LanguageTexts.globalPropertyName("pp_" + propertyName + "_st")
    );

    const propertyValue = uniquePropertieValues[propertyName];

    return {
      header: (
        <ToolTipWrapper
          padding="0px"
          title={sharedState.translate(
            LanguageTexts.globalPropertyName("pp_" + propertyName)
          )}
        >
          <StyledDiv>{name}</StyledDiv>
        </ToolTipWrapper>
      ),
      visible: true,
      amountFormatSelector: () => {
        if (propertyValue.type !== "amount") {
          return null;
        }
        const amountFormat = sharedState.screenAmounts.getAmountFormat(
          fieldGroupName,
          propertyName,
          PropertyValue.getAmount(propertyValue)!
        );

        if (!amountFormat) {
          return null;
        }

        return (
          <FlexContainer>
            <span> [ </span>
            <AmountFormatSelector
              type="AmountFormatSelectorProps"
              fieldName={propertyName}
              fieldGroup={fieldGroupName}
              amountFormat={amountFormat}
              conversionParameters={undefined}
              translate={sharedState.translate}
              onFormatCleared={() => {
                onFormatCleared(fieldGroupName, propertyName);
              }}
              onFormatChanged={(unit, decimalCount) => {
                onFormatChanged(
                  fieldGroupName,
                  propertyName,
                  unit,
                  decimalCount
                );
              }}
            />
            <span> ] </span>
          </FlexContainer>
        );
      }
    };
  });
}

function getDistinctNames(
  result: ReadonlyArray<PropertyValueSet.PropertyValueSet>
) {
  return result.reduce((propertyNames: string[], pvs) => {
    propertyNames = propertyNames.concat(
      PropertyValueSet.getPropertyNames(pvs)
    );

    return Array.from(new Set(propertyNames));
  }, []);
}

function createRows(
  monthlyResults: ReadonlyArray<
    GraphQLTypes.MoistureLoadResultFragment["monthlyResults"][0]
  >,
  summaryResults: ReadonlyArray<
    GraphQLTypes.MoistureLoadResultFragment["summaryResults"][0]
  >,
  sharedState: SharedState.State,
  columnStart: number,
  resultsToShow: ReadonlyArray<string>
): ReadonlyArray<{
  readonly isBold?: boolean;
  readonly isHidden?: boolean;
  readonly results: ReadonlyArray<string>;
}> {
  const resultsSummaryPvs = summaryResults.map(x =>
    PropertyValueSet.fromString(x.propertyValueSet)
  );
  const summaryDistinctNames = getDistinctNames(resultsSummaryPvs)
    .slice(columnStart, undefined)
    .filter(x => resultsToShow.includes(x));
  console.log(getDistinctNames(resultsSummaryPvs));
  const monthlyResultsPvs = monthlyResults.map(x =>
    PropertyValueSet.fromString(x.propertyValueSet)
  );
  console.log(getDistinctNames(monthlyResultsPvs));
  const monthlyDistinctNames = getDistinctNames(monthlyResultsPvs)
    .slice(columnStart, undefined)
    .filter(x => resultsToShow.includes(x));

  return [
    ...convertResultToTableRows(
      monthlyResultsPvs,
      monthlyDistinctNames,
      sharedState,

      false
    ),
    ...convertResultToTableRows(
      resultsSummaryPvs,
      summaryDistinctNames,
      sharedState,

      true
    )
  ];
}

function getUniquePropertyValues(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>
): PropertyValueSet.PropertyValueSet {
  return results.reduce(
    (
      propertyValueMap: {
        // tslint:disable-next-line:readonly-keyword
        [propertyName: string]: PropertyValue.PropertyValue;
      },
      result
    ) => {
      for (const propertyName of PropertyValueSet.getPropertyNames(result)) {
        propertyValueMap[propertyName] = PropertyValueSet.get(
          propertyName,
          result
        )!;
      }

      return propertyValueMap;
    },
    {}
  ) as {
    readonly [propertyName: string]: PropertyValue.PropertyValue;
  };
}
