import * as React from "react";
import styled from "styled-components";
import { genesysSelectStylingProps, InputContainer } from "../../elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  NewRowState,
  NewRowType,
  NewPricingRow,
  PricingArticle,
  PricingRowState
} from "../../types";
import { ArticleGroupSelector } from "./article-group-selector";
import { ArticleSelector } from "./article-selector";
import { HeaderSelector } from "./header-selector";
import { SystemSelector } from "./system-selector";
import * as GraphQLTypes from "../../../graphql-types";
import {
  S2,
  Input,
  P2,
  AddComponent,
  GenesysSelect
} from "@genesys/ui-elements";

interface NewRowSelectorProps {
  readonly sharedState: SharedState.State;
  readonly newRowState: NewRowState;
  readonly pricingArticles: ReadonlyArray<PricingArticle>;
  readonly pricingGroups: ReadonlyArray<string>;
  readonly SystemPricingSearch: ReadonlyArray<
    GraphQLTypes.PricingSystemSearchQuery["user"]["systemPricingSearch"][0]
  >;
  readonly pricingRows: ReadonlyArray<PricingRowState>;
  readonly inputRowNo: number;
  readonly genesysNoPrefix: string;
  readonly onRowNoChange: (rowNo: number) => void;
  readonly onNewRowTypeChange: (addRowType: NewRowType) => void;
  readonly onNewRowStateChange: (newRowState: NewRowState) => void;
  readonly onAddRows: (rows: ReadonlyArray<NewPricingRow>) => void;
  readonly onSystemSearch: () => void;
  readonly onAddSystemRow: (systemId: string, visualizerCode: string) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px 25px 20px;
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  > div > button {
    margin-top: -3px;
  }
`;

const RowNoInput = styled(Input)`
  height: 32px;
  width: 90px;
  padding-left: 6px;
`;

export const NewRowInput = styled(Input)`
  height: 32px;
  width: 180px;
  padding-left: 6px;
`;

const SystemSearchContainer = styled.div`
  overflow: auto;
  height: 275px;
`;

const SystemSearchTable = styled.table`
  padding-top: 10px;

  td,
  th {
    padding: 0 10px;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  th {
    height: 30px;
    text-align: left;
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 30px;
    a {
      cursor: pointer;
    }
  }
`;

export function NewRowSelection({
  sharedState,
  newRowState,
  pricingArticles,
  pricingGroups,
  SystemPricingSearch,
  pricingRows,
  inputRowNo,
  genesysNoPrefix,
  onRowNoChange,
  onNewRowTypeChange,
  onNewRowStateChange,
  onAddRows,
  onSystemSearch,
  onAddSystemRow
}: NewRowSelectorProps) {
  const defaultRowNo =
    pricingRows.reduce((a, b) => (b.rowNo > a ? b.rowNo : a), 0) + 10;

  const rowNo = !Number.isNaN(inputRowNo) ? inputRowNo : defaultRowNo;

  const getRowTypeOptions = () => {
    let options = [
      {
        title: sharedState.translate(LanguageTexts.system()),
        value: "system"
      },
      {
        title: sharedState.translate(LanguageTexts.header()),
        value: "header"
      }
    ];

    if (pricingArticles.length) {
      options.push({
        title: sharedState.translate(LanguageTexts.article()),
        value: "article"
      });
    }

    if (pricingGroups.length) {
      options.push({
        title: sharedState.translate(LanguageTexts.articleGroup()),
        value: "articleGroup"
      });
    }
    return options;
  };

  return (
    <Root>
      <OuterContainer>
        <InputContainer>
          <S2 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.newRow())}
          </S2>
          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={!pricingRows.length}
            value={newRowState.type}
            onChange={e => onNewRowTypeChange(e.target.value as NewRowType)}
            options={getRowTypeOptions()}
          />
        </InputContainer>
        <InputContainer>
          <S2 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.rowNo())}
          </S2>
          <RowNoInput
            value={rowNo}
            onChange={e => onRowNoChange(parseInt(e.target.value, 10))}
          />
        </InputContainer>
        {newRowState.type === "article" && (
          <ArticleSelector
            sharedState={sharedState}
            newArticleState={newRowState}
            pricingArticles={pricingArticles}
            rowNo={rowNo}
            onChange={onNewRowStateChange}
            onAddRow={row => onAddRows([row])}
          />
        )}
        {newRowState.type === "articleGroup" && (
          <ArticleGroupSelector
            sharedState={sharedState}
            newArticleGroupState={newRowState}
            pricingArticles={pricingArticles}
            pricingGroups={pricingGroups}
            rowNo={rowNo}
            onChange={onNewRowStateChange}
            onAddRows={rows => onAddRows(rows)}
          />
        )}
        {newRowState.type === "header" && (
          <HeaderSelector
            sharedState={sharedState}
            newHeaderState={newRowState}
            rowNo={rowNo}
            onChange={onNewRowStateChange}
            onAddRow={row => onAddRows([row])}
          />
        )}
        {newRowState.type === "system" && (
          <SystemSelector
            sharedState={sharedState}
            newSystemState={newRowState}
            rowNo={rowNo}
            onChange={onNewRowStateChange}
            onAddRow={row => onAddRows([row])}
            onSystemSearch={onSystemSearch}
          />
        )}
      </OuterContainer>
      {SystemPricingSearch.length > 0 && (
        <SystemSearchContainer>
          <SystemSearchTable>
            <thead>
              <tr>
                <th>
                  <S2 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.genesysNo())}
                  </S2>
                </th>
                <th>
                  <S2 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.name())}
                  </S2>
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              {SystemPricingSearch.map(s => {
                const visualizerCode = s.genesysNo + "-" + s.revisionNo;
                return (
                  <tr key={s.id}>
                    <td>
                      <P2 weight="normal" color="secondary">
                        {genesysNoPrefix + visualizerCode}
                      </P2>
                    </td>
                    <td>
                      <P2 weight="normal" color="secondary">
                        {s.name}
                      </P2>
                    </td>
                    <td>
                      <a onClick={() => onAddSystemRow(s.id, visualizerCode)}>
                        <AddComponent height="1.6rem" />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </SystemSearchTable>
        </SystemSearchContainer>
      )}
    </Root>
  );
}
