import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import {
  changedFromBoth0,
  changedToBoth0,
  getIfIntegerChanged
} from "../../helper-functions";
import { EmissionFromCombustionIcon } from "@genesys/ui-elements";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import { LoadOperationTime } from "../../types";
//Icon
export const Icon = EmissionFromCombustionIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty =
  KnownProperties.useEmissionFromCombustionMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty =
  KnownProperties.useEmissionFromCombustionHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcemissionfromcombustion";
export const sourceName = MoistureHeatLoadTypeEnum.EMISSION_FROM_COMBUSTION;
export const illustrationPath =
  "/assets/images/moisture-and-heat-load-types/sources/emission-from-combustion.jpg";
export const description = "mlcemissionfromcombustionexplanation";
export const useLoadKey = "mlcuseemissionfromcombustion";

export const manualInputProperty =
  KnownProperties.emissionFromCombustionManualInput;

export const properties = [
  KnownProperties.useEmissionFromCombustionMoistureLoad,
  KnownProperties.useEmissionFromCombustionHeatLoad,
  KnownProperties.emissionFromCombustionManualInput,
  KnownProperties.fuelCombustionRate,
  KnownProperties.fuel,
  KnownProperties.manualWaterVaporPerFuelRatio,
  KnownProperties.waterVaporPerFuelRatio,
  KnownProperties.manualFuelEnergyDensity,
  KnownProperties.fuelEnergyDensity
];

export const overridableProperties = [
  KnownProperties.fuelCombustionRate,
  KnownProperties.fuel,
  KnownProperties.manualWaterVaporPerFuelRatio,
  KnownProperties.waterVaporPerFuelRatio,
  KnownProperties.manualFuelEnergyDensity,
  KnownProperties.fuelEnergyDensity
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.factoryHours;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  if (
    changedFromBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEmissionFromCombustionMoistureLoad,
      KnownProperties.useEmissionFromCombustionHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(1),
      defaults
    );
  } else if (
    changedToBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEmissionFromCombustionMoistureLoad,
      KnownProperties.useEmissionFromCombustionHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(0),
      defaults
    );
  }

  let useMoistureLoad = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useEmissionFromCombustionMoistureLoad
  );
  if (useMoistureLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      setMoistureLoadDefaults(useMoistureLoad!),
      defaults
    );
  }

  let useHeatLoad = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useEmissionFromCombustionHeatLoad
  );
  if (useHeatLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      setHeatLoadDefaults(useHeatLoad!),
      defaults
    );
  }

  defaults = PropertyValueSet.merge(
    setFuelEnergyDensity(PropertyValueSet.merge(defaults, currentSelections)!),
    defaults
  );
  defaults = PropertyValueSet.merge(
    setWaterVaporPerFuelRatio(
      PropertyValueSet.merge(defaults, currentSelections)!
    ),
    defaults
  );

  return defaults;
}

function setMoistureAndHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.emissionFromCombustionManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.fuel]: PropertyValue.fromInteger(0),
        [KnownProperties.fuelCombustionRate]: PropertyValue.fromAmount(
          Amount.create(0, Units.StandardCubicMeterPerSecond)
        )
      };

    case 1:
      return {
        [KnownProperties.emissionFromCombustionManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.fuel]: PropertyValue.fromInteger(1),
        [KnownProperties.fuelCombustionRate]: PropertyValue.fromAmount(
          Amount.create(1, Units.KilogramPerHour)
        )
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setMoistureLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
      return {
        [KnownProperties.manualWaterVaporPerFuelRatio]:
          PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
      return {
        [KnownProperties.manualFuelEnergyDensity]: PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setWaterVaporPerFuelRatio(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.manualWaterVaporPerFuelRatio,
      current
    ) === 0
  ) {
    return {
      [KnownProperties.waterVaporPerFuelRatio]: PropertyValue.fromAmount(
        getWaterVaporPerFuelRatioPerFuel(
          PropertyValueSet.getInteger(KnownProperties.fuel, current) || 0
        )
      )
    };
  }
  return PropertyValueSet.Empty;
}

function setFuelEnergyDensity(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.manualFuelEnergyDensity,
      current
    ) === 0
  ) {
    return {
      [KnownProperties.fuelEnergyDensity]: PropertyValue.fromAmount(
        getFuelEnergyDensityPerFuel(
          PropertyValueSet.getInteger(KnownProperties.fuel, current) || 0
        )
      )
    };
  }
  return PropertyValueSet.Empty;
}

function getWaterVaporPerFuelRatioPerFuel(value: number) {
  return Amount.create([0, 1.6, 2.2, 1.4, 1.2][value] || 0, Units.One);
}

function getFuelEnergyDensityPerFuel(value: number) {
  return Amount.create(
    [0, 13, 13, 12, 12][value] || 0,
    Units.KiloWattHourPerKilogram
  );
}
