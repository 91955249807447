import { PropertyValueSet } from "@genesys/property";
import { PropertyDefinitions, getAllPropertyUpdates } from "../tools";
import { queryUser } from "../queries";
import { Action } from "./actions";
import { SystemOverrides, BinData } from "../tools";
import { State } from "./types";
import {
  getCaseOverrideUpdates,
  caseOverridesData
} from "../tools/moisture-load-rules/case-overrides-settings";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import * as SharedState from "../../shared-state";
import * as PropertiesSelector from "../../properties-selector";
import * as LoadTypesContent from "../main-content/load-types";
import * as OperationTimeGen2 from "../../operation-time-manager";
import * as GraphQLTypes from "../../graphql-types";
import * as ClimateSettingsEditor from "../components/climate-settings-editor";
import * as Main from "../main-content";

export function getUpdatedProperties(
  newPropertiesSelectorState: PropertiesSelector.State,
  oldProperties: PropertyValueSet.PropertyValueSet,
  propertyDefinitions: PropertyDefinitions
) {
  const currentProperies = PropertiesSelector.getSelectedProperties(
    newPropertiesSelectorState!
  );
  return PropertyValueSet.merge(
    getAllPropertyUpdates(
      oldProperties,
      currentProperies,
      propertyDefinitions,
      true
    ),
    currentProperies
  );
}

export function getNewSystemOverrides(
  prevProperties: PropertyValueSet.PropertyValueSet,
  currentProperties: PropertyValueSet.PropertyValueSet,
  currentSystemOverrides: SystemOverrides,
  updatedValues: PropertyValueSet.PropertyValueSet
) {
  const cleanUpOverrides = caseOverridesData.every(
    ip =>
      PropertyValueSet.hasProperty(ip.manualInputProperty, currentProperties) &&
      PropertyValueSet.getInteger(ip.manualInputProperty, currentProperties) ===
        0
  );

  return (
    Object.keys(
      GraphQLTypes.OperatingCaseGroupEnum
    ) as ReadonlyArray<GraphQLTypes.OperatingCaseGroupEnum>
  ).reduce(
    (
      soFar: {
        // tslint:disable-next-line:readonly-keyword
        [key: string]: PropertyValueSet.PropertyValueSet;
      },
      operatingCaseResultGroup
    ) => {
      if (cleanUpOverrides) {
        soFar[operatingCaseResultGroup] = currentProperties;
        return soFar;
      }

      const prevOverrideForResultGroup =
        (currentSystemOverrides &&
          currentSystemOverrides[operatingCaseResultGroup]) ||
        currentProperties;

      // Först setdefaults
      const result = getCaseOverrideUpdates(
        operatingCaseResultGroup,
        prevProperties,
        currentProperties,
        prevOverrideForResultGroup!,
        updatedValues
      );

      soFar[operatingCaseResultGroup] = PropertyValueSet.merge(
        result,
        prevOverrideForResultGroup!
      );
      return soFar;
    },
    {}
  ) as SystemOverrides;
}
//TODO:put inside of loads
export function getLoadsOperationTimes(state: State) {
  const alLoadTypes = Object.keys(
    MoistureHeatLoadTypeEnum
  ) as ReadonlyArray<MoistureHeatLoadTypeEnum>;

  const selectedLoads = new Map(
    LoadTypesContent.getCurrentSelectedLoads(
      state.mainContentState?.loadTypesContentState!
    ).map(i => [i.sourceName, i])
  );

  const baseOperationtimes = LoadTypesContent.getBaseOperationTimes();

  //TODO: DO we need to save all?
  return alLoadTypes.map(x => {
    const operationTimeState = selectedLoads.get(x)?.operationTimeState;

    return {
      sourceName: x,
      operationTimeState: operationTimeState || baseOperationtimes[x]
    };
  });
  // const remainingLoads = LoadTypesContent.getAllLoads(
  //   state.mainContentState?.loadTypesContentState!
  // ).filter(x => !selectedLoads.has(x.sourceName));

  // return Array.from(selectedLoads.values())
  //   .map(x => ({
  //     sourceName: x.sourceName,
  //     operationTimeState: x.operationTimeState
  //   }))
  //   .concat(
  //     remainingLoads.map(x => ({
  //       sourceName: x.sourceName,
  //       operationTimeState: OperationTimeGen2.init(
  //         LoadTypesContent.getBaseOperationTimes()[x.sourceName]
  //       )[0]
  //     }))
  //   );
}

export function createMoistureLoadInputSettingsForCalc(
  state: State,
  binDataInfo: BinData,
  loadsOperationTime: ReadonlyArray<{
    readonly sourceName: GraphQLTypes.MoistureHeatLoadTypeEnum;
    readonly operationTimeState: OperationTimeGen2.State;
  }>,

  caseOverrides: ReadonlyArray<{
    readonly operatingCaseGroup: GraphQLTypes.OperatingCaseGroupEnum;
    readonly moistureLoadSettings: string;
  }>
) {
  return {
    moistureLoadSettings: PropertyValueSet.toString(
      PropertiesSelector.getSelectedProperties(state.propertiesSelectorState!)
    ),
    // System operation time as stringified property value set.
    systemOperationTime: PropertyValueSet.toString(
      OperationTimeGen2.toPropertyValueSet(
        Main.getUseCaseAndBuildingConfigState(state.mainContentState!)!
          .operationTimeState!
      )
    ),
    // List of case specific settings.
    caseOverrides: caseOverrides,
    // Bin locaiton id.
    binDataLocationId: binDataInfo.locationId,
    // **BIN** data type (i.e. DB/MCWB etc).
    climateDataCoolingType: binDataInfo.dataType,
    // Bin size.
    binSize: binDataInfo.binSize,
    // List of load specific operation times.
    operationTimes: loadsOperationTime
      // .filter(
      //   x =>
      //     x.sourceName !==
      //     GraphQLTypes.MoistureHeatLoadTypeEnum.DIFFUSION_THROUGH_MATERIALS
      // )
      .map(load => ({
        loadType: load.sourceName,
        operationTimeSettings: PropertyValueSet.toString(
          OperationTimeGen2.toPropertyValueSet(load.operationTimeState!)
        )
      })),
    climateDesignData: PropertyValueSet.toString(
      ClimateSettingsEditor.getClimateSettings(
        state.climateSettingsEditorState!
      )!
    )
  };
}

export function fechUserQuery(
  sharedState: SharedState.State,
  moistureLoadNo: number,
  revisionNo: number
) {
  return sharedState.graphQL.queryUser<
    GraphQLTypes.MoistureLoadCalculationUserQuery,
    GraphQLTypes.MoistureLoadCalculationUserQueryVariables
  >(queryUser, {
    moistureLoadNo: moistureLoadNo,
    revisionNo: revisionNo
  });
}

export function fechUserQueryCmd(
  sharedState: SharedState.State,
  moistureLoadNo: number,
  revisionNo: number
) {
  return sharedState.graphQL.queryUserCmd<
    GraphQLTypes.MoistureLoadCalculationUserQuery,
    GraphQLTypes.MoistureLoadCalculationUserQueryVariables,
    Action
  >(
    queryUser,
    {
      moistureLoadNo: moistureLoadNo,
      revisionNo: revisionNo
    },
    Action.userQueryRecieved
  );
}
