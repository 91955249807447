import * as React from "react";
import styled from "styled-components";
import { Pop, ArrowDown, CheckBox } from "@genesys/ui-elements";

const StyledSelect = styled.div`
  box-sizing: border-box;
  height: 44px;
  width: 290px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3d3d3d;
    padding-left: 20px;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
  }
`;

const DropDownContainer = styled.div`
  width: 290px;
`;

const StyledUl = styled.ul`
  overflow-y: auto;
  max-height: 283px;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledLi = styled.li<{
  readonly isChecked: boolean;
}>`
  padding-left: 12px;
  padding-top: 2px;
  padding-bottom: 2px;

  margin-top: 6px;

  ${props => props.isChecked && "background-color: #F1F5FA;"};
`;

const BoldP = styled.p`
  font-weight: bold;
`;

const StyledP = styled.p`
  font-size: 16px;
  letter-spacing: 5px;
  margin-bottom: 4px;
  margin-top: 7px;
`;

export interface SelectItem {
  readonly title: string;
  readonly isChecked: boolean;
  readonly isDisabled: boolean;
  readonly onClick: () => void;
}

export interface Props {
  readonly title: string;
  readonly color: string;
  readonly isAllSelected: boolean;
  readonly selectItems: ReadonlyArray<SelectItem>;
  readonly onSelectAll: () => void;
}

export function SelectBox(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <StyledP style={{ color: props.color }}>{props.title}</StyledP>
      <StyledSelect onClick={handleClick}>
        <label>
          {getSelectBoxTitle(
            props.isAllSelected,
            props.title,
            props.selectItems
          )}
        </label>
        <ArrowDown />
      </StyledSelect>
      <DropDown {...props} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div>
  );
}

function DropDown({
  anchorEl,
  selectItems,
  isAllSelected,
  onSelectAll,
  setAnchorEl
}: {
  readonly anchorEl: Element | null;
  readonly title: string;
  readonly selectItems: ReadonlyArray<SelectItem>;
  readonly isAllSelected: boolean;
  readonly onSelectAll: () => void;
  readonly setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}) {
  if (!anchorEl) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Pop
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchor={anchorEl}
      onClose={handleClose}
    >
      <DropDownContainer>
        <StyledUl>
          <StyledLi isChecked={isAllSelected}>
            <CheckBox isChecked={isAllSelected} onClick={onSelectAll}>
              <BoldP>Select All</BoldP>
            </CheckBox>
          </StyledLi>
          {selectItems.map(x => (
            <StyledLi key={x.title} isChecked={x.isChecked}>
              <CheckBox isChecked={x.isChecked} onClick={x.onClick}>
                {x.title}
              </CheckBox>
            </StyledLi>
          ))}
        </StyledUl>
      </DropDownContainer>
    </Pop>
  );
}

function getSelectBoxTitle(
  isAllSelected: boolean,
  name: string,
  selectItems: readonly SelectItem[]
) {
  if (isAllSelected) {
    return "All " + name + " selected";
  }

  const selectedItems = selectItems.filter(x => x.isChecked);

  if (selectedItems.length === 0) {
    return "Select " + name;
  }

  return selectedItems.map((x, i) => {
    let value = x.title;

    if (i < selectedItems.length - 1) {
      value = value + ", ";
    }

    return value;
  });
}
