import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as GraphQlTypes from "../../../../graphql-types";
import * as HelperFunctions from "../helper-functions";
import * as Product from "../../../product";
import * as System from "../../../system";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { SelectedOptionsType } from "../types";
import { CurrencyAndSalesOrgSelector } from "./sub-views/currency-and-sales-org";

export function SystemHasNoPrice({
  priceProductData,
  sharedState,
  selectedOptions,
  isDisabled,
  systemType,
  system,
  dispatch
}: {
  readonly selectedOptions: SelectedOptionsType;
  readonly sharedState: SharedState.State;
  readonly priceProductData: GraphQlTypes.PriceProduct;
  readonly systemType: Product.SystemType;
  readonly dispatch: Dispatch<Action>;
  readonly system: System.System;
  readonly isDisabled: boolean;
}) {
  const sys = system.components.find(c => c.productId.endsWith("SYS"))!;
  const showMaster = HelperFunctions.checkIfMasterSettings(
    systemType,
    sys.properties
  );
  const showMasterSettings =
    showMaster &&
    AuthorizationTools.checkPermission(
      sharedState.user.applicationClaims,
      AuthorizationTools.priceClaims.canSeeMasterSettings,
      AuthorizationTools.contexts.genesys
    );

  return (
    <CurrencyAndSalesOrgSelector
      showMasterSettings={showMasterSettings}
      selectedOptions={selectedOptions}
      isDisabled={isDisabled}
      sharedState={sharedState}
      dispatch={dispatch}
      selectableSalesOrganisations={priceProductData.product.salesOrganisations.filter(
        s => sharedState.user.salesOrganisations.some(ss => s.id === ss.id)
      )}
    />
  );
}
