import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import { changedTo1 } from "../../helper-functions";
import { UnintendedVentilationIcon } from "@genesys/ui-elements";
import { LoadOperationTime } from "../../types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";

//Icon
export const Icon = UnintendedVentilationIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty =
  KnownProperties.useUnintendedVentilationMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useUnintendedVentilationHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcunintendedventilation";
export const sourceName = MoistureHeatLoadTypeEnum.UNINTENDED_VENTILATION;

export const description = "mlcunintendedventilationexplanation";
export const useLoadKey = "mlcuseunintendedventilation";

export const manualInputProperty =
  KnownProperties.unintendedVentilationManualInput;

export const properties = [
  KnownProperties.useUnintendedVentilationMoistureLoad,
  KnownProperties.useUnintendedVentilationHeatLoad,
  KnownProperties.unintendedVentilationManualInput
];

export const overridableProperties = [];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.allDay;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
) {
  let defaults = PropertyValueSet.Empty;

  if (
    changedTo1(
      prevSelections,
      currentSelections,
      KnownProperties.systemOverPressure
    )
  ) {
    defaults = PropertyValueSet.merge(
      moistureLoadSystemOverPressureDefaults(1),
      defaults
    );
  }

  return defaults;
}

function moistureLoadSystemOverPressureDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return PropertyValueSet.Empty;

    case 1:
      return {
        [KnownProperties.useUnintendedVentilationMoistureLoad]:
          PropertyValue.fromInteger(0),
        [KnownProperties.useUnintendedVentilationHeatLoad]:
          PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

// tslint:disable-next-line:max-file-line-count
