import * as GraphQLEntityCache from "gql-cache";

export const getObjectToId: GraphQLEntityCache.GetObjectId = (object: {
  readonly id?: string;
  readonly __typename: string;
}) => {
  if (object.__typename === "ProductDataType") {
    return "ProductDataType";
  }

  if (object.__typename === "UserType") {
    return "UserType";
  }

  if (object.__typename === undefined) {
    throw new Error("Required field __typename is missing");
  }

  // TODO

  // if (object.id !== undefined) {
  //   return `${object.__typename};${object.id}`;
  // }

  // throw new Error(
  //   `Object does not have id property: ${JSON.stringify(object)}`
  // );

  return GraphQLEntityCache.defaultGetObjectId(object);
};
