import * as GQLCachePatch from "gql-cache-patch";
import * as MutationsQueue from "@genesys/graphql-mutations-queue";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as GraphQLMutations from "../graphql-mutations";

export function setCultureQueuedMutation(
  settings: {
    readonly currency?: string;
    readonly language?: string;
    readonly locale?: string;
  },
  userProfileId: string
): MutationsQueue.QueuedMutation<
  GraphQLTypes.SetUserSettingsCulture,
  GraphQLTypes.SetUserSettingsCultureVariables
> {
  const settingsKey = `UserSettingsCultureType;settings-culture:${userProfileId}`;
  const cachePatches: Array<GQLCachePatch.CachePatch> = [];

  if (settings.language) {
    const languageKey = `LanguageType;${settings.language}`;
    cachePatches.push(
      GQLCachePatch.updateField<
        GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["userSettings"]["culture"]
      >(settingsKey, "language", languageKey)
    );
  }

  if (settings.currency) {
    const currencyKey = `CurrencyType;${settings.currency}`;
    cachePatches.push(
      GQLCachePatch.updateField<
        GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["userSettings"]["culture"]
      >(settingsKey, "currency", currencyKey)
    );
  }

  if (settings.locale) {
    const localKey = `CurrencyType;${settings.locale}`;
    cachePatches.push(
      GQLCachePatch.updateField<
        GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["userSettings"]["culture"]
      >(settingsKey, "locale", localKey)
    );
  }

  return {
    document: GraphQLMutations.setUserSettingsCultureMutation,
    variables: {
      userSettingsCulture: settings
    },
    optimisticCachePatches: cachePatches
  };
}

export function setInitialSettings(
  culture: {
    readonly currency: string;
    readonly language: string;
    readonly locale: string;
  },
  activeAmountProfileId: string,
  climate: {
    readonly countryName: string;
    readonly regionName: string;
    readonly locationId: string;
  }
): MutationsQueue.QueuedMutation<
  GraphQLTypes.SetInitialSettings,
  GraphQLTypes.SetInitialSettingsVariables
> {
  return {
    document: GraphQLMutations.setInitialSettingsMutation,
    variables: {
      amountProfileId: activeAmountProfileId,
      userSettingsCulture: culture,
      userSettingsClimate: climate
    }
  };
}

export function setDefaultSalesOrganisation(
  defaultSalesOrganisationCode: string | null,
  userProfileId: string
): MutationsQueue.QueuedMutation<
  GraphQLTypes.SetUserSettingsSales,
  GraphQLTypes.SetUserSettingsSalesVariables
> {
  const cacheKey = `UserSettingsSalesType;settings-sale-${userProfileId}`;
  return {
    document: GraphQLMutations.setUserSettingsSalesMutation,
    variables: {
      userSettingsSales: {
        defaultSalesOrganisationCode
      }
    },
    optimisticCachePatches: [
      GQLCachePatch.updateField<
        GraphQLTypes.SetUserSettingsSales["setUserSettingsSales"]["sales"]
      >(cacheKey, "defaultSalesOrganisation", defaultSalesOrganisationCode)
    ]
  };
}

export function updateWizardSettings(
  lastCreatedSystemType: string,
  lastSelectedLabels: ReadonlyArray<string>,
  userProfileId: string
): MutationsQueue.QueuedMutation<
  GraphQLTypes.UpdateWizardSettings,
  GraphQLTypes.UpdateWizardSettingsVariables
> {
  const cacheKey = `WizardSettingsType;wizard-settings-${userProfileId}`;
  return {
    document: GraphQLMutations.updateWizardSettingsMutation,
    variables: {
      wizardSettings: {
        lastCreatedSystemType,
        lastSelectedLabels
      }
    },
    optimisticCachePatches: [
      GQLCachePatch.updateField<
        GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["userSettings"]["wizardSettings"]
      >(cacheKey, "lastCreatedSystemType", lastCreatedSystemType),
      GQLCachePatch.updateField<
        GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["userSettings"]["wizardSettings"]
      >(cacheKey, "lastSelectedLabels", lastSelectedLabels)
    ]
  };
}

export function resetUserSettings(): MutationsQueue.QueuedMutation<
  GraphQLTypes.ResetUserSettings,
  GraphQLTypes.ResetUserSettingsVariables
> {
  return {
    document: GraphQLMutations.resetUserSettingsMutation,
    variables: {}
  };
}
