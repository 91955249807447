import { Quantity, Units } from "@genesys/uom";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { Amount } from "uom";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as PromasterSdkProperty from "@promaster-sdk/property";
import * as QuantityConverison from "@genesys/shared/lib/quantity-conversion";

export function getErrorMessage(
  value: Amount.Amount<unknown> | undefined,
  validationFilter: PropertyFilter.PropertyFilter,
  propertyValueSet: PropertyValueSet.PropertyValueSet,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  filterPrettyPrint: PropertyFiltering.FilterPrettyPrint
): string {
  const errorMessage =
    conversionParameters === undefined
      ? getValidationMessage(
          propertyValueSet,
          value,
          validationFilter,
          filterPrettyPrint
        )
      : [
          humidityValidationMessage(
            maybeHumidityValidation(conversionParameters, value)
          ),
          getValidationMessageWithComparer(
            propertyValueSet,
            value,
            validationFilter,
            filterPrettyPrint
          )
        ].reduce((a, b) => (a ? a : b), "");

  return errorMessage;
}

function getValidationMessageWithComparer(
  propertyValueSet: PropertyValueSet.PropertyValueSet,
  value: Amount.Amount<unknown> | undefined,
  validationFilter: PropertyFilter.PropertyFilter,
  filterPrettyPrint: PropertyFiltering.FilterPrettyPrint
): string {
  if (!value || !validationFilter) {
    return "";
  }

  if (
    PromasterSdkProperty.PropertyFilter.isValid(
      propertyValueSet,
      validationFilter,
      PromasterSdkProperty.PropertyValue.defaultComparer
    )
  ) {
    return "";
  } else {
    return filterPrettyPrint(validationFilter);
  }
}

function getValidationMessage(
  propertyValueSet: PropertyValueSet.PropertyValueSet,
  value: Amount.Amount<unknown> | undefined,
  validationFilter: PropertyFilter.PropertyFilter,
  filterPrettyPrint: PropertyFiltering.FilterPrettyPrint
): string {
  if (!value || !validationFilter) {
    return "";
  }

  if (PropertyFilter.isValid(propertyValueSet, validationFilter)) {
    return "";
  } else {
    return filterPrettyPrint(validationFilter);
  }
}

function humidityValidationMessage(
  hum: Amount.Amount<Quantity.RelativeHumidity> | undefined
): string {
  if (!hum) {
    return "";
  }
  return "Invalid humidity";
}

function maybeHumidityValidation(
  conversionParameters: QuantityConverison.ConversionParameters | undefined,
  value: Amount.Amount<unknown> | undefined
): Amount.Amount<Quantity.RelativeHumidity> | undefined {
  if (
    !conversionParameters ||
    !value ||
    value.unit.quantity === "MassFlow" ||
    value.unit.quantity === "VolumeFlow"
  ) {
    return undefined;
  }

  let relativeHum: Amount.Amount<"RelativeHumidity"> | undefined = undefined;
  try {
    relativeHum =
      value.unit.quantity === "RelativeHumidity"
        ? (value as Amount.Amount<Quantity.RelativeHumidity>)
        : QuantityConverison.convertQuantity(
            value as Amount.Amount<Quantity.RelativeHumidity>,
            "RelativeHumidity",
            conversionParameters
          );
  } catch (error) {
    return;
  }

  if (!relativeHum) {
    return;
  }

  return Amount.greaterThan(
    relativeHum,
    Amount.create(0.9995, Units.HumidityFactor)
  )
    ? relativeHum
    : undefined;
}
