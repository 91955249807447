import * as React from "react";
import styled from "styled-components";
import {
  // Input,
  StandardButton,
  ArrowDownBlue,
  SearchIcon,
  GenesysSelect,
  zIndex,
  PrimaryColors,
  TextBox
} from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
// import * as GraphQLTypes from "../../graphql-types";

import { SearchOptionsComponentProps } from "./types";
import { SearchFilterView } from "./components";

const SearchFilterOuterContainer = styled.div`
  z-index: ${zIndex.Dropdown};

  width: 289px;
  border: ${(props: Props) =>
    props.isExpanded ? "1px solid #c5d0e5" : "1px solid #f7f9fc"};

  box-shadow: ${(props: Props) =>
    props.isExpanded ? "0 5px 5px 0 rgba(0, 0, 0, 0.1)" : "none"};
  border-radius: 4px;
  background-color: #f7f9fc;
  margin: 0 8px;
`;

const Root = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const SearchInputContainer = styled.div`
  width: 286px;
  height: 38px;
  border-radius: 4px;
  background-color: inherit;
  display: flex;
  align-items: center;
  img {
    width: 28px;
    height: 28px;
    margin: 0 14px 0 7px;
  }
  input {
    width: 207px;
    height: 22px;
    color: #8f9bb3;
    background-color: inherit;
    border: none;
    padding: 0;

    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;

    &:focus {
      border: none;
    }
  }
  button {
    width: 30px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    background-color: inherit;
    cursor: pointer;
    img {
      height: 6px;
      width: 11px;
      margin: 0 0 0 1px;
    }
    :hover {
      background: ${PrimaryColors.lightBlue};
    }
  }
`;

interface Props {
  readonly isExpanded: boolean;
}

export function SearchOptionsComponent<T>(
  props: SearchOptionsComponentProps<T>
) {
  const {
    onSearchFilterOptionsToggle,
    onScopeChange,
    onSearch,
    onSearchKeyChanged,

    sharedState,
    searchKey,
    isSearchFilterOptionsOpen,
    selectedScope,
    scopes
  } = props;

  // const onSearchWrapper = (e: any) => {
  //   onSearchKeyChanged((e.target as any).value);
  //   onSearch();
  // };

  return (
    <Root>
      <GenesysSelect
        width={150}
        height={38}
        fontSize={16}
        value={selectedScope}
        options={scopes}
        onChange={e => onScopeChange(e.target.value)}
      />

      <SearchFilterOuterContainer isExpanded={isSearchFilterOptionsOpen}>
        <SearchInputContainer>
          <SearchIcon />
          <SearchInput
            placeholder={sharedState.translate(LanguageTexts.search())}
            value={searchKey}
            // onChange={e => onSearchKeyChanged(e.target.value)}
            onChange={e => onSearchKeyChanged(e)}
            onSearch={() => onSearch()}
            // onDebounceValueChange={[e => onSearchKeyChanged(e), [props], 250]}
            // onKeyDown={e => {
            //   if (e.keyCode === 13) {
            //     onSearchWrapper(e);
            //   }
            // }}
          />
          <button
            onClick={() =>
              onSearchFilterOptionsToggle(!isSearchFilterOptionsOpen)
            }
          >
            <ArrowDownBlue />
          </button>
        </SearchInputContainer>
        <SearchFilterView {...(props as any)} />
      </SearchFilterOuterContainer>

      <StandardButton
        size="Large"
        buttonType="Primary-2"
        onClick={() => onSearch()}
      >
        {sharedState.translate(LanguageTexts.search())}
      </StandardButton>
    </Root>
  );
}

function SearchInput(props: {
  readonly value: string;
  readonly placeholder: string;
  readonly onSearch: () => void;
  readonly onChange: (value: string) => void;
}) {
  const { value, placeholder, onChange, onSearch } = props;

  React.useEffect(() => {
    setState({ value });
  }, [value]);

  const [state, setState] = React.useState({
    value
  });

  function internalOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const element = event.target as HTMLInputElement;

    setState({
      value: element.value
    });
  }

  return (
    <TextBox
      value={state.value}
      placeholder={placeholder}
      onBlur={() => onChange(state.value)}
      onChange={event => internalOnChange(event)}
      hasError={"none"}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          onChange(state.value);
          onSearch();
        }
      }}
      errorMessage=""
    />
  );
}
