import * as React from "react";
import * as Product from "../product";
import * as System from "../system";
import * as SystemPerformance from "@genesys/client-core/lib/system-performance";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { Action } from "./state";
import { AmountFormatSelector } from "../../amount-format-selector";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  GenericTable,
  GenericTableRowType,
  GenericTableColumnDefinitionType
} from "@genesys/ui-elements";
import { PropsWithOpc } from "./types";
import { OpCase } from "@genesys/client-core/src/system-performance";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { Amount, Quantity, Unit } from "@genesys/uom";
import { Translate } from "@genesys/shared/src/language-texts";

interface TableColumns {
  readonly name: string;
  readonly value: string;
}

interface TemplateRow {
  readonly name: string;
  readonly type: Product.GenericExtraInfoSource;
  readonly group: string;
  readonly value: PropertyValue.PropertyValue;
  readonly sortNo: number;
  readonly productId: string;
  readonly component: System.Component;
}

interface FieldGroupFieldName {
  readonly fieldGroup: string;
  readonly fieldName: string;
}

type ExtraInfoTableRow = Product.GenericPerfExtraInfoResult & {
  readonly productKey: string;
};

interface CellAttrbutes {
  readonly fieldName: string;
  readonly fieldGroup: string;
  readonly propertyValue: PropertyValue.PropertyValue;
  readonly amountFormat: ScreenAmounts.AmountFormat | undefined;
  readonly conversionParam: QuantityConversion.ConversionParameters | undefined;
}

type GetAmountFormatFunc = (
  fieldGroup: string,
  fieldName: string,
  amountOrQuantity: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
) => ScreenAmounts.AmountFormat;

export function ExtraInfoTable(props: PropsWithOpc): JSX.Element | null {
  const {
    systemType,
    opCases,
    selectedOpCaseId,
    systemItem,
    sharedState,
    dispatch
  } = props;

  const currentOpCase = opCases.find(opCase => opCase.id === selectedOpCaseId)!;

  const components = systemItem.components;
  const genericPerfExtraInfoRows: ReadonlyArray<ExtraInfoTableRow> =
    systemType.products
      .filter(product => product.genericPerfExtraInfoResults.length > 0)
      .map(product => {
        return product.genericPerfExtraInfoResults.map(x => ({
          ...x,
          productKey: product.id
        }));
      })
      .flat();

  if (genericPerfExtraInfoRows.length === 0) {
    return null;
  }

  const templateRows = createTemplateRows(
    genericPerfExtraInfoRows,
    currentOpCase,
    components,
    sharedState.translate
  )
    .slice()
    .sort((a, b) => a.sortNo - b.sortNo);

  if (templateRows.length === 0) {
    return null;
  }

  return (
    <div>
      <GenericTable
        columns={generateColumns(
          sharedState.translate,
          (fieldGroup, fieldName) =>
            dispatch(Action.FormatCleared(fieldGroup, fieldName)),
          (fieldGroup, fieldName, unit, decimalCount) =>
            dispatch(
              Action.FormatChanged(fieldGroup, fieldName, unit, decimalCount)
            )
        )}
        data={createTableRowDataFromTemplateRows(
          templateRows,
          currentOpCase,
          systemType.systemType.systemTypeResults,
          sharedState.translate,
          sharedState.screenAmounts.getAmountFormat,
          (fieldGroup, fieldName) =>
            dispatch(Action.FormatCleared(fieldGroup, fieldName))
        )}
      />
    </div>
  );
}

function generateColumns(
  translate: Translate,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void
): ReadonlyArray<
  GenericTableColumnDefinitionType<TableColumns, CellAttrbutes>
> {
  return [
    {
      header: "",
      key: "name",
      customCellRenderer: (cellData, attributes) => {
        if (!attributes || !attributes.amountFormat) {
          return cellData;
        }
        const { fieldName, fieldGroup, amountFormat, conversionParam } =
          attributes;
        return (
          <span>
            {cellData}[{" "}
            <AmountFormatSelector
              type="AmountFormatSelectorProps"
              fieldName={fieldName}
              fieldGroup={fieldGroup}
              amountFormat={amountFormat}
              conversionParameters={conversionParam}
              translate={translate}
              onFormatCleared={() => {
                onFormatCleared(fieldGroup, fieldName);
              }}
              onFormatChanged={(unit, decimalCount) => {
                onFormatChanged(fieldGroup, fieldName, unit, decimalCount);
              }}
            />{" "}
            ]
          </span>
        );
      }
    },
    {
      header: "",
      key: "value"
    }
  ];
}

function createTemplateRows(
  extraInfoTableRow: ReadonlyArray<ExtraInfoTableRow>,
  currentOpCase: OpCase,
  components: ReadonlyArray<System.Component>,
  translate: Translate
): ReadonlyArray<TemplateRow> {
  return extraInfoTableRow
    .map(row => {
      const comp = components.find(c => c.productId === row.productKey);
      if (!comp) {
        return null;
      }
      if (!PropertyFilter.isValid(comp.properties, row.propertyFilter)) {
        return null;
      }

      const value = getRowValue(row, currentOpCase, comp, translate);
      if (!value) {
        return null;
      }

      return {
        name: row.name,
        type: row.source,
        group: row.group,
        sortNo: row.sortNo,
        component: comp,
        productId: row.productKey,
        value: value
      };
    })
    .filter((row): row is TemplateRow => row !== null); // Type guard to filter out null values
}

function amountFormatWrapper(
  propertyValue: PropertyValue.PropertyValue | undefined,
  fieldGroup: string,
  fieldName: string,
  getAmountFormat: GetAmountFormatFunc
) {
  const amountFormat =
    propertyValue?.type === "amount"
      ? getAmountFormat(
          fieldGroup,
          fieldName,
          propertyValue?.value as Amount.Amount<Quantity.Quantity>
        )
      : undefined;

  return amountFormat;
}

function getRowValue(
  row: ExtraInfoTableRow,
  currentOpCase: OpCase,
  component: System.Component,
  translate: Translate
): PropertyValue.PropertyValue | null | undefined {
  const name = row.name;

  switch (row.source) {
    case "Property": {
      return PropertyValueSet.getValue(row.name, component.properties);
    }

    case "Result": {
      const resultsByComponentId =
        SystemPerformance.getResultByComponentId(currentOpCase);

      const resultForComponent = resultsByComponentId.get(component.id);

      return resultForComponent
        ? PropertyValueSet.get(name, resultForComponent.output)
        : null;
    }

    case "Specification": {
      const spec = component.componentSpec;
      return PropertyValueSet.getValue(row.name, spec);
    }

    case "FreeText": {
      return PropertyValue.create("text", row.name);
    }

    case "TextId": {
      return PropertyValue.create(
        "text",
        translate(LanguageTexts.dynamicText(row.name, component.productId))
      );
    }

    default: {
      return exhaustiveCheck(row.source);
    }
  }
}

function createTableRowDataFromTemplateRows(
  templateRows: ReadonlyArray<TemplateRow>,
  currentOpCase: SystemPerformance.OpCase,
  systemTypeResults: ReadonlyArray<Product.SystemTypeResults>,
  translate: Translate,
  getAmountFormat: GetAmountFormatFunc,
  resetAmount: (fieldGroup: string, fieldName: string) => void
): ReadonlyArray<GenericTableRowType<TableColumns, CellAttrbutes>> {
  const tableRows: Array<GenericTableRowType<TableColumns, CellAttrbutes>> = [];

  templateRows.forEach(row => {
    const fieldGroupFieldName = ScreenAmounts.getFieldGroupFieldName(
      row.productId,
      row.name
    );

    const productId = row.productId;

    // Skip if the row is an amount and lacks the necessary field group information
    if (row.value.type === "amount" && !fieldGroupFieldName) {
      return;
    }

    const conversionParam = getConversionParams(
      row,
      currentOpCase,
      systemTypeResults
    );

    const result = getResultFromTemplateRow(
      row,
      fieldGroupFieldName,
      row.value.type === "amount",
      productId,
      conversionParam,
      translate,
      getAmountFormat,
      resetAmount
    );

    const attributes = fieldGroupFieldName
      ? {
          fieldName: fieldGroupFieldName.fieldName,
          fieldGroup: fieldGroupFieldName.fieldGroup,
          propertyValue: row.value,
          conversionParam: conversionParam,
          amountFormat: amountFormatWrapper(
            row.value,
            fieldGroupFieldName.fieldGroup,
            fieldGroupFieldName.fieldName,
            getAmountFormat
          )
        }
      : undefined;

    tableRows.push({
      rowId: row.name,
      group: row.group,
      rowValues: {
        name: {
          cellId: "1",
          attributes,
          value: getNameFromTemplateRow(
            row,
            productId,
            fieldGroupFieldName,
            translate
          )
        },
        value: {
          cellId: "2",
          value: result
        }
      }
    });
  });

  return tableRows;
}

function getConversionParams(
  row: TemplateRow,
  currentOpCase: OpCase,
  systemTypeResults: ReadonlyArray<Product.SystemTypeResults>
) {
  const component = row.component;

  // if (!component) {
  //   return undefined;
  // }

  const resultsByComponentId =
    SystemPerformance.getResultByComponentId(currentOpCase);

  const resultForComponent = resultsByComponentId.get(component.id);

  if (!resultForComponent) {
    return undefined;
  }

  const systemTypeResult = systemTypeResults.find(
    x => x.resultName === row.name
  );

  if (!systemTypeResult) {
    return undefined;
  }

  return QuantityConversion.createConversionParameters(
    systemTypeResult?.quantityConversionParams,
    resultForComponent?.output
  );
}

function getResultFromTemplateRow(
  row: TemplateRow,
  fieldGroupFieldName: FieldGroupFieldName | undefined,
  isAmount: boolean,
  productId: string,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  translate: Translate,
  getAmountFormat: GetAmountFormatFunc,
  resetAmount: (fieldGroup: string, fieldName: string) => void
): string {
  const handleAmountValue = () =>
    getFormattedAmount(
      row,
      fieldGroupFieldName!,
      conversionParameters,
      getAmountFormat,
      resetAmount
    );

  const handlePropertyValue = () =>
    getPropertyValue(
      row,
      productId,
      translate,
      conversionParameters,
      fieldGroupFieldName,
      getAmountFormat,
      resetAmount
    );

  if (isAmount && fieldGroupFieldName) {
    return handleAmountValue();
  }

  switch (row.type) {
    case "Property":
      return handlePropertyValue();
    case "Result":
    case "FreeText":
    case "Specification":
    case "TextId":
      return PropertyValue.getText(row.value) || "";
    default:
      return exhaustiveCheck(row.type);
  }
}

// Helper function to handle formatted amount extraction
function getFormattedAmount(
  row: TemplateRow,
  { fieldName, fieldGroup }: FieldGroupFieldName,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  getAmountFormat: GetAmountFormatFunc,
  resetAmount: (fieldGroup: string, fieldName: string) => void
): string {
  const amountFormat = amountFormatWrapper(
    row.value,
    fieldGroup,
    fieldName,
    getAmountFormat
  );

  try {
    return amountFormat
      ? ProductProperties.getValue(
          row.value,
          amountFormat,
          conversionParameters
        )
      : "";
  } catch (e) {
    resetAmount(fieldGroup, fieldName);
  }

  return "";
}

// Helper function to handle property value extraction based on type
function getPropertyValue(
  row: TemplateRow,
  productId: string,
  translate: Translate,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  fieldGroupFieldName: FieldGroupFieldName | undefined,
  getAmountFormat: GetAmountFormatFunc,
  resetAmount: (fieldGroup: string, fieldName: string) => void
): string {
  let result = "";

  if (row.value.type === "amount" && fieldGroupFieldName) {
    result = getFormattedAmount(
      row,
      fieldGroupFieldName,
      conversionParameters,
      getAmountFormat,
      resetAmount
    );
  } else if (row.value.type === "text") {
    result = PropertyValue.getText(row.value) || "";
  }

  return (
    translate(
      LanguageTexts.productPropertyValue(
        productId,
        row.name,
        PropertyValue.getInteger(row.value) || 0
      )
    ) || result
  );
}

function getNameFromTemplateRow(
  row: TemplateRow,
  productId: string,
  fieldGroupFieldNameResult: FieldGroupFieldName | undefined,
  translate: Translate
): string {
  if (fieldGroupFieldNameResult) {
    const { fieldName } = fieldGroupFieldNameResult;
    return translate(LanguageTexts.perfParam(fieldName));
  }

  switch (row.type) {
    case "Property":
      return translate(LanguageTexts.productProperty(productId, row.name));
    case "Result":
    case "Specification":
      return row.name;
    case "TextId":
    case "FreeText":
      return "";

    default:
      return exhaustiveCheck(row.type);
  }
}
// tslint:disable-next-line
