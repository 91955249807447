import styled from "styled-components";
import * as React from "react";
import * as FlexContainerRow from "../containers/flex-container-row";

const unstyledDeleteButton = (
  props: { readonly text?: string } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const { text, ...rest } = props;
  return (
    <button {...rest}>
      <FlexContainerRow.FlexContainerRow>
        <img
          src={"./assets/images/red-circle-white-line.svg"}
          width="20px"
          height="20px"
        />
        {text && <span>{text}</span>}
      </FlexContainerRow.FlexContainerRow>
    </button>
  );
};

// tslint:disable-next-line
export const DeleteButton = styled(unstyledDeleteButton)`
  background-image: linear-gradient(to top, #e0e0e0, white 60%);
  border-radius: 4px;
  border: 1px outset #ddd;
  padding: 1px;
  cursor: pointer;
  margin: 0px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
      font-size: 12px;
      padding: 2px;
    }
  }
`;
