import * as React from "react";
import { P2, StatusComplete } from "@genesys/ui-elements";
import {
  PopupRoot,
  Popup,
  PopupContainer,
  ErrorIconWithCursor,
  WarningIconWithCursor,
  PaddingBottomContainer,
  MessageContainer
} from "../elements";
import { RootMessage } from "@genesys/shared/src/components-messages";
import { formattedMessage } from "../functions";
import { GetAmountFormat } from "@genesys/shared/src/screen-amounts";

export function WarningsAndErrors({
  rootMessage,
  amountFormat
}: {
  readonly rootMessage: RootMessage;
  readonly amountFormat: GetAmountFormat;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);
  const errors = [
    ...rootMessage.exception.componentMessages,
    ...rootMessage.error.componentMessages
  ];
  const warnings = rootMessage.warning.componentMessages;
  const hasErrors = errors.length > 0;
  const hasWarnings = warnings.length > 0;

  const icon = hasErrors ? (
    <ErrorIconWithCursor />
  ) : hasWarnings ? (
    <WarningIconWithCursor />
  ) : (
    <StatusComplete />
  );

  return (
    <PopupRoot>
      <PopupContainer
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}
      >
        {icon}
        {isOpen && (hasErrors || hasWarnings) && (
          <Popup borderColor={hasErrors ? "red" : "yellow"} width={"narrow"}>
            <PaddingBottomContainer>
              {warnings.flatMap(w => (
                <MessageContainer key={w.componentId}>
                  <div>
                    {w.productName} {w.groups[0].entries[0].heading}
                  </div>
                  {w.groups.flatMap(g =>
                    g.entries.map((e, ix) => (
                      <P2 key={ix}>
                        {formattedMessage(g.code, e.parts, amountFormat)}
                      </P2>
                    ))
                  )}
                </MessageContainer>
              ))}
              {errors.flatMap(err => (
                <MessageContainer key={err.componentId}>
                  <div>
                    {err.productName} {err.groups[0].entries[0].heading}
                  </div>
                  {err.groups.flatMap(g =>
                    g.entries.map((e, ix) => (
                      <P2 key={ix}>
                        {formattedMessage(g.code, e.parts, amountFormat)}
                      </P2>
                    ))
                  )}
                </MessageContainer>
              ))}
            </PaddingBottomContainer>
          </Popup>
        )}
      </PopupContainer>
    </PopupRoot>
  );
}
