import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

export function ConsecutiveAlerts({
  children,
  alertSeverity,
  show,
  timeout,
  getNextMessage
}: {
  readonly children: React.ReactNode;
  readonly show: boolean;
  readonly timeout: number;
  readonly alertSeverity: "info" | "error" | "warning" | "success";
  readonly getNextMessage: () => void;
}) {
  const [open, setOpen] = React.useState(show);

  const handleClose = (_event: any, reason: string) => {
    setOpen(false);
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <Snackbar
      TransitionProps={{
        onExited: () => {
          getNextMessage();
          setOpen(true);
        }
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top"
      }}
      open={open}
      autoHideDuration={timeout || 500}
      onClose={handleClose}
    >
      <Alert severity={alertSeverity}>{children}</Alert>
    </Snackbar>
  );
}
