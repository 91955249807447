import styled from "styled-components";
import { zIndex, PrimaryColors } from "@genesys/ui-elements";

import { StandardButton, Label, Input } from "@genesys/ui-elements";

export const Root = styled.div`
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
`;

export const CasesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  padding-top: 10px;
  margin-top: 30px;
`;

export const StyledDiv = styled.div`
  width: 400px;
  min-height: 40px;
`;

export const ComponentContainer = styled.div`
  min-width: 350px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-bottom: 8px;

  /* margin-top: 13px; */
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 2px 50px 2px 0px;
  color: #959695;
`;

export const PressureContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 50px 2px 0px;
  color: #959695;
`;

export const ButtonsContainer = styled.div`
  width: 200px;
  margin-left: 615px;
`;

export const StyledStandardButton = styled(StandardButton)`
  margin: 25px 10px 5px 0;
`;

export const StyledTextInput = styled(Input)`
  height: 20px;
  width: 70px;
  padding: 0;
  text-align: center;
`;

export const StyledLabel = styled(Label)`
  width: 120px;
`;

export const LicensingContainer = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const DescriptionOuterContainer = styled.div`
  position: relative;
`;

export const DescriptionInnerContainer = styled.div`
  position: absolute;
  left: -30px;
  top: -10px;
  cursor: pointer;
`;

export const DescriptionPopup = styled.div`
  position: relative;
  left: -50px;
  display: flex;
  background-color: white;
  z-index: ${zIndex.FullOverlay};
  padding: 12px;
  border: 1px solid ${PrimaryColors.muntersBlue};
  border-radius: 8px;
  p {
    white-space: nowrap;
    padding-left: 8px;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  margin-left: 15px;
  font-size: 11px;
  cursor: pointer;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LocationContainer = styled.div`
  margin-top: 13px;
`;

export const LocationHelpContainer = styled(FlexContainer)`
  position: relative;
`;
export const LocationInnerContainer = styled.div`
  position: absolute;
  left: -30px;
`;
