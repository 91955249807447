import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";

import {
  PropertyDefinition,
  PropertyDefinitions,
  SystemOverrides
} from "./types";
import { Quantity, Amount, Units } from "@genesys/uom";
import { CalculationInput } from "../main-content/result";
import * as GraphQLTypes from "../../graphql-types";
import * as PropertiesSelector from "../../properties-selector";
import * as OperationTimeGen2 from "../../operation-time-manager";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as LocationSelectorGen2 from "../../location-selector";
import * as ClimateSettingsEditor from "../components/climate-settings-editor";
import * as MainState from "../state";

export function getIfIntegerChanged(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyName: string
): number | undefined {
  const prevValue = PropertyValueSet.getInteger(propertyName, prevSelections);
  const currentValue = PropertyValueSet.getInteger(
    propertyName,
    currentSelections
  );

  return currentValue === prevValue ? undefined : currentValue;
}

export function getIfPropertyChanged(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyName: string
): PropertyValue.PropertyValue | undefined {
  const prevValue = PropertyValueSet.getValue(propertyName, prevSelections);
  const currentValue = PropertyValueSet.getValue(
    propertyName,
    currentSelections
  );

  return currentValue === prevValue ? undefined : currentValue;
}

export function changedFromBoth0(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyNameA: string,
  propertyNameB: string
): boolean {
  const prevValueA = PropertyValueSet.getInteger(propertyNameA, prevSelections);
  const currentValueA = PropertyValueSet.getInteger(
    propertyNameA,
    currentSelections
  );

  const prevValueB = PropertyValueSet.getInteger(propertyNameB, prevSelections);
  const currentValueB = PropertyValueSet.getInteger(
    propertyNameB,
    currentSelections
  );

  return !prevValueA && !prevValueB && (!!currentValueA || !!currentValueB);
}

export function changedToBoth0(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyNameA: string,
  propertyNameB: string
): boolean {
  const prevValueA = PropertyValueSet.getInteger(propertyNameA, prevSelections);
  const currentValueA = PropertyValueSet.getInteger(
    propertyNameA,
    currentSelections
  );

  const prevValueB = PropertyValueSet.getInteger(propertyNameB, prevSelections);
  const currentValueB = PropertyValueSet.getInteger(
    propertyNameB,
    currentSelections
  );

  return (!!prevValueA || !!prevValueB) && !currentValueA && !currentValueB;
}

export function changedTo1(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyName: string
): boolean {
  const prevValue = PropertyValueSet.getInteger(propertyName, prevSelections);
  const currentValue = PropertyValueSet.getInteger(
    propertyName,
    currentSelections
  );

  return currentValue === 1 && currentValue !== prevValue;
}

export const definitionIsValid = (
  definition: PropertyDefinition,
  selectedProperties: PropertyValueSet.PropertyValueSet
) => {
  // Check non-discrete.
  if (
    !PropertyFilter.isValid(selectedProperties, definition.validationFilter)
  ) {
    return false;
  }

  // Check discrete.
  const activeOption = definition.items.find(
    value =>
      value.value &&
      PropertyValue.getInteger(value.value) ===
        PropertyValueSet.getInteger(definition.name, selectedProperties)
  );
  if (
    activeOption &&
    !PropertyFilter.isValid(selectedProperties, activeOption.validationFilter)
  ) {
    return false;
  }

  return true;
};

export function propertyValueAllowed(
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyName: string,
  propertyIntegerValue: number,
  propertyDefinitions: PropertyDefinitions
): boolean {
  const propertyDefinition = propertyDefinitions.find(
    pd => pd.name === propertyName
  )!;

  //checkbox used by old client
  if (propertyDefinition.selector_type === "Checkbox") {
    // If it is about to be checked, make sure it is allowed to be.
    return (
      propertyIntegerValue === 0 ||
      (propertyIntegerValue === 1 &&
        PropertyFilter.isValid(
          currentSelections,
          propertyDefinition.true_filter || PropertyFilter.Empty
        ))
    );
  } else if (propertyDefinition.quantity === "Discrete") {
    const item = propertyDefinition.items.find(
      option => PropertyValue.getInteger(option.value!) === propertyIntegerValue
    );
    return (
      // Does an option with the ewanted value exist, and is it valid?
      !!item && PropertyFilter.isValid(currentSelections, item.validationFilter)
    );
  }

  throw new Error(
    "Used `propertyValueAllowed` on non-Discrete propertyDefinition."
  );
}

export function setPropertyIfValueAllowed(
  newDefaults: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyName: string,
  propertyIntegerValue: number,
  propertyDefinitions: PropertyDefinitions
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      propertyName,
      PropertyValueSet.merge(newDefaults, currentSelections)
    ) === propertyIntegerValue
  ) {
    return newDefaults;
  }

  if (
    propertyValueAllowed(
      PropertyValueSet.merge(newDefaults, currentSelections),
      propertyName,
      propertyIntegerValue,
      propertyDefinitions
    )
  ) {
    return PropertyValueSet.setInteger(
      propertyName,
      propertyIntegerValue,
      newDefaults
    );
  }

  return newDefaults;
}

export function loadRuleToPropertyDefinition(
  definition: GraphQLTypes.MoistureLoadCalculationProductQuery["product"]["moistureLoadRules"]["propertyDefinitions"][0],
  index: number,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
): PropertyDefinition {
  // Merge trueFilter with validation_filter.
  const validationFilter = (() => {
    const trueFilterPart =
      definition.truePropertyFilter &&
      definition.propertyName + "=0|(" + definition.truePropertyFilter + ")";

    const validationFilterParts = [];
    if (trueFilterPart) {
      validationFilterParts.push(trueFilterPart);
    }
    if (definition.validationFilter) {
      validationFilterParts.push(definition.validationFilter);
    }

    return PropertyFilter.fromString(
      validationFilterParts.map(part => "(" + part + ")").join("&")
    );
  })();

  if (!validationFilter) {
    throw new Error("Could not build validation_filter from trueFilter.");
  }

  // const combinedStringFilter = definition.visibilityFilter
  //   ? "(" +
  //     definition.visibilityFilter +
  //     ")" +
  //     (definition.enabledFilter ? "&(" + definition.enabledFilter + ")" : "")
  //   : "";

  // const combinedVisibilityFilter =
  //   PropertyFilter.fromString(combinedStringFilter)!;

  // combinedVisibilityFilter;

  const defaultValue = PropertyValue.fromString(definition.defaultValue || "");
  const defaultAmount =
    defaultValue && defaultValue.type === "amount"
      ? defaultValue.value
      : undefined;
  const defaultAmountQuantity = defaultAmount && defaultAmount.unit.quantity;

  return {
    true_filter: definition.truePropertyFilter
      ? PropertyFilter.fromString(definition.truePropertyFilter)
      : undefined,
    enabled_filter: definition.enabledFilter
      ? PropertyFilter.fromString(definition.enabledFilter)
      : undefined,
    selector_type: (
      {
        CHECK_BOX: "Checkbox"
      } as { readonly [index: string]: string }
    )[definition.moistureLoadPropertySelectorType] as "Checkbox" | undefined,
    sortNo: index,
    name: definition.propertyName,
    group: "",
    conversionParameters: undefined,
    valueSources: [],
    defaultValues: definition.defaultValue
      ? [
          {
            value: PropertyValue.fromString(definition.defaultValue)!,
            propertyFilter: PropertyFilter.Empty
          }
        ]
      : [],
    quantity: (defaultAmountQuantity as Quantity.Quantity) || "Discrete",
    descriptionTexts: [
      ...(definition.showDescription
        ? [
            {
              id: "",
              propertyFilter: PropertyFilter.Empty,
              language: "en-GB",
              text: translate(
                LanguageTexts.globalPropertyDescription(definition.propertyName)
              )
            }
          ]
        : [])
    ],
    validationFilter: validationFilter,
    visibilityFilter: PropertyFilter.fromStringOrEmpty(
      definition.visibilityFilter || ""
    ),
    showDescription: definition.showDescription,
    showValuesDescription:
      (definition.items &&
        definition.items
          .filter(
            item =>
              item.showDescription &&
              (item.value !== null || item.value !== undefined)
          )
          .map(item => item.value!)) ||
      undefined,
    items:
      definition.moistureLoadPropertySelectorType === "CHECK_BOX"
        ? [
            {
              id: "0",
              sortNo: 0,
              value: PropertyValue.fromInteger(0),
              validationFilter: PropertyFilter.fromStringOrEmpty(
                definition.falsePropertyFilter || ""
              ),
              text: translate(LanguageTexts.globalPropertyName("mlcpvno")),
              descriptionValuesTexts: [],
              rangeFilter: PropertyFilter.Empty
            },
            {
              id: "1",
              sortNo: 1,
              text: translate(LanguageTexts.globalPropertyName("mlcpvyes")),
              value: PropertyValue.fromInteger(1),
              validationFilter: PropertyFilter.fromStringOrEmpty(
                definition.truePropertyFilter || ""
              ),
              descriptionValuesTexts: [],
              rangeFilter: PropertyFilter.Empty
            }
          ]
        : !definition.items
        ? []
        : definition.items.map(
            (item, valueIndex): PropertiesSelector.PropertyItem => ({
              id: "2",
              sortNo: valueIndex,
              text: translate(
                LanguageTexts.globalPropertyName(
                  (definition.propertyName + "_" + (item.value || 0)) as string
                )
              ),
              value: PropertyValue.fromInteger(item.value || 0),
              validationFilter: PropertyFilter.fromStringOrEmpty(
                item.validationFilter || ""
              ),
              descriptionValuesTexts: [
                ...(item.showDescription
                  ? [
                      {
                        id: "",
                        propertyFilter: PropertyFilter.Empty,
                        language: "en-GB",
                        text: translate(
                          LanguageTexts.globalPropertyDescription(
                            definition.propertyName + "_" + item.value
                          )
                        )
                      }
                    ]
                  : [])
              ],
              rangeFilter: PropertyFilter.Empty
            })
          )
  };
}

export function setOperationTimePreset(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  relevantPropertyNames: Array<string>,
  presetKey: keyof typeof OperationTimeGen2.presets
): OperationTimeGen2.OperationTime | undefined {
  const somePropertiesWereSetPrev = relevantPropertyNames.some(
    knownPropertyName =>
      PropertyValueSet.getInteger(knownPropertyName, prevSelections) === 1
  );

  const somePropertiesAreSetCurrently = relevantPropertyNames.some(
    knownPropertyName =>
      PropertyValueSet.getInteger(knownPropertyName, currentSelections) === 1
  );

  if (somePropertiesWereSetPrev && !somePropertiesAreSetCurrently) {
    return OperationTimeGen2.presets["noHours"];
  }

  if (!somePropertiesWereSetPrev && somePropertiesAreSetCurrently) {
    return OperationTimeGen2.presets[presetKey];
  }

  return undefined;
}

export function getDefaultSystemOverrides(
  defaultMoistureLoadSettings: PropertyValueSet.PropertyValueSet
): SystemOverrides {
  return {
    [GraphQLTypes.OperatingCaseGroupEnum.DESIGN_SUMMER]:
      defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.DESIGN_WINTER]:
      defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.JANUARY]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.FEBRUARY]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.MARCH]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.APRIL]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.MAY]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.JUNE]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.JULY]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.AUGUST]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.SEPTEMBER]:
      defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.OCTOBER]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.NOVEMBER]: defaultMoistureLoadSettings,
    [GraphQLTypes.OperatingCaseGroupEnum.DECEMBER]: defaultMoistureLoadSettings
  };
}

export function createSystemOverridesFromCalcInput(
  input: GraphQLTypes.MoistureLoadCalcInputSettingsType,
  propertiesToKeep: string[]
): SystemOverrides {
  return {
    [GraphQLTypes.OperatingCaseGroupEnum.DESIGN_SUMMER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesDesignSummer!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.DESIGN_WINTER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesDesignWinter!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.JANUARY]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesJanuary!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.FEBRUARY]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesFebruary!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.MARCH]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesMarch!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.APRIL]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesApril!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.MAY]: PropertyValueSet.keepProperties(
      propertiesToKeep,
      PropertyValueSet.fromString(input.caseOverridesMay!)
    ),
    [GraphQLTypes.OperatingCaseGroupEnum.JUNE]: PropertyValueSet.keepProperties(
      propertiesToKeep,
      PropertyValueSet.fromString(input.caseOverridesJune!)
    ),
    [GraphQLTypes.OperatingCaseGroupEnum.JULY]: PropertyValueSet.keepProperties(
      propertiesToKeep,
      PropertyValueSet.fromString(input.caseOverridesJuly!)
    ),
    [GraphQLTypes.OperatingCaseGroupEnum.AUGUST]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesAugust!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.SEPTEMBER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesSeptember!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.OCTOBER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesOctober!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.NOVEMBER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesNovember!)
      ),
    [GraphQLTypes.OperatingCaseGroupEnum.DECEMBER]:
      PropertyValueSet.keepProperties(
        propertiesToKeep,
        PropertyValueSet.fromString(input.caseOverridesDecember!)
      )
  };
}

export function getCoordinateFromClimateDataProperties(
  climateDataProperties: PropertyValueSet.PropertyValueSet
): LocationSelectorGen2.Coordinate | undefined {
  const latAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    LocationSelectorGen2.manualData.latitudeN,
    climateDataProperties
  );
  const latitude = latAmount && Amount.valueAs(Units.Degrees, latAmount);
  const longAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    LocationSelectorGen2.manualData.longitudeW,
    climateDataProperties
  );
  const longitude = longAmount && Amount.valueAs(Units.Degrees, longAmount);
  const coordinate =
    latitude !== undefined && longitude !== undefined
      ? {
          latitude,
          longitude
        }
      : undefined;

  return coordinate;
}

export function createCalculationinputForReport(
  moistureLoadSettings: PropertyValueSet.PropertyValueSet,
  state: MainState.State
): CalculationInput | undefined {
  const loadsOperationTimes = MainState.getLoadsOperationTimes(state);
  //TODO: Find out what this does
  const loadNamesIndexes = new Map(
    [
      GraphQLTypes.MoistureHeatLoadTypeEnum.EVAPORATION_OF_WATER,
      GraphQLTypes.MoistureHeatLoadTypeEnum.EMISSION_FROM_PEOPLE,
      GraphQLTypes.MoistureHeatLoadTypeEnum.DIFFUSION_THROUGH_MATERIALS,
      GraphQLTypes.MoistureHeatLoadTypeEnum.INTENTIONAL_VENTILATION,
      GraphQLTypes.MoistureHeatLoadTypeEnum.UNINTENDED_VENTILATION,
      GraphQLTypes.MoistureHeatLoadTypeEnum.EMISSION_FROM_COMBUSTION,
      GraphQLTypes.MoistureHeatLoadTypeEnum.EVAPORATION_FROM_MATERIALS,
      GraphQLTypes.MoistureHeatLoadTypeEnum.HEAT_TRANSMISSION,
      GraphQLTypes.MoistureHeatLoadTypeEnum.STATIC_MOISTURE_LOAD,
      GraphQLTypes.MoistureHeatLoadTypeEnum.STATIC_HEAT_LOAD,

      GraphQLTypes.MoistureHeatLoadTypeEnum.OPEN_DOOR
    ].map((load, i) => [load, i])
  );

  const binData = ClimateSettingsEditor.getBinDataInfo(
    state.climateSettingsEditorState!
  );

  if (!binData) {
    return undefined;
  }

  return {
    moistureLoadSettings: PropertyValueSet.toString(moistureLoadSettings),
    systemOperationTime: PropertyValueSet.toString(
      OperationTimeGen2.toPropertyValueSet(
        state.mainContentState!.useCaseAndBuildingConfigState!
          .operationTimeState!
      )
    ),
    caseOverrideOperatingCaseGroups: Object.keys(
      GraphQLTypes.OperatingCaseGroupEnum
    ).map(key => Object.keys(GraphQLTypes.OperatingCaseGroupEnum).indexOf(key)),
    caseOverrideSettings: Object.keys(GraphQLTypes.OperatingCaseGroupEnum).map(
      (operatingCaseGroup: GraphQLTypes.OperatingCaseGroupEnum) =>
        PropertyValueSet.toString(
          state.currentSystemOverrides![operatingCaseGroup]
        )
    ),
    operationTimeKeys: loadsOperationTimes.map(
      x => loadNamesIndexes.get(x.sourceName)!
    ),
    operationTimeSettings: loadsOperationTimes.map(load =>
      PropertyValueSet.toString(
        OperationTimeGen2.toPropertyValueSet(load.operationTimeState!)
      )
    ),
    climateDesignData: PropertyValueSet.toString(
      ClimateSettingsEditor.getClimateSettings(
        state.climateSettingsEditorState!
      )!
    ),
    binDataLocationId: binData.locationId,
    binSize: binData.binSize,
    coolingDataType: binData.dataType
  };
}

export function isSubset(set1: boolean[][], set2: boolean[][]): boolean {
  for (let i = 0; i < set2.length; i++) {
    for (let j = 0; j < set2[i].length; j++) {
      if (set2[i][j] && !set1[i][j]) {
        return false;
      }
    }
  }
  return true;
}

export function getPropertiesChanged(
  prevProperties: PropertyValueSet.PropertyValueSet,
  currentProperties: PropertyValueSet.PropertyValueSet
): Array<string> {
  const properties = PropertyValueSet.getPropertyNames(currentProperties);

  const changedProperties: Array<string> = [];

  for (const property of properties) {
    if (!!getIfPropertyChanged(prevProperties, currentProperties, property)) {
      changedProperties.push(property);
    }
    getIfPropertyChanged(prevProperties, currentProperties, property);
  }

  return changedProperties;
}
// tslint:disable-next-line:max-file-line-count
