import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { LabelInputState } from "../shared-manager-components";
import { SearchParams, Modal } from "./types";
import { queryUser } from "./queries";
import { SearchFilterOptions } from "../shared-manager-components";
import { deleteMoistureLoadFilesMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as SharedState from "../shared-state";
import * as Guid from "@genesys/shared/lib/guid";
import * as GraphQLTypes from "../graphql-types";
import * as MoistureLoadActions from "../moisture-load-actions";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export type State = {
  readonly currentPage: number;
  readonly moistureloadActionState: MoistureLoadActions.State;
  readonly expandedRevisions: ReadonlyArray<string>;
  readonly labelInputState: LabelInputState;
  readonly modal: Modal | undefined;
  readonly moistureLoadActionOpenId: string;
  readonly openLabelPopup: string | undefined;
  readonly searchParams: SearchParams;
  readonly isSearchFilterOptionsOpen: boolean;
  readonly selectedMoistureLoadIds: ReadonlyArray<string>;
  // readonly searchFilterOptions: SearchFilterOptions;
  readonly useOverlayLoader: boolean;
  readonly userQueryResult:
    | GraphQLTypes.MoistureLoadManagerUserQuery
    | undefined;
};

export const itemsPerPage = 16;

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?] => {
  return [
    {
      currentPage: 1,
      expandedRevisions: [],
      labelInputState: undefined,
      moistureloadActionState: MoistureLoadActions.init()[0],
      isSearchFilterOptionsOpen: false,
      modal: undefined,
      moistureLoadActionOpenId: "",
      openLabelPopup: undefined,
      selectedMoistureLoadIds: [],
      searchParams: {
        scope: GraphQLTypes.MoistureLoadSearchScope.MY,
        searchFilterOptions: { textFieldOptions: {}, useDate: false },
        searchKey: "",
        orderByColumn: {
          isDescending: true,
          name: "date-changed"
        }
      },
      useOverlayLoader: false,
      userQueryResult: undefined
    },
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.MoistureLoadManagerUserQuery,
      GraphQLTypes.MoistureLoadManagerUserQueryVariables,
      Action
    >(
      queryUser,
      {
        filter: {
          startRow: 0,
          endRow: itemsPerPage,
          searchKey: "",
          searchScope: GraphQLTypes.MoistureLoadSearchScope.MY,
          orderByColumn: {
            isDescending: true,
            name: "date-changed"
          }
        }
      },
      Action.userQueryDataReceived
    )
  ];
};

export const Action = ctorsUnion({
  changePage: (pageNumber: number) => ({ pageNumber }),
  closeModal: () => ({}),
  createLabel: (labelName: string) => ({ labelName }),
  deleteLabel: (labelId: string) => ({ labelId }),
  deleteMoistureLoads: (moistureLoadFileIds: ReadonlyArray<string>) => ({
    moistureLoadFileIds
  }),
  dispatchMoistureLoadAction: (action: MoistureLoadActions.Action) => ({
    action: action
  }),
  openModal: (modal: Modal) => ({ modal }),
  setLabelInputState: (state: LabelInputState) => ({ state }),
  setSearchFilterOptions: (searchFilterOptions: SearchFilterOptions) => ({
    searchFilterOptions
  }),
  setSelectedMoistureLoads: (selectedPricings: ReadonlyArray<string>) => ({
    selectedMoistureLoads: selectedPricings
  }),
  setSearchParams: (searchParams: SearchParams) => ({ searchParams }),
  search: (messageOnDone?: SharedState.AlertMessage) => ({ messageOnDone }),
  toggleIsSearchFilterOptionsOpen: () => ({}),
  toggleOpenLabelPopup: (labelId: string | undefined) => ({ labelId }),
  toggleRevision: (resultId: string) => ({ resultId }),
  toggleManagerActionsMenu: (id: string) => ({ id }),
  updateLabel: (labelId: string, labelName: string) => ({ labelId, labelName }),
  userQueryDataReceived: (data: GraphQLTypes.MoistureLoadManagerUserQuery) => ({
    data
  })
});
export type Action = CtorsUnion<typeof Action>;

type UpdateReturnType = readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
];

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  switch (action.type) {
    case "changePage": {
      const { startRow, endRow } = computeStartAndEndRow(action.pageNumber);

      return [
        {
          ...state,
          currentPage: action.pageNumber,
          userQueryResult: undefined
        },
        search(sharedState, state, startRow, endRow)
      ];
    }

    case "closeModal": {
      return [{ ...state, modal: undefined }];
    }
    case "createLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [
          SharedState.Action.createLabel(
            Guid.guidToString(Guid.createGuid()),
            action.labelName
          )
        ]
      ];
    }

    case "deleteLabel": {
      return [
        state,
        undefined,
        [SharedState.Action.deleteLabel(action.labelId)]
      ];
    }

    case "deleteMoistureLoads": {
      return [
        {
          ...state,
          modal: undefined
        },

        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeleteMoistureLoadFiles,
          GraphQLTypes.DeleteMoistureLoadFilesVariables,
          Action
        >(
          deleteMoistureLoadFilesMutation,
          {
            moistureLoadFileIds: action.moistureLoadFileIds
          },
          data =>
            Action.search(
              // state.searchParams,
              data.deleteMoistureLoadFiles.deletionSuccessful
                ? {
                    content: "Moisture Load(s) Succesfully deleted",
                    messageType: "success",
                    timeout: 2000
                  }
                : {
                    content: sharedState.translate(
                      LanguageTexts.globalPropertyName(
                        data.deleteMoistureLoadFiles.errorMessage
                      )
                    ),
                    messageType: "error",
                    timeout: 10000
                  }
            )
        )
      ];
    }

    case "dispatchMoistureLoadAction": {
      const [
        moistureloadActionState,
        moistureloadActionsCmd,
        sharedStateActions
      ] = MoistureLoadActions.update(
        action.action,
        state.moistureloadActionState,
        sharedState
      );

      const [updatedState, cmd, newSharedStateActions] = handleMLActionStatus(
        moistureloadActionState.status,
        state,
        sharedState
      );
      let combinedActions: Array<SharedState.Action | undefined> = [];
      if (sharedStateActions) {
        combinedActions = [...sharedStateActions];
      }

      if (newSharedStateActions) {
        combinedActions = [...combinedActions, ...newSharedStateActions];
      }

      return [
        {
          ...updatedState,
          moistureloadActionState
        },
        Cmd.batch([
          Cmd.map(Action.dispatchMoistureLoadAction, moistureloadActionsCmd),
          cmd
        ]),
        combinedActions
      ];
    }

    case "openModal": {
      return [
        {
          ...state,
          modal: action.modal
        }
      ];
    }

    case "setLabelInputState": {
      return [{ ...state, labelInputState: action.state }];
    }

    case "setSearchFilterOptions": {
      return [
        {
          ...state,
          searchParams: {
            ...state.searchParams,
            searchFilterOptions: action.searchFilterOptions
          }
        }
      ];
    }

    case "setSelectedMoistureLoads": {
      return [
        { ...state, selectedMoistureLoadIds: action.selectedMoistureLoads }
      ];
    }

    case "setSearchParams": {
      return [{ ...state, searchParams: action.searchParams }];
    }
    case "search": {
      const { startRow, endRow } = computeStartAndEndRow(state.currentPage);

      return [
        {
          ...state,
          useOverlayLoader: false,
          userQueryResult: undefined
        },

        search(sharedState, state, startRow, endRow),
        [
          action.messageOnDone
            ? SharedState.Action.addAlertMessageToQueue(action.messageOnDone)
            : undefined
        ]
      ];
    }

    case "toggleIsSearchFilterOptionsOpen": {
      return [
        {
          ...state,
          isSearchFilterOptionsOpen: !state.isSearchFilterOptionsOpen
        }
      ];
    }
    case "toggleOpenLabelPopup": {
      return [{ ...state, openLabelPopup: action.labelId }];
    }

    case "toggleRevision": {
      const newExpandedRevisions = state.expandedRevisions.includes(
        action.resultId
      )
        ? state.expandedRevisions.filter(rev => rev !== action.resultId)
        : state.expandedRevisions.concat(action.resultId);

      return [{ ...state, expandedRevisions: newExpandedRevisions }];
    }
    case "toggleManagerActionsMenu": {
      return [
        {
          ...state,
          moistureLoadActionOpenId: action.id
        }
      ];
    }

    case "updateLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [SharedState.Action.updateLabel(action.labelId, action.labelName)]
      ];
    }

    case "userQueryDataReceived": {
      return [
        { ...state, userQueryResult: action.data, moistureLoadActionOpenId: "" }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function handleMLActionStatus(
  status: MoistureLoadActions.StatusType,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  const startRow = state.currentPage * itemsPerPage - itemsPerPage;
  const endrow = state.currentPage * itemsPerPage;

  const type = status.type;

  switch (type) {
    case "ready": {
      return [{ ...state, useOverlayLoader: false }];
    }

    case "moisture-load-deleted": {
      return [
        {
          ...state,
          useOverlayLoader: false
        },
        search(sharedState, state, startRow, endrow),
        [
          SharedState.Action.addAlertMessageToQueue(
            status.hasError
              ? {
                  content: status.message,
                  messageType: "error",
                  timeout: 10000
                }
              : {
                  content: "Moisture Load Succesfully deleted",
                  messageType: "success",
                  timeout: 2000
                }
          )
        ]
      ];
    }
    case "moisture-load-file-updated": {
      const newState = {
        ...state,
        useOverlayLoader: false
      };

      const searchResult = search(sharedState, state, startRow, endrow);
      let alertMessage: SharedState.AlertMessage | undefined = undefined;

      if (status.errorMessage) {
        alertMessage = {
          content: status.errorMessage,
          messageType: "error",
          timeout: 10000
        };
      } else if (status.updateType && status.updateType.type === "transfer") {
        alertMessage = {
          content: `${status.updateType.transferTo.substring(
            0,
            status.updateType.transferTo.indexOf("-")
          )} succesfully transfered to ${status.updateType.transferTo}`,
          messageType: "success",
          timeout: 10000
        };
      }
      return [
        newState,
        searchResult,
        alertMessage
          ? [SharedState.Action.addAlertMessageToQueue(alertMessage)]
          : []
      ];
    }
    case "moisture-load-copied-to-new-file": {
      return [
        {
          ...state,
          useOverlayLoader: false,
          modal: {
            type: "open-ml-modal",
            header: "Copied SuccesFully",
            url: `/moisture-load/${sharedState.genesysPrefix.moistureLoadNo(
              status.moistureLoadNo,
              1
            )}`
          }
        }
      ];
    }
    case "new-revision-created": {
      return [
        {
          ...state,
          useOverlayLoader: false
        },
        search(sharedState, state, startRow, endrow),
        [
          SharedState.Action.addAlertMessageToQueue({
            content: "Revision succesfully created",
            messageType: "success",
            timeout: 2000
          })
        ]
      ];
    }
    case "label-manager-unmounted":
      return [
        {
          ...state,
          useOverlayLoader: false
        },
        search(sharedState, state, startRow, endrow)
      ];
    case "in-progress": {
      return [
        {
          ...state,
          useOverlayLoader: true
        }
      ];
    }

    default:
      return exhaustiveCheck(type, true);
  }
}

function search(
  sharedState: SharedState.State,
  state: State,
  startRow: number,
  endRow: number
) {
  return sharedState.graphQL.queryUserCmd<
    GraphQLTypes.MoistureLoadManagerUserQuery,
    GraphQLTypes.MoistureLoadManagerUserQueryVariables,
    Action
  >(
    queryUser,
    {
      filter: {
        startRow: startRow,
        endRow: endRow,
        searchKey: state.searchParams.searchKey,
        searchScope: state.searchParams.scope,
        orderByColumn: state.searchParams.orderByColumn
      }
    },
    Action.userQueryDataReceived
  );
}

const computeStartAndEndRow = (pageNumber: number) => {
  const startRow = pageNumber * itemsPerPage - itemsPerPage;
  const endRow = pageNumber * itemsPerPage;

  return {
    startRow,
    endRow
  };
};

// tslint:disable-next-line
