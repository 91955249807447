import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import * as LabelManager from "../label-manager/state";
import { Label } from "./types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../graphql-types";
import { createPricingMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as Navigation from "../navigation-effect-manager";
import gql from "graphql-tag";

const validationQuery = gql`
  query SystemToPricingValidationUserQuery($id: String!) {
    user {
      systemPricingInformationMaster(systemId: $id) {
        error
      }
    }
  }
`;

export type State = {
  readonly labelManagerState: LabelManager.State | undefined;
  readonly assignedLabels: ReadonlyArray<Label>;
  readonly name: string;
  readonly pricingUrl: string | undefined;
  readonly labelAnchor: Element | null;
  readonly showLoader: boolean;
  readonly error: string | undefined;
};

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      labelManagerState: undefined,
      pricingUrl: undefined,
      assignedLabels: [],
      name: sharedState.translate(LanguageTexts.newPricing()),
      labelAnchor: null,
      showLoader: false,
      error: undefined
    },
    undefined,
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  dispatchLabelManager: (action: LabelManager.Action) => ({ action }),
  toggleLabelManager: (labelAnchor: Element | null) => ({ labelAnchor }),
  setAssignedlables: (labels: ReadonlyArray<Label>) => ({ labels }),
  setName: (name: string) => ({ name }),
  createPricing: (systemId: string | undefined) => ({ systemId }),
  openPricing: (openInNewTab: boolean) => ({
    openInNewTab
  }),
  validateSystem: (systemId: string) => ({
    systemId
  }),
  setError: (error: string) => ({ error }),
  setPricingUrl: (pricingNo: number, revisionNo: number) => ({
    pricingNo,
    revisionNo
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchLabelManager": {
      if (!state.labelManagerState) {
        return [state];
      }

      const [labelManagerState, sharedStateActions] = LabelManager.update(
        action.action,
        state.labelManagerState
      );

      return [
        { ...state, labelManagerState: labelManagerState },
        undefined,
        sharedStateActions
      ];
    }
    case "toggleLabelManager": {
      if (!state) {
        return [state];
      }

      const [labelManagerState] = LabelManager.init(state.assignedLabels);

      return [
        {
          ...state,
          labelAnchor: action.labelAnchor,
          labelManagerState: state.labelManagerState
            ? undefined
            : labelManagerState
        }
      ];
    }
    case "setAssignedlables": {
      return [{ ...state, assignedLabels: action.labels }];
    }
    case "setName": {
      return [{ ...state, name: action.name }];
    }
    case "setPricingUrl": {
      const url = `/pricing/${sharedState.genesysPrefix.pricingNo(
        action.pricingNo,
        action.revisionNo
      )}`;
      return [{ ...state, pricingUrl: url, showLoader: false }];
    }
    case "createPricing": {
      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CreatePricing,
          GraphQLTypes.CreatePricingVariables,
          Action
        >(
          createPricingMutation,
          action.systemId
            ? {
                name: state.name,
                labels: state.assignedLabels,
                systemId: action.systemId
              }
            : { name: state.name, labels: state.assignedLabels },
          data =>
            Action.setPricingUrl(
              data.createPricing.pricingNo,
              data.createPricing.revisionNo
            )
        )
      ];
    }
    case "validateSystem": {
      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemToPricingValidationUserQuery,
          GraphQLTypes.SystemToPricingValidationUserQueryVariables,
          Action
        >(validationQuery, { id: action.systemId }, data =>
          data.user.systemPricingInformationMaster.error
            ? Action.setError(data.user.systemPricingInformationMaster.error)
            : Action.createPricing(action.systemId)
        )
      ];
    }
    case "setError": {
      return [
        {
          ...state,
          showLoader: false,
          error: action.error
        }
      ];
    }

    case "openPricing": {
      if (action.openInNewTab) {
        window.open(state.pricingUrl);
        return [{ ...state, pricingUrl: undefined }];
      }
      return [
        { ...state, pricingUrl: undefined },
        Navigation.pushUrl(state.pricingUrl!)
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
