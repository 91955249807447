import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  OuterContainer,
  InnerContainer,
  BorderContainer,
  ControllerContainer
} from "./elements";
import { PressureComponent } from "./component/pressure-component";
import { PointsTableComponent } from "./component/points-table-component";
import {
  ChartSettingsComponent,
  chartSettingsDefinition
} from "./component/chart-settings-component";
import * as PsychrometricChart from "../../psychrometric-chart";
import { H2, StandardButton } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as FetchDocument from "@genesys/client-core/lib/fetch-document";
import { clientConfig } from "../../config";
import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { Physics } from "@munters/calculations";

export function PsychrometricChartToolView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "pressure",
    state.pressure
  )!;
  const humidityMax = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    "humiditymax",
    state.chartSettings.properties
  )!;
  const dpT =
    Physics.RP1485.AmountConversions.humidityRatioToDewPointTemperature(
      pressure,
      humidityMax
    );
  const properties = chartSettingsDefinition(
    Amount.create(Amount.valueAs(Units.CelsiusDewPoint, dpT), Units.Celsius)
  );
  const chartSettingsOk = !properties.some(
    p =>
      !PropertyFilter.isValid(
        state.chartSettings.properties,
        p.validationFilter
      )
  );

  return (
    <Root>
      <OuterContainer>
        <InnerContainer>
          <H2 weight="normal" color="primary">
            {sharedState.translate(LanguageTexts.psychrometricChart())}
          </H2>
          <BorderContainer>
            <PressureComponent
              state={state}
              sharedState={sharedState}
              dispatch={dispatch}
            />
            <PointsTableComponent
              state={state}
              sharedState={sharedState}
              dispatch={dispatch}
            />
          </BorderContainer>
          <ChartSettingsComponent
            state={state}
            sharedState={sharedState}
            dispatch={dispatch}
            properties={properties}
          />
          <ControllerContainer>
            <StandardButton
              buttonType="Primary"
              size="Large"
              onClick={() => dispatch(Action.generateChart())}
              disabled={!chartSettingsOk}
            >
              Generate
            </StandardButton>
            <FetchDocument.FetchDocumentComponent
              authentication={{
                type: "Bearer",
                accessToken: sharedState.accessToken
              }}
              filename="PsychrometricChart.pdf"
              type="download"
              onComplete={() =>
                dispatch(Action.setWaitingForDownloading(false))
              }
              method="GET"
              url={`${clientConfig.genesysBackend}/internal/GetPsychrometricChart`}
              params={{
                culture: sharedState.user.settings.language,
                pressureValue: PropertyValue.toString(
                  PropertyValueSet.get("pressure", state.pressure)!
                ),
                ipsi: PropertyValueSet.getInteger(
                  "ipsi",
                  state.chartSettings.properties
                )!,
                chartType: PropertyValueSet.getInteger(
                  "charttype",
                  state.chartSettings.properties
                )!,
                limits: PropertyValueSet.getInteger(
                  "limits",
                  state.chartSettings.properties
                )!,
                humidityMax: PropertyValue.toString(
                  PropertyValueSet.get(
                    "humiditymax",
                    state.chartSettings.properties
                  )!
                ),
                temperatureMin: PropertyValue.toString(
                  PropertyValueSet.get(
                    "temperaturemin",
                    state.chartSettings.properties
                  )!
                ),
                temperaturemax: PropertyValue.toString(
                  PropertyValueSet.get(
                    "temperaturemax",
                    state.chartSettings.properties
                  )!
                ),
                stringChartPoints: state.points.map(p =>
                  PropertyValueSet.toString(p.state.properties)
                )
              }}
            >
              {onClick => (
                <StandardButton
                  buttonType="Primary"
                  disabled={state.waitingForDownload || !chartSettingsOk}
                  size="Large"
                  onClick={() => {
                    dispatch(Action.setWaitingForDownloading(true));
                    onClick();
                  }}
                >
                  Download
                </StandardButton>
              )}
            </FetchDocument.FetchDocumentComponent>
          </ControllerContainer>
        </InnerContainer>

        <InnerContainer>
          {state.psychrometricChartState && (
            <PsychrometricChart.PsychrometricChartView
              state={state.psychrometricChartState}
              sharedState={sharedState}
            />
          )}
        </InnerContainer>
      </OuterContainer>
    </Root>
  );
}
