import * as Types from "./types";
import * as Helper from "./helper";
import * as Calculations from "@munters/calculations";
import * as DrawingOrder from "./drawing-sorter";
// import * as Helpers from "./helper";

export function applyTransformsAvoidingOverlapsByEdgeExtension(
  diagram: Types.Diagram
): Types.Diagram {
  const orderedNodeIds = DrawingOrder.GetDrawingOrder(diagram);
  let newNodes = Array<Types.Node>();
  let newEdges = [...diagram.edges];

  for (const nodeId of orderedNodeIds) {
    const node = diagram.nodes.find(n => n.id === nodeId)!;
    newNodes.push(node);
    let newDiagram = applyTransforms(
      {
        ...diagram,
        nodes: newNodes,
        edges: newEdges
      },
      orderedNodeIds
    );

    newDiagram = avoidOverlapByExtendingOneEdge(newDiagram, orderedNodeIds);

    newNodes = [...newDiagram.nodes];
    newEdges = [...newDiagram.edges];
  }

  return {
    ...diagram,
    nodes: newNodes,
    edges: newEdges
  };
}

function applyTransforms(
  diagram: Types.Diagram,
  startOrderedIds: ReadonlyArray<string>
): Types.Diagram {
  let remainingOrderedNodeIds = startOrderedIds.concat();
  const newNodes = Array<Types.Node>();

  while (remainingOrderedNodeIds.length > 0) {
    const startId = remainingOrderedNodeIds[0];
    const startNode = diagram.nodes.find(n => n.id === startId);
    if (startNode === undefined) {
      remainingOrderedNodeIds = remainingOrderedNodeIds.filter(
        n => n !== startId
      );
      continue;
    }
    let list: Types.Node[] = [];
    list.push(startNode);

    while (list.length > 0) {
      let node = list[0];

      list = list.filter(n => n.id !== node.id);

      if (!remainingOrderedNodeIds.find(n => n === node.id)) {
        continue;
      }

      remainingOrderedNodeIds = remainingOrderedNodeIds.filter(
        n => n !== node.id
      );

      const allConnectedNodes = Helper.getConnectedNodes(
        diagram.nodes,
        diagram.edges,
        node
      );

      for (const nodeId of remainingOrderedNodeIds.filter(rn =>
        allConnectedNodes.find(cn => cn.nextNode.id === rn)
      )) {
        const connectedToThisNode = allConnectedNodes.filter(
          cn => cn.nextNode.id === nodeId
        );
        if (connectedToThisNode.length > 0) {
          // If two components are connected with >1 edges, it does not matter which one you pick. Whichever one you pick will be positioned and transformed relative to the other components port anyway.
          // So we just pick the first found connection. No magic there!
          const connection = connectedToThisNode[0];

          const translate = Calculations.Math.Matrix2.translate(
            connection.port.connectionType === "Outlet"
              ? connection.edge!.length
              : -connection.edge!.length,
            0
          );
          const inverse = Calculations.Math.Matrix2.inverse(
            connection.nextPort.transform
          );
          const nextTransform = Calculations.Math.Matrix2.multiply(
            Calculations.Math.Matrix2.multiply(
              Calculations.Math.Matrix2.multiply(
                node.transform,
                connection.port.transform
              ),
              translate
            ),
            inverse
          );

          const nextNode = connection.nextNode;

          // if node already exists on the list, the old one should be removed and the new added.
          // Last added node wins. Example:
          // Connection1: A is before B
          // Connection2: A is before C which is before B.
          // A - - \
          // A - C - B
          // Then we should place B relative to C because C is correct relative to A.
          const oldNodes = list.filter(i => i.id === nextNode.id);

          if (oldNodes.length > 0) {
            list = list.filter(i => i.id !== nextNode.id);
          }

          list.push({
            ...nextNode,
            transform: nextTransform
          });
        }
      }
      newNodes.push(node);
    }
  }
  return {
    ...diagram,
    nodes: newNodes
  };
}

function avoidOverlapByExtendingOneEdge(
  diagram: Types.Diagram,
  startOrderedIds: ReadonlyArray<string>
): Types.Diagram {
  if (getOverlap(diagram) <= 0.5) {
    return diagram;
  }

  const bounds = Calculations.Math.BoundingRect.expand(
    20,
    diagram.nodes
      .map(n => Helper.getNodeBounds(n))
      .reduce(
        (a, b) => Calculations.Math.BoundingRect.unionBoundingRect(a, b),
        Calculations.Math.BoundingRect.empty
      )
  );
  let min = Helper.edgeLength;
  let max = Math.max(
    Calculations.Math.BoundingRect.getWidth(bounds),
    Calculations.Math.BoundingRect.getHeight(bounds)
  );

  for (const edge of diagram.edges) {
    let newEdges = diagram.edges.map(e =>
      e === edge ? { ...e, length: max } : e
    );
    let newDiagram = applyTransforms(
      { ...diagram, edges: newEdges },
      startOrderedIds
    );
    let overlap = getOverlap(newDiagram);
    if (overlap > 0.5) {
      continue;
    }

    while (max - min > 1) {
      const current = (max + min) / 2;
      newEdges = diagram.edges.map(e =>
        e === edge ? { ...e, length: current } : e
      );
      newDiagram = applyTransforms(
        { ...diagram, edges: newEdges },
        startOrderedIds
      );
      overlap = getOverlap(newDiagram);

      if (max - min < 10 && overlap < 0.5) {
        break;
      }

      if (overlap < 0.5) {
        max = current;
      } else {
        min = current;
      }
    }

    return newDiagram;
  }

  console.log("Could not avoid overlaps");
  return diagram;
}

function getOverlap(diagram: Types.Diagram): number {
  let overlap = 0;

  const subGraphs = diagram.nodes.reduce(
    (a, b) =>
      a[b.subGraphId]
        ? {
            ...a,
            [b.subGraphId]: [...a[b.subGraphId], b]
          }
        : {
            ...a,
            [b.subGraphId]: [b]
          },
    {} as { readonly [key: string]: Array<Types.Node> }
  );

  Object.keys(subGraphs).forEach(key => {
    const subGraphNodes = subGraphs[key];

    const edgeBounds = Array<Calculations.Math.BoundingRect.BoundingRect>();
    subGraphNodes.forEach(node => {
      node.ports
        .filter(p => p.connectionType === "Outlet")
        .forEach(port => {
          const edge = diagram.edges.find(
            e =>
              e.node1 === node.componentId &&
              e.node1Section === port.componentSectionId
          );
          if (!edge) {
            return;
          }

          const portInfo = Helper.getPortsAndPositions(diagram, node, port);
          if (portInfo.port1 && portInfo.port2) {
            edgeBounds.push(
              Calculations.Math.BoundingRect.create(
                Calculations.Math.Vector2.vec2Scale(
                  Calculations.Math.Vector2.vec2Add(
                    portInfo.startPoint,
                    portInfo.endPoint
                  ),
                  0.5
                ),
                Calculations.Math.Vector2.abs(
                  Calculations.Math.Vector2.vec2Scale(
                    Calculations.Math.Vector2.vec2Sub(
                      portInfo.startPoint,
                      portInfo.endPoint
                    ),
                    0.5
                  )
                )
              )
            );
          }
        });
    });

    for (let e1 = 0; e1 < edgeBounds.length; e1++) {
      for (let e2 = e1 + 1; e2 < edgeBounds.length; e2++) {
        overlap += Calculations.Math.BoundingRect.maxOverlapDepth(
          Calculations.Math.BoundingRect.expand(5, edgeBounds[e1]),
          Calculations.Math.BoundingRect.expand(5, edgeBounds[e2])
        );
      }
    }

    for (let n1 = 0; n1 < subGraphNodes.length; n1++) {
      const symbols1 = Helper.getNodeSymbolBounds(subGraphNodes[n1], true);
      overlap += symbols1.reduce(
        (a, b) =>
          a +
          edgeBounds.reduce(
            (aa, bb) =>
              aa + Calculations.Math.BoundingRect.maxOverlapDepth(b, bb),
            0
          ),
        0
      );

      for (let n2 = n1 + 1; n2 < subGraphNodes.length; n2++) {
        const symbols2 = Helper.getNodeSymbolBounds(subGraphNodes[n2], true);
        overlap += symbols1.reduce(
          (a, b) =>
            a +
            symbols2.reduce(
              (aa, bb) =>
                aa +
                Calculations.Math.BoundingRect.maxOverlapDepth(
                  Calculations.Math.BoundingRect.expand(20, b),
                  Calculations.Math.BoundingRect.expand(20, bb)
                ),
              0
            ),
          0
        );
      }
    }
  });
  return overlap;
}
