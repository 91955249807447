import * as KnownProperties from "../../known-properties";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import { OpenDoorIcon } from "@genesys/ui-elements";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";

import { LoadOperationTime } from "../../types";

import { changedFromBoth0, changedToBoth0 } from "../../helper-functions";

//Icon
export const Icon = OpenDoorIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty = KnownProperties.useOpenDoorMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useOpenDoorHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const sourceName = MoistureHeatLoadTypeEnum.OPEN_DOOR;
export const description = "mlcopendoorexplanation";
export const title = "mlcopendoor";

export const manualInputProperty = KnownProperties.openDoorManualInput;

export const properties = [
  KnownProperties.useOpenDoorHeatLoad,
  KnownProperties.useOpenDoorMoistureLoad,
  KnownProperties.openDoorManualInput,
  KnownProperties.openDoorTime,
  KnownProperties.numberOfOpenDoors,
  KnownProperties.useManualOpenDoorLeakageMultilplier,
  KnownProperties.openDoorLeakageMultilplier
];

export const overridableProperties = [
  KnownProperties.openDoorTime,
  KnownProperties.numberOfOpenDoors,
  KnownProperties.useManualOpenDoorLeakageMultilplier,
  KnownProperties.openDoorLeakageMultilplier
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function onParentPropertyUpdate(
  prevProperties: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  parentHeatProperty: string,
  parentMoistProperty: string
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  if (
    changedFromBoth0(
      prevProperties,
      PropertyValueSet.merge(defaults, currentSelections),
      parentHeatProperty,
      parentMoistProperty
    )
  ) {
    defaults = PropertyValueSet.merge(
      updateOpenDoorLoad(
        PropertyValueSet.getInteger(parentHeatProperty, currentSelections)!,
        PropertyValueSet.getInteger(parentMoistProperty, currentSelections)!
      ),
      defaults
    );
    defaults = PropertyValueSet.merge(useOpenDoorDefaults(1), defaults);
  } else if (
    changedToBoth0(
      prevProperties,
      PropertyValueSet.merge(defaults, currentSelections),
      parentHeatProperty,
      parentMoistProperty
    )
  ) {
    defaults = PropertyValueSet.merge(
      updateOpenDoorLoad(
        PropertyValueSet.getInteger(parentHeatProperty, currentSelections)!,
        PropertyValueSet.getInteger(parentMoistProperty, currentSelections)!
      ),
      defaults
    );

    defaults = PropertyValueSet.merge(useOpenDoorDefaults(0), defaults);
  }

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.factoryHours;
}

function updateOpenDoorLoad(
  useHeatpropertyValue: number,
  useMoisturepropertyValue: number
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.useOpenDoorHeatLoad]:
      PropertyValue.fromInteger(useHeatpropertyValue),
    [KnownProperties.useOpenDoorMoistureLoad]: PropertyValue.fromInteger(
      useMoisturepropertyValue
    )
  };
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
) {
  let defaults = PropertyValueSet.Empty;

  if (
    changedFromBoth0(
      prevSelections,
      PropertyValueSet.merge(defaults, currentSelections),
      useMoistProperty,
      useHeatProperty
    )
  ) {
    defaults = PropertyValueSet.merge(useOpenDoorDefaults(1), defaults);
  } else if (
    changedToBoth0(
      prevSelections,
      PropertyValueSet.merge(defaults, currentSelections),
      useMoistProperty,
      useHeatProperty
    )
  ) {
    defaults = PropertyValueSet.merge(useOpenDoorDefaults(0), defaults);
  }

  defaults = PropertyValueSet.merge(
    setOpenDoorLeakageMultilplier(
      PropertyValueSet.merge(defaults, currentSelections)!
    ),
    defaults
  );

  return defaults;
}

function useOpenDoorDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.openDoorManualInput]: PropertyValue.fromInteger(0),
        [KnownProperties.openDoorTime]: PropertyValue.fromAmount(
          Amount.create(0, Units.Minute)
        ),
        [KnownProperties.numberOfOpenDoors]: PropertyValue.fromInteger(0),
        [KnownProperties.useManualOpenDoorLeakageMultilplier]:
          PropertyValue.fromInteger(0),
        [KnownProperties.openDoorLeakageMultilplier]: PropertyValue.fromAmount(
          Amount.create(0, Units.One)
        )
      };

    case 1:
      return {
        [KnownProperties.openDoorManualInput]: PropertyValue.fromInteger(0),
        [KnownProperties.openDoorTime]: PropertyValue.fromAmount(
          Amount.create(5, Units.Minute)
        ),
        [KnownProperties.numberOfOpenDoors]: PropertyValue.fromInteger(1),
        [KnownProperties.useManualOpenDoorLeakageMultilplier]:
          PropertyValue.fromInteger(0),
        [KnownProperties.openDoorLeakageMultilplier]: PropertyValue.fromAmount(
          getOpenDoorLeakageMultilplier(1)
        )
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setOpenDoorLeakageMultilplier(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualOpenDoorLeakageMultilplier,
    selections
  ) === 1
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.openDoorLeakageMultilplier]: PropertyValue.fromAmount(
          getOpenDoorLeakageMultilplier(
            PropertyValueSet.getInteger(
              KnownProperties.numberOfOpenDoors,
              selections
            ) || 0
          )
        )
      };
}

function getOpenDoorLeakageMultilplier(
  value: number
): Amount.Amount<Quantity.Dimensionless> {
  return Amount.create([0, 3.2, 3.7, 4.2, 4.8][value] || 0, Units.One);
}
