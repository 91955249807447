import * as React from "react";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as RoomControlSettings from "../tools/moisture-load-rules/room-control-settings";
import styled from "styled-components";
import { PropertyValueSet } from "@genesys/property";
import { Location } from "@genesys/ui-elements/lib/icons";
import { H3, P1 } from "@genesys/ui-elements";
import { getValue } from "@genesys/shared/lib/product-properties";
import { AmountFormatSelector } from "../../amount-format-selector";
import { AmountFormat } from "@genesys/shared/src/screen-amounts";
import { Quantity, Unit } from "@genesys/uom";

const Root = styled.div`
  max-width: 340px;

  td:first-child {
    display: flex;
    align-items: center;
    align-content: center;
    padding-right: 14px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  color: #646f86;
  margin-right: 5px;
`;

const StyledP1 = styled(P1)`
  /* margin-right: 5px; */
`;

const fieldGroup = "IndoorClimate";

interface Fields {
  readonly title: string;
  readonly format: AmountFormat;
  readonly value: string;
  readonly fieldName: string;
}

type Min = {
  readonly type: "min";
} & Fields;

type Max = {
  readonly type: "max";
} & Fields;

type Constant = {
  readonly type: "constant";
} & Fields;

type None = {
  readonly type: "none";
};

type Unknown = {
  readonly type: "unknown";
};

type InfoType = Min | Max | Constant | None | Unknown;

interface HumControlTypeFields {
  readonly format: AmountFormat;
  readonly value: string;
  readonly completefieldName: string;
}

type HumControlType =
  | AbsolControlType
  | RelControlType
  | DpControlType
  | Unknown;

type HumidityType =
  | "absolute-hum"
  | "relative-hum"
  | "dew-point-temp"
  | "unknown";
type AbsolControlType = {
  readonly type: "absolute-hum";
} & HumControlTypeFields;
type RelControlType = {
  readonly type: "relative-hum";
} & HumControlTypeFields;
type DpControlType = {
  readonly type: "dew-point-temp";
} & HumControlTypeFields;

export function IndoorClimatePresenter({
  indoorRoomSettings,
  sharedState,
  onFormatChanged,
  onFormatCleared
}: {
  readonly indoorRoomSettings: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const format = (
    amountFormat: AmountFormat,
    fieldGroup: string,
    fieldName: string
  ) => {
    return (
      <AmountFormatSelector
        type="AmountFormatSelectorProps"
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        amountFormat={amountFormat}
        conversionParameters={undefined}
        translate={sharedState.translate}
        onFormatChanged={(unit, decimalCount) =>
          onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        }
        onFormatCleared={() => {
          onFormatCleared(fieldGroup, fieldName);
        }}
      />
    );
  };

  const renderClimateInfo = (info: InfoType, emptyTranslationKey: string) => {
    if (info.type === "none") {
      return (
        <StyledP1 color="secondary" weight="normal">
          {sharedState.translate(
            LanguageTexts.globalPropertyName(emptyTranslationKey)
          )}
        </StyledP1>
      );
    } else if (info.type === "unknown") {
      return (
        <StyledP1 color="secondary" weight="normal">
          {sharedState.translate(
            LanguageTexts.globalPropertyName("mlcunknowntype")
          )}
        </StyledP1>
      );
    }

    return (
      <>
        <StyledP1 color="secondary" weight="normal">
          {info.title}: {info.value}
        </StyledP1>{" "}
        {format(info.format, fieldGroup, info.fieldName)}
      </>
    );
  };

  const manualInputSelected = PropertyValueSet.getInteger(
    RoomControlSettings.manualInputProperty,
    indoorRoomSettings
  );

  const tempControl = PropertyValueSet.getInteger(
    "mlcroomtemperaturecontrol",
    indoorRoomSettings
  )!;

  const humControl = PropertyValueSet.getInteger(
    "mlcroomhumiditycontrol",
    indoorRoomSettings
  )!;

  const humControlType = PropertyValueSet.getInteger(
    "mlcroomhumiditycontroltype",
    indoorRoomSettings
  )!;

  const tempInfo = getTemperatureInfo(
    tempControl,
    indoorRoomSettings,
    sharedState
  );

  const humInfo = getHumidityInfo(
    humControl,
    humControlType,
    indoorRoomSettings,
    sharedState
  );

  return (
    <Root>
      <table>
        <tbody>
          <tr>
            <td>
              <Location height="1.2rem" />
            </td>
            <td>
              <H3 color="dark" weight="bold">
                {sharedState.translate(
                  LanguageTexts.mlcDesiredIndoorsClimate()
                )}
              </H3>
            </td>
          </tr>
          <tr>
            <td></td>
            {manualInputSelected ? (
              <td>
                <StyledP1 color="secondary" weight="normal">
                  {sharedState.translate(
                    LanguageTexts.globalPropertyName("manual")
                  )}
                </StyledP1>
              </td>
            ) : (
              <td>
                <FlexContainer>
                  {renderClimateInfo(
                    humInfo,
                    LanguageTexts.mlcNoTargethum().textId
                  )}
                </FlexContainer>
                <FlexContainer>
                  {renderClimateInfo(
                    tempInfo,
                    LanguageTexts.mlcNoTargetTemp().textId
                  )}
                </FlexContainer>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </Root>
  );
}

function getTemperatureInfo(
  tempControl: number,
  indoorRoomSettings: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State
): InfoType {
  const translate = sharedState.translate;
  const tempControlInfos = {
    1: {
      type: "min" as const,
      fieldName: "mlcroommintemperature",
      translationKey: LanguageTexts.mlcMinTargetTemp()
    },
    2: {
      type: "max" as const,
      fieldName: "mlcroommaxtemperature",
      translationKey: LanguageTexts.mlcMaxTargetTemp()
    },
    3: {
      type: "constant" as const,
      fieldName: "mlcroomtemperature",
      translationKey: LanguageTexts.mlcConstantTargetTemp()
    }
  };

  type T = typeof tempControlInfos;

  if (tempControl in tempControlInfos) {
    const index = tempControl as keyof T;
    const tempControlInfo = tempControlInfos[index];
    const format = sharedState.screenAmounts.getAmountFormat(
      fieldGroup,
      tempControlInfo.fieldName,
      "Temperature"
    );
    const value = getValue(
      PropertyValueSet.get(tempControlInfo.fieldName, indoorRoomSettings)!,
      format
    );

    return {
      type: tempControlInfo.type,
      title: translate(tempControlInfo.translationKey),
      fieldName: tempControlInfo.fieldName,
      format,
      value
    };
  } else if (tempControl === 0) {
    return {
      type: "none"
    };
  } else {
    throw new Error("Temp control not found");
  }
}

function getHumidityInfo(
  humControl: number,
  humControlType: number,
  indoorRoomSettings: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State
): InfoType {
  const translate = sharedState.translate;

  const buildTextId = (
    control: "min" | "max" | "constant",
    humidityType: HumidityType
  ) => {
    return control + "target" + humidityType.replace(/-/g, "").toLowerCase();
  };

  const getExtraInfo = (startingFieldName: string) => {
    return getHumidityTypeInfo(
      humControlType,
      startingFieldName,
      sharedState,
      indoorRoomSettings
    );
  };

  const getInfo = (
    control: "min" | "max" | "constant",
    startingFieldName: string
  ): InfoType => {
    const extraInfo = getExtraInfo(startingFieldName);
    if (extraInfo.type === "unknown") {
      return {
        type: "unknown"
      };
    }

    return {
      type: control,
      title: translate(
        LanguageTexts.globalPropertyName(buildTextId(control, extraInfo.type))
      ),
      fieldName: extraInfo.completefieldName,
      value: extraInfo.value,
      format: extraInfo.format
    };
  };

  switch (humControl) {
    case 0: {
      // none
      return {
        type: "none"
      };
    }

    case 1: {
      // min
      return getInfo("min", "mlcroommin");
    }

    case 2: {
      // max
      return getInfo("max", "mlcroommax");
    }

    case 3: {
      // constant
      return getInfo("constant", "mlcroom");
    }

    default:
      return {
        type: "unknown"
      };
  }
}

function getHumidityTypeInfo(
  humControlType: number,
  startingFieldName: string,
  sharedState: SharedState.State,
  indoorRoomSettings: PropertyValueSet.PropertyValueSet
): HumControlType {
  switch (humControlType) {
    case 1: {
      const completefieldName = startingFieldName + "humidityabs";
      const format = sharedState.screenAmounts.getAmountFormat(
        fieldGroup,
        completefieldName,
        "HumidityRatio"
      );

      const value = getValue(
        PropertyValueSet.get(completefieldName, indoorRoomSettings)!,
        format
      );

      return {
        type: "absolute-hum",
        format,
        value,
        completefieldName
      };
    }

    case 2: {
      const completefieldName = startingFieldName + "humidityrel";

      const format = sharedState.screenAmounts.getAmountFormat(
        fieldGroup,
        completefieldName,
        "RelativeHumidity"
      );
      const value = getValue(
        PropertyValueSet.get(completefieldName, indoorRoomSettings)!,
        format
      );
      return {
        type: "relative-hum",
        format,
        value,
        completefieldName
      };
    }
    case 3: {
      const completefieldName = startingFieldName + "humiditydpt";
      const format = sharedState.screenAmounts.getAmountFormat(
        fieldGroup,
        completefieldName,
        "DewPointTemperature"
      );

      const value = getValue(
        PropertyValueSet.get(completefieldName, indoorRoomSettings)!,
        format
      );
      return {
        type: "dew-point-temp",
        format,
        value,
        completefieldName
      };
    }
    default:
      return {
        type: "unknown"
      };
  }
}
