import * as DoubleN from "double-n";
import { Amount } from "uom";

const bitPrecision: DoubleN.Precision = 40;

export const compare: Amount.Comparer = <
  T1 extends Amount.Amount<unknown>,
  T2 extends T1
>(
  a: T1,
  b: T2
) => compareAmounts(a, b);

function compareAmounts<T1, T2 extends T1>(
  a: Amount.Amount<T1>,
  b: Amount.Amount<T2>
): number {
  const mostGranularUnit = Amount.getMostGranularUnit(a.unit, b.unit);
  const decimalCount = Math.max(a.decimalCount, b.decimalCount);
  const valueA = Amount.valueAs(mostGranularUnit, a);
  const valueB = Amount.valueAs(mostGranularUnit, b);

  const f = Math.round(valueA * Math.pow(10, decimalCount));
  const s = Math.round(valueB * Math.pow(10, decimalCount));

  const f42 = DoubleN.numberToDoubleN(f, bitPrecision);
  const s42 = DoubleN.numberToDoubleN(s, bitPrecision);

  if (f42 === s42) {
    return 0;
  }
  if (f42 < s42) {
    return -1;
  } else {
    return 1;
  }
}
