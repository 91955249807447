import { MoistureHeatLoadTypeEnum } from "../../../graphql-types";
import { PropertyValueSet } from "@genesys/property";
import * as KnownProperties from "../../tools/known-properties";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import * as GraphQLTypes from "../../../graphql-types";

export function getWarningTextKey(
  loadName: MoistureHeatLoadTypeEnum,
  selectedProperties: PropertyValueSet.PropertyValueSet
): string | undefined {
  const systemConfiguration = PropertyValueSet.getInteger(
    KnownProperties.systemConfiguration,
    selectedProperties
  );

  return (
    {
      [MoistureHeatLoadTypeEnum.HEAT_TRANSMISSION]: undefined,
      [MoistureHeatLoadTypeEnum.STATIC_MOISTURE_LOAD]: undefined,
      [MoistureHeatLoadTypeEnum.STATIC_HEAT_LOAD]: undefined,
      [MoistureHeatLoadTypeEnum.INTENTIONAL_VENTILATION]: [
        "mlcinvestigatemakeupsystem",
        "mlctakencareofbymakeupair",
        "mlctakencareofbysystem"
      ][systemConfiguration!],
      [MoistureHeatLoadTypeEnum.EMISSION_FROM_PEOPLE]:
        systemConfiguration === 0 ? "mlcinvestigatemakeupsystem" : undefined,
      [MoistureHeatLoadTypeEnum.EVAPORATION_OF_WATER]: undefined,
      [MoistureHeatLoadTypeEnum.UNINTENDED_VENTILATION]: [1, 2].includes(
        systemConfiguration!
      )
        ? "mlcnotapplicapleifoverpressure"
        : undefined,
      [MoistureHeatLoadTypeEnum.EMISSION_FROM_COMBUSTION]: undefined,
      [MoistureHeatLoadTypeEnum.EVAPORATION_FROM_MATERIALS]: undefined,
      [MoistureHeatLoadTypeEnum.DIFFUSION_THROUGH_MATERIALS]: undefined
    } as { [key in MoistureHeatLoadTypeEnum]: string | undefined }
  )[loadName];
}

export function getBaseOperationTimes() {
  return {
    DIFFUSION_THROUGH_MATERIALS: OperationTime.presets.noHours,
    EMISSION_FROM_COMBUSTION: OperationTime.presets.noHours,
    EMISSION_FROM_PEOPLE: OperationTime.presets.noHours,
    EVAPORATION_FROM_MATERIALS: OperationTime.presets.noHours,
    EVAPORATION_OF_WATER: OperationTime.presets.noHours,
    HEAT_TRANSMISSION: OperationTime.presets.noHours,
    INTENTIONAL_VENTILATION: OperationTime.presets.noHours,
    STATIC_MOISTURE_LOAD: OperationTime.presets.noHours,
    STATIC_HEAT_LOAD: OperationTime.presets.noHours,
    UNINTENDED_VENTILATION: OperationTime.presets.noHours,
    OPEN_DOOR: OperationTime.presets.noHours
  };
}

export function getOperationTimesFromInputSettings(
  input: GraphQLTypes.MoistureLoadCalcInputSettingsType | null | undefined
) {
  return (
    input &&
    [
      {
        key: "DIFFUSION_THROUGH_MATERIALS",
        value: input.diffusionThroughMaterialsOperationTime
      },
      {
        key: "EVAPORATION_OF_WATER",
        value: input.evaporationOfWaterOperationTime
      },
      {
        key: "EVAPORATION_FROM_MATERIALS",
        value: input.evaporationFromMaterialsOperationTime
      },
      {
        key: "EMISSION_FROM_COMBUSTION",
        value: input.emissionFromCombustionOperationTime
      },
      {
        key: "EMISSION_FROM_PEOPLE",
        value: input.emissionFromPeopleOperationTime
      },
      {
        key: "HEAT_TRANSMISSION",
        value: input.heatTransmissionOperationTime
      },
      {
        key: "INTENTIONAL_VENTILATION",
        value: input.intentionalVentilationOperationTime
      },
      {
        key: "STATIC_MOISTURE_LOAD",
        value: input.staticMoistureLoadOperationTime
      },
      {
        key: "STATIC_HEAT_LOAD",
        value: input.staticHeatLoadOperationTime
      },
      {
        key: "UNINTENDED_VENTILATION",
        value: input.unintendedVentilationOperationTime
      },
      {
        key: "OPEN_DOOR",
        value: input.openDoorOperationTime
      }
    ]
      .filter(
        // tslint:disable-next-line:readonly-keyword
        (entry): entry is { key: string; value: string } => !!entry.value
      )
      .map(({ key, value }) => ({
        key: key as MoistureHeatLoadTypeEnum,
        value: OperationTime.fromPropertyValueSet(
          PropertyValueSet.fromString(value)
        )
      }))
      .reduce(
        (soFar, current) => {
          soFar[current.key] = current.value;
          return soFar;
        },
        {} as {
          [loadName in MoistureHeatLoadTypeEnum]?: OperationTime.OperationTime;
        }
      )
  );
}
