import * as Types from "./types";

export const seriesColors: Array<Types.ChartColor> = [
  {
    name: "Red1",
    color: {
      a: 255,
      r: 255,
      g: 0,
      b: 0
    }
  },
  {
    name: "Red2",
    color: {
      a: 255,
      r: 150,
      g: 0,
      b: 59
    }
  },
  {
    name: "Red3",
    color: {
      a: 255,
      r: 255,
      g: 110,
      b: 110
    }
  },
  {
    name: "Red4",
    color: {
      a: 255,
      r: 255,
      g: 59,
      b: 0
    }
  },
  {
    name: "Blue1",
    color: {
      a: 255,
      r: 0,
      g: 0,
      b: 255
    }
  },
  {
    name: "Blue2",
    color: {
      a: 255,
      r: 59,
      g: 0,
      b: 150
    }
  },
  {
    name: "Blue3",
    color: {
      a: 255,
      r: 110,
      g: 110,
      b: 255
    }
  },
  {
    name: "Blue4",
    color: {
      a: 255,
      r: 59,
      g: 0,
      b: 255
    }
  },
  {
    name: "Yellow1",
    color: {
      a: 255,
      r: 255,
      g: 255,
      b: 0
    }
  },
  {
    name: "Yellow2",
    color: {
      a: 255,
      r: 200,
      g: 200,
      b: 0
    }
  },
  {
    name: "Yellow3",
    color: {
      a: 255,
      r: 220,
      g: 180,
      b: 0
    }
  },
  {
    name: "Green1",
    color: {
      a: 255,
      r: 64,
      g: 255,
      b: 0
    }
  },
  {
    name: "Green2",
    color: {
      a: 255,
      r: 64,
      g: 192,
      b: 64
    }
  },
  {
    name: "Green3",
    color: {
      a: 255,
      r: 0,
      g: 128,
      b: 64
    }
  }
];
