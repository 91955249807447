import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { BinData } from "../../tools";
import { BaseState } from "../../state";
import * as ClimateSelector from "../../../climate-selector";
import * as SharedState from "../../../shared-state";
import * as LocationSelectorGen2 from "../../../location-selector";
import * as GraphQLTypes from "../../../graphql-types";

export type State = BaseState & {
  readonly locationSelectorState: LocationSelectorGen2.State | undefined;
  readonly binData: BinData | undefined;
  readonly climateSelectorState: ClimateSelector.State | undefined;
  readonly binDataSelectorDisabled: boolean;
};

export const init = (
  baseState: BaseState,
  sharedState: SharedState.State,
  climateSettings: PropertyValueSet.PropertyValueSet,
  binLocations: ReadonlyArray<
    GraphQLTypes.MoistureLoadCalculationProductQuery["product"]["binDataLocations"][0]
  >,
  coordinate: LocationSelectorGen2.Coordinate | undefined,
  binData: BinData
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const [climateSelectorState, climateSelectorCmd] = ClimateSelector.init(
    climateSettings,
    sharedState
  );

  const initialState: State = {
    ...baseState,
    binData: binData,
    locationSelectorState: LocationSelectorGen2.init(binLocations, coordinate),
    climateSelectorState: climateSelectorState,
    binDataSelectorDisabled: false
  };

  const newState = onClimateSettingsChange(initialState);

  return [
    newState,
    Cmd.map(
      action => Action.dispatchClimateSelector(action, true),
      climateSelectorCmd
    )
  ];
};

export const Action = ctorsUnion({
  dispatchClimateSelector: (
    action: ClimateSelector.Action,
    fromInit?: boolean
  ) => ({ action, fromInit }),
  dispatchLocationSelector: (action: LocationSelectorGen2.Action) => ({
    action
  }),
  setBinData: (binData: BinData) => ({ binData })
});

export type Action = CtorsUnion<typeof Action>;

type ResultsInvalid = boolean;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?,
  ResultsInvalid?
] {
  switch (action.type) {
    case "dispatchClimateSelector": {
      const fromInit = action.fromInit;

      if (!state.climateSelectorState) {
        return [state];
      }

      const [climateSelectorState, climateSelectorCmd, sharedStateAction] =
        ClimateSelector.update(
          action.action,
          state.climateSelectorState,
          sharedState
        );

      const newState = onClimateSettingsChange({
        ...state,
        climateSelectorState: climateSelectorState
      });

      return [
        newState,
        Cmd.map(
          action => Action.dispatchClimateSelector(action, fromInit),
          climateSelectorCmd
        ),
        [sharedStateAction],
        !fromInit
      ];
    }

    case "dispatchLocationSelector": {
      if (state.locationSelectorState === undefined) {
        return [state];
      }

      const [locationSelectorState, locationId] = LocationSelectorGen2.update(
        action.action,
        state.locationSelectorState,
        sharedState
      );
      return [
        {
          ...state,
          binData: {
            ...state.binData!,
            locationId
          },
          locationSelectorState
        },
        undefined,
        undefined,
        true
      ];
    }

    case "setBinData": {
      return [
        {
          ...state,
          binData: action.binData
        },
        undefined,
        undefined,
        true
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getBinDataInfo(state: State) {
  return state.binData;
}

export function reset(state: State): State {
  return {
    ...state
  };
}

export function getClimateSettings(state: State) {
  return state.climateSelectorState?.climateSettings;
}

export function defaultBinDataLocationProperties(
  binLocations: ReadonlyArray<
    GraphQLTypes.MoistureLoadCalculationProductQuery["product"]["binDataLocations"][0]
  >,
  coordinate: LocationSelectorGen2.Coordinate | undefined
): BinData {
  const location = LocationSelectorGen2.getDefaultLocation(
    binLocations,
    coordinate
  );
  const coolingDataType = "DP/MCDB";

  return {
    locationId: location.binDataLocationId,
    binSize: 5,
    dataType: coolingDataType
  };
}

function onClimateSettingsChange(state: State): State {
  const climateSettings = state.climateSelectorState!.climateSettings;
  const wmo = PropertyValueSet.getText("wmo", climateSettings);

  if (!wmo) {
    return {
      ...state,
      binDataSelectorDisabled: false
    };
  }

  const binDataIndexesForWmo =
    state.locationSelectorState!.binLocationIndexMapper.get(wmo);
  if (!binDataIndexesForWmo) {
    return {
      ...state,
      binDataSelectorDisabled: false
    };
  }

  return {
    ...state,
    binDataSelectorDisabled: true,
    binData: {
      ...state.binData!,
      locationId: wmo
    },
    locationSelectorState: {
      ...state.locationSelectorState!,
      selectedCountryIndex: binDataIndexesForWmo.countryIndex,
      selectedRegionIndex: binDataIndexesForWmo.regionIndex,
      selectedLocationIndex: binDataIndexesForWmo.locationIndex
    }
  };
}
