import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  width: 1440px;
  margin: 0 auto;
`;

export const OuterContainer = styled.div`
  display: flex;
  padding: 30px 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
`;

export const BorderContainer = styled.div`
  margin-top: 10px;
  border: 1px solid ${PrimaryColors.darkBlue};
  border-radius: 10px;
`;

export const ControllerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GreyDiv = styled.div`
  color: #959695;
`;
