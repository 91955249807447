import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as MoistureLoadStatus from "@genesys/shared/lib/enums/moisture-load-status";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import Tooltip from "@mui/material/Tooltip";
import {
  StatusInProgress100,
  StatusLocked,
  StatusComplete,
  StatusError,
  StatusInProgressYellow
} from "@genesys/ui-elements/lib/icons";

const StyledSpan = styled.span`
  img {
    margin: 0;
    padding: 0;
  }
`;

type SystemStatus = "inProgress" | "lockedRevision" | "succesfull" | "error";
type MoistureLoadStatus =
  | "created"
  | "saved"
  | "calcSuccess"
  | "error"
  | "lockedRevision";
export function StatusIconManager({
  statusNumber,
  sharedState,
  height,
  width,
  type = "system"
}: {
  readonly statusNumber: number;
  readonly sharedState: SharedState.State;
  readonly type?: "moistureload" | "system";
  readonly height?: string;
  readonly width?: string;
}): JSX.Element {
  if (type === "system") {
    return (
      <SystemStatusView
        sharedState={sharedState}
        statusNumber={statusNumber}
        height={height}
        width={width}
        type={type}
      />
    );
  }

  return (
    <MoistureLoadStatusView
      sharedState={sharedState}
      statusNumber={statusNumber}
      height={height}
      width={width}
      type={type}
    />
  );
}

function MoistureLoadStatusView({
  statusNumber,
  sharedState,
  height,
  width,
  type
}: {
  readonly statusNumber: number;
  readonly sharedState: SharedState.State;
  readonly type: "moistureload";
  readonly height?: string;
  readonly width?: string;
}) {
  const status = getStatusMoistureLoad(statusNumber);
  switch (status) {
    case "created": {
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusInProgressYellow
          width={width}
          height={height ? height : "1.6rem"}
        />
      );
    }
    case "saved":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusInProgress100
          width={width}
          height={height ? height : "1.6rem"}
        />
      );

    case "lockedRevision":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusLocked width={width} height={height ? height : "1.6rem"} />
      );

    case "calcSuccess":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusComplete width={width} height={height ? height : "1.6rem"} />
      );

    case "error":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusError width={width} height={height ? height : "1.6rem"} />
      );

    default:
      return exhaustiveCheck(status);
  }
}

function SystemStatusView({
  statusNumber,
  sharedState,
  height,
  width,
  type
}: {
  readonly statusNumber: number;
  readonly sharedState: SharedState.State;
  readonly type: "system";
  readonly height?: string;
  readonly width?: string;
}) {
  const status = getStatusSystem(statusNumber);
  switch (status) {
    case "inProgress":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusInProgress100
          width={width}
          height={height ? height : "1.6rem"}
        />
      );

    case "lockedRevision":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusLocked width={width} height={height ? height : "1.6rem"} />
      );

    case "succesfull":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusComplete width={width} height={height ? height : "1.6rem"} />
      );

    case "error":
      return createStatusIcon(
        sharedState,
        type,
        statusNumber,
        <StatusError width={width} height={height ? height : "1.6rem"} />
      );

    default:
      return exhaustiveCheck(status);
  }
}

function createStatusIcon(
  sharedState: SharedState.State,
  type: "moistureload" | "system",
  statusNumber: number,
  statusIcon: JSX.Element
) {
  return (
    <Tooltip title={getStatusTitle(sharedState, type, statusNumber)} arrow>
      <StyledSpan>{statusIcon}</StyledSpan>
    </Tooltip>
  );
}

function getStatusTitle(
  sharedState: SharedState.State,
  type: "moistureload" | "system",
  status: SystemStatus.SystemStatus | MoistureLoadStatus.MoistureLoadStatus
) {
  const systemStatusTranslator = (status: SystemStatus.SystemStatus) =>
    sharedState.translate(
      LanguageTexts.dynamicText(
        "systemstatus_" + SystemStatus.SystemStatus[status]
      )
    );

  const moistureLoadStatusTranslator = (
    status: MoistureLoadStatus.MoistureLoadStatus
  ) =>
    sharedState.translate(
      LanguageTexts.dynamicText(
        "moistureloadstatus_" + MoistureLoadStatus.MoistureLoadStatus[status]
      )
    );

  if (type === "system") {
    return systemStatusTranslator(status as SystemStatus.SystemStatus);
  }

  return moistureLoadStatusTranslator(
    status as MoistureLoadStatus.MoistureLoadStatus
  );
}

function getStatusSystem(statusNumber: number): SystemStatus {
  const statusString = SystemStatus.SystemStatus[statusNumber];

  if (statusString.includes("Failed")) {
    return "error";
  } else if (statusNumber <= SystemStatus.SystemStatus.PostProcessingSuccess) {
    return "inProgress";
  } else if (
    statusNumber <= SystemStatus.SystemStatus.MCompareCalculationSuccess
  ) {
    return "succesfull";
  } else {
    return "lockedRevision";
  }
}

function getStatusMoistureLoad(statusNumber: number): MoistureLoadStatus {
  const statusString = MoistureLoadStatus.MoistureLoadStatus[statusNumber];

  if (statusString.includes("Failed")) {
    return "error";
  } else if (statusNumber === MoistureLoadStatus.MoistureLoadStatus.Created) {
    return "created";
  } else if (statusNumber <= MoistureLoadStatus.MoistureLoadStatus.Saved) {
    return "saved";
  } else if (
    statusNumber <= MoistureLoadStatus.MoistureLoadStatus.CalculationSuccess
  ) {
    return "calcSuccess";
  } else {
    return "lockedRevision";
  }
}
