import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { Cmd } from "@typescript-tea/core";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { Action } from "../state";
import { updateSystemMutation } from "../update-system-mutation";

export function calculateEnergyComparison(
  sharedState: SharedState.State,
  systemId: string
): [Cmd<Action>, SystemStatus] {
  return [
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.UpdateSystemStatus,
      GraphQLTypes.UpdateSystemStatusVariables,
      Action
    >(
      updateSystemMutation,
      {
        input: {
          forceUpdate: false,
          systemId,
          targetStatus: SystemStatus.MCompareCalculationSuccess
        }
      },
      data =>
        Action.systemMutationComplete(
          data.updateSystem.__typename === "UpdateSystemRejectResultType"
            ? data.updateSystem.rejectReason ?? undefined
            : undefined,
          data.updateSystem.traceId || undefined
        )
    ),
    SystemStatus.MCompareCalculationSuccess
  ];
}
