import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as GraphQlTypes from "../../../../graphql-types";
import * as System from "../../../system";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import { getEnergyTotalItemsWithVisibility } from "../functions";
import { Amount, Quantity, Units, Format, Unit } from "@genesys/uom";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../../shared-state";
import { SimplifiedProps } from "../../../../amount-property-selector";
import { Action } from "./state";

export function buildCostSettings(
  energyItems: ReadonlyArray<SharedEnergyTools.EnergyItem>,
  systemTypeId: string,
  userEnergySettings: { readonly [key: string]: string },
  binSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const userSettings = PropertyValueSet.fromString(
    userEnergySettings[
      KnownProperties.energyPriceInput + systemTypeId.toLowerCase()
    ] || ""
  );

  return energyItems.reduce((a, b) => {
    const fromBin = PropertyValueSet.get(b.name, binSelections);
    if (fromBin !== undefined) {
      return PropertyValueSet.set(b.name, fromBin, a);
    }

    const userSetting = PropertyValueSet.get(b.name, userSettings);

    return PropertyValueSet.set(
      b.name,
      userSetting ? userSetting : PropertyValue.fromAmount(b.defaultAmount),
      a
    );
  }, PropertyValueSet.Empty);
}

export function buildSystemEnergyItems(
  energyItems: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["systemType"]["energyTotals"][0]
  >,
  components: ReadonlyArray<System.Component>
): ReadonlyArray<SharedEnergyTools.EnergyItem> {
  if (!energyItems.length) {
    return [];
  }
  const costTypes = getEnergyTotalItemsWithVisibility(energyItems, components)
    .concat()
    .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
    .reduce((soFar, current) => {
      const costType = formatCostType(current.costType);
      const quantity = current.quantityId as Quantity.Quantity;
      const getUnit = (quantity: Quantity.Quantity) => {
        switch (quantity) {
          case "DimensionlessPerEnergy":
            return Units.OnePerKiloWattHour;
          case "DimensionlessPerMass":
            return Units.OnePerKilogram;
          case "DimensionlessPerVolume":
            return Units.OnePerCubicMeter;
          default:
            return Format.getUnitsForQuantity(quantity)[0];
        }
      };

      // cooling-water, heating-water and steam have extra fields that are used to calculate the price
      if (
        (costType === KnownProperties.coolingWaterCost ||
          costType === KnownProperties.heatingWaterCost ||
          costType === KnownProperties.steamCost) &&
        quantity === "DimensionlessPerEnergy"
      ) {
        // Cost per unit energy
        soFar.push({
          name:
            costType.substring(0, costType.length - 4) +
            KnownProperties.costPerUnitEnergy,
          defaultAmount: Amount.create(0.1, getUnit(quantity)),
          cost: true,
          shouldBeVisible: current.shouldBeVisible
        });
        // COP or System efficiency
        const efficiencyName =
          costType === KnownProperties.coolingWaterCost
            ? KnownProperties.coolingWaterCop
            : formatCostType(current.costType) + "efficiency";
        soFar.push({
          name: efficiencyName,
          defaultAmount: Amount.create(1, getUnit("Dimensionless")),
          cost: false,
          shouldBeVisible: current.shouldBeVisible
        });
        // Calculated price
        soFar.push({
          name: costType,
          defaultAmount: Amount.create(0.1, getUnit(quantity)),
          cost: true,
          shouldBeVisible: current.shouldBeVisible,
          readonly: true
        });
      } else {
        // Regular price
        soFar.push({
          name: costType,
          defaultAmount: Amount.create(0.1, getUnit(quantity)),
          cost: true,
          shouldBeVisible: current.shouldBeVisible
        });
      }
      // Price increase
      soFar.push({
        name: costType + KnownProperties.priceInc,
        defaultAmount: Amount.create(0, Units.One),
        shouldBeVisible: current.shouldBeVisible
      });
      return soFar;
    }, [] as Array<SharedEnergyTools.EnergyItem>);

  return costTypes;
}

export function buildStaticEnergyItems(): ReadonlyArray<SharedEnergyTools.EnergyItem> {
  return [
    {
      name: KnownProperties.maintenanceCost,
      defaultAmount: Amount.create(0, Units.One),
      cost: true,
      shouldBeVisible: true
    },
    {
      name: KnownProperties.maintenanceCostPriceInc,
      defaultAmount: Amount.create(0, Units.One),
      shouldBeVisible: true
    },
    {
      name: KnownProperties.disposalCost,
      defaultAmount: Amount.create(0, Units.One),
      cost: true,
      shouldBeVisible: true
    },
    {
      name: KnownProperties.lccYears,
      defaultAmount: Amount.create(20, Units.Year),
      shouldBeVisible: true
    },
    {
      name: KnownProperties.systemPrice,
      defaultAmount: Amount.create(0, Units.One),
      cost: true,
      shouldBeVisible: true
    },
    {
      name: KnownProperties.interest,
      defaultAmount: Amount.create(0, Units.One),
      shouldBeVisible: true
    }
  ];
}

export function buildSettingsDictionary(
  energySettings: ReadonlyArray<{
    readonly settingName: string;
    readonly settingValue: string;
  }>
): { readonly [key: string]: string } {
  const dictionary = energySettings.reduce(
    // tslint:disable-next-line
    (a: { [key: string]: string }, b) => {
      a[b.settingName.toLowerCase()] = b.settingValue;
      return a;
    },
    {}
  );

  return { ...dictionary };
}

export function getAmountPropertySelectorOptions(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  item: SharedEnergyTools.EnergyItem,
  pvs: PropertyValueSet.PropertyValueSet,
  readonly: boolean
): SimplifiedProps {
  const fieldGroup = "EnergyPriceInputFieldGroup";
  // Adding quantity to fieldname so that users can set formats without it crashing,
  // when items might have DimensionlessPerEnergy on one system and DimensionLessPerMass on another,
  // which whould cause a converstion crash
  const fieldName = item.name + item.defaultAmount.unit.quantity;
  return {
    type: "with-simplified-props",
    fieldName: fieldName,
    propertyName: item.name,
    quantity: item.defaultAmount.unit.quantity,
    translate: sharedState.translate,
    propertyValueSet: pvs,
    fieldGroup: fieldGroup,
    getAmountFormat: sharedState.screenAmounts.getAmountFormat,
    onValueChange: pv => {
      if (!pv) {
        return;
      }
      dispatch(
        Action.setEnergyCost(item.name, PropertyValue.fromAmount(pv), pvs)
      );
    },
    validationFilter: PropertyFilter.Empty,
    readOnly: readonly,
    onFormatChanged: (
      unit: Unit.Unit<Quantity.Quantity>,
      decimalCount: number
    ) =>
      dispatch(
        Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
      ),
    onFormatCleared: () => {
      dispatch(Action.onFormatCleared(fieldGroup, fieldName));
    }
  };
}

function formatCostType(cost: string): string {
  return cost.replace(
    /([A-Z]+)_?/g,
    (_, g1) => g1[0].toUpperCase() + g1.substr(1).toLowerCase()
  );
}
