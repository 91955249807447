import * as React from "react";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { GenesysSelect, H3 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../../state";
import { SelectedOptionsType } from "../../types";

const Container = styled.div``;

const SelectionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;

const Header = styled.div``;

const genesysSelectStylingProps = {
  width: 80,
  height: 35
};

export function CurrencyAndSalesOrgSelector({
  selectableSalesOrganisations,
  dispatch,
  sharedState,
  isDisabled,
  selectedOptions,
  showMasterSettings
}: {
  readonly selectableSalesOrganisations: ReadonlyArray<
    GraphQlTypes.PriceProduct["product"]["salesOrganisations"][0]
  >;
  readonly dispatch: Dispatch<Action>;
  readonly selectedOptions: SelectedOptionsType;
  readonly sharedState: SharedState.State;
  readonly isDisabled: boolean;
  readonly showMasterSettings: boolean;
}): JSX.Element {
  return (
    <Container>
      <Header>
        <H3>{sharedState.translate(LanguageTexts.selection())}</H3>
        <SelectionContainer>
          <span>
            {sharedState.translate(LanguageTexts.salesOrganisation())}
          </span>
          <span>
            <GenesysSelect
              {...genesysSelectStylingProps}
              disabled={isDisabled}
              value={selectedOptions.selectedSalesOrganisationId}
              onChange={e => {
                dispatch(Action.setSalesOrganisation(e.target.value));
              }}
              options={selectableSalesOrganisations.map(s => ({
                value: s.id,
                title: "(" + s.id + ")" + " " + s.name
              }))}
            />
          </span>
        </SelectionContainer>
        <SelectionContainer>
          <span>{sharedState.translate(LanguageTexts.currency())}</span>
          <span>
            <GenesysSelect
              {...genesysSelectStylingProps}
              value={selectedOptions.selectedCurrencyCode}
              disabled={isDisabled}
              onChange={e => dispatch(Action.setCurrency(e.target.value))}
              options={sharedState.user.currencies.map(c => ({
                value: c.id,
                title: "" + c.name + " (" + c.id + ")"
              }))}
            />
            {}
          </span>
        </SelectionContainer>
        {showMasterSettings && [
          <SelectionContainer>
            <span>
              {sharedState.translate(LanguageTexts.masterSalesOrganisation())}
            </span>
            <span>
              <GenesysSelect
                {...genesysSelectStylingProps}
                value={selectedOptions.selectedMasterSalesOrganisationId}
                disabled={isDisabled}
                onChange={e =>
                  dispatch(Action.setMasterSalesOrganisation(e.target.value))
                }
                options={selectableSalesOrganisations.map(m => ({
                  value: m.id,
                  title: "(" + m.id + ")" + " " + m.name
                }))}
              />
            </span>
          </SelectionContainer>,
          <SelectionContainer>
            <span>{sharedState.translate(LanguageTexts.masterCurrency())}</span>
            <span>
              <GenesysSelect
                {...genesysSelectStylingProps}
                value={selectedOptions.selectedMasterCurrencyCode || "none"}
                disabled={isDisabled}
                onChange={e =>
                  dispatch(Action.setMasterCurrencyCode(e.target.value))
                }
                options={sharedState.user.currencies.map(m => ({
                  value: m.id,
                  title: "" + m.name + " (" + m.id + ")"
                }))}
              />
            </span>
          </SelectionContainer>
        ]}
      </Header>
    </Container>
  );
}
