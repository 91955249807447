import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../../../graphql-types";
import * as ResultTable from "../../../shared-manager-components/search-result-table";
import { State, Action, itemsPerPage } from "../../state";
import { Dispatch } from "@typescript-tea/core";
import { OverlayLoader, H3, P2, ActionButton } from "@genesys/ui-elements";
import { PagnationContainer, ActionButtonsContainer } from "../../elements";
import {
  getHeaderCellContent,
  getFileAndRevisionContent
} from "./cell-content";
import {
  PagnationComponent,
  ResultsRoot
} from "../../../shared-manager-components";
import { ColumnDefinition, PricingRow } from "../../types";

export function ResultTableView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state.userQueryResult) {
    return <OverlayLoader />;
  }

  const paginationComponent = (
    <PagnationContainer>
      <PagnationComponent
        sharedState={sharedState}
        currentPage={state.currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={
          state.userQueryResult?.user.searchPricings.totalResults || 0
        }
        onPageChange={pageNumber => dispatch(Action.changePage(pageNumber))}
      />
    </PagnationContainer>
  );

  const allPricingIds =
    state.userQueryResult?.user.searchPricings.results.reduce(
      (a, b) => a.concat(b.pricings.map(s => s.id)),
      [] as ReadonlyArray<string>
    ) || [];

  const dataColumns = createDataColumnsDef();
  const isAllSelected =
    allPricingIds.length === state.selectedPricingIds.length;

  const onDeletePricings = () => {
    if (
      !state.userQueryResult?.user.searchPricings.results
        .filter(r =>
          r.pricings.find(
            s => !!state.selectedPricingIds.find(ss => s.id === ss)
          )
        )
        .every(sf =>
          sf.pricings.every(
            s => !!state.selectedPricingIds.find(ss => ss === s.id)
          )
        )
    ) {
      dispatch(
        Action.openModal({
          content: sharedState.translate(
            LanguageTexts.dynamicText("must_check_all_revisions")
          ),
          onClose: () => dispatch(Action.closeModal()),
          header: sharedState.translate(LanguageTexts.error())
        })
      );
    } else {
      const pricingFileIds = state
        .userQueryResult!.user.searchPricings.results.filter(r =>
          r.pricings.find(
            s => !!state.selectedPricingIds.find(ss => s.id === ss)
          )
        )
        .map(s => s.id);

      dispatch(
        Action.openModal({
          header: sharedState.translate(LanguageTexts.confirmation()),
          content: sharedState.translate(
            LanguageTexts.deleteAllSelectedMsg(state.selectedPricingIds.length)
          ),
          onClose: () => dispatch(Action.closeModal()),
          onConfirm: () => {
            dispatch(Action.deletePricings(pricingFileIds));
          }
        })
      );
    }
  };

  return (
    <ResultsRoot>
      {paginationComponent}

      {state.selectedPricingIds.length > 0 && (
        <ActionButtonsContainer>
          <H3 weight="bold" color="dark">
            {sharedState.translate(LanguageTexts.actions())}
          </H3>
          <ActionButton kind="delete" onClick={() => onDeletePricings()} />
        </ActionButtonsContainer>
      )}

      <ResultTable.Container>
        <ResultTable.TableHeader
          headerIsChecked={isAllSelected}
          checkBoxOnClick={() => {
            isAllSelected
              ? dispatch(Action.setSelectedPricings([]))
              : dispatch(Action.setSelectedPricings(allPricingIds));
          }}
          cellValues={dataColumns.map(columnDef =>
            getHeaderCellContent(
              state,
              columnDef.columnName,
              sharedState,
              dispatch
            )
          )}
        />
        {state.userQueryResult?.user.searchPricings.results.map(resultRow => {
          const selectedPricingIds = state.selectedPricingIds;

          const allRevisionsChecked = resultRow.pricings.every(s =>
            selectedPricingIds.includes(s.id)
          );

          const isRevisionExpanded = state.expandedRevisions.includes(
            resultRow.id
          );

          return (
            <ResultTable.TableBodyRowContainer
              isRevisionExpanded={isRevisionExpanded}
            >
              {createResultTableRow(
                allRevisionsChecked,
                sharedState,
                state,
                dataColumns,
                resultRow,
                isRevisionExpanded,
                dispatch
              )}

              {resultRow.pricings.map(rev => {
                return createRevisionRow(
                  state,
                  sharedState,
                  isRevisionExpanded,
                  dataColumns,
                  resultRow,
                  rev,
                  dispatch
                );
              })}
            </ResultTable.TableBodyRowContainer>
          );
        })}
      </ResultTable.Container>
      {paginationComponent}
    </ResultsRoot>
  );
}

function createRevisionHeader(
  revisionCell: number,
  totalNumberOfcells: number,
  sharedState: SharedState.State
) {
  return (
    <tr>
      {Array(totalNumberOfcells)
        .fill(null)
        .map((_u, ix) => (
          <td>
            {ix === revisionCell ? (
              <P2 color="dark" weight="bold">
                {sharedState.translate(LanguageTexts.revisions())}
              </P2>
            ) : (
              ""
            )}
          </td>
        ))}
    </tr>
  );
}

function createRevisionRow(
  state: State,
  sharedState: SharedState.State,
  isRevisionExpanded: boolean,

  dataColumns: readonly ColumnDefinition[],
  resultRow: GraphQLTypes.PricingManagerResultSearchResult,
  rev: GraphQLTypes.PricingManagerResultPricing,
  dispatch: (action: Action) => void
) {
  const isActionsOpen = state.openPricingActions === rev.id;
  const revisionRow: PricingRow = {
    type: "revision",
    id: rev.id,
    name: rev.name,
    pricingNo: resultRow.pricingNo,
    revisionNo: rev.revisionNo,
    status: rev.status,
    statusDate: rev.statusDate,
    isActionMenuOpen: isActionsOpen,
    isRevisionExpanded: isRevisionExpanded
  };

  return (
    <ResultTable.RevisionRow
      isActionsOpen={isActionsOpen}
      isRowChecked={state.selectedPricingIds.includes(rev.id)}
      isRevisionExpanded={isRevisionExpanded}
      cells={dataColumns.map(columnDef =>
        getFileAndRevisionContent(
          state,
          columnDef.columnName,
          sharedState,
          dispatch,
          revisionRow
        )
      )}
      checkBoxOnClick={() => {
        dispatch(Action.toggleSelectedPricings(rev.id));
      }}
    />
  );
}

function createResultTableRow(
  allRevisionsChecked: boolean,
  sharedState: SharedState.State,
  state: State,
  dataColumns: readonly ColumnDefinition[],
  resultRow: GraphQLTypes.PricingManagerResultSearchResult,
  isRevisionExpanded: boolean,
  dispatch: (action: Action) => void
) {
  const isActionsOpen = state.openPricingActions === resultRow.id;

  const pricingFileRow: PricingRow = {
    type: "file",
    id: resultRow.id,
    labels: resultRow.labels,
    name: resultRow.name,
    owner: resultRow.owner,
    pricings: resultRow.pricings,
    latestPricing: resultRow.latestPricing,
    pricingNo: resultRow.pricingNo,
    isActionMenuOpen: isActionsOpen,
    isRevisionExpanded: isRevisionExpanded
  };

  return (
    <ResultTable.TableRow
      revisionsHeader={
        pricingFileRow.isRevisionExpanded &&
        createRevisionHeader(
          dataColumns.findIndex(x => x.columnName === "p-no") + 1,
          dataColumns.length + 1,
          sharedState
        )
      }
      isRowChecked={allRevisionsChecked}
      cells={dataColumns.map(columnDef =>
        getFileAndRevisionContent(
          state,
          columnDef.columnName,
          sharedState,
          dispatch,
          pricingFileRow
        )
      )}
      isActionsOpen={pricingFileRow.isActionMenuOpen}
      checkBoxOnClick={() => {
        dispatch(
          Action.setSelectedPricings(
            allRevisionsChecked
              ? state.selectedPricingIds.filter(
                  x => !pricingFileRow.pricings.map(x => x.id).includes(x)
                )
              : Array.from(
                  new Set(
                    state.selectedPricingIds.concat(
                      pricingFileRow.pricings.map(x => x.id)
                    )
                  )
                )
          )
        );
      }}
    />
  );
}

function createDataColumnsDef(): ReadonlyArray<ColumnDefinition> {
  return [
    {
      columnName: "p-no"
    },
    {
      columnName: "actions-menu"
    },
    {
      columnName: "pricing-name"
    },
    { columnName: "owner" },
    {
      columnName: "date-changed"
    },
    { columnName: "status" },
    {
      columnName: "labels"
    },
    {
      columnName: "revisions"
    }
  ];
}
