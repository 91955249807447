import gql from "graphql-tag";

export const setUserSettingsSystemsSummaryMutation = gql`
  mutation SetUserSettingsSystemsSummary(
    $newsettings: UserSettingsSystemsSummaryInputType!
  ) {
    setUserSettingsSystemsSummary(userSettingsSystemsSummary: $newsettings) {
      id
    }
  }
`;
