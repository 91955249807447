import * as GraphQlTypes from "../../../../graphql-types";
import * as Types from "./types";
import * as System from "../../../system";
import * as CaseType from "@genesys/shared/lib/enums/case-type";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { EnergyResult } from "../../../../energy-result-visualizer";

export function getTotalEnergyConsumption(
  params: ReadonlyArray<GraphQlTypes.CompleteEnergyParam>
): SharedEnergyTools.EnergyParam {
  return params.reduce(
    (soFar, current) => {
      const a = PropertyValue.getAmount(
        PropertyValue.fromString(current.amountFieldValue.amount)!
      )! as Amount.Amount<Quantity.Quantity>;

      if (a.unit.quantity === "Energy") {
        return {
          ...soFar,
          amountField: {
            ...soFar.amountField,
            amount: Amount.plus(soFar.amountField.amount, a)
          }
        };
      } else {
        return soFar;
      }
    },
    {
      energyParamName: "TotalEnergyConsumption",
      amountField: {
        fieldId: {
          fieldGroup: params[0].amountFieldValue.amountField.fieldGroup,
          fieldName: "TotalEnergyConsumption"
        },
        amount: Amount.create(0, Units.KiloWattHour)
      }
    } as SharedEnergyTools.EnergyParam
  );
}

export function parseEnergyParam(
  param: GraphQlTypes.CompleteEnergyParam
): SharedEnergyTools.EnergyParam {
  return {
    energyParamName: param.paramName,
    amountField: {
      amount: PropertyValue.getAmount(
        PropertyValue.fromString(param.amountFieldValue.amount)!
      )! as Amount.Amount<Quantity.Quantity>,
      fieldId: {
        fieldGroup: param.amountFieldValue.amountField.fieldGroup,
        fieldName: param.amountFieldValue.amountField.fieldName
      }
    }
  };
}

export function parseEnergyCost(
  cost: GraphQlTypes.CompleteEnergyCost
): SharedEnergyTools.EnergyCost {
  return {
    name: cost.name,
    cost: cost.cost,
    currencySymbol: cost.currencySymbol
  };
}

export function getEnergyResult(
  systems: Array<System.System>,
  energyTotalItems: ReadonlyArray<GraphQlTypes.EnergyResultEnergyTotalItem>,
  energyListItems: ReadonlyArray<GraphQlTypes.EnergyResultEnergyListItem>,
  product: string
): EnergyResult {
  const binCaseResult = getBinCaseResults(systems, product);
  const energyPresentationPerfParams = energyListItems
    .concat()
    .sort((a, b) => a.sortNo - b.sortNo)
    .map(e => e.name);

  const energyPresentationTotalPerfParams = energyTotalItems
    .concat()
    .sort((a, b) => a.sortNo - b.sortNo)
    .map(e => e.name);

  return {
    binResults: binCaseResult,
    presentationPerfParams: energyPresentationPerfParams,
    presentationTotalPerfParams: energyPresentationTotalPerfParams
  };
}

function getBinCaseResults(
  systems: Array<System.System>,
  binProduct: string
): Array<Types.BinCaseResult> {
  const firstSystem = systems[0];
  const binCases = firstSystem.operatingCases.filter(
    oc => oc.caseType === CaseType.CaseType.Bin
  );

  return binCases.reduce((a, b) => {
    const binResults = systems.reduce(
      (aa, bb) => {
        const systemBinCase = bb.operatingCases
          .filter(oc => oc.caseType === CaseType.CaseType.Bin)
          .find(
            oc =>
              PropertyValueSet.getInteger(
                "id",
                PropertyValueSet.fromString(oc.binData!)
              ) ===
              PropertyValueSet.getInteger(
                "id",
                PropertyValueSet.fromString(b.binData!)
              )
          )!;
        const binComponent = bb.components.find(c =>
          c.productId.endsWith(binProduct)
        )!;

        const sysComponentResults = systemBinCase.results.find(
          r => r.componentId === binComponent.id
        );

        return sysComponentResults
          ? aa.concat({
              count:
                PropertyValueSet.getInteger(
                  "numberofunits",
                  binComponent.properties
                ) || 1,
              settings: PropertyValueSet.fromString(
                sysComponentResults.settings!
              )
            })
          : aa;
      },
      [] as Array<{
        readonly count: number;
        readonly settings: PropertyValueSet.PropertyValueSet;
      }>
    );

    const allPerfParams = binResults
      .map(r => r.settings)
      .reduce((aa, bb) => {
        const pp = PropertyValueSet.getPropertyNames(bb)
          .filter(p => p.startsWith("_") && !aa.some(aaa => aaa === p))
          .map(p => p.replace("_", ""));
        return aa.concat(pp);
      }, [] as Array<string>);

    const results = allPerfParams.reduce(
      (aa, bb) =>
        bb !== "BinTime"
          ? PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                bb,
                PropertyValue.fromAmount(
                  binResults
                    .map(r =>
                      Amount.times(
                        PropertyValueSet.getAmount("_" + bb, r.settings)!,
                        r.count
                      )
                    )
                    .reduce((aaa, bbb) => Amount.plus(aaa, bbb))
                )
              ),
              aa
            )
          : aa,
      PropertyValueSet.fromProperty(
        "BinTime",
        PropertyValueSet.get("_BinTime", binResults[0].settings)!
      )
    );

    return a.concat({
      binData: PropertyValueSet.fromString(b.binData!),
      results: results
    });
  }, [] as Array<Types.BinCaseResult>);
}
