import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SystemOverrides } from "../../tools";
import { BaseState } from "../../state";
import * as SystemOverride from "../system-overrides";
import * as SharedState from "../../../shared-state";
import * as RoomControlSettings from "../../tools/moisture-load-rules/room-control-settings";

export type State = BaseState & {
  readonly systemOverridesState: SystemOverride.State;
};

export const init = (
  baseState: BaseState
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      systemOverridesState: SystemOverride.init(baseState)[0],
      ...baseState
    }
  ];
};

export const Action = ctorsUnion({
  dispatchSystemOverride: (action: SystemOverride.Action) => ({ action }),
  dummy: () => ({})
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  currentSystemOverrides: SystemOverrides
): readonly [
  State,
  SystemOverrides,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchSystemOverride": {
      const [systemOverridesState, updatedSystemOverrides] =
        SystemOverride.update(
          action.action,
          state.systemOverridesState,
          sharedState,
          currentSystemOverrides,
          RoomControlSettings.getPropertyUpdates
        );
      return [
        {
          ...state,
          systemOverridesState
        },
        updatedSystemOverrides
      ];
    }

    case "dummy": {
      return [
        {
          ...state
        },
        currentSystemOverrides
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function reset(state: State): State {
  return {
    ...state
  };
}
