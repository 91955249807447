import * as React from "react";
import gql from "graphql-tag";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as Elements from "../elements";
import * as MutationsQueue from "@genesys/graphql-mutations-queue";
import * as MutationsQueueUser from "../mutations-queue-user";
import * as GraphQLMutations from "../graphql-mutations";
import * as UserProfile from "../user-profile";
import { LocationComponentAshrae } from "./components/location-component-ashrae";
import { LocationComponentManual } from "./components/location-component-manual";
import { CaseComponentAshrae } from "./components/case-component-ashrae";
import { CaseComponentManual } from "./components/case-component-manual";
import * as KnownProperties from "./known-properties";
import * as Calculations from "@munters/calculations";
import {
  InternalRepresentation,
  internalAshraeToPublicFacingPvs,
  internalManualToPublicFacingPvs,
  InternalRepresentationBase,
  InternalRepresentationAshrae,
  InternalRepresentationManual,
  internalToInternalAshraePvs,
  internalToInternalManualPvs,
  internalPvsToInternal
} from "./internal-representation";
import * as PropertyDefinitions from "./product-definitions";
import styled from "styled-components";
import {
  getLocation,
  getDataPoint,
  getAllowedDataTypes,
  getAllowedOccurences,
  validate
} from "./functions";
import { Quantity } from "@genesys/uom";
import { UserProfileContextValue } from "../contexts";

//tslint:disable-next-line
const SeasonSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  > div {
    border-left: 1px solid #999;
    padding-left: 10px;
  }
  > div:first-child {
    border-left: none;
    padding-left: 0px;
  }
`;

export const userProfileQuery = gql`
  query ClimateDataDialog_UserProfileQuery {
    user {
      userProfile {
        id
        userSettings {
          id
          climate {
            id
            country {
              id
            }
            region {
              id
            }
            location {
              id
            }
            heatingDataType
            coolingDataType
            heatingOccurence
            coolingOccurence
            climateDataSource
            manualData
          }
        }
      }
    }
  }
`;

// TODO: Merge locationQuery and userProfileQuery?.
export const locationQuery = gql`
  query ClimateDataDialog_CountriesQuery {
    product {
      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
          }
        }
      }
    }
  }
`;

export const dataPointsQuery = gql`
  query ClimateDataDialog_DatapointsQuery($locationId: String!) {
    product {
      dataPointsForLocationId(locationId: $locationId) {
        id
        caseType
        climateDataType
        annualOccurence
        temperature
        humidity
        windSpeed
      }
    }
  }
`;

function saveUserSettingsQueuedMutationEdit(
  newValues: GraphQLTypes.UserSettingsClimateInputType
): MutationsQueue.QueuedMutation<
  GraphQLTypes.SetUserSettingsClimate,
  GraphQLTypes.SetUserSettingsClimateVariables
> {
  return {
    document: GraphQLMutations.setUserSettingsClimateMutation,
    variables: { userSettingsClimate: newValues }
  };
}

interface Props {
  readonly showWindVelocity: boolean;
  readonly state: InternalRepresentation;
  readonly countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >;
  readonly dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >;
  readonly onChange: (state: InternalRepresentation) => void;
  readonly onConfirm: (climateData: PropertyValueSet.PropertyValueSet) => void;
  readonly onAbort: () => void;

  readonly queueAndFlush: MutationsQueueUser.QueueAndFlush;
}

function validateManual(
  state: InternalRepresentationBase & InternalRepresentationManual
): boolean {
  return (
    validate(
      state.cooling.temperature,
      state.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          state.altitude
        ),
      state.cooling.humidity
    ) &&
    validate(
      state.heating.temperature,
      state.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          state.altitude
        ),
      state.heating.humidity
    )
  );
}

function validateAshrae(
  userProfile: UserProfileContextValue,
  state: InternalRepresentationBase & InternalRepresentationAshrae,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >,
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >
): boolean {
  const fullLocation = getLocation(
    state.locationId || userProfile.defaultLocationId!,
    countries
  );
  const coolingDataPoint = getDataPoint(
    dataPoints,
    "cooling",
    state.cooling.dataType,
    state.cooling.annualOccurence
  );

  const heatingDataPoint = getDataPoint(
    dataPoints,
    "heating",
    state.heating.dataType,
    state.heating.annualOccurence
  );
  return (
    validate(
      PropertyValue.getAmount<Quantity.Temperature>(
        PropertyValue.fromString(coolingDataPoint.temperature)!
      )!,
      state.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValue.getAmount<Quantity.Length>(
            PropertyValue.fromString(
              fullLocation.locationData.location.elevation
            )!
          )!
        ),
      PropertyValue.getAmount<Quantity.Quantity>(
        PropertyValue.fromString(coolingDataPoint.humidity)!
      )!
    ) &&
    validate(
      PropertyValue.getAmount<Quantity.Temperature>(
        PropertyValue.fromString(heatingDataPoint.temperature)!
      )!,
      state.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValue.getAmount<Quantity.Length>(
            PropertyValue.fromString(
              fullLocation.locationData.location.elevation
            )!
          )!
        ),
      PropertyValue.getAmount<Quantity.Quantity>(
        PropertyValue.fromString(heatingDataPoint.humidity)!
      )!
    )
  );
}

// tslint:disable-next-line:function-name
export function ClimateDataDialog(props: Props) {
  return (
    <UserProfile.UserProfile>
      {userProfile => (
        <Elements.FixedOverlay>
          <Elements.ContainerWithWhiteBorder>
            <Elements.DialogHeaderContainer>
              <h2>
                <span>Climate</span>
              </h2>
              <span onClick={props.onAbort}>X</span>
            </Elements.DialogHeaderContainer>
            <Elements.DialogBodyContainer>
              {props.state.climateDataSource === "ASHRAE"
                ? ashraeClimate(
                    props.state,
                    props.showWindVelocity,
                    props.countries,
                    props.dataPoints,
                    userProfile.defaultLocationId,
                    props.onChange
                  )
                : manualClimate(
                    props.state,
                    props.countries,
                    props.showWindVelocity,
                    userProfile.defaultLocationId,
                    props.onChange
                  )}
              <Elements.ButtonContainer>
                <Elements.StandardButton
                  disabled={
                    props.state.climateDataSource === "ASHRAE"
                      ? !validateAshrae(
                          userProfile,
                          props.state,
                          props.countries,
                          props.dataPoints
                        )
                      : !validateManual(props.state)
                  }
                  onClick={() => {
                    const publicPvs =
                      props.state.climateDataSource === "ASHRAE"
                        ? internalAshraeToPublicFacingPvs(
                            props.state,
                            props.countries,
                            props.dataPoints
                          )
                        : internalManualToPublicFacingPvs(props.state);

                    const manualData = PropertyValueSet.keepProperties(
                      [
                        KnownProperties.manualData.altitude,
                        KnownProperties.manualData.customPressure,
                        KnownProperties.manualData.atmosphericPressure,
                        KnownProperties.manualData.coolingAnnualOccurence,
                        KnownProperties.manualData.heatingAnnualOccurence,
                        KnownProperties.manualData.coolingDataType,
                        KnownProperties.manualData.heatingDataType,
                        KnownProperties.manualData.latitudeN,
                        KnownProperties.manualData.longitudeW,
                        KnownProperties.manualData.locationName,
                        KnownProperties.manualData.summerHumidity,
                        KnownProperties.manualData.summerTemperature,
                        KnownProperties.manualData.winterHumidity,
                        KnownProperties.manualData.winterTemperature
                      ],
                      publicPvs
                    );

                    const fullLocation = getLocation(
                      props.state.locationId || userProfile.defaultLocationId!,
                      props.countries
                    );

                    // This will be saved to user defaults location.
                    const locationData = {
                      countryName: fullLocation.countryData.country.name,
                      regionName: fullLocation.regionData.region.name,
                      locationId: fullLocation.locationData.location.id
                    };

                    props.queueAndFlush([
                      {
                        type: "AddToQueueMutationDescription",
                        mutation: saveUserSettingsQueuedMutationEdit({
                          ...locationData,
                          heatingDataType: props.state.heating.dataType,
                          coolingDataType: props.state.cooling.dataType,
                          heatingOccurence: props.state.heating.annualOccurence,
                          coolingOccurence: props.state.cooling.annualOccurence,
                          climateDataSource: props.state.climateDataSource,
                          manualData: PropertyValueSet.toString(manualData)
                        })
                      }
                    ]);

                    props.onConfirm(publicPvs);
                  }}
                >
                  OK
                </Elements.StandardButton>
                <Elements.StandardButton onClick={props.onAbort}>
                  Cancel
                </Elements.StandardButton>
              </Elements.ButtonContainer>
            </Elements.DialogBodyContainer>
          </Elements.ContainerWithWhiteBorder>
        </Elements.FixedOverlay>
      )}
    </UserProfile.UserProfile>
  );
}

function ashraeClimate(
  state: InternalRepresentationBase & InternalRepresentationAshrae,
  showWindVelocity: boolean,
  countries: GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"],
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  defaultLocationId: string | undefined,
  onChange: (state: InternalRepresentation) => void
): JSX.Element {
  // Overall
  const coolingDataPoint = getDataPoint(
    dataPoints,
    "cooling",
    state.cooling.dataType,
    state.cooling.annualOccurence
  );

  const heatingDataPoint = getDataPoint(
    dataPoints,
    "heating",
    state.heating.dataType,
    state.heating.annualOccurence
  );

  const fullLocation = getLocation(
    state.locationId || defaultLocationId!,
    countries
  );
  const selectedProperties = internalToInternalAshraePvs(
    state,
    fullLocation,
    coolingDataPoint,
    heatingDataPoint
  );

  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    KnownProperties.manualData.atmosphericPressure,
    selectedProperties
  )!;

  // Cooling

  const coolingAllowedDataTypes = getAllowedDataTypes(
    dataPoints,
    "cooling",
    state.cooling.annualOccurence
  );

  const coolingAllowedOccurences = getAllowedOccurences(
    dataPoints,
    "cooling",
    state.cooling.dataType
  );

  const coolingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.manualData.summerTemperature,
    selectedProperties
  )!;

  const coolingProductDefinitions = PropertyDefinitions.caseProductDefinitions(
    showWindVelocity,
    "cooling",
    coolingAllowedDataTypes,
    coolingAllowedOccurences,
    coolingTemperature,
    pressure
  );
  const coolingReadOnlyProperties = PropertyDefinitions.caseReadOnlyProperties(
    "cooling",
    selectedProperties
  );

  // Heating
  const heatingAllowedDataTypes = getAllowedDataTypes(
    dataPoints,
    "heating",
    state.heating.annualOccurence
  );

  const heatingAllowedOccurences = getAllowedOccurences(
    dataPoints,
    "heating",
    state.heating.dataType
  );

  const heatingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.manualData.winterTemperature,
    selectedProperties
  )!;

  const heatingProductDefinitions = PropertyDefinitions.caseProductDefinitions(
    showWindVelocity,
    "heating",
    heatingAllowedDataTypes,
    heatingAllowedOccurences,
    heatingTemperature,
    pressure
  );
  const heatingReadOnlyProperties = PropertyDefinitions.caseReadOnlyProperties(
    "heating",
    selectedProperties
  );
  // All

  const locationProductDefinitions =
    PropertyDefinitions.locationProductDefinitions(
      countries.length,
      fullLocation.countryData
        ? fullLocation.countryData.country.regions.length
        : 0,
      fullLocation.regionData
        ? fullLocation.regionData.region.locations.length
        : 0
    );

  const locationReadOnlyProperties =
    PropertyDefinitions.locationReadOnlyProperties(
      selectedProperties,
      state.customPressure !== undefined
    );

  const locationNamesByPropertyName = {
    country: countries.map(item => item.name),
    region: fullLocation.countryData.country.regions.map(item => item.name),
    location: fullLocation.regionData.region.locations.map(item => item.name)
  };

  return (
    <>
      <LocationComponentAshrae
        selectedProperties={selectedProperties}
        productDefinitions={locationProductDefinitions}
        readOnlyProperties={locationReadOnlyProperties}
        fullLocation={fullLocation}
        locationNamesByPropertyName={locationNamesByPropertyName}
        countries={countries}
        onLocationChange={(
          pvs: PropertyValueSet.PropertyValueSet,
          locationId: string
        ) =>
          onChange(
            internalPvsToInternal(
              pvs,
              countries,
              locationId || defaultLocationId!
            )
          )
        }
        userSettingLocation={defaultLocationId!}
      />
      <SeasonSelectorWrapper>
        <div>
          <CaseComponentAshrae
            selectedProperties={selectedProperties}
            productDefinitions={coolingProductDefinitions}
            readOnlyProperties={coolingReadOnlyProperties}
            onCaseChange={(pvs: PropertyValueSet.PropertyValueSet) =>
              onChange(
                internalPvsToInternal(
                  pvs,
                  countries,
                  fullLocation.locationData.location.id
                )
              )
            }
          />
        </div>
        <div>
          <CaseComponentAshrae
            selectedProperties={selectedProperties}
            productDefinitions={heatingProductDefinitions}
            readOnlyProperties={heatingReadOnlyProperties}
            onCaseChange={(pvs: PropertyValueSet.PropertyValueSet) =>
              onChange(
                internalPvsToInternal(
                  pvs,
                  countries,
                  fullLocation.locationData.location.id
                )
              )
            }
          />
        </div>
      </SeasonSelectorWrapper>
    </>
  );
}

function manualClimate(
  state: InternalRepresentationBase & InternalRepresentationManual,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >,
  showWindVelocity: boolean,
  defaultLocationId: string | undefined,
  onChange: (state: InternalRepresentation) => void
): JSX.Element {
  const selectedProperties = internalToInternalManualPvs(state);
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    KnownProperties.manualData.atmosphericPressure,
    selectedProperties
  )!;

  // Cooling
  const coolingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.manualData.summerTemperature,
    selectedProperties
  )!;

  const coolingProductDefinitions = PropertyDefinitions.caseProductDefinitions(
    showWindVelocity,
    "cooling",
    [],
    [],
    coolingTemperature,
    pressure
  );
  const coolingReadOnlyProperties = PropertyDefinitions.caseReadOnlyProperties(
    "heating",
    selectedProperties
  );
  // Heating
  const heatingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.manualData.winterTemperature,
    selectedProperties
  )!;

  const heatingProductDefinitions = PropertyDefinitions.caseProductDefinitions(
    showWindVelocity,
    "heating",
    [],
    [],
    heatingTemperature,
    pressure
  );

  const heatingReadOnlyProperties = PropertyDefinitions.caseReadOnlyProperties(
    "heating",
    selectedProperties
  );

  // Location
  const locationProductDefinitions =
    PropertyDefinitions.locationProductDefinitions(0, 0, 0);
  const locationReadOnlyProperties =
    PropertyDefinitions.locationReadOnlyProperties(
      selectedProperties,
      state.customPressure !== undefined
    );
  return (
    <>
      <LocationComponentManual
        selectedProperties={selectedProperties}
        productDefinitions={locationProductDefinitions}
        readOnlyProperties={locationReadOnlyProperties}
        onLocationChange={(pvs: PropertyValueSet.PropertyValueSet) =>
          onChange(internalPvsToInternal(pvs, countries, defaultLocationId!))
        }
      />
      <SeasonSelectorWrapper>
        <div>
          <CaseComponentManual
            selectedProperties={selectedProperties}
            productDefinitions={coolingProductDefinitions}
            readOnlyProperties={coolingReadOnlyProperties}
            onCaseChange={(pvs: PropertyValueSet.PropertyValueSet) =>
              onChange(
                internalPvsToInternal(pvs, countries, defaultLocationId!)
              )
            }
          />
        </div>
        <div>
          <CaseComponentManual
            selectedProperties={selectedProperties}
            productDefinitions={heatingProductDefinitions}
            readOnlyProperties={heatingReadOnlyProperties}
            onCaseChange={(pvs: PropertyValueSet.PropertyValueSet) =>
              onChange(
                internalPvsToInternal(pvs, countries, defaultLocationId!)
              )
            }
          />
        </div>
      </SeasonSelectorWrapper>
    </>
  );
  // tslint:disable-next-line:max-file-line-count
}
