import * as React from "react";
import { isDesktop } from "react-device-detect";
import { H1, StandardButton } from "@genesys/ui-elements";
import {
  Progress,
  ProgressBarRoot,
  ProgressItem,
  DisabledProgressItem,
  SelectedProgressItem
} from "./elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";

export interface Props {
  readonly translate: LanguageTexts.Translate;
  readonly steps: ReadonlyArray<Types.StepType>;
  readonly currentStepIndex: number;
  readonly canFinish: boolean;
  readonly finish: () => void;
}

export function ProgressBar(props: Props): JSX.Element {
  return (
    <ProgressBarRoot>
      <H1>{props.translate(LanguageTexts.createNewSystem())}</H1>
      <Progress>
        {props.steps.map((s, ix) => (
          <Item
            key={s}
            step={s}
            index={ix}
            current={props.currentStepIndex}
            translate={props.translate}
          />
        ))}
      </Progress>
      {isDesktop && (
        <div>
          <StandardButton
            buttonType="Primary-2"
            size="Large"
            onClick={props.finish}
            disabled={!props.canFinish}
          >
            {props.translate(LanguageTexts.finish())}
          </StandardButton>
        </div>
      )}
    </ProgressBarRoot>
  );
}

function Item(props: {
  readonly step: Types.StepType;
  readonly index: number;
  readonly current: number;
  readonly translate: LanguageTexts.Translate;
}): JSX.Element {
  if (props.current > props.index) {
    return <ProgressItem>{title(props.step, props.translate)}</ProgressItem>;
  }
  if (props.current < props.index) {
    return (
      <DisabledProgressItem>
        {title(props.step, props.translate)}
      </DisabledProgressItem>
    );
  }
  return (
    <SelectedProgressItem>
      {title(props.step, props.translate)}
    </SelectedProgressItem>
  );
}

function title(
  step: Types.StepType,
  translate: LanguageTexts.Translate
): string {
  switch (step) {
    case "system-settings":
      return translate(LanguageTexts.systemSettings());
    case "initial-configuration":
      return translate(LanguageTexts.initialConfiguration());
    case "operating-cases":
      return translate(LanguageTexts.operatingCases());
    case "hum-size-selector":
    case "ecc-size-selector":
    case "dhu-size-selector":
    case "data-center-size-selector":
      return translate(LanguageTexts.sizeSelection());
    default:
      exhaustiveCheck(step, true);
  }
}
