import * as GraphQLTypes from "../graphql-types";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import { PropertyValueSet } from "@genesys/property";

export interface System {
  readonly id: string;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<OperatingCase>;
  readonly status: number;
  readonly revisionNo: number;
  readonly statusDate: GraphQLTypes.Scalars["GenesysDate"];
  readonly genesysNo: number;
  readonly systemTypeId: string;
  readonly name: string;
  readonly isEditable: boolean;
}

export interface OperatingCase {
  readonly id: string;
  readonly caseName: string;
  readonly customCaseName?: string | null | undefined;
  readonly settings: PropertyValueSet.PropertyValueSet;
  readonly sortNo: number;
  readonly caseType: number;
}

export interface OperatingCaseResult {
  readonly id: string;
  readonly componentId: string;
  readonly calculationType: GraphQLTypes.ComponentCalculationType;
  readonly isDesignCase: boolean;
  readonly settings?: string | null | undefined;
  readonly sortNo: number;
}

export function parseSystems(
  data: GraphQLTypes.SystemsInfoQuery["user"]["systems"]
): ReadonlyArray<System> {
  return data.map(system => ({
    id: system.id,
    components: [],
    operatingCases: system.operatingCases.map(parseOperatingCase),
    climateSettings: parseClimateSettings(system.climateSettings),
    revisionNo: system.revisionNo,
    status: system.status,
    statusDate: system.statusDate,
    systemTypeId: system.systemFile.systemTypeId,
    genesysNo: system.systemFile.genesysNo,
    name: system.systemFile.name,
    isEditable: system.status !== SystemStatus.SystemStatus.LockSuccess
  }));
}

function parseClimateSettings(climateSettings: string | null | undefined) {
  return climateSettings
    ? PropertyValueSet.fromString(climateSettings)
    : PropertyValueSet.Empty;
}

function parseOperatingCase(
  operatingCase: NonNullable<
    GraphQLTypes.SystemsInfoQuery["user"]["systems"]
  >[0]["operatingCases"][0]
): OperatingCase {
  return {
    id: operatingCase.id,
    caseName: operatingCase.caseName,
    customCaseName: operatingCase.customCaseName,
    settings: PropertyValueSet.fromString(operatingCase.settings || ""),
    caseType: operatingCase.caseType,
    sortNo: operatingCase.sortNo
  };
}
