import * as React from "react";
import styled from "../../styled-components";

export interface Props {
  readonly children: React.ReactNode;
}

const StyledModelContent = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;

  flex: 1;
  overflow-y: auto;
`;

export function ModalContent(props: Props): JSX.Element {
  return <StyledModelContent>{props.children}</StyledModelContent>;
}
