import styled from "styled-components";

export const Root = styled.div`
  background-color: #ffffff;
`;

export const Menu = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;

  h5 {
    margin-bottom: 10px;
  }

  button {
    padding: 0 20px;
    background-color: inherit;
    width: 100%;
    height: 25px;
    margin: 0;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

export const InputActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 370px;
  height: 160px;
  padding: 15px 20px;
  border: 1px solid #dddddd;
  h3 {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  button {
    margin: 15px 10px 0 0;
  }
`;

export const RevisionLockMessageContainer = styled.div`
  padding: 15px 20px;
  width: 370px;
  border: 1px solid #dddddd;
  button {
    margin: 15px 10px 0 0;
  }
  h3 {
    white-space: normal;
  }
`;

export const ConfirmDeleteContainer = styled.div`
  padding: 15px 20px;
  border: 1px solid #dddddd;
  white-space: nowrap;

  div {
    display: flex;
    button {
      margin: 15px 10px 0 0;
    }
  }
`;

export const PricingWizardContainer = styled.div`
  padding: 15px 20px;
`;
