import * as React from "react";
import styled from "styled-components";
import { zIndex } from "@genesys/ui-elements";

const Container = styled.div`
  z-index: ${zIndex.Dropdown};
  position: fixed;
  border: 1px solid #dddddd;
  background-color: white;
`;

export function MenuContainer({
  children,
  menuIsOpen,
  menuParentRef
}: {
  readonly children: JSX.Element;
  readonly menuIsOpen: boolean;
  readonly menuParentRef: React.MutableRefObject<HTMLElement>;
}) {
  const menuRef = React.useRef<HTMLDivElement>(undefined!);

  React.useLayoutEffect(() => {
    if (menuIsOpen && menuRef.current && menuParentRef.current) {
      const buttonRect = menuParentRef.current.getBoundingClientRect();
      const uListRect = menuRef.current.getBoundingClientRect();

      if (
        buttonRect.left + uListRect.width > window.innerWidth &&
        buttonRect.right - uListRect.width > 0
      ) {
        menuRef.current.style.left = `${
          window.innerWidth - uListRect.width - 2
        }px`;
      } else {
        menuRef.current.style.left = `${buttonRect.left}px`;
      }
      if (
        buttonRect.bottom + uListRect.height > window.innerHeight &&
        buttonRect.top - uListRect.height > 0
      ) {
        menuRef.current.style.top = `${
          buttonRect.top - uListRect.height - 12
        }px`;
      } else {
        menuRef.current.style.top = `${buttonRect.bottom + 1}px`;
      }
    }
  }, [menuIsOpen, menuRef?.current?.getBoundingClientRect()]);

  return <Container ref={menuRef as any}>{children}</Container>;
}
