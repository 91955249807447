import * as React from "react";
import { Label, StatusError } from "@genesys/ui-elements";
import * as Types from "./types";
import {
  ScreenMenuContainer,
  ScreenMenuItem,
  ScreenMenuItemIconContainer,
  ScreenMenuRow,
  ScreenMenuLabelContainer
} from "./elements";

export interface ScreenMenuProps {
  readonly screens: ReadonlyArray<Types.Screen>;
}

export function ScreenMenu(props: ScreenMenuProps): JSX.Element {
  const columns = 4;
  const rows = Math.ceil(props.screens.length / columns);

  const item = (item: Types.Screen) => (
    <ScreenMenuItem key={item.title} onClick={item.onClick}>
      <ScreenMenuItemIconContainer>
        <item.Icon height="74px" />
      </ScreenMenuItemIconContainer>
      <ScreenMenuLabelContainer>
        <Label weight="normal">{item.title}</Label>
        {item.hasError && <StatusError />}
      </ScreenMenuLabelContainer>
    </ScreenMenuItem>
  );

  return (
    <ScreenMenuContainer>
      {Array(rows)
        .fill(1)
        .map((_r, ix) => (
          <ScreenMenuRow key={ix}>
            {props.screens
              .slice(ix * columns, (ix + 1) * columns)
              .map(s => item(s))}
          </ScreenMenuRow>
        ))}
    </ScreenMenuContainer>
  );
}
