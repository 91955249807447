import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import * as PricingActions from "../../../pricing-actions";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as StatusIcon from "../../../status-icon-manager";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Action, State } from "../../state";
import { ColumnName, OrderByColumn, PricingRow } from "../../types";
import { Dispatch } from "@typescript-tea/core";
import {
  OrderyByHeader,
  MenuContainer,
  RevisionsContainer,
  StatusContainer
} from "../../../shared-manager-components";
import {
  S2,
  P2,
  zIndex,
  PrimaryColors,
  MenuDots,
  ArrowUp,
  ArrowDown
} from "@genesys/ui-elements";
const NormalHeaderContainer = styled.div`
  padding-bottom: 3px;
`;

const PricingNameContainer = styled.div`
  padding: 5px;
  border-radius: 10px;
  width: 250px;
  :hover {
    background: ${PrimaryColors.lightBlue};
  }
`;

const StyledA = styled.a`
  text-decoration: none;
`;

const PricingActionsContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  position: absolute;
  top: -20px;
`;

const LabelsP2 = styled(P2)`
  width: 150px;
`;

function NormalHeader({ text }: { readonly text: string }) {
  return <NormalHeaderContainer>{headerElement(text)}</NormalHeaderContainer>;
}

function headerElement(text: string) {
  return (
    <S2 color="dark" weight="bold">
      {text}
    </S2>
  );
}

export function getFileAndRevisionContent(
  state: State,
  cellName: ColumnName,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>,
  pricingRow: PricingRow
) {
  switch (cellName) {
    case "p-no": {
      const prefix = sharedState.genesysPrefixNotation.pricingNo;

      if (pricingRow.type === "file") {
        return (
          <P2 weight="normal" color="secondary">
            {prefix + pricingRow.pricingNo}
          </P2>
        );
      }

      return (
        <P2 weight="normal" color="secondary">
          {pricingRow.revisionNo}
        </P2>
      );
    }

    case "actions-menu": {
      const pricingActionsButtonRef = React.createRef<HTMLAnchorElement>();

      return (
        <>
          <a
            onMouseEnter={() =>
              dispatch(Action.toggleOpenPricingActions(pricingRow.id))
            }
            onMouseLeave={() => {
              dispatch(Action.toggleOpenPricingActions(""));
            }}
          >
            <span ref={pricingActionsButtonRef}>
              <MenuDots height="1.8rem" />
            </span>
            {pricingRow.isActionMenuOpen && state.pricingActionsState && (
              <PricingActionsContainer>
                <MenuContainer
                  menuIsOpen={pricingRow.isActionMenuOpen}
                  menuParentRef={pricingActionsButtonRef as any}
                >
                  <PricingActions.PricingActionsView
                    state={state.pricingActionsState}
                    sharedState={sharedState}
                    dispatch={Dispatch.map(
                      Action.dispatchPricingActions,
                      dispatch
                    )}
                    pricingFile={
                      pricingRow.type === "file" ? pricingRow : undefined
                    }
                    pricingRevision={
                      pricingRow.type === "revision" ? pricingRow : undefined
                    }
                  />
                </MenuContainer>
              </PricingActionsContainer>
            )}
          </a>
        </>
      );
    }

    case "pricing-name": {
      const revisionNo =
        pricingRow.type === "file"
          ? pricingRow.latestPricing.revisionNo
          : pricingRow.revisionNo;
      return (
        <StyledA
          href={`/pricing/${sharedState.genesysPrefix.pricingNo(
            pricingRow.pricingNo,
            revisionNo
          )}`}
        >
          <PricingNameContainer>
            <P2 weight="normal" color="secondary">
              {pricingRow.name}
            </P2>
          </PricingNameContainer>
        </StyledA>
      );
    }

    case "owner": {
      return (
        <P2 weight="normal" color="secondary">
          {pricingRow.type === "file" ? pricingRow.owner.userName : ""}
        </P2>
      );
    }

    case "date-changed": {
      const dateFormatter = (date: number) =>
        DateHelper.getDateString(
          DateHelper.fromUTC(date),
          sharedState.user.settings.locale
        );
      return (
        <P2 weight="normal" color="secondary">
          {dateFormatter(
            pricingRow.type === "file"
              ? pricingRow.latestPricing.statusDate
              : pricingRow.statusDate
          )}
        </P2>
      );
    }

    case "status": {
      const status =
        pricingRow.type === "file"
          ? pricingRow.latestPricing.status
          : pricingRow.status;
      return (
        <StatusContainer>
          <StatusIcon.StatusIconManager
            sharedState={sharedState}
            statusNumber={status === "CREATED" ? 0 : 90}
          />
        </StatusContainer>
      );
    }

    case "labels": {
      return (
        <LabelsP2 weight="normal" color="secondary">
          {pricingRow.type === "file"
            ? pricingRow.labels.map((label, i) => {
                if (i !== pricingRow.labels.length - 1) {
                  return label.name + " | ";
                } else {
                  return label.name;
                }
              })
            : ""}
        </LabelsP2>
      );
    }

    case "revisions": {
      if (pricingRow.type === "file") {
        return (
          <RevisionsContainer>
            <P2 weight="normal" color="secondary">
              {pricingRow.latestPricing.revisionNo}
            </P2>
            <span
              onClick={() => dispatch(Action.toggleRevision(pricingRow.id))}
            >
              {pricingRow.isRevisionExpanded ? (
                <ArrowUp height="0.4rem" />
              ) : (
                <ArrowDown height="0.4rem" />
              )}
            </span>
          </RevisionsContainer>
        );
      }

      return null;
    }

    default:
      exhaustiveCheck(cellName);
  }
}

export function getHeaderCellContent(
  state: State,
  cellName: ColumnName,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
) {
  const currentColumn = state.searchParams.orderByColumn;

  const setOrderByColumn = (column: OrderByColumn) => {
    let newSearchParams = state.searchParams;
    if (currentColumn.name === column) {
      newSearchParams = {
        ...state.searchParams,
        orderByColumn: {
          ...currentColumn,
          isDescending: !currentColumn.isDescending
        }
      };
    } else {
      newSearchParams = {
        ...state.searchParams,
        orderByColumn: {
          name: column,
          isDescending: true
        }
      };
    }
    dispatch(Action.setSearchParams(newSearchParams));
    dispatch(Action.search());
  };

  switch (cellName) {
    case "p-no": {
      return (
        <OrderyByHeader
          headerElement={headerElement("P-No")}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "actions-menu": {
      return <></>;
    }

    case "pricing-name": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.pricingName())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "owner": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.owner())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "date-changed": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.changed())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "status": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.status())} />
      );
    }

    case "labels": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.labels())} />
      );
    }

    case "revisions": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.revisions())} />
      );
    }

    default:
      exhaustiveCheck(cellName);
  }
}
