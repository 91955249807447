import { Cmd } from "@typescript-tea/core";
import { v4 } from "uuid";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { exchangeComponentMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { promiseCmd } from "../../../promise-effect-manager";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";

export function exchangeComponent(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<ConfiguratorAction, { readonly type: "exchangeComponent" }>
): [Cmd<Action>, SystemStatus] {
  return [
    promiseCmd<Action, {}>(
      async () => {
        await sharedState.graphQL.queryUser<
          GraphQLTypes.ExchangeComponent,
          GraphQLTypes.ExchangeComponentVariables
        >(exchangeComponentMutation, {
          input: {
            systemId,
            componentIdToDelete: action.input.componentId,
            componentIdToAdd: v4(),
            productIdToAdd: action.input.productId,
            componentSectionPositionsToAdd:
              action.input.componentSectionPositions
          }
        });
        return {};
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.ValidateConfigurationSuccess
  ];
}
