const perfParamRegex = /^(.+?)?_(.+?)$/;

interface GetFieldGroupFieldName {
  readonly fieldGroup: string;
  readonly fieldName: string;
}

export function getFieldGroupFieldName(
  productId: string,
  value: string
): GetFieldGroupFieldName | undefined {
  const matches = perfParamRegex.exec(
    // resultKey
    value
  )!;

  if (!matches) {
    return undefined;
  }

  const [, section, fieldName] = matches;

  const fieldGroup = `PerfParam.${
    section && section.length > 0 ? section : productId
  }`;

  return { fieldGroup, fieldName };
}
