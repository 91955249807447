import * as React from "react";
import * as Main from "../main";
import * as InitialSettings from "../initial-settings";
import * as VersionChecker from "../version-checker";
import * as TermsAndCondtions from "../terms-and-conditions";
import { Dispatch } from "@typescript-tea/core";
import {
  OverlayLoader,
  MaterialFontFamilyOverride
} from "@genesys/ui-elements";
import { Action, State, buildSharedState } from "./state";
import { ErrorBoundary } from "./error-boundary";
import { ConsecutiveAlerts } from "@genesys/ui-elements";

export function view({
  dispatch,
  state
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
}): JSX.Element {
  const sharedState = buildSharedState(state);

  if (
    !state.user ||
    !sharedState ||
    !state.mainState ||
    state.queriesLoading.length > 0
  ) {
    return <OverlayLoader />;
  }

  if (state.termsAndConditionsState) {
    return (
      <TermsAndCondtions.View
        dispatch={Dispatch.map(Action.dispatchTermsAndConditions, dispatch)}
        state={state.termsAndConditionsState}
      />
    );
  }

  const currentMessage = state.alertMessagesQueue[0];

  return (
    <ErrorBoundary>
      <>
        {currentMessage && (
          <ConsecutiveAlerts
            timeout={currentMessage.timeout}
            show={!!state.alertMessagesQueue.length}
            getNextMessage={() => {
              dispatch(Action.getNextMessage());
            }}
            alertSeverity={currentMessage.messageType}
          >
            <div>{currentMessage.content}</div>
          </ConsecutiveAlerts>
        )}

        <VersionChecker.View state={state.versionCheckerState} />
        <MaterialFontFamilyOverride>
          {state.showInitialSettings && state.initialSettingsState ? (
            <InitialSettings.InitialSettingsView
              dispatch={Dispatch.map(Action.dispatchInitialSettings, dispatch)}
              sharedState={sharedState}
              state={state.initialSettingsState}
            />
          ) : (
            <Main.MainView
              dispatch={Dispatch.map(Action.dispatchMain, dispatch)}
              state={state.mainState}
              sharedState={sharedState}
            />
          )}
        </MaterialFontFamilyOverride>
      </>
    </ErrorBoundary>
  );
}
