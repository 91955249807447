import * as React from "react";
import styled from "../../styled-components";

export interface IconButtonProps {
  readonly icon: React.ReactNode;
  readonly onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const StyledIconButton = styled.span`
  cursor: pointer;
  padding: 2px;
  padding-top: 3px;
  /* border: 1px solid white; */

  /* :hover {
    border: 1px solid #3d3d3d;
    border-radius: 5px;
    background: linear-gradient(180deg, #f8f8f8 0%, #fafafa 100%);
    box-sizing: border-box;
  } */
`;

export function IconButton(props: IconButtonProps): JSX.Element {
  return (
    <StyledIconButton onClick={e => props.onClick && props.onClick(e)}>
      {props.icon}
    </StyledIconButton>
  );
}
