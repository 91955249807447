import gql from "graphql-tag";

export const updateMoistureLoadInputsMutation = gql`
  mutation SaveMoistureLoadInputs(
    $moistureLoadinputs: MoistureLoadCalcInputType!
    $moistureLoadId: ID!
  ) {
    saveMoistureLoadInputs(
      inputSettings: $moistureLoadinputs
      moistureLoadId: $moistureLoadId
    ) {
      id
    }
  }
`;
