import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { getIfIntegerChanged } from "../../helper-functions";
import * as KnownProperties from "../../known-properties";
import * as OperationTimeGen2 from "../../../../operation-time-manager";

import { LoadOperationTime } from "../../types";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import { Amount, Units } from "@genesys/uom";

import { HeatTransmissionIcon } from "@genesys/ui-elements";
export const title = "mlcheattransmission";
export const sourceName = MoistureHeatLoadTypeEnum.HEAT_TRANSMISSION;
export const description = "mlcheattransmissionexplanation";
export const useLoadKey = "mlcuseheattransmission";

//Icon
export const Icon = HeatTransmissionIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty = undefined;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useHeatTransmissionHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}
export const manualInputProperty = KnownProperties.heatTransmissionManualInput;

export const properties = [
  KnownProperties.useHeatTransmissionHeatLoad,
  KnownProperties.heatTransmissionManualInput,
  KnownProperties.heatTransmissionAirSource,
  KnownProperties.heatTransmissionAirTemperature,
  KnownProperties.heatTransmissionAirHumidity
];
export const overridableProperties = [
  KnownProperties.heatTransmissionAirTemperature,
  KnownProperties.heatTransmissionAirHumidity
];

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let allUpdates = getPropertyDefaults(prevProperties, selectedProperties);

  return allUpdates;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.allDay;
}

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  const useHeatTransmissionHeatLoad = getIfIntegerChanged(
    prevSelections,
    currentSelections,
    KnownProperties.useHeatTransmissionHeatLoad
  );
  if (useHeatTransmissionHeatLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      useHeatTransmissionDefaults(useHeatTransmissionHeatLoad),
      defaults
    );
  }

  // Room location
  const buildingLocation = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLocation
  );

  if (buildingLocation !== undefined) {
    defaults = PropertyValueSet.merge(
      roomLocationDefaults(
        PropertyValueSet.merge(defaults, currentSelections),
        buildingLocation
      ),
      defaults
    );
  }

  // Air sources for infiiltration
  const heatTransmissionAirSource = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.heatTransmissionAirSource
  );

  if (heatTransmissionAirSource !== undefined) {
    defaults = PropertyValueSet.merge(
      heatTransmissionAirSourceDefaults(heatTransmissionAirSource),
      defaults
    );
  }

  return defaults;
}

function useHeatTransmissionDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.heatTransmissionManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.heatTransmissionAirSource]:
          PropertyValue.fromInteger(0)
      };

    case 1:
      return {
        [KnownProperties.heatTransmissionManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.heatTransmissionAirSource]:
          PropertyValue.fromInteger(1)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function roomLocationDefaults(
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.useHeatTransmissionHeatLoad,
      currentSelections
    ) === 0
  ) {
    return {
      [KnownProperties.heatTransmissionAirSource]: PropertyValue.fromInteger(0)
    };
  }
  switch (propertyValue) {
    case 0:
    case 1:
    default:
      return {
        [KnownProperties.heatTransmissionAirSource]:
          PropertyValue.fromInteger(1)
      };
  }
}
function heatTransmissionAirSourceDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
    case 2:
    default:
      return {
        [KnownProperties.heatTransmissionAirTemperature]:
          PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
        [KnownProperties.heatTransmissionAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 3:
      return {
        [KnownProperties.heatTransmissionAirTemperature]:
          PropertyValue.fromAmount(Amount.create(15, Units.Celsius)),
        [KnownProperties.heatTransmissionAirHumidity]: PropertyValue.fromAmount(
          Amount.create(8, Units.GramPerKilogram)
        )
      };
  }
}
