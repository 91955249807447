import * as React from "react";
import styled from "styled-components";
import * as Product from "../product";
import { Diagram } from "./product-diagram";
import { PrimaryColors } from "@genesys/ui-elements/lib/colors";
import { Label, H1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";

export interface ProductMenuProps {
  readonly system: System.System;
  readonly productInfo: ReadonlyArray<ProductInfo>;
  readonly productData: Product.ProductData;
  readonly columns: number;
  readonly translate: LanguageTexts.Translate;
  readonly onSelect: (productId: string) => void;
}

type ProductInfo = {
  readonly productId: string;
  readonly isThereAnyOpenPosition: boolean;
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 20px;
  margin-right: 20px;
  width: 130px;

  & {
    cursor: pointer;
    user-select: none;
  }

  &:hover div {
    border: 1px solid #3d3d3d;
    border-radius: 9px;
    background-color: #ff0000;
    background: none;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }

  &:hover span {
    color: ${PrimaryColors.muntersBlue};
  }
`;

const CellImageContianer = styled.div`
  position: relative;
  display: flex;
  height: 140px;

  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background: linear-gradient(180deg, #f8f8f8 0%, #fafafa 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);

  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 16px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const RedCross = styled(H1)`
  position: absolute;
  bottom: 14px;
  left: 5px;
  color: ${PrimaryColors.red};
  font-size: 2rem;
`;

export function ProductMenu(props: ProductMenuProps): JSX.Element {
  const rows = Math.ceil(props.productInfo.length / props.columns);

  const component = (productInfo: ProductInfo) => {
    return (
      <ContentCell
        key={productInfo.productId}
        onClick={e => {
          if (productInfo.isThereAnyOpenPosition) {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            props.onSelect(productInfo.productId);
          }
        }}
      >
        <CellImageContianer>
          <Diagram
            productData={props.productData}
            productId={productInfo.productId}
          />
          {!productInfo.isThereAnyOpenPosition && <RedCross>✘</RedCross>}
        </CellImageContianer>
        <Label weight="normal">
          {props.translate(LanguageTexts.product(productInfo.productId))}
        </Label>
      </ContentCell>
    );
  };

  return (
    <Content>
      {Array(rows)
        .fill(1)
        .map((_r, ix) => (
          <ContentRow key={ix}>
            {props.productInfo
              .slice(ix * props.columns, (ix + 1) * props.columns)
              .map(p => component(p))}
          </ContentRow>
        ))}
    </Content>
  );
}
