export enum zIndex {
  Background = -10,
  AmountFormat = 5,
  Dropdown = 10,
  OperatingCasePropertyNames = 13,
  MainMenu = 15,
  AlertView = 16,
  Modal = 17,
  FormatSelector = 21,
  FullOverlay = 100
}
