import gql from "graphql-tag";

export const query = gql`
  query WizardInitialConfiguration($systemTypeInput: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemTypeInput) {
        symbols {
          id
          symbolId
          svg
          width
          height
        }
        allProducts {
          id
          properties {
            sortNo
            quantity
            defaultValues {
              value
              propertyFilter
            }
            name
            groupName
            visibilityFilter
            validationFilter
            descriptionTexts {
              id
              propertyFilter
              language
              text
            }
            values {
              sortNo
              image
              value
              validationFilter
              rangeFilter
              descriptionTexts {
                id
                propertyFilter
                language
                text
              }
            }
            quantityConversionParams
          }
          boxConnectionPoints {
            id
            productSectionId
            box
            connectionType
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            hideStatePoint
            diagramType
            propertyFilter
          }

          boxSymbols {
            id
            box
            symbolId
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            sizeX
            sizeY
            propertyFilter
            absoluteRotationAndScale
            useForSelectionBounds
            diagramType
          }
          boxLinePoints {
            id
            box
            connectionId
            lineType
            positionX
            positionY
            direction
            propertyFilter
            diagramType
          }
        }
        templates {
          propertyFilter
          climateDataDefaults
          components {
            id
            label
            labelAbove
            productId
            properties
            propertyFilter
            visibleProperties
            sortNo
            componentSections {
              id
              airstream
              productSectionId
              sortNo
            }
          }
        }
      }
    }
  }
`;
