import {
  checkPermission,
  hasPermission,
  ApplicationClaims
} from "./claim-validator";
import { genesysUserClaims, systemTypeClaims, contexts } from "./constants";

export function getClaimValues(
  applicationClaims: ApplicationClaims,
  claimKey: string,
  context: string = contexts.genesys
): ReadonlyArray<string> | undefined {
  const claim =
    applicationClaims[context] &&
    applicationClaims[context][claimKey.toLowerCase()];
  if (!claim) {
    return undefined;
  }
  return claim;
}

export function hasSystemPermission(
  applicationClaims: ApplicationClaims,
  systemTypeId: string
): boolean {
  // Developer has all permissions
  //   console.log(`HasSystemPermission: ${systemTypeId}`);
  if (checkPermission(applicationClaims, genesysUserClaims.developer)) {
    // console.log(`HasSystemPermission: developer`);
    return true;
  }

  if (hasPermission(applicationClaims, systemTypeClaims.create, systemTypeId)) {
    // console.log(`HasSystemPermission: create ${systemTypeId}`);
    return true;
  }
  //   console.log(`HasSystemPermission: no permission`);
  return false;
}
