import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const createNewPricingRevisionMutation = gql`
  mutation CreateNewPricingRevision($pricingFileId: ID!, $name: String!) {
    createNewPricingRevision(pricingFileId: $pricingFileId, name: $name) {
      id
      revisionNo
      pricingNo
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
