import * as React from "react";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import * as GenesysPropertiesSelector from "../genesys-properties-selector";
import { PropertyValueItem } from "../react-properties-selector";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import { Coordinate, BinDataLocation } from "./types";
import {
  getDefaultLocation,
  groupLocations,
  GroupedLocations
} from "./functions";

type Definitions = ReadonlyArray<GenesysPropertiesSelectorTypes.Property>;

const propertyNames = {
  countryIndex: "country",
  regionIndex: "state",
  locationIndex: "location"
};

const binDataLocationDefinitions = (
  groupedLocations: GroupedLocations,
  selectedCountryIndex: number,
  selectedRegionIndex: number
): Definitions => {
  const country = groupedLocations[selectedCountryIndex];
  const region = country.regions[selectedRegionIndex];

  return [
    {
      sort_no: 0,
      name: propertyNames.countryIndex,
      group: "",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: groupedLocations.map((_, index) => makeOption(index))
    },
    {
      sort_no: 1,
      name: propertyNames.regionIndex,
      group: "",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: country.regions.map((_, index) => makeOption(index))
    },
    {
      sort_no: 2,
      name: propertyNames.locationIndex,
      group: "",
      quantity: "Discrete",
      validation_filter: PropertyFilter.Empty,
      visibility_filter: PropertyFilter.Empty,
      value: region.locations.map((_, index) => makeOption(index))
    }
  ];
};

interface Props {
  readonly binLocations: ReadonlyArray<BinDataLocation>;
  readonly coordinate?: Coordinate;
  readonly readonly?: boolean;
  readonly onChange: (locationId: string) => void;
}

interface State {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
}

// tslint:disable-next-line:variable-name
export class LocationSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultLocation = getDefaultLocation(
      this.props.binLocations,
      this.props.coordinate
    );

    const countries = groupLocations(this.props.binLocations);

    const country = countries.find(
      item => item.countryName === defaultLocation.countryName
    )!;

    const region = country.regions.find(
      item => item.regionName === defaultLocation.regionName
    )!;

    const location = region.locations.find(
      item => item.locationName === defaultLocation.locationName
    )!;

    this.state = {
      selectedProperties: {
        [propertyNames.countryIndex]: PropertyValue.fromInteger(
          countries.indexOf(country)
        ),
        [propertyNames.regionIndex]: PropertyValue.fromInteger(
          country.regions.indexOf(region)
        ),
        [propertyNames.locationIndex]: PropertyValue.fromInteger(
          region.locations.indexOf(location)
        )
      }
    };
  }
  render() {
    const groupedLocations = groupLocations(this.props.binLocations);

    const selectedCountryIndex =
      PropertyValueSet.getInteger(
        propertyNames.countryIndex,
        this.state.selectedProperties
      ) || 0;
    const selectedRegionIndex =
      PropertyValueSet.getInteger(
        propertyNames.regionIndex,
        this.state.selectedProperties
      ) || 0;
    return (
      <GenesysPropertiesSelector.PropertiesSelectorContainer
        selectedProperties={this.state.selectedProperties}
        productProperties={binDataLocationDefinitions(
          groupedLocations,
          selectedCountryIndex,
          selectedRegionIndex
        )}
        screenAmountProfileFieldGroup="BinDataLocationSettings"
        onChange={pvs => {
          // Old values.
          let oldCountryIndex =
            PropertyValueSet.getInteger(
              propertyNames.countryIndex,
              this.state.selectedProperties
            ) || 0;
          let oldRegionIndex =
            PropertyValueSet.getInteger(
              propertyNames.regionIndex,
              this.state.selectedProperties
            ) || 0;

          // New values.
          let newCountryIndex =
            PropertyValueSet.getInteger(propertyNames.countryIndex, pvs) || 0;
          let newRegionIndex =
            PropertyValueSet.getInteger(propertyNames.regionIndex, pvs) || 0;
          let newLocationIndex =
            PropertyValueSet.getInteger(propertyNames.locationIndex, pvs) || 0;

          // Make the selections hierarcial.
          if (oldCountryIndex !== newCountryIndex) {
            newRegionIndex = 0;
            newLocationIndex = 0;
          }
          if (oldRegionIndex !== newRegionIndex) {
            newLocationIndex = 0;
          }

          // Show the changes.
          this.setState({
            selectedProperties: {
              [propertyNames.countryIndex]:
                PropertyValue.fromInteger(newCountryIndex),
              [propertyNames.regionIndex]:
                PropertyValue.fromInteger(newRegionIndex),
              [propertyNames.locationIndex]:
                PropertyValue.fromInteger(newLocationIndex)
            }
          });

          // Create the change event.
          const location =
            groupedLocations[newCountryIndex].regions[newRegionIndex].locations[
              newLocationIndex
            ];
          this.props.onChange(location.binDataLocationId);
        }}
        uniqueId="BinDataLocationSettings"
        customTranslation={{
          propertyValue: (
            propertyName: string,
            propertyValue: number
          ): string => {
            switch (propertyName) {
              case propertyNames.countryIndex:
                return groupedLocations[propertyValue].countryName;

              case propertyNames.regionIndex:
                return groupedLocations[selectedCountryIndex].regions[
                  propertyValue
                ].regionName;

              case propertyNames.locationIndex:
                return groupedLocations[selectedCountryIndex].regions[
                  selectedRegionIndex
                ].locations[propertyValue].locationName;

              default:
                return "";
            }
          }
        }}
        readOnlyProperties={
          this.props.readonly
            ? Object.keys(propertyNames).map(
                k => (propertyNames as { readonly [key: string]: string })[k]
              )
            : undefined
        }
      />
    );
  }
}

const makeOption = (
  i: number,
  propertyFilter?: PropertyFilter.PropertyFilter
): PropertyValueItem => ({
  sort_no: i,
  value: {
    type: "integer",
    value: i
  },
  property_filter: propertyFilter || PropertyFilter.Empty
});
