import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import { State, Action } from "../../../state";
import { Dispatch } from "@typescript-tea/core";
import { OverlayLoader } from "@genesys/ui-elements";
import { ResultsRoot, BottomPagnationContainer } from "../../../elements";
import { PagnationComponent } from "../../../../shared-manager-components";
import { ColumnDefinition } from "../../../types";
import { Result } from "./result";
import { ActionsMenu } from "./actions-menu";
import { UpperMenu } from "./upper-menu";

export function ResultView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state.queryUserResult) {
    return <OverlayLoader />;
  }

  const dataColumns = createDataColumnsDef(sharedState);

  const paginationComponent = (
    <PagnationComponent
      sharedState={sharedState}
      currentPage={state.currentPage}
      itemsPerPage={state.systemsPerPage}
      totalItems={state.queryUserResult?.user.searchSystems.totalResults || 0}
      onPageChange={pageNumber => dispatch(Action.changePage(pageNumber))}
    />
  );

  return (
    <ResultsRoot>
      <UpperMenu
        sharedState={sharedState}
        state={state}
        paginationComponent={paginationComponent}
        dispatch={dispatch}
      />
      <ActionsMenu
        sharedState={sharedState}
        state={state}
        dispatch={dispatch}
        dataColumns={dataColumns}
      />
      <Result
        dataColumns={dataColumns}
        sharedState={sharedState}
        state={state}
        dispatch={dispatch}
      />
      <BottomPagnationContainer>{paginationComponent}</BottomPagnationContainer>
    </ResultsRoot>
  );
}

function createDataColumnsDef(
  sharedState: SharedState.State
): ReadonlyArray<ColumnDefinition> {
  return [
    {
      columnName: "favorites"
    },
    {
      columnName: "actions-menu"
    },

    {
      columnName: "g-no",
      excelHeaderName: "G #"
    },

    {
      columnName: "system-name",
      excelHeaderName: sharedState.translate(LanguageTexts.name())
    },

    {
      columnName: "system-type",
      excelHeaderName: sharedState.translate(LanguageTexts.type())
    },
    {
      columnName: "owner",
      excelHeaderName: sharedState.translate(LanguageTexts.owner())
    },
    {
      columnName: "date-changed",
      excelHeaderName: sharedState.translate(LanguageTexts.changed())
    },
    {
      columnName: "status",
      excelHeaderName: sharedState.translate(LanguageTexts.status())
    },
    {
      columnName: "labels",
      excelHeaderName: sharedState.translate(LanguageTexts.labels())
    },
    {
      columnName: "revisions",
      excelHeaderName: sharedState.translate(LanguageTexts.revisions())
    }
  ];
}
