import styled from "styled-components";
import { zIndex, PrimaryColors, defaultFontFamily } from "@genesys/ui-elements";

type Props = {
  readonly hasError: boolean;
};

export const Root = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  font-size: 13px;
  font-weight: 500;
  font-family: ${defaultFontFamily};
  letter-spacing: 0;
  line-height: 17px;

  height: 24px;
  width: 100%;
  padding: 12px 0;

  border: 0px;
  border-radius: 3px;
  border-bottom: 1px solid
    ${(props: Props) => (props.hasError ? "black" : PrimaryColors.blue400)};

  color: ${(props: Props) => (props.hasError ? "black" : "#373737")};

  background-color: ${(props: Props) =>
    props.hasError ? PrimaryColors.lightRed : "inherit"};

  &:focus {
    outline: none;
  }
`;

export const ReadOnlyText = styled.label`
  color: ${(props: Props) => (props.hasError ? "black" : "#373737")};
  background-color: ${(props: Props) =>
    props.hasError ? PrimaryColors.lightRed : "inherit"};
  border-bottom: ${(props: Props) =>
    props.hasError ? "1px solid black" : "none"};
  border-radius: 3px;
`;

export const ErrorContainer = styled.div`
  width: 180px;
  top: 27px;
  position: absolute;
  z-index: ${zIndex.Dropdown};
  background-color: #e8767b;
  padding-left: 5px;
  padding-bottom: 4px;
  padding-top: 4px;
`;

export const ErrorText = styled.p`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  white-space: normal;
  color: #ffffff;
`;
