import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div``;

export const TargetPowerContainer = styled.div`
  display: flex;
  padding: 20px 0 10px 0;
`;

export const CardsContainer = styled.div`
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 8px;
    margin-right: 20px;
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00adf2;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SizeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px;
  margin: 10px;

  border: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected
      ? `2px solid ${PrimaryColors.muntersBlue}`
      : "2px solid #f0f0f0"};

  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);

  cursor: pointer;

  :hover {
    border: 2px solid #3d3d3d;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
`;

export const InnerDiv = styled.div`
  display: flex;
  padding: 5px;

  p {
    white-space: nowrap;
  }
`;
