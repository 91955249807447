import * as React from "react";
import {
  PropertiesSelectorView,
  Action,
  PropertyInfo
} from "../../../properties-selector";
import { TemplateComponentListItem } from "./elements";
import { H2 } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import { PropertyValueSet } from "@genesys/property";
import { Dispatch } from "@typescript-tea/core";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface Props {
  readonly label: string | undefined;
  readonly productId: string;
  readonly sharedState: SharedState.State;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly propertyDef: ReadonlyArray<PropertyInfo>;
  readonly showCodes: boolean;
  readonly dispatch: Dispatch<Action>;
}

export function TemplateComponent(props: Props): JSX.Element {
  const systemId = props.productId.substring(0, 3);
  const label = props.sharedState.translate(
    LanguageTexts.dynamicText(
      props.label ? systemId + "_" + props.label : props.productId,
      systemId
    )
  );

  return (
    <TemplateComponentListItem>
      <H2 weight="normal">{label}</H2>
      <PropertiesSelectorView
        state={{
          properties: props.properties
        }}
        propertiesInfo={props.propertyDef}
        dispatch={props.dispatch}
        productId={props.productId}
        isReadonly={false}
        fieldGroup={`Settings.${props.productId}`}
        sharedState={props.sharedState}
        showCodes={props.showCodes}
      />
    </TemplateComponentListItem>
  );
}
