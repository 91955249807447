import * as React from "react";
import { State, Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import * as PropertiesSelector from "../../../properties-selector";
import { PropertyFilter, PropertyValue } from "@genesys/property";
import { P1 } from "@genesys/ui-elements";
import { Amount, Units } from "@genesys/uom";

const Root = styled.div`
  display: flex;
`;

const PropertyNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 11px 13px 0 0;
`;

export function ChartSettingsComponent({
  properties,
  state,
  sharedState,
  dispatch
}: {
  readonly properties: ReadonlyArray<PropertiesSelector.PropertyInfo>;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <Root>
      <PropertyNamesContainer>
        {properties
          .filter(p =>
            PropertyFilter.isValid(
              state.chartSettings.properties,
              p.visibilityFilter
            )
          )
          .map(p => (
            <P1 key={p.name} weight="normal" color="dark">
              {title(p.name)}
            </P1>
          ))}
      </PropertyNamesContainer>

      <PropertiesSelector.PropertiesSelectorView
        dispatch={Dispatch.map(Action.dispatchChartSettings, dispatch)}
        productId={"PsychrometricTool"}
        sharedState={sharedState}
        propertiesInfo={properties}
        fieldGroup={"Settings.PsychrometricTool"}
        isReadonly={false}
        showCodes={false}
        state={state.chartSettings}
        hidePropertyNames={true}
      />
    </Root>
  );
}

function title(name: string): string {
  switch (name) {
    case "showpower":
      return "Power";
    case "charttype":
      return "Chart type";
    case "ipsi":
      return "IP/SI";
    case "limits":
      return "Limits";
    case "humiditymax":
      return "Max humidity";
    case "temperaturemin":
      return "Min temperature";
    case "temperaturemax":
      return "Max temperature";
    default:
      return name;
  }
}

export function chartSettingsDefinition(
  temperatureLimit: Amount.Amount<Units.Temperature>
): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "showpower",
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: [
        {
          id: "0",
          sortNo: 0,
          value: {
            type: "integer",
            value: 0
          },
          text: "No",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        },
        {
          id: "1",
          sortNo: 1,
          value: {
            type: "integer",
            value: 1
          },
          text: "Yes",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        }
      ]
    },
    {
      sortNo: 2,
      name: "charttype",
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: [
        {
          id: "2",
          sortNo: 0,
          value: {
            type: "integer",
            value: 0
          },
          text: "Psychrometric",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        },
        {
          id: "3",
          sortNo: 1,
          value: {
            type: "integer",
            value: 1
          },
          text: "Mollier",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        }
      ]
    },
    {
      sortNo: 3,
      name: "ipsi",
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: [
        {
          id: "4",
          sortNo: 0,
          value: {
            type: "integer",
            value: 0
          },
          text: "IP",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        },
        {
          id: "5",
          sortNo: 1,
          value: {
            type: "integer",
            value: 1
          },
          text: "SI",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        }
      ]
    },
    {
      sortNo: 4,
      name: "limits",
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: [
        {
          id: "6",
          sortNo: 0,
          value: {
            type: "integer",
            value: 0
          },
          text: "Smart",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        },
        {
          id: "8",
          sortNo: 1,
          value: {
            type: "integer",
            value: 1
          },
          text: "Manual",
          validationFilter: PropertyFilter.Empty,
          descriptionValuesTexts: [],
          rangeFilter: PropertyFilter.Empty
        }
      ]
    },
    {
      sortNo: 5,
      name: "humiditymax",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.fromStringOrEmpty("limits=1"),
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: []
    },
    {
      sortNo: 6,
      name: "temperaturemin",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "limits=0|temperaturemin<" +
          PropertyValue.toString(PropertyValue.fromAmount(temperatureLimit))
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("limits=1"),
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: []
    },
    {
      sortNo: 7,
      name: "temperaturemax",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "limits=0|temperaturemax>" +
          PropertyValue.toString(PropertyValue.fromAmount(temperatureLimit))
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("limits=1"),
      conversionParameters: undefined,
      valueSources: [],
      descriptionTexts: [],
      defaultValues: [],
      items: []
    }
  ];
}
