import styled from "styled-components";
import { zIndex, P2, PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  padding: 20px;
  overflow: auto;
`;

export const DesignContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const OptionsTableContainer = styled.div`
  padding-left: 30px;
`;

export const OpcSelectorsContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CheckBoxContainer = styled.div`
  margin-top: -10px;
  margin-left: 10px;
`;

export const OptionsTable = styled.table`
  tr:first-child {
    th {
      height: 22px;
    }
  }

  th,
  td {
    height: 30px;
    padding: 0 8px;
    text-align: center;
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;

export const CasesContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: inherit;
`;

export const PropertyNamesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  margin-right: 20px;
  white-space: nowrap;
  z-index: ${zIndex.Modal + zIndex.OperatingCasePropertyNames};
  background-color: white;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  margin: 0 20px 20px 0;
  white-space: nowrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: white;

  h3 {
    color: white;
  }
`;

export const CaseNameValidation = styled.div`
  position: absolute;
  top: 30px;
  left: 10px;
  background-color: ${PrimaryColors.lightRed};
  min-height: 25px;
  min-width: 148px;
  border-radius: 3px;
`;

export const PropertiesSelectorJsxContainer = styled.div`
  position: relative;
  padding-bottom: 40px;

  table {
    min-width: 238px;
    tbody {
      tr:nth-child(1) {
        td {
          height: 0;
          h3 {
            display: none;
          }
        }
      }
      tr {
        td {
          height: 30px;
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 3 : 1}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 155px;
          max-width: 155px;
          padding-left: 10px;

          > div {
            max-width: 155px;
          }
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 4 : 2}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 58px;
          padding: 0 10px 0 0;

          * {
            max-width: 58px;
          }

          div {
            div {
              div {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const ClimateSelectorContainer = styled.div`
  width: 100%;
  padding: 15px 34px;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 40px;
  width: 100%;

  position: absolute;
  bottom: 0px;
  left: 5px;

  a {
    position: relative;
    right: 204px;

    img {
      cursor: pointer;
    }
  }
`;

export const AddNewCaseButtonContainer = styled.div`
  position: absolute;
  top: -50px;
  right: 21px;
`;

export const StyledP2 = styled(P2)`
  ${(props: { readonly showLineThrough: boolean }) =>
    props.showLineThrough ? "text-decoration: line-through" : ""};
`;

export const GreyDiv = styled.div`
  color: #959695;
`;
