import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as PsychrometricChart from "../../psychrometric-chart";
import { BinDataLocation } from "../../location-selector";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { ListView } from "./list-view";
import { BinChartView } from "./chart-view";
import { Quantity, Unit } from "@genesys/uom";
import { BinVisualizer, ChartType, BinListViewType, BinInfo } from "../types";
import { PropertyValue } from "@genesys/property";

const Root = styled.div`
  padding-top: 15px;
`;

const ViewAsSelectorContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 12px 15px 0;
  > span {
    margin-right: 5px;
  }
`;

const LicensingContainer = styled.div`
  font-size: 12px;
  margin-top: 20px;
  ul {
    max-width: 705px;
    li {
      /* list-style: none; */
      margin-left: 20px;
    }
  }
`;

const binVisualizers: ReadonlyArray<BinVisualizer> = ["List", "Chart"];

type Version = "v1" | "v2";

export function ResultView({
  binInfo,
  version,
  pressure,
  sharedState,
  exportFileName,
  binDataLocation,
  psychrometricChartState,
  setChartType,
  setBinListView,
  onFormatChanged,
  onFormatCleared,
  setBinVisualizer
}: {
  readonly binInfo: BinInfo;
  readonly version: Version;
  readonly exportFileName: string;
  readonly sharedState: SharedState.State;
  readonly binDataLocation: BinDataLocation | undefined;
  readonly pressure?: PropertyValue.PropertyValue;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
  readonly setChartType: (chartType: ChartType) => void;
  readonly setBinVisualizer: (binVisualizer: BinVisualizer) => void;
  readonly setBinListView: (binListView: BinListViewType) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const translate = sharedState.translate;
  const selectedBinVisualizer = binInfo.selectedBinVisualizer;

  return (
    <Root>
      <ViewAsSelectorContainer>
        <span>{translate(LanguageTexts.viewAs())}</span>
        <GenesysSelect
          width={80}
          height={35}
          value={selectedBinVisualizer}
          options={binVisualizers.map(b => ({
            value: b,
            title: translate(LanguageTexts.globalPropertyName(b.toLowerCase()))
          }))}
          onChange={e => {
            setBinVisualizer(e.target.value as BinVisualizer);
          }}
        />
      </ViewAsSelectorContainer>

      {selectedBinVisualizer === "List" ? (
        <ListView
          pressure={pressure}
          sharedState={sharedState}
          binCases={binInfo.binCases!}
          exportFileName={exportFileName}
          binDataLocation={binDataLocation}
          selectedBinType={binInfo.selectedBinType}
          selectedBinListView={binInfo.selectedBinListView}
          setBinListView={setBinListView}
          onFormatChanged={onFormatChanged}
          onFormatCleared={onFormatCleared}
        />
      ) : (
        <BinChartView
          info={binInfo}
          sharedState={sharedState}
          binCases={binInfo.binCases!}
          psychrometricChartState={psychrometricChartState}
          setChartType={setChartType}
        />
      )}
      <LicensingContainer>
        {getLicensingInfo(version, binInfo.selectedBinType === "Generated")}
      </LicensingContainer>
    </Root>
  );
}

function getLicensingInfo(version: Version, showLicensingInfo: boolean) {
  if (!showLicensingInfo) {
    return null;
  }
  switch (version) {
    case "v1": {
      return (
        <ul>
          <li>
            IWEC version2.0 Weather files. © 2012 ASHRAE (www.ashrae.org), used
            by permission.
          </li>
          <li>
            CWEC: Based on Environmental and Climate Change Canada data
            (climate.weather.gc.ca).
          </li>
          <li>
            Weather data provided by U.S. Department of
            Energy(DOE)/NREL/ALLIANCE (nrel.gov)
            <p style={{ fontSize: "8px", paddingLeft: "10px" }}>
              USER AGREES TO INDEMNIFY DOE/NREL/ALLIANCE AND ITS SUBSIDIARIES,
              AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES AGAINST ANY CLAIM OR
              DEMAND, INCLUDING REASONABLE ATTORNEYS' FEES, RELATED TO USER'S
              USE OF THE DATA. THE DATA ARE PROVIDED BY DOE/NREL/ALLIANCE "AS
              IS," AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING BUT NOT
              LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
              FOR A PARTICULAR PURPOSE ARE DISCLAIMED. DOE/NREL/ALLIANCE ASSUME
              NO LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY,
              COMPLETENESS, OR USEFULNESS OF THE DATA, OR REPRESENT THAT ITS USE
              WOULD NOT INFRINGE PRIVATELY OWNED RIGHTS. IN NO EVENT SHALL
              DOE/NREL/ALLIANCE BE LIABLE FOR ANY SPECIAL, INDIRECT OR
              CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT
              LIMITED TO CLAIMS ASSOCIATED WITH THE LOSS OF DATA OR PROFITS,
              THAT MAY RESULT FROM AN ACTION IN CONTRACT, NEGLIGENCE OR OTHER
              TORTIOUS CLAIM THAT ARISES OUT OF OR IN CONNECTION WITH THE
              ACCESS, USE OR PERFORMANCE OF THE DATA.
            </p>
          </li>
        </ul>
      );
    }
    case "v2": {
      return (
        <ul>
          <li>
            Citation for this work: Lawrie, Linda K, Drury B Crawley. 2022.
            Development of Global Typical Meteorological Years (TMYx).
            <a target="_blank" href="http://climate.onebuilding.org">
              http://climate.onebuilding.org
            </a>
          </li>
          <li>
            TMY date ranges (used to create bins). Total bin locations=8375,
            8352 have data from 2007-2021, and 23 have data from 2004-2018.
          </li>
          <li>
            <p style={{ fontSize: "10px" }}>
              Disclaimer: Climate.OneBuilding.Org, OneBuilding.Org and the
              authors accept no liability for the content, quality or
              completeness of any data posted on this site, or for the
              consequences of any actions taken on the basis of the information
              provided.
            </p>
          </li>
        </ul>
      );
    }
    default: {
      return null;
    }
  }
}
