import gql from "graphql-tag";

export const buildMutation = gql`
  mutation BuildWiringDiagramMutation($input: WiringDiagramInputType!) {
    buildWiringDiagram(input: $input) {
      responseCode
    }
  }
`;

export const getBuildStateQuery = gql`
  query getWiringDiagramBuildStateUserQuery($input: WiringDiagramInputType!) {
    user {
      getWiringDiagramBuildState(input: $input) {
        responseCode
      }
    }
  }
`;

export const downloadQuery = gql`
  query downloadWiringDiagramUserQuery($input: WiringDiagramInputType!) {
    user {
      downloadWiringDiagram(input: $input) {
        responseCode
        base64Pdf
      }
    }
  }
`;
