import styled from "styled-components";
import * as React from "react";

export interface FlexContainerRowProps {
  readonly center?: boolean;
  readonly justifyContent?: string;
}

export const unStyledFlexContainerRowWithPadding: React.FunctionComponent<
  FlexContainerRowProps & React.HTMLProps<HTMLDivElement>
> = props => {
  const { center, justifyContent, ...htmlProps } = props;
  return <div {...htmlProps}>{htmlProps.children}</div>;
};

// tslint:disable-next-line
export const FlexContainerRow = styled(unStyledFlexContainerRowWithPadding)`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ||
    (props.center === undefined || props.center ? "center" : "flex-start")};
  flex-direction: row;
`;

// tslint:disable-next-line
export const FlexContainerRowWithPadding = styled(FlexContainerRow)`
  > div {
    padding: 15px;
  }
`;
