import styled from "styled-components";
import * as React from "react";

export const unStyledManagerFilterContainer = (
  props: { readonly center?: boolean } & React.HTMLProps<HTMLDivElement>
) => {
  const { center, ...htmlProps } = props;
  return <div {...htmlProps}>{htmlProps.children}</div>;
};

// tslint:disable-next-line
export const ManagerSearchContainer = styled(unStyledManagerFilterContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
