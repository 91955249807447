import gql from "graphql-tag";

export const setSelectedLoadCaseFlow = gql`
  mutation SetSelectedLoadCaseFlow(
    $moistureLoadId: ID!
    $caseId: ID!
    $flowId: Int!
  ) {
    setSelectedLoadCaseFlow(
      moistureloadId: $moistureLoadId
      caseId: $caseId
      flowId: $flowId
    ) {
      id
    }
  }
`;
