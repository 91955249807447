import { PropertyFilter, PropertyValue } from "@genesys/property";

export type PropertyValueFilterOverride = {
  readonly systemType: string;
  readonly propertyName: string;
  readonly propertyValue: PropertyValue.PropertyValue;
  readonly replacementFilter: PropertyFilter.PropertyFilter;
};

export const newPropertiesOverrides: ReadonlyArray<PropertyValueFilterOverride> =
  [
    {
      systemType: "MXO",
      propertyName: "dhmodel",
      propertyValue: PropertyValue.fromInteger(30),
      replacementFilter: PropertyFilter.Empty
    }
  ];
