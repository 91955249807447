export const airMidPointTemperature = "airmidpointtemperature";
export const airMidPointWetTemperature = "airmidpointwettemperature";
export const airMidPointDewPointTemperature = "airmidpointdewpointtemperature";
export const airMidPointSpecificEnthalpy = "airmidpointspecificenthalpy";
export const airMidPointHumidityRatio = "airmidpointhumidityratio";
export const id = "id";
export const airTemperature = "airtemperature";
export const binPressure = "binpressure";
export const binInputCustomPressure = "bininputcustompressure";
export const airHumidity = "airhumidity";
export const time = "time";
export const binTimeJanuary = "bintimejanuary";
export const binTimeFebruary = "bintimefebruary";
export const binTimeMarch = "bintimemarch";
export const binTimeApril = "bintimeapril";
export const binTimeMay = "bintimemay";
export const binTimeJune = "bintimejune";
export const binTimeJuly = "bintimejuly";
export const binTimeAugust = "bintimeaugust";
export const binTimeSeptember = "bintimeseptember";
export const binTimeOctober = "bintimeoctober";
export const binTimeNovember = "bintimenovember";
export const binTimeDecember = "bintimedecember";
