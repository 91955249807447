import { PropertyValueSet } from "@genesys/property";
import * as Types from "./types";
import { Amount, Quantity, Units } from "@genesys/uom";
import { State } from "./state";

const interval = 100;

export function getAirflows(
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>
): Types.AirFlows {
  const airFlows = operatingCases.map(opc => {
    const outletFlowPropertyName = "supplyoutletairflow";
    const outletFlow = PropertyValueSet.hasProperty(outletFlowPropertyName, opc)
      ? PropertyValueSet.getAmount<Quantity.MassFlow>(
          outletFlowPropertyName,
          opc
        )!
      : Amount.create(0, Units.KilogramPerSecond);

    return outletFlow;
  });

  const sortedAirFlows = airFlows.sort((a, b) =>
    Amount.lessThan(a, b) ? -1 : 1
  );

  return {
    minAirFlow: sortedAirFlows[0],
    maxAirFlow: sortedAirFlows[sortedAirFlows.length - 1]
  };
}

export function getTotalWallLength(
  wallLenghtX: Amount.Amount<Quantity.Length>,
  wallLenghtY: Amount.Amount<Quantity.Length>,
  wallSections: number
): Amount.Amount<Quantity.Length> | undefined {
  const x = Amount.valueAs(Units.Meter, wallLenghtX);
  const y = Amount.valueAs(Units.Meter, wallLenghtY);
  switch (wallSections) {
    case 1:
      return Amount.create(x, Units.Meter);
    case 2:
      return Amount.create(2 * x, Units.Meter);
    case 3:
      return Amount.create(2 * x + y, Units.Meter);
    case 4:
      return Amount.create(2 * x + 2 * y, Units.Meter);
    default:
      return undefined;
  }
}

export function getPadHeights(
  state: State
): ReadonlyArray<Amount.Amount<Quantity.Length>> {
  const minPadHeight = 600;
  const maxPadHeight = state.maxPadHeight
    ? Amount.valueAs(Units.Millimeter, state.maxPadHeight)
    : 2000;

  let padHeights: Array<number> = [];

  for (let i = minPadHeight; i <= maxPadHeight; i = i + interval) {
    padHeights.push(i);
  }

  return padHeights.map(p => Amount.create(p, Units.Millimeter));
}
