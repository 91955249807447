import * as TraceSpanExtration from "./trace-span-extraction";
import {
  TimerNode,
  TraceSpan,
  TopLevelNodes,
  SingleNode,
  Timer,
  ParallellTimer,
  ParallellNode,
  MultipleParallellNode,
  NodeInfo
} from "../types";

export function createTopLevelNodes(
  traceSpans: ReadonlyArray<TraceSpan>
): TopLevelNodes {
  const timerNodes = TraceSpanExtration.createTimerNodes(traceSpans);
  const allSingleTimerNodes = getSingleTimerNodes(timerNodes);
  const allSingleTimers = allSingleTimerNodes.map(c => createSingleTimer(c));
  const topLevelSingleNodes = createSingleNodes(allSingleTimers, undefined);

  const parallellTimerNodeGroups = getParallellTimerNodeGroups(timerNodes);
  const parallellTimers = Object.keys(parallellTimerNodeGroups).map(key =>
    createParallellTimer(parallellTimerNodeGroups[key])
  );

  const topLevelMultipleParallellNode = createMultipleParallellNode(
    parallellTimers,
    undefined
  );

  return {
    singleNodes: topLevelSingleNodes,
    multipleParallellNode: topLevelMultipleParallellNode
  };
}

function createSingleNodes(
  singleTimers: ReadonlyArray<Timer>,
  parentTotalNs: number | undefined
): ReadonlyArray<SingleNode> {
  const timersGroupedByName = groupTimersByName(singleTimers);
  return Object.keys(timersGroupedByName).reduce(
    (
      //tslint:disable-next-line
      soFarGroups: SingleNode[],
      name
    ) => {
      const timers: readonly Timer[] = timersGroupedByName[name];

      const isFireAndForget = timers.every(n => n.isFireAndForget);
      const singleSubTimers = timers.reduce(
        (
          //tslint:disable-next-line
          soFar: Timer[],
          current
        ) => {
          soFar = soFar.concat(current.allSingleSubTimers);
          return soFar;
        },
        []
      ) as ReadonlyArray<Timer>;

      const parallellSubTimers = timers.reduce(
        (
          //tslint:disable-next-line
          soFar: ParallellTimer[],
          current
        ) => {
          soFar = soFar.concat(current.allParallellSubTimers);
          return soFar;
        },
        []
      ) as ReadonlyArray<ParallellTimer>;

      const totalNs = timers.reduce((a, b) => {
        return a + b.totalNs;
      }, 0);

      const idlelNs = timers.every(t => t.idleNs === undefined)
        ? undefined
        : timers.reduce((a, b) => {
            return a + (b.idleNs || 0);
          }, 0);

      const parallellSubNode = createMultipleParallellNode(
        parallellSubTimers,
        isFireAndForget ? undefined : totalNs
      );

      const groupedSubTimers = createSingleNodes(
        singleSubTimers,
        isFireAndForget ? undefined : totalNs
      );

      const startNs = timers.reduce((soFar, current) => {
        if (current.startNs && current.startNs < soFar) {
          return current.startNs;
        }

        return soFar;
      }, Number.MAX_VALUE);

      const otherNs =
        isFireAndForget ||
        (groupedSubTimers.length === 0 && parallellSubNode === undefined)
          ? undefined
          : totalNs -
            (idlelNs || 0) -
            groupedSubTimers.reduce((a, b) => {
              return a + b.totalNs;
            }, 0) -
            (parallellSubNode !== undefined ? parallellSubNode.totalNs : 0);

      const percentOfParent = isFireAndForget
        ? undefined
        : parentTotalNs !== undefined
        ? (totalNs / parentTotalNs) * 100
        : undefined;

      return soFarGroups.concat([
        {
          name: name,
          count: timers.length,
          startNs: startNs,
          totalNs: totalNs,
          idleNs: idlelNs,
          otherNs: otherNs,
          percentOfParent: percentOfParent,
          isFireAndForget: isFireAndForget,
          singleNodes: groupedSubTimers,
          parallellNode: parallellSubNode
        }
      ]);
    },
    []
  ) as ReadonlyArray<SingleNode>;
}

function createMultipleParallellNode(
  parallellTimers: ReadonlyArray<ParallellTimer>,
  parentTotalNs: number | undefined
): MultipleParallellNode | undefined {
  if (parallellTimers.length === 0) {
    return undefined;
  }

  const totalNs = parallellTimers.reduce((a, b) => {
    return a + b.totalNs;
  }, 0);

  const summedAverage = sumParallellTimers(parallellTimers, totalNs);

  const individualParallellNodes = parallellTimers.reduce((soFar, current) => {
    soFar = soFar.concat(createParallellNode(current, totalNs));
    return soFar;
  }, [] as ReadonlyArray<ParallellNode>);

  return {
    count: parallellTimers.length,
    totalNs: totalNs,
    percentOfParent:
      parentTotalNs !== undefined ? (totalNs / parentTotalNs) * 100 : undefined,
    individualParallellNodes: individualParallellNodes,
    deepSummedNodes: summedAverage
  };
}

function createParallellNode(
  parallellTimer: ParallellTimer,
  parentTotalNs: number | undefined
): ParallellNode {
  const name = createParallellTimerName("Parallell", parallellTimer);

  const averages = averageParallellTimer(parallellTimer);

  const individualSingleNodes = parallellTimer.parallellSingleTimers.reduce(
    (soFar, current) => {
      soFar = soFar.concat(
        createSingleNodes([current], parallellTimer.totalNs)
      );
      return soFar;
    },
    [] as ReadonlyArray<SingleNode>
  );

  return {
    name: name,
    count: 1,
    startNs: parallellTimer.startNs,
    totalNs: parallellTimer.totalNs,
    idleNs: undefined,
    percentOfParent:
      parentTotalNs !== undefined
        ? (parallellTimer.totalNs / parentTotalNs) * 100
        : undefined,
    deepAveragedNodes: averages,
    individualSingleNodes: individualSingleNodes
  };
}

function sumParallellTimers(
  parallellTimers: ReadonlyArray<ParallellTimer>,
  parentTotalNs: number | undefined
): ReadonlyArray<NodeInfo> {
  const allParallellAverages = parallellTimers.map(pt =>
    averageParallellTimer(pt)
  );

  return deepSumNodeInfos(allParallellAverages, parentTotalNs);
}

function createParallellTimerName(
  groupName: string,
  parallellTimer: ParallellTimer
): string {
  const names = parallellTimer.parallellSingleTimers.reduce(
    (
      soFar: //tslint:disable-next-line
      string[],
      current
    ) => {
      if (!soFar.includes(current.name)) {
        soFar = soFar.concat(current.name);
      }
      return soFar;
    },
    []
  ) as ReadonlyArray<string>;

  return groupName + " (" + names.join(", ") + ")";
}

function averageParallellTimer(
  parallellTimer: ParallellTimer
): ReadonlyArray<NodeInfo> {
  // 1. För varje parallellSingleTimer (Tråd).
  //   1.1. Skapa averages för alla
  //      1.1.1 Subtimers -> ReadonlyArray<Average>
  //      1.1.2 Parallella subtimers -> ReadonlyArray<Average>
  //   1.2 Djupsummera dessa
  //   1.3 Addera en average för denna tråds idle time -> parallellTimer.totalNs-parallellSingleTimer.TotalNs
  // 2. Djupmedelevårdesbilda dessa

  const name = createParallellTimerName("Parallell", parallellTimer);
  const parallellAverages = parallellTimer.parallellSingleTimers.map(pst => {
    const groupedSingleSubTimers = sumSingleTimers(
      pst.allSingleSubTimers,
      pst.totalNs
    );

    const groupedParallellTimers = sumParallellTimers(
      pst.allParallellSubTimers,
      pst.totalNs
    );

    const summedAverage = deepSumNodeInfos(
      [groupedSingleSubTimers, groupedParallellTimers],
      pst.totalNs
    );

    return [
      {
        type: "Sum",
        name: name,
        count: 1,
        startNs: pst.startNs,
        totalNs: pst.totalNs,
        otherNs: undefined,
        idleNs: pst.idleNs,
        percentOfParent: pst.totalNs / parallellTimer.totalNs,
        subNodes: summedAverage
      }
    ];
  }) as ReadonlyArray<ReadonlyArray<NodeInfo>>;

  return deepAverageNodeInfos(parallellAverages, parallellTimer.totalNs);
}

function sumSingleTimers(
  singleTimers: ReadonlyArray<Timer>,
  parentTotalNs: number | undefined
): ReadonlyArray<NodeInfo> {
  const timersGroupedByName = groupTimersByName(singleTimers);

  return Object.keys(timersGroupedByName).reduce(
    (
      //tslint:disable-next-line
      soFarGroups: NodeInfo[],
      currentKey
    ) => {
      const timers = timersGroupedByName[currentKey];

      const subTimers = timers.reduce(
        (
          //tslint:disable-next-line
          soFar: Timer[],
          current
        ) => {
          soFar = soFar.concat(current.allSingleSubTimers);
          return soFar;
        },
        []
      ) as ReadonlyArray<Timer>;

      const parallellTimers = timers.reduce(
        (
          //tslint:disable-next-line
          soFar: ParallellTimer[],
          current
        ) => {
          soFar = soFar.concat(current.allParallellSubTimers);
          return soFar;
        },
        []
      ) as ReadonlyArray<ParallellTimer>;

      const startNs = timers.reduce((currentMinStartNs, current) => {
        if (current.startNs < currentMinStartNs) {
          return current.startNs;
        }
        return currentMinStartNs;
      }, Number.MAX_VALUE);

      const totalNs = timers.reduce((a, b) => {
        return a + b.totalNs;
      }, 0);

      const groupedSingleSubTimers = sumSingleTimers(subTimers, totalNs);
      const groupedParallellTimers = sumParallellTimers(
        parallellTimers,
        totalNs
      );
      const summedAverage = deepSumNodeInfos(
        [groupedSingleSubTimers, groupedParallellTimers],
        totalNs
      );

      const otherNs =
        summedAverage.length > 0
          ? totalNs -
            summedAverage.reduce((a, b) => {
              return a + (b.type === "Sum" ? b.totalNs : b.averageTotalNs);
            }, 0)
          : undefined;

      return soFarGroups.concat([
        {
          type: "Sum",
          name: currentKey,
          count: timers.length,
          totalNs: totalNs,
          otherNs: otherNs,
          startNs: startNs,
          idleNs: undefined,
          percentOfParent:
            parentTotalNs !== undefined
              ? (totalNs / parentTotalNs) * 100
              : undefined,
          subNodes: summedAverage
        }
      ]);
    },
    []
  ) as ReadonlyArray<NodeInfo>;
}

function deepSumNodeInfos(
  arraysToSum: ReadonlyArray<ReadonlyArray<NodeInfo>>,
  parentTotalNs: number | undefined
): ReadonlyArray<NodeInfo> {
  const serialAveragesGroupedByName =
    groupAveragesPerCollectionByName(arraysToSum);

  return Object.keys(serialAveragesGroupedByName).map(name => {
    const averagesToSum = serialAveragesGroupedByName[name];
    const totalCount = averagesToSum.reduce((a, b) => {
      return a + (b.type === "Sum" ? b.count : b.averageCount);
    }, 0);

    const startNs = averagesToSum.reduce((currentMinStartNs, current) => {
      if (current.startNs < currentMinStartNs) {
        return current.startNs;
      }
      return currentMinStartNs;
    }, Number.MAX_VALUE);

    const totalNs = averagesToSum.reduce((a, b) => {
      return a + (b.type === "Sum" ? b.totalNs : b.averageTotalNs);
    }, 0);

    const otherNs = averagesToSum.every(
      a =>
        (a.type === "Sum" && a.otherNs === undefined) ||
        (a.type === "Average" && a.averageOtherNs === undefined)
    )
      ? undefined
      : averagesToSum.reduce((a, b) => {
          return (
            a + (b.type === "Sum" ? b.otherNs || 0 : b.averageOtherNs || 0)
          );
        }, 0);

    const idleNs = averagesToSum.every(
      a =>
        (a.type === "Sum" && a.idleNs === undefined) ||
        (a.type === "Average" && a.averageIdleNs === undefined)
    )
      ? undefined
      : averagesToSum.reduce((a, b) => {
          return a + (b.type === "Sum" ? b.idleNs || 0 : b.averageIdleNs || 0);
        }, 0);

    const subAveragesPerThread = averagesToSum.reduce(
      (
        //tslint:disable-next-line
        soFar: NodeInfo[][],
        current
      ) => {
        soFar = soFar.concat([current.subNodes as NodeInfo[]]);
        return soFar;
      },
      []
    ) as ReadonlyArray<ReadonlyArray<NodeInfo>>;

    const subAverages = deepSumNodeInfos(subAveragesPerThread, totalNs);
    return {
      type: "Sum",
      name: name,
      count: totalCount,
      startNs: startNs,
      totalNs: totalNs,
      otherNs: otherNs,
      idleNs: idleNs,
      percentOfParent:
        parentTotalNs !== undefined
          ? (totalNs / parentTotalNs) * 100
          : undefined,
      subNodes: subAverages
    };
  });
}

function deepAverageNodeInfos(
  arraysToAverage: ReadonlyArray<ReadonlyArray<NodeInfo>>,
  parentTotalNs: number | undefined
): ReadonlyArray<NodeInfo> {
  const parallellAveragesGroupedByName =
    groupAveragesPerCollectionByName(arraysToAverage);

  return Object.keys(parallellAveragesGroupedByName).map(name => {
    const averagesToAverage = parallellAveragesGroupedByName[name];
    const averageCount =
      averagesToAverage.reduce((sum, current) => {
        return (
          sum + (current.type === "Sum" ? current.count : current.averageCount)
        );
      }, 0) / arraysToAverage.length;

    const minCount =
      averagesToAverage.length < arraysToAverage.length
        ? 0
        : averagesToAverage.reduce((currentMinCount, current) => {
            const currentCount =
              current.type === "Sum" ? current.count : current.averageCount;
            if (currentCount < currentMinCount) {
              return currentCount;
            }
            return currentMinCount;
          }, Number.MAX_VALUE);

    const maxCount = averagesToAverage.reduce((currentMaxCount, current) => {
      const currentCount =
        current.type === "Sum" ? current.count : current.averageCount;
      if (currentCount > currentMaxCount) {
        return currentCount;
      }
      return currentMaxCount;
    }, 0);

    const startNs = averagesToAverage.reduce((currentMinStartNs, current) => {
      const currentStartNs =
        current.type === "Sum" ? current.startNs : current.startNs;
      if (currentStartNs < currentMinStartNs) {
        return currentStartNs;
      }
      return currentMinStartNs;
    }, Number.MAX_VALUE);

    const averageTotalNs =
      averagesToAverage.reduce((sum, current) => {
        return (
          sum +
          (current.type === "Sum" ? current.totalNs : current.averageTotalNs)
        );
      }, 0) / arraysToAverage.length;

    const minTotalNs =
      averagesToAverage.length < arraysToAverage.length
        ? 0
        : averagesToAverage.reduce((currentMinTotalNs, current) => {
            const currentTotalNs =
              current.type === "Sum" ? current.totalNs : current.averageTotalNs;
            if (currentTotalNs < currentMinTotalNs) {
              return currentTotalNs;
            }
            return currentMinTotalNs;
          }, Number.MAX_VALUE);

    const maxTotalNs = averagesToAverage.reduce(
      (currentMaxTotalNs, current) => {
        const currentTotalNs =
          current.type === "Sum" ? current.totalNs : current.averageTotalNs;
        if (currentTotalNs > currentMaxTotalNs) {
          return currentTotalNs;
        }
        return currentMaxTotalNs;
      },
      0
    );

    const averageOtherNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.otherNs === undefined) ||
        (a.type === "Average" && a.averageOtherNs === undefined)
    )
      ? undefined
      : averagesToAverage.reduce((sum, current) => {
          return (
            sum +
            (current.type === "Sum"
              ? current.otherNs || 0
              : current.averageOtherNs || 0)
          );
        }, 0) / arraysToAverage.length;

    const minOtherNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.otherNs === undefined) ||
        (a.type === "Average" && a.averageOtherNs === undefined)
    )
      ? undefined
      : averagesToAverage.length < arraysToAverage.length
      ? 0
      : averagesToAverage.reduce((currentMinOtherNs, current) => {
          const currentOtherNs =
            current.type === "Sum"
              ? current.otherNs || 0
              : current.averageOtherNs || 0;
          if (currentOtherNs < currentMinOtherNs) {
            return currentOtherNs;
          }
          return currentMinOtherNs;
        }, Number.MAX_VALUE);

    const maxOtherNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.otherNs === undefined) ||
        (a.type === "Average" && a.averageOtherNs === undefined)
    )
      ? undefined
      : averagesToAverage.reduce((currentMaxOtherNs, current) => {
          const currentOtherNs =
            current.type === "Sum"
              ? current.otherNs || 0
              : current.averageOtherNs || 0;
          if (currentOtherNs > currentMaxOtherNs) {
            return currentOtherNs;
          }
          return currentMaxOtherNs;
        }, 0);

    const averageIdleNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.idleNs === undefined) ||
        (a.type === "Average" && a.averageIdleNs === undefined)
    )
      ? undefined
      : averagesToAverage.reduce((sum, current) => {
          return (
            sum +
            (current.type === "Sum"
              ? current.idleNs || 0
              : current.averageIdleNs || 0)
          );
        }, 0) / arraysToAverage.length;

    const minIdleNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.idleNs === undefined) ||
        (a.type === "Average" && a.averageIdleNs === undefined)
    )
      ? undefined
      : averagesToAverage.length < arraysToAverage.length
      ? 0
      : averagesToAverage.reduce((currentMinIdleNs, current) => {
          const currentIdleNs =
            current.type === "Sum"
              ? current.idleNs || 0
              : current.averageIdleNs || 0;
          if (currentIdleNs < currentMinIdleNs) {
            return currentIdleNs;
          }
          return currentMinIdleNs;
        }, Number.MAX_VALUE);

    const maxIdleNs = averagesToAverage.every(
      a =>
        (a.type === "Sum" && a.idleNs === undefined) ||
        (a.type === "Average" && a.averageIdleNs === undefined)
    )
      ? undefined
      : averagesToAverage.reduce((currentMaxIdleNs, current) => {
          const currentIdleNs =
            current.type === "Sum"
              ? current.idleNs || 0
              : current.averageIdleNs || 0;
          if (currentIdleNs > currentMaxIdleNs) {
            return currentIdleNs;
          }
          return currentMaxIdleNs;
        }, 0);

    const subAveragesPerThread = averagesToAverage.reduce(
      (
        //tslint:disable-next-line
        soFar: NodeInfo[][],
        current
      ) => {
        soFar = soFar.concat([current.subNodes as NodeInfo[]]);
        return soFar;
      },
      []
    ) as ReadonlyArray<ReadonlyArray<NodeInfo>>;

    const subAverages = deepAverageNodeInfos(
      subAveragesPerThread,
      averageTotalNs
    );

    return {
      type: "Average",
      name: name,
      averageCount: averageCount,
      minCount: minCount,
      maxCount: maxCount,
      startNs: startNs,
      averageTotalNs: averageTotalNs,
      minTotalNs: minTotalNs,
      maxTotalNs: maxTotalNs,
      averageOtherNs: averageOtherNs,
      minOtherNs: minOtherNs,
      maxOtherNs: maxOtherNs,
      averageIdleNs: averageIdleNs,
      minIdleNs: minIdleNs,
      maxIdleNs: maxIdleNs,
      averagePercentOfParent:
        parentTotalNs !== undefined
          ? (averageTotalNs / parentTotalNs) * 100
          : undefined,
      subNodes: subAverages
    };
  });
}

function groupAveragesPerCollectionByName(
  multipleAveragesCollections: ReadonlyArray<ReadonlyArray<NodeInfo>>
): {
  readonly [name: string]: ReadonlyArray<NodeInfo>;
} {
  return multipleAveragesCollections.reduce(
    (
      //tslint:disable-next-line
      soFar: { [name: string]: ReadonlyArray<NodeInfo> },
      current
    ) => {
      const timersGroupedByName = groupNodeInfosByName(current);

      Object.keys(timersGroupedByName).forEach(key => {
        const group = timersGroupedByName[key];
        if (soFar[key] === undefined) {
          soFar[key] = [];
        }
        if (group.length === 1) {
          soFar[key] = soFar[key].concat(group[0]);
        } else if (group.length > 1) {
          throw Error("Should not happen.");
        }
      });

      return soFar;
    },
    {}
  ) as {
    readonly [name: string]: ReadonlyArray<NodeInfo>;
  };
}

function groupTimersByName(singleTimers: ReadonlyArray<Timer>): {
  readonly [name: string]: ReadonlyArray<Timer>;
} {
  const nodesInSeries = singleTimers.reduce(
    (
      //tslint:disable-next-line
      soFar: { [name: string]: ReadonlyArray<Timer> },
      current
    ) => {
      const newKey = `${current.name}`;
      if (soFar[newKey] === undefined) {
        soFar[newKey] = [];
      }
      soFar[newKey] = soFar[newKey].concat([current]);

      return soFar;
    },

    {}
  ) as { readonly [name: string]: ReadonlyArray<Timer> };

  return nodesInSeries;
}

function groupNodeInfosByName(singleTimers: ReadonlyArray<NodeInfo>): {
  readonly [name: string]: ReadonlyArray<NodeInfo>;
} {
  const nodesInSeries = singleTimers.reduce(
    (
      //tslint:disable-next-line
      soFar: { [name: string]: ReadonlyArray<NodeInfo> },
      current
    ) => {
      const newKey = `${current.name}`;
      if (soFar[newKey] === undefined) {
        soFar[newKey] = [];
      }
      soFar[newKey] = soFar[newKey].concat([current]);

      return soFar;
    },

    {}
  ) as { readonly [name: string]: ReadonlyArray<NodeInfo> };

  return nodesInSeries;
}

function createParallellTimer(
  parallellSingleTimerNodes: ReadonlyArray<TimerNode>
): ParallellTimer {
  // De inskickade noderna ligger parallellt.
  const startAndEnd = parallellSingleTimerNodes.reduce(
    (
      soFarInGroup: { readonly start: number; readonly end: number },
      currentInGroup
    ) => {
      let newMin = soFarInGroup.start;
      if (currentInGroup.startTimeUnixNano < soFarInGroup.start) {
        newMin = currentInGroup.startTimeUnixNano;
      }
      let newMax = soFarInGroup.end;
      if (currentInGroup.endTimeUnixNano > soFarInGroup.end) {
        newMax = currentInGroup.endTimeUnixNano;
      }

      return { start: newMin, end: newMax };
    },
    { start: Number.MAX_VALUE, end: Number.MIN_VALUE }
  );

  const totalNs = startAndEnd.end - startAndEnd.start;
  const parallellSingleTimers = parallellSingleTimerNodes.map(c =>
    createParallellSubCallSingleTimer(c, totalNs)
  );

  return {
    startNs: startAndEnd.start,
    totalNs: totalNs,
    parallellSingleTimers: parallellSingleTimers
  };
}

function createParallellSubCallSingleTimer(
  singleTimerNode: TimerNode,
  parallellTimerTotalNs: number
): Timer {
  const singleTimerNodes = getSingleTimerNodes(singleTimerNode.children);
  const allSingleSubTimers = singleTimerNodes.map(c => createSingleTimer(c));

  const parallellTimerNodeGroups = getParallellTimerNodeGroups(
    singleTimerNode.children
  );
  const allParallellSubTimers = Object.keys(parallellTimerNodeGroups).map(key =>
    createParallellTimer(parallellTimerNodeGroups[key])
  );

  const totalNs =
    singleTimerNode.endTimeUnixNano - singleTimerNode.startTimeUnixNano;

  return {
    name: singleTimerNode.name,
    isFireAndForget: singleTimerNode.isFireAndForget,
    allSingleSubTimers: allSingleSubTimers,
    allParallellSubTimers: allParallellSubTimers,
    startNs: singleTimerNode.startTimeUnixNano,
    totalNs: parallellTimerTotalNs,
    idleNs: parallellTimerTotalNs - totalNs
  };
}

function createSingleTimer(singleTimerNode: TimerNode): Timer {
  const singleTimerNodes = getSingleTimerNodes(singleTimerNode.children);
  const allSingleSubTimers = singleTimerNodes.map(c => createSingleTimer(c));

  const parallellTimerNodeGroups = getParallellTimerNodeGroups(
    singleTimerNode.children
  );
  const allParallellSubTimers = Object.keys(parallellTimerNodeGroups).map(key =>
    createParallellTimer(parallellTimerNodeGroups[key])
  );

  return {
    name: singleTimerNode.name,
    isFireAndForget: singleTimerNode.isFireAndForget,
    allSingleSubTimers: allSingleSubTimers,
    allParallellSubTimers: allParallellSubTimers,
    startNs: singleTimerNode.startTimeUnixNano,
    totalNs:
      singleTimerNode.endTimeUnixNano - singleTimerNode.startTimeUnixNano,
    idleNs: undefined
  };
}

function getSingleTimerNodes(
  timerNodes: ReadonlyArray<TimerNode>
): ReadonlyArray<TimerNode> {
  return timerNodes.filter(
    c => !c.parallellGroupId
  ) as ReadonlyArray<TimerNode>;
}

function getParallellTimerNodeGroups(timerNodes: ReadonlyArray<TimerNode>): {
  readonly [groupId: string]: ReadonlyArray<TimerNode>;
} {
  const nodesInParallellGroups = timerNodes
    .filter(c => c.parallellGroupId)
    .reduce(
      (
        soFar: {
          //tslint:disable-next-line
          [groupId: string]: ReadonlyArray<TimerNode>;
        },
        current
      ) => {
        const newKey = `${current.parallellGroupId}`;
        if (soFar[newKey] === undefined) {
          soFar[newKey] = [];
        }
        soFar[newKey] = soFar[newKey].concat([current]);

        return soFar;
      },
      {}
    ) as {
    readonly [groupId: string]: ReadonlyArray<TimerNode>;
  };

  return nodesInParallellGroups;
}

//tslint:disable-next-line
