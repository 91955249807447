import * as InitialSettings from "../../../initial-settings";
import * as GQLTypes from "../../../graphql-types";
import { Cmd } from "@typescript-tea/core";
import { promiseCmd } from "../../../promise-effect-manager";
import { State } from "../types";
import { Action } from "../actions";
import { buildSharedState } from "../state-builders";
import { languagesQuery } from "../../queries";
import { buildUser } from "../state-builders";
import {
  userSettingsDoComplyWithClaims,
  allowedLangauages,
  limitedLanguagePath,
  queryTranslations
} from "../tools";

export function handleUserQuery(
  state: State,
  userQuery: GQLTypes.RootUserQuery
): [State, Cmd<Action>?] {
  const user = buildUser(userQuery);
  const newState: State = {
    ...state,
    user,
    queriesLoading: state.queriesLoading
      .filter(q => q !== "userQuery")
      .concat("translationQuery")
  };

  const sharedState = buildSharedState(newState);
  if (!sharedState) {
    return [state];
  }

  const showInitialSettings =
    !user.settings.language ||
    !user.settings.locale ||
    !user.settings.currency ||
    !userSettingsDoComplyWithClaims(
      user.applicationClaims,
      user.settings.currency
    ) ||
    !user.settings.climate ||
    user.settings.climate.location === "not-set";
  const [initialSettingsState, initialSettingsCmd] =
    (showInitialSettings && InitialSettings.init(sharedState)) || [];

  // Dummy promise if lngcod. Because de dont need translation data
  const translationCmd =
    user.settings.language === "lngcod"
      ? promiseCmd<Action, {}>(
          () => Promise.resolve({}),
          _ =>
            Action.translationQueryReceived(
              {
                product: {
                  language: { id: "lngcod", texts: {} }
                }
              },
              "lngcod"
            )
        )
      : promiseCmd(
          async () => {
            const availableLanguagesQuery =
              await sharedState.graphQL.queryProduct<
                GQLTypes.LanguagesQuery,
                GQLTypes.LanguagesQueryVariables
              >(languagesQuery, {});

            const onLimitedLangRoute = limitedLanguagePath.some(x =>
              state.url.path.startsWith(x)
            );

            const lang = onLimitedLangRoute
              ? allowedLangauages.find(x => x === user.settings.language) ||
                "en-US"
              : availableLanguagesQuery.product.languages.find(
                  x => x.id === user.settings.language
                )?.id || "en-GB";

            const res = await queryTranslations(sharedState, lang);
            return {
              res,
              lang
            };
          },
          data => Action.translationQueryReceived(data.res, data.lang)
        );

  return [
    { ...newState, initialSettingsState, showInitialSettings },
    Cmd.batch<Action>([
      Cmd.map(Action.dispatchInitialSettings, initialSettingsCmd),
      translationCmd
    ])
  ];
}
