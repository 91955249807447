import * as React from "react";
import { createRoot } from "react-dom/client";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { UserManager, Log, UserManagerSettings } from "oidc-client";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/browser";
import { Program } from "@typescript-tea/core";
import * as Logger from "@genesys/logger";
import * as PromiseEffectManager from "./promise-effect-manager";
import * as NavigationEffectManager from "./navigation-effect-manager";
import * as NatsEffectManager from "./nats-effect-manager";
import * as OidcClientEffectManager from "./oidc-client-effect-manager";
import { clientConfig, Environment } from "./config";
import * as Root from "./root";

// Polyfill setImmediate, setInterval
// tslint:disable-next-line
require("timers-browserify");

document.title = clientConfig.defaultBrowserTitle;

configureGoogleAnalytics();
configureSentry();
const userManagerSettings: UserManagerSettings = {
  authority: clientConfig.oidcAuthority,
  client_id: clientConfig.oidcClientId,
  redirect_uri: clientConfig.oidcRedirectUri,
  response_type: clientConfig.oidcResponseType,
  scope: clientConfig.oidcScope,
  monitorSession: false,
  loadUserInfo: false,
  automaticSilentRenew: clientConfig.oidcResponseType === "code"
};
const manager = initializeOidc(userManagerSettings);
const app = document.getElementById("root");
const root = createRoot(app!);

(async () => {
  if (isLoginCallbackRequest(window.location)) {
    const user = await manager.signinRedirectCallback();
    window.location.href = user.state || "/";
  } else {
    const user = await manager.getUser();
    if (!user) {
      await manager.signinRedirect({ state: window.location.href });
      return;
    }

    if (
      clientConfig.environment !== "Localhost" &&
      !validateSitePermission(clientConfig.environment, user.access_token)
    ) {
      console.log("not valid");
      return root.render(
        <>
          <div>Permission denied.</div>
          <div>
            Request access <a href="https://stsusers.munters.com">here</a>
          </div>
          <div>
            If you've recently been given access you need to sign again.{" "}
            <a
              href="#"
              onClick={async () => {
                await manager.signoutRedirect();
              }}
            >
              Sign out
            </a>
          </div>
        </>
      );
    }
    Sentry.setUser({ username: user.profile.sub, email: user.profile.email });
    const rootProgram: Program<
      {
        readonly accessToken: string;
        readonly url: NavigationEffectManager.Url;
        readonly userManagerSettings: UserManagerSettings;
      },
      Root.State,
      Root.Action,
      JSX.Element
    > = {
      init: Root.init,
      update: Root.update,
      view: Root.view,
      subscriptions: Root.subscriptions
    };

    const effectManagers = [
      NavigationEffectManager.createEffectManager<Root.Action>(
        Root.Action.UrlChanged,
        Root.Action.UrlRequested
      ),
      OidcClientEffectManager.createEffectManager(),
      PromiseEffectManager,
      NatsEffectManager.createEffectManager({
        url: `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${
          window.location.host
        }/natsws`,
        fetchNatsJwt: async () => {
          const user = await manager.getUser();
          if (!user) {
            console.error("Error getting user");
            return "";
          }
          const natsJwt = await getNatsJwt(user.access_token);

          if (!natsJwt) {
            console.error("Error fetching nats jwt");
            return "";
          }

          return natsJwt;
        },
        natsJwtExpirationSeconds: clientConfig.natsTokenExpirationSeconds
      })
    ];

    const rootProgramWithLogger =
      process.env.NODE_ENV !== "production"
        ? Logger.programWithLogger(rootProgram, undefined)
        : rootProgram;

    const myEffectManagersWithLogger =
      process.env.NODE_ENV === "development2"
        ? Logger.effectManagersWithLogger(effectManagers, undefined)
        : effectManagers;

    // -- RUN
    const render = (view: JSX.Element) => root.render(view);
    Program.run(
      rootProgramWithLogger,
      {
        accessToken: user.access_token,
        url: NavigationEffectManager.getCurrentUrl(),
        userManagerSettings
      },
      render,
      myEffectManagersWithLogger
    );
  }
})();

function configureGoogleAnalytics() {
  // Develop
  //ReactGA.initialize("UA-207971374-1");
  //Production
  ReactGA.initialize("G-LY1VN641TC");
}

function initializeOidc(userManagerSettings: UserManagerSettings) {
  if (clientConfig.environment === "Localhost" && false) {
    Log.logger = console;
    Log.level = 4;
  }
  const manager = new UserManager(userManagerSettings);
  manager.events.addAccessTokenExpired(async () => {
    await manager.signinRedirect({ state: window.location.href });
  });
  return manager;
}

function configureSentry(): void {
  const sentryEnabled = clientConfig.sentryDsn.length > 0;
  if (sentryEnabled) {
    Sentry.init({
      environment: clientConfig.environment,
      dsn: clientConfig.sentryDsn,

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: `genesys-client-gen2@${clientConfig.clientVersion.tag}`
    });
  }
}

const BASE_CLAIM = "http://munters.com/ApplicationClaim";
function validateSitePermission(
  env: Environment,
  accessToken: string
): boolean {
  const payload = accessToken.split(".")[1];
  const parsedPayload: { readonly [BASE_CLAIM]: ReadonlyArray<string> } =
    JSON.parse(atob(parseBase64Url(payload)));

  const requiredClaim = getEnvClaim(env).toLocaleLowerCase();

  // The user may not have any claims yet with base claim
  // Happens when they dont have an approved account in STS
  return (parsedPayload[BASE_CLAIM] || []).some(
    x => x.toLocaleLowerCase() === requiredClaim
  );
}

function getEnvClaim(env: Environment): string {
  switch (env) {
    case "Localhost":
    case "Develop":
    case "Staging": {
      return "munters.genesys;CanUseDevelopment;true";
    }
    case "Production": {
      return "munters.genesys;Login;true";
    }
    default: {
      return exhaustiveCheck(env);
    }
  }
}

async function getNatsJwt(accessToken: string): Promise<string | undefined> {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const result = await fetch("/nats-token", { headers });
  if (result.ok) {
    return result.text();
  }
  console.error(await result.text());
  return undefined;
}

function isLoginCallbackRequest(location: Window["location"]): boolean {
  return (
    location.hash.indexOf("id_token") > -1 ||
    location.pathname.startsWith("/signinoidc")
  );
}

function parseBase64Url(input: string) {
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, "+").replace(/_/g, "/");

  // Pad out with standard base64 required padding characters
  let pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        "InvalidLengthError: Input base64url string is the wrong length to determine padding"
      );
    }
    input += new Array(5 - pad).join("=");
  }

  return input;
}
