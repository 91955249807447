import styled from "styled-components";

// tslint:disable-next-line
export const ManagerSearchResultPagingText = styled.span`
  span {
    font-weight: bold;
  }

  margin-right: 10px;
`;
