import * as React from "react";
import * as GraphQL from "graphql";
import * as GraphQLRequest from "@genesys/graphql-request";
import * as Contexts from "../contexts";

interface Props<TResponse, TVariables> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly beforeDownload: (response: TResponse) => Object;
  readonly filename: string;
  readonly children: (onClick: () => void) => JSX.Element;
  readonly onComplete: () => void;
}

interface State {
  readonly fileInfo:
    | {
        readonly blob: Blob;
        readonly fileUrl: string;
      }
    | undefined;
}

// tslint:disable-next-line:function-name
export function GraphQLDocumentFetcher<TResponse, TVariables>(
  props: Props<TResponse, TVariables>
) {
  return (
    <Contexts.clientConfigContext.Consumer>
      {config => (
        <InternalComponent {...props} clientConfigContextValue={config} />
      )}
    </Contexts.clientConfigContext.Consumer>
  );
}

class InternalComponent<TResponse, TVariables> extends React.Component<
  Props<TResponse, TVariables> & {
    readonly clientConfigContextValue: Contexts.ClientConfigContextValue;
  },
  State
> {
  // tslint:disable-next-line
  anchorElement: HTMLAnchorElement | null;

  constructor(
    props: Props<TResponse, TVariables> & {
      readonly clientConfigContextValue: Contexts.ClientConfigContextValue;
    }
  ) {
    super(props);

    this.state = { fileInfo: undefined };
  }

  componentDidUpdate() {
    if (!this.state.fileInfo) {
      return;
    }

    this.download();
    this.props.onComplete();
  }

  componentWillReceiveProps(_props: Props<TResponse, TVariables>) {
    this.setState({ fileInfo: undefined });
  }

  generate() {
    GraphQLRequest.request<TResponse, TVariables>(
      this.props.query,
      this.props.variables,
      false,
      this.props.clientConfigContextValue.graphqlEndpoint,
      this.props.clientConfigContextValue.authorization
    ).then(response =>
      this.generateBlob(
        new Blob([JSON.stringify(this.props.beforeDownload(response))])
      )
    );
  }

  download(): void {
    if (!!(window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(
        this.state.fileInfo!.blob,
        this.props.filename
      );
      return;
    }

    this.anchorElement!.click();
    this.setState({
      ...this.state,
      fileInfo: undefined
    });
  }

  generateBlob(blob: Blob) {
    const url = createUrl(blob);
    this.setState({
      ...this.state,
      fileInfo: {
        ...this.state.fileInfo,
        blob: blob,
        fileUrl: url
      }
    });
  }

  render() {
    return (
      <>
        {this.props.children(() => this.generate())}
        {this.state.fileInfo && (
          <a
            style={{ display: "none" }}
            ref={el => {
              this.anchorElement = el;
            }}
            href={this.state.fileInfo.fileUrl}
            download={this.props.filename}
          />
        )}
      </>
    );
  }
}

function createUrl(blob: Blob): string {
  return URL.createObjectURL(blob);
}
