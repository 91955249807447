import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "../language-texts";
import { removeWhitespaces } from "./remove-white-spaces";

const productCodePropertyName = "productcode";
const rangeTypePropertyName = "rangetype";

export function getText(
  translate: LanguageTexts.Translate,
  sysComponentProperties: string | undefined,
  systemTypeId: string
): string {
  const sysProps =
    sysComponentProperties !== undefined
      ? PropertyValueSet.fromString(sysComponentProperties)
      : PropertyValueSet.Empty;

  const productCodeText = removeWhitespaces(
    PropertyValueSet.getText(productCodePropertyName, sysProps) || ""
  );

  const rangeType = PropertyValueSet.getInteger(
    rangeTypePropertyName,
    sysProps
  );

  const rangeTypeText = removeWhitespaces(
    translate(
      rangeType !== undefined
        ? LanguageTexts.systemTypePropertyValue(
            systemTypeId,
            rangeTypePropertyName,
            rangeType
          )
        : LanguageTexts.pNull()
    )
  );

  return [rangeTypeText, productCodeText].join(", ");
}
