import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../../../../shared-state";
import gql from "graphql-tag";
import { Cmd } from "@typescript-tea/core";
import * as GraphQlTypes from "../../../.../../../graphql-types";
import * as System from "../../../.../../system";
import { Quantity, Unit } from "@genesys/uom";
import * as Notes from "../../../../notes";
import { ConfiguratorAction } from "../../../shell-system-configurator/configurator-actions";
import { CaseFilter } from "../../../types";

const resultSummmaryQuery = gql`
  query ResultSummmaryQuery(
    $productInput: GetProductInputType!
    $systemTypeInput: GetSystemTypeInputType!
  ) {
    product {
      systemType(input: $systemTypeInput) {
        id
        systemTypeResults {
          id
          resultName
          quantityConversionParams
        }
      }

      product(input: $productInput) {
        id
        showAlternativesFilter

        screen_OpCaseResults: productDataTable(
          tableName: "screen_OpCaseResults"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }

        images {
          id
          url
          name
          imageUsage
          propertyFilter
          imageFormat
        }

        imageDataMappings {
          sortNo
          propertyFilter
          type
          x
          y
          valueSource
          valueSourceParameter
          claimFilter
        }
        screen_ResultSummary: productDataTable(
          tableName: "screen_ResultSummary"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }

        properties {
          id
          quantityConversionParams
          name
        }
        screen_ResultSummary_CaseOrder: productDataTable(
          tableName: "screen_ResultSummary_CaseOrder"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
        screen_Specifications: productDataTable(
          tableName: "screen_Specifications"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
        report_TechnicalData_OpCase: productDataTable(
          tableName: "report_TechnicalData_OpCase"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
        caseFilter: productDataTable(tableName: "caseFilter") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;

export type State = {
  readonly summaryData: GraphQlTypes.ResultSummmaryQuery | undefined;
  readonly selectedCasedResultId: string | undefined;
  readonly notesState: Notes.State;
  readonly systemTypeCaseFilter: ReadonlyArray<CaseFilter>;
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string,
  systemId: string,
  systemStatus: number,
  component: System.Component,
  systemTypeCaseFilter: ReadonlyArray<CaseFilter>
): [State, Cmd<Action>] => {
  const [notesState, notesCmd] = Notes.init(systemId, systemStatus, component);

  return [
    {
      summaryData: undefined,
      selectedCasedResultId: undefined,
      notesState: notesState,
      systemTypeCaseFilter: systemTypeCaseFilter
    },

    Cmd.batch<Action>([
      sharedState.graphQL.queryProductCmd<
        GraphQlTypes.ResultSummmaryQuery,
        GraphQlTypes.ResultSummmaryQueryVariables,
        Action
      >(
        resultSummmaryQuery,
        {
          productInput: {
            productId: component.productId
          },
          systemTypeInput: {
            systemTypeId
          }
        },
        Action.resultSummmaryQuery
      ),
      Cmd.map(Action.dispatchNotes, notesCmd)
    ])
  ];
};

export const Action = ctorsUnion({
  resultSummmaryQuery: (data: GraphQlTypes.ResultSummmaryQuery) => ({
    data
  }),
  selectCaseResultId: (id: string) => ({ id }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  dispatchNotes: (action: Notes.Action) => ({
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, SharedState.Action?] {
  switch (action.type) {
    case "resultSummmaryQuery":
      return [
        {
          ...state,
          summaryData: action.data
        }
      ];
    case "selectCaseResultId":
      return [
        {
          ...state,
          selectedCasedResultId: action.id
        }
      ];
    case "onFormatChanged": {
      return [
        state,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    case "dispatchNotes": {
      const [notesState] = Notes.update(action.action, state.notesState);
      return [
        {
          ...state,
          notesState
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getDetailsConfigurationActions(
  state: State
): ConfiguratorAction | undefined {
  return Notes.getNotesConfigurationActions(state.notesState);
}
