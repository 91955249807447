import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createNotesIconSvg(color: string): string {
  const svgString = `
    <svg width="9px" height="12px" viewBox="0 0 9 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>919F2251-1D50-45E5-901F-D12B6EAF89B2</title>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-988.000000, -762.000000)" fill="${color}">
            <g id="Group-4-Copy-3" transform="translate(687.000000, 758.000000)">
                <path d="M307.026625,7.6 C306.805,7.6 306.625,7.3714 306.625,7.09 L306.625,5.1868 L308.729875,7.6 L307.026625,7.6 Z M307.1875,13.6 L303.8125,13.6 C303.502,13.6 303.25,13.3312 303.25,13 C303.25,12.6688 303.502,12.4 303.8125,12.4 L307.1875,12.4 C307.498562,12.4 307.75,12.6688 307.75,13 C307.75,13.3312 307.498562,13.6 307.1875,13.6 L307.1875,13.6 Z M303.8125,10 L305.5,10 C305.8105,10 306.0625,10.2688 306.0625,10.6 C306.0625,10.9312 305.8105,11.2 305.5,11.2 L303.8125,11.2 C303.502,11.2 303.25,10.9312 303.25,10.6 C303.25,10.2688 303.502,10 303.8125,10 L303.8125,10 Z M309.85375,7.1968 L307.354,4.1968 C307.247687,4.0714 307.096375,4 306.93775,4 L302.43775,4 C301.645188,4 301,4.6732 301,5.5 L301,14.5 C301,15.3268 301.645188,16 302.43775,16 L308.56225,16 C309.355375,16 310,15.3268 310,14.5 L310,7.6 C310,7.4506 309.947688,7.3072 309.85375,7.1968 L309.85375,7.1968 Z" id="Icons/System-Component-Options/Notes"></path>
            </g>
        </g>
    </g>
</svg>
    
    `;
  return encodeSvgStringToImgSrc(svgString);
}
