import styled from "styled-components";
import { zIndex } from "@genesys/ui-elements";
import { Img, PrimaryColors, S1 } from "@genesys/ui-elements";

export const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 299px;
  width: 252px;
  border: 2px solid #f0f0f0;
  border-radius: 9px;
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  :hover {
    border: 2px solid #3d3d3d;
  }
`;

export const ErrorCard = styled(Root)`
  background-color: ${PrimaryColors.lightRed};
  cursor: pointer;
  justify-content: center;
`;

export const ErrorTextContainer = styled.div`
  padding-top: 4px;
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: white;
  height: 10%;
  padding-top: 4px;
  div {
    cursor: pointer;
  }
  div:first-child {
    margin-right: 7px;
  }
  div:last-child {
    margin-right: 2px;
  }
`;

export const SystemContainer = styled.div`
  height: 75%;
  cursor: pointer;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 0 10px 0;
  height: 115px;
`;

export const TextContainer = styled.div`
  width: 85%;
  margin: auto;
  P {
    width: 16em;
    word-break: break-all;
    white-space: normal;
  }
`;

export const RevisionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  width: 100%;
  height: 15%;
  padding: 0 20px;

  border-radius: 0 0 9px 9px;
  background-color: ${PrimaryColors.graphicsLight};
`;

export const RevisionOptionsContainer = styled.div`
  max-height: 80px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SystemImage = styled(Img)`
  height: 90px;
  display: block;
  margin: auto;
  padding-top: 10px;
  object-fit: contain;
`;

export const RevisionsDropDownButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 34px;
  padding: 5px;
  cursor: pointer;
  :hover {
    border-radius: 20px;
    background: ${PrimaryColors.blue25};
  }
`;

export const RevisionsDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 265px;
  background-color: #ffffff;

  padding: 10px 0;

  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);

  z-index: ${zIndex.Dropdown};
  position: absolute;
  right: -9px;
  bottom: 40px;

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    padding: 0 10px;
    margin: 0;
    background-color: inherit;
    cursor: pointer;

    span {
      max-width: 170px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

export const SystemActionsContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  position: fixed;
  left: ${(props: { readonly x: number; readonly y: number }) =>
    props.x + "px"};
  top: ${(props: { readonly x: number; readonly y: number }) =>
    props.y + 20 + "px"};
  background-color: #ffffff;
`;

export const SkeletonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 299px;
  width: 252px;
  border: 2px solid #f0f0f0;
  border-radius: 9px;
  background-color: ${PrimaryColors.sand100};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  :hover {
    border: 2px solid #3d3d3d;
  }
`;

export const SkeletonIconsContainer = styled.div`
  height: 30px;
  width: 248px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const SkeletonSystemImage = styled.div`
  height: 114px;
  width: 161px;
  display: block;
  margin: auto;
  object-fit: contain;
`;

export const SystemsLabel = styled(S1)`
  font-size: 15.5px;
`;
