import * as GraphQL from "graphql";
import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";

interface ReadFromCacheResult {
  readonly partial: boolean;
  readonly stale: any;
  readonly data: any;
}

export function readFromCache(
  entities: GraphQLEntityCache.EntityCache,
  queryWithRequiredFields: GraphQL.DocumentNode,
  variables: { readonly [key: string]: any },
  environment: string
): ReadFromCacheResult {
  const staleEntities = GraphQLMutationsQueue.getStaleEntities(entities);
  const { partial, response, stale } = GraphQLEntityCache.denormalize(
    queryWithRequiredFields,
    variables,
    entities,
    staleEntities
  );

  if (stale && environment === "localhost") {
    const operation = queryWithRequiredFields.definitions.find(
      d => d.kind === "OperationDefinition"
    ) as GraphQL.OperationDefinitionNode;
    console.log(`${operation.name && operation.name.value}:`, staleEntities);
  }

  return {
    partial,
    data: response && response.data,
    stale
  };
}
