import { exhaustiveCheck } from "ts-exhaustive-check";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Amount, Units } from "@genesys/uom";
import { Cmd } from "@typescript-tea/core";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as LocationSelectorGen2 from "../../../location-selector";
import * as GraphQlTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as System from "../../system";
import * as EmissionSelectionFunctions from "./emissions-selection/functions";
import * as CostSelection from "./cost-selection";
import * as EmissionsSelection from "./emissions-selection";
import * as BinGeneration from "./bin-generation";
import { initializeState } from "./functions";
import { productQuery } from "./queries";
import * as Result from "./results";
import { TabType } from "./types";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";

export type State = {
  readonly productData: GraphQlTypes.EnergyInputQueryProduct | undefined;
  readonly locationSelectorState: LocationSelectorGen2.State | undefined;
  readonly binGenerationState: BinGeneration.State | undefined;
  readonly costSelectionState: CostSelection.State | undefined;
  readonly emissionsSelectionState: EmissionsSelection.State | undefined;
  readonly resultState: Result.State | undefined;
  readonly shouldCalculateEnergy: boolean;
  readonly initialShouldCalculateEnergy: boolean;
  readonly activeTab: TabType;
  readonly energyProduct: Product.Product | undefined;
};

export const init = (
  system: System.System,
  sharedState: SharedState.State,
  energyProduct: Product.Product | undefined
): [State, Cmd<Action>?] => {
  const systemTypeId = system.file.systemTypeId;

  const shouldCalculateEnergy = system.operatingCases.some(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );

  return [
    {
      productData: undefined,
      locationSelectorState: undefined,
      binGenerationState: undefined,
      costSelectionState: undefined,
      emissionsSelectionState: undefined,
      resultState: undefined,
      initialShouldCalculateEnergy: shouldCalculateEnergy,
      shouldCalculateEnergy: shouldCalculateEnergy,
      activeTab: "bin-generation",
      energyProduct: energyProduct
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.EnergyInputQueryProduct,
      GraphQlTypes.EnergyInputQueryProductVariables,
      Action
    >(
      productQuery,
      {
        systemType: {
          systemTypeId
        }
      },
      Action.productQueryReceived
    )
  ];
};

export const Action = ctorsUnion({
  productQueryReceived: (data: GraphQlTypes.EnergyInputQueryProduct) => ({
    data
  }),
  dispatchLocationSelector: (action: LocationSelectorGen2.Action) => ({
    action
  }),
  dispatchBinGeneration: (action: BinGeneration.Action) => ({ action }),
  dispatchCostSelection: (action: CostSelection.Action) => ({
    action
  }),
  dispatchEmissionsSelection: (action: EmissionsSelection.Action) => ({
    action
  }),
  dispatchResult: (action: Result.Action) => ({ action }),
  toggleCalculateEnergy: () => ({}),
  setActiveTab: (tabType: TabType) => ({ tabType })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "productQueryReceived": {
      if (
        !state.shouldCalculateEnergy &&
        system.status === SystemStatusEnum.SystemStatus.LockSuccess
      ) {
        return [state];
      }

      return initializeState(
        sharedState,
        action.data,
        system,
        state.energyProduct,
        state.initialShouldCalculateEnergy
      );
    }
    case "dispatchLocationSelector": {
      if (
        !state.locationSelectorState ||
        !state.binGenerationState ||
        !state.emissionsSelectionState ||
        !state.productData
      ) {
        return [state];
      }

      const [newLocationSelectorState, newBinLocationId] =
        LocationSelectorGen2.update(
          action.action,
          state.locationSelectorState,
          sharedState
        );

      const locationName =
        state.productData.product.binDataLocations.find(
          l => l.binDataLocationId === newBinLocationId
        )?.locationName ?? "";

      const newBinSelections = PropertyValueSet.setText(
        KnownProperties.binDataLocationId,
        newBinLocationId,
        PropertyValueSet.setText(
          KnownProperties.binLocation,
          locationName,
          BinGeneration.getBinSelections(state.binGenerationState) ??
            PropertyValueSet.Empty
        )
      );

      if (
        newLocationSelectorState.selectedCountryIndex ===
        state.locationSelectorState.selectedCountryIndex
      ) {
        return [
          {
            ...state,
            locationSelectorState: newLocationSelectorState,
            binGenerationState: {
              ...state.binGenerationState,
              binCases: [],
              binSelections: newBinSelections
            }
          }
        ];
      } else {
        const binCountry =
          newLocationSelectorState.countries[
            newLocationSelectorState.selectedCountryIndex
          ].countryName;

        const defaultElectricEmissionFactor =
          EmissionSelectionFunctions.getDefaultElectricEmissionFactor(
            binCountry,
            state.productData.product.co2EmissionIndexes
          );

        const energyEmissions = PropertyValueSet.set(
          KnownProperties.CO2EmissionElectric,
          PropertyValue.fromAmount(
            Amount.create(
              defaultElectricEmissionFactor,
              Units.GramPerKiloWattHour
            )
          ),
          EmissionsSelection.getEnergyEmissionsSettings(
            state.emissionsSelectionState
          )
        );
        return [
          {
            ...state,
            locationSelectorState: newLocationSelectorState,
            binGenerationState: {
              ...state.binGenerationState,
              binCases: [],
              binSelections: newBinSelections
            },
            emissionsSelectionState: {
              ...state.emissionsSelectionState,
              emissionsSelectorState: {
                ...state.emissionsSelectionState.emissionsSelectorState,
                properties: energyEmissions
              }
            }
          }
        ];
      }
    }
    case "dispatchBinGeneration": {
      if (state.binGenerationState === undefined) {
        return [state];
      }

      const [binGenerationState, binGenerationCmd, sharedStateAction] =
        BinGeneration.update(
          action.action,
          state.binGenerationState,
          sharedState,
          system
        );

      return [
        {
          ...state,
          binGenerationState: binGenerationState
        },
        Cmd.map(Action.dispatchBinGeneration, binGenerationCmd),
        sharedStateAction
      ];
    }
    case "dispatchCostSelection": {
      if (state.costSelectionState === undefined) {
        return [state];
      }

      const [costSelectionsState, costSelectionsCmd, sharedStateAction] =
        CostSelection.update(action.action, state.costSelectionState, system);

      return [
        {
          ...state,
          costSelectionState: costSelectionsState
        },
        Cmd.map(Action.dispatchCostSelection, costSelectionsCmd),
        sharedStateAction
      ];
    }
    case "dispatchEmissionsSelection": {
      if (state.emissionsSelectionState === undefined) {
        return [state];
      }

      const [
        emissionsSelectionsState,
        emissionsSelectionsCmd,
        sharedStateAction
      ] = EmissionsSelection.update(
        action.action,
        state.emissionsSelectionState,
        sharedState
      );

      return [
        {
          ...state,
          emissionsSelectionState: emissionsSelectionsState
        },
        Cmd.map(Action.dispatchEmissionsSelection, emissionsSelectionsCmd),
        sharedStateAction
      ];
    }
    case "dispatchResult": {
      const [resultState, resultCmd, sharedStateAction] = Result.update(
        action.action,
        state.resultState
      );
      return [
        {
          ...state,
          resultState
        },
        Cmd.map(Action.dispatchResult, resultCmd),
        sharedStateAction
      ];
    }
    case "toggleCalculateEnergy": {
      const shouldCalculateEnergy = !state.shouldCalculateEnergy;

      if (shouldCalculateEnergy && state.productData) {
        return initializeState(
          sharedState,
          state.productData,
          system,
          state.energyProduct,
          state.initialShouldCalculateEnergy
        );
      } else {
        return [
          {
            ...state,
            binGenerationState: undefined,
            costSelectionState: undefined,
            emissionsSelectionState: undefined,
            shouldCalculateEnergy: false
          }
        ];
      }
    }
    case "setActiveTab": {
      if (action.tabType === "results" && state.resultState === undefined) {
        const [resultState, resultCmd] = Result.init(sharedState, system);

        return [
          {
            ...state,
            activeTab: action.tabType,
            resultState: resultState
          },
          Cmd.map(Action.dispatchResult, resultCmd)
        ];
      }

      return [
        {
          ...state,
          activeTab: action.tabType
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
