import * as Download from "./download";

interface XlsxMultiSheetsExportProps {
  readonly sheets: ReadonlyArray<Download.WorkSheet>;
  readonly filename: string;
  readonly children: (download: () => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function XlsxMultiSheetsExport(props: XlsxMultiSheetsExportProps) {
  return props.children(() => Download.download(props.sheets, props.filename));
}
