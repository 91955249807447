import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { promiseCmd } from "../promise-effect-manager";
import { getSrc } from "./functions";
import { Props } from "./types";

export type State = {
  readonly src: string | undefined;
};

export const init = (props: Props): [State, Cmd<Action>?] => {
  return [
    { src: undefined },
    promiseCmd<Action, string>(async () => {
      const src = await getSrc(props);
      return src;
    }, Action.setSource)
  ];
};

export const Action = ctorsUnion({
  setSource: (src: string | undefined) => ({ src }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "setSource": {
      return [{ ...state, src: action.src }];
    }
    case "dummy": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
