import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { Action } from "../state";
import { updateSystemNonBlockingMutation } from "../update-system-mutation";

export function calculateEnergy(
  sharedState: SharedState.State,
  systemId: string
): [Cmd<Action>, SystemStatus | undefined] {
  return [
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.UpdateSystemNonBlocking,
      GraphQLTypes.UpdateSystemNonBlockingVariables,
      Action
    >(
      updateSystemNonBlockingMutation,
      {
        input: {
          forceUpdate: false,
          systemId,
          targetStatus: SystemStatus.EnergyCalculationSuccess
        }
      },
      data =>
        Action.updateSystemNonBlockingStarted(
          data.updateSystemNonBlocking.traceId || undefined
        )
    ),
    SystemStatus.EnergyCalculationSuccess
  ];
}
