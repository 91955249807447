import * as React from "react";
import * as Redux from "redux";
import * as GraphQL from "graphql";
import fastDeepEqual from "fast-deep-equal";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";
import * as GraphQLAddRemoveFields from "graphql-add-remove-fields";
import * as Elements from "../elements";
import * as Contexts from "../contexts";
import * as ClientConfig from "../client-config";
import * as DebugSettings from "../debug-settings";
import * as GlobalActions from "../global-actions";
import { updateFromServer } from "./update-from-server";
import {
  createResultProps,
  isDirty,
  PartialResultProps,
  ResultProps
} from "./result-props";
import { readFromCache } from "./read-from-cache";

interface State<TResult extends {}, TVariables extends {}> {
  readonly queryWithRequiredFields: GraphQL.DocumentNode;
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly timestamp?: number;
  readonly entitiesWithNoOptimistic: GraphQLEntityCache.EntityCache | undefined;
  readonly entitiesWithOptimistic: GraphQLEntityCache.EntityCache | undefined;
  readonly mutationsQueue:
    | GraphQLMutationsQueue.MutationsQueueState
    | undefined;
  readonly result: ResultProps<TResult>;
}

export interface Props<TResult extends {}, TVariables extends {}> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly timestamp?: number;
  readonly children: (
    props: ResultProps<TResult>,
    refetch: () => void
  ) => JSX.Element | null;
  readonly mutationsQueue?: GraphQLMutationsQueue.MutationsQueueState;
  readonly modalOnError?: boolean;
}

// tslint:disable-next-line:function-name
export function QueryUser<
  TResult extends GraphQLEntityCache.RootFields = {},
  TVariables extends {} = {}
>(props: Props<TResult, TVariables>) {
  const { modalOnError = false } = props;
  return (
    <ClientConfig.ClientConfigConsumer>
      {config => (
        <DebugSettings.DebugSettingsConsumer>
          {({ includeServerLog }) => (
            <Contexts.dispatchContext.Consumer>
              {dispatch => (
                <Contexts.UserEntitiesContext.Consumer>
                  {userEntitiesContext => (
                    <QueryUserInternal<TResult, TVariables>
                      {...props}
                      modalOnError={modalOnError}
                      dispatch={dispatch}
                      userEntitiesContext={userEntitiesContext}
                      includeServerLog={includeServerLog}
                      clientConfigContext={config}
                    />
                  )}
                </Contexts.UserEntitiesContext.Consumer>
              )}
            </Contexts.dispatchContext.Consumer>
          )}
        </DebugSettings.DebugSettingsConsumer>
      )}
    </ClientConfig.ClientConfigConsumer>
  );
}

export const emptyResultProps: PartialResultProps = {
  type: "PartialResultProps"
};

type InternalProps<TResult extends {}, TVariables extends {}> = Props<
  TResult,
  TVariables
> & {
  readonly modalOnError: boolean;
  readonly dispatch: Redux.Dispatch<GlobalActions.Error>;
  readonly userEntitiesContext: Contexts.UserEntitiesContextValue;
  readonly includeServerLog: boolean;
  readonly clientConfigContext: Contexts.ClientConfigContextValue;
};

class QueryUserInternal<
  TResult extends GraphQLEntityCache.RootFields = {},
  TVariables extends {} = {}
> extends React.Component<
  InternalProps<TResult, TVariables>,
  State<TResult, TVariables>
> {
  constructor(props: InternalProps<TResult, TVariables>) {
    super(props);
    this.state = {
      entitiesWithNoOptimistic: undefined,
      entitiesWithOptimistic: undefined,
      queryWithRequiredFields: GraphQLAddRemoveFields.addFields(props.query, [
        "__typename"
      ]),
      query: props.query,
      variables: props.variables,
      timestamp: undefined,
      mutationsQueue: undefined,
      result: emptyResultProps
    };
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    nextProps: Readonly<InternalProps<any, any>>,
    prevState: State<any, any>
  ): Partial<State<any, any>> {
    if (prevState.query !== nextProps.query) {
      throw new Error("Changing query is not supported");
    }
    // console.log("getDerivedStateFromProps");
    const variablesChanged = !fastDeepEqual(
      nextProps.variables,
      prevState.variables
    );

    if (
      nextProps.userEntitiesContext.entities !==
        prevState.entitiesWithNoOptimistic ||
      variablesChanged ||
      nextProps.mutationsQueue !== prevState.mutationsQueue ||
      nextProps.query !== prevState.query
    ) {
      // console.log("getDerivedStateFromProps variables or entities changed");
      const entitiesWithNoOptimistic = nextProps.userEntitiesContext.entities;
      const entitiesWithOptimistic =
        nextProps.mutationsQueue &&
        nextProps.mutationsQueue.queuedMutations.length > 0
          ? GraphQLMutationsQueue.optimisticCache(
              nextProps.mutationsQueue,
              entitiesWithNoOptimistic
            )
          : entitiesWithNoOptimistic;

      const variables = variablesChanged
        ? nextProps.variables
        : prevState.variables;

      const { partial, data, stale } = readFromCache(
        entitiesWithOptimistic,
        prevState.queryWithRequiredFields,
        nextProps.variables,
        nextProps.clientConfigContext.environment
      );

      const newState: Partial<State<any, any>> = {
        entitiesWithNoOptimistic,
        entitiesWithOptimistic,
        variables,
        result: createResultProps(
          partial,
          stale,
          data,
          entitiesWithNoOptimistic
        ),
        timestamp: nextProps.timestamp
      };
      return newState;
    }

    return {
      timestamp: nextProps.timestamp
    };
  }

  componentDidMount() {
    // console.log("didmount");

    // Didmount cannot be queried more than once per instance
    if (isDirty(this.state.result)) {
      this.fetchFromServer();
    }
  }

  componentDidUpdate(
    _: Readonly<InternalProps<any, any>>,
    prevState: State<any, any>
  ) {
    // Check for force update
    if (prevState.timestamp !== this.state.timestamp) {
      this.refetch();
      return;
    }
    // DidUpdate can be queried multiple times therefore we check if any of our dependencies for request has changed
    if (
      shouldStartFetchFromServer(
        isDirty(this.state.result),
        prevState,
        this.state
      )
    ) {
      this.fetchFromServer();
    }
  }

  refetch = () => {
    this.setState({
      result: emptyResultProps
    });
    this.fetchFromServer();
  };

  private async fetchFromServer(): Promise<void> {
    const { queryWithRequiredFields, variables } = this.state;
    const result = await updateFromServer(
      queryWithRequiredFields!,
      variables,
      this.props.includeServerLog,
      this.props.clientConfigContext.graphqlEndpoint,
      this.props.clientConfigContext.authorization
    );

    switch (result.type) {
      case "UpdateFromServerResultSucess": {
        this.props.userEntitiesContext.mergeEntities(result.entities);
        break;
      }
      case "UpdateFromServerResultFailure": {
        this.setState({
          result: {
            type: "ErrorResultProps",
            error: result.error
          }
        });

        if (this.props.modalOnError) {
          this.props.dispatch(
            GlobalActions.error({
              referenceNo: "",
              invalidState: true,
              message: "Data fetching error",
              exception: result.error
            })
          );
        }
        break;
      }
      default: {
        exhaustiveCheck(result);
      }
    }
  }

  render() {
    return this.props.children(this.state.result, this.refetch);
  }
}

function shouldStartFetchFromServer(
  dirty: boolean,
  prevState: State<any, any>,
  state: State<any, any>
) {
  if (!dirty) {
    return false;
  }

  if (prevState.entitiesWithNoOptimistic !== state.entitiesWithNoOptimistic) {
    return true;
  }

  if (prevState.variables !== state.variables) {
    return true;
  }

  if (prevState.queryWithRequiredFields !== state.queryWithRequiredFields) {
    return true;
  }

  return false;
}

type SimpleChildrenFunc<TResult extends GraphQLEntityCache.RootFields> = (
  result: TResult,
  entities: GraphQLEntityCache.EntityCache,
  refetch: () => void
) => JSX.Element;

interface PropsSimple<TResult2 extends {}, TVariables extends {}> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly timestamp?: number;
  readonly children: SimpleChildrenFunc<TResult2>;
  readonly mutationsQueue?: GraphQLMutationsQueue.MutationsQueueState;
}

export class QueryUserSimple<
  TResult extends GraphQLEntityCache.RootFields,
  TVariables extends {} = {}
> extends React.Component<PropsSimple<TResult, TVariables>, {}> {
  render() {
    // const child = this.props.children;
    const { children: childFunc, ...props } = this.props;
    return (
      <QueryUser {...props} modalOnError={true}>
        {(resultProps, refetch) => {
          if (resultProps.type === "ErrorResultProps") {
            console.error(resultProps.error);
            return <div>Data fetching Error. See console</div>;
          }

          if (
            resultProps.type === "PartialResultProps" ||
            resultProps.type === "StaleResultProps"
          ) {
            return <Elements.Loader />;
          }

          return childFunc(
            resultProps.data as any,
            resultProps.entities,
            refetch
          );
        }}
      </QueryUser>
    );
  }
}
