export interface AxisPreset {
  readonly label: string;
  readonly axisType: "Line" | "SignedStackedArea";
  readonly results: ReadonlyArray<string>;
  readonly unitSi: string;
  readonly unitIp: string;
}

export interface ChartPreset {
  readonly name: string;
  readonly y1: AxisPreset;
  readonly y2: AxisPreset;
}

const moistureLoadsAndTime: ChartPreset = {
  name: "MoistureLoads",
  y1: {
    label: "MoistureLoads",
    axisType: "SignedStackedArea",
    results: [
      "mlcsystemmakeupopenmoistureload,Blue4",
      "mlcmoistureloademissionfromcombustion,Red2",
      "mlcmoistureloademissionfrompeople,Red3",
      "mlcmoistureloadevaporationfrommaterials,Yellow3",
      "mlcmoistureloadevaporationofwater,Yellow1",
      "mlcmoistureloadintentionalventilation,Blue3",
      "mlcmoistureloadunintendedventilation,Blue1",
      "mlcmoistureloadstaticloads,Green3",
      "mlcmoistureloadopendoor,Red4"
    ],
    unitSi: "KilogramPerHour",
    unitIp: "PoundLbPerHour"
  },
  y2: {
    label: "Time",
    axisType: "Line",
    results: ["mlctime,Black"],
    unitSi: "Hour",
    unitIp: "Hour"
  }
};

const heatLoadsAndTime: ChartPreset = {
  name: "HeatLoads",
  y1: {
    label: "HeatLoads",
    axisType: "SignedStackedArea",
    results: [
      "mlcsystemmakeupopenheatload,Blue4",
      "mlcheatloademissionfromcombustion,Red2",
      "mlcheatloademissionfrompeople,Red3",
      "mlcheatloadevaporationfrommaterials,Yellow3",
      "mlcheatloadevaporationofwater,Yellow1",
      "mlcheatloadintentionalventilation,Blue3",
      "mlcheatloadunintendedventilation,Blue1",
      "mlcheatloadheattransmission,Green1",
      "mlcheatloadstaticloads,Green3",
      "mlcheatloadopendoor,Red4"
    ],
    unitSi: "KiloWatt",
    unitIp: "KiloBtuPerHour"
  },
  y2: {
    label: "Time",
    axisType: "Line",
    results: ["mlctime,Black"],
    unitSi: "Hour",
    unitIp: "Hour"
  }
};

const totalMoistureLoadAndHeatLoads: ChartPreset = {
  name: "System moisture/heat loads",
  y1: {
    label: "System heatLoad",
    axisType: "Line",
    results: ["mlcsystemtotalheatload,Red1"],
    unitSi: "KiloWatt",
    unitIp: "KiloBtuPerHour"
  },
  y2: {
    label: "System moisture load",
    axisType: "Line",
    results: ["mlcsystemtotalmoistureload,Blue1"],
    unitSi: "KilogramPerHour",
    unitIp: "PoundLbPerHour"
  }
};

export const presets = {
  moistureLoadsAndTime,
  heatLoadsAndTime,
  totalMoistureLoadAndHeatLoads
};

export type Presets = keyof typeof presets;
