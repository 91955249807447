import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { updateComponentAccessoriesMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import { promiseCmd } from "../../../promise-effect-manager";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { tryRaiseSystemStatusToPriceCalculationSuccess } from "../calculate";

export function updateComponentAccessories(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<
    ConfiguratorAction,
    { readonly type: "updateComponentAccessories" }
  >
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        for (const a of action.accessory) {
          await sharedState.graphQL.queryUser<
            GraphQLTypes.UpdateComponentAccessories,
            GraphQLTypes.UpdateComponentAccessoriesVariables
          >(updateComponentAccessoriesMutation, {
            input: {
              systemId,
              parentComponentId: a.parentComponentId,
              parentComponentProperties: a.parentComponentProperties,
              accessoriesProperties: a.accessoriesProperties
            }
          });
        }
        const traceId = action.calculate
          ? await tryRaiseSystemStatusToPriceCalculationSuccess(
              sharedState.graphQL.queryUser,
              systemId
            )
          : undefined;
        return traceId;
      },
      traceId =>
        action.calculate
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculate
      ? [
          ...action.accessory.map(
            _ => SystemStatus.ValidateConfigurationSuccess
          ),
          SystemStatus.PriceCalculationSuccess
        ]
      : action.accessory.map(_ => SystemStatus.ValidateConfigurationSuccess)
  ];
}
