import * as React from "react";
import styled from "styled-components";
import { OperationTime, Checks, MutableChecks } from "./types";
import {
  monthRange,
  hourRange,
  allHoursDay,
  noHoursDay,
  allMonthsYear,
  noMonthsYear
} from "./presets";

export interface Props {
  readonly operationTime: OperationTime;
  readonly readonly: boolean;
  readonly onChange: (operationTime: OperationTime) => void;
}

type CheckGrid = { readonly [column: string]: Checks };
// tslint:disable-next-line:readonly-keyword
type MutableCheckGrid = { [column: string]: MutableChecks };

// tslint:disable-next-line:function-name
function TimeTable(props: {
  readonly className: string;
  readonly columns: ReadonlyArray<string>;
  readonly rows: ReadonlyArray<number>;
  readonly checkGrid: CheckGrid;
  readonly readonly: boolean;
  readonly onChange: (column: string, row: string, checked: boolean) => void;
}) {
  return (
    <table className={props.className}>
      <thead>
        <tr>
          {props.columns.map(column => (
            <th key={column}>
              <label>
                <input
                  type="checkbox"
                  checked={props.checkGrid[column]["all"]}
                  disabled={props.readonly}
                  onChange={event =>
                    props.onChange(column, "all", event.currentTarget.checked)
                  }
                />{" "}
                {column}
              </label>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rows.map(row => (
          <tr key={row}>
            {props.columns.map(column => (
              <td key={column}>
                <label>
                  <input
                    type="checkbox"
                    checked={props.checkGrid[column][row]}
                    disabled={props.readonly}
                    onChange={event =>
                      props.onChange(
                        column,
                        row.toString(),
                        event.currentTarget.checked
                      )
                    }
                  />{" "}
                  {row}
                </label>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// tslint:disable-next-line:variable-name
const Styling = styled.div`
  display: flex;
  flex-direction: row;

  > table {
    margin: 15px;
    > thead > tr {
      th {
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
      }
      td,
      th {
        width: 100px;
      }
    }
  }

  //   > .months {
  //   }
`;

function allRow(otherChecks: Checks) {
  return Object.keys(otherChecks).reduce(
    (soFar, currentRowIndex) => soFar && otherChecks[currentRowIndex],
    true
  );
}

// tslint:disable-next-line:function-name
export function TimeGridComponent(props: Props) {
  const dayNames = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];

  const allColumn = hourRange.reduce(
    (soFar, currentRow) => {
      soFar[currentRow] = dayNames.every(
        dayName =>
          props.operationTime[dayName as keyof OperationTime][currentRow]
      );
      return soFar;
    },
    {} as MutableChecks
  );

  return (
    <Styling>
      <TimeTable
        className="months"
        columns={["month"]}
        rows={monthRange}
        readonly={props.readonly}
        checkGrid={{
          month: {
            ...props.operationTime.months,
            all: allRow(props.operationTime.months)
          }
        }}
        onChange={(_column, row, checked) => {
          const checks = {
            ...props.operationTime
          };

          if (row === "all") {
            checks.months = checked ? allMonthsYear : noMonthsYear;
          } else {
            checks.months = {
              ...props.operationTime.months,
              [row]: checked
            };
          }

          props.onChange(checks as any);
        }}
      />
      <TimeTable
        className="hours"
        columns={["all", ...dayNames]}
        rows={hourRange}
        readonly={props.readonly}
        checkGrid={{
          ...dayNames.reduce(
            (soFar, currentDayName) => {
              const checks =
                props.operationTime[currentDayName as keyof OperationTime];
              soFar[currentDayName] = {
                ...checks,
                all: allRow(checks)
              };
              return soFar;
            },
            {} as MutableCheckGrid
          ),
          all: { ...allColumn, all: allRow(allColumn) }
        }}
        onChange={(column, row, checked) => {
          const checks = {
            ...props.operationTime
          } as MutableCheckGrid;

          if (column === "all") {
            if (row === "all") {
              dayNames.forEach(
                dayName =>
                  (checks[dayName] = checked ? allHoursDay : noHoursDay)
              );
            } else {
              dayNames.forEach(
                dayName =>
                  (checks[dayName] = {
                    ...checks[dayName],
                    [row]: checked
                  })
              );
            }
          } else if (row === "all") {
            checks[column] = checked ? allHoursDay : noHoursDay;
          } else {
            checks[column] = {
              ...checks[column],
              [row]: checked
            };
          }

          props.onChange(checks as any);
        }}
      />
    </Styling>
  );
}
