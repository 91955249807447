import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const PropertyNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 13px 13px 0 0;
`;

export const AirInOuterContainer = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    position: relative;
    top: 18px;
  }
`;

export const AirInInnerContainer = styled.div`
  display: flex;
`;

export const CalculateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 20px;
  button {
    margin: 5px 0;
  }
`;

export const AirInAndPowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin: 10px 0;
  }
`;

export const GreyDiv = styled.div`
  color: #959695;
`;
