import * as React from "react";
import * as GQLCachePatch from "gql-cache-patch";
import * as MutationsQueue from "@genesys/graphql-mutations-queue";

// tslint:disable-next-line:variable-name
export interface MutationsQueueContextValue {
  readonly queue: (
    queueId: string,
    queueMutationDescription: ReadonlyArray<
      MutationsQueue.QueueMutationDescription
    >
  ) => void;
  readonly deQueue: (
    queueId: string,
    queuedMutations: ReadonlyArray<MutationsQueue.QueuedMutation>
  ) => void;
  readonly flushQueue: (
    queueId: string
  ) => Promise<ReadonlyArray<MutationsQueue.SentMutationResult>>;
  readonly discardQueue: (queueId: string) => void;
  readonly mutationQueues: {
    readonly [key: string]: MutationsQueue.MutationsQueueState;
  };
  readonly applyPatches: (
    cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>
  ) => void;
}

// tslint:disable-next-line:variable-name
export const mutationsQueueContext = React.createContext<
  MutationsQueueContextValue
>({
  queue: () => {
    /*  */
  },
  deQueue: () => {
    /*  */
  },
  flushQueue: async () => {
    return [];
  },
  discardQueue: () => {
    /*  */
  },
  applyPatches: () => {
    /*  */
  },
  mutationQueues: {}
});
