import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton } from "@genesys/ui-elements";
import { LogsView } from "./logs-view";
import { TimersView } from "./timers-view";
import { Action, State } from "./state";
import {
  NavigationBox,
  RefreshButtonBox,
  Root,
  LoadedStateBox
} from "./elements";

export function View({
  state,
  dispatch
}: {
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <Root>
      <RefreshButtonBox>
        <StandardButton
          size="Large"
          buttonType="Primary-2"
          onClick={() => {
            dispatch(Action.RetrieveTelemetry());
          }}
        >
          {"Refresh"}
        </StandardButton>
      </RefreshButtonBox>
      {state.type === "NoSearchState" && <div>{"NoSearchState"}</div>}
      {state.type === "FetchingState" && <div>{"FetchingState"}</div>}
      {state.type === "LoadedState" && (
        <LoadedStateBox>
          <NavigationBox>
            <StandardButton
              size="Small"
              buttonType={state.view === "logs" ? "Primary" : "Secondary"}
              onClick={() => dispatch(Action.setView("logs"))}
            >
              {"Logs"}
            </StandardButton>
            <StandardButton
              size="Small"
              buttonType={state.view === "timers" ? "Primary" : "Secondary"}
              onClick={() => dispatch(Action.setView("timers"))}
            >
              {"Timers"}
            </StandardButton>
          </NavigationBox>

          {state.view === "logs" && (
            <LogsView state={state} dispatch={dispatch} />
          )}

          {state.view === "timers" && <TimersView state={state} />}
        </LoadedStateBox>
      )}
    </Root>
  );
}
