import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { OverlayLoader, H1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { DataCenterSizeSelectorView } from "../../../../size-selectors/data-center-size-selector";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";

const Root = styled.div``;

export function DataCenterSizeSelectorStepView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!state) {
    return <OverlayLoader />;
  }

  const validTemplateComponents = state.template.components.filter(tc =>
    PropertyFilter.isValid(state.newProperties, tc.propertyFilter)
  );

  const systemProperties = PropertyValueSet.merge(
    state.newProperties,
    validTemplateComponents.find(t => t.productId.endsWith("SYS"))
      ?.properties || PropertyValueSet.Empty
  );

  return (
    <Root>
      <H1 weight="normal">
        {sharedState.translate(LanguageTexts.selectSystemSize())}
      </H1>

      <DataCenterSizeSelectorView
        state={state.dataCenterSizeSelectorState}
        sharedState={sharedState}
        dispatch={Dispatch.map(
          Action.dispatchDataCenterSizeSelectorActions,
          dispatch
        )}
        systemProperties={systemProperties}
        operatingCases={state.operatingCases}
        systemTypeId={state.systemTypeId}
        isSystemLocked={false}
      />
    </Root>
  );
}
