import styled from "styled-components";

// tslint:disable-next-line
export const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  height: 33px;
`;
