import { exhaustiveCheck } from "ts-exhaustive-check";
import { SystemTypeId, SizeSelectorState, SizeSelectorAction } from "./types";
import * as SharedState from "../../../shared-state";
import * as DataCenterSizeSelector from "../../../size-selectors/data-center-size-selector";
import * as DhuSizeSelector from "../../../size-selectors/dhu-size-selector";
import * as HumSizeSelector from "../../../size-selectors/hum-size-selector";
import * as EccSizeSelector from "../../../size-selectors/ecc-size-selector";
import * as System from "../../system";
import { PropertyValueSet } from "@genesys/property";
import { Quantity } from "@genesys/uom";

export function initSizeSelector(
  sharedState: SharedState.State,
  system: System.System
) {
  const systemTypeId = system.file.systemTypeId as SystemTypeId;

  switch (systemTypeId) {
    case "FAA":
    case "FAU":
    case "HUM": {
      const size = PropertyValueSet.getInteger(
        "hummodel",
        system.components.find(c => c.productId.endsWith("FA6"))?.properties ||
          PropertyValueSet.Empty
      );
      return HumSizeSelector.init(sharedState, systemTypeId, size);
    }
    case "ECC": {
      const size = PropertyValueSet.getAmount<Quantity.Length>(
        "padheight",
        system.components.find(c => c.productId.endsWith("HDE"))?.properties ||
          PropertyValueSet.Empty
      );
      return EccSizeSelector.init(sharedState, size);
    }
    case "MLP":
    case "MXO":
    case "MXN": {
      return DhuSizeSelector.init(sharedState, systemTypeId);
    }
    case "DEB":
    case "DCX":
    case "DCA": {
      {
        return DataCenterSizeSelector.init(sharedState, systemTypeId);
      }
    }
    default:
      return exhaustiveCheck(systemTypeId, true);
  }
}

export function updateSizeSelector(
  systemTypeId: SystemTypeId,
  sizeSelectorAction: SizeSelectorAction,
  sizeSelectorState: SizeSelectorState
) {
  switch (systemTypeId) {
    case "FAA":
    case "FAU":
    case "HUM":
      return HumSizeSelector.update(
        sizeSelectorAction as HumSizeSelector.Action,
        sizeSelectorState as HumSizeSelector.State
      );
    case "ECC":
      return EccSizeSelector.update(
        sizeSelectorAction as EccSizeSelector.Action,
        sizeSelectorState as EccSizeSelector.State
      );
    case "MLP":
    case "MXO":
    case "MXN":
      return DhuSizeSelector.update(
        sizeSelectorAction as DhuSizeSelector.Action,
        sizeSelectorState as DhuSizeSelector.State
      );
    case "DEB":
    case "DCX":
    case "DCA": {
      return DataCenterSizeSelector.update(
        sizeSelectorAction as DataCenterSizeSelector.Action,
        sizeSelectorState as DataCenterSizeSelector.State
      );
    }
    default:
      return exhaustiveCheck(systemTypeId, true);
  }
}
