import React from "react";
import styled from "styled-components";
import { Label, InlineButton } from "@genesys/ui-elements";

// tslint:disable-next-line
const StyledTable = styled.table<{
  readonly alignHeader: "left" | "center" | "right";
  readonly alignRows: "left" | "center" | "right";
}>`
  letter-spacing: 0;
  font-size: 13px;

  border-collapse: collapse;
  z-index: 1;

  overflow: hidden;

  position: relative;
  td,
  th {
    padding: 3px;
  }
  /* td,
  th,
  .row,
  .col {
    cursor: pointer;
    padding: 10px;
    position: relative;
  } */

  th {
    text-align: ${props => props.alignHeader};
    vertical-align: top;
  }

  tr {
    td {
      color: #959695;
      font-size: 13px;
      line-height: 17px;
      text-align: ${props => props.alignRows};
      height: 23px;
      /* padding-left: 15px;
      padding-right: 10px; */
    }
  }

  /* td:hover::before,
  .row:hover::before {
    background-color: #ffa;
    content: "\00a0";
    height: 100%;
    left: -5000px;
    position: absolute;
    top: 0;
    width: 10000px;
    z-index: -1;
  }

  td:hover::after,
  .col:hover::after {
    background-color: #ffa;
    content: "\00a0";
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
    z-index: -1;
  } */
`;

const StyledTBody = styled.tbody`
  tr:nth-child(odd) {
    td {
      background-color: #f7f9fc;
    }
  }
`;

const StyledCol = styled.col`
  border: 2px solid black;
  /* background-color: lightgrey; */
`;

const StyledDiv = styled.div`
  span:nth-child(2) {
    white-space: nowrap;
  }
`;

const StyledLabel = styled(Label)<{
  readonly isHidden: boolean | undefined;
}>`
  ${props => props.isHidden && "border: 1px solid red;"}
`;

interface Row {
  readonly name?: React.ReactNode;
  readonly amountFormatSelector?: RenderAmountFormatSelector;
  readonly isBold?: boolean;
  readonly isHidden?: boolean;
  readonly results: ReadonlyArray<string>;
}

interface Props {
  readonly rows: ReadonlyArray<Row>;
  readonly alignHeader?: "left" | "center" | "right";
  readonly alignRows?: "left" | "center" | "right";
  readonly selectedFlowIndex: number;
  readonly flowOnChangeDisabled: boolean;
  readonly flowOnchange: (newId: number) => void;
  readonly headers?: ReadonlyArray<{
    readonly header: React.ReactNode;
    readonly visible: boolean;
    readonly amountFormatSelector?: RenderAmountFormatSelector;
  }>;
}

type RenderAmountFormatSelector = () => JSX.Element | null;

export function MoistureLoadReadOnlyTable({
  flowOnchange,
  flowOnChangeDisabled,
  headers,
  selectedFlowIndex,
  rows,
  alignHeader = "left",
  alignRows = "left"
}: Props) {
  const noOFColumuns = rows[0].results.length + 1;

  return (
    <StyledTable alignRows={alignRows} alignHeader={alignHeader}>
      <colgroup>
        {Array(noOFColumuns)
          .fill(1)
          .map((_x, i) => (i === selectedFlowIndex ? <StyledCol /> : <col />))}
      </colgroup>
      {headers && headers.length > 0 && (
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th
                className="col"
                key={i}
                style={{ visibility: header.visible ? "visible" : "hidden" }}
              >
                <StyledDiv>
                  <span> {header.header} </span>
                  <span>
                    {header.amountFormatSelector
                      ? header.amountFormatSelector()
                      : null}
                  </span>
                </StyledDiv>
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>
        <tr>
          {Array(noOFColumuns)
            .fill(1)
            .map((_x, i) => (
              <td>
                {i !== 0 && (
                  <InlineButton
                    disabled={selectedFlowIndex === i || flowOnChangeDisabled}
                    onClick={() => {
                      flowOnchange(i - 1);
                    }}
                  >
                    {selectedFlowIndex === i ? "Selected Flow" : "Use flow"}
                  </InlineButton>
                )}
              </td>
            ))}
        </tr>
      </tbody>

      <StyledTBody>
        {rows.map((row, i) => (
          <tr key={`${i}`}>
            {row.name && (
              <td>
                <StyledLabel
                  isHidden={row.isHidden}
                  color="light"
                  weight={row.isBold ? "bold" : "normal"}
                >
                  {row.name}
                  {row.amountFormatSelector ? row.amountFormatSelector() : null}
                </StyledLabel>
              </td>
            )}

            {row.results.map((result, i) => (
              <td key={`${result}${i}`}>
                <Label color="light" weight={row.isBold ? "bold" : "normal"}>
                  {result}
                </Label>
              </td>
            ))}
          </tr>
        ))}
      </StyledTBody>
    </StyledTable>
  );
}
