import * as GraphQlTypes from "../../../graphql-types";
import * as System from "../../system";
import * as ComponentOrder from "@genesys/shared/lib/component-order";
import * as Product from "../../product";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { ComponentPriceRow } from "./types";

export function getComponentOrder(
  system: System.System,
  connectionPoints: readonly Product.BoxConnectionPoint[]
): ReadonlyArray<string> {
  return ComponentOrder.getComponentOrder(
    system.components.map(c => ({
      id: c.id,
      productId: c.productId,
      accessoryToId: c.accessoryToId,
      properties: PropertyValueSet.toString(c.properties),
      sections: c.sections,
      sortNo: c.sortNo
    })),
    system.airstreams,
    connectionPoints
  );
}

export function getCurrencies(
  currencies: ReadonlyArray<{
    readonly id: string;
    readonly signAfter?: string;
    readonly signBefore?: string;
  }>,
  system: System.System
): {
  readonly currency: {
    readonly signAfter?: string;
    readonly signBefore?: string;
  };
  readonly masterCurrency: {
    readonly signAfter?: string;
    readonly signBefore?: string;
  };
} {
  const currency = currencies.find(c => c.id === system.currencyCode)!;
  const masterCurrency = currencies.find(
    c => c.id === system.masterCurrencyCode
  )!;

  return {
    currency: currency,
    masterCurrency: masterCurrency
  };
}

export function getVailidityText(
  systemUserData: GraphQlTypes.SystemHasPrice,
  sharedState: SharedState.State
) {
  const validityText = systemUserData.user.system.validityDate
    ? sharedState.translate(LanguageTexts.systemCalculationIsValidUntil()) +
      " " +
      DateHelper.getDateString(
        DateHelper.fromUTC(systemUserData.user.system.validityDate),
        sharedState.user.settings.locale
      )
    : undefined;

  return validityText;
}

export function getAuthorizations(
  claims: Authorization.ApplicationClaims,
  systemType: Product.SystemType,
  system: System.System
) {
  const filters = getFilters(system);

  const canSeeComponentPrice = Authorization.hasPermission(
    claims,
    Authorization.genesysUserClaims.showPartPrices,
    systemType.id
  );

  const canSeeProductCode = Authorization.checkPermission(
    claims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const canSeeRefCost = Authorization.checkClaimFilter(
    claims,
    filters.showReferenceCostFilter
  );

  const canSeeMarginCost = Authorization.checkClaimFilter(
    claims,
    filters.showMarginCostFilter
  );

  const canSeeTransferPrice = Authorization.checkClaimFilter(
    claims,
    filters.showTransferPriceFilter
  );

  const canSeeListPrice = Authorization.checkClaimFilter(
    claims,
    filters.showListPriceFilter
  );

  const canSeeConsolidatedMargin = Authorization.checkClaimFilter(
    claims,
    filters.showConsolitadedMarginFilter
  );

  const canSeeMargin = Authorization.checkClaimFilter(
    claims,
    filters.showMarginFilter
  );

  const canSeeMasterMarginCost = Authorization.checkPermission(
    claims,
    "canseemastermargincost"
  );

  const canSeeMasterTransferPrice = Authorization.checkPermission(
    claims,
    "canseemastertransferprice"
  );

  const canSeeMasterListPrice = Authorization.checkPermission(
    claims,
    "canseemasterlistprice"
  );

  const canSeeMCZPrice = Authorization.checkClaimFilter(
    claims,
    filters.showMCZPriceFilter
  );

  return {
    canSeeComponentPrice,
    canSeeProductCode,
    canSeeRefCost,
    canSeeMarginCost,
    canSeeTransferPrice,
    canSeeListPrice,
    canSeeConsolidatedMargin,
    canSeeMasterMarginCost,
    canSeeMasterTransferPrice,
    canSeeMasterListPrice,
    canSeeMargin,
    canSeeMCZPrice
  };
}

export function checkIfMasterSettings(
  systemType: Product.SystemType,
  sysProperties: PropertyValueSet.PropertyValueSet
) {
  const validScreen = systemType.screens
    .filter(s =>
      PropertyFilterHelpers.isValid(
        s.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
    .find(s => s.screenType === "ADJUST_PRICE");

  if (validScreen) {
    const screenParams = PropertyValueSet.fromString(
      validScreen.screenParameters
        ? validScreen.screenParameters.toLowerCase()
        : ""
    );
    const showMaster = !!PropertyValueSet.getInteger(
      "showmaster",
      screenParams
    );
    return showMaster;
  }
  return false;
}

export function getTotalPrice(tableComponents: Array<ComponentPriceRow>) {
  const totalBaseComponentsPrice = tableComponents
    .filter(c => !c.isAccessory)
    .reduce((a, b) => a + b.price, 0);
  const totalAccessoriesPrice = tableComponents
    .filter(c => c.isAccessory)
    .reduce((a, b) => a + b.price, 0);

  return {
    totalBaseComponentsPrice,
    totalAccessoriesPrice
  };
}

export function getProductsToExclude(
  systemType: Product.SystemType,
  sysProperties: PropertyValueSet.PropertyValueSet
) {
  const validScreen = systemType.screens
    .filter(s =>
      PropertyFilterHelpers.isValid(
        s.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
    .find(s => s.screenType === "ADJUST_PRICE");

  if (validScreen) {
    const screenParams = PropertyValueSet.fromString(
      validScreen.screenParameters
        ? validScreen.screenParameters.toLowerCase()
        : ""
    );

    const excludeList = PropertyValueSet.getText("exclude", screenParams);
    const productsToExclude = excludeList ? excludeList.split(",") : [];

    return productsToExclude;
  }

  return [];
}

export function getPriceTypeToDisplay(
  priceProductData: GraphQlTypes.PriceProduct,
  salesOrg: string | undefined
): string {
  const priceSetting =
    priceProductData.product.systemType.systemTypePriceSetting[0]; // Should always be exactly one row
  const specialDisplayOfPriceType = priceSetting?.displayException;
  if (!specialDisplayOfPriceType) {
    return "ListPrice";
  }

  // TransferPrice&MEAS,MUK,MUS
  const parts = specialDisplayOfPriceType.split("&");
  const listType = parts[0];
  const salesOrgs = parts[1].split(",");
  const matchingOrg = salesOrgs.filter(s => s === salesOrg || s === "EVERYONE");
  if (matchingOrg.length !== 0) {
    return listType;
  }

  return "ListPrice";
}

export function getTableComponents(
  baseComponents: ReadonlyArray<System.Component>,
  accessories: ReadonlyArray<System.Component>,
  systemUserData: GraphQlTypes.SystemHasPrice,
  translate: LanguageTexts.Translate,
  priceType: string,
  productsToExclude: ReadonlyArray<any>
) {
  return baseComponents
    .reduce(
      (a, b) =>
        a.concat(
          createTableComponent(
            b,
            accessories,
            systemUserData.user.system.systemComponentPrice,
            translate,
            "Market" + priceType
          )
        ),
      [] as Array<ComponentPriceRow>
    )
    .filter(
      c =>
        !productsToExclude.some(
          p => p === c.productId.substring(3).toLowerCase()
        )
    );
}

export function priceDictionary(
  componentPrices: ReadonlyArray<
    GraphQlTypes.SystemHasPrice["user"]["system"]["systemComponentPrice"][0]
  >,
  components: ReadonlyArray<System.Component>
): { readonly [key: string]: number } {
  return componentPrices.reduce(
    (a, b) => {
      const quantity = getComponentQuantity(components, b.componentId);
      const componentDic = b.priceRows.reduce(
        (aa, bb) => ({
          ...aa,
          [bb.priceType]: bb.value * quantity
        }),
        {} as { readonly [key: string]: number }
      );

      for (const key of Object.keys(componentDic)) {
        a[key] = (a[key] || 0) + componentDic[key];
      }

      return a;
    },
    // tslint:disable-next-line
    {} as { [key: string]: number }
  );
}

export function checkIfExpired(
  systemUserData: GraphQlTypes.SystemHasPrice,
  sharedState: SharedState.State
) {
  return systemUserData.user.system.validityDate
    ? new Date(
        DateHelper.getDateString(
          DateHelper.fromUTC(systemUserData.user.system.validityDate),
          sharedState.user.settings.locale
        )
      ) < new Date()
    : false;
}

function getComponentQuantity(
  components: ReadonlyArray<System.Component>,
  componentId: string
): number {
  const component = components.find(c => c.id === componentId)!;
  if (!component.accessoryToId) {
    return 1;
  }

  const parent = components.find(c => c.id === component.accessoryToId)!;
  const quantityAccordingToParent = PropertyValueSet.getInteger(
    "acc_" + component.productId.toLowerCase(),
    parent.properties
  );
  return quantityAccordingToParent !== undefined
    ? quantityAccordingToParent
    : 1;
}

function getFilters(system: System.System) {
  const showTransferPriceFilter = PropertyFilter.fromString(
    'salesorgshowtransferprice="' +
      system.salesOrganisationId +
      '"&systemtypeshowtransferprice="' +
      system.file.systemTypeId +
      '"'
  )!;

  const showListPriceFilter = PropertyFilter.fromString(
    'salesorgshowlistprice="' +
      system.salesOrganisationId +
      '"&systemtypeshowlistprice="' +
      system.file.systemTypeId +
      '"'
  )!;

  const showMarginCostFilter = PropertyFilter.fromString(
    'salesorgshowmargincost="' +
      system.salesOrganisationId +
      '"&systemtypeshowmargincost="' +
      system.file.systemTypeId +
      '"'
  )!;

  const showReferenceCostFilter = PropertyFilter.fromString(
    'salesorgshowreferencecost="' +
      system.salesOrganisationId +
      '"&systemtypeshowreferencecost="' +
      system.file.systemTypeId +
      '"'
  )!;
  const showConsolitadedMarginFilter = PropertyFilter.fromString(
    'salesorgshowconsolidatedmargin="' +
      system.salesOrganisationId +
      '"&systemtypeshowconsolidatedmargin="' +
      system.file.systemTypeId +
      '"'
  )!;

  const showMarginFilter = PropertyFilter.fromString(
    'salesorgshowmargin="' +
      system.salesOrganisationId +
      '"&systemtypeshowmargin="' +
      system.file.systemTypeId +
      '"'
  )!;

  const showMCZPriceFilter = PropertyFilter.fromString(
    'salesorgshowmczprice="' +
      system.salesOrganisationId +
      '"&systemtypeshowmczprice="' +
      system.file.systemTypeId +
      '"'
  )!;

  return {
    showTransferPriceFilter,
    showListPriceFilter,
    showMarginCostFilter,
    showReferenceCostFilter,
    showConsolitadedMarginFilter,
    showMarginFilter,
    showMCZPriceFilter
  };
}

function createTableComponent(
  component: System.Component,
  accessories: ReadonlyArray<System.Component>,
  componentPrices: ReadonlyArray<
    GraphQlTypes.SystemHasPrice["user"]["system"]["systemComponentPrice"][0]
  >,
  translate: LanguageTexts.Translate,
  priceType: string
): Array<ComponentPriceRow> {
  const listTypePrice = componentPrices
    .find(c => c.componentId === component.id)!
    .priceRows.find(r => r.priceType === priceType);
  const componentPrice = {
    id: component.id,
    description: getDescription(component, translate),
    productId: component.productId,
    quantity: 1,
    isAccessory: false,
    price: listTypePrice !== undefined ? listTypePrice.value : 0
  };
  const accessoryPrices = accessories
    .filter(a => a.accessoryToId === component.id)
    .map(a => {
      const quantity =
        PropertyValueSet.getInteger(
          "acc_" + a.productId.toLowerCase(),
          component.properties
        ) || 1;
      const listTypePrice = componentPrices
        .find(c => c.componentId === a.id)!
        .priceRows.find(r => r.priceType === priceType);
      const price = listTypePrice !== undefined ? listTypePrice.value : 0;
      return {
        id: a.id,
        description: "└ " + getDescription(a, translate),
        productId: a.productId,
        quantity: quantity,
        isAccessory: true,
        price: price * quantity
      };
    });

  return [componentPrice, ...accessoryPrices];
}

function getDescription(
  component: System.Component,
  translate: LanguageTexts.Translate
) {
  const label = PropertyValueSet.getText("label", component.properties);
  if (label) {
    return translate(
      LanguageTexts.curlyTranslate(label, component.productId.substring(0, 3))
    );
  }
  return translate(LanguageTexts.product(component.productId));
}
