import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import * as Types from "./types";
import * as GraphQlTypes from "../../../graphql-types";
import * as Queries from "./queries";
import { BaseState, DivergentTemplateComponentMap } from "../step-state-base";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import { promiseCmd } from "../../../promise-effect-manager";
import * as OperatingCaseSelector from "../../../operating-case-selector";
import {
  getEffectiveTemplateComponentsProperties,
  parseProduct,
  parseOpcPreProcessRows,
  createPreProcessedOpcs
} from "./functions";
import {
  getDefaultClimateSettings,
  defaultWmo,
  dataPointsQuery
} from "../../../climate-selector/";
import { isValid } from "@genesys/shared/lib/property-filter-helpers";
import {
  convertMoistureLoadToOPC,
  MlOpcaseResult,
  mapQueryResultToOpc,
  testMoistureLoadNo,
  getMoistureLoadNoAndRevNo
} from "../../../moisture-load-calculation";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [Types.State, Cmd<Action>?] => {
  return [
    undefined,
    promiseCmd(
      async () => {
        // Fetches the datapoints first before the other queries to ensure not null datapoints
        return getInitialData(base.systemTypeId, base, sharedState);
      },

      res => Action.dataLoaded(res, base)
    )
  ];
};

export const Action = ctorsUnion({
  dataLoaded: (data: Types.DataLoadedRes, base: BaseState) => ({
    data,
    base
  }),
  closeAlert: () => ({}),
  dispatchOperatingCaseSelector: (action: OperatingCaseSelector.Action) => ({
    action
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: Types.State,
  sharedState: SharedState.State
): readonly [Types.State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "dataLoaded": {
      const {
        operatingCases,
        opcProductData,
        climateSettings,
        moistureLoadId,
        alert
      } = parseOperatingCasesData(
        action.data,
        sharedState,
        action.base.newProperties,
        action.base.template.climateDataDefaults,
        action.base.divergentTemplateComponentMap
      );

      if (operatingCases.length === 0) {
        return [
          {
            ...action.base,
            OperatingCaseSelectorState: undefined,
            PropertiesSelectorProductData: undefined,
            alert: undefined,
            ok: false,
            moistureLoadId: undefined
          }
        ];
      }

      const [operatingCasesSelectorState] = OperatingCaseSelector.init(
        climateSettings,
        operatingCases.map(opc => {
          return { id: opc.id.value, settings: opc.settings };
        }),
        sharedState,
        opcProductData
      );

      return [
        {
          ...action.base,
          moistureLoadId: moistureLoadId,
          operatingCases: operatingCases.map(opc => opc.settings),
          climateSettings: climateSettings,
          OperatingCaseSelectorState: operatingCasesSelectorState,
          alert: alert,
          PropertiesSelectorProductData: opcProductData
        }
      ];
    }

    case "dispatchOperatingCaseSelector": {
      if (!state?.OperatingCaseSelectorState) {
        return [state];
      }

      const [
        operatingCaseSelectorState,
        operatingCaseSelectorCmd,
        sharedStateAction
      ] = OperatingCaseSelector.update(
        action.action,
        state.OperatingCaseSelectorState,
        sharedState
      );

      const newClimateSettings = OperatingCaseSelector.getClimateSettings(
        operatingCaseSelectorState
      );
      const newOperatingCases = OperatingCaseSelector.getOperatingCases(
        operatingCaseSelectorState
      ).map(opc => opc.settings);

      return [
        {
          ...state,
          ok: true,
          OperatingCaseSelectorState: operatingCaseSelectorState,
          climateSettings: newClimateSettings,
          operatingCases: newOperatingCases
        },
        Cmd.map(Action.dispatchOperatingCaseSelector, operatingCaseSelectorCmd),
        sharedStateAction ? [sharedStateAction] : undefined
      ];
    }

    case "closeAlert": {
      if (!state?.OperatingCaseSelectorState) {
        return [state];
      }
      return [
        {
          ...state,
          alert: undefined
        }
      ];
    }
    default:
      exhaustiveCheck(action, true);
  }
}

function getOperatingCases(
  moistureLoadOpcase: MlOpcaseResult | undefined,
  operatingCasesFromTemplates: ReadonlyArray<Types.WizardOperatingCase>,
  propertiesSelectorProductData: Types.ProductData
) {
  const operatingCases = !moistureLoadOpcase
    ? operatingCasesFromTemplates
    : convertMoistureLoadToOPC(
        operatingCasesFromTemplates.map(opc => opc.settings),
        moistureLoadOpcase.opcResult,
        propertiesSelectorProductData.properties
      );

  return operatingCases.map(opc => ({
    id: opc.id,
    settings: opc.settings
  }));
}

export async function getInitialData(
  systemTypeId: string,
  base: BaseState | undefined,
  sharedState: SharedState.State
): Promise<Types.DataLoadedRes> {
  const dataPoints = await FetchUserLocationDataPointsOrDefault(sharedState);

  const res = await sharedState.graphQL.queryUser<
    GraphQlTypes.WizardOperatingCasesProductQuery,
    GraphQlTypes.WizardOperatingCasesProductQueryVariables
  >(Queries.productDataQuery, {
    newSystemTypeInput: {
      systemTypeId: systemTypeId
    },
    newProductTypeInput: {
      productId: systemTypeId + "OPC"
    }
  });

  const moistureLoadErrorMessage = base?.moistureLoadNo
    ? testMoistureLoadNo(base.moistureLoadNo, sharedState)
    : "";

  let moistureLoadInfo:
    | {
        readonly moistureLoadNo: number;
        readonly revisionNo: number;
      }
    | undefined = undefined;

  if (!moistureLoadErrorMessage && base?.moistureLoadNo) {
    const [moistureLoadNo, revisionNo] = getMoistureLoadNoAndRevNo(
      base.moistureLoadNo
    );

    moistureLoadInfo = { moistureLoadNo, revisionNo };
  }

  const moistureLoadRes = moistureLoadInfo
    ? await sharedState.graphQL.queryUser<
        GraphQlTypes.WizardMoistureLoadQuery,
        GraphQlTypes.WizardMoistureLoadQueryVariables
      >(Queries.moistureLoadQuery, {
        moistureLoadNo: moistureLoadInfo.moistureLoadNo,
        revisionNo: moistureLoadInfo.revisionNo
      })
    : undefined;

  return { ...res, dataPoints, moistureLoadRes, moistureLoadErrorMessage };
}

export function parseOperatingCasesData(
  data: Types.DataLoadedRes,
  sharedState: SharedState.State,
  newProperties: PropertyValueSet.PropertyValueSet,
  climateDataDefaults: PropertyValueSet.PropertyValueSet,
  divergentTemplateComponentMap: DivergentTemplateComponentMap
) {
  const opcTemplates = data.product.systemType.templates
    .find(t =>
      PropertyFilterHelpers.isValid(
        t.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    )!
    .components.filter(
      c =>
        c.productId.endsWith("OPC") &&
        PropertyFilterHelpers.isValid(
          c.propertyFilter,
          PropertyFilter.Empty,
          newProperties
        )
    );

  const searchedForMoistureLoad = !!data.moistureLoadRes;

  const moistureLoadRes =
    data.moistureLoadRes?.user.moistureLoadByMoistureLoadNo;

  const moistureLoadOpcase = moistureLoadRes
    ? mapQueryResultToOpc(
        {
          moistureLoadResult: moistureLoadRes.moistureLoadResult!,
          moistureloadInput: moistureLoadRes.moistureloadInput!
        },
        sharedState.translate
      )
    : undefined;

  const climateSettings = moistureLoadOpcase
    ? PropertyValueSet.fromString(moistureLoadOpcase.climateSettings)
    : getDefaultClimateSettings(
        sharedState,
        climateDataDefaults,
        data.dataPoints.product.dataPointsForLocationId!,
        data.product.countries
      );

  const rangeFilteredOpcProductData = {
    ...data.product.systemType.product,
    properties: data.product.systemType.product.properties.map(property => {
      return {
        ...property,
        items: property.values.filter(v =>
          isValid(v.rangeFilter, PropertyFilter.Empty, newProperties)
        )
      };
    })
  };

  const effectiveOpcTemplateComponentsProperties =
    getEffectiveTemplateComponentsProperties(
      rangeFilteredOpcProductData,
      opcTemplates,
      divergentTemplateComponentMap
    );

  const effectiveOpcTemplateComponentsArray: ReadonlyArray<Types.OpcTemplateComponent> =
    opcTemplates.map(opcTemplate => ({
      id: opcTemplate.id,
      properties: PropertyValueSet.fromString(opcTemplate.properties || ""),
      selectedProperties:
        effectiveOpcTemplateComponentsProperties[opcTemplate.id]
    }));

  const opcPreProcessRows = parseOpcPreProcessRows(
    data.product.systemType.new.opcPreProcess
  );

  const preProcessedOpc = createPreProcessedOpcs(
    newProperties,
    opcPreProcessRows
  );

  const operatingCasesFromTemplates: ReadonlyArray<Types.WizardOperatingCase> =
    OperatingCaseSelector.createInitialOperatingCases(
      climateSettings,
      effectiveOpcTemplateComponentsArray,
      rangeFilteredOpcProductData.properties.map(p => ({
        ...p,
        valueSources: p.valueSources.map(v => ({
          id: v.id,
          value: v.value,
          propertyValueSourceId: v.propertyValueSourceId,
          parameters: v.parameters,
          claimFilter: v.claimFilter,
          propertyFilter: v.propertyFilter
        })),
        defaultValues: p.defaultValues.map(d => ({
          value: PropertyValue.fromString(d.value)!,
          propertyFilter: PropertyFilter.fromStringOrEmpty(d.propertyFilter)
        }))
      })),
      sharedState.translate
    ).map(p => ({
      ...p,
      settings: PropertyValueSet.merge(preProcessedOpc, p.settings)
    }));

  const opcProductData = parseProduct(
    rangeFilteredOpcProductData,
    sharedState.translate
  );

  const operatingCasesToBeUsed = getOperatingCases(
    moistureLoadOpcase,
    operatingCasesFromTemplates,
    opcProductData
  );

  const alert: Types.AlertType =
    searchedForMoistureLoad && !moistureLoadRes
      ? {
          type: "error",
          message: "Moisture load not found. Using default template values"
        }
      : data.moistureLoadErrorMessage
      ? {
          type: "error",
          message:
            data.moistureLoadErrorMessage +
            ". Default template values being used"
        }
      : moistureLoadRes?.id
      ? { type: "success", message: "Opcase imported from ML" }
      : undefined;

  return {
    operatingCases: operatingCasesToBeUsed,
    alert: alert,
    opcProductData: opcProductData,
    climateSettings: climateSettings,
    moistureLoadId: moistureLoadRes?.id
  };
}

async function FetchUserLocationDataPointsOrDefault(
  sharedState: SharedState.State
): Promise<GraphQlTypes.ClimateSelectorDataPointsProductQuery> {
  let dataPoints;
  const locationIds = [
    sharedState.user.settings.climate.location || defaultWmo,
    defaultWmo
  ];
  do {
    if (locationIds.length === 0) {
      throw new Error("No datapoints was found");
    }
    dataPoints = await sharedState.graphQL.queryProduct<
      GraphQlTypes.ClimateSelectorDataPointsProductQuery,
      GraphQlTypes.ClimateSelectorDataPointsProductQueryVariables
    >(dataPointsQuery, {
      locationId: locationIds.shift()!
    });
  } while (!dataPoints.product.dataPointsForLocationId);

  return dataPoints;
}
