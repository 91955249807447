import styled from "styled-components";
import { zIndex } from "@genesys/ui-elements";

export const PopOverContainer = styled.div`
  /* position: absolute; */

  padding: 10px 20px;
  border: 1px solid #dddddd;

  background-color: white;
  z-index: ${zIndex.Modal};

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
