import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as Types from "../types";
import * as Tools from "../tools";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as Exporter from "@genesys/client-core/lib/exporter";
import * as System from "../../../../system";
import styled from "styled-components";
import {
  GenesysSelect,
  ReadOnlyTable,
  StandardButton
} from "@genesys/ui-elements";
import { Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { AmountFormatSelector } from "../../../../../amount-format-selector";

const FlexContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;

  > * {
    margin-right: 5px;
  }
`;

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  > span,
  button {
    margin-right: 8px;
  }
`;

const StyledDiv = styled.div`
  margin-top: 5px;
  overflow-y: auto;
`;

const listFieldGroup = "ClimateBinVisualizerList";

export function BinListView({
  sharedState,
  selectedBinListView,
  binCases,
  system,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly system: System.System;
  readonly selectedBinListView: Types.BinListView;
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase>;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;
  const canShowMonthly = Tools.isMonthlyPossible(binCases);
  const list =
    selectedBinListView === "withmonths"
      ? SharedEnergyTools.createBinListWithMonths(
          binCases,
          translate,
          sharedState.screenAmounts.getAmountFormat,
          listFieldGroup
        )
      : SharedEnergyTools.createStandardBinList(
          binCases,
          translate,
          sharedState.screenAmounts.getAmountFormat,
          listFieldGroup
        );
  let binListViewOptions = [
    {
      id: "standard",
      title: translate(LanguageTexts.binViewStandard())
    },
    {
      id: "withmonths",
      title: translate(LanguageTexts.binViewWithMonths())
    }
  ];

  const fileName =
    sharedState.genesysPrefix.genesysNo(
      system.file.genesysNo,
      system.revisionNo
    ) + " EnergyBinCases";

  if (!canShowMonthly) {
    binListViewOptions = binListViewOptions.filter(
      obj => obj.id !== "withmonths"
    );
  }
  return (
    <>
      <FlexContainer>
        <span>{translate(LanguageTexts.viewAs())}</span>
        <GenesysSelect
          width={6}
          height={33}
          fontSize={13}
          options={binListViewOptions.map(b => ({
            value: b.id,
            title: b.title
          }))}
          value={selectedBinListView}
          onChange={e => {
            dispatch(
              Action.setBinListView(e.target.value as Types.BinListView)
            );
          }}
        />
      </FlexContainer>
      <StyledDiv>
        <ReadOnlyTable
          rows={list.formattedRows.map((row, i) => ({
            name: i.toString(),
            results: row.slice(1)
          }))}
          headers={list.formattedHeader.map(header => ({
            header: header.name,
            visible: true,
            amountFormatSelector: () => {
              if (header.format === undefined) {
                return null;
              }

              return (
                <>
                  [
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    translate={sharedState.translate}
                    conversionParameters={
                      header.createConversionParameters &&
                      binCases &&
                      binCases.length > 0
                        ? header.createConversionParameters(binCases[0])
                        : undefined
                    }
                    fieldGroup={listFieldGroup}
                    fieldName={header.internalName}
                    amountFormat={header.format}
                    onFormatCleared={() =>
                      dispatch(
                        Action.onFormatCleared(
                          listFieldGroup,
                          header.internalName
                        )
                      )
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.onFormatChanged(
                          listFieldGroup,
                          header.internalName,
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                  ]
                </>
              );
            }
          }))}
          alignHeader="center"
          alignRows="center"
        />
      </StyledDiv>
      <SubContainer>
        <Exporter.XlsxSingleSheetExport
          data={exportData(
            SharedEnergyTools.createStandardBinList(
              binCases,
              translate,
              sharedState.screenAmounts.getAmountFormat,
              listFieldGroup
            )
          )}
          filename={fileName}
        >
          {onClick => (
            <StandardButton
              buttonType={"Secondary"}
              size={"Small"}
              onClick={onClick}
            >
              {translate(LanguageTexts.exportBinsAsStandard())}
            </StandardButton>
          )}
        </Exporter.XlsxSingleSheetExport>

        {canShowMonthly ? (
          <Exporter.XlsxSingleSheetExport
            data={exportData(
              SharedEnergyTools.createBinListWithMonths(
                binCases,
                translate,
                sharedState.screenAmounts.getAmountFormat,
                listFieldGroup
              )
            )}
            filename={fileName}
          >
            {onClick => (
              <StandardButton
                buttonType={"Primary"}
                size={"Small"}
                onClick={onClick}
              >
                {translate(LanguageTexts.exportBinsWithMonths())}
              </StandardButton>
            )}
          </Exporter.XlsxSingleSheetExport>
        ) : null}
      </SubContainer>
    </>
  );
}

function exportData(table: SharedEnergyTools.EnergyResultTable) {
  return [
    table.formattedHeader.map(
      h => h.name + (h.format ? " (" + h.format.unit.name + ")" : "")
    )
  ].concat(table.formattedRows);
}
