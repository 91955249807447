import * as React from "react";
import styled from "styled-components";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import { Dispatch } from "@typescript-tea/core";
import {
  zIndex,
  PrimaryColors,
  StandardButton,
  P1
} from "@genesys/ui-elements";
import * as PropertiesSelector from "../../../../../properties-selector";
import * as SharedState from "../../../../../shared-state";
import * as System from "../../../../system";
import { State, Action } from "../state";
import { Unit } from "uom";
import { Quantity } from "@genesys/uom";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { AmountFormatSelectors } from "./components/amount-format-selectors";

const Root = styled.div`
  padding: 0 16px 0px 16px;
`;

const ColumnsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 0 18px 0;
  background-color: inherit;

  overflow: auto;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: ${zIndex.Modal + zIndex.OperatingCasePropertyNames};
`;

const PropertyNameColumnContainer = styled.div`
  white-space: nowrap;

  label {
    color: black;
  }

  table {
    tbody {
      tr:first-child {
        td:nth-child(3) {
          display: none; // Hide binid
        }
      }
      tr {
        td {
          height: 31px; // To make the propertynames line up with the values
        }
        td:first-child {
          display: none; // To hide the Infobox
        }
        td:nth-child(3) {
          display: none; // Hide values
        }
        td:nth-child(4) {
          div {
            span {
              display: none; // hide empty cell
            }
          }
        }
      }
    }
  }
`;

const AmountColumnContainer = styled.div`
  white-space: nowrap;

  label {
    color: black;
  }

  table {
    tbody {
      tr {
        td {
          height: 31px; // To make the propertynames line up with the values
        }
      }
    }
  }
`;

const ValueColumnContainer = styled.div`
  min-width: 100px;

  input {
    border-radius: 0;
  }

  table {
    tbody {
      tr:first-child {
        span {
          color: black; // making binid name black
        }
      }
      tr {
        td {
          height: 31px; // To make the propertynames line up with the values
          padding: 0;
        }
        td:first-child {
          div {
            *:nth-child(2) {
              display: none; // To hide the AmountFormatSelector
            }
          }
        }
        td:nth-child(2) {
          display: none; // To hide the ValueSourceSelector
        }
      }
    }
  }
`;

const DefaultValueColumnContainer = styled(ValueColumnContainer)`
  border: 1px solid ${PrimaryColors.muntersBlue};
  border-radius: 5px;
  box-shadow: -5px 5px 10px 2px rgb(0 0 0 / 10%);
  padding: 0 10px 10px 10px;
  margin-right: 10px;

  position: relative;
  top: -1px;
`;

const DefaultValueHeaderContainer = styled.div`
  position: absolute;
  top: 10px;
  width: 90%;
  padding-bottom: 10px;
  background-color: white;
  z-index: ${zIndex.Modal + zIndex.OperatingCasePropertyNames + 1};
`;

export function OperatingCasesEditor({
  state,
  sharedState,
  dispatch,
  system
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly system: System.System;
}) {
  if (
    !state.operatingCaseSelectorStates ||
    !state.operatingCaseSelectorStates.length ||
    !state.binCases ||
    !state.binCases.length
  ) {
    return (
      <Root>
        <P1 weight="bold" color="dark">
          {sharedState.translate(LanguageTexts.binCasesMissing())}
        </P1>
      </Root>
    );
  }

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  if (!state.energyProduct) {
    return (
      <Root>
        <P1 weight="bold" color="dark">
          {"Missing OPE product"}
        </P1>
      </Root>
    );
  }

  const sysComponent = system.components.find(c => c.productId.endsWith("SYS"));
  const sysProperties = sysComponent
    ? sysComponent.properties
    : PropertyValueSet.Empty;

  const filteredProductData = ProductDataTools.filterProductForRange(
    state.energyProduct,
    sysProperties || PropertyValueSet.Empty
  );

  const operatingcaseSelectorPropertiesDef = filteredProductData.properties.map(
    (p): PropertiesSelector.PropertyInfo => ({
      ...p,
      visibilityFilter:
        p.name !== KnownProperties.binId
          ? p.visibilityFilter
          : PropertyFilter.Empty
    })
  );

  const fieldGroup = `Settings.${state.energyProduct.id}`;

  return (
    <Root>
      <ColumnsContainer>
        <LeftContainer>
          <PropertyNameColumnContainer>
            <PropertiesSelector.PropertiesSelectorView
              fieldGroup={fieldGroup}
              isReadonly={system.status >= SystemStatus.LockSuccess}
              propertiesInfo={operatingcaseSelectorPropertiesDef}
              dispatch={Dispatch.map(
                action => Action.dispatchOperatingCaseSelector(0, action),
                dispatch
              )}
              state={state.operatingCaseSelectorStates[0]}
              showCodes={showCodes}
              hidePropertyNames={false}
              sharedState={sharedState}
              productId={state.energyProduct.id}
            />
          </PropertyNameColumnContainer>

          <AmountColumnContainer>
            <AmountFormatSelectors
              fieldGroup={fieldGroup}
              productProperties={operatingcaseSelectorPropertiesDef}
              selectedProperties={
                state.operatingCaseSelectorStates[0].properties
              }
              showCodes={showCodes}
              sharedState={sharedState}
              onFormatChanged={(
                fieldGroup: string,
                fieldName: string,
                unit: Unit.Unit<Quantity.Quantity>,
                decimalCount: number
              ) =>
                dispatch(
                  Action.onFormatChanged(
                    fieldGroup,
                    fieldName,
                    unit,
                    decimalCount
                  )
                )
              }
              onFormatCleared={(fieldGroup: string, fieldName: string) => {
                dispatch(Action.onFormatCleared(fieldGroup, fieldName));
              }}
            />
          </AmountColumnContainer>

          {state.defaultValuesSelectorState && (
            <DefaultValueColumnContainer>
              <DefaultValueHeaderContainer>
                <p>Set all</p>
              </DefaultValueHeaderContainer>
              <PropertiesSelector.PropertiesSelectorView
                fieldGroup={fieldGroup}
                isReadonly={system.status >= SystemStatus.LockSuccess}
                propertiesInfo={operatingcaseSelectorPropertiesDef}
                dispatch={Dispatch.map(
                  action => Action.dispatchDefaultValuesSelector(action),
                  dispatch
                )}
                state={state.defaultValuesSelectorState}
                showCodes={showCodes}
                hidePropertyNames={true}
                sharedState={sharedState}
                productId={state.energyProduct.id}
              />
              {system.status < SystemStatus.LockSuccess && (
                <StandardButton
                  onClick={() =>
                    dispatch(Action.resetAllOperatingCaseSelectors())
                  }
                  buttonType="Primary"
                  size="Small"
                >
                  {sharedState.translate(LanguageTexts.resetAll())}
                </StandardButton>
              )}
            </DefaultValueColumnContainer>
          )}
        </LeftContainer>

        {state.operatingCaseSelectorStates.map((pps, ix) => (
          <ValueColumnContainer key={"pps" + ix}>
            <PropertiesSelector.PropertiesSelectorView
              fieldGroup={fieldGroup}
              isReadonly={system.status >= SystemStatus.LockSuccess}
              propertiesInfo={operatingcaseSelectorPropertiesDef}
              dispatch={Dispatch.map(
                action => Action.dispatchOperatingCaseSelector(ix, action),
                dispatch
              )}
              state={pps}
              showCodes={showCodes}
              hidePropertyNames={true}
              sharedState={sharedState}
              productId={state.energyProduct!.id}
            />
            {!PropertyValueSet.equals(
              pps.properties,
              state.resetOperatingCaseSelectorStates![ix].properties
            ) && (
              <StandardButton
                onClick={() => dispatch(Action.resetOperatingCaseSelector(ix))}
                buttonType="Primary"
                size="Small"
              >
                {sharedState.translate(LanguageTexts.reset())}
              </StandardButton>
            )}
          </ValueColumnContainer>
        ))}
      </ColumnsContainer>
    </Root>
  );
}
