import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import styled from "styled-components";
import moment from "moment";
import { CheckBox, Label } from "@genesys/ui-elements";

import { SearchFilterOptions } from "../types";

const DatePickerContainer = styled.div`
  margin-top: 10px;
  width: 258px;

  input {
    width: 124px;
    height: 26px;
  }
  td {
    padding-right: 13px;
  }
`;

const DateCheckboxContainer = styled.div`
  display: flex;
`;

export function DatePicker({
  searchOptionValues,
  sharedState,
  onSearchFilterOptionsChange
}: {
  readonly searchOptionValues: SearchFilterOptions;
  readonly sharedState: SharedState.State;
  readonly onSearchFilterOptionsChange: (
    searchFilterOptions: SearchFilterOptions
  ) => void;
}) {
  const { textFieldOptions, useDate } = searchOptionValues;

  const currentDate = moment().format("YYYY-MM-DD");
  const fromDate = textFieldOptions["from"] || currentDate;
  const toDate = textFieldOptions["to"] || currentDate;

  const onDateToggle = () => {
    const newTextFieldOptions = {
      ...textFieldOptions,
      from: fromDate,
      to: toDate
    };

    onSearchFilterOptionsChange({
      ...searchOptionValues,
      textFieldOptions: newTextFieldOptions,
      useDate: !searchOptionValues.useDate
    });
  };

  const onDateChange = (key: string) => (date: any) => {
    const newTextFieldOptions = {
      ...textFieldOptions,
      [key]: date
    };

    onSearchFilterOptionsChange({
      ...searchOptionValues,
      textFieldOptions: newTextFieldOptions
    });
  };
  return (
    <DatePickerContainer>
      <DateCheckboxContainer>
        <CheckBox onClick={onDateToggle} isChecked={useDate} children="" />
        <Label weight="normal" color="secondary">
          {sharedState.translate(LanguageTexts.date())}
        </Label>
      </DateCheckboxContainer>
      <table>
        <tbody>
          <tr>
            <td>
              <Label weight="normal" color="secondary">
                {sharedState.translate(LanguageTexts.from())}
              </Label>
            </td>
            <td>
              <Label weight="normal" color="secondary">
                {sharedState.translate(LanguageTexts.to())}
              </Label>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="date"
                disabled={!useDate}
                onChange={e => onDateChange("from")(e.target.value)}
                value={fromDate}
              />
            </td>
            <td>
              <input
                type="date"
                disabled={!useDate}
                onChange={e => onDateChange("to")(e.target.value)}
                value={toDate}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </DatePickerContainer>
  );
}
