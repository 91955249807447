import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const deleteAmountFieldFormatMutation = gql`
  mutation DeleteAmountFieldFormat(
    $amountFieldFormat: DeleteAmountFieldFormatInputType!
  ) {
    deleteAmountFieldFormat(amountFieldFormat: $amountFieldFormat) {
      ...CompleteAmountFieldProfile
    }
  }
  ${Fragments.completeAmountFieldProfile}
`;
