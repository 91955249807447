import styled from "styled-components";

export const Root = styled.div``;

export const ViewTypeSelectorContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  > span,
  button {
    margin-right: 8px;
  }
`;

export const ExportButtonsContainer = styled.div`
  padding-top: 16px;
  button {
    margin-right: 16px;
  }
`;
