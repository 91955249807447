// tslint:disable:variable-name
import * as React from "react";
import styled from "styled-components";
import * as PropertiesSelector from "../react-properties-selector";
import * as GenesysPropertySelectorElements from "../genesys-property-selector-elements";

export const PropertySelectorGroup = styled.div`
  padding-right: 5px;
`;

const unStyledPropertySelectorGroupRows = (
  props: { readonly hidden: boolean } & React.HTMLProps<HTMLDivElement>
): JSX.Element => {
  const { hidden, ...restProps } = props;
  return <div {...restProps}>{props.children}</div>;
};

export const PropertySelectorGroupRows = styled(
  unStyledPropertySelectorGroupRows
)`
  padding-right: 10px;
  ${props => (props.hidden ? "height: 0; visibility: hidden;" : "")};
`;

export const StyledPropertySelectorGroupTable = styled.table`
  tr {
    td {
      padding-left: 10px;
      vertical-align: middle;
      white-space: nowrap;
      font-size: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    td:first-child {
      padding-left: 0px;
      padding-right: 2px;
    }

    td:nth-of-type(2) {
      padding-right: 15px;
      padding-left: 0;
    }
  }
`;
export const ColumnLayoutRoot = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DefaultPropertySelector =
  PropertiesSelector.createPropertySelector({
    AmountPropertySelector:
      GenesysPropertySelectorElements.DefaultAmountPropertySelector,
    ComboboxPropertySelector:
      GenesysPropertySelectorElements.DefaultComboBoxPropertySelector,
    TextboxPropertySelector:
      GenesysPropertySelectorElements.DefaultTextboxPropertySelector,
    CheckboxPropertySelector:
      GenesysPropertySelectorElements.DefaultCheckboxPropertySelector
  });

export const DeleteButtonContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const HelpContainer = styled.span`
  a {
    color: black;
  }
  a:link {
    color: black;
  }
  a:visited {
    color: black;
  }
  a:hover {
    color: black;
    text-decoration: underline;
    font-weight: bold;
  }
  a:active {
    color: black;
  }
`;

export const PropertyContainer = styled.div`
  padding: 10px;
  text-align: left;
`;

export const StyledP = styled.p`
  white-space: pre-wrap;
`;

export const PropertyValueContainer = styled.div`
  font-weight: bold;
  padding-left: 10px;
  text-align: left;
`;

export const PropertyValueDescription = styled.div`
  padding-left: 10px;
  text-align: left;
`;
