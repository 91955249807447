import { PropertyValueSet } from "@genesys/property";
import * as GraphQlTypes from "../graphql-types";

export interface System {
  readonly id: string;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly binSelections?: string | null | undefined;
  readonly components: ReadonlyArray<Component>;
  readonly airstreams: ReadonlyArray<Airstream>;
  readonly operatingCases: ReadonlyArray<OperatingCase>;
  readonly pricingInformation: ReadonlyArray<PricingInformation>;
  readonly revisionNo: number;
  readonly currencyCode: string;
  readonly masterSalesOrganisationId?: string | null | undefined;
  readonly masterCurrencyCode?: string | null | undefined;
  readonly moistureLoadInfo?: MoistureLoadInfo | null | undefined;
  readonly status: number;
  readonly statusDate: GraphQlTypes.Scalars["GenesysDate"];
  readonly salesOrganisationId: string;
  readonly lastModifiedBy: User;
  readonly file: {
    readonly id: string;
    readonly genesysNo: number;
    readonly name: string;
    readonly systemTypeId: string;
    readonly owner: User;
    readonly labels: ReadonlyArray<Label>;
    readonly systems: ReadonlyArray<SystemFileSystem>;
  };
}
export interface SystemFileSystem {
  readonly id: string;
  readonly revisionNo: number;
  readonly status: number;
  readonly statusDate: number;
  readonly comment: string;
}

export interface User {
  readonly id: string;
  readonly name: string;
}

export interface Label {
  readonly id: string;
  readonly name: string;
}

export interface Component {
  readonly id: string;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly componentSpec: PropertyValueSet.PropertyValueSet;
  readonly sections: ReadonlyArray<ComponentSection>;
  readonly accessoryToId?: string | null | undefined;
  readonly label: string | undefined;
  readonly labelAbove: string | undefined;
  readonly sortNo: number;
  readonly messages: ReadonlyArray<Message>;
  readonly internalNotes: string;
  readonly externalNotes: string;
}

export interface Message {
  readonly id: string;
  readonly messageCode: number;
  readonly messageSeverity: number;
  readonly messageParameters: string | undefined;
  readonly operatingCaseResultId: string | undefined;
}

export interface ComponentSection {
  readonly id: string;
  readonly productSectionId: string;
  readonly componentId: string;
  readonly productSectionSortNo: number;
  readonly sortNo: number;
  readonly systemAirStreamId: string | undefined;
}

export interface Airstream {
  readonly id: string;
  readonly componentSections: ReadonlyArray<ComponentSection>;
}

export interface OperatingCase {
  readonly id: string;
  readonly caseName: string;
  readonly customCaseName?: string | null | undefined;
  readonly settings?: string | null | undefined;
  readonly results: ReadonlyArray<OperatingCaseResult>;
  readonly sortNo: number;
  readonly binData?: string | null | undefined;
  readonly caseType: number;
}

export interface OperatingCaseResult {
  readonly id: string;
  readonly componentId: string;
  readonly calculationType: GraphQlTypes.ComponentCalculationType;
  readonly isDesignCase: boolean;
  readonly settings?: string | null | undefined;
  readonly sortNo: number;
}

export interface PricingInformation {
  readonly id: string;
  readonly pricingNo: number;
  readonly revisionNo: number;
}

export interface MoistureLoadInfo {
  readonly id: string;
  readonly moistureLoadNo: number;
  readonly revisionNo: number;
}

export function parseSystem(
  system: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >
): System {
  return {
    id: system.id,
    binSelections: system.binSelections,
    climateSettings: parseClimateSettings(system.climateSettings),
    components: system.components.map(parseComponent),
    airstreams: system.airstreams.map(parseAirstream),
    status: system.status,
    salesOrganisationId: system.salesOrganisationId,
    revisionNo: system.revisionNo,
    pricingInformation: system.pricingInformation,
    statusDate: system.statusDate,
    currencyCode: system.currencyCode,
    masterCurrencyCode: system.masterCurrencyCode,
    lastModifiedBy: {
      id: system.lastModifiedBy.id,
      name: system.lastModifiedBy.userName
    },
    moistureLoadInfo: system.moistureLoadInfo
      ? {
          id: system.moistureLoadInfo.id,
          revisionNo: system.moistureLoadInfo.revisionNo,
          moistureLoadNo: system.moistureLoadInfo.moistureLoadNo
        }
      : undefined,
    masterSalesOrganisationId: system.masterSalesOrganisationId,
    operatingCases: system.operatingCases.map(parseOperatingCase),
    file: {
      id: system.systemFile.id,
      name: system.systemFile.name,
      genesysNo: system.systemFile.genesysNo,
      systemTypeId: system.systemFile.systemTypeId,
      owner: parseOwner(system.systemFile.owner),
      labels: system.systemFile.labels.map(parseLabel),
      systems: system.systemFile.systems.map(parseSystemFileSystem)
    }
  };
}

function parseSystemFileSystem(
  systemFileSystem: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["systemFile"]["systems"][0]
): SystemFileSystem {
  return {
    id: systemFileSystem.id,
    revisionNo: systemFileSystem.revisionNo,
    status: systemFileSystem.status,
    statusDate: systemFileSystem.statusDate,
    comment: systemFileSystem.comment || ""
  };
}

function parseOwner(
  owner: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["systemFile"]["owner"]
): User {
  return {
    id: owner.id,
    name: owner.userName
  };
}

function parseLabel(
  label: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["systemFile"]["labels"][0]
): Label {
  return {
    id: label.id,
    name: label.name
  };
}

function parseOperatingCase(
  operatingCase: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["operatingCases"][0]
): OperatingCase {
  return {
    id: operatingCase.id,
    binData: operatingCase.binData,
    caseName: operatingCase.caseName,
    customCaseName: operatingCase.customCaseName,
    settings: operatingCase.settings,
    results: operatingCase.results,
    caseType: operatingCase.caseType,
    sortNo: operatingCase.sortNo
  };
}

function parseComponent(
  c: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["components"][0]
): Component {
  return {
    id: c.id,
    labelAbove: undefined,
    label: undefined,
    productId: c.productId,
    sortNo: c.sortNo,
    accessoryToId: c.accessoryToId,
    properties: PropertyValueSet.fromString(c.properties ?? ""),
    componentSpec: PropertyValueSet.fromString(c.componentSpec ?? ""),
    sections: c.sections.map(parseComponentSection),
    messages: c.messages.map(parseMessage),
    externalNotes: c.externalNotes || "",
    internalNotes: c.internalNotes || ""
  };
}

function parseMessage(
  message: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["components"][0]["messages"][0]
): Message {
  return {
    id: message.id,
    messageCode: message.messageCode,
    messageSeverity: message.messageSeverity,
    messageParameters: message.messageParameters || undefined,
    operatingCaseResultId: message.operatingCaseResultId ?? undefined
  };
}

function parseComponentSection(
  cs: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["components"][0]["sections"][0]
): ComponentSection {
  return {
    id: cs.id,
    productSectionId: cs.productSectionId,
    componentId: cs.componentId,
    productSectionSortNo: cs.productSectionSortNo,
    sortNo: cs.sortNo,
    systemAirStreamId: cs.systemAirStreamId ?? undefined
  };
}

function parseAirstream(
  a: NonNullable<
    GraphQlTypes.SystemConfiguratorUser["user"]["systemByGenesysNo"]
  >["airstreams"][0]
): Airstream {
  return {
    id: a.id,
    componentSections: a.componentSections.map(parseComponentSection)
  };
}

function parseClimateSettings(climateSettings: string | null | undefined) {
  return climateSettings
    ? PropertyValueSet.fromString(climateSettings)
    : PropertyValueSet.Empty;
}
