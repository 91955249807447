import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as PropertiesSelector from "../../../properties-selector";
import * as System from "../../system";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import * as KnownProperties from "./known-properties";
import * as GraphQlTypes from "../../../graphql-types";
import { productQuery } from "./queries";
import * as Types from "./types";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import {
  parseSizeInfoPerformance,
  getUpdatedOpcSelectors,
  getUpdatedCustomOpcSelector,
  getHasValuesChanged,
  getOptions,
  getTotalVolumeFlow
} from "./functions";
import * as Guid from "@genesys/shared/lib/guid";
import { runValueSources } from "../../../operating-case-selector";
import { Amount, Quantity, Unit, Units } from "@genesys/uom";
import * as Product from "../../product";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as ClimateSelector from "../../../climate-selector/state";

export type State = {
  readonly sizes: ReadonlyArray<Types.Size>;
  readonly designSelector: PropertiesSelector.State;
  readonly opcSelectors: ReadonlyArray<{
    readonly id: string;
    readonly state: PropertiesSelector.State;
  }>;
  readonly options: ReadonlyArray<Types.Option>;
  readonly climateSelectorState: ClimateSelector.State;
  readonly isOpenClimateSelector: boolean;
  readonly selectedSize: number;
  readonly valuesHasChanged: boolean;
  readonly initialSettings: {
    readonly designSelector: PropertiesSelector.State;
    readonly opcSelectors: ReadonlyArray<{
      readonly id: string;
      readonly state: PropertiesSelector.State;
    }>;
    readonly selectedSize: number | undefined;
  };
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string,
  sysProduct: Product.Product,
  sysComponent: System.Component,
  operatingCases: ReadonlyArray<{
    readonly id: string;
    readonly settings: PropertyValueSet.PropertyValueSet;
  }>,
  climateSettings: PropertyValueSet.PropertyValueSet
): [State, Cmd<Action>?] => {
  const selectedSize = PropertyValueSet.getInteger(
    KnownProperties.epxSize,
    sysComponent.properties
  )!;

  const opcSelectors = operatingCases
    .map(opc => {
      return {
        id: opc.id,
        state: PropertiesSelector.init(opc.settings)
      };
    })
    .sort(
      (a, b) =>
        PropertyValueSet.getInteger(
          "sortno",
          PropertiesSelector.getSelectedProperties(a.state)
        )! -
        PropertyValueSet.getInteger(
          "sortno",
          PropertiesSelector.getSelectedProperties(b.state)
        )!
    );

  const opcSelectorsWithMaybeUserValueSources = opcSelectors.map((opcs, ix) => {
    if (ix < 2) {
      return opcs;
    } else {
      let opcPvs = PropertiesSelector.getSelectedProperties(opcs.state);
      const normalOpcPvs = PropertiesSelector.getSelectedProperties(
        opcSelectors[1].state
      );

      const setValueSource = (propertyName: string) => {
        const isEqual = PropertyValue.equals(
          PropertyValueSet.get(propertyName, opcPvs)!,
          PropertyValueSet.get(propertyName, normalOpcPvs)!
        );
        return isEqual
          ? opcPvs
          : PropertyValueSet.setInteger("source_" + propertyName, 1, opcPvs);
      };

      opcPvs = setValueSource(KnownProperties.supplyTargetTemperature);
      opcPvs = setValueSource(KnownProperties.returnAirTemperature);
      opcPvs = setValueSource(KnownProperties.iteLoadPercent);
      opcPvs = setValueSource(KnownProperties.numberOfNormalUnitsOperating);
      opcPvs = setValueSource(KnownProperties.numberOfRedundantUnitsOperating);

      return { ...opcs, state: PropertiesSelector.init(opcPvs) };
    }
  });

  const designSelector = PropertiesSelector.init(
    PropertyValueSet.merge(
      PropertyValueSet.keepProperties(
        [
          KnownProperties.numberOfNormalUnits,
          "source_" + KnownProperties.numberOfNormalUnits,
          KnownProperties.numberOfRedundantUnits,
          KnownProperties.iteLoad
        ],
        sysComponent?.properties ?? PropertyValueSet.Empty
      ),
      PropertyValueSet.keepProperties(
        [
          KnownProperties.supplyTargetTemperature,
          KnownProperties.returnAirTemperature,
          KnownProperties.returnAirHumidity,
          KnownProperties.atmosphericPressure
        ],
        operatingCases[0].settings
      )
    )
  );

  const [climateSelectorState, climateSelectorCmd] = ClimateSelector.init(
    climateSettings,
    sharedState
  );

  return [
    {
      climateSelectorState: climateSelectorState,
      isOpenClimateSelector: false,
      sizes: [],
      designSelector: designSelector,
      opcSelectors: opcSelectorsWithMaybeUserValueSources,
      options: [],
      selectedSize: selectedSize,
      valuesHasChanged: false,
      initialSettings: {
        designSelector: designSelector,
        opcSelectors: opcSelectorsWithMaybeUserValueSources,
        selectedSize: selectedSize
      }
    },
    Cmd.batch([
      Cmd.map(Action.dispatchClimateSelector, climateSelectorCmd),
      sharedState.graphQL.queryProductCmd<
        GraphQlTypes.DataCenterEditorProductQuery,
        GraphQlTypes.DataCenterEditorProductQueryVariables,
        Action
      >(
        productQuery,
        {
          sys: {
            productId: `${systemTypeId}SYS`
          }
        },
        data =>
          Action.productDataReceived(data, sysComponent.properties, sysProduct)
      )
    ])
  ];
};

export const Action = ctorsUnion({
  dispatchClimateSelector: (action: ClimateSelector.Action) => ({ action }),
  setIsOpenClimateSelector: (isOpen: boolean) => ({ isOpen }),
  onSaveClimateSelector: (
    climateSettings: PropertyValueSet.PropertyValueSet,
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({ climateSettings, productData: productDataProperties }),
  dispatchDesignSelector: (
    action: PropertiesSelector.Action,
    isNumberOfUnitsSourceUser: boolean
  ) => ({
    action,
    isNumberOfUnitsSourceUser
  }),
  dispatchOpcSelector: (
    id: string,
    action: PropertiesSelector.Action,
    productData: Types.ProductData
  ) => ({
    id,
    action,
    productData
  }),
  onAddNewOperatingCase: (
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({
    productDataProperties
  }),
  onDeleteOperatingCase: (
    operatingCaseId: string,
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({
    operatingCaseId,
    productDataProperties
  }),
  productDataReceived: (
    data: GraphQlTypes.DataCenterEditorProductQuery,
    sysProperties: PropertyValueSet.PropertyValueSet,
    sysProduct: Product.Product
  ) => ({ data, sysProperties, sysProduct }),
  selectOption: (option: Types.Option, isNumberOfUnitsSourceUser: boolean) => ({
    option,
    isNumberOfUnitsSourceUser
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchClimateSelector": {
      if (!state.climateSelectorState) {
        return [state];
      }

      const [climateSelectorState, climateSelectorCmd, sharedStateAction] =
        ClimateSelector.update(
          action.action,
          state.climateSelectorState,
          sharedState
        );

      return [
        { ...state, climateSelectorState: climateSelectorState },
        Cmd.map(Action.dispatchClimateSelector, climateSelectorCmd),
        sharedStateAction
      ];
    }
    case "setIsOpenClimateSelector": {
      return [{ ...state, isOpenClimateSelector: action.isOpen }];
    }
    case "onSaveClimateSelector": {
      const newOpcSelectors = state.opcSelectors.map(opcSelector => {
        return {
          id: opcSelector.id,
          state: PropertiesSelector.init(
            runValueSources(
              PropertiesSelector.getSelectedProperties(opcSelector.state),
              state.opcSelectors.map(pss =>
                PropertiesSelector.getSelectedProperties(pss.state)
              ),
              action.climateSettings,
              action.productData
            )
          )
        };
      });

      const options = getOptions(
        state.sizes,
        PropertiesSelector.getSelectedProperties(state.designSelector),
        PropertyValueSet.getAmount<Quantity.Length>(
          KnownProperties.altitude,
          action.climateSettings
        )!,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.atmosphericPressure,
          action.climateSettings
        )!
      );

      return [
        {
          ...state,
          options: options,
          opcSelectors: newOpcSelectors,
          valuesHasChanged: true
        },
        undefined
      ];
    }
    case "dispatchDesignSelector": {
      if (!state.designSelector) {
        return [state];
      }

      const [designSelectorState, designSelectorCmd, sharedAction] =
        PropertiesSelector.update(
          action.action,
          state.designSelector,
          sharedState,
          "SkipCalculateProperties"
        );

      const newDesignProperties =
        PropertiesSelector.getSelectedProperties(designSelectorState);

      const options = getOptions(
        state.sizes,
        newDesignProperties,
        PropertyValueSet.getAmount<Quantity.Length>(
          KnownProperties.altitude,
          state.climateSelectorState.climateSettings
        )!,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.atmosphericPressure,
          state.climateSelectorState.climateSettings
        )!
      );

      const numberOfUnitsOnSelectedSize = options.find(
        option => option.sizeValue === state.selectedSize
      )!.numberOfUnits;

      const hasNumberOfRequiredUnitsChanged =
        PropertyValueSet.getInteger(
          KnownProperties.numberOfNormalUnits,
          newDesignProperties
        ) !== numberOfUnitsOnSelectedSize;

      const hasDesignInputChanged = !PropertyValueSet.equals(
        PropertyValueSet.removeProperties(
          [
            KnownProperties.numberOfNormalUnits,
            KnownProperties.numberOfRedundantUnits
          ],
          PropertiesSelector.getSelectedProperties(state.designSelector)
        ),
        PropertyValueSet.removeProperties(
          [
            KnownProperties.numberOfNormalUnits,
            KnownProperties.numberOfRedundantUnits
          ],
          PropertiesSelector.getSelectedProperties(designSelectorState)
        )
      );

      const newDesignSelectorState =
        hasDesignInputChanged &&
        hasNumberOfRequiredUnitsChanged &&
        !action.isNumberOfUnitsSourceUser
          ? PropertiesSelector.init(
              PropertyValueSet.set(
                KnownProperties.numberOfNormalUnits,
                PropertyValue.fromInteger(numberOfUnitsOnSelectedSize ?? 0),
                newDesignProperties
              )
            )
          : designSelectorState;

      const opcSelectors = getUpdatedOpcSelectors(
        newDesignSelectorState,
        state.opcSelectors,
        state.climateSelectorState.climateSettings,
        state.sizes.find(s => s.sizeValue === state.selectedSize)!,
        action.isNumberOfUnitsSourceUser
      );

      const hasValuesChanged = getHasValuesChanged(
        state.initialSettings,
        newDesignSelectorState,
        opcSelectors,
        state.selectedSize
      );

      return [
        {
          ...state,
          options: options,
          designSelector: newDesignSelectorState,
          opcSelectors: opcSelectors,
          valuesHasChanged: hasValuesChanged
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchDesignSelector(
              cmdAction,
              action.isNumberOfUnitsSourceUser
            ),

          designSelectorCmd
        ),
        sharedAction
      ];
    }
    case "dispatchOpcSelector": {
      const opcSelector = state.opcSelectors.find(s => s.id === action.id);
      if (!opcSelector) {
        return [state];
      }

      const getUpdatedPropertyValueSet = (
        pvs: PropertyValueSet.PropertyValueSet,
        operatingsCases: PropertyValueSet.PropertyValueSet[]
      ) =>
        runValueSources(
          pvs,
          operatingsCases,
          state.climateSelectorState.climateSettings,
          action.productData.properties
        );

      const [newOpcSelectorState, opcSelectorCmd, opcSelectorSharedAction] =
        PropertiesSelector.update(
          action.action,
          opcSelector.state,
          sharedState,
          "SkipCalculateProperties",
          pvs =>
            getUpdatedPropertyValueSet(
              pvs,
              state.opcSelectors.map(fs =>
                PropertiesSelector.getSelectedProperties(fs.state)
              )
            )
        );

      const newOperatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet> =
        state.opcSelectors.map(p =>
          p.id === action.id
            ? PropertiesSelector.getSelectedProperties(newOpcSelectorState)
            : PropertiesSelector.getSelectedProperties(p.state)
        );

      const isCustomOperatingCase =
        PropertyValueSet.getInteger(
          KnownProperties.sortNo,
          PropertiesSelector.getSelectedProperties(opcSelector.state)
        )! > 1;

      const opcSelectors = state.opcSelectors.map(p =>
        p.id === action.id
          ? {
              id: action.id,
              state: isCustomOperatingCase
                ? getUpdatedCustomOpcSelector(
                    state.designSelector,
                    newOpcSelectorState,
                    state.climateSelectorState.climateSettings,
                    state.sizes.find(s => s.sizeValue === state.selectedSize)!
                  )
                : newOpcSelectorState
            }
          : {
              id: p.id,
              state: PropertiesSelector.init(
                runValueSources(
                  PropertiesSelector.getSelectedProperties(p.state),
                  newOperatingCases,
                  state.climateSelectorState.climateSettings,
                  action.productData.properties
                )
              )
            }
      );

      const hasValuesChanged = getHasValuesChanged(
        state.initialSettings,
        state.designSelector,
        opcSelectors,
        state.selectedSize
      );

      return [
        {
          ...state,
          opcSelectors: opcSelectors,
          valuesHasChanged: hasValuesChanged
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchOpcSelector(
              action.id,
              cmdAction,
              action.productData
            ),
          opcSelectorCmd
        ),
        opcSelectorSharedAction
      ];
    }
    case "onAddNewOperatingCase": {
      const opcSelectors = state.opcSelectors.concat([
        {
          id: Guid.guidToString(Guid.createGuid()),
          state: PropertiesSelector.init(
            runValueSources(
              PropertyValueSet.merge(
                PropertyValueSet.setText(
                  "casename",
                  "",
                  PropertyValueSet.fromProperty(
                    "sortno",
                    PropertyValue.fromInteger(state.opcSelectors.length)
                  )
                ),
                PropertyValueSet.filter(
                  kvp => !kvp.key.startsWith("source_"),
                  PropertiesSelector.getSelectedProperties(
                    state.opcSelectors[state.opcSelectors.length - 1].state
                  )
                )
              ),
              state.opcSelectors.map(pss =>
                PropertiesSelector.getSelectedProperties(pss.state)
              ),
              state.climateSelectorState.climateSettings,
              action.productDataProperties
            )
          )
        }
      ]);
      return [
        {
          ...state,
          opcSelectors: opcSelectors,
          valuesHasChanged: true
        }
      ];
    }
    case "onDeleteOperatingCase": {
      const newOperatingCases = state.opcSelectors
        .filter(pps => pps.id !== action.operatingCaseId)
        .map(pps => pps.state.properties);

      const opcSelectors: ReadonlyArray<{
        readonly id: string;
        readonly state: PropertiesSelector.State;
      }> = state.opcSelectors
        .filter(pps => pps.id !== action.operatingCaseId)
        .map((pps, ix) => ({
          id: pps.id,
          state: {
            ...pps.state,
            selectedProperties: runValueSources(
              PropertyValueSet.merge(
                PropertyValueSet.fromProperty(
                  "sortno",
                  PropertyValue.fromInteger(ix)
                ),
                pps.state.properties
              ),
              newOperatingCases,
              state.climateSelectorState.climateSettings,
              action.productDataProperties
            )
          }
        }));

      const hasValuesChanged = getHasValuesChanged(
        state.initialSettings,
        state.designSelector,
        opcSelectors,
        state.selectedSize
      );

      return [
        {
          ...state,
          opcSelectors: opcSelectors,
          valuesHasChanged: hasValuesChanged
        }
      ];
    }
    case "productDataReceived": {
      const sizeInfoPerformanceRows = parseSizeInfoPerformance(
        action.data.product.sys.sizeInfoPerformance
      );

      const rangeFilteredSysProductData =
        ProductDataTools.filterProductForRange(
          action.sysProduct,
          action.sysProperties
        );

      const sizeValues = rangeFilteredSysProductData.properties
        .find(p => p.name === KnownProperties.epxSize)!
        .items.filter(v =>
          PropertyFilter.isValid(action.sysProperties, v.validationFilter)
        )
        .map(v => v.value);

      const sizes: ReadonlyArray<Types.Size> = sizeValues.reduce(
        (soFar, current) => {
          const sizeInfoPerformanceRow = sizeInfoPerformanceRows.find(m =>
            PropertyFilter.isValid(
              PropertyValueSet.set(
                KnownProperties.epxSize,
                current,
                action.sysProperties
              ),
              m.propertyFilter
            )
          );

          return sizeInfoPerformanceRow !== undefined
            ? soFar.concat([
                {
                  sizeValue: PropertyValue.getInteger(current)!,
                  maxProcessFlow: sizeInfoPerformanceRow!.maxProcessFlow
                }
              ])
            : soFar;
        },
        [] as ReadonlyArray<Types.Size>
      );

      const options = getOptions(
        sizes,
        PropertiesSelector.getSelectedProperties(state.designSelector),
        PropertyValueSet.getAmount<Quantity.Length>(
          KnownProperties.altitude,
          state.climateSelectorState.climateSettings
        )!,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.atmosphericPressure,
          state.climateSelectorState.climateSettings
        )!
      );

      // This is done to select a size and update the design and opc selectors when
      // the system is fresh from the wizard and the values are not correct yet.
      const iteLoad = PropertyValueSet.getAmount<Quantity.Power>(
        KnownProperties.iteLoad,
        PropertiesSelector.getSelectedProperties(state.designSelector)
      )!;
      const numberOfUnitsOnCritialOpc = PropertyValueSet.getInteger(
        KnownProperties.numberOfNormalUnitsOperating,
        PropertiesSelector.getSelectedProperties(state.opcSelectors[0].state)
      )!;
      const targetPowerOnCriticalOpc = Amount.valueAs(
        Units.KiloWatt,
        PropertyValueSet.getAmount<Quantity.Power>(
          KnownProperties.targetPowerPerUnit,
          PropertiesSelector.getSelectedProperties(state.opcSelectors[0].state)
        )!
      );
      const whatTargetPowerOnCriticalOpcShouldBe =
        Amount.valueAs(Units.KiloWatt, iteLoad) / numberOfUnitsOnCritialOpc;
      const difference =
        targetPowerOnCriticalOpc / whatTargetPowerOnCriticalOpcShouldBe;

      if (difference > 1.05 || difference < 0.95) {
        const newDesignSelector = PropertiesSelector.init(
          PropertyValueSet.set(
            KnownProperties.numberOfNormalUnits,
            PropertyValue.fromInteger(options[0].numberOfUnits),
            PropertiesSelector.getSelectedProperties(state.designSelector)
          )
        );
        const opcSelectors = getUpdatedOpcSelectors(
          newDesignSelector,
          state.opcSelectors,
          state.climateSelectorState.climateSettings,
          sizes.find(s => s.sizeValue === options[0].sizeValue)!,
          false
        );
        return [
          {
            ...state,
            selectedSize: options[0].sizeValue,
            designSelector: newDesignSelector,
            opcSelectors: opcSelectors,
            valuesHasChanged: true,
            options: options,
            sizes: sizes
          }
        ];
      } else {
        return [
          {
            ...state,
            options: options,
            sizes: sizes
          }
        ];
      }
    }
    case "selectOption": {
      if (!action.option.numberOfUnits) {
        return [state];
      }

      const newDesignSelector = action.isNumberOfUnitsSourceUser
        ? state.designSelector
        : PropertiesSelector.init(
            PropertyValueSet.set(
              KnownProperties.numberOfNormalUnits,
              PropertyValue.fromInteger(action.option.numberOfUnits),
              PropertiesSelector.getSelectedProperties(state.designSelector)
            )
          );

      const opcSelectors = getUpdatedOpcSelectors(
        newDesignSelector,
        state.opcSelectors,
        state.climateSelectorState.climateSettings,
        state.sizes.find(s => s.sizeValue === action.option.sizeValue)!,
        action.isNumberOfUnitsSourceUser
      );

      const hasValuesChanged = getHasValuesChanged(
        state.initialSettings,
        newDesignSelector,
        opcSelectors,
        action.option.sizeValue
      );

      return [
        {
          ...state,
          selectedSize: action.option.sizeValue,
          designSelector: newDesignSelector,
          opcSelectors: opcSelectors,
          valuesHasChanged: hasValuesChanged
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfiguratorActions(
  state: State,
  sysComponent: System.Component | undefined,
  userAction: "Save" | "SaveAndCalculate"
): ReadonlyArray<ConfiguratorAction | undefined> {
  const designProperties = PropertiesSelector.getSelectedProperties(
    state.designSelector
  );
  const numberOfUnits = PropertyValueSet.get(
    KnownProperties.numberOfNormalUnits,
    designProperties
  );
  const sourceNumberOfUnits = PropertyValueSet.get(
    "source_" + KnownProperties.numberOfNormalUnits,
    designProperties
  );
  const numberOfRedundantUnits = PropertyValueSet.get(
    KnownProperties.numberOfRedundantUnits,
    designProperties
  );
  const iteLoad = PropertyValueSet.getAmount<Quantity.Power>(
    KnownProperties.iteLoad,
    designProperties
  );
  const supplyTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.supplyTargetTemperature,
    designProperties
  );

  const returnTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.returnAirTemperature,
    designProperties
  );

  if (
    !sysComponent ||
    !numberOfUnits ||
    !numberOfRedundantUnits ||
    !iteLoad ||
    !sourceNumberOfUnits ||
    !supplyTargetTemp ||
    !returnTargetTemp
  ) {
    return [undefined];
  }

  const totalDataCenterAirflow = getTotalVolumeFlow(
    iteLoad,
    supplyTargetTemp,
    returnTargetTemp,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.altitude,
      state.climateSelectorState.climateSettings
    )!,
    1
  );

  const sysProperties = PropertyValueSet.set(
    KnownProperties.epxSize,
    PropertyValue.fromInteger(state.selectedSize),
    PropertyValueSet.set(
      KnownProperties.numberOfNormalUnits,
      numberOfUnits,
      PropertyValueSet.set(
        KnownProperties.numberOfRedundantUnits,
        numberOfRedundantUnits,
        PropertyValueSet.set(
          KnownProperties.numberOfUnitsTotal,
          PropertyValue.fromInteger(
            PropertyValue.getInteger(numberOfUnits)! +
              PropertyValue.getInteger(numberOfRedundantUnits)!
          ),
          PropertyValueSet.setAmount<Quantity.VolumeFlow>(
            KnownProperties.totalDataCenterAirflow,
            totalDataCenterAirflow,
            PropertyValueSet.setAmount<Quantity.Power>(
              KnownProperties.iteLoad,
              iteLoad,
              PropertyValueSet.set(
                "source_" + KnownProperties.numberOfNormalUnits,
                sourceNumberOfUnits,
                sysComponent.properties
              )
            )
          )
        )
      )
    )
  );

  const operatingCases = state.opcSelectors.map(opcs => ({
    id: opcs.id,
    settings: PropertiesSelector.getSelectedProperties(opcs.state)
  }));

  const saveActions: ReadonlyArray<ConfiguratorAction> = [
    ConfiguratorAction.updateComponentsPropertiesAndSaveOperatingCases(
      [
        {
          componentId: sysComponent.id,
          properties: sysProperties
        }
      ],
      state.climateSelectorState.climateSettings,
      operatingCases,
      userAction === "SaveAndCalculate"
    )
  ];

  return saveActions;
}

//tslint:disable-next-line
