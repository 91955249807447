import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyGroup } from "./types";
import * as PropertiesSelector from "../../../properties-selector";
import * as System from "../../system";
import * as Product from "../../product";
import { getInitalGroupFilter, getInitialPropertyGroups } from "./functions";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import { PropertyValueSet } from "@genesys/property";

export type State = {
  readonly collapsed: {
    readonly [componentId: string]: true | undefined;
  };
  readonly groupFilter: ReadonlyArray<string>;
  readonly propertyGroups: ReadonlyArray<PropertyGroup>;
  readonly valuesHasChanged: boolean;
};

export const init = (
  system: System.System,
  systemType: Product.SystemType,
  products: ReadonlyArray<Product.Product>
): [State] => {
  return [
    {
      collapsed: {},
      groupFilter: getInitalGroupFilter(system, systemType),
      propertyGroups: getInitialPropertyGroups(system, systemType, products),
      valuesHasChanged: false
    }
  ];
};

export const Action = ctorsUnion({
  toggleCollapsed: (componentId: string) => ({ componentId }),
  clearGroupFilter: () => ({}),
  toggleGroupFilter: (groupFilter: string) => ({
    groupFilter
  }),
  dispatchPropertiesSelector: (
    systemId: string,
    componentId: string,
    action: PropertiesSelector.Action
  ) => ({ systemId, componentId, action })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "toggleCollapsed": {
      return [
        {
          ...state,
          collapsed: {
            ...state.collapsed,
            [action.componentId]: state.collapsed[action.componentId]
              ? undefined
              : true
          }
        }
      ];
    }
    case "clearGroupFilter": {
      return [{ ...state, groupFilter: [] }];
    }
    case "toggleGroupFilter": {
      const newGroupFilter = state.groupFilter.includes(action.groupFilter)
        ? state.groupFilter.filter(gf => gf !== action.groupFilter)
        : state.groupFilter.concat(action.groupFilter);

      return [{ ...state, groupFilter: newGroupFilter }];
    }
    case "dispatchPropertiesSelector": {
      const propertiesSelector = state.propertyGroups.find(
        p => p.component.id === action.componentId
      );
      if (!propertiesSelector) {
        return [state];
      }

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        propertiesSelector.state,
        sharedState,
        {
          type: "Component",
          componentId: action.componentId,
          systemId: action.systemId
        }
      );

      const newProperties = state.propertyGroups.map(p =>
        p.component.id !== action.componentId
          ? p
          : { ...p, state: propertiesSelectorState }
      );

      return [
        {
          ...state,
          valuesHasChanged: true,
          propertyGroups: newProperties
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchPropertiesSelector(
              action.systemId,
              action.componentId,
              cmdAction
            ),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfigurationActions(
  state: State,
  type: "save" | "saveAndCalculate"
): ReadonlyArray<ConfiguratorAction> {
  const components: Array<{
    readonly componentId: string;
    readonly properties: PropertyValueSet.PropertyValueSet;
  }> = [];

  state.propertyGroups.forEach(p => {
    if (!PropertyValueSet.equals(p.state.properties, p.component.properties)) {
      components.push({
        componentId: p.component.id,
        properties: PropertiesSelector.getSelectedProperties(p.state)
      });
    }
  });

  const configurationActions: ReadonlyArray<ConfiguratorAction> =
    components.length > 0
      ? [
          ConfiguratorAction.updateComponentProperties(
            components,
            type === "saveAndCalculate"
          )
        ]
      : [];

  return configurationActions;
}
