import styled from "styled-components";
import { BareUl } from "../lists/unstyled-list";
// tslint:disable-next-line
export const SubMenu = styled(BareUl)`
  position: absolute;
  display: block;
  left: 100%;
  top: 0;

  background: linear-gradient(to bottom, #eef0ec, #fcfcfc 80%);

  box-shadow: 5px 5px 5px #000;

  border: 1px solid #000;

  padding: 3px 0;

  li {
    white-space: nowrap;

    font-weight: 100;
    color: black;

    &:hover {
      cursor: pointer;
      background: linear-gradient(to top, rgb(84, 192, 242), rgb(0, 168, 235));
      color: white;
    }
  }
`;
