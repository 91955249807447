import * as React from "react";
import * as Downloader from "./downloader";

interface Props {
  readonly csvData: ReadonlyArray<ReadonlyArray<string>>;
  readonly delimiter: string;
  readonly fileName: string;
  readonly children: (onClick: () => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function CsvExportContainer(props: Props) {
  const data = props.csvData.reduce(
    (a, b) => `${a}${b.join(props.delimiter)}\r\n`,
    ""
  );
  return (
    <Downloader.Downloader
      data={data}
      children={props.children}
      fileName={props.fileName}
    />
  );
}
