import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const SelectBox = styled.select`
  /* reset border */
  border: none;
  border: 1px solid ${Colors.tabNavBorderColor};
  background-image: linear-gradient(to top, #e0e0e0, white 60%);
  border-radius: 4px;
  padding: 5px;

  /* -webkit-appearance: none;
  -moz-appearance: none; */
  &::-ms-expand {
    border: none;
    background: transparent;
  }
`;
