import * as React from "react";
import {
  SystemInfoContainer,
  SystemInfoTextSection,
  SystemInfoImage,
  PricingLabel,
  MoistureLoadLabel
} from "../elements";
import { getSystemTypeStatusText } from "@genesys/shared/lib/system-status-text";
import { P2, Label } from "@genesys/ui-elements";
import { SystemInfoMenu, SystemInfoMenuProps } from "./system-info-menu";
import { System } from "../system";
import { SystemType } from "../product";
import { PropertyValueSet } from "@genesys/property";
import * as SharedState from "../../shared-state";

export interface SystemInfoProps {
  readonly img: string | undefined;
  readonly genesysNo: string;
  readonly accessToken: string;
  readonly showAdjustPrice: boolean;
  readonly showSystemPrintoutScreen: boolean;
  readonly systemInfoMenuProps: SystemInfoMenuProps;
  readonly system: System;
  readonly systemType: SystemType;
  readonly sharedState: SharedState.State;
}

export function SystemInfo(props: SystemInfoProps): JSX.Element {
  const pricing = props.system.pricingInformation;

  const createPricingLabel = () => {
    return pricing.map((x, i) => {
      const pricingNo = props.sharedState.genesysPrefix.pricingNo(
        x.pricingNo,
        x.revisionNo
      );

      return (
        <a target="_blank" href={`/pricing/${pricingNo}`}>
          {pricingNo}
          {i < pricing.length - 1 ? ", " : null}
        </a>
      );
    });
  };

  const createMoistureLoadLabel = () => {
    if (!props.system.moistureLoadInfo) {
      return null;
    }
    const mNo = props.sharedState.genesysPrefix.moistureLoadNo(
      props.system.moistureLoadInfo.moistureLoadNo,
      props.system.moistureLoadInfo.revisionNo
    );
    return (
      <a target="_blank" href={`/moisture-load/${mNo}`}>
        {mNo}
      </a>
    );
  };

  return (
    <SystemInfoContainer>
      {props.img && (
        <SystemInfoImage accessToken={props.accessToken} src={props.img} />
      )}
      <SystemInfoTextSection>
        <P2 weight="bold">
          {props.system.file.systemTypeId} {props.genesysNo}
        </P2>
        <Label weight="normal">{props.system.file.name}</Label>
        <Label weight="normal">{props.system.salesOrganisationId}</Label>
        {props.system.moistureLoadInfo && (
          <MoistureLoadLabel weight="normal">
            {createMoistureLoadLabel()}
          </MoistureLoadLabel>
        )}
        <Label weight="normal">
          {getSystemTypeStatusText(
            props.sharedState.translate,
            props.system.components.map(c => ({
              productId: c.productId,
              properties: PropertyValueSet.toString(c.properties)
            })),
            {
              id: props.systemType.id,
              plenumSizeProperty: props.systemType.plenumSizeProperty
            }
          )}
        </Label>
        <PricingLabel weight="normal">{createPricingLabel()}</PricingLabel>
      </SystemInfoTextSection>
      <SystemInfoMenu {...props.systemInfoMenuProps} />
    </SystemInfoContainer>
  );
}
