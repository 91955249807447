import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import * as SystemActions from "../system-actions";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as SystemStatusText from "./system-status";
import * as Navigation from "../navigation-effect-manager";

type SystemActionsState = {
  readonly coordinates: { readonly x: number; readonly y: number } | undefined;
  readonly state: SystemActions.State;
};

export type State = {
  readonly isRevisionDropdownOpen: boolean;
  readonly userSelectedRevision: string;
  readonly systemActionsState: SystemActionsState | undefined;
  readonly systemStatusTextState: SystemStatusText.State;
};

export const init = (
  systemId: string,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  const [systemStatusTextState, systemStatusTextCmd] = SystemStatusText.init(
    systemId,
    sharedState
  );
  return [
    {
      isRevisionDropdownOpen: false,
      systemActionsState: undefined,
      userSelectedRevision: systemId,
      systemStatusTextState
    },
    Cmd.map(Action.dispatchSystemStatusText, systemStatusTextCmd)
  ];
};

export const Action = ctorsUnion({
  dispatchSystemActions: (action: SystemActions.Action) => ({ action }),
  dispatchSystemStatusText: (action: SystemStatusText.Action) => ({ action }),
  toggleOpenRevisonDropdown: () => ({}),
  toggleSystemActions: (coordinates?: {
    readonly x: number;
    readonly y: number;
  }) => ({
    coordinates
  }),
  toggleFavorites: (systemId: string) => ({ systemId }),
  changeActiveRevision: (systemId: string) => ({
    systemId
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, ReadonlyArray<SharedState.Action | undefined>?] {
  switch (action.type) {
    case "toggleSystemActions": {
      if (state.systemActionsState) {
        return [{ ...state, systemActionsState: undefined }];
      }
      const [systemActionsState] = SystemActions.init();
      return [
        {
          ...state,
          systemActionsState: {
            coordinates: action.coordinates,
            state: systemActionsState
          }
        }
      ];
    }

    case "toggleOpenRevisonDropdown": {
      return [
        { ...state, isRevisionDropdownOpen: !state.isRevisionDropdownOpen }
      ];
    }
    case "dispatchSystemStatusText": {
      const [systemStatusTextState, systemStatusTextCmd] =
        SystemStatusText.update(
          action.action,
          state.systemStatusTextState,
          sharedState
        );
      return [
        { ...state, systemStatusTextState },
        Cmd.map(Action.dispatchSystemStatusText, systemStatusTextCmd)
      ];
    }
    case "dispatchSystemActions": {
      if (!state.systemActionsState) {
        return [state];
      }
      const [
        systemActionsState,
        SystemActionsCmd,
        sharedStateActions,
        isDone,
        url
      ] = SystemActions.update(
        action.action,
        state.systemActionsState.state,
        sharedState
      );
      if (isDone) {
        return [
          {
            ...state,
            systemActionsState: undefined
          },
          url ? Navigation.pushUrl(url.url) : undefined,
          [
            ...(sharedStateActions || []),
            SharedState.Action.loadLastOpenedSystemsAndFavorites(true)
          ]
        ];
      } else {
        return [
          {
            ...state,
            systemActionsState: {
              ...state.systemActionsState,
              state: systemActionsState
            }
          },
          Cmd.map(Action.dispatchSystemActions, SystemActionsCmd),
          sharedStateActions
        ];
      }
    }
    case "toggleFavorites": {
      return [
        state,
        undefined,
        [SharedState.Action.toggleFavoritesSystems(action.systemId)]
      ];
    }
    case "changeActiveRevision": {
      const [systemStatusTextState, systemStatusTextCmd] =
        SystemStatusText.init(action.systemId, sharedState);
      return [
        {
          ...state,
          userSelectedRevision: action.systemId,
          isRevisionDropdownOpen: false,
          systemStatusTextState
        },
        Cmd.map(Action.dispatchSystemStatusText, systemStatusTextCmd)
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
