import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Quantity, Unit } from "@genesys/uom";
import * as SharedState from "../../shared-state";

export type State = {
  readonly selectedOpCaseId: string | undefined;
};

export const init = (): State => {
  return { selectedOpCaseId: undefined };
};

export const Action = ctorsUnion({
  SelectOpCase: (id: string) => ({ id }),
  FormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  FormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, SharedState.Action?] {
  switch (action.type) {
    case "SelectOpCase": {
      return [
        {
          ...state,
          selectedOpCaseId: action.id
        }
      ];
    }
    case "FormatChanged": {
      return [
        state,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "FormatCleared": {
      return [
        state,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
