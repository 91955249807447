import * as React from "react";
import * as Authorization from "@genesys/shared/lib/authorization";
import { MenuDots, IconButton, Confirm } from "@genesys/ui-elements";
import {
  SystemInfoMenuContainer,
  SystemInfoLabel,
  SystemActionsContainer,
  SystemInfoMenuNotes,
  SystemInfoMenuLabels,
  SystemInfoMenuHeader,
  SystemInfoMenuNote,
  SystemInfoMenuRoot
} from "../elements";
import { System } from "../system";
import { SystemType } from "../product";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemTypeNotes from "../system-type-notes/notes";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import * as SystemActions from "../../system-actions";

export interface SystemInfoMenuProps {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly systemActionsState: SystemActions.State | undefined;
  readonly actionCompleteUrl: SystemActions.ActionCompleteUrl | undefined;
  readonly system: System;
  readonly systemType: SystemType;
  readonly toggleSystemActions: () => void;
}

export function SystemInfoMenu(props: SystemInfoMenuProps): JSX.Element {
  const header = (name: string) => (
    <SystemInfoMenuHeader>{name}</SystemInfoMenuHeader>
  );
  const menuLabel = (id: string, name: string) => (
    <SystemInfoLabel key={id}>{name}</SystemInfoLabel>
  );
  const note = (id: string, text: string) => (
    <SystemInfoMenuNote key={id}>{text}</SystemInfoMenuNote>
  );

  const maybeToggleSystemActions = () => {
    if (!props.systemActionsState) {
      props.toggleSystemActions();
    } else if (
      props.systemActionsState &&
      SystemActions.isInMenu(props.systemActionsState)
    ) {
      props.toggleSystemActions();
    } else {
      return;
    }
  };

  const myRef = React.useRef<HTMLAnchorElement>(null);

  return (
    <SystemInfoMenuRoot>
      <a
        ref={myRef}
        onMouseEnter={() => maybeToggleSystemActions()}
        onMouseLeave={() => maybeToggleSystemActions()}
      >
        <IconButton icon={<MenuDots height="1.6rem" />}></IconButton>
        {props.systemActionsState && (
          <SystemActionsContainer>
            <SystemActions.SystemActionsView
              state={props.systemActionsState}
              // sharedState={props.sharedState}
              dispatch={Dispatch.map(
                Action.dispatchSystemActions,
                props.dispatch
              )}
              systemActions={createSystemActions(props, props.system.id)}
            />

            {SystemActions.isInMenu(props.systemActionsState) && (
              <SystemInfoMenuContainer>
                <SystemInfoMenuLabels>
                  {header(props.sharedState.translate(LanguageTexts.labels()))}
                  {props.system.file.labels.map(l => menuLabel(l.id, l.name))}
                </SystemInfoMenuLabels>
                <SystemInfoMenuNotes>
                  {header(props.sharedState.translate(LanguageTexts.notes()))}
                  {SystemTypeNotes.notes(
                    props.system,
                    props.systemType,
                    props.sharedState.translate
                  ).map(n => note(n, n))}
                </SystemInfoMenuNotes>
              </SystemInfoMenuContainer>
            )}
          </SystemActionsContainer>
        )}
      </a>
      {props.actionCompleteUrl && (
        <Confirm
          cancelTranslated={props.sharedState.translate(
            LanguageTexts.openInCurrentTab()
          )}
          confirmTranslated={props.sharedState.translate(
            LanguageTexts.openInNewTab()
          )}
          headerText={
            props.actionCompleteUrl.type === "copy-transfer"
              ? props.sharedState.translate(
                  LanguageTexts.copyOfSystemCompletedMsg()
                )
              : props.sharedState.translate(
                  LanguageTexts.revisionCompletedMsg()
                )
          }
          labelRef={myRef}
          showDialog={!!props.actionCompleteUrl}
          onConfirm={() => {
            props.dispatch(
              Action.openSystemConfirmation(props.actionCompleteUrl!.url!, true)
            );
          }}
          onCancel={() =>
            props.dispatch(
              Action.openSystemConfirmation(
                props.actionCompleteUrl!.url!,
                false
              )
            )
          }
        />
      )}
    </SystemInfoMenuRoot>
  );
}

function createSystemActions(props: SystemInfoMenuProps, revisionId: string) {
  return SystemActions.createActions(
    {
      dispatch: Dispatch.map(Action.dispatchSystemActions, props.dispatch),
      sharedState: props.sharedState,
      state: props.systemActionsState!,
      shouldOpenNewRevision: true,
      revisionId,
      systemFile: {
        id: props.system.file.id,
        name: props.system.file.name,
        owner: props.system.file.owner.name,
        labels: props.system.file.labels,
        systems: props.system.file.systems,
        genesysNo: props.system.file.genesysNo
      }
    },
    [
      SystemActions.saveAndCalculateAction,
      SystemActions.lockRevisionAction,
      SystemActions.renameAction,
      SystemActions.editCommentAction,
      SystemActions.assignLabelsAction,
      SystemActions.createNewRevisionAction,
      SystemActions.copyTransferOpenAction,
      ...(Authorization.checkPermission(
        props.sharedState.user.applicationClaims,
        Authorization.genesysPricingClaims.canUsePricing
      )
        ? [SystemActions.createPricingAction]
        : []),

      ...(Authorization.checkPermission(
        props.sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.developer
      )
        ? [SystemActions.exportSystemAction]
        : [])
    ]
  );
}
