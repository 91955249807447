export type Action = ToggleGroup | ToggleQuantitySelector;

export interface ToggleGroup {
  readonly type: "genesys-properties-selector/TOGGLE_GROUP";
  readonly propertiesSelectorId: string;
  readonly groupName: string;
}
export function toggleGroup(
  propertiesSelectorId: string,
  groupName: string
): ToggleGroup {
  return {
    type: "genesys-properties-selector/TOGGLE_GROUP",
    propertiesSelectorId,
    groupName
  };
}

export interface ToggleQuantitySelector {
  readonly type: "genesys-properties-selector/TOGGLE_QUANTITY_SELECTOR";
  readonly propertiesSelectorId: string;
  readonly propertyName: string;
}
export function toggleQuantitySelector(
  propertiesSelectorId: string,
  propertyName: string
): ToggleQuantitySelector {
  return {
    type: "genesys-properties-selector/TOGGLE_QUANTITY_SELECTOR",
    propertiesSelectorId,
    propertyName
  };
}
