import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as Types from "../types";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as System from "../../../../system";
import styled from "styled-components";
import { BinListView } from "./bin-list";
import { BinChartView } from "./bin-chart";
import { GenesysSelect } from "@genesys/ui-elements";
import { Action, State } from "../state";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div``;
const FlexContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;

  > * {
    margin-right: 5px;
  }
`;

const binVisualizers: ReadonlyArray<Types.BinVisualizer> = ["List", "Chart"];

export function BinCasesView({
  sharedState,
  binCases,
  system,
  state,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly system: System.System;
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase>;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;
  return (
    <Container>
      <FlexContainer>
        <span>{translate(LanguageTexts.viewAs())}</span>
        <GenesysSelect
          width={6}
          height={33}
          fontSize={13}
          options={binVisualizers.map(b => ({
            value: b,
            title: b
          }))}
          onChange={e => {
            dispatch(
              Action.setBinVisualizer(e.target.value as Types.BinVisualizer)
            );
          }}
          value={state.binVisualizer}
        />
      </FlexContainer>
      {state.binVisualizer === "List" ? (
        <BinListView
          system={system}
          binCases={binCases}
          selectedBinListView={state.binListView}
          sharedState={sharedState}
          dispatch={dispatch}
        />
      ) : (
        <BinChartView
          state={state}
          dispatch={dispatch}
          sharedState={sharedState}
          selectedChartType={state.chartType}
        />
      )}
    </Container>
  );
}
