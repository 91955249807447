import { Dispatch } from "@typescript-tea/core";
import { Action, State, PaperSizeInfo, Report, ReportState } from "./state";
import * as PaperSizeEnum from "@genesys/shared/lib/enums/paper-size";

import * as System from "../../system";
import * as SharedState from "../../../shared-state";

export const userParamRegex = /([^=]+)=(.+)/;

export const paperSizes: Array<PaperSizeInfo> = [
  {
    size: PaperSizeEnum.PaperSize.A4,
    displayName: "paper_a4"
  },
  {
    size: PaperSizeEnum.PaperSize.Letter,
    displayName: "paper_letter"
  }
];

export const headerLabels = [
  { header: undefined },
  { header: "notreleased" },
  { header: "preliminary" },
  { header: "confidential" }
];

export function getParams(reportParameters: any): string {
  return reportParameters
    ? Object.keys(reportParameters)
        .reduce(
          (a, b) =>
            reportParameters![b] !== undefined
              ? a +
                (Array.isArray(reportParameters![b])
                  ? (reportParameters![b] as Array<string>).reduce(
                      (aa, bb: string) => aa + "&" + b + "=" + bb,
                      ""
                    )
                  : "&" + b + "=" + reportParameters![b])
              : a,
          ""
        )
        .replace("&", "?")
    : "";
}

export function arraysContainSameElements<T>(
  array1: T[],
  array2: T[]
): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  // Create copies of the arrays and sort them
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  // Compare sorted arrays
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
}
const getUserSelectedReportsForSystemType = (
  sharedState: SharedState.State,
  system: System.System
) =>
  sharedState.user.settings.reportSettings.find(
    x => x.systemType === system.file.systemTypeId
  )?.ids;

export const getUserSelectedReports = (
  sharedState: SharedState.State,
  system: System.System,
  visibleReports: ReadonlyArray<Report>
): Report[] | undefined => {
  const userSelectedReportsForSystemType = getUserSelectedReportsForSystemType(
    sharedState,
    system
  );

  if (!userSelectedReportsForSystemType) {
    return undefined;
  }
  return userSelectedReportsForSystemType.reduce<Report[]>((acc, r) => {
    const report = visibleReports.find(fr => fr.id === r);
    if (report) {
      acc.push(report);
    }
    return acc;
  }, []);
};

const getValuesFromReportStateMap = (
  reportStateMap: Map<string, ReportState>
) => {
  const values = Array.from(reportStateMap.values());
  if (values.length === 0) {
    return undefined;
  }

  return values;
};

export const getSelectedReports = (
  state: State,
  system: System.System,
  userPreSelectedReports?: ReadonlyArray<Report>
): ReadonlyArray<ReportState> =>
  getValuesFromReportStateMap(state.reportStateMap || new Map())?.filter(
    x => x.isSelected
  ) ||
  userPreSelectedReports
    ?.filter(
      r => system.status >= r.status && !additionalReportCheck(r, system)
    )
    .map(r => ({
      id: r.id,
      userParams: getDefaultUserParamValueForReport({
        id: r.id,
        userParams: r.userParams
      }),
      isSelected: true
    })) ||
  [];

export const toggleAll = (
  isAllSelected: boolean,
  selectableReports: ReadonlyArray<Report>,
  selectedReports: ReadonlyArray<ReportState>,
  reportsStateMap: Map<string, ReportState> | undefined,
  dispatch: Dispatch<Action>
) => {
  const newReportStateMap =
    reportsStateMap || new Map(selectedReports.map(x => [x.id, x]));

  dispatch(
    Action.setReportsState(
      new Map(
        selectableReports.map(sr => {
          const reportState = newReportStateMap.get(sr.id);
          const obj = reportState || {
            id: sr.id,
            userParams: getDefaultUserParamValueForReport({
              id: sr.id,
              userParams: sr.userParams
            })
          };
          return [
            sr.id,
            {
              ...obj,
              isSelected: !isAllSelected
            }
          ];
        })
      )
    )
  );
};

export function getDefaultUserParamValueForReport(report: {
  readonly id: string;
  readonly userParams: string | null | undefined;
}) {
  const input = report.userParams || "";
  const match = input.match(userParamRegex);

  if (!match) {
    return "";
  }

  const userParamName = match[1];
  const paramValue = match[2].split(",")[0];

  return `${userParamName}=${paramValue}`;
}

export const additionalReportCheck = (
  report: Report,
  system: System.System
): boolean => {
  if (report.name === "MoistureLoad") {
    return !system.moistureLoadInfo;
  } else if (report.name === "Energy") {
    return !system.operatingCases.find(x => x.caseType === 2);
  }
  return false;
};
