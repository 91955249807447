import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const createNewSystemRevisionMutation = gql`
  mutation CreateNewSystemRevision($systemFileId: ID!, $comment: String!) {
    createNewSystemRevision(systemFileId: $systemFileId, comment: $comment) {
      ...CompleteSystemFile
    }
  }
  ${Fragments.completeSystemFile}
`;
