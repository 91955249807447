import { isDesktop } from "react-device-detect";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import * as Guid from "@genesys/shared/lib/guid";
import * as SystemActions from "../system-actions";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemSummary from "../system-summary";
import { queryUser, queryProduct } from "./queries";
import {
  SearchParams,
  Modal,
  ViewMode,
  SystemActionsState,
  SystemsPerEnum,
  OrderByColumn
} from "./types";
import { deleteSystemFileMutation } from "@genesys/client-core/lib/graphql-mutations";
import { LabelInputState } from "../shared-manager-components";
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type State = {
  readonly queryUserResult: GraphQLTypes.SystemManagerUser | undefined;
  readonly queryProductResult: GraphQLTypes.SystemManagerProduct | undefined;
  readonly searchParams: SearchParams;
  readonly selectedSystemIds: ReadonlyArray<string>;
  readonly expandedRevisions: ReadonlyArray<string>;
  readonly isSearchFilterOptionsOpen: boolean;
  readonly currentPage: number;
  readonly Modal: Modal | undefined;
  readonly labelInputState: LabelInputState;
  readonly openLabelPopup: string | undefined;
  readonly systemActionsState: SystemActionsState | undefined;
  readonly systemSummaryState: SystemSummary.State | undefined;
  readonly systemActionIsCompleteUrl:
    | SystemActions.ActionCompleteUrl
    | undefined;
  readonly viewMode: ViewMode;
  readonly systemsPerPage: SystemsPerEnum;
  readonly userSelectedRevisions: {
    readonly [systemId: string]: string | undefined;
  };
  readonly shouldUpdateSearch: boolean;
};

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const defualtOrderByColumn = {
    name: "date-changed" as OrderByColumn,
    isDescending: true
  };
  return [
    {
      searchParams: {
        scope: sharedState.user.searchScope as GraphQLTypes.SystemSearchScope,
        searchKey: "",
        orderByColumns: [defualtOrderByColumn]
      },
      queryUserResult: undefined,
      queryProductResult: undefined,
      selectedSystemIds: [],
      expandedRevisions: [],
      isSearchFilterOptionsOpen: false,
      currentPage: 1,
      Modal: undefined,
      labelInputState: undefined,
      openLabelPopup: undefined,
      systemActionsState: undefined,
      systemActionIsCompleteUrl: undefined,
      viewMode: isDesktop ? "table" : "grid",
      systemsPerPage: SystemsPerEnum.sixteen,
      userSelectedRevisions: {},
      shouldUpdateSearch: false,
      systemSummaryState: undefined
    },

    Cmd.batch<Action>([
      sharedState.graphQL.queryUserCmd<
        GraphQLTypes.SystemManagerUser,
        GraphQLTypes.SystemManagerUserVariables,
        Action
      >(
        queryUser,
        {
          filter: {
            startRow: 0,
            endRow: 16,
            searchKey: "",
            searchScope: sharedState.user
              .searchScope as GraphQLTypes.SystemSearchScope,
            orderByColumns: [defualtOrderByColumn]
          }
        },
        data => Action.queryUserDataReceived(data)
      ),
      sharedState.graphQL.queryProductCmd<
        GraphQLTypes.SystemManagerProduct,
        GraphQLTypes.SystemManagerProductVariables,
        Action
      >(queryProduct, {}, Action.queryProductDataReceived)
    ]),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  queryUserDataReceived: (data: GraphQLTypes.SystemManagerUser) => ({
    data
  }),
  queryProductDataReceived: (data: GraphQLTypes.SystemManagerProduct) => ({
    data
  }),
  selectAllSystems: () => ({}),
  clearAllSelectedSystems: () => ({}),
  toggleSystemSelected: (systemId: string) => ({ systemId }),
  toggleFavoriteSystem: (systemId: string) => ({ systemId }),
  toggleRevision: (resultId: string) => ({ resultId }),
  toggleOpenSystemActions: (systemId: string) => ({ systemId }),
  setSearchParams: (searchParams: SearchParams) => ({ searchParams }),
  search: (
    messageOnDone?: SharedState.AlertMessage,
    setCurrentPage?: number
  ) => ({ messageOnDone, setCurrentPage }),
  toggleIsSearchFilterOptionsOpen: (isOpen: boolean) => ({ isOpen }),
  clearSearchFilter: () => ({}),
  changePage: (pageNumber: number) => ({ pageNumber }),
  deleteSystems: (
    systemFileIds: string[],
    messageOnDone: SharedState.AlertMessage
  ) => ({ systemFileIds, messageOnDone }),
  openModal: (modal: Modal) => ({
    modal
  }),
  closeModal: () => ({}),
  setLabelInputState: (state: LabelInputState) => ({ state }),
  createLabel: (labelName: string) => ({ labelName }),
  updateLabel: (labelId: string, labelName: string) => ({ labelId, labelName }),
  deleteLabel: (labelId: string) => ({ labelId }),
  toggleOpenLabelPopup: (labelId: string | undefined) => ({ labelId }),
  openSystemConfirmation: (url: string, openInNewTab: boolean) => ({
    url,
    openInNewTab
  }),
  setViewMode: (viewMode: ViewMode) => ({ viewMode }),
  updateUserSelectedRevisions: (systemId: string, revisionId: string) => ({
    systemId,
    revisionId
  }),
  dispatchSystemActions: (action: SystemActions.Action) => ({ action }),
  dispatchSystemSummary: (action: SystemSummary.Action) => ({ action }),
  setSystemsPerPage: (systemsPerPage: SystemsPerEnum) => ({ systemsPerPage })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "queryUserDataReceived": {
      const getSearchFilterOptionsFromSearchKey = (searchKey: string) => {
        const options: Record<string, string> = {};

        const regex = /(\w+):"([^"]+)"/g;
        let match;
        // tslint:disable-next-line
        while ((match = regex.exec(searchKey)) !== null) {
          const [, key, value] = match;
          options[key] = value.trim();
        }

        return options;
      };

      const searchFilterOptions = getSearchFilterOptionsFromSearchKey(
        state.searchParams.searchKey
      );

      if (
        // making revisions expanded when searching for a specific system revision
        searchFilterOptions["gno"] !== undefined &&
        searchFilterOptions["gno"].includes("-") &&
        action.data.user.searchSystems.totalResults === 1
      ) {
        const systemFileId = action.data.user.searchSystems.results[0].id;
        return [
          {
            ...state,
            queryUserResult: action.data,
            expandedRevisions: [systemFileId]
          }
        ];
      } else {
        return [
          {
            ...state,
            queryUserResult: action.data
          }
        ];
      }
    }
    case "queryProductDataReceived": {
      return [{ ...state, queryProductResult: action.data }];
    }
    case "toggleSystemSelected": {
      const newSelectedSystemIds = state.selectedSystemIds.includes(
        action.systemId
      )
        ? state.selectedSystemIds.filter(ssid => ssid !== action.systemId)
        : state.selectedSystemIds.concat(action.systemId);

      return [{ ...state, selectedSystemIds: newSelectedSystemIds }];
    }
    case "selectAllSystems": {
      let newSelectedSystemIds: Array<string> = [];
      state.queryUserResult!.user.searchSystems.results.forEach(result =>
        result.systems.forEach(system => newSelectedSystemIds.push(system.id))
      );
      return [
        {
          ...state,
          selectedSystemIds: newSelectedSystemIds
        }
      ];
    }
    case "clearAllSelectedSystems": {
      return [{ ...state, selectedSystemIds: [] }];
    }
    case "toggleFavoriteSystem": {
      return [
        state,
        undefined,
        [SharedState.Action.toggleFavoritesSystems(action.systemId)]
      ];
    }
    case "toggleRevision": {
      const newExpandedRevisions = state.expandedRevisions.includes(
        action.resultId
      )
        ? state.expandedRevisions.filter(rev => rev !== action.resultId)
        : state.expandedRevisions.concat(action.resultId);

      return [{ ...state, expandedRevisions: newExpandedRevisions }];
    }
    case "toggleOpenSystemActions": {
      if (state.systemActionsState) {
        return [{ ...state, systemActionsState: undefined }];
      }
      const [systemActionsState] = SystemActions.init();
      return [
        {
          ...state,
          systemActionsState: {
            systemId: action.systemId,
            state: systemActionsState
          }
        }
      ];
    }
    case "setSearchParams": {
      if (action.searchParams.scope !== state.searchParams.scope) {
        return [
          { ...state, searchParams: action.searchParams },
          undefined,
          [
            SharedState.Action.updateSearchScope(
              action.searchParams.scope as string
            )
          ]
        ];
      }

      return [{ ...state, searchParams: action.searchParams }];
    }
    case "search": {
      return [
        {
          ...state,
          currentPage: action.setCurrentPage || state.currentPage,
          queryUserResult: undefined,
          expandedRevisions: [],
          shouldUpdateSearch: false
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemManagerUser,
          GraphQLTypes.SystemManagerUserVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow:
                (action.setCurrentPage || state.currentPage) *
                  state.systemsPerPage -
                state.systemsPerPage,
              endRow:
                (action.setCurrentPage || state.currentPage) *
                state.systemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumns: state.searchParams.orderByColumns
            }
          },
          data => Action.queryUserDataReceived(data)
        ),
        [
          action.messageOnDone
            ? SharedState.Action.addAlertMessageToQueue(action.messageOnDone)
            : undefined
        ]
      ];
    }
    case "toggleIsSearchFilterOptionsOpen": {
      return [
        {
          ...state,
          isSearchFilterOptionsOpen: action.isOpen
        }
      ];
    }
    case "clearSearchFilter": {
      return [
        {
          ...state,
          searchParams: {
            ...state.searchParams,
            searchKey: ""
          }
        }
      ];
    }
    case "changePage": {
      return [
        {
          ...state,
          currentPage: action.pageNumber,
          queryUserResult: undefined
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemManagerUser,
          GraphQLTypes.SystemManagerUserVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow:
                action.pageNumber * state.systemsPerPage - state.systemsPerPage,
              endRow: action.pageNumber * state.systemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumns: state.searchParams.orderByColumns
            }
          },
          data => Action.queryUserDataReceived(data)
        )
      ];
    }
    case "deleteSystems": {
      if (!state.queryUserResult) {
        return [state];
      }

      let deletedSystemIds: Array<string> = [];
      const deletedSystemFiles =
        state.queryUserResult.user.searchSystems.results.filter(r =>
          action.systemFileIds.includes(r.id)
        );
      deletedSystemFiles.forEach(f =>
        f.systems.forEach(s => deletedSystemIds.push(s.id))
      );

      return [
        { ...state, selectedSystemIds: [] },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeleteSystemFile,
          GraphQLTypes.DeleteSystemFileVariables,
          Action
        >(
          deleteSystemFileMutation,
          {
            systemFileIds: action.systemFileIds
          },
          data =>
            Action.search(
              data.deleteSystemFile.deletionSuccessful
                ? action.messageOnDone
                : {
                    messageType: "error",
                    timeout: 10000,
                    content: `Deletion failed. ${sharedState.translate(
                      LanguageTexts.globalPropertyName(
                        data.deleteSystemFile.errorMessage
                      )
                    )}`
                  }
            )
        ),
        [
          SharedState.Action.removeSystemsFromLastOpenedSystemsAndFavorites(
            deletedSystemIds
          )
        ]
      ];
    }
    case "openModal": {
      if (action.modal.mode === "system-summary") {
        type Type = {
          readonly latestRevisionId: string;
          readonly systemType: string;
        };
        const latestRevisions =
          state.queryUserResult?.user.searchSystems.results
            .filter(r =>
              r.systems.find(s => !!state.selectedSystemIds.includes(s.id))
            )
            .reduce((sofar, current) => {
              const latestRevision = [...current.systems].sort(
                (a, b) => b.revisionNo - a.revisionNo
              )[0];

              return [
                ...sofar,
                {
                  systemType: current.systemTypeId,
                  latestRevisionId: latestRevision.id
                }
              ];
            }, [] as Array<Type>);

        const [systemSummaryState, systemSummaryCmd] = SystemSummary.init(
          sharedState,

          latestRevisions?.map(x => ({
            systemId: x.latestRevisionId,
            systemType: x.systemType
          })) || [],
          {
            selectedRows: state.systemSummaryState?.selectedRows || new Set()
          }
        );
        return [
          {
            ...state,
            Modal: action.modal,
            systemSummaryState
          },
          Cmd.map(Action.dispatchSystemSummary, systemSummaryCmd)
        ];
      }

      return [{ ...state, Modal: action.modal }];
    }
    case "closeModal": {
      return [{ ...state, Modal: undefined }];
    }
    case "setLabelInputState": {
      return [{ ...state, labelInputState: action.state }];
    }

    case "openSystemConfirmation": {
      if (action.openInNewTab) {
        window.open(action.url);
      } else {
        window.location.href = action.url;
      }
      return [{ ...state, systemActionIsCompleteUrl: undefined }];
    }
    case "createLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [
          SharedState.Action.createLabel(
            Guid.guidToString(Guid.createGuid()),
            action.labelName
          )
        ]
      ];
    }
    case "updateLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [SharedState.Action.updateLabel(action.labelId, action.labelName)]
      ];
    }
    case "deleteLabel": {
      return [
        state,
        undefined,
        [SharedState.Action.deleteLabel(action.labelId)]
      ];
    }
    case "toggleOpenLabelPopup": {
      return [{ ...state, openLabelPopup: action.labelId }];
    }

    case "setViewMode": {
      const systemsPerPage: SystemsPerEnum =
        action.viewMode === "table"
          ? SystemsPerEnum.sixteen
          : SystemsPerEnum.six;

      return [
        {
          ...state,
          queryUserResult: undefined,
          viewMode: action.viewMode,
          systemsPerPage: systemsPerPage,
          currentPage: 1
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemManagerUser,
          GraphQLTypes.SystemManagerUserVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow: 0,
              endRow: systemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumns: state.searchParams.orderByColumns
            }
          },
          data => Action.queryUserDataReceived(data)
        )
      ];
    }
    case "updateUserSelectedRevisions": {
      return [
        {
          ...state,
          userSelectedRevisions: {
            ...state.userSelectedRevisions,
            [action.systemId]: action.revisionId
          },
          expandedRevisions: []
        }
      ];
    }

    case "dispatchSystemSummary": {
      if (!state.systemSummaryState) {
        return [state];
      }
      const [systemSummaryState, systemSummaryCmd, sharedStateActions] =
        SystemSummary.update(
          action.action,
          state.systemSummaryState,
          sharedState
        );
      return [
        {
          ...state,
          systemSummaryState
        },
        Cmd.map(Action.dispatchSystemSummary, systemSummaryCmd),
        sharedStateActions
      ];
    }

    case "dispatchSystemActions": {
      if (!state.systemActionsState) {
        return [state];
      }

      const [
        systemActionsState,
        SystemActionsCmd,
        sharedStateActions,
        isDone,
        systemActionsCompleteUrl
      ] = SystemActions.update(
        action.action,
        state.systemActionsState.state,
        sharedState
      );

      if (isDone) {
        return [
          {
            ...state,
            queryUserResult: undefined,
            currentPage: 1,
            systemActionIsCompleteUrl: systemActionsCompleteUrl,
            systemActionsState: undefined
          },
          Cmd.batch<Action>([
            Cmd.map(Action.dispatchSystemActions, SystemActionsCmd),
            sharedState.graphQL.queryUserCmd<
              GraphQLTypes.SystemManagerUser,
              GraphQLTypes.SystemManagerUserVariables,
              Action
            >(
              queryUser,
              {
                filter: {
                  startRow: 0,
                  endRow: state.systemsPerPage,
                  searchKey: state.searchParams.searchKey,
                  searchScope: state.searchParams.scope,
                  orderByColumns: state.searchParams.orderByColumns
                }
              },
              data => Action.queryUserDataReceived(data)
            )
          ]),
          [
            ...(sharedStateActions ?? []),
            SharedState.Action.loadLastOpenedSystemsAndFavorites(true)
          ]
        ];
      } else {
        return [
          {
            ...state,
            systemActionIsCompleteUrl: systemActionsCompleteUrl,
            systemActionsState: {
              ...state.systemActionsState,
              state: systemActionsState
            }
          },
          Cmd.map(Action.dispatchSystemActions, SystemActionsCmd),
          sharedStateActions
        ];
      }
    }
    case "setSystemsPerPage": {
      return [
        {
          ...state,
          queryUserResult: undefined,
          systemsPerPage: action.systemsPerPage,
          currentPage: 1
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemManagerUser,
          GraphQLTypes.SystemManagerUserVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow: 0,
              endRow: action.systemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumns: state.searchParams.orderByColumns
            }
          },
          data => Action.queryUserDataReceived(data)
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

//tslint:disable-next-line
