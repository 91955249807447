import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import * as PropertyFilterHelpers from "../property-filter-helpers";

export interface ValueSourceMap {
  readonly [propertyName: string]: ReadonlyArray<{
    readonly value: number;
    readonly propertyFilter: PropertyFilter.PropertyFilter;
    readonly propertyValueSourceId: string;
  }>;
}

export function createValueSourcesDict(
  productProperties: ReadonlyArray<{
    readonly name: string;
    readonly valueSources: ReadonlyArray<{
      readonly value: number;
      readonly propertyFilter: string | PropertyFilter.PropertyFilter;
      readonly propertyValueSourceId: string;
    }>;
  }>,
  selectedProperties: PropertyValueSet.PropertyValueSet
): ValueSourceMap {
  return productProperties.reduce((soFar: ValueSourceMap, current) => {
    const validValueSources = current.valueSources
      .filter(f =>
        PropertyFilterHelpers.isValid(
          f.propertyFilter,
          PropertyFilter.Empty,
          selectedProperties
        )
      )
      .map(vs => ({
        ...vs,
        propertyFilter: PropertyFilterHelpers.createPropertyFilter(
          vs.propertyFilter,
          PropertyFilter.Empty
        )
      }));
    return {
      ...soFar,
      [current.name]: validValueSources
    };
  }, {});
}

export function fixValueSources(
  productProperties: ReadonlyArray<{ readonly name: string }>,
  valueSourceDict: ValueSourceMap,
  properties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let selectedPropertiesCheckedForValueSources = properties;

  for (const propertyName of productProperties
    .map(pp => pp.name)
    .filter(pName => !pName.startsWith("source_"))) {
    const valuesSources = valueSourceDict[propertyName];

    const sourcePropertyName = `source_${propertyName}`;

    if (valuesSources.length === 0) {
      selectedPropertiesCheckedForValueSources = PropertyValueSet.removeProperty(
        sourcePropertyName,
        selectedPropertiesCheckedForValueSources
      );

      continue;
    }

    if (valuesSources.length === 1) {
      selectedPropertiesCheckedForValueSources = PropertyValueSet.setInteger(
        sourcePropertyName,
        valuesSources[0].value,
        selectedPropertiesCheckedForValueSources
      );

      continue;
    }

    if (
      !PropertyValueSet.hasProperty(
        sourcePropertyName,
        selectedPropertiesCheckedForValueSources
      )
    ) {
      const firstValid = valuesSources[0].value;
      selectedPropertiesCheckedForValueSources = PropertyValueSet.setInteger(
        sourcePropertyName,
        firstValid,
        selectedPropertiesCheckedForValueSources
      );
    }
  }

  return selectedPropertiesCheckedForValueSources;
}
