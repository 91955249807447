import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as System from "../../system";
import * as Product from "../../product";
import * as MCompareInput from "./input";
import * as MCompareResult from "./result";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
// import * as Product from "../../product";
import styled from "styled-components";
import { State, Action } from "./state";
import { PrimaryColors, BinaryToggle, S1 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div`
  padding: 15px;
  overflow: auto;
  font-size: 13px;
`;

const StyledS1 = styled(S1)<{ readonly isDisabled: boolean }>`
  ${props => props.isDisabled && `opacity: 0.8; `}
`;

const EnergyNav = styled.ul`
  display: flex;
  /* align-items: center; */
  list-style: none;

  li {
    margin-right: 25px;
    padding: 10px;
    cursor: pointer;
  }
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;

  h4 {
    margin-right: 10px;
  }
`;

const StyledLi = styled.li<{
  readonly active: boolean;
}>`
  ${props =>
    props.active && `border-bottom: 3px solid ${PrimaryColors.muntersBlue}; `};
`;

const Content = styled.div``;

export function MCompareView({
  system,
  state,
  sharedState,
  productData,
  mcsproductData,
  dispatch
}: {
  readonly system: System.System;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly productData: Product.Product;
  readonly mcsproductData: Product.Product;
}) {
  const binCasesExists = system.operatingCases.some(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );
  const readonly = system.status >= SystemStatus.SystemStatus.LockSuccess;
  const calculateMCompare = state.calculateMCompare;
  const activeTab = state.activeTab;

  return (
    <Container>
      <StyledSpan>
        <StyledS1 isDisabled={readonly || !binCasesExists}>
          {sharedState.translate(
            LanguageTexts.calculateEnergyCompareForThisSystem()
          )}
        </StyledS1>
        <BinaryToggle
          disabled={readonly || !binCasesExists}
          isOn={calculateMCompare}
          onClick={() => dispatch(Action.toggleMCompare())}
        />
      </StyledSpan>

      {calculateMCompare && (
        <>
          <EnergyNav>
            <StyledLi
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                if (activeTab !== "Input") {
                  dispatch(Action.setActiveTab("Input"));
                }
              }}
              active={activeTab === "Input"}
            >
              {sharedState.translate(LanguageTexts.energyInput())}
            </StyledLi>
            <StyledLi
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                if (activeTab !== "Results") {
                  dispatch(Action.setActiveTab("Results"));
                }
              }}
              active={activeTab === "Results"}
            >
              {sharedState.translate(LanguageTexts.results())}
            </StyledLi>
          </EnergyNav>

          <Content>
            {activeTab === "Input" ? (
              <MCompareInput.View
                mcsproductData={mcsproductData}
                productData={productData}
                system={system}
                sharedState={sharedState}
                dispatch={Dispatch.map(Action.dispatchInput, dispatch)}
                state={state.inputState}
              />
            ) : (
              <MCompareResult.View
                system={system}
                sharedState={sharedState}
                state={state.resultState}
                dispatch={Dispatch.map(Action.dispatchResult, dispatch)}
              />
            )}
          </Content>
        </>
      )}
    </Container>
  );
}
