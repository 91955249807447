import { EffectManager } from "@typescript-tea/core";
import { diffLogger } from "../program-logger/diff-logger";
import { Logger } from "../types";

export interface SelfActionEntry<MA, MS> {
  readonly action: MA;
  readonly prevState: MS;
  readonly nextState: MS;
  readonly error: Error | undefined;
  readonly started: number;
  readonly startedTime: Date;
  readonly took: number;
}

const colors = {
  title: "inherit",
  action: "#03A9F4",
  prevState: "#9E9E9E",
  nextState: "#4CAF50",
  error: "#F20404"
};

type ActionWithType = { readonly type: string };

export function printBuffer<Home, ProgramAction, SelfAction, SelfState>(
  buffer: readonly SelfActionEntry<SelfAction, SelfState>[],
  logger: Logger,
  manager: EffectManager<Home, ProgramAction, SelfAction, SelfState>,
  showDiff: boolean
): void {
  // const logger = console;

  buffer.forEach((logEntry, key) => {
    const { started, startedTime, action, prevState, error } = logEntry;
    let { took, nextState } = logEntry;
    const nextEntry = buffer[key + 1];

    if (nextEntry && nextEntry.started && started) {
      nextState = nextEntry.prevState;
      took = nextEntry.started - started;
    }

    // Format title
    const formattedTime = formatTime(startedTime);
    const titleCSS = ["color: gray; font-weight: lighter;"];
    titleCSS.push(`color: ${colors.title};`);
    titleCSS.push("color: gray; font-weight: lighter;");
    titleCSS.push("color: gray; font-weight: lighter;");
    const actionType =
      ((action as unknown) as ActionWithType).type ||
      "Action did not have a type property";
    const title = `onSelfAction %c${
      manager.home
    } > ${actionType} %c@ ${formattedTime} %c(in ${took.toFixed(2)} ms)`;

    // Render group with title
    try {
      logger.groupCollapsed(`%c${title}`, ...titleCSS);
    } catch (e) {
      logger.log(title);
    }

    // prev state
    logger.log(
      "%c prev state",
      `color: ${colors.prevState}; font-weight: bold`,
      prevState
    );

    // action
    logger.log(
      "%c action raw        ",
      `color: ${colors.action}; font-weight: bold`,
      action
    );

    // error
    if (error) {
      logger.log(
        "%c error     ",
        `color: ${colors.error}; font-weight: bold;`,
        error
      );
    }

    // next state
    logger.log(
      "%c next state",
      `color: ${colors.nextState}; font-weight: bold`,
      nextState
    );

    // diff
    if (showDiff) {
      diffLogger(prevState, nextState, logger);
    }

    try {
      logger.groupEnd();
    } catch (e) {
      logger.log("—— log end ——");
    }
  });
}

const repeat = (str: string, times: number): string =>
  new Array(times + 1).join(str);

const pad = (num: number, maxLength: number): string =>
  repeat("0", maxLength - num.toString().length) + num;

function formatTime(time: Date): string {
  return `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2
  )}.${pad(time.getMilliseconds(), 3)}`;
}
