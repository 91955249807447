import * as SharedState from "../../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import { State } from "./types";
import { Action } from "../../actions";
import { buildSharedState } from "../../state-builders";
import { actionHandlersMap } from "./action-handlers-mapper";

export function handleSharedStateActions(
  actions: ReadonlyArray<SharedState.Action | undefined>,
  state: State
): readonly [State, ReadonlyArray<Cmd<Action>>] {
  const oldSharedState = buildSharedState(state);
  if (!oldSharedState) {
    return [state, []];
  }
  let newState = state;
  const AllCmds: Array<Cmd<Action>> = [];

  for (const action of actions) {
    if (action === undefined) {
      continue;
    }
    const handler = actionHandlersMap[action.type];
    if (!handler) {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    const [state, cmds] = handler(action, newState, oldSharedState);
    newState = {
      ...newState,
      ...state
    };
    AllCmds.push(...(cmds || []));
  }
  return [newState, AllCmds];
}
