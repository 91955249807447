import { PropertyValueSet, PropertyFilter } from "@genesys/property";

export function getValidDataVariantRows<
  T extends { readonly propertyFilter?: string | null | undefined }
>(
  rows: ReadonlyArray<T>,
  properties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<T> {
  return rows.filter(r =>
    isValid(r.propertyFilter, PropertyFilter.Empty, properties)
  );
}

export function isValid(
  filter: string | null | undefined | PropertyFilter.PropertyFilter,
  fallbackFilter: PropertyFilter.PropertyFilter,
  selectedProperties: PropertyValueSet.PropertyValueSet
): boolean {
  return PropertyFilter.isValid(
    selectedProperties,
    createPropertyFilter(filter, fallbackFilter)
  );
}

export function createPropertyFilter(
  filter: string | null | undefined | PropertyFilter.PropertyFilter,
  fallbackFilter: PropertyFilter.PropertyFilter
): PropertyFilter.PropertyFilter {
  if (filter === null || filter === undefined || typeof filter === "string") {
    return (
      (filter !== undefined &&
        filter !== null &&
        PropertyFilter.fromString(filter)) ||
      fallbackFilter
    );
  }

  return filter;
}
