import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import * as GraphQlTypes from "../../graphql-types";
import { productQuery } from "./queries";
import { Quantity, Unit } from "@genesys/uom";

export type State = {
  readonly data: GraphQlTypes.DataCenterSizeSelectorProductQuery | undefined;
  readonly selectedSize: number | undefined;
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string
): readonly [State, Cmd<Action>?] => [
  { data: undefined, selectedSize: undefined },
  sharedState.graphQL.queryProductCmd<
    GraphQlTypes.DataCenterSizeSelectorProductQuery,
    GraphQlTypes.DataCenterSizeSelectorProductQueryVariables,
    Action
  >(
    productQuery,
    {
      sys: {
        productId: `${systemTypeId}SYS`
      }
    },
    Action.productDataRecieved
  )
];

export const Action = ctorsUnion({
  productDataRecieved: (
    data: GraphQlTypes.DataCenterSizeSelectorProductQuery
  ) => ({ data }),
  setSize: (size: number) => ({ size }),
  FormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  FormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [State, Cmd<Action>?, SharedState.Action?, boolean?] {
  switch (action.type) {
    case "productDataRecieved": {
      return [{ ...state, data: action.data }];
    }
    case "setSize": {
      if (!state) {
        return [state];
      }
      return [
        {
          ...state,
          selectedSize: action.size
        },
        undefined,
        undefined,
        true
      ];
    }
    case "FormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "FormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }

    default:
      exhaustiveCheck(action, true);
  }
}

export function getSize(state: State): number | undefined {
  return state.selectedSize;
}
