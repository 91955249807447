import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PaperSizeEnum from "@genesys/shared/lib/enums/paper-size";
import * as Auth from "@genesys/shared/lib/authorization";
import * as MeasureSystemEnum from "@genesys/shared/lib/enums/measure-system";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { Action, State, PaperSizeInfo, PrintParams } from "./state";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div``;

const AttributesContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;
const StyledDiv = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
`;

const Label = styled.div`
  margin-right: 15px;
`;

export const paperSizes: Array<PaperSizeInfo> = [
  {
    size: PaperSizeEnum.PaperSize.A4,
    displayName: "paper_a4"
  },
  {
    size: PaperSizeEnum.PaperSize.Letter,
    displayName: "paper_letter"
  }
];

export const headerLabels = [
  { header: undefined },
  { header: "notreleased" },
  { header: "preliminary" },
  { header: "confidential" }
];

const genesysSelectStylingProps = {
  width: 100,
  height: 35
};

export function View({
  state,
  sharedState,
  isDisabled,
  dispatch,
  customTranslation
}: {
  readonly dispatch: Dispatch<Action>;
  readonly customTranslation?: (arg: LanguageTexts.StaticText) => string;
  readonly state: State;
  readonly isDisabled: boolean;
  readonly sharedState: SharedState.State;
}) {
  const translate = customTranslation || sharedState.translate;

  const printParams: PrintParams = state.printParams || {
    selectedLanguage: sharedState.user.settings.language,
    selectedPaperSize: (sharedState.user.settings.selectedAmountProfile
      .measureSystem === MeasureSystemEnum.MeasureSystem.SI
      ? paperSizes[0]
      : paperSizes[1]
    ).size,
    selectedHeaderLabel: headerLabels[0]
  };

  const applicationClaims = sharedState.user.applicationClaims;

  const languages = (state.languages || [])
    .map(l => l.id)
    .concat(
      Auth.checkPermission(
        applicationClaims,
        Auth.genesysUserClaims.showLanguageCodes
      )
        ? "lngcod"
        : []
    );

  return (
    <Container>
      <AttributesContainer>
        <StyledDiv>
          <Label>{translate(LanguageTexts.language())}</Label>

          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={isDisabled}
            value={
              state.printParams?.selectedLanguage ||
              sharedState.user.settings.language
            }
            onChange={e => {
              dispatch(
                Action.setPrintParams({
                  ...printParams,
                  selectedLanguage: e.target.value
                })
              );
            }}
            options={languages.map(lan => ({
              value: lan,
              title: lan
            }))}
          />
        </StyledDiv>

        <StyledDiv>
          <Label>{translate(LanguageTexts.papersize())}</Label>

          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={isDisabled}
            value={printParams.selectedPaperSize}
            onChange={e => {
              dispatch(
                Action.setPrintParams({
                  ...printParams,
                  selectedPaperSize: e.target.value as any
                })
              );
            }}
            options={paperSizes.map(p => ({
              value: p.size,
              title: translate(LanguageTexts.dynamicText(p.displayName))
            }))}
          />
        </StyledDiv>

        <StyledDiv>
          <Label>{translate(LanguageTexts.headerLabel())}</Label>

          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={isDisabled}
            value={printParams.selectedHeaderLabel.header || "None"}
            onChange={e => {
              dispatch(
                Action.setPrintParams({
                  ...printParams,
                  selectedHeaderLabel: {
                    header: e.target.value
                  }
                })
              );
            }}
            options={headerLabels.map(h => ({
              value: h.header || "None",
              title: translate(LanguageTexts.dynamicText(h.header || "None"))
            }))}
          />
        </StyledDiv>
      </AttributesContainer>
    </Container>
  );
}
