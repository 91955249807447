import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SystemOverrides } from "../../tools";
import { BaseState } from "../../state";
import { PropertyValueSet } from "@genesys/property";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as GraphQLTypes from "../../../graphql-types";
import * as LoadTypesContent from "../../main-content/load-types";

export type State = BaseState & {};

export const init = (
  baseState: BaseState
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      ...baseState
    }
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (
    action: PropertiesSelector.Action,
    name: GraphQLTypes.OperatingCaseGroupEnum
  ) => ({ action, name }),
  dummy: () => ({})
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  currentSystemOverrides: SystemOverrides,
  onChange?: LoadTypesContent.LoadUpdateFunction
): readonly [State, SystemOverrides] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const propertySelectorState: PropertiesSelector.State = {
        properties: currentSystemOverrides[action.name]
      };

      const previousProps = currentSystemOverrides[action.name];

      const [newPropertiesSelectorState] = PropertiesSelector.update(
        action.action,
        propertySelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      const newProps = PropertiesSelector.getSelectedProperties(
        newPropertiesSelectorState
      );

      const updatedSystemOverrides = {
        ...currentSystemOverrides,
        [action.name]: onChange
          ? PropertyValueSet.merge(onChange(previousProps, newProps), newProps)
          : newProps
      };

      return [
        {
          ...state
        },
        updatedSystemOverrides
      ];
    }

    case "dummy": {
      return [
        {
          ...state
        },
        currentSystemOverrides
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function reset(state: State): State {
  return {
    ...state
  };
}
