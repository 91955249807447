import styled from "styled-components";

// tslint:disable-next-line
export const ListVisualizerTable = styled.table`
  border: 1px solid black;
  font-size: 11px;
  white-space: nowrap;
  padding-top: 5px;

  thead {
    th {
      background-color: #ccc;
      border: 1px solid black;
      padding: 5px;
    }
  }

  tbody {
    overflow-y: auto;
    border: 1px solid black;
  }

  tr {
    background-color: #eee;
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  tr:nth-child(even) {
    background-color: #fcfcfc;
  }
`;

// tslint:disable-next-line
export const ChartColorLabel = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
`;

// tslint:disable-next-line
export const Section = styled.div`
  padding: 5px;
`;
