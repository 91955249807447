import styled from "styled-components";
import { zIndex, PrimaryColors } from "@genesys/ui-elements";

export const Container = styled.div`
  max-width: 1220px;
  margin: 0 auto;
`;

export const Background = styled.div`
  position: absolute;
  height: 300px;
  width: 100%;
  z-index: ${zIndex.Background};
`;

export const StepContainer = styled.div`
  margin-left: 22px;
`;

export const ControllerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    min-width: 163px;
    > span {
      width: 100%;
      text-align: center;
    }
  }
`;

export const ControllerHeader = styled.div`
  font-size: 1.8667rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2.4667rem;
  text-align: center;
  margin-bottom: 43px;
  color: #646f86;
`;

export const ProgressBarContainer = styled.div`
  margin-top: 23px;
  margin-bottom: 38px;
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 22px;
  padding-right: 14px;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
`;

export const ProgressBarRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: row;
  > div + div {
    margin-left: 40px;
  }
`;

export const ProgressItem = styled.div`
  padding-bottom: 17px;
  padding-top: 17px;
  text-transform: uppercase;
`;

export const SelectedProgressItem = styled(ProgressItem)`
  padding-bottom: 14px;
  border-bottom: solid 3px ${PrimaryColors.muntersBlue};
`;

export const DisabledProgressItem = styled(ProgressItem)`
  opacity: 0.3;
`;

export const CreatingSystemLoaderOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: ${zIndex.FullOverlay};
`;
