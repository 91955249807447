import * as React from "react";
import styled from "styled-components";
import { StandardButton, S2, GenesysSelect } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  NewArticleGroupState,
  PricingArticle,
  NewPricingRow,
  PricingRowState
} from "../../types";
import * as Guid from "@genesys/shared/lib/guid";
import { InputContainer, genesysSelectStylingProps } from "../../elements";

interface ArticleGroupSelectorProps {
  readonly sharedState: SharedState.State;
  readonly newArticleGroupState: NewArticleGroupState;
  readonly pricingArticles: ReadonlyArray<PricingArticle>;
  readonly pricingGroups: ReadonlyArray<string>;
  readonly rowNo: number;
  readonly onChange: (newArticleState: NewArticleGroupState) => void;
  readonly onAddRows: (rows: ReadonlyArray<NewPricingRow>) => void;
}

const Root = styled.div`
  display: flex;
  align-items: flex-end;
`;

export function ArticleGroupSelector({
  sharedState,
  newArticleGroupState,
  pricingArticles,
  pricingGroups,
  rowNo,
  onChange,
  onAddRows
}: ArticleGroupSelectorProps) {
  const onArticleChange = (articleGroup: string) => {
    onChange({ ...newArticleGroupState, selectedArticleGroup: articleGroup });
  };

  return (
    <Root>
      <InputContainer>
        <S2 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.group())}
        </S2>
        <GenesysSelect
          {...genesysSelectStylingProps}
          value={newArticleGroupState.selectedArticleGroup}
          onChange={e => onArticleChange(e.target.value)}
          options={pricingGroups.map(pg => ({ title: pg, value: pg }))}
        />
      </InputContainer>

      <StandardButton
        buttonType="Primary"
        size="Small"
        onClick={() => {
          const articles = pricingArticles.filter(
            a => a.templateGroup === newArticleGroupState.selectedArticleGroup
          );

          onAddRows(
            articles.map((article, ix) => ({
              row: mapArticle(rowNo + ix * 10, article),
              currencyCode: article.currencyCode,
              masterCurrencyCode: article.currencyCode,
              exchangeRateTemplateId: undefined,
              salesOrganisationId: undefined,
              masterSalesOrganisationId: undefined
            }))
          );
        }}
      >
        {sharedState.translate(LanguageTexts.add())}
      </StandardButton>
    </Root>
  );
}

function mapArticle(rowNo: number, article: PricingArticle): PricingRowState {
  return {
    id: Guid.guidToString(Guid.createGuid()),
    rowNo: rowNo,
    articleNo: article.articleNo,
    description: article.description,
    pid: undefined,
    quantity: article.quantity,
    unit: article.unit,
    costPerUnit: article.costPerUnit,
    transferPriceFactor: article.transferPricePerUnit / article.costPerUnit,
    masterPriceFactor: article.transferPricePerUnit / article.costPerUnit,
    transferPricePerUnit: article.transferPricePerUnit,
    listPriceFactor: article.listPricePerUnit / article.transferPricePerUnit,
    listPricePerUnit: article.listPricePerUnit,
    masterPricePerUnit: undefined,
    systemId: undefined,
    systemTypeId: undefined,
    type: "extra",
    visualizerCode: ""
  };
}
