import gql from "graphql-tag";

export const productQuery = gql`
  query DataCenterEditorProductQuery($sys: GetProductInputType!) {
    product {
      sys: product(input: $sys) {
        sizeInfoPerformance: productDataTable(
          tableName: "sizeInfoPerformance"
        ) {
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
