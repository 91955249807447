// tslint:disable:variable-name
import * as React from "react";
import styled from "styled-components";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as PropertySelectors from "../react-properties-selector";
import * as AmountPropertySelector from "../amount-property-selector";
import * as AmountFormatSelector from "../amount-format-selector";

const invalid = `
  border-color: red;
  outline: red;
  color: red;
`;

const AmountInputField = styled(PropertySelectors.AmountInputField)`
  border: 1px solid #617584;
  border-radius: 2px;
  font-size: 12px;
  padding: 2.5px;
  width: 44px;
  ${props => (props.isReadonly ? "background-color: #ebebeb;" : "")};
  ${props => (props.effectiveErrorMessage ? invalid : "")};
`;

export const DefaultAmountInputBox = PropertySelectors.createAmountInputBox({
  AmountInputField: AmountInputField as any
});

export const DefaultAmountFormatSelector =
  AmountFormatSelector.createAmountFormatSelector({
    AmountFormatWrapper: styled(PropertySelectors.AmountFormatWrapper)`
      font-size: 12px;
      padding-left: 5px;
    `,
    CancelButton: styled.button`
      &::after {
        content: "X";
      }
    ` as any,
    ClearButton: styled.button`
      &::after {
        content: "C";
      }
    ` as any
  });

const ComboBoxStandardOption = PropertySelectors.createComboBoxStandardOption({
  OptionElement: styled(PropertySelectors.OptionElement)`
    ${props => (!props.isItemValid && "color: red;") || "color: black;"};
  `
});

const DefaultInputTextBox = styled.input`
  border: 1px solid #617584;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 2px;
  ${props => (props.readOnly ? "background-color: #ebebeb;" : "")};
`;

export const DefaultTextboxPropertySelector =
  PropertySelectors.createTextboxPropertySelector({
    StyledInputTextBox: DefaultInputTextBox as any
  });

export const DefaultAmountPropertySelector =
  AmountPropertySelector.createAmountPropertySelector({
    AmountInputBox: DefaultAmountInputBox,
    AmountFormatSelector: DefaultAmountFormatSelector
  });

const DefaultCheckboxContainer = styled.div`
  // This forces the type to match createCheckboxPropertySelector
  ${(_p: PropertySelectors.CheckboxProps) => ""} //

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  img {
    max-width: 100px;
    max-height: 100px;
  }
`;
const DefaultCheckbox = (props: PropertySelectors.CheckboxProps) => (
  <input
    checked={props.checked}
    // `onChange` must be set to prevent warning.
    onChange={() => undefined}
    type="checkbox"
  />
);

// Copied from `promaster-react`, but does not render the label.
export const DefaultCheckboxPropertySelector = (
  props: PropertySelectors.CheckboxPropertySelectorProps
) => {
  const value = PropertyValueSet.getValue(
    props.propertyName,
    props.propertyValueSet
  );

  if (!props.valueItems || props.valueItems.length !== 2) {
    return <div />;
  }
  const falseValue = props.valueItems[0];
  const trueValue = props.valueItems[1];
  if (!falseValue.value || !trueValue.value) {
    return <div />;
  }

  const checked = PropertyValue.equals(trueValue.value, value);
  const nextValue = checked ? falseValue.value : trueValue.value;

  return (
    <DefaultCheckboxContainer
      locked={props.locked}
      checked={checked}
      onClick={() => props.onValueChange(nextValue)}
    >
      {trueValue.image && <img src={trueValue.image} />}
      <DefaultCheckbox locked={props.locked} checked={checked} />
    </DefaultCheckboxContainer>
  );
};

type ComboBoxImageButtonElementProps = {
  readonly isSelectedItemValid?: boolean;
  readonly locked: boolean;
} & React.HTMLProps<HTMLDivElement>;

function comboBoxImageElement({
  isSelectedItemValid: _,
  locked: _1,
  ...htmlProps
}: ComboBoxImageButtonElementProps): JSX.Element {
  return <div {...htmlProps}>{htmlProps.children}</div>;
}

type ComboBoxImageOptionElementRowProps = {
  readonly isItemValid?: boolean;
} & React.HTMLProps<HTMLDivElement>;

function comboBoxImageOptionElementRow({
  isItemValid: _,
  ...htmlProps
}: ComboBoxImageOptionElementRowProps): JSX.Element {
  return <div {...htmlProps}>{htmlProps.children}</div>;
}

const optionImageElementRow = styled(comboBoxImageOptionElementRow)`
  color: rgb(131, 131, 131);
  min-height: 18px;
  align-self: center;
  border: 0px none rgb(131, 131, 131);
  font: normal normal 300 normal 12px / 30px Helvetica, Arial, sans-serif;
  outline: rgb(131, 131, 131) none 0px;

  padding: 0.2em 0.5em;
  cursor: default;

  &:hover {
    background: rgba(203, 210, 246, 0.79);
    color: white;
  }

  ${props => (props.isItemValid === false ? "color: red;" : "")};
`;

const lockedStyle = `
  border: none;
  appearance: none;
  color: #000;
  background: transparent;
  &::-ms-expand {
    display: none;
  }

  &::-ms-value {
    color: #000;
  }
`;

const nonLockedStyle = `
border-radius: 4px;
  border: 1px solid rgb(169, 169, 169);
  background-image: linear-gradient(to top, #e0e0e0, white 60%);
  padding: 2px;

  &::-ms-expand {
    border: none;
    background: transparent;
  }
`;

export const DefaultComboBoxPropertySelector =
  PropertySelectors.createComboboxPropertySelector({
    ComboBoxStandardOption: ComboBoxStandardOption,
    ComboBoxStandardSelect: styled(PropertySelectors.ComboBoxStandardSelect)`
      ${props => (props.locked ? lockedStyle : nonLockedStyle)};
      ${props => (!props.isSelectedItemValid && invalid) || ""};
      font-size: 12px;
    `,
    ComboBoxImageDropdownSelector:
      PropertySelectors.createImageDropdownSelector({
        DropdownSelectElement: styled.div`
          display: inline-block;
        `,
        DropdownOptionsElement: styled.div`
          position: absolute;
          display: block;
          background: white;
          border: 1px solid #bbb;
          list-style: none;
          margin: 0;
          padding: 0;
          max-height: 350px;
          overflow-y: scroll;
          z-index: 100;
        ` as any,
        ComboBoxImageButtonElement: styled(comboBoxImageElement)`
          /* width: 162px; */
          align-items: center;
          background: white;
          color: black;
          white-space: nowrap;
          border: 1px solid #b4b4b4;
          border-radius: 3px;
          font: normal normal 300 normal 12px / 30px Helvetica, Arial,
            sans-serif;
          outline: rgb(131, 131, 131) none 0px;
          padding: 0 10px;

          ${props => !props.isSelectedItemValid && "color: red;"}

          i {
            display: none;
          }

          span {
            display: inline-block;
            width: 88%;
          }

          img {
            height: 25px;
            vertical-align: top;
            margin-top: 2px;
            margin-left: 7px;
          }

          &::after {
            content: "▾";
            font-size: 20px;
            line-height: 30px;
            vertical-align: middle;
          }
        `,
        OptionImage: styled.img`` as any,
        OptionImageElementRow: optionImageElementRow
      })
  });
