import * as GraphQLTypes from "../graphql-types";
import { NewPricingRow } from "./types";
import * as Guid from "@genesys/shared/lib/guid";
import * as Finance from "@genesys/shared/lib/finance";
import { OkState } from "./state";

export function getError(
  data: GraphQLTypes.PricingSystemValidationQuery,
  state: OkState
): string | undefined {
  if (data.user.systemPricingInformationMaster.error) {
    return data.user.systemPricingInformationMaster.error;
  }

  const system = data.user.systemPricingInformationMaster;
  const systemIds = state
    .pricing!.rows.filter(r => r.systemId)
    .map(r => r.systemId!);

  if (systemIds.find(id => id === system.systemPricingInformation!.id)) {
    return "alreadyadded";
  }

  if (
    state.pricing!.exchangeRateTemplateId &&
    state.pricing!.exchangeRateTemplateId !==
      system.systemPricingInformation!.exchangeRateTemplateId
  ) {
    return "differentexchangerate";
  }

  if (
    state.pricing!.salesOrganisationId &&
    (state.pricing!.salesOrganisationId !==
      system.systemPricingInformation!.salesOrganisationId ||
      state.pricing!.masterSalesOrganisationId !==
        system.systemPricingInformation!.masterSalesOrganisationId)
  ) {
    return "cannotaddfromothersalesorg";
  }

  return undefined;
}

export function getSystemRow(
  data: GraphQLTypes.PricingSystemValidationQuery,
  state: OkState,
  visualizerCode: string
) {
  const currencyRate = (
    from: string,
    to: string,
    exchangeRateTemplateId: Guid.Guid
  ) => {
    return Finance.getTodaysRate(
      from,
      to,
      exchangeRateTemplateId,
      state.exchangeRateTable!
    );
  };

  const system = data.user.systemPricingInformationMaster;
  const costPerUnit = system.systemPricingInformation!.marginCost;
  const transferPricePerUnit = system.systemPricingInformation!.transferPrice;
  const listPricePeUnit = system.systemPricingInformation!.listPrice!;
  const masterPricePerUnit = system.systemPricingInformation!.masterSalesPrice;

  const transferPriceFactor = transferPricePerUnit / costPerUnit;
  const listPriceFactor = listPricePeUnit / transferPricePerUnit;
  const masterPriceFactor =
    masterPricePerUnit /
    (currencyRate(
      system.systemPricingInformation!.currencyCode,
      system.systemPricingInformation!.masterCurrencyCode,
      Guid.guidFromString(
        system.systemPricingInformation!.exchangeRateTemplateId
      )
    ) *
      costPerUnit);

  const row: NewPricingRow = {
    row: {
      id: Guid.guidToString(Guid.createGuid()),
      rowNo: state.inputRowNo,
      articleNo: "SYSTEM",
      description: system.systemPricingInformation!.name,
      pid:
        system.systemPricingInformation!.pid !== null
          ? system.systemPricingInformation!.pid!
          : undefined,
      quantity: 1,
      unit: "One",
      costPerUnit: costPerUnit,
      transferPricePerUnit: transferPricePerUnit,
      listPricePerUnit: listPricePeUnit,
      listPriceFactor: listPriceFactor,
      masterPricePerUnit: masterPricePerUnit,
      transferPriceFactor: transferPriceFactor,
      masterPriceFactor: masterPriceFactor,
      systemId: system.systemPricingInformation!.id,
      systemTypeId: system.systemPricingInformation!.systemTypeId,
      type: "system",
      visualizerCode: visualizerCode
    },
    currencyCode: system.systemPricingInformation!.currencyCode,
    masterCurrencyCode: system.systemPricingInformation!.masterCurrencyCode,
    exchangeRateTemplateId:
      system.systemPricingInformation!.exchangeRateTemplateId,
    salesOrganisationId: system.systemPricingInformation!.salesOrganisationId,
    masterSalesOrganisationId:
      system.systemPricingInformation!.masterSalesOrganisationId
  };

  return row;
}
