import { Diff, diff } from "deep-diff";
import { Logger } from "../types";

// https://github.com/flitbit/diff#differences
type ColorText = {
  readonly color: string;
  readonly text: string;
};

const dictionary: {
  readonly E: ColorText;
  readonly N: ColorText;
  readonly D: ColorText;
  readonly A: ColorText;
} = {
  E: { color: "#2196F3", text: "CHANGED:" },
  N: { color: "#4CAF50", text: "ADDED:" },
  D: { color: "#F44336", text: "DELETED:" },
  A: { color: "#2196F3", text: "ARRAY:" }
};

export function diffLogger<S>(prevState: S, newState: S, logger: Logger): void {
  const theDiff = diff(prevState, newState);

  try {
    logger.groupCollapsed("%cdiff", `color: #B200FF; font-weight: bold`);
  } catch (e) {
    logger.log("diff");
  }

  if (theDiff) {
    theDiff.forEach(elem => {
      const kind = elem.kind as keyof typeof dictionary;
      const output = render(elem);

      logger.log(`%c${dictionary[kind].text}`, style(kind), ...output);
    });
  } else {
    logger.log("—— no diff ——");
  }

  try {
    logger.groupEnd();
  } catch (e) {
    logger.log("—— diff end —— ");
  }
}

function style(kind: keyof typeof dictionary): string {
  return `color: ${dictionary[kind].color}; font-weight: bold`;
}

function render(
  diff: Diff<unknown>
): readonly [string?, (string | Diff<unknown>)?, string?, string?] {
  switch (diff.kind) {
    case "E": {
      const { path, lhs, rhs } = diff;
      return [path && path.join("."), lhs as string, "→", rhs as string];
    }
    case "N": {
      const { path, rhs } = diff;
      return [path && path.join("."), rhs as string];
    }
    case "D": {
      const { path } = diff;
      return [path && path.join(".")];
    }
    case "A": {
      const { path, index, item } = diff;
      return [path && `${path.join(".")}[${index}]`, item];
    }
    default: {
      return [];
    }
  }
}
