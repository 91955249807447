import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";
import * as Product from "../product";

const dhuRegex = new RegExp(/\w+?(DHU|HCU|DHS)/, "i");
const rangeTypePropertyName = "rangetype";
const dhmodelPropertyName = "dhmodel";

export function notes(
  system: System.System,
  systemType: Product.SystemType,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;
  const dhuComponent = system.components.find(c => dhuRegex.test(c.productId));

  const systemTypeId = system.file.systemTypeId;

  const rangeType = PropertyValueSet.getInteger(
    rangeTypePropertyName,
    sysComponent.properties
  );

  const rangeTypeText = translate(
    rangeType !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          rangeTypePropertyName,
          rangeType
        )
      : LanguageTexts.pNull()
  );

  const size = PropertyValueSet.getInteger(
    systemType.plenumSizeProperty,
    sysComponent.properties
  );

  const sizeText = `${translate(
    LanguageTexts.systemTypeProperty(
      systemTypeId,
      systemType.plenumSizeProperty
    )
  )}: ${translate(
    size !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          systemType.plenumSizeProperty,
          size
        )
      : LanguageTexts.pNull()
  )}`;

  if (dhuComponent) {
    const dhmodel = PropertyValueSet.getInteger(
      dhmodelPropertyName,
      dhuComponent.properties
    );

    const dhText = `${translate(
      LanguageTexts.systemTypeProperty(systemTypeId, dhmodelPropertyName)
    )}: ${translate(
      dhmodel !== undefined
        ? LanguageTexts.systemTypePropertyValue(
            systemTypeId,
            dhmodelPropertyName,
            dhmodel
          )
        : LanguageTexts.pNull()
    )}`;

    return [rangeTypeText, sizeText, dhText];
  } else {
    return [rangeTypeText, sizeText, ""];
  }
}
