import styled from "styled-components";

// tslint:disable-next-line
export const UserInfo = styled.div`
  display: inline-flex;
  align-items: flex-end;
  background: rgb(238, 240, 236);

  padding: 2px 5px 5px 5px;

  border-radius: 0 0 0 10px;

  align-items: center;

  font-size: 11px;

  div:first-of-type {
    border-left: 2px solid rgb(128, 128, 128);
    border-right: 2px solid rgb(128, 128, 128);

    padding: 0 5px;
    margin: 0 5px;
  }
`;
