import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const createLabelMutation = gql`
  mutation CreateLabel($label: LabelInputType!) {
    createLabel(label: $label) {
      id
      labels {
        ...CompleteLabel
      }
    }
  }
  ${Fragments.completeLabel}
`;
