import styled from "styled-components";

export const SearchOptionsContainer = styled.div`
  margin-top: 24px;
  padding-right: 12px;
`;

export const PagnationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SearchResultsTable = styled.table`
  th:nth-child(3),
  td:nth-child(3) {
    max-width: 300px;
    width: 300px;
  }

  th:nth-child(7),
  td:nth-child(7) {
    max-width: 435px;
    width: 435px;
  }

  td,
  th {
    padding: 0 10px;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  th {
    height: 30px;
    text-align: left;
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 40px;
    a {
      cursor: pointer;
    }
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;
export const StyledDiv = styled.div`
  margin-top: -9px;
  margin-left: 10px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 14px;
  h3 {
    margin: 0 24px 0 12px;
  }
  button {
    margin: 0 4px;
  }
`;

export const Root = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;
