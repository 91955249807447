import { PropertyValueSet } from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as EmissionFromCombustion from "./moisture-load-rules/load-definititions/emission-from-combustion";
import * as EmissionFromPeople from "./moisture-load-rules/load-definititions/emission-from-people";
import * as EvaporationFromMaterials from "./moisture-load-rules/load-definititions/evaporation-from-materials";
import * as EvaporationOfWater from "./moisture-load-rules/load-definititions/evaporation-of-water";
import * as HeatTransmission from "./moisture-load-rules/load-definititions/heat-transmission";
import * as IntentionalVentilation from "./moisture-load-rules/load-definititions/intentional-ventilation";
import * as StaticMoistureLoad from "./moisture-load-rules/load-definititions/static-moisture-load";
import * as StaticHeatLoad from "./moisture-load-rules/load-definititions/static-heat-load";
import * as UnintendedVentilation from "./moisture-load-rules/load-definititions/unintentional-ventilation";
import * as OpenDoor from "./moisture-load-rules/load-definititions/open-door";
import * as RoomControlSettings from "./moisture-load-rules/room-control-settings";
import * as SystemSettings from "./moisture-load-rules/system-settings";
import * as Building from "./moisture-load-rules/building-settings";
import * as InfiltrationModelSettings from "./moisture-load-rules/infiltration-model-settings";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import { PropertyDefinitions } from "./types";

import { getTopLevelPropertyUpdates } from "./moisture-load-rules/application-settings";

export interface InternalGetPropertyUpdatesFunction {
  (
    prevSelections: PropertyValueSet.PropertyValueSet,
    currentSelections: PropertyValueSet.PropertyValueSet
  ): PropertyValueSet.PropertyValueSet;
}

export const propertyUpdates: ReadonlyArray<InternalGetPropertyUpdatesFunction> =
  Array.from([
    "Building",
    "SystemSettings",
    "InfiltrationModelSettings",
    "RoomControlSettings"
  ])
    .concat(Object.keys(MoistureHeatLoadTypeEnum))
    .map(x => getPropertyUpdateFunc(x as Item));

// export const propertyUpdates: ReadonlyArray<InternalGetPropertyUpdatesFunction> =
//   [
//     Building.getPropertyUpdates,
//     SystemSettings.getPropertyUpdates,
//     HeatTransmission.getPropertyUpdates,
//     EmissionFromCombustion.getPropertyUpdates,
//     EmissionFromPeople.getPropertyUpdates,
//     EvaporationFromMaterials.getPropertyUpdates,
//     EvaporationOfWater.getPropertyUpdates,
//     IntentionalVentilation.getPropertyUpdates,
//     StaticMoistureLoad.getPropertyUpdates,
//     StaticHeatLoad.getPropertyUpdates,
//     UnintendedVentilation.getPropertyUpdates,
//     OpenDoor.getPropertyUpdates,
//     RoomControlSettings.getPropertyUpdates
//   ];

export function getAllPropertyUpdates(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyDefinitions: PropertyDefinitions,
  doApplicationTypeLogic: boolean
): PropertyValueSet.PropertyValueSet {
  let totalDefaults = doApplicationTypeLogic
    ? getTopLevelPropertyUpdates(
        currentSelections,
        prevSelections,
        propertyDefinitions
      )
    : PropertyValueSet.Empty;

  //   Apply the rule based changes.
  propertyUpdates.forEach(getPropertyUpdatesPart => {
    const result = getPropertyUpdatesPart(
      prevSelections,
      PropertyValueSet.merge(totalDefaults, currentSelections)
    );

    totalDefaults = PropertyValueSet.merge(result, totalDefaults);
  });

  return totalDefaults;
}

type Item =
  | keyof typeof MoistureHeatLoadTypeEnum
  | "Building"
  | "SystemSettings"
  | "RoomControlSettings"
  | "InfiltrationModelSettings";

// gives compile time error if not all cases are handled
function getPropertyUpdateFunc(item: Item) {
  switch (item) {
    case "Building":
      return Building.getPropertyUpdates;
    case "SystemSettings":
      return SystemSettings.getPropertyUpdates;
    case "InfiltrationModelSettings":
      return InfiltrationModelSettings.getPropertyUpdates;
    case "HEAT_TRANSMISSION":
      return HeatTransmission.getPropertyUpdates;
    case "EMISSION_FROM_COMBUSTION":
      return EmissionFromCombustion.getPropertyUpdates;
    case "EMISSION_FROM_PEOPLE":
      return EmissionFromPeople.getPropertyUpdates;
    case "EVAPORATION_FROM_MATERIALS":
      return EvaporationFromMaterials.getPropertyUpdates;
    case "EVAPORATION_OF_WATER":
      return EvaporationOfWater.getPropertyUpdates;
    case "INTENTIONAL_VENTILATION":
      return IntentionalVentilation.getPropertyUpdates;
    case "STATIC_MOISTURE_LOAD":
      return StaticMoistureLoad.getPropertyUpdates;
    case "STATIC_HEAT_LOAD":
      return StaticHeatLoad.getPropertyUpdates;
    case "UNINTENDED_VENTILATION":
      return UnintendedVentilation.getPropertyUpdates;
    case "OPEN_DOOR":
      return OpenDoor.getPropertyUpdates;
    case "RoomControlSettings":
      return RoomControlSettings.getPropertyUpdates;

    // Remove?
    case "DIFFUSION_THROUGH_MATERIALS":
      return () => PropertyValueSet.Empty;
    default:
      return exhaustiveCheck(item, true);
  }
}
