import * as React from "react";
import * as PropertiesSelector from "../react-properties-selector";
import * as Contexts from "../contexts";
import { GroupWithDeleteButtonRenderer } from "./group-with-delete-button-renderer";
import { createSelectorInfoMap } from "../genesys-properties-selector-helpers";

export function deletableLayoutRenderer(
  props: PropertiesSelector.LayoutRendererProps
): JSX.Element {
  const selectorInfoMap = createSelectorInfoMap(props.selectors);
  return (
    <Contexts.propertiesSelectorContext.Consumer>
      {({
        activatedQuantitySelectors,
        conversionParametersMap,
        filterPrettyPrint,
        layoutRendererExtraProps,
        onQuantityChange,
        valueSources,
        translatePropertyValue
      }) => {
        if (layoutRendererExtraProps.sections.length === 0) {
          return <div />;
        }

        const groups = layoutRendererExtraProps.sections[0].groups.map(
          group => (
            <GroupWithDeleteButtonRenderer
              key={`${layoutRendererExtraProps.uniqueId}-${group.key}`}
              filterPrettyPrint={filterPrettyPrint}
              renderGroup={group}
              fieldGroup={layoutRendererExtraProps.fieldGroup}
              hidePropertyNames={layoutRendererExtraProps.hidePropertyNames}
              onDelete={layoutRendererExtraProps.onDelete}
              uniqueId={layoutRendererExtraProps.uniqueId}
              valueSourcesDict={valueSources || {}}
              selectorInfoMap={selectorInfoMap}
              activatedQuantitySelectors={activatedQuantitySelectors}
              onQuantityChange={onQuantityChange}
              conversionParametersMap={conversionParametersMap}
              translatePropertyValue={translatePropertyValue}
              showDelete={layoutRendererExtraProps.showDelete}
            />
          )
        );
        return <div>{groups}</div>;
      }}
    </Contexts.propertiesSelectorContext.Consumer>
  );
}
