import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 16px 16px 16px;
`;

export const EmissionSelectorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  h2 {
    padding-left: 15px;
  }
`;

export const ElectricityEmissionValueSourceContainer = styled.div`
  position: relative;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f2f4f7;
  white-space: nowrap;
`;

export const Arrow = styled.div`
  border-right: 8pt solid #f2f4f7;
  border-top: 6pt solid transparent;
  border-bottom: 6pt solid transparent;
  position: absolute;
  left: -8pt;
  top: 6pt;
`;

export const InputsContainer = styled.div`
  display: flex;
`;

export const InformationContianer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 4px;
  background-color: ${PrimaryColors.muntersBlue};
  img {
    margin-right: 8px;
  }
`;
