import * as React from "react";
import styled from "styled-components";
import { Label } from "@genesys/ui-elements";

interface MenuItemProps {
  readonly label: string;
  readonly isDisabled: boolean;
  readonly onClick: () => void;
}

const StyledLabel = styled(Label)`
  ${(props: { readonly isDisabled: boolean }) =>
    props.isDisabled ? "opacity: 0.5;" : ""};
`;

export function MenuItem({ label, isDisabled, onClick }: MenuItemProps) {
  return (
    <button
      onClick={
        !isDisabled
          ? e => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }
          : undefined
      }
    >
      <StyledLabel weight="bold" color="secondary" isDisabled={isDisabled}>
        {label}
      </StyledLabel>
    </button>
  );
}
