import * as React from "react";
import { Label } from "@genesys/ui-elements";

interface MenuItemProps {
  readonly label: string;
  readonly onClick: () => void;
}

export function MenuItem({ label, onClick }: MenuItemProps) {
  return (
    <button onClick={onClick}>
      <Label weight="bold" color="secondary">
        {label}
      </Label>
    </button>
  );
}
