import * as SharedState from "../../../../../shared-state";
import { Columns, SystemRow } from "../../../../types";
import { labelFormatter, systemLabelFormatter, dateFormatter } from "./tools";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function getExcelCellInfo(
  cell: Columns,
  sharedState: SharedState.State,
  prefix: string,
  systemFleRow: SystemRow
) {
  if (systemFleRow.type === "revision") {
    return;
  }
  const latestSystem = systemFleRow.latestSystem;

  switch (cell) {
    case "g-no": {
      return prefix + systemFleRow.genesysNo;
    }

    case "system-name": {
      return systemFleRow.name;
    }

    case "system-type": {
      return systemLabelFormatter(
        systemFleRow.systemLabel || "",
        systemFleRow.systemTypeId,
        sharedState,
        true
      );
    }

    case "owner": {
      return systemFleRow.owner.userName;
    }

    case "date-changed": {
      return dateFormatter(latestSystem.statusDate, sharedState);
    }

    case "status": {
      return latestSystem.status;
    }

    case "labels": {
      return labelFormatter(systemFleRow);
    }

    case "revisions": {
      return systemFleRow.systems.length;
    }
    case "favorites":
    case "actions-menu":
      return "";

    default:
      exhaustiveCheck(cell);
  }
}
