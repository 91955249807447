import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GroupFiltersContainer = styled.div`
  height: 765px;
  width: 20%;
  background-color: #f7f9fc;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 80%;
  height: 765px;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const OuterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #dde4f0;
  width: 100%;
  margin: 0 25px;
`;

export const InnerContentContainer = styled.div`
  padding: 10px 0;
  border-bottom: 2px solid #dde4f0;
  div {
    overflow: visible;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`;
