import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as LabelManager from "../label-manager";
import styled from "styled-components";
import {
  //Pop,
  ArrowDown,
  Label,
  Input
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;

  span {
    margin-bottom: 8px;
  }
`;

const FakeSelect = styled.div`
  box-sizing: border-box;
  height: 48px;
  width: 455px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3d3d3d;
    padding-left: 20px;
    display: inline-block;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
`;

const StyledDiv = styled.div`
  position: relative;

  > div {
    position: absolute;
  }
`;

export function LabelSelector({
  sharedState,
  inputValue,
  inputName,
  labelEditorInputs,
  inputOnChange
}: {
  readonly sharedState: SharedState.State;
  readonly inputValue: string;
  readonly inputName: string;
  readonly inputOnChange: (name: string) => void;
  readonly labelEditorInputs: {
    readonly labelAnchor: Element | null;
    readonly assignedLabels: ReadonlyArray<LabelManager.Label>;
    readonly labelManagerState: LabelManager.State | undefined;
    readonly dispatchMap: Dispatch<LabelManager.Action>;
    readonly onToggleLabelManager: (element: Element | null) => void;
    readonly onAssign: (values: ReadonlyArray<LabelManager.Label>) => void;
  };
}) {
  return (
    <Container>
      <InputContainer>
        <Label weight="bold" color="light">
          {inputName}
        </Label>
        <Input
          type="text"
          value={inputValue}
          width={"455px"}
          placeholder={sharedState.translate(LanguageTexts.enterFileNameHere())} // check
          onChange={e => inputOnChange(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Label weight="bold" color="light">
          {sharedState.translate(LanguageTexts.labels())}
        </Label>
        <LabelEditorView
          labelAnchor={labelEditorInputs.labelAnchor}
          assignedLabels={labelEditorInputs.assignedLabels}
          state={labelEditorInputs.labelManagerState}
          sharedState={sharedState}
          dispatchMap={labelEditorInputs.dispatchMap}
          onAssign={labelEditorInputs.onAssign}
          onToggleLabelManager={labelEditorInputs.onToggleLabelManager}
        />
      </InputContainer>
    </Container>
  );
}

function LabelEditorView({
  sharedState,
  state,
  labelAnchor,
  assignedLabels,
  dispatchMap,
  onToggleLabelManager,
  onAssign
}: {
  readonly sharedState: SharedState.State;
  readonly state: LabelManager.State | undefined;
  readonly labelAnchor: Element | null;
  readonly assignedLabels: ReadonlyArray<LabelManager.Label>;
  readonly dispatchMap: Dispatch<LabelManager.Action>;
  readonly onToggleLabelManager: (element: Element | null) => void;
  readonly onAssign: (values: ReadonlyArray<LabelManager.Label>) => void;
}): JSX.Element | null {
  const labels = sharedState.user.labels;

  const handleClick = (event: React.MouseEvent<Element>) => {
    onToggleLabelManager(event.currentTarget);
  };

  const handleClose = () => {
    onToggleLabelManager(null);
  };

  let displayedLabels: ReadonlyArray<LabelManager.Label> = [];

  if (state) {
    displayedLabels = LabelManager.getSelectedLabels(state!);
  } else {
    displayedLabels = assignedLabels;
  }

  return (
    <>
      <FakeSelect onClick={handleClick}>
        <label>
          {!displayedLabels.length
            ? sharedState.translate(LanguageTexts.chooseLabels())
            : displayedLabels.map((obj, i) => {
                const value =
                  i < displayedLabels.length - 1 ? obj.name + "; " : obj.name;
                return value;
              })}
        </label>
        <ArrowDown />
      </FakeSelect>
      {labelAnchor && state && (
        <StyledDiv>
          <div>
            <LabelManager.LabelManagerView
              onAssign={values => {
                onAssign(values);
              }}
              state={state}
              dispatch={dispatchMap}
              onCancel={handleClose}
              sharedState={sharedState}
              labels={labels}
            />
          </div>
        </StyledDiv>
      )}
    </>
  );
}
