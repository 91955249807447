import * as React from "react";
import {
  Container,
  ListMenu,
  ListMenuItem,
  ListMenuItemTitle,
  ListMenuItemIcon,
  ListMenuItemSelected
} from "./elements";
import { Icon, Label } from "@genesys/ui-elements";
import * as State from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../shared-state";
import * as Types from "./types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ComponentPlacement from "../component-placement";
import { v4 } from "uuid";
import * as Product from "../product";
import { ProductMenu } from "../components/product-menu";
import * as System from "../system";

export interface AddComponentMenuProps {
  readonly system: System.System;
  readonly dispatch: Dispatch<State.Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
  readonly groups: ReadonlyArray<Types.AddComponentGroup>;
  readonly addComponent: (
    componentId: string,
    productId: string,
    productSectionIds: ReadonlyArray<string>
  ) => void;
  readonly productData: Product.ProductData;
}

export function AddComponentMenu(props: AddComponentMenuProps): JSX.Element {
  const selectGroup = (group: string) =>
    props.dispatch(State.Action.selectGroup(group));

  const itemContent = (title: string, Icon: Icon) => (
    <>
      <ListMenuItemIcon>
        <Icon height="1.4rem" width="1.4rem" />
      </ListMenuItemIcon>
      <ListMenuItemTitle>
        <Label weight="normal">
          {props.sharedState.translate(LanguageTexts.dynamicText(title))}
        </Label>
      </ListMenuItemTitle>
    </>
  );

  const item = (title: string, Icon: Icon, selected: boolean) =>
    selected ? (
      <ListMenuItemSelected key={title}>
        {itemContent(title, Icon)}
      </ListMenuItemSelected>
    ) : (
      <ListMenuItem key={title} onClick={() => selectGroup(title)}>
        {itemContent(title, Icon)}
      </ListMenuItem>
    );

  const products = props.groups.find(
    g => g.group === props.state.selectedGroup
  )?.products;

  const sysProperties = props.system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const sequenceRegexes = ComponentPlacement.getSequenceRegexes(
    sysProperties,
    props.productData.systemType.sequenceRules
  );

  const isThereAnyOpenPosition = (productSectionId: string) =>
    ComponentPlacement.isThereAnOpenPositionForSectionId(
      productSectionId,
      props.productData.systemType.positionValidation,
      props.productData.systemType.placementRules,
      sysProperties,
      props.system.airstreams,
      sequenceRegexes
    );

  const productInfo = products
    ? products.map(p => ({
        productId: p.productId,
        isThereAnyOpenPosition: isThereAnyOpenPosition(p.productSectionIds[0])
      }))
    : [];

  return (
    <Container>
      <ListMenu>
        {props.groups.map(g =>
          item(g.group, g.Icon, props.state.selectedGroup === g.group)
        )}
      </ListMenu>
      {products && (
        <ProductMenu
          columns={3}
          onSelect={productId =>
            props.addComponent(
              v4(),
              productId,
              products.find(p => p.productId === productId)!.productSectionIds
            )
          }
          productData={props.productData}
          productInfo={productInfo}
          translate={props.sharedState.translate}
          system={props.system}
        />
      )}
    </Container>
  );
}
