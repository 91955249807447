import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ElectricalOptions, Icon } from "@genesys/ui-elements";
import { Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { PropertyDefinitions } from "../../../tools";
// import { exhaustiveCheck } from "ts-exhaustive-check";
import { LoadDef } from "../types";
import styled from "styled-components";

const LoadSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 73px;
  /* margin-right: 5px;
  margin-bottom: 5px; */
  margin-right: 5px;
  overflow-wrap: break-word;
  word-break: break-all;
  cursor: pointer;

  label {
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export function MenuView({
  // tab,
  loadSources,
  sharedState,
  selectedProperties,
  propertyDefinitions,
  isDisabled,
  dispatch
}: {
  readonly loadSources: ReadonlyArray<LoadDef>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly propertyDefinitions: PropertyDefinitions;
  readonly isDisabled: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const View = ({
    children,
    onClick,
    Icon
  }: {
    readonly children: React.ReactNode;
    readonly Icon?: Icon;
    readonly onClick: () => void;
  }) => {
    return (
      <LoadSourceContainer
        onClick={() => {
          if (!isDisabled) {
            onClick();
          }
        }}
      >
        {Icon ? (
          <Icon height={"35px"} width="35px" />
        ) : (
          <ElectricalOptions width="35px" height={"35px"} />
        )}
        <label>{children}</label>
      </LoadSourceContainer>
    );
  };
  return (
    <Container>
      {loadSources
        .filter(
          x =>
            (x.claims.canUseHeat || x.claims.canUseMoisture) &&
            (PropertyFilter.isValid(
              selectedProperties,
              propertyDefinitions.find(
                pd => pd.name === x.properties.moistureProperty
              )?.enabled_filter || PropertyFilter.Empty
            ) ||
              PropertyFilter.isValid(
                selectedProperties,
                propertyDefinitions.find(
                  pd => pd.name === x.properties.heatProperty
                )?.enabled_filter || PropertyFilter.Empty
              ))
        )
        .map(load => (
          <View
            key={load.title}
            Icon={load.Icon}
            onClick={() => dispatch(Action.addLoad(load))}
          >
            {sharedState.translate(
              LanguageTexts.globalPropertyName(load.title)
            )}
          </View>
        ))}
    </Container>
  );
}
