import * as SharedState from "../../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import { SystemRow } from "../../../../types";

export function labelFormatter(systemRow: SystemRow) {
  if (systemRow.type === "revision") {
    return "";
  }
  return systemRow.labels.map(label => label.name).join("|");
}

export function systemLabelFormatter(
  label: string | null,
  systemTypeId: string,
  sharedState: SharedState.State,
  includeSystemTypeId: boolean
) {
  let finalLabel = label;

  if (label === null) {
    finalLabel = sharedState.translate(
      LanguageTexts.systemTypeIdText(systemTypeId)
    );
  } else if (label.startsWith("{") && label.endsWith("}")) {
    finalLabel = sharedState.translate(
      LanguageTexts.dynamicText(
        label.substring(1, label.length - 1),
        systemTypeId
      )
    );
  }
  return finalLabel! + (includeSystemTypeId ? " (" + systemTypeId + ")" : "");
}

export function dateFormatter(date: number, sharedState: SharedState.State) {
  return DateHelper.getDateString(
    DateHelper.fromUTC(date),
    sharedState.user.settings.locale
  );
}
