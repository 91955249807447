import React from "react";
import styled from "../../styled-components";

const Container = styled.div<{
  readonly isOn: boolean;
  readonly disabled: boolean;
}>`
  box-sizing: border-box;
  height: 20px;
  cursor: pointer;
  width: 32px;
  padding: 1.1px 1.5px 1.9px 2.5px;
  border: ${props => (props.isOn ? "1px solid #00adf2" : "")};
  background-color: ${props => (props.isOn ? "#00adf2" : "#C5D0E5")};
  ${props =>
    props.disabled &&
    !props.isOn &&
    `
  background: linear-gradient(180deg, #F8F8F8 0%, #FAFAFA 100%);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.03);
  `}

  ${props =>
    props.disabled &&
    props.isOn &&
    `
 opacity: 0.3;
  `}
  border-radius: 100px;
  display: inline-flex;
  justify-content: ${props => (props.isOn ? "flex-end" : "flex-start")};
`;

const CircleContainer = styled.span<{ readonly isOn: boolean }>`
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;

  &:after {
    ${props =>
      props.isOn &&
      `
    content: "";
    position: absolute;

    left: 6px;
    top: 3px;
    height: 5px;
    width: 2.5px;
    border: solid #00adf2;
    border-width: 0 2.5px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    `}
  }
`;

export function BinaryToggle({
  isOn,
  disabled,
  onClick
}: {
  readonly onClick: () => void;
  readonly isOn: boolean;
  readonly disabled?: boolean;
}) {
  return (
    <Container
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        if (!disabled) {
          onClick();
        }
      }}
      disabled={!!disabled}
      isOn={isOn}
    >
      <CircleContainer isOn={isOn}></CircleContainer>
    </Container>
  );
}
