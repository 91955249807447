import { EntityCache } from "gql-cache";
import * as GQLCachePatch from "gql-cache-patch";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";

export type UserAction =
  | MergeUserEntities
  | QueueMutations
  | DeQueueMutations
  | MergeUserEntitiesAndDeuqueQueuedMutations
  | DiscardMutationsQueue
  | ApplyCachePathes;

export interface QueueMutations {
  readonly type: "query-user/queue-mutations";
  readonly queueId: string;
  readonly payload: ReadonlyArray<
    GraphQLMutationsQueue.QueueMutationDescription
  >;
}

export function queueMutations(
  queueId: string,
  payload: ReadonlyArray<GraphQLMutationsQueue.QueueMutationDescription>
): QueueMutations {
  return {
    type: "query-user/queue-mutations",
    queueId,
    payload
  };
}
export interface DeQueueMutations {
  readonly type: "query-user/de-queue-mutations";
  readonly queueId: string;
  readonly payload: ReadonlyArray<GraphQLMutationsQueue.QueuedMutation>;
}

export function deQueueMutations(
  queueId: string,
  payload: ReadonlyArray<GraphQLMutationsQueue.QueuedMutation>
): DeQueueMutations {
  return {
    type: "query-user/de-queue-mutations",
    queueId,
    payload
  };
}

export interface DiscardMutationsQueue {
  readonly type: "query-user/discard-mutations-queue";
  readonly queueId: string;
}

export function discardMutationsQueue(queueId: string): DiscardMutationsQueue {
  return {
    type: "query-user/discard-mutations-queue",
    queueId
  };
}

export interface MergeUserEntities {
  readonly type: "query-user/MergeUserEntities";
  readonly payload: {
    readonly entities: EntityCache;
    readonly cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>;
  };
}

export function mergeUserEntities(
  entities: EntityCache,
  cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>
): MergeUserEntities {
  return {
    type: "query-user/MergeUserEntities",
    payload: {
      entities,
      cachePatches
    }
  };
}

export interface MergeUserEntitiesAndDeuqueQueuedMutations {
  readonly type: "query-user/MergeUserEntitiesAndDeuqueQueuedMutations";
  readonly payload: {
    readonly entities: EntityCache;
    readonly cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>;
    readonly queueId: string;
    readonly mutationsToDequeue: ReadonlyArray<
      GraphQLMutationsQueue.QueuedMutation
    >;
  };
}

export function mergeUserEntitiesAndDeuqueQueuedMutations(
  entities: EntityCache,
  cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>,
  queueId: string,
  mutationsToDequeue: ReadonlyArray<GraphQLMutationsQueue.QueuedMutation>
): MergeUserEntitiesAndDeuqueQueuedMutations {
  return {
    type: "query-user/MergeUserEntitiesAndDeuqueQueuedMutations",
    payload: {
      entities,
      cachePatches,
      queueId,
      mutationsToDequeue
    }
  };
}
export interface ApplyCachePathes {
  readonly type: "query-user/ApplyCachePathes";
  readonly payload: ReadonlyArray<GQLCachePatch.CachePatch>;
}

export function applyCachePathes(
  cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>
): ApplyCachePathes {
  return {
    type: "query-user/ApplyCachePathes",
    payload: cachePatches
  };
}
