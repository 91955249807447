import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { SystemAccessory } from "./types";
import * as System from "../../system";
import * as Product from "../../product";
import { getInitialSystemAccessories } from "./functions";
import * as Accessories from "../accessories";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import { PropertyValueSet } from "@genesys/property";

export type State = {
  readonly collapsed: {
    readonly [componentId: string]: true | undefined;
  };
  readonly groupFilter: ReadonlyArray<string>;
  readonly systemAccessories: ReadonlyArray<SystemAccessory>;
  readonly valuesHasChanged: boolean;
};

export const init = (
  system: System.System,
  products: ReadonlyArray<Product.Product>
): [State] => {
  return [
    {
      collapsed: {},
      groupFilter: [],
      valuesHasChanged: false,
      systemAccessories: getInitialSystemAccessories(system, products)
    }
  ];
};

export const Action = ctorsUnion({
  toggleCollapsed: (componentId: string) => ({ componentId }),
  clearGroupFilter: () => ({}),
  toggleGroupFilter: (groupFilter: string) => ({
    groupFilter
  }),
  dispatchAccessories: (
    systemId: string,
    componentId: string,
    action: Accessories.Action
  ) => ({
    systemId,
    componentId,
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "toggleCollapsed": {
      return [
        {
          ...state,
          collapsed: {
            ...state.collapsed,
            [action.componentId]: state.collapsed[action.componentId]
              ? undefined
              : true
          }
        }
      ];
    }
    case "clearGroupFilter": {
      return [{ ...state, groupFilter: [] }];
    }
    case "toggleGroupFilter": {
      const newGroupFilter = state.groupFilter.includes(action.groupFilter)
        ? state.groupFilter.filter(gf => gf !== action.groupFilter)
        : state.groupFilter.concat(action.groupFilter);

      return [{ ...state, groupFilter: newGroupFilter }];
    }
    case "dispatchAccessories": {
      const accessory = state.systemAccessories.find(
        a => a.parentComponent.id === action.componentId
      );
      if (!accessory) {
        return [state];
      }

      const [
        accessoryState,
        accessoryCmd,
        sharedStateAction
      ] = Accessories.update(
        action.action,
        accessory.state,
        sharedState,
        action.systemId
      );

      const newAccessories = state.systemAccessories.map(a =>
        a.parentComponent.id !== action.componentId
          ? a
          : { ...a, state: accessoryState }
      );

      return [
        { ...state, systemAccessories: newAccessories, valuesHasChanged: true },
        Cmd.map(
          cmdAction =>
            Action.dispatchAccessories(
              action.systemId,
              action.componentId,
              cmdAction
            ),
          accessoryCmd
        ),
        sharedStateAction
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfigurationActions(
  state: State,
  system: System.System,
  type: "save" | "saveAndCalculate"
): ReadonlyArray<ConfiguratorAction> {
  const accessories: Array<{
    readonly parentComponentId: string;
    readonly parentComponentProperties: string;
    readonly accessoriesProperties: ReadonlyArray<{
      readonly productId: string;
      readonly properties: string;
    }>;
  }> = [];

  state.systemAccessories.forEach(systemAccessory => {
    const unmodifiedAccessories = system.components.filter(
      c => c.accessoryToId === systemAccessory.parentComponent.id
    );
    const componentProperties = Accessories.getComponentProperties(
      systemAccessory.state
    );

    const hasParentComponentChanged = !PropertyValueSet.equals(
      systemAccessory.parentComponent.properties,
      systemAccessory.state.parentSelectedProperties
    );

    const hasAccessoriesPropertiesChanged = !!componentProperties.accessoriesProperties.find(
      accessory => {
        const unmodifiedAccessory = unmodifiedAccessories.find(
          a => a.productId === accessory.productId
        );
        return !unmodifiedAccessory
          ? false
          : !PropertyValueSet.equals(
              PropertyValueSet.fromString(accessory.properties),
              unmodifiedAccessory.properties
            );
      }
    );

    if (hasParentComponentChanged || hasAccessoriesPropertiesChanged) {
      accessories.push({
        parentComponentId: componentProperties.parentComponentId,
        parentComponentProperties:
          componentProperties.parentComponentProperties,
        accessoriesProperties: componentProperties.accessoriesProperties
      });
    }
  });

  const configurationActions: ReadonlyArray<ConfiguratorAction> =
    accessories.length > 0
      ? [
          ConfiguratorAction.updateComponentAccessories(
            accessories,
            type === "saveAndCalculate"
          )
        ]
      : [];

  return configurationActions;
}
