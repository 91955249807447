import * as React from "react";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { Spinner, P1, InfoWhite } from "@genesys/ui-elements";
import * as GraphQlTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import * as PropertiesSelector from "../../../../properties-selector";
import * as System from "../../../system";
import { State, Action } from "./state";
import {
  getEmissionPropertiesDefinitions,
  getElectricityEmissionValueSource
} from "./functions";
import {
  ElectricityEmissionValueSourceContainer,
  EmissionSelectorContainer,
  Root,
  InformationContianer,
  InputsContainer,
  Arrow
} from "./elements";

export function EmissionsSelectionView({
  state,
  sharedState,
  dispatch,
  system,
  co2EmissionIndexes,
  binCountry
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly system: System.System;
  readonly co2EmissionIndexes: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["co2EmissionIndexes"][0]
  >;
  readonly binCountry: string;
}) {
  if (!state.emissionsSelectorState) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const emissionPropertiesDefinitions = getEmissionPropertiesDefinitions(
    state.showElectricity,
    state.showCoolingWater,
    state.showGas,
    state.showHeatingWater,
    state.showSteam,
    state.showHumidifierWater
  );

  const electricityEmissionValueSource = getElectricityEmissionValueSource(
    state.emissionsSelectorState,
    binCountry,
    co2EmissionIndexes
  );

  return (
    <Root>
      <InformationContianer>
        <InfoWhite />
        <P1 color="white" weight="normal">
          {sharedState.translate(LanguageTexts.costAndEmissionsInformation())}
        </P1>
      </InformationContianer>
      <InputsContainer>
        <EmissionSelectorContainer>
          {state.emissionsSelectorState && (
            <div>
              <PropertiesSelector.PropertiesSelectorView
                fieldGroup={`Settings.EnergyEmission`}
                isReadonly={system.status >= SystemStatus.LockSuccess}
                propertiesInfo={emissionPropertiesDefinitions}
                dispatch={Dispatch.map(
                  action => Action.dispatchEmissionsPropertiesSelector(action),
                  dispatch
                )}
                state={state.emissionsSelectorState}
                hideGroupNames={true}
                showCodes={showCodes}
                hidePropertyNames={false}
                sharedState={sharedState}
                productId={"EnergyEmission"}
              />
            </div>
          )}
          <ElectricityEmissionValueSourceContainer>
            <Arrow />
            <P1 color="secondary" weight="normal">
              {sharedState.translate(
                LanguageTexts.dynamicText(
                  "emissionvaluesource_" + electricityEmissionValueSource
                )
              )}
            </P1>
          </ElectricityEmissionValueSourceContainer>
        </EmissionSelectorContainer>
      </InputsContainer>
    </Root>
  );
}
