import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setUserSettingsSalesMutation = gql`
  mutation SetUserSettingsSales(
    $userSettingsSales: UserSettingsSalesInputType!
  ) {
    setUserSettingsSales(userSettingsSales: $userSettingsSales) {
      id
      sales {
        ...CompleteUserSettingsSales
      }
    }
  }
  ${Fragments.completeUserSettingsSales}
`;
