import gql from "graphql-tag";

export const moistureLoadOperatingCaseQuery = gql`
  query MoistureLoadOperatingCaseQuery($id: ID!) {
    user {
      moistureLoad(id: $id) {
        id
        revisionNo
        status
        moistureloadInput {
          id
          climateSettings
        }
        moistureloadFile {
          id
          moistureLoadNo
        }

        moistureLoadResult {
          maxLoadResults {
            id
            selectedFlowId
            caseType
            needResourceString
            caseName
            usedRoomControlSettings
            operatingCaseResults
            roomWarnings
            flowSuggestions {
              id
              flowSourceId
              isValid
              result
            }
          }
        }
      }
    }
  }
`;

export const moistureLoadSystemSearch = gql`
  query MoistureLoadSystemSearchQuery($searchText: String!) {
    user {
      moistureLoadSystemSearch(searchText: $searchText) {
        id
        revisionNo
        moistureLoadNo
        name
      }
    }
  }
`;

export const queryMissingClimateLocation = gql`
  query OperatingCaseMissingClimateQuery($locationId: String!) {
    product {
      dataPointsForLocationId(locationId: $locationId) {
        id
        caseType
        climateDataType
        annualOccurence
        temperature
        humidity
        windSpeed
      }
      binDataLocations {
        binDataLocationId
        locationName
        regionName
        countryName
        latitude
        longitude
      }

      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }
    }
  }
`;
