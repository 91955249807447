import { Quantity } from "@genesys/uom";
import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import * as PropertiesSelector from "../react-properties-selector";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";

interface GraphQlProperty {
  // Quantity conversion parameters
  readonly quantityConversionParams: ReadonlyArray<string>;
  // Property name
  readonly name: string;
  // Quantity
  readonly quantity: string;
  // Sort number
  readonly sortNo?: number | null | undefined;
  // Group name
  readonly groupName: string;
  // Validation filter
  readonly validationFilter: string;
  // Visibility filter
  readonly visibilityFilter: string;
  // Show description
  // Property values
  readonly values: ReadonlyArray<{
    // Value
    readonly value: string;
    // Validation filter
    readonly validationFilter: string;
    // Sort number
    readonly sortNo?: number | null | undefined;

    readonly image?: string | null | undefined;
  }>;
}

export function createPropertiesSelectorPropertiesFromGraphqlProperties(
  productProperties: ReadonlyArray<GraphQlProperty>,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  skipGroupNames: boolean = false,
  explicitPropertyNamnes: ReadonlyArray<string> = productProperties.map(
    p => p.name
  )
): ReadonlyArray<GenesysPropertiesSelectorTypes.Property> {
  const explicitPropertyNamnesSet = new Set(explicitPropertyNamnes);
  const toPropertiesSelectorProperty = createToPropertiesSelectorProperty(
    selectedProperties,
    skipGroupNames
  );
  const selectorProperties = (productProperties as Array<GraphQlProperty>)
    .filter(p => explicitPropertyNamnesSet.has(p.name))
    .filter(p => !p.name.startsWith("acc_"))
    .reduce(toPropertiesSelectorProperty, [])
    .sort((a, b) => a.sort_no - b.sort_no);

  return selectorProperties;
}

function createToPropertiesSelectorProperty(
  selectedProperties: PropertyValueSet.PropertyValueSet,
  skipGroupNames: boolean
) {
  return (
    selectorProperties: Array<GenesysPropertiesSelectorTypes.Property>,
    productProperty: GraphQlProperty
  ): Array<GenesysPropertiesSelectorTypes.Property> => {
    const property: GenesysPropertiesSelectorTypes.Property = {
      conversionParameters: QuantityConversion.createConversionParameters(
        productProperty.quantityConversionParams,
        selectedProperties
      ),
      name: productProperty.name,
      quantity: productProperty.quantity as Quantity.Quantity,
      sort_no: numberOrZero(productProperty.sortNo),
      group: (!skipGroupNames && productProperty.groupName) || "",
      validation_filter: PropertyFilterHelpers.createPropertyFilter(
        productProperty.validationFilter,
        PropertyFilter.Empty
      ),
      visibility_filter: PropertyFilterHelpers.createPropertyFilter(
        productProperty.visibilityFilter,
        PropertyFilter.Empty
      ),
      value: productProperty.values
        .map(v => {
          const pv: PropertiesSelector.PropertyValueItem = {
            value: PropertyValue.fromString(v.value)!,
            sort_no: numberOrZero(v.sortNo),
            property_filter: PropertyFilterHelpers.createPropertyFilter(
              v.validationFilter,
              PropertyFilter.Empty
            ),
            image: v.image || undefined
          };
          return pv;
        })
        .sort((a, b) => a.sort_no - b.sort_no)
    };
    selectorProperties.push(property);
    return selectorProperties;
  };
}

function numberOrZero(value: number | null | undefined): number {
  return value !== null && value !== undefined ? value : 0;
}
