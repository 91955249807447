import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";
import { Point } from "./types";
import { Physics } from "@munters/calculations";
import * as PropertiesSelector from "../../properties-selector";

export function getNewPoint(): PropertyValueSet.PropertyValueSet {
  return {
    temperature: PropertyValue.fromAmount(Amount.create(20, Units.Celsius)),
    humidity: PropertyValue.fromAmount(
      Amount.create(10, Units.GramPerKilogram)
    ),
    enthalpy: PropertyValue.fromAmount(
      Amount.create(45.5, Units.KilojoulePerKilogram)
    ),
    sensiblepower: PropertyValue.fromAmount(Amount.create(0, Units.KiloWatt)),
    totalpower: PropertyValue.fromAmount(Amount.create(0, Units.KiloWatt))
  };
}

export function getCalculatedPoints(
  points: ReadonlyArray<Point>,
  pressurePvs: PropertyValueSet.PropertyValueSet,
  airflowPvs: PropertyValueSet.PropertyValueSet
): ReadonlyArray<Point> {
  const pointsWithCalculatedEnthalpy = points.map(p =>
    getPointWithCalculatedEnthalpy(p, pressurePvs)
  );
  return getPointsWithCalculatedPower(
    pointsWithCalculatedEnthalpy,
    pressurePvs,
    airflowPvs
  );
}

function getPointWithCalculatedEnthalpy(
  point: Point,
  pressurePvs: PropertyValueSet.PropertyValueSet
): Point {
  const pointPvs = PropertiesSelector.getSelectedProperties(point.state);

  const humidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    "humidity",
    pointPvs
  )!;

  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "temperature",
    pointPvs
  )!;

  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "pressure",
    pressurePvs
  )!;

  const enthalpy =
    Physics.RP1485.AmountConversions.humidityRatioToSpecificEnthalpy(
      pressure,
      temperature,
      humidity
    );

  const newPoint: Point = {
    index: point.index,
    state: {
      properties: PropertyValueSet.set(
        "enthalpy",
        PropertyValue.fromAmount(enthalpy),
        pointPvs
      )
    }
  };

  return newPoint;
}

function getPointsWithCalculatedPower(
  points: ReadonlyArray<Point>,
  pressurePvs: PropertyValueSet.PropertyValueSet,
  airflowPvs: PropertyValueSet.PropertyValueSet
): ReadonlyArray<Point> {
  return points.map(point => {
    if (point.index === 0) {
      return point;
    }

    const airInPvs = PropertiesSelector.getSelectedProperties(
      points[point.index - 1].state
    );
    let pointPvs = PropertiesSelector.getSelectedProperties(point.state);

    const temperatureIn = PropertyValueSet.getAmount(
      "temperature",
      airInPvs
    ) as Amount.Amount<Quantity.Temperature>;
    const humidityIn = PropertyValueSet.getAmount(
      "humidity",
      airInPvs
    ) as Amount.Amount<Quantity.HumidityRatio>;

    const temperatureOut = PropertyValueSet.getAmount(
      "temperature",
      pointPvs
    ) as Amount.Amount<Quantity.Temperature>;
    const humidtyOut = PropertyValueSet.getAmount(
      "humidity",
      pointPvs
    ) as Amount.Amount<Quantity.HumidityRatio>;

    const pressure = PropertyValueSet.getAmount(
      "pressure",
      pressurePvs
    ) as Amount.Amount<Quantity.Pressure>;

    const flow = PropertyValueSet.getAmount(
      "airflow",
      airflowPvs
    ) as Amount.Amount<Quantity.MassFlow>;

    pointPvs = PropertyValueSet.set(
      "sensiblepower",
      PropertyValue.fromAmount(
        Physics.AirCalculation.calculateAirSensiblePowerForAirFlowAirInAirOut(
          temperatureIn,
          humidityIn,
          pressure,
          temperatureOut,
          pressure,
          flow
        )
      ),
      pointPvs
    );

    pointPvs = PropertyValueSet.set(
      "totalpower",
      PropertyValue.fromAmount(
        Physics.AirCalculation.calculateAirTotalPowerForAirFlowAirInAirOut(
          temperatureIn,
          humidityIn,
          pressure,
          temperatureOut,
          humidtyOut,
          pressure,
          flow
        )
      ),
      pointPvs
    );

    return {
      ...point,
      state: { properties: pointPvs }
    };
  });
}
