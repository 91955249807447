import { encodeSvgStringToImgSrc } from "../../svg-string-img-encoder";

export function createManualIconSvg(color: string): string {
  const svgString = `
    <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>AAC08024-0BDA-4EE5-AD48-6040364620BC</title>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-198.000000, -378.000000)" fill="${color}">
            <g id="Group-4" transform="translate(78.000000, 378.000000)">
                <g id="Icons/Main-Menu/Profile" transform="translate(120.600000, 0.000000)">
                    <path d="M23.75,25 C23.75,25.69 23.19125,26.25 22.5,26.25 C21.80875,26.25 21.25,25.69 21.25,25 C21.25,21.55375 18.44625,18.75 15,18.75 C11.55375,18.75 8.75,21.55375 8.75,25 C8.75,25.69 8.19125,26.25 7.5,26.25 C6.80875,26.25 6.25,25.69 6.25,25 C6.25,20.175 10.17625,16.25 15,16.25 C19.82375,16.25 23.75,20.175 23.75,25 M15,6.25 C16.37875,6.25 17.5,7.37125 17.5,8.75 C17.5,10.12875 16.37875,11.25 15,11.25 C13.62125,11.25 12.5,10.12875 12.5,8.75 C12.5,7.37125 13.62125,6.25 15,6.25 M15,13.75 C17.7575,13.75 20,11.5075 20,8.75 C20,5.9925 17.7575,3.75 15,3.75 C12.2425,3.75 10,5.9925 10,8.75 C10,11.5075 12.2425,13.75 15,13.75" id="🎨-Icon-Сolor"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
