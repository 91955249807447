import * as React from "react";
import { Message, Divider, MessageSubTitle, TextButton } from "./elements";
import { RootGroupedMessage } from "@genesys/shared/lib/components-messages";
import { FormattedMessage } from "./formatted-message";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface MessageGroupProps {
  readonly messages: RootGroupedMessage;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly expandedGroup: boolean;
  readonly expand: () => void;
  readonly collapse: () => void;
  readonly translate: LanguageTexts.Translate;
}

export function MessageGroup(props: MessageGroupProps): JSX.Element {
  return (
    <>
      <Divider />
      {props.expandedGroup ? (
        <>
          {props.messages.entries.map(m => (
            <Message key={m.id}>
              {m.heading && <MessageSubTitle>{m.heading}:</MessageSubTitle>}
              <FormattedMessage
                getAmountFormat={props.getAmountFormat}
                errorCode={props.messages.code}
                parts={m.parts}
              />
            </Message>
          ))}
          <TextButton
            onClick={e => {
              e.stopPropagation();
              props.collapse();
            }}
          >
            {props.translate(LanguageTexts.showLess())}
          </TextButton>
        </>
      ) : (
        <>
          <Message>
            <FormattedMessage
              getAmountFormat={props.getAmountFormat}
              errorCode={props.messages.code}
              parts={props.messages.genericParts}
            />
          </Message>
          <TextButton
            onClick={e => {
              e.stopPropagation();
              props.expand();
            }}
          >
            {props.translate(LanguageTexts.showAll())} (
            {props.messages.entries.length})
          </TextButton>
        </>
      )}
    </>
  );
}
