import * as GraphQLTypes from "../../graphql-types";
import { CalculationResults } from "../main-content/result";

export function createCalculationResultInput(
  calculationResults: GraphQLTypes.MoistureLoadResultFragment | undefined
): CalculationResults | undefined {
  if (!calculationResults) {
    return undefined;
  }

  return {
    diagramResults: calculationResults.diagramResults,
    monthlyResults: calculationResults.monthlyResults.map(result => ({
      resultNameInput: result.resultName,
      propertyValueSet: result.propertyValueSet
    })),
    summaryResults: calculationResults.summaryResults.map(result => ({
      resultNameInput: result.resultName,
      propertyValueSet: result.propertyValueSet
    })),
    summaryWarnings: calculationResults.resultWarnings.summaryWarnings.map(
      warning => ({
        moistureLoadWarning: [
          "LOW_HUMIDITY",
          "HIGH_HUMIDITY",
          "LOW_TEMP",
          "HIGH_TEMP",
          "GENERAL_WARNING"
        ].indexOf(warning.warning),

        localizedResultNames: warning.localizedResultNames,
        plainTextResultNames: [] // TODO
      })
    ),
    maxLoadResults: calculationResults.maxLoadResults.map(result => ({
      maxLoadId: [
        "MAX_DEHUMIDIFICATION",
        "MAX_HUMIDIFICATION",
        "MAX_HEATING",
        "MAX_COOLING"
      ].indexOf(result.caseType),
      needResourceString: result.needResourceString,
      caseNameResourceString: result.caseName,
      usedRoomControlSettings: result.usedRoomControlSettings,
      operatingCaseResults: result.operatingCaseResults,
      warnings: result.roomWarnings.map(warning =>
        [
          "LOW_HUMIDITY",
          "HIGH_HUMIDITY",
          "LOW_TEMP",
          "HIGH_TEMP",
          "GENERAL_WARNING"
        ].indexOf(warning)
      ),
      flowSuggestions: result.flowSuggestions.map(flowSuggestion => ({
        valuesToShow: flowSuggestion.result,
        valid: flowSuggestion.isValid,
        flowSourceId: [
          "MIN_COOLING_FLOW",
          "MAX_COOLING_FLOW",
          "MIN_HEATING_FLOW",
          "MAX_HEATING_FLOW",
          "MIN_DEHUMIDIFICATION_FLOW",
          "MAX_DEHUMIDIFICATION_FLOW",
          "MIN_HUMIDIFICATION_FLOW",
          "MAX_HUMIDIFICATION_FLOW",
          "MAKE_UP_FLOW",
          "MIN_EXCHANGES",
          "OK_GENERATED"
        ].indexOf(flowSuggestion.flowSourceId)
      }))
    }))
  };
}

export function getResultParams(
  moistureLoadCalculationResult:
    | NonNullable<
        GraphQLTypes.MoistureLoadCalculationResult["user"]["moistureLoadCalculation"]
      >
    | null
    | undefined,
  moistureLoadClaimValue: number
): {
  readonly result: GraphQLTypes.MoistureLoadResultFragment | undefined;
  readonly showDetailedResults: boolean;
  readonly showAnnualSummary: boolean;
  readonly showReport: boolean;
} {
  if (moistureLoadCalculationResult?.success) {
    const result = moistureLoadCalculationResult.result!;
    const showDetailedResults = !!(
      moistureLoadClaimValue >= 1 && result.diagramResults.length
    );

    const showSystemFlowSuggestions =
      moistureLoadClaimValue >= 1 && result.maxLoadResults.length > 0;

    const showAnnualSummary =
      moistureLoadClaimValue >= 1 &&
      (result.monthlyResults.length > 0 || result.summaryResults.length > 0);

    const showReport =
      moistureLoadClaimValue >= 1 &&
      (showSystemFlowSuggestions || showDetailedResults || showAnnualSummary);
    return {
      result,
      showDetailedResults,
      showAnnualSummary,
      showReport
    };
  } else {
    return {
      result: undefined,
      showDetailedResults: false,
      showAnnualSummary: false,
      showReport: false
    };
  }
}
