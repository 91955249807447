import * as React from "react";
import { Message, Divider, MessageSubTitle } from "./elements";
import { RootMessageEntry } from "@genesys/shared/lib/components-messages";
import { FormattedMessage } from "./formatted-message";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";

export interface MessageSingleProps {
  readonly message: RootMessageEntry;
  readonly code: number;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
}

export function MessageSingle(props: MessageSingleProps): JSX.Element {
  return (
    <>
      <Divider />
      <Message>
        {props.message.heading && (
          <MessageSubTitle>{props.message.heading}:</MessageSubTitle>
        )}
        <FormattedMessage
          getAmountFormat={props.getAmountFormat}
          errorCode={props.code}
          parts={props.message.parts}
        />
      </Message>
    </>
  );
}
