import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";

const dhmodelPropertyName = "dhmodel";

export function notes(
  system: System.System,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  const systemTypeId = system.file.systemTypeId;
  const dhuComponent = system.components.find(c => c.productId.endsWith("DHU"));

  if (!dhuComponent) {
    return [""];
  }

  const dhuProps = dhuComponent.properties;
  const dhmodel = PropertyValueSet.getInteger(dhmodelPropertyName, dhuProps);
  const dhText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, dhmodelPropertyName)
  )}=${translate(
    dhmodel !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          dhmodelPropertyName,
          dhmodel
        )
      : LanguageTexts.pNull()
  )}`;

  return [dhText];
}
