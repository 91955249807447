import * as React from "react";
import styled from "styled-components";
import { GenesysTableOption, GenesysTableSelect } from "@genesys/ui-elements";

const options = [
  {
    label: "",
    // tslint:disable-next-line
    imageUrl: require("ui-elements/assets/toggle/settings-dark.svg"),
    isItemValid: true,
    value: 0
  },
  {
    label: "",
    // tslint:disable-next-line
    imageUrl: require("ui-elements/assets/toggle/manual-dark.svg"),
    isItemValid: true,
    value: 1
  }
];

const Image = styled.img`
  width: 18px;
  vertical-align: middle;
`;

export function CustomPressureSelector({
  isManual,
  isDisabled: isLocked,
  showCodes,
  onChange
}: {
  readonly isManual: boolean;
  readonly isDisabled: boolean;
  readonly showCodes: boolean;
  readonly onChange: () => void;
}): JSX.Element | null {
  return (
    <GenesysTableSelect
      onChange={_e => onChange()}
      value={Number(isManual)}
      disabled={isLocked}
    >
      {options.map(o => (
        <GenesysTableOption key={o.value} value={o.value}>
          <Image src={o.imageUrl} />
          {showCodes && <span> ({o.value})</span>}
        </GenesysTableOption>
      ))}
    </GenesysTableSelect>
  );
}
