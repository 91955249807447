import styled from "styled-components";

export const Root = styled.div``;

export const LoadedStateBox = styled.div``;

export const NavigationBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  button {
    margin-left: 5px;
  }
`;

export const RefreshButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: solid #ddd 1px;
  padding: 10px;
`;

export const LogsRoot = styled.div`
  padding: 5px 10px;
`;

export const FilterSelectionBox = styled.div`
  display: flex;
  button {
    margin: 0px 5px;
  }
`;

export const ExportBox = styled.div`
  padding: 10px 5px;
`;

export const FiltersBox = styled.div`
  padding: 5px 10px;
  border-bottom: 1px #ddd solid;

  ul {
    list-style-type: none;
  }
`;

export const CategoriesBox = styled.div`
  display: flex;
  margin-bottom: 10px;
  column-gap: 15px;

  h2 {
    padding: 10px 0;
  }
`;

export const AdvancedFilterBox = styled.div`
  display: flex;
  margin-bottom: 10px;
  column-gap: 15px;

  h2 {
    padding: 10px 0;
  }
`;

export const RegexFilterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px;

  textArea {
    width: 700px;
  }

  button {
    margin: 5px 0px;
  }
`;

export const RowsContainer = styled.div`
  padding: 10px 0;
  max-width: 100%;

  p {
    white-space: pre;
  }

  p:nth-child(2n + 2) {
    background-color: rgb(211, 211, 211);
  }
`;

export const TimerViewRoot = styled.div`
  padding: 12px 24px;
`;

// Timer elements

export const TopContainer = styled.div`
  display: flex;
  position: relative;
`;

export const OpenableRelativeWithMarginContainer = styled.div`
  margin-left: 32px;
  position: relative;
  ${(props: { readonly isOpen: boolean }) =>
    props.isOpen ? "" : "display: none"};
`;

export const OpenableWithMarginContainer = styled.div`
  margin-left: 32px;
  ${(props: { readonly isOpen: boolean }) =>
    props.isOpen ? "" : "display: none"};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 1px;
  left: -20px;
  img {
    cursor: pointer;
  }
`;
