import styled from "styled-components";

// tslint:disable-next-line
export const H3 = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
`;
