import { EffectManager } from "@typescript-tea/core";
import * as SelfActionEntry from "./on-self-action-entry";
import * as OnEffectsEntry from "./on-effects-entry";
import { Logger } from "../types";

// Use performance API if it's available in order to get better precision
const timer =
  typeof performance !== "undefined" &&
  performance !== null &&
  typeof performance.now === "function"
    ? performance
    : Date;

export interface EffectManagerLoggerOptions {
  readonly showDiff: boolean;
}

export function effectManagersWithLogger<ProgramAction, SelfAction, SelfState>(
  managers: readonly EffectManager<
    unknown,
    ProgramAction,
    SelfAction,
    SelfState
  >[],
  options: EffectManagerLoggerOptions = { showDiff: false },
  logger: Logger = console
): readonly EffectManager[] {
  return managers.map(m =>
    effectManagerWithLogger(
      m as EffectManager<string, ProgramAction, SelfAction, SelfState>,
      options,
      logger
    )
  );
}

// const grayLight = "color: gray; font-weight: lighter;";

export function effectManagerWithLogger<ProgramAction, SelfAction, SelfState>(
  manager: EffectManager<string, ProgramAction, SelfAction, SelfState>,
  options: EffectManagerLoggerOptions,
  logger: Logger = console
): EffectManager<string, ProgramAction, SelfAction, SelfState> {
  const onEffectsBuffer: OnEffectsEntry.OnEffectsEntry<
    ProgramAction,
    SelfState
  >[] = [];
  const selfActionBuffer: SelfActionEntry.SelfActionEntry<
    SelfAction,
    SelfState
  >[] = [];
  return {
    home: manager.home,
    mapCmd: manager.mapCmd,
    mapSub: manager.mapSub,
    setup: manager.setup,
    onEffects: (dispatchApp, dispatchSelf, cmds, subs, state) => {
      const started = timer.now();
      const startedTime = new Date();

      // Do the original call
      let result: SelfState | undefined = undefined;
      let error = undefined;
      try {
        result = manager.onEffects(
          dispatchApp,
          dispatchSelf,
          cmds,
          subs,
          state
        );
      } catch (e) {
        error = e;
      }
      const took = timer.now() - started;

      const logEntry: OnEffectsEntry.OnEffectsEntry<
        ProgramAction,
        SelfState
      > = {
        cmds,
        subs,
        prevState: state,
        nextState: result!,
        error,
        started,
        startedTime,
        took
      };
      onEffectsBuffer.push(logEntry);

      OnEffectsEntry.printBuffer(
        onEffectsBuffer,
        logger,
        manager,
        options.showDiff
      );
      onEffectsBuffer.length = 0;

      if (error) {
        throw error;
      }

      return result!;
    },
    onSelfAction: (dispatchApp, dispatchSelf, action, state) => {
      const started = timer.now();
      const startedTime = new Date();

      // Do the original call
      let result: SelfState | undefined = undefined;
      let error = undefined;
      try {
        result = manager.onSelfAction(dispatchApp, dispatchSelf, action, state);
      } catch (e) {
        error = e;
      }
      const took = timer.now() - started;

      const logEntry: SelfActionEntry.SelfActionEntry<SelfAction, SelfState> = {
        action,
        prevState: state,
        nextState: result!,
        error,
        started,
        startedTime,
        took
      };
      selfActionBuffer.push(logEntry);

      SelfActionEntry.printBuffer(
        selfActionBuffer,
        logger,
        manager,
        options.showDiff
      );
      selfActionBuffer.length = 0;

      if (error) {
        throw error;
      }

      return result!;
    }
  };
}
