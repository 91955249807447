import styled from "styled-components";
// import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchFilterTextContainer = styled.div`
  input {
    display: block;
    width: 100%;
  }
`;
