import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import { PromiseCmd, promiseCmd } from "../promise-effect-manager";

export type State = {
  readonly showNewVersionMessage: boolean;
  readonly loadedVersion: string | undefined;
};
export const init = (): readonly [State, Cmd<Action>] => {
  return [
    {
      showNewVersionMessage: false,
      loadedVersion: undefined
    },
    createDelayedVersionFetch(undefined)
  ];
};

export const Action = ctorsUnion({
  VersionCheck: (response: string) => ({ response }),
  Dummy: (language: string) => ({ language })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): readonly [State, Cmd<Action>?] {
  switch (action.type) {
    case "VersionCheck": {
      if (state.loadedVersion === undefined) {
        const loadedVersion = action.response;
        return [
          { loadedVersion, showNewVersionMessage: false },
          createDelayedVersionFetch(loadedVersion)
        ];
      }

      if (state.loadedVersion !== action.response) {
        return [{ ...state, showNewVersionMessage: true }];
      }

      return [state, createDelayedVersionFetch(state.loadedVersion)];
    }
    case "Dummy": {
      return [
        {
          ...state
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

const clientCommitUrl = "/assets/client-commit.txt";
const timeout = 60 * 1000; // seconds
function createDelayedVersionFetch(
  oldValue: string | undefined
): PromiseCmd<Action, string | undefined> {
  return promiseCmd(
    (): Promise<string | undefined> =>
      new Promise(resolve => {
        setTimeout(async () => {
          // Don't make requests when the tab is not active
          // Just resolve the old value
          if (document.hidden) {
            resolve(oldValue);
            return;
          }

          const resp = await fetch(clientCommitUrl);
          if (resp.status !== 200) {
            resolve(oldValue);
            return;
          }

          const value = await resp.text();
          resolve(value);
        }, timeout);
      }),
    Action.VersionCheck
  );
}
