import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createElectricalOptionSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps): string {
  const svgString = `
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>017EE77A-3925-4F98-87DF-3CB4266A0F14</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-67.000000, -1786.000000)">
            <g id="Icons/System-Menu-Items/Electrical-Options" transform="translate(72.000000, 1789.000000)">
                <g id="Rectangle-Copy-14">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <path d="M39.8283184,12.2400001 L25.7498147,12.2400001 L16.8748203,34.2884359 L27.601,34.288 L27.6018384,47.2227815 L44.5497246,25.3362919 L34.816,25.336 L39.8283184,12.2400001 Z M26.776,13.759 L37.618,13.759 L32.6066766,26.8562918 L41.45,26.856 L29.121,42.776 L29.1218383,32.768436 L19.125,32.768 L26.776,13.759 Z" id="Path-2" fill="${color}" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
