import * as React from "react";
import * as Redux from "redux";
import { EntityCache } from "gql-cache";
import { mergeUserEntities } from "../graphql-entity-state";

// tslint:disable-next-line:variable-name
export interface UserEntitiesContextValue {
  readonly type: "UserEntitiesContextValue";
  readonly entities: EntityCache;
  readonly mergeEntities: (entities: EntityCache) => void;
}

// tslint:disable-next-line:variable-name
const userEntitiesContext = React.createContext<UserEntitiesContextValue>({
  type: "UserEntitiesContextValue",
  entities: {},
  mergeEntities: () => {
    /*  */
  }
});

interface Props {
  readonly dispatch: Redux.Dispatch<any>;
  readonly entities: EntityCache;
  readonly children: React.ReactNode;
}

export class UserEntitiesContextProvider extends React.Component<
  Props,
  UserEntitiesContextValue
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: "UserEntitiesContextValue",
      mergeEntities: this.mergeEntities.bind(this),
      entities: {}
    };
  }

  mergeEntities(entities: EntityCache) {
    this.props.dispatch(mergeUserEntities(entities, []));
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    props: Props,
    state: UserEntitiesContextValue
  ): Partial<UserEntitiesContextValue> | null {
    if (props.entities !== state.entities) {
      return {
        entities: props.entities
      };
    }

    return null;
  }
  render() {
    // Make sure there's only one child
    // https://reactjs.org/docs/react-api.html#reactchildrenonly
    return (
      <userEntitiesContext.Provider value={this.state}>
        {React.Children.only(this.props.children)}
      </userEntitiesContext.Provider>
    );
  }
}

// tslint:disable-next-line:variable-name
export const UserEntitiesContext = {
  Provider: UserEntitiesContextProvider,
  Consumer: userEntitiesContext.Consumer
};
