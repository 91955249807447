import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { TranslatePropertyName, TranslatePropertyValue } from "./types";

export function createTranslation(
  translate: LanguageTexts.Translate,
  productId: string
): {
  readonly propertyName: TranslatePropertyName;
  readonly propertyValue: TranslatePropertyValue;
} {
  return {
    propertyName: (propertyName: string) => {
      return translate(LanguageTexts.productProperty(productId, propertyName));
    },
    propertyValue: (propertyName, propertyValue) => {
      return translate(
        LanguageTexts.productPropertyValue(
          productId,
          propertyName,
          propertyValue
        )
      );
    }
  };
}
