import React from "react";
import styled from "styled-components";
import { SnackBarComponent } from "@genesys/ui-elements";
import { State } from "./state";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    margin-right: 30px;
  }
`;

export function View({ state }: { readonly state: State }) {
  return (
    <SnackBarComponent show={state.showNewVersionMessage}>
      <StyledDiv>
        <span>A new version has been deployed</span>
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            location.reload();
          }}
        >
          Click here to reload
        </a>
      </StyledDiv>
    </SnackBarComponent>
  );
}
