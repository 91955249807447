import styled from "styled-components";

// tslint:disable-next-line
export const Field = styled.fieldset`
  display: block;
  border: 1px solid #000;
  padding: 3px;

  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;

  > legend {
    text-align: left;
    padding: 3px;
  }
`;
