import * as PaperSizeEnum from "@genesys/shared/lib/enums/paper-size";
import * as SharedState from "../../../shared-state";
import * as GraphQlTypes from "../../../graphql-types";
import * as PrintParams from "../../../print-params";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { PropertyFilter } from "@genesys/property";

export const reportQuery = gql`
  query SystemReportQuery($input: GetSystemTypeInputType!) {
    product {
      systemType(input: $input) {
        id
        reports {
          id
          reportId
          textId
          minSystemStatus
          propertyFilter
          userParameters
          claimFilter
        }
      }
      languages {
        id
      }
    }
  }
`;

export interface ReportState {
  readonly id: string;
  readonly userParams: string;
  readonly isSelected: boolean;
}

export type State = {
  readonly reportStateMap: Map<string, ReportState> | undefined;
  readonly printParamsState: PrintParams.State | undefined;
  readonly reportHeaderInfo: ReportHeaderInfo | undefined;
  readonly reports: ReadonlyArray<Report> | undefined;
  readonly languages: ReadonlyArray<{ readonly id: string }> | undefined;
  readonly generatingReport: {
    [key in ReportType]: boolean;
  };
};

type ReportType = "pdf" | "docx";

// export interface ReportDTO {
//   readonly id: string;
//   readonly reportId: string;
//   readonly textId?: string | null;
//   // readonly defaultSelected: boolean;
//   readonly minSystemStatus: number;
//   readonly propertyFilter?: string | null | undefined;
//   readonly claimFilter?: string | null;
//   readonly userParameters?: string | null;
// }

export interface Report {
  readonly id: string;
  readonly name: string;
  readonly textId: string | undefined;
  readonly status: number;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly claimFilter: PropertyFilter.PropertyFilter;
  readonly userParams: string | null | undefined;
}

export interface ReportHeaderInfo {
  readonly systemName: string;
  readonly preparedFor: string;
  readonly salesOrder: string;
  readonly endUser: string;
}
export interface PaperSizeInfo {
  readonly size: PaperSizeEnum.PaperSize;
  readonly displayName: string;
}

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string
): readonly [State, Cmd<Action>] => {
  return [
    {
      printParamsState: undefined,
      reportStateMap: undefined,
      reportHeaderInfo: undefined,
      reports: undefined,
      languages: undefined,
      generatingReport: { pdf: false, docx: false }
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.SystemReportQuery,
      GraphQlTypes.SystemReportQueryVariables,
      Action
    >(
      reportQuery,
      {
        input: {
          systemTypeId: systemTypeId
        }
      },
      Action.reportQueryReceived
    )
  ];
};

export const Action = ctorsUnion({
  dispatchPrintParams: (action: PrintParams.Action) => ({ action }),
  saveCurrentSelectedReporsAsDefaults: () => ({}),
  setReportsState: (newStates: Map<string, ReportState>, id?: string) => ({
    newStates,
    id
  }),
  reportQueryReceived: (data: GraphQlTypes.SystemReportQuery) => ({
    data
  }),
  setReportHeaderInfo: (params: ReportHeaderInfo) => ({ params }),
  toggleGeneratingReport: (report: ReportType) => ({ report })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  systemTypeId: string,
  salesOrganisationId: string
): [State, SharedState.Action?] {
  switch (action.type) {
    case "dispatchPrintParams":
      const [printParamsState] = PrintParams.update(
        action.action,
        state.printParamsState!
      );

      return [
        {
          ...state,
          printParamsState
        }
      ];

    case "saveCurrentSelectedReporsAsDefaults": {
      return [
        {
          ...state
        },
        state.reportStateMap &&
          SharedState.Action.toggleReportForSystemType(
            systemTypeId,
            Array.from(state.reportStateMap.values())
              .filter(x => x.isSelected)
              .map(x => x.id)
            // action.id
          )
      ];
    }
    case "setReportsState":
      return [
        {
          ...state,
          reportStateMap: action.newStates
        }
        // SharedState.Action.toggleReportForSystemType(
        //   systemTypeId,
        //   action.ids
        //   // action.id
        // )
      ];
    case "setReportHeaderInfo":
      return [
        {
          ...state,
          reportHeaderInfo: action.params
        }
      ];
    case "reportQueryReceived": {
      const data = action.data;

      return [
        {
          ...state,
          printParamsState: PrintParams.init(data.product.languages)[0],
          reports: data.product.systemType.reports.map(x =>
            createReport(x, salesOrganisationId)
          ),
          languages: data.product.languages
        }
      ];
    }
    case "toggleGeneratingReport": {
      return [
        {
          ...state,
          generatingReport: {
            ...state.generatingReport,
            [action.report]: !state.generatingReport[action.report]
          }
        }
      ];
    }
    default:
      exhaustiveCheck(action);
  }
}

const createReport = (
  r: GraphQlTypes.SystemReportQuery["product"]["systemType"]["reports"][0],
  salesOrganisationId: string
) => ({
  id: r.id,
  name: r.reportId,
  textId: r.textId || undefined,
  status: r.minSystemStatus,
  propertyFilter: PropertyFilter.fromStringOrEmpty(r.propertyFilter || ""),
  claimFilter: PropertyFilter.fromStringOrEmpty(
    (r.claimFilter || "").replace(/{SystemSalesOrg}/gi, salesOrganisationId)
  ),
  userParams: r.userParameters
});
