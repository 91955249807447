import { exhaustiveCheck } from "ts-exhaustive-check";
import gql from "graphql-tag";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQlTypes from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

const getBuilderStateUserQuery = gql`
  query getBuildInScStateUserQuery($input: BuildInSolidContactStateInputType!) {
    user {
      getBuildInSolidContactState(input: $input) {
        state
        stateDescription
        modelLink
        imageData
      }
    }
  }
`;

const requestBuildMutation = gql`
  mutation RequestBuildInScMutation(
    $input: RequestBuildInSolidContactInputType!
  ) {
    requestBuildInSolidContact(input: $input)
  }
`;

export type State = {
  readonly stateNumber: number;
  readonly stateDescription: string;
  readonly modelLink: string;
  readonly image: string | undefined;
  readonly error: boolean;
  readonly isLoading: boolean;
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string
): [State, Cmd<Action>] => {
  return [
    {
      stateNumber: 0,
      stateDescription: "",
      modelLink: "",
      image: undefined,
      error: false,
      isLoading: true
    },
    sharedState.graphQL.queryUserCmd<
      GraphQlTypes.GetBuildInScStateUserQuery,
      GraphQlTypes.GetBuildInScStateUserQueryVariables,
      Action
    >(
      getBuilderStateUserQuery,
      {
        input: {
          systemId: systemTypeId
        }
      },
      Action.buildStateDataRecieved
    )
  ];
};

export const Action = ctorsUnion({
  getBuildState: (systemId: string) => ({ systemId }),
  buildStateDataRecieved: (data: GraphQlTypes.GetBuildInScStateUserQuery) => ({
    data
  }),
  requestBuild: (systemId: string) => ({ systemId }),
  errorBuild: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "getBuildState": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.GetBuildInScStateUserQuery,
          GraphQlTypes.GetBuildInScStateUserQueryVariables,
          Action
        >(
          getBuilderStateUserQuery,
          {
            input: {
              systemId: action.systemId
            }
          },
          Action.buildStateDataRecieved
        )
      ];
    }
    case "buildStateDataRecieved": {
      return [
        {
          ...state,
          stateNumber: action.data.user.getBuildInSolidContactState.state,
          stateDescription:
            action.data.user.getBuildInSolidContactState.stateDescription,
          modelLink: action.data.user.getBuildInSolidContactState.modelLink,
          image:
            action.data.user.getBuildInSolidContactState.imageData || undefined,
          isLoading: false
        }
      ];
    }

    case "requestBuild": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.RequestBuildInScMutation,
          GraphQlTypes.RequestBuildInScMutationVariables,
          Action
        >(
          requestBuildMutation,
          {
            input: {
              systemId: action.systemId
            }
          },
          data =>
            data.requestBuildInSolidContact
              ? Action.getBuildState(action.systemId)
              : Action.errorBuild()
        )
      ];
    }
    case "errorBuild": {
      return [{ ...state, error: true, isLoading: false }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
