import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createSystemManagerIconSvg(color: string): string {
  const svgString = `
<svg
  width="133"
  height="106"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  overflow="hidden"
>
  <g transform="translate(-2726 -240)">
    <rect
      x="2729.5"
      y="243.5"
      width="20.0002"
      height="19.0003"
      stroke="${color}"
      stroke-width="6.875"
      stroke-miterlimit="8"
      fill="${color}"
    />
    <rect
      x="2729.5"
      y="282.5"
      width="20.0002"
      height="19.9999"
      stroke="${color}"
      stroke-width="6.875"
      stroke-miterlimit="8"
      fill="${color}"
    />
    <rect
      x="2729.5"
      y="322.5"
      width="20.0002"
      height="19.9999"
      stroke="${color}"
      stroke-width="6.875"
      stroke-miterlimit="8"
      fill="${color}"
    />
    <path
      d="M2769.5 252.5 2845.67 252.501"
      stroke="${color}"
      stroke-width="22.9167"
      stroke-miterlimit="8"
      fill="${color}"
      fill-rule="evenodd"
    />
    <path
      d="M2770.5 291.5 2846.67 291.5"
      stroke="${color}"
      stroke-width="22.9167"
      stroke-miterlimit="8"
      fill="${color}"
      fill-rule="evenodd"
    />
    <path
      d="M2769.5 333.5 2845.67 333.5"
      stroke="${color}"
      stroke-width="22.9167"
      stroke-miterlimit="8"
      fill="${color}"
      fill-rule="evenodd"
    />
  </g>
</svg>
  `;
  return encodeSvgStringToImgSrc(svgString);
}
