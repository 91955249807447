import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { initSizeSelector, updateSizeSelector } from "./functions";
import { SystemTypeId, SizeSelectorState, SizeSelectorAction } from "./types";
import * as DataCenterSizeSelector from "../../../size-selectors/data-center-size-selector";
import * as DhuSizeSelector from "../../../size-selectors/dhu-size-selector";
import * as HumSizeSelector from "../../../size-selectors/hum-size-selector";
import * as EccSizeSelector from "../../../size-selectors/ecc-size-selector";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as System from "../../system";
import { Quantity } from "@genesys/uom";

export type State = {
  readonly sizeSelectorState: SizeSelectorState;
  readonly systemTypeId: SystemTypeId;
  readonly valuesHasChanged: boolean;
};

export const init = (
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?] => {
  const [sizeSelectorState, SizeSelectorCmd] = initSizeSelector(
    sharedState,
    system
  );

  return [
    {
      systemTypeId: system.file.systemTypeId as SystemTypeId,
      sizeSelectorState: sizeSelectorState,
      valuesHasChanged: false
    },
    Cmd.map(Action.dispatchSizeSelectorActions, SizeSelectorCmd)
  ];
};

export const Action = ctorsUnion({
  dispatchSizeSelectorActions: (action: SizeSelectorAction) => ({
    action
  }),
  dummy2: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchSizeSelectorActions": {
      if (!state?.sizeSelectorState) {
        return [state];
      }

      const [
        sizeSelectorState,
        sizeSelectorCmd,
        sharedStateAction,
        didSizeChange
      ] = updateSizeSelector(
        state.systemTypeId,
        action.action,
        state.sizeSelectorState
      );

      if (didSizeChange) {
        return [
          {
            ...state,
            sizeSelectorState: sizeSelectorState,
            valuesHasChanged: true
          },
          Cmd.map(Action.dispatchSizeSelectorActions, sizeSelectorCmd),
          sharedStateAction
        ];
      } else {
        return [
          {
            ...state,
            sizeSelectorState: sizeSelectorState
          },
          Cmd.map(Action.dispatchSizeSelectorActions, sizeSelectorCmd),
          sharedStateAction
        ];
      }
    }
    case "dummy2": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfigurationActions(
  state: State,
  system: System.System,
  type: "save" | "saveAndCalculate"
): ReadonlyArray<ConfiguratorAction> {
  switch (state.systemTypeId) {
    case "FAA":
    case "FAU":
    case "HUM": {
      const sizeComponent = system.components.find(c =>
        c.productId.endsWith("FA6")
      )!;
      return [
        ConfiguratorAction.updateComponentProperties(
          [
            {
              componentId: sizeComponent.id,
              properties: PropertyValueSet.setInteger(
                "hummodel",
                HumSizeSelector.getSize(
                  state.sizeSelectorState as HumSizeSelector.State
                )!,
                sizeComponent.properties
              )
            }
          ],
          type === "saveAndCalculate"
        )
      ];
    }
    case "ECC": {
      const sizeComponent = system.components.find(c =>
        c.productId.endsWith("HDE")
      )!;
      return [
        ConfiguratorAction.updateComponentProperties(
          [
            {
              componentId: sizeComponent.id,
              properties: PropertyValueSet.setAmount<Quantity.Length>(
                "padheight",
                EccSizeSelector.getSize(
                  state.sizeSelectorState as EccSizeSelector.State
                )!,
                sizeComponent.properties
              )
            }
          ],
          type === "saveAndCalculate"
        )
      ];
    }
    case "MLP":
    case "MXO":
    case "MXN": {
      const sizeComponent = system.components.find(c =>
        c.productId.endsWith("SYS")
      )!;
      return [
        ConfiguratorAction.updateComponentProperties(
          [
            {
              componentId: sizeComponent.id,
              properties: PropertyValueSet.merge(
                PropertyValueSet.fromProperty(
                  "size",
                  PropertyValue.fromInteger(
                    DhuSizeSelector.getSize(
                      state.sizeSelectorState as DhuSizeSelector.State
                    )!
                  )
                ),
                sizeComponent.properties
              )
            }
          ],
          type === "saveAndCalculate"
        )
      ];
    }
    case "DEB":
    case "DCX":
    case "DCA": {
      const sizeComponent = system.components.find(c =>
        c.productId.endsWith("SYS")
      )!;
      return [
        ConfiguratorAction.updateComponentProperties(
          [
            {
              componentId: sizeComponent.id,
              properties: PropertyValueSet.merge(
                PropertyValueSet.fromProperty(
                  "epxsize",
                  PropertyValue.fromInteger(
                    DataCenterSizeSelector.getSize(
                      state.sizeSelectorState as DataCenterSizeSelector.State
                    )!
                  )
                ),
                sizeComponent.properties
              )
            }
          ],
          type === "saveAndCalculate"
        )
      ];
    }
    default:
      return exhaustiveCheck(state.systemTypeId, true);
  }
}
