import styled from "styled-components";
import * as React from "react";

const footerHeight = "30px";

export interface Props {
  readonly children: React.ReactNode;
}

// tslint:disable-next-line
const FooterInformationBar = styled.div`
  width: 100%;
  background-color: #a9a9a9;
  color: #f5f5f5;
  font-size: 12pt;
  height: ${footerHeight};
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

// tslint:disable-next-line
const MainContent = styled.div`
  flex: auto;
  position: relative;
  padding-bottom: ${footerHeight};
  overflow-y: auto;
`;

// tslint:disable-next-line
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

// tslint:disable-next-line
export const ContainerWithFooter = (props: Props) => {
  const childArray = props.children as Array<React.ReactElement<{}>>;
  return (
    <Wrapper>
      <MainContent>{childArray[0]}</MainContent>
      <FooterInformationBar>{childArray[1]}</FooterInformationBar>
    </Wrapper>
  );
};
