import * as React from "react";
import * as Redux from "redux";
import { EntityCache } from "gql-cache";
import { mergeProductEntities } from "../graphql-entity-state";

export interface ProductEntitiesContextValue {
  readonly type: "ProductEntitiesContext";
  readonly entities: EntityCache;
  readonly mergeEntities: (entities: EntityCache) => void;
}

// tslint:disable-next-line:variable-name
const productEntitiesContext = React.createContext<ProductEntitiesContextValue>(
  {
    type: "ProductEntitiesContext",
    entities: {},
    mergeEntities: () => {
      /*  */
    }
  }
);

interface Props {
  readonly dispatch: Redux.Dispatch<any>;
  readonly entities: EntityCache;
  readonly children: React.ReactNode;
}

class ProductEntitiesContextProvider extends React.Component<
  Props,
  ProductEntitiesContextValue
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: "ProductEntitiesContext",
      mergeEntities: this.mergeEntities.bind(this),
      entities: {}
    };
  }

  mergeEntities(entities: EntityCache) {
    this.props.dispatch(mergeProductEntities(entities));
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    props: Props,
    state: ProductEntitiesContextValue
  ): Partial<ProductEntitiesContextValue> | null {
    if (props.entities !== state.entities) {
      return {
        entities: props.entities
      };
    }

    return null;
  }
  render() {
    // Make sure there's only one child
    // https://reactjs.org/docs/react-api.html#reactchildrenonly
    return (
      <productEntitiesContext.Provider value={this.state}>
        {React.Children.only(this.props.children)}
      </productEntitiesContext.Provider>
    );
  }
}

// tslint:disable-next-line:variable-name
export const ProductEntitiesContext = {
  Provider: ProductEntitiesContextProvider,
  Consumer: productEntitiesContext.Consumer
};
