import * as GraphQL from "graphql";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Unit } from "@genesys/uom";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";

export interface AlertMessage {
  readonly content: string;
  readonly timeout: number;
  readonly messageType: "info" | "error" | "warning" | "success";
}

export interface AmountFieldProfile {
  readonly id: string;
  readonly name: string;
  readonly measureSystem: number;
}

export interface EnergySetting {
  readonly id: string;
  readonly settingName: string;
  readonly settingValue: string;
}

export interface KVPSettings {
  readonly settingName: string;
  readonly settingValue: string;
}

export type CreateSystemType = { readonly [key: string]: number | undefined };

export type BriefSystemInfo = {
  readonly id: string;
  readonly systemFileId: string;
  readonly systemTypeId: string;
  readonly systemFileName: string;
  readonly name: string;
  readonly genesysNo: number;
  readonly revisionNo: number;
  readonly status: number;
  readonly statusDate: number;
  readonly owner: string;
  readonly labels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly systems: ReadonlyArray<BriefSystemInfoRevision>;
};

export type BriefSystemInfoRevision = {
  readonly id: string;
  readonly revisionNo: number;
  readonly status: number;
  readonly statusDate: number;
  readonly comment: string;
  readonly salesOrganisationId: string;
};

export interface UserReportSettings {
  readonly systemType: string;
  readonly reports: ReadonlyArray<ReportSetting>;
}

export interface ReportSetting {
  readonly reportId: string;
  readonly reportSettingParams: ReadonlyArray<ReportSettingParam>;
}

export interface ReportSettingParam {
  readonly paramName: string;
  readonly value: string;
}

export interface PsychrometricChartSettings {
  readonly chartType: string;
  readonly limits: string;
  readonly humidityMax: string;
  readonly temperatureMin: string;
  readonly temperatureMax: string;
}

export type User = {
  readonly id: string;
  readonly name: string;
  readonly userName: string;
  readonly hasAcceptedTerms: boolean;
  readonly labels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly applicationClaims: Authorization.ApplicationClaims;
  readonly currencies: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly signBefore?: string;
    readonly signAfter?: string;
  }>;
  readonly salesOrganisations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly lastOpenedSystems: ReadonlyArray<BreifSystem>;
  readonly seenNewsItems: ReadonlyArray<number>;
  readonly favoritesSystems: ReadonlyArray<BreifSystem>;
  readonly lastCreatedSystemType: string | undefined;
  readonly createSystemType: CreateSystemType;
  readonly searchScope: string;
  readonly settings: {
    readonly amountFieldProfiles: ReadonlyArray<AmountFieldProfile>;
    readonly selectedAmountProfile: AmountFieldProfile;
    readonly userAmountFieldsMap: ScreenAmounts.UserAmountFieldsMap;
    readonly defaultSalesOrganisation: string | undefined;
    readonly language: string;
    readonly energySettings: ReadonlyArray<EnergySetting>;
    readonly reportSettings: ReadonlyArray<UserReportSettings>;
    readonly psychrometricChartSettings: PsychrometricChartSettings;
    readonly systemSummarySettings: ReadonlyArray<KVPSettings>;
    readonly locale: string;
    readonly currency: string;
    readonly climate: {
      readonly country: string;
      readonly region: string;
      readonly location: string | undefined;
      readonly heatingDataType: string | undefined;
      readonly coolingDataType: string | undefined;
      readonly heatingOccurence: string | undefined;
      readonly coolingOccurence: string | undefined;
      readonly climateDataSource: string | undefined;
      readonly manualData?: string | undefined;
    };
  };
};

export type BriefSystemLoaded = {
  readonly type: "loaded";
  readonly system: BriefSystemInfo;
};

export type BreifSystemLoading = {
  readonly type: "loading";
  readonly id: string;
};

export type BreifSystemError = {
  readonly type: "error";
  readonly id: string;
  readonly message: string;
};

export interface DebugSettings {
  readonly includeServerLog: boolean;
  readonly showHiddenProperties: boolean;
  readonly showHiddenResults: boolean;
  readonly useCalculationWorkers: boolean;
  readonly showTextsDB: boolean;
  readonly showTextsWithTextId: boolean;
}

export type BreifSystem =
  | BriefSystemLoaded
  | BreifSystemLoading
  | BreifSystemError;

type QueryUser = <Data, Variables extends {}>(
  query: GraphQL.DocumentNode,
  variables: Variables
) => Promise<Data>;
type QueryUserCmd = <Data, Variables extends {}, Action>(
  query: GraphQL.DocumentNode,
  variables: Variables,
  onResolved: (data: Data) => Action,
  onRejected?: (error: Error) => Action
) => Cmd<Action>;
type QueryProduct = <Data, Variables extends {}>(
  query: GraphQL.DocumentNode,
  variables: Variables
) => Promise<Data>;
type QueryProductCmd = <Data, Variables extends {}, Action>(
  query: GraphQL.DocumentNode,
  variables: Variables,
  onResolved: (data: Data) => Action,
  onRejected?: (error: Error) => Action
) => Cmd<Action>;

export interface State {
  readonly accessToken: string;
  readonly user: User;
  readonly debugSettings: {
    readonly includeServerLog: boolean;
    readonly showHiddenProperties: boolean;
    readonly showHiddenResults: boolean;
    readonly useCalculationWorkers: boolean;
    readonly showTextsDB: boolean;
    readonly showTextsWithTextId: boolean;
  };
  readonly genesysPrefixNotation: {
    readonly genesysNo: string;
    readonly pricingNo: string;
    readonly moistureLoadNo: string;
  };
  readonly screenAmounts: {
    readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
    readonly getPropertyFormats: ScreenAmounts.GetPropertyFormats;
  };
  readonly graphQL: {
    readonly queryUser: QueryUser;
    readonly queryUserCmd: QueryUserCmd;
    readonly queryProduct: QueryProduct;
    readonly queryProductCmd: QueryProductCmd;
  };
  readonly translate: LanguageTexts.Translate;
  readonly genesysPrefix: {
    readonly genesysNo: (genesysNo: number, revision: number) => string;
    readonly pricingNo: (pricingNo: number, revision: number) => string;
    readonly moistureLoadNo: (
      moistureLoadNo: number,
      revision: number
    ) => string;
  };
  readonly routes: ReadonlyArray<{
    readonly route: string;
    readonly url: string;
  }>;
}

export const Action = ctorsUnion({
  saveAmountFormat: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  clearAmountFormat: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  addAlertMessageToQueue: (message: AlertMessage) => ({ message }),
  updateLastOpenedSystems: (systemId: string) => ({ systemId }),
  updateSeenNewsItem: (newsItemIds: ReadonlyArray<number>) => ({ newsItemIds }),
  loadLastOpenedSystemsAndFavorites: (force: boolean = false) => ({ force }),
  updateLastCreatedSystemType: (systemType: string) => ({ systemType }),
  updateCreateSystemType: (systemTypes: ReadonlyArray<string>) => ({
    systemTypes
  }),
  toggleFavoritesSystems: (systemId: string) => ({ systemId }),
  toggleReportForSystemType: (
    systemTypeId: string,
    reports: ReadonlyArray<ReportSetting>
    // currentId?: string
  ) => ({
    systemTypeId,
    reports
  }),
  setSystemSummarySettings: (
    systemSummarySettings: ReadonlyArray<KVPSettings>
  ) => ({ systemSummarySettings }),
  setPsychrometricChartSettings: (settings: {
    readonly chartType?: string | null;
    readonly limits?: string | null;
    readonly humidityMax?: string | null;
    readonly temperatureMin?: string | null;
    readonly temperatureMax?: string | null;
  }) => ({ settings }),
  setUserLanguage: (language: string) => ({ language }),
  setUserLocale: (locale: string) => ({ locale }),
  setBrowserTitle: (title: string) => ({ title }),
  setUserCurrency: (currency: string) => ({ currency }),
  setUserInitialSettings: (
    culture: {
      readonly currency: string;
      readonly language: string;
      readonly locale: string;
    },
    activeAmountProfileId: string,
    climate: {
      readonly countryName: string;
      readonly regionName: string;
      readonly locationId: string;
    }
  ) => ({ culture, activeAmountProfileId, climate }),
  setUserDefaultSalesOrganisation: (
    defaultSalesOrganisationCode: string | undefined
  ) => ({ defaultSalesOrganisationCode }),
  setUserSettingsClimate: (userSettingsClimate: {
    readonly countryName: string;
    readonly regionName: string;
    readonly locationId: string;
    readonly heatingDataType?: string | null;
    readonly coolingDataType?: string | null;
    readonly heatingOccurence?: string | null;
    readonly coolingOccurence?: string | null;
    readonly climateDataSource?: string | null;
    readonly manualData?: string | null;
  }) => ({ userSettingsClimate }),
  setActiveAmountFieldProfile: (id: string) => ({ id }),
  resetUserSettings: () => ({}),
  setDebugSettings: (debugSettings: DebugSettings) => debugSettings,
  createLabel: (labelId: string, labelName: string) => ({ labelId, labelName }),
  updateLabel: (labelId: string, labelName: string) => ({ labelId, labelName }),
  deleteLabel: (labelId: string) => ({ labelId }),
  removeSystemsFromLastOpenedSystemsAndFavorites: (
    systemIds: ReadonlyArray<string>
  ) => ({ systemIds }),
  updateSearchScope: (searchScope: string) => ({ searchScope })
});
export type Action = CtorsUnion<typeof Action>;
