import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { promiseCmd } from "../../../promise-effect-manager";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { saveBinSelectionsAndEnergy } from "../save-bin-selections-and-energy";
import { updateSystemNonBlockingMutation } from "../update-system-mutation";

export function saveEnergy(
  sharedState: SharedState.State,
  systemId: string,
  systemStatus: SystemStatus,
  action: Extract<ConfiguratorAction, { readonly type: "saveEnergy" }>
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  const saveBinSelectionsAndEnergyStatus =
    systemStatus > SystemStatus.GenerateCasingsSuccess
      ? SystemStatus.GenerateCasingsSuccess
      : systemStatus;
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        await saveBinSelectionsAndEnergy(
          sharedState,
          systemId,
          action.binSelections,
          action.binOperatingCases,
          action.energySettings
        );
        if (action.calculate || action.calculateEnergy) {
          return sharedState.graphQL
            .queryUser<
              GraphQLTypes.UpdateSystemNonBlocking,
              GraphQLTypes.UpdateSystemNonBlockingVariables
            >(updateSystemNonBlockingMutation, {
              input: {
                forceUpdate: false,
                systemId,
                targetStatus: action.calculateEnergy
                  ? SystemStatus.EnergyCalculationSuccess
                  : SystemStatus.PriceCalculationSuccess
              }
            })
            .then(data => data.updateSystemNonBlocking.traceId || undefined);
        }
        return undefined;
      },
      traceId =>
        action.calculate || action.calculateEnergy
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculateEnergy
      ? [
          saveBinSelectionsAndEnergyStatus,
          SystemStatus.EnergyCalculationSuccess
        ]
      : action.calculate
      ? [saveBinSelectionsAndEnergyStatus, SystemStatus.PriceCalculationSuccess]
      : [saveBinSelectionsAndEnergyStatus]
  ];
}
