import * as GQLTypes from "../../../../../graphql-types";
import { promiseCmd } from "../../../../../promise-effect-manager";
import { Action } from "../../../actions";
import {
  resetUserSettingsMutation,
  setInitialSettingsMutation,
  setUserSettingsCultureMutation,
  setUserSettingsClimateMutation,
  updateSystemManagerSettingsMutation,
  setUserSettingsSalesMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { translationQuery } from "../../../../queries";
import { buildloadRecentAndFavoritesCmd } from "../../../tools";
import { UpdateFunction } from "../types";

export const handleSetUserLanguage: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserLanguage") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    queriesLoading: state.queriesLoading.concat("translationQuery"),
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        language: action.language
      }
    }
  };

  const translationCmd =
    action.language === "lngcod"
      ? promiseCmd<Action, {}>(
          () => Promise.resolve({}),
          _ =>
            Action.translationQueryReceived(
              {
                product: {
                  language: { id: "lngcod", texts: {} }
                }
              },
              "lngcod"
            )
        )
      : oldSharedState.graphQL.queryProductCmd<
          GQLTypes.TranslationQuery,
          GQLTypes.TranslationQueryVariables,
          Action
        >(translationQuery, { language: action.language }, data =>
          Action.translationQueryReceived(data, action.language)
        );
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsCulture,
        GQLTypes.SetUserSettingsCultureVariables,
        Action
      >(
        setUserSettingsCultureMutation,
        {
          userSettingsCulture: {
            language: action.language
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      ),
      translationCmd
    ]
  ];
};

export const handleSetUserLocale: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserLocale") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        locale: action.locale
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsCulture,
        GQLTypes.SetUserSettingsCultureVariables,
        Action
      >(
        setUserSettingsCultureMutation,
        {
          userSettingsCulture: {
            locale: action.locale
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetUserCurrency: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserCurrency") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        currency: action.currency
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsCulture,
        GQLTypes.SetUserSettingsCultureVariables,
        Action
      >(
        setUserSettingsCultureMutation,
        {
          userSettingsCulture: {
            currency: action.currency
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetUserDefaultSalesOrganisation: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserDefaultSalesOrganisation") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        defaultSalesOrganisation: action.defaultSalesOrganisationCode
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsSales,
        GQLTypes.SetUserSettingsSalesVariables,
        Action
      >(
        setUserSettingsSalesMutation,
        {
          userSettingsSales: {
            defaultSalesOrganisationCode:
              action.defaultSalesOrganisationCode || null
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetUserSettingsClimate: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserSettingsClimate") {
    return [state];
  }

  if (!state.user) {
    return [state];
  }
  const {
    countryName,
    regionName,
    locationId,
    heatingDataType,
    coolingDataType,
    heatingOccurence,
    coolingOccurence,
    climateDataSource,
    manualData
  } = action.userSettingsClimate;
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        climate: {
          ...state.user.settings.climate,
          country: countryName,
          region: regionName,
          location: locationId,
          heatingDataType: heatingDataType || undefined,
          coolingDataType: coolingDataType || undefined,
          heatingOccurence: heatingOccurence || undefined,
          coolingOccurence: coolingOccurence || undefined,
          climateDataSource: climateDataSource || undefined,
          manualData: manualData || undefined
        }
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsClimate,
        GQLTypes.SetUserSettingsClimateVariables,
        Action
      >(
        setUserSettingsClimateMutation,
        {
          userSettingsClimate: {
            countryName: countryName,
            regionName: regionName,
            locationId: locationId,
            heatingDataType: heatingDataType,
            coolingDataType: coolingDataType,
            heatingOccurence: heatingOccurence,
            coolingOccurence: coolingOccurence,
            climateDataSource: climateDataSource,
            manualData: manualData
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetUserInitialSettings: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setUserInitialSettings") {
    return [state];
  }
  return [
    state,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetInitialSettings,
        GQLTypes.SetInitialSettingsVariables,
        Action
      >(
        setInitialSettingsMutation,
        {
          amountProfileId: action.activeAmountProfileId,
          userSettingsClimate: action.climate,
          userSettingsCulture: action.culture
        },
        () => Action.sharedStateActionMutationCompleted(true)
      )
    ]
  ];
};

export const handleResetUserSettings: UpdateFunction = (
  _action,
  state,
  oldSharedState
) => {
  return [
    state,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.ResetUserSettings,
        GQLTypes.ResetUserSettingsVariables,
        Action
      >(resetUserSettingsMutation, {}, () =>
        Action.sharedStateActionMutationCompleted(true)
      )
    ]
  ];
};

export const handleUpdateSearchScope: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "updateSearchScope") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      searchScope: action.searchScope
    }
  };
  const cmds = [
    oldSharedState.graphQL.queryUserCmd<
      GQLTypes.UpdateSystemManagerSettings,
      GQLTypes.UpdateSystemManagerSettingsVariables,
      Action
    >(
      updateSystemManagerSettingsMutation,
      {
        systemManagerSettings: {
          lastSearchScopeType: action.searchScope
        }
      },
      () => Action.sharedStateActionMutationCompleted(false)
    ),
    buildloadRecentAndFavoritesCmd(oldSharedState, false)
  ];

  return [newState, cmds];
};
