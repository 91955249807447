import { PropertyValueSet } from "@genesys/property";
import { getWarningTextKey } from "../../utils";
import {
  ActiveLoadState,
  LoadDef,
  LoadOperationTime,
  LoadsState,
  State
} from "../types";
import * as OperationTimeGen2 from "../../../../../operation-time-manager";
import { loadMapCreator } from "../../loads-creator";
import { MoistureHeatLoadTypeEnum } from "@genesys/graphql-types";

export function generateActiveLoadsFromMoistureLoadSettings(
  loadsDefMap: Map<MoistureHeatLoadTypeEnum, LoadDef>,
  moistureLoadSettings: PropertyValueSet.PropertyValueSet,
  loadOperationTime: LoadOperationTime | undefined
): ReadonlyArray<ActiveLoadState> {
  const allLoads = Array.from(loadsDefMap.values());
  const activeLoads = getActiveLoadsFromSettings(
    allLoads,
    moistureLoadSettings
  );

  return activeLoads.map(loadName => {
    const loadDef = loadsDefMap.get(loadName)!;
    return createActiveLoad(loadDef, moistureLoadSettings, loadOperationTime);
  });
}

export function createActiveLoad(
  loadDef: LoadDef,
  currentMoistureLoadSettings: PropertyValueSet.PropertyValueSet,
  defaultLoadsOperationTimes: LoadOperationTime | undefined,
  currentLoadOperatimeState?: OperationTimeGen2.State
): ActiveLoadState {
  let operatimeState = currentLoadOperatimeState;

  if (!operatimeState) {
    const initialLoadSelectedOperatimes =
      getInitialLoadSelectedOperationalTimes(
        currentMoistureLoadSettings,
        loadDef,
        defaultLoadsOperationTimes
      );

    operatimeState = OperationTimeGen2.init(initialLoadSelectedOperatimes)[0];
  }

  return {
    sourceName: loadDef.sourceName,
    moistureAdded: loadDef.additionalInfo.isMoistureAdded(
      currentMoistureLoadSettings
    ),
    heatAdded: loadDef.additionalInfo.isHeatAdded(currentMoistureLoadSettings),
    operationTimeState: operatimeState,
    warningTextKey: getWarningTextKey(
      loadDef.sourceName,
      currentMoistureLoadSettings
    )
  };
}

export function getInitialLoadSelectedOperationalTimes(
  currentMoistureLoadSettings: PropertyValueSet.PropertyValueSet,
  loadDef: LoadDef,
  loadOperationTimes: LoadOperationTime | undefined
) {
  const loadName = loadDef.sourceName;
  const operationTime = loadOperationTimes?.[loadName];

  if (
    operationTime &&
    OperationTimeGen2.getPresetKey(operationTime) !== "noHours"
  ) {
    return operationTime;
  }

  if (loadOperationTimes && loadDef.operationalTime.getDefaultOperationTime) {
    return loadDef.operationalTime.getDefaultOperationTime(
      currentMoistureLoadSettings,
      loadOperationTimes
    );
  }

  return OperationTimeGen2.presets.factoryHours;
}

export function getSelectableloads(
  allVisibleLoads: ReadonlyArray<MoistureHeatLoadTypeEnum>,
  currentSelectedLoads: ReadonlyArray<ActiveLoadState>
): ReadonlyArray<MoistureHeatLoadTypeEnum> {
  const currentSelectedLoadNames = currentSelectedLoads.map(x => x.sourceName);
  return allVisibleLoads.filter(x => {
    return !currentSelectedLoadNames.includes(x);
  });
}

export function getActiveLoadsFromSettings(
  loadsDefMap: ReadonlyArray<LoadDef>,
  moistureLoadSettings: PropertyValueSet.PropertyValueSet
) {
  return loadsDefMap
    .filter(x => {
      const hasHeat = x.additionalInfo.isHeatAdded(moistureLoadSettings);
      const hasMoist = x.additionalInfo.isMoistureAdded(moistureLoadSettings);

      return hasHeat || hasMoist;
    })
    .map(x => x.sourceName);
}

export function getSelectedLoadState(
  state: State,
  updatedSettings: PropertyValueSet.PropertyValueSet
): LoadsState {
  const loadDefMap = getLoadsDefMap(state);

  const activeLoads = getActiveLoadsFromSettings(
    Array.from(loadDefMap.values()),
    updatedSettings
  );

  const loadsState = state.loadsState;

  const activeLoadsOperationTime = getActiveLoadsOperationalTimes(
    loadsState.activeLoads
  );

  const newCurrentSelectedLoads = activeLoads.map(loadName => {
    const loadDef = loadDefMap.get(loadName)!;
    return createActiveLoad(
      loadDef,
      updatedSettings,
      activeLoadsOperationTime!,
      loadsState.activeLoads.find(x => x.sourceName === loadDef.sourceName)
        ?.operationTimeState
    );
  });

  const newCurrentSelectedLoadsNames = newCurrentSelectedLoads.map(
    x => x.sourceName
  );

  const existingLoadsSortOrder = loadsState.selectedLoadsSortOrder.filter(
    name => newCurrentSelectedLoadsNames.includes(name)
  );

  const sortingArr = Array.from(
    new Set([...existingLoadsSortOrder, ...newCurrentSelectedLoadsNames])
  );

  return {
    activeLoads: newCurrentSelectedLoads.sort(
      (a, b) =>
        sortingArr.indexOf(a.sourceName) - sortingArr.indexOf(b.sourceName)
    ),
    selectedLoadsSortOrder: sortingArr,
    selectableLoads: getSelectableloads(
      loadsState.visibleLoads,
      newCurrentSelectedLoads
    ),
    selectedLoad: loadsState.selectedLoad,
    visibleLoads: loadsState.visibleLoads
  };
}

export function getActiveLoadsOperationalTimes(
  currentSelectedLoads: ReadonlyArray<ActiveLoadState>
) {
  return currentSelectedLoads.reduce((sofar, current) => {
    return {
      ...sofar,
      [current.sourceName]: current.operationTimeState
    };
  }, {} as LoadOperationTime);
}

export function getAllLoads(state: State) {
  const loadDefMap = getLoadsDefMap(state);
  return Array.from(loadDefMap.values());
}

export function getLoadsDefMap(state: State) {
  return new Map(
    (
      Object.keys(
        MoistureHeatLoadTypeEnum
      ) as ReadonlyArray<MoistureHeatLoadTypeEnum>
    ).map(key => {
      return [key, loadMapCreator[key](state.moistureLoadClaimValue!)];
    })
  );
}
