import React from "react";
import styled from "../../styled-components";
import { P1 } from "../typography";

// tslint:disable-next-line
const Span = styled.span<{
  readonly isChecked: boolean;
  readonly disabled: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  border-radius: 3px;
  border: ${props =>
    props.isChecked ? "1px solid white" : "1px solid #8f9bb3"};
  background-color: ${props => (props.isChecked ? "#00ADF2" : "#f7f9fc")};

  ${props => props.disabled && "opacity: 1;"}

  &:hover {
    background-color: #ccc;
  }

  &:after {
    content: "";
    position: absolute;

    left: 6px;
    top: 2px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2.5px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
// tslint:disable-next-line
const LabelContainer = styled.label<{
  readonly disabled: boolean;
}>`
  display: flex;
  position: relative;
  padding-left: 29px;
  color: #3d3d3d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${props => props.disabled && "opacity: 0.3;"}
  ${props => !props.disabled && "cursor: pointer;"}
`;

export function CheckBox(props: {
  readonly children?: React.ReactNode;
  readonly onClick: () => void;
  readonly isChecked: boolean;
  readonly disabled?: boolean;
}) {
  const { children: label, onClick, isChecked, disabled = false } = props;

  return (
    <>
      <LabelContainer disabled={disabled}>
        {label && <P1 weight="normal">{label}</P1>}
        <Span
          disabled={disabled}
          onClick={e => {
            if (!disabled) {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }
          }}
          isChecked={isChecked}
        ></Span>
      </LabelContainer>
    </>
  );
}
