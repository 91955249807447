import styled from "../../styled-components";

import React, { useState, useEffect, useRef } from "react";
// tslint:disable-next-line
const manualDark = require("ui-elements/assets/toggle/manual-dark.svg");

// tslint:disable-next-line
const manualLight = require("ui-elements/assets/toggle/manual-light.svg");

// tslint:disable-next-line
const settingsDark = require("ui-elements/assets/toggle/settings-dark.svg");

// tslint:disable-next-line
const settingsLight = require("ui-elements/assets/toggle/settings-light.svg");

// tslint:disable-next-line
const dropDownArrow = require("ui-elements/assets/table-drop-down/arrow-down.svg");

interface Props {
  readonly header?: boolean;
}

const logoHeight = "32px";

// tslint:disable-next-line
const Container = styled.div`
  display: inline-block;
  font-size: 14px;
  box-sizing: border-box;
  justify-items: start;
`;

// tslint:disable-next-line
const StyledImg = styled.img`
  height: ${(prorps: Props) => (prorps.header ? "20px" : "19px")};
  width: ${(prorps: Props) => (prorps.header ? "20px" : "19px")};
  margin-top: ${(prorps: Props) => (prorps.header ? "5px" : "7px")};
  margin-left: ${(prorps: Props) => (prorps.header ? "9px" : "4px")};
`;

// tslint:disable-next-line
const StyledImg2 = styled.img`
  margin-bottom: 7px;
  margin-left: 7px;
`;

// tslint:disable-next-line
const ToggleHeader = styled.div`
  box-sizing: border-box;
  height: ${logoHeight};
  width: 47px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
`;

// tslint:disable-next-line
const ToggleListContainer = styled.div`
  position: relative;
`;

// tslint:disable-next-line
const ToggleList = styled.ul`
  box-sizing: border-box;
  height: 62px;
  width: 128px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  list-style: none;
  position: absolute;
  bottom: 0;
`;

// tslint:disable-next-line
const ToggleListItem = styled.li`
  color: #757575;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  &:hover {
    background-color: #f1f5fa;
  }
  label {
    display: inline-block;

    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

// tslint:disable-next-line
export function Toggle() {
  //props: {} & React.SelectHTMLAttributes<HTMLSelectElement>
  const [state, setState] = useState({
    isOpen: false,
    selectedOption: ""
  });

  const divRef = useRef<HTMLUListElement>(null);

  function getStyle(divRef: React.RefObject<HTMLUListElement>) {
    if (divRef.current) {
      if (divRef.current.getBoundingClientRect().top < 0) {
        return {
          top: logoHeight
        };
      }
    }
    return {
      bottom: "0"
    };
  }

  const onOptionClicked = (value: string) => () => {
    setState({
      isOpen: !state.isOpen,
      selectedOption: value === "manual" ? manualLight : settingsLight
    });
  };

  const defautLogo = <StyledImg src={manualLight} />;

  useEffect(() => {
    if (divRef?.current) {
      const top = divRef.current.getBoundingClientRect().top;
      if (top < 0) {
        setState({
          isOpen: state.isOpen,
          selectedOption: state.selectedOption
        });
      }
    }
  }, [state.isOpen]);

  const toggling = () =>
    setState({
      isOpen: !state.isOpen,
      selectedOption: state.selectedOption
    });

  return (
    <Container>
      <ToggleListContainer>
        {state.isOpen && (
          <ToggleList
            style={getStyle(divRef)}
            ref={divRef as string & React.RefObject<HTMLUListElement>}
          >
            <ToggleListItem onClick={onOptionClicked("automatic")}>
              <StyledImg header={true} src={settingsDark} />
              <label>Automatic</label>
            </ToggleListItem>
            <ToggleListItem onClick={onOptionClicked("manual")}>
              <StyledImg header={true} src={manualDark} />
              <label>Manual</label>
            </ToggleListItem>
          </ToggleList>
        )}
      </ToggleListContainer>

      <ToggleHeader onClick={toggling}>
        {state.selectedOption ? (
          <StyledImg src={state.selectedOption} />
        ) : (
          defautLogo
        )}
        <StyledImg2 src={dropDownArrow} />
      </ToggleHeader>
    </Container>
  );
}
