import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import {
  ComponentMessage,
  getRootMessages,
  MessagePart,
  OperatingCaseResult
} from "@genesys/shared/lib/components-messages";
import { ErrorSeverity } from "@genesys/primitives";
import { AlertAccordion } from "@genesys/ui-elements";
import { PropertyValue } from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Format, UnitsFormat } from "@genesys/uom";

const Container = styled.div``;

const fieldGroup = "Errors";

const order: ReadonlyArray<MessageType> = ["info", "warning", "error"];

type MessageType = "warning" | "error" | "info";

export function ComponentMessages({
  componentMessages,
  operatingCaseResults,
  sharedState,
  systemTypeId,
  products,
  showProductName
}: {
  readonly componentMessages: ReadonlyArray<ComponentMessage>;
  readonly operatingCaseResults: ReadonlyArray<OperatingCaseResult>;
  readonly sharedState: SharedState.State;
  readonly systemTypeId: string;
  readonly products: ReadonlyArray<Product.Product>;
  readonly showProductName?: boolean;
}) {
  const translate = sharedState.translate;
  const amountFormat = sharedState.screenAmounts.getAmountFormat;

  let highestWarningType = 0;

  if (!componentMessages.length) {
    return null;
  }

  const rootMessages = getRootMessages(
    translate,
    products,
    systemTypeId,
    componentMessages,
    operatingCaseResults,
    showProductName
  );

  const allMessages = [
    ...rootMessages.exception.componentMessages.flatMap(cm =>
      cm.groups.flatMap(g =>
        g.entries.map(e => ({
          code: g.code,
          severity: ErrorSeverity.Exception,
          message: e
        }))
      )
    ),
    ...rootMessages.error.componentMessages.flatMap(cm =>
      cm.groups.flatMap(g =>
        g.entries.map(e => ({
          code: g.code,
          severity: ErrorSeverity.Error,
          message: e
        }))
      )
    ),
    ...rootMessages.warning.componentMessages.flatMap(cm =>
      cm.groups.flatMap(g =>
        g.entries.map(e => ({
          code: g.code,
          severity: ErrorSeverity.Warning,
          message: e
        }))
      )
    ),
    ...rootMessages.information.componentMessages.flatMap(cm =>
      cm.groups.flatMap(g =>
        g.entries.map(e => ({
          code: g.code,
          severity: ErrorSeverity.Info,
          message: e
        }))
      )
    )
  ];

  if (!allMessages.length) {
    return null;
  }

  const alerts: ReadonlyArray<{
    readonly title: string;
    readonly body: string;
  }> = allMessages.flatMap(message => {
    const warningType = getMessageType(message.severity);
    const currentWarningType = order.indexOf(warningType);

    if (currentWarningType > highestWarningType) {
      highestWarningType = currentWarningType;
    }

    const mainMessage = formattedMessage(
      message.message.parts,
      message.code,
      amountFormat
    );

    return [{ title: message.message.heading, body: mainMessage }];
  });

  return (
    <Container>
      <AlertAccordion
        warningType={order[highestWarningType]}
        alerts={alerts}
        title={translate(LanguageTexts.systemMessages())}
        width={"100%"}
      />
    </Container>
  );
}

function getMessageType(errorSeverity: ErrorSeverity): MessageType {
  switch (errorSeverity) {
    case ErrorSeverity.Exception:
    case ErrorSeverity.Error:
      return "error";
    case ErrorSeverity.Warning:
    case ErrorSeverity.CriticalWarning:
      return "warning";
    case ErrorSeverity.Info:
      return "info";
    default:
      return exhaustiveCheck(errorSeverity);
  }
}

function formattedMessage(
  parts: ReadonlyArray<MessagePart>,
  errorCode: number,
  getAmountFormat: ScreenAmounts.GetAmountFormat
) {
  const elements = parts.map(p => {
    switch (p.kind) {
      case "MessagePartText": {
        return p.text;
      }
      case "MessagePartAmount": {
        const fieldName = `${errorCode}_${p.propertyName}`;
        if (getAmountFormat) {
          const amountFormat = getAmountFormat(fieldGroup, fieldName, p.amount);
          const value = getValue(
            PropertyValue.fromAmount(p.amount),
            amountFormat
          );
          const unitFormat = Format.getUnitFormat(
            amountFormat.unit,
            UnitsFormat
          );
          return `${value} ${unitFormat?.label}`;
        } else {
          return "";
        }
      }
      default: {
        exhaustiveCheck(p);
      }
    }
  });

  return elements.join(" ");
}
