import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const updatePricingFileMutation = gql`
  mutation UpdatePricingFile($pricingFile: PricingFileInputType!) {
    updatePricingFile(pricingFile: $pricingFile) {
      __typename
      ... on PricingFileType {
        ...CompletePricingFile
      }
      ... on UpdateFailedType {
        errorMessage
      }
    }
  }
  ${Fragments.completePricingFile}
`;
