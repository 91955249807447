import { PropertyValue, PropertyValueSet } from "@genesys/property";
import * as GraphQLTypes from "../graphql-types";
import {
  LocationData,
  DataPoint,
  DataCenterCoolingClimateDataType,
  CaseType,
  Location
} from "./types";
import { defaultWmo } from "./state";
import * as Calculations from "@munters/calculations";
import { Quantity, Amount } from "@genesys/uom";
import { Physics } from "@munters/calculations/";
import * as SharedState from "../shared-state";
import * as KnownProperties from "./known-properties";
import { exhaustiveCheck } from "ts-exhaustive-check";

export const datacenterCoolingClimateDataTypes: DataCenterCoolingClimateDataType[] =
  [
    "DB/MCWB-MONTHLY-PEAK",
    "WB/MCDB-MONTHLY-PEAK",
    "N-YEAR-MAX-DB/WB",
    "N-YEAR-MAX-DB/EXTREME-MAX-WB"
  ];

export function getLocationData(
  locationId: string,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateSelectorCountriesProductQuery["product"]["countries"][0]
  >
): LocationData | undefined {
  for (const country of countries) {
    for (const region of country.regions) {
      const location = region.locations.find(
        currentLocation => currentLocation.id === locationId
      );
      if (location) {
        return {
          country: { id: country.id, name: country.name },
          region: {
            id: region.id,
            name: region.name,
            locations: region.locations.map(l => ({
              id: l.id,
              name: l.name,
              latitude: PropertyValue.fromString(l.latitude)!,
              longitude: PropertyValue.fromString(l.longitude)!,
              elevation: PropertyValue.fromString(l.elevation)!,
              extremeMaxWB: PropertyValue.fromString(l.extremeMaxWB)!,
              binLocationId: l.binLocationId
            }))
          },
          location: {
            id: location.id,
            name: location.name,
            latitude: PropertyValue.fromString(location.latitude)!,
            longitude: PropertyValue.fromString(location.longitude)!,
            elevation: PropertyValue.fromString(location.elevation)!,
            extremeMaxWB: PropertyValue.fromString(location.extremeMaxWB)!,
            binLocationId: location.binLocationId
          },
          allRegions: country.regions.map(r => ({
            id: r.id,
            name: r.name,
            locations: r.locations.map(l => ({
              id: l.id,
              name: l.name,
              latitude: PropertyValue.fromString(l.latitude)!,
              longitude: PropertyValue.fromString(l.longitude)!,
              elevation: PropertyValue.fromString(l.elevation)!,
              extremeMaxWB: PropertyValue.fromString(l.extremeMaxWB)!,
              binLocationId: l.binLocationId
            }))
          }))
        };
      }
    }
  }

  return undefined;
}

export function isValidLocation(
  countries: ReadonlyArray<
    GraphQLTypes.ClimateSelectorCountriesProductQuery["product"]["countries"][0]
  >,
  locationId: string | undefined
): boolean {
  if (!locationId) {
    return false;
  }

  for (const country of countries) {
    for (const region of country.regions) {
      const location = region.locations.find(
        currentLocation => currentLocation.id === locationId
      );

      if (location) {
        return true;
      }
    }
  }
  return false;
}

export function getDataPoints(
  datapoints: ReadonlyArray<DataPoint>,
  location: Location,
  climateDataType: string,
  isdataCenterCoolingType: boolean,
  annualOccurence: string,
  caseType: CaseType,
  pressure: Amount.Amount<"Pressure">,
  updateSelectedMonth?: (mon: string) => void,
  nYear?: number
) {
  if (!isdataCenterCoolingType) {
    return datapoints.find(
      dp =>
        dp.annualOccurence === annualOccurence &&
        dp.caseType === caseType &&
        dp.climateDataType === climateDataType
    )!;
  }
  let type = climateDataType as DataCenterCoolingClimateDataType;
  switch (type) {
    case "DB/MCWB-MONTHLY-PEAK":
      const points = datapoints.filter(dp =>
        dp.climateDataType.includes("DB/MCWB-")
      );
      const selectedPoint = points
        .sort(
          (a, b) =>
            (PropertyValue.getAmount<Quantity.Temperature>(b.temperature)!
              .value as number) -
            (PropertyValue.getAmount<Quantity.Temperature>(a.temperature)!
              .value as number)
        )
        .shift()!;

      if (updateSelectedMonth) {
        updateSelectedMonth(
          selectedPoint.climateDataType.substring(
            selectedPoint.climateDataType.indexOf("-") + 1
          )
        );
      }

      return selectedPoint;

    case "WB/MCDB-MONTHLY-PEAK": {
      const points = datapoints.filter(dp =>
        dp.climateDataType.startsWith("WB/MCDB-")
      );

      const selectedPoint = points
        .sort(
          (a, b) =>
            (Physics.RP1485.AmountConversions.humidityRatioToWetTemperature(
              pressure,
              PropertyValue.getAmount<Quantity.Temperature>(b.temperature)!,
              PropertyValue.getAmount<Quantity.HumidityRatio>(b.humidity)!
            ).value as number) -
            (Physics.RP1485.AmountConversions.humidityRatioToWetTemperature(
              pressure,
              PropertyValue.getAmount<Quantity.Temperature>(a.temperature)!,
              PropertyValue.getAmount<Quantity.HumidityRatio>(a.humidity)!
            ).value as number)
        )
        .shift()!;

      if (updateSelectedMonth) {
        updateSelectedMonth(
          selectedPoint.climateDataType.substring(
            selectedPoint.climateDataType.indexOf("-") + 1
          )
        );
      }

      return selectedPoint;
    }

    case "N-YEAR-MAX-DB/WB": {
      return datapoints.find(dp =>
        dp.climateDataType.startsWith(nYear!.toString())
      )!;
    }

    case "N-YEAR-MAX-DB/EXTREME-MAX-WB": {
      const dataPoint = datapoints.find(dp =>
        dp.climateDataType.startsWith(nYear!.toString())
      )!;

      const humdity =
        Physics.RP1485.AmountConversions.wetTemperatureToHumidityRatio(
          pressure,
          PropertyValue.getAmount<Quantity.Temperature>(dataPoint.temperature)!,
          PropertyValue.getAmount<Quantity.WetTemperature>(
            location.extremeMaxWB
          )!
        );

      return {
        ...dataPoint,
        humidity: PropertyValue.fromAmount(humdity)
      };
    }

    default:
      return exhaustiveCheck(type, true);
  }
}

export function getClimateSettingsForLocation(
  climateSettings: PropertyValueSet.PropertyValueSet,
  dataPoints: ReadonlyArray<DataPoint>,
  location: Location,
  updateSelectedMonth?: (mon: string) => void
) {
  const heatingDataPoint = dataPoints.find(
    dp =>
      dp.annualOccurence ===
        PropertyValueSet.getText(
          KnownProperties.manualData.heatingAnnualOccurence,
          climateSettings
        ) &&
      dp.caseType === "H" &&
      dp.climateDataType ===
        PropertyValueSet.getText(
          KnownProperties.manualData.heatingDataType,
          climateSettings
        )
  )!;

  const climateDataType = PropertyValueSet.getText(
    KnownProperties.manualData.coolingDataType,
    climateSettings
  )!;

  const isdataCenterCoolingType = datacenterCoolingClimateDataTypes.includes(
    climateDataType as any
  );

  PropertyValueSet.getText(
    KnownProperties.manualData.coolingAnnualOccurence,
    climateSettings
  );

  const coolingDataPoint = getDataPoints(
    dataPoints,
    location,
    climateDataType,
    isdataCenterCoolingType,
    PropertyValueSet.getText(
      KnownProperties.manualData.coolingAnnualOccurence,
      climateSettings
    )!,
    "C",
    PropertyValueSet.getAmount(
      KnownProperties.manualData.atmosphericPressure,
      climateSettings
    )!,
    updateSelectedMonth,
    PropertyValueSet.getInteger("climatenyearextreme", climateSettings) || 5
  );

  let newClimateSettings = PropertyValueSet.set(
    "summertemperature",
    coolingDataPoint.temperature,
    climateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "summerhumidity",
    coolingDataPoint.humidity,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "summerwindspeed",
    coolingDataPoint.windSpeed,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "wintertemperature",
    heatingDataPoint.temperature,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "winterhumidity",
    heatingDataPoint.humidity,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "winterwindspeed",
    heatingDataPoint.windSpeed,
    newClimateSettings
  );

  return newClimateSettings;
}

export function getDefaultClimateSettings(
  sharedState: SharedState.State,
  climateDataDefaults: PropertyValueSet.PropertyValueSet,
  datapoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateSelectorDataPointsProductQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateSelectorCountriesProductQuery["product"]["countries"][0]
  >,
  updateSelectedMonth?: (mon: string) => void
) {
  const isManualDataSource =
    sharedState.user.settings.climate.climateDataSource === "Manual";

  if (isManualDataSource && sharedState.user.settings.climate.manualData) {
    return PropertyValueSet.fromString(
      sharedState.user.settings.climate.manualData
    );
  }

  const locationId = isValidLocation(
    countries,
    sharedState.user.settings.climate.location
  )
    ? sharedState.user.settings.climate.location!
    : defaultWmo;

  const heatingDataType =
    PropertyValueSet.getText("climateheatingdatatype", climateDataDefaults) ||
    sharedState.user.settings.climate.heatingDataType ||
    "DB";
  const coolingDataType =
    PropertyValueSet.getText("climatecoolingdatatype", climateDataDefaults) ||
    sharedState.user.settings.climate.coolingDataType ||
    "DB/MCWB";
  const heatingOccurence = getAnnualOccurenceAsPvValue(
    PropertyValueSet.getText("heatingannualoccurence", climateDataDefaults) ||
      sharedState.user.settings.climate.heatingOccurence ||
      "996"
  );
  const coolingOccurence = getAnnualOccurenceAsPvValue(
    PropertyValueSet.getText("coolingannualoccurence", climateDataDefaults) ||
      sharedState.user.settings.climate.coolingOccurence ||
      "04"
  );

  const locationData = getLocationData(locationId, countries)!;

  let climateSettings: PropertyValueSet.PropertyValueSet =
    PropertyValueSet.Empty;
  climateSettings = PropertyValueSet.setText(
    "wmo",
    locationId,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "locationname",
    locationData.country.name +
      ", " +
      locationData.region.name +
      ", " +
      locationData.location.name,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "climatebinLocationid",
    locationData.location.binLocationId,
    climateSettings
  );
  climateSettings = PropertyValueSet.set(
    "climatelatituden",
    locationData.location.latitude,
    climateSettings
  );
  climateSettings = PropertyValueSet.set(
    "climatelongitudew",
    locationData.location.longitude,
    climateSettings
  );
  climateSettings = PropertyValueSet.set(
    "altitude",
    locationData.location.elevation,
    climateSettings
  );
  climateSettings = PropertyValueSet.set(
    "atmosphericpressure",
    PropertyValue.fromAmount(
      Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
        PropertyValue.getAmount<Quantity.Length>(
          locationData.location.elevation
        )!
      )
    ),
    climateSettings
  );
  climateSettings = PropertyValueSet.setInteger(
    "custompressure",
    0,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "coolingannualoccurence",
    coolingOccurence,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "heatingannualoccurence",
    heatingOccurence,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "climatecoolingdatatype",
    coolingDataType,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "climateheatingdatatype",
    heatingDataType,
    climateSettings
  );
  climateSettings = PropertyValueSet.setText(
    "occurrences",
    PropertyValueSet.getText("coolingannualoccurence", climateSettings) +
      " " +
      PropertyValueSet.getText("climatecoolingdatatype", climateSettings) +
      ", " +
      PropertyValueSet.getText("heatingannualoccurence", climateSettings) +
      " " +
      PropertyValueSet.getText("climateheatingdatatype", climateSettings),
    climateSettings
  );

  climateSettings = getClimateSettingsForLocation(
    climateSettings,
    MapDataPoints(datapoints),
    locationData.location,
    updateSelectedMonth
  );

  return climateSettings;
}

export function getClimateSettingsForLocationChange(
  climateSettings: PropertyValueSet.PropertyValueSet,
  countryData: ReadonlyArray<
    GraphQLTypes.ClimateSelectorCountriesProductQuery["product"]["countries"][0]
  >,
  locationId: string
): PropertyValueSet.PropertyValueSet {
  const fullLocation = getLocationData(locationId, countryData)!;
  const isCustomPressure =
    PropertyValueSet.getInteger("custompressure", climateSettings) === 1;

  let newClimateSettings = PropertyValueSet.setText(
    "wmo",
    locationId,
    climateSettings
  );

  newClimateSettings = PropertyValueSet.setText(
    "climateDataSource",
    "ASHRAE",
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.setText(
    "locationname",
    fullLocation.country.name +
      ", " +
      fullLocation.region.name +
      ", " +
      fullLocation.location.name,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.setText(
    "climatebinLocationid",
    fullLocation.location.binLocationId,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "climatelatituden",
    fullLocation.location.latitude,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "climatelongitudew",
    fullLocation.location.longitude,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "altitude",
    fullLocation.location.elevation,
    newClimateSettings
  );

  if (!isCustomPressure) {
    newClimateSettings = PropertyValueSet.set(
      "atmosphericpressure",
      PropertyValue.fromAmount(
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValue.getAmount<Quantity.Length>(
            fullLocation.location.elevation
          )!
        )
      ),
      newClimateSettings
    );
  }

  return newClimateSettings;
}

export function MapDataPoints(
  datapoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateSelectorDataPointsProductQuery["product"]["dataPointsForLocationId"]
    >[0]
  >
): ReadonlyArray<DataPoint> {
  return datapoints.map(d => ({
    caseType: d.caseType,
    climateDataType: d.climateDataType,
    annualOccurence: d.annualOccurence
      ? getAnnualOccurenceAsPvValue(d.annualOccurence)
      : undefined,
    temperature: PropertyValue.fromString(d.temperature)!,
    humidity: PropertyValue.fromString(d.humidity)!,
    windSpeed: PropertyValue.fromString(d.windSpeed)!
  }));
}

export function getAnnualOccurenceAsPvValue(dpOccurance: string) {
  switch (dpOccurance) {
    case "04": {
      return "0.4 %";
    }
    case "10": {
      return "1 %";
    }
    case "20": {
      return "2 %";
    }
    case "996": {
      return "99.6 %";
    }
    case "99": {
      return "99 %";
    }
    case "0.4 %":
    case "1 %":
    case "2 %":
    case "99.6 %":
    case "99 %": {
      return dpOccurance;
    }
    default: {
      {
        throw new Error("dpOccurance string not supported: " + dpOccurance);
      }
    }
  }
}
//tslint:disable-next-line
