import * as React from "react";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { ResultSummary } from "./result-summary";
import { OpCaseResults } from "./op-case-results";
import { Spinner } from "@genesys/ui-elements";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { ProductImageView } from "./visualizers";
import {
  getDefaultSelectedOpCase,
  getResultSummaryTabCaseResults,
  getOperatingCaseTabCaseResults,
  createConversionParametersMapByCaseResultId
} from "./shared-tools";
import * as System from "../../../system";
import * as SharedState from "../../../../shared-state";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Notes from "../../../../notes";
import * as ComponentSpecs from "./component-spec";
import { ActiveTab } from "../state";
import { CaseFilter } from "../../../types";
import { ResultSummmaryQuery } from "../../../../graphql-types";
import { CaseType, ComponentCalculationType } from "@genesys/graphql-types";
import * as Authorization from "@genesys/shared/lib/authorization";

const Error = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 620px;
`;

interface ReportTechnicalDataOpCaseRow {
  readonly id: string;
  readonly min_Max?: string | undefined;
  readonly result?: string | undefined;
  readonly propertyFilter?: string | undefined | null;
}

interface ScreenRow {
  readonly id?: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}

const Container = styled.div``;

export function ComponentEditorDetailsContent({
  activeTab,
  state,
  system,
  component,
  sharedState,
  componentSpecRows,
  dispatch
}: {
  readonly activeTab: ActiveTab;
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly component: System.Component;
  readonly componentSpecRows: ScreenRow[];
  readonly sharedState: SharedState.State;
}): JSX.Element {
  if (activeTab === "notes") {
    return (
      <Notes.NotesView
        state={state.notesState}
        sharedState={sharedState}
        dispatch={Dispatch.map(Action.dispatchNotes, dispatch)}
      />
    );
  }

  if (state.summaryData === undefined) {
    return <Spinner />;
  }
  if (system.status < SystemStatusEnum.SystemStatus.RatingCalculationSuccess) {
    return (
      <ErrorContainer>
        <Error>
          {sharedState.translate(LanguageTexts.noPerformanceAvailable())}
        </Error>
      </ErrorContainer>
    );
  }

  const translate = sharedState.translate;
  const selectedCaseResultId = state.selectedCasedResultId;
  const resultSummaryTabResults = getResultSummaryTabCaseResults(
    translate,
    system.operatingCases,
    (
      state.summaryData.product.product.screen_ResultSummary_CaseOrder?.rows ||
      []
    ).map(r => {
      const values = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        r.values
      );
      return {
        calculationType:
          PropertyValueSet.getText("CalculationType", values) || "",
        filter: PropertyValueSet.getText("Filter", values) || ""
      };
    }),
    component!.id,
    sharedState.user.applicationClaims
  );
  if (resultSummaryTabResults.length === 0 && activeTab !== "component-spec") {
    return (
      <Container>
        <ProductImageView
          sharedState={sharedState}
          product={state.summaryData.product.product}
          selectedProperties={component.properties}
        />
      </Container>
    );
  }

  const resultConversionParametersMapByCaseResultId =
    createConversionParametersMapByCaseResultId(
      state.summaryData.product.systemType.systemTypeResults,
      resultSummaryTabResults
    );

  const productCaseFilter = parseProductCaseFilter(
    state.summaryData.product.product.caseFilter
  );

  const effectiveCaseFilter = getEffectiveCaseFilter(
    state.systemTypeCaseFilter,
    productCaseFilter
  );

  const filteredCaseResults = resultSummaryTabResults.filter(r => {
    const key =
      CaseTypeEnum.CaseType[r.caseType].toUpperCase() +
      "." +
      r.calculationType.toUpperCase();
    if (!effectiveCaseFilter.has(key)) {
      return false;
    }
    const claimFilterText = effectiveCaseFilter.get(key)!.claimFilter;
    const claimFilter = PropertyFilter.fromStringOrEmpty(claimFilterText);
    return Authorization.checkClaimFilter(
      sharedState.user.applicationClaims,
      claimFilter
    );
  });

  const effectiveSelectedCaseResultId: string | undefined =
    filteredCaseResults.find(ocr => ocr.id === selectedCaseResultId)
      ? selectedCaseResultId
      : getDefaultSelectedOpCase(
          (
            state.summaryData.product.product.report_TechnicalData_OpCase
              ?.rows || []
          ).map(r => {
            const values = PropertyValueSet.fromStringOrError(
              () => PropertyValueSet.Empty,
              r.values
            );
            const row: ReportTechnicalDataOpCaseRow = {
              id: r.id,
              propertyFilter: r.propertyFilter,
              min_Max: PropertyValueSet.getText("Min/Max", values),
              result: PropertyValueSet.getText("Result", values)
            };
            return row;
          }),
          component.properties,
          filteredCaseResults
        );

  const operatingCaseTabResults = getOperatingCaseTabCaseResults(
    translate,
    system.operatingCases,
    component.id
  );

  const caseResult = filteredCaseResults.find(
    ocr => ocr.id === effectiveSelectedCaseResultId
  );

  if (!caseResult && activeTab !== "component-spec") {
    return <div>Couldn't find selected result</div>;
  }

  switch (activeTab) {
    case "results-summary": {
      return (
        <ResultSummary
          resultConversionParametersMapByCaseResultId={
            resultConversionParametersMapByCaseResultId
          }
          results={filteredCaseResults}
          component={component}
          effectiveSelectedCaseResultId={effectiveSelectedCaseResultId}
          dispatch={dispatch}
          productData={state.summaryData.product.product}
          screenResultSummary={
            state.summaryData.product.product.screen_ResultSummary!
          }
          caseResult={caseResult!}
          sharedState={sharedState}
        />
      );
    }
    case "op-case-results": {
      return (
        <OpCaseResults
          productId={state.summaryData.product.product.id}
          caseResult={operatingCaseTabResults}
          productData={state.summaryData.product.product}
          resultConversionParametersMapByCaseResultId={
            resultConversionParametersMapByCaseResultId
          }
          dispatch={dispatch}
          selectedProperties={component.properties}
          sharedState={sharedState}
        ></OpCaseResults>
      );
    }
    case "component-spec": {
      return (
        <ComponentSpecs.View
          systemType={system.file.systemTypeId}
          dispatch={dispatch}
          validRows={componentSpecRows}
          componentSpec={component.componentSpec}
          sharedState={sharedState}
          images={state.summaryData.product.product.images}
        />
      );
    }
    default: {
      return exhaustiveCheck(activeTab);
    }
  }
}

function getEffectiveCaseFilter(
  systemType: ReadonlyArray<CaseFilter>,
  product: ReadonlyArray<CaseFilter>
): ReadonlyMap<string, CaseFilter> {
  const map = new Map<string, CaseFilter>();

  for (const cf of systemType) {
    const key =
      cf.caseType.toUpperCase() + "." + cf.calculationType.toUpperCase();
    map.set(key, cf);
  }

  for (const cf of product) {
    const key =
      cf.caseType.toUpperCase() + "." + cf.calculationType.toUpperCase();
    map.set(key, cf);
  }

  return map;
}

function parseProductCaseFilter(
  caseFilter?:
    | ResultSummmaryQuery["product"]["product"]["caseFilter"]
    | null
    | undefined
): ReadonlyArray<CaseFilter> {
  if (!caseFilter) {
    return [];
  }
  return caseFilter.rows.map(r => {
    const pvs = PropertyValueSet.fromString(r.values);
    const caseType = PropertyValueSet.getText("CaseType", pvs)?.toUpperCase();
    const calculationType = PropertyValueSet.getText(
      "CalculationType",
      pvs
    )?.toUpperCase();
    const claimFilter = PropertyValueSet.getText("ClaimFilter", pvs) ?? "";
    return {
      caseType: CaseType[caseType as keyof typeof CaseType],
      calculationType:
        ComponentCalculationType[
          calculationType as keyof typeof calculationType
        ],
      claimFilter: claimFilter
    };
  });
}
