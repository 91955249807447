import { PropertyValueSet } from "@genesys/property";
import { LoadType, LoadDef } from "../types";

export function updateSettingsToRemoveTypesFromLoad(
  currentSettings: PropertyValueSet.PropertyValueSet,
  typeOfLoad: LoadType,
  loadDefinition: LoadDef
): PropertyValueSet.PropertyValueSet {
  let updatedSettings = { ...currentSettings };

  if (typeOfLoad === "heat") {
    updatedSettings = disableHeatForLoad(loadDefinition, updatedSettings);
  } else if (typeOfLoad === "moisture") {
    updatedSettings = disableMoistureForLoad(loadDefinition, updatedSettings);
  } else {
    updatedSettings = disableHeatForLoad(loadDefinition, updatedSettings);
    updatedSettings = disableMoistureForLoad(loadDefinition, updatedSettings);
  }

  if (loadDefinition.additionalInfo.updateFunction) {
    updatedSettings = PropertyValueSet.merge(
      loadDefinition.additionalInfo.updateFunction(
        currentSettings,
        updatedSettings
      ),
      updatedSettings
    );
  }

  return updatedSettings;
}

export function updateSettingsToAddTypesToLoad(
  currentSettings: PropertyValueSet.PropertyValueSet,
  typeOfLoad: LoadType,
  loadDefinition: LoadDef
) {
  let updatedSettings = { ...currentSettings };

  if (typeOfLoad === "heat") {
    updatedSettings = enableHeatForLoad(loadDefinition, updatedSettings);
  } else if (typeOfLoad === "moisture") {
    updatedSettings = enableMoistureForLoad(loadDefinition, updatedSettings);
  } else {
    updatedSettings = enableHeatForLoad(loadDefinition, updatedSettings);
    updatedSettings = enableMoistureForLoad(loadDefinition, updatedSettings);
  }
  if (loadDefinition.additionalInfo.updateFunction) {
    updatedSettings = PropertyValueSet.merge(
      loadDefinition.additionalInfo.updateFunction(
        currentSettings,
        updatedSettings
      ),
      updatedSettings
    );
  }

  return updatedSettings;
}

function enableHeatForLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet
) {
  return updateHeatLoad(newLoad, settings, 1);
}

function enableMoistureForLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet
) {
  return updateMoistureLoad(newLoad, settings, 1);
}

function disableHeatForLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet
) {
  return updateHeatLoad(newLoad, settings, 0);
}

function disableMoistureForLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet
) {
  return updateMoistureLoad(newLoad, settings, 0);
}

function updateHeatLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet,
  value: number
): PropertyValueSet.PropertyValueSet {
  if (newLoad.properties.heatProperty !== undefined) {
    return PropertyValueSet.setInteger(
      newLoad.properties.heatProperty,
      value,
      settings
    );
  }
  return settings;
}

function updateMoistureLoad(
  newLoad: LoadDef,
  settings: PropertyValueSet.PropertyValueSet,
  value: number
): PropertyValueSet.PropertyValueSet {
  if (newLoad.properties.moistureProperty !== undefined) {
    return PropertyValueSet.setInteger(
      newLoad.properties.moistureProperty,
      value,
      settings
    );
  }
  return settings;
}
