import { encodeSvgStringToImgSrc } from "../../svg-string-img-encoder";

export function createGridIcon(color: string): string {
  const svgString = `
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
 <g id="Layer_1">
  <title>Layer 1</title>
  <rect stroke="${color}" id="svg_4" height="4.54545" width="4.54545" y="1.31861" x="1.84461" fill="#C5D0E5"/>
  <rect stroke="${color}" id="svg_8" height="4.54545" width="4.54545" y="1.31861" x="8.60994" fill="#C5D0E5"/>
  <rect stroke="${color}" id="svg_9" height="4.54545" width="4.54545" y="8.18964" x="1.84461" fill="#C5D0E5"/>
  <rect stroke="${color}" id="svg_10" height="4.54545" width="4.54545" y="8.18964" x="8.60994" fill="#C5D0E5"/>
 </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
