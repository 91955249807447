import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import {
  CheckBox,
  EditBlue,
  Trash,
  Input,
  StandardButton,
  OpenGreen
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  FilterInput,
  InputContainer,
  RowSection,
  StyledLabel,
  StyledLi,
  TrashContainer,
  Container,
  UpperSubContainer,
  StyledButton,
  LowerSubContainer,
  ButtonsContainer,
  LabelGroupContainer
} from "./elements";
import { Label, View } from "./types";

export function LabelManagerView({
  state,
  labels,
  sharedState,
  dispatch,
  onCancel,
  onAssign
}: {
  readonly state: State;
  readonly labels: ReadonlyArray<Label>;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onCancel: () => void;
  readonly onAssign: (selectedLabels: ReadonlyArray<Label>) => void;
}) {
  // Derived data
  const myLabels = labels;
  const otherLabels = React.useMemo(
    () =>
      state.labelEditorChosenValues.filter(
        chosenLabel => !myLabels.some(myLabel => myLabel.id === chosenLabel.id)
      ),
    [state.labelEditorChosenValues, myLabels]
  );

  const filteredLabels = React.useMemo(
    () =>
      myLabels.filter(
        label =>
          !state.labelFilter.length ||
          label.name.toUpperCase().includes(state.labelFilter.toUpperCase())
      ),
    [myLabels, state.labelFilter]
  );

  const isDisabled = React.useMemo(
    () =>
      !state.newLabelValue.trim().length ||
      labels.some(
        label => label.name.toLowerCase() === state.newLabelValue.toLowerCase()
      ),
    [state.newLabelValue, labels]
  );

  const [hoveredLabelId, setHoveredLabelId] = React.useState<string | null>(
    null
  );

  const labelGroups = React.useMemo(
    () => [
      {
        id: "other-labels",
        name: sharedState.translate(
          LanguageTexts.globalPropertyName("otherlabels")
        ),
        labels: otherLabels,
        editable: false
      },
      {
        id: "my-labels",
        name: sharedState.translate(
          LanguageTexts.globalPropertyName("mylabels")
        ),
        labels: filteredLabels,
        editable: true
      }
    ],
    [otherLabels, filteredLabels, sharedState]
  );

  // Sub-components
  const LabelGroup = ({
    group
  }: {
    readonly group: (typeof labelGroups)[0];
  }) => (
    <div key={group.id}>
      <h4>{group.name}</h4>
      {group.labels.map(label => {
        const isChecked = state.labelEditorChosenValues.some(
          selectedLabel => selectedLabel.id === label.id
        );
        const handleLabelClick = () =>
          dispatch(Action.toggleLabel(label.id, label.name));

        return (
          <StyledLi
            key={label.id}
            isChecked={isChecked}
            onClick={handleLabelClick}
            onMouseEnter={() => setHoveredLabelId(label.id)}
            onMouseLeave={() => setHoveredLabelId(null)}
          >
            <CheckBox
              isChecked={isChecked}
              onClick={handleLabelClick}
              children={
                <StyledLabel>
                  {label.name}
                  {hoveredLabelId === label.id && group.editable && (
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          Action.changeView(
                            "editOrDelete",
                            label.name,
                            label.id
                          )
                        );
                      }}
                    >
                      <EditBlue />
                    </span>
                  )}
                </StyledLabel>
              }
            />
          </StyledLi>
        );
      })}
    </div>
  );

  const RenderView = () => {
    if (state.view === "assign") {
      return (
        <>
          <RowSection>
            <FilterInput
              placeholder={sharedState.translate(LanguageTexts.search())}
              value={state.labelFilter}
              onChange={e =>
                dispatch(Action.setLabelFilter(e.currentTarget.value))
              }
            />
            {labelGroups.map(group => (
              <LabelGroupContainer key={group.id}>
                <LabelGroup group={group} />
              </LabelGroupContainer>
            ))}
          </RowSection>
          <StyledButton
            icon={OpenGreen}
            size="Small"
            buttonType="Secondary-2"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(Action.changeView("create"));
            }}
          >
            {sharedState.translate(LanguageTexts.createNewLabel())}
          </StyledButton>
        </>
      );
    }

    if (["create", "editOrDelete"].includes(state.view)) {
      return (
        <InputContainer>
          <InputBox
            translate={sharedState.translate}
            view={state.view}
            inputBoxValue={state.newLabelValue}
            onInputBoxChange={value => dispatch(Action.setLabelValue(value))}
            onDelete={() => dispatch(Action.deleteLabel())}
          />
        </InputContainer>
      );
    }

    return null;
  };

  return (
    <Container>
      <UpperSubContainer>
        <RenderView />
      </UpperSubContainer>
      <LowerSubContainer>
        <ButtonsContainer>
          {ctoButtons(
            isDisabled,
            state.view,
            state,
            onCancel,
            onAssign,
            dispatch,
            sharedState.translate
          )}
        </ButtonsContainer>
      </LowerSubContainer>
    </Container>
  );
}
function InputBox({
  inputBoxValue,
  view,
  onInputBoxChange,
  onDelete,
  translate
}: {
  readonly inputBoxValue: string;
  readonly onInputBoxChange: (value: string) => void;
  readonly onDelete: () => void;
  readonly view: View;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  return (
    <>
      <label>{translate(LanguageTexts.labelName())}</label>

      <Input
        placeholder={translate(LanguageTexts.newLabel())}
        onDebounceValueChange={[
          value => {
            onInputBoxChange(value);
          },
          []
        ]}
        value={inputBoxValue}
      />
      {view === "editOrDelete" && (
        <TrashContainer>
          <label onClick={onDelete}>
            <Trash />
          </label>
        </TrashContainer>
      )}
    </>
  );
}

function ctoButtons(
  isDisabled: boolean,
  view: View,
  state: State,
  onCancel: () => void,
  onAssgin: (
    selectedlabels: ReadonlyArray<{
      readonly name: string;
      readonly id: string;
    }>
  ) => void,
  dispatch: Dispatch<Action>,
  translate: (arg: LanguageTexts.StaticText) => string
) {
  const backButton = (
    <StandardButton
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(Action.changeView("assign"));
      }}
      size="Large"
      buttonType="Secondary-2"
    >
      {translate(LanguageTexts.back()).toUpperCase()}
    </StandardButton>
  );

  switch (view) {
    case "create": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.createNewLabel());
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.create()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    case "editOrDelete": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.editLabelValue());
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.save()).toUpperCase()}
          </StandardButton>
        </>
      );
    }

    case "assign": {
      return (
        <>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onCancel();
            }}
            size="Large"
            buttonType="Secondary-2"
          >
            {translate(LanguageTexts.cancel()).toUpperCase()}
          </StandardButton>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onAssgin(state.labelEditorChosenValues);
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.assign()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    default:
      return exhaustiveCheck(view, true);
  }
}
