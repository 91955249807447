import * as React from "react";
import * as Redux from "redux";
import fastDeepEqual from "fast-deep-equal";
import * as GraphQL from "graphql";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLAddRemoveFields from "graphql-add-remove-fields";
import * as Elements from "../elements";
import * as Contexts from "../contexts";
import * as DebugSettings from "../debug-settings";
import * as ClientConfig from "../client-config";
import * as GlobalActions from "../global-actions";
import { updateFromServer } from "./update-from-server";
import { readFromCache } from "./read-from-cache";
import { createResultProps, ResultProps, isDirty } from "./result-props";

interface State<TResult extends GraphQLEntityCache.RootFields, TVariables> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly result: ResultProps<TResult>;
}

interface Props<TResult extends {}, TVariables> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly children: (props: ResultProps<TResult>) => JSX.Element | null;
  readonly modalOnError?: boolean;
}

// tslint:disable-next-line:function-name
export function QueryProduct<
  TResult extends GraphQLEntityCache.RootFields = {},
  TVariables extends {} = {}
>(props: Props<TResult, TVariables>) {
  const { modalOnError = false } = props;
  return (
    <ClientConfig.ClientConfigConsumer>
      {config => (
        <DebugSettings.DebugSettingsConsumer>
          {({ includeServerLog }) => (
            <Contexts.dispatchContext.Consumer>
              {dispatch => (
                <Contexts.ProductEntitiesContext.Consumer>
                  {productEntitiesContext => (
                    <QueryProductInternal<TResult, TVariables>
                      {...props}
                      modalOnError={modalOnError}
                      productEntitiesContext={productEntitiesContext}
                      dispatch={dispatch}
                      includeServerLog={includeServerLog}
                      clientConfigContextValue={config}
                    />
                  )}
                </Contexts.ProductEntitiesContext.Consumer>
              )}
            </Contexts.dispatchContext.Consumer>
          )}
        </DebugSettings.DebugSettingsConsumer>
      )}
    </ClientConfig.ClientConfigConsumer>
  );
}

type InternalProps<TResult extends {}, TVariables> = Props<
  TResult,
  TVariables
> & {
  readonly modalOnError: boolean;
  readonly productEntitiesContext: Contexts.ProductEntitiesContextValue;
  readonly dispatch: Redux.Dispatch<GlobalActions.Error>;
  readonly includeServerLog: boolean;
  readonly clientConfigContextValue: Contexts.ClientConfigContextValue;
};
class QueryProductInternal<
  TResult extends GraphQLEntityCache.RootFields = {},
  TVariables extends {} = {}
> extends React.Component<
  InternalProps<TResult, TVariables>,
  State<TResult, TVariables>
> {
  constructor(props: InternalProps<TResult, TVariables>) {
    super(props);
    this.state = {
      query: undefined as any,
      variables: props.variables,
      result: {
        type: "PartialResultProps"
      }
    };
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    props: Readonly<InternalProps<any, any>>,
    state: State<any, any>
  ): Partial<State<any, any>> | null {
    const variablesChanged = !fastDeepEqual(props.variables, state.variables);

    if (
      state.result.type !== "ErrorResultProps" &&
      (variablesChanged || props.query !== state.query || isDirty(state.result))
    ) {
      const query = props.query;
      const variables = props.variables;
      const queryWithRequiredFields = GraphQLAddRemoveFields.addFields(query, [
        "__typename"
      ]);

      const { partial, data, stale } = readFromCache(
        props.productEntitiesContext.entities,
        queryWithRequiredFields,
        variables,
        props.clientConfigContextValue.environment
      );

      const resultProps = createResultProps(partial, stale, data, undefined);

      const newState = {
        query,
        variables,
        result: resultProps
      };

      return newState;
    }

    return null;
  }

  private fetching: boolean;

  componentDidMount() {
    // Didmount cannot be queried more than once per instance
    if (isDirty(this.state.result)) {
      this.fetchFromServer();
    }
  }

  componentDidUpdate() {
    if (isDirty(this.state.result)) {
      this.fetchFromServer();
    }
  }

  private async fetchFromServer(): Promise<void> {
    if (this.fetching) {
      return;
    }
    this.fetching = true;
    const { query, variables } = this.state;
    const queryWithRequiredFields = GraphQLAddRemoveFields.addFields(query!, [
      "__typename"
    ]);

    const result = await updateFromServer(
      queryWithRequiredFields!,
      variables,
      this.props.includeServerLog,
      this.props.clientConfigContextValue.graphqlEndpoint,
      this.props.clientConfigContextValue.authorization
    );
    switch (result.type) {
      case "UpdateFromServerResultSucess": {
        this.props.productEntitiesContext.mergeEntities(result.entities);
        break;
      }
      case "UpdateFromServerResultFailure": {
        this.setState({
          result: {
            type: "ErrorResultProps",
            error: result.error
          }
        });

        if (this.props.modalOnError) {
          this.props.dispatch(
            GlobalActions.error({
              referenceNo: "",
              invalidState: true,
              message: "Data fetching error",
              exception: result.error
            })
          );
        }
        break;
      }
      default: {
        exhaustiveCheck(result);
      }
    }

    this.fetching = false;
  }

  render() {
    return this.props.children(this.state.result);
  }
}

type SimpleChildrenFunc<TResult extends GraphQLEntityCache.RootFields> = (
  result: TResult
) => JSX.Element;

interface PropsSimple<TResult2 extends {}, TVariables extends {}> {
  readonly query: GraphQL.DocumentNode;
  readonly variables: TVariables;
  readonly children: SimpleChildrenFunc<TResult2>;
}

export class QueryProductSimple<
  TResult extends GraphQLEntityCache.RootFields,
  TVariables extends {} = {}
> extends React.Component<PropsSimple<TResult, TVariables>, {}> {
  render() {
    const { children: childFunc, ...props } = this.props;
    return (
      <QueryProduct {...props} modalOnError={true}>
        {resultProps => {
          if (resultProps.type === "ErrorResultProps") {
            console.error(resultProps.error);
            return <div>Data fetching Error. See console</div>;
          }

          if (resultProps.type === "PartialResultProps") {
            return <Elements.Loader />;
          }
          return childFunc(resultProps.data as any);
        }}
      </QueryProduct>
    );
  }
}
