import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createArrowRightSvg(color: string): string {
  const svgString = `
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 <title>93793EB6-167F-46D5-8D71-7071577D2F2E</title>
 <defs>
  <polygon points="0 0.125 5 5.125 10 0.125" id="path-1"/>
 </defs>
 <g>
  <title>Layer 1</title>
  <g transform="rotate(-90 4.9999990463256845,5.04878044128418) " fill-rule="evenodd" fill="none" id="UI-Kit">
   <g id="UI-Kit---5.Icons-&amp;-Illustrations">
    <g id="Icons/Actions/Dropdown">
     <mask transform="translate(0.09756000339984894,0) " id="mask-2">
      <use id="svg_1" xlink:href="#path-1"/>
     </mask>
     <use x="0" y="2.375" xlink:href="#path-1" fill="${color}" id="Shape"/>
    </g>
   </g>
  </g>
  <path id="svg_3" d="m13.19317,2.26088l0.56913,0l0.17587,-0.54067l0.17587,0.54067l0.56913,0l-0.46044,0.33415l0.17588,0.54067l-0.46044,-0.33416l-0.46044,0.33416l0.17588,-0.54067l-0.46044,-0.33415z" opacity="undefined" stroke="#000" fill="#fff"/>
  <path id="svg_4" d="m11.72127,0.90674l0.56913,0l0.17587,-0.54067l0.17587,0.54067l0.56913,0l-0.46044,0.33415l0.17588,0.54067l-0.46044,-0.33416l-0.46044,0.33416l0.17588,-0.54067l-0.46044,-0.33415z" opacity="undefined" stroke="#000" fill="#fff"/>
 </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
