import gql from "graphql-tag";

export const systemConfiguratorValidationQuery = gql`
  query SystemConfiguratorValidationUser($genesysNo: Int!, $revisionNo: Int!) {
    user {
      validateSystemGenesysNo(genesysNo: $genesysNo, revisionNo: $revisionNo) {
        hasPermission
        isValid
        systemTypeId
        systemId
      }
    }
  }
`;

export const systemConfiguratorQuery = gql`
  query SystemConfiguratorUser($genesysNo: Int!, $revisionNo: Int!) {
    user {
      systemByGenesysNo(genesysNo: $genesysNo, revisionNo: $revisionNo) {
        id
        revisionNo
        status
        binSelections
        climateSettings
        currencyCode
        masterSalesOrganisationId
        masterCurrencyCode
        statusDate
        salesOrganisationId
        moistureLoadInfo {
          id
          moistureLoadNo
          revisionNo
        }

        lastModifiedBy {
          id
          userName
        }

        pricingInformation {
          id
          pricingNo
          revisionNo
        }
        systemFile {
          id
          systemTypeId
          name
          genesysNo
          owner {
            id
            userName
          }
          labels {
            id
            name
          }
          systems {
            id
            revisionNo
            status
            statusDate
            comment
          }
        }
        airstreams {
          id
          componentSections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
            systemAirStreamId
          }
        }

        operatingCases {
          id
          caseName
          customCaseName
          caseType
          settings
          binData
          sortNo
          results {
            id
            componentId
            calculationType
            settings
            isDesignCase
            sortNo
          }
        }

        components {
          id
          productId
          accessoryToId
          properties
          componentSpec
          sortNo
          externalNotes
          internalNotes
          sections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
            systemAirStreamId
          }
          messages {
            id
            messageCode
            messageSeverity
            messageParameters
            operatingCaseResultId
          }
        }
      }
    }
  }
`;

export const systemConfiguratorProductQuery = gql`
  query SystemConfiguratorProduct(
    $systemType: GetSystemTypeInputType!
    $systemProduct: GetProductInputType!
  ) {
    product {
      languages {
        id
      }
      systemType(input: $systemType) {
        id
        plenumSizeProperty
        positionValidation
        productSectionPlacementRules {
          id
          productSectionId1
          productSectionId2
          rangePropertyFilter
        }

        systemTypeResults {
          id
          resultName
          quantityConversionParams
        }

        productSectionSequenceRules {
          id
          name
          sequence
          rangePropertyFilter
        }
        symbols {
          id
          symbolId
          svg
          width
          height
        }
        screens {
          claimFilter
          propertyFilter
          screenParameters
          screenType
          sortNo
        }
        airPointProperties {
          id
          name
          airProperty
          propertyFilter
        }
        systemTypeCaseFilter {
          caseType
          calculationType
          claimFilter
        }

        allProducts {
          id
          menuGroup
          optional
          wikiJsPath

          images {
            id
            imageUsage
            propertyFilter
            url
            imageFormat
          }

          productType {
            type
          }
          rangeFilter
          productSections {
            id
            movable
            sortNo
          }
          properties {
            id
            quantityConversionParams
            name
            defaultValues {
              value
              propertyFilter
            }
            quantity
            sortNo
            groupName
            validationFilter
            visibilityFilter
            descriptionTexts {
              id
              propertyFilter
              language
              text
            }
            values {
              id
              value
              validationFilter
              rangeFilter
              sortNo
              image
              descriptionTexts {
                id
                propertyFilter
                language
                text
              }
            }
            valueSources {
              id
              value
              propertyValueSourceId
              parameters
              propertyFilter
              claimFilter
            }
          }
          boxConnectionPoints {
            id
            productSectionId
            box
            connectionType
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            hideStatePoint
            diagramType
            propertyFilter
          }

          boxSymbols {
            id
            box
            symbolId
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            sizeX
            sizeY
            propertyFilter
            absoluteRotationAndScale
            useForSelectionBounds
            diagramType
          }
          boxTexts {
            id
            box
            source
            text
            fontSize
            positionX
            positionY
            positionZ
            rotation
            absolutePosition
            useAsLabel
            propertyFilter
            diagramType
          }
          boxLinePoints {
            id
            box
            connectionId
            lineType
            positionX
            positionY
            direction
            propertyFilter
            diagramType
          }
          helpText: productDataTable(tableName: "helpText") {
            rows {
              id
              propertyFilter
              values
            }
          }

          caseFilter: productDataTable(tableName: "caseFilter") {
            id
            tableName
            rows {
              id
              propertyFilter
              values
            }
          }

          genericPerfOverViewResults: productDataTable(
            tableName: "Product_GenericPerfOverViewResults"
          ) {
            id
            tableName
            rows {
              id
              propertyFilter
              sortNo
              values
            }
          }
        }
        product(input: $systemProduct) {
          images {
            id
            imageUsage
            imageFormat
            name
            url
            propertyFilter
          }
        }
      }
    }
  }
`;
