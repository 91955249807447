import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import {
  userQuery,
  productQuery,
  searchQuery,
  validationQuery
} from "./queries";
import {
  Pricing,
  EditCell,
  NewRowState,
  NewRowType,
  NewPricingRow,
  PricingArticle,
  ReportSettings,
  Modal
} from "./types";
import { mapGraphQlPricing } from "./mappings";
import {
  mapCellChangeToRow,
  MapGraphQlPricingArticles,
  mapToSaveParams
} from "./mappings";
import * as Guid from "@genesys/shared/lib/guid";
import * as Finance from "@genesys/shared/lib/finance";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as PricingActions from "../pricing-actions";
import { updatePricingMutation } from "@genesys/client-core/lib/graphql-mutations";
import { getError, getSystemRow } from "./functions";
import { clientConfig } from "../config";

export type State = ErrorState | OkState;

export type ErrorState = {
  readonly type: "error";
  readonly errorMessage: string;
};

export type OkState = {
  readonly type: "ok";
  readonly productQueryResult:
    | GraphQLTypes.PricingEditorProductQuery
    | undefined;
  readonly pricing: Pricing | undefined;
  readonly editCell: EditCell | undefined;
  readonly unsavedContent: boolean;
  readonly newRowState: NewRowState | undefined;
  readonly pricingArticles: ReadonlyArray<PricingArticle>;
  readonly pricingGroups: ReadonlyArray<string>;
  readonly pricingActionsState: PricingActions.State | undefined;
  readonly inputRowNo: number;
  readonly SystemPricingSearch: ReadonlyArray<
    GraphQLTypes.PricingSystemSearchQuery["user"]["systemPricingSearch"][0]
  >;
  readonly exchangeRateTable: Finance.ExchangeRateTable | undefined;
  readonly reportSettings: ReportSettings;
  readonly showLoader: boolean;
  readonly modal: Modal | undefined;
  readonly pricingActionIsCompleteUrl:
    | PricingActions.ActionCompleteUrl
    | undefined;
};

export type PricingPrefix = "d" | "g" | "s";

export const init = (
  sharedState: SharedState.State,
  pricingNoParam: string
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const enviroment = clientConfig.environment;

  // Fix for now since pricing on Production does not use "G"
  const isProduction = enviroment === "Production";

  const pricingNoFormat = isProduction
    ? /p[0-9]+?-[0-9]+?$/i
    : /p[ds]{1}[0-9]+?-[0-9]+?$/i;

  if (!pricingNoFormat.test(pricingNoParam)) {
    return [
      {
        type: "error",
        errorMessage: "Invalid PricingFormat"
      }
    ];
  }
  const split = pricingNoParam.split("-");
  const pricingNo = parseInt(split[0].replace(/\D/g, ""), 10);
  const pricingPrefix = !isProduction
    ? split[0].replace(/[0-9]/g, "")
    : undefined;

  if (
    pricingPrefix &&
    pricingPrefix.toLowerCase() !==
      sharedState.genesysPrefixNotation.pricingNo.toLowerCase()
  ) {
    return [
      {
        type: "error",
        errorMessage: `Invalid Pricing prefix "${pricingPrefix}" for enviroment ${enviroment}`
      }
    ];
  }

  const revisionNo = parseInt(split[1], 10);

  return [
    {
      type: "ok",
      productQueryResult: undefined,
      pricing: undefined,
      editCell: undefined,
      unsavedContent: false,
      newRowState: undefined,
      pricingActionsState: undefined,
      pricingArticles: [],
      pricingGroups: [],
      inputRowNo: 10,
      SystemPricingSearch: [],
      exchangeRateTable: undefined,
      reportSettings: {
        includeCost: false,
        includeTransfer: false,
        includeList: false,
        includePerUnit: false,
        includeSubtotal: false,
        includeTotal: false,
        culture: sharedState.user.settings.language
      },
      showLoader: false,
      modal: undefined,
      pricingActionIsCompleteUrl: undefined
    },
    Cmd.batch<Action>([
      sharedState.graphQL.queryUserCmd<
        GraphQLTypes.PricingEditorUserQuery,
        GraphQLTypes.PricingEditorUserQueryVariables,
        Action
      >(
        userQuery,
        {
          pricingNo: pricingNo,
          revisionNo: revisionNo
        },
        Action.userQueryDataReceived
      ),
      sharedState.graphQL.queryProductCmd<
        GraphQLTypes.PricingEditorProductQuery,
        GraphQLTypes.PricingEditorProductQueryVariables,
        Action
      >(productQuery, {}, Action.productQueryDataReceived)
    ]),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  userQueryDataReceived: (
    data: GraphQLTypes.PricingEditorUserQuery,
    actionUrl?: PricingActions.ActionCompleteUrl
  ) => ({
    data,
    actionUrl
  }),
  productQueryDataReceived: (data: GraphQLTypes.PricingEditorProductQuery) => ({
    data
  }),
  setEditCell: (editCell: EditCell) => ({ editCell }),
  updateEditCell: (value: string) => ({ value }),
  endEdit: () => ({}),
  changeNewRowType: (newRowType: NewRowType) => ({
    newRowType
  }),
  updateNewRowState: (newRowState: NewRowState) => ({ newRowState }),
  deleteRow: (rowId: string, isLastSystem: boolean) => ({
    rowId,
    isLastSystem
  }),
  dispatchPricingActions: (action: PricingActions.Action) => ({ action }),
  addRows: (rows: ReadonlyArray<NewPricingRow>) => ({
    rows
  }),
  changeInputRowNo: (rowNo: number) => ({ rowNo }),
  systemSearch: () => ({}),
  systemSearchQueryDataReceived: (
    data: GraphQLTypes.PricingSystemSearchQuery
  ) => ({ data }),
  validateSystem: (systemId: string, visualizerCode: string) => ({
    systemId,
    visualizerCode
  }),
  setCurrency: (currencyCode: string) => ({ currencyCode }),
  setMasterMode: (isMasterMode: boolean) => ({ isMasterMode }),
  setComment: (notes: string) => ({ notes }),
  savePricing: () => ({}),
  openPricing: (url: string, openInNewTab: boolean) => ({
    url,
    openInNewTab
  }),
  lockPricing: () => ({}),
  mutationCompleted: () => ({}),
  setReportSettings: (reportSettings: ReportSettings) => ({ reportSettings }),
  setLoader: (showLoader: boolean) => ({ showLoader }),
  setModal: (modal: Modal | undefined) => ({ modal }),
  toggleOpenPricingActions: (id: string) => ({ id }),
  onValidationError: (errorMessage: string) => ({ errorMessage })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "userQueryDataReceived": {
      if (state.type !== "ok") {
        return [state];
      }

      if (!action.data.user.pricingByPricingNo) {
        return [
          {
            type: "error",
            errorMessage: "Pricing not Found"
          }
        ];
      }

      const pricing: Pricing = mapGraphQlPricing(
        action.data.user.pricingByPricingNo
      );

      const pricingArticles: ReadonlyArray<PricingArticle> =
        MapGraphQlPricingArticles(action.data.user.pricingArticles);

      const pricingGroups = [
        ...new Set(
          pricingArticles
            .filter(pa => pa.templateGroup)
            .map(pa => pa.templateGroup!)
        )
      ];

      const initialDefaultRowNo =
        pricing.rows.reduce((a, b) => (b.rowNo > a ? b.rowNo : a), 0) + 10;

      const newRow: NewRowState = pricing.rows.length
        ? {
            type: "header",
            description: ""
          }
        : {
            type: "system",
            searchText: ""
          };

      const pricingFileName = pricing.pricingFileName;
      const fullPricingNo = sharedState.genesysPrefix.pricingNo(
        pricing.pricingNo,
        pricing.revisionNo
      );

      return [
        {
          ...state,
          pricing: pricing,
          pricingActionIsCompleteUrl: action.actionUrl,
          showLoader: false,
          pricingArticles: pricingArticles,
          pricingGroups: pricingGroups,
          newRowState: newRow,
          inputRowNo: initialDefaultRowNo
        },
        undefined,
        [
          SharedState.Action.setBrowserTitle(
            `${fullPricingNo} ${pricingFileName}`
          )
        ]
      ];
    }
    case "productQueryDataReceived": {
      if (state.type !== "ok") {
        return [state];
      }
      const currencyRates = action.data.product.currencyRates;
      const exchangeRateTable: Finance.ExchangeRateTable = currencyRates.map(
        r => ({
          currency: r.currency.id,
          exchangeRate: r.exchangeRate,
          exchangeRateTemplate: Guid.guidFromString(
            r.currencyExchangeRateTemplate.id
          ),
          validFrom: DateHelper.fromUTC(r.validFrom!)
        })
      );
      return [
        {
          ...state,
          productQueryResult: action.data,
          exchangeRateTable: exchangeRateTable
        }
      ];
    }
    case "setEditCell": {
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, editCell: action.editCell }];
    }

    case "dispatchPricingActions": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricingActionsState) {
        return [state];
      }

      const [
        pricingActionsState,
        pricingActionsCmd,
        sharedStateActions,
        isDone,
        actionUrl
      ] = PricingActions.update(
        action.action,
        state.pricingActionsState,
        sharedState
      );

      // const showLoader = isDone === undefined ? false : !isDone;

      if (isDone) {
        return [
          { ...state, showLoader: true, pricingActionsState },
          sharedState.graphQL.queryUserCmd<
            GraphQLTypes.PricingEditorUserQuery,
            GraphQLTypes.PricingEditorUserQueryVariables,
            Action
          >(
            userQuery,
            {
              pricingNo: state.pricing!.pricingNo,
              revisionNo: state.pricing!.revisionNo
            },
            data => Action.userQueryDataReceived(data, actionUrl)
          )
        ];
      }

      return [
        {
          ...state,
          showLoader: false,
          pricingActionsState: pricingActionsState
        },
        Cmd.map(Action.dispatchPricingActions, pricingActionsCmd),
        sharedStateActions
      ];
    }
    case "updateEditCell": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.editCell) {
        return [state];
      }

      return [
        {
          ...state,
          editCell: {
            ...state.editCell,
            value: action.value
          },
          unsavedContent: true
        }
      ];
    }
    case "endEdit": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing || !state.editCell) {
        return [state];
      }

      const index = state.pricing.rows.findIndex(
        r => r.id === state.editCell!.rowId
      );

      const newRow = mapCellChangeToRow(
        state.editCell,
        state.pricing.rows[index],
        state.editCell.value,
        state.pricing.masterMode
      );

      return [
        {
          ...state,
          editCell: undefined,
          pricing: {
            ...state.pricing,
            rows: state.pricing.rows
              .slice(0, index)
              .concat(newRow)
              .concat(state.pricing.rows.slice(index + 1))
          }
        }
      ];
    }
    case "changeNewRowType": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.newRowState || !state.pricing) {
        return [state];
      }

      switch (action.newRowType) {
        case "article": {
          return [
            {
              ...state,
              newRowState: {
                type: "article",
                selectedArticle: state.pricingArticles.length
                  ? state.pricingArticles[0].id
                  : ""
              },
              SystemPricingSearch: []
            }
          ];
        }

        case "articleGroup":
          return [
            {
              ...state,
              newRowState: {
                type: "articleGroup",
                selectedArticleGroup: state.pricingGroups.length
                  ? state.pricingGroups[0]
                  : ""
              },
              SystemPricingSearch: []
            }
          ];
        case "header":
          return [
            {
              ...state,
              newRowState: {
                type: "header",
                description: ""
              },
              SystemPricingSearch: []
            }
          ];
        case "system":
          return [
            {
              ...state,
              newRowState: {
                type: "system",
                searchText: ""
              },
              SystemPricingSearch: []
            }
          ];
        default:
          return exhaustiveCheck(action.newRowType, true);
      }
    }
    case "updateNewRowState": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.newRowState) {
        return [state];
      }

      return [{ ...state, newRowState: action.newRowState }];
    }

    case "deleteRow": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing) {
        return [state];
      }

      if (action.isLastSystem) {
        return [
          {
            ...state,
            newRowState: {
              type: "system",
              searchText: ""
            },
            pricing: {
              ...state.pricing,
              rows: [],
              exchangeRateTemplateId: undefined,
              masterMode: false,
              salesOrganisationId: undefined,
              masterSalesOrganisationId: undefined
            },
            unsavedContent: true
          }
        ];
      } else {
        const pricingRows = state.pricing.rows.filter(
          r => r.id !== action.rowId
        );
        return [
          {
            ...state,
            pricing: { ...state.pricing, rows: pricingRows },
            unsavedContent: true
          }
        ];
      }
    }

    case "addRows": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing || !state.exchangeRateTable) {
        return [state];
      }

      const firstSystem =
        state.pricing.rows.length === 0
          ? action.rows.find(r => r.row.type === "system")
          : undefined;

      const exchangeRateTemplateId = firstSystem
        ? firstSystem.exchangeRateTemplateId!
        : state.pricing.exchangeRateTemplateId!;

      const currencyAdjustedRows = action.rows.map(r => {
        const rate = Finance.getTodaysRate(
          r.currencyCode,
          state.pricing!.currencyCode,
          Guid.guidFromString(exchangeRateTemplateId),
          state.exchangeRateTable!
        );
        const masterRate = Finance.getTodaysRate(
          r.masterCurrencyCode,
          state.pricing!.currencyCode,
          Guid.guidFromString(exchangeRateTemplateId),
          state.exchangeRateTable!
        );
        return {
          ...r.row,
          costPerUnit: r.row.costPerUnit! * rate,
          transferPricePerUnit: r.row.transferPricePerUnit! * rate,
          masterPricePerUnit: r.row.masterPricePerUnit! * masterRate
        };
      });

      const newRows = state.pricing.rows.concat(currencyAdjustedRows);

      const pricing = firstSystem
        ? {
            ...state.pricing,
            rows: newRows,
            salesOrganisationId: firstSystem.salesOrganisationId,
            masterSalesOrganisationId: firstSystem.masterSalesOrganisationId,
            exchangeRateTemplateId: firstSystem.exchangeRateTemplateId
          }
        : {
            ...state.pricing,
            rows: newRows
          };

      const rowNo =
        newRows.reduce((a, b) => (b.rowNo > a ? b.rowNo : a), 0) + 10;

      return [
        {
          ...state,
          pricing: pricing,
          inputRowNo: rowNo,
          unsavedContent: true,
          showLoader: false,
          SystemPricingSearch: []
        }
      ];
    }
    case "changeInputRowNo":
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, inputRowNo: action.rowNo }];
    case "systemSearch": {
      if (state.type !== "ok") {
        return [state];
      }
      if (state.newRowState?.type !== "system") {
        return [state];
      }

      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.PricingSystemSearchQuery,
          GraphQLTypes.PricingSystemSearchQueryVariables,
          Action
        >(
          searchQuery,
          {
            searchText: state.newRowState.searchText
          },
          Action.systemSearchQueryDataReceived
        )
      ];
    }
    case "systemSearchQueryDataReceived": {
      if (state.type !== "ok") {
        return [state];
      }
      return [
        {
          ...state,
          SystemPricingSearch: action.data.user.systemPricingSearch,
          showLoader: false
        }
      ];
    }
    case "validateSystem": {
      if (state.type !== "ok") {
        return [state];
      }
      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.PricingSystemValidationQuery,
          GraphQLTypes.PricingSystemValidationQueryVariables,
          Action
        >(
          validationQuery,
          {
            id: action.systemId
          },
          data =>
            getError(data, state) === undefined
              ? Action.addRows([
                  getSystemRow(data, state, action.visualizerCode)
                ])
              : Action.onValidationError(getError(data, state)!)
        )
      ];
    }
    case "setCurrency": {
      if (state.type !== "ok") {
        return [state];
      }
      if (
        !state.pricing ||
        !state.pricing.exchangeRateTemplateId ||
        !state.exchangeRateTable
      ) {
        return [state];
      }

      const rate = Finance.getTodaysRate(
        state.pricing.currencyCode,
        action.currencyCode,
        Guid.guidFromString(state.pricing.exchangeRateTemplateId),
        state.exchangeRateTable
      );

      return [
        {
          ...state,
          pricing: {
            ...state.pricing,
            currencyCode: action.currencyCode,
            rows: state.pricing.rows.map(r => ({
              ...r,
              costPerUnit: (r.costPerUnit || 0) * rate,
              transferPricePerUnit: (r.transferPricePerUnit || 0) * rate,
              masterPricePerUnit: (r.masterPricePerUnit || 0) * rate
            }))
          },
          unsavedContent: true
        }
      ];
    }
    case "setMasterMode": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing) {
        return [state];
      }
      return [
        {
          ...state,
          pricing: { ...state.pricing, masterMode: action.isMasterMode },
          unsavedContent: true
        }
      ];
    }
    case "setComment": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing) {
        return [state];
      }
      return [
        {
          ...state,
          pricing: { ...state.pricing, comment: action.notes },
          unsavedContent: true
        }
      ];
    }
    case "savePricing": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing) {
        return [state];
      }

      const params = mapToSaveParams(state.pricing);

      return [
        { ...state, showLoader: true },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdatePricing,
          GraphQLTypes.UpdatePricingVariables,
          Action
        >(updatePricingMutation, params, () => Action.mutationCompleted())
      ];
    }

    case "lockPricing": {
      if (state.type !== "ok") {
        return [state];
      }
      if (!state.pricing) {
        return [state];
      }

      const params = mapToSaveParams({
        ...state.pricing,
        status: GraphQLTypes.PricingStatus.LOCKED
      });

      return [
        {
          ...state,
          showLoader: true,
          pricing: {
            ...state.pricing,
            status: GraphQLTypes.PricingStatus.LOCKED
          }
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdatePricing,
          GraphQLTypes.UpdatePricingVariables,
          Action
        >(updatePricingMutation, params, () => Action.mutationCompleted())
      ];
    }

    case "mutationCompleted":
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, unsavedContent: false, showLoader: false }];
    case "setReportSettings":
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, reportSettings: action.reportSettings }];
    case "setLoader": {
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, showLoader: action.showLoader }];
    }
    case "setModal": {
      if (state.type !== "ok") {
        return [state];
      }
      return [{ ...state, modal: action.modal }];
    }

    case "toggleOpenPricingActions": {
      if (state.type !== "ok") {
        return [state];
      }
      let newPricingActionsState: PricingActions.State | undefined = undefined;

      if (state.pricingActionsState === undefined) {
        const [pricingActionsState] = PricingActions.init();
        newPricingActionsState = pricingActionsState;
      }
      return [
        {
          ...state,
          pricingActionsState: newPricingActionsState
        }
      ];
    }

    case "openPricing": {
      if (state.type !== "ok") {
        return [state];
      }
      if (action.openInNewTab) {
        window.open(action.url);
      } else {
        window.location.href = action.url;
      }
      return [{ ...state, pricingActionIsCompleteUrl: undefined }];
    }

    case "onValidationError": {
      if (state.type !== "ok") {
        return [state];
      }
      return [
        {
          ...state,
          modal: { type: "error", message: action.errorMessage },
          showLoader: false
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

// tslint:disable-next-line
