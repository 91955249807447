import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as LanguageText from "@genesys/shared/lib/language-texts";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { CaseType } from "@genesys/shared/lib/enums/case-type";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../../shared-state";
import * as GraphQlTypes from "../../graphql-types";
import { promiseCmd } from "../../promise-effect-manager";
import * as Navigation from "../../navigation-effect-manager";
import { clientConfig, Environment } from "../../config";
import { SystemEvent } from "../../system-events";
import * as LoadedState from "../state";
import * as Product from "../product";
import * as System from "../system";
import { handleConfiguratorActions } from "./configurator-action-handlers";
import { updateSystemMutation } from "./update-system-mutation";
import * as Types from "./types";
import { ConfiguratorAction } from "./configurator-actions";
import * as Queries from "./queries";

export type State =
  | Types.SystemLoadedState
  | Types.SystemLoadingState
  | Types.SystemExpiredState
  | Types.SystemError;

export const init = (
  sharedState: SharedState.State,
  genesysNoParam: string
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const genesysNoFormat = /^[dsg]{1}[0-9]+?-[0-9]+?$/i;

  if (!genesysNoFormat.test(genesysNoParam)) {
    return [
      {
        mode: "error",
        errorMessage: "Invalid GenesysNoFromat",
        traceId: undefined
      }
    ];
  }

  const split = genesysNoParam.split("-");
  const genesysNo = parseInt(split[0].substring(1, 7), 10);
  const genesysPrefix = split[0][0];

  if (
    genesysPrefix.toLowerCase() !== getGenesysPrefix(clientConfig.environment)
  ) {
    return [
      {
        mode: "error",
        errorMessage: `Invalid Genesys prefix "${genesysPrefix}" for enviroment ${clientConfig.environment}`,
        traceId: undefined
      },
      undefined,
      SharedState.Action.loadLastOpenedSystemsAndFavorites()
    ];
  }
  const revisionNo = parseInt(split[1], 10);
  return [
    {
      mode: "loading"
    },
    loadData(genesysNo, revisionNo, sharedState),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

function loadData(
  genesysNo: number,
  revisionNo: number,
  sharedState: SharedState.State
): Cmd<Action> {
  return promiseCmd<
    Action,
    | {
        readonly type: "ok";
        readonly user: GraphQlTypes.SystemConfiguratorUser;
        readonly product: GraphQlTypes.SystemConfiguratorProduct;
      }
    | {
        readonly type: "error";
        readonly message?: string;
      }
    | {
        readonly type: "expired";
        readonly systemId: string;
        readonly genesysNo: number;
        readonly revisionNo: number;
      }
    | {
        readonly type: "unauthorized";
      }
  >(
    async () => {
      let validation;
      try {
        validation = await sharedState.graphQL.queryUser<
          GraphQlTypes.SystemConfiguratorValidationUser,
          GraphQlTypes.SystemConfiguratorValidationUserVariables
        >(Queries.systemConfiguratorValidationQuery, {
          genesysNo: genesysNo,
          revisionNo: revisionNo
        });
      } catch (err) {
        return {
          type: "error",
          message: err
        };
      }
      if (!validation.user.validateSystemGenesysNo) {
        return {
          type: "error",
          message: "System file not found"
        };
      }

      if (!validation.user.validateSystemGenesysNo.hasPermission) {
        return {
          type: "unauthorized"
        };
      }

      if (!validation.user.validateSystemGenesysNo.isValid) {
        return {
          type: "expired",
          systemId: validation.user.validateSystemGenesysNo.systemId,
          genesysNo: genesysNo,
          revisionNo: revisionNo
        };
      }

      const user = await sharedState.graphQL.queryUser<
        GraphQlTypes.SystemConfiguratorUser,
        GraphQlTypes.SystemConfiguratorUserVariables
      >(Queries.systemConfiguratorQuery, {
        genesysNo: genesysNo,
        revisionNo: revisionNo
      });

      if (!user.user.systemByGenesysNo) {
        return {
          type: "error"
        };
      }

      const product = await sharedState.graphQL.queryProduct<
        GraphQlTypes.SystemConfiguratorProduct,
        GraphQlTypes.SystemConfiguratorProductVariables
      >(Queries.systemConfiguratorProductQuery, {
        systemType: {
          systemTypeId: user.user.systemByGenesysNo.systemFile.systemTypeId
        },
        systemProduct: {
          productId: user.user.systemByGenesysNo.systemFile.systemTypeId + "SYS"
        }
      });

      return {
        type: "ok",
        user: user,
        product: product
      };
    },
    result => {
      switch (result.type) {
        case "ok":
          return Action.loadingComplete({
            system: System.parseSystem(result.user.user.systemByGenesysNo!),
            productData: Product.parseProductData(
              result.product.product,
              sharedState.translate
            )
          });
        case "error":
          return Action.systemError(new Error(result.message || "error"));
        case "expired":
          return Action.systemExpiredDialog(
            result.systemId,
            result.genesysNo,
            result.revisionNo
          );
        case "unauthorized":
          return Action.systemError(new Error("unauthorized"));
        default:
          throw new Error("Unknown type");
      }
    },
    rej => Action.systemError(rej)
  );
}
function getGenesysPrefix(environment: Environment): Types.GenesysPrefix {
  switch (environment) {
    case "Develop":
    case "Localhost": {
      return "d";
    }
    case "Production": {
      return "g";
    }
    case "Staging": {
      return "s";
    }

    default:
      return exhaustiveCheck(environment, true);
  }
}

function openExpiredSystem(
  systemId: string,
  genesysNo: number,
  revisionNo: number,
  sharedState: SharedState.State
): Cmd<Action> {
  return promiseCmd<
    Action,
    | {
        readonly type: "ok";
        readonly user: GraphQlTypes.SystemConfiguratorUser;
        readonly product: GraphQlTypes.SystemConfiguratorProduct;
      }
    | {
        readonly type: "error";
        readonly message?: string;
      }
    | {
        readonly type: "expired";
        readonly systemId: string;
        readonly genesysNo: number;
        readonly revisionNo: number;
      }
    | {
        readonly type: "unauthorized";
      }
  >(
    async () => {
      const res = await sharedState.graphQL.queryUser<
        GraphQlTypes.UpdateSystemStatus,
        GraphQlTypes.UpdateSystemStatusVariables
      >(updateSystemMutation, {
        input: {
          forceUpdate: true,
          systemId: systemId,
          targetStatus: SystemStatus.ConfigureSystemSuccess
        }
      });

      if (res.updateSystem.__typename === "UpdateSystemRejectResultType") {
        return {
          type: "error",
          message: res.updateSystem.rejectReason
        };
      }

      let validation;
      try {
        validation = await sharedState.graphQL.queryUser<
          GraphQlTypes.SystemConfiguratorValidationUser,
          GraphQlTypes.SystemConfiguratorValidationUserVariables
        >(Queries.systemConfiguratorValidationQuery, {
          genesysNo: genesysNo,
          revisionNo: revisionNo
        });
      } catch (err) {
        return {
          type: "error",
          message: err
        };
      }

      if (!validation.user.validateSystemGenesysNo) {
        return {
          type: "error",
          message: "System file not found"
        };
      }

      if (!validation.user.validateSystemGenesysNo.hasPermission) {
        return {
          type: "unauthorized"
        };
      }

      if (!validation.user.validateSystemGenesysNo.isValid) {
        return {
          type: "expired",
          systemId: validation.user.validateSystemGenesysNo.systemId,
          genesysNo: genesysNo,
          revisionNo: revisionNo
        };
      }

      const user = await sharedState.graphQL.queryUser<
        GraphQlTypes.SystemConfiguratorUser,
        GraphQlTypes.SystemConfiguratorUserVariables
      >(Queries.systemConfiguratorQuery, {
        genesysNo: genesysNo,
        revisionNo: revisionNo
      });

      if (!user.user.systemByGenesysNo) {
        return {
          type: "error"
        };
      }

      const product = await sharedState.graphQL.queryProduct<
        GraphQlTypes.SystemConfiguratorProduct,
        GraphQlTypes.SystemConfiguratorProductVariables
      >(Queries.systemConfiguratorProductQuery, {
        systemType: {
          systemTypeId: user.user.systemByGenesysNo.systemFile.systemTypeId
        },
        systemProduct: {
          productId: user.user.systemByGenesysNo.systemFile.systemTypeId + "SYS"
        }
      });

      return {
        type: "ok",
        user: user,
        product: product
      };
    },
    result => {
      switch (result.type) {
        case "ok":
          return Action.loadingComplete({
            system: System.parseSystem(result.user.user.systemByGenesysNo!),
            productData: Product.parseProductData(
              result.product.product,
              sharedState.translate
            )
          });
        case "error":
          return Action.systemError(new Error(result.message ?? "error"));
        case "expired":
          return Action.systemExpiredDialog(
            result.systemId,
            result.genesysNo,
            result.revisionNo
          );
        case "unauthorized":
          return Action.systemError(new Error("unauthorized"));
        default:
          throw new Error("Unknown type");
      }
    },
    rej => Action.systemError(rej)
  );
}

export const Action = ctorsUnion({
  GotSystemEvent: (event: SystemEvent) => ({ event }),
  loadingComplete: (data: {
    readonly system: System.System;
    readonly productData: Product.ProductData;
  }) => ({
    data
  }),
  loadingSystemComplete: (data: System.System | undefined) => ({
    data
  }),
  systemMutationComplete: (
    errorMessage: string | undefined,
    traceId: string | undefined
  ) => ({
    errorMessage,
    traceId
  }),
  updateSystemNonBlockingStarted: (traceId: string | undefined) => ({
    traceId
  }),
  systemError: (error: Error) => ({ error }),
  systemExpiredDialog: (
    systemId: string,
    genesysNo: number,
    revisionNo: number
  ) => ({ systemId, genesysNo, revisionNo }),
  lowerStatusAndLoad: () => ({}),
  dispatchLoadedSystem: (action: LoadedState.Action) => ({ action }),
  redirect: (url: string) => ({ url }),
  calculateEnergy: (system: System.System) => ({ system })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "loadingComplete":
      const fullGno = sharedState.genesysPrefix.genesysNo(
        action.data.system.file.genesysNo,
        action.data.system.revisionNo
      );
      const systemFileName = action.data.system.file.name;
      const browserTitleAction = SharedState.Action.setBrowserTitle(
        `${fullGno} ${systemFileName}`
      );

      const [loadedState, sharedStateAction] = LoadedState.init(
        sharedState,
        action.data.system,
        action.data.productData
      );
      return [
        {
          ...state,
          mode: "loaded",
          loadedState: loadedState,
          traceId: undefined
        },
        undefined,
        [sharedStateAction, browserTitleAction]
      ];
    case "systemError":
      return [
        {
          ...state,
          mode: "error",
          traceId: undefined,
          errorMessage: action.error.message
        }
      ];
    case "dispatchLoadedSystem":
      if (state.mode === "loaded") {
        const [
          updatedState,
          configuratorActions,
          sharedStateActions,
          loadedCmd,
          shouldReloadSystem
        ] = LoadedState.update(action.action, state.loadedState, sharedState);
        const withLoadedState: State = { ...state, loadedState: updatedState };
        const [newState, cmds] = handleConfiguratorActions(
          configuratorActions,
          withLoadedState,
          sharedState
        );
        return [
          shouldReloadSystem &&
          newState.mode === "loaded" &&
          newState.loadedState.overlay.type === "OverlayNone"
            ? {
                ...newState,
                loadedState: {
                  ...newState.loadedState,
                  overlay: {
                    type: "OverlayQuery",
                    text: sharedState.translate(
                      LanguageText.calculationProgress("fetching")
                    )
                  }
                }
              }
            : newState,
          Cmd.batch<Action>([
            ...(cmds || []),
            Cmd.map(Action.dispatchLoadedSystem, loadedCmd),
            ...(shouldReloadSystem
              ? [
                  sharedState.graphQL.queryUserCmd<
                    GraphQlTypes.SystemConfiguratorUser,
                    GraphQlTypes.SystemConfiguratorUserVariables,
                    Action
                  >(
                    Queries.systemConfiguratorQuery,
                    {
                      genesysNo: state.loadedState.system.file.genesysNo,
                      revisionNo: state.loadedState.system.revisionNo
                    },
                    result =>
                      Action.loadingSystemComplete(
                        result.user.systemByGenesysNo
                          ? System.parseSystem(result.user.systemByGenesysNo)
                          : undefined
                      )
                  )
                ]
              : [])
          ]),
          sharedStateActions
        ];
      }
      return [state];

    case "calculateEnergy": {
      if (state.mode === "loaded") {
        const binCaseIds = action.system.operatingCases
          .filter(oc => oc.caseType === CaseType.Bin)
          .map(oc => oc.id);

        const [newState, cmds] = handleConfiguratorActions(
          [ConfiguratorAction.calculateEnergy(binCaseIds)],
          state,
          sharedState
        );

        return [newState, Cmd.batch<Action>([...(cmds || [])]), undefined];
      }
      return [state];
    }

    case "systemMutationComplete": {
      if (action.errorMessage) {
        return [
          {
            mode: "error",
            traceId: action.traceId,
            errorMessage: action.errorMessage
          }
        ];
      }

      if (state.mode !== "loaded") {
        return [state];
      }

      if (state.loadedState.runningMutations > 1) {
        return [
          {
            ...state,
            traceId: action.traceId,
            loadedState: {
              ...state.loadedState,
              runningMutations: state.loadedState.runningMutations - 1
            }
          }
        ];
      }

      return [
        {
          ...state,
          traceId: action.traceId,
          loadedState: {
            ...state.loadedState,
            runningMutations: 0
          }
        },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.SystemConfiguratorUser,
          GraphQlTypes.SystemConfiguratorUserVariables,
          Action
        >(
          Queries.systemConfiguratorQuery,
          {
            genesysNo: state.loadedState.system.file.genesysNo,
            revisionNo: state.loadedState.system.revisionNo
          },
          result =>
            Action.loadingSystemComplete(
              result.user.systemByGenesysNo
                ? System.parseSystem(result.user.systemByGenesysNo)
                : undefined
            )
        )
      ];
    }
    case "loadingSystemComplete": {
      if (state.mode !== "loaded") {
        return [state];
      }

      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            system: action.data!,
            overlay: { type: "OverlayNone" }
          }
        }
      ];
    }
    case "redirect":
      return [
        {
          ...state
        },
        Navigation.pushUrl(action.url)
      ];
    case "systemExpiredDialog":
      return [
        {
          ...state,
          mode: "expired",
          systemId: action.systemId,
          genesysNo: action.genesysNo,
          revisionNo: action.revisionNo
        }
      ];
    case "lowerStatusAndLoad":
      if (state.mode !== "expired") {
        return [state];
      }
      return [
        {
          ...state,
          mode: "loading"
        },

        openExpiredSystem(
          state.systemId,
          state.genesysNo,
          state.revisionNo,
          sharedState
        )
      ];
    case "GotSystemEvent": {
      if (state.mode !== "loaded") {
        return [state];
      }
      const [newState, cmd] = getStateFromSystemEvent(
        sharedState,
        state,
        action.event
      );
      return [newState, cmd];
    }
    case "updateSystemNonBlockingStarted": {
      if (state.mode !== "loaded") {
        return [state];
      }
      return [{ ...state, traceId: action.traceId }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
function getStateFromSystemEvent(
  sharedState: SharedState.State,
  state: Extract<State, { readonly mode: "loaded" }>,
  systemEvent: SystemEvent
): [State, Cmd<Action> | undefined] {
  const targetStatuses =
    state.loadedState.overlay.type !== "OverlayQuery" &&
    state.loadedState.overlay.type !== "OverlayNone"
      ? state.loadedState.overlay.targetStatuses
      : [];
  switch (systemEvent.type) {
    case "SystemMutationStarted": {
      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            overlay: { type: "OverlayDefault", targetStatuses }
          }
        },
        undefined
      ];
    }
    case "StepStarted": {
      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            overlay: {
              type: "OverlayWithText",
              targetStatuses,
              text: sharedState.translate(
                LanguageText.calculationProgress(systemEvent.name)
              )
            }
          }
        },
        undefined
      ];
    }
    case "SystemPersisting": {
      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            overlay: {
              type: "OverlayWithText",
              targetStatuses,
              text: sharedState.translate(
                LanguageText.calculationProgress("saving")
              )
            }
          }
        },
        undefined
      ];
    }
    case "SystemPersisted": {
      if (!systemEvent.wasSuccess) {
        return reloadSystem(state, sharedState);
      }

      const overlayState = state.loadedState.overlay;
      if (
        overlayState.type === "OverlayNone" ||
        overlayState.type === "OverlayQuery"
      ) {
        return [state, undefined];
      }

      const targetStatusesLeft = overlayState.targetStatuses.slice(1);

      if (targetStatusesLeft.length > 0) {
        return [
          {
            ...state,
            loadedState: {
              ...state.loadedState,
              overlay: { ...overlayState, targetStatuses: targetStatusesLeft }
            }
          },
          undefined
        ];
      }

      return reloadSystem(state, sharedState);
    }
    case "SystemMutationException": {
      return [
        {
          mode: "error",
          errorMessage: systemEvent.rejectReason,
          traceId: state.traceId
        },
        undefined
      ];
    }
    case "StepCompleted":
    case "EnergyBinCalculationsCompleted": {
      return [state, undefined];
    }
    case "EnergyBinCalculationStarted": {
      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            overlay: {
              type: "OverlayBinCalculations",
              casesLeft: systemEvent.cases,
              total: systemEvent.cases.length,
              targetStatuses
            }
          }
        },
        undefined
      ];
    }
    case "EnergyBinCalculationCompleted": {
      if (state.loadedState.overlay.type !== "OverlayBinCalculations") {
        return [state, undefined];
      }
      return [
        {
          ...state,
          loadedState: {
            ...state.loadedState,
            overlay: {
              type: "OverlayBinCalculations",
              casesLeft: state.loadedState.overlay.casesLeft.filter(
                c => c !== systemEvent.caseId
              ),
              total: state.loadedState.overlay.total,
              targetStatuses
            }
          }
        },
        undefined
      ];
    }
    default: {
      exhaustiveCheck(systemEvent, true);
    }
  }
}

function reloadSystem(
  state: Types.SystemLoadedState,
  sharedState: SharedState.State
): [State, Cmd<Action>] {
  return [
    {
      ...state,
      loadedState: {
        ...state.loadedState,
        overlay: {
          type: "OverlayQuery",
          text: sharedState.translate(
            LanguageText.calculationProgress("fetching")
          )
        }
      }
    },
    sharedState.graphQL.queryUserCmd<
      GraphQlTypes.SystemConfiguratorUser,
      GraphQlTypes.SystemConfiguratorUserVariables,
      Action
    >(
      Queries.systemConfiguratorQuery,
      {
        genesysNo: state.loadedState.system.file.genesysNo,
        revisionNo: state.loadedState.system.revisionNo
      },
      result =>
        Action.loadingSystemComplete(
          result.user.systemByGenesysNo
            ? System.parseSystem(result.user.systemByGenesysNo)
            : undefined
        )
    )
  ];
}
//tslint:disable-next-line
