import * as KnownProperties from "../known-properties";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";
import {
  getIfIntegerChanged as getIfIntegerValueChanged,
  getIfPropertyChanged as getIfPropertyValueChanged
} from "../helper-functions";
import { OperatingCaseGroupEnum } from "@genesys/graphql-types/src";

export const manualInputProperty =
  KnownProperties.buildingSurroundingAirManualInput;

export const dialogProperties = [
  KnownProperties.buildingLength,
  KnownProperties.buildingWidth,
  KnownProperties.buildingHeight,
  KnownProperties.buildingSurfaceArea,
  KnownProperties.buildingFloorArea,
  KnownProperties.buildingVolume,
  KnownProperties.buildingStructure,
  KnownProperties.buildingUseManualUValues,
  KnownProperties.buildingWallUValue,
  KnownProperties.buildingRoofUValue,
  KnownProperties.buildingUseManualLeakageCoefficient,
  KnownProperties.buildingLeakageCoefficient,
  KnownProperties.buildingLocation
];

export const standardProperties = [
  KnownProperties.buildingLength,
  KnownProperties.buildingHeight,
  KnownProperties.buildingSurfaceArea,
  KnownProperties.buildingFloorArea,
  KnownProperties.buildingVolume,
  KnownProperties.buildingStructure,
  KnownProperties.buildingWidth
];

export const surroundingRoom = [
  KnownProperties.buildingSurroundingAirManualInput,
  KnownProperties.buildingSurroundingAirTemperature,
  KnownProperties.buildingSurroundingUseOutdoorAirHumidity,
  KnownProperties.buildingSurroundingAirHumidity
];

export const allProperties = dialogProperties.concat(surroundingRoom);

export const overridableProperties = [
  KnownProperties.buildingSurroundingAirTemperature,
  KnownProperties.buildingSurroundingAirHumidity
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

function getPropertyDefaults(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  let length = getIfPropertyValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingLength
  );
  let height = getIfPropertyValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingHeight
  );
  let width = getIfPropertyValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingWidth
  );
  if (length !== undefined || height !== undefined || width !== undefined) {
    defaults = PropertyValueSet.merge(
      updateCalculatedValues(selectedProperties),
      defaults
    );
  }

  let structure = getIfIntegerValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingStructure
  );

  if (structure !== undefined) {
    defaults = PropertyValueSet.merge(
      updateUValues(selectedProperties),
      defaults
    );
    defaults = PropertyValueSet.merge(
      updateLeakageCoefficient(selectedProperties),
      defaults
    );
  }

  let buildingLocation = getIfIntegerValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingLocation
  );

  if (buildingLocation !== undefined) {
    defaults = PropertyValueSet.merge(
      buildingLocationDefaults(buildingLocation),
      defaults
    );
  }

  let buildingSurroundingUseOutdoorAirHumidity = getIfIntegerValueChanged(
    prevProperties,
    selectedProperties,
    KnownProperties.buildingSurroundingUseOutdoorAirHumidity
  );

  if (buildingSurroundingUseOutdoorAirHumidity !== undefined) {
    defaults = PropertyValueSet.merge(
      buildingSurroundingUseOutdoorAirHumidityDefaults(
        buildingSurroundingUseOutdoorAirHumidity
      ),
      defaults
    );
  }

  return defaults;
}

function updateLeakageCoefficient(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.buildingUseManualLeakageCoefficient,
      selections
    )
  ) {
    return PropertyValueSet.Empty;
  }

  return {
    [KnownProperties.buildingLeakageCoefficient]: PropertyValue.fromAmount(
      getLeakagePerForStructure(
        PropertyValueSet.getInteger(
          KnownProperties.buildingStructure,
          selections
        ) || 0
      )
    )
  };
}

function getLeakagePerForStructure(value: number) {
  return Amount.create(
    [0, 7.5, 6.17, 5, 3.33, 10, 9, 3.33, 2][value] || 0,
    Units.Percent
  );
}

function updateUValues(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.buildingUseManualUValues,
      selections
    )
  ) {
    return PropertyValueSet.Empty;
  }

  return {
    [KnownProperties.buildingRoofUValue]: PropertyValue.fromAmount(
      getRoofUValueForStructure(
        PropertyValueSet.getInteger(
          KnownProperties.buildingStructure,
          selections
        ) || 0
      )
    ),
    [KnownProperties.buildingWallUValue]: PropertyValue.fromAmount(
      getWallUValueForStructure(
        PropertyValueSet.getInteger(
          KnownProperties.buildingStructure,
          selections
        ) || 0
      )
    )
  };
}

function getWallUValueForStructure(value: number) {
  return Amount.create(
    [0, 0.6, 0.8, 1.2, 1, 6, 6, 6, 6][value] || 0,
    Units.WattPerSquareMeterPerKelvin
  );
}

function getRoofUValueForStructure(value: number) {
  return Amount.create(
    [0, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6, 0.6][value] || 0,
    Units.WattPerSquareMeterPerKelvin
  );
}
function updateCalculatedValues(
  selectedProperties: PropertyValueSet.PropertyValueSet
) {
  const length = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingLength,
      selectedProperties
    )!
  );
  const width = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingWidth,
      selectedProperties
    )!
  );
  const height = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingHeight,
      selectedProperties
    )!
  );

  return {
    [KnownProperties.buildingSurfaceArea]: PropertyValue.fromAmount(
      Amount.create(
        length * width + 2 * length * height + 2 * width * height,
        Units.SquareMeter
      )
    ),

    [KnownProperties.buildingFloorArea]: PropertyValue.fromAmount(
      Amount.create(length * width, Units.SquareMeter)
    ),
    [KnownProperties.buildingVolume]: PropertyValue.fromAmount(
      Amount.create(length * width * height, Units.CubicMeter)
    )
  };
}

function buildingLocationDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    default:
      return {
        [KnownProperties.buildingSurroundingAirTemperature]:
          PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
        [KnownProperties.buildingSurroundingAirHumidity]:
          PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
        [KnownProperties.buildingSurroundingAirManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.buildingSurroundingUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(0)
      };
    case 1:
      return {
        [KnownProperties.buildingSurroundingAirTemperature]:
          PropertyValue.fromAmount(Amount.create(15, Units.Celsius)),
        [KnownProperties.buildingSurroundingAirHumidity]:
          PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
        [KnownProperties.buildingSurroundingUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(1)
      };
  }
}

function buildingSurroundingUseOutdoorAirHumidityDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 1:
    default:
      return {
        [KnownProperties.buildingSurroundingAirHumidity]:
          PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram))
      };
    case 0:
      return {
        [KnownProperties.buildingSurroundingAirHumidity]:
          PropertyValue.fromAmount(Amount.create(8, Units.GramPerKilogram))
      };
  }
}
