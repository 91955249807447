import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createSystemAccessoriesSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps): string {
  const svgString = `<svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>FFEDB727-0727-4A73-9750-18195A6F72A9</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-804.000000, -1885.000000)">
            <g id="Icons/System-Menu-Items/Mechanical-Options" transform="translate(809.000000, 1888.000000)">
                <g id="Rectangle-Copy-13">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <path d="M49.75,13.25 L49.75,14.75 L31.5,14.75 L31.5,20.25 L33.9078947,20.25 L33.907,27.091 L40.75,27.0922206 L40.75,34.9080101 L33.907,34.906 L33.9078947,41.75 L31.5,41.75 L31.5,47.25 L49.75,47.25 L49.75,48.75 L11,48.75 L11,47.25 L29.25,47.25 L29.25,41.75 L26.0921053,41.75 L26.092,34.906 L19.25,34.9080101 L19.25,27.0922206 L26.092,27.091 L26.0921053,20.25 L29.25,20.25 L29.25,14.75 L11,14.75 L11,13.25 L49.75,13.25 Z M32.407,21.75 L27.592,21.75 L27.5921053,28.5909373 L20.75,28.592 L20.75,33.407 L27.5921053,33.4067267 L27.592,40.25 L32.407,40.25 L32.4078947,33.4067267 L39.25,33.407 L39.25,28.592 L32.4078947,28.5909373 L32.407,21.75 Z" id="Shape" fill="${color}" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>`;

  return encodeSvgStringToImgSrc(svgString);
}
