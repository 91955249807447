import * as React from "react";
import { Modal, ModalContent, ModalHeader } from "@genesys/ui-elements";
import * as Types from "../types";

export type SystemErrorDetailsModalProps = {
  readonly details: Types.ErrorDetails;
  readonly onClose: () => void;
};

export function SystemErrorDetailsModal(
  props: SystemErrorDetailsModalProps
): JSX.Element {
  return (
    <Modal maxWidth={600} onClose={props.onClose}>
      <ModalHeader borderBottom="0">Error details</ModalHeader>
      <ModalContent>
        <div>System ID: {props.details.systemId}</div>
        <div style={{ marginTop: "24px" }}>{props.details.message}</div>
      </ModalContent>
    </Modal>
  );
}
