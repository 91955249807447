import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import {
  changedFromBoth0,
  changedToBoth0,
  getIfIntegerChanged
} from "../../helper-functions";
import { IntenitionalVentilationIcon } from "@genesys/ui-elements";
import { LoadOperationTime } from "../../types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";

//Icon
export const Icon = IntenitionalVentilationIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

/// Moisture load selection
export const useMoistProperty =
  KnownProperties.useIntentionalVentilationMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty =
  KnownProperties.useIntentionalVentilationHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcintentionalventilation";
export const sourceName = MoistureHeatLoadTypeEnum.INTENTIONAL_VENTILATION;

export const description = "mlcintentionalventilationexplanation";
export const useLoadKey = "mlcuseintentionalventilation";

export const manualInputProperty =
  KnownProperties.intentionalVentilationManualInput;

export const properties = [
  KnownProperties.useIntentionalVentilationMoistureLoad,
  KnownProperties.useIntentionalVentilationHeatLoad,
  KnownProperties.intentionalVentilationManualInput,
  KnownProperties.ventilationFlow,
  KnownProperties.ventilationAirSource,
  KnownProperties.ventilationAirTemperature,
  KnownProperties.ventilationAirHumidity
];

export const overridableProperties = [
  KnownProperties.ventilationFlow,
  KnownProperties.ventilationAirTemperature,
  KnownProperties.ventilationAirHumidity
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let allUpdates = getPropertyDefaults(prevProperties, selectedProperties);

  return allUpdates;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.twoShiftHours;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  let systemConfiguration = getIfIntegerChanged(
    prevSelections,
    currentSelections,
    KnownProperties.systemConfiguration
  );
  if (systemConfiguration !== undefined) {
    defaults = PropertyValueSet.merge(
      setMoistureLoadSystemConfigurationDefaults(systemConfiguration),
      defaults
    );
  }

  if (
    changedFromBoth0(
      prevSelections,
      PropertyValueSet.merge(defaults, currentSelections),
      KnownProperties.useIntentionalVentilationMoistureLoad,
      KnownProperties.useIntentionalVentilationHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      useIntentionalVentilationDefaults(1),
      defaults
    );
  } else if (
    changedToBoth0(
      prevSelections,
      PropertyValueSet.merge(defaults, currentSelections),
      KnownProperties.useIntentionalVentilationMoistureLoad,
      KnownProperties.useIntentionalVentilationHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      useIntentionalVentilationDefaults(0),
      defaults
    );
  }

  // Room location
  const buildingLocation = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLocation
  );

  if (buildingLocation !== undefined) {
    defaults = PropertyValueSet.merge(
      roomLocationDefaults(
        PropertyValueSet.merge(defaults, currentSelections),
        buildingLocation
      ),
      defaults
    );
  }

  const ventilationAirSource = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.ventilationAirSource
  );
  if (ventilationAirSource !== undefined) {
    defaults = PropertyValueSet.merge(
      setVentilationAirSourceDefaults(ventilationAirSource),
      defaults
    );
  }
  return defaults;
}

function setMoistureLoadSystemConfigurationDefaults(
  propertyValue: number | undefined
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return PropertyValueSet.Empty;
    case 1:
      return {
        [KnownProperties.useIntentionalVentilationMoistureLoad]:
          PropertyValue.fromInteger(0),
        [KnownProperties.useIntentionalVentilationHeatLoad]:
          PropertyValue.fromInteger(0)
      };
    case 2:
      return {
        [KnownProperties.useIntentionalVentilationMoistureLoad]:
          PropertyValue.fromInteger(0),
        [KnownProperties.useIntentionalVentilationHeatLoad]:
          PropertyValue.fromInteger(0)
      };
    case undefined:
      return PropertyValueSet.Empty;
    default:
      throw new Error("Invalid value.");
  }
}

function useIntentionalVentilationDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.ventilationFlow]: PropertyValue.fromAmount(
          Amount.create(0, Units.StandardCubicMeterPerHour)
        ),
        [KnownProperties.ventilationAirSource]: PropertyValue.fromInteger(0),
        [KnownProperties.intentionalVentilationManualInput]:
          PropertyValue.fromInteger(0)
      };

    case 1:
      return {
        [KnownProperties.ventilationFlow]: PropertyValue.fromAmount(
          Amount.create(500, Units.StandardCubicMeterPerHour)
        ),
        [KnownProperties.ventilationAirSource]: PropertyValue.fromInteger(1),
        [KnownProperties.intentionalVentilationManualInput]:
          PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function roomLocationDefaults(
  currentSelections: PropertyValueSet.PropertyValueSet,
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.useIntentionalVentilationMoistureLoad,
      currentSelections
    ) === 0 &&
    PropertyValueSet.getInteger(
      KnownProperties.useIntentionalVentilationHeatLoad,
      currentSelections
    ) === 0
  ) {
    return {
      [KnownProperties.ventilationAirSource]: PropertyValue.fromInteger(0)
    };
  }
  switch (propertyValue) {
    case 0:
    case 1:
    default:
      return {
        [KnownProperties.ventilationAirSource]: PropertyValue.fromInteger(1)
      };
  }
}

function setVentilationAirSourceDefaults(
  propertyValue: number | undefined
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
    case 2:
      return {
        [KnownProperties.ventilationAirTemperature]: PropertyValue.fromAmount(
          Amount.create(0, Units.Celsius)
        ),
        [KnownProperties.ventilationAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 3:
      return {
        [KnownProperties.ventilationAirTemperature]: PropertyValue.fromAmount(
          Amount.create(15, Units.Celsius)
        ),
        [KnownProperties.ventilationAirHumidity]: PropertyValue.fromAmount(
          Amount.create(8, Units.GramPerKilogram)
        )
      };
    case undefined:
      return PropertyValueSet.Empty;
    default:
      throw new Error("Invalid value.");
  }
}
