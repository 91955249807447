import gql from "graphql-tag";

export const query = gql`
  query EccSizeSelector($ecc: GetProductInputType!) {
    product {
      ecc: product(input: $ecc) {
        id
        minMaxFaceVelocity: productDataTable(tableName: "minMaxFaceVelocity") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
