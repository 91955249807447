import * as Types from "./types";
import * as ParseTypes from "./parse-types";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { Quantity } from "@genesys/uom";

export function parseSystem(system: ParseTypes.System): Types.System {
  return {
    ...system,
    components: system.components.map(c => parseComponent(c))
  };
}

function parseComponent(component: ParseTypes.Component): Types.Component {
  return {
    ...component,
    properties: component.properties
      ? PropertyValueSet.fromString(component.properties)
      : PropertyValueSet.Empty,
    label: undefined,
    labelAbove: undefined
  };
}

export function parseProduct(product: ParseTypes.Product): Types.Product {
  return {
    id: product.id,
    boxConnectionPoints: product.boxConnectionPoints.map(cp =>
      parseConnectionPoint(cp)
    ),
    boxSymbols: product.boxSymbols.map(s => parseSymbol(s)),
    boxTexts: product.boxTexts.map(t => parseText(t)),
    boxLinePoints: product.boxLinePoints.map(lp => parseLinePoint(lp)),
    properties: product.properties.map(p => parseProperty(p))
  };
}

function parseConnectionPoint(
  connectionPoint: ParseTypes.BoxConnectionPoint
): Types.BoxConnectionPoint {
  return {
    ...connectionPoint,
    connectionType: connectionPoint.connectionType as Types.ConnectionType,
    diagramType: connectionPoint.diagramType as Types.DiagramType,
    propertyFilter: connectionPoint.propertyFilter
      ? PropertyFilter.fromString(connectionPoint.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseSymbol(symbol: ParseTypes.BoxSymbol): Types.BoxSymbol {
  return {
    ...symbol,
    diagramType: symbol.diagramType as Types.DiagramType,
    propertyFilter: symbol.propertyFilter
      ? PropertyFilter.fromString(symbol.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseText(text: ParseTypes.BoxText): Types.BoxText {
  return {
    ...text,
    diagramType: text.diagramType as Types.DiagramType,
    propertyFilter: text.propertyFilter
      ? PropertyFilter.fromString(text.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseLinePoint(linePoint: ParseTypes.BoxLine): Types.BoxLine {
  return {
    ...linePoint,
    diagramType: linePoint.diagramType as Types.DiagramType,
    direction: linePoint.direction as Types.Direction,
    lineType: linePoint.lineType as Types.LineType,
    propertyFilter: linePoint.propertyFilter
      ? PropertyFilter.fromString(linePoint.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseProperty(property: ParseTypes.Property): Types.Property {
  return {
    name: property.name,
    quantity: property.quantity as Quantity.Quantity
  };
}
