import * as React from "react";
import * as System from "../../../system";
import * as SharedState from "../../../../shared-state";
import { SolidContactImgRoot } from "../elements";

export function SolidContactImage({
  system,
  routeEndpoint,
  sharedState,
  onLoaded
}: {
  readonly system: System.System;
  readonly routeEndpoint: string;
  readonly sharedState: SharedState.State;
  readonly onLoaded: () => void;
}): JSX.Element {
  return (
    <SolidContactImgRoot
      accessToken={sharedState.accessToken}
      onLoad={onLoaded}
      src={`${routeEndpoint}/internal/GetSketchFromFixedView?format=svg&provider=SolidContact&systemId=${system.id}&view=Print&niid=${system.statusDate}`}
    />
  );
}
