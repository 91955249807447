import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  ErrorContainer,
  ErrorText,
  ReadOnlyText,
  Root,
  StyledInput
} from "./elements";

export type PropertyInputProps = {
  readonly value: string;
  readonly isReadOnly: boolean;
  readonly errorMessage?: string;
  readonly onChange: React.ChangeEventHandler<{ readonly value: string }>;
};

export function PropertyInput({
  value,
  errorMessage,
  isReadOnly,
  onChange
}: PropertyInputProps): JSX.Element {
  const [showError, setShowError] = useState(false);
  const hasError = errorMessage !== undefined && errorMessage.length > 0;

  if (!isReadOnly) {
    return (
      <ClickAwayListener onClickAway={() => setShowError(false)}>
        <Root>
          <StyledInput
            value={value}
            hasError={hasError}
            onChange={onChange}
            onClick={() => setShowError(true)}
          />
          {hasError && showError && (
            <ErrorContainer>
              <ErrorText>{errorMessage}</ErrorText>
            </ErrorContainer>
          )}
        </Root>
      </ClickAwayListener>
    );
  } else {
    return (
      <Root
        onMouseEnter={() => setShowError(true)}
        onMouseLeave={() => setShowError(false)}
      >
        <ReadOnlyText hasError={hasError}>{value}</ReadOnlyText>
        {hasError && showError && (
          <ErrorContainer>
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        )}
      </Root>
    );
  }
}
