import React from "react";
import {
  TopContainer,
  OpenableRelativeWithMarginContainer,
  OpenableWithMarginContainer,
  RelativeContainer,
  IconContainer
} from "../elements";
import { ExpandIcon, CollapseIcon, P1 } from "@genesys/ui-elements";
import {
  SingleNode,
  MultipleParallellNode,
  ParallellNode,
  NodeInfo
} from "../types";

export function StandardTimerLogElement({
  singleNode,
  shouldBeExpanded
}: {
  readonly shouldBeExpanded: boolean;
  readonly singleNode: SingleNode;
}): JSX.Element {
  // Time
  let nodeLog = `: ${getFormattedTimeSpan(singleNode.totalNs)} s`;

  // Percent of parent
  if (singleNode.percentOfParent !== undefined) {
    nodeLog += `, (${getFormatPercentOfParent(
      singleNode.percentOfParent
    )} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${getFormatedCalls(singleNode.count)}`;

  // Other
  if (singleNode.otherNs !== undefined) {
    nodeLog += `, Other: ${getFormattedTimeSpan(singleNode.otherNs)} s`;
  }

  // Idle
  if (singleNode.idleNs !== undefined) {
    nodeLog += `, Idle: ${getFormattedTimeSpan(singleNode.idleNs)} s`;
  }

  if (singleNode.isFireAndForget) {
    nodeLog +=
      ", call starts a job in another thread and exits, sub-totals will be found in subcalls.";
  }

  const subNodes = singleNode.singleNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const parallels = singleNode.parallellNode;

  const [isOpen, setState] = React.useState(shouldBeExpanded);
  const Icon = () => {
    if (isOpen) {
      return <CollapseIcon height={"16px"} onClick={() => setState(false)} />;
    } else {
      return <ExpandIcon height={"16px"} onClick={() => setState(true)} />;
    }
  };

  return (
    <div>
      <TopContainer>
        {subNodes.length > 0 && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <P1
          color="dark"
          weight={subNodes.length > 0 || parallels ? "bold" : "normal"}
        >
          {getFormatNodeName(singleNode.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen}>
        {subNodes.length > 0 &&
          subNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              <StandardTimerLogElement
                singleNode={subNode}
                shouldBeExpanded={true}
              />
            </div>
          ))}
        {parallels && (
          <div>
            <GroupedParallellNodesElement node={parallels} />
          </div>
        )}
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

export function GroupedParallellNodesElement({
  node
}: {
  node: MultipleParallellNode;
}): JSX.Element {
  // Time
  let nodeLog = `: ${getFormattedTimeSpan(node.totalNs)} s`;

  // Percent of parent
  if (node.percentOfParent !== undefined) {
    nodeLog += `, (${getFormatPercentOfParent(
      node.percentOfParent
    )} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${getFormatedCalls(node.count)}`;

  const orderedDeepSummedNodes = node.deepSummedNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const orderedIndividualNodes = node.individualParallellNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const hasSubNodes = orderedIndividualNodes.length > 0;

  const [isOpen, setState] = React.useState({
    isIndividualCallsOpen: false,
    isSubNodesOpen: true
  });
  const IndividualCallsIcon = () => {
    if (isOpen.isIndividualCallsOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: true })}
        />
      );
    }
  };

  const SubNodesIcon = () => {
    if (isOpen.isSubNodesOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: true })}
        />
      );
    }
  };

  return (
    <div>
      <TopContainer>
        {hasSubNodes && (
          <IconContainer>
            <SubNodesIcon />
          </IconContainer>
        )}
        <P1 color="dark" weight={hasSubNodes ? "bold" : "normal"}>
          All parallell calls
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
        <P1 color="dark" weight="bold">
          Sum of parallell calls
        </P1>
        <OpenableWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
          {orderedDeepSummedNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              <NodeInfoLogElement nodeInfo={subNode} shouldBeExpanded={false} />
            </div>
          ))}
        </OpenableWithMarginContainer>
        <RelativeContainer>
          <IconContainer>
            <IndividualCallsIcon />
          </IconContainer>
          <P1 color="dark" weight="bold">
            Individual calls
          </P1>
        </RelativeContainer>
        <OpenableWithMarginContainer isOpen={isOpen.isIndividualCallsOpen}>
          {hasSubNodes &&
            orderedIndividualNodes.map((subNode, index) => (
              <div key={`${subNode.name}-${index}`}>
                <ParallellNodeElement node={subNode} shouldBeExpanded={false} />
              </div>
            ))}
        </OpenableWithMarginContainer>
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function ParallellNodeElement({
  node,
  shouldBeExpanded
}: {
  readonly shouldBeExpanded: boolean;
  readonly node: ParallellNode;
}): JSX.Element {
  // Time
  let nodeLog = `: ${getFormattedTimeSpan(node.totalNs)} s`;

  // Percent of parent
  if (node.percentOfParent !== undefined) {
    nodeLog += `, (${getFormatPercentOfParent(
      node.percentOfParent
    )} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${getFormatedCalls(node.count)}`;

  // Idle
  if (node.idleNs !== undefined) {
    nodeLog += `, Idle: ${getFormattedTimeSpan(node.idleNs)} s`;
  }

  const hasSubNodes = node.individualSingleNodes.length > 0;

  const orderedSingleNodes = node.individualSingleNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const orderedAverageNodes = node.deepAveragedNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const [isOpen, setState] = React.useState({
    isIndividualCallsOpen: false,
    isSubNodesOpen: true
  });
  const IndividualCallsIcon = () => {
    if (isOpen.isIndividualCallsOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: true })}
        />
      );
    }
  };

  const SubNodesIcon = () => {
    if (isOpen.isSubNodesOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: true })}
        />
      );
    }
  };

  return (
    <div>
      <TopContainer>
        {hasSubNodes && (
          <IconContainer>
            <SubNodesIcon />
          </IconContainer>
        )}
        <P1 color="dark" weight={hasSubNodes ? "bold" : "normal"}>
          {getFormatNodeName(node.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
        <P1 color="dark" weight="bold">
          Average of parallell calls
        </P1>
        <OpenableWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
          {orderedAverageNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              <NodeInfoLogElement
                nodeInfo={subNode}
                shouldBeExpanded={shouldBeExpanded}
              />
            </div>
          ))}
        </OpenableWithMarginContainer>
        <RelativeContainer>
          <IconContainer>
            <IndividualCallsIcon />
          </IconContainer>
          <P1 color="dark" weight="bold">
            Individual calls
          </P1>
        </RelativeContainer>
        <OpenableWithMarginContainer isOpen={isOpen.isIndividualCallsOpen}>
          {orderedSingleNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              <StandardTimerLogElement
                singleNode={subNode}
                shouldBeExpanded={false}
              />
            </div>
          ))}
        </OpenableWithMarginContainer>
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function NodeInfoLogElement({
  nodeInfo,
  shouldBeExpanded
}: {
  readonly shouldBeExpanded: boolean;
  readonly nodeInfo: NodeInfo;
}): JSX.Element {
  let nodeLog = "";
  if (nodeInfo.type === "Sum") {
    // Time
    nodeLog += `: ${getFormattedTimeSpan(nodeInfo.totalNs)} s`;

    // Percent of parent
    if (nodeInfo.percentOfParent !== undefined) {
      nodeLog += `, (${getFormatPercentOfParent(
        nodeInfo.percentOfParent
      )} % of parent)`;
    }

    // Count
    nodeLog += `, Calls: ${getFormatedCalls(nodeInfo.count)}`;

    // Other
    if (nodeInfo.otherNs !== undefined) {
      nodeLog += `, Other: ${getFormattedTimeSpan(nodeInfo.otherNs)} s`;
    }

    // Idle
    if (nodeInfo.idleNs !== undefined) {
      nodeLog += `, Idle: ${getFormattedTimeSpan(nodeInfo.idleNs)} s`;
    }
  } else {
    // Time
    nodeLog += `: ${getFormattedTimeSpan(
      nodeInfo.averageTotalNs
    )} s, [Min: ${getFormattedTimeSpan(
      nodeInfo.minTotalNs
    )} s, Max: ${getFormattedTimeSpan(nodeInfo.maxTotalNs)} s]`;

    // Percent of parent
    if (nodeInfo.averagePercentOfParent !== undefined) {
      nodeLog += `, (${getFormatPercentOfParent(
        nodeInfo.averagePercentOfParent
      )} % of parent)`;
    }

    // Count
    nodeLog += `, Calls: ${getFormatedCalls(
      nodeInfo.averageCount
    )}, [Min: ${getFormatedCalls(nodeInfo.minCount)}, Max: ${getFormatedCalls(
      nodeInfo.maxCount
    )}]`;

    // Other
    if (nodeInfo.averageOtherNs !== undefined) {
      nodeLog += `, Other: ${getFormattedTimeSpan(
        nodeInfo.averageOtherNs
      )} s, [Min: ${getFormattedTimeSpan(
        nodeInfo.minOtherNs || 0
      )} s, Max: ${getFormattedTimeSpan(nodeInfo.maxOtherNs || 0)} s]`;
    }

    // Idle
    if (nodeInfo.averageIdleNs !== undefined) {
      nodeLog += `, Idle: ${getFormattedTimeSpan(
        nodeInfo.averageIdleNs
      )} s, [Min: ${getFormattedTimeSpan(
        nodeInfo.minIdleNs || 0
      )} s, Max: ${getFormattedTimeSpan(nodeInfo.maxIdleNs || 0)} s]`;
    }
  }

  const subNodes = nodeInfo.subNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const [isOpen, setState] = React.useState(shouldBeExpanded);
  const Icon = () => {
    if (isOpen) {
      return <CollapseIcon height={"16px"} onClick={() => setState(false)} />;
    } else {
      return <ExpandIcon height={"16px"} onClick={() => setState(true)} />;
    }
  };

  return (
    <div>
      <TopContainer>
        {subNodes.length > 0 && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <P1 color="dark" weight={subNodes.length > 0 ? "bold" : "normal"}>
          {getFormatNodeName(nodeInfo.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen}>
        {subNodes.length > 0 &&
          subNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {
                <NodeInfoLogElement
                  nodeInfo={subNode}
                  shouldBeExpanded={true}
                />
              }
            </div>
          ))}
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function getFormattedTimeSpan(nanoSeconds: number): string {
  const seconds = nanoSeconds / 1000000000;
  return `${Number.parseFloat(seconds.toFixed(3))}`;
}

function getFormatedCalls(numberOfCalls: number): string {
  return `${Number.parseFloat(numberOfCalls.toFixed(2))}`;
}

function getFormatPercentOfParent(percentOfParent: number): string {
  return `${Number.parseFloat(percentOfParent.toFixed(2))}`;
}

function getFormatNodeName(name: string): string {
  return name.length > 50 ? name.substring(0, 50) + " ..." : name;
}
