import { Cmd } from "@typescript-tea/core";
import { promiseCmd } from "../../promise-effect-manager";
import { queryMoistureLoadProduct } from "../queries";
import { clientConfig } from "../../config";
import { Action } from "./actions";
import { State } from "./types";
import { fechUserQuery } from "./shared";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";
import * as ClimateSelector from "../../climate-selector";
import * as MoistureLoadActions from "../../moisture-load-actions";

export const init = (
  sharedState: SharedState.State,
  moistureloadNoParam: string
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const initialState: State = {
    printParamsState: undefined,
    moistureLoadId: undefined,
    moistureloadActionState: MoistureLoadActions.init()[0],
    actionMenuIsOpen: false,
    moistureLoadFileId: undefined,
    propertyDefinitions: undefined,
    calculating: false,
    moistureLoadUserSettings: undefined,
    inputsUpdated: false,
    showLoader: false,
    propertiesSelectorState: undefined,
    initialSelections: undefined,
    moistureLoadClaimValue: undefined,
    userQuery: undefined,
    productQueryMoistureLoad: undefined,
    currentSystemOverrides: undefined,
    climateSettingsEditorState: undefined,
    indoorSettingsEditorState: undefined,
    mainContentState: undefined,
    calcResult: undefined,
    additionalOptions: {
      includeAllInputs: false,
      includeCharts: false
    },
    languages: [],
    moistureLoadNo: 0,
    revisionNo: 0,
    aclMessage: undefined,
    userInputSearch: "",
    modal: undefined,
    errorMessage: undefined,
    developerSettings: {
      showDevResults: false,
      showLogs: false,
      logsTraceId: undefined
    }
  };

  const errorMessage = testMoistureLoadNo(moistureloadNoParam, sharedState);

  if (errorMessage) {
    return [
      {
        ...initialState,
        errorMessage
      }
    ];
  }

  const split = moistureloadNoParam.split("-");
  const moistureLoadNo = parseInt(split[0].replace(/\D/g, ""), 10);
  const revisionNo = parseInt(split[1], 10);

  return [
    {
      ...initialState,
      errorMessage: undefined
    },

    promiseCmd(
      async () => {
        const userQuery = fechUserQuery(
          sharedState,
          moistureLoadNo,
          revisionNo
        );

        const productQuery = sharedState.graphQL.queryProduct<
          GraphQLTypes.MoistureLoadCalculationProductQuery,
          GraphQLTypes.MoistureLoadCalculationProductQueryVariables
        >(queryMoistureLoadProduct, {
          locationId: ClimateSelector.defaultWmo
        });

        const [userQueryResult, productQueryResult] = await Promise.all([
          userQuery,
          productQuery
        ]);

        return { userQueryResult, productQueryResult };
      },

      res =>
        Action.initialQueryRecieved(res.userQueryResult, res.productQueryResult)
    )
  ];
};

type MoistureLoadNo = number;
type RevNo = number;

export function getMoistureLoadNoAndRevNo(
  testedMoistureloadNoParam: string
): [MoistureLoadNo, RevNo] {
  const split = testedMoistureloadNoParam.split("-");
  const moistureLoadNo = parseInt(split[0].replace(/\D/g, ""), 10);
  const revisionNo = parseInt(split[1], 10);

  return [moistureLoadNo, revisionNo];
}

export function testMoistureLoadNo(
  moistureloadNoParam: string,
  sharedState: SharedState.State
) {
  const moistureLoadNoFormat = /m[dsg]{1}[0-9]+?-[0-9]+?$/i;
  if (!moistureLoadNoFormat.test(moistureloadNoParam)) {
    return "Invalid MoistureLoad Format";
  }

  const split = moistureloadNoParam.split("-");
  const prefix = split[0].replace(/[0-9]/g, "");

  const enviroment = clientConfig.environment;

  if (
    prefix.toLowerCase() !==
    sharedState.genesysPrefixNotation.moistureLoadNo.toLowerCase()
  ) {
    return `Invalid Moisture load prefix "${prefix}" for enviroment ${enviroment}`;
  }

  return "";
}
