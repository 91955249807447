import { clientConfig } from "../../../../../config";
import { UpdateFunction } from "../types";

export const handleSetBrowserTitle: UpdateFunction = (action, state) => {
  if (action.type !== "setBrowserTitle") {
    return [state];
  }
  window.document.title =
    action.title + " | " + clientConfig.defaultBrowserTitle;
  return [state];
};
