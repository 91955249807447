import * as Types from "./types";
import * as Helper from "./helper";
import * as uuid from "uuid";

export function assignSubgraphIds(diagram: Types.Diagram): Types.Diagram {
  const visited: Array<string> = [];
  const newNodes: Array<Types.Node> = [];

  // tslint:disable-next-line
  while (!!diagram.nodes.find(n => !~visited.indexOf(n.id))) {
    const subGraphId = uuid.v4();
    // tslint:disable-next-line
    const start = diagram.nodes.find(n => !~visited.indexOf(n.id))!;

    const stack = Array<Types.Node>();
    stack.push(start);
    while (stack.length > 0) {
      const node = stack.pop()!;
      // tslint:disable-next-line
      if (~visited.indexOf(node.id)) {
        continue;
      }
      visited.push(node.id);
      const nodeNeighbours = Helper.getNodeNeighbours(diagram, node);
      nodeNeighbours.forEach(n => stack.push(n));

      newNodes.push({
        ...node,
        subGraphId: subGraphId
      });
    }
  }

  return {
    ...diagram,
    nodes: newNodes
  };
}
