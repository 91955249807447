import { PropertyValue } from "@promaster-sdk/property";
import { unitLookup } from "@genesys/uom";
import * as PropertyCompare from "./property-compare";

export {
  AmountPropertyValue,
  IntegerPropertyValue,
  PropertyType,
  PropertyValue,
  TextPropertyValue,
  fromAmount,
  fromInteger,
  fromText,
  getAmount,
  getInteger,
  create,
  getText,
  toString,
  valueAs
} from "@promaster-sdk/property/lib/property-value";

export const equals = (
  left: PropertyValue.PropertyValue,
  right: PropertyValue.PropertyValue
) => PropertyValue.equals(left, right, PropertyCompare.compare);

export const lessThan = (
  left: PropertyValue.PropertyValue,
  right: PropertyValue.PropertyValue
) => PropertyValue.lessThan(left, right, PropertyCompare.compare);

export const lessOrEqualTo = (
  left: PropertyValue.PropertyValue,
  right: PropertyValue.PropertyValue
) => PropertyValue.lessOrEqualTo(left, right, PropertyCompare.compare);

export const greaterThan = (
  left: PropertyValue.PropertyValue,
  right: PropertyValue.PropertyValue
) => PropertyValue.greaterThan(left, right, PropertyCompare.compare);

export const greaterOrEqualTo = (
  left: PropertyValue.PropertyValue,
  right: PropertyValue.PropertyValue
) => PropertyValue.greaterOrEqualTo(left, right, PropertyCompare.compare);

export function fromString(
  encodedValue: string
): PropertyValue.PropertyValue | undefined {
  return PropertyValue.fromString(encodedValue.toLowerCase(), unitLookup);
}
