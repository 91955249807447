import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Quantity, Unit } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import * as PropertiesSelector from "../../properties-selector";

export type AirStream = "one" | "two";

export type State = {
  readonly airStreamOne: PropertiesSelector.State;
  readonly airStreamTwo: PropertiesSelector.State;
  readonly airMix: PropertyValueSet.PropertyValueSet;
};

export const init = (): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const initialProperties = PropertyValueSet.fromString(
    "temperature=20:Celsius;humidity=2:GramPerKilogram;pressure=101325:Pascal;flow=300:StandardCubicMeterPerHour"
  );
  return [
    {
      airStreamOne: PropertiesSelector.init(initialProperties),
      airStreamTwo: PropertiesSelector.init(initialProperties),
      airMix: PropertyValueSet.Empty
    }
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (
    airStream: AirStream,
    action: PropertiesSelector.Action
  ) => ({ airStream, action }),
  calculateAirMix: () => ({}),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const oldState =
        action.airStream === "one" ? state.airStreamOne : state.airStreamTwo;
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        oldState,
        sharedState,
        "SkipCalculateProperties"
      );

      switch (action.airStream) {
        case "one":
          return [
            { ...state, airStreamOne: propertiesSelectorState },
            Cmd.map(
              action => Action.dispatchPropertiesSelector("one", action),
              propertiesSelectorCmd
            ),
            [propertiesSelectorSharedAction]
          ];
        case "two":
          return [
            { ...state, airStreamTwo: propertiesSelectorState },
            Cmd.map(
              action => Action.dispatchPropertiesSelector("two", action),
              propertiesSelectorCmd
            ),
            [propertiesSelectorSharedAction]
          ];
        default:
          return exhaustiveCheck(action.airStream, true);
      }
    }
    case "calculateAirMix": {
      const airStreamOneProperties = PropertiesSelector.getSelectedProperties(
        state.airStreamOne
      );
      const airStreamTwoProperties = PropertiesSelector.getSelectedProperties(
        state.airStreamTwo
      );

      const air1 = Physics.AirFlowState.create(
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "temperature",
          airStreamOneProperties
        ) as Amount.Amount<Quantity.Temperature>,
        PropertyValueSet.getAmount<Quantity.HumidityRatio>(
          "humidity",
          airStreamOneProperties
        ) as Amount.Amount<Quantity.HumidityRatio>,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          "pressure",
          airStreamOneProperties
        ) as Amount.Amount<Quantity.Pressure>,
        PropertyValueSet.getAmount<Quantity.MassFlow>(
          "flow",
          airStreamOneProperties
        ) as Amount.Amount<Quantity.MassFlow>
      );

      const air2 = Physics.AirFlowState.create(
        PropertyValueSet.getAmount<Quantity.Temperature>(
          "temperature",
          airStreamTwoProperties
        ) as Amount.Amount<Quantity.Temperature>,
        PropertyValueSet.getAmount<Quantity.HumidityRatio>(
          "humidity",
          airStreamTwoProperties
        ) as Amount.Amount<Quantity.HumidityRatio>,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          "pressure",
          airStreamTwoProperties
        ) as Amount.Amount<Quantity.Pressure>,
        PropertyValueSet.getAmount<Quantity.MassFlow>(
          "flow",
          airStreamTwoProperties
        ) as Amount.Amount<Quantity.MassFlow>
      );

      const mix = Physics.AirCalculation.calculateAdiabaticMixingOfTwoAirstreams(
        air1,
        air2
      );
      const temperature = PropertyValue.fromAmount(mix.airOut.temperature);
      const humidity = PropertyValue.fromAmount(mix.airOut.humidity);
      const pressure = PropertyValue.fromAmount(mix.airOut.pressure);
      const flow = PropertyValue.fromAmount(mix.airOut.flow);
      const condensation = PropertyValue.fromInteger(
        mix.dehumidification ? 1 : 0
      );

      const mixAirstream = PropertyValueSet.set(
        "temperature",
        temperature,
        PropertyValueSet.set(
          "humidity",
          humidity,
          PropertyValueSet.set(
            "pressure",
            pressure,
            PropertyValueSet.set(
              "flow",
              flow,
              PropertyValueSet.set(
                "condensation",
                condensation,
                PropertyValueSet.Empty
              )
            )
          )
        )
      );

      return [{ ...state, airMix: mixAirstream }];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
