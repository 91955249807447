import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import {
  GetWiringDiagramBuildStateUserQuery,
  GetWiringDiagramBuildStateUserQueryVariables,
  BuildWiringDiagramMutation,
  BuildWiringDiagramMutationVariables,
  DownloadWiringDiagramUserQuery,
  DownloadWiringDiagramUserQueryVariables,
  WiringDiagramCheckStateResponseCodeEnum,
  WiringDiagramDownloadResponseCodeEnum
} from "../../../graphql-types";
import { buildMutation, getBuildStateQuery, downloadQuery } from "./queries";
import {
  BuildStateResponse,
  DownloadResponse,
  RequestBuildResponse
} from "./types";
import {
  parseWiringDiagramBuildResponseCodeEnum,
  parseWiringDiagramCheckStateResponseCodeEnum,
  parseWiringDiagramDownloadResponseCodeEnum,
  getPdfUrl
} from "./functions";

export type State = {
  readonly isLoading: boolean;
  readonly isBuilding: boolean;
  readonly url: string | undefined;
  readonly stateDescription: string;
};

export const init = (
  systemId: string,
  sharedState: SharedState.State
): [State, Cmd<Action>] => {
  return [
    {
      isLoading: true,
      isBuilding: false,
      url: undefined,
      stateDescription: ""
    },
    sharedState.graphQL.queryUserCmd<
      GetWiringDiagramBuildStateUserQuery,
      GetWiringDiagramBuildStateUserQueryVariables,
      Action
    >(
      getBuildStateQuery,
      {
        input: {
          systemId: systemId
        }
      },
      data =>
        Action.handleBuildStateResponse({
          queryData: data,
          systemId: systemId
        })
    )
  ];
};

export const Action = ctorsUnion({
  requestBuild: (systemId: string) => ({ systemId }),
  handleRequestBuildResponse: (response: RequestBuildResponse) => ({
    response
  }),
  getBuildState: (systemId: string) => ({ systemId }),
  handleBuildStateResponse: (response: BuildStateResponse) => ({ response }),
  handleDownloadResponse: (response: DownloadResponse) => ({ response })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "requestBuild": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          BuildWiringDiagramMutation,
          BuildWiringDiagramMutationVariables,
          Action
        >(
          buildMutation,
          {
            input: {
              systemId: action.systemId
            }
          },
          data =>
            Action.handleRequestBuildResponse({
              queryData: data
            })
        )
      ];
    }
    case "handleRequestBuildResponse": {
      const { stateDescription, isBuilding } =
        parseWiringDiagramBuildResponseCodeEnum(
          action.response.queryData.buildWiringDiagram.responseCode
        );

      return [
        {
          ...state,
          isLoading: false,
          isBuilding: isBuilding,
          stateDescription: stateDescription
        }
      ];
    }
    case "getBuildState": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GetWiringDiagramBuildStateUserQuery,
          GetWiringDiagramBuildStateUserQueryVariables,
          Action
        >(
          getBuildStateQuery,
          {
            input: {
              systemId: action.systemId
            }
          },
          data =>
            Action.handleBuildStateResponse({
              queryData: data,
              systemId: action.systemId
            })
        )
      ];
    }
    case "handleBuildStateResponse": {
      const responseCode: WiringDiagramCheckStateResponseCodeEnum =
        action.response.queryData.user.getWiringDiagramBuildState.responseCode;

      const { stateDescription, isBuilding } =
        parseWiringDiagramCheckStateResponseCodeEnum(responseCode);

      const isBuildCompleted =
        responseCode === WiringDiagramCheckStateResponseCodeEnum.BUILT;

      if (isBuildCompleted) {
        return [
          {
            ...state,
            isBuilding: false,
            stateDescription: stateDescription
          },
          sharedState.graphQL.queryUserCmd<
            DownloadWiringDiagramUserQuery,
            DownloadWiringDiagramUserQueryVariables,
            Action
          >(
            downloadQuery,
            {
              input: {
                systemId: action.response.systemId
              }
            },
            data =>
              Action.handleDownloadResponse({
                queryData: data
              })
          )
        ];
      } else {
        return [
          {
            ...state,
            isLoading: false,
            isBuilding: isBuilding,
            stateDescription: stateDescription
          }
        ];
      }
    }
    case "handleDownloadResponse": {
      var responseCode: WiringDiagramDownloadResponseCodeEnum =
        action.response.queryData.user.downloadWiringDiagram.responseCode;

      const stateDescription =
        parseWiringDiagramDownloadResponseCodeEnum(responseCode);

      const isPdfAvailable =
        responseCode === WiringDiagramDownloadResponseCodeEnum.OK;

      const url = isPdfAvailable
        ? getPdfUrl(action.response.queryData)
        : undefined;

      return [
        {
          ...state,
          url: url,
          isLoading: false,
          stateDescription: stateDescription
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
