import * as React from "react";
import { Quantity } from "@genesys/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as PropertiesSelector from "../react-properties-selector";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as PropertiesSelectorElements from "./elements";
import { ProductPropertySelector } from "./product-property-selector";
import { ValueSourceSelector } from "../value-source-selector";
import { PropertyLabel } from "./property-label";
import * as UserProfile from "../user-profile";

interface RowRendererProps {
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly hidePropertyName: boolean;
  readonly selectorInfo: PropertiesSelector.PropertySelectorRenderInfo;
}

// tslint:disable-next-line:function-name
export function RowRenderer({
  fieldGroup,
  fieldName,
  hidePropertyName,
  selectorInfo
}: RowRendererProps) {
  return (
    <UserProfile.UserProfile>
      {user => {
        const title = AuthorizationTools.checkPermission(
          user.applicationClaims,
          AuthorizationTools.genesysUserClaims.developer
        )
          ? "GroupName: " + fieldGroup + "\nFieldName: " + fieldName
          : undefined;
        return (
          <tr>
            {!hidePropertyName && (
              <PropertyLabel
                labelText={selectorInfo.label}
                isHidden={selectorInfo.isHidden}
              />
            )}
            <td>
              <span title={title}>
                <PropertiesSelectorElements.DefaultPropertySelector
                  {...selectorInfo.selectorComponentProps}
                />
              </span>
            </td>
          </tr>
        );
      }}
    </UserProfile.UserProfile>
  );
}

interface ProductPropertyRowRenderer {
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly valueSourcesDict: {
    readonly [
      propertyName: string
    ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  };
  readonly hidePropertyName: boolean;
  readonly onQuantityChange: (quantity: Quantity.Quantity) => void;
  readonly selectorInfo: PropertiesSelector.PropertySelectorRenderInfo;
  readonly conversionParameters:
    | QuantityConversion.ConversionParameters
    | undefined;
  readonly isQuantitySelectorActive: boolean;
}

// tslint:disable-next-line:function-name
export function ProductPropertyRowRenderer({
  conversionParameters,
  fieldGroup,
  fieldName,
  filterPrettyPrint,
  isQuantitySelectorActive,
  hidePropertyName,
  onQuantityChange,
  selectorInfo,
  translatePropertyValue,
  valueSourcesDict
}: ProductPropertyRowRenderer): JSX.Element {
  const { isHidden, propertyName, label, selectorComponentProps } =
    selectorInfo;
  const valueSources = valueSourcesDict[propertyName] || [];
  return (
    <tr>
      {!hidePropertyName && (
        <PropertyLabel labelText={label} isHidden={isHidden} />
      )}
      <ProductPropertySelector
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        filterPrettyPrint={filterPrettyPrint}
        onPropertyFormatChanged={selectorComponentProps.onPropertyFormatChanged}
        onPropertyFormatCleared={selectorComponentProps.onPropertyFormatCleared}
        onPropertyFormatSelectorToggled={
          selectorComponentProps.onPropertyFormatSelectorToggled
        }
        onQuantityChange={onQuantityChange}
        valueSources={valueSources}
        selectorInfo={selectorInfo}
        conversionParameters={conversionParameters}
        isQuantitySelectorActive={isQuantitySelectorActive}
        translatePropertyValue={translatePropertyValue}
      />
      <td>
        <ValueSourceSelector
          isLocked={selectorInfo.selectorComponentProps.readOnly}
          onChange={selectorComponentProps.onChange}
          selectedProperties={selectorComponentProps.selectedProperties}
          propertyName={propertyName}
          showCodes={selectorComponentProps.includeCodes}
          valueSources={valueSources}
        />
      </td>
    </tr>
  );
}
