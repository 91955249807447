import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const copySystemAndTransferToSelfMutation = gql`
  mutation CopySystemAndTransferToSelfMutation(
    $systemId: ID!
    $systemName: String!
  ) {
    copySystemAndTransferToSelf(systemId: $systemId, systemName: $systemName) {
      ...CompleteSystemFile
    }
  }
  ${Fragments.completeSystemFile}
`;
