import * as React from "react";
import * as uuid from "uuid";
import * as GQLCachePatch from "gql-cache-patch";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";
import * as Contexts from "../contexts";

export type DiscardQueue = () => void;
export type Queue = (
  queueMutationDescription: GraphQLMutationsQueue.QueueMutationDescription
) => void;
export type DeQueue = (
  queuedMutations: ReadonlyArray<GraphQLMutationsQueue.QueuedMutation>
) => void;
export type Flush = () => Promise<
  ReadonlyArray<GraphQLMutationsQueue.SentMutationResult>
>;
export type QueueAndFlush = (
  queueMutationDescriptions: ReadonlyArray<
    GraphQLMutationsQueue.QueueMutationDescription
  >
) => Promise<ReadonlyArray<GraphQLMutationsQueue.SentMutationResult>>;

export type ApplyPatches = (
  cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>
) => void;

interface ChildProps {
  readonly queue: Queue;
  readonly deQueue: DeQueue;
  readonly flushQueue: Flush;

  readonly queueAndFlush: QueueAndFlush;
  readonly discardQueue: DiscardQueue;
  readonly applyPatches: ApplyPatches;
  readonly mutationQueue: GraphQLMutationsQueue.MutationsQueueState;
  readonly hasNonFlushedChanges: boolean;
}

interface ConsumerProps {
  readonly children: (props: ChildProps) => JSX.Element;
  readonly persistentQueueId?: string;
}

interface ConsumerState {
  readonly queueId: string;
}

export class MutationsQueue extends React.Component<
  ConsumerProps,
  ConsumerState
> {
  constructor(props: ConsumerProps) {
    super(props);

    this.state = {
      queueId: this.props.persistentQueueId || uuid.v4()
    };
  }
  render() {
    return (
      <Contexts.mutationsQueueContext.Consumer>
        {({
          mutationQueues,
          queue,
          deQueue,
          discardQueue,
          flushQueue,
          applyPatches
        }) => {
          const mutationQueue =
            mutationQueues[this.state.queueId] ||
            GraphQLMutationsQueue.EmptyQueue;
          return React.Children.only(
            this.props.children({
              hasNonFlushedChanges: mutationQueue.queuedMutations.length > 0,
              mutationQueue,
              queue: (
                queueMutationDescription: GraphQLMutationsQueue.QueueMutationDescription
              ) => {
                queue(this.state.queueId, [queueMutationDescription]);
              },
              deQueue: (
                queuedMutations: ReadonlyArray<
                  GraphQLMutationsQueue.QueuedMutation
                >
              ) => {
                deQueue(this.state.queueId, queuedMutations);
              },
              discardQueue: () => {
                discardQueue(this.state.queueId);
              },
              applyPatches,
              flushQueue: async () => {
                return await flushQueue(this.state.queueId);
              },
              queueAndFlush: async (
                queueMutationDescriptions: ReadonlyArray<
                  GraphQLMutationsQueue.QueueMutationDescription
                >
              ) => {
                queue(this.state.queueId, queueMutationDescriptions);

                return new Promise<
                  ReadonlyArray<GraphQLMutationsQueue.SentMutationResult>
                >(resolve => {
                  //Important to do this in next tic so react can rerender and give new mutations state to provider
                  setImmediate(async () => {
                    const results = await flushQueue(this.state.queueId);
                    resolve(results);
                  });
                });
              }
            })
          );
        }}
      </Contexts.mutationsQueueContext.Consumer>
    );
  }
}
