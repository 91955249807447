import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import { Quantity, UnitFormat, UnitsFormat } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as AbstractImage from "abstract-image";
import {
  Spinner,
  StandardButton,
  OverlayLoader,
  GenesysSelect
} from "@genesys/ui-elements";
import {
  Root,
  ExportContainer,
  SpinnerContainer,
  FlowDiagramContainer
} from "./elements";
import * as FetchDocument from "@genesys/client-core/lib/fetch-document";
import { clientConfig } from "../../../config";

const exportOptions: ReadonlyArray<{
  readonly value: string;
  readonly title: string;
}> = [
  {
    value: "SVG",
    title: "SVG"
  },
  {
    value: "DXF2D",
    title: "DXF"
  }
];

export function PidView({
  state,
  dispatch,
  sharedState,
  sysProperties
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
}): JSX.Element {
  if (!state.userData || !state.productData) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const routeEndpoint = clientConfig.genesysBackend;
  const product = state.productData.product;

  const allProducts = ProductData.getValidProductsForRange(
    product.systemType.allProducts,
    sysProperties
  );
  const parsedProducts = allProducts.map(p =>
    FlowDiagram.Parser.parseProduct(p)
  );
  const parsedSystem = FlowDiagram.Parser.parseSystem(
    state.userData.user.system
  );

  const getAmountFormatString = (
    fieldGroup: string,
    fieldName: string,
    quantity: Quantity.Quantity,
    property: PropertyValue.PropertyValue
  ) => {
    const amountFormat = sharedState.screenAmounts.getAmountFormat(
      fieldGroup,
      fieldName,
      quantity
    );

    const unitFormat = UnitFormat.getUnitFormat(
      amountFormat.unit,
      UnitsFormat
    )?.label;

    return `${getValue(property, amountFormat)} ${unitFormat}`;
  };

  const diagram = FlowDiagram.buildDiagram(
    false,
    "PidDiagram",
    false,
    parsedProducts,
    parsedSystem,
    (productId, propertyName, propertyValue) =>
      sharedState.translate(
        LanguageTexts.productPropertyValue(
          productId,
          propertyName,
          propertyValue
        )
      ),
    translationKey =>
      sharedState.translate(
        LanguageTexts.dynamicText(
          translationKey,
          state.productData!.product.systemType.id
        )
      ),
    translationKey =>
      sharedState.translate(
        LanguageTexts.curlyTranslate(
          translationKey,
          state.productData!.product.systemType.id
        )
      ),
    getAmountFormatString,
    40
  );

  const abstractDiagram = FlowDiagram.buildAbstractImage(
    diagram,
    [],
    [],
    [],
    product.systemType.symbols,
    40
  );

  const svgDiagram = AbstractImage.createReactSvg(abstractDiagram);

  return (
    <Root>
      <ExportContainer>
        <GenesysSelect
          width={50}
          height={28}
          fontSize={14}
          value={state.selectedFormat.apiValue}
          options={exportOptions}
          onChange={e => dispatch(Action.setFormat(e.target.value))}
        />
        <FetchDocument.FetchDocumentComponent
          authentication={{
            type: "Bearer",
            accessToken: sharedState.accessToken
          }}
          filename={
            state.userData.user.system.systemFile.name +
            "." +
            state.selectedFormat.fileExtension
          }
          url={`${routeEndpoint}/internal/GetPidDiagram`}
          params={{
            format: state.selectedFormat.apiValue,
            systemId: state.userData.user.system.id
          }}
          onComplete={() => dispatch(Action.setLoader(false))}
          type={"download"}
        >
          {onClick => (
            <StandardButton
              buttonType="Primary"
              size="Small"
              onClick={() => {
                dispatch(Action.setLoader(true));
                onClick();
              }}
            >
              {sharedState.translate(LanguageTexts.exportText())}
            </StandardButton>
          )}
        </FetchDocument.FetchDocumentComponent>
        {state.showLoader && <OverlayLoader />}
      </ExportContainer>

      <FlowDiagramContainer>{svgDiagram}</FlowDiagramContainer>
    </Root>
  );
}
