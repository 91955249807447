import styled from "styled-components";

export const Root = styled.div`
  padding: 25px 50px 25px 50px;
  overflow-y: auto;
`;

// AbstractImage
export const ExportBox = styled.div`
  display: flex;
  button {
    margin-left: 8px;
  }
`;

export const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 731px;
`;

export const FlowDiagramBox = styled.div`
  max-width: 85%;
  max-height: 85%;
  svg {
    width: 100%;
    height: 100%;
  }
`;

// eContact
export const LoadingRoot = styled.div`
  display: flex;
  justify-content: center;
  height: 500px;
`;

export const ButtonsBox = styled.div`
  padding-top: 15px;

  button {
    margin-right: 10px;
  }
`;
