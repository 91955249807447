import * as UpdateActionHandlers from "./handlers/update-action-handlers";
import { Cmd } from "@typescript-tea/core";
import { State } from "./types";
import { Action } from "./actions";

// Define a type for the update functions
type UpdateFunction = (
  action: Action,
  state: State
) => readonly [State, Cmd<Action>?];

const actionHandlers: Record<Action["type"], UpdateFunction> = {
  accessTokenRefreshed: UpdateActionHandlers.handleAccessTokenRefreshed,
  dispatchTermsAndConditions:
    UpdateActionHandlers.handleDispatchTermsAndConditions,
  dispatchInitialSettings: UpdateActionHandlers.handleDispatchInitialSettings,
  dispatchMain: UpdateActionHandlers.handleDispatchMain,
  dispatchVersionChecker: UpdateActionHandlers.handleDispatchVersionChecker,
  initialQueriesReceived: UpdateActionHandlers.handleInitialQueriesReceived,
  getNextMessage: UpdateActionHandlers.handleGetNextMessage,
  sharedStateActionMutationCompleted:
    UpdateActionHandlers.handleSharedActionMutationCompleted,
  recentAndFavoritesLoaded:
    UpdateActionHandlers.handleRecentAndFavoritesQueryLoaded,
  requestNewTranslationOnUrlChange:
    UpdateActionHandlers.handleRequestNewTranslationOnUrlChange,
  translationQueryReceived: UpdateActionHandlers.handleTranslationQueryReceived,
  UrlChanged: UpdateActionHandlers.handleUrlChanged,
  UrlRequested: UpdateActionHandlers.handleUrlRequested,
  userQueryReceived: UpdateActionHandlers.handleUserQueryReceived
};

export function update(
  action: Action,
  state: State
): readonly [State, Cmd<Action>?] {
  const handler = actionHandlers[action.type];
  if (!handler) {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  return handler(action, state);
}
