import * as React from "react";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { Label, H3 } from "@genesys/ui-elements";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;

  span {
    line-height: 1.2em;
  }
`;

const StyledH3 = styled(H3)`
  font-size: 14.5px;
`;

const Name = styled(Label)`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
`;

export function MoistureLoadInfo({
  labels,
  moistureLoadNo,
  name,
  systems,
  sharedState
}: {
  readonly labels: ReadonlyArray<string>;
  readonly moistureLoadNo: string;
  readonly name: string;
  readonly systems: ReadonlyArray<string>;
  readonly sharedState: SharedState.State;
}) {
  const translate = sharedState.translate;
  return (
    <Container>
      <StyledH3 color="dark">{moistureLoadNo}</StyledH3>
      <Name weight="normal">{name}</Name>
      <span>
        <StyledLabel weight="normal">
          {systems.length > 0
            ? print(systems)
            : translate(LanguageTexts.mlcNoSystemsconnected())}
        </StyledLabel>
      </span>
      <span>
        <StyledLabel weight="normal">
          {!!labels.length
            ? print(labels)
            : translate(LanguageTexts.mlcNoLabelsAssigned())}
        </StyledLabel>
      </span>
    </Container>
  );
}

function print(values: ReadonlyArray<string>) {
  return values.map((x, i) => {
    if (i < values.length - 1) {
      return x + ", ";
    } else {
      return x;
    }
  });
}
