import styled from "styled-components";
import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Img, OverlayLoader, P1 } from "@genesys/ui-elements";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { State, Action } from "./state";
import * as SharedState from "../shared-state";
import * as Config from "../config";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  margin: 24px auto;
  padding: 30px 50px 50px 50px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const Table = styled.table`
  td {
    padding-right: 10px;
  }
`;

export function AboutView({
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const hasDeveloperPermission = AuthorizationTools.checkPermission(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.developer
  );

  if (!state.productData) {
    return <OverlayLoader />;
  }

  const { productDbInfo, version, serverCommitInfo, serverName, userDbInfo } =
    state.productData.product.serverInformation;

  return (
    <Root>
      <ImagesContainer>
        <Img
          src={"/assets/images/munterslogo2.png"}
          accessToken={sharedState.accessToken}
          width={200}
          height={"auto"}
        />
        <Img
          src={"/assets/images/genesys.png"}
          accessToken={sharedState.accessToken}
          width={180}
          height={"auto"}
        />
      </ImagesContainer>

      <Table>
        <tbody>
          {row(
            "Genesys",
            "Munters Selection Software is developed by Divid AB"
          )}
          {hasDeveloperPermission && row("Version", version)}
          {hasDeveloperPermission &&
            row(
              "Client commit",
              `${Config.clientConfig.clientVersion.tag} - ${Config.clientConfig.clientVersion.commit}`
            )}
          {hasDeveloperPermission && row("Server commit", serverCommitInfo)}
          {hasDeveloperPermission && row("Server", serverName)}
          {hasDeveloperPermission && row("ProductDb", productDbInfo)}
          {hasDeveloperPermission && row("UserDb", userDbInfo)}
          {row("Contact", "genesys@munters.se")}
        </tbody>
      </Table>
    </Root>
  );
}

function row(left: string, right: string) {
  return (
    <tr>
      <td>
        <P1 weight="normal" color="dark">
          {left}
        </P1>
      </td>
      <td>
        <P1 weight="normal" color="dark">
          {right}
        </P1>
      </td>
    </tr>
  );
}
