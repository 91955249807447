import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { StandardButton } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Label, View } from "../types";

export function CtoButtons({
  isDisabled,
  view,
  selectedlabels,
  onCancel,
  onAssgin,
  dispatch,
  translate
}: {
  readonly isDisabled: boolean;
  readonly view: View;
  readonly selectedlabels: ReadonlyArray<Label>;
  readonly onCancel: () => void;
  readonly onAssgin: (
    selectedlabels: ReadonlyArray<{
      readonly name: string;
      readonly id: string;
    }>
  ) => void;
  readonly dispatch: Dispatch<Action>;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  const backButton = (
    <StandardButton
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(Action.changeView("assign"));
      }}
      size="Large"
      buttonType="Secondary-2"
    >
      {translate(LanguageTexts.back()).toUpperCase()}
    </StandardButton>
  );

  switch (view) {
    case "create": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.createNewLabel());
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.create()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    case "editOrDelete": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.editLabelValue());
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.save()).toUpperCase()}
          </StandardButton>
        </>
      );
    }

    case "assign": {
      return (
        <>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onCancel();
            }}
            size="Large"
            buttonType="Secondary-2"
          >
            {translate(LanguageTexts.cancel()).toUpperCase()}
          </StandardButton>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onAssgin(selectedlabels);
            }}
            size="Large"
            buttonType="Primary-2"
          >
            {translate(LanguageTexts.assign()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    default:
      return exhaustiveCheck(view, true);
  }
}
