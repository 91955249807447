import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchControlsPagingInput = styled.input`
  height: 26px;
  width: 30px;
  text-align: center;
  font-weight: bold;
  border: 1px solid ${Colors.searchFilterBorderColor};

  margin: 0 5px 0 0;
`;
