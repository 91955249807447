import * as xlsx from "xlsx";

export type Data = ReadonlyArray<ReadonlyArray<string>>;
export interface WorkSheet {
  readonly name: string | undefined;
  readonly data: Data;
}

export function download(
  worksheets: ReadonlyArray<WorkSheet>,
  filename: string
) {
  const workbook = xlsx.utils.book_new();

  for (const sheet of worksheets) {
    const worksheet = xlsx.utils.aoa_to_sheet([...sheet.data.map(d => [...d])]);

    sheet.name
      ? xlsx.utils.book_append_sheet(workbook, worksheet, sheet.name)
      : xlsx.utils.book_append_sheet(workbook, worksheet);
  }

  xlsx.writeFile(workbook, filename + ".xlsx");
}
