import { Action } from "../../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { v4 } from "uuid";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as Types from "../../types";

export function createSystem(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  systemName: string,
  systemTypeId: string,
  newProperties: PropertyValueSet.PropertyValueSet,
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  climateData: PropertyValueSet.PropertyValueSet,
  divergentSysComponent:
    | {
        readonly id: string;
        readonly properties: PropertyValueSet.PropertyValueSet;
      }
    | undefined,
  moistureLoadInfo: Types.MoistureLoadInfo | undefined
): void {
  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  return dispatch(
    Action.createSystem({
      input: [
        {
          id: v4(),
          name: moistureLoadInfo?.name || systemName,
          moistureLoadId: moistureLoadInfo?.moistureLoadId,
          addMCompare: false,
          labels: moistureLoadInfo?.labels,
          climateData: PropertyValueSet.toString(climateData),
          currencyCode: sharedState.user.settings.currency,
          generateBinCases: false,
          newProperties: PropertyValueSet.toString(newProperties),
          operatingCases: operatingCases.map(oc =>
            PropertyValueSet.toString(oc)
          ),
          salesOrganisationId:
            sharedState.user.settings.defaultSalesOrganisation ||
            (primarySalesOrganisationFromClaim &&
              primarySalesOrganisationFromClaim[0]) ||
            sharedState.user.salesOrganisations[0].id,
          systemTypeId: systemTypeId,
          targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
          templateComponents: divergentSysComponent
            ? [
                {
                  id: divergentSysComponent.id,
                  properties: PropertyValueSet.toString(
                    divergentSysComponent.properties
                  )
                }
              ]
            : []
        }
      ]
    })
  );
}

export function calculateSystems(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  systemInputs: ReadonlyArray<{
    readonly systemName: string;
    readonly systemTypeId: string;
    readonly newProperties: PropertyValueSet.PropertyValueSet;
    readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
    readonly climateData: PropertyValueSet.PropertyValueSet;
    readonly systemIdentifier: string;
  }>
): void {
  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  return dispatch(
    Action.calculateSystems(
      systemInputs.map(s => ({
        systemIdentifier: s.systemIdentifier,
        createSystemInput: {
          systemSettings: {
            addMCompare: false,
            labels: undefined,
            climateData: PropertyValueSet.toString(s.climateData),
            currencyCode: sharedState.user.settings.currency,
            generateBinCases: false,
            id: v4(),
            name: s.systemName,
            newProperties: PropertyValueSet.toString(s.newProperties),
            operatingCases: s.operatingCases.map(oc =>
              PropertyValueSet.toString(oc)
            ),
            salesOrganisationId:
              sharedState.user.settings.defaultSalesOrganisation ||
              (primarySalesOrganisationFromClaim &&
                primarySalesOrganisationFromClaim[0]) ||
              sharedState.user.salesOrganisations[0].id,
            systemTypeId: s.systemTypeId,
            targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
            templateComponents: []
          },
          systemTypeId: { systemTypeId: s.systemTypeId }
        }
      }))
    )
  );
}
