import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const copySystemToNewSystemFileMutation = gql`
  mutation CopySystemToNewSystemFile($systemId: ID!) {
    copySystemToNewSystemFile(systemId: $systemId) {
      ...CompleteSystemFile
    }
  }
  ${Fragments.completeSystemFile}
`;
