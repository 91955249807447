import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import styled from "styled-components";
import { Pop, ArrowDown, CheckBox } from "@genesys/ui-elements";
import { OperationTime, Action } from "./state";
import { allRow } from "./view";
import {
  monthRange,
  MutableValues,
  allMonthsYear,
  noMonthsYear
} from "./predefined-presets";
import { Dispatch } from "@typescript-tea/core";

const FakeSelect = styled.div<{ readonly isDisabled: boolean }>`
  box-sizing: border-box;
  height: 34px;
  width: 190px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  ${props => props.isDisabled && "color: rgba(0, 0, 0, 0.38)"};
  ${props => !props.isDisabled && "cursor: pointer"};
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    overflow: hidden;
    color: #3d3d3d;
    color: ${props => (props.isDisabled ? "rgba(0, 0, 0, 0.38)" : "#3d3d3d")};
    padding-left: 20px;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
  }
`;

const RowSection = styled.ul`
  height: 200px;
  width: 190px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledLi = styled.li<{
  readonly isChecked: boolean;
}>`
  padding-left: 20px;
  padding-top: 2px;

  ${props => props.isChecked && "background-color: #F1F5FA;"}
`;

const StyledLabel = styled.label`
  color: #16181e;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 4px;
  height: 34px;
  display: inline-block;
`;

const months = [
  "all",
  "jan",
  "feb",
  "mars",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "okt",
  "nov",
  "dec"
];

export function MonthSelector({
  sharedState,
  operationTime,
  disabled,
  allowedSelections,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly operationTime: OperationTime;
  readonly allowedSelections?: OperationTime;
  readonly disabled: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const valuesForMonths: MutableValues = {
    ...operationTime.months,
    all: allRow(operationTime.months)
  };

  const allowedMonthlyVal = allowedSelections?.months || allMonthsYear;

  const allowedMonths: MutableValues = {
    ...allowedMonthlyVal,
    all: allRow(allowedMonthlyVal)
  };

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<Element>) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FakeSelect isDisabled={disabled} onClick={handleClick}>
        <label>{getTitleForSelectBox(valuesForMonths, sharedState)}</label>
        <ArrowDown />
      </FakeSelect>
      {anchorEl && (
        <Pop
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          <RowSection>
            {["all", ...monthRange].map((month, i) => {
              const isChecked = valuesForMonths[month];
              const isMonthSelectable = allowedMonths[month];
              return (
                <StyledLi key={month} isChecked={isChecked}>
                  <CheckBox
                    children={
                      <StyledLabel>
                        {sharedState.translate(
                          LanguageTexts.globalPropertyName(months[i])
                        )}
                        {month !== "all" && " (" + month + ")"}
                      </StyledLabel>
                    }
                    isChecked={isChecked}
                    disabled={!isMonthSelectable}
                    onClick={() => {
                      if (month === "all") {
                        dispatch(
                          Action.setOperationTime({
                            ...operationTime,
                            months: isChecked ? noMonthsYear : allMonthsYear
                          })
                        );
                      } else {
                        dispatch(
                          Action.setOperationTime({
                            ...operationTime,
                            months: {
                              ...operationTime.months,
                              [month]: !isChecked
                            }
                          })
                        );
                      }
                    }}
                  />
                </StyledLi>
              );
            })}
          </RowSection>
        </Pop>
      )}
    </>
  );
}

function getTitleForSelectBox(
  valuesForMonths: MutableValues,
  sharedState: SharedState.State
) {
  let title: string = "";

  if (valuesForMonths["all"]) {
    title = sharedState.translate(LanguageTexts.globalPropertyName("allyear"));
  } else {
    const selectedMonths = getSelectedMonths(valuesForMonths);

    if (selectedMonths.length === 1) {
      title = `${sharedState.translate(
        LanguageTexts.globalPropertyName(months[selectedMonths[0]])
      )}`;
    } else if (selectedMonths.length > 0) {
      if (isAscending(selectedMonths)) {
        const firstMonthIndex = selectedMonths[0];
        const lastMonthIndex = selectedMonths[selectedMonths.length - 1];
        title = `${sharedState.translate(
          LanguageTexts.globalPropertyName(months[firstMonthIndex])
        )} - ${sharedState.translate(
          LanguageTexts.globalPropertyName(months[lastMonthIndex])
        )}`;
      } else {
        title = `${selectedMonths.length} ${sharedState.translate(
          LanguageTexts.months()
        )}`;
      }
    } else {
      title = sharedState.translate(LanguageTexts.chooseMonths());
    }
  }
  return title;
}

function getSelectedMonths(valuesForMonths: MutableValues) {
  const selectedMonths: Array<number> = [];
  for (const month of monthRange) {
    if (valuesForMonths[month]) {
      selectedMonths.push(month);
    }
  }
  return selectedMonths;
}

function isAscending(arr: ReadonlyArray<number>) {
  return arr.every(function (x, i) {
    return i === 0 || x === arr[i - 1] + 1;
  });
}
