import * as React from "react";
import styled from "styled-components";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import { PropertyValueSet } from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as DataCenterSizeSelector from "../../../size-selectors/data-center-size-selector";
import * as DhuSizeSelector from "../../../size-selectors/dhu-size-selector";
import * as HumSizeSelector from "../../../size-selectors/hum-size-selector";
import * as EccSizeSelector from "../../../size-selectors/ecc-size-selector";
import { Component } from "./types";

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  overflow-y: auto;
`;

export function PlenumSizeSelectorView({
  state,
  sharedState,
  properties,
  operatingCases,
  components,
  isSystemLocked,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly components: ReadonlyArray<Component>;
  readonly isSystemLocked: boolean;
}): JSX.Element {
  switch (state.systemTypeId) {
    case "FAA":
    case "FAU":
    case "HUM":
      return (
        <Root>
          <HumSizeSelector.HumSizeSelectorView
            state={state.sizeSelectorState as HumSizeSelector.State}
            sharedState={sharedState}
            dispatch={Dispatch.map(
              Action.dispatchSizeSelectorActions,
              dispatch
            )}
            systemProperties={properties}
            operatingCases={operatingCases}
            isSystemLocked={isSystemLocked}
          />
        </Root>
      );
    case "ECC":
      return (
        <Root>
          <EccSizeSelector.EccSizeSelectorView
            state={state.sizeSelectorState as EccSizeSelector.State}
            sharedState={sharedState}
            dispatch={Dispatch.map(
              Action.dispatchSizeSelectorActions,
              dispatch
            )}
            properties={properties}
            operatingCases={operatingCases}
          />
        </Root>
      );
    case "MLP":
    case "MXN":
    case "MXO":
      return (
        <Root>
          <DhuSizeSelector.DhuSizeSelectorView
            state={state.sizeSelectorState as DhuSizeSelector.State}
            sharedState={sharedState}
            dispatch={Dispatch.map(
              Action.dispatchSizeSelectorActions,
              dispatch
            )}
            systemProperties={properties}
            components={components}
            operatingCases={operatingCases}
            isSystemLocked={isSystemLocked}
          />
        </Root>
      );
    case "DEB":
    case "DCX":
    case "DCA": {
      return (
        <Root>
          <DataCenterSizeSelector.DataCenterSizeSelectorView
            state={state.sizeSelectorState as DataCenterSizeSelector.State}
            sharedState={sharedState}
            dispatch={Dispatch.map(
              Action.dispatchSizeSelectorActions,
              dispatch
            )}
            systemProperties={properties}
            operatingCases={operatingCases}
            systemTypeId={state.systemTypeId}
            isSystemLocked={isSystemLocked}
          />
        </Root>
      );
    }
    default:
      return exhaustiveCheck(state.systemTypeId, true);
  }
}
