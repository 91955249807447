import styled from "styled-components";

export const Root = styled.div``;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 20px;
  margin-bottom: 10px;

  span {
    margin-bottom: 8px;
  }
`;

export const FakeSelect = styled.div`
  box-sizing: border-box;
  height: 48px;
  width: 455px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3d3d3d;
    padding-left: 20px;
    display: inline-block;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
`;

export const ErrorMessageContainer = styled.div`
  width: 200px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  button {
    margin-right: 10px;
  }
`;

export const StyledDiv = styled.div`
  position: relative;

  > div {
    position: absolute;
  }
`;
