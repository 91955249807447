import * as React from "react";
import styled from "styled-components";
import { PropertyValueSet } from "@genesys/property";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as Images from "../images";
import { ValueSourceImage /* ValueSourcesImageSelector */ } from "./elements";
import { ValueSourceDropdown } from "./dropdown";

interface ValueSourceSelectorProps {
  readonly onChange: (
    selectedProperties: PropertyValueSet.PropertyValueSet,
    propertyName: string
  ) => void;
  readonly isLocked: boolean;
  readonly showCodes: boolean;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly propertyName: string;
  readonly valueSources: ReadonlyArray<
    GenesysPropertiesSelectorTypes.ValueSource
  >;
}

// tslint:disable-next-line:variable-name
export const ValueSourceImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 24px;
`;

const autoImage = Images.ValueSources.auto;
const userImage = Images.ValueSources.user;

// tslint:disable-next-line:function-name
export function ValueSourceSelector({
  isLocked,
  onChange,
  propertyName,
  selectedProperties,
  showCodes,
  valueSources
}: ValueSourceSelectorProps): JSX.Element {
  if (valueSources.length === 0) {
    return (
      <ValueSourceImageContainer>
        <ValueSourceImage src={userImage} />
        <span> {showCodes && `(null)`}</span>
      </ValueSourceImageContainer>
    );
  }

  if (valueSources.length === 1 || isLocked) {
    const valueSource = valueSources[0];
    const effectiveImage =
      valueSource.propertyValueSourceId === "User" ? userImage : autoImage;
    return (
      <ValueSourceImageContainer>
        <ValueSourceImage src={effectiveImage} />
        <span> {showCodes && `(${valueSource.value})`}</span>
      </ValueSourceImageContainer>
    );
  }
  const options = valueSources.map(v => ({
    label: "",
    imageUrl: v.propertyValueSourceId === "User" ? userImage : autoImage,
    isItemValid: true,
    value: v.value.toString()
  }));

  const sourceInt = PropertyValueSet.getInteger(
    `source_${propertyName}`,
    selectedProperties
  );
  const value =
    (sourceInt && sourceInt.toString()) || valueSources[0].value.toString();

  return (
    <ValueSourceDropdown
      onChange={(newValue: string) => {
        onChange(
          PropertyValueSet.setInteger(
            `source_${propertyName}`,
            parseInt(newValue, 10),
            selectedProperties
          ),
          `source_${propertyName}`
        );
      }}
      options={options}
      selected={value}
      showCodes={showCodes}
    />
  );
}
