import styled from "styled-components";

const crossColor = "rgb(102, 102, 102)";

// tslint:disable-next-line
export const ManagerSearchFilterCloseButton = styled.span`
  display: block;
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;

  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  opacity: 0.8;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: ${crossColor};
  }

  &:before {
    left: 55%;
    width: 20%;
    margin-left: -15%;
    height: 100%;
  }

  &:after {
    top: 55%;
    height: 20%;
    margin-top: -15%;
    width: 100%;
  }

  &:hover {
    opacity: 1;
  }
`;
