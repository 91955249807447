import React, { useState } from "react";
import { ErrorType } from "./input";
import styled from "../../styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// tslint:disable-next-line
const infoSvg = require("ui-elements/assets/alert-accordion/info.svg");
// tslint:disable-next-line
const arrowDown = require("ui-elements/assets/alert-accordion/arrow-down.svg");
// tslint:disable-next-line
const arrowUp = require("ui-elements/assets/alert-accordion/arrow-up.svg");

const Container = styled.div<{
  readonly warningType: Type;
}>`
  background-color: ${props =>
    props.warningType === "warning" ? "#f0c530" : "#E8767B"};
  padding-left: 5px;
  padding-bottom: 4px;
  padding-top: 4px;
  max-height: 80px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 14fr 1fr;
  row-gap: 5px;
  cursor: pointer;
`;

const ImgInfo = styled.img`
  height: 15px;
  width: 15px;
`;

const WarningTitle = styled.span`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
`;

const Arrow = styled.img`
  height: 5px;
  width: 10px;
`;

const BodyContainer = styled.div`
  grid-column: 2;
  align-self: start;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  padding-bottom: 5px;
  overflow-y: auto;
  max-height: 50px;

  ::-webkit-scrollbar {
    width: 3px;

    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #023442;

    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

type Type = Exclude<ErrorType, "none">;

export function WarningBox(
  props: {
    readonly warningType: Type;
    readonly title: string;
    readonly messageContent: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
) {
  const { messageContent, title, warningType } = props;
  const [state, setState] = useState({
    isOpen: false,
    imgSrc: arrowDown
  });

  return (
    <ClickAwayListener
      onClickAway={() => {
        setState({
          ...state,
          isOpen: false
        });
      }}
    >
      <Container
        warningType={warningType}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          setState({
            isOpen: !state.isOpen,
            imgSrc: state.isOpen ? arrowUp : arrowDown
          });
        }}
      >
        <ImgInfo src={infoSvg} />
        <WarningTitle>{title}</WarningTitle>
        <Arrow src={state.imgSrc}></Arrow>
        {state.isOpen && <BodyContainer>{messageContent}</BodyContainer>}
      </Container>
    </ClickAwayListener>
  );
}
