import { State } from "../types";
import { Action } from "../actions";
import { Cmd } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";

export type UpdateReturnType = readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
];

export { State, Action };
