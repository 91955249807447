import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { deleteMCompareComponentMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { promiseCmd } from "../../../promise-effect-manager";
import { Action } from "../state";

export function deleteMCompareComponent(
  sharedState: SharedState.State,
  systemId: string
): [Cmd<Action>, SystemStatus] {
  return [
    promiseCmd<Action, {}>(
      async () => {
        await sharedState.graphQL.queryUser<
          GraphQLTypes.DeleteMCompareComponent,
          GraphQLTypes.DeleteMCompareComponentVariables
        >(deleteMCompareComponentMutation, {
          input: {
            systemId
          }
        });

        return {};
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.ValidateConfigurationSuccess
  ];
}
