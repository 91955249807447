import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import styled from "styled-components";
import {
  HourPickerCard,
  GenesysSelect,
  StandardButton
} from "@genesys/ui-elements";
import { OperationTime, Values, Action } from "./state";
import { MonthSelector } from "./month-selector";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  hourRange,
  PresetKey,
  getPresetKey,
  presets,
  MutableValues,
  allHoursDay,
  noHoursDay,
  binOperationTime,
  months,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
} from "./predefined-presets";
import { Dispatch } from "@typescript-tea/core";

type MutableSelections = {
  // tslint:disable-next-line:readonly-keyword
  [row: string]: Values;
};

interface NonMutableSelections {
  readonly [key: string]: Values;
}

const Container = styled.div`
  width: max-content;
  /* width: 490px; */
  /* height: 765px; */
  padding: 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    display: flex;
    > * {
      margin-right: 6px;
    }
  }

  justify-content: space-between;
`;

const DaysContainer = styled.div`
  overflow-y: auto;
  > div {
    display: inline-block;
    margin: 7px;
    margin-right: 10px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #8f9bb3;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #00adf2;
  }
`;

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

const predfinedPresets = [
  "allDay",
  "officeHours",
  "factoryHours",
  "twoShiftHours"
] as ReadonlyArray<PresetKey>;

export function OperationTimeView({
  operationTime,
  readonly,
  sharedState,
  allowedSelections,
  dispatch,
  onClose,
  customTranslation
}: {
  readonly operationTime: OperationTime;
  readonly readonly: boolean;
  readonly sharedState: SharedState.State;
  readonly allowedSelections?: OperationTime;
  readonly onClose: () => void;
  readonly dispatch: Dispatch<Action>;
  readonly customTranslation?: (arg: LanguageTexts.StaticText) => string;
}) {
  const dailyValues = getDailyValues(operationTime);
  const allowedDailyValues = getDailyValues(
    allowedSelections || presets.allDay
  );
  const translate = customTranslation || sharedState.translate;
  let options: ReadonlyArray<string> = [...predfinedPresets];

  const presetKey = getPresetKey(operationTime);
  if (presetKey === undefined) {
    options = options.concat(["custom"]);
  }

  const filteredPresets = options
    .map(key => {
      return {
        value: key,
        title:
          key === "custom"
            ? "Custom"
            : translate(
                LanguageTexts.globalPropertyName(
                  presetNameFromKey(key as PresetKey)
                )
              )
      };
    })
    .filter(
      x =>
        x.value === "custom" ||
        presetIsSelectable(x.value as PresetKey, allowedSelections)
    );

  return (
    <Container>
      <FlexContainer>
        <div>
          <GenesysSelect
            width={80}
            height={32}
            fontSize={15}
            disabled={readonly}
            options={filteredPresets}
            value={presetKey || "custom"}
            onChange={e => {
              const preset = presets[e.target.value as keyof typeof presets] as
                | OperationTime
                | undefined;
              if (preset) {
                dispatch(Action.setOperationTime(preset));
              }
            }}
          />
          <MonthSelector
            disabled={readonly}
            dispatch={dispatch}
            operationTime={operationTime}
            sharedState={sharedState}
            allowedSelections={allowedSelections}
          />
        </div>

        <StandardButton onClick={onClose} size="Small" buttonType={"Primary-2"}>
          {translate(LanguageTexts.close())}
        </StandardButton>
      </FlexContainer>

      <DaysContainer>
        {Object.keys(dailyValues).map(value => {
          const valueForDay = dailyValues[value as keyof NonMutableSelections];
          const selectableValueForday =
            allowedDailyValues[value as keyof NonMutableSelections];

          return (
            <div key={value}>
              <HourPickerCard
                hours={convertToHourPickerCardFormat(
                  valueForDay,
                  selectableValueForday,
                  value,
                  operationTime,
                  readonly,
                  dispatch,
                  translate
                )}
                title={sharedState.translate(
                  LanguageTexts.globalPropertyName(value.substring(0, 3))
                )}
              />
            </div>
          );
        })}
      </DaysContainer>
    </Container>
  );
}

export function allRow(otherDaysValues: Values) {
  return Object.keys(otherDaysValues).reduce(
    (soFar, currentRowIndex) => soFar && otherDaysValues[currentRowIndex],
    true
  );
}

export function presetName(operationTime: OperationTime) {
  const key = getPresetKey(operationTime);
  return key ? presetNameFromKey(key) : "custom";
}

export function toPropertyValueSet(
  operationTime: OperationTime
): PropertyValueSet.PropertyValueSet {
  return {
    [binOperationTime]: PropertyValue.fromText(presetName(operationTime)),
    [months]: PropertyValue.fromText(checksToString(operationTime.months)),
    [monday]: PropertyValue.fromText(checksToString(operationTime.monday)),
    [tuesday]: PropertyValue.fromText(checksToString(operationTime.tuesday)),
    [wednesday]: PropertyValue.fromText(
      checksToString(operationTime.wednesday)
    ),
    [thursday]: PropertyValue.fromText(checksToString(operationTime.thursday)),
    [friday]: PropertyValue.fromText(checksToString(operationTime.friday)),
    [saturday]: PropertyValue.fromText(checksToString(operationTime.saturday)),
    [sunday]: PropertyValue.fromText(checksToString(operationTime.sunday))
  };

  function checksToString(checks: Values): string {
    return Object.keys(checks)
      .map(numberString => parseInt(numberString, 10))
      .sort((a, b) => a - b)
      .map(index => checks[index])
      .map(b => (b ? 1 : 0))
      .join("");
  }
}

function extractValuesFromOpTime(opTime: OperationTime) {
  return Object.keys(opTime).map(x => {
    return Object.values(opTime[x as keyof typeof opTime]);
  });
}

function presetIsSelectable(
  presetKey: PresetKey,
  allowedSelections?: OperationTime
): boolean {
  if (!allowedSelections) {
    return true;
  }

  const preset = presets[presetKey];
  const valuesFromPreset = extractValuesFromOpTime(preset);
  const valuesFromAllowedPresets = extractValuesFromOpTime(allowedSelections);

  return isSubset(valuesFromAllowedPresets, valuesFromPreset);
}

function isSubset(set1: boolean[][], set2: boolean[][]): boolean {
  for (let i = 0; i < set2.length; i++) {
    for (let j = 0; j < set2[i].length; j++) {
      if (set2[i][j] && !set1[i][j]) {
        return false;
      }
    }
  }
  return true;
}

function getDailyValues(operationTime: OperationTime): NonMutableSelections {
  const allColumn = hourRange.reduce((soFar, currentRow) => {
    soFar[currentRow] = days.every(
      dayName => operationTime[dayName as keyof OperationTime][currentRow]
    );
    return soFar;
  }, {} as MutableValues);

  return {
    all: { ...allColumn, all: allRow(allColumn) },
    ...days.reduce((soFar, currentDayName) => {
      const valueForDay = operationTime[currentDayName as keyof OperationTime];
      soFar[currentDayName] = {
        ...valueForDay,
        all: allRow(valueForDay)
      };
      return soFar;
    }, {} as MutableSelections)
  };
}

function presetNameFromKey(key: PresetKey) {
  const map: { readonly [k in PresetKey]: string } = {
    noHours: "nohours",
    allDay: "allday",
    officeHours: "office",
    factoryHours: "factory",
    twoShiftHours: "twoshift"
  };

  return map[key];
}

function convertToHourPickerCardFormat(
  valueForDay: Values,
  selectableValueForday: Values,
  day: string,
  operationTime: OperationTime,
  disabled: boolean,
  dispatch: Dispatch<Action>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
) {
  return ["all", ...hourRange].map(hour => {
    const isChecked = valueForDay[hour];
    const canBeSelected = disabled || !selectableValueForday[hour];
    return {
      time:
        hour === "all"
          ? translate(LanguageTexts.globalPropertyName("all"))
          : hour.toString(),
      isChecked,
      disabled: canBeSelected,
      onClick: () => {
        if (day === "all") {
          if (hour === "all") {
            dispatch(
              Action.setOperationTime(
                isChecked ? presets.noHours : presets.allDay
              )
            );
          } else {
            dispatch(
              Action.setOperationTime({
                ...operationTime,
                ...days.reduce((soFar, currentDayName) => {
                  const valueForDay =
                    operationTime[currentDayName as keyof OperationTime];
                  soFar[currentDayName] = {
                    ...valueForDay,
                    [hour]: !isChecked
                  };

                  return soFar;
                }, {} as MutableSelections)
              })
            );
          }
        } else if (hour === "all") {
          dispatch(
            Action.setOperationTime({
              ...operationTime,
              [day]: isChecked ? noHoursDay : allHoursDay
            })
          );
        } else {
          dispatch(
            Action.setOperationTime({
              ...operationTime,
              [day]: {
                ...operationTime[day as keyof OperationTime],
                [hour]: !isChecked
              }
            })
          );
        }
      }
    };
  });
}
