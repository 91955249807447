import * as Uuid from "uuid";
import { home } from "./home";

export type NatsCmd<A> = Rpc<A, unknown, unknown> | Publish<unknown>;

export type Rpc<A, TRequest, TResponse> = {
  readonly home: typeof home;
  readonly type: "Rpc";
  readonly request: TRequest;
  readonly requestEncoder: (msg: TRequest) => Uint8Array;
  readonly responseDecoder: (buf: Uint8Array) => TResponse;
  readonly headers: Record<string, string>;
  readonly subject: string;
  readonly gotResponse: (response: TResponse, correlationId: string) => A;
  readonly correlationId: string;
};

// Only internal, application will use specific constructor functions
export function rpc<A, TRequest, TReponse>(
  request: TRequest,
  requestEncoder: (msg: TRequest) => Uint8Array,
  responseDecoder: (buf: Uint8Array) => TReponse,
  headers: Record<string, string>,
  subject: string,
  gotResponse: (response: TReponse, correlationId: string) => A,
  correlationId?: string
): Rpc<A, TRequest, TReponse> {
  return {
    home,
    type: "Rpc",
    request,
    requestEncoder,
    responseDecoder,
    headers,
    subject,
    gotResponse,
    correlationId: correlationId ?? Uuid.v4()
  };
}

export type Publish<TCommand> = {
  readonly home: typeof home;
  readonly type: "Publish";
  readonly msg: TCommand;
  readonly msgEncoder: (msg: TCommand) => Uint8Array;
  readonly headers: Record<string, string>;
  readonly subject: string;
};

// Only internal, application will use specific constructor functions
export function publish<TMsg>(
  msg: TMsg,
  msgEncoder: (msg: TMsg) => Uint8Array,
  headers: Record<string, string>,
  subject: string
): Publish<TMsg> {
  return {
    home,
    type: "Publish",
    msg,
    msgEncoder,
    headers,
    subject
  };
}
