import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchResultsTable = styled.table`
  width: 100%;

  th {
    font-weight: bold;
    text-align: left;
    padding: 5px 15px 15px 5px;
    vertical-align: middle;

    a {
      font-weight: bold;
      color: #000;
    }

    a:link {
      font-weight: bold;
      color: #000;
    }

    a:visited {
      font-weight: bold;
      color: #000;
    }

    a:hover {
      font-weight: bold;
      text-decoration: underline;
      color: #000;
    }

    a:active {
      font-weight: bold;
      color: #000;
    }
  }

  th:first-child {
  }

  tr {
    border-bottom: 1px solid ${Colors.searchFilterBorderColor};
  }
  td {
    padding: 5px;
    white-space: nowrap;
  }
  td:last-child {
    width: 100%;
  }
`;
