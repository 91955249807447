import * as React from "react";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PricingActions from "../pricing-actions";
import { State, Action, OkState } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { PricingRow, PricingRowType } from "./types";
import {
  parseTransferPriceFactor,
  parseListPriceFactor,
  parseCostPricePerUnit
} from "./parsing";
import {
  Root,
  genesysSelectStylingProps,
  InputContainer,
  ResultsContainer,
  SaveAndLockContainer,
  BottomInnerContainer,
  BodyContainer,
  TopContainer,
  MiddleInnerContainer,
  ResultTable,
  NotesContainer,
  PricingIdContainer,
  FlexContainer,
  PricingActionsContainer,
  PricingInfoContainer,
  ErrorContainer,
  Error
} from "./elements";
import {
  TextArea,
  StandardButton,
  OverlayLoader,
  P1,
  S2,
  ToolTipWrapper,
  MenuDots,
  Confirm,
  GenesysSelect
} from "@genesys/ui-elements";
import { PricingTable } from "./components/pricing-table/pricing-table";
import { NewRowSelection } from "./components/new-row-selection/new-row-selection";
import { ReportsComponent } from "./components/reports-component";
import { ModalComponent } from "./components/modal-component";
import { exhaustiveCheck } from "ts-exhaustive-check";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export function PricingEditorView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  const canSeePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );

  if (!canUsePricing && !canSeePricing) {
    return <Root>Missing Claims</Root>;
  }

  if (state.type === "error") {
    return (
      <ErrorContainer>
        <Error>{state.errorMessage}</Error>
      </ErrorContainer>
    );
  }

  if (!state.pricing || !state.productQueryResult || !state.newRowState) {
    return <OverlayLoader />;
  }

  const pricing = state.pricing;

  const pricingRows: Array<PricingRow> = pricing.rows
    .map(r => ({
      id: r.id,
      rowNo: r.rowNo,
      articleNo: r.articleNo,
      description: r.description,
      pid: r.pid || "",
      quantity: r.quantity,
      unit: r.unit,
      costPerUnit: r.costPerUnit
        ? parseCostPricePerUnit(
            r.costPerUnit,
            r.type as PricingRowType,
            r.systemTypeId
          )!
        : 0,
      listPricePerUnit: r.listPricePerUnit || 0,
      transerPricePerunit:
        (r.type === "system" && pricing.masterMode
          ? r.masterPricePerUnit
          : r.transferPricePerUnit) || 0,
      listPriceFactor:
        parseListPriceFactor(
          pricing.masterMode,
          r.transferPricePerUnit || 0,
          r.listPricePerUnit || 0,
          r.type === "system" && pricing.masterMode
            ? 0
            : r.listPricePerUnit || 0
        ) || 0,
      transferPriceFactor:
        parseTransferPriceFactor(
          pricing.masterMode,
          r.costPerUnit || 0,
          r.transferPricePerUnit || 0,
          r.type === "system" && pricing.masterMode
            ? r.masterPricePerUnit || 0
            : r.transferPricePerUnit || 0,
          r.type,
          r.systemTypeId
        ) || 0,
      type: r.type,
      visualizerCode: r.visualizerCode
    }))
    .concat([])
    .sort((a, b) => a.rowNo - b.rowNo);

  const isLocked = state.pricing.status >= GraphQLTypes.PricingStatus.LOCKED;

  const currencyCodes = sharedState.user.currencies.map(c => c.id);

  const totalTransferPrice = pricingRows.reduce(
    (a, b) => a + b.quantity * Math.round(b.transerPricePerunit || 0),
    0
  );

  const totalListPrice = pricingRows.reduce(
    (a, b) => a + b.quantity * Math.round(b.listPricePerUnit || 0),
    0
  );

  const ref = React.useRef<HTMLElement>(null);

  return (
    <Root>
      {state.modal && getModal(state, sharedState, dispatch)}

      {!isLocked && (
        <TopContainer>
          <FlexContainer>
            <S2 weight="bold" color="dark">
              {sharedState.genesysPrefix.pricingNo(
                state.pricing.pricingNo,
                state.pricing.revisionNo
              )}
            </S2>
          </FlexContainer>

          <NewRowSelection
            sharedState={sharedState}
            newRowState={state.newRowState}
            pricingArticles={state.pricingArticles}
            pricingGroups={state.pricingGroups}
            SystemPricingSearch={state.SystemPricingSearch}
            pricingRows={state.pricing.rows}
            inputRowNo={state.inputRowNo}
            genesysNoPrefix={
              state.productQueryResult.product.genesysNumberPrefixes.genesysNo
            }
            onRowNoChange={rowNo => dispatch(Action.changeInputRowNo(rowNo))}
            onNewRowTypeChange={addRowType =>
              dispatch(Action.changeNewRowType(addRowType))
            }
            onNewRowStateChange={newRowState =>
              dispatch(Action.updateNewRowState(newRowState))
            }
            onAddRows={rows => dispatch(Action.addRows(rows))}
            onSystemSearch={() => dispatch(Action.systemSearch())}
            onAddSystemRow={(systemId, visualizerCode) => {
              dispatch(Action.validateSystem(systemId, visualizerCode));
            }}
          />
        </TopContainer>
      )}

      <>
        <Confirm
          cancelTranslated={sharedState.translate(
            LanguageTexts.openInCurrentTab()
          )}
          confirmTranslated={sharedState.translate(
            LanguageTexts.openInNewTab()
          )}
          headerText={
            state.pricingActionIsCompleteUrl?.type === "copy-of-latest-revision"
              ? "Copy of latest revision done, open in new tab?"
              : "New revison created, open in new tab?"
          }
          labelRef={ref}
          showDialog={!!state.pricingActionIsCompleteUrl}
          onConfirm={() => {
            dispatch(
              Action.openPricing(state.pricingActionIsCompleteUrl!.url, true)
            );
          }}
          onCancel={() => {
            dispatch(
              Action.openPricing(state.pricingActionIsCompleteUrl!.url, false)
            );
          }}
        />
      </>

      <BodyContainer>
        <div ref={ref as any}></div>

        <a style={{ cursor: "pointer" }}>
          <ToolTipWrapper
            onClick={() => {
              dispatch(Action.toggleOpenPricingActions(pricing.id));
            }}
            title={sharedState.translate(LanguageTexts.actions())}
            placement="top"
          >
            <MenuDots height="20px" />
          </ToolTipWrapper>
        </a>
        <span>
          {state.pricingActionsState && (
            <ClickAwayListener
              onClickAway={() =>
                dispatch(Action.toggleOpenPricingActions(pricing.id))
              }
            >
              <PricingActionsContainer>
                <PricingActions.PricingActionsView
                  state={state.pricingActionsState}
                  sharedState={sharedState}
                  disableDelete
                  disableLabels
                  dispatch={Dispatch.map(
                    Action.dispatchPricingActions,
                    dispatch
                  )}
                  pricingFile={{
                    id: pricing.pricingFileId,
                    labels: pricing.labels,
                    name: pricing.pricingFileName,
                    owner: {
                      id: pricing.pricingFileOwnerId,
                      userName: pricing.pricingFileOwnerUsername
                    },
                    latestPricing: {
                      id: pricing.id,
                      name: pricing.name,
                      revisionNo: pricing.revisionNo,
                      status: pricing.status
                    }
                  }}
                />
              </PricingActionsContainer>
            </ClickAwayListener>
          )}
        </span>

        <PricingTable
          sharedState={sharedState}
          currencyCode={state.pricing.currencyCode}
          pricingRows={pricingRows}
          genesysNoPrefix={
            state.productQueryResult.product.genesysNumberPrefixes.genesysNo
          }
          locked={state.pricing.status >= GraphQLTypes.PricingStatus.LOCKED}
          editCell={state.editCell}
          onEditBegin={editCell => dispatch(Action.setEditCell(editCell))}
          onEditEnd={() => dispatch(Action.endEdit())}
          onCellChange={value => dispatch(Action.updateEditCell(value))}
          onDelete={rowId =>
            dispatch(Action.setModal({ type: "delete", rowId: rowId }))
          }
        />
        <MiddleInnerContainer>
          <NotesContainer>
            <S2 weight="bold" color="dark">
              {sharedState.translate(LanguageTexts.notes())}
            </S2>
            <TextArea
              disabled={isLocked}
              value={state.pricing.comment}
              onChange={e => dispatch(Action.setComment(e.target.value))}
              cols={68}
              rows={8}
            ></TextArea>
          </NotesContainer>

          <ResultsContainer>
            <ResultTable>
              <tbody style={{}}>
                <tr>
                  <td>
                    <S2 weight="bold" color="dark">
                      {sharedState.translate(
                        LanguageTexts.totalTransferPrice()
                      )}
                    </S2>
                  </td>
                  <td>
                    <S2 weight="normal" color="dark">
                      {totalTransferPrice
                        .toFixed(0)
                        .split("")
                        .reduce(
                          (a, b, ix) =>
                            a +
                            (a !== "" &&
                            (totalTransferPrice.toFixed(0).length - ix) % 3 ===
                              0
                              ? " "
                              : "") +
                            b,
                          ""
                        )}{" "}
                      {state.pricing.currencyCode}
                    </S2>
                  </td>
                </tr>

                {Authorization.checkPermission(
                  sharedState.user.applicationClaims,
                  Authorization.genesysPricingClaims.CanSeePricingListPrice
                ) && (
                  <tr>
                    <td>
                      <S2 weight="bold" color="dark">
                        {sharedState.translate(LanguageTexts.totalListPrice())}
                      </S2>
                    </td>
                    <td>
                      <S2 weight="normal" color="dark">
                        {totalListPrice
                          .toFixed(0)
                          .split("")
                          .reduce(
                            (a, b, ix) =>
                              a +
                              (a !== "" &&
                              (totalListPrice.toFixed(0).length - ix) % 3 === 0
                                ? " "
                                : "") +
                              b,
                            ""
                          )}{" "}
                        {state.pricing.currencyCode}
                      </S2>
                    </td>
                  </tr>
                )}
              </tbody>
            </ResultTable>

            <div>
              <InputContainer>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.currency())}
                </S2>
                <GenesysSelect
                  {...genesysSelectStylingProps}
                  onChange={e => dispatch(Action.setCurrency(e.target.value))}
                  disabled={isLocked}
                  value={state.pricing.currencyCode}
                  options={currencyCodes.map(c => ({ title: c, value: c }))}
                />
              </InputContainer>
              <InputContainer>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.salesorg())}
                </S2>
                <GenesysSelect
                  {...genesysSelectStylingProps}
                  onChange={e => dispatch(Action.setMasterMode(!!e))}
                  disabled={
                    isLocked ||
                    state.pricing.salesOrganisationId ===
                      state.pricing.masterSalesOrganisationId ||
                    !Authorization.checkPermission(
                      sharedState.user.applicationClaims,
                      "CanSeeMasterTransferPrice"
                    )
                  }
                  value={state.pricing.masterMode ? 1 : 0}
                  options={[
                    {
                      value: 0,
                      title: state.pricing.salesOrganisationId || ""
                    },
                    {
                      value: 1,
                      title: state.pricing.masterSalesOrganisationId || ""
                    }
                  ]}
                />
              </InputContainer>
            </div>
            <PricingIdContainer>
              <label>
                {sharedState.genesysPrefix.pricingNo(
                  state.pricing.pricingNo,
                  state.pricing.revisionNo
                )}
              </label>
            </PricingIdContainer>
            <PricingInfoContainer>
              <label> {pricing.pricingFileName} </label>
            </PricingInfoContainer>
          </ResultsContainer>
        </MiddleInnerContainer>
        <BottomInnerContainer>
          {!isLocked && (
            <SaveAndLockContainer>
              <StandardButton
                buttonType="Primary"
                size="Small"
                onClick={() => dispatch(Action.savePricing())}
              >
                {sharedState.translate(LanguageTexts.save())}
              </StandardButton>
              <StandardButton
                buttonType="Primary"
                size="Small"
                onClick={() => dispatch(Action.setModal({ type: "lock" }))}
              >
                {sharedState.translate(LanguageTexts.lockPricing())}
              </StandardButton>
            </SaveAndLockContainer>
          )}

          <ReportsComponent
            sharedState={sharedState}
            reportSettings={state.reportSettings}
            unsavedContent={state.unsavedContent}
            languageIds={state.productQueryResult.product.languages.map(
              l => l.id
            )}
            pricing={state.pricing}
            pricingNoPrefix={
              state.productQueryResult.product.genesysNumberPrefixes.pricingNo
            }
            onReportSettingsChange={reportSettings =>
              dispatch(Action.setReportSettings(reportSettings))
            }
            setLoader={showLoader => dispatch(Action.setLoader(showLoader))}
          />
        </BottomInnerContainer>
      </BodyContainer>

      {state.showLoader && <OverlayLoader />}
    </Root>
  );
}

function getModal(
  state: OkState,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
) {
  if (!state.modal) {
    return undefined;
  }

  const type = state.modal.type;

  switch (type) {
    case "error": {
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.error())}
          content={
            <P1 color="dark" weight="normal">
              {sharedState.translate(
                LanguageTexts.errorMessage(state.modal.message)
              )}
            </P1>
          }
          onClose={() => dispatch(Action.setModal(undefined))}
        />
      );
    }
    case "delete": {
      if (!state.pricing) {
        return undefined;
      }

      const rowId = state.modal.rowId;
      const isLastSystem =
        state.pricing.rows.filter(r => r.type === "system").length === 1 &&
        state.pricing.rows.find(r => r.id === rowId)!.type === "system";
      const header = isLastSystem
        ? sharedState.translate(LanguageTexts.deleteAllRows())
        : sharedState.translate(LanguageTexts.deleteRow());
      const confirmMessage = isLastSystem
        ? sharedState.translate(LanguageTexts.deleteAllRowsConfirm())
        : sharedState.translate(LanguageTexts.deleteRowConfirm());

      return (
        <ModalComponent
          sharedState={sharedState}
          header={header}
          content={
            <P1 color="dark" weight="normal">
              {confirmMessage}
            </P1>
          }
          onClose={() => dispatch(Action.setModal(undefined))}
          onConfirm={() => {
            dispatch(Action.setModal(undefined));
            dispatch(Action.deleteRow(rowId, isLastSystem));
          }}
        />
      );
    }
    case "lock": {
      return (
        <ModalComponent
          sharedState={sharedState}
          header={sharedState.translate(LanguageTexts.lockPricing())}
          content={
            <P1 color="dark" weight="normal">
              {sharedState.translate(LanguageTexts.lockingPricingInfo())}
            </P1>
          }
          onClose={() => dispatch(Action.setModal(undefined))}
          onConfirm={() => {
            dispatch(Action.setModal(undefined));
            dispatch(Action.lockPricing());
          }}
        />
      );
    }
    default:
      return exhaustiveCheck(type);
  }
}

// tslint:disable-next-line: max-file-line-count
