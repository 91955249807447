import { Cmd } from "@typescript-tea/core";
import { Logger } from "../types";
import { diffLogger } from "./diff-logger";

/**
 * Represents one call to update()
 */
export interface UpdateEntry<A, S> {
  readonly action: A;
  readonly prevState: S | undefined;
  readonly nextState: S | undefined;
  readonly cmd: Cmd<A> | undefined;
  readonly error: Error | undefined;
  readonly started: number;
  readonly startedTime: Date;
  readonly took: number;
}

const colors = {
  title: "inherit",
  prevState: "#9E9E9E",
  action: "#03A9F4",
  actionUnwrapped: "#03A9F4",
  actionRaw: "#03A9F4",
  nextState: "#4CAF50",
  error: "#F20404",
  cmds: "#DB7093"
};

export function printBuffer<A, S>(
  buffer: readonly UpdateEntry<A, S>[],
  // managers: { [home: string]: EffectManager },
  logger: Logger,
  showDiff: boolean
): void {
  // const logger = console.group();

  buffer.forEach((logEntry, key) => {
    const { started, startedTime, action, prevState, error, cmd } = logEntry;
    let { took, nextState } = logEntry;
    const nextEntry = buffer[key + 1];

    if (nextEntry && nextEntry.started && started) {
      nextState = nextEntry.prevState;
      took = nextEntry.started - started;
    }

    // Format title
    const formattedTime = formatTime(startedTime);
    const actionPath = getActionPath(action);
    let formattedActionPath = actionPath
      .slice(1)
      .map(p => p.type || "LOG ERROR: Action did not have a type property.")
      .join(" < ");
    const actionType = actionPath[0].type;
    if (formattedActionPath.length > 0) {
      formattedActionPath = " < " + formattedActionPath;
    }

    // Render group with title
    try {
      logger.groupCollapsed(
        `%cupdate %c${actionType}%c${formattedActionPath} %c@ ${formattedTime} %c(in ${took.toFixed(
          2
        )} ms)`,
        "color: gray; font-weight: lighter;",
        `color: ${colors.title};`,
        "color: gray; font-weight: lighter;",
        "color: gray; font-weight: lighter;",
        "color: gray; font-weight: lighter;"
      );
    } catch (e) {
      logger.log("update");
    }

    // prev state
    logger.log(
      "%cprev state",
      `color: ${colors.prevState}; font-weight: bold`,
      prevState
    );

    // action
    logger.log(
      "%caction unwrapped  ",
      `color: ${colors.actionUnwrapped}; font-weight: bold`,
      // getUnwrappedAction(action)
      actionPath[0]
    );
    logger.log(
      "%caction raw        ",
      `color: ${colors.actionRaw}; font-weight: bold`,
      action
    );

    // error
    if (error) {
      logger.log(
        "%cerror     ",
        `color: ${colors.error}; font-weight: bold;`,
        error
      );
    }

    // next state
    logger.log(
      "%cnext state",
      `color: ${colors.nextState}; font-weight: bold`,
      nextState
    );

    // cmd
    if (cmd) {
      logger.log(
        `%ccmd`,
        `color: ${colors.cmds}; font-weight: bold`,
        "color: gray; font-weight: lighter;",
        cmd
      );
      // const gatheredEffects = gatherCommandsPerManager(cmd, managers);
      // if (Object.keys(gatheredEffects).length > 0) {
      //   for (const [home, effects] of Object.entries(gatheredEffects)) {
      //     logger.log(
      //       `%ccmds %c${home}`,
      //       `color: ${colors.cmds}; font-weight: bold`,
      //       "color: gray; font-weight: lighter;",
      //       effects.cmds
      //     );
      //   }
      // }
    }

    // diff
    if (showDiff) {
      diffLogger(prevState, nextState, logger);
    }

    try {
      logger.groupEnd();
    } catch (e) {
      logger.log("—— log end ——");
    }
  });
}

type ActionWithType = { readonly type: string };
type WrappedActionWithType = ActionWithType & {
  readonly action?: ActionWithType;
};

const repeat = (str: string, times: number): string =>
  new Array(times + 1).join(str);

const pad = (num: number, maxLength: number): string =>
  repeat("0", maxLength - num.toString().length) + num;

function formatTime(time: Date): string {
  return `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2
  )}.${pad(time.getMilliseconds(), 3)}`;
}

function getActionPath(action: unknown): readonly ActionWithType[] {
  const actionWithType = (action as unknown) as ActionWithType;
  let wrapped: WrappedActionWithType | undefined = actionWithType;
  const path = [];
  while (wrapped) {
    path.push(wrapped);
    wrapped = wrapped.action;
  }
  return path.reverse();
}

// function gatherCommandsPerManager(
//   cmd: Cmd<unknown>,
//   managers: { readonly [home: string]: EffectManager }
// ): GatheredEffects<unknown> {
//   const gatheredEffects: GatheredEffects<unknown> = {};
//   gatherEffects(managers, gatheredEffects, true, cmd);
//   return gatheredEffects;
// }
