import * as React from "react";
import { CheckBox, Edit } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { StyledLabel, StyledLi } from "../elements";
import { Label, LabelGroup } from "../types";

export const LabelGroupComp = ({
  group,
  labelEditorChosenValues,
  dispatch
}: {
  readonly group: LabelGroup;
  readonly labelEditorChosenValues: readonly Label[];
  readonly dispatch: Dispatch<Action>;
}) => {
  const [hoveredLabelId, setHoveredLabelId] = React.useState<string | null>(
    null
  );

  return (
    <div key={group.id}>
      <h4>{group.name}</h4>
      {group.labels.map(label => {
        const isChecked = labelEditorChosenValues.some(
          selectedLabel => selectedLabel.id === label.id
        );
        const handleLabelClick = () =>
          dispatch(Action.toggleLabel(label.id, label.name));

        return (
          <StyledLi
            key={label.id}
            isChecked={isChecked}
            onClick={handleLabelClick}
            onMouseEnter={() => setHoveredLabelId(label.id)}
            onMouseLeave={() => setHoveredLabelId(null)}
          >
            <CheckBox
              isChecked={isChecked}
              onClick={handleLabelClick}
              children={
                <StyledLabel>
                  {label.name}
                  {hoveredLabelId === label.id && group.editable && (
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          Action.changeView(
                            "editOrDelete",
                            label.name,
                            label.id
                          )
                        );
                      }}
                    >
                      <Edit />
                    </span>
                  )}
                </StyledLabel>
              }
            />
          </StyledLi>
        );
      })}
    </div>
  );
};
