import * as React from "react";
import * as PropertiesSelector from "../../../../properties-selector";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import {
  PropertyDefinitions,
  PropertyDefinition,
  SystemOverrides,
  definitionIsValid,
  propertyValueAllowed
} from "../../../tools";
import {
  PropertyValueSet,
  PropertyFilter
  // PropertyValue
} from "@genesys/property";
import { Dispatch } from "@typescript-tea/core";
import { Action, Load } from "../state";
import { Header } from "./header";
import { Body } from "./body";
import { Footer } from "./footer";
import { LoadOperationTime } from "../../type";

const Container = styled.div<{
  readonly warningType: WarningType;
}>`
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 10%);
  border: ${props => getBorderColor(props.warningType)};
  width: 399px;
  border-radius: 5px;
  /* display: inline-block; */

  /* margin-right: 10px; */
  /* margin-bottom: 5px; */

  /* display: inline-block; */
  /* align-self: start; */
`;

type WarningType = "warning" | "error" | "none";

export function View({
  sharedState,
  propertiesSelectorState,
  currentSystemOverrides,
  load,
  allPropertyDefinitions,
  isDisabled,
  activeLoadsOperationTime,
  dispatch,
  propertieSelectorDispatch
}: {
  readonly sharedState: SharedState.State;
  readonly load: Load;
  readonly isDisabled: boolean;
  readonly activeLoadsOperationTime: LoadOperationTime;
  readonly allPropertyDefinitions: PropertyDefinitions;
  readonly currentSystemOverrides: SystemOverrides;
  readonly propertiesSelectorState: PropertiesSelector.State;
  readonly dispatch: Dispatch<Action>;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
}) {
  const selectedProperties = PropertiesSelector.getSelectedProperties(
    propertiesSelectorState
  )!;

  const hasError = !load.properties.loadProperties
    .map(pn => allPropertyDefinitions.find(pd => pd.name === pn)!)
    .every(definition => definitionIsValid(definition, selectedProperties));

  const manualInputKey = load.properties.manualInputKey;
  const manualInputEnabled = !!(
    manualInputKey &&
    PropertyValueSet.getInteger(manualInputKey, selectedProperties)
  );

  const messages = [];

  const warningMessage =
    load.warningTextKey &&
    sharedState.translate(
      LanguageTexts.globalPropertyName(load.warningTextKey)
    );

  let warningType: WarningType = "none";
  if (warningMessage) {
    messages.push(warningMessage);
    warningType = "warning";
  }

  if (hasError) {
    warningType = "error";

    messages.unshift(
      ...getErrorMessages(
        load.properties.loadProperties,
        allPropertyDefinitions,
        selectedProperties,
        load,
        sharedState.translate
      )
    );
  }

  return (
    <Container warningType={warningType}>
      <Header
        sharedState={sharedState}
        dispatch={dispatch}
        isDisabled={isDisabled}
        load={load}
        messages={messages}
        warningType={warningType}
        // warningMessage={warningMessage}
      />
      <Body
        allPropertyDefinitions={allPropertyDefinitions}
        currentSystemOverrides={currentSystemOverrides}
        dispatch={dispatch}
        isDisabled={isDisabled}
        load={load}
        manualInputEnabled={manualInputEnabled}
        propertieSelectorDispatch={propertieSelectorDispatch}
        propertiesSelectorState={propertiesSelectorState}
        sharedState={sharedState}
      />
      <Footer
        selectedProperties={selectedProperties}
        dispatch={dispatch}
        isDisabled={isDisabled}
        load={load}
        sharedState={sharedState}
        activeLoadsOperationTime={activeLoadsOperationTime}
      />
    </Container>
  );
}

function getBorderColor(warningType: WarningType) {
  if (warningType === "error") {
    return "2px solid red";
  } else if (warningType === "warning") {
    return "1px solid #F0C530";
  }

  return "1px solid #f1f5fa";
}

function getErrorMessages(
  properties: ReadonlyArray<string>,
  allPropertyDefinitions: PropertyDefinitions,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  load: Load,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
) {
  const invalidProperties: Array<string> = [];

  for (const property of properties) {
    const propertyDef = allPropertyDefinitions.find(
      pd => pd.name === property
    )!;
    if (definitionIsValid(propertyDef, selectedProperties)) {
      continue;
    }
    getErrorReason(
      selectedProperties,
      propertyDef,
      allPropertyDefinitions,
      invalidProperties,
      load,
      translate
    );
    // invalidProperties.push(`property ${propertyDef.name} invalid`);
  }
  return invalidProperties;
}

function getErrorReason(
  selectedProperties: PropertyValueSet.PropertyValueSet,
  definition: PropertyDefinition,
  allPropertyDefinitions: PropertyDefinitions,
  invalidProperties: string[],
  load: Load,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
) {
  const propertyNameTranslated = translate(
    LanguageTexts.globalPropertyName(definition.name)
  );
  if (
    !PropertyFilter.isValid(selectedProperties, definition.validationFilter)
  ) {
    if (definition.name.startsWith("mlcuse")) {
      let errorMessage = builMlcUseError(
        definition,
        allPropertyDefinitions,
        selectedProperties,
        load
      );

      errorMessage += ` PropertyFIlter: (${PropertyFilter.toString(
        definition.validationFilter
      )})`;

      invalidProperties.push(errorMessage);

      return;
    }
    invalidProperties.push(`property ${propertyNameTranslated} invalid`);
  }

  // Check discrete.
  invalidProperties.push(
    `property value ${translate(
      LanguageTexts.globalPropertyName(
        definition.name +
          "_" +
          PropertyValueSet.getInteger(definition.name, selectedProperties)
      )
    )} invalid for ${propertyNameTranslated} `
  );
}

function builMlcUseError(
  definition: PropertyDefinition,
  allPropertyDefinitions: PropertyDefinitions,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  load: Load
): string {
  let message = "";
  // check if booth moistture and heat are added to load
  if (load.moistureAdded && load.heatAdded) {
    message +=
      "Both Moisture and heat loads cannot be used on this load for the current selections.";
    if (
      load.properties.heatProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.heatProperty,
          0,
          selectedProperties
        )
      ) &&
      propertyValueAllowed(
        selectedProperties,
        load.properties.heatProperty,
        0,
        allPropertyDefinitions
      )
    ) {
      message += " Try removing heat.";
    } else if (
      load.properties.moistureProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.moistureProperty,
          0,
          selectedProperties
        )
      ) &&
      propertyValueAllowed(
        selectedProperties,
        load.properties.moistureProperty,
        0,
        allPropertyDefinitions
      )
    ) {
      message += " Try removing moisture.";
    } else {
      message = "This load cannot be active";
    }
    // Check if only moisture is added
  } else if (load.moistureAdded) {
    message = `Moisture cannot be used on this load for the current selections.`;
    if (
      load.properties.heatProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.heatProperty,
          1,
          selectedProperties
        )
      ) &&
      propertyValueAllowed(
        selectedProperties,
        load.properties.heatProperty,
        1,
        allPropertyDefinitions
      )
    ) {
      message += " Try adding heat aswell.";
      return message;
    }
    const moistureDeselected = PropertyValueSet.setInteger(
      load.properties.moistureProperty!,
      0,
      selectedProperties
    );
    if (
      load.properties.heatProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.heatProperty,
          1,
          moistureDeselected
        )
      ) &&
      propertyValueAllowed(
        moistureDeselected,
        load.properties.heatProperty,
        1,
        allPropertyDefinitions
      )
    ) {
      message += " Try using only heat instead.";
    } else {
      message = "This load cannot be active.";
    }
    // Check if only heat is added
  } else if (load.heatAdded) {
    message = `Heat cannot be added on this load for the current selections.`;

    if (
      load.properties.moistureProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.moistureProperty,
          1,
          selectedProperties
        )
      ) &&
      propertyValueAllowed(
        selectedProperties,
        load.properties.moistureProperty,
        1,
        allPropertyDefinitions
      )
    ) {
      message += " Try adding moisture aswell.";
      return message;
    }

    const heatDeselected = PropertyValueSet.setInteger(
      load.properties.heatProperty!,
      0,
      selectedProperties
    );

    if (
      load.properties.moistureProperty &&
      definitionIsValid(
        definition,
        PropertyValueSet.setInteger(
          load.properties.moistureProperty,
          1,
          heatDeselected
        )
      ) &&
      propertyValueAllowed(
        heatDeselected,
        load.properties.moistureProperty,
        1,
        allPropertyDefinitions
      )
    ) {
      message += " Try using only moisture.";
    } else {
      message = "This load cannot be active.";
    }
  }

  return message;
}
