import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import * as SharedState from "../shared-state";
import { OverlayLoader, H3, StandardButton } from "@genesys/ui-elements";
import { Pricing, PricingFile } from "./types";
import { MenuItem } from "./components/menu-item";
import { InputAction } from "./components/input-action";
import {
  Root,
  Menu,
  RevisionLockMessageContainer,
  ConfirmDeleteContainer
} from "./elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { LabelManagerView } from "../label-manager/view";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function PricingActionsView({
  state,
  sharedState,
  dispatch,
  pricingFile,
  pricingRevision,
  disableDelete,
  disableLabels
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly pricingFile?: PricingFile;
  readonly pricingRevision?: Pricing;
  readonly disableDelete?: boolean;
  readonly disableLabels?: boolean;
}): JSX.Element {
  if (pricingFile && pricingRevision) {
    return <div>Enter either a pricing File or a pricing revision</div>;
  }

  if (!state) {
    return <OverlayLoader />;
  }

  switch (state.mode) {
    case "menu":
      return (
        <Root>
          <Menu>
            {pricingRevision && (
              <MenuItem
                label={sharedState.translate(LanguageTexts.editComment())}
                onClick={() => {
                  dispatch(Action.setInput(pricingRevision.name));
                  dispatch(Action.setMode("edit-comment"));
                }}
              />
            )}

            {pricingFile && (
              <>
                <MenuItem
                  label={sharedState.translate(LanguageTexts.rename())}
                  onClick={() => {
                    dispatch(Action.setInput(pricingFile.name));
                    dispatch(Action.setMode("rename"));
                  }}
                />

                {!disableLabels && (
                  <MenuItem
                    label={sharedState.translate(LanguageTexts.assignlabels())}
                    onClick={() => {
                      dispatch(Action.initLabelManager(pricingFile.labels));
                      dispatch(Action.setMode("assign-labels"));
                    }}
                  />
                )}

                {!disableDelete && (
                  <MenuItem
                    label={sharedState.translate(LanguageTexts.deleteText())}
                    onClick={() => dispatch(Action.setMode("confirm-delete"))}
                  />
                )}

                <MenuItem
                  label={sharedState.translate(
                    LanguageTexts.createnewrevision()
                  )}
                  onClick={() => {
                    dispatch(Action.setInput(""));
                    dispatch(Action.setMode("create-revison"));
                  }}
                />

                <MenuItem
                  label={sharedState.translate(
                    LanguageTexts.copylatestrevision()
                  )}
                  onClick={() =>
                    dispatch(
                      Action.copyPricingToNewPricingFile(
                        pricingFile.latestPricing.id
                      )
                    )
                  }
                />

                <MenuItem
                  label={sharedState.translate(LanguageTexts.transfertouser())}
                  onClick={() => {
                    dispatch(Action.setInput(pricingFile.owner.userName));
                    dispatch(Action.setMode("transfer"));
                  }}
                />
              </>
            )}
          </Menu>
        </Root>
      );
    case "rename":
      return (
        <Root>
          <InputAction
            sharedState={sharedState}
            header={sharedState.translate(LanguageTexts.rename())}
            inputValue={state.input}
            onChange={e => dispatch(Action.setInput(e.target.value))}
            onCancel={() => dispatch(Action.setMode("menu"))}
            onSave={() =>
              dispatch(
                Action.updatePricingFile({
                  pricingFile: {
                    id: pricingFile!.id,
                    labelIds: pricingFile!.labels.map(l => l.id),
                    owner: pricingFile!.owner.userName,
                    name: state.input
                  }
                })
              )
            }
          />
        </Root>
      );
    case "edit-comment":
      return (
        <Root>
          <InputAction
            sharedState={sharedState}
            header={sharedState.translate(LanguageTexts.editComment())}
            inputValue={state.input}
            onChange={e => dispatch(Action.setInput(e.target.value))}
            onCancel={() => dispatch(Action.setMode("menu"))}
            onSave={() =>
              dispatch(
                Action.updatePricingName(pricingRevision!.id, state.input)
              )
            }
          />
        </Root>
      );
    case "assign-labels":
      if (!state.labelManagerState) {
        return <OverlayLoader />;
      }

      return (
        <Root>
          <LabelManagerView
            labels={sharedState.user.labels}
            sharedState={sharedState}
            state={state.labelManagerState}
            onCancel={() => dispatch(Action.setMode("menu"))}
            onAssign={selectedLabels => {
              dispatch(
                Action.updatePricingFile({
                  pricingFile: {
                    id: pricingFile!.id,
                    labelIds: selectedLabels.map(l => l.id),
                    owner: pricingFile!.owner.userName,
                    name: pricingFile!.name
                  }
                })
              );
            }}
            dispatch={Dispatch.map(Action.dispatchLabelManager, dispatch)}
          />
        </Root>
      );
    case "create-revison":
      return (
        <Root>
          <>
            {pricingFile!.latestPricing.status === "LOCKED" ? (
              <InputAction
                sharedState={sharedState}
                header={sharedState.translate(
                  LanguageTexts.createnewrevision()
                )}
                inputValue={state.input}
                onChange={e => dispatch(Action.setInput(e.target.value))}
                onCancel={() => dispatch(Action.setMode("menu"))}
                onSave={() =>
                  dispatch(
                    Action.createNewPricingRevision(
                      pricingFile!.id,
                      state.input
                    )
                  )
                }
              />
            ) : (
              <RevisionLockMessageContainer>
                <H3 color="dark" weight="normal">
                  {sharedState.translate(
                    LanguageTexts.latestRevNeedsToBeLocked()
                  )}
                </H3>

                <StandardButton
                  buttonType="Secondary-2"
                  size="Small"
                  onClick={() => dispatch(Action.setMode("menu"))}
                >
                  {sharedState.translate(LanguageTexts.ok())}
                </StandardButton>
              </RevisionLockMessageContainer>
            )}
          </>
        </Root>
      );
    case "transfer":
      return (
        <Root>
          <InputAction
            sharedState={sharedState}
            header={sharedState.translate(LanguageTexts.transfertouser())}
            inputValue={state.input}
            onChange={e => dispatch(Action.setInput(e.target.value))}
            onCancel={() => dispatch(Action.setMode("menu"))}
            onSave={() =>
              dispatch(
                Action.updatePricingFile({
                  pricingFile: {
                    id: pricingFile!.id,
                    labelIds: pricingFile!.labels.map(l => l.id),
                    owner: state.input,
                    name: pricingFile!.name
                  }
                })
              )
            }
          />
        </Root>
      );

    case "confirm-delete": {
      return (
        <Root>
          <ConfirmDeleteContainer>
            <H3 color="dark" weight="normal">
              {sharedState.translate(LanguageTexts.confirmdelete())}
            </H3>
            <div>
              <StandardButton
                buttonType="Secondary-2"
                size="Small"
                onClick={() => dispatch(Action.setMode("menu"))}
              >
                {sharedState.translate(LanguageTexts.cancel())}
              </StandardButton>

              <StandardButton
                buttonType="Primary-2"
                size="Small"
                onClick={() => dispatch(Action.deletePricing(pricingFile!.id))}
              >
                {sharedState.translate(LanguageTexts.confirm())}
              </StandardButton>
            </div>
          </ConfirmDeleteContainer>
        </Root>
      );
    }

    case "loading":
      return <OverlayLoader />;

    default:
      return exhaustiveCheck(state.mode, true);
  }
}
