import * as Types from "./types";

const nonZeroTransferAndCostSystemTypes = ["DSP"];
// TODO check if can be merged to single function
export function parseTransferPriceFactor(
  masterMode: boolean,
  costPerunit: number | null | undefined,
  transferPricePerUnit: number | null | undefined,
  masterPricePerUnit: number | null | undefined,
  rowType: Types.PricingRowType,
  systemTypeId: string | undefined | null
): number | undefined {
  if (
    !masterMode &&
    rowType === "system" &&
    !nonZeroTransferAndCostSystemTypes.includes(systemTypeId!.toUpperCase())
  ) {
    return 0;
  }
  return costPerunit !== null && costPerunit !== undefined
    ? costPerunit !== 0
      ? (masterMode ? masterPricePerUnit! : transferPricePerUnit!) / costPerunit
      : 0
    : undefined;
}

// TODO check if can be merged to single function
export function parseListPriceFactor(
  masterMode: boolean,
  transferPricePerunit: number | null | undefined,
  listPricePerUnit: number | null | undefined,
  masterPricePerUnit: number | null | undefined
): number | undefined {
  return transferPricePerunit !== null && transferPricePerunit !== undefined
    ? transferPricePerunit !== 0
      ? (masterMode ? masterPricePerUnit! : listPricePerUnit!) /
        transferPricePerunit
      : 0
    : undefined;
}

export function parseNullableString(
  str: string | null | undefined
): string | undefined {
  return str !== null ? str : undefined;
}

export function parseNullableNumber(
  num: number | null | undefined
): number | undefined {
  return num !== null ? num : undefined;
}

export function parseCostPricePerUnit(
  costPerunit: number | null | undefined,
  rowType: Types.PricingRowType,
  systemTypeId: string | undefined | null
) {
  if (
    rowType === "system" &&
    !nonZeroTransferAndCostSystemTypes.includes(systemTypeId!.toUpperCase())
  ) {
    return 0;
  }

  return parseNullableNumber(costPerunit);
}
