type Immutable<Type> = {
  readonly [Property in keyof Type]: Type[Property];
};
const primaryColors = {
  darkBlue: "#023442",
  lightBlue: "#F1F5FA",
  blue25: "#DDE4F0",
  blue50: "#9AB3D9",
  grey50: "#C5D0E5",
  grey75: "#8F9BB3",
  grey: "#646F86",
  gold: "#F0C530",
  green: "#46D580",
  red: "#E8767B",
  lightRed: "#f3babd",
  orange: "#FFA500",
  // New colors
  muntersBlue: "#00BAFF", // same as blue300
  blue200: "#66D9FB",
  blue300: "#00BAFF",
  blue400: "#2B89ED",
  blue500: "#1D61CF",
  blue600: "#2554A1",
  sand100: "#FAF7F2",
  sand200: "#F6F1E7",
  graphicsLight: "#F5f5f5"
};

// tslint:disable-next-line
export const PrimaryColors: Immutable<typeof primaryColors> = primaryColors;
