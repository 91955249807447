import * as React from "react";
import {
  UsePropertiesSelector,
  OnPropertiesChanged
} from "@promaster-sdk/react-property-selectors";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import {
  PropertyInfo,
  PropertyItem,
  RowRenderer,
  RowJsxElements
} from "../types";
import { PropertyLabel } from "../elements";
import { PropertySelector } from "./property-selector";
import { PropertyDescription } from "./property-description";
import { ValueSourceSelector } from "./value-source-selector";

export function PropertyRow({
  sel,
  language,
  property,
  showCodes,
  fieldGroup,
  properties,
  isReadonly,
  accessToken,
  hidePropertyNames,
  applicationClaims,
  getAmountFormat,
  customRowRenderer,
  translate,
  onPropertiesChanged,
  translatePropertyName,
  translatePropertyValue
}: {
  readonly language: string;
  readonly fieldGroup: string;
  readonly showCodes: boolean;
  readonly isReadonly: boolean;
  readonly accessToken: string;
  readonly property: PropertyInfo;
  readonly hidePropertyNames: boolean;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly applicationClaims: Authorization.ApplicationClaims;
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly onPropertiesChanged: OnPropertiesChanged;
  readonly customRowRenderer?: RowRenderer;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly translate: (textDefinition: LanguageTexts.TextDefinition) => string;
  readonly translatePropertyName: (propertyName: string) => string;
  readonly translatePropertyValue: (
    propertyName: string,
    value: number
  ) => string;
}): JSX.Element {
  const valueSources = property.valueSources.filter(
    vs =>
      PropertyFilterHelpers.isValid(
        vs.propertyFilter,
        PropertyFilter.Empty,
        properties
      ) &&
      Authorization.checkClaimFilter(
        applicationClaims,
        PropertyFilterHelpers.createPropertyFilter(
          vs.claimFilter,
          PropertyFilter.fromString("1=0")!
        )
      )
  );

  const defaultRowRenderer: RowRenderer = (
    property: PropertyInfo,
    jsxElements: RowJsxElements
  ) => {
    const { descriptionJsx, selectorJsx, valueSourceJsx, propertyLabelJsx } =
      jsxElements;

    return (
      <tr key={property.name}>
        {!hidePropertyNames && (
          <>
            <td>{descriptionJsx}</td>
            <td>{propertyLabelJsx}</td>
          </>
        )}
        <td>{selectorJsx}</td>
        <td>{valueSourceJsx}</td>
      </tr>
    );
  };
  const descriptionJsx = (
    <PropertyDescription
      language={language}
      property={property}
      showCodes={showCodes}
      properties={properties}
      translatePropertyName={translatePropertyName}
      translatePropertyValue={translatePropertyValue}
    />
  );

  const propertyLabelJsx = (
    <PropertyLabel isHidden={sel.isPropertyHidden(property)}>
      {sel.getPropertyLabel(property, translatePropertyName(property.name))}
    </PropertyLabel>
  );

  const selectorJsx = (
    <PropertySelector
      sel={sel}
      property={property}
      properties={properties}
      fieldGroup={fieldGroup}
      accessToken={accessToken}
      getAmountFormat={getAmountFormat}
      translate={translate}
    />
  );

  const valueSourceJsx = (
    <ValueSourceSelector
      isLocked={isReadonly}
      showCodes={showCodes}
      properties={properties}
      valueSources={valueSources}
      propertyName={property.name}
      onChange={(selectedProperties, propertyName) => {
        onPropertiesChanged(selectedProperties, [propertyName]);
      }}
    />
  );

  const rowRenderer = customRowRenderer || defaultRowRenderer;

  return rowRenderer(property, {
    descriptionJsx,
    selectorJsx,
    valueSourceJsx,
    propertyLabelJsx
  });
}
