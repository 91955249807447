import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as System from "../../../system";
import * as Tools from "./tools";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { getValue } from "@genesys/shared/lib/product-properties";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import { DetailedView } from "./detailed-view";
import { PropertyValue } from "@genesys/property";
import { Spinner, S1, TableCard } from "@genesys/ui-elements";

const Container = styled.div``;

const SpinnerContainer = styled.div`
  height: 557px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  > span {
    padding-left: 5px;
    margin-right: 3px;
    width: 75px;
    > h4 {
      word-break: break-word;
    }
  }
`;

export function View({
  state,
  sharedState,
  system,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
}) {
  const translate = sharedState.translate;
  const systemTypeId = system.file.systemTypeId;

  if (state === undefined) {
    return (
      <ErrorContainer>
        <Error>{translate(LanguageTexts.noEnergyResultAvailable())}</Error>
      </ErrorContainer>
    );
  }

  if (
    state.userQueryData === undefined ||
    state.productQueryData === undefined
  ) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const ownSystemEnergyParams = Tools.getSystemsOwnEnergyParam(
    state.userQueryData,
    translate
  );

  const ownSystemEnergyCosts = Tools.getSystemsOwnEnergyCost(
    state.userQueryData,
    translate
  );

  const mCompareEnergyParams = Tools.getMcompareEnergyParam(
    state.userQueryData,
    translate
  );

  const mCompareEnergyCosts = Tools.getMcompareEnergyCost(
    state.userQueryData,
    translate
  );

  return (
    <Container>
      <BoxContainer>
        <span>
          <S1 color="primary">
            {translate(LanguageTexts.systemTypeIdText(systemTypeId))}
          </S1>
        </span>
        <div>
          <DisplayResults
            sharedState={sharedState}
            translate={translate}
            dispatch={dispatch}
            energyCosts={ownSystemEnergyCosts}
            energyParams={ownSystemEnergyParams}
          />
        </div>
      </BoxContainer>
      <BoxContainer>
        <span>
          <S1 color="primary">{translate(LanguageTexts.mCompareSystem())}</S1>
        </span>
        <div>
          <DisplayResults
            sharedState={sharedState}
            translate={translate}
            dispatch={dispatch}
            energyCosts={mCompareEnergyCosts}
            energyParams={mCompareEnergyParams}
          />
        </div>
      </BoxContainer>
      <DetailedView
        system={system}
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />
    </Container>
  );
}

function DisplayResults({
  energyParams,
  energyCosts,
  sharedState,
  translate,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly translate: LanguageTexts.Translate;
  readonly energyParams: ReadonlyArray<{
    readonly title: string;
    readonly result: ReadonlyArray<SharedEnergyTools.EnergyParam>;
  }>;
  readonly energyCosts: ReadonlyArray<{
    readonly title: string;
    readonly result: ReadonlyArray<SharedEnergyTools.EnergyCost>;
  }>;
  readonly sharedState: SharedState.State;
}) {
  const amountFormat = sharedState.screenAmounts.getAmountFormat;
  return (
    <>
      {energyParams.map((item, i) => {
        const result = item?.result;
        return (
          result &&
          !!result.length && (
            <TableCard
              key={i}
              rows={createTableFormatForEnergyParam(
                result,
                dispatch,
                translate,
                amountFormat
              )}
              title={translate(LanguageTexts.predictedAnnualConsumption())}
            />
          )
        );
      })}

      {energyCosts.map((item, i) => {
        const result = item?.result;
        return (
          result &&
          !!result.length && (
            <TableCard
              key={i}
              rows={createTableFormatForEnergyCost(result, translate)}
              title={translate(LanguageTexts.predictedAnnualConsumption())}
            />
          )
        );
      })}
    </>
  );
}

function createTableFormatForEnergyParam(
  rows: ReadonlyArray<SharedEnergyTools.EnergyParam>,
  dispatch: Dispatch<Action>,
  translate: LanguageTexts.Translate,
  amountFormat: ScreenAmounts.GetAmountFormat
) {
  return rows.map(row => {
    const fieldName = row.amountField.fieldId.fieldName;
    const fieldGroup = row.amountField.fieldId.fieldGroup;
    const format = amountFormat(
      fieldGroup,
      fieldName,
      row.amountField.amount.unit.quantity
    );
    return {
      title: translate(LanguageTexts.dynamicText("pp_" + row.energyParamName)),
      value: getValue(PropertyValue.fromAmount(row.amountField.amount), format),
      amountFormatSelector: () =>
        getAmountFormatSelector(
          dispatch,
          translate,
          fieldGroup,
          fieldName,
          format
        )
    };
  });
}

function createTableFormatForEnergyCost(
  rows: ReadonlyArray<SharedEnergyTools.EnergyCost>,
  translate: LanguageTexts.Translate
) {
  return rows.map(row => {
    const fieldName = row.name;
    const value = row.cost
      .toFixed(0)
      .split("")
      .reduce(
        (a, b, ix) =>
          a +
          (a !== "" && (row.cost.toFixed(0).length - ix) % 3 === 0 ? " " : "") +
          b,
        ""
      );

    const currency = row.currencySymbol;

    return {
      title: translate(LanguageTexts.dynamicText(fieldName)),
      value: `${value} ${currency}`
    };
  });
}

function getAmountFormatSelector(
  dispatch: Dispatch<Action>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  fieldGroup: string,
  fieldName: string,
  format: ScreenAmounts.AmountFormat
) {
  return (
    <>
      [
      <AmountFormatSelector
        type="AmountFormatSelectorProps"
        amountFormat={format}
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        conversionParameters={undefined}
        translate={translate}
        onFormatChanged={(unit, decimalCount) =>
          dispatch(
            Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
          )
        }
        onFormatCleared={() =>
          dispatch(Action.onFormatCleared(fieldGroup, fieldName))
        }
      />
      ]
    </>
  );
}
