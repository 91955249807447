import { Cmd } from "@typescript-tea/core";
import { Action } from "./state";
import { promiseCmd } from "../promise-effect-manager";
import { TraceSpan, LogRowFromServer, All, Empty } from "./types";

export function categoryKey(serviceName: string, category: string): string {
  return `${serviceName};${category}`;
}

export function createFetchCmd(
  genesysBackend: string,
  accessToken: string,
  traceId: string
): Cmd<Action> {
  return promiseCmd(
    async () => {
      let logRows: ReadonlyArray<LogRowFromServer> = [];
      let traceSpans: ReadonlyArray<TraceSpan> = [];

      try {
        logRows = await fetch(
          `${genesysBackend}/internal/CalculationLogsApi?traceId=${traceId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then(d => d.json());
      } catch (e) {
        console.log(e);
      }

      try {
        traceSpans = await fetch(
          `${genesysBackend}/internal/TraceApi?traceId=${traceId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        ).then(d => d.json());
      } catch (e) {
        console.log(e);
      }

      return { logRows, traceSpans };
    },
    data => Action.TelemetryReceived(data.traceSpans, data.logRows)
  );
}

export function getFilterMaps(rows: ReadonlyArray<LogRowFromServer>): {
  readonly servicesWithCategories: Map<string, Set<string>>;
  readonly enabledServicesAndCategories: Set<string>;
  readonly caseTypes: Set<string>;
  readonly caseNames: Set<string>;
  readonly components: Set<string>;
} {
  const servicesWithCategories = new Map<string, Set<string>>();
  const enabledServicesAndCategories = new Set<string>();
  const caseNames = new Set<string>();
  const caseTypes = new Set<string>();
  const components = new Set<string>();
  caseNames.add(All);
  caseNames.add(Empty);
  caseTypes.add(All);
  caseTypes.add(Empty);
  components.add(All);
  components.add(Empty);
  for (const r of rows) {
    servicesWithCategories.set(
      r.serviceName,
      (servicesWithCategories.get(r.serviceName) ?? new Set<string>()).add(
        r.category
      )
    );
    enabledServicesAndCategories.add(categoryKey(r.serviceName, All));
    enabledServicesAndCategories.add(categoryKey(r.serviceName, r.category));
    if (r.caseName !== "") {
      caseNames.add(r.caseName);
    }
    if (r.caseType !== "") {
      caseTypes.add(r.caseType);
    }
    if (r.component !== "") {
      components.add(r.component);
    }
  }
  return {
    servicesWithCategories,
    enabledServicesAndCategories,
    caseTypes,
    caseNames,
    components
  };
}

export function toggleCategoriesSelection(
  groupMap: Map<string, Set<string>>,
  enabledSet: Set<string>,
  groupKey: string,
  key: string
) {
  if (key === All) {
    const enabledServicesAndCategories = toggleAllCategoriesInGroup(
      groupMap,
      enabledSet,
      groupKey
    );
    return enabledServicesAndCategories;
  } else {
    const newEnableSet = new Set(enabledSet);
    toggleEnabledCategories(newEnableSet, groupKey, key);
    return newEnableSet;
  }
}

export function toggleAdvancedSet(
  set: Set<string>,
  enabledSet: Set<string>,
  key: string
): Set<string> {
  if (key === All) {
    return toggleAdvancedAll(set, enabledSet);
  } else {
    const newEnableSet = new Set(enabledSet);
    toggleEnabled(newEnableSet, key);
    return newEnableSet;
  }
}

function toggleAllCategoriesInGroup(
  groupMap: Map<string, Set<string>>,
  enabledSet: Set<string>,
  groupKey: string
): Set<string> {
  const newEnabledSet = new Set(enabledSet);
  toggleEnabledCategories(newEnabledSet, groupKey, All);
  for (const key of groupMap.get(groupKey) || []) {
    if (newEnabledSet.has(categoryKey(groupKey, All))) {
      newEnabledSet.add(categoryKey(groupKey, key));
    } else {
      newEnabledSet.delete(categoryKey(groupKey, key));
    }
  }

  return newEnabledSet;
}

function toggleEnabledCategories(
  enabledSet: Set<string>,
  groupKey: string,
  key: string
): void {
  const setKey = categoryKey(groupKey, key);
  if (enabledSet.has(setKey)) {
    enabledSet.delete(setKey);
  } else {
    enabledSet.add(setKey);
  }
}

function toggleAdvancedAll(
  set: Set<string>,
  enabledSet: Set<string>
): Set<string> {
  const newEnabledSet = new Set(enabledSet);
  toggleEnabled(newEnabledSet, All);
  for (const key of set || []) {
    if (newEnabledSet.has(All)) {
      newEnabledSet.add(key);
    } else {
      newEnabledSet.delete(key);
    }
  }

  return newEnabledSet;
}

function toggleEnabled(enabledSet: Set<string>, key: string): void {
  if (enabledSet.has(key)) {
    enabledSet.delete(key);
  } else {
    enabledSet.add(key);
  }
}
