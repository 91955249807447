import { exhaustiveCheck } from "ts-exhaustive-check";
import * as Actions from "./actions";
export interface State {
  readonly includeServerLog: boolean;
  readonly showHiddenProperties: boolean;
  readonly showHiddenResults: boolean;
}

const initialState: State = {
  includeServerLog: false,
  showHiddenProperties: false,
  showHiddenResults: false
};

export function reducer(state = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "SetDebugSettings": {
      return {
        includeServerLog: action.includeServerLog,
        showHiddenProperties: action.showHiddenProperties,
        showHiddenResults: action.showHiddenResults
      };
    }
    case "Dummy": {
      return state;
    }
    default: {
      exhaustiveCheck(action);
      return state;
    }
  }
}
