import gql from "graphql-tag";

export const productQuery = gql`
  query DataCenterSizeSelectorProductQuery($sys: GetProductInputType!) {
    product {
      sys: product(input: $sys) {
        id
        properties {
          id
          name
          values {
            value
            validationFilter
            rangeFilter
          }
        }
        images {
          propertyFilter
          url
          name
        }
        sizeInfoMechanical: productDataTable(tableName: "sizeInfoMechanical") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
        sizeInfoPerformance: productDataTable(
          tableName: "sizeInfoPerformance"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
