import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { AmountFormat } from "@genesys/shared/lib/screen-amounts";
import { StaticText } from "@genesys/shared/lib/language-texts/types";
import { getValue } from "@genesys/shared/lib/product-properties";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import * as KnownProperties from "./known-properties";

export function createMonthlyList(
  energyResult: Types.EnergyResult,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  createAmountFormat: (
    fieldName: string,
    amount: Amount.Amount<Quantity.Quantity>
  ) => AmountFormat
): Types.EnergyResultTable {
  const resultQuantities = energyResult.presentationPerfParams.reduce(
    (sofar: Array<Types.ResultQuantity>, current: string) => {
      const v = energyResult.binResults.find(
        br =>
          PropertyValueSet.hasProperty(current, br.results) &&
          PropertyValueSet.getAmount(current, br.results)!.value !== 0
      );
      if (v !== undefined) {
        const amount = PropertyValueSet.getAmount(current, v.results)!;
        sofar.push({
          propertyName: current,
          internalName: current,
          displayName: translate(LanguageTexts.dynamicText("pp_" + current)),
          format:
            amount &&
            createAmountFormat(
              current,
              amount as Amount.Amount<Quantity.Quantity>
            )
        });
      }
      return sofar;
    },
    []
  );

  const binDataQuantities: Array<Types.ResultQuantity> = [
    {
      propertyName: "airtemperature",
      internalName: "AverageTemp",
      displayName: translate(LanguageTexts.averageTemp()),
      format: getBinDataFormat(
        energyResult,
        "airtemperature",
        "AverageTemp",
        createAmountFormat
      )
    },
    {
      propertyName: "airhumidity",
      internalName: "Humidity",
      displayName: translate(LanguageTexts.humidity()),
      format: getBinDataFormat(
        energyResult,
        "airhumidity",
        "Humidity",
        createAmountFormat
      )
    },
    {
      propertyName: "time",
      internalName: "Time",
      displayName: translate(LanguageTexts.time()),
      format: getBinDataFormat(energyResult, "time", "Time", createAmountFormat)
    }
  ];

  const rows = Types.months.map(m => {
    return getMonthResults(
      m,
      binDataQuantities.map(bdq => {
        return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
      }),

      resultQuantities.map(bdq => {
        return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
      }),

      energyResult
    );
  });
  const headersWithFormat = [
    {
      internalName: "month",
      name: translate(LanguageTexts.month()),
      format: undefined
    } as Types.EnergyListColumn
  ]
    .concat(
      binDataQuantities.map(bq => {
        return {
          internalName: bq.internalName,
          name: bq.displayName,
          format: bq.format
        } as Types.EnergyListColumn;
      })
    )
    .concat(
      resultQuantities.map(rq => {
        return {
          internalName: rq.internalName,
          name: rq.displayName,
          format: rq.format
        } as Types.EnergyListColumn;
      })
    );

  const formattedRows = Types.months.map((m, rowIndex) => {
    return [translate(LanguageTexts.dynamicText(m.toString()))].concat(
      rows[rowIndex].map((c, cellIndex) =>
        c
          ? getValue(
              PropertyValue.fromAmount(c!),
              createAmountFormat(
                headersWithFormat[cellIndex + 1].internalName,
                c!
              )
            )
          : "-"
      )
    );
  });

  const totalRow = getTotalAverageResult(
    "Total",
    binDataQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    resultQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    energyResult
  );

  const formattedTotalRow = [
    translate(LanguageTexts.dynamicText("totalaverage"))
  ].concat(
    totalRow.map((c, cellIndex) =>
      c
        ? getValue(
            PropertyValue.fromAmount(c!),
            createAmountFormat(
              headersWithFormat[cellIndex + 1].internalName,
              c!
            )
          )
        : "-"
    )
  );
  const averageRow = getTotalAverageResult(
    "Average",
    binDataQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    resultQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    energyResult
  );

  const formattedAverageRow = [
    translate(LanguageTexts.dynamicText("average"))
  ].concat(
    averageRow.map((c, cellIndex) =>
      c
        ? getValue(
            PropertyValue.fromAmount(c!),
            createAmountFormat(
              headersWithFormat[cellIndex + 1].internalName,
              c!
            )
          )
        : "-"
    )
  );

  return {
    formattedHeader: headersWithFormat,
    formattedRows: formattedRows,
    totalRow: formattedTotalRow,
    averageRow: formattedAverageRow
  };
}

export function getMidPointQuantity(
  energyResult: Types.EnergyResult
): Quantity.Quantity {
  if (!energyResult || energyResult.binResults.length === 0) {
    return "Temperature";
  }
  if (
    PropertyValueSet.hasProperty(
      KnownProperties.airMidPointTemperature,
      energyResult.binResults[0].binData
    )
  ) {
    return "Temperature";
  }
  if (
    PropertyValueSet.hasProperty(
      KnownProperties.airMidPointWetTemperature,
      energyResult.binResults[0].binData
    )
  ) {
    return "WetTemperature";
  }
  if (
    PropertyValueSet.hasProperty(
      KnownProperties.airMidPointDewPointTemperature,
      energyResult.binResults[0].binData
    )
  ) {
    return "DewPointTemperature";
  }
  if (
    PropertyValueSet.hasProperty(
      KnownProperties.airMidPointSpecificEnthalpy,
      energyResult.binResults[0].binData
    )
  ) {
    return "SpecificEnthalpy";
  }
  if (
    PropertyValueSet.hasProperty(
      KnownProperties.airMidPointHumidityRatio,
      energyResult.binResults[0].binData
    )
  ) {
    return "HumidityRatio";
  }
  return "Temperature";
}

export function getMidPointPropertyName(quantity: Quantity.Quantity): string {
  if (quantity === "Temperature") {
    return KnownProperties.airMidPointTemperature;
  }
  if (quantity === "WetTemperature") {
    return KnownProperties.airMidPointWetTemperature;
  }
  if (quantity === "DewPointTemperature") {
    return KnownProperties.airMidPointDewPointTemperature;
  }
  if (quantity === "SpecificEnthalpy") {
    return KnownProperties.airMidPointSpecificEnthalpy;
  }
  if (quantity === "HumidityRatio") {
    return KnownProperties.airMidPointHumidityRatio;
  }
  return KnownProperties.airMidPointTemperature;
}

export function getMidPointFieldName(quantity: Quantity.Quantity): string {
  if (quantity === "Temperature") {
    return "MidPointTemp";
  }
  if (quantity === "WetTemperature") {
    return "MidPointWetTemp";
  }
  if (quantity === "DewPointTemperature") {
    return "MidPointDewPointTemp";
  }
  if (quantity === "SpecificEnthalpy") {
    return "MidPointSpecificEnthalpy";
  }
  if (quantity === "HumidityRatio") {
    return "MidPointHumidityRatio";
  }
  return "MidPointTemp";
}

export function getMidPointTextId(quantity: Quantity.Quantity): StaticText {
  if (quantity === "Temperature") {
    return LanguageTexts.midPointTemp();
  }
  if (quantity === "WetTemperature") {
    return LanguageTexts.midPointWetTemp();
  }
  if (quantity === "DewPointTemperature") {
    return LanguageTexts.midPointDewPointTemp();
  }
  if (quantity === "SpecificEnthalpy") {
    return LanguageTexts.midPointSpecificEnthalpy();
  }
  if (quantity === "HumidityRatio") {
    return LanguageTexts.midPointHumidityRatio();
  }
  return LanguageTexts.midPointTemp();
}

export function createBinList(
  energyResult: Types.EnergyResult,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  createAmountFormat: (
    fieldName: string,
    amount: Amount.Amount<Quantity.Quantity>
  ) => AmountFormat
): Types.EnergyResultTable {
  const resultQuantities = energyResult.presentationPerfParams.reduce(
    (sofar: Array<Types.ResultQuantity>, current: string) => {
      const v = energyResult.binResults.find(
        br =>
          PropertyValueSet.hasProperty(current, br.results) &&
          PropertyValueSet.getAmount(current, br.results)!.value !== 0
      );
      if (v !== undefined) {
        const amount = PropertyValueSet.getAmount(current, v.results)!;
        sofar.push({
          propertyName: current,
          internalName: current,
          displayName: translate(LanguageTexts.dynamicText("pp_" + current)),
          format: createAmountFormat(
            current,
            amount as Amount.Amount<Quantity.Quantity>
          )
        });
      }
      return sofar;
    },
    []
  );

  const midPointQuantity = getMidPointQuantity(energyResult);
  const midPointPropertyName = getMidPointPropertyName(midPointQuantity);
  const midPointFieldName = getMidPointFieldName(midPointQuantity);
  const binDataQuantities: Array<Types.ResultQuantity> = [
    {
      propertyName: midPointPropertyName,
      internalName: midPointFieldName,
      displayName: translate(getMidPointTextId(midPointQuantity)),
      format: getBinDataFormat(
        energyResult,
        midPointPropertyName,
        midPointFieldName,
        createAmountFormat
      )
    },
    {
      propertyName: "airtemperature",
      internalName: "AverageTemp",
      displayName: translate(LanguageTexts.averageTemp()),
      format: getBinDataFormat(
        energyResult,
        "airtemperature",
        "AverageTemp",
        createAmountFormat
      )
    },
    {
      propertyName: "airhumidity",
      internalName: "Humidity",
      displayName: translate(LanguageTexts.humidity()),
      format: getBinDataFormat(
        energyResult,
        "airhumidity",
        "Humidity",
        createAmountFormat
      )
    },
    {
      propertyName: "time",
      internalName: "Time",
      displayName: translate(LanguageTexts.time()),
      format: getBinDataFormat(energyResult, "time", "Time", createAmountFormat)
    }
  ];

  const rows = energyResult.binResults.map(br =>
    [
      PropertyValueSet.getInteger(KnownProperties.id, br.binData)! as
        | Amount.Amount<Quantity.Quantity>
        | number
    ].concat(
      binDataQuantities
        .map(
          bDQ =>
            PropertyValueSet.getAmount(bDQ.propertyName, br.binData)! as
              | Amount.Amount<Quantity.Quantity>
              | number
        )
        .concat(
          resultQuantities.map(
            rQ =>
              PropertyValueSet.getAmount(rQ.propertyName, br.results) as
                | Amount.Amount<Quantity.Quantity>
                | number
          )
        )
    )
  );

  const headersWithFormat = [
    {
      name: translate(LanguageTexts.no()),
      internalName: "No",
      format: undefined as AmountFormat | undefined
    }
  ].concat(
    binDataQuantities.concat(resultQuantities).map(h => {
      return {
        internalName: h.internalName,
        name: h.displayName,
        format: h.format
      };
    })
  );

  const formattedRows = rows.map(r =>
    r.map((c, ix) =>
      typeof c === "number"
        ? c.toString()
        : c
        ? getValue(
            PropertyValue.fromAmount(c!),
            createAmountFormat(headersWithFormat[ix].internalName, c!)
          )
        : "-"
    )
  );

  const totalRow = getTotalAverageResult(
    "Total",
    binDataQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    resultQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    energyResult
  );

  const formattedTotalRow = [
    translate(LanguageTexts.dynamicText("total"))
  ].concat(
    totalRow.map((c, cellIndex) =>
      c
        ? getValue(
            PropertyValue.fromAmount(c!),
            createAmountFormat(
              headersWithFormat[cellIndex + 1].internalName,
              c!
            )
          )
        : "-"
    )
  );

  const averageRow = getTotalAverageResult(
    "Average",
    binDataQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    resultQuantities.map(bdq => {
      return { name: bdq.propertyName, quantity: bdq.format.unit.quantity };
    }),

    energyResult
  );

  const formattedAverageRow = [
    translate(LanguageTexts.dynamicText("average"))
  ].concat(
    averageRow.map((c, cellIndex) =>
      c
        ? getValue(
            PropertyValue.fromAmount(c!),
            createAmountFormat(
              headersWithFormat[cellIndex + 1].internalName,
              c!
            )
          )
        : "-"
    )
  );

  return {
    formattedHeader: headersWithFormat,
    formattedRows: formattedRows,
    totalRow: formattedTotalRow,
    averageRow: formattedAverageRow
  };
}

function getBinDataFormat(
  energyResult: Types.EnergyResult,
  propertyName: string,
  internalName: string,
  createAmountFormat: (
    fieldName: string,
    amount: Amount.Amount<Quantity.Quantity>
  ) => AmountFormat
): AmountFormat {
  const result = energyResult.binResults.find(br =>
    PropertyValueSet.hasProperty(propertyName, br.binData)
  );

  const amount = PropertyValueSet.getAmount(propertyName, result!.binData);
  return createAmountFormat(
    internalName,
    amount as Amount.Amount<Quantity.Quantity>
  );
}

function getTotalAverageResult(
  resultType: "Total" | "Average",
  binDataNames: ReadonlyArray<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>,
  resultNames: ReadonlyArray<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>,
  energyResult: Types.EnergyResult
): (Amount.Amount<Quantity.Quantity> | undefined)[] {
  const totalTime = energyResult.binResults.reduce(
    (soFar: Amount.Amount<Quantity.Duration>, current) => {
      return Amount.plus(
        soFar,
        PropertyValueSet.getAmount<Quantity.Duration>(
          KnownProperties.time,
          current.binData
        )!
      );
    },
    Amount.create(0, Units.Hour)
  );

  const binData = binDataNames.map(bn => {
    if (bn.name === KnownProperties.time) {
      return totalTime;
    }
    if (totalTime.value === 0) {
      return undefined;
    }
    const values = energyResult.binResults
      .filter(br => PropertyValueSet.hasProperty(bn.name, br.binData))
      .map(br => {
        return {
          value: PropertyValueSet.getAmount(
            bn.name,
            br.binData
          )! as Amount.Amount<Quantity.Quantity>,
          subTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!,
          groupTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!
        };
      });
    const sumOrAverage = getAggregateType(bn.quantity);
    if (resultType === "Total" && sumOrAverage === "WeightedSum") {
      return weightedSum(values);
    } else if (resultType === "Average" && sumOrAverage === "WeightedAverage") {
      return weightedAverage(values, totalTime);
    }
    return undefined;
  });

  const results = resultNames.map(rn => {
    if (totalTime.value === 0) {
      return undefined;
    }
    const sumOrAverage = getAggregateType(rn.quantity);

    const values = energyResult.binResults
      .filter(br => PropertyValueSet.hasProperty(rn.name, br.results))
      .map(br => {
        return {
          value: PropertyValueSet.getAmount(
            rn.name,
            br.results
          )! as Amount.Amount<Quantity.Quantity>,
          subTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!,
          groupTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!
        };
      });

    if (resultType === "Total" && sumOrAverage === "WeightedSum") {
      return weightedSum(values);
    } else if (resultType === "Average" && sumOrAverage === "WeightedAverage") {
      return weightedAverage(values, totalTime);
    }
    return undefined;
  });

  return binData.concat(results);
}

function getAggregateType(
  quantity: Quantity.Quantity
): "WeightedSum" | "WeightedAverage" {
  switch (quantity) {
    case "Energy":
    case "Mass":
    case "Volume":
    case "Duration":
      return "WeightedSum";

    // return "Sum";

    case "Power":
    case "MassFlow":
    case "Temperature":
    case "HumidityRatio":
      return "WeightedAverage";
    default:
      return "WeightedAverage";
  }
}

function weightedSum(
  values: Array<{
    readonly value: Amount.Amount<Quantity.Quantity>;
    readonly subTime: Amount.Amount<Quantity.Duration>;
    readonly groupTime: Amount.Amount<Quantity.Duration>;
  }>
): Amount.Amount<Quantity.Quantity> | undefined {
  return values.reduce(
    (
      sofar: Amount.Amount<Quantity.Quantity>,
      current: {
        readonly value: Amount.Amount<Quantity.Quantity>;
        readonly subTime: Amount.Amount<Quantity.Duration>;
        readonly groupTime: Amount.Amount<Quantity.Duration>;
      }
    ) => {
      if (current.value === undefined) {
        return sofar;
      }
      if (sofar !== undefined) {
        sofar = Amount.plus(
          sofar,
          Amount.times(
            current.value,
            current.subTime.value / current.groupTime.value
          )
        );
      } else {
        sofar = Amount.times(
          current.value,
          current.subTime.value / current.groupTime.value
        );
      }

      return sofar;
    },
    undefined as Amount.Amount<Quantity.Quantity> | undefined
  );
}

function weightedAverage(
  values: Array<{
    readonly value: Amount.Amount<Quantity.Quantity>;
    readonly subTime: Amount.Amount<Quantity.Duration>;
    readonly groupTime: Amount.Amount<Quantity.Duration>;
  }>,
  totalTime: Amount.Amount<Quantity.Duration>
): Amount.Amount<Quantity.Quantity> | undefined {
  const weightedValue = values.reduce(
    (
      sofar: Amount.Amount<Quantity.Quantity>,
      current: {
        readonly value: Amount.Amount<Quantity.Quantity>;
        readonly subTime: Amount.Amount<Quantity.Duration>;
        readonly groupTime: Amount.Amount<Quantity.Duration>;
      }
    ) => {
      if (current.value === undefined) {
        return sofar;
      }
      if (sofar !== undefined) {
        sofar = Amount.plus(
          sofar,
          Amount.times(current.value, current.subTime.value)
        );
      } else {
        sofar = Amount.times(current.value, current.subTime.value);
      }

      return sofar;
    },
    undefined as Amount.Amount<Quantity.Quantity> | undefined
  );
  return weightedValue
    ? Amount.times(weightedValue, 1 / totalTime.value)
    : undefined;
}

function sum(
  values: Array<{
    readonly value: Amount.Amount<Quantity.Quantity>;
    readonly subTime: Amount.Amount<Quantity.Duration>;
    readonly groupTime: Amount.Amount<Quantity.Duration>;
  }>
): Amount.Amount<Quantity.Quantity> | undefined {
  return values.reduce(
    (
      sofar: Amount.Amount<Quantity.Quantity>,
      current: {
        readonly value: Amount.Amount<Quantity.Quantity>;
        readonly subTime: Amount.Amount<Quantity.Duration>;
        readonly groupTime: Amount.Amount<Quantity.Duration>;
      }
    ) => {
      if (current.value === undefined) {
        return sofar;
      }
      if (sofar !== undefined) {
        sofar = Amount.plus(sofar, current.value);
      } else {
        sofar = current.value;
      }

      return sofar;
    },
    undefined as Amount.Amount<Quantity.Quantity> | undefined
  );
}

function getMonthResults(
  month: Types.MonthEnum,
  binDataNames: ReadonlyArray<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>,
  resultNames: ReadonlyArray<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>,
  energyResult: Types.EnergyResult
): (Amount.Amount<Quantity.Quantity> | undefined)[] {
  const totalTime = energyResult.binResults.reduce(
    (soFar: Amount.Amount<Quantity.Duration>, current) => {
      return Amount.plus(soFar, getBinTime(month, current.binData));
    },
    Amount.create(0, Units.Hour)
  );
  const binData = binDataNames.map(bn => {
    if (bn.name === KnownProperties.time) {
      return totalTime;
    }
    if (totalTime.value === 0) {
      return undefined;
    }
    const values = energyResult.binResults
      .filter(br => PropertyValueSet.hasProperty(bn.name, br.binData))
      .map(br => {
        return {
          value: PropertyValueSet.getAmount(
            bn.name,
            br.binData
          )! as Amount.Amount<Quantity.Quantity>,
          subTime: getBinTime(month, br.binData),
          groupTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!
        };
      });
    const sumOrAverage = getAggregateType(bn.quantity);
    if (sumOrAverage === "WeightedSum") {
      return weightedSum(values);
    } else if (sumOrAverage === "WeightedAverage") {
      return weightedAverage(values, totalTime);
    }
    return sum(values);
  });

  const results = resultNames.map(rn => {
    if (totalTime.value === 0) {
      return undefined;
    }
    const sumOrAverage = getAggregateType(rn.quantity);

    const values = energyResult.binResults
      .filter(br => PropertyValueSet.hasProperty(rn.name, br.results))
      .map(br => {
        return {
          value: PropertyValueSet.getAmount(
            rn.name,
            br.results
          )! as Amount.Amount<Quantity.Quantity>,
          subTime: getBinTime(month, br.binData),
          groupTime: PropertyValueSet.getAmount<Quantity.Duration>(
            KnownProperties.time,
            br.binData
          )!
        };
      });

    if (sumOrAverage === "WeightedSum") {
      return weightedSum(values);
    } else if (sumOrAverage === "WeightedAverage") {
      return weightedAverage(values, totalTime);
    }
    return sum(values);
  });

  return binData.concat(results);
}

function getBinTime(
  month: Types.MonthEnum,
  binData: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Duration> {
  switch (month) {
    case Types.MonthEnum.January:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeJanuary, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.February:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeFebruary, binData) ||
        Amount.create(0, Units.Second)
      );

    case Types.MonthEnum.March:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeMarch, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.April:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeApril, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.May:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeMay, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.June:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeJune, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.July:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeJuly, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.August:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeAugust, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.September:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeSeptember, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.October:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeOctober, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.November:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeNovember, binData) ||
        Amount.create(0, Units.Second)
      );
    case Types.MonthEnum.December:
      return (
        PropertyValueSet.getAmount(KnownProperties.binTimeDecember, binData) ||
        Amount.create(0, Units.Second)
      );

    default:
      break;
  }
  return Amount.create(0, Units.Hour);
}
// tslint:disable-next-line:max-file-line-count
