import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Tools from "./tools";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as System from "../../../system";
import styled from "styled-components";
import { Spinner, TableCard, P1 } from "@genesys/ui-elements";
import { getValue } from "@genesys/shared/lib/product-properties";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValue } from "@genesys/property";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import { DetailedView } from "./detailed-view";

const Root = styled.div`
  padding-left: 16px;
  > div {
    margin-right: 12px;
  }
`;

const SpinnerContainer = styled.div`
  height: 557px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ResultsView({
  state,
  sharedState,
  system,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
}) {
  const translate = sharedState.translate;

  if (state === undefined) {
    return (
      <Root>
        <P1 weight="bold" color="dark">
          {translate(LanguageTexts.noEnergyResultAvailable())}
        </P1>
      </Root>
    );
  }

  if (state.userQuery === undefined || state.productQuery === undefined) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const amountFormat = sharedState.screenAmounts.getAmountFormat;

  const energyAnnualConsumption =
    state.userQuery.user.system.energySummary.energyAnnualConsumption
      .map(e => Tools.parseEnergyParam(e))
      .concat([
        Tools.getTotalEnergyConsumption(
          state.userQuery.user.system.energySummary.energyAnnualConsumption
        )
      ]);

  const energyEmission =
    state.userQuery.user.system.energySummary.energyEmission.map(e =>
      Tools.parseEnergyParam(e)
    );
  const energyAnnualExtra =
    state.userQuery.user.system.energySummary.energyAnnualExtra.map(e =>
      Tools.parseEnergyParam(e)
    );
  const energyAnnualCost =
    state.userQuery.user.system.energySummary.energyAnnualCost.map(e =>
      Tools.parseEnergyCost(e)
    );
  const lccCost = state.userQuery.user.system.energySummary.lccCost.map(e =>
    Tools.parseEnergyCost(e)
  );

  return (
    <Root>
      <TableCard
        rows={createTableFormatForEnergyParam(
          energyAnnualConsumption,
          dispatch,
          translate,
          amountFormat
        )}
        title={translate(LanguageTexts.predictedAnnualConsumption())}
      />

      {!!energyAnnualExtra.length && (
        <TableCard
          rows={createTableFormatForEnergyParam(
            energyAnnualExtra,
            dispatch,
            translate,
            amountFormat
          )}
          title={translate(LanguageTexts.predictedAnnualExtra())}
        />
      )}

      <TableCard
        rows={createTableFormatForEnergyCost(energyAnnualCost, translate)}
        title={translate(LanguageTexts.predictedAnnualCost())}
      />

      {!!lccCost.length && (
        <TableCard
          rows={createTableFormatForEnergyCost(lccCost, translate)}
          title={translate(LanguageTexts.predictedLccCost())}
        />
      )}

      <TableCard
        rows={createTableFormatForEnergyParam(
          energyEmission,
          dispatch,
          translate,
          amountFormat
        )}
        title={translate(LanguageTexts.emissions())}
      />

      <DetailedView
        system={system}
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />
    </Root>
  );
}

function createTableFormatForEnergyParam(
  rows: ReadonlyArray<SharedEnergyTools.EnergyParam>,
  dispatch: Dispatch<Action>,
  translate: LanguageTexts.Translate,
  amountFormat: ScreenAmounts.GetAmountFormat
) {
  return rows.map(row => {
    const fieldName = row.amountField.fieldId.fieldName;
    const fieldGroup = row.amountField.fieldId.fieldGroup;
    const format = amountFormat(
      fieldGroup,
      fieldName,
      row.amountField.amount.unit.quantity
    );
    return {
      title: translate(LanguageTexts.dynamicText("pp_" + row.energyParamName)),
      value: getValue(PropertyValue.fromAmount(row.amountField.amount), format),
      amountFormatSelector: () =>
        getAmountFormatSelector(
          dispatch,
          translate,
          fieldGroup,
          fieldName,
          format
        )
    };
  });
}

function createTableFormatForEnergyCost(
  rows: ReadonlyArray<SharedEnergyTools.EnergyCost>,
  translate: LanguageTexts.Translate
) {
  return rows.reduce((soFar, current) => {
    const value = current.cost
      .toFixed(0)
      .split("")
      .reduce(
        (a, b, ix) =>
          a +
          (a !== "" && (current.cost.toFixed(0).length - ix) % 3 === 0
            ? " "
            : "") +
          b,
        ""
      );
    return value === "0"
      ? soFar
      : soFar.concat([
          {
            title: translate(LanguageTexts.dynamicText(current.name)),
            value: `${value} ${current.currencySymbol}`
          }
        ]);
  }, [] as ReadonlyArray<{ readonly title: string; readonly value: string }>);
}

function getAmountFormatSelector(
  dispatch: Dispatch<Action>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  fieldGroup: string,
  fieldName: string,
  format: ScreenAmounts.AmountFormat
) {
  return (
    <>
      [
      <AmountFormatSelector
        type="AmountFormatSelectorProps"
        amountFormat={format}
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        conversionParameters={undefined}
        translate={translate}
        onFormatCleared={() =>
          dispatch(Action.onFormatCleared(fieldGroup, fieldName))
        }
        onFormatChanged={(unit, decimalCount) =>
          dispatch(
            Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
          )
        }
      />
      ]
    </>
  );
}
