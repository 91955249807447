import { Quantity } from "@genesys/uom";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import React, { useCallback, useState } from "react";
import { Amount, Unit } from "uom";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  UseAmountPropertyInput,
  UseAmountPropertyInputOptions,
  State,
  StateInitParams
} from "./types";
import { getErrorMessage } from "./get-error-message";

export function getUseAmountPropertyInput(
  options: UseAmountPropertyInputOptions
): UseAmountPropertyInput {
  const {
    readOnly,
    inputUnit,
    propertyName,
    originalQuantity,
    propertyValueSet,
    validationFilter,
    inputDecimalCount,
    isRequiredMessage,
    notNumericMessage,
    conversionParameters,
    isLockedByValueSource,
    onValueChange,
    filterPrettyPrint
  } = options;

  const value = PropertyValueSet.getAmount(propertyName, propertyValueSet);

  const errorMessage = getErrorMessage(
    value,
    validationFilter,
    propertyValueSet,
    conversionParameters,
    filterPrettyPrint
  );

  const [state, setState] = useState<State>(
    initStateFromParams({
      ...options,
      value: value,
      errorMessage: errorMessage
    })
  );

  // Re-init state if specific params change
  React.useEffect(() => {
    const newState = initStateFromParams({
      ...options,
      value: value,
      errorMessage: errorMessage,
      inputDecimalCount: state.effectiveDecimalCount // This is needed for converted amounts, otherwise writing decimals wont work properly
    });
    setState(newState);
  }, [
    value,
    inputUnit,
    errorMessage,
    isRequiredMessage,
    notNumericMessage,
    conversionParameters
  ]);

  // Needs to be seperate from the other params due to the effectiveDecimalCount used while typing
  React.useEffect(() => {
    const newState = initStateFromParams({
      ...options,
      value: value,
      errorMessage: errorMessage
    });
    setState(newState);
  }, [inputDecimalCount]);

  const onValueChangeCallback = useCallback(
    (newAmount: Amount.Amount<unknown>) =>
      onValueChange(
        newAmount !== undefined
          ? PropertyValue.create("amount", newAmount)
          : undefined
      ),
    [onValueChange]
  );

  const debouncedOnValueChange = useCallback(
    debounce((newAmount: Amount.Amount<any>, isValid: boolean) => {
      // An event can have been received when the input was valid, then the input has gone invalid
      // but we still received the delayed event from when the input was valid. Therefore
      // we need an extra check here to make sure that the current input is valid before we
      // dispatch the value change.
      if (newAmount !== undefined && isValid) {
        if (newAmount.unit.quantity === originalQuantity) {
          onValueChangeCallback(newAmount);
        } else {
          const convertedAmount = QuantityConversion.convertQuantity(
            newAmount,
            originalQuantity as Quantity.Quantity,
            conversionParameters as QuantityConversion.ConversionParameters
          );
          onValueChangeCallback(convertedAmount);
        }
      }
    }, 350),
    [onValueChangeCallback]
  );

  return {
    value: state.textValue,
    errorMessage: state.effectiveErrorMessage,
    isReadOnly: readOnly || isLockedByValueSource,
    onChange: e =>
      onChange(debouncedOnValueChange, setState, options, errorMessage, e)
  };
}

function initStateFromParams({
  value,
  inputUnit,
  errorMessage,
  inputDecimalCount,
  isRequiredMessage,
  notNumericMessage,
  conversionParameters
}: StateInitParams): State {
  const formattedValue =
    (value !== undefined &&
      ProductProperties.getValue(
        PropertyValue.create("amount", value!),
        { decimalCount: inputDecimalCount, unit: inputUnit },
        conversionParameters
      )) ||
    "";

  const newState = calculateNewState(
    value,
    formattedValue,
    isRequiredMessage,
    notNumericMessage,
    errorMessage,
    inputDecimalCount
  );

  return newState;
}

function calculateNewState(
  newAmount: Amount.Amount<unknown> | undefined,
  newStringValue: string,
  isRequiredMessage: string,
  notNumericMessage: string,
  errorMessage: string,
  effectiveDecimals: number
): State {
  const internalErrorMessage = getInternalErrorMessage(
    newAmount,
    newStringValue,
    isRequiredMessage,
    notNumericMessage
  );
  if (internalErrorMessage) {
    return {
      isValid: false,
      textValue: newStringValue,
      effectiveErrorMessage: internalErrorMessage,
      effectiveDecimalCount: effectiveDecimals
    };
  } else {
    return {
      isValid: true,
      textValue: newStringValue,
      effectiveErrorMessage: errorMessage,
      effectiveDecimalCount: effectiveDecimals
    };
  }
}

function onChange(
  debouncedOnValueChange: (
    newAmount: Amount.Amount<unknown> | undefined,
    isValid: boolean
  ) => void,
  setState: React.Dispatch<React.SetStateAction<State>>,
  params: UseAmountPropertyInputOptions,
  errorMessage: string,
  e: React.ChangeEvent<{ readonly value: string }>
): void {
  const newStringValue = e.target.value.replace(",", ".");
  const { inputUnit, inputDecimalCount } = params;

  // If the change would add more decimals than allowed then ignore the change
  const stringDecimalCount = getDecimalCountFromString(newStringValue);
  if (stringDecimalCount > inputDecimalCount) {
    return;
  }

  // Update the internal state and if the change resulted in a valid value then emit a change with that value
  const newAmount = unformatWithUnitAndDecimalCount(
    newStringValue,
    inputUnit,
    inputDecimalCount
  );
  const newState = calculateNewState(
    newAmount,
    newStringValue,
    params.isRequiredMessage,
    params.notNumericMessage,
    errorMessage,
    stringDecimalCount
  );
  setState(newState);
  // We need to check isValid from the new state because state is not immidiately mutated
  debouncedOnValueChange(newAmount, newState.isValid);
}

function getInternalErrorMessage(
  newAmount: Amount.Amount<unknown> | undefined,
  newStringValue: string,
  isRequiredMessage: string,
  notNumericMessage: string
): string | undefined {
  // Check if blank and if required or not
  if (newStringValue.trim() === "" && isRequiredMessage) {
    // The user has not entred anything, but a value was required
    return isRequiredMessage;
  }
  if (newStringValue.trim() !== "" && !newAmount && notNumericMessage) {
    // The user has entered something, but it could not be converted to an amount (=was not numeric)
    return notNumericMessage;
  }
  return undefined;
}

function unformatWithUnitAndDecimalCount<T>(
  text: string,
  unit: Unit.Unit<T>,
  inputDecimalCount: number
): Amount.Amount<T> | undefined {
  if (!text || text.length === 0) {
    return undefined;
  }
  const parsedFloatValue = filterFloat(text);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parsedFloatValue)) {
    return undefined;
  }
  // Keep number of decimals from the entered text except if they are more than the formats decimal count
  const textDecimalCount = getDecimalCountFromString(text);
  const finalDecimalCount =
    textDecimalCount > inputDecimalCount ? inputDecimalCount : textDecimalCount;
  const finalFloatValue =
    textDecimalCount > inputDecimalCount
      ? parseFloat(parsedFloatValue.toFixed(inputDecimalCount))
      : parsedFloatValue;
  return Amount.create(finalFloatValue, unit, finalDecimalCount);
}

function getDecimalCountFromString(stringValue: string): number {
  const pointIndex = stringValue.indexOf(".");
  if (pointIndex >= 0) {
    return stringValue.length - pointIndex - 1;
  }
  return 0;
}

function filterFloat(value: string): number {
  // eslint-disable-next-line no-useless-escape
  if (/^(\-|\+)?([0-9]*?(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

// (From underscore.js)
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce(func: Function, wait: number): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timeout: any;
  return function (): void {
    // tslint:disable-next-line: no-arguments
    const args = arguments;
    const later = function (): void {
      timeout = null;
      func.apply({}, args);
    };
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
}
