import * as GraphQL from "graphql";
import shajs from "sha.js";
import * as GraphQLRequest from "@genesys/graphql-request";
import { Cmd } from "@typescript-tea/core";
import { clientConfig } from "../config";
import { promiseCmd } from "../promise-effect-manager";

export async function getGraphQLUser<Data, Variables extends {}>(
  accessToken: string,
  query: GraphQL.DocumentNode,
  variables: Variables,
  includeServerLog: boolean,
  useCalculationWorkers: boolean
): Promise<Data> {
  const dummy = "graphql";
  const result = await GraphQLRequest.requestGen2(
    `${clientConfig.genesysBackend}/${dummy}`,
    query,
    variables,
    includeServerLog,
    useCalculationWorkers,
    accessToken,
    undefined
  );

  return result as Data;
}

export async function getGraphQLProduct<Data, Variables extends {}>(
  accessToken: string,
  query: GraphQL.DocumentNode,
  variables: Variables,
  includeServerLog: boolean
): Promise<Data> {
  const sha256 = shajs("sha256")
    .update(`${GraphQL.print(query)}|${JSON.stringify(variables)}`)
    .digest("hex");
  const result = await GraphQLRequest.requestGen2(
    `${clientConfig.genesysBackend}/graphql`,
    query,
    variables,
    includeServerLog,
    false,
    accessToken,
    sha256
  );

  return result as Data;
}

export function getGraphQLUserCmd<Data, Variables extends {}, Action>(
  accessToken: string,
  query: GraphQL.DocumentNode,
  variables: Variables,
  includeServerLog: boolean,
  useCalculationWorkers: boolean,
  onResolved: (data: Data) => Action,
  onRejected?: (error: Error) => Action
): Cmd<Action> {
  if (check(query, "user")) {
    throw new Error("Not a user query");
  }
  return promiseCmd(
    async () => {
      return await getGraphQLUser(
        accessToken,
        query,
        variables,
        includeServerLog,
        useCalculationWorkers
      );
    },
    onResolved,
    onRejected
  );
}

export function getGraphQLProductCmd<Data, Variables extends {}, Action>(
  accessToken: string,
  query: GraphQL.DocumentNode,
  variables: Variables,
  includeServerLog: boolean,
  onResolved: (data: Data) => Action,
  onRejected?: (error: Error) => Action
): Cmd<Action> {
  if (check(query, "product")) {
    throw new Error("Not a product query");
  }
  return promiseCmd(
    async () => {
      return await getGraphQLProduct(
        accessToken,
        query,
        variables,
        includeServerLog
      );
    },
    onResolved,
    onRejected
  );
}

function check(query: GraphQL.DocumentNode, expectedValue: "product" | "user") {
  const definition = query.definitions[0];

  if (definition.kind === GraphQL.Kind.OPERATION_DEFINITION) {
    const selection = definition.selectionSet.selections[0];
    if (
      selection.kind === GraphQL.Kind.FIELD &&
      definition.operation === "query"
    ) {
      return selection.name.value !== expectedValue;
    }
  }

  return false; // because of mutations
}
