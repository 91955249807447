import * as React from "react";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as BinTools from "../../../../../binning-tools";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { BinDataLocation } from "../../../../../location-selector";
import * as PsychrometricChart from "../../../../../psychrometric-chart";
import * as SharedState from "../../../../../shared-state";
import * as System from "../../../../system";
import { Action } from "../state";

export function BinVisualizerComponent({
  system,
  binInfo,
  sharedState,
  binDataLocation,
  atmosphericPressure,
  psychrometricChartState,
  dispatch
}: {
  readonly system: System.System;
  readonly binInfo: BinTools.BinInfo;
  readonly sharedState: SharedState.State;
  readonly binDataLocation: BinDataLocation | undefined;
  readonly atmosphericPressure: PropertyValue.PropertyValue | undefined;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
  readonly dispatch: Dispatch<Action>;
}) {
  const binLocationId = PropertyValueSet.getText(
    KnownProperties.binDataLocationId,
    binInfo.binSelections
  );
  const hasBinLocationId =
    binLocationId !== undefined && binLocationId.length > 0;

  const exportFileName =
    sharedState.genesysPrefix.genesysNo(
      system.file.genesysNo,
      system.revisionNo
    ) + " EnergyBinCases";

  const showBinResults =
    !(!hasBinLocationId && binInfo.selectedBinType === "Generated") &&
    binInfo.binCases &&
    binInfo.binCases?.length > 0;

  const getBinHourlyDataVersion = (): "v1" | "v2" => {
    const version =
      system.status === SystemStatus.LockSuccess
        ? PropertyValueSet.getText(
            KnownProperties.binDataHourlyVersion,
            PropertyValueSet.fromString(system.binSelections || "")
          )
        : PropertyValueSet.getText(
            KnownProperties.binDataHourlyVersion,
            binInfo.binSelections
          );

    return (version ?? "v1") as "v1" | "v2";
  };

  if (!showBinResults) {
    return <></>;
  }

  return (
    <BinTools.ResultView
      binInfo={binInfo}
      sharedState={sharedState}
      pressure={atmosphericPressure}
      exportFileName={exportFileName}
      binDataLocation={binDataLocation}
      psychrometricChartState={psychrometricChartState}
      version={getBinHourlyDataVersion()}
      setChartType={value => dispatch(Action.setChartType(value))}
      setBinVisualizer={value => dispatch(Action.setBinVisualizer(value))}
      setBinListView={value => dispatch(Action.setBinListView(value))}
      onFormatCleared={(fieldGroup, fieldName) =>
        dispatch(Action.onFormatCleared(fieldGroup, fieldName))
      }
      onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
        dispatch(
          Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        )
      }
    />
  );
}
