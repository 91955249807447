import * as React from "react";

type Environment = "localhost" | "Develop" | "Staging" | "Production";
// tslint:disable-next-line:variable-name
export type ClientConfigContextValue = {
  readonly environment: Environment;
  readonly graphqlEndpoint: string;
  readonly authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string };
};

// tslint:disable-next-line:variable-name
export const clientConfigContext =
  React.createContext<ClientConfigContextValue>({
    environment: "localhost",
    graphqlEndpoint: "/graphql",
    authorization: { type: "cookies" }
  });
