import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { StandardButton, OverlayLoader, P1 } from "@genesys/ui-elements";
import { State as SharedState } from "../../shared-state";
import { PropertiesSelectorView } from "../../properties-selector";
import { Root, FlexBox, ErrorBox } from "./elements";
import { State, Action } from "./state";
import {
  getInputProductData,
  resultProductData,
  getSummaryProductData
} from "./product-data/product-data";

export function DryCapView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const canSeeDryCapClaims = AuthorizationTools.getClaimValues(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.canSeeDryCap
  );

  if (
    canSeeDryCapClaims === undefined ||
    state.inputSelectorState === undefined
  ) {
    return <div>Unauthorized</div>;
  }

  const inputProductData = getInputProductData(canSeeDryCapClaims);
  const summaryProductData = getSummaryProductData(canSeeDryCapClaims);

  return (
    <Root>
      {state.isLoading && <OverlayLoader />}

      <FlexBox>
        <PropertiesSelectorView
          showCodes={false}
          isReadonly={false}
          sharedState={sharedState}
          productId={inputProductData.id}
          state={state.inputSelectorState}
          propertiesInfo={inputProductData.properties}
          fieldGroup={`Settings.${inputProductData.id}`}
          dispatch={Dispatch.map(
            action => Action.dispatchInputSelector(action),
            dispatch
          )}
        />
        <StandardButton
          size="Large"
          buttonType="Primary"
          onClick={() => dispatch(Action.calculate())}
        >
          {sharedState.translate(LanguageTexts.calculate())}
        </StandardButton>
      </FlexBox>
      <FlexBox>
        {state.resultSelectorState !== undefined && (
          <PropertiesSelectorView
            showCodes={false}
            isReadonly={true}
            sharedState={sharedState}
            productId={resultProductData.id}
            state={state.resultSelectorState}
            propertiesInfo={resultProductData.properties}
            fieldGroup={`Settings.${resultProductData.id}`}
            dispatch={Dispatch.map(
              action => Action.dispatchInputSelector(action),
              dispatch
            )}
          />
        )}
      </FlexBox>
      <FlexBox>
        {state.summarySelectorState !== undefined && (
          <PropertiesSelectorView
            showCodes={false}
            isReadonly={true}
            sharedState={sharedState}
            productId={summaryProductData.id}
            state={state.summarySelectorState}
            propertiesInfo={summaryProductData.properties}
            fieldGroup={`Settings.${summaryProductData.id}`}
            dispatch={Dispatch.map(
              action => Action.dispatchSummarySelector(action),
              dispatch
            )}
          />
        )}
      </FlexBox>
      {state.hasCalculationFailed && (
        <ErrorBox>
          <P1 color="primary" weight="normal">
            {sharedState.translate(LanguageTexts.calculationfailed())}
          </P1>
        </ErrorBox>
      )}
    </Root>
  );
}
