import styled from "styled-components";

// tslint:disable-next-line
export const DataPresenterTable = styled.table`
  border: 1px solid black;

  thead {
    th {
      font-weight: bold;
      background-color: #ccc;
      border: 1px solid black;
      padding: 5px;
    }
  }

  tbody {
    overflow-y: auto;
    border: 1px solid black;
  }

  tr {
    background-color: #eee;
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  tr:nth-child(even) {
    background-color: #fcfcfc;
  }
`;
