import { UrlParser, createUrlParser, LocationFromParams } from "./url-parser";
import {
  UrlBuilder,
  createUrlBuilder,
  ParamsFromLocation
} from "./url-builder";

export interface Route<TLocation> {
  readonly parseUrl: UrlParser<TLocation>;
  readonly buildUrl: UrlBuilder<TLocation>;
}

// tslint:disable-next-line: variable-name
export const NoParams = (): {} => ({});

export function createRoute<TLocation>(
  path: string,
  locationFromParams: LocationFromParams<TLocation>,
  paramsFromLocation: ParamsFromLocation<TLocation>
): Route<TLocation> {
  return {
    parseUrl: createUrlParser<TLocation>(path, locationFromParams),
    buildUrl: createUrlBuilder(path, paramsFromLocation)
  };
}

// // TODO: Need more overloads that takes 3, 4, 5 routes etc.
// export function combineRoutes<TLocation>(...routes: readonly Route<TLocation>[]): Route<TLocation> {
//   return {
//     parseUrl: combineUrlParsers(routes.map((r) => r.parseUrl)),
//     buildUrl: combineUrlBuilders(routes.map((r) => r.buildUrl)),
//   };
// }

// // TODO: Need more overloads that takes 3, 4, 5 routes etc.
// export function combineRoutes<TLocation1, TLocation2>(
//   routes: readonly [Route<TLocation1>, Route<TLocation2>]
// ): Route<TLocation1 | TLocation2> {
//   return {
//     parseUrl: combineUrlParsers([routes[0].parseUrl, routes[1].parseUrl]),
//     buildUrl: combineUrlBuilders([routes[0].buildUrl, routes[1].buildUrl]),
//   };
// }

// export function combineRoutes3<TLocation1, TLocation2, TLocation3>(
//   routes: readonly [Route<TLocation1>, Route<TLocation2>, Route<TLocation3>]
// ): Route<TLocation1 | TLocation2 | TLocation3> {
//   return combineRoutes([combineRoutes([routes[0], routes[1]]), routes[2]]);
// }

// export function combineRoutes4<TLocation1, TLocation2, TLocation3, TLocation4>(
//   routes: readonly [Route<TLocation1>, Route<TLocation2>, Route<TLocation3>, Route<TLocation4>]
// ): Route<TLocation1 | TLocation2 | TLocation3 | TLocation4> {
//   return combineRoutes([combineRoutes3([routes[0], routes[1], routes[2]]), routes[3]]);
// }

// export function combineRoutes5<TLocation1, TLocation2, TLocation3, TLocation4, TLocation5>(
//   routes: readonly [Route<TLocation1>, Route<TLocation2>, Route<TLocation3>, Route<TLocation4>, Route<TLocation5>]
// ): Route<TLocation1 | TLocation2 | TLocation3 | TLocation4 | TLocation5> {
//   return combineRoutes([combineRoutes4([routes[0], routes[1], routes[2], routes[3]]), routes[4]]);
// }

// export function combineRoutes10<
//   TLocation1,
//   TLocation2,
//   TLocation3,
//   TLocation4,
//   TLocation5,
//   TLocation6,
//   TLocation7,
//   TLocation8,
//   TLocation9,
//   TLocation10
// >(
//   routes: readonly [
//     Route<TLocation1>,
//     Route<TLocation2>,
//     Route<TLocation3>,
//     Route<TLocation4>,
//     Route<TLocation5>,
//     Route<TLocation6>,
//     Route<TLocation7>,
//     Route<TLocation8>,
//     Route<TLocation9>,
//     Route<TLocation10>
//   ]
// ): Route<
//   | TLocation1
//   | TLocation2
//   | TLocation3
//   | TLocation4
//   | TLocation5
//   | TLocation6
//   | TLocation7
//   | TLocation8
//   | TLocation9
//   | TLocation10
// > {
//   return combineRoutes([
//     combineRoutes5([routes[0], routes[1], routes[2], routes[3], routes[4]]),
//     combineRoutes5([routes[5], routes[6], routes[7], routes[8], routes[9]]),
//   ]);
// }

export function combineUrls(...urls: readonly string[]): string {
  if (urls.length === 0) {
    return "";
  }

  // If the first url is full url we need to handle that first.
  const isFullUrl = /^(https?:\/\/)/;
  const match = isFullUrl.exec(urls[0]);
  let fullUrl = match ? match[1] : "/";
  for (const [index, u1] of urls.entries()) {
    const u = index === 0 && match ? u1.replace(match[1], "") : u1;

    // If string has en leading or an trailing slash it will add empty strings
    const parts = u.split("/").filter(s => s !== "");
    for (const part of parts) {
      if (part === "..") {
        const lastPart = fullUrl.lastIndexOf("/");
        fullUrl = fullUrl.substring(0, lastPart);
      } else {
        fullUrl = `${fullUrl.replace(/\/$/, "")}/${part}`;
      }
    }
  }
  return fullUrl;
}
