import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const saveAmountFieldFormatMutation = gql`
  mutation SaveAmountFieldFormat(
    $amountFieldFormat: AmountFieldFormatInputType!
  ) {
    saveAmountFieldFormat(amountFieldFormat: $amountFieldFormat) {
      ...CompleteAmountFieldProfile
    }
  }
  ${Fragments.completeAmountFieldProfile}
`;
