import * as Types from "../../types";

export function createRowNoCell(
  rowNo: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.RowNoCell {
  return {
    type: "RowNo",
    canEdit: canEdit,
    rowNo: rowNo,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createArticleNoCell(
  articleNo: string,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.ArticleNoCell {
  return {
    type: "ArticleNo",
    canEdit: canEdit,
    articleNo: articleNo,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createDescriptionCell(
  description: string,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.DescriptionCell {
  return {
    type: "Description",
    canEdit: canEdit,
    description: description,
    header: header,
    visibility: visibility,
    alignRight: alignRight,
    isReadOnlyStyle: {
      width: "300px",
      whiteSpace: "normal"
    }
  };
}

export function createPidCell(
  pid: string,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignright: boolean
): Types.PidCell {
  return {
    type: "Pid",
    visibility: visibility,
    canEdit: canEdit,
    pid: pid,
    header: header,
    alignRight: alignright
  };
}

export function createQuantityCell(
  quantity: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.QuantityCell {
  return {
    type: "Quantity",
    canEdit: canEdit,
    quantity: quantity,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createUnitCell(
  unit: string,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.UnitCell {
  return {
    type: "Unit",
    canEdit: canEdit,
    unit: unit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createCostPerUnitCell(
  costPerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.CostPerUnitCell {
  return {
    type: "CostPerUnit",
    canEdit: canEdit,
    costPerUnit: costPerUnit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createListPriceFactorCell(
  listPriceFactor: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alightRight: boolean
): Types.ListPriceFactorCell {
  return {
    type: "ListPriceFactor",
    canEdit: canEdit,
    listpriceFactor: listPriceFactor,
    header: header,
    visibility: visibility,
    alignRight: alightRight
  };
}

export function createListPricePerUnitCell(
  listPricePerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.ListPricePerUnitCell {
  return {
    type: "ListPricePerUnit",
    canEdit: canEdit,
    listPricePerUnit: listPricePerUnit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createTransferPricePerUnitCell(
  pricePerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.TransferPricePerUnitCell {
  return {
    type: "TransferPricePerUnit",
    canEdit: canEdit,
    transferPricePerUnit: pricePerUnit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createTransferPriceFactorCell(
  transferPriceFactor: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alightRight: boolean
): Types.TransferPriceFactorCell {
  return {
    type: "TransferPriceFactor",
    canEdit: canEdit,
    transferPriceFactor: transferPriceFactor,
    header: header,
    visibility: visibility,
    alignRight: alightRight
  };
}

export function createCostSubtotalCell(
  quantity: number,
  costPerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alightRight: boolean
): Types.CostSubtotalCell {
  return {
    type: "CostSubtotal",
    canEdit: canEdit,
    quantity: quantity,
    costPerUnit: costPerUnit,
    header: header,
    visibility: visibility,
    alignRight: alightRight
  };
}

export function createTransferPriceSubtotalCell(
  quantity: number,
  pricePerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.TransferPriceSubtotalCell {
  return {
    type: "TransferPriceSubtotal",
    canEdit: canEdit,
    quantity: quantity,
    transferPricePerUnit: pricePerUnit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}

export function createListPriceSubtotalCell(
  quantity: number,
  pricePerUnit: number,
  visibility: boolean,
  canEdit: boolean,
  header: string,
  alignRight: boolean
): Types.ListPriceSubtotalCell {
  return {
    type: "ListPriceSubtotal",
    canEdit: canEdit,
    quantity: quantity,
    listPricePerUnit: pricePerUnit,
    header: header,
    visibility: visibility,
    alignRight: alignRight
  };
}
