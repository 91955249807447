import { QueueMutationDescription, QueuedMutation } from "./mutations-queue";

export type Action = QueueMany | Dequeue | DequeueMany;

export interface QueueMany {
  readonly type: "QueueMany";
  readonly queueMutationDescriptions: ReadonlyArray<QueueMutationDescription>;
}

export function queueMany(
  queueMutationDescriptions: ReadonlyArray<QueueMutationDescription>
): QueueMany {
  return {
    type: "QueueMany",
    queueMutationDescriptions
  };
}

export interface Dequeue {
  readonly type: "Dequeue";
  readonly mutation: QueuedMutation;
}

export function dequeue(mutation: QueuedMutation): Dequeue {
  return {
    type: "Dequeue",
    mutation
  };
}

export interface DequeueMany {
  readonly type: "DequeueMany";
  readonly mutations: ReadonlyArray<QueuedMutation>;
}

export function dequeueMany(
  mutations: ReadonlyArray<QueuedMutation>
): DequeueMany {
  return {
    type: "DequeueMany",
    mutations
  };
}
