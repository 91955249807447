import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as PropertiesSelector from "../../../properties-selector";
import * as System from "../../system";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import * as KnownProperties from "./known-properties";
import * as GraphQlTypes from "../../../graphql-types";
import { productQuery } from "./queries";
import * as Types from "./types";
import { Amount } from "uom";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import {
  getSupplyOutletAirflowPropertyName,
  parseMinMaxFlows
} from "./functions";
import { runValueSources } from "../../../operating-case-selector";

export type State = {
  readonly flowSelectors: ReadonlyArray<{
    readonly id: string;
    readonly state: PropertiesSelector.State;
  }>;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly minMaxFlows: ReadonlyArray<Types.MinMaxFlow>;
  readonly selectedModel: number | undefined;
  readonly numberOfUnits: number | undefined;
  readonly valuesHasChanged: boolean;
  readonly initialSettings: {
    readonly flowSelectors: ReadonlyArray<{
      readonly id: string;
      readonly state: PropertiesSelector.State;
    }>;
    readonly selectedModel: number | undefined;
  };
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string,
  sysComponent: System.Component | undefined,
  dhuComponent: System.Component | undefined,
  operatingCases: ReadonlyArray<{
    readonly id: string;
    readonly settings: PropertyValueSet.PropertyValueSet;
  }>,
  climateSettings: PropertyValueSet.PropertyValueSet
): [State, Cmd<Action>?] => {
  const selectedModel = dhuComponent
    ? PropertyValueSet.getInteger(
        KnownProperties.dhmodel,
        dhuComponent.properties
      )
    : undefined;

  const numberOfUnits = PropertyValueSet.getInteger(
    KnownProperties.numberOfUnits,
    sysComponent?.properties ?? PropertyValueSet.Empty
  );

  const supplyOutletAirflowPropertyName = getSupplyOutletAirflowPropertyName(
    operatingCases[0].settings ?? PropertyValueSet.Empty
  );

  const flowSelectors =
    numberOfUnits && supplyOutletAirflowPropertyName
      ? operatingCases
          .map(opc => opc)
          .sort(
            (a, b) =>
              PropertyValueSet.getInteger("sortno", a.settings)! -
              PropertyValueSet.getInteger("sortno", b.settings)!
          )
          .map(opc => {
            const supplyOutletAmount = PropertyValueSet.getAmount(
              supplyOutletAirflowPropertyName,
              opc.settings
            )!;
            const totalSupplyOutletAmount = Amount.times(
              supplyOutletAmount,
              numberOfUnits
            );
            const sourceSupplyOutlet = PropertyValue.fromInteger(1);
            const pvs = PropertyValueSet.setAmount(
              KnownProperties.totalSupplyOutletAirflow,
              totalSupplyOutletAmount,
              PropertyValueSet.set(
                KnownProperties.sourceTotalSupplyOutletAirflow,
                sourceSupplyOutlet,
                opc.settings
              )
            );
            return {
              id: opc.id,
              state: PropertiesSelector.init(pvs)
            };
          })
      : [];

  return [
    {
      flowSelectors: flowSelectors,
      climateSettings: climateSettings,
      minMaxFlows: [],
      selectedModel: selectedModel,
      numberOfUnits: numberOfUnits,
      valuesHasChanged: false,
      initialSettings: {
        flowSelectors: flowSelectors,
        selectedModel: selectedModel
      }
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.MultipleSystemsEditorProductQuery,
      GraphQlTypes.MultipleSystemsEditorProductQueryVariables,
      Action
    >(
      productQuery,
      {
        dhu: {
          productId: `${systemTypeId}dhu`
        }
      },
      Action.productDataReceived
    )
  ];
};

export const Action = ctorsUnion({
  dispatchFlowSelector: (
    id: string,
    action: PropertiesSelector.Action,
    productData: Types.ProductData
  ) => ({
    id,
    action,
    productData
  }),
  productDataReceived: (
    data: GraphQlTypes.MultipleSystemsEditorProductQuery
  ) => ({ data }),
  setSelectModelAndNumberOfUnits: (option: Types.Option) => ({
    option
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchFlowSelector": {
      const flowSelector = state.flowSelectors.find(s => s.id === action.id);

      if (!flowSelector) {
        return [state];
      }

      const getUpdatedPropertyValueSet = (
        pvs: PropertyValueSet.PropertyValueSet,
        operatingsCases: PropertyValueSet.PropertyValueSet[]
      ) =>
        runValueSources(
          pvs,
          operatingsCases,
          state.climateSettings,
          action.productData.properties
        );

      const [newFlowSelectorState, flowSelectorCmd, flowSelectorSharedAction] =
        PropertiesSelector.update(
          action.action,
          flowSelector.state,
          sharedState,
          "SkipCalculateProperties",
          pvs =>
            getUpdatedPropertyValueSet(
              pvs,
              state.flowSelectors.map(fs =>
                PropertiesSelector.getSelectedProperties(fs.state)
              )
            )
        );

      const newOperatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet> =
        state.flowSelectors.map(p =>
          p.id === action.id
            ? PropertiesSelector.getSelectedProperties(newFlowSelectorState)
            : PropertiesSelector.getSelectedProperties(p.state)
        );

      const flowSelectors = state.flowSelectors.map(p =>
        p.id === action.id
          ? { id: action.id, state: newFlowSelectorState }
          : {
              id: p.id,
              state: PropertiesSelector.init(
                runValueSources(
                  PropertiesSelector.getSelectedProperties(p.state),
                  newOperatingCases,
                  state.climateSettings,
                  action.productData.properties
                )
              )
            }
      );

      const valuesHasChanged =
        JSON.stringify(flowSelectors) !==
          JSON.stringify(state.initialSettings.flowSelectors) ||
        state.selectedModel !== state.initialSettings.selectedModel;

      return [
        {
          ...state,
          flowSelectors: flowSelectors,
          valuesHasChanged: valuesHasChanged
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchFlowSelector(
              action.id,
              cmdAction,
              action.productData
            ),
          flowSelectorCmd
        ),
        flowSelectorSharedAction
      ];
    }
    case "productDataReceived": {
      const minMaxFlows = parseMinMaxFlows(action.data.product.dhu.minMaxFlow);
      return [{ ...state, minMaxFlows: minMaxFlows }];
    }
    case "setSelectModelAndNumberOfUnits": {
      const valuesHasChanged =
        JSON.stringify(state.flowSelectors) !==
          JSON.stringify(state.initialSettings.flowSelectors) ||
        action.option.model !== state.initialSettings.selectedModel;
      return [
        {
          ...state,
          selectedModel: action.option.model,
          numberOfUnits: action.option.numberOfUnits,
          valuesHasChanged: valuesHasChanged
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfiguratorActions(
  state: State,
  sysComponent: System.Component | undefined,
  dhuComponent: System.Component | undefined,
  climateSettings: PropertyValueSet.PropertyValueSet,
  userAction: "Save" | "SaveAndCalculate"
): ReadonlyArray<ConfiguratorAction | undefined> {
  if (
    !sysComponent ||
    !dhuComponent ||
    !state.numberOfUnits ||
    !state.selectedModel
  ) {
    return [undefined];
  }

  const sysProperties = PropertyValueSet.set(
    KnownProperties.numberOfUnits,
    PropertyValue.fromInteger(state.numberOfUnits),
    sysComponent.properties
  );

  const dhuProperties = PropertyValueSet.set(
    KnownProperties.dhmodel,
    PropertyValue.fromInteger(state.selectedModel),
    dhuComponent.properties
  );

  const operatingCases = state.flowSelectors.map(sfs => {
    const opc = PropertiesSelector.getSelectedProperties(sfs.state);
    const supplyOutletPropertyName = getSupplyOutletAirflowPropertyName(opc);
    if (!supplyOutletPropertyName) {
      return { id: sfs.id, settings: opc };
    }
    const totalSupplyOutletAmount = PropertyValueSet.getAmount(
      KnownProperties.totalSupplyOutletAirflow,
      opc
    )!;
    const dividedSupplyOutletAmount = Amount.divide(
      totalSupplyOutletAmount,
      state.numberOfUnits!
    );

    const pvs = PropertyValueSet.setAmount(
      supplyOutletPropertyName,
      dividedSupplyOutletAmount,
      PropertyValueSet.removeProperties(
        [
          KnownProperties.totalSupplyOutletAirflow,
          KnownProperties.sourceTotalSupplyOutletAirflow
        ],
        opc
      )
    );
    return { id: sfs.id, settings: pvs };
  });

  const saveActions: ReadonlyArray<ConfiguratorAction> = [
    ConfiguratorAction.updateComponentsPropertiesAndSaveOperatingCases(
      [
        {
          componentId: sysComponent.id,
          properties: sysProperties
        },
        {
          componentId: dhuComponent.id,
          properties: dhuProperties
        }
      ],
      climateSettings,
      operatingCases,
      userAction === "SaveAndCalculate"
    )
  ];

  return saveActions;
}
