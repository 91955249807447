import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../../shared-state";
import { BaseState } from "../../step-state-base";
import { PropertyValueSet } from "@genesys/property";
import * as EccSizeSelector from "../../../../size-selectors/ecc-size-selector";
import { Quantity } from "@genesys/uom";

export type State =
  | ({
      readonly ok: boolean;
      readonly eccSizeSelectorState: EccSizeSelector.State;
    } & BaseState)
  | undefined;

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [State, Cmd<Action>?] => {
  const padHeight = PropertyValueSet.getAmount<Quantity.Length>(
    "padheight",
    base.newProperties
  );

  const [eccSizeSelectorState, eccSizeSelectorCmd] = EccSizeSelector.init(
    sharedState,
    undefined,
    padHeight
  );

  return [
    { ...base, ok: false, eccSizeSelectorState: eccSizeSelectorState },
    Cmd.map(Action.dispatchEccSizeSelectorActions, eccSizeSelectorCmd)
  ];
};

export const Action = ctorsUnion({
  dispatchEccSizeSelectorActions: (action: EccSizeSelector.Action) => ({
    action
  }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "dispatchEccSizeSelectorActions": {
      if (!state?.eccSizeSelectorState) {
        return [state];
      }

      const [
        eccSizeSelectorState,
        eccSizeSelectorCmd,
        sharedStateAction,
        didSizeChanged
      ] = EccSizeSelector.update(action.action, state.eccSizeSelectorState);

      const size = EccSizeSelector.getSize(eccSizeSelectorState);

      if (didSizeChanged && size) {
        return [
          {
            ...state,
            eccSizeSelectorState: eccSizeSelectorState,
            ok: true,
            newProperties: PropertyValueSet.setAmount(
              "padheight",
              size,
              state.newProperties
            )
          }
        ];
      } else {
        return [
          {
            ...state,
            eccSizeSelectorState: eccSizeSelectorState
          },
          Cmd.map(Action.dispatchEccSizeSelectorActions, eccSizeSelectorCmd),
          sharedStateAction ? [sharedStateAction] : []
        ];
      }
    }
    case "dummy":
      return [state];
    default:
      exhaustiveCheck(action, true);
  }
}
