import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createArrowLeftSvg(color: string): string {
  const svgString = `
<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 <title>93793EB6-167F-46D5-8D71-7071577D2F2E</title>
 <defs>
  <polygon id="path-1" points="0 0.125 5 5.125 10 0.125"/>
 </defs>
 <g>
  <title>Layer 1</title>
  <g id="UI-Kit" fill="none" fill-rule="evenodd" transform="rotate(90 5,5.000000000000001) ">
   <g id="UI-Kit---5.Icons-&amp;-Illustrations">
    <g id="Icons/Actions/Dropdown">
     <mask transform="translate(-0.5,0) " id="mask-2">
      <use xlink:href="#path-1" id="svg_1"/>
     </mask>
     <use id="Shape" fill="${color}" xlink:href="#path-1" y="2.375" x="0"/>
    </g>
   </g>
  </g>
 </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
