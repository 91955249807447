import styled from "styled-components";
import { tabNavBorderColor } from "../colors";
import * as React from "react";

interface TabNavItemProps {
  readonly active: boolean;
}

// tslint:disable-next-line
const UnStyledTabNavItem: React.FunctionComponent<
  TabNavItemProps & {
    readonly className?: string;
    readonly children: React.ReactNode;
  }
> = props => <li className={props.className}>{props.children}</li>;

// tslint:disable-next-line
export const TabNavItem = styled(UnStyledTabNavItem)`
  position: relative;

  padding: 4px 35px 4px 10px;
  margin-right: 5px;

  border: 1px solid ${tabNavBorderColor};
  border-bottom: 0;
  border-radius: 5px 5px 0 0;

  font-size: 14px;
  font-weight: bold;

  background: ${props =>
    props.active
      ? "linear-gradient(to top, rgb(84, 192, 242), rgb(0, 168, 235))"
      : "linear-gradient(to top, rgb(252, 252, 252) 10%, rgb(238, 240, 236))"};

  a:link,
  a:visited,
  a:hover,
  a:active,
  a {
    color: ${props => (props.active ? "white" : "rgb(102, 102, 102)")};
    text-decoration: none;
  }

  &:hover {
    background: linear-gradient(to top, rgb(84, 192, 242), rgb(0, 168, 235));

    a:link,
    a:visited,
    a:hover,
    a:active,
    a {
      color: white;
    }
  }
`;
