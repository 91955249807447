import * as Types from "./types";
import { Math } from "@munters/calculations";

export const edgeLength = 40;
export const routedLineStepSize = 30;

export function getNodeNeighbours(
  diagram: Types.Diagram,
  node: Types.Node
): Array<Types.Node> {
  const outgoing = diagram.edges
    .filter(e => e.node1 === node.componentId && e.node1Box === node.box)
    .map(e => ({ id: e.node2, box: e.node2Box }));
  const ingoing = diagram.edges
    .filter(e => e.node2 === node.componentId && e.node2Box === node.box)
    .map(e => ({ id: e.node1, box: e.node1Box }));

  return [...ingoing, ...outgoing]
    .map(
      t => diagram.nodes.find(n => n.componentId === t.id && n.box === t.box)!
    )
    .reduce(
      // tslint:disable-next-line
      (a, b) => (!~a.indexOf(b) ? a.concat(b) : a),
      [] as Array<Types.Node>
    );
}

export function getConnections(
  nodes: ReadonlyArray<Types.Node>,
  edges: ReadonlyArray<Types.Edge>
): ReadonlyArray<{
  readonly fromNodeId: string;
  readonly toNodeId: string;
}> {
  return nodes.reduce(
    (
      soFarTotalConnections: {
        readonly fromNodeId: string;
        readonly toNodeId: string;
      }[],
      currentNode
    ) => {
      for (const port of currentNode.ports) {
        if (port.connectionType === "Outlet") {
          let edge = edges.find(
            e =>
              e.node1 === currentNode.componentId &&
              e.node1Box === currentNode.box &&
              e.node1Section === port.componentSectionId
          );
          if (!edge) {
            continue;
          }
          let toNode = nodes.find(
            n =>
              n.componentId === edge!.node2 &&
              n.box === edge!.node2Box &&
              !!n.ports.find(
                p =>
                  p.connectionType === "Inlet" &&
                  p.componentSectionId === edge!.node2Section
              )
          );
          if (!toNode) {
            continue;
          }
          if (
            !soFarTotalConnections.find(
              c => c.fromNodeId === currentNode.id && c.toNodeId === toNode!.id
            )
          ) {
            soFarTotalConnections.push({
              fromNodeId: currentNode.id,
              toNodeId: toNode!.id
            });
          }
        } else {
          let edge = edges.find(
            e =>
              e.node2 === currentNode.componentId &&
              e.node2Box === currentNode.box &&
              e.node2Section === port.componentSectionId
          );
          if (!edge) {
            continue;
          }
          let fromNode = nodes.find(
            n =>
              n.componentId === edge!.node1 &&
              n.box === edge!.node1Box &&
              !!n.ports.find(
                p =>
                  p.connectionType === "Outlet" &&
                  p.componentSectionId === edge!.node1Section
              )
          );
          if (!fromNode) {
            continue;
          }

          if (
            !soFarTotalConnections.find(
              c =>
                c.fromNodeId === fromNode!.id && c.toNodeId === currentNode.id
            )
          ) {
            soFarTotalConnections.push({
              fromNodeId: fromNode!.id,
              toNodeId: currentNode.id
            });
          }
        }
      }

      return soFarTotalConnections;
    },
    []
  );
}

export function getConnectedNodes(
  nodes: ReadonlyArray<Types.Node>,
  edges: ReadonlyArray<Types.Edge>,
  node: Types.Node
): ReadonlyArray<{
  readonly port: Types.Port;
  readonly edge: Types.Edge;
  readonly nextNode: Types.Node;
  readonly nextPort: Types.Port;
}> {
  let connectedNodes: {
    readonly port: Types.Port;
    readonly edge: Types.Edge;
    readonly nextNode: Types.Node;
    readonly nextPort: Types.Port;
  }[] = [];

  for (const port of node.ports) {
    let nextNode: Types.Node | undefined = undefined;
    let edge: Types.Edge | undefined = undefined;

    if (port.connectionType === "Outlet") {
      edge = edges.find(
        e =>
          e.node1 === node.componentId &&
          e.node1Box === node.box &&
          e.node1Section === port.componentSectionId
      );
      if (!edge) {
        continue;
      }

      nextNode = nodes.find(
        n =>
          n.componentId === edge!.node2 &&
          n.box === edge!.node2Box &&
          !!n.ports.find(
            p =>
              p.connectionType === "Inlet" &&
              p.componentSectionId === edge!.node2Section
          )
      );
      if (!nextNode) {
        continue;
      }

      const nextPort = nextNode.ports.find(
        p =>
          p.connectionType === "Inlet" &&
          p.componentSectionId === edge!.node2Section
      )!;

      connectedNodes.push({
        edge: edge,
        nextNode: nextNode,
        port: port,
        nextPort: nextPort
      });
    } else {
      edge = edges.find(
        e =>
          e.node2 === node.componentId &&
          e.node2Box === node.box &&
          e.node2Section === port.componentSectionId
      );
      if (!edge) {
        continue;
      }
      nextNode = nodes.find(
        n =>
          n.componentId === edge!.node1 &&
          n.box === edge!.node1Box &&
          !!n.ports.find(
            p =>
              p.connectionType === "Outlet" &&
              p.componentSectionId === edge!.node1Section
          )
      );
      if (!nextNode) {
        continue;
      }

      const nextPort = nextNode.ports.find(
        p =>
          p.connectionType === "Outlet" &&
          p.componentSectionId === edge!.node1Section
      )!;

      connectedNodes.push({
        edge: edge,
        nextNode: nextNode,
        port: port,
        nextPort: nextPort
      });
    }
  }
  return connectedNodes;
}

export function getNodeSymbolBounds(
  node: Types.Node,
  onlyClickable: boolean
): ReadonlyArray<Math.BoundingRect.BoundingRect> {
  return node.symbols
    .filter(s => s.clickable || !onlyClickable)
    .map(s => {
      const transform = Math.Matrix2.multiply(node.transform, s.transform);
      const symbolBounds = Math.BoundingRect.transform(
        transform,
        Math.BoundingRect.create(
          Math.Vector2.vec2Zero,
          Math.Vector2.vec2Scale(s.size, 0.5)
        )
      );
      return symbolBounds;
    });
}

export function getPortsAndPositions(
  diagram: Types.Diagram,
  node: Types.Node,
  port: Types.Port
): {
  readonly port1: Types.Port | undefined;
  readonly startPoint: Math.Vector2.Vector2;
  readonly port2: Types.Port | undefined;
  readonly endPoint: Math.Vector2.Vector2;
} {
  if (port.connectionType === "Outlet") {
    const node1 = { ...node };
    const port1 = { ...port };

    const startPoint = Math.Matrix2.apply(
      Math.Matrix2.apply(Math.Vector2.vec2Zero, port1.transform),
      node1.transform
    );

    const edge = diagram.edges.find(
      e =>
        e.node1 === node1.componentId &&
        e.node1Box === node1.box &&
        e.node1Section === port.componentSectionId
    );
    if (edge !== undefined) {
      const node2 = diagram.nodes.find(
        n => n.componentId === edge.node2 && n.box === edge.node2Box
      );
      if (node2 !== undefined) {
        const ports2 = node2.ports.filter(
          p =>
            p.connectionType === "Inlet" &&
            p.componentSectionId === edge.node2Section
        );
        if (ports2.length === 0) {
          throw new Error(
            "Found no inlet connection point for " + node.productId
          );
        } else if (ports2.length > 1) {
          throw new Error(
            "Found multiple inlet connection points for " + node.productId
          );
        }

        const port2 = ports2[0];

        const endPoint = Math.Matrix2.apply(
          Math.Matrix2.apply(Math.Vector2.vec2Zero, port2.transform),
          node2.transform
        );
        return {
          port1: port1,
          startPoint: startPoint,
          port2: port2,
          endPoint: endPoint
        };
      }
    }

    const endPoint = Math.Matrix2.apply(
      Math.Matrix2.apply(
        Math.Vector2.vec2Create(edgeLength, 0),
        port1.transform
      ),
      node1.transform
    );
    return {
      port1: port1,
      startPoint: startPoint,
      port2: undefined,
      endPoint: endPoint
    };
  } else {
    const node2 = { ...node };
    const port2 = { ...port };

    const endPoint = Math.Matrix2.apply(
      Math.Matrix2.apply(Math.Vector2.vec2Zero, port2.transform),
      node2.transform
    );

    const edge = diagram.edges.find(
      e =>
        e.node2 === node.componentId &&
        e.node2Box === node.box &&
        e.node2Section === port.componentSectionId
    );
    if (edge !== undefined) {
      const node1 = diagram.nodes.find(
        n => n.componentId === edge.node1 && n.box === edge.node1Box
      );
      if (node1 !== undefined) {
        const ports1 = node1.ports.filter(
          p =>
            p.connectionType === "Outlet" &&
            p.componentSectionId === edge.node1Section
        );

        if (ports1.length === 0) {
          throw new Error(
            "Found no outlet connection point for " + node.productId
          );
        } else if (ports1.length > 1) {
          throw new Error(
            "Found multiple outlet connection points for " + node.productId
          );
        }

        const port1 = ports1[0];

        const startPoint = Math.Matrix2.apply(
          Math.Matrix2.apply(Math.Vector2.vec2Zero, port1.transform),
          node1.transform
        );
        return {
          port1: port1,
          startPoint: startPoint,
          port2: port2,
          endPoint: endPoint
        };
      }
    }

    const startPoint = Math.Matrix2.apply(
      Math.Matrix2.apply(
        Math.Vector2.vec2Create(-edgeLength, 0),
        port2.transform
      ),
      node2.transform
    );
    return {
      port1: undefined,
      startPoint: startPoint,
      port2: port2,
      endPoint: endPoint
    };
  }
}

export function getNodeBounds(
  node: Types.Node
): Math.BoundingRect.BoundingRect {
  return getNodeSymbolBounds(node, false).reduce(
    (a, b) => Math.BoundingRect.unionBoundingRect(a, b),
    Math.BoundingRect.empty
  );
}
