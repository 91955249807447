import styled from "styled-components";
import * as PropertySelectors from "../react-properties-selector";
import * as QuantitySelector from "../quantity-selector";
import { createAmountFormatSelector } from "./amount-format-selector";

const amountFormatWrapper = styled(PropertySelectors.AmountFormatWrapper)`
  background: transparent;
  font-size: 12px;
`;

const amountFormatWrapperWithPadding = styled(amountFormatWrapper)`
  padding-left: 5px;
`;
// tslint:disable-next-line:variable-name
export const AmountFormatSelectorWithPadding = createAmountFormatSelector({
  AmountFormatWrapper: amountFormatWrapperWithPadding,
  CancelButton: styled.button`
    &::after {
      content: "X";
    }
  ` as any,
  ClearButton: styled.button`
    &::after {
      content: "C";
    }
  ` as any
});

// tslint:disable-next-line:variable-name
export const DefaultAmountFormatSelector = createAmountFormatSelector({
  AmountFormatWrapper: amountFormatWrapper,
  CancelButton: styled.button`
    &::after {
      content: "X";
    }
  ` as any,
  ClearButton: styled.button`
    &::after {
      content: "C";
    }
  ` as any
});

// tslint:disable-next-line:variable-name
export const StyledQuantitySelector = styled(QuantitySelector.Component)`
  display: inline-block;
`;
