import gql from "graphql-tag";

export const updateLabelMutation = gql`
  mutation UpdateLabel($label: LabelInputType!) {
    updateLabel(label: $label) {
      id
      name
    }
  }
`;
