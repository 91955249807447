import * as React from "react";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../../graphql-types";
import { State, Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { TableIcon, H1 } from "@genesys/ui-elements";
import { SearchOptionsContainer } from "../elements";
import {
  TopContainer,
  HeadingContainer,
  SearchOptionsComponent,
  SearchOption
} from "../../shared-manager-components";
import { SearchParams } from "../types";

interface PricingSearchFields {
  readonly pno: string;
  readonly label: string;
  readonly name: string;
  readonly status: string;
  readonly gno: string;
  readonly owner: string;
}

const searchOptions: SearchOption<PricingSearchFields>[] = [
  {
    type: "text",
    option: "pno",
    textId: "pno"
  },
  {
    type: "text",
    option: "label",
    textId: "label"
  },
  {
    type: "text",
    option: "name",
    textId: "name"
  },
  {
    type: "text",
    option: "owner",
    textId: "owner"
  },
  {
    type: "text",
    option: "gno",
    textId: "gno"
  },
  {
    option: "status",
    statusOptions: [
      {
        value: "0",
        title: "Created"
      },
      {
        value: "90",
        title: "Locked"
      }
    ],
    type: "status-search",
    textId: "name"
  }
];

export function TopContentView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const setScopeAndSearch = (scope: GraphQLTypes.PricingSearchScopeEnum) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      scope: scope
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search());
  };

  const applySearchOptionsAndSearch = (searchKey: string) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      searchKey: searchKey
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search());
    dispatch(Action.toggleIsSearchFilterOptionsOpen(false));
  };

  return (
    <TopContainer>
      <HeadingContainer>
        <TableIcon height="1.6rem" />
        <H1 weight="normal">
          {sharedState.translate(LanguageTexts.pricingmanager())}
        </H1>
      </HeadingContainer>
      <SearchOptionsContainer>
        <SearchOptionsComponent
          sharedState={sharedState}
          searchKey={state.searchParams.searchKey}
          isSearchFilterOptionsOpen={state.isSearchFilterOptionsOpen}
          selectedScope={state.searchParams.scope}
          searchOptions={searchOptions}
          scopes={[
            {
              value: GraphQLTypes.PricingSearchScopeEnum.MY,
              title: sharedState.translate(LanguageTexts.myPricings())
            },
            {
              value: GraphQLTypes.PricingSearchScopeEnum.ALL,
              title: sharedState.translate(LanguageTexts.allPricings())
            }
          ]}
          onSearch={() => dispatch(Action.search())}
          onScopeChange={scope =>
            setScopeAndSearch(scope as GraphQLTypes.PricingSearchScopeEnum)
          }
          onSearchKeyChanged={searchKey =>
            dispatch(
              Action.setSearchParams({
                ...state.searchParams,
                searchKey: searchKey
              })
            )
          }
          onSearchFilterOptionsToggle={value =>
            dispatch(Action.toggleIsSearchFilterOptionsOpen(value))
          }
          onSearchOptionsSearch={seachKey =>
            applySearchOptionsAndSearch(seachKey)
          }
          onClearSearchOptions={() => dispatch(Action.clearSearchFilter())}
          onToggleHelpModal={() => dispatch(Action.toggleHelpModal())}
        />
      </SearchOptionsContainer>
    </TopContainer>
  );
}
