import {
  MutationsQueue,
  queueMutation,
  dequeueMutation
} from "./mutations-queue";
import * as Actions from "./mutations-queue-actions";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type MutationsQueueState = MutationsQueue;

// tslint:disable-next-line:variable-name
export const EmptyQueue: MutationsQueueState = {
  queuedMutations: []
};

export function reducer(
  state: MutationsQueueState,
  action: Actions.Action
): MutationsQueueState {
  switch (action.type) {
    case "QueueMany": {
      let newState = state;
      for (const queueMutationDescription of action.queueMutationDescriptions) {
        newState = queueMutation(queueMutationDescription, newState);
      }
      return newState;
    }
    case "Dequeue": {
      return dequeueMutation(action.mutation, state);
    }
    case "DequeueMany": {
      let newState = state;
      for (const m of action.mutations) {
        newState = dequeueMutation(m, newState);
      }
      return newState;
    }
    default: {
      exhaustiveCheck(action);
      return state;
    }
  }
}
