import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Unit, Quantity, Amount, Units } from "@genesys/uom";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as GraphQlTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import { UserEnergySettings } from "../types";
import {
  buildCostSettings,
  buildSystemEnergyItems,
  buildStaticEnergyItems,
  buildSettingsDictionary
} from "./functions";

export type State = {
  readonly energyItems: ReadonlyArray<SharedEnergyTools.EnergyItem>;
  readonly costSettings: PropertyValueSet.PropertyValueSet;
  readonly initialCostSettings: PropertyValueSet.PropertyValueSet;
  readonly userCostSettings: UserEnergySettings;
};

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  productData: GraphQlTypes.EnergyInputQueryProduct
): [State] => {
  const energyItems = [
    ...buildSystemEnergyItems(
      productData.product.systemType.energyTotals,
      system.components
    ),
    ...buildStaticEnergyItems()
  ];

  const userEnergySettings = buildSettingsDictionary(
    sharedState.user.settings.energySettings
  );

  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );

  const costSettings = buildCostSettings(
    energyItems,
    system.file.systemTypeId,
    userEnergySettings,
    systemBinSelections
  );

  const userCostSettings = {
    [KnownProperties.energyPriceInput + system.file.systemTypeId]:
      PropertyValueSet.toString(costSettings)
  };

  return [
    {
      energyItems: energyItems,
      costSettings: costSettings,
      initialCostSettings: costSettings,
      userCostSettings: userCostSettings
    }
  ];
};

export const Action = ctorsUnion({
  setEnergyCost: (
    propertyName: string,
    propertyValue: PropertyValue.PropertyValue,
    pvs: PropertyValueSet.PropertyValueSet
  ) => ({
    propertyName,
    propertyValue,
    pvs
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  system: System.System
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "setEnergyCost": {
      const updateCostSettings = () => {
        const pvs = PropertyValueSet.set(
          action.propertyName,
          action.propertyValue,
          action.pvs
        );

        switch (action.propertyName) {
          case KnownProperties.coolingWaterCostPerUnitEnergy:
          case KnownProperties.coolingWaterCop:
          case KnownProperties.heatingWaterCostPerUnitEnergy:
          case KnownProperties.heatingWaterEfficiency:
          case KnownProperties.steamCostPerUnitEnergy:
          case KnownProperties.steamEfficiency: {
            const costPerEnergyName = action.propertyName.startsWith("Cooling")
              ? KnownProperties.coolingWaterCostPerUnitEnergy
              : action.propertyName.startsWith("Heating")
              ? KnownProperties.heatingWaterCostPerUnitEnergy
              : KnownProperties.steamCostPerUnitEnergy;

            const factorName = action.propertyName.startsWith("Cooling")
              ? KnownProperties.coolingWaterCop
              : action.propertyName.startsWith("Heating")
              ? KnownProperties.heatingWaterEfficiency
              : KnownProperties.steamEfficiency;

            const costName = action.propertyName.startsWith("Cooling")
              ? KnownProperties.coolingWaterCost
              : action.propertyName.startsWith("Heating")
              ? KnownProperties.heatingWaterCost
              : KnownProperties.steamCost;

            const costPerEnergy = Amount.valueAs(
              Units.OnePerKiloWattHour,
              PropertyValueSet.getAmount<Quantity.DimensionlessPerEnergy>(
                costPerEnergyName,
                pvs
              )!
            );

            const factor = Amount.valueAs(
              Units.One,
              PropertyValueSet.getAmount<Quantity.Dimensionless>(
                factorName,
                pvs
              )!
            );

            const adjustedCost = PropertyValue.fromAmount(
              Amount.create(costPerEnergy / factor, Units.OnePerKiloWattHour)
            );

            return PropertyValueSet.set(costName, adjustedCost, pvs);
          }

          default:
            return pvs;
        }
      };

      const newPvs = updateCostSettings();

      const userEnergySettingsPrice = {
        [KnownProperties.energyPriceInput + system.file.systemTypeId]:
          PropertyValueSet.toString(newPvs)
      };

      return [
        {
          ...state,
          costSettings: newPvs,
          userCostSettings: userEnergySettingsPrice
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getCostSettings(state: State) {
  return state.costSettings ?? PropertyValueSet.Empty;
}

export function getInitialCostSettings(state: State) {
  return state.initialCostSettings;
}

export function getUserCostSettings(state: State) {
  return state.userCostSettings;
}
