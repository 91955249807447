import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as Types from "./types";
import * as Tools from "./tools";

export const init = (
  binLocations: ReadonlyArray<Types.BinDataLocation>,
  coordinate?: Types.Coordinate
): State => {
  const defaultLocation = Tools.getDefaultLocation(binLocations, coordinate);
  const countries = Tools.groupLocations(binLocations);
  const country = countries.find(
    item => item.countryName === defaultLocation.countryName
  )!;

  const region = country.regions.find(
    item => item.regionName === defaultLocation.regionName
  )!;

  const location = region.locations.find(
    item => item.locationName === defaultLocation.locationName
  )!;

  return {
    selectedCountryIndex: countries.indexOf(country),
    selectedRegionIndex: country.regions.indexOf(region),
    selectedLocationIndex: region.locations.indexOf(location),
    countries,
    binLocationIndexMapper: new Map(
      binLocations.map(item => {
        const countryIndex = countries.findIndex(
          country => country.countryName === item.countryName
        );
        const regionIndex = countries[countryIndex].regions.findIndex(
          region => region.regionName === item.regionName
        );
        return [
          item.binDataLocationId,
          {
            countryIndex: countryIndex,
            regionIndex: regionIndex,
            locationIndex: countries[countryIndex].regions[
              regionIndex
            ].locations.findIndex(
              location => location.locationName === item.locationName
            )
          }
        ];
      })
    )
  };
};

export interface State {
  readonly selectedCountryIndex: number;
  readonly selectedRegionIndex: number;
  readonly selectedLocationIndex: number;
  readonly countries: Types.GroupedLocations;
  readonly binLocationIndexMapper: Map<
    string,
    {
      readonly countryIndex: number;
      readonly regionIndex: number;
      readonly locationIndex: number;
    }
  >;
}

export const Action = ctorsUnion({
  setSelectedCountry: (countryIndex: number) => ({
    countryIndex
  }),
  setSelectedRegion: (regionIndex: number) => ({
    regionIndex
  }),
  setSelectedLocation: (locationIndex: number) => ({
    locationIndex
  })
});
export type Action = CtorsUnion<typeof Action>;

type BinLocationId = string;
export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, BinLocationId] {
  switch (action.type) {
    case "setSelectedCountry": {
      return [
        {
          ...state,
          selectedCountryIndex: action.countryIndex,
          selectedRegionIndex: 0,
          selectedLocationIndex: 0
        },
        state.countries[action.countryIndex].regions[0].locations[0]
          .binDataLocationId
      ];
    }
    case "setSelectedRegion": {
      return [
        {
          ...state,
          selectedRegionIndex: action.regionIndex,
          selectedLocationIndex: 0
        },
        state.countries[state.selectedCountryIndex].regions[action.regionIndex]
          .locations[0].binDataLocationId
      ];
    }
    case "setSelectedLocation": {
      return [
        {
          ...state,
          selectedLocationIndex: action.locationIndex
        },
        state.countries[state.selectedCountryIndex].regions[
          state.selectedRegionIndex
        ].locations[action.locationIndex].binDataLocationId
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function GetSelectedLocation(
  state: State | undefined
): Types.BinDataLocation | undefined {
  if (state === undefined) {
    return undefined;
  }
  return state.countries[state.selectedCountryIndex].regions[
    state.selectedRegionIndex
  ].locations[state.selectedLocationIndex];
}
