import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { addOrUpdateMCompareComponentMutation } from "@genesys/client-core/lib/graphql-mutations";
import { promiseCmd } from "../../../promise-effect-manager";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { PropertyValueSet } from "@genesys/property";

export function addOrUpdateMCompareComponent(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<
    ConfiguratorAction,
    { readonly type: "addOrUpdateMCompareComponent" }
  >
): [Cmd<Action>, SystemStatus] {
  return [
    promiseCmd<Action, {}>(
      async () => {
        await sharedState.graphQL.queryUser<
          GraphQLTypes.AddOrUpdateMCompareComponent,
          GraphQLTypes.AddOrUpdateMCompareComponentVariables
        >(addOrUpdateMCompareComponentMutation, {
          input: {
            systemId,
            productId: action.productId,
            properties: PropertyValueSet.toString(action.settings)
          }
        });

        return {};
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.ValidateConfigurationSuccess
  ];
}
