import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SelectBox } from "../components";
import {
  SummaryItem,
  SelectedColumn,
  SummaryType,
  ColumnGroup
} from "../types";
import { Action } from "../state";

export function SummaryItemGroup({
  selectedSummaryItems,
  summaryItem,
  dispatch
}: {
  readonly summaryItem: SummaryItem;
  readonly selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>;
  readonly dispatch: Dispatch<Action>;
}) {
  const selectedColumns =
    selectedSummaryItems.get(summaryItem.type) || new Set();
  const allIsSelected =
    selectedColumns.size === summaryItem.selectableColumns.length;

  const handleSelectAll = () => {
    if (allIsSelected) {
      dispatch(Action.setSelectedSummaryItems(summaryItem.type, new Set()));
    } else {
      const allColumnNames = summaryItem.selectableColumns.map(
        col => col.columnName
      );
      dispatch(
        Action.setSelectedSummaryItems(
          summaryItem.type,
          new Set(allColumnNames)
        )
      );
    }
  };

  const handleSelectItem = (columnName: ColumnGroup["columnName"]) => {
    if (selectedColumns.has(columnName)) {
      selectedColumns.delete(columnName);
    } else {
      selectedColumns.add(columnName);
    }
    dispatch(Action.setSelectedSummaryItems(summaryItem.type, selectedColumns));
  };

  const selectItems = summaryItem.selectableColumns.map(col => {
    const columnName = col.columnName;
    const isSelected = selectedColumns.has(columnName);

    return {
      isChecked: isSelected,
      isDisabled: false,
      onClick: () => handleSelectItem(columnName),
      title: col.displayName
    };
  });

  return (
    <SelectBox
      key={summaryItem.type}
      color={summaryItem.backgroundColor}
      isAllSelected={allIsSelected}
      onSelectAll={handleSelectAll}
      selectItems={selectItems}
      title={summaryItem.title}
    />
  );
}
