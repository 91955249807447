import React from "react";
import {
  DiscretePropertySelectorOptions,
  useDiscretePropertySelector
} from "@promaster-sdk/react-property-selectors";
import {
  GenesysTableSelect,
  GenesysTableOption,
  Img
} from "@genesys/ui-elements";
import { ItemLabel, RedItemLabel } from "./elements";

export function DiscretePropertySelector(
  options: DiscretePropertySelectorOptions<{
    readonly sortNo: number;
    readonly text: string;
    readonly image?: string;
    readonly height?: string;
  }> & {
    readonly lockedByValueSource: boolean;
    readonly accessToken: string;
  }
): JSX.Element {
  const sel = useDiscretePropertySelector(options);
  const selectorProps = sel.getSelectProps();

  if (sel.disabled || selectorProps.disabled || options.lockedByValueSource) {
    return (
      <ItemLabel title={selectorProps.title}>
        {sel.getItemLabel(sel.selectedItem.text, sel.selectedItem)}
      </ItemLabel>
    );
  }

  const getImgHeight = (height: string | undefined) => {
    if (height === undefined) {
      return "95";
    }
    return Math.min(...[parseInt(height, 10), 95]);
  };

  return (
    <GenesysTableSelect {...selectorProps}>
      {sel.items.map(item => (
        <GenesysTableOption
          key={item.sortNo}
          {...sel.getSelectOptionProps(item)}
        >
          {item.image && (
            <Img
              height={getImgHeight(item.height)}
              src={item.image}
              accessToken={options.accessToken}
            />
          )}
          {sel.isItemValid(item) ? (
            <ItemLabel>{sel.getItemLabel(item.text, item)}</ItemLabel>
          ) : (
            <RedItemLabel>{sel.getItemLabel(item.text, item)}</RedItemLabel>
          )}
        </GenesysTableOption>
      ))}
    </GenesysTableSelect>
  );
}
