import gql from "graphql-tag";

export const updateReportSettingsParam = gql`
  mutation UpdateReportSettingsParam(
    $reportParamSettings: UpdateReportParamSettingsInputType!
  ) {
    updateReportSettingsParam(reportParamSettings: $reportParamSettings) {
      id
    }
  }
`;
