import * as GraphQLTypes from "@genesys/graphql-types";
import { PropertyValueSet } from "@genesys/property";
import { CaseType, FullLocation } from "./types";
import * as KnownProperties from "./known-properties";
import { InternalRepresentation } from "./internal-representation";
import { Quantity, Amount, Units } from "@genesys/uom";
import {
  parseFormattedAnnualOccurence,
  makeClimateAnnualOccurence,
  ClimateAnnualOccurence
} from "./climate-annual-occurences";
import { makeClimateDataType, ClimateDataType } from "./climate-data-types";
import * as QuantityConverison from "@genesys/shared/lib/quantity-conversion";

export function validate(
  temperature: Amount.Amount<Quantity.Temperature>,
  pressure: Amount.Amount<Quantity.Pressure>,
  humidity: Amount.Amount<Quantity.Quantity>
): boolean {
  const relativeHum =
    humidity.unit.quantity === "RelativeHumidity"
      ? (humidity as Amount.Amount<Quantity.RelativeHumidity>)
      : QuantityConverison.convertQuantity(humidity, "RelativeHumidity", {
          temperature: temperature,
          pressure: pressure
        });

  return (
    Amount.lessOrEqualTo(
      relativeHum,
      Amount.create(100, Units.PercentHumidity)
    ) &&
    Amount.greaterOrEqualTo(
      relativeHum,
      Amount.create(0, Units.PercentHumidity)
    )
  );
}

export function getAllowedDataTypes(
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  caseType: CaseType,
  annualOccurence: ClimateAnnualOccurence
): ReadonlyArray<ClimateDataType> {
  const caseDataPoints = dataPoints.filter(
    x => x.caseType === caseType.charAt(0).toUpperCase()
  );
  return Array.from(
    new Set(
      caseDataPoints
        .filter(x => x.annualOccurence === annualOccurence)
        .map(x => makeClimateDataType(x.climateDataType))
    )
  );
}

export function getAllowedOccurences(
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  caseType: CaseType,
  dataType: ClimateDataType
): ReadonlyArray<ClimateAnnualOccurence> {
  const caseDataPoints = dataPoints.filter(
    x => x.caseType === caseType.charAt(0).toUpperCase()
  );
  return Array.from(
    new Set(
      caseDataPoints
        .filter(x => x.climateDataType === dataType && x.annualOccurence)
        .map(occ => makeClimateAnnualOccurence(occ.annualOccurence!))
    )
  );
}
export function getAllowedDataTypesAndOccurences(
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  caseType: CaseType,
  dataType: ClimateDataType,
  annualOccurence: ClimateAnnualOccurence
): {
  readonly dataTypes: ReadonlyArray<ClimateDataType>;
  readonly annualOccurences: ReadonlyArray<ClimateAnnualOccurence>;
} {
  const caseDataPoints = dataPoints.filter(
    x => x.caseType === caseType.charAt(0).toUpperCase()
  );

  const dataTypes = Array.from(
    new Set(
      caseDataPoints
        .filter(x => x.annualOccurence === annualOccurence)
        .map(x => makeClimateDataType(x.climateDataType))
    )
  );

  const annualOccurences = Array.from(
    new Set(
      caseDataPoints
        .filter(x => x.climateDataType === dataType && x.annualOccurence)
        .map(occ => makeClimateAnnualOccurence(occ.annualOccurence!))
    )
  );

  return {
    dataTypes,
    annualOccurences
  };
}

export function getDataPoint(
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >,
  caseType: CaseType,
  dataType: ClimateDataType,
  annualOccurence: ClimateAnnualOccurence
): NonNullable<
  GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
>[0] {
  const caseDataPoints = dataPoints.filter(
    x => x.caseType === caseType.charAt(0).toUpperCase()
  );

  const dataPoint = caseDataPoints.find(
    occurence =>
      occurence.climateDataType === dataType &&
      occurence.annualOccurence === annualOccurence
  );

  if (!dataPoint) {
    throw new Error("Climate data point not found.");
  }

  return dataPoint;
}

export function getDefaults(
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >,
  userClimate: GraphQLTypes.ClimateDataDialog_UserProfileQuery["user"]["userProfile"]["userSettings"]["climate"]
): InternalRepresentation {
  const hardcodedDefaults = {
    climateDataSource: "ASHRAE",
    altitude: Amount.create(0, Units.Meter),
    customPressure: 0,
    atmosphericPressure: Amount.create(101325, Units.Pascal),
    summerHumidity: Amount.create(10, Units.GramPerKilogram),
    summerTemperature: Amount.create(30, Units.Celsius),
    summerWindSpeed: Amount.create(0, Units.MeterPerSecond),
    winterHumidity: Amount.create(1, Units.GramPerKilogram),
    winterTemperature: Amount.create(-10, Units.Celsius),
    winterWindSpeed: Amount.create(0, Units.MeterPerSecond),
    coolingAnnualOccurence: "0.4 %",
    heatingAnnualOccurence: "99.6 %",
    coolingDataType: "DB/MCWB",
    heatingDataType: "DB"
  };
  const manualData = PropertyValueSet.fromString(userClimate.manualData || "");
  const country =
    countries.find(item => item.id === userClimate.country!.id) || countries[0];

  const region =
    country.regions.find(item => item.id === userClimate.region!.id) ||
    country.regions[0];

  const location =
    (userClimate.location &&
      region.locations.find(item => item.id === userClimate.location!.id)) ||
    region.locations[0];

  const common = {
    customPressure: undefined,
    locationId: location.id
  };

  const climateDataSource =
    userClimate.climateDataSource || hardcodedDefaults.climateDataSource;

  if (climateDataSource === "ASHRAE") {
    return {
      ...common,
      altitude:
        PropertyValueSet.getAmount(
          KnownProperties.manualData.altitude,
          manualData
        ) || hardcodedDefaults.altitude,
      climateDataSource,
      // TODO: Cooling/heating should pick an available option depending on the location.
      cooling: {
        dataType: makeClimateDataType(userClimate.coolingDataType || "DB/MCWB"),
        annualOccurence: makeClimateAnnualOccurence(
          userClimate.coolingOccurence || "04"
        )
      },
      heating: {
        dataType: makeClimateDataType(userClimate.heatingDataType || "DB"),
        annualOccurence: makeClimateAnnualOccurence(
          userClimate.heatingOccurence || "996"
        )
      }
    };
  }

  if (climateDataSource === "Manual") {
    return {
      ...common,
      climateDataSource,
      altitude:
        PropertyValueSet.getAmount(
          KnownProperties.manualData.altitude,
          manualData
        ) || hardcodedDefaults.altitude,
      cooling: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingDataType,
            manualData
          ) || hardcodedDefaults.coolingDataType
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingAnnualOccurence,
            manualData
          ) || hardcodedDefaults.coolingAnnualOccurence
        ),
        temperature:
          PropertyValueSet.getAmount<Quantity.Temperature>(
            KnownProperties.manualData.summerTemperature,
            manualData
          ) || hardcodedDefaults.summerTemperature,
        humidity:
          PropertyValueSet.getAmount<Quantity.HumidityRatio>(
            KnownProperties.manualData.summerHumidity,
            manualData
          ) || hardcodedDefaults.summerHumidity,
        windSpeed:
          PropertyValueSet.getAmount<Quantity.Velocity>(
            KnownProperties.manualData.summerWindSpeed,
            manualData
          ) || hardcodedDefaults.summerWindSpeed
      },
      heating: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingDataType,
            manualData
          ) || hardcodedDefaults.heatingDataType
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingAnnualOccurence,
            manualData
          ) || hardcodedDefaults.heatingAnnualOccurence
        ),
        temperature:
          PropertyValueSet.getAmount<Quantity.Temperature>(
            KnownProperties.manualData.winterTemperature,
            manualData
          ) || hardcodedDefaults.winterTemperature,
        humidity:
          PropertyValueSet.getAmount<Quantity.HumidityRatio>(
            KnownProperties.manualData.winterHumidity,
            manualData
          ) || hardcodedDefaults.winterHumidity,
        windSpeed:
          PropertyValueSet.getAmount<Quantity.Velocity>(
            KnownProperties.manualData.winterWindSpeed,
            manualData
          ) || hardcodedDefaults.winterWindSpeed
      }
    };
  }

  throw new Error('"' + climateDataSource + '" is not a valid data source.');
}

export function getLocation(
  locationId: string,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >
): FullLocation {
  //   Find the country, region and location in the tree structure.
  let countryData:
    | {
        readonly country: GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0];
        readonly index: number;
      }
    | undefined = undefined;

  let regionData:
    | {
        readonly region: GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]["regions"][0];
        readonly index: number;
      }
    | undefined = undefined;
  let locationData:
    | {
        readonly location: GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]["regions"][0]["locations"][0];
        readonly index: number;
      }
    | undefined = undefined;
  let countryIndex: number = 0;

  // tslint:disable-next-line:no-label
  outerFor: for (const currentCountry of countries) {
    let regionIndex: number = 0;
    for (const currentRegion of currentCountry.regions) {
      const gqlLocation = currentRegion.locations.find(
        currentLocation => currentLocation.id === locationId
      );
      if (gqlLocation) {
        countryData = { country: currentCountry, index: countryIndex };
        regionData = { region: currentRegion, index: regionIndex };
        locationData = {
          location: gqlLocation,
          index: currentRegion.locations.indexOf(gqlLocation)
        };
        break outerFor;
      }
      regionIndex++;
    }
    countryIndex++;
  }

  if (!(locationData && regionData && countryData)) {
    throw new Error("location not found");
  }

  return {
    countryData,
    regionData,
    locationData
  };
}
