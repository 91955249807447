import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { Quantity } from "@genesys/uom";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQlTypes from "../graphql-types";
import { CaseType, ComponentCalculationType } from "@genesys/graphql-types";
import {
  PropertyInfo,
  DefaultValue,
  DescriptionText,
  PropertyItem,
  PropertyValueSource
} from "../properties-selector";

export type ProductType = GraphQlTypes.ProductTypeEnum;
export const PropertyTypeEnum = GraphQlTypes.ProductTypeEnum;

export type ScreenType = GraphQlTypes.ScreenType;
export const ScreenTypeEnum = GraphQlTypes.ScreenType;

export interface Product {
  readonly id: string;
  readonly images: ReadonlyArray<Image>;
  readonly optional: boolean;
  readonly productType: ProductType;
  readonly sections: ReadonlyArray<ProductSection>;
  readonly menuGroup: string | undefined;
  readonly rangeFilter: PropertyFilter.PropertyFilter;
  readonly boxSymbols: ReadonlyArray<BoxSymbol>;
  readonly boxConnectionPoints: ReadonlyArray<BoxConnectionPoint>;
  readonly boxTexts: ReadonlyArray<BoxText>;
  readonly boxLinePoints: ReadonlyArray<BoxLine>;
  readonly properties: ReadonlyArray<PropertyInfo>;
  readonly helpTextTable: HelpTextTable;
  readonly wikiJsPath: string | null | undefined;
  readonly caseFilters: ReadonlyArray<CaseFilter>;
  readonly genericPerfExtraInfoResults: ReadonlyArray<GenericPerfExtraInfoResult>;
}

export interface ProductSection {
  readonly id: string;
  readonly movable: boolean;
  readonly sortNo: number;
}

export type PositionValidation = GraphQlTypes.PositionValidation;
export const PositionValidationEnum = GraphQlTypes.PositionValidation;

export interface SystemType {
  readonly id: string;
  readonly airPointProperties: ReadonlyArray<AirPointProperty>;
  readonly positionValidation: PositionValidation;
  readonly placementRules: ReadonlyArray<PlacementRule>;
  readonly sequenceRules: ReadonlyArray<SequenceRule>;
  readonly screens: ReadonlyArray<Screen>;
  readonly images: ReadonlyArray<Image>;
  readonly symbols: ReadonlyArray<SymbolDefinition>;
  readonly plenumSizeProperty: string;
  readonly caseFilters: ReadonlyArray<CaseFilter>;
}

export type ImageUsage = GraphQlTypes.ImageUsage;
export const ImageUsageEnum = GraphQlTypes.ImageUsage;

export interface Image {
  readonly id: string;
  readonly imageUsage: ImageUsage;
  readonly name: string;
  readonly imageFormat: GraphQlTypes.ImageFormat;
  readonly url: string;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface Screen {
  readonly screenType: ScreenType;
  readonly claimFilter: string | undefined;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly screenParameters: string | undefined;
  readonly sortNo: number;
}

export interface CaseFilter {
  readonly caseType: CaseType;
  readonly calculationType: ComponentCalculationType;
  readonly claimFilter: string;
}

export interface GenericPerfExtraInfoResult {
  readonly sortNo: number;
  readonly name: string;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly group: string;
  readonly source: GenericExtraInfoSource;
}

export type GenericExtraInfoSource =
  | "Property"
  | "Result"
  | "Specification"
  | "FreeText"
  | "TextId";

export interface ProductData {
  readonly systemType: SystemType;
  readonly products: ReadonlyArray<Product>;
}

export interface PlacementRule {
  readonly id: string;
  readonly productSectionId1: string | undefined;
  readonly productSectionId2: string | undefined;
  readonly rangePropertyFilter: PropertyFilter.PropertyFilter;
}

export interface SequenceRule {
  readonly id: string;
  readonly name: string | undefined;
  readonly sequence: string;
  readonly rangePropertyFilter: PropertyFilter.PropertyFilter;
}

export interface BoxConnectionPoint {
  readonly id: string;
  readonly productSectionId: string;
  readonly box: string;
  readonly connectionType: ConnectionType;
  readonly positionX: number;
  readonly positionY: number;
  readonly positionZ: number;
  readonly mirrorX: boolean;
  readonly mirrorY: boolean;
  readonly rotation: number;
  readonly hideStatePoint: boolean;
  readonly diagramType: DiagramType;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface BoxSymbol {
  readonly id: string;
  readonly box: string;
  readonly symbolId: string;
  readonly positionX: number;
  readonly positionY: number;
  readonly positionZ: number;
  readonly mirrorX: boolean;
  readonly mirrorY: boolean;
  readonly rotation: number;
  readonly sizeX: number;
  readonly sizeY: number;
  readonly absoluteRotationAndScale: boolean;
  readonly useForSelectionBounds: boolean;
  readonly diagramType: DiagramType;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface BoxText {
  readonly id: string;
  readonly box: string;
  readonly source: string;
  readonly text: string;
  readonly fontSize: number;
  readonly positionX: number;
  readonly positionY: number;
  readonly positionZ: number;
  readonly rotation: number;
  readonly absolutePosition: boolean;
  readonly useAsLabel: boolean;
  readonly diagramType: DiagramType;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface BoxLine {
  readonly id: string;
  readonly box: string;
  readonly connectionId: string;
  readonly lineType: LineType;
  readonly positionX: number;
  readonly positionY: number;
  readonly direction: Direction;
  readonly diagramType: DiagramType;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
}

export interface SymbolDefinition {
  readonly symbolId: string;
  readonly svg: string;
  readonly width: number;
  readonly height: number;
}

export interface HelpTextTable {
  readonly rows: ReadonlyArray<{
    readonly propertyFilter?: string | null | undefined;
    readonly values: string;
  }>;
}

export interface Route {
  readonly id: string;
  readonly route: string;
  readonly url: string;
}

export type AirProperty = GraphQlTypes.AirProperty;
export const AirPropertyEnum = GraphQlTypes.AirProperty;

export interface AirPointProperty {
  readonly id: string;
  readonly name: string;
  readonly airProperty: GraphQlTypes.AirProperty;
  readonly propertyFilter?: string | null | undefined;
}

export type DiagramType = "FlowDiagram" | "PidDiagram" | "UnitConfiguration";
export type ConnectionType = "Inlet" | "Outlet";
export type LineType = "Air" | "Io" | "Thin";
export type Direction = "Up" | "Right" | "Down" | "Left";

export function parseProductData(
  data: GraphQlTypes.SystemConfiguratorProduct["product"],
  translate: LanguageTexts.Translate
): ProductData {
  return {
    systemType: {
      id: data.systemType.id,
      airPointProperties: data.systemType.airPointProperties.map(
        parseAirPointProperty
      ),
      images: data.systemType.product.images.map(parseImage),
      screens: data.systemType.screens.map(parseScreen),
      symbols: data.systemType.symbols.map(parseSymbolDefinition),
      positionValidation: data.systemType.positionValidation,
      placementRules:
        data.systemType.productSectionPlacementRules.map(parsePlacementRule),
      sequenceRules:
        data.systemType.productSectionSequenceRules.map(parseSequenceRule),
      plenumSizeProperty: data.systemType.plenumSizeProperty,
      caseFilters: data.systemType.systemTypeCaseFilter.map(parseCaseFilter)
    },
    products: data.systemType.allProducts.map(p => parseProduct(p, translate))
  };
}

function parseAirPointProperty(
  airPointProp: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["airPointProperties"][0]
): AirPointProperty {
  return {
    id: airPointProp.id,
    name: airPointProp.name,
    airProperty: airPointProp.airProperty,
    propertyFilter: airPointProp.propertyFilter
  };
}

function parsePlacementRule(
  rule: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["productSectionPlacementRules"][0]
): PlacementRule {
  return {
    id: rule.id,
    productSectionId1: rule.productSectionId1 ?? undefined,
    productSectionId2: rule.productSectionId2 ?? undefined,
    rangePropertyFilter: PropertyFilter.fromStringOrEmpty(
      rule.rangePropertyFilter ?? ""
    )
  };
}

function parseSequenceRule(
  rule: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["productSectionSequenceRules"][0]
): SequenceRule {
  return {
    id: rule.id,
    name: rule.name ?? undefined,
    sequence: rule.sequence,
    rangePropertyFilter: PropertyFilter.fromStringOrEmpty(
      rule.rangePropertyFilter ?? ""
    )
  };
}

function parseCaseFilter(
  caseFilter: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["systemTypeCaseFilter"][0]
): CaseFilter {
  return {
    caseType: caseFilter.caseType,
    calculationType: caseFilter.calculationType,
    claimFilter: ""
  };
}

function parseProduct(
  product: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0],
  translate: LanguageTexts.Translate
): Product {
  return {
    id: product.id,
    images: product.images.map(i => parseImage(i)),
    optional: product.optional,
    boxConnectionPoints: product.boxConnectionPoints.map(cp =>
      parseConnectionPoint(cp)
    ),
    boxSymbols: product.boxSymbols.map(s => parseSymbol(s)),
    boxTexts: product.boxTexts.map(t => parseText(t)),
    boxLinePoints: product.boxLinePoints.map(lp => parseLinePoint(lp)),
    properties: product.properties.map(p =>
      parseProperty(p, product.id, translate)
    ),
    helpTextTable: parserHelpTexts(product.helpText?.rows || []),
    menuGroup: product.menuGroup || undefined,
    productType: product.productType.type,
    rangeFilter: PropertyFilter.fromStringOrEmpty(product.rangeFilter ?? ""),
    sections: product.productSections.map(parseProductSection),
    wikiJsPath: product.wikiJsPath,
    caseFilters: parseProductCaseFilter(product.caseFilter),
    genericPerfExtraInfoResults:
      product.genericPerfOverViewResults?.rows.map(
        parseGenericPerfExtraInfoResult
      ) ?? []
  };
}

function parseGenericPerfExtraInfoResult(
  row: NonNullable<
    GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["genericPerfOverViewResults"]
  >["rows"][0]
): GenericPerfExtraInfoResult {
  const pvs = PropertyValueSet.fromString(row.values);
  return {
    name: PropertyValueSet.getText("Name", pvs) ?? "",
    propertyFilter: PropertyFilter.fromStringOrEmpty(row.propertyFilter || ""),
    group: PropertyValueSet.getText("Group", pvs) ?? "",
    source: PropertyValueSet.getText("Source", pvs) as GenericExtraInfoSource,
    sortNo: numberOrZero(row.sortNo)
  };
}

function parseProductCaseFilter(
  caseFilter?:
    | GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["caseFilter"]
    | null
    | undefined
): ReadonlyArray<CaseFilter> {
  if (!caseFilter) {
    return [];
  }
  return caseFilter.rows.map(r => {
    const pvs = PropertyValueSet.fromString(r.values);
    const caseType = PropertyValueSet.getText("CaseType", pvs)?.toUpperCase();
    const calculationType = PropertyValueSet.getText(
      "CalculationType",
      pvs
    )?.toUpperCase();
    const claimFilter = PropertyValueSet.getText("ClaimFilter", pvs) ?? "";
    return {
      caseType:
        GraphQlTypes.CaseType[caseType as keyof typeof GraphQlTypes.CaseType],
      calculationType:
        GraphQlTypes.ComponentCalculationType[
          calculationType as keyof typeof calculationType
        ],
      claimFilter: claimFilter
    };
  });
}

function parserHelpTexts(
  textTableRows: NonNullable<
    GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["helpText"]
  >["rows"]
): HelpTextTable {
  const rows = textTableRows.map(r => ({
    propertyFilter: r.propertyFilter,
    values: r.values
  }));
  return { rows };
}

function parseConnectionPoint(
  connectionPoint: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["boxConnectionPoints"][0]
): BoxConnectionPoint {
  return {
    ...connectionPoint,
    connectionType: connectionPoint.connectionType as ConnectionType,
    diagramType: connectionPoint.diagramType as DiagramType,
    propertyFilter: connectionPoint.propertyFilter
      ? PropertyFilter.fromString(connectionPoint.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseSymbol(
  symbol: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["boxSymbols"][0]
): BoxSymbol {
  return {
    ...symbol,
    diagramType: symbol.diagramType as DiagramType,
    propertyFilter: symbol.propertyFilter
      ? PropertyFilter.fromString(symbol.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseText(
  text: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["boxTexts"][0]
): BoxText {
  return {
    ...text,
    diagramType: text.diagramType as DiagramType,
    propertyFilter: text.propertyFilter
      ? PropertyFilter.fromString(text.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseLinePoint(
  linePoint: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["boxLinePoints"][0]
): BoxLine {
  return {
    ...linePoint,
    diagramType: linePoint.diagramType as DiagramType,
    direction: linePoint.direction as Direction,
    lineType: linePoint.lineType as LineType,
    propertyFilter: linePoint.propertyFilter
      ? PropertyFilter.fromString(linePoint.propertyFilter)!
      : PropertyFilter.Empty
  };
}

function parseProperty(
  property: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0],
  productId: string,
  translate: LanguageTexts.Translate
): PropertyInfo {
  const getText = (value: number) =>
    translate(
      LanguageTexts.productPropertyValue(productId, property.name, value)
    );

  return {
    name: property.name,
    quantity: property.quantity as Quantity.Quantity,
    group: property.groupName,
    sortNo: numberOrZero(property.sortNo),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      property.validationFilter
    ),
    visibilityFilter: PropertyFilter.fromStringOrEmpty(
      property.visibilityFilter
    ),
    items: property.values.map(v => parsePropertyValue(v, getText)),
    descriptionTexts: property.descriptionTexts.map(parseDescriptionTexts),
    valueSources: property.valueSources.map(parsePropertyValueSource),
    conversionParameters: property.quantityConversionParams,
    defaultValues: property.defaultValues.map(parseDefaultValue)
  };
}

function parseDescriptionTexts(
  x:
    | GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["descriptionTexts"][0]
    | GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["values"][0]["descriptionTexts"][0]
): DescriptionText {
  return {
    id: x.id,
    propertyFilter: PropertyFilter.fromStringOrEmpty(x.propertyFilter),
    language: x.language,
    text: x.text
  };
}

function parsePropertyValue(
  propertyValue: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["values"][0],
  getText: (value: number) => string
): PropertyItem {
  const value = PropertyValue.fromString(propertyValue.value)!;
  return {
    id: propertyValue.id,
    rangeFilter: PropertyFilter.fromStringOrEmpty(
      propertyValue.rangeFilter || ""
    ),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      propertyValue.validationFilter || ""
    ),
    value: value,
    sortNo: numberOrZero(propertyValue.sortNo),
    image: propertyValue.image || undefined,
    descriptionValuesTexts: propertyValue.descriptionTexts.map(
      parseDescriptionTexts
    ),
    text: getText(value.value as number)
  };
}

function parsePropertyValueSource(
  valueSource: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["valueSources"][0]
): PropertyValueSource {
  return {
    id: valueSource.id,
    claimFilter: valueSource.claimFilter,
    propertyFilter: PropertyFilter.fromStringOrEmpty(
      valueSource.propertyFilter || ""
    ),
    propertyValueSourceId: valueSource.propertyValueSourceId,
    value: valueSource.value,
    parameters: valueSource.parameters
  };
}

function parseDefaultValue(
  defaultValue: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["defaultValues"][0]
): DefaultValue {
  return {
    value: PropertyValue.fromString(defaultValue.value)!,
    propertyFilter: PropertyFilter.fromStringOrEmpty(
      defaultValue.propertyFilter
    )
  };
}

function parseImage(
  image: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["images"][0]
): Image {
  return {
    id: image.id,
    name: image.imageUsage,
    url: image.url,
    imageUsage: image.imageUsage,
    imageFormat: image.imageFormat,
    propertyFilter: PropertyFilter.fromStringOrEmpty(image.propertyFilter ?? "")
  };
}

function parseScreen(
  screen: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["screens"][0]
): Screen {
  return {
    screenType: screen.screenType,
    sortNo: screen.sortNo,
    screenParameters: screen.screenParameters ?? undefined,
    claimFilter: screen.claimFilter || undefined,
    propertyFilter: PropertyFilter.fromStringOrEmpty(
      screen.propertyFilter ?? ""
    )
  };
}

function parseSymbolDefinition(
  symbol: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["symbols"][0]
): SymbolDefinition {
  return {
    symbolId: symbol.symbolId,
    svg: symbol.svg,
    height: symbol.height,
    width: symbol.width
  };
}

function parseProductSection(
  section: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["productSections"][0]
): ProductSection {
  return {
    id: section.id,
    movable: section.movable,
    sortNo: section.sortNo
  };
}

function numberOrZero(value: number | null | undefined): number {
  return value !== null && value !== undefined ? value : 0;
}
//tslint:disable-next-line
