import * as React from "react";
import * as Types from "./types";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import {
  Amount,
  Quantity,
  Units,
  Unit,
  Format,
  UnitsFormat
} from "@genesys/uom";
import { Physics } from "@munters/calculations";
import * as ChartColor from "./chart-color";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AbstractChart from "abstract-chart";
import * as ChartAxisType from "@genesys/shared/lib/enums/chart-axis-type";
import * as AbstractImage from "abstract-image";
import * as Elements from "../elements";
import * as LocalElements from "./elements";
import * as KnownProperties from "./known-properties";

const intervalAlternatives = [
  0.001,
  0.002,
  0.005,
  0.01,
  0.02,
  0.05,
  0.1,
  0.2,
  0.5,
  1,
  2,
  2.5,
  5,
  7.5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  75,
  100,
  150,
  200,
  250,
  500,
  1000,
  2000,
  5000,
  10000,
  20000,
  50000,
  100000,
  200000,
  500000,
  1000000,
  5000000,
  10000000,
  20000000,
  50000000,
  100000000
];

function regroupBinResults(
  selectedClimateCoolingDatatType: Types.ClimateCoolingDataType,
  savedClimateCoolingDataType: Types.ClimateCoolingDataType,
  binSize: number,
  si: boolean,
  binResults: ReadonlyArray<Types.BinCaseResult>,
  binDataNames: Array<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>,
  resultNames: Array<{
    readonly name: string;
    readonly quantity: Quantity.Quantity;
  }>
): ReadonlyArray<Types.BinCaseResult> {
  if (savedClimateCoolingDataType === selectedClimateCoolingDatatType) {
    return binResults;
  }
  const xUnit = getXUnit(selectedClimateCoolingDatatType, si);
  const groupedResults = binResults.reduce(
    // tslint:disable-next-line:readonly-keyword
    (sofar: { [key: string]: Array<Types.BinCaseResult> }, current) => {
      const amountToGroupOn = getX(
        current.binData,
        selectedClimateCoolingDatatType
      );

      const binNo = Math.floor(
        (Amount.valueAs(xUnit, amountToGroupOn) * 1.0) / binSize
      ).toString();

      if (sofar[binNo] === undefined) {
        sofar[binNo] = [];
      }
      sofar[binNo].push(current);
      return sofar;
    },

    // tslint:disable-next-line:readonly-keyword
    // tslint:disable-next-line:readonly-keyword
    ([] as unknown) as { [key: string]: Array<Types.BinCaseResult> }
  );

  return Object.keys(groupedResults).map(key => {
    const results = groupedResults[key];
    // let binData = PropertyValueSet.Empty;
    const totalBinTime = getTotalBinTime(results);
    const binDataPvs = binDataNames.reduce((soFar, bn) => {
      const values = results
        .filter(br => PropertyValueSet.hasProperty(bn.name, br.binData))
        .map(br => {
          return {
            value: PropertyValueSet.getAmount(
              bn.name,
              br.binData
            )! as Amount.Amount<Quantity.Quantity>,
            binTime: PropertyValueSet.getAmount<Quantity.Duration>(
              KnownProperties.time,
              br.binData
            )!
          };
        });
      const sumOrAverage = getAggregateType(bn.quantity);
      let value: Amount.Amount<Quantity.Quantity> | undefined;

      if (sumOrAverage === "WeightedAverage") {
        value = weightedAverage(values, totalBinTime);
      } else {
        value = sum(values);
      }
      if (value !== undefined) {
        soFar = PropertyValueSet.merge(
          { [bn.name]: PropertyValue.fromAmount(value) },
          soFar
        );
      }
      return soFar;
    }, PropertyValueSet.Empty);

    const resultPvs = resultNames.reduce((soFar, rn) => {
      const values = results
        .filter(br => PropertyValueSet.hasProperty(rn.name, br.results))
        .map(br => {
          return {
            value: PropertyValueSet.getAmount(
              rn.name,
              br.results
            )! as Amount.Amount<Quantity.Quantity>,
            binTime: PropertyValueSet.getAmount<Quantity.Duration>(
              KnownProperties.time,
              br.binData
            )!
          };
        });
      const sumOrAverage = getAggregateType(rn.quantity);
      let value: Amount.Amount<Quantity.Quantity> | undefined;

      if (sumOrAverage === "WeightedAverage") {
        value = weightedAverage(values, totalBinTime);
      } else {
        value = sum(values);
      }
      if (value !== undefined) {
        soFar = PropertyValueSet.merge(
          { [rn.name]: PropertyValue.fromAmount(value) },
          soFar
        );
      }
      return soFar;
    }, PropertyValueSet.Empty);

    return { binData: binDataPvs, results: resultPvs };
  }) as ReadonlyArray<Types.BinCaseResult>;
}

function getTotalBinTime(
  bins: Array<Types.BinCaseResult>
): Amount.Amount<Quantity.Duration> {
  return bins.reduce((soFar, bin) => {
    soFar = Amount.plus(
      soFar,
      PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.time,
        bin.binData
      )!
    );
    return soFar;
  }, Amount.create(0, Units.Second));
}
function sum(
  values: Array<{
    readonly value: Amount.Amount<Quantity.Quantity>;
    readonly binTime: Amount.Amount<Quantity.Duration>;
  }>
): Amount.Amount<Quantity.Quantity> | undefined {
  return values.reduce(
    (
      sofar: Amount.Amount<Quantity.Quantity>,
      current: {
        readonly value: Amount.Amount<Quantity.Quantity>;
        readonly binTime: Amount.Amount<Quantity.Duration>;
      }
    ) => {
      if (current.value === undefined) {
        return sofar;
      }
      if (sofar !== undefined) {
        sofar = Amount.plus(sofar, current.value);
      } else {
        sofar = current.value;
      }

      return sofar;
    },
    undefined as Amount.Amount<Quantity.Quantity> | undefined
  );
}

function weightedAverage(
  values: Array<{
    readonly value: Amount.Amount<Quantity.Quantity>;
    readonly binTime: Amount.Amount<Quantity.Duration>;
  }>,
  totalBinTime: Amount.Amount<Quantity.Duration>
): Amount.Amount<Quantity.Quantity> | undefined {
  const weightedValue = values.reduce(
    (
      sofar: Amount.Amount<Quantity.Quantity>,
      current: {
        readonly value: Amount.Amount<Quantity.Quantity>;
        readonly binTime: Amount.Amount<Quantity.Duration>;
      }
    ) => {
      if (current.value === undefined) {
        return sofar;
      }
      if (sofar !== undefined) {
        sofar = Amount.plus(
          sofar,
          Amount.times(current.value, current.binTime.value)
        );
      } else {
        sofar = Amount.times(current.value, current.binTime.value);
      }

      return sofar;
    },
    undefined as Amount.Amount<Quantity.Quantity> | undefined
  );
  return weightedValue
    ? Amount.times(weightedValue, 1 / totalBinTime.value)
    : undefined;
}

function getAggregateType(
  quantity: Quantity.Quantity
): "Sum" | "WeightedAverage" {
  switch (quantity) {
    case "Energy":
    case "Mass":
    case "Volume":
    case "Duration":
      return "Sum";

    case "Power":
    case "MassFlow":
    case "Temperature":
    case "HumidityRatio":
      return "WeightedAverage";
    default:
      return "WeightedAverage";
  }
}

export function getEnergyCharts(
  energyResults: ReadonlyArray<Types.EnergyResult>,
  chartPreset: Types.ChartPreset,
  selectedClimateCoolingDataType: Types.ClimateCoolingDataType,
  climateCoolingDataType: Types.ClimateCoolingDataType,
  binSize: number,
  si: boolean,
  translate: LanguageTexts.Translate
) {
  const y1Unit = si ? chartPreset.y1UnitSi : chartPreset.y1UnitIp;
  const y2Unit = si ? chartPreset.y2UnitSi : chartPreset.y2UnitIp;

  const energyPerfParamsY1: Array<Types.EnergyPerfParam> = chartPreset.y1PerfParams
    .filter(pp =>
      energyResults.some(er =>
        er.binResults.some(
          br =>
            PropertyValueSet.hasProperty(pp.name, br.results) &&
            PropertyValueSet.getAmount(pp.name, br.results)!.value >
              Number.EPSILON
        )
      )
    )
    .map(p => ({
      perfParamName: p.name,
      chartColor: ChartColor.seriesColors.find(c => c.name === p.color)!,
      quantity: y1Unit.quantity
    }));

  const energyPerfParamsY2: Array<Types.EnergyPerfParam> = chartPreset.y2PerfParams
    .filter(pp =>
      energyResults.some(er =>
        er.binResults.some(
          br =>
            PropertyValueSet.hasProperty(pp.name, br.results) &&
            PropertyValueSet.getAmount(pp.name, br.results)!.value >
              Number.EPSILON
        )
      )
    )
    .map(p => ({
      perfParamName: p.name,
      chartColor: ChartColor.seriesColors.find(c => c.name === p.color)!,
      quantity: y2Unit.quantity
    }));

  let rebinnedEnergyResults = energyResults.map(r => {
    return {
      binResults: regroupBinResults(
        selectedClimateCoolingDataType,
        climateCoolingDataType,
        binSize,
        si,
        r.binResults,
        [
          { name: KnownProperties.airTemperature, quantity: "Temperature" },
          { name: KnownProperties.airHumidity, quantity: "HumidityRatio" },
          { name: KnownProperties.binPressure, quantity: "Pressure" },
          { name: KnownProperties.time, quantity: "Duration" }
        ],
        energyPerfParamsY1.concat(energyPerfParamsY2).map(pp => {
          return { name: pp.perfParamName, quantity: pp.quantity };
        })
      ),
      presentationPerfParams: r.presentationPerfParams,
      presentationTotalPerfParams: r.presentationTotalPerfParams
    };
  }) as ReadonlyArray<Types.EnergyResult>;

  const sortedEnergyResults = sortEnergyResults(
    selectedClimateCoolingDataType,
    rebinnedEnergyResults
  );

  const y1ChartSeries = sortedEnergyResults.map(er =>
    chartPreset.y1PerfParams.reduce(
      (a, b) =>
        er.binResults.some(br => {
          const result = PropertyValueSet.getAmount(b.name, br.results);
          return !!result && result.value > Number.EPSILON;
        })
          ? a.concat({
              name: b.name,
              color: ChartColor.seriesColors.find(c => c.name === b.color)!
                .color,
              xAxis: "bottom",
              yAxis: "left",
              chartPoints: er.binResults.map(bin => ({
                x: getX(bin.binData, selectedClimateCoolingDataType),
                y:
                  (PropertyValueSet.getAmount(
                    b.name,
                    bin.results
                  ) as Amount.Amount<Quantity.Quantity>) ||
                  (Amount.create(0, y1Unit) as Amount.Amount<Quantity.Quantity>)
              }))
            })
          : a,
      [] as ReadonlyArray<Types.ChartSeries>
    )
  );

  const y2ChartSeries = sortedEnergyResults.map(er =>
    chartPreset.y2PerfParams.reduce(
      (a, b) =>
        er.binResults.some(br => {
          const result = PropertyValueSet.getAmount(b.name, br.results);
          return !!result && result.value > Number.EPSILON;
        })
          ? a.concat({
              name: b.name,
              color: ChartColor.seriesColors.find(c => c.name === b.color)!
                .color,
              xAxis: "bottom",
              yAxis: "right",
              chartPoints: er.binResults.map(bin => ({
                x: getX(bin.binData, selectedClimateCoolingDataType),
                y:
                  (PropertyValueSet.getAmount(
                    b.name,
                    bin.results
                  ) as Amount.Amount<Quantity.Quantity>) ||
                  (Amount.create(0, y2Unit) as Amount.Amount<Quantity.Quantity>)
              }))
            })
          : a,
      [] as ReadonlyArray<Types.ChartSeries>
    )
  );

  const allLeftSeries = y1ChartSeries.reduce((a, b) => a.concat(b));
  const allRightSeries = y2ChartSeries.reduce((a, b) => a.concat(b));

  const xPoints = allLeftSeries
    .concat(allRightSeries)
    .reduce(
      (a, b) => a.concat(b.chartPoints.map(p => p.x)),
      [] as ReadonlyArray<Amount.Amount<Quantity.Quantity>>
    );

  const y1Points = y1ChartSeries
    .reduce(
      (a, b) => a.concat(getChartSeries(b, chartPreset.y1AxisType, y1Unit)),
      []
    )
    .reduce(
      (a, b) => a.concat(b.chartPoints.map(p => p.y)),
      [] as ReadonlyArray<Amount.Amount<Quantity.Quantity>>
    );

  const y2Points = y2ChartSeries
    .reduce(
      (a, b) => a.concat(getChartSeries(b, chartPreset.y2AxisType, y2Unit)),
      []
    )
    .reduce(
      (a, b) => a.concat(b.chartPoints.map(p => p.y)),
      [] as ReadonlyArray<Amount.Amount<Quantity.Quantity>>
    );

  const xUnit = getXUnit(selectedClimateCoolingDataType, si);
  const xAxis = createAxis(xPoints, xUnit, 35, 0, 0, false);
  const y1Axis = createAxis(y1Points, y1Unit, 20, 0, 0, true);
  const y2Axis = createAxis(y2Points, y2Unit, 20, 0, 0, true);

  const charts = sortedEnergyResults.map(er => {
    const sortedBins = [...er.binResults];
    const chartLinesLeft =
      chartPreset.y1AxisType === ChartAxisType.ChartAxisType.Line
        ? generateChartLines(
            sortedBins,
            energyPerfParamsY1,
            y1Unit,
            selectedClimateCoolingDataType,
            "left",
            xUnit
          )
        : [];

    const chartLinesRight =
      chartPreset.y2AxisType === ChartAxisType.ChartAxisType.Line
        ? generateChartLines(
            sortedBins,
            energyPerfParamsY2,
            y2Unit,
            selectedClimateCoolingDataType,
            "right",
            xUnit
          )
        : [];

    const chartStack =
      chartPreset.y1AxisType === ChartAxisType.ChartAxisType.StackedArea
        ? generateChartStack(
            sortedBins,
            energyPerfParamsY1,
            y1Unit,
            selectedClimateCoolingDataType,
            "left",
            xUnit
          )
        : chartPreset.y2AxisType === ChartAxisType.ChartAxisType.StackedArea
        ? generateChartStack(
            sortedBins,
            energyPerfParamsY2,
            y2Unit,
            selectedClimateCoolingDataType,
            "right",
            xUnit
          )
        : undefined;

    const xUnitFormat = Format.getUnitFormat(
      getXUnit(selectedClimateCoolingDataType, si),
      UnitsFormat
    );
    const y1UnitFormat = Format.getUnitFormat(y1Unit, UnitsFormat);
    const y2UnitFormat = Format.getUnitFormat(y2Unit, UnitsFormat);

    const chart = AbstractChart.createChart({
      chartLines: chartLinesLeft.concat(chartLinesRight),
      chartStack: chartStack,
      xAxisBottom: AbstractChart.createLinearAxis(
        Amount.valueAs(xUnit, xAxis.chartMin),
        Amount.valueAs(xUnit, xAxis.chartMax),
        translate(
          LanguageTexts.perfParam(getXLabel(selectedClimateCoolingDataType))
        ) +
          " [" +
          (xUnitFormat && xUnitFormat.label) +
          "]"
      ),
      yAxisLeft: AbstractChart.createLinearAxis(
        Amount.valueAs(y1Unit, y1Axis.chartMin),
        Amount.valueAs(y1Unit, y1Axis.max),
        translate(LanguageTexts.dynamicText(chartPreset.y1Label)) +
          " [" +
          (y1UnitFormat && y1UnitFormat.label) +
          "]"
      ),
      yAxisRight: AbstractChart.createLinearAxis(
        Amount.valueAs(y2Unit, y2Axis.chartMin),
        Amount.valueAs(y2Unit, y2Axis.max),
        translate(LanguageTexts.dynamicText(chartPreset.y2Label)) +
          " [" +
          (y2UnitFormat && y2UnitFormat.label) +
          "]"
      ),
      labelLayout: "center",
      width: 900,
      height: 500
    });
    return chart;
  });

  return charts.map((c, ix) => (
    <Elements.FlexContainerRow key={ix} center={false}>
      {AbstractImage.createReactSvg(AbstractChart.renderChart(c))}
      <Elements.FlexContainerStack center={false}>
        {energyPerfParamsY1.map(pp => (
          <LocalElements.ChartColorLabel key={pp.perfParamName}>
            {colorSquare(pp.chartColor)}
            &nbsp;
            {translate(LanguageTexts.perfParam(pp.perfParamName))}
          </LocalElements.ChartColorLabel>
        ))}
        {energyPerfParamsY2.map(pp => (
          <LocalElements.ChartColorLabel key={pp.perfParamName}>
            {colorSquare(pp.chartColor)}
            &nbsp;
            {translate(LanguageTexts.perfParam(pp.perfParamName))}
          </LocalElements.ChartColorLabel>
        ))}
      </Elements.FlexContainerStack>
    </Elements.FlexContainerRow>
  ));
}

function createAxis(
  points: ReadonlyArray<Amount.Amount<Quantity.Quantity>>,
  unit: Unit.Unit<Quantity.Quantity>,
  steps: number,
  extraStepsMin: number,
  extraStepsMax: number,
  forceZero: boolean
): Types.ChartAxisInfo {
  let dummy = Amount.create(0, unit);
  let storageUNit = dummy.unit;
  let min = Amount.create(0, unit);
  let max = Amount.create(1, unit);
  let step = Amount.create(1, unit);

  const getValue = (amount: Amount.Amount<Quantity.Quantity>) =>
    Amount.valueAs(unit, amount);

  const sortedPoints = points.concat([]).sort((a, b) => b.value - a.value);

  if (points.length !== 0) {
    if (points.every(p => p.value >= 0) && forceZero) {
      min = Amount.create(0, unit);
      const pointsLength = getValue(sortedPoints[0]);
      const pointsInterval = pointsLength / steps;
      const goodAlternatives = intervalAlternatives.filter(
        s => s >= pointsInterval
      );

      step = goodAlternatives.length
        ? Amount.create(goodAlternatives[0], unit)
        : Amount.create(
            intervalAlternatives[intervalAlternatives.length - 1],
            unit
          );
      max = Amount.create(getValue(step) * steps, unit);
    } else if (points.every(p => p.value <= 0) && forceZero) {
      max = Amount.create(0, unit);
      const pointsLength = -getValue(sortedPoints[sortedPoints.length - 1]);
      const pointsInterval = pointsLength / steps;
      const goodAlternatives = intervalAlternatives.filter(
        s => s >= pointsInterval
      );

      step = goodAlternatives.length
        ? Amount.create(goodAlternatives[0], unit)
        : Amount.create(
            intervalAlternatives[intervalAlternatives.length - 1],
            unit
          );
      min = Amount.create(-getValue(step) * steps, unit);
    } else {
      const pointsLength =
        getValue(sortedPoints[0]) -
        getValue(sortedPoints[sortedPoints.length - 1]);
      const pointsInterval = pointsLength / steps;
      const goodAlternatives = intervalAlternatives.filter(
        s => s >= pointsInterval
      );

      step = goodAlternatives.length
        ? Amount.create(goodAlternatives[0], unit)
        : Amount.create(
            intervalAlternatives[intervalAlternatives.length - 1],
            unit
          );

      const intervalInStorageUnit = getValue(step);
      const maxInStorageUnit = getValue(sortedPoints[0]);
      const minInStorageUnit = getValue(sortedPoints[sortedPoints.length - 1]);

      const actualNoOfNegativeIntervals =
        minInStorageUnit < 0
          ? Math.ceil(-minInStorageUnit / intervalInStorageUnit)
          : 0;
      const actualNoOfPositiveIntervals =
        maxInStorageUnit > 0
          ? Math.ceil(maxInStorageUnit / intervalInStorageUnit)
          : 0;

      min = Amount.create(
        -actualNoOfNegativeIntervals * getValue(step),
        storageUNit
      );
      max = Amount.create(
        actualNoOfPositiveIntervals * getValue(step),
        storageUNit
      );
    }
  }
  const chartMin = Amount.create(
    getValue(min) - getValue(step) * extraStepsMin,
    storageUNit
  );
  const chartMax = Amount.create(
    getValue(max) + getValue(step) * extraStepsMax,
    storageUNit
  );

  return {
    chartMin: chartMin,
    chartMax: chartMax,
    min: min,
    max: max,
    step: step,
    storageUnit: storageUNit
  };
}

// function createCharts(
//   chartSeries: ReadonlyArray<ReadonlyArray<Types.ChartSeries>>
// ): ReadonlyArray<>;

function getX(
  settings: PropertyValueSet.PropertyValueSet,
  coolingDataType: Types.ClimateCoolingDataType
): Amount.Amount<Quantity.Quantity> {
  switch (coolingDataType) {
    case "WB/MCDB":
      return getWetBulbTemperature(settings);
    case "DP/MCDB":
      return getDewPointTemperature(settings);
    case "h/MCDB":
      return getSpecificEnthalpy(settings);
    case "DB/MCWB":
    default:
      return getAverageTemperature(settings);
  }
}

function getXUnit(
  coolingDataType: Types.ClimateCoolingDataType,
  si: boolean
): Unit.Unit<Quantity.Quantity> {
  switch (coolingDataType) {
    case "WB/MCDB":
      return si ? Units.CelsiusWet : Units.FahrenheitWet;
    case "DP/MCDB":
      return si ? Units.CelsiusDewPoint : Units.FahrenheitDewPoint;
    case "h/MCDB":
      return si ? Units.KilojoulePerKilogram : Units.BtuPerPoundLb;
    case "DB/MCWB":
    default:
      return si ? Units.Celsius : Units.Fahrenheit;
  }
}

function sortEnergyResults(
  coolingDataType: Types.ClimateCoolingDataType,
  energyResults: ReadonlyArray<Types.EnergyResult>
): ReadonlyArray<Types.EnergyResult> {
  return energyResults.map(r => {
    let sortedBinResults: Array<Types.BinCaseResult>;
    switch (coolingDataType) {
      case "WB/MCDB":
        sortedBinResults = r.binResults
          .concat([])
          .sort((a, b) =>
            compareAmounts(
              getWetBulbTemperature(a.binData),
              getWetBulbTemperature(b.binData)
            )
          );
        break;
      case "DP/MCDB":
        sortedBinResults = r.binResults
          .concat([])
          .sort((a, b) =>
            compareAmounts(
              getDewPointTemperature(a.binData),
              getDewPointTemperature(b.binData)
            )
          );
        break;
      case "h/MCDB":
        sortedBinResults = r.binResults
          .concat([])
          .sort((a, b) =>
            compareAmounts(
              getSpecificEnthalpy(a.binData),
              getSpecificEnthalpy(b.binData)
            )
          );
        break;
      case "DB/MCWB":
      default:
        sortedBinResults = r.binResults
          .concat([])
          .sort((a, b) =>
            compareAmounts(
              getAverageTemperature(a.binData),
              getAverageTemperature(b.binData)
            )
          );
        break;
    }
    return { ...r, binResults: sortedBinResults };
  });
}

function getAverageTemperature(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Temperature> {
  return PropertyValueSet.getAmount<Quantity.Temperature>(
    "airtemperature",
    settings
  )!;
}

function getWetBulbTemperature(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.WetTemperature> {
  return Physics.RP1485.AmountConversions.humidityRatioToWetTemperature(
    getAbsolutePressure(settings),
    getAverageTemperature(settings),
    getHumidity(settings)
  );
}

function getHumidity(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.HumidityRatio> {
  return PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    "airhumidity",
    settings
  )!;
}

function getAbsolutePressure(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Pressure> {
  return PropertyValueSet.getAmount<Quantity.Pressure>(
    "binpressure",
    settings
  )!;
}

function getDewPointTemperature(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.DewPointTemperature> {
  return Physics.RP1485.AmountConversions.humidityRatioToDewPointTemperature(
    getAbsolutePressure(settings),
    getHumidity(settings)
  );
}

function getSpecificEnthalpy(
  settings: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.SpecificEnthalpy> {
  return Physics.RP1485.AmountConversions.humidityRatioToSpecificEnthalpy(
    getAbsolutePressure(settings),
    getAverageTemperature(settings),
    getHumidity(settings)
  );
}

function compareAmounts(
  a: Amount.Amount<Quantity.Quantity>,
  b: Amount.Amount<Quantity.Quantity>
): number {
  return a.value - b.value;
}

function getXLabel(coolingDataType: Types.ClimateCoolingDataType): string {
  switch (coolingDataType) {
    case "WB/MCDB":
      return "AirOutsideWetTemperature";
    case "DP/MCDB":
      return "AirOutsideDewPointTemperature";
    case "h/MCDB":
      return "AirOutsideSpecificEnthalpy";
    case "DB/MCWB":
    default:
      return "AirOutsideTemperature";
  }
}

function generateChartLines(
  bins: ReadonlyArray<Types.BinCaseResult>,
  energyPerfParams: Array<Types.EnergyPerfParam>,
  yUnit: Unit.Unit<Quantity.Quantity>,
  coolingDataType: Types.ClimateCoolingDataType,
  yAxis: AbstractChart.YAxis,
  xUnit: Unit.Unit<Quantity.Quantity>
): Array<AbstractChart.ChartLine> {
  return energyPerfParams.map(e => ({
    points: bins.map(br =>
      PropertyValueSet.hasProperty(e.perfParamName, br.results)
        ? {
            x: Amount.valueAs(xUnit, getX(br.binData, coolingDataType)),
            y: Amount.valueAs(
              yUnit,
              PropertyValueSet.getAmount<Quantity.Quantity>(
                e.perfParamName,
                br.results
              )!
            )
          }
        : {
            x: Amount.valueAs(xUnit, getX(br.binData, coolingDataType)),
            y: 0
          }
    ),
    color: e.chartColor.color,
    thickness: 2,
    label: "",
    xAxis: "bottom" as AbstractChart.XAxis,
    yAxis: yAxis
  }));
}

function generateChartStack(
  bins: ReadonlyArray<Types.BinCaseResult>,
  energyPerfParams: Array<Types.EnergyPerfParam>,
  yUnit: Unit.Unit<Quantity.Quantity>,
  coolingDataType: Types.ClimateCoolingDataType,
  yAxis: AbstractChart.YAxis,
  xUnit: Unit.Unit<Quantity.Quantity>
): AbstractChart.ChartStack {
  return {
    points: bins.map(br => ({
      x: Amount.valueAs(xUnit, getX(br.binData, coolingDataType)),
      ys: energyPerfParams.map(e =>
        PropertyValueSet.hasProperty(e.perfParamName, br.results)
          ? Amount.valueAs(
              yUnit,
              PropertyValueSet.getAmount<Quantity.Quantity>(
                e.perfParamName,
                br.results
              )!
            )
          : 0
      )
    })),
    config: energyPerfParams.map(e => ({
      color: e.chartColor.color,
      label: ""
    })),
    xAxis: "bottom",
    yAxis: yAxis
  };
}

function getChartSeries(
  series: ReadonlyArray<Types.ChartSeries>,
  axisType: ChartAxisType.ChartAxisType,
  yUnit: Unit.Unit<Quantity.Quantity>
): ReadonlyArray<Types.ChartSeries> {
  switch (axisType) {
    case ChartAxisType.ChartAxisType.Line:
      return series;

    case ChartAxisType.ChartAxisType.StackedArea:
      return series.map((s, ix) => {
        const seriesToStack = series.filter((_ss, i) => i >= ix);
        const stackedPoints = s.chartPoints.map((point, px) => {
          const y = seriesToStack
            .map(ss => ss.chartPoints.find((_p, pxx) => pxx === px)!.y)
            .reduce((a, b) => Amount.plus(a, b), Amount.create(0, yUnit));
          return {
            ...point,
            y: y
          };
        });
        return { ...s, chartPoints: stackedPoints };
      });

    case ChartAxisType.ChartAxisType.SignedStackedArea:
      return series.reduce((arr, s, ix) => {
        const seriesToStack = series.filter((_ss, i) => i >= ix);
        const stackedPoints = s.chartPoints.map((point, px) => {
          const ys = seriesToStack.map(
            ss => ss.chartPoints.find((_p, pxx) => pxx === px)!.y
          );
          const positiveY = ys.reduce(
            (a, b) =>
              Amount.greaterOrEqualTo(
                b,
                Amount.create(0, yUnit) as Amount.Amount<Quantity.Quantity>
              )
                ? Amount.plus(a, b)
                : a,
            Amount.create(0, yUnit)
          );
          const negativeY = ys.reduce(
            (a, b) =>
              Amount.lessThan(
                b,
                Amount.create(0, yUnit) as Amount.Amount<Quantity.Quantity>
              )
                ? Amount.plus(a, b)
                : a,
            Amount.create(0, yUnit)
          );
          return {
            x: point.x,
            positiveY: positiveY,
            negativeY: negativeY
          };
        });
        return arr.concat([
          {
            ...s,
            chartPoints: stackedPoints.map(p => ({ ...p, y: p.positiveY }))
          },
          {
            ...s,
            chartPoints: stackedPoints.map(p => ({ ...p, y: p.negativeY }))
          }
        ]);
      }, [] as ReadonlyArray<Types.ChartSeries>);
    default:
      throw new Error("Unknown axistype");
  }
}

function colorSquare(color: Types.ChartColor) {
  return (
    <svg width="14" height="14">
      <rect
        width="14"
        height="14"
        fill={
          "rgb(" +
          color.color.r +
          "," +
          color.color.g +
          "," +
          color.color.b +
          ")"
        }
      />
    </svg>
  );
}

// tslint:disable-next-line
