import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Exporter from "@genesys/client-core/lib/exporter";
import * as SharedState from "../../../../shared-state";
import { State, Action } from "../../../state";
import { Dispatch } from "@typescript-tea/core";
import { H3, ActionButton } from "@genesys/ui-elements";
import { ActionButtonsContainer } from "../../../elements";
import { getExcelCellInfo } from "./cell-contents";
import { ColumnDefinition } from "../../../types";

export function ActionsMenu({
  state,
  sharedState,
  dataColumns,
  dispatch
}: {
  readonly state: State;
  readonly dataColumns: ReadonlyArray<ColumnDefinition>;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.selectedSystemIds.length === 0) {
    return null;
  }

  const onDeleteSystems = () => {
    if (
      !state.queryUserResult?.user.searchSystems.results
        .filter(r =>
          r.systems.find(s => !!state.selectedSystemIds.find(ss => s.id === ss))
        )
        .every(sf =>
          sf.systems.every(
            s => !!state.selectedSystemIds.find(ss => ss === s.id)
          )
        )
    ) {
      dispatch(Action.openModal({ mode: "mustSelectAllRevisions" }));
    } else {
      const systemFileIds = state
        .queryUserResult!.user.searchSystems.results.filter(r =>
          r.systems.find(s => !!state.selectedSystemIds.find(ss => s.id === ss))
        )
        .map(s => s.id);

      dispatch(
        Action.openModal({
          mode: "confirmSystemsDelete",
          systemFileIds: systemFileIds
        })
      );
    }
  };

  const DeleteButton = () => (
    <ActionButton kind="delete" onClick={() => onDeleteSystems()} />
  );

  const SummaryButton = () => (
    <ActionButton
      kind="summary"
      onClick={() => dispatch(Action.openModal({ mode: "system-summary" }))}
    />
  );

  return (
    <ActionButtonsContainer>
      <H3 weight="bold" color="dark">
        {sharedState.translate(LanguageTexts.actions())}
      </H3>
      <DeleteButton />
      <ExportButton
        dataColumns={dataColumns}
        sharedState={sharedState}
        state={state}
      />
      <SummaryButton />
    </ActionButtonsContainer>
  );
}

function ExportButton({
  dataColumns,
  sharedState,
  state
}: {
  readonly dataColumns: ReadonlyArray<ColumnDefinition>;
  readonly sharedState: SharedState.State;
  readonly state: State;
}) {
  const excelHeaderNames = dataColumns.filter(x => x.excelHeaderName);

  return (
    <Exporter.XlsxSingleSheetExport
      data={[
        excelHeaderNames.map(x => x.excelHeaderName as string),
        ...state
          .queryUserResult!.user.searchSystems.results.filter(x => {
            const latestSystemId = x.systems.reduce(
              (a, b) => (b.revisionNo > a.revisionNo ? b : a),
              x.systems[0]
            ).id;

            return state.selectedSystemIds.includes(latestSystemId);
          })
          .map(system => {
            const latestSystem = system.systems.reduce(
              (a, b) => (b.revisionNo > a.revisionNo ? b : a),
              system.systems[0]
            );
            return excelHeaderNames.map(
              column =>
                getExcelCellInfo(
                  column.columnName,
                  sharedState,
                  state.queryProductResult!.product.genesysNumberPrefixes
                    .genesysNo,
                  {
                    type: "file",
                    id: system.id,
                    labels: system.labels,
                    name: system.name,
                    owner: system.owner,
                    isDeleted: system.isDeleted,
                    systemTypeId: system.systemTypeId,
                    systemLabel: system.systemLabel,
                    systems: system.systems,
                    latestSystem: latestSystem,
                    genesysNo: system.genesysNo,
                    isActionMenuOpen: false,
                    isRevisionExpanded: false
                  }
                ) as string
            );
          })
      ]}
      filename={"SystemManagerResults"}
    >
      {onClick => <ActionButton kind="export" onClick={onClick} />}
    </Exporter.XlsxSingleSheetExport>
  );
}
