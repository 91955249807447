import * as React from "react";
import * as PropertiesSelector from "../react-properties-selector";
import * as Contexts from "../contexts";
import { createSelectorInfoMap } from "../genesys-properties-selector-helpers";
import { GroupRenderer } from "./group-renderer";
import * as Elements from "./elements";

export const columnsStackedWithGroupsLayoutRenderer = (
  props: PropertiesSelector.LayoutRendererProps
): JSX.Element => {
  const selectorInfoMap = createSelectorInfoMap(props.selectors);
  return (
    <Contexts.propertiesSelectorContext.Consumer>
      {({
        activatedQuantitySelectors,
        conversionParametersMap,
        filterPrettyPrint,
        layoutRendererExtraProps,
        onQuantityChange,
        showGroups,
        translatePropertyValue
      }) => {
        if (layoutRendererExtraProps.sections.length === 0) {
          return <div />;
        }

        return (
          <Elements.ColumnLayoutRoot>
            {layoutRendererExtraProps.sections.map(s => (
              <div key={s.key}>
                {s.groups.map(group => (
                  <GroupRenderer
                    key={`${layoutRendererExtraProps.uniqueId}-${group.key}`}
                    filterPrettyPrint={filterPrettyPrint}
                    renderGroup={group}
                    showGroups={showGroups}
                    fieldGroup={layoutRendererExtraProps.fieldGroup}
                    hidePropertyNames={
                      layoutRendererExtraProps.hidePropertyNames
                    }
                    rowRendererType={{
                      type: "StandardRowRenderer",
                      translatePropertyValue: translatePropertyValue
                    }}
                    selectorInfoMap={selectorInfoMap}
                    activatedQuantitySelectors={activatedQuantitySelectors}
                    onQuantityChange={onQuantityChange}
                    conversionParametersMap={conversionParametersMap}
                  />
                ))}
              </div>
            ))}
          </Elements.ColumnLayoutRoot>
        );
      }}
    </Contexts.propertiesSelectorContext.Consumer>
  );
};
