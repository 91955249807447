import { exhaustiveCheck } from "ts-exhaustive-check";
import gql from "graphql-tag";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as GraphQLTypes from "../../graphql-types";
import * as SharedState from "../../shared-state";

const userQuery = gql`
  query SystemStatusText($systemId: ID!) {
    user {
      system(id: $systemId) {
        id
        components {
          id
          productId
          properties
        }
      }
    }
  }
`;
type Loading = {
  readonly type: "Loading";
};
type Loaded = {
  readonly type: "Loaded";
  readonly components: ReadonlyArray<{
    readonly productId: string;
    readonly properties?: string | null;
  }>;
};
export type State = Loading | Loaded;
export const Action = ctorsUnion({
  componentsReceived: (data: GraphQLTypes.SystemStatusText) => ({
    data
  }),
  error: (error: Error) => ({ error })
});
export type Action = CtorsUnion<typeof Action>;

export const init = (
  systemId: string,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  return [
    { type: "Loading" },
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.SystemStatusText,
      GraphQLTypes.SystemStatusTextVariables,
      Action
    >(userQuery, { systemId }, Action.componentsReceived, Action.error)
  ];
};

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?] {
  switch (action.type) {
    case "componentsReceived": {
      const system = action.data.user.system;
      return [
        {
          ...state,
          type: "Loaded",
          components: system.components
        }
      ];
    }
    case "error": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
