import * as Navigation from "../navigation-effect-manager";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { UrlMatch } from "@genesys/route";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as UserSettings from "../user-settings/state";
import * as SharedState from "../shared-state";
import * as MainMenu from "../main-menu";
import * as Dashboard from "../dashboard";
import * as Routes from "../routes";
import * as SystemConfigurator from "../system-configurator";
import * as Wizard from "../wizard";
import * as SystemManager from "../system-manager";
import * as PricingManager from "../pricing-manager";
import * as PricingEditor from "../pricing-editor";
import * as PricingWizard from "../pricing-wizard";
import * as Tools from "../tools";
import * as About from "../about";
import * as DebugSettings from "../debug-settings";
import * as MoistureLoadManager from "../moisture-load-manager";
import * as MoistureLoadCalc from "../moisture-load-calculation";
import * as MoistureLoadWizard from "../moisture-load-wizard";
import * as SystemSelectionGuide from "../system-selection-guide";
import * as DrySize from "../dry-size";
import * as LogViewer from "../log-viewer";

export type State = {
  readonly userSettingsState: UserSettings.State | undefined;
  readonly mainMenuState: MainMenu.State;
  readonly dashboardState: Dashboard.State | undefined;
  readonly systemConfiguratorState: SystemConfigurator.State | undefined;
  readonly systemManagerState: SystemManager.State | undefined;
  readonly debugSettingsState: DebugSettings.State | undefined;
  readonly pricingManagerState: PricingManager.State | undefined;
  readonly pricingEditorState: PricingEditor.State | undefined;
  readonly pricingWizardState: PricingWizard.State | undefined;
  readonly moistureLoadManagerState: MoistureLoadManager.State | undefined;
  readonly moistureLoadCalcState: MoistureLoadCalc.State | undefined;
  readonly moistureLoadWizardState: MoistureLoadWizard.State | undefined;
  readonly toolsState: Tools.State | undefined;
  readonly aboutState: About.State | undefined;
  readonly currentUrlMatch: UrlMatch<Routes.MainLocation> | undefined;
  readonly wizardState: Wizard.State | undefined;
  readonly logoClickedCount: number;
  readonly systemSelectionGuideState: SystemSelectionGuide.State | undefined;
  readonly drySizeState: DrySize.State | undefined;
  readonly logViewerState: LogViewer.State | undefined;
};

export const init = (
  sharedState: SharedState.State,
  url: Navigation.Url
): readonly [
  State,
  Cmd<Action> | undefined,
  ReadonlyArray<SharedState.Action | undefined>?
] => {
  const [mainMenuState] = MainMenu.init();
  const tempState: State = {
    userSettingsState: undefined,
    dashboardState: undefined,
    currentUrlMatch: undefined,
    moistureLoadManagerState: undefined,
    moistureLoadCalcState: undefined,
    moistureLoadWizardState: undefined,
    mainMenuState,
    systemConfiguratorState: undefined,
    systemManagerState: undefined,
    debugSettingsState: undefined,
    wizardState: undefined,
    pricingManagerState: undefined,
    pricingEditorState: undefined,
    pricingWizardState: undefined,
    toolsState: undefined,
    aboutState: undefined,
    logoClickedCount: 0,
    systemSelectionGuideState: undefined,
    drySizeState: undefined,
    logViewerState: undefined
  };
  const urlMatch = Routes.parseUrl(url.path);
  const [effectiveState, routeCmd, sharedStateActions] = handleRouteChange(
    tempState,
    sharedState,
    urlMatch
  );
  return [effectiveState, routeCmd, sharedStateActions];
};

// -- UPDATE

export const Action = ctorsUnion({
  dispatchUserSettings: (action: UserSettings.Action) => ({ action }),
  dispatchMainMenu: (action: MainMenu.Action) => ({ action }),
  dispatchDashboard: (action: Dashboard.Action) => ({ action }),
  dispatchSystemConfigurator: (action: SystemConfigurator.Action) => ({
    action
  }),
  dispatchSystemManager: (action: SystemManager.Action) => ({ action }),
  dispatchMoistureLoadManager: (action: MoistureLoadManager.Action) => ({
    action
  }),

  dispatchMoistureLoadCalc: (action: MoistureLoadCalc.Action) => ({
    action
  }),
  dispatchMoistureLoadWizard: (action: MoistureLoadWizard.Action) => ({
    action
  }),
  dispatchPricingManager: (action: PricingManager.Action) => ({ action }),
  dispatchPricingEditor: (action: PricingEditor.Action) => ({ action }),
  dispatchPricingWizard: (action: PricingWizard.Action) => ({ action }),
  dispatchTools: (action: Tools.Action) => ({ action }),
  dispatchAbout: (action: About.Action) => ({ action }),
  dispatchDebugSettings: (action: DebugSettings.Action) => ({ action }),
  closeDebugSettings: () => ({}),
  UrlChanged: (url: Navigation.Url) => ({ url }),
  dispatchWizard: (action: Wizard.Action) => ({ action }),
  logoClicked: () => ({}),
  dispatchSystemSelectionGuide: (action: SystemSelectionGuide.Action) => ({
    action
  }),
  dispatchDrySize: (action: DrySize.Action) => ({
    action
  }),
  dispatchLogViewer: (action: LogViewer.Action) => ({ action })
});
export type Action = CtorsUnion<typeof Action>;

type mainStateUpdateReturn = [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
];

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): mainStateUpdateReturn {
  switch (action.type) {
    case "dispatchUserSettings": {
      const [newUserSettingsState, cmd, sharedStateAction] =
        UserSettings.update(
          action.action,
          state.userSettingsState!,
          sharedState
        );
      return [
        { ...state, userSettingsState: newUserSettingsState },
        Cmd.batch<Action>([Cmd.map(Action.dispatchUserSettings, cmd)]),
        sharedStateAction
      ];
    }
    case "dispatchMainMenu": {
      const [mainMenuState, cmd, sharedStateAction] = MainMenu.update(
        action.action,
        state.mainMenuState
      );
      return [
        { ...state, mainMenuState },
        Cmd.batch<Action>([Cmd.map(Action.dispatchMainMenu, cmd)]),
        sharedStateAction
      ];
    }
    case "dispatchDashboard": {
      const [dashboardState, dashboardCmd, sharedStateAction] =
        Dashboard.update(action.action, state.dashboardState!, sharedState);
      return [
        { ...state, dashboardState },
        Cmd.batch<Action>([Cmd.map(Action.dispatchDashboard, dashboardCmd)]),
        sharedStateAction
      ];
    }
    case "dispatchSystemConfigurator": {
      const [systemConfiguratorState, cmd, sharedStateAction] =
        SystemConfigurator.update(
          action.action,
          state.systemConfiguratorState!,
          sharedState
        );
      return [
        { ...state, systemConfiguratorState },
        Cmd.map(Action.dispatchSystemConfigurator, cmd),
        sharedStateAction
      ];
    }
    case "dispatchWizard": {
      const [wizardState, cmd, sharedStateAction] = Wizard.update(
        action.action,
        state.wizardState!,
        sharedState
      );
      return [
        {
          ...state,
          wizardState
        },
        Cmd.map(Action.dispatchWizard, cmd),
        sharedStateAction
      ];
    }
    case "dispatchSystemManager": {
      const [systemManagerState, systemManagerCmd, sharedStateAction] =
        SystemManager.update(
          action.action,
          state.systemManagerState!,
          sharedState
        );
      return [
        { ...state, systemManagerState },
        Cmd.map(Action.dispatchSystemManager, systemManagerCmd),
        sharedStateAction
      ];
    }

    case "dispatchMoistureLoadManager": {
      return UpdateMoistureLoadManager(sharedState, state, action.action);
    }

    case "dispatchMoistureLoadCalc": {
      const [moistureLoadCalcState, moistureLoadCalcCmd, sharedStateAction] =
        MoistureLoadCalc.update(
          action.action,
          state.moistureLoadCalcState!,
          sharedState
        );
      return [
        { ...state, moistureLoadCalcState },
        Cmd.map(Action.dispatchMoistureLoadCalc, moistureLoadCalcCmd),
        sharedStateAction
      ];
    }

    case "dispatchMoistureLoadWizard": {
      const [
        moistureLoadWizardState,
        moistureLoadWizardCmd,
        sharedStateAction
      ] = MoistureLoadWizard.update(
        action.action,
        state.moistureLoadWizardState!,
        sharedState
      );
      return [
        { ...state, moistureLoadWizardState },
        Cmd.map(Action.dispatchMoistureLoadWizard, moistureLoadWizardCmd),
        sharedStateAction
      ];
    }
    case "dispatchPricingManager": {
      const [pricingManagerState, pricingManagerCmd, sharedStateAction] =
        PricingManager.update(
          action.action,
          state.pricingManagerState!,
          sharedState
        );
      return [
        { ...state, pricingManagerState },
        Cmd.map(Action.dispatchPricingManager, pricingManagerCmd),
        sharedStateAction
      ];
    }
    case "dispatchPricingEditor": {
      const [pricingEditorState, pricingEditorCmd, sharedStateAction] =
        PricingEditor.update(
          action.action,
          state.pricingEditorState!,
          sharedState
        );
      return [
        { ...state, pricingEditorState },
        Cmd.map(Action.dispatchPricingEditor, pricingEditorCmd),
        sharedStateAction
      ];
    }
    case "dispatchPricingWizard": {
      const [pricingWizardState, pricingWizardCmd, sharedStateAction] =
        PricingWizard.update(
          action.action,
          state.pricingWizardState!,
          sharedState
        );
      return [
        { ...state, pricingWizardState },
        Cmd.map(Action.dispatchPricingWizard, pricingWizardCmd),
        sharedStateAction
      ];
    }
    case "dispatchTools": {
      const [toolsState, toolsCmd, sharedStateAction] = Tools.update(
        action.action,
        state.toolsState!,
        sharedState
      );
      return [
        { ...state, toolsState: toolsState },
        Cmd.map(Action.dispatchTools, toolsCmd),
        sharedStateAction
      ];
    }
    case "dispatchAbout": {
      const [aboutState, aboutCmd, sharedStateAction] = About.update(
        action.action,
        state.aboutState!,
        sharedState
      );
      return [
        { ...state, aboutState: aboutState },
        Cmd.map(Action.dispatchAbout, aboutCmd),
        sharedStateAction
      ];
    }
    case "UrlChanged": {
      const urlMatch = Routes.parseUrl(action.url.path);
      return handleRouteChange(state, sharedState, urlMatch);
    }
    case "logoClicked": {
      const newDebugSettingsState =
        (state.logoClickedCount >= 2 &&
          state.debugSettingsState === undefined &&
          DebugSettings.init(sharedState)) ||
        undefined;
      return [
        {
          ...state,
          logoClickedCount: state.logoClickedCount + 1,
          debugSettingsState: newDebugSettingsState
        }
      ];
    }
    case "dispatchDebugSettings": {
      if (state.debugSettingsState === undefined) {
        return [state];
      }

      const [debugSettingsState, cmd, sharedStateAction] = DebugSettings.update(
        action.action,
        state.debugSettingsState,
        sharedState
      );
      return [
        { ...state, debugSettingsState },
        Cmd.map(Action.dispatchDebugSettings, cmd),
        sharedStateAction
      ];
    }
    case "closeDebugSettings": {
      return [{ ...state, logoClickedCount: 0, debugSettingsState: undefined }];
    }
    case "dispatchSystemSelectionGuide": {
      if (!state.systemSelectionGuideState) {
        return [state];
      }

      const [
        systemSelectionGuideState,
        systemSelectionGuideCmd,
        sharedStateAction
      ] = SystemSelectionGuide.update(
        action.action,
        state.systemSelectionGuideState,
        sharedState
      );
      return [
        { ...state, systemSelectionGuideState: systemSelectionGuideState },
        Cmd.map(Action.dispatchSystemSelectionGuide, systemSelectionGuideCmd),
        sharedStateAction
      ];
    }
    case "dispatchLogViewer": {
      if (!state.logViewerState) {
        return [state];
      }
      const [logViewerState, logViewerCmd] = LogViewer.update(
        action.action,
        state.logViewerState,
        sharedState
      );
      return [
        { ...state, logViewerState },
        Cmd.map(Action.dispatchLogViewer, logViewerCmd)
      ];
    }
    case "dispatchDrySize": {
      if (!state.drySizeState) {
        return [state];
      }

      const [drySizeState, drySizeCmd, sharedStateAction] = DrySize.update(
        action.action,
        state.drySizeState,
        sharedState
      );
      return [
        { ...state, drySizeState: drySizeState },
        Cmd.map(Action.dispatchDrySize, drySizeCmd),
        sharedStateAction
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function handleRouteChange(
  state: State,
  sharedState: SharedState.State,
  urlMatch: UrlMatch<Routes.MainLocation> | undefined
): [State, Cmd<Action>?, ReadonlyArray<SharedState.Action | undefined>?] {
  if (!urlMatch) {
    throw new Error("No route found");
  }
  const stateWithMatch: State = {
    ...state,
    currentUrlMatch: urlMatch
  };

  const browserTitleAction = SharedState.Action.setBrowserTitle(
    `${urlMatch.location.type}`
  );
  switch (urlMatch.location.type) {
    case "Dashboard": {
      const [dashboardState, sharedStateAction, dashboardCmd] =
        Dashboard.init(sharedState);
      return [
        { ...stateWithMatch, dashboardState },
        Cmd.map(Action.dispatchDashboard, dashboardCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "SystemConfiguration": {
      const getRemainingParams = (
        params: typeof urlMatch.location.location
      ):
        | {
            readonly type: "componentId";
            readonly componentId: string;
          }
        | {
            readonly type: "modal";
            readonly modal: string;
          }
        | {
            readonly type: "accessories";
            readonly componentId: string;
          }
        | undefined => {
        if (params.type === "RootWithComponentId") {
          return {
            type: "componentId" as const,
            componentId: params.params.compId
          };
        } else if (params.type === "RootWithAccessoriesForComp") {
          return {
            type: "accessories" as const,
            componentId: params.params.compId
          };
        } else if (params.type === "RootWithModal") {
          return {
            type: "modal" as const,
            modal: params.params.modalName
          };
        }
        return undefined;
      };

      const [
        systemConfiguratorState,
        systemConfigurationCmd,
        sharedStateAction
      ] = SystemConfigurator.init(
        sharedState,
        urlMatch.location.location.params.genesysNo,
        getRemainingParams(urlMatch.location.location)
      );
      return [
        { ...stateWithMatch, systemConfiguratorState },
        Cmd.map(Action.dispatchSystemConfigurator, systemConfigurationCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "UserSettings": {
      const [userSettingsState, userSettingsCmd, sharedStateAction] =
        UserSettings.init(sharedState);

      return [
        { ...stateWithMatch, userSettingsState },
        Cmd.map(Action.dispatchUserSettings, userSettingsCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "SystemManager": {
      if (state.systemManagerState) {
        return [
          {
            ...stateWithMatch,
            systemManagerState: {
              ...state.systemManagerState,
              shouldUpdateSearch: true
            }
          },
          undefined,
          [browserTitleAction]
        ];
      }

      const [systemManagerState, systemManagerCmd, sharedStateAction] =
        SystemManager.init(sharedState);
      return [
        {
          ...stateWithMatch,
          systemManagerState
        },
        Cmd.map(Action.dispatchSystemManager, systemManagerCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }

    case "WizardWithMoistureLoad": {
      if (
        !canSeeRoute(
          MoistureLoadCalc.hasPermissionForMlc(
            sharedState.user.applicationClaims
          )
        )
      ) {
        return [state, Navigation.pushUrl("/")];
      }
      const moistureLoadNo = urlMatch.location.location.params.mNo;

      const [wizardState, wizardCmd, sharedStateAction] = Wizard.init(
        sharedState,
        moistureLoadNo
      );
      return [
        {
          ...stateWithMatch,
          wizardState
        },
        Cmd.map(Action.dispatchWizard, wizardCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }

    case "Wizard": {
      const [wizardState, wizardCmd, sharedStateAction] =
        Wizard.init(sharedState);
      return [
        {
          ...stateWithMatch,
          wizardState
        },
        Cmd.map(Action.dispatchWizard, wizardCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "PricingManager": {
      if (state.pricingManagerState) {
        return [
          {
            ...stateWithMatch
          },
          undefined,
          [browserTitleAction]
        ];
      }
      const [pricingManagerState, pricingManagerCmd, sharedStateAction] =
        PricingManager.init(sharedState);
      return [
        {
          ...stateWithMatch,
          pricingManagerState: pricingManagerState
        },
        Cmd.map(Action.dispatchPricingManager, pricingManagerCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "PricingEditor": {
      const [pricingEditorState, pricingEditorCmd, sharedStateAction] =
        PricingEditor.init(
          sharedState,
          urlMatch.location.location.params.pricingNo
        );
      return [
        {
          ...stateWithMatch,
          pricingEditorState: pricingEditorState
        },
        Cmd.map(Action.dispatchPricingEditor, pricingEditorCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "PricingWizard": {
      const [pricingWizardState, pricingWizardCmd, sharedStateAction] =
        PricingWizard.init(sharedState);
      return [
        {
          ...stateWithMatch,
          pricingWizardState: pricingWizardState
        },
        Cmd.map(Action.dispatchPricingWizard, pricingWizardCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "Tools": {
      const [toolsState, toolsCmd, sharedStateAction] = Tools.init(
        urlMatch.location.location.params.toolType as Tools.ToolType,
        sharedState
      );
      return [
        {
          ...stateWithMatch,
          toolsState: toolsState
        },
        Cmd.map(Action.dispatchTools, toolsCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }
    case "About": {
      const [aboutState, aboutCmd, sharedStateAction] = About.init(sharedState);
      return [
        {
          ...stateWithMatch,
          aboutState: aboutState
        },
        Cmd.map(Action.dispatchAbout, aboutCmd),
        [sharedStateAction, browserTitleAction]
      ];
    }

    case "MoistureLoadManager": {
      if (
        !canSeeRoute(
          MoistureLoadCalc.hasPermissionForMlc(
            sharedState.user.applicationClaims
          )
        )
      ) {
        return [state, Navigation.pushUrl("/")];
      }

      if (state.moistureLoadManagerState) {
        return UpdateMoistureLoadManager(
          sharedState,
          stateWithMatch,
          MoistureLoadManager.Action.search(),
          browserTitleAction
        );
      }

      const [moistureLoadManagerState, moistureloadmanagerCmd] =
        MoistureLoadManager.init(sharedState);
      return [
        {
          ...stateWithMatch,
          moistureLoadManagerState
        },
        Cmd.map(Action.dispatchMoistureLoadManager, moistureloadmanagerCmd),
        [browserTitleAction]
      ];
    }

    case "MoistureLoadCalc": {
      if (
        !canSeeRoute(
          MoistureLoadCalc.hasPermissionForMlc(
            sharedState.user.applicationClaims
          )
        )
      ) {
        return [state, Navigation.pushUrl("/")];
      }
      const [moistureLoadCalcState, moistureLoadCalcCmd] =
        MoistureLoadCalc.init(
          sharedState,
          urlMatch.location.location.params.moistureLoadId
        );
      return [
        {
          ...stateWithMatch,
          moistureLoadCalcState
        },
        Cmd.map(Action.dispatchMoistureLoadCalc, moistureLoadCalcCmd),
        [browserTitleAction]
      ];
    }

    case "MoistureLoadWizard": {
      if (
        !canSeeRoute(
          MoistureLoadCalc.hasPermissionForMlc(
            sharedState.user.applicationClaims
          )
        )
      ) {
        return [state, Navigation.pushUrl("/")];
      }
      const [moistureLoadWizardState, moistureLoadWizardCmd] =
        MoistureLoadWizard.init(sharedState);
      return [
        {
          ...stateWithMatch,
          moistureLoadWizardState
        },
        Cmd.map(Action.dispatchMoistureLoadWizard, moistureLoadWizardCmd),
        [browserTitleAction]
      ];
    }

    case "SystemSelectionGuide": {
      const [systemSelectionGuideState, systemSelectionGuideCmd] =
        SystemSelectionGuide.init(sharedState);
      return [
        {
          ...stateWithMatch,
          systemSelectionGuideState: systemSelectionGuideState
        },
        Cmd.map(Action.dispatchSystemSelectionGuide, systemSelectionGuideCmd),
        [browserTitleAction]
      ];
    }

    case "SystemSelectionGuideWithMoistureLoad": {
      if (
        !canSeeRoute(
          MoistureLoadCalc.hasPermissionForMlc(
            sharedState.user.applicationClaims
          )
        )
      ) {
        return [state, Navigation.pushUrl("/")];
      }

      const [systemSelectionGuideState, systemSelectionGuideCmd] =
        SystemSelectionGuide.init(
          sharedState,
          urlMatch.location.location.params.mNo
        );
      return [
        {
          ...stateWithMatch,
          systemSelectionGuideState: systemSelectionGuideState
        },
        Cmd.map(Action.dispatchSystemSelectionGuide, systemSelectionGuideCmd),
        [browserTitleAction]
      ];
    }
    case "LogViewer": {
      if (
        !Authorization.checkPermission(
          sharedState.user.applicationClaims,
          Authorization.genesysUserClaims.developer
        )
      ) {
        return [stateWithMatch];
      }
      const [logViewerState, logViewerCmd] = LogViewer.init(
        urlMatch.location.location,
        sharedState
      );
      return [
        { ...stateWithMatch, logViewerState },
        Cmd.map(Action.dispatchLogViewer, logViewerCmd)
      ];
    }

    case "DrySize": {
      const [drySizeState, drySizeCmd] = DrySize.init(
        sharedState,
        urlMatch.location.location.params.mNo
      );
      return [
        {
          ...stateWithMatch,
          drySizeState: drySizeState
        },
        Cmd.map(Action.dispatchDrySize, drySizeCmd),
        [browserTitleAction]
      ];
    }
    default: {
      return exhaustiveCheck(urlMatch.location, true);
    }
  }
}

function canSeeRoute(condition: boolean) {
  return condition;
}

function UpdateMoistureLoadManager(
  sharedState: SharedState.State,
  stateWithMatch: State,
  action: MoistureLoadManager.Action,
  browserTitleAction?: SharedState.Action
): mainStateUpdateReturn {
  const [moistureLoadManagerState, moistureloadmanagerCmd, sharedStateAction] =
    MoistureLoadManager.update(
      action,
      stateWithMatch.moistureLoadManagerState!,
      sharedState
    );

  let sharedActions = sharedStateAction ? [...sharedStateAction] : [];

  return [
    {
      ...stateWithMatch,
      moistureLoadManagerState
    },
    Cmd.map(Action.dispatchMoistureLoadManager, moistureloadmanagerCmd),
    [...sharedActions, browserTitleAction]
  ];
}
// tslint:disable-next-line: max-file-line-count
