import * as React from "react";
import { Quantity } from "@genesys/uom";
import * as PropertiesSelector from "../react-properties-selector";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as Elements from "../elements";
import * as PropertiesSelectorElements from "./elements";
import { RowRenderer, ProductPropertyRowRenderer } from "./row-renderer";

interface ProductPropertyRowRenderer {
  readonly type: "ProductPropertyRowRenderer";
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly valueSourcesDict: {
    readonly [
      propertyName: string
    ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  };
  readonly propertyFormats: PropertiesSelector.PropertyFormats;
}
interface StandardRowRenderer {
  readonly type: "StandardRowRenderer";
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
}

type RowRendererType = ProductPropertyRowRenderer | StandardRowRenderer;

interface Props {
  readonly renderGroup: GenesysPropertiesSelectorTypes.RenderGroup;
  readonly fieldGroup: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly hidePropertyNames: boolean;
  readonly rowRendererType: RowRendererType;
  readonly selectorInfoMap: {
    readonly [key: string]: PropertiesSelector.PropertySelectorRenderInfo;
  };
  readonly conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly onQuantityChange: (
    propertyName: string,
    quantity: Quantity.Quantity
  ) => void;
  readonly showGroups: boolean;
}

// tslint:disable-next-line:function-name
export function GroupRenderer({
  activatedQuantitySelectors,
  conversionParametersMap,
  fieldGroup,
  filterPrettyPrint,
  hidePropertyNames,
  onQuantityChange,
  renderGroup,
  rowRendererType,
  selectorInfoMap,
  showGroups
}: Props) {
  return (
    <PropertiesSelectorElements.PropertySelectorGroup>
      {showGroups &&
        renderGroup.key !== GenesysPropertiesSelectorTypes.NO_GROUP && (
          <Elements.ExpanderWithArrow
            onToggle={renderGroup.onToggle}
            isCollapsed={renderGroup.isCollapsed}
            text={renderGroup.translatedName}
          />
        )}
      <PropertiesSelectorElements.PropertySelectorGroupRows
        hidden={renderGroup.isCollapsed}
      >
        <PropertiesSelectorElements.StyledPropertySelectorGroupTable>
          <tbody>
            {rowRendererType.type === "ProductPropertyRowRenderer"
              ? renderGroup.properties.map(renderProperty => {
                  const selectorInfo =
                    selectorInfoMap[renderProperty.propertyName];
                  return (
                    <ProductPropertyRowRenderer
                      fieldName={renderProperty.fieldName}
                      key={`${renderGroup.key}-${renderProperty.propertyName}`}
                      fieldGroup={fieldGroup}
                      hidePropertyName={hidePropertyNames}
                      valueSourcesDict={rowRendererType.valueSourcesDict}
                      selectorInfo={selectorInfo}
                      conversionParameters={
                        conversionParametersMap[renderProperty.propertyName]
                      }
                      isQuantitySelectorActive={
                        !!activatedQuantitySelectors[
                          renderProperty.propertyName
                        ]
                      }
                      onQuantityChange={quantity =>
                        onQuantityChange(renderProperty.propertyName, quantity)
                      }
                      translatePropertyValue={
                        rowRendererType.translatePropertyValue
                      }
                      filterPrettyPrint={filterPrettyPrint}
                    />
                  );
                })
              : renderGroup.properties.map(renderProperty => {
                  const selectorInfo =
                    selectorInfoMap[renderProperty.propertyName];
                  return (
                    <RowRenderer
                      fieldName={renderProperty.fieldName}
                      key={`${renderGroup.key}-${renderProperty.propertyName}`}
                      fieldGroup={fieldGroup}
                      hidePropertyName={hidePropertyNames}
                      selectorInfo={selectorInfo}
                      filterPrettyPrint={filterPrettyPrint}
                    />
                  );
                })}
          </tbody>
        </PropertiesSelectorElements.StyledPropertySelectorGroupTable>
      </PropertiesSelectorElements.PropertySelectorGroupRows>
    </PropertiesSelectorElements.PropertySelectorGroup>
  );
}
