import * as React from "react";
import { StandardButton } from "@genesys/ui-elements";
import styled from "styled-components";
import * as SharedState from "../shared-state";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";
import { clientConfig, getModalBorderColor } from "../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

interface Props {
  readonly sharedState: SharedState.State;
  readonly header: string;
  readonly content: React.ReactNode;
  readonly confirmLabel?: string;
  readonly cancelLabel?: string;
  readonly onClose: () => void;
  readonly onConfirm?: () => void;
}

const ButtonsContainer = styled.div`
  margin: 0 0 20px 40px;
  button {
    margin-right: 20px;
  }
`;

export function ModalComponent({
  sharedState,
  cancelLabel,
  confirmLabel = sharedState.translate(LanguageTexts.confirm()),
  content,
  header,
  onClose,
  onConfirm
}: Props) {
  const renderCancelLabel = () => {
    if (cancelLabel) {
      return cancelLabel;
    }
    if (!onConfirm) {
      return sharedState.translate(LanguageTexts.close());
    }
    return sharedState.translate(LanguageTexts.cancel());
  };

  return (
    <Modal maxWidth={600} onClose={onClose}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        {header}
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ButtonsContainer>
        <StandardButton buttonType="Secondary-2" size="Small" onClick={onClose}>
          {renderCancelLabel()}
        </StandardButton>
        {onConfirm && (
          <StandardButton
            buttonType="Primary-2"
            size="Small"
            onClick={onConfirm}
          >
            {confirmLabel}
          </StandardButton>
        )}
      </ButtonsContainer>
    </Modal>
  );
}
