import { exhaustiveCheck } from "ts-exhaustive-check";
// import gql from "graphql-tag";
import * as SharedState from "../../../shared-state";
import * as System from "../../system";
import * as Product from "../../product";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import * as MCompareInput from "./input";
import * as MCompareResult from "./result";
import { Cmd } from "@typescript-tea/core";
// import * as GraphQlTypes from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValueSet } from "@genesys/property";

export type State = {
  readonly calculateMCompare: boolean;
  readonly activeTab: ActiveTab;
  readonly inputState: MCompareInput.State;
  readonly resultState: MCompareResult.State;
  readonly canSave: boolean;
};

export type ActiveTab = "Input" | "Results";

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  products: ReadonlyArray<Product.Product>
): [State, Cmd<Action>?] => {
  const binSelections = PropertyValueSet.fromString(system.binSelections || "");
  const binCasesExists = system.operatingCases.some(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );

  const [resultState, resultCmd] = MCompareResult.init(sharedState, system);

  const [inputState, inputCmd] = MCompareInput.init(
    sharedState,
    system,
    products
  );

  return [
    {
      calculateMCompare:
        !PropertyValueSet.isEmpty(binSelections) &&
        binCasesExists &&
        system.components.some(c => c.productId.endsWith("MCS")),
      activeTab: "Input",
      inputState,
      resultState,
      canSave: false
    },
    Cmd.batch<Action>([
      Cmd.map(Action.dispatchInput, inputCmd),
      Cmd.map(Action.dispatchResult, resultCmd)
    ])
  ];
};

export const Action = ctorsUnion({
  dispatchInput: (action: MCompareInput.Action) => ({ action }),
  dispatchResult: (action: MCompareResult.Action) => ({ action }),
  toggleMCompare: () => ({}),
  setActiveTab: (tab: ActiveTab) => ({ tab })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "toggleMCompare": {
      const newValue = !state.calculateMCompare;
      return [
        { ...state, calculateMCompare: newValue, canSave: !state.canSave }
      ];
    }
    case "dispatchInput": {
      const [inputState, inputCmd, inputSharedStateAction] =
        MCompareInput.update(
          action.action,
          state.inputState,
          sharedState,
          system
        );
      return [
        {
          ...state,
          inputState
        },
        Cmd.map(Action.dispatchInput, inputCmd),
        inputSharedStateAction
      ];
    }

    case "dispatchResult": {
      const [resultState, resultCmd, resultSharedStateAction] =
        MCompareResult.update(action.action, state.resultState, sharedState);
      return [
        {
          ...state,
          resultState
        },
        Cmd.map(Action.dispatchResult, resultCmd),
        resultSharedStateAction
      ];
    }

    case "setActiveTab": {
      return [
        {
          ...state,
          activeTab: action.tab
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
