export const numberOfUnits = "numberofunits";

export const supplyOutletAirflow = "supplyoutletairflow";
export const supplyOutletFlow = "supplyoutletflow";

export const caseName = "casename";
export const sortNo = "sortno";

export const dhmodel = "dhmodel";

export const totalSupplyOutletAirflow = "totalsupplyoutletairflow";
export const sourceTotalSupplyOutletAirflow = "source_totalsupplyoutletairflow";
