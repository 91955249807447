import gql from "graphql-tag";

export const setUserSettingsPsychrometricChartMutation = gql`
  mutation SetUserSettingsPsychrometricChart(
    $newsettings: UserSettingsPsychrometricChartInputType!
  ) {
    setUserSettingsPsychrometricChart(
      userSettingsPsychrometricChart: $newsettings
    ) {
      id
    }
  }
`;
