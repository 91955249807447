import styled from "styled-components";
import * as React from "react";

interface Props {
  readonly maxWidth?: number;
}

// tslint:disable-next-line
const UnStyledContainer: React.FunctionComponent<
  Props & React.HTMLProps<HTMLDivElement>
> = props => {
  const { maxWidth, ...unStyledContainerProps } = props;

  return <div {...unStyledContainerProps}>{props.children}</div>;
};

// tslint:disable-next-line
export const StandardContainer = styled(UnStyledContainer)`
  padding: 10px;

  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px` : "")};
`;
