import { OperationTime } from "./types";
export type Action = OperationTimeDialogChangeAction | Dummy;
interface OperationTimeDialogChangeAction {
  readonly type: "operation-time-dialog/CHANGE";
  readonly operationTime: OperationTime;
}

export function operationTimeDialogChange(
  operationTime: OperationTime
): OperationTimeDialogChangeAction {
  return {
    type: "operation-time-dialog/CHANGE",
    operationTime: operationTime
  };
}

interface Dummy {
  readonly type: "operation-time-dialog/DUMMY";
}

export function dummy(): Dummy {
  return {
    type: "operation-time-dialog/DUMMY"
  };
}
