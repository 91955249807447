import * as Types from "./types";
import * as Helper from "./helper";
import * as Calculations from "@munters/calculations";

export function arrangeSubGraphs(
  diagram: Types.Diagram,
  expandSize: number
): Types.Diagram {
  const subGraphsDict = diagram.nodes.reduce(
    (a, b) =>
      a[b.subGraphId]
        ? { ...a, [b.subGraphId]: [...a[b.subGraphId], b] }
        : { ...a, [b.subGraphId]: [b] },
    {} as { readonly [key: string]: Array<Types.Node> }
  );

  const subGraphBounds = Object.keys(subGraphsDict).reduce(
    (a, b) => ({
      ...a,
      [b]: Calculations.Math.BoundingRect.expand(
        expandSize,
        subGraphsDict[b]
          .map(node => Helper.getNodeBounds(node))
          .reduce((aa, bb) =>
            Calculations.Math.BoundingRect.unionBoundingRect(aa, bb)
          )
      )
    }),
    {} as {
      readonly [key: string]: Calculations.Math.BoundingRect.BoundingRect;
    }
  );

  const mid = Object.keys(subGraphBounds)
    .map(key => subGraphBounds[key])
    .reduce((a, b) => Math.max(a, b.halfSize.x), 0);
  const newNodes = Array<Types.Node>();
  let y = 0;

  Object.keys(subGraphsDict).map(key => {
    const bounds = subGraphBounds[key];
    const adjustX = mid - bounds.position.x;
    const adjustY = y + bounds.halfSize.y - bounds.position.y;
    subGraphsDict[key].forEach(node =>
      newNodes.push({
        ...node,
        transform: Calculations.Math.Matrix2.setTranslation(
          Calculations.Math.Vector2.vec2Add(
            Calculations.Math.Matrix2.getTranslation(node.transform),
            Calculations.Math.Vector2.vec2Create(adjustX, adjustY)
          ),
          node.transform
        )
      })
    );
    y += bounds.halfSize.y * 2 + 100;
  });

  return {
    ...diagram,
    nodes: newNodes
  };
}
