import { PropertyValueSet } from "@genesys/property";
import { getAllPropertyUpdates, PropertyDefinitions } from "../../tools";

export function updatedPropertiesPreHook(
  oldProperties: PropertyValueSet.PropertyValueSet,
  currentProperties: PropertyValueSet.PropertyValueSet,
  propertyDefinitions: PropertyDefinitions
) {
  return PropertyValueSet.merge(
    getAllPropertyUpdates(
      oldProperties,
      currentProperties,
      propertyDefinitions,
      true
    ),
    currentProperties
  );
}
