import * as React from "react";
// import * as SharedState from "../../shared-state";
// import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "../../styled-components";

import { H3, StandardButton, Pop } from "../../";

const ConfirmContainer = styled.div`
  padding: 15px 20px;
  border: 1px solid #dddddd;
  background-color: white;
  white-space: nowrap;
  > div {
    display: flex;
    button {
      margin: 15px 10px 0 0;
    }
  }
`;

export function Confirm({
  showDialog,
  labelRef,
  headerText,
  cancelTranslated,
  confirmTranslated,
  onConfirm,
  onCancel
}: {
  readonly showDialog: boolean;
  readonly labelRef: React.MutableRefObject<HTMLElement | null>;
  readonly headerText: string;
  readonly cancelTranslated: string;
  readonly confirmTranslated: string;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
}) {
  const ref = labelRef?.current as Element;

  return (
    <div>
      {ref && showDialog && (
        <Pop
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchor={ref}
          onClose={onCancel}
        >
          <ConfirmContainer>
            <H3 color="dark" weight="normal">
              {headerText}
            </H3>
            <div>
              <StandardButton
                buttonType="Secondary-2"
                size="Small"
                onClick={onCancel}
              >
                {cancelTranslated}
              </StandardButton>

              <StandardButton
                buttonType="Primary-2"
                size="Small"
                onClick={() => onConfirm()}
              >
                {confirmTranslated}
              </StandardButton>
            </div>
          </ConfirmContainer>
        </Pop>
      )}
    </div>
  );
}
