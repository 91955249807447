import * as PropertiesSelector from "../../properties-selector";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../../shared-state";
import { PropertyFilter } from "@genesys/property";
import { SystemType } from "../types";

/*
TODO: 
- Ligger textIds med p_SSG_rangetype etc och skräpar i munter_texts nu när vi inte har en riktigt product för detta
*/

export function getAllowedRanges(
  sharedState: SharedState.State,
  systemTypes: ReadonlyArray<SystemType>
) {
  return (
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.systemTypeClaims.create
    ) || []
  ).reduce((soFar, current) => {
    const splits = current.split(";");
    const systemId = splits[0];
    if (!systemTypes.find(st => st.id === systemId)) {
      return soFar;
    }
    const filter = splits[1];
    const rangeType = parseInt(filter.split("rangetype=")[1].charAt(0), 10);
    return soFar.includes(rangeType) ? soFar : soFar.concat([rangeType]);
  }, [] as ReadonlyArray<number>);
}

export function filterPropertiesForRange(
  productProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>,
  allowedRanges: ReadonlyArray<number>
) {
  return productProperties.map(p => {
    if (p.name !== "rangetype") {
      return p;
    } else {
      return {
        ...p,
        items: p.items.filter(i =>
          allowedRanges.some(ar => ar === (i.value!.value as number))
        )
      };
    }
  });
}

export function createNewProductProperties(
  sharedState: SharedState.State,
  allowedRanges: readonly number[]
): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  const validateRangeValue = (value: number) => {
    const isDeveloper = Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.developer
    );
    if (isDeveloper) {
      return true;
    }
    return allowedRanges.includes(value);
  };
  return [
    {
      name: "rangetype",
      quantity: "Discrete",
      group: "mainselections",
      sortNo: 10,
      defaultValues: [],
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [
        {
          sortNo: 0,
          value: { type: "integer", value: 1 },
          validationFilter: PropertyFilter.Empty,
          text: "Released",
          descriptionValuesTexts: []
        },
        {
          sortNo: 1,
          value: { type: "integer", value: 4 },
          validationFilter: PropertyFilter.Empty,
          text: "OEM",
          descriptionValuesTexts: []
        }
      ].filter(item => validateRangeValue(item.value?.value)) as any,
      valueSources: [],
      conversionParameters: [],
      descriptionTexts: []
    },
    {
      name: "airsource",
      quantity: "Discrete",
      group: "mainselections",
      defaultValues: [],
      sortNo: 20,
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [
        {
          sortNo: 1,
          value: { type: "integer", value: 1 },
          validationFilter: PropertyFilter.Empty,
          text: "Outdoors",
          descriptionValuesTexts: []
        },
        {
          sortNo: 2,
          value: { type: "integer", value: 2 },
          validationFilter: PropertyFilter.Empty,
          text: "Return air",
          descriptionValuesTexts: []
        },
        {
          sortNo: 3,
          value: { type: "integer", value: 3 },
          validationFilter: PropertyFilter.Empty,
          text: "Custom",
          descriptionValuesTexts: []
        }
      ],
      valueSources: [],
      conversionParameters: [],
      descriptionTexts: []
    },
    {
      name: "voltage",
      quantity: "Discrete",
      group: "mainselections",
      defaultValues: [],
      sortNo: 30,
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [
        {
          sortNo: 115150,
          value: { type: "integer", value: 115150 },
          validationFilter: PropertyFilter.Empty,
          text: "115/1/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 115160,
          value: { type: "integer", value: 115160 },
          validationFilter: PropertyFilter.Empty,
          text: "115/1/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 200350,
          value: { type: "integer", value: 200350 },
          validationFilter: PropertyFilter.Empty,
          text: "200/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 200360,
          value: { type: "integer", value: 200360 },
          validationFilter: PropertyFilter.Empty,
          text: "200/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 220150,
          value: { type: "integer", value: 220150 },
          validationFilter: PropertyFilter.Empty,
          text: "220/1/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 220160,
          value: { type: "integer", value: 220160 },
          validationFilter: PropertyFilter.Empty,
          text: "220/1/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 220350,
          value: { type: "integer", value: 220350 },
          validationFilter: PropertyFilter.Empty,
          text: "220/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 220360,
          value: { type: "integer", value: 220360 },
          validationFilter: PropertyFilter.Empty,
          text: "220/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 230150,
          value: { type: "integer", value: 230150 },
          validationFilter: PropertyFilter.Empty,
          text: "230/1/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 230160,
          value: { type: "integer", value: 230160 },
          validationFilter: PropertyFilter.Empty,
          text: "230/1/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 230350,
          value: { type: "integer", value: 230350 },
          validationFilter: PropertyFilter.Empty,
          text: "230/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 230360,
          value: { type: "integer", value: 230360 },
          validationFilter: PropertyFilter.Empty,
          text: "230/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 240150,
          value: { type: "integer", value: 240150 },
          validationFilter: PropertyFilter.Empty,
          text: "240/1/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 380350,
          value: { type: "integer", value: 380350 },
          validationFilter: PropertyFilter.Empty,
          text: "380/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 380360,
          value: { type: "integer", value: 380360 },
          validationFilter: PropertyFilter.Empty,
          text: "380/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 400350,
          value: { type: "integer", value: 400350 },
          validationFilter: PropertyFilter.Empty,
          text: "400/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 415350,
          value: { type: "integer", value: 415350 },
          validationFilter: PropertyFilter.Empty,
          text: "415/3/50",
          descriptionValuesTexts: []
        },
        {
          sortNo: 440360,
          value: { type: "integer", value: 440360 },
          validationFilter: PropertyFilter.Empty,
          text: "440/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 460360,
          value: { type: "integer", value: 460360 },
          validationFilter: PropertyFilter.Empty,
          text: "460/3/60",
          descriptionValuesTexts: []
        },
        {
          sortNo: 480360,
          value: { type: "integer", value: 480360 },
          validationFilter: PropertyFilter.Empty,
          text: "480/3/60",
          descriptionValuesTexts: []
        }
      ],
      valueSources: [],
      conversionParameters: [],
      descriptionTexts: []
    },
    {
      name: "humidityoption",
      quantity: "Discrete",
      group: "mainselections",
      defaultValues: [],
      sortNo: 40,
      validationFilter: PropertyFilter.Empty,

      visibilityFilter: PropertyFilter.Empty,
      items: [
        {
          sortNo: 0,
          value: { type: "integer", value: 0 },
          validationFilter: PropertyFilter.Empty,
          text: "Dehumidification",
          descriptionValuesTexts: []
        },
        {
          sortNo: 1,
          value: { type: "integer", value: 1 },
          validationFilter: PropertyFilter.Empty,
          text: "Humidification",
          descriptionValuesTexts: []
        }
      ],
      valueSources: [],
      conversionParameters: [],
      descriptionTexts: []
    }
  ];
}
