import * as GQLCachePatch from "gql-cache-patch";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as GraphQLMutations from "../graphql-mutations";

export function updateAmountFieldProfileQueuedMutation(
  id: string,
  name: string,
  measureSystem: number
): GraphQLMutationsQueue.QueuedMutation<
  GraphQLTypes.SetAmountProfile,
  GraphQLTypes.SetAmountProfileVariables
> {
  const mutation: GraphQLMutationsQueue.QueuedMutation<
    GraphQLTypes.SetAmountProfile,
    GraphQLTypes.SetAmountProfileVariables
  > = {
    document: GraphQLMutations.setAmountProfileMutation,
    variables: {
      amountProfile: {
        id,
        measureSystem,
        name
      }
    },
    optimisticCachePatches: updateAmountFieldProfileCachePatches(
      id,
      name,
      measureSystem
    )
  };

  return mutation;
}

export function setActiveAmountFieldProfileQueuedMutation(
  profileId: string,
  userProfileId: string
): GraphQLMutationsQueue.QueuedMutation<
  GraphQLTypes.SetActiveAmountProfile,
  GraphQLTypes.SetActiveAmountProfileVariables
> {
  const settingsKey = `UserSettingsType;userSettings-${userProfileId}`;
  const profileCacheKey = `AmountFieldProfileType;${profileId}`;
  const mutation: GraphQLMutationsQueue.QueuedMutation<
    GraphQLTypes.SetActiveAmountProfile,
    GraphQLTypes.SetActiveAmountProfileVariables
  > = {
    document: GraphQLMutations.setActiveAmountProfileMutation,
    variables: {
      amountProfileId: profileId
    },
    optimisticCachePatches: [
      GQLCachePatch.updateField<
        GraphQLTypes.ScreenAmountProfileProviderUserData["user"]["userProfile"]["userSettings"]
      >(settingsKey, "selectedAmountFieldProfile", profileCacheKey)
    ]
  };

  return mutation;
}

export function saveAmountFieldFormatQueuedMutation(
  amountFieldProfileId: string,
  measureSystem: number,
  fieldGroup: string,
  fieldName: string,
  decimalCount: number,
  unit: string
): GraphQLMutationsQueue.QueuedMutation<
  GraphQLTypes.SaveAmountFieldFormat,
  GraphQLTypes.SaveAmountFieldFormatVariables
> {
  const mutation: GraphQLMutationsQueue.QueuedMutation<
    GraphQLTypes.SaveAmountFieldFormat,
    GraphQLTypes.SaveAmountFieldFormatVariables
  > = {
    document: GraphQLMutations.saveAmountFieldFormatMutation,
    variables: {
      amountFieldFormat: {
        amountFieldProfileId,
        decimalCount,
        fieldGroup,
        fieldName,
        unit
      }
    },
    optimisticCachePatches: saveAmountFormatOptimisticCachePatches(
      amountFieldProfileId,
      measureSystem,
      fieldGroup,
      fieldName,
      decimalCount,
      unit
    )
  };

  return mutation;
}

export function deleteAmountFieldFormatQueuedMutation(
  amountFieldProfileId: string,
  fieldGroup: string,
  fieldName: string
): GraphQLMutationsQueue.QueuedMutation<
  GraphQLTypes.DeleteAmountFieldFormat,
  GraphQLTypes.DeleteAmountFieldFormatVariables
> {
  const mutation: GraphQLMutationsQueue.QueuedMutation<
    GraphQLTypes.DeleteAmountFieldFormat,
    GraphQLTypes.DeleteAmountFieldFormatVariables
  > = {
    document: GraphQLMutations.deleteAmountFieldFormatMutation,
    variables: {
      amountFieldFormat: {
        amountFieldProfileId,
        fieldGroup,
        fieldName
      }
    },
    optimisticCachePatches: deleteAmountFormatOptimisticCachePatches(
      amountFieldProfileId,
      fieldGroup,
      fieldName
    )
  };

  return mutation;
}

function deleteAmountFormatOptimisticCachePatches(
  amountFieldProfileId: string,
  fieldGroup: string,
  fieldName: string
): ReadonlyArray<GQLCachePatch.CachePatch> {
  const profileCacheKey = `AmountFieldProfileType;${amountFieldProfileId}`;
  const formatCacheKey = `AmountFieldFormatType;${amountFieldProfileId};${fieldGroup};${fieldName}`;
  return [
    GQLCachePatch.removeEntityElement<
      GraphQLTypes.ScreenAmountProfileProviderUserData["user"]["userProfile"]["userSettings"]["selectedAmountFieldProfile"]
    >(profileCacheKey, "amountFieldFormats", formatCacheKey),
    GQLCachePatch.deleteEntity(formatCacheKey)
  ];
}

function saveAmountFormatOptimisticCachePatches(
  amountFieldProfileId: string,
  measureSystem: number,
  fieldGroup: string,
  fieldName: string,
  decimalCount: number,
  unit: string
): ReadonlyArray<GQLCachePatch.CachePatch> {
  const profileCacheKey = `AmountFieldProfileType;${amountFieldProfileId}`;
  const formatCacheKey = `AmountFieldFormatType;${amountFieldProfileId};${fieldGroup};${fieldName}`;
  return [
    GQLCachePatch.createEntity<
      GraphQLTypes.ScreenAmountProfileProviderUserData["user"]["userProfile"]["userSettings"]["amountFieldProfiles"][0]["amountFieldFormats"][0]
    >(formatCacheKey, {
      __typename: "AmountFieldFormatType",
      id: formatCacheKey,
      fieldGroup,
      fieldName,
      decimalCount,
      unit,
      measureSystem
    }),
    GQLCachePatch.insertElement<
      GraphQLTypes.ScreenAmountProfileProviderUserData["user"]["userProfile"]["userSettings"]["selectedAmountFieldProfile"]
    >(profileCacheKey, "amountFieldFormats", 0, formatCacheKey)
  ];
}

function updateAmountFieldProfileCachePatches(
  id: string,
  name: string,
  measureSystem: number
): ReadonlyArray<GQLCachePatch.CachePatch> {
  const profileCacheKey = `AmountFieldProfileType;${id}`;
  return [
    GQLCachePatch.createEntity<
      GraphQLTypes.ScreenAmountProfileProviderUserData["user"]["userProfile"]["userSettings"]["amountFieldProfiles"][0]
    >(profileCacheKey, {
      __typename: "AmountFieldProfileType",
      id,
      name,
      measureSystem,
      isEditable: true,
      amountFieldFormats: []
    })
  ];
}
