import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as Routes from "../../routes";

import { Dispatch } from "@typescript-tea/core";
import { Open, HomeIcon, SystemsIcon } from "@genesys/ui-elements";
import { State, Action } from "../state";
import { MenuItem, Permisssions } from "../types";
import {
  genesysLogoContent,
  homeLogoContent,
  manageSystemsLogoContent,
  newSystemLogoContent,
  helpIconContent,
  profileIconContent,
  userSettingsIconContent
} from "./contents";
export function getHeaderMenuItems(
  logoClicked: () => void,
  enviromentStlye: { readonly logo: JSX.Element },
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
): ReadonlyArray<MenuItem> {
  return [
    {
      type: "custom",
      isVisable: true,
      name: "genesys-logo",
      content: genesysLogoContent(logoClicked, enviromentStlye)
    },

    {
      type: "custom",
      isVisable: true,
      name: "home-logo",
      content: homeLogoContent(sharedState, activeRoute)
    },
    {
      type: "custom",
      isVisable: true,
      name: "new-system-logo",
      content: newSystemLogoContent(sharedState, activeRoute)
    },
    {
      type: "custom",
      isVisable: true,
      name: "manage-systems-logo",
      content: manageSystemsLogoContent(sharedState, activeRoute)
    }
  ];
}

export function getMainMenuMenuItems(
  sharedState: SharedState.State,
  state: State,
  dispatch: Dispatch<Action>,
  permissions: Permisssions
): ReadonlyArray<MenuItem> {
  const {
    canUsePricing,
    canSeePricing,
    canSeeMoistureload,
    canSeeSystemSelectionGuide,
    canSeeMRC
  } = permissions;
  return [
    {
      type: "dropdown",
      name: "system",
      label: sharedState.translate(LanguageTexts.system()),
      isOpen: state.openMenu === "system",
      isVisable: true,
      routes: ["SystemManager", "SystemConfiguration", "Wizard"],
      toggleDropdown: () =>
        dispatch(
          Action.setOpenMenu(state.openMenu === "system" ? undefined : "system")
        ),
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.newsystem()),
          href: "/new-system",
          isVisable: true,
          icon: Open
        },
        {
          label: sharedState.translate(LanguageTexts.systemsmanager()),
          href: "/system-manager",
          isVisable: true,
          icon: SystemsIcon
        },
        {
          label: sharedState.translate(LanguageTexts.recentsystemsheader()),
          href: "/",
          isVisable: true,
          icon: HomeIcon
        }
      ]
    },
    {
      type: "dropdown",
      name: "tools",
      label: sharedState.translate(LanguageTexts.tools()),
      isOpen: state.openMenu === "tools",
      toggleDropdown: () =>
        dispatch(
          Action.setOpenMenu(state.openMenu === "tools" ? undefined : "tools")
        ),
      isVisable: true,
      routes: ["Tools"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.airMixer()),
          href: "/tools/air-mixing",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.airPowerTool()),
          href: "/tools/air-power",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.humidityConversion()),
          href: "/tools/humidity-conversion",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.psychrometricChart()),
          href: "/tools/psychrometric-chart",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.binPage()),
          href: "/tools/weather-data-binning",
          isVisable: true
        }
      ]
    },
    {
      type: "dropdown",
      name: "pricing",
      label: sharedState.translate(LanguageTexts.pricing()),
      isOpen: state.openMenu === "pricing",
      toggleDropdown: () =>
        dispatch(
          Action.setOpenMenu(
            state.openMenu === "pricing" ? undefined : "pricing"
          )
        ),
      isVisable: canUsePricing || canSeePricing,
      routes: ["PricingEditor", "PricingManager", "PricingWizard"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.pricingmanager()),
          href: "/pricing-manager",
          isVisable: true
        },

        {
          label: sharedState.translate(LanguageTexts.newPricing()),
          href: "/new-pricing",
          isVisable: canUsePricing
        }
      ]
    },

    {
      type: "dropdown",
      name: "moisture-load",
      label: sharedState.translate(LanguageTexts.moistureLoad()),
      isOpen: state.openMenu === "moisture-load",
      toggleDropdown: () =>
        dispatch(
          Action.setOpenMenu(
            state.openMenu === "moisture-load" ? undefined : "moisture-load"
          )
        ),
      isVisable: canSeeMoistureload,
      routes: ["MoistureLoadCalc", "MoistureLoadManager", "MoistureLoadWizard"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.moistureLoadManager()),
          href: "/moisture-load-manager",
          isVisable: canSeeMoistureload
        },
        {
          label: sharedState.translate(LanguageTexts.newMoistureLoadCalc()),
          href: "/new-moisture-load",
          isVisable: canSeeMoistureload
        }
      ]
    },

    {
      type: "link",
      name: "system-selection-guide",
      label: "Selection Guide",
      href: "/system-selection-guide",
      routes: ["SystemSelectionGuide"],
      isVisable: canSeeSystemSelectionGuide
    },
    {
      type: "link",
      name: "mrc",
      label: "MRC",
      href: "https://mrc.genesys.munters.com/",
      isVisable: canSeeMRC,
      routes: [],
      openInNewTab: true
    }
  ];
}
export function getHelpAndUserMenuItems(
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
): ReadonlyArray<MenuItem> {
  return [
    {
      type: "custom",
      name: "helpdesk",
      content: helpIconContent(sharedState),
      isVisable: true
    },
    {
      type: "custom",
      name: "user-settings",
      content: userSettingsIconContent(sharedState, activeRoute),
      isVisable: true
    },

    {
      type: "custom",
      name: "profile",
      content: profileIconContent(sharedState),
      isVisable: true
    }
  ];
}
