import * as React from "react";
import { StandardButton } from "@genesys/ui-elements";
import styled from "styled-components";
import * as SharedState from "../../shared-state";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";
import { clientConfig, getModalBorderColor } from "../../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

interface Props {
  readonly sharedState: SharedState.State;
  readonly header: string;
  readonly content: React.ReactNode;
  readonly onClose: () => void;
  readonly onConfirm?: () => void;
}

const ButtonsContainer = styled.div`
  margin: 0 0 20px 40px;
  button {
    margin-right: 20px;
  }
`;

export function ModalComponent({
  sharedState,
  content,
  header,
  onClose,
  onConfirm
}: Props) {
  return (
    <Modal maxWidth={600} onClose={() => onClose()}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        {header}
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ButtonsContainer>
        <StandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={() => onClose()}
        >
          {!onConfirm
            ? sharedState.translate(LanguageTexts.close())
            : sharedState.translate(LanguageTexts.cancel())}
        </StandardButton>
        {onConfirm && (
          <StandardButton
            buttonType="Primary-2"
            size="Small"
            onClick={() => onConfirm()}
          >
            {sharedState.translate(LanguageTexts.confirm())}
          </StandardButton>
        )}
      </ButtonsContainer>
    </Modal>
  );
}
