import gql from "graphql-tag";

export const setMoistureLoadACLMutation = gql`
  mutation SetMoistureLoadACLMutation(
    $input: UpdateMoistureLoadFileAccessInputType!
  ) {
    setMoistureLoadACL(input: $input) {
      id
      errorMessage
    }
  }
`;
