export const All = "All";
export const Empty = "Empty";

export type TraceSpan = {
  readonly serviceName: string;
  readonly spanId: string;
  readonly name: string;
  readonly parentSpanId: string;
  readonly startTimeUnixNano: number;
  readonly endTimeUnixNano: number;
  readonly parallellGroupId: string;
  readonly isFireAndForget: boolean;
  readonly isSummarySpan: boolean;
};

export type LogRowFromServer = {
  readonly timestamp: number;
  readonly serviceName: string;
  readonly body: string;
  readonly category: string;
  readonly errorCode: number;
  readonly caseType: string;
  readonly caseName: string;
  readonly component: string;
};

export type LogRow = LogRowFromServer & {
  readonly id: number;
  readonly formatted: string;
};

export type Timer = {
  readonly name: string;
  readonly startNs: number;
  readonly totalNs: number;
  readonly idleNs: number | undefined;
  readonly isFireAndForget: boolean;
  readonly allSingleSubTimers: ReadonlyArray<Timer>;
  readonly allParallellSubTimers: ReadonlyArray<ParallellTimer>;
};

export type ParallellTimer = {
  readonly startNs: number;
  readonly totalNs: number;
  readonly parallellSingleTimers: ReadonlyArray<Timer>;
};

export type TopLevelNodes = {
  readonly singleNodes: ReadonlyArray<SingleNode>;
  readonly multipleParallellNode: MultipleParallellNode | undefined;
};

export type SingleNode = {
  readonly name: string;
  readonly count: number;
  readonly startNs: number;
  readonly totalNs: number;
  readonly otherNs: number | undefined;
  readonly idleNs: number | undefined;
  readonly percentOfParent: number | undefined;
  readonly isFireAndForget: boolean;
  readonly singleNodes: ReadonlyArray<SingleNode>;
  readonly parallellNode: MultipleParallellNode | undefined;
};

export type MultipleParallellNode = {
  readonly count: number;
  readonly totalNs: number;
  readonly percentOfParent: number | undefined;
  readonly deepSummedNodes: ReadonlyArray<NodeInfo>;
  readonly individualParallellNodes: ReadonlyArray<ParallellNode>;
};
export type ParallellNode = {
  readonly name: string;
  readonly count: number;
  readonly startNs: number;
  readonly totalNs: number;
  readonly idleNs: number | undefined;
  readonly percentOfParent: number | undefined;
  readonly deepAveragedNodes: ReadonlyArray<NodeInfo>;
  readonly individualSingleNodes: ReadonlyArray<SingleNode>;
};

export type NodeInfo = NodeAverage | NodeSum;

export type NodeAverage = {
  readonly type: "Average";
  readonly name: string;
  readonly averageCount: number;
  readonly minCount: number;
  readonly maxCount: number;
  readonly startNs: number;
  readonly averageTotalNs: number;
  readonly minTotalNs: number;
  readonly maxTotalNs: number;
  readonly averageOtherNs: number | undefined;
  readonly minOtherNs: number | undefined;
  readonly maxOtherNs: number | undefined;
  readonly averageIdleNs: number | undefined;
  readonly minIdleNs: number | undefined;
  readonly maxIdleNs: number | undefined;
  readonly averagePercentOfParent: number | undefined;
  readonly subNodes: ReadonlyArray<NodeInfo>;
};

export type NodeSum = {
  readonly type: "Sum";
  readonly name: string;
  readonly count: number;
  readonly startNs: number;
  readonly totalNs: number;
  readonly otherNs: number | undefined;
  readonly idleNs: number | undefined;
  readonly percentOfParent: number | undefined;
  readonly subNodes: ReadonlyArray<NodeInfo>;
};

export type TimerNode = {
  readonly spanId: string;
  readonly name: string;
  readonly startTimeUnixNano: number;
  readonly endTimeUnixNano: number;
  readonly parallellGroupId: string;
  readonly isFireAndForget: boolean;
  readonly children: ReadonlyArray<TimerNode>;
};

export type TraceSpanParent = {
  readonly spanId: string;
  readonly children: ReadonlyArray<string>;
};
