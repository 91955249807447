import { Dispatch, EffectManager } from "@typescript-tea/core";
import { home } from "./home";
import { exhaustiveCheck } from "ts-exhaustive-check";

export const setup = () => () => undefined;

export function createEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: () => () => undefined,
    onEffects,
    onSelfAction
  };
}

// -- STATE

export interface State {}

// -- COMMANDS

export type MyCmd<A> =
  | PromiseCmd<A, unknown>
  | PromisesCmd<A, unknown>
  | ActionCmd<unknown>;

export interface PromiseCmd<A, T> {
  readonly home: typeof home;
  readonly type: "Promise";
  readonly promise: () => Promise<T>;
  readonly onResolved?: (response: T) => A;
  readonly onRejected?: (error: Error) => A;
}
export interface PromisesCmd<A, T> {
  readonly home: typeof home;
  readonly type: "Promises";
  readonly promiseFuncs: ReadonlyArray<() => Promise<T>>;
  readonly onResolved?: (response: T) => A;
  readonly onRejected?: (error: Error) => A;
}

export interface ActionCmd<ActionToDispatch> {
  readonly home: "promise";
  readonly type: "ActionCmd";
  readonly action: ActionToDispatch;
}

export function promiseCmd<A, T>(
  promise: () => Promise<T>,
  onResolved: (response: T) => A,
  onRejected?: (error: Error) => A
): PromiseCmd<A, T> {
  return {
    home: "promise",
    type: "Promise",
    promise,
    onResolved,
    onRejected
  };
}

export function promisesCmd<A, T>(
  promiseFuncs: ReadonlyArray<() => Promise<T>>,
  onResolved: (response: T) => A,
  onRejected?: (error: Error) => A
): PromisesCmd<A, T> {
  return {
    home: "promise",
    type: "Promises",
    promiseFuncs,
    onResolved,
    onRejected
  };
}

export function actionCmd<ActionToDispatch>(
  action: ActionToDispatch
): ActionCmd<ActionToDispatch> {
  return {
    home: "promise",
    type: "ActionCmd",
    action
  };
}

export function mapCmd<A1, A2>(
  actionMapper: (a: A1) => A2,
  cmd: MyCmd<A1>
): MyCmd<A2> {
  switch (cmd.type) {
    case "Promise": {
      const onResolved = cmd.onResolved;
      const onRejected = cmd.onRejected;
      return {
        ...cmd,
        onResolved:
          onResolved &&
          ((response: string) => actionMapper(onResolved(response))),
        onRejected:
          onRejected && ((error: Error) => actionMapper(onRejected(error)))
      };
    }
    case "Promises": {
      const onResolved = cmd.onResolved;
      const onRejected = cmd.onRejected;
      return {
        ...cmd,
        onResolved:
          onResolved &&
          ((response: string) => actionMapper(onResolved(response))),
        onRejected:
          onRejected && ((error: Error) => actionMapper(onRejected(error)))
      };
    }
    case "ActionCmd": {
      return cmd;
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}

// -- SUBSCRIPTIONS

export function mapSub<A1, A2>(__: (a: A1) => A2, _: never): never {
  throw new Error("Not implemented.");
}

// -- MANAGER

export function onEffects<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  cmds: ReadonlyArray<MyCmd<unknown>>,
  _: ReadonlyArray<never>,
  state: State
): State {
  let newState = state;
  for (const cmd of cmds) {
    switch (cmd.type) {
      case "Promise": {
        executePromise(
          cmd.promise,
          dispatchApp,
          cmd.onResolved,
          cmd.onRejected
        );
        break;
      }
      case "Promises": {
        if (cmd.promiseFuncs.length === 0) {
          break;
        }

        let promise = executePromise(
          cmd.promiseFuncs[0],
          dispatchApp,
          cmd.onResolved
        );

        for (let i = 1; i < cmd.promiseFuncs.length; i++) {
          promise = promise.then(() =>
            executePromise(
              cmd.promiseFuncs[i],
              dispatchApp,
              cmd.onResolved,
              cmd.onRejected
            )
          );
        }
        break;
      }
      case "ActionCmd": {
        dispatchApp(cmd.action as ActionApp);
        break;
      }
      default: {
        return exhaustiveCheck(cmd, true);
      }
    }
  }

  return newState;
}

function executePromise<ActionApp>(
  promiseFunc: () => Promise<unknown>,
  dispatchApp: Dispatch<ActionApp>,
  onResolved?: (response: unknown) => unknown,
  onRejected?: (error: Error) => unknown
): Promise<void> {
  return promiseFunc()
    .then(value => {
      if (onResolved) {
        dispatchApp(onResolved(value) as ActionApp);
      }
    })
    .catch(error => {
      if (onRejected) {
        dispatchApp(onRejected(error) as ActionApp);
      } else {
        throw error;
      }
    });
}

export function onSelfAction<ActionApp>(
  _dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  _: unknown,
  state: State
): State {
  return state;
}

export function mapPromiseEffect<A1, A2>(
  effect: ((dispatch: Dispatch<A1>) => void) | undefined,
  mapper?: (a: A1) => A2
): ((dispatch: Dispatch<A2>) => void) | undefined {
  if (!effect) {
    return effect;
  }

  const mapEffectFn = function _mapEffect(dispatchToApp: Dispatch<A2>): void {
    function interceptAppAction(action: A1): void {
      if (mapper !== undefined) {
        dispatchToApp(mapper(action));
      }
    }

    return effect(interceptAppAction);
  };
  return mapEffectFn;
}
