import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const resetUserSettingsMutation = gql`
  mutation ResetUserSettings {
    resetUserSettings {
      ...CompleteUserSettings
    }
  }
  ${Fragments.completeUserSettings}
`;
