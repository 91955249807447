import * as React from "react";
import styled from "styled-components";
import * as Elements from "@genesys/ui-elements";
import * as FileUpload from "@genesys/client-core/lib/file-upload";

const StyledFieldset = styled.fieldset`
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;

  legend {
    padding: 10px;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-height: 75px;
`;

const ErrorMessageContainer = styled.div`
  color: red;
`;

export interface Props {
  readonly showLoader: Boolean;
  readonly missingProps: ReadonlyArray<string>;
  readonly importSystem: (jsonText: string) => void;
  readonly onValidationFailed: (missingProps: ReadonlyArray<string>) => void;
}

export function SystemImportComponent({
  showLoader,
  missingProps,
  importSystem,
  onValidationFailed
}: Props): JSX.Element {
  const systemModelErrorMessage = missingProps.length > 0;

  return (
    <StyledFieldset>
      <legend>Import system model</legend>

      {systemModelErrorMessage && (
        <ErrorMessageContainer>
          <span>Validation failed. Missing props: </span>
          <div>{missingProps.join(",")}</div>
        </ErrorMessageContainer>
      )}
      <div>
        <FileUpload.FileUploadContainer
          accept={".json"}
          onFileUploaded={file => {
            const fileReader = new FileReader();
            fileReader.onload = async evt => {
              const jsonText: string = (evt.target as any).result;
              const missingProps = validateJsonString(jsonText);

              if (missingProps.length > 0) {
                return onValidationFailed(missingProps);
              }
              importSystem(jsonText);
            };
            fileReader.readAsText(file);
          }}
        >
          {onClick => (
            <>
              {showLoader ? (
                <SpinnerContainer>
                  <Elements.Spinner />
                </SpinnerContainer>
              ) : (
                <Elements.StandardButton
                  onClick={onClick}
                  buttonType="Primary-2"
                  size="Small"
                >
                  Upload system model
                </Elements.StandardButton>
              )}
            </>
          )}
        </FileUpload.FileUploadContainer>
      </div>
    </StyledFieldset>
  );
}

type MissingProperties = ReadonlyArray<string>;

const expectedSystemProperties = [
  "id",
  "revisionNo",
  "systemFile",
  "climateSettings",
  "components",
  "operatingCases"
];

function validateJsonString(jsonString: string): MissingProperties {
  const jsonObj = JSON.parse(jsonString);
  const sysObject = jsonObj["system"];

  const missingProps: Array<string> = [];

  if (!sysObject) {
    missingProps.push("system");
    return missingProps;
  }

  for (const prop of expectedSystemProperties) {
    if (!(prop in sysObject)) {
      missingProps.push(prop);
    }
  }

  return missingProps;
}
