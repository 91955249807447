import * as React from "react";
import * as GenesysPropertiesSelector from "../../genesys-properties-selector";
import * as GenesysPropertiesSelectorRenderers from "../../genesys-properties-selector-renderers";
import * as GenesysPropertiesSelectorTypes from "../../genesys-properties-selector-types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { PropertyValueSet } from "@genesys/property";

export interface Props {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly productDefinitions: Array<GenesysPropertiesSelectorTypes.Property>;
  readonly readOnlyProperties: Array<string>;
  readonly onLocationChange: (pvs: PropertyValueSet.PropertyValueSet) => void;
}

// tslint:disable-next-line:variable-name
export const LocationComponentManual = (props: Props) => {
  const climateDataDialogTranslation = {
    propertyName: (propertyName: string, translate: LanguageTexts.Translate) =>
      translate(
        LanguageTexts.globalPropertyName("climatedatadialog_" + propertyName)
      ),
    propertyValue: (
      propertyName: string,
      propertyValue: number,
      translate: LanguageTexts.Translate
    ) => {
      return translate(
        LanguageTexts.globalPropertyName(
          "climatedatadialog_" + propertyName + "_" + propertyValue
        )
      );
    }
  };

  return (
    <GenesysPropertiesSelector.PropertiesSelectorContainer
      selectedProperties={props.selectedProperties}
      productProperties={props.productDefinitions}
      screenAmountProfileFieldGroup={"ClimateDataLocationDialog"}
      onChange={pvs => {
        props.onLocationChange(pvs);
      }}
      customTranslation={climateDataDialogTranslation}
      uniqueId={"ClimateDataLocationDialog"}
      readOnlyProperties={props.readOnlyProperties}
      layoutRenderer={
        GenesysPropertiesSelectorRenderers.columnsStackedWithGroupsLayoutRenderer
      }
    />
  );
};
