import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "../language-texts";
import { removeWhitespaces } from "./remove-white-spaces";

const dhmodelPropertyName = "dhmodel";

export function getText(
  translate: LanguageTexts.Translate,
  dhuComponentProperties: string | undefined,
  systemTypeId: string
): string {
  if (!dhuComponentProperties) {
    return "";
  }

  const dhuProps = PropertyValueSet.fromString(dhuComponentProperties);
  const dhmodel = PropertyValueSet.getInteger(dhmodelPropertyName, dhuProps);
  const dhText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, dhmodelPropertyName)
  )}=${translate(
    dhmodel !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          dhmodelPropertyName,
          dhmodel
        )
      : LanguageTexts.pNull()
  )}`;

  return removeWhitespaces(dhText);
}
