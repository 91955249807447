import styled from "styled-components";

interface SubMenuItemProps {
  readonly withIcon: boolean;
}

// tslint:disable-next-line
export const SubMenuItem = styled.li<SubMenuItemProps>`
  position: relative;

  padding: ${props =>
    props.withIcon ? "2.5px 25px 2.5px 2.5px" : "2.5px 25px"};
`;
