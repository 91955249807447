// tslint:disable:no-var-requires no-require-imports
export const brick: string = require("../../../../assets/images/workflow/brick.png");
export const cog: string = require("../../../../assets/images/workflow/cog.png");
export const weather: string = require("../../../../assets/images/workflow/weather.png");
export const report: string = require("../../../../assets/images/workflow/report.png");
export const document: string = require("../../../../assets/images/workflow/document.png");
export const lineChart: string = require("../../../../assets/images/workflow/line-chart.png");
export const coins: string = require("../../../../assets/images/workflow/coins.png");
export const barChart: string = require("../../../../assets/images/workflow/bar-chart.png");
export const wrench: string = require("../../../../assets/images/workflow/wrench.png");
export const size: string = require("../../../../assets/images/workflow/size.png");

export const success: string = require("../../../../assets/images/workflow/success.svg");
export const successChanges: string = require("../../../../assets/images/workflow/success-changes.svg");
export const failure: string = require("../../../../assets/images/workflow/failure.svg");
export const failureChanges: string = require("../../../../assets/images/workflow/failure-changes.svg");
export const notRun: string = require("../../../../assets/images/workflow/not-run.svg");
