import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createFileSvg(color: string): string {
  const svgString = `
<svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>B2BFB10B-940D-4C6C-AAE1-C38FF1551F0F</title>
    <defs>
        <path d="M16.805375,22.5 L3.194125,22.5 C2.811625,22.5 2.500375,22.22 2.500375,21.875 L2.500375,3.125 C2.500375,2.78 2.811625,2.5 3.194125,2.5 L8.750375,2.5 L8.750375,7.6875 C8.750375,9.6525 10.271625,11.25 12.142875,11.25 L17.500375,11.25 L17.500375,21.875 C17.500375,22.22 17.187875,22.5 16.805375,22.5 L16.805375,22.5 Z M17.061625,8.75 L12.142875,8.75 C11.650375,8.75 11.250375,8.27375 11.250375,7.6875 L11.250375,2.5 L11.390375,2.5 L17.061625,8.75 Z M19.675375,7.91 L12.870375,0.41 C12.634125,0.14875 12.297875,0 11.944125,0 L3.194125,0 C1.432875,0 0.000375,1.4025 0.000375,3.125 L0.000375,21.875 C0.000375,23.5975 1.432875,25 3.194125,25 L16.805375,25 C18.566625,25 20.000375,23.5975 20.000375,21.875 L20.000375,8.75 C20.000375,8.43875 19.884125,8.14 19.675375,7.91 L19.675375,7.91 Z" id="path-1"></path>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-199.000000, -566.000000)">
            <g id="Group-3" transform="translate(78.000000, 566.000000)">
                <g id="Icons/System-Toolbar/Notes" transform="translate(121.160000, 0.000000)">
                    <g id="Icon-/-Files" transform="translate(5.000000, 2.500000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <use id="🎨-Icon-Сolor" fill="${color}" xlink:href="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
