import gql from "graphql-tag";

export const createNewMoistureLoadRevisionMutation = gql`
  mutation CreateNewMoistureLoadRevision(
    $moistureLoadFileId: ID!
    $name: String!
  ) {
    createNewMoistureLoadRevision(
      moistureLoadFileId: $moistureLoadFileId
      name: $name
    ) {
      id
      revisionNo
      moistureLoadNo
    }
  }
`;
