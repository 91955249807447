import { PropertyFilter, PropertyValueSet } from "@genesys/property";

export enum SectionConnectionType {
  Inlet = 0,
  Outlet = 1
}

type Letter = number;

export interface AirPosition {
  readonly prevProductId: string | undefined;
  readonly prevProductSectionId: string | undefined;
  readonly prevComponentId: string | undefined;
  readonly prevSectionId: string | undefined;
  readonly nextProductId: string | undefined;
  readonly nextProductSectionId: string | undefined;
  readonly nextComponentId: string | undefined;
  readonly nextSectionId: string | undefined;
  readonly label: Letter;
  readonly airstreamId: string;
  readonly sectionConnectionType: SectionConnectionType;
}

export interface ConnectionPoint {
  readonly id: string;
  readonly hideStatePoint: boolean;
  readonly diagramType: string;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly connectionType: string;
  readonly productSectionId: string;
}

export interface Airstream {
  readonly id: string;
  readonly componentSections: ReadonlyArray<ComponentSection>;
}

interface AirstreamConnection {
  readonly primaryAirStreamId: string;
  readonly componentSection: ComponentSection;
  readonly streamConnections: ReadonlyArray<AirStreamComponentSections>;
}

interface AirStreamComponentSections {
  readonly secondaryAirStreamId: string;
  readonly componentSections: ReadonlyArray<ComponentSection>;
}

export interface Component {
  readonly id: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly sections: ReadonlyArray<ComponentSection>;
}

interface ComponentSection {
  readonly id: string;
  readonly productSectionId: string;
  readonly componentId: string;
  readonly productSectionSortNo: number;
  readonly systemAirStreamId?: string | undefined;
  readonly sortNo: number;
}

export function getAirPositions(
  diagramType: "FlowDiagram" | "UnitConfiguration",
  components: ReadonlyArray<Component>,
  airstreams: ReadonlyArray<Airstream>,
  connectionPoints: ReadonlyArray<ConnectionPoint>,
  onlyMainFlow: boolean
): ReadonlyArray<AirPosition> {
  return getAirPositionInternal(
    diagramType,
    components,
    airstreams,
    connectionPoints,
    onlyMainFlow,
    false
  );
}

export function getAirPositionInternal(
  diagramType: "FlowDiagram" | "UnitConfiguration",
  components: ReadonlyArray<Component>,
  airstreams: ReadonlyArray<Airstream>,
  connectionPoints: ReadonlyArray<ConnectionPoint>,
  onlyMainFlow: boolean,
  includeAllConnectionPoints: boolean
): ReadonlyArray<AirPosition> {
  const flowDiagramConnectionPoints = connectionPoints.filter(
    cp =>
      cp.diagramType === diagramType &&
      (includeAllConnectionPoints || !cp.hideStatePoint)
  );

  const primaryAirstreams = airstreams.filter(isPrimaryAirStream);

  let positions: ReadonlyArray<AirPosition> = [];
  let letter = 65;
  for (const stream of primaryAirstreams) {
    const result = getPositionsInStream(
      components,
      airstreams,
      stream,
      primaryAirstreams,
      positions,
      flowDiagramConnectionPoints,
      letter,
      0
    );

    positions = result[0];
    letter = result[1];
  }

  if (!onlyMainFlow) {
    const airstreamsWithMoreThanOneComponentSection = airstreams.filter(
      sas => sas.componentSections.length > 1
    );
    const secondaryStreams = getOrderedSecondaryAirStreams(
      primaryAirstreams,
      airstreamsWithMoreThanOneComponentSection.filter(sas =>
        sas.componentSections.some(
          cs =>
            cs.productSectionId.endsWith("SOC.1") ||
            cs.productSectionId.endsWith("EOC.1")
        )
      )
    );

    const connectedStreams = new Set([
      ...primaryAirstreams,
      ...secondaryStreams
    ]);

    const unconnectedStreams = airstreamsWithMoreThanOneComponentSection.filter(
      as => !connectedStreams.has(as)
    );

    for (const stream of unconnectedStreams) {
      const result = getPositionsInStream(
        components,
        airstreams,
        stream,
        unconnectedStreams,
        positions,
        flowDiagramConnectionPoints,
        letter,
        0
      );

      positions = result[0];
      letter = result[1];
    }

    for (const stream of secondaryStreams) {
      const result = getPositionsInStream(
        components,
        airstreams,
        stream,
        secondaryStreams,
        positions,
        flowDiagramConnectionPoints,
        letter,
        0
      );

      positions = result[0];
      letter = result[1];
    }
  }

  return positions;
}

function isPrimaryAirStream(airstream: Airstream): boolean {
  return airstream.componentSections.some(cs =>
    cs.productSectionId.endsWith("SUC.1")
  );
}

function getPositionsInStream(
  components: ReadonlyArray<Component>,
  allAirstreams: ReadonlyArray<Airstream>,
  airstream: Airstream,
  airstreams: ReadonlyArray<Airstream>,
  airPositions: ReadonlyArray<AirPosition>,
  connectionPoints: ReadonlyArray<ConnectionPoint>,
  inLetter: Letter,
  depth: number
): [ReadonlyArray<AirPosition>, Letter] {
  if (depth > 10) {
    throw new Error("Error in component order calculator");
  }

  if (airPositions.some(p => p.airstreamId === airstream.id)) {
    return [airPositions, inLetter];
  }

  if (airstream.componentSections.length === 1) {
    const section = airstream.componentSections[0];

    if (
      airPositions.every(p => p.prevSectionId !== section.id) &&
      hasOutlet(connectionPoints, section, components)
    ) {
      const newAirPosition: AirPosition = {
        prevProductId: section.productSectionId.split(".")[0],
        prevProductSectionId: section.productSectionId,
        prevComponentId: section.componentId,
        prevSectionId: section.id,
        nextProductId: undefined,
        nextProductSectionId: undefined,
        nextComponentId: undefined,
        nextSectionId: undefined,
        label: inLetter,
        airstreamId: airstream.id,
        sectionConnectionType: SectionConnectionType.Outlet
      };

      if (
        !airPositions.some(
          p =>
            p.prevSectionId === newAirPosition.prevSectionId &&
            p.nextSectionId === newAirPosition.nextSectionId
        )
      ) {
        return [airPositions.concat(newAirPosition), inLetter + 1];
      }
    } else if (
      airPositions.every(p => p.nextSectionId !== section.id) &&
      hasInlet(connectionPoints, section, components)
    ) {
      const newAirPosition: AirPosition = {
        prevProductId: undefined,
        prevProductSectionId: undefined,
        prevComponentId: undefined,
        prevSectionId: undefined,
        nextProductId: section.productSectionId.split(".")[0],
        nextProductSectionId: section.productSectionId,
        nextComponentId: section.componentId,
        nextSectionId: section.id,
        label: inLetter,
        airstreamId: airstream.id,
        sectionConnectionType: SectionConnectionType.Inlet
      };

      if (
        !airPositions.some(
          p =>
            p.prevSectionId === newAirPosition.prevSectionId &&
            p.nextSectionId === newAirPosition.nextSectionId
        )
      ) {
        return [airPositions.concat(newAirPosition), inLetter + 1];
      }
    }
    return [airPositions, inLetter];
  }

  let previous: ComponentSection | undefined = undefined;
  let positions = airPositions.concat();
  let outLetter = inLetter;

  for (const sectionInAirStream of airstream.componentSections) {
    if (positions.some(p => p.prevSectionId === sectionInAirStream.id)) {
      continue;
    }

    if (
      previous === undefined &&
      hasInlet(connectionPoints, sectionInAirStream, components)
    ) {
      const firstPos: AirPosition = {
        prevProductId: undefined,
        prevProductSectionId: undefined,
        prevComponentId: undefined,
        prevSectionId: undefined,
        nextProductId: sectionInAirStream.productSectionId.split(".")[0],
        nextProductSectionId: sectionInAirStream.productSectionId,
        nextComponentId: sectionInAirStream.componentId,
        nextSectionId: sectionInAirStream.id,
        label: outLetter++,
        airstreamId: airstream.id,
        sectionConnectionType: SectionConnectionType.Inlet
      };

      positions.push(firstPos);
    }

    if (
      previous !== undefined &&
      hasOutlet(connectionPoints, previous, components)
    ) {
      const newPos: AirPosition = {
        prevProductId: previous.productSectionId.split(".")[0],
        prevProductSectionId: previous.productSectionId,
        prevComponentId: previous.componentId,
        prevSectionId: previous.id,
        nextProductId: sectionInAirStream.productSectionId.split(".")[0],
        nextProductSectionId: sectionInAirStream.productSectionId,
        nextComponentId: sectionInAirStream.componentId,
        nextSectionId: sectionInAirStream.id,
        label: outLetter++,
        airstreamId: airstream.id,
        sectionConnectionType: SectionConnectionType.Outlet
      };

      if (
        !positions.some(
          p =>
            p.prevSectionId === newPos.prevSectionId &&
            p.nextSectionId === newPos.nextSectionId
        )
      ) {
        positions.push(newPos);
      }
    }

    if (
      sectionInAirStream ===
        airstream.componentSections[airstream.componentSections.length - 1] &&
      hasOutlet(connectionPoints, sectionInAirStream, components)
    ) {
      const lastPos: AirPosition = {
        prevProductId: sectionInAirStream.productSectionId.split(".")[0],
        prevProductSectionId: sectionInAirStream.productSectionId,
        prevComponentId: sectionInAirStream.componentId,
        prevSectionId: sectionInAirStream.id,
        nextProductId: undefined,
        nextProductSectionId: undefined,
        nextComponentId: undefined,
        nextSectionId: undefined,
        label: outLetter++,
        airstreamId: airstream.id,
        sectionConnectionType: SectionConnectionType.Outlet
      };

      positions.push(lastPos);
    }

    const sectionsInComponent = components.find(
      c => c.id === sectionInAirStream.componentId
    )!.sections;

    const sortedComponentSections = airstreams
      .reduce(
        (soFar: ReadonlyArray<ComponentSection>, s) =>
          soFar.concat(s.componentSections),
        []
      )
      .filter(
        cs =>
          cs.componentId === sectionInAirStream.componentId &&
          cs !== sectionInAirStream &&
          cs.productSectionSortNo < sectionInAirStream.productSectionSortNo
      )
      .sort((a, b) => a.productSectionSortNo - b.productSectionSortNo);

    const previousInternalComponentSectionInOtherAirStream:
      | ComponentSection
      | undefined = sortedComponentSections[sortedComponentSections.length - 1];

    for (const internalSection of sectionsInComponent
      .filter(
        cs =>
          (previousInternalComponentSectionInOtherAirStream === undefined ||
            previousInternalComponentSectionInOtherAirStream.productSectionSortNo <
              cs.productSectionSortNo) &&
          cs.productSectionSortNo < sectionInAirStream.productSectionSortNo
      )
      .sort((a, b) => a.productSectionSortNo - b.productSectionSortNo)) {
      const recursiveResult = getPositionsInStream(
        components,
        allAirstreams,
        allAirstreams.find(a => a.id === internalSection.systemAirStreamId)!,
        allAirstreams.filter(a => a.id === internalSection.systemAirStreamId),
        positions,
        connectionPoints,
        outLetter,
        depth + 1
      );

      positions = recursiveResult[0].concat();
      outLetter = recursiveResult[1];
    }

    previous = sectionInAirStream;
  }

  return [positions, outLetter];
}
function hasOutlet(
  connectionPoints: ReadonlyArray<ConnectionPoint>,
  section: ComponentSection,
  components: ReadonlyArray<Component>
): boolean {
  const component = components.find(c => c.id === section.componentId)!;

  return connectionPoints.some(
    p =>
      p.connectionType === "Outlet" &&
      p.productSectionId === section.productSectionId &&
      PropertyFilter.isValid(component.properties, p.propertyFilter)
  );
}

function hasInlet(
  connectionPoints: ReadonlyArray<ConnectionPoint>,
  section: ComponentSection,
  components: ReadonlyArray<Component>
): boolean {
  const component = components.find(c => c.id === section.componentId)!;

  return connectionPoints.some(
    p =>
      p.connectionType === "Inlet" &&
      p.productSectionId === section.productSectionId &&
      PropertyFilter.isValid(component.properties, p.propertyFilter)
  );
}

function getOrderedSecondaryAirStreams(
  primaryStreams: ReadonlyArray<Airstream>,
  secondaryStreams: ReadonlyArray<Airstream>
): ReadonlyArray<Airstream> {
  if (secondaryStreams.length <= 1) {
    return secondaryStreams;
  }

  const streamConnections = getPrimaryToSecondaryStreamConnections(
    primaryStreams,
    secondaryStreams
  );
  if (streamConnections.length === 0) {
    return secondaryStreams;
  }

  const airStreamConnections = streamConnections
    .concat()
    .sort((a, b) => b.streamConnections.length - a.streamConnections.length)
    .sort((a, b) => b.componentSection.sortNo - a.componentSection.sortNo);

  const orderedSecondaryAirStreams: Array<Airstream> = [];
  for (const airStreamConnection of airStreamConnections) {
    for (const streamConnection of airStreamConnection.streamConnections
      .concat()
      .sort(
        (sca, scb) =>
          Math.min(
            ...sca.componentSections.map(cs => cs.productSectionSortNo)
          ) -
          Math.min(...scb.componentSections.map(cs => cs.productSectionSortNo))
      )
      // tslint:disable-next-line:no-shadowed-variable
      .filter(
        streamConnection =>
          !orderedSecondaryAirStreams.some(
            sas => sas.id === streamConnection.secondaryAirStreamId
          )
      )) {
      orderedSecondaryAirStreams.push(
        secondaryStreams.find(
          sas => sas.id === streamConnection.secondaryAirStreamId
        )!
      );
    }
  }

  const unconnectedSecondaryStreams = secondaryStreams.filter(
    sas => !orderedSecondaryAirStreams.some(sas2 => sas2.id === sas.id)
  );

  orderedSecondaryAirStreams.push(...unconnectedSecondaryStreams);

  return orderedSecondaryAirStreams;
}

function getPrimaryToSecondaryStreamConnections(
  primaryStreams: ReadonlyArray<Airstream>,
  secondaryStreams: ReadonlyArray<Airstream>
): ReadonlyArray<AirstreamConnection> {
  const list: Array<AirstreamConnection> = [];
  for (const primaryStream of primaryStreams) {
    for (const primaryStreamComponentSection of primaryStream.componentSections) {
      const connections: Array<AirStreamComponentSections> = [];
      for (const secondaryStream of secondaryStreams) {
        const connectedSections = secondaryStream.componentSections.filter(
          cs => cs.componentId === primaryStreamComponentSection.componentId
        );

        if (connectedSections.length > 0) {
          connections.push({
            secondaryAirStreamId: secondaryStream.id,
            componentSections: connectedSections
          });
        }
      }

      // Add existing connections between this primary stream component section and the secondary air streams if exists.
      if (connections.length > 0) {
        list.push({
          primaryAirStreamId: primaryStream.id,
          componentSection: primaryStreamComponentSection,
          streamConnections: connections
        });
      }
    }
  }
  return list;
}
//tslint:disable-next-line
