import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import { StyledTable } from "@genesys/ui-elements";
import { PropertyValueSet } from "@genesys/property";

interface Props {
  readonly results: PropertyValueSet.PropertyValueSet;
  readonly soundRows: ReadonlyArray<ScreenRow>;
  readonly sharedState: SharedState.State;
}

interface ScreenRow {
  readonly id: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}
// tslint:disable-next-line:function-name
export function SoundView({
  results,
  soundRows,
  sharedState
}: Props): JSX.Element | null {
  const soundResults = getSoundResults(results, soundRows);

  if (soundResults.length === 0) {
    return null;
  }

  return (
    <>
      {soundResults.map((r, i) => (
        <SoundTable
          key={i}
          headerTextId={r.headerTextId}
          soundResults={r.values}
          translate={sharedState.translate}
        />
      ))}
    </>
  );
}

interface SoundTableProps {
  readonly headerTextId: LanguageTexts.TextDefinition;
  readonly soundResults: ReadonlyArray<number>;
  readonly translate: LanguageTexts.Translate;
}

function SoundTable({
  headerTextId,
  soundResults,
  translate
}: SoundTableProps): JSX.Element {
  return (
    <StyledTable alignRows="left" alignHeader="left">
      <thead>
        <tr>
          <th colSpan={9}>{translate(headerTextId)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td />
          <td>63</td>
          <td>125</td>
          <td>250</td>
          <td>500</td>
          <td>1k</td>
          <td>2k</td>
          <td>4k</td>
          <td>8k</td>
          <td>dBA</td>
        </tr>
        <tr>
          <td>dB</td>
          {soundResults.map((s, i) => (
            <td key={i}>{s.toFixed(1)}</td>
          ))}
          <td>
            {lwa(
              soundResults[0],
              soundResults[1],
              soundResults[2],
              soundResults[3],
              soundResults[4],
              soundResults[5],
              soundResults[6],
              soundResults[7]
            ).toFixed(1)}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
}

interface SoundResult {
  readonly headerTextId: LanguageTexts.TextDefinition;
  readonly values: ReadonlyArray<number>;
}
function getSoundResults(
  results: PropertyValueSet.PropertyValueSet,
  soundRows: ReadonlyArray<ScreenRow>
): ReadonlyArray<SoundResult> {
  const soundResults: Array<SoundResult> = [];
  for (const sr of soundRows) {
    const resultKey = sr.name || "";
    const propName = resultKey.substr(resultKey.indexOf("_") + 1);

    const result = PropertyValueSet.getText(resultKey, results);

    if (!result) {
      continue;
    }
    const parsedResults = result.split(";").map(v => parseFloat(v));

    // Dont add if no sound
    if (parsedResults.reduce((a, b) => a + b, 0) <= 0) {
      continue;
    }

    soundResults.push({
      headerTextId: LanguageTexts.perfParam(propName),
      values: parsedResults
    });
  }
  return soundResults;
}

const attenuationCorrections = [-26.0, -16.0, -9.0, -3.0, 0.0, 1.0, 1.0, -1.0];

function lwa(
  _db63: number,
  _db125: number,
  _db250: number,
  _db500: number,
  _db1K: number,
  _db2K: number,
  _db4K: number,
  _db8K: number
): number {
  return (
    10.0 *
    (Math.log(
      Math.pow(10, (_db63 + attenuationCorrections[0]) / 10.0) +
        Math.pow(10, (_db125 + attenuationCorrections[1]) / 10.0) +
        Math.pow(10, (_db250 + attenuationCorrections[2]) / 10.0) +
        Math.pow(10, (_db500 + attenuationCorrections[3]) / 10.0) +
        Math.pow(10, (_db1K + attenuationCorrections[4]) / 10.0) +
        Math.pow(10, (_db2K + attenuationCorrections[5]) / 10.0) +
        Math.pow(10, (_db4K + attenuationCorrections[6]) / 10.0) +
        Math.pow(10, (_db8K + attenuationCorrections[7]) / 10.0)
    ) /
      Math.log(10.0))
  );
}
