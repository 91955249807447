import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchControlsButtonContainer = styled.button`
  margin-left: 2px;
  height: 30px;
  border: 1px solid ${Colors.searchFilterBorderColor};
  border-radius: 3px;
  background-color: ${Colors.searchBackground};
  width: 70px;
  vertical-align: bottom;
  margin-left: 20px;

  img {
    position: absolute;
    top: 25%;
    left: 38%;
  }

  position: relative;
  &:hover {
    border-color: ${Colors.searchFilterBorderHoverColor};
    border-width: 2px;
  }
`;
