import pathToRegexp, { Key } from "path-to-regexp";

export type UrlParser<TLocation> = (
  url: string
) => UrlMatch<TLocation> | undefined;

export interface UrlMatch<TLocation> {
  readonly location: TLocation;
  readonly matched: string;
  readonly rest: string;
}

export interface PathParams {
  readonly [key: string]: string;
}

export type LocationFromParams<TLocation> = (
  pathParams: PathParams
) => TLocation;

// export type Location<TParams extends {}> = {
//   readonly name: string;
//   readonly params?: TParams | undefined;
// };

export function createUrlParser<TLocation>(
  path: string,
  createLocation: LocationFromParams<TLocation>
): UrlParser<TLocation> {
  const keys: Key[] = [];
  const regExp = pathToRegexp(path, keys, { end: false });
  return (url: string) => {
    return matchPathAndCreateLocation<TLocation>(
      regExp,
      keys,
      createLocation,
      url
    );
  };
}

export function combineUrlParsers<TLocation>(
  parsers: readonly UrlParser<TLocation>[]
): UrlParser<TLocation> {
  return (url: string) => {
    for (const up of parsers) {
      const parseResult = up(url);
      if (parseResult) {
        // console.log("Found matching url parser", parseResult);
        return parseResult;
      }
    }
    return undefined;
  };
}

function matchPathAndCreateLocation<TLocation>(
  regExp: RegExp,
  keys: readonly Key[],
  createLocation: LocationFromParams<TLocation>,
  url: string
): UrlMatch<TLocation> | undefined {
  const pathMatch = matchPath(regExp, keys, url);
  if (pathMatch) {
    const location = createLocation(pathMatch.params);
    return { location, matched: pathMatch.matched, rest: pathMatch.rest };
  }
  return undefined;
}

interface PathMatch {
  readonly params: PathParams;
  readonly matched: string;
  readonly rest: string;
}

function matchPath(
  regExp: RegExp,
  keys: readonly Key[],
  url: string
): PathMatch | undefined {
  const result = regExp.exec(url);
  let rest: string = "";
  if (result !== null) {
    rest = url.substr(result[0].length);
    // tslint:disable-next-line: readonly-keyword
    const params: { [key: string]: string } = {};
    for (let x = 0; x < keys.length; x++) {
      params[keys[x].name] = result[x + 1];
    }
    return { params, matched: result[0], rest };
  }
  return undefined;
}
