import { PropertyValue } from "@promaster-sdk/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { compare as compareAmounts } from "@genesys/uom";

export const compare: PropertyValue.Comparer = (
  a: PropertyValue.PropertyValue,
  b: PropertyValue.PropertyValue
) => {
  switch (a.type) {
    case "integer":
    case "text":
      return PropertyValue.defaultComparer(a, b);
    case "amount":
      if (a.type !== b.type) {
        throw new Error("Must compare same type");
      }
      return compareAmounts(a.value, b.value);
    default:
      exhaustiveCheck(a);
      throw new Error("Unkown property type");
  }
};
