import moment from "moment";

export function getDateString(date: moment.Moment, culture: string): string {
  const d = date.toDate();
  return d.toLocaleDateString(culture);
}

export function getTimeString(date: moment.Moment, culture: string): string {
  const d = date.toDate();
  return d.toLocaleTimeString(culture);
}
