import { PropertyValueSet } from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Amount, Quantity, Unit, Format, UnitsFormat } from "@genesys/uom";
import * as ScreenAmounts from "../screen-amounts";
import { relativeHumidityConversions } from "./conversions/relative-humidity-conversions";
import { humidityRatioConversions } from "./conversions/humidity-ratio-conversions";
import { specificEnthalpyConversions } from "./conversions/specific-enthalpy-conversions";
import { dewPointTemperatureConversions } from "./conversions/dew-point-temperature-conversions";
import { wetTemperatureConversions } from "./conversions/wet-temperature-conversions";
import { ConversionType, ConversionParameters } from "./types";
import {
  massFlowConversions,
  volumeFlowConversions
} from "./conversions/flow-conversions";
import { quantityFromAndToRegistry } from "./quantity-conversion-registry";

export function createConversionParameters(
  quantityConversionParams: ReadonlyArray<string>,
  selectedProperties: PropertyValueSet.PropertyValueSet
): ConversionParameters | undefined {
  if (quantityConversionParams.length === 0) {
    return undefined;
  }

  const params: ConversionParameters = {
    density: getAmountOfTypeOrUndefined(
      selectedProperties,
      quantityConversionParams,
      "Density"
    ),
    pressure: getAmountOfTypeOrUndefined(
      selectedProperties,
      quantityConversionParams,
      "Pressure"
    ),
    temperature: getAmountOfTypeOrUndefined(
      selectedProperties,
      quantityConversionParams,
      "Temperature"
    ),
    humidityRatio: getAmountOfTypeOrUndefined(
      selectedProperties,
      quantityConversionParams,
      "HumidityRatio"
    )
  };
  return params.density !== undefined ||
    params.pressure !== undefined ||
    params.temperature !== undefined
    ? params
    : undefined;
}

export function createOnQuantityChange(
  onPropertyFormatChanged: (
    propertyName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ) => void,
  measureSystem: number,
  quantityDefaults: ScreenAmounts.QuantityDefaultsMap,
  propertyName: string
): (quantity: Quantity.Quantity) => void {
  return (quantity: Quantity.Quantity) => {
    // Take the default unit. The user have to change to the desired unit in the new quantity.
    const quantityDefault = getQuantityDefault(
      quantityDefaults,
      measureSystem,
      quantity
    );
    onPropertyFormatChanged(
      propertyName,
      quantityDefault.unit,
      quantityDefault.decimalCount
    );
  };
}

export function convertQuantity<TQuantityTo extends Quantity.Quantity>(
  fromAmount: Amount.Amount<Quantity.Quantity>,
  to: TQuantityTo,
  conversionParameters: ConversionParameters
): Amount.Amount<TQuantityTo> {
  const requiredConversionParameters = getConversionParameters(
    fromAmount.unit.quantity,
    to,
    conversionParameters
  );
  switch (fromAmount.unit.quantity) {
    case "HumidityRatio": {
      return humidityRatioConversions(
        fromAmount as Amount.Amount<Quantity.HumidityRatio>,
        to,
        requiredConversionParameters
      );
    }
    case "RelativeHumidity": {
      return relativeHumidityConversions(
        fromAmount as Amount.Amount<Quantity.RelativeHumidity>,
        to,
        requiredConversionParameters
      );
    }
    case "SpecificEnthalpy": {
      return specificEnthalpyConversions(
        fromAmount as Amount.Amount<Quantity.SpecificEnthalpy>,
        to,
        requiredConversionParameters
      );
    }
    case "DewPointTemperature": {
      return dewPointTemperatureConversions(
        fromAmount as Amount.Amount<Quantity.DewPointTemperature>,
        to,
        requiredConversionParameters
      );
    }
    case "WetTemperature": {
      return wetTemperatureConversions(
        fromAmount as Amount.Amount<Quantity.WetTemperature>,
        to,
        requiredConversionParameters
      );
    }
    case "MassFlow": {
      return massFlowConversions(
        fromAmount as Amount.Amount<Quantity.MassFlow>,
        to,
        requiredConversionParameters
      );
    }
    case "VolumeFlow": {
      return volumeFlowConversions(
        fromAmount as Amount.Amount<Quantity.VolumeFlow>,
        to,
        requiredConversionParameters
      );
    }
    default: {
      throw new Error(`Can't convert from quantity ${fromAmount}`);
    }
  }
}

export function getConversionParameters(
  fromQuantity: Quantity.Quantity,
  toQuantity: Quantity.Quantity,
  conversionParameters: ConversionParameters
): ConversionParameters {
  const conversionType: ConversionType = getConversionTypeFromQuantity(
    fromQuantity,
    true
  )!;

  const densityAmount = conversionParameters.density;
  const pressureAmount = conversionParameters.pressure;
  const temperatureAmount = conversionParameters.temperature;
  const humidityRatioAmount = conversionParameters.humidityRatio;

  switch (conversionType) {
    case "Flow": {
      if (
        densityAmount === undefined &&
        (humidityRatioAmount === undefined ||
          pressureAmount === undefined ||
          temperatureAmount === undefined)
      ) {
        throw new Error("Missing required amounts for flow conversion");
      }

      return {
        density: densityAmount,
        humidityRatio: humidityRatioAmount,
        pressure: pressureAmount,
        temperature: temperatureAmount
      };
    }
    case "Humidity": {
      if (pressureAmount === undefined) {
        throw new Error("Missing pressure amount");
      }

      if (
        (fromQuantity === "HumidityRatio" &&
          toQuantity === "DewPointTemperature") ||
        (fromQuantity === "DewPointTemperature" &&
          toQuantity === "HumidityRatio")
      ) {
        return { pressure: pressureAmount };
      }

      if (temperatureAmount === undefined) {
        throw new Error("Missing temperature amount");
      }

      return { pressure: pressureAmount, temperature: temperatureAmount };
    }
    default: {
      exhaustiveCheck(conversionType);
      return {};
    }
  }
}

export function getQuantitiesToConvertTo(
  quantity: Quantity.Quantity
): Array<Quantity.Quantity> {
  const conversionType = getConversionTypeFromQuantity(quantity, true)!;
  const quantities = quantityFromAndToRegistry.get(conversionType);

  if (!quantities) {
    throw new Error(`Can't convert from ${quantity}`);
  }

  return quantities;
}

export function isConvertableQuantity(quantity: Quantity.Quantity): boolean {
  return !!getConversionTypeFromQuantity(quantity, false);
}

function getConversionTypeFromQuantity(
  quantity: Quantity.Quantity,
  throwOnMissing: boolean
): ConversionType | undefined {
  switch (quantity) {
    case "MassFlow":
    case "VolumeFlow": {
      return "Flow";
    }
    case "HumidityRatio":
    case "RelativeHumidity":
    case "WetTemperature":
    case "SpecificEnthalpy":
    case "DewPointTemperature": {
      return "Humidity";
    }
    default: {
      if (throwOnMissing) {
        throw new Error(`No ConversionType found for quantity ${quantity}`);
      } else {
        return undefined;
      }
    }
  }
}

export function getQuantityDefault(
  quantityDefaults: ScreenAmounts.QuantityDefaultsMap,
  measureSystem: number,
  quantity: Quantity.Quantity
): {
  readonly unit: Unit.Unit<Quantity.Quantity>;
  readonly decimalCount: number;
} {
  try {
    const quantityDefault = quantityDefaults[quantity][measureSystem];
    const unit = quantityDefault.unit;
    const decimalCount = quantityDefault.decimalCount;

    return {
      unit,
      decimalCount
    };
  } catch (e) {
    const msg = `Couldn't get unit from quantity: ${quantity}. Falling back to promaster default`;
    console.warn(msg);

    const quantityUnits = Format.getUnitsForQuantity(quantity);
    if (quantityUnits.length === 0) {
      throw new Error("Couldn't find any units for quantity: " + quantityUnits);
    }
    const unit = quantityUnits[0];
    const unitFormat = Format.getUnitFormat(unit, UnitsFormat);
    const decimalCount = unitFormat !== undefined ? unitFormat.decimalCount : 2;
    return {
      unit,
      decimalCount
    };
  }
}

function getAmountOfTypeOrUndefined<TQuantity extends Quantity.Quantity>(
  pvs: PropertyValueSet.PropertyValueSet,
  conversionParameters: ReadonlyArray<string>,
  quantity: TQuantity
): Amount.Amount<TQuantity> | undefined {
  const conversionAmountProperties = conversionParameters.map(cp =>
    PropertyValueSet.getAmount(cp, pvs)
  );

  const amount = conversionAmountProperties.find(
    a => !!(a && a.unit.quantity === quantity)
  );
  if (!amount || amount.unit.quantity !== quantity) {
    return undefined;
  }

  return amount as Amount.Amount<TQuantity>;
}
