import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import * as Authorization from "@genesys/shared/lib/authorization";
import {
  P2,
  P1,
  OverlayLoader,
  StandardButton,
  CheckBox,
  Spinner,
  StatusError
} from "@genesys/ui-elements";
import { AmountPropertySelector } from "../amount-property-selector";
import { opcProductData } from "./data";
import * as PropertiesSelector from "../properties-selector";
import {
  Root,
  CheckBoxContainer,
  MainGridContainer,
  GridRowCell,
  GroupCell,
  GridRowsCell,
  SubGridContainer,
  ShowInvalidSystemsCheckboxContainer,
  TopContainer,
  AmountPropertyContainer
} from "./elements";
import * as Types from "./types";
import { calculateSystems, calculateDehumCapacities } from "./functions";
import {
  CreateButton,
  PreValidation,
  WarningsAndErrors,
  CreatedSystemDialog
} from "./components";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount } from "uom";
import { DhCapacityInfo } from "./components";

export function DrySizeView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const isDeveloper = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.developer
  );

  if (
    !(
      isDeveloper ||
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseDrySize
      )
    )
  ) {
    return <div>unauthorized</div>;
  }

  if (
    state.operatingCaseSelectorState === undefined ||
    state.systemVariantsGroups.length === 0
  ) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const showVariant = (sv: Types.SystemVariant) => {
    const calculatedCapacityIsLessThanThreeTimesLoad =
      sv.calculatedDehumCapacity.type === "calculated"
        ? Amount.lessThan(
            sv.calculatedDehumCapacity.capacity,
            Amount.times(sv.totalMoistureLoad, 3)
          )
        : true;

    return (
      sv.newProperties !== undefined &&
      sv.invalidThreshold === undefined &&
      sv.quantity <= 5 &&
      sv.calculatedDehumCapacity.type !== "failed" &&
      calculatedCapacityIsLessThanThreeTimesLoad
    );
  };

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const selectedSystemVariantsGroup = state.systemVariantsGroups.reduce(
    (soFar, current) => {
      return current.reactHeaterType === state.selectedReactHeaterView
        ? {
            ...soFar,
            systemVariants: soFar.systemVariants.concat(current.systemVariants)
          }
        : soFar;
    },
    {
      reactHeaterType: state.selectedReactHeaterView,
      systemVariants: []
    } as Types.SystemVariantsGroup
  );

  React.useEffect(() => {
    const systemVariantsToCalculate =
      selectedSystemVariantsGroup.systemVariants.filter(
        sv => sv.calculatedDehumCapacity.type === "undefined" && showVariant(sv)
      );

    const systemInputs = systemVariantsToCalculate.map(s => ({
      systemName: s.systemType + ": " + s.variant,
      systemTypeId: s.systemType,
      newProperties: s.newProperties!,
      operatingCases: s.operatingCases,
      climateData: state.climateSettings!,
      systemIdentifier: s.identifier
    }));

    calculateDehumCapacities(
      dispatch,
      sharedState,
      systemInputs,
      state.operatingCaseSelectorState!.properties
    );
  }, [state.selectedReactHeaterView]);

  return (
    <Root>
      {state.isLoading && <OverlayLoader />}

      {state.createdSystemUrl && (
        <CreatedSystemDialog
          url={state.createdSystemUrl!}
          dispatch={dispatch}
        />
      )}

      <TopContainer>
        <div>
          <CheckBox
            isChecked={state.selectedReactHeaterView === "electric"}
            onClick={() => dispatch(Action.setReactHeaterView("electric"))}
            children={"Electric Heater"}
          />
          <CheckBox
            isChecked={state.selectedReactHeaterView === "gas"}
            onClick={() => dispatch(Action.setReactHeaterView("gas"))}
            children={"Gas Heater"}
          />
          <CheckBox
            isChecked={state.selectedReactHeaterView === "steam"}
            onClick={() => dispatch(Action.setReactHeaterView("steam"))}
            children={"Steam Heater"}
          />
        </div>

        <PropertiesSelector.PropertiesSelectorView
          dispatch={Dispatch.map(
            action => Action.dispatchOperatingCaseSelector(action),
            dispatch
          )}
          productId={opcProductData.id}
          sharedState={sharedState}
          propertiesInfo={opcProductData.properties}
          fieldGroup={`Settings.${opcProductData.id}`}
          isReadonly={false}
          showCodes={showCodes}
          state={state.operatingCaseSelectorState}
          hideGroupNames={true}
        />
        <StandardButton
          size="Large"
          buttonType="Primary-2"
          onClick={() => {
            const systemVariantToCalculate = state.systemVariantsGroups
              .find(
                svg => svg.reactHeaterType === state.selectedReactHeaterView
              )!
              .systemVariants.filter(
                sv => sv.shouldCalculate && sv.calculationResults === undefined
              );

            const systemInputs = systemVariantToCalculate.map(s => ({
              systemName: s.systemType + ": " + s.variant,
              systemTypeId: s.systemType,
              newProperties: s.newProperties!,
              operatingCases: s.operatingCases,
              climateData: state.climateSettings!,
              systemIdentifier: s.identifier
            }));

            calculateSystems(
              dispatch,
              sharedState,
              systemInputs,
              state.operatingCaseSelectorState!.properties
            );
          }}
          disabled={
            state.isCalculatingSystems ||
            state.systemVariantsGroups.find(svg =>
              svg.systemVariants.find(sv => sv.shouldCalculate)
            ) === undefined
          }
        >
          {state.isCalculatingSystems ? "Calculating" : "Calculate Systems"}
        </StandardButton>
      </TopContainer>

      <MainGridContainer isDeveloper={isDeveloper}>
        <GridRowCell x={1} y={1}>
          <P1 color="dark" weight="bold">
            Calculate
          </P1>
        </GridRowCell>
        <GridRowCell x={2} y={1}>
          <P1 color="dark" weight="bold">
            #
          </P1>
        </GridRowCell>
        <GridRowCell x={3} y={1}>
          <P1 color="dark" weight="bold">
            Variant
          </P1>
        </GridRowCell>
        <GridRowCell x={4} y={1}>
          <P1 color="dark" weight="bold">
            Quantity
          </P1>
        </GridRowCell>
        <GridRowCell x={5} y={1}>
          <DhCapacityInfo sharedState={sharedState} />
        </GridRowCell>
        <GridRowCell x={6} y={1}>
          <P1 color="dark" weight="bold">
            Load
          </P1>
        </GridRowCell>
        <GridRowCell x={7} y={1}>
          <P1 color="dark" weight="bold">
            Errors/Warnings
          </P1>
        </GridRowCell>
        <GridRowCell x={8} y={1}></GridRowCell>
        {isDeveloper && (
          <>
            <GridRowCell x={9} y={1}>
              <P1 color="dark" weight="bold">
                Product
              </P1>
            </GridRowCell>
            <GridRowCell x={10} y={1}>
              <P1 color="dark" weight="bold">
                Pre-Validation
              </P1>
              <ShowInvalidSystemsCheckboxContainer>
                <CheckBox
                  isChecked={state.showInvalidSystems}
                  onClick={() => dispatch(Action.toogleShowInvalidSystems())}
                  children={"Show invalid systems"}
                />
              </ShowInvalidSystemsCheckboxContainer>
            </GridRowCell>
          </>
        )}

        <GroupCell y={selectedSystemVariantsGroup.systemVariants.length + 1}>
          <MainGridContainer isDeveloper={isDeveloper}>
            <GridRowsCell>
              {selectedSystemVariantsGroup.systemVariants
                .filter(sv => state.showInvalidSystems || showVariant(sv))
                .map((sv, ix) => (
                  <SubGridContainer
                    index={ix}
                    isDeveloper={isDeveloper}
                    key={ix + "asd"}
                  >
                    <GridRowCell x={1} y={1}>
                      <CheckBoxContainer>
                        <CheckBox
                          children={""}
                          isChecked={
                            sv.shouldCalculate ||
                            sv.calculationResults !== undefined
                          }
                          onClick={() => {
                            if (!state.isCalculatingSystems) {
                              dispatch(
                                Action.toogleCalculateSystem(sv.identifier)
                              );
                            }
                          }}
                          disabled={
                            sv.newProperties === undefined ||
                            sv.calculationResults !== undefined
                          }
                        />
                      </CheckBoxContainer>
                    </GridRowCell>
                    <GridRowCell x={2} y={1}>
                      <P2 color="secondary" weight="normal">
                        {ix + 1}
                      </P2>
                    </GridRowCell>
                    <GridRowCell x={3} y={1}>
                      <P2 color="secondary" weight="normal">
                        {sv.variant}
                      </P2>
                    </GridRowCell>
                    <GridRowCell x={4} y={1}>
                      {sv.quantity !== 0 && (
                        <P2 color="secondary" weight="normal">
                          {sv.quantity}
                        </P2>
                      )}
                    </GridRowCell>
                    <GridRowCell x={5} y={1}>
                      {sv.calculatedDehumCapacity.type === "undefined" &&
                        !state.showInvalidSystems && <Spinner height={30} />}
                      {sv.calculatedDehumCapacity.type === "failed" && (
                        <StatusError />
                      )}
                      {sv.calculatedDehumCapacity.type === "calculated" && (
                        <AmountPropertyContainer>
                          <AmountPropertySelector
                            type="with-simplified-props"
                            translate={sharedState.translate}
                            fieldGroup="DrySize"
                            fieldName="load"
                            readOnly={true}
                            propertyName="load"
                            propertyValueSet={PropertyValueSet.fromProperty(
                              "load",
                              PropertyValue.fromAmount(
                                Amount.times(
                                  sv.calculatedDehumCapacity.capacity,
                                  sv.quantity
                                )
                              )
                            )}
                            onFormatChanged={(unit, decimalCount) =>
                              dispatch(
                                Action.onFormatChanged(
                                  "DrySize",
                                  "load",
                                  unit,
                                  decimalCount
                                )
                              )
                            }
                            onFormatCleared={() =>
                              dispatch(
                                Action.onFormatCleared("DrySize", "load")
                              )
                            }
                            onValueChange={_v => console.log()}
                            getAmountFormat={
                              sharedState.screenAmounts.getAmountFormat
                            }
                            quantity="MassFlow"
                          />
                        </AmountPropertyContainer>
                      )}
                    </GridRowCell>
                    <GridRowCell x={6} y={1}>
                      {(state.moistureLoadInfo?.systemConfiguration ===
                        "open-system" ||
                        sv.shouldCalculate ||
                        sv.calculationResults !== undefined) && (
                        <AmountPropertyContainer>
                          <AmountPropertySelector
                            type="with-simplified-props"
                            translate={sharedState.translate}
                            fieldGroup="DrySize"
                            fieldName="load"
                            readOnly={true}
                            propertyName="load"
                            propertyValueSet={PropertyValueSet.fromProperty(
                              "load",
                              PropertyValue.fromAmount(sv.totalMoistureLoad)
                            )}
                            onFormatChanged={(unit, decimalCount) =>
                              dispatch(
                                Action.onFormatChanged(
                                  "DrySize",
                                  "load",
                                  unit,
                                  decimalCount
                                )
                              )
                            }
                            onFormatCleared={() =>
                              dispatch(
                                Action.onFormatCleared("DrySize", "load")
                              )
                            }
                            onValueChange={_v => console.log()}
                            getAmountFormat={
                              sharedState.screenAmounts.getAmountFormat
                            }
                            quantity="MassFlow"
                          />
                        </AmountPropertyContainer>
                      )}
                    </GridRowCell>
                    <GridRowCell x={7} y={1}>
                      {sv.calculationResults && (
                        <WarningsAndErrors
                          rootMessage={sv.calculationResults}
                          amountFormat={
                            sharedState.screenAmounts.getAmountFormat
                          }
                        />
                      )}
                    </GridRowCell>
                    <GridRowCell x={8} y={1}>
                      <CreateButton
                        systemVariant={sv}
                        state={state}
                        sharedState={sharedState}
                        dispatch={dispatch}
                      />
                    </GridRowCell>
                    {isDeveloper && (
                      <>
                        <GridRowCell x={9} y={1}>
                          <P2 color="secondary" weight="normal">
                            {sv.systemType}
                          </P2>
                        </GridRowCell>
                        <GridRowCell x={10} y={1}>
                          <PreValidation systemVariant={sv} />
                        </GridRowCell>
                      </>
                    )}
                  </SubGridContainer>
                ))}
            </GridRowsCell>
          </MainGridContainer>
        </GroupCell>
      </MainGridContainer>
    </Root>
  );
}
