import * as React from "react";
import * as Elements from "../elements";
// tslint:disable-next-line:no-require-imports
import { OperationTime } from "./types";
import * as Actions from "./actions";
import { TimeGridComponent } from "./time-grid-component";
import * as Presets from "./presets";
import { PresetKey, presetKey, presetNameFromKey } from "./functions";
import { TranslateContext } from "../contexts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface Props {
  readonly operationTimeState: OperationTime;
  readonly isReadOnly?: boolean;
  readonly dispatch: (action: Actions.Action) => void;
  readonly onConfirm: (operationTime: OperationTime) => void;
  readonly onClose: () => void;
}

// tslint:disable-next-line:function-name
export function OperationTimeDialog(props: Props) {
  return (
    <TranslateContext.Consumer>
      {translate => (
        <Elements.FixedOverlay>
          <Elements.ContainerWithWhiteBorder>
            <Elements.DialogHeaderContainer>
              <h2>
                <span>{translate(LanguageTexts.operationTime()) + "asd"}</span>
              </h2>
              <span onClick={props.onClose}>X</span>
            </Elements.DialogHeaderContainer>
            <Elements.DialogBodyContainer>
              <select
                onChange={event => {
                  const preset = Presets.presets[
                    event.currentTarget.value as keyof typeof Presets.presets
                  ] as OperationTime | undefined;
                  if (preset) {
                    props.dispatch(Actions.operationTimeDialogChange(preset));
                  }
                }}
                value={presetKey(props.operationTimeState) || "custom"}
                disabled={props.isReadOnly}
              >
                {presetKey(props.operationTimeState) ? undefined : (
                  <option value="custom" disabled>
                    Custom
                  </option>
                )}
                {(
                  [
                    "allDay",
                    "officeHours",
                    "factoryHours",
                    "twoShiftHours"
                  ] as ReadonlyArray<PresetKey>
                ).map(key => (
                  <option value={key}>
                    {translate(
                      LanguageTexts.globalPropertyName(presetNameFromKey(key))
                    )}
                  </option>
                ))}
              </select>
              <TimeGridComponent
                operationTime={props.operationTimeState}
                readonly={props.isReadOnly || false}
                onChange={operationTime =>
                  props.dispatch(
                    Actions.operationTimeDialogChange(operationTime)
                  )
                }
              />
              <Elements.ButtonContainer>
                <Elements.StandardButton
                  onClick={() => {
                    props.onConfirm(props.operationTimeState);
                  }}
                >
                  OK
                </Elements.StandardButton>
                <Elements.StandardButton onClick={props.onClose}>
                  Cancel
                </Elements.StandardButton>
              </Elements.ButtonContainer>
            </Elements.DialogBodyContainer>
          </Elements.ContainerWithWhiteBorder>
        </Elements.FixedOverlay>
      )}
    </TranslateContext.Consumer>
  );
}
