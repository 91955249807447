import { PropertyValue, PropertyValueSet } from "@genesys/property";

import * as KnownProperties from "../known-properties";
import { PropertyDefinitions } from "../types";
import {
  setPropertyIfValueAllowed,
  definitionIsValid
} from "../helper-functions";

export function getTopLevelOverrides(
  defaultsToSet: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.hasProperty(KnownProperties.applicationType, defaultsToSet)
  ) {
    return PropertyValueSet.keepProperties(
      [KnownProperties.applicationType],
      defaultsToSet
    );
  }
  return PropertyValueSet.Empty;
}

export function getTopLevelPropertyUpdates(
  selectedProperties: PropertyValueSet.PropertyValueSet,
  previousSelectedProperties: PropertyValueSet.PropertyValueSet,
  propertyDefinitions: PropertyDefinitions
): PropertyValueSet.PropertyValueSet {
  // Check if applicationType changed.
  const applicationTypeChanged =
    PropertyValueSet.getInteger(
      KnownProperties.applicationType,
      selectedProperties
    ) !==
    PropertyValueSet.getInteger(
      KnownProperties.applicationType,
      previousSelectedProperties
    );

  // Ignore if applicationType didn't change or if.
  if (!applicationTypeChanged) {
    return PropertyValueSet.Empty;
  }

  const selectedApplicationType = PropertyValueSet.getInteger(
    KnownProperties.applicationType,
    selectedProperties
  );

  let newDefaults = PropertyValueSet.Empty;

  const systemDef = propertyDefinitions.find(
    x => x.name === KnownProperties.systemConfiguration
  )!;

  if (!definitionIsValid(systemDef, selectedProperties)) {
    newDefaults = setPropertyIfValueAllowed(
      newDefaults,
      selectedProperties,
      KnownProperties.systemConfiguration,
      0,
      propertyDefinitions
    );
  }

  if (selectedApplicationType !== 11) {
    // HT
    newDefaults = setPropertyIfValueAllowed(
      newDefaults,
      selectedProperties,
      KnownProperties.useHeatTransmissionHeatLoad,
      1,
      propertyDefinitions
    );

    newDefaults = setPropertyIfValueAllowed(
      newDefaults,
      selectedProperties,
      KnownProperties.systemOverPressure,
      0,
      propertyDefinitions
    );

    // IHT
    newDefaults = setPropertyIfValueAllowed(
      newDefaults,
      selectedProperties,
      KnownProperties.useIntentionalVentilationHeatLoad,
      1,
      propertyDefinitions
    );
  }

  switch (selectedApplicationType) {
    case 0:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );

      break;

    case 1:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        1,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );

      break;

    case 2:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        0,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      break;

    case 3:
    case 10:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      break;
    case 4:
    case 5:
    case 6:
    case 7:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        1,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      break;

    case 8:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        1,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      break;

    case 9:
      // EfC
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        1,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        1,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        1,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        1,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        1,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      break;

    case 11:
      // EfC
      break;
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromCombustionHeatLoad,
        0,
        propertyDefinitions
      );

      //EfM
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationFromMaterialsHeatLoad,
        0,
        propertyDefinitions
      );

      // Efp
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEmissionFromPeopleHeatLoad,
        0,
        propertyDefinitions
      );

      // EoW
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useEvaporationOfWaterHeatLoad,
        0,
        propertyDefinitions
      );

      // IV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useIntentionalVentilationHeatLoad,
        0,
        propertyDefinitions
      );

      // UV
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationMoistureLoad,
        0,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useUnintendedVentilationHeatLoad,
        0,
        propertyDefinitions
      );

      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );
      // OD
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorMoistureLoad,
        1,
        propertyDefinitions
      );
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useOpenDoorHeatLoad,
        1,
        propertyDefinitions
      );

      // HT
      newDefaults = setPropertyIfValueAllowed(
        newDefaults,
        selectedProperties,
        KnownProperties.useHeatTransmissionHeatLoad,
        0,
        propertyDefinitions
      );

      break;

    default:
  }

  return getPropertyDefaults(newDefaults);
}

function getPropertyDefaults(
  changedSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let newSelections = changedSelections;

  for (const propertyName of PropertyValueSet.getPropertyNames(
    changedSelections
  )) {
    const propertyValue = PropertyValueSet.getInteger(
      propertyName,
      changedSelections
    )!;

    switch (propertyName) {
      case KnownProperties.systemOverPressure:
        changedSelections = PropertyValueSet.merge(
          moistureLoadSystemOverPressureDefaults(propertyValue),
          changedSelections
        );
        break;

      default:
        break;
    }
  }

  return newSelections;
}

function moistureLoadSystemOverPressureDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return PropertyValueSet.Empty;

    case 1:
      return {
        [KnownProperties.useUnintendedVentilationMoistureLoad]:
          PropertyValue.fromInteger(0),
        [KnownProperties.useUnintendedVentilationHeatLoad]:
          PropertyValue.fromInteger(0),
        [KnownProperties.unintendedVentilationManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.useOpenDoorMoistureLoad]: PropertyValue.fromInteger(0),
        [KnownProperties.useOpenDoorHeatLoad]: PropertyValue.fromInteger(0),
        [KnownProperties.openDoorManualInput]: PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

// tslint:disable-next-line:max-file-line-count
