import { Coordinate, BinDataLocation } from "./types";
import { PropertyValueSet } from "@genesys/property";
import { Quantity, Amount, Units } from "@genesys/uom";
import { manualData } from "../climate-data-dialog";

function radians(x: number) {
  return (x * Math.PI) / 180;
}

function geodesicDistance(a: Coordinate, b: Coordinate) {
  const dlon = radians(b.longitude - a.longitude);
  const dlat = radians(b.latitude - a.latitude);

  const alpha =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(radians(a.latitude)) *
      Math.cos(radians(b.latitude)) *
      (Math.sin(dlon / 2) * Math.sin(dlon / 2));
  const angle = 2 * Math.atan2(Math.sqrt(alpha), Math.sqrt(1 - alpha));

  return angle * 6378.16;
}

function closestPointOnSphere<T extends Coordinate>(
  locations: ReadonlyArray<T>,
  to: Coordinate
): T {
  let closestLoction = locations[0];
  let closestDistance = Infinity;

  for (const location of locations) {
    const distance: number = geodesicDistance(to, location);

    if (distance < closestDistance) {
      closestLoction = location;
      closestDistance = distance;
    }
  }

  return closestLoction;
}

export function getCoordinateFromClimateDataProperties(
  climateDataProperties: PropertyValueSet.PropertyValueSet
): Coordinate | undefined {
  const latAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    manualData.latitudeN,
    climateDataProperties
  );
  const latitude = latAmount && Amount.valueAs(Units.Degrees, latAmount);
  const longAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    manualData.longitudeW,
    climateDataProperties
  );
  const longitude = longAmount && Amount.valueAs(Units.Degrees, longAmount);
  const coordinate =
    latitude !== undefined && longitude !== undefined
      ? {
          latitude,
          longitude
        }
      : undefined;

  return coordinate;
}

export function getDefaultLocation(
  binLocations: ReadonlyArray<BinDataLocation>,
  coordinate?: Coordinate
) {
  return coordinate
    ? closestPointOnSphere(binLocations, coordinate)
    : binLocations[0];
}

export type GroupedLocations = ReadonlyArray<{
  readonly countryName: string;
  readonly regions: ReadonlyArray<{
    readonly regionName: string;
    readonly locations: ReadonlyArray<BinDataLocation>;
  }>;
}>;

export function groupLocations(
  locations: ReadonlyArray<BinDataLocation>
): GroupedLocations {
  return makeUnique(
    locations
      .map(location => location.countryName)
      .sort((a, b) => a.localeCompare(b))
  ).map(countryName => ({
    countryName,
    regions: makeUnique(
      locations
        .filter(location => location.countryName === countryName)
        .sort((a, b) => a.regionName.localeCompare(b.regionName))
        .map(location => location.regionName)
    ).map(regionName => ({
      regionName,
      locations: locations
        .filter(
          location =>
            location.countryName === countryName &&
            location.regionName === regionName
        )
        .sort((a, b) => a.locationName.localeCompare(b.locationName))
    }))
  }));
}
export function makeUnique(
  array: ReadonlyArray<string>
): ReadonlyArray<string> {
  return Array.from(new Set(array));
}
