import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  P1,
  StandardButton,
  zIndex,
  PrimaryColors
} from "@genesys/ui-elements";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 380px;
  z-index: ${zIndex.Modal};
  padding: 12px;
  border: 2px solid ${PrimaryColors.muntersBlue};
  border-radius: 6px;
  background-color: white;
  button {
    margin: 10px 0;
  }
`;

const ButtonsContainer = styled.div`
  button {
    margin: 8px;
  }
`;

export function ConfirmationDialog({
  translate,
  resetDefaultSettings,
  close
}: {
  readonly translate: LanguageTexts.Translate;
  readonly resetDefaultSettings: () => void;
  readonly close: () => void;
}): JSX.Element {
  return (
    <ClickAwayListener onClickAway={() => close()}>
      <Root>
        <P1 color="dark" weight="normal">
          {translate(LanguageTexts.resetuserprofile())}
        </P1>
        <ButtonsContainer>
          <StandardButton
            buttonType="Primary-2"
            size="Small"
            onClick={() => {
              close();
              resetDefaultSettings();
            }}
          >
            {translate(LanguageTexts.confirm())}
          </StandardButton>
          <StandardButton
            buttonType="Secondary-2"
            size="Small"
            onClick={() => close()}
          >
            {translate(LanguageTexts.cancel())}
          </StandardButton>
        </ButtonsContainer>
      </Root>
    </ClickAwayListener>
  );
}
