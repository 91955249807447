import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createHelpIconSvg(color: string) {
  const svgString = `
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>A81BD608-2AAF-4EBD-8CD7-745102F26735</title>
    <defs>
        <path d="M10,14 C10.553,14 11,14.447 11,15 C11,15.553 10.553,16 10,16 C9.447,16 9,15.553 9,15 C9,14.447 9.447,14 10,14 Z M10,4 C11.93,4 13.5,5.57 13.5,7.5 C13.5,9.08 12.44,10.403 11,10.837 L11,10.837 L11,12 C11,12.553 10.553,13 10,13 C9.447,13 9,12.553 9,12 L9,12 L9,10 C9,9.447 9.447,9 10,9 C10.827,9 11.5,8.327 11.5,7.5 C11.5,6.673 10.827,6 10,6 C9.173,6 8.5,6.673 8.5,7.5 C8.5,8.053 8.053,8.5 7.5,8.5 C6.947,8.5 6.5,8.053 6.5,7.5 C6.5,5.57 8.07,4 10,4 Z M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0" id="path-1"></path>
    </defs>
    <g id="Page-Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Design---Dashboard" transform="translate(-1308.000000, -22.000000)">
            <g id="Icon-/-Help" transform="translate(1308.000000, 22.000000)">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <use id="🎨-Icon-Сolor" fill="${color}" xlink:href="#path-1"></use>
            </g>
        </g>
    </g>
</svg>
    
    `;
  return encodeSvgStringToImgSrc(svgString);
}
