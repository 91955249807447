import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as System from "../../../../system";
import * as HelperFunctions from "../../helper-functions";
import styled from "styled-components";
import { MenuButton, ReadOnlyTable } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../../state";

const Container = styled.div``;

const StyledLabel = styled.label`
  font-size: 16px;

  &:hover {
    color: #4bccff;
    cursor: pointer;
  }
`;

interface PermissionType {
  readonly canSeeComponentPrice: boolean;
  readonly canSeeProductCode: boolean;
  readonly canSeeRefCost: boolean;
  readonly canSeeMarginCost: boolean;
  readonly canSeeTransferPrice: boolean;
  readonly canSeeListPrice: boolean;
  readonly canSeeConsolidatedMargin: boolean;
  readonly canSeeMasterMarginCost: boolean;
  readonly canSeeMasterTransferPrice: boolean;
  readonly canSeeMasterListPrice: boolean;
  readonly canSeeMargin: boolean;
  readonly canSeeMCZPrice: boolean;
}

export function ExtendedInformation({
  sharedState,
  permissions,
  systemUserData,
  baseComponents,
  accessories,
  isOpen,
  currencies,
  dispatch
}: {
  readonly systemUserData: GraphQlTypes.SystemHasPrice;
  readonly permissions: PermissionType;
  readonly sharedState: SharedState.State;
  readonly baseComponents: ReadonlyArray<System.Component>;
  readonly accessories: ReadonlyArray<System.Component>;
  readonly currencies: {
    readonly currency: {
      readonly signAfter?: string;
      readonly signBefore?: string;
    };
    readonly masterCurrency: {
      readonly signAfter?: string;
      readonly signBefore?: string;
    };
  };
  readonly isOpen: boolean;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const translate = sharedState.translate;
  const rows = prepareRows(
    permissions,
    sharedState,
    systemUserData,
    baseComponents,
    accessories,
    currencies
  );

  return (
    <Container>
      <MenuButton
        onClick={() => dispatch(Action.toggleExtendedInformation())}
        hasMenu={true}
      >
        <StyledLabel>
          {translate(LanguageTexts.extendedInformation())}
        </StyledLabel>
      </MenuButton>
      {isOpen && <ReadOnlyTable rows={rows} />}
    </Container>
  );
}

function prepareRows(
  permissions: PermissionType,
  sharedState: SharedState.State,
  systemUserData: GraphQlTypes.SystemHasPrice,
  baseComponents: ReadonlyArray<System.Component>,
  accessories: ReadonlyArray<System.Component>,
  currencies: {
    readonly currency: {
      readonly signAfter?: string;
      readonly signBefore?: string;
    };
    readonly masterCurrency: {
      readonly signAfter?: string;
      readonly signBefore?: string;
    };
  }
): ReadonlyArray<{
  readonly name: string;
  readonly results: ReadonlyArray<string>;
}> {
  const translate = sharedState.translate;
  const currency = currencies.currency;
  const masterCurrency = currencies.masterCurrency;
  const systemNumbers = HelperFunctions.priceDictionary(
    systemUserData.user.system.systemComponentPrice,
    [...baseComponents, ...accessories]
  );
  const rows: Array<{
    readonly name: string;
    readonly results: ReadonlyArray<string>;
  }> = [];
  const marginCost = systemNumbers["MarginCost"];
  const masterMarginCost = systemNumbers["MasterMarginCost"];
  //const refCost = systemNumbers["RefCost"];
  const listPrice = systemNumbers["MarketListPrice"];
  const transferPrice = systemNumbers["MarketTransferPrice"];
  const masterListPrice = systemNumbers["MasterMarketListPrice"];
  const masterTransferPrice = systemNumbers["MasterMarketTransferPrice"];

  const MCZFactoryPrice = systemNumbers["MczFactoryPrice"];
  const margin = ((listPrice - transferPrice) / listPrice) * 100;
  const consolidatedMargin = ((listPrice - marginCost) / listPrice) * 100;

  if (permissions.canSeeMargin) {
    rows.push({
      name: translate(LanguageTexts.margin()),
      results: [`${margin.toFixed(2)} %`]
    });
  }

  if (permissions.canSeeConsolidatedMargin) {
    rows.push({
      name: translate(LanguageTexts.consolidatedMargin()),
      results: [`${consolidatedMargin.toFixed(2)} %`]
    });
  }

  if (permissions.canSeeMarginCost) {
    rows.push({
      name: translate(LanguageTexts.marginCost()),
      results: [
        `${currency.signBefore ? currency.signBefore : ""} ${Math.round(
          marginCost
        ).toLocaleString(sharedState.user.settings.locale)} ${
          currency.signAfter ? currency.signAfter : ""
        }`
      ]
    });
  }

  if (permissions.canSeeMCZPrice) {
    rows.push({
      name: translate(LanguageTexts.mczFactoryPrice()),
      results: [
        `${currency.signBefore ? currency.signBefore : ""} ${Math.round(
          MCZFactoryPrice
        ).toLocaleString(sharedState.user.settings.locale)} ${
          currency.signAfter ? currency.signAfter : ""
        }`
      ]
    });
  }

  if (permissions.canSeeTransferPrice) {
    rows.push({
      name: translate(LanguageTexts.transferPrice()),
      results: [
        `${currency.signBefore ? currency.signBefore : ""} ${Math.round(
          transferPrice
        ).toLocaleString(sharedState.user.settings.locale)} ${
          currency.signAfter ? currency.signAfter : ""
        }`
      ]
    });
  }

  if (permissions.canSeeListPrice) {
    rows.push({
      name: translate(LanguageTexts.listPrice()),
      results: [
        `${currency.signBefore ? currency.signBefore : ""} ${Math.round(
          listPrice
        ).toLocaleString(sharedState.user.settings.locale)} ${
          currency.signAfter ? currency.signAfter : ""
        }`
      ]
    });
  }

  if (permissions.canSeeMasterMarginCost) {
    rows.push({
      name: translate(LanguageTexts.masterMarginCost()),
      results: [
        masterCurrency
          ? `${
              masterCurrency.signBefore ? masterCurrency.signBefore : ""
            } ${Math.round(masterMarginCost).toLocaleString(
              sharedState.user.settings.locale
            )} ${masterCurrency.signAfter ? masterCurrency.signAfter : ""}`
          : "Missing claim for master currency"
      ]
    });
  }

  if (permissions.canSeeMasterTransferPrice) {
    rows.push({
      name: translate(LanguageTexts.masterTransferPrice()),
      results: [
        masterCurrency
          ? `${
              masterCurrency.signBefore ? masterCurrency.signBefore : ""
            } ${Math.round(masterTransferPrice).toLocaleString(
              sharedState.user.settings.locale
            )} ${masterCurrency.signAfter ? masterCurrency.signAfter : ""}`
          : "Missing claim for master currency"
      ]
    });
  }

  if (permissions.canSeeMasterListPrice) {
    rows.push({
      name: translate(LanguageTexts.masterListPrice()),
      results: [
        masterCurrency
          ? `${
              masterCurrency.signBefore ? masterCurrency.signBefore : ""
            } ${Math.round(masterListPrice).toLocaleString(
              sharedState.user.settings.locale
            )} ${masterCurrency.signAfter ? masterCurrency.signAfter : ""}`
          : "Missing claim for master currency"
      ]
    });
  }

  return rows;
}
