import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import * as SharedState from "../shared-state";
import { ClimateSelectorView } from "../climate-selector/view";
import * as ClimateSelector from "../climate-selector/state";
import { ProductData } from "./types";
import { NewOperatingCaseCard } from "./components/new-operating-case-card";
import { ClimateDataPresenter } from "../climate-data-presenter";
import { StandardButton, Spinner } from "@genesys/ui-elements";
import { hasPermissionForMlc } from "../moisture-load-calculation";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  CasesContainer,
  LocationContainer,
  Root,
  ClimateSelectorContainer,
  CardContainer,
  PropertiesSelectorJsxContainer,
  DeleteButtonContainer,
  AddNewCaseButtonContainer,
  PropertyNamesContainer,
  SpinnerContainer,
  MoistureLoadContainer
} from "./elements";
import * as PropertiesSelector from "../properties-selector";
import { PropertyValueSet } from "@genesys/property";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as Authorization from "@genesys/shared/lib/authorization";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { TrashBlue, OpenBlue } from "@genesys/ui-elements/lib/icons";
import { MoistureLoadPresenter } from "./components/moisture-load-presenter";

export function OperatingCasesView({
  dispatch,
  state,
  sharedState,
  productData,
  sysProperties,
  systemStatus,
  disableAddAndDelete,
  useMoistureLoadSelector
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly productData: ProductData | undefined;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly systemStatus: SystemStatus;
  readonly disableAddAndDelete?: boolean;
  readonly useMoistureLoadSelector?: boolean;
}): JSX.Element {
  const productProperties = React.useMemo<
    ReadonlyArray<PropertiesSelector.PropertyInfo>
  >(() => {
    if (productData === undefined) {
      return [];
    }
    const filteredProductData = ProductDataTools.filterProductForRange(
      productData,
      sysProperties
    );
    return filteredProductData.properties;
  }, [productData]);

  if (!productData) {
    return <Root>Error product not found</Root>;
  }

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const showMoistureLoadSelector =
    useMoistureLoadSelector &&
    hasPermissionForMlc(sharedState.user.applicationClaims);

  if (state.loading) {
    return (
      <Root>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Root>
    );
  }

  const showClimateSelector =
    state.isOpenClimateSelector && state.climateSelectorState?.dataPoints;

  const isLocked = systemStatus >= SystemStatus.LockSuccess;

  return (
    <Root>
      {!showClimateSelector && (
        <LocationContainer>
          <ClimateDataPresenter
            title={sharedState.translate(LanguageTexts.location())}
            climateSettings={state.climateSettings}
            sharedState={sharedState}
            onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
              dispatch(
                Action.formatChanged(fieldGroup, fieldName, unit, decimalCount)
              )
            }
            onFormatCleared={(fieldGroup, fieldName) =>
              dispatch(Action.formatCleared(fieldGroup, fieldName))
            }
          />

          {!isLocked && (
            <StandardButton
              buttonType="Primary"
              size="Large"
              onClick={() => dispatch(Action.onOpenClimateSelector())}
            >
              {sharedState.translate(LanguageTexts.selectLocation())}
            </StandardButton>
          )}
        </LocationContainer>
      )}

      {showClimateSelector && (
        <ClimateSelectorContainer>
          <ClimateSelectorView
            isDisabled={isLocked}
            state={state.climateSelectorState}
            sharedState={sharedState}
            showWindVelocity={false}
            dispatch={Dispatch.map(Action.dispatchClimateSelector, dispatch)}
            onChange={() =>
              dispatch(
                Action.onClimateSelectorChange(
                  ClimateSelector.getClimateSettings(
                    state.climateSelectorState!
                  ),
                  productData.properties
                )
              )
            }
            onClose={() => dispatch(Action.onCloseClimateSelector())}
          />
        </ClimateSelectorContainer>
      )}

      {!isLocked && showMoistureLoadSelector && (
        <MoistureLoadContainer>
          <MoistureLoadPresenter
            onMoistureLoadChange={newId =>
              dispatch(Action.setNewMoistureLoadId(newId))
            }
            dispatch={dispatch}
            sharedState={sharedState}
            state={state}
          />
        </MoistureLoadContainer>
      )}

      {!isLocked &&
        !disableAddAndDelete &&
        state.propertiesSelectorStates.length > 2 && (
          <AddNewCaseButtonContainer>
            <StandardButton
              icon={OpenBlue}
              size="Small"
              buttonType="Secondary"
              onClick={() =>
                dispatch(Action.onAddNewOperatingCase(productData.properties))
              }
            >
              {sharedState.translate(LanguageTexts.addNew())}
            </StandardButton>
          </AddNewCaseButtonContainer>
        )}

      <CasesContainer>
        <PropertyNamesContainer>
          <PropertiesSelectorJsxContainer isFirstCard={true}>
            <PropertiesSelector.PropertiesSelectorView
              dispatch={Dispatch.map(
                action =>
                  Action.dispatchPropertiesSelector(
                    state.propertiesSelectorStates[0].id,
                    action,
                    productData
                  ),
                dispatch
              )}
              productId={productData.id}
              sharedState={sharedState}
              propertiesInfo={productProperties}
              fieldGroup={`Settings.${productData.id}`}
              isReadonly={isLocked}
              showCodes={showCodes}
              state={state.propertiesSelectorStates[0].state}
            />
          </PropertiesSelectorJsxContainer>
        </PropertyNamesContainer>

        {state.propertiesSelectorStates.map(pss => (
          <CardContainer key={pss.id}>
            <>
              <PropertiesSelectorJsxContainer isFirstCard={false}>
                <PropertiesSelector.PropertiesSelectorView
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchPropertiesSelector(
                        pss.id,
                        action,
                        productData
                      ),
                    dispatch
                  )}
                  productId={productData.id}
                  sharedState={sharedState}
                  propertiesInfo={productProperties}
                  fieldGroup={`Settings.${productData.id}`}
                  isReadonly={isLocked}
                  showCodes={showCodes}
                  state={pss.state}
                  hidePropertyNames={true}
                />
              </PropertiesSelectorJsxContainer>

              {!isLocked && !disableAddAndDelete && (
                <DeleteButtonContainer>
                  {state.propertiesSelectorStates.length !== 1 && (
                    <a
                      onClick={() =>
                        dispatch(
                          Action.onDeleteOperatingCase(
                            pss.id,
                            productData.properties
                          )
                        )
                      }
                    >
                      <TrashBlue height="1.6rem" />
                    </a>
                  )}
                </DeleteButtonContainer>
              )}
            </>
          </CardContainer>
        ))}

        {!isLocked && !disableAddAndDelete && (
          <NewOperatingCaseCard
            onClick={() =>
              dispatch(Action.onAddNewOperatingCase(productData.properties))
            }
            label={sharedState.translate(LanguageTexts.addNew())}
          />
        )}
      </CasesContainer>
    </Root>
  );
}
