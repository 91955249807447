import styled from "styled-components";
import * as React from "react";

const unstyledAddButton = (
  props: { readonly text?: string } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const { text, ...rest } = props;
  return (
    <button {...rest}>
      <div>
        <img
          src={"./assets/images/green-circle-white-plus.svg"}
          width="30px"
          height="30px"
        />
        {text && <span>{text}</span>}
      </div>
    </button>
  );
};

// tslint:disable-next-line
export const AddButton = styled(unstyledAddButton)`
  background-image: linear-gradient(to top, #e0e0e0, white 60%);
  border-radius: 4px;
  border: 1px outset #ddd;
  padding: 1px;
  cursor: pointer;
  margin: 0px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
      font-size: 12px;
      padding: 2px;
    }
  }
`;
