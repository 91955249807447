import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as System from "../../system";
import * as Notes from "../../../notes";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";

export type State = {
  readonly componentsNotes: ReadonlyArray<{
    readonly componentId: string;
    readonly state: Notes.State;
    readonly hasChanged: boolean;
  }>;
  readonly selectedComponent: string;
  readonly canSave: boolean;
  readonly visibleComponents: ReadonlyArray<System.Component>;
};

export const init = (
  systemId: string,
  systemStatus: System.System["status"],
  components: ReadonlyArray<System.Component>
): [State, Cmd<Action>?] => {
  let componentsNotes: Array<{
    readonly componentId: string;
    readonly state: Notes.State;
    readonly hasChanged: boolean;
  }> = [];

  const visibleComponents = components.filter(
    c => !c.productId.endsWith("OPC") && !c.productId.endsWith("OPE")
  );

  visibleComponents.forEach(c => {
    const [notesState] = Notes.init(systemId, systemStatus, c);
    componentsNotes.push({
      componentId: c.id,
      state: notesState,
      hasChanged: false
    });
  });

  return [
    {
      componentsNotes: componentsNotes,
      selectedComponent: visibleComponents[0].id,
      canSave: false,
      visibleComponents
    }
  ];
};

export const Action = ctorsUnion({
  dispatchNotes: (componentId: string, action: Notes.Action) => ({
    componentId,
    action
  }),
  setSelectedComponent: (componentID: string) => ({ componentID })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchNotes": {
      const dispatchedComponentNotes = state.componentsNotes.find(
        ns => ns.componentId === action.componentId
      );

      if (!dispatchedComponentNotes) {
        return [state];
      }

      const [notesState] = Notes.update(
        action.action,
        dispatchedComponentNotes.state
      );

      const newNotesStates = state.componentsNotes.map(ns => {
        if (ns.componentId === action.componentId) {
          return {
            componentId: action.componentId,
            state: notesState,
            hasChanged: Notes.hasChanged(notesState)
          };
        } else {
          return ns;
        }
      });

      const hasAnyNotesChanged =
        newNotesStates.filter(ns => ns.hasChanged).length > 0;

      return [
        {
          ...state,
          componentsNotes: newNotesStates,
          canSave: hasAnyNotesChanged
        }
      ];
    }
    case "setSelectedComponent": {
      return [{ ...state, selectedComponent: action.componentID }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfigurationActions(
  state: State
): ReadonlyArray<ConfiguratorAction | undefined> {
  let configurationActions: Array<ConfiguratorAction | undefined> = [];

  state.componentsNotes.forEach(ns => {
    configurationActions.push(Notes.getNotesConfigurationActions(ns.state));
  });

  return configurationActions;
}
