import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Presets } from "./detailed-results-and-charts/chart-presets";
import { setSelectedLoadCaseFlow } from "@genesys/client-core/lib/graphql-mutations";
import * as SharedState from "../../../shared-state";
import * as Types from "./types";
import * as GraphQLTypes from "../../../graphql-types";

interface SectionState {
  readonly [key: string]: {
    readonly isOpen: boolean;
  };
}

export const defaultFlowIndex = 0;

export const mlSessionStorageName = "ml-op-case";

export type State = {
  readonly selectedBinVisualizer: Types.BinVisualizer;
  readonly selectedChartPreset: Presets;
  readonly selectedFlowIndex: {
    readonly [key: string]: number;
  };
  readonly sectionState: SectionState;
  readonly useOverlay: boolean;
};

export const init = (): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      selectedBinVisualizer: "List",
      selectedChartPreset: "moistureLoadsAndTime",
      selectedFlowIndex: {},
      sectionState: {
        mlcsystemsuggestions: {
          isOpen: true
        }
      },
      useOverlay: false
    }
  ];
};

export const Action = ctorsUnion({
  setBinVisualizer: (binVisualizer: Types.BinVisualizer) => ({ binVisualizer }),
  setChartPreset: (preset: Presets) => ({ preset }),
  setSelectedFlowIndexes: (flowIndexes: {
    readonly [key: string]: number;
  }) => ({
    flowIndexes
  }),
  setSelectedLoadCaseFlow: (
    moistureLoadId: string,
    caseId: string,
    flowId: number
  ) => ({ moistureLoadIId: moistureLoadId, caseId, flowId }),
  setSelectedLoadCaseFlowDone: () => ({}),
  updateSectionState: (newSectionState: SectionState) => ({
    newSectionState
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "setBinVisualizer": {
      return [
        {
          ...state,
          selectedBinVisualizer: action.binVisualizer
        }
      ];
    }

    case "setChartPreset": {
      return [
        {
          ...state,
          selectedChartPreset: action.preset
        }
      ];
    }

    case "setSelectedFlowIndexes": {
      return [
        {
          ...state,
          selectedFlowIndex: action.flowIndexes
        }
      ];
    }

    case "setSelectedLoadCaseFlow": {
      return [
        {
          ...state,
          useOverlay: true
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SetSelectedLoadCaseFlow,
          GraphQLTypes.SetSelectedLoadCaseFlowVariables,
          Action
        >(
          setSelectedLoadCaseFlow,
          {
            caseId: action.caseId,
            flowId: action.flowId,
            moistureLoadId: action.moistureLoadIId
          },
          Action.setSelectedLoadCaseFlowDone
        )
      ];
    }
    case "updateSectionState": {
      return [
        {
          ...state,
          sectionState: action.newSectionState
        }
      ];
    }

    case "setSelectedLoadCaseFlowDone": {
      return [{ ...state, useOverlay: false }];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function reset(state: State): State {
  return {
    ...state
  };
}
