import {
  LanguageIdText,
  StaticText,
  SystemIdText,
  TabTitleText,
  TranslateTodoText,
  StaticTextWithParameters,
  ProductPropertyText,
  ProductPropertyValueText,
  CurlyText,
  SystemTypePropertyText,
  SystemTypePropertyValueText,
  ProductText,
  ReportParamText,
  ReportParamValueText
} from "./types";

export const dhCapacity = () => staticText("dhCapacity");
export const systemCreated = () => staticText("systemCreated");
export const preValidation = () => staticText("preValidation");
export const warnings = () => staticText("warnings");
export const errors = () => staticText("errors");
export const variant = () => staticText("variant");
export const calculateSystems = () => staticText("calculateSystems");
export const calculating = () => staticText("calculating");
export const electricHeater = () => staticText("electricHeater");
export const steamHeater = () => staticText("steamHeater");
export const condenser = () => staticText("condenser");
export const gasHeater = () => staticText("gasheater");
export const labelsmanager = () => staticText("labelsmanager");
export const chooseLabels = () => staticText("chooseLabels");
export const chooseMonths = () => staticText("chooseMonths");
export const edviewreport = () => staticText("edviewreport");
export const edsubmitreport = () => staticText("edsubmitreport");
export const reportoptions = () => staticText("reportOptions");
export const edreload = () => staticText("edreload");
export const edcopyexception = () => staticText("edcopyexception");
export const edContinue = () => staticText("edcontinue");
export const loggedinuser = () => staticText("loggedinuser");
export const logintime = () => staticText("logintime");
export const logout = () => staticText("logout");
export const systemArrangement = () => staticText("systemarrangement");
export const systemSettings = () => staticText("systemsettings");
export const quickSelect = () => staticText("quickselect");
export const system = () => staticText("system");
export const alert = () => staticText("alert");
export const dashboard = () => staticText("dashboard");
export const favourites = () => staticText("favourites");
export const newInHelpDeskHeader = () => staticText("newinhelpdeskheader");
export const systemsmanager = () => staticText("systemsmanager");
export const newsystem = () => staticText("newsystem");
export const pricing = () => staticText("pricing");
export const pricingmanager = () => staticText("pricingmanager");
export const newPricing = () => staticText("newpricing");
export const newMoistureLoad = () => staticText("newmoistureload");
export const usersettings = () => staticText("usersettings");
export const settings = () => staticText("settings");
export const culture = () => staticText("culture");
export const languagecodes = () => staticText("languagecodes");
export const screenamountprofiles = () => staticText("screenamountprofiles");
export const editprofiles = () => staticText("editprofiles");
export const userDefaults = () => staticText("userDefaults");
export const resetuserprofile = () => staticText("resetuserprofile");
export const reset = () => staticText("reset");
export const resetAll = () => staticText("resetall");
export const tools = () => staticText("tools");
export const psychrometricChart = () => staticText("PsychrometricChart");
export const binPage = () => staticText("binpage");
export const dryCap = () => staticText("dryCap");
export const humidityConversion = () => staticText("HumidityConversion");
export const fanChart = () => staticText("FanChart");
export const airMixer = () => staticText("AirMixer");
export const airPowerTool = () => staticText("AirPowerTool");
export const coilProTool = () => staticText("CoilProTool");
export const fanProTool = () => staticText("FanProTool");
export const nevadaTestSite = () => staticText("NevadaTestSite");
export const sycoolTool = () => staticText("SycoolTool");
export const dataCenterWizard = () => staticText("DataCenterWizard");
export const iceDryTool = () => staticText("IceDryTool");
export const usagestatistics = () => staticText("usagestatistics");
export const viewEffectiveClaims = () => staticText("ViewEffectiveClaims");
export const viewAll = () => staticText("ViewAll");
export const advancedProductSearch = () => staticText("AdvancedProductSearch");
export const tAndCAcceptanceStatus = () => staticText("tandcacceptancestatus");
export const translationSearch = () => staticText("translationsearch");
export const moistureLoad = () => staticText("moistureload");
export const moistureLoadName = () => staticText("moistureloadname");
export const moistureLoadCreated = () => staticText("moistureloadcreated");
export const moistureLoadNo = () => staticText("moistureloadno");
export const connectSystemTomlc = () => staticText("connectsystemtomlc");
export const opcMlcImportText = () => staticText("opcmlcimporttext");
export const moistureLoadCalculation = () =>
  staticText("MoistureLoadCalculation");
export const drysize = () => staticText("drysize");
export const moistureLoadManager = () => staticText("moistureloadmanager");
export const newMoistureLoadCalc = () => staticText("newmoistureloadcalc");
export const errorreports = () => staticText("errorreports");
export const startpage = () => staticText("startpage");
export const about = () => staticText("about");
export const abort = () => staticText("abort");
export const calculatingEnergyCase = () => staticText("calculatingenergycase");
export const changelog = () => staticText("changelog");
export const requestAccessLevel = () => staticText("requestAccessLevel");
export const wiki = () => staticText("wiki");
export const legacyClient = () => staticText("legacyclient");
export const instructionVideos = () => staticText("instructionvideos");
export const maintenance = () => staticText("maintenance");
export const helpmenu = () => staticText("helpmenu");
export const confirmresetallsettings = () =>
  staticText("confirmresetallsettings");
export const yes = () => staticText("yes");
export const no = () => staticText("no");
export const ok = () => staticText("ok");
export const update = () => staticText("update");
export const confirmation = () => staticText("confirmation");
export const open = () => staticText("open");
export const language = () => staticText("language");
export const currency = () => staticText("currency");
export const measuresystem = () => staticText("measuresystem");
export const dateTimeNumberFormat = () => staticText("dateTimeNumberFormat");
export const country = () => staticText("country");
export const state = () => staticText("state");
export const location = () => staticText("location");
export const fileformat = () => staticText("fileformat");
export const papersize = () => staticText("papersize");
export const headerLabel = () => staticText("headerlabel");
export const printData = () => staticText("printdata");
export const load = () => staticText("load");
export const latitude = () => staticText("latitude");
export const longitude = () => staticText("longitude");
export const recentsystemsheader = () => staticText("recentsystemsheader");
export const showallrecentlychangedsystems = () =>
  staticText("showallrecentlychangedsystems");
export const lastChange = () => staticText("LastChange");
export const systems = () => staticText("Systems");
export const systemsFromMySystems = () => staticText("systemsFromMySystems");
export const systemsFromAllSystems = () => staticText("systemsFromAllSystems");
export const elevation = () => staticText("elevation");
export const name = () => staticText("name");
export const type = () => staticText("type");
export const measureSystem = () => staticText("measuresystem");
export const edit = () => staticText("edit");
export const del = () => staticText("delete");
export const close = () => staticText("close");
export const cancel = () => staticText("cancel");
export const assign = () => staticText("assign");
export const back = () => staticText("back");
export const select = () => staticText("select");
export const selected = () => staticText("selected");
export const save = () => staticText("save");
export const newText = () => staticText("new");
export const createProfile = () => staticText("createnewprofile");
export const unsupportedBrowser = () => staticText("unsupportedbrowser");
export const browserinformation = () => staticText("browserinformation");
export const defaultCurrency = () => staticText("defaultCurrency");
export const defaultSalesOrganisation = () =>
  staticText("defaultSalesOrganisation");
export const deleteArticle = () => staticText("deletearticle");
export const deleteArticleConfirm = () => staticText("deletearticleconfirm");
export const changed = () => staticText("changed");
export const owner = () => staticText("owner");
export const status = () => staticText("status");
export const labels = () => staticText("labels");
export const rename = () => staticText("rename");
export const assignlabels = () => staticText("assignlabels");
export const editLabels = () => staticText("editlabels");
export const copylatestrevision = () => staticText("copylatestrevision");
export const createnewrevision = () => staticText("createnewrevision");
export const transfertouser = () => staticText("transfertouser");
export const confirmdeletelong = () => staticText("confirmdeletelong");
export const confirmdelete = () => staticText("confirmdelete");
export const allLabels = () => staticText("alllabels");
export const assignedLabels = () => staticText("assignedlabels");
export const createNewLabel = () => staticText("createnewlabel");
export const newLabel = () => staticText("newlabel");
export const labelName = () => staticText("labelname");
export const createNewSystem = () => staticText("createnewsystem");
export const noFavoritesMsg = () => staticText("nofavoritesmsg");
export const helpCenter = () => staticText("helpcenter");
export const traningVideos = () => staticText("traningvideos");
export const faq = () => staticText("faqs");
export const systemInformation = () => staticText("systeminformation");
export const changeLog = () => staticText("changelog");
export const showAll = () => staticText("showall");
export const showLess = () => staticText("showless");
export const showUnassigned = () => staticText("showunassigned");
export const noAssignedLabels = () => staticText("noassignedlabels");
export const enterFileNameHere = () => staticText("enterFileNameHere");
export const date = () => staticText("date");
export const from = () => staticText("from");
export const to = () => staticText("to");
export const clear = () => staticText("clear");
export const saveAsPrecheckedDefault = () =>
  staticText("saveAsPrecheckedDefault");
export const helpAboutSearching = () => staticText("helpaboutsearching");
export const of = () => staticText("of");
export const pages = () => staticText("pages");
export const myPricings = () => staticText("mypricings");
export const myMoistureLoad = () => staticText("mymoistureload");
export const allMoistureLoads = () => staticText("allmoistureloads");
export const allPricings = () => staticText("allpricings");
export const mySystems = () => staticText("mysystems");
export const allSystems = () => staticText("allsystems");
export const latestRevNeedsToBeLocked = () =>
  staticText("latestrevneedstobelocked");
export const copyTransferOpen = () => staticText("copytransferopen");
export const editComment = () => staticText("editcomment");
export const comment = () => staticText("comment");
export const nocomment = () => staticText("nocomment");
export const createPricingAndAdd = () => staticText("createpricingandadd");
export const recoverSystem = () => staticText("recoversystem");
export const deletedBy = () => staticText("deletedby");
export const moreActions = () => staticText("moreactions");
export const exportText = () => staticText("export");
export const importText = () => staticText("import");
export const exampleTemplate = () => staticText("exampletemplate");
export const summary = () => staticText("summary");
export const projectSelection = () => staticText("projectselection");
export const systemCompare = () => staticText("systemcompare");
export const exportSketch = () => staticText("exportsketch");
export const cannotShowMechanicalSketchMsg = () =>
  staticText("cannotshowmechanicalsketchmsg");
export const cannotShowMechanicalSketchStaticMsg = () =>
  staticText("cannotshowmechanicalsketchstaticmsg");
export const actions = () => staticText("actions");
export const systemsPerPage = () => staticText("systemsperpage");
export const usingLabels = () => staticText("usinglabels");
export const usingLabelsMsg = () => staticText("usinglabelsmsg");
export const createNew = () => staticText("createnew");
export const deleteText = () => staticText("delete");
export const deleteLabelMsg = (labelName: string) =>
  staticTextWithParams("deletelabelmsg", labelName);
export const confirm = () => staticText("confirm");
export const continueText = () => staticText("continue");
export const buildState = () => staticText("buildState");
export const longRunningTask = () => staticText("longRunningTask");
export const couldNotBuild = () => staticText("couldNotBuild");
export const requestBuild = () => staticText("requestBuild");
export const refresh = () => staticText("refresh");
export const piece = () => staticText("piece");
export const article = () => staticText("article");
export const header = () => staticText("header");
export const articleGroup = () => staticText("articlegroup");
export const rowNo = () => staticText("rowno");
export const articleNo = () => staticText("articleno");
export const add = () => staticText("add");
export const pricingCurrency = () => staticText("pricingCurrency");
export const salesorg = () => staticText("salesorg");
export const group = () => staticText("group");
export const description = () => staticText("description");
export const pidshort = () => staticText("pidshort");
export const caseshort = () => staticText("caseshort");
export const quantity = () => staticText("quantity");
export const unit = () => staticText("unit");
export const costPerUnit = () => staticText("costperunit");
export const priceFactor = () => staticText("pricefactor");
export const transferPriceFactor = () => staticText("transferpricefactor");
export const listPriceFactor = () => staticText("listpricefactor");
export const pricePerUnit = () => staticText("pricePerUnit");
export const transferPricePerUnit = () => staticText("transferpriceperunit");
export const listPricePerUnit = () => staticText("listpriceperunit");
export const discountPercent = () => staticText("discountpercent");
export const total = () => staticText("total");
export const costSubtotal = () => staticText("costsubtotal");
export const priceSubtotal = () => staticText("pricesubtotal");
export const transferPriceSubtotal = () => staticText("transferpricesubtotal");
export const listPriceSubtotal = () => staticText("listpricesubtotal");
export const notes = () => staticText("notes");
export const goToProductPage = () => staticText("gotoproductpage");
export const componentSpec = () => staticText("componentspec");
export const totalPrice = () => staticText("totalprice");
export const totalListPrice = () => staticText("totallistprice");
export const totalTransferPrice = () => staticText("totaltransferprice");
export const totalMarginPercent = () => staticText("totalmarginpercent");
export const lockPricing = () => staticText("lockpricing");
export const printPreview = () => staticText("printpreview");
export const includeCost = () => staticText("includecost");
export const includeMargin = () => staticText("includemargin");
export const includePricePerUnit = () => staticText("includepriceperunit");
export const includeListPrice = () => staticText("includelistprice");
export const includeTransferPrice = () => staticText("includetransferprice");
export const includePriceSubtotal = () => staticText("includepricesubtotal");
export const includeTotalPrice = () => staticText("includetotalprice");
export const lockingPricingInfo = () => staticText("lockingpricinginfo");
export const deleteAllRows = () => staticText("deleteallrows");
export const deleteRow = () => staticText("deleterow");
export const deleteRowConfirm = () => staticText("deleterowconfirm");
export const deleteAllRowsConfirm = () => staticText("deleteallrowsconfirm");
export const search = () => staticText("search");
export const genesysNo = () => staticText("genesysno");
export const pricingNoSystemFound = () => staticText("pricingnosystemfound");
export const pricingReport = () => staticText("pricingreport");
export const savePricingToCreateReport = () =>
  staticText("savepricingtocreatereport");
export const preview = () => staticText("preview");
export const download = () => staticText("download");
export const upload = () => staticText("upload");
export const downloadDotDotDot = () => staticText("downloaddotdotdot");
export const newRow = () => staticText("newrow");
export const create = () => staticText("create");
export const createPDF = () => staticText("createpdf");
export const createDocx = () => staticText("createdocx");
export const generating = () => staticText("generating");
export const pricingName = () => staticText("pricingname");
export const moistureloadName = () => staticText("moistureloadname");
export const nameAndLabels = () => staticText("nameandlabels");
export const selectProductRange = () => staticText("selectproductrange");
export const selectSystemSize = () => staticText("selectsystemsize");
export const previous = () => staticText("Previous");
export const next = () => staticText("Next");
export const nextStep = () => staticText("nextstep");
export const finish = () => staticText("finish");
export const initialConfiguration = () => staticText("initialconfiguration");
export const operatingCases = () => staticText("operatingcases");
export const sizeSelection = () => staticText("sizeselection");
export const serviceCalculation = () => staticText("servicecalculation");
export const serviceCalculationNotLocked = () =>
  staticText("serviceCalculationNotLocked");
export const dehumidifier = () => staticText("Dehumidifier");
export const plenum = () => staticText("plenum");
export const model = () => staticText("model");
export const faceArea = () => staticText("facearea");
export const velocity = () => staticText("velocity");
export const minVelocity = () => staticText("minvelocity");
export const maxVelocity = () => staticText("maxvelocity");
export const targetPower = () => staticText("targetpower");
export const noSizesFoundForVelocity = () =>
  staticText("nosizesfoundforvelocity");
export const selectLocation = () => staticText("selectlocation");
export const error = () => staticText("error");
export const or = () => staticText("or");
export const and = () => staticText("and");
export const pNull = () => staticText("p_null");
export const na2SsFilters = () => staticText("NA2_SS_Filters");
export const na2SsCoils = () => staticText("NA2_SS_Coils");
export const na2SsHumidifier = () => staticText("NA2_SS_Humidifier");
export const na2SsAirflows = () => staticText("NA2_SS_Airflows");
export const na2SsSize1 = () => staticText("NA2_SS_Size_1");
export const na2SsSize2 = () => staticText("NA2_SS_Size_2");
export const na2SsEmpty1 = () => staticText("NA2_SS_Empty_1");
export const na2SsEmpty2 = () => staticText("NA2_SS_Empty_2");
export const na2SsMax = () => staticText("NA2_SS_Max");
export const na2SsNoMax = () => staticText("NA2_SS_NoMax");
export const na2SsFilter1 = () => staticText("NA2_SS_Filter_1");
export const na2SsFilter2 = () => staticText("NA2_SS_Filter_2");
export const na2SsAbsFilter1 = () => staticText("NA2_SS_AbsFilter_1");
export const na2SsAbsFilter2 = () => staticText("NA2_SS_AbsFilter_2");
export const na2SsCarFilter1 = () => staticText("NA2_SS_CarFilter_1");
export const na2SsCarFilter2 = () => staticText("NA2_SS_CarFilter_2");
export const na2SsHeatCoil1 = () => staticText("NA2_SS_HeatCoil_1");
export const na2SsCoolCoil1 = () => staticText("NA2_SS_CoolCoil_1");
export const na2SsHumidifier1 = () => staticText("NA2_SS_Humidifier_1");
export const na2SsMinFlow1 = () => staticText("NA2_SS_MinFlow_1");
export const na2SsMinFlow2 = () => staticText("NA2_SS_MinFlow_2");
export const na2SsMaxFlow1 = () => staticText("NA2_SS_MaxFlow_1");
export const na2SsMaxFlow2 = () => staticText("NA2_SS_MaxFlow_2");
export const na2SsBox = () => staticText("NA2_SS_Box");
export const na2SsExternalHeight = () => staticText("NA2_SSExternalHeight");
export const na2SsExternalWidth = () => staticText("NA2_SSExternalWidth");
export const na2SsBaseFrameHeight = () => staticText("NA2_SSBaseFrameHeight");
export const na2SsEurovent = () => staticText("NA2_SS_Eurovent");
export const dimensions = () => staticText("dimensions");
export const dcProjectInput = () => staticText("dc_projectinput");
export const dcRange = () => staticText("dc_range");
export const dcProjectInfo = () => staticText("dc_projectinfo");
export const dcOutdoorSummer = () => staticText("dc_outdoorsummer");
export const dcSuggest = () => staticText("dc_suggest");
export const dcSuggestedUnits = () => staticText("dc_suggestedunits");
export const dcEpxSize = () => staticText("dc_epxsize");
export const projectName = () => staticText("projectname");
export const noAdditionalLabelsAssigned = () =>
  staticText("noadditionallabelsassigned");
export const editAdditionalLabels = () => staticText("editadditionallabels");
export const powerPerUnit = () => staticText("powerperunit");
export const flowPerUnit = () => staticText("flowperunit");
export const unitNominalAirflow = () => staticText("unitnominalairflow");
export const maxPowerPerUnit = () => staticText("maxpowerperunit");
export const requiredNumberOfUnits = () => staticText("requirednumberofunits");
export const suggestedNumberOfUnits = () =>
  staticText("suggestednumberofunits");
export const totalMaxPower = () => staticText("totalmaxpower");
export const configurationName = () => staticText("configurationname");
export const numberOfUnits = () => staticText("numberofunits");
export const unitsDefined = () => staticText("unitsdefined");
export const redundant = () => staticText("redundant");
export const projectIdAlreadyExists = () =>
  staticText("projectidalreadyexists");

export const inputs = () => staticText("inputs");
export const results = () => staticText("results");
export const selectedSystems = () => staticText("selectedsystems");
export const binLocation = () => staticText("binlocation");
export const binSize = () => staticText("binsize");
export const binCases = () => staticText("bincases");
export const binCasesMissing = () => staticText("bincasesmissing");
export const energySumSystemStatus = () => staticText("energysumsystemstatus");
export const noEnergyResultAvailable = () =>
  staticText("noenergyresultavailable");
export const noEnergySumResultAvailable = () =>
  staticText("noenergysumresultavailable");
export const predictedAnnualConsumption = () =>
  staticText("predictedannualconsumption");
export const predictedAnnualEmission = () =>
  staticText("predictedannualemission");
export const predictedAnnualCost = () => staticText("predictedannualcost");
export const predictedAnnualExtra = () => staticText("predictedannualextra");
export const predictedLccCost = () => staticText("predictedlcccost");
export const detailedResults = () => staticText("detailedresults");
export const print = () => staticText("print");
export const viewAs = () => staticText("viewas");
export const list = () => staticText("list");
export const chart = () => staticText("chart");
export const months = () => staticText("months");
export const month = () => staticText("month");
export const bins = () => staticText("bins");
export const binViewStandard = () => staticText("binviewstandard");
export const binViewWithMonths = () => staticText("binviewwithmonths");
export const chartPreset = () => staticText("chartpreset");
export const chartXAxis = () => staticText("chartxaxis");
export const midPointTemp = () => staticText("MidPointTemp");
export const midPointWetTemp = () => staticText("MidPointWetTemp");
export const midPointDewPointTemp = () => staticText("MidPointDewPointTemp");
export const midPointHumidityRatio = () => staticText("midpointhumidityratio");
export const midPointHourly = () => staticText("midpointhourly");
export const midPointSpecificEnthalpy = () =>
  staticText("MidPointSpecificEnthalpy");
export const averageTemp = () => staticText("AverageTemp");
export const humidity = () => staticText("Humidity");
export const averageHumidity = () => staticText("averagehumidity");
export const averageSpecificEnthalpy = () =>
  staticText("averagespecificenthalpy");
export const returnTemp = () => staticText("returnTemp");
export const returnHumidity = () => staticText("returnHumidity");
export const targetTemp = () => staticText("targetTemp");
export const targetHumidity = () => staticText("targetHumidity");
export const time = () => staticText("Time");
export const exportBinsAsStandard = () => staticText("exportbinsasstandard");
export const exportBinsWithMonths = () => staticText("exportbinswithmonths");
export const januaryHours = () => staticText("januaryhours");
export const februaryHours = () => staticText("februaryhours");
export const marchHours = () => staticText("marchhours");
export const aprilHours = () => staticText("aprilhours");
export const mayHours = () => staticText("mayhours");
export const juneHours = () => staticText("junehours");
export const julyHours = () => staticText("julyhours");
export const augustHours = () => staticText("augusthours");
export const septemberHours = () => staticText("septemberhours");
export const octoberHours = () => staticText("octoberhours");
export const novemberHours = () => staticText("novemberhours");
export const decemberHours = () => staticText("decemberhours");
export const wind = () => staticText("Wind");
export const binPressure = () => staticText("BinPressure");
export const dataType = () => staticText("datatype");
export const binOn = () => staticText("binon");
export const energyRoofTemperatureCompensation = () =>
  staticText("energy_rooftemperaturecompensation");
export const energyRoofTemperatureCompensationYes = () =>
  staticText("energy_rooftemperaturecompensation_yes");
export const energyRoofTemperatureCompensationNo = () =>
  staticText("energy_rooftemperaturecompensation_no");
export const temperature = () => staticText("temperature");
export const screens = () => staticText("screens");
export const processingsteps = () => staticText("processingsteps");
export const systemDiagram = () => staticText("SystemDiagram");
export const mechanicalSketch = () => staticText("MechanicalSketch");
export const pidDiagram = () => staticText("PidDiagram");
export const systemSize = () => staticText("SystemSize");
export const systemPrintout = () => staticText("SystemPrintout");
export const systemDocuments = () => staticText("SystemDocuments");
export const psychrometricsChart = () => staticText("PsychrometricsChart");
export const adjustPrice = () => staticText("AdjustPrice");
export const energy = () => staticText("Energy");
export const mCompare = () => staticText("MCompare");
export const solidContactBuilder = () => staticText("SolidContactBuilder");
export const wiringDiagramBuilder = () => staticText("wiringDiagramBuilder");
export const revitBuilder = () => staticText("RevitBuilder");
export const climatixControlFile = () => staticText("ClimatixControlFile");
export const propertyGroup = () => staticText("PropertyGroup");
export const properties = () => staticText("properties");
export const systemAccessories = () => staticText("SystemAccessories");
export const accessories = () => staticText("accessories");
export const enhanceComponent = () => staticText("enhancecomponent");
export const other = () => staticText("other");
export const componentAlternatives = () => staticText("componentalternatives");
export const notesAndDocuments = () => staticText("notesanddocuments");
export const airLocations = () => staticText("airlocations");
export const systemRevisionActions = () => staticText("systemrevisionactions");
export const systemActions = () => staticText("systemactions");
export const duplicate = () => staticText("duplicate");
export const addNew = () => staticText("addnew");
export const filters = () => staticText("filters");
export const serviceCalculationFailed = () =>
  staticText("serviceCalculationFailed");
export const saveAndCalculate = () => staticText("saveandcalculate");
export const calculateEnergyForThisSystem = () =>
  staticText("calculateenergyforthissystem");
export const systemIsLockedCantCalculateEnergy = () =>
  staticText("systemislockedcantcalculateenergy");
export const energyInput = () => staticText("energyinput");
export const cost = () => staticText("cost");
export const emissions = () => staticText("emissions");
export const costAndEmissionsInformation = () =>
  staticText("costAndEmissionsInformation");
export const annualOperationInputs = () => staticText("annualOperationinputs");
export const annualOperatingCases = () => staticText("annualoperatingcases");
export const operationTime = () => staticText("operationTime");
export const generateBinCases = () => staticText("generatebincases");
export const calculateEnergyCompareForThisSystem = () =>
  staticText("calculateenergycompareforthissystem");
export const sortBy = () => staticText("sortBy");
export const latest = () => staticText("latest");
export const oldest = () => staticText("oldest");
export const openInNewTab = () => staticText("openInNewTab");
export const openInCurrentTab = () => staticText("openincurrenttab");
export const copyOfSystemCompletedMsg = () =>
  staticText("copyofsystemcompleted");
export const revisionCompletedMsg = () => staticText("revisoncompleted");
export const calculationfailed = () => staticText("calculationfailed");
export const electricity = () => staticText("electricity");
export const gasHeaters = () => staticText("gasheaters");
export const chilledWaterSystem = () => staticText("chilledwatersystem");
export const hotWaterSystem = () => staticText("hotWaterSystem");
export const steamHeatSystem = () => staticText("steamHeatSystem");
export const humidifier = () => staticText("humidifier");
export const water = () => staticText("water");
export const sewage = () => staticText("sewage");
export const dhCapacityInfo = () => staticText("dhCapacityInfo");
export const calculate = () => staticText("calculate");
export const CustomPressure = () => staticText("custompressure");
export const validateSelections = () => staticText("validateselections");
export const calculateDesign = () => staticText("calculatedesign");
export const validateDesign = () => staticText("validatedesign");
export const calculateRatingCases = () => staticText("calculateratingcases");
export const generateCasings = () => staticText("generatecasings");
export const validateFinal = () => staticText("validatefinal");
export const calculateSound = () => staticText("calculatesound");
export const calculatePrice = () => staticText("calculateprice");
export const calculateEnergy = () => staticText("calculateenergy");
export const saveAndCalculateEnergy = () =>
  staticText("saveandcalculateenergy");
export const validateMcompare = () => staticText("validatemcompare");
export const calculateMcompare = () => staticText("calculatemcompare");
export const lockRevision = () => staticText("lockrevision");
export const revisions = () => staticText("revisions");
export const addToFavorites = () => staticText("addtofavorites");
export const removeFromFavorites = () => staticText("removefromfavorites");
export const calcstepAlreadyCompleted = () =>
  staticText("calcstep_alreadycompleted");
export const message = () => staticText("message");
export const permissionDenied = () => staticText("permissiondenied");
export const moreMessages = () => staticText("MoreMessages");
export const saveChangesQuestion = () => staticText("savechangesquestion");
export const saveChangesToQuestion = (displayName: string) =>
  staticTextWithParams("savechangestoquestion", displayName);
export const addComponent = () => staticText("addcomponent");
export const systemInputs = () => staticText("systeminputs");
export const engineeringOutputs = () => staticText("engineeringoutputs");
export const partsAndAccessories = () => staticText("partsandaccessories");
export const operatingCaseName = (caseName: string) => staticText(caseName);
export const move = () => staticText("move");
export const exchangeComponent = () => staticText("exchangeComponent");
export const cannotExchangeComponent = () =>
  staticText("cannotexchangecomponentmsg");
export const systemMessages = () => staticText("SystemMessages");
export const revisionLockedMsg = () => staticText("revisionlockedmsg");
export const locked = () => staticText("locked");
export const currencyHasBeenChanged = () =>
  staticText("currencyhasbeenchanged");
export const diagram = () => staticText("diagram");
export const mechanicalView = () => staticText("mechanicalview");
export const notImplemented = () => staticText("notImplemented");
export const components = () => staticText("components");
export const casings = () => staticText("casings");
export const systemName = () => staticText("systemname");
export const preparedFor = () => staticText("preparedfor");
export const salesOrder = () => staticText("salesorder");
export const endUser = () => staticText("enduser");
export const selection = () => staticText("selection");
export const salesOrganisation = () => staticText("salesorganisation");
export const quantityShort = () => staticText("quantityShort");
export const partPrice = () => staticText("partprice");
export const validTime = () => staticText("validtime");
export const systemCalculationIsValidUntil = () =>
  staticText("systemcalculationisvaliduntil");
export const systemCalculation = () => staticText("systemcalculation");
export const baseComponents = () => staticText("basecomponents");
export const validityWarning = () => staticText("ValidityWarning");
export const extendedInformation = () => staticText("extendedInformation");
export const marginCost = () => staticText("margincost");
export const masterMarginCost = () => staticText("mastermargincost");
export const margin = () => staticText("margin");
export const refCost = () => staticText("refcost");
export const consolidatedMargin = () => staticText("consolidatedmargin");
export const mczFactoryPrice = () => staticText("mczfactoryprice");
export const transferPrice = () => staticText("transferprice");
export const masterTransferPrice = () => staticText("mastertransferprice");
export const listPrice = () => staticText("listprice");
export const masterListPrice = () => staticText("masterlistprice");
export const productText = () => staticText("product");
export const masterCurrency = () => staticText("mastercurrency");
export const masterSalesOrganisation = () =>
  staticText("masterSalesOrganisation");
export const mCompareSystem = () => staticText("mcomparesystem");
export const isInPricings = () => staticText("isinpricings");
export const systemNotLocked = () => staticText("systemnotlocked");
export const systemExpiredRecalculate = () =>
  staticText("systemexpiredrecalculate");
export const systemValidityExpired = () => staticText("systemvalidityexpired");
export const calculatingEnergy = () => staticText("calculatingenergy");
export const showResultFor = () => staticText("showresultfor");
export const noPerformanceAvailable = () =>
  staticText("noperformanceavailable");
export const resultsSummary = () => staticText("resultssummary");
export const opCaseResults = () => staticText("opcaseresults");
export const calculateToShowDiagram = () =>
  staticText("calculatetoshowdiagram");
export const componentCalculationType = (type: string) =>
  staticText(
    type
      .toLowerCase()
      .replace(/([a-z]+)_?/g, (_, g1) => g1[0].toUpperCase() + g1.substr(1))
  );
export const cannotDeleteComponent = () =>
  staticText("cannotdeletecomponentmsg");
export const deleteComponent = () => staticText("deletecomponentmsg");
export const internalNotes = () => staticText("internalnotes");
export const externalNotes = () => staticText("externalnotes");
export const documents = () => staticText("documents");
export const mlcBuilding = () => staticText("mlcbuilding");
export const mlcUsageStatistics = () => staticText("mlcusagestatistics");
export const mlcOutdoorsClimate = () => staticText("mlcoutdoorclimate");
export const mlcDesiredIndoorsClimate = () =>
  staticText("mlcdesiredindoorsclimate");
export const mlcUsecases = () => staticText("mlcusecases");
export const mlcSystemAndBuildingConfig = () =>
  staticText("mlcsystemandbuildingconfig");
export const mlcSystemOperationtime = () =>
  staticText("mlcsystemoperationtime");
export const mlcBuildingExtended = () => staticText("mlcbuildingextended");
export const mlcApplicationTypeInfo = () =>
  staticText("mlcapplicationtypeinfo");
export const mlcApplicationType = () => staticText("mlcapplicationtype");
export const mlcNoTargethum = () => staticText("mlcnotargethum");
export const mlcNoTargetTemp = () => staticText("mlcnotargettemp");
export const mlcApplicationTypes = (type: number) =>
  staticText("mlcapplicationtype_" + type);
export const mlcSystemConfiguration = () =>
  staticText("mlcsystemconfiguration");
export const mlcHasToBeLocked = () => staticText("mlchastobelocked");
export const mlcSystemConfigurationTypes = (type: number) =>
  staticText("mlcsystemconfiguration_" + type);
export const mlcSystemOperationTime = () =>
  staticText("mlcsystemoperationtime");
export const mlcNoSystemsconnected = () => staticText("mlcnosystemsconnected");
export const mlcAddMoistureAndHeaTypes = () =>
  staticText("mlcaddmoistureandheattypes");
export const mlcNoLabelsAssigned = () => staticText("mlcnolabelsassigned");
export const mlcLoadsWithMoisture = () => staticText("mlcloadswithmoisture");
export const mlcMinTargetTemp = () => staticText("mlcmintargettemp");
export const mlcMaxTargetTemp = () => staticText("mlcmaxtargettemp");
export const mlcConstantTargetTemp = () => staticText("mlcconstanttargettemp");
export const mlcLoadsWithHeat = () => staticText("mlcloadswithheat");
export const mlcLoadsWithBoth = () => staticText("mlcloadswithboth");
export const mlcCreateWithSysSel = () => staticText("mlcCreateWithSysSel");
export const mlcCreateSystemFromopc = () =>
  staticText("mlccreatesystemfromopc");
export const mlcBinDataLocation = () => staticText("mlcbindatalocation");
export const mlclatestRevNeedsToBeLocked = () =>
  staticText("mlclatestrevneedstobelocked");
export const mlcCreateNewRevision = () => staticText("mlccreatenewrevision");
export const mlcAssginLabels = () => staticText("mlcassignLabels");
export const mlcDelete = () => staticText("mlcdelete");
export const mlcCopyLatestRevision = () => staticText("mlccopylatestrevision");
export const mlcTransferToUser = () => staticText("mlctransfertouser");
export const mlcRename = () => staticText("mlcrename");
export const mlcAccesControl = () => staticText("mlcaccesscontrol");
export const mlcOk = () => staticText("mlcok");
export const mlcviewAs = () => staticText("mlcviewas");
export const mlcReportoptions = () => staticText("mlcreportOptions");
export const mlcHeaderLabel = () => staticText("mlcheaderlabel");
export const mlcPapersize = () => staticText("mlcpapersize");
export const mlcLanguage = () => staticText("mlclanguage");
export const mlcHasNotBeenCalculatedError = () =>
  staticText("mlchasnotbeenbalculatederror");
export const mlcMoistureHeatLoadTypes = () =>
  staticText("mlcmoistureheatloadtypes");
export const mlcSelectDeselectHeatLoads = () =>
  staticText("mlcselectdeselectheatloads");
export const mlcSelectDeselectMoistureLoads = () =>
  staticText("mlcselectdeselectmoistureloads");
export const newVersionMessage = () => staticText("newversionmessage");
export const cannotAddProduct = (productId: string) =>
  curlyTranslate(
    `{product} "{${productId}}" {cannotaddproduct}`,
    productId.substring(0, 3)
  );
export const perfParam = (pp: string) => staticText("pp_" + pp);
export const specProp = (sp: string, systemTypeId: string) =>
  staticText("s_" + systemTypeId + "_" + sp, systemTypeId);
export const shouldBeBetweenMinMax = (min: string, max: string) =>
  staticTextWithParams("ShouldBebetweenminmax", min, max);
export const shouldBeEquals = (left: string, right: string) =>
  staticTextWithParams("ShouldBeEquals", left, right);
export const shouldBeNotEquals = (left: string, right: string) =>
  staticTextWithParams("ShouldBeNotEquals", left, right);
export const shouldBeGreater = (left: string, right: string) =>
  staticTextWithParams("ShouldBeGreater", left, right);
export const shouldBeGreaterOrEqual = (left: string, right: string) =>
  staticTextWithParams("ShouldBeGreaterOrEqual", left, right);
export const shouldBeLess = (left: string, right: string) =>
  staticTextWithParams("ShouldBeLess", left, right);
export const shouldBeLessOrEqual = (left: string, right: string) =>
  staticTextWithParams("ShouldBeLessOrEqual", left, right);
export const filterOptionsText = (propertyName: string) => {
  if (propertyName === "pno") {
    return staticText("pricingNO");
  }

  return staticText(propertyName);
};
export const deleteAllSelectedMsg = (systems: number) =>
  staticTextWithParams("deleteallselectedmsg", systems.toString());
export const globalPropertyName = (propertyName: string) =>
  staticText(propertyName);

export const globalPropertyDescription = (propertyName: string) =>
  staticText(`${propertyName}_description`);
export const dynamicText = (text: string, systemTypeId?: string) =>
  staticText(text, systemTypeId);
export const errorMessage = (message: string) => staticText(message);
export const pricingArticle = (articleName: string) =>
  staticText("article_" + articleName);
export const processingToolTip = (mode: string) =>
  staticText("calcstep_" + mode);
export const fileFormat = (fileFormat: string) =>
  staticText("fileformat_" + fileFormat);
export const calculationProgress = (state: string) =>
  staticText("calculationprogress_" + state);
export function languageId(id: string): LanguageIdText {
  return {
    type: "LanguageIdText",
    languageId: id
  };
}
export const functionType = (functionTypeTextId: string) =>
  staticText(functionTypeTextId);
export function product(productId: string): ProductText {
  return {
    type: "ProductText",
    productId
  };
}
export function systemTypeProperty(
  systemTypeId: string,
  propertyName: string
): SystemTypePropertyText {
  return {
    type: "SystemTypePropertyText",
    systemTypeId,
    propertyName
  };
}
export function systemTypePropertyValue(
  systemTypeId: string,
  propertyName: string,
  propertyValue: number
): SystemTypePropertyValueText {
  return {
    type: "SystemTypePropertyValueText",
    systemTypeId,
    propertyName,
    propertyValue
  };
}
export function productProperty(
  productId: string,
  propertyName: string
): ProductPropertyText {
  return {
    type: "ProductPropertyText",
    productId,
    propertyName
  };
}
export function productPropertyValue(
  productId: string,
  propertyName: string,
  propertyValue: number
): ProductPropertyValueText {
  return {
    type: "ProductPropertyValueText",
    productId,
    propertyName,
    propertyValue
  };
}
export function reportParam(
  paramName: string,
  systemTypeId: string
): ReportParamText {
  return {
    type: "ReportParamText",
    paramName,
    systemTypeId
  };
}
export function reportParamValue(
  paramName: string,
  paramValue: string,
  systemTypeId: string
): ReportParamValueText {
  return {
    type: "ReportParamValueText",
    paramName,
    paramValue,
    systemTypeId
  };
}
export function systemTypeIdText(systemId: string): SystemIdText {
  return {
    type: "SystemIdText",
    systemId
  };
}
export function tabTitleText(key: string): TabTitleText {
  return {
    type: "TabTitleText",
    key
  };
}
export function createTranslateTodoText(textId: string): TranslateTodoText {
  return {
    type: "TranslateTodoText",
    textId
  };
}
export function curlyTranslate(text: string, systemTypeId: string): CurlyText {
  return {
    type: "CurlyText",
    text,
    systemTypeId
  };
}
function staticText(textId: string, systemTypeId?: string): StaticText {
  return {
    type: "StaticText",
    textId,
    systemTypeId
  };
}
function staticTextWithParams(
  textId: string,
  ...parameters: Array<string>
): StaticTextWithParameters {
  return {
    type: "StaticTextWithParameters",
    textId,
    parameters
  };
}

// tslint:disable-next-line
