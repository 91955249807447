import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setInitialSettingsMutation = gql`
  mutation SetInitialSettings(
    $amountProfileId: String!
    $userSettingsCulture: UserSettingsCultureInputType!
    $userSettingsClimate: UserSettingsClimateInputType!
  ) {
    setInitialSettings(
      amountProfileId: $amountProfileId
      userSettingsCulture: $userSettingsCulture
      userSettingsClimate: $userSettingsClimate
    ) {
      ...CompleteUserSettings
    }
  }
  ${Fragments.completeUserSettings}
`;
