import * as React from "react";
import { MessagePart } from "@genesys/shared/lib/components-messages";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { getValue } from "@genesys/shared/lib/product-properties";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValue } from "@genesys/property";
import { Format, UnitsFormat } from "@genesys/uom";

export interface FormattedMessageProps {
  readonly errorCode: number;
  readonly parts: ReadonlyArray<MessagePart>;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
}

const fieldGroup = "Errors";

export function FormattedMessage({
  parts,
  errorCode,
  getAmountFormat
}: FormattedMessageProps): JSX.Element {
  const jsxElements = parts.map((p, index) => {
    const reactKey = `${index}`;
    switch (p.kind) {
      case "MessagePartText": {
        return <React.Fragment key={reactKey}>{p.text} </React.Fragment>;
      }
      case "MessagePartAmount": {
        const fieldName = `${errorCode}_${p.propertyName}`;
        if (getAmountFormat) {
          const amountFormat = getAmountFormat(fieldGroup, fieldName, p.amount);
          const value = getValue(
            PropertyValue.fromAmount(p.amount),
            amountFormat
          );
          const unitFormat = Format.getUnitFormat(
            amountFormat.unit,
            UnitsFormat
          );
          return (
            <React.Fragment key={reactKey}>
              {value} {unitFormat?.label}{" "}
            </React.Fragment>
          );
        } else {
          return <></>;
        }
      }
      default: {
        exhaustiveCheck(p);
      }
    }
  });

  return <span>{jsxElements}</span>;
}
