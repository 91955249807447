import { isDesktop } from "react-device-detect";
import { hasPermissionForMlc } from "../moisture-load-calculation";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../shared-state";

export function getPermissions(sharedState: SharedState.State) {
  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  const canSeePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );

  const canSeeMoistureload = hasPermissionForMlc(
    sharedState.user.applicationClaims
  );

  const canSeeSystemSelectionGuide =
    (Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseSystemSelectionGuide
      )) &&
    isDesktop;

  const canSeeMRC =
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canSeeMRC
    ) && isDesktop;

  return {
    canUsePricing,
    canSeePricing,
    canSeeMoistureload,
    canSeeSystemSelectionGuide,
    canSeeMRC
  };
}
