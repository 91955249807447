import * as React from "react";
import { Quantity } from "@genesys/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as Contexts from "../contexts";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";

interface Props {
  readonly children: React.ReactNode;
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly fieldGroup: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly layoutRendererExtraProps: GenesysPropertiesSelectorTypes.LayoutRendererExtraProps;
  readonly productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>;
  readonly onQuantityChange: (
    propertyName: string,
    quantity: Quantity.Quantity
  ) => void;
  readonly valueSources:
    | {
        readonly [
          propertyName: string
        ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
      }
    | undefined;
  readonly groupFilter: ReadonlyArray<string>;
  readonly showGroups: boolean;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly translatePropertyDescription: (propertyName: string) => string;
  readonly translatePropertyValueDescription: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly fieldNameMap: { readonly [key: string]: string };
}

export class ContextProvider extends React.Component<
  Props,
  Contexts.PropertiesSelectorContextValue
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activatedQuantitySelectors: props.activatedQuantitySelectors,
      conversionParametersMap: createConversionParametersMap(
        props.productProperties
      ),
      fieldGroup: props.fieldGroup,
      filterPrettyPrint: props.filterPrettyPrint,
      getAmountFormat: props.getAmountFormat,
      layoutRendererExtraProps: props.layoutRendererExtraProps,
      onQuantityChange: props.onQuantityChange,
      valueSources: props.valueSources,
      groupFilter: new Set(props.groupFilter),
      showGroups: props.showGroups,
      translatePropertyValue: props.translatePropertyValue,
      fieldNameMap: props.fieldNameMap
    };
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    nextProps: Props,
    _prevState: Contexts.PropertiesSelectorContextValue
  ): Contexts.PropertiesSelectorContextValue {
    return {
      activatedQuantitySelectors: nextProps.activatedQuantitySelectors,
      conversionParametersMap: createConversionParametersMap(
        nextProps.productProperties
      ),
      fieldGroup: nextProps.fieldGroup,
      filterPrettyPrint: nextProps.filterPrettyPrint,
      getAmountFormat: nextProps.getAmountFormat,
      layoutRendererExtraProps: nextProps.layoutRendererExtraProps,
      onQuantityChange: nextProps.onQuantityChange,
      valueSources: nextProps.valueSources,
      groupFilter: new Set(nextProps.groupFilter),
      showGroups: nextProps.showGroups,
      translatePropertyValue: nextProps.translatePropertyValue,
      fieldNameMap: nextProps.fieldNameMap
    };
  }

  render() {
    return (
      <Contexts.propertiesSelectorContext.Provider value={this.state}>
        {React.Children.only(this.props.children)}
      </Contexts.propertiesSelectorContext.Provider>
    );
  }
}

function createConversionParametersMap(
  productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>
): Contexts.PropertiesSelectorContextValue["conversionParametersMap"] {
  const map = productProperties.reduce(
    (
      soFar: {
        // tslint:disable-next-line:readonly-keyword
        [key: string]: QuantityConversion.ConversionParameters | undefined;
      },
      property
    ) => {
      soFar[property.name] = property.conversionParameters;

      return soFar;
    },
    {}
  );

  return map;
}
