import * as React from "react";
import * as PropertiesSelector from "../react-properties-selector";
import * as Contexts from "../contexts";
import { GroupRenderer } from "./group-renderer";
import { createSelectorInfoMap } from "../genesys-properties-selector-helpers";

export const stackedWithGroupsLayoutRenderer = (
  props: PropertiesSelector.LayoutRendererProps
): JSX.Element => {
  const selectorInfoMap = createSelectorInfoMap(props.selectors);
  return (
    <Contexts.propertiesSelectorContext.Consumer>
      {({
        activatedQuantitySelectors,
        conversionParametersMap,
        filterPrettyPrint,
        onQuantityChange,
        layoutRendererExtraProps,
        showGroups,
        translatePropertyValue
      }) => {
        if (layoutRendererExtraProps.sections.length === 0) {
          return <div />;
        }

        const groups = layoutRendererExtraProps.sections[0].groups.map(
          group => (
            <GroupRenderer
              key={`${layoutRendererExtraProps.uniqueId}-${group.key}`}
              fieldGroup={layoutRendererExtraProps.fieldGroup}
              filterPrettyPrint={filterPrettyPrint}
              renderGroup={group}
              showGroups={showGroups}
              hidePropertyNames={layoutRendererExtraProps.hidePropertyNames}
              rowRendererType={{
                type: "StandardRowRenderer",
                translatePropertyValue: translatePropertyValue
              }}
              selectorInfoMap={selectorInfoMap}
              activatedQuantitySelectors={activatedQuantitySelectors}
              onQuantityChange={onQuantityChange}
              conversionParametersMap={conversionParametersMap}
            />
          )
        );
        return <div>{groups}</div>;
      }}
    </Contexts.propertiesSelectorContext.Consumer>
  );
};
