import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
// import { PropertyValueSet } from "@genesys/property";
// import { Icon } from "@genesys/ui-elements";
import { LoadCreatorFunc } from "../types";
import { createLoad } from "./load-creator";
import * as DiffusionThroughMaterials from "../../../tools/moisture-load-rules/load-definititions/diffusion-through-materials";
import * as EmissionFromCombustion from "../../../tools/moisture-load-rules/load-definititions/emission-from-combustion";
import * as EmissionFromPeople from "../../../tools/moisture-load-rules/load-definititions/emission-from-people";
import * as EvaporationFromMaterials from "../../../tools/moisture-load-rules/load-definititions/evaporation-from-materials";
import * as EvaporationOfWater from "../../../tools/moisture-load-rules/load-definititions/evaporation-of-water";
import * as HeatTransmission from "../../../tools/moisture-load-rules/load-definititions/heat-transmission";
import * as IntentionalVentilation from "../../../tools/moisture-load-rules/load-definititions/intentional-ventilation";
import * as UnintentionalVentilation from "../../../tools/moisture-load-rules/load-definititions/unintentional-ventilation";
import * as StaticMoistureLoad from "../../../tools/moisture-load-rules/load-definititions/static-moisture-load";
import * as StaticHeatLoad from "../../../tools/moisture-load-rules/load-definititions/static-heat-load";
import * as OpenDoorLoad from "../../../tools/moisture-load-rules/load-definititions/open-door";

export const loadMapCreator: {
  readonly [loadName in MoistureHeatLoadTypeEnum]: LoadCreatorFunc;
} = {
  [MoistureHeatLoadTypeEnum.HEAT_TRANSMISSION]: createLoad(
    HeatTransmission.sourceName,
    HeatTransmission.Icon,
    HeatTransmission.getVisibility,
    HeatTransmission.getMoistureLoadSelectionVisibility,
    HeatTransmission.getHeatLoadSelectionVisibility,
    HeatTransmission.getPropertyUpdates,
    {
      getDefaultOperationTime: HeatTransmission.getDefaultOperationTime
    },
    HeatTransmission.title,
    HeatTransmission.useHeatProperty,
    HeatTransmission.useMoistProperty,
    // HeatTransmission.useLoadKey,
    HeatTransmission.description,
    HeatTransmission.properties,
    HeatTransmission.manualInputProperty,
    HeatTransmission.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.STATIC_MOISTURE_LOAD]: createLoad(
    StaticMoistureLoad.sourceName,
    StaticMoistureLoad.Icon,
    StaticMoistureLoad.getVisibility,
    StaticMoistureLoad.getMoistureLoadSelectionVisibility,
    StaticMoistureLoad.getHeatLoadSelectionVisibility,
    StaticMoistureLoad.getPropertyUpdates,
    {
      getDefaultOperationTime: StaticMoistureLoad.getDefaultOperationTime
    },
    StaticMoistureLoad.title,
    StaticMoistureLoad.useHeatProperty,
    StaticMoistureLoad.useMoistProperty,
    // StaticMoistureLoad.useLoadKey,
    StaticMoistureLoad.description,
    StaticMoistureLoad.properties,
    StaticMoistureLoad.manualInputProperty,
    StaticMoistureLoad.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.STATIC_HEAT_LOAD]: createLoad(
    StaticHeatLoad.sourceName,
    StaticHeatLoad.Icon,
    StaticHeatLoad.getVisibility,
    StaticHeatLoad.getMoistureLoadSelectionVisibility,
    StaticHeatLoad.getHeatLoadSelectionVisibility,
    StaticHeatLoad.getPropertyUpdates,
    {
      getDefaultOperationTime: StaticHeatLoad.getDefaultOperationTime
    },
    StaticHeatLoad.title,
    StaticHeatLoad.useHeatProperty,
    StaticHeatLoad.useMoistProperty,
    // StaticHeatLoad.useLoadKey,
    StaticHeatLoad.description,
    StaticHeatLoad.properties,
    StaticHeatLoad.manualInputProperty,
    StaticHeatLoad.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.INTENTIONAL_VENTILATION]: createLoad(
    IntentionalVentilation.sourceName,
    IntentionalVentilation.Icon,
    IntentionalVentilation.getVisibility,
    IntentionalVentilation.getMoistureLoadSelectionVisibility,
    IntentionalVentilation.getHeatLoadSelectionVisibility,
    IntentionalVentilation.getPropertyUpdates,
    {
      getDefaultOperationTime: IntentionalVentilation.getDefaultOperationTime
    },
    IntentionalVentilation.title,
    IntentionalVentilation.useHeatProperty,
    IntentionalVentilation.useMoistProperty,
    // IntentionalVentilation.useLoadKey,
    IntentionalVentilation.description,
    IntentionalVentilation.properties,
    IntentionalVentilation.manualInputProperty,
    IntentionalVentilation.overridableProperties,
    []
    // OperationTime.presets.allDay,
  ),
  [MoistureHeatLoadTypeEnum.EMISSION_FROM_PEOPLE]: createLoad(
    EmissionFromPeople.sourceName,
    EmissionFromPeople.Icon,
    EmissionFromPeople.getVisibility,
    EmissionFromPeople.getMoistureLoadSelectionVisibility,
    EmissionFromPeople.getHeatLoadSelectionVisibility,
    EmissionFromPeople.getPropertyUpdates,
    {
      getDefaultOperationTime: EmissionFromPeople.getDefaultOperationTime
    },
    EmissionFromPeople.title,
    EmissionFromPeople.useHeatProperty,
    EmissionFromPeople.useMoistProperty,
    // EmissionFromPeople.useLoadKey,
    EmissionFromPeople.description,
    EmissionFromPeople.properties,
    EmissionFromPeople.manualInputProperty,
    EmissionFromPeople.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.EVAPORATION_OF_WATER]: createLoad(
    EvaporationOfWater.sourceName,
    EvaporationOfWater.Icon,
    EvaporationOfWater.getVisibility,
    EvaporationOfWater.getMoistureLoadSelectionVisibility,
    EvaporationOfWater.getHeatLoadSelectionVisibility,
    EvaporationOfWater.getPropertyUpdates,
    {
      getDefaultOperationTime: EvaporationOfWater.getDefaultOperationTime
    },
    EvaporationOfWater.title,
    EvaporationOfWater.useHeatProperty,
    EvaporationOfWater.useMoistProperty,
    // EvaporationOfWater.useLoadKey,
    EvaporationOfWater.description,
    EvaporationOfWater.properties,
    EvaporationOfWater.manualInputProperty,
    EvaporationOfWater.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.UNINTENDED_VENTILATION]: createLoad(
    UnintentionalVentilation.sourceName,
    UnintentionalVentilation.Icon,
    UnintentionalVentilation.getVisibility,
    UnintentionalVentilation.getMoistureLoadSelectionVisibility,
    UnintentionalVentilation.getHeatLoadSelectionVisibility,
    UnintentionalVentilation.getPropertyUpdates,
    {
      // onOperationTimeUpdate: UnintentionalVentilation.onLoadOperationTimeUpdate
      getDefaultOperationTime: UnintentionalVentilation.getDefaultOperationTime
    },
    UnintentionalVentilation.title,
    UnintentionalVentilation.useHeatProperty,
    UnintentionalVentilation.useMoistProperty,
    // UnintentionalVentilation.useLoadKey,
    UnintentionalVentilation.description,
    UnintentionalVentilation.properties,
    UnintentionalVentilation.manualInputProperty,
    UnintentionalVentilation.overridableProperties,
    []
    // OperationTime.presets.allDay
  ),
  [MoistureHeatLoadTypeEnum.EMISSION_FROM_COMBUSTION]: createLoad(
    EmissionFromCombustion.sourceName,
    EmissionFromCombustion.Icon,
    EmissionFromCombustion.getVisibility,
    EmissionFromCombustion.getMoistureLoadSelectionVisibility,
    EmissionFromCombustion.getHeatLoadSelectionVisibility,
    EmissionFromCombustion.getPropertyUpdates,
    {
      getDefaultOperationTime: EmissionFromCombustion.getDefaultOperationTime
    },
    EmissionFromCombustion.title,
    EmissionFromCombustion.useHeatProperty,
    EmissionFromCombustion.useMoistProperty,
    // EmissionFromCombustion.useLoadKey,
    EmissionFromCombustion.description,
    EmissionFromCombustion.properties,
    EmissionFromCombustion.manualInputProperty,
    EmissionFromCombustion.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.EVAPORATION_FROM_MATERIALS]: createLoad(
    EvaporationFromMaterials.sourceName,
    EvaporationFromMaterials.Icon,
    EvaporationFromMaterials.getVisibility,
    EvaporationFromMaterials.getMoistureLoadSelectionVisibility,
    EvaporationFromMaterials.getHeatLoadSelectionVisibility,
    EvaporationFromMaterials.getPropertyUpdates,
    {
      getDefaultOperationTime: EvaporationFromMaterials.getDefaultOperationTime
    },
    EvaporationFromMaterials.title,
    EvaporationFromMaterials.useHeatProperty,
    EvaporationFromMaterials.useMoistProperty,
    // EvaporationFromMaterials.useLoadKey,
    EvaporationFromMaterials.description,
    EvaporationFromMaterials.properties,
    EvaporationFromMaterials.manualInputProperty,
    EvaporationFromMaterials.overridableProperties,
    []
  ),
  [MoistureHeatLoadTypeEnum.DIFFUSION_THROUGH_MATERIALS]: createLoad(
    DiffusionThroughMaterials.sourceName,
    undefined,
    DiffusionThroughMaterials.getVisibility,
    DiffusionThroughMaterials.getMoistureLoadSelectionVisibility,
    DiffusionThroughMaterials.getHeatLoadSelectionVisibility,
    DiffusionThroughMaterials.getPropertyUpdates,
    {},
    DiffusionThroughMaterials.title,
    DiffusionThroughMaterials.useHeatProperty,
    DiffusionThroughMaterials.useMoistProperty,
    // DiffusionThroughMaterials.useLoadKey,
    DiffusionThroughMaterials.description,
    DiffusionThroughMaterials.properties,
    undefined,
    [],
    []
  ),
  [MoistureHeatLoadTypeEnum.OPEN_DOOR]: createLoad(
    OpenDoorLoad.sourceName,
    OpenDoorLoad.Icon,
    OpenDoorLoad.getVisibility,
    OpenDoorLoad.getMoistureLoadSelectionVisibility,
    OpenDoorLoad.getHeatLoadSelectionVisibility,
    OpenDoorLoad.getPropertyUpdates,
    {
      getDefaultOperationTime: OpenDoorLoad.getDefaultOperationTime
    },
    OpenDoorLoad.title,
    OpenDoorLoad.useHeatProperty,
    OpenDoorLoad.useMoistProperty,
    // DiffusionThroughMaterials.useLoadKey,
    OpenDoorLoad.description,
    OpenDoorLoad.properties,
    OpenDoorLoad.manualInputProperty,
    OpenDoorLoad.overridableProperties,
    []
  )
};
