import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as PsychrometricChart from "../../psychrometric-chart";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { ChartType, BinInfo } from "../types";

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > span {
    margin-right: 8px;
  }
`;

const ImageContainer = styled.div`
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
  margin-bottom: 10px;
  display: inline-block;
`;

const chartTypes: ReadonlyArray<ChartType> = [
  "mollier_SI",
  "mollier_IP",
  "psycrom_SI",
  "psycrom_IP"
];

export function BinChartView({
  sharedState,
  psychrometricChartState,
  info,
  setChartType
}: {
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase>;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;

  readonly sharedState: SharedState.State;
  readonly info: BinInfo;
  readonly setChartType: (chartType: ChartType) => void;
}) {
  if (psychrometricChartState === undefined) {
    return null;
  }
  const translate = sharedState.translate;
  const selectedChartType = info.selectedChartType;
  return (
    <>
      <SubContainer>
        <span>{translate(LanguageTexts.chartPreset())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={chartTypes.map(ct => ({
            value: ct,
            title: translate(LanguageTexts.dynamicText(ct))
          }))}
          value={selectedChartType}
          onChange={e => {
            setChartType(e.target.value as ChartType);
          }}
        />
      </SubContainer>
      <br></br>
      <ImageContainer>
        <PsychrometricChart.PsychrometricChartView
          state={psychrometricChartState}
          sharedState={sharedState}
        />
      </ImageContainer>
    </>
  );
}
