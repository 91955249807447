import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import * as ResultTable from "../../../shared-manager-components/search-result-table";
import * as GraphQLTypes from "../../../graphql-types";
import styled from "styled-components";
import { ResultsRoot } from "../../../shared-manager-components";
import { Dispatch } from "@typescript-tea/core";
import { State, Action, itemsPerPage } from "../../state";
import { ColumnDefinition, RowInfo } from "../../types";
import { getCellContent, getHeaderCellContent } from "./cell-content";
import { P2, OverlayLoader, H3, ActionButton } from "@genesys/ui-elements";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";
import { PagnationComponent } from "../../../shared-manager-components";

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 14px;
  h3 {
    margin: 0 24px 0 12px;
  }
  button {
    margin: 0 4px;
  }
`;

const PagnationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export function ResultTableView({
  queryResult,
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly queryResult:
    | ReadonlyArray<
        GraphQLTypes.MoistureLoadManagerUserQuery["user"]["searchMoistureLoads"]["results"][0]
      >
    | undefined;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!queryResult) {
    return <OverlayLoader />;
  }

  const paginationComponent = (
    <PagnationContainer>
      <PagnationComponent
        sharedState={sharedState}
        currentPage={state.currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={
          state.userQueryResult?.user.searchMoistureLoads.totalResults || 0
        }
        onPageChange={pageNumber => {
          dispatch(Action.changePage(pageNumber));
        }}
      />
    </PagnationContainer>
  );

  const dataColumns = createDataColumnsDef();
  const result = state.userQueryResult?.user.searchMoistureLoads.results || [];
  const selectedMoistureLoadFiles = result.filter(x =>
    x.moistureLoads.find(x => state.selectedMoistureLoadIds.includes(x.id))
  );
  const isAllSelected = result.length === selectedMoistureLoadFiles.length;

  const onDeleteMoistureLoad = () => {
    if (
      selectedMoistureLoadFiles.every(x =>
        x.moistureLoads.every(m => state.selectedMoistureLoadIds.includes(m.id))
      )
    ) {
      return dispatch(
        Action.openModal({
          type: "confirm-ml-delete-modal",
          selectedMoistureLoadFilesID: selectedMoistureLoadFiles.map(x => x.id),
          selectedRevisions: state.selectedMoistureLoadIds.length,
          header: sharedState.translate(LanguageTexts.confirmation())
        })
      );
    }

    return dispatch(
      Action.openModal({
        type: "info-modal",
        header: "info",
        info: "All revisions need to be selected"
      })
    );
  };
  return (
    <ResultsRoot>
      {paginationComponent}
      {state.selectedMoistureLoadIds.length > 0 && (
        <ActionButtonsContainer>
          <H3 weight="bold" color="dark">
            {sharedState.translate(LanguageTexts.actions())}
          </H3>
          <ActionButton kind="delete" onClick={() => onDeleteMoistureLoad()} />
        </ActionButtonsContainer>
      )}
      <ResultTable.Container>
        <ResultTable.TableHeader
          cellValues={dataColumns.map(columnDef =>
            getHeaderCellContent(
              state,
              columnDef.columnName,
              sharedState,
              dispatch
            )
          )}
          headerIsChecked={isAllSelected}
          checkBoxOnClick={() => {
            const allMoistureLoadiDs = result.flatMap(x =>
              x.moistureLoads.map(y => y.id)
            );

            dispatch(
              Action.setSelectedMoistureLoads(
                isAllSelected ? [] : allMoistureLoadiDs
              )
            );
          }}
        />
        {queryResult.map(row => {
          const allRevisionsChecked = row.moistureLoads.every(s =>
            state.selectedMoistureLoadIds.includes(s.id)
          );

          const isRevisionExpanded = state.expandedRevisions.includes(row.id);
          const isActionMenuOpen = state.moistureLoadActionOpenId === row.id;
          const rowInfo: RowInfo = {
            moistureLoadRow: {
              type: "moisture-load-file",
              id: row.id,
              moistureLoadNo: row.moistureLoadNo,
              owner: row.owner?.userName!,
              name: row.name,
              labels: row.labels,
              latestRevision: row.latestMoistureLoad.revisionNo,
              latestRevisionId: row.latestMoistureLoad.id,
              latestRevisionIsLocked:
                row.latestMoistureLoad.status ===
                MoistureLoadStatus.LockSuccess,
              status: row.latestMoistureLoad.status,
              statusDate: row.latestMoistureLoad.statusDate
            },
            isActionMenuOpen,
            isRevisionExpanded
          };

          return (
            <ResultTable.TableBodyRowContainer
              isRevisionExpanded={isRevisionExpanded}
            >
              {createResultTableRow(
                isRevisionExpanded,
                allRevisionsChecked,
                isActionMenuOpen,
                sharedState,
                state,
                dataColumns,
                rowInfo,
                row.moistureLoads,
                dispatch
              )}

              {row.moistureLoads.map(rev => {
                return createRevisionRow(
                  state,
                  sharedState,
                  isRevisionExpanded,
                  dataColumns,
                  rev,
                  row.moistureLoadNo,
                  dispatch
                );
              })}
            </ResultTable.TableBodyRowContainer>
          );
        })}
      </ResultTable.Container>

      {paginationComponent}
    </ResultsRoot>
  );
}

function createDataColumnsDef(): ReadonlyArray<ColumnDefinition> {
  return [
    {
      type: "moisture-load",
      columnName: "m-no"
      // rowStyles: { width: "100px" }
    },
    {
      type: "moisture-load",
      columnName: "actions-menu"
    },
    {
      type: "moisture-load",
      columnName: "moisture-load-name"
      // rowStyles: { maxWidth: "250px" }
    },
    { type: "moisture-load", columnName: "owner" },
    {
      type: "moisture-load",
      columnName: "date-changed"
    },
    { type: "moisture-load", columnName: "status" },
    {
      type: "moisture-load",
      columnName: "labels"
      // rowStyles: { maxWidth: "150px" }
    },
    {
      type: "moisture-load",
      columnName: "revisions"
      // rowStyles: { width: "100px" }
    }
  ];
}

function createResultTableRow(
  isRevisionExpanded: boolean,
  allRevisionsChecked: boolean,
  isActionMenuOpen: boolean,
  sharedState: SharedState.State,
  state: State,
  dataColumns: readonly ColumnDefinition[],
  rowInfo: RowInfo,
  moistureLoads: ReadonlyArray<
    GraphQLTypes.MoistureLoadManagerUserQuery["user"]["searchMoistureLoads"]["results"][0]["moistureLoads"][0]
  >,
  dispatch: (action: Action) => void
) {
  return (
    <ResultTable.TableRow
      revisionsHeader={
        isRevisionExpanded &&
        createRevisionHeader(
          dataColumns.findIndex(x => x.columnName === "m-no") + 1,
          dataColumns.length + 1,
          sharedState
        )
      }
      isRowChecked={allRevisionsChecked}
      cells={dataColumns.map(columnDef =>
        getCellContent(
          state,
          columnDef.columnName,
          sharedState,
          dispatch,
          rowInfo
        )
      )}
      isActionsOpen={isActionMenuOpen}
      checkBoxOnClick={() => {
        dispatch(
          Action.setSelectedMoistureLoads(
            allRevisionsChecked
              ? state.selectedMoistureLoadIds.filter(
                  x => !moistureLoads.map(r => r.id).includes(x)
                )
              : [
                  ...state.selectedMoistureLoadIds,
                  ...moistureLoads.map(x => x.id)
                ]
          )
        );
      }}
    />
  );
}

function createRevisionRow(
  state: State,
  sharedState: SharedState.State,
  isRevisionExpanded: boolean,
  dataColumns: readonly ColumnDefinition[],
  rev: GraphQLTypes.MoistureLoadManagerUserQuery["user"]["searchMoistureLoads"]["results"][0]["moistureLoads"][0],
  moistureLoadNo: number,
  dispatch: (action: Action) => void
) {
  return (
    <ResultTable.RevisionRow
      isActionsOpen={state.moistureLoadActionOpenId === rev.id}
      isRowChecked={state.selectedMoistureLoadIds.includes(rev.id)}
      isRevisionExpanded={isRevisionExpanded}
      cells={dataColumns.map(columnDef =>
        getCellContent(state, columnDef.columnName, sharedState, dispatch, {
          moistureLoadRow: {
            type: "moisture-load-revision",
            id: rev.id,
            name: rev.name,
            moistureLoadNo: moistureLoadNo,
            revisionNo: rev.revisionNo,
            statusDate: rev.statusDate,
            status: rev.status
          },
          isActionMenuOpen: state.moistureLoadActionOpenId === rev.id,
          isRevisionExpanded: false
        })
      )}
      checkBoxOnClick={() => {
        if (state.selectedMoistureLoadIds.includes(rev.id)) {
          return dispatch(
            Action.setSelectedMoistureLoads(
              state.selectedMoistureLoadIds.filter(x => x !== rev.id)
            )
          );
        }

        dispatch(
          Action.setSelectedMoistureLoads([
            ...state.selectedMoistureLoadIds,
            rev.id
          ])
        );
      }}
    />
  );
}

function createRevisionHeader(
  revisionCell: number,
  totalNumberOfcells: number,
  sharedState: SharedState.State
) {
  return (
    <tr>
      {Array(totalNumberOfcells)
        .fill(null)
        .map((_u, ix) => (
          <td>
            {ix === revisionCell ? (
              <P2 color="dark" weight="bold">
                {sharedState.translate(LanguageTexts.revisions())}
              </P2>
            ) : (
              ""
            )}
          </td>
        ))}
    </tr>
  );
}
