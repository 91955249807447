import styled from "styled-components";
import * as React from "react";

type Props = { readonly dummy: string } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

// tslint:disable-next-line:function-name
function UnStyledButton(props: Props): JSX.Element {
  const { dummy, ...restProps } = props;
  return <button {...restProps}>x</button>;
}

// tslint:disable-next-line
export const CloseButton = styled(UnStyledButton)`
  padding: 0;
  padding-bottom: 1.5px;
  margin: 0;
  margin-left: 10px;

  line-height: 0.9;
  width: 15px;

  color: black;

  border: 0;
  border: 1px solid rgb(109, 128, 145);
  border-radius: 4px;

  background: linear-gradient(to top, rgb(208, 214, 219), white 50%);

  font-weight: 100;
  font-size: 14px;

  position: absolute;
  right: 4px;
  top: 3px;

  &:hover {
    background: linear-gradient(to top, rgb(92, 172, 245), white 50%);
  }
`;
