import styled from "styled-components";
import { keyframes } from "styled-components";

const rotate = keyframes`
    from {
        background-position-x: 0px;
    }
    to {
      background-position-x: 1000px;
    }
`;

// tslint:disable-next-line:variable-name
export const LoadingBar = styled.div`
  height: 15px;
  background: repeating-linear-gradient(
    120deg,
    #fff,
    #fff 10px,
    #fff 10px,
    rgb(166, 208, 228) 25px
  );

  animation: ${rotate} 20s linear infinite;

  border: 1px solid rgb(112, 131, 148);
  border-radius: 3px;
`;
