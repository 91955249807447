import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as GraphQLTypes from "../../../../graphql-types";
import { State, Action } from "../../../state";
import { Dispatch } from "@typescript-tea/core";
import {
  S2,
  P1,
  P2,
  GenesysTableSelect,
  GenesysTableOption
} from "@genesys/ui-elements";

import {
  SortBySelectContainer,
  ResultsTopContainer,
  NumberOfSearchResultsContainer,
  SystemsPerPageContainer
} from "../../../elements";

import { SystemsPerEnum } from "../../../types";

export function UpperMenu({
  state,
  sharedState,
  paginationComponent,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly paginationComponent: JSX.Element;
  readonly dispatch: Dispatch<Action>;
}) {
  const systemPerPageOptions =
    state.viewMode === "table"
      ? [
          SystemsPerEnum.sixteen,
          SystemsPerEnum.thirtyTwo,
          SystemsPerEnum.fourtyEight,
          SystemsPerEnum.sixtyFour,
          SystemsPerEnum.oneHundredAndTwentyEight
        ]
      : [
          SystemsPerEnum.six,
          SystemsPerEnum.twelve,
          SystemsPerEnum.eightteen,
          SystemsPerEnum.twentyFour
        ];
  return (
    <>
      <ResultsTopContainer>
        <SystemsPerPageContainer>
          <P1 color="dark" weight="normal">
            {sharedState.translate(LanguageTexts.systemsPerPage())}
            {":"}
          </P1>
          <GenesysTableSelect
            value={state.systemsPerPage}
            onChange={e =>
              dispatch(
                Action.setSystemsPerPage(
                  parseInt(e.target.value, 10) as SystemsPerEnum
                )
              )
            }
          >
            {systemPerPageOptions.map(s => (
              <GenesysTableOption value={s} key={s}>
                {s}
              </GenesysTableOption>
            ))}
          </GenesysTableSelect>
        </SystemsPerPageContainer>

        <SortBySelectContainer>
          <P1 color="dark" weight="normal">
            {sharedState.translate(LanguageTexts.sortBy())}
            {":"}
          </P1>
          {/* <GenesysTableSelect
            value={Number(sortBySelectValue.isDescending)}
            onChange={() => orderByColumn("date-changed")}
          >
            <GenesysTableOption value={1}>
              {sharedState.translate(LanguageTexts.latest())}
            </GenesysTableOption>
            <GenesysTableOption value={0}>
              {sharedState.translate(LanguageTexts.oldest())}
            </GenesysTableOption>
          </GenesysTableSelect> */}
        </SortBySelectContainer>

        {paginationComponent}
      </ResultsTopContainer>
      <NumberOfSearchResultsContainer>
        <P2 weight="bold">
          {state.queryUserResult?.user.searchSystems.totalResults || 0}
        </P2>
        <S2 weight="normal">
          {state.searchParams.scope === GraphQLTypes.SystemSearchScope.MY
            ? sharedState.translate(LanguageTexts.systemsFromMySystems())
            : sharedState.translate(LanguageTexts.systemsFromAllSystems())}
        </S2>
      </NumberOfSearchResultsContainer>
    </>
  );
}
