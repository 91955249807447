import styled from "styled-components";

interface Props {
  readonly useFixedWidth?: boolean;
}
// tslint:disable-next-line
export const DialogContainer = styled.div<Props>`
  display: inline-block;
  border: 2px solid rgb(128, 128, 128);
  border-radius: 3px;

  ${({ useFixedWidth = true }) => useFixedWidth && `width: 400px;`}

  text-align: center;

  margin: 10px 0 10px 0;
`;
