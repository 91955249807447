import * as React from "react";
import * as OperationTimeGen2 from "../operation-time-manager";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Pop } from "@genesys/ui-elements";
import { Edit } from "@genesys/ui-elements";

export function OperationTime({
  operationTimeState,
  readonly,
  sharedState,
  labelRef,
  onClick,
  onClose,
  operationTimeDispatch
}: {
  readonly operationTimeState: OperationTimeGen2.State;
  readonly readonly: boolean;
  readonly labelRef: React.RefObject<HTMLLabelElement>;
  readonly sharedState: SharedState.State;
  readonly onClick: () => void;
  readonly onClose: () => void;
  readonly operationTimeDispatch: Dispatch<OperationTimeGen2.Action>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = () => {
    setAnchorEl(labelRef.current);
    onClick();
  };

  const handleClose = () => {
    onClose();
    setAnchorEl(null);
  };

  return (
    <>
      <span onClick={handleClick}>
        <Edit />
      </span>
      {anchorEl && operationTimeState && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          <OperationTimeGen2.OperationTimeView
            sharedState={sharedState}
            dispatch={operationTimeDispatch}
            readonly={readonly}
            onClose={handleClose}
            operationTime={operationTimeState}
          />
        </Pop>
      )}
    </>
  );
}
