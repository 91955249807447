import * as Constants from "./constants";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { Claim } from "./types";

export interface ApplicationClaims {
  readonly [context: string]: { readonly [name: string]: string[] };
}

export function checkPermission(
  claims: ApplicationClaims,
  name: string,
  context: string = Constants.contexts.genesys
): boolean {
  return hasClaim(claims, name, "true", context);
}

export function hasPermission(
  claims: ApplicationClaims,
  name: string,
  value: string,
  context: string = Constants.contexts.genesys
): boolean {
  return hasClaim(claims, name, value, context);
}

export function checkClaimFilter(
  claims: { readonly [context: string]: { readonly [name: string]: string[] } },
  claimFilter: PropertyFilter.PropertyFilter,
  context: string = Constants.contexts.genesys
): boolean {
  const lowerClaimFilter = PropertyFilter.fromStringOrEmpty(
    claimFilter.text.toLowerCase()
  );
  const usedClaims = PropertyFilter.getReferencedProperties(lowerClaimFilter);
  const variants = getClaimsAsPropertyValues(
    claims[context],
    usedClaims.filter(uc => !!claims[context][uc])
  );

  const starClaims = usedClaims.filter(
    uc => claims[context][uc] && claims[context][uc].some(c => c === "*")
  );

  const starLessClaimFilter = PropertyFilter.fromStringOrEmpty(
    starClaims.reduce((a, b) => {
      const regex = new RegExp(b + '="(.+?)"', "ig");
      return a.replace(regex, "1=1");
    }, lowerClaimFilter.text)
  );

  return (
    usedClaims.length === 0 ||
    variants.some(v => PropertyFilter.isValid(v, starLessClaimFilter))
  );
}

function hasClaim(
  claims: ApplicationClaims,
  name: string,
  value: string,
  context: string
): boolean {
  const lowerValue = value.toLowerCase();
  const lowerContext = context.toLowerCase();
  const lowerName = name.toLowerCase();
  const values = claims[lowerContext] && claims[lowerContext][lowerName];

  return (
    values !== undefined &&
    values.some(
      v =>
        (v.indexOf(";") !== -1 ? v.split(";")[0] : v).toLowerCase() ===
          lowerValue || v === "*"
    )
  );
}

function getClaimsAsPropertyValues(
  claims: { readonly [name: string]: string[] },
  usedClaims: string[]
): Array<PropertyValueSet.PropertyValueSet> {
  if (usedClaims.length === 0) {
    return [];
  }

  const firstClaim = usedClaims[0];
  let variants: Array<PropertyValueSet.PropertyValueSet> = addValuesToSet(
    PropertyValueSet.Empty,
    firstClaim,
    claims[firstClaim]
  );

  const rest = usedClaims.slice(1);
  rest.forEach(uc => {
    let newVariants: Array<PropertyValueSet.PropertyValueSet> = [];
    variants.forEach(
      v => (newVariants = newVariants.concat(addValuesToSet(v, uc, claims[uc])))
    );

    variants = newVariants;
  });

  return variants;
}

function addValuesToSet(
  pvs: PropertyValueSet.PropertyValueSet,
  name: string,
  values: string[]
): PropertyValueSet.PropertyValueSet[] {
  return values.map(v => PropertyValueSet.setText(name, v, pvs));
}

export function toObjectClaims(claims: ReadonlyArray<Claim>): {
  readonly [context: string]: { readonly [name: string]: string[] };
} {
  return claims.reduce(
    (
      soFar: {
        // tslint:disable-next-line: readonly-keyword
        [context: string]: { [name: string]: string[] };
      },
      current: Claim
    ) => {
      const contextLower = current.context.toLowerCase();
      const contextClaims = soFar[contextLower] || {};
      const newContextClaims = {
        ...contextClaims,
        [current.key.toLowerCase()]: current.values
      };
      return {
        ...soFar,
        [contextLower]: newContextClaims
      };
    },
    {}
  ) as {
    readonly [context: string]: { readonly [name: string]: string[] };
  };
}
