import { encodeSvgStringToImgSrc } from "../../svg-string-img-encoder";

export function createEditIconSvg(color: string): string {
  const svgString = `
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>99654155-5F6C-41C0-99A7-D97DF184BBCD</title>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-687.000000, -764.000000)" fill="${color}">
            <g id="Group-4-Copy-3" transform="translate(687.000000, 758.000000)">
                <path d="M9.01508122,10.9994939 L6.99362735,8.97542313 L8.45477471,7.51163315 L10.4754785,9.5349529 L9.01508122,10.9994939 Z M11.5540873,8.49024697 L11.5533372,8.48949593 L9.49963009,6.43313013 C8.94382403,5.87810663 7.98897773,5.85182 7.46242463,6.3813079 L0.7147441,13.1377238 C0.470219439,13.381814 0.318704158,13.7062661 0.287200981,14.0494945 L0.0029223105,17.1813591 C-0.0165796563,17.4029179 0.0621782867,17.6222235 0.219694173,17.7799433 C0.36145847,17.9218911 0.55272776,18 0.749997656,18 C0.773250001,18 0.79575227,17.999249 0.81825454,17.9969958 L3.94606999,17.7123491 C4.28960464,17.6808052 4.61288724,17.5298448 4.85666183,17.2857546 L11.6050924,10.5285877 C12.1511475,9.98032358 12.1278952,9.06554873 11.5540873,8.49024697 L11.5540873,8.49024697 Z" id="Icons/System-Component-Options/Edit"></path>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
