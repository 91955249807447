import styled from "styled-components";
import * as React from "react";

interface TextDialogContainerProps {
  readonly containerWidth: number;
}

// tslint:disable-next-line
const UnStyledContainer: React.FunctionComponent<
  TextDialogContainerProps & React.HTMLProps<HTMLDivElement>
> = props => {
  const { containerWidth, ...unStyledContainerProps } = props;

  return <div {...unStyledContainerProps}>{props.children}</div>;
};

// tslint:disable-next-line
export const TextDialogContainer = styled(UnStyledContainer)`
  display: inline-block;
  border: 2px solid rgb(128, 128, 128);
  border-radius: 3px;

  width: ${props => props.containerWidth}px;

  text-align: center;

  margin: 10px 0 10px 0;
`;
