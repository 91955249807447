import * as GraphQL from "graphql";
import * as GraphQLRequest from "graphql-request";

export async function requestGen2<TResponse, TVariables extends {}>(
  graphqlEndpoint: string,
  query: GraphQL.DocumentNode,
  variables: TVariables,
  includeServerLog: boolean,
  useCalculationWorkers: boolean,
  accessToken: string,
  _sha256: string | undefined
): Promise<TResponse> {
  const minimizedQuery = GraphQL.print(query).replace(/\s+/g, " ");

  if (!minimizedQuery) {
    throw new Error("Could not get query string.");
  }

  const defaultHeaders: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
    ...(includeServerLog
      ? {
          "include-server-log": "true"
        }
      : {}),
    ...(useCalculationWorkers
      ? {
          "use-calculation-workers": "true"
        }
      : {})
  };

  // if (sha256) {
  //   // first try with GET and see if the query is persisted
  //   const response = await fetch(`${graphqlEndpoint}?queryHash=${sha256}`, {
  //     method: "GET",
  //     headers: {
  //       ...defaultHeaders,
  //       "Content-Type": "application/json"
  //     }
  //   });

  //   if (response.ok) {
  //     const responseData = await response.json();
  //     if (!responseData.errors) {
  //       return responseData.data;
  //     }
  //   }

  //   // query hasn't been persisted yet. Make another request with with more information so the server can persist it.
  //   const response2 = await fetch(`${graphqlEndpoint}?queryHash=${sha256}`, {
  //     method: "GET",
  //     headers: {
  //       ...defaultHeaders,
  //       "persisted-query": minimizedQuery,
  //       "persisted-query-variables": JSON.stringify(variables),
  //       "Content-Type": "application/json"
  //     }
  //   });
  //   const responseData2 = await response2.json();
  //   return responseData2.data;
  // } else {
  const client = new GraphQLRequest.GraphQLClient(graphqlEndpoint, {
    headers: defaultHeaders
  });
  const { data, headers, extensions } = await client.rawRequest<TResponse>(
    minimizedQuery,
    variables
  );

  if (extensions) {
    if (extensions.log) {
      setImmediate(() => log(extensions.log));
    }

    const timerLog = headers.get("X-TIMER");
    if (timerLog) {
      setImmediate(() => {
        for (const row of timerLog.split("@@@")) {
          console.log(row);
        }
      });
    }

    if (
      extensions.traceId &&
      minimizedQuery.indexOf("UpdateSystemStatus") > -1
    ) {
      setImmediate(() => {
        console.log(
          "CalculationLogs: " +
            `${window.location.origin}/log-viewer/${extensions.traceId}`
        );
      });
    }
  }

  return data!;
  // }
}

/**
 * This is just a proxy for the request function in the graphql-request package
 * with the added feature that you can also send an AST instead of a string.
 */
export async function request<TResponse, TVariables = {}>(
  query: string | GraphQL.DocumentNode,
  variables: TVariables,
  includeServerLog: boolean,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string }
): Promise<TResponse> {
  // If it is not a string, then get the string from the AST
  const queryString = typeof query === "string" ? query : GraphQL.print(query);
  if (!queryString) {
    throw new Error("Could not get query string.");
  }

  const client = new GraphQLRequest.GraphQLClient(endpoint, {
    credentials: authorization.type === "cookies" ? "include" : "omit",
    headers: {
      ...(authorization.type === "bearer"
        ? { Authorization: `Bearer ${authorization.accessToken}` }
        : {}),
      ...(includeServerLog
        ? {
            "include-server-log": "true"
          }
        : {})
    }
  });
  const { data, /* headers, */ extensions } =
    await client.rawRequest<TResponse>(queryString, variables || {});

  if (extensions && extensions.log) {
    setImmediate(() => log(extensions.log));
  }

  return data!;
}

function log(rows: ReadonlyArray<string>): void {
  // Severity: 0 = Info, Below 100 = Warning, Above 100 = Error.
  for (const row of rows) {
    const [severity, category, message] = row.split("@@@");
    const formattedMessage = `[${category}]: ${message}`;
    const severityParsed = parseInt(severity, 10);

    // Only use console.error for messages from Genesys namespaces
    // We don't have control over EventIds in external libraries
    if (!category.startsWith("Genesys")) {
      console.info(formattedMessage);
      continue;
    }

    if (severityParsed <= 100) {
      console.info(formattedMessage);
    } else {
      console.error(formattedMessage);
    }
  }
}
