import * as React from "react";
import * as GenesysPropertiesSelector from "../../genesys-properties-selector";
import * as GenesysPropertiesSelectorRenderers from "../../genesys-properties-selector-renderers";
import * as GenesysPropertiesSelectorTypes from "../../genesys-properties-selector-types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { PropertyValueSet } from "@genesys/property";
import * as KnownProperties from "../known-properties";
import * as KnownUserSettings from "../known-user-settings";
import * as GraphQLTypes from "@genesys/graphql-types";
import { FullLocation } from "../types";

export interface Props {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly productDefinitions: Array<GenesysPropertiesSelectorTypes.Property>;
  readonly readOnlyProperties: Array<string>;
  readonly fullLocation: FullLocation;
  readonly locationNamesByPropertyName: {
    readonly country: Array<string>;
    readonly region: Array<string>;
    readonly location: Array<string>;
  };
  readonly countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >;
  readonly onLocationChange: (
    pvs: PropertyValueSet.PropertyValueSet,
    locationId: string
  ) => void;
  readonly userSettingLocation: string;
}

// tslint:disable-next-line:variable-name
export const LocationComponentAshrae = (props: Props) => {
  const climateDataDialogTranslation = {
    propertyName: (propertyName: string, translate: LanguageTexts.Translate) =>
      translate(
        LanguageTexts.globalPropertyName("climatedatadialog_" + propertyName)
      ),
    propertyValue: (
      propertyName: string,
      propertyValue: number,
      translate: LanguageTexts.Translate
    ) => {
      const names =
        props.locationNamesByPropertyName[
          propertyName as keyof typeof props.locationNamesByPropertyName
        ];

      return names
        ? names[propertyValue]
        : translate(
            LanguageTexts.globalPropertyName(
              "climatedatadialog_" + propertyName + "_" + propertyValue
            )
          );
    }
  };

  return (
    <GenesysPropertiesSelector.PropertiesSelectorContainer
      selectedProperties={props.selectedProperties}
      productProperties={props.productDefinitions}
      screenAmountProfileFieldGroup={"ClimateDataLocationDialog"}
      onChange={pvs => {
        const countryHasChanged =
          PropertyValueSet.getInteger(
            KnownUserSettings.selectedCountryIndex,
            props.selectedProperties
          ) !==
          PropertyValueSet.getInteger(
            KnownUserSettings.selectedCountryIndex,
            pvs
          );
        const regionHasChanged =
          PropertyValueSet.getInteger(
            KnownUserSettings.selectedRegionIndex,
            props.selectedProperties
          ) !==
          PropertyValueSet.getInteger(
            KnownUserSettings.selectedRegionIndex,
            pvs
          );

        // Clear the region  when country changes.
        if (countryHasChanged) {
          pvs = PropertyValueSet.setInteger(
            KnownUserSettings.selectedRegionIndex,
            0,
            pvs
          );
        }

        // Clear the location when region or country changes.
        if (regionHasChanged || countryHasChanged) {
          pvs = PropertyValueSet.setInteger(
            KnownProperties.locationIndex,
            0,
            pvs
          );
        }

        const countryIndex = PropertyValueSet.getInteger(
          KnownUserSettings.selectedCountryIndex,
          pvs
        );
        const regionIndex = PropertyValueSet.getInteger(
          KnownUserSettings.selectedRegionIndex,
          pvs
        );
        const locationIndex = PropertyValueSet.getInteger(
          KnownProperties.locationIndex,
          pvs
        );
        const locationId =
          countryIndex !== undefined &&
          regionIndex !== undefined &&
          locationIndex !== undefined
            ? props.countries[countryIndex].regions[regionIndex].locations[
                locationIndex
              ].id
            : props.userSettingLocation;

        props.onLocationChange(pvs, locationId);
      }}
      customTranslation={climateDataDialogTranslation}
      uniqueId={"ClimateDataLocationDialog"}
      readOnlyProperties={props.readOnlyProperties}
      layoutRenderer={
        GenesysPropertiesSelectorRenderers.columnsStackedWithGroupsLayoutRenderer
      }
    />
  );
};
