import styled from "styled-components";
import * as React from "react";

export const unStyledManagerMainContainer = (
  props: { readonly center?: boolean } & React.HTMLProps<HTMLDivElement>
) => {
  const { center, ...htmlProps } = props;
  return <div {...htmlProps}>{htmlProps.children}</div>;
};

// tslint:disable-next-line
export const ManagerWrapperContainer = styled(unStyledManagerMainContainer)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
