import * as React from "react";
import styled from "styled-components";
import * as PropertySelectors from "../react-properties-selector";

// tslint:disable-next-line:variable-name
export const ValueSourceImage = styled.img`
  height: 16px;
  width: 16px;
`;

const unstyledDiv = (
  props: React.DetailedHTMLProps<
    React.HtmlHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => <div className={props.className}>{props.children}</div>;

type ComboBoxImageButtonElementProps = {
  readonly isSelectedItemValid?: boolean;
  readonly locked: boolean;
} & React.HTMLProps<HTMLDivElement>;

function comboBoxImageElement({
  isSelectedItemValid: _,
  locked: _1,
  ...htmlProps
}: ComboBoxImageButtonElementProps): JSX.Element {
  return <div {...htmlProps}>{htmlProps.children}</div>;
}

type ComboBoxImageOptionElementRowProps = {
  readonly isItemValid?: boolean;
} & React.HTMLProps<HTMLDivElement>;

function comboBoxImageOptionElementRow({
  isItemValid: _,
  ...htmlProps
}: ComboBoxImageOptionElementRowProps): JSX.Element {
  return <div {...htmlProps}>{htmlProps.children}</div>;
}

const optionImageElementRow = styled(comboBoxImageOptionElementRow)`
  color: rgb(131, 131, 131);
  min-height: 18px;
  align-self: center;
  border: 0px none rgb(131, 131, 131);
  font: normal normal 300 normal 12px / 30px Helvetica, Arial, sans-serif;
  outline: rgb(131, 131, 131) none 0px;

  padding: 0.2em 0.5em;
  cursor: default;

  &:hover {
    background: rgba(203, 210, 246, 0.79);
    color: white;
  }

  ${props => (props.isItemValid === false ? "color: red;" : "")};
`;

// tslint:disable-next-line:variable-name
export const ValueSourcesImageSelector =
  PropertySelectors.createImageDropdownSelector({
    OptionImage: styled.img`` as any,
    OptionImageElementRow: optionImageElementRow,
    DropdownSelectElement: styled(unstyledDiv)`
      margin-left: -8px;
    `,
    ComboBoxImageButtonElement: styled(comboBoxImageElement)`
      /* width: 162px; */
      align-items: center;
      background: white;
      color: black;
      height: 19px;
      white-space: nowrap;
      border: 1px solid #b4b4b4;
      border-radius: 3px;
      font: normal normal 300 normal 12px / 30px Helvetica, Arial, sans-serif;
      outline: rgb(131, 131, 131) none 0px;
      padding: 0;
      display: inline-block;
      width: 31px;

      i {
        display: none;
      }

      span {
      }

      img {
        height: 16px;
        width: 16px;
        vertical-align: top;
        margin-top: 2px;
        margin-left: 7px;
      }
    `
  });
