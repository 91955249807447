import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as Types from "../types";
import * as GraphQLTypes from "../../../../graphql-types";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../state";
import { PropertyValueSet } from "@genesys/property";
import { ListView } from "./list-view";
import { Unit, Quantity } from "@genesys/uom";
import { ChartView } from "./chart-view";
import { exhaustiveCheck } from "ts-exhaustive-check";

const Container = styled.div``;

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > * {
    margin-right: 6px;
  }
`;

const binVisualizers: ReadonlyArray<Types.BinVisualizer> = [
  "List",
  // "List-Monthtly",
  "Chart"
];

interface Props {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly diagramResults: ReadonlyArray<string>;
  readonly resultsToShow: ReadonlyArray<string>;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly dispatch: Dispatch<Action>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}

export function View(props: Props) {
  const { sharedState, state, dispatch } = props;
  return (
    <Container>
      <SubContainer>
        <span>{sharedState.translate(LanguageTexts.mlcviewAs())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={binVisualizers.map(b => ({
            value: b,
            title: b
          }))}
          value={state.selectedBinVisualizer}
          onChange={e => {
            dispatch(
              Action.setBinVisualizer(e.target.value as Types.BinVisualizer)
            );
          }}
        />
      </SubContainer>
      <BinVisualizer {...props} />
    </Container>
  );
}

function BinVisualizer(props: Props) {
  const {
    sharedState,
    state,
    moistureLoadItem,
    resultsToShow,
    diagramResults,
    dispatch,
    onFormatChanged,
    onFormatCleared
  } = props;

  switch (state.selectedBinVisualizer) {
    case "List": {
      const propertiesToKeep = ["mlcbinid", "airmidpointtemperature"].concat(
        resultsToShow
      );
      return (
        <ListView
          moistureLoadItem={moistureLoadItem}
          onFormatChanged={onFormatChanged}
          onFormatCleared={onFormatCleared}
          sharedState={sharedState}
          propertiesToShow={propertiesToKeep}
          results={diagramResults.map(x => PropertyValueSet.fromString(x))}
        />
      );
    }

    case "List-Monthtly": {
      return (
        <ListView
          moistureLoadItem={moistureLoadItem}
          onFormatChanged={onFormatChanged}
          onFormatCleared={onFormatCleared}
          sharedState={sharedState}
          propertiesToShow={PropertyValueSet.getPropertyNames(
            PropertyValueSet.fromString(diagramResults[0])
          )}
          results={diagramResults.map(x => PropertyValueSet.fromString(x))}
        />
      );
    }

    case "Chart": {
      return (
        <ChartView
          dispatch={dispatch}
          results={diagramResults.map(x => PropertyValueSet.fromString(x))}
          selectedChartPreset={state.selectedChartPreset}
          sharedState={sharedState}
        />
      );
    }
    default:
      return exhaustiveCheck(state.selectedBinVisualizer);
  }
}
