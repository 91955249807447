import * as React from "react";
import { State, Action } from "../state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton } from "@genesys/ui-elements";
import * as Types from "../types";
import { createSystem } from "../functions";

export function CreateButton({
  systemVariant,
  state,
  sharedState,
  dispatch
}: {
  readonly systemVariant: Types.SystemVariant;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!systemVariant.newProperties || systemVariant.invalidThreshold) {
    return <></>;
  } else if (
    systemVariant.shouldCalculate &&
    state.isCalculatingSystems &&
    !systemVariant.calculationResults
  ) {
    return (
      <StandardButton
        size="Small"
        buttonType="Primary-2"
        onClick={() => {
          /* */
        }}
        disabled={true}
      >
        Calculating
      </StandardButton>
    );
  } else if (systemVariant.calculationResults) {
    return (
      <StandardButton
        size="Small"
        buttonType="Primary-2"
        disabled={
          systemVariant.calculationResults.exception.componentMessages.length +
            systemVariant.calculationResults.error.componentMessages.length !==
          0
        }
        onClick={() => {
          createSystem(
            dispatch,
            sharedState,
            systemVariant.systemType + ": " + systemVariant.variant,
            systemVariant.systemType,
            systemVariant.newProperties!,
            systemVariant.operatingCases,
            state.climateSettings!,
            systemVariant.divergentSysComponent,
            state.moistureLoadInfo,
            state.operatingCaseSelectorState!.properties
          );
        }}
      >
        Create
      </StandardButton>
    );
  } else {
    return <></>;
  }
}
