import styled from "styled-components";
import { tabNavBorderColor } from "../colors";

// tslint:disable-next-line
export const TabNav = styled.ul`
  margin: 0;
  padding: 0 15px;
  list-style-type: none;
  border-bottom: 2px solid ${tabNavBorderColor};

  display: flex;
  flex-wrap: wrap;
`;
