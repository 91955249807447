import { Serialize, Unit, Amount } from "uom";
import * as Quantity from "./quantity";
import { unitLookup } from "./unit-lookup";

export function stringToUnit<T extends Quantity.Quantity>(
  unitString: string
): Unit.Unit<T> | undefined {
  return Serialize.stringToUnit(unitString.toLowerCase(), unitLookup);
}

export function unitToString(unit: Unit.Unit<Quantity.Quantity>): string {
  return Serialize.unitToString(unit);
}

export function amountToString(
  amount: Amount.Amount<Quantity.Quantity>
): string {
  return Serialize.amountToString(amount);
}

export function stringToAmount(
  amountString: string
): Amount.Amount<unknown> | undefined {
  return Serialize.stringToAmount(amountString.toLowerCase(), unitLookup);
}
