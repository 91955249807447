import * as Authorization from "@genesys/shared/lib/authorization";

export function hasPermissionForMlc(
  applicationClaims: Authorization.ApplicationClaims
) {
  return (
    Authorization.checkPermission(
      applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
    Authorization.hasPermission(
      applicationClaims,
      Authorization.genesysUserClaims.moistureLoadCalculation,
      "1"
    ) ||
    Authorization.hasPermission(
      applicationClaims,
      Authorization.genesysUserClaims.moistureLoadCalculation,
      "5"
    ) ||
    Authorization.hasPermission(
      applicationClaims,
      Authorization.genesysUserClaims.moistureLoadCalculation,
      "10"
    )
  );
}

export function hasPermissionForDevView(
  applicationClaims: Authorization.ApplicationClaims
) {
  return (
    Authorization.checkPermission(
      applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
    Authorization.hasPermission(
      applicationClaims,
      Authorization.genesysUserClaims.moistureLoadCalculation,
      "5"
    )
  );
}
