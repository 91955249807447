import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createEnergySvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps) {
  const svString = `
    
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>F518836B-8F7C-4CD5-9755-B19CEE84E1F8</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-423.000000, -2083.000000)">
            <g id="Icons/System-Menu-Items/Operating-Cases" transform="translate(428.000000, 2086.000000)">
                <g id="Rectangle">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <path d="M31,11.25 C32.4625318,11.25 33.6584043,12.3917046 33.7449812,13.8324777 L33.75,14 L33.75,14.25 L37,14.25 C38.9374505,14.25 40.5317314,15.719284 40.7293807,17.6043903 L40.744802,17.8008414 L40.75,18 L40.75,46 C40.75,48.0042592 39.1776389,49.6412737 37.1991586,49.744802 L37,49.75 L23,49.75 C20.9957408,49.75 19.3587263,48.1776389 19.255198,46.1991586 L19.25,46 L19.25,18 C19.25,15.9957408 20.8223611,14.3587263 22.8008414,14.255198 L23,14.25 L25.25,14.25 L25.25,14 C25.25,12.5959694 26.302195,11.4376922 27.6610744,11.2706787 L27.8324777,11.2550188 L28,11.25 L31,11.25 Z M31,12.75 L28,12.75 C27.3527913,12.75 26.8204661,13.2418747 26.7564536,13.8721948 L26.75,14 L26.75,15.75 L23,15.75 C21.809136,15.75 20.8343551,16.6751616 20.7551908,17.8459512 L20.75,18 L20.75,46 C20.75,47.190864 21.6751616,48.1656449 22.8459512,48.2448092 L23,48.25 L37,48.25 C38.190864,48.25 39.1656449,47.3248384 39.2448092,46.1540488 L39.25,46 L39.25,18 C39.25,16.809136 38.3248384,15.8343551 37.1540488,15.7551908 L37,15.75 L32.25,15.75 L32.25,14 C32.25,13.3527913 31.7581253,12.8204661 31.1278052,12.7564536 L31,12.75 Z M36,44 C36.5522847,44 37,44.4477153 37,45 C37,45.5522847 36.5522847,46 36,46 L24,46 C23.4477153,46 23,45.5522847 23,45 C23,44.4477153 23.4477153,44 24,44 L36,44 Z M36,40 C36.5522847,40 37,40.4477153 37,41 C37,41.5522847 36.5522847,42 36,42 L24,42 C23.4477153,42 23,41.5522847 23,41 C23,40.4477153 23.4477153,40 24,40 L36,40 Z M36,36 C36.5522847,36 37,36.4477153 37,37 C37,37.5522847 36.5522847,38 36,38 L24,38 C23.4477153,38 23,37.5522847 23,37 C23,36.4477153 23.4477153,36 24,36 L36,36 Z M31.4444444,20 L30.939,25.711 L33.6666667,25.7118951 L29.2222222,32 L29.726,26.288 L27,26.2881049 L31.4444444,20 Z" id="Shape" fill="${color}" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svString);
}
