import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQLTypes from "../graphql-types";
import {
  deletePricingFilesMutation,
  updatePricingFileMutation,
  createNewPricingRevisionMutation,
  copyPricingToNewPricingFileMutation,
  updatePricingNameMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { SystemLabel, Mode } from "./types";
import * as LabelManager from "../label-manager/state";

export type State = {
  readonly labelManagerState: LabelManager.State | undefined;
  readonly input: string;
  readonly mode: Mode;
  readonly hasPerformedMutation: boolean;
};

export type ActionCompleteUrl = {
  readonly type: "new-revision" | "copy-of-latest-revision";
  readonly url: string;
};

export const init = (): [State] => {
  return [
    {
      labelManagerState: undefined,
      input: "",
      mode: "menu",
      hasPerformedMutation: false
    }
  ];
};

export const Action = ctorsUnion({
  setMode: (mode: Mode) => ({ mode }),
  setInput: (input: string) => ({ input }),
  actionComplete: (actionType?: ActionCompleteUrl) => ({ actionType }),
  updatePricingFile: (input: GraphQLTypes.UpdatePricingFileVariables) => ({
    input
  }),
  createNewPricingRevision: (pricingFileId: string, name: string) => ({
    pricingFileId,
    name
  }),
  deletePricing: (pricingFileId: string) => ({ pricingFileId }),
  copyPricingToNewPricingFile: (pricingId: string) => ({ pricingId }),
  updatePricingName: (pricingId: string, name: string) => ({
    pricingId,
    name
  }),
  initLabelManager: (systemLabels: ReadonlyArray<SystemLabel>) => ({
    systemLabels
  }),
  dispatchLabelManager: (action: LabelManager.Action) => ({ action }),
  updatePricingFileComplete: (res: GraphQLTypes.UpdatePricingFile) => ({ res })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?,
  boolean?,
  ActionCompleteUrl?
] {
  switch (action.type) {
    case "setMode": {
      return [{ ...state, mode: action.mode }];
    }
    case "setInput": {
      return [{ ...state, input: action.input }];
    }
    case "actionComplete": {
      return [
        { ...state, mode: "menu", input: "" },
        undefined,
        undefined,
        true,
        action.actionType
      ];
    }
    case "updatePricingFile": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdatePricingFile,
          GraphQLTypes.UpdatePricingFileVariables,
          Action
        >(updatePricingFileMutation, action.input, data =>
          Action.updatePricingFileComplete(data)
        ),
        ,
        false
      ];
    }
    case "createNewPricingRevision": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CreateNewPricingRevision,
          GraphQLTypes.CreateNewPricingRevisionVariables,
          Action
        >(
          createNewPricingRevisionMutation,
          { pricingFileId: action.pricingFileId, name: action.name },
          data => {
            return Action.actionComplete({
              type: "new-revision",
              url: `/pricing/${sharedState.genesysPrefix.pricingNo(
                data.createNewPricingRevision.pricingNo,
                data.createNewPricingRevision.revisionNo
              )}`
            });
          }
        ),
        ,
        false
      ];
    }
    case "deletePricing": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeletePricingFiles,
          GraphQLTypes.DeletePricingFilesVariables,
          Action
        >(
          deletePricingFilesMutation,
          {
            pricingFileIds: [action.pricingFileId]
          },
          () => Action.actionComplete()
        ),
        ,
        false
      ];
    }
    case "copyPricingToNewPricingFile": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CopyPricingToNewPricingFile,
          GraphQLTypes.CopyPricingToNewPricingFileVariables,
          Action
        >(
          copyPricingToNewPricingFileMutation,
          { pricingId: action.pricingId },
          data =>
            Action.actionComplete({
              type: "copy-of-latest-revision",
              url: `/pricing/${sharedState.genesysPrefix.pricingNo(
                data.copyPricingToNewPricingFile.pricingNo,
                data.copyPricingToNewPricingFile.latestPricing.revisionNo
              )}`
            })
        ),
        ,
        false
      ];
    }
    case "updatePricingName": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdatePricingName,
          GraphQLTypes.UpdatePricingNameVariables,
          Action
        >(
          updatePricingNameMutation,
          { pricingId: action.pricingId, name: action.name },
          () => Action.actionComplete()
        ),
        ,
        false
      ];
    }
    case "initLabelManager": {
      const [labelManagerState] = LabelManager.init(action.systemLabels);
      return [{ ...state, labelManagerState: labelManagerState }];
    }
    case "dispatchLabelManager": {
      if (!state.labelManagerState) {
        return [state];
      }

      const [labelManagerState, sharedStateActions] = LabelManager.update(
        action.action,
        state.labelManagerState
      );

      return [
        { ...state, labelManagerState: labelManagerState },
        undefined,
        sharedStateActions
      ];
    }

    case "updatePricingFileComplete": {
      const resType = action.res.updatePricingFile.__typename;
      if (resType === "UpdateFailedType") {
        return [
          { ...state, mode: "menu", input: "" },
          undefined,
          [
            SharedState.Action.addAlertMessageToQueue({
              messageType: "error",
              content: `Update failed: ${action.res.updatePricingFile.errorMessage}`,
              timeout: 5000
            })
          ]
        ];
      }
      return [
        { ...state, mode: "menu", input: "" },
        undefined,
        undefined,
        true
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
