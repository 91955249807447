import * as React from "react";
import * as Types from "./types";
import * as SharedState from "../../../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import { OverlayLoader, H1, H2 } from "@genesys/ui-elements";
import {
  PropertiesSelectorView,
  Action as PropertiesSelectorAction
} from "../../../properties-selector";
import {
  TemplateComponentList,
  PropertySelectorSection,
  DiagramContainer,
  DiagramRoot,
  AutoScrollOffSet
} from "./elements";
import { TemplateComponent } from "./template-component";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Diagram } from "./diagram";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { defaultProperties } from "./default-properties";

export interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
}

export function InitialConfiguration(props: Props): JSX.Element {
  if (!props.state) {
    return <OverlayLoader />;
  }

  const showCodes = Authorization.checkPermission(
    props.sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  return (
    <div>
      <AutoScrollOffSet />
      <H1 weight="normal" color="primary">
        {props.sharedState.translate(LanguageTexts.systemArrangement())}
      </H1>
      <DiagramRoot>
        <DiagramContainer>
          <Diagram
            systemTypeId={props.state.systemTypeId}
            productMap={props.state.data.productMap}
            sharedState={props.sharedState}
            symbols={props.state.data.symbols}
            templateComponents={props.state.template.components
              .filter(c =>
                PropertyFilter.isValid(
                  props.state!.newProperties,
                  c.propertyFilter
                )
              )
              .map(c => {
                const component =
                  props.state!.divergentTemplateComponentMap[c.id];
                return {
                  ...c,
                  properties: component
                    ? component.properties
                    : PropertyValueSet.merge(
                        c.properties,
                        PropertyValueSet.merge(
                          props.state!.newProperties,
                          defaultProperties(
                            props.state!.data.productMap[c.productId].properties
                          )
                        )
                      )
                };
              })}
          />
        </DiagramContainer>
      </DiagramRoot>
      <PropertySelectorSection>
        <div>
          <H2 weight="normal">
            {props.sharedState.translate(LanguageTexts.initialConfiguration())}
          </H2>
          <PropertiesSelectorView
            state={props.state.propertiesSelectorState}
            propertiesInfo={
              props.state.data.productMap[props.state.systemTypeId + "NEW"]
                .properties
            }
            dispatch={Dispatch.map(
              Action.dispatchNewPropertiesSelector,
              props.dispatch
            )}
            productId={props.state.systemTypeId + "NEW"}
            isReadonly={false}
            fieldGroup={`Settings.${props.state.systemTypeId}NEW`}
            sharedState={props.sharedState}
            showCodes={showCodes}
          />
        </div>
        <TemplateComponentList>
          {Object.keys(props.state.divergentTemplateComponentMap).map(id => {
            const templateComponent = props.state?.template.components.find(
              c => c.id === id
            )!;

            return (
              <TemplateComponent
                key={id}
                showCodes={showCodes}
                productId={templateComponent.productId}
                dispatch={Dispatch.map<PropertiesSelectorAction, Action>(
                  action =>
                    Action.dispatchTemplateComponentPropertiesSelector(
                      id,
                      action
                    ),
                  props.dispatch
                )}
                properties={
                  props.state!.divergentTemplateComponentMap[id].properties
                }
                propertyDef={props.state!.data.productMap[
                  templateComponent.productId
                ].properties.filter(p =>
                  templateComponent.visibleProperties.includes(p.name)
                )}
                sharedState={props.sharedState}
                label={templateComponent.label}
              />
            );
          })}
        </TemplateComponentList>
      </PropertySelectorSection>
    </div>
  );
}
