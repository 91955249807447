import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const updateComponenNotesMutation = gql`
  mutation UpdateComponentNotes($input: UpdateComponentNotesInputType!) {
    updateComponentNotes(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
