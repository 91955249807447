import * as React from "react";
import { UnitsFormat } from "@genesys/uom";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { AmountFormatSelectorRendererProps, Renderer } from "./types";
import { StyledQuantitySelector } from "./elements";

// tslint:disable-next-line:variable-name
export const FormatSelectorRenderer: Renderer = ({
  AmountFormatSelector,
  conversionParameters,
  onFormatChanged,
  onFormatSelectorActiveChanged,
  isQuantitySelectorActive,
  onQuantityChange,
  propertyType,
  onFormatCleared,
  selectedDecimalCount,
  selectedUnit,
  userSelected
}: AmountFormatSelectorRendererProps): JSX.Element | null => {
  if (propertyType !== "amount") {
    return null;
  }

  const effectiveUnit = selectedUnit!;
  const effectiveDecimalCount = selectedDecimalCount!;
  const effectiveQuantity = effectiveUnit.quantity;

  if (conversionParameters === undefined || !isQuantitySelectorActive) {
    return (
      <AmountFormatSelector
        onFormatChanged={onFormatChanged}
        onFormatCleared={onFormatCleared}
        selectedUnit={effectiveUnit}
        selectedDecimalCount={effectiveDecimalCount}
        onFormatSelectorActiveChanged={onFormatSelectorActiveChanged}
        userSelected={userSelected}
        unitsFormat={UnitsFormat}
      />
    );
  }

  return (
    <>
      <AmountFormatSelector
        onFormatChanged={onFormatChanged}
        onFormatCleared={onFormatCleared}
        selectedUnit={effectiveUnit}
        selectedDecimalCount={effectiveDecimalCount}
        onFormatSelectorActiveChanged={onFormatSelectorActiveChanged}
        userSelected={userSelected}
        unitsFormat={UnitsFormat}
      />
      {conversionParameters &&
        QuantityConversion.isConvertableQuantity(effectiveQuantity) && (
          <StyledQuantitySelector
            onChange={onQuantityChange}
            selectedQuantity={selectedUnit!.quantity}
          />
        )}
    </>
  );
};
