import * as React from "react";
import * as FileUpload from "../file-upload";
import * as Xlsx from "xlsx";

interface Props {
  readonly onParsed: (result: Array<Array<string>>) => void;
  readonly children: (onClick: () => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function SimpleDataUploadContainer({
  onParsed,
  children
}: Props): JSX.Element {
  return (
    <FileUpload.FileUploadContainer
      onFileUploaded={createParseFile(onParsed)}
      children={children}
      accept="text/csv,.csv,.xlsx"
    />
  );
}

function createParseFile(onParsed: (result: Array<Array<string>>) => void) {
  return (file: File) => {
    const fileFormat = file.name.split(".").reverse()[0].toLowerCase();
    switch (fileFormat) {
      case "csv":
        parseCsv(file, onParsed);
        break;
      case "xlsx":
        parseXlsx(file, onParsed);
        break;
      default:
        throw new Error("Unsupported format");
    }
  };
}

function parseXlsx(
  file: File,
  onParsed: (result: Array<Array<string>>) => void
) {
  const fileReader = new FileReader();
  fileReader.onload = evt => {
    const textResult: string = (evt.target as any).result;
    const workbook = Xlsx.read(textResult, { type: "binary" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = Xlsx.utils.sheet_to_json(worksheet, { header: 1, defval: "" });

    const parsedContent: Array<Array<string>> = (json as any).map((r: any) =>
      r.map((c: any) => c.toString())
    );
    onParsed(parsedContent);
  };
  fileReader.readAsBinaryString(file);
}

function parseCsv(
  file: File,
  onParsed: (result: Array<Array<string>>) => void
) {
  const fileReader = new FileReader();
  fileReader.onload = evt => {
    const textResult: string = (evt.target as any).result;

    const parsed = parseCsvText(textResult);
    onParsed(parsed);
  };
  fileReader.readAsText(file);
}

function parseCsvText(text: string): Array<Array<string>> {
  const newLineRegex = /\r?\n/;
  const rows = text.split(newLineRegex);

  return rows
    .filter(row => row && row.length > 0) // Filter empty rows. E.G last CRLF would create and empty row
    .map(parseRow);
}

function parseRow(row: string): Array<string> {
  return row.split(/[,;]/).map(val => {
    return (val && val.replace(/\"/g, "")) || "";
  });
}
