import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const updatePricingMutation = gql`
  mutation UpdatePricing($pricing: PricingInputType!) {
    updatePricing(pricing: $pricing) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
