import styled from "styled-components";

export const Root = styled.div`
  padding-left: 16px;
`;

export const CustomBinsImportRoot = styled.div`
  display: flex;
  > div {
    margin-right: 10px;
  }
`;

export const BinTypeSelectorRoot = styled.div`
  padding-bottom: 12px;

  label {
    padding-right: 16px;
    p {
      padding-left: 4px;
    }
  }
`;
