import styled from "styled-components";
import { zIndex, HomeIcon, Open } from "@genesys/ui-elements";

export const Home = styled(HomeIcon)`
  position: relative;
  left: 8px;
`;

export const OpenIcon = styled(Open)`
  position: relative;
  left: 2px;
`;

export const TopOuter = styled.div`
  max-width: 1440px;
  min-height: 68px;
  margin: 0 auto;
`;

export const FixedContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  position: fixed;
  z-index: ${zIndex.MainMenu};
`;

export const MenuBarContainer = styled.div`
  max-height: 65px;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }
  /* > u
  l {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      margin-left: 35px;
    }
    li:first-child {
      margin-left: 0;
    }

    :nth-child(2) {
      li {
        margin-left: 11px;
        display: inline-block;
      }
    }
  } */
`;

export const FlexItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin: 0 10px; */
`;

export const DropDownContainer = styled.div`
  position: relative;
`;

export const DropDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  padding: 10px;
  left: -15px;
  border-radius: 5px;
`;
export const StyledA = styled.a`
  color: inherit;
  text-decoration: none;
  border: 1px solid white;
  padding: 6px 6px 3px 6px;
`;

export const MenuContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
`;

export const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  li {
    margin-left: 35px;
  }
  li:first-child {
    margin-left: 0;
  }

  :nth-child(2) {
    li {
      margin-left: 11px;
      display: inline-block;
    }
  }
`;
