import * as GQLTypes from "../../../../../graphql-types";
import { Action } from "../../../actions";
import {
  createLabelMutation,
  deleteLabelMutation,
  updateLabelMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { UpdateFunction } from "../types";

export const handleCreateLabel: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "createLabel") {
    return [state];
  }
  const newLabel = {
    id: action.labelId,
    name: action.labelName
  };
  return [
    {
      ...state,
      user: {
        ...state.user!,
        labels: state.user!.labels.concat(newLabel)
      }
    },
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.CreateLabel,
        GQLTypes.CreateLabelVariables,
        Action
      >(
        createLabelMutation,
        {
          label: newLabel
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleDeleteLabel: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "deleteLabel") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  return [
    {
      ...state,
      user: {
        ...state.user,
        labels: state.user.labels.filter(l => l.id !== action.labelId)
      }
    },
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.DeleteLabel,
        GQLTypes.DeleteLabelVariables,
        Action
      >(
        deleteLabelMutation,
        {
          labelId: action.labelId
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleUpdateLabel: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "updateLabel") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newLabel = {
    id: action.labelId,
    name: action.labelName
  };
  const newState = {
    ...state,
    user: {
      ...state.user,
      labels: state.user.labels.map(l =>
        l.id === action.labelId ? newLabel : l
      )
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.UpdateLabel,
        GQLTypes.UpdateLabelVariables,
        Action
      >(
        updateLabelMutation,
        {
          label: newLabel
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};
