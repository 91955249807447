import * as React from "react";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../shared-state";
import { Action, State } from "./state";
import { RecentSystemsView } from "./components/recent-systems-view/recent-systems-view";
import { FavoriteSystemsView } from "./components/favorite-systems-view/favorite-system-view";
import { NewsInHelpDeskView } from "./components/news-in-help-desk/news-in-help-desk-view";
import { SystemErrorDetailsModal } from "./components/system-error-details-modal";

const BottomContainer = styled.div`
  display: flex;
  padding-top: 30px;
  justify-content: space-evenly;
`;

export function DashboardView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <>
      {state.systemError && (
        <SystemErrorDetailsModal
          details={state.systemError}
          onClose={() => dispatch(Action.showSystemErrorDetails(undefined))}
        />
      )}
      <div>
        <RecentSystemsView
          recentSystemCardState={state.recentSystemCardState}
          sharedState={sharedState}
          productData={state.productData}
          dispatch={dispatch}
        />

        <BottomContainer>
          <FavoriteSystemsView
            sharedState={sharedState}
            systemActionsState={state.systemActionsState}
            onToggleIsFavorite={id => dispatch(Action.toggleFavorites(id))}
            toggleSystemActions={recentSystemId =>
              dispatch(Action.toggleSystemActions(recentSystemId))
            }
            navigate={url => dispatch(Action.navigate(url))}
            dispatch={dispatch}
          />
          <NewsInHelpDeskView
            wikijsData={state.wikijsData}
            sharedState={sharedState}
            onNewsItemClicked={id => dispatch(Action.updateSeenNewsItem([id]))}
            onShowAllNewsClicked={notSeenIds => {
              dispatch(Action.updateSeenNewsItem(notSeenIds));
            }}
          />
        </BottomContainer>
      </div>
    </>
  );
}
