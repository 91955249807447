import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";
import * as Product from "../product";

export function notes(
  system: System.System,
  systemType: Product.SystemType,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  const epxComponent = system.components.find(c =>
    c.productId.endsWith("EPX")
  )!;
  const systemTypeId = system.file.systemTypeId;
  const epxSizePropertyName = systemType.plenumSizeProperty;

  const epxSize = PropertyValueSet.getInteger(
    epxSizePropertyName,
    epxComponent.properties
  );
  const epxText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, epxSizePropertyName)
  )}: ${translate(
    epxSize !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          epxSizePropertyName,
          epxSize
        )
      : LanguageTexts.pNull()
  )}`;

  return [epxText];
}
