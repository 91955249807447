import * as OperationTimeGen2 from "../../../../operation-time-manager";
import * as KnownProperties from "../../known-properties";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import {
  getIfIntegerChanged,
  getIfPropertyChanged
} from "../../helper-functions";
import { StaticMoistureLoadIcon } from "@genesys/ui-elements";

import { LoadOperationTime } from "../../types";

//Icon
export const Icon = StaticMoistureLoadIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

/// Moisture load selection
export const useMoistProperty = KnownProperties.useStaticMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = undefined;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}
export const title = "mlcstaticmoistureload";
export const sourceName = MoistureHeatLoadTypeEnum.STATIC_MOISTURE_LOAD;
export const description = "mlcstaticmoistureloadexplanation";
export const useLoadKey = "mlcusestaticmoistureload";

export const manualInputProperty =
  KnownProperties.staticMoistureLoadManualInput;

export const properties = [
  KnownProperties.useStaticMoistureLoad,
  KnownProperties.staticMoistureLoadManualInput,
  KnownProperties.useMoistureLoadPerArea,
  KnownProperties.moistureLoadPerArea,
  KnownProperties.useFloorArea,
  KnownProperties.useWallArea,
  KnownProperties.useRoofArea,
  KnownProperties.staticLoadsMoistureLoad
];
export const overridableProperties = [
  KnownProperties.moistureLoadPerArea,
  KnownProperties.staticLoadsMoistureLoad
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.allDay;
}

export function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  const useStaticMoistureLoad = getIfIntegerChanged(
    prevSelections,
    currentSelections,
    KnownProperties.useStaticMoistureLoad
  );
  if (useStaticMoistureLoad !== undefined) {
    defaults = PropertyValueSet.merge(staticMoistureLoadDefault(), defaults);
  }
  const useMoistureLoadPerArea = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useMoistureLoadPerArea
  );
  if (useMoistureLoadPerArea !== undefined) {
    defaults = PropertyValueSet.merge(
      setMoistureLoadPerAreaDefaults(),
      defaults
    );
  }

  const staticLoadsMoistureLoad = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.staticLoadsMoistureLoad
  );

  const moistureLoadPerArea = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.moistureLoadPerArea
  );

  const useFloorArea = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useFloorArea
  );

  const useWallArea = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useWallArea
  );

  const useRoofArea = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useRoofArea
  );

  const buildingLength = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLength
  );

  const buildingHeight = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingHeight
  );

  const buildingWidth = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingWidth
  );

  if (
    useMoistureLoadPerArea !== undefined ||
    staticLoadsMoistureLoad !== undefined ||
    moistureLoadPerArea !== undefined ||
    useFloorArea !== undefined ||
    useWallArea !== undefined ||
    useRoofArea !== undefined ||
    buildingLength !== undefined ||
    buildingWidth !== undefined ||
    buildingHeight !== undefined
  ) {
    defaults = PropertyValueSet.merge(
      setTotalMoistureLoad(PropertyValueSet.merge(defaults, currentSelections)),
      defaults
    );
  }

  return defaults;
}

function staticMoistureLoadDefault(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.staticMoistureLoadManualInput]:
      PropertyValue.fromInteger(0),
    [KnownProperties.useMoistureLoadPerArea]: PropertyValue.fromInteger(0),
    [KnownProperties.staticLoadsMoistureLoad]: PropertyValue.fromAmount(
      Amount.create(0, Units.KilogramPerHour)
    )
  };
}

function setMoistureLoadPerAreaDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.staticLoadsMoistureLoad]: PropertyValue.fromAmount(
      Amount.create(0, Units.KilogramPerHour)
    ),
    [KnownProperties.moistureLoadPerArea]: PropertyValue.fromAmount(
      Amount.create(0, Units.GramPerSquareMeterHour)
    ),
    [KnownProperties.useFloorArea]: PropertyValue.fromInteger(0),
    [KnownProperties.useWallArea]: PropertyValue.fromInteger(0),
    [KnownProperties.useRoofArea]: PropertyValue.fromInteger(0)
  };
}

function setTotalMoistureLoad(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.useMoistureLoadPerArea,
      current
    ) === 1
  ) {
    let totalArea = 0;
    if (
      PropertyValueSet.getInteger(KnownProperties.useFloorArea, current) === 1
    ) {
      totalArea =
        totalArea +
        Amount.valueAs(
          Units.SquareMeter,
          PropertyValueSet.getAmount<Quantity.Area>(
            KnownProperties.buildingFloorArea,
            current
          ) || Amount.create(0, Units.SquareMeter)
        );
    }

    if (
      PropertyValueSet.getInteger(KnownProperties.useWallArea, current) === 1
    ) {
      totalArea =
        totalArea +
        Amount.valueAs(
          Units.SquareMeter,
          PropertyValueSet.getAmount<Quantity.Area>(
            KnownProperties.buildingSurfaceArea,
            current
          ) || Amount.create(0, Units.SquareMeter)
        ) -
        Amount.valueAs(
          Units.SquareMeter,
          PropertyValueSet.getAmount<Quantity.Area>(
            KnownProperties.buildingFloorArea,
            current
          ) || Amount.create(0, Units.SquareMeter)
        );
    }

    if (
      PropertyValueSet.getInteger(KnownProperties.useRoofArea, current) === 1
    ) {
      totalArea =
        totalArea +
        Amount.valueAs(
          Units.SquareMeter,
          PropertyValueSet.getAmount<Quantity.Area>(
            KnownProperties.buildingFloorArea,
            current
          ) || Amount.create(0, Units.SquareMeter)
        );
    }

    let staticMoistureLoadPerArea = Amount.valueAs(
      Units.GramPerSquareMeterHour,
      PropertyValueSet.getAmount<Quantity.MassFlowPerArea>(
        KnownProperties.moistureLoadPerArea,
        current
      ) || Amount.create(0, Units.GramPerSquareMeterHour)
    );

    const staticMoistureLoad = Amount.create(
      totalArea * staticMoistureLoadPerArea,
      Units.GramPerHour
    );
    return {
      [KnownProperties.staticLoadsMoistureLoad]:
        PropertyValue.fromAmount(staticMoistureLoad)
    };
  }
  return PropertyValueSet.Empty;
}
