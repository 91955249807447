import styled from "styled-components";

export const TemplateComponentList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemplateComponentListItem = styled.div``;

export const PropertySelectorSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DiagramContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 400px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const DiagramRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

export const AutoScrollOffSet = styled.div`
  height: 80px;
`;
