import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  FilterIcon,
  P2,
  ClearIcon,
  CheckBox,
  PrimaryColors
} from "@genesys/ui-elements";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  height: 100%;
  padding: 20px 20px 10px 30px;
`;

const FilterCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 18px;
  margin: 6px 0;
`;

const TopFilterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  div {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 6px 0 2px;
    border-radius: 10px;
    :hover {
      cursor: pointer;
      background-color: ${PrimaryColors.blue25};
    }
  }
`;

export function GroupFilterComponent({
  sharedState,
  availableGroups,
  groupFilter,
  onClearClick,
  onGroupClick
}: {
  readonly sharedState: SharedState.State;
  readonly availableGroups: ReadonlyArray<string>;
  readonly groupFilter: ReadonlyArray<string>;
  readonly onClearClick: () => void;
  readonly onGroupClick: (group: string) => void;
}) {
  return (
    <Root>
      <TopFilterContainer>
        <div>
          <FilterIcon height="1.6rem" />
          <P2 color="secondary" weight="normal">
            {sharedState.translate(LanguageTexts.filters())}
          </P2>
        </div>

        <a onClick={() => onClearClick()}>
          <ClearIcon height="1.6rem" />
          <P2 color="secondary" weight="normal">
            {sharedState.translate(LanguageTexts.clear())}
          </P2>
        </a>
      </TopFilterContainer>

      {availableGroups.map(group => (
        <FilterCheckboxContainer key={group}>
          <CheckBox
            isChecked={groupFilter.includes(group)}
            children={""}
            onClick={() => onGroupClick(group)}
          />
          <P2 color="secondary" weight="normal">
            {group}
          </P2>
        </FilterCheckboxContainer>
      ))}
    </Root>
  );
}
