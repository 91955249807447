import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createPidSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps) {
  const svgString = `
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>388A670A-3542-4A09-B1BE-1FBF1201C339</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-423.000000, -1885.000000)">
            <g id="Icons/System-Menu-Items/Mechanical-Options" transform="translate(428.000000, 1888.000000)">
                <g id="Rectangle-Copy-13">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <path d="M37.25,18.0851471 L48.75,20.3851471 L48.75,40.6148529 L37.25,42.9148529 L37.25,32.2491471 L29.5,32.25 L29.5,30.75 L37.25,30.7491471 L37.25,18.0851471 Z M38.75,19.915 L38.75,30.7491471 L43.5,30.75 L43.5,32.25 L38.75,32.2491471 L38.75,41.084 L47.25,39.384 L47.25,21.615 L38.75,19.915 Z M24.25,20.0997862 L34.1354183,21.9145006 L33.8645817,23.3898472 L25.75,21.9 L25.75,39.099 L33.8645817,37.6101528 L34.1354183,39.0854994 L24.25,40.9002138 L24.25,20.0997862 Z M11.25,20.0997862 L21.1354183,21.9145006 L20.8645817,23.3898472 L12.75,21.9 L12.75,39.099 L20.8645817,37.6101528 L21.1354183,39.0854994 L11.25,40.9002138 L11.25,20.0997862 Z M23.5,30.75 L23.5,32.25 L15.5,32.25 L15.5,30.75 L23.5,30.75 Z" id="Shape" fill="${color}" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
