import { Translate } from "./types";
import { PropertyValueSet } from "@genesys/property";
import { curlyTranslate, product } from "./text-definitions";

export function getComponentLabel(
  translate: Translate,
  productId: string,
  properties: PropertyValueSet.PropertyValueSet
): string {
  const label = PropertyValueSet.getText("label", properties);
  if (label !== undefined) {
    return translate(curlyTranslate(label, productId.substring(0, 3)));
  }

  return translate(product(productId));
}
