import * as React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  H1,
  StandardButton,
  Spinner
  // zIndex
} from "@genesys/ui-elements";
import { clientConfig, getModalBorderColor } from "../config";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto 20px auto;
  background-color: inherit;

  /* overflow: auto; */
`;
const TitleContainer = styled.div`
  display: flex;
  > h1 {
    margin-right: 5px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 1.8rem;
  }

  button {
    font-size: 1rem;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  height: 500px;
`;

const ButtonContainer = styled.div`
  button {
    margin-right: 10px;
  }
`;

export function View(props: {
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  const { state, dispatch } = props;

  const disabled = !state.blobResponse?.success || state.processing;

  return (
    <Modal onClose={() => undefined} maxWidth={1400}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <HeaderContainer>
          <TitleContainer>
            <H1>Terms and Conditions</H1>
          </TitleContainer>
          <ButtonContainer>
            <StandardButton
              disabled={disabled}
              size="Large"
              buttonType="Primary-2"
              onClick={() => dispatch(Action.termsAccepted())}
            >
              Accept
            </StandardButton>
            <StandardButton
              disabled={disabled}
              size="Large"
              buttonType="Secondary"
              onClick={async () => {
                dispatch(Action.termsDeclined());
              }}
            >
              Decline
            </StandardButton>
          </ButtonContainer>
        </HeaderContainer>
      </ModalHeader>

      <ContentContainer>
        <MemoizedContent {...props} />
      </ContentContainer>
    </Modal>
  );
}

const MemoizedContent = React.memo(Content);

function Content({ state }: { readonly state: State }) {
  const blobResponse = state.blobResponse;
  const loadingContent = (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
  if (!blobResponse) {
    return loadingContent;
  }

  if (!blobResponse.success) {
    return <p>Failed to fetch Document</p>;
  }

  const { flieBlob } = blobResponse;

  return (
    <embed
      src={URL.createObjectURL(flieBlob)}
      type="application/pdf"
      width="100%"
      height="1000px"
    />
  );
}
