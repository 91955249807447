import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { OperationTime, Checks, MutableChecks } from "./types";
import * as KnownProperties from "./known-properties";
import { presets } from "./presets";

export function toPropertyValueSet(
  operationTime: OperationTime
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.binOperationTime]: PropertyValue.fromText(
      presetName(operationTime)
    ),
    [KnownProperties.months]: PropertyValue.fromText(
      checksToString(operationTime.months)
    ),
    [KnownProperties.monday]: PropertyValue.fromText(
      checksToString(operationTime.monday)
    ),
    [KnownProperties.tuesday]: PropertyValue.fromText(
      checksToString(operationTime.tuesday)
    ),
    [KnownProperties.wednesday]: PropertyValue.fromText(
      checksToString(operationTime.wednesday)
    ),
    [KnownProperties.thursday]: PropertyValue.fromText(
      checksToString(operationTime.thursday)
    ),
    [KnownProperties.friday]: PropertyValue.fromText(
      checksToString(operationTime.friday)
    ),
    [KnownProperties.saturday]: PropertyValue.fromText(
      checksToString(operationTime.saturday)
    ),
    [KnownProperties.sunday]: PropertyValue.fromText(
      checksToString(operationTime.sunday)
    )
  };
}

function checksToString(checks: Checks): string {
  return Object.keys(checks)
    .map(numberString => parseInt(numberString, 10))
    .sort((a, b) => a - b)
    .map(index => checks[index])
    .map(b => (b ? 1 : 0))
    .join("");
}

export type PresetKey = keyof typeof presets;

export function presetKey(operationTime: OperationTime) {
  for (const key of Object.keys(presets) as ReadonlyArray<PresetKey>) {
    const preset = presets[key as keyof typeof presets];
    if (operationTime === preset) {
      return key;
    }
  }
  return undefined;
}

export function presetNameFromKey(key: PresetKey) {
  const map: { readonly [k in PresetKey]: string } = {
    noHours: "nohours",
    allDay: "allday",
    officeHours: "office",
    factoryHours: "factory",
    twoShiftHours: "twoshift"
  };

  return map[key];
}

export function presetName(operationTime: OperationTime) {
  const key = presetKey(operationTime);
  return key ? presetNameFromKey(key) : "custom";
}

export function fromPropertyValueSet(
  pvs: PropertyValueSet.PropertyValueSet
): OperationTime {
  const presetName = PropertyValueSet.getText(
    KnownProperties.binOperationTime,
    pvs
  );

  switch (presetName) {
    case "nohours":
      return presets.noHours;
    case "allday":
      return presets.allDay;
    case "office":
      return presets.officeHours;
    case "factory":
      return presets.factoryHours;
    case "twoshift":
      return presets.twoShiftHours;
    case "custom":
      return {
        months: stringToChecks(
          PropertyValueSet.getText(KnownProperties.months, pvs)!
        ),
        monday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.monday, pvs)!
        ),
        tuesday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.tuesday, pvs)!
        ),
        wednesday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.wednesday, pvs)!
        ),
        thursday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.thursday, pvs)!
        ),
        friday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.friday, pvs)!
        ),
        saturday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.saturday, pvs)!
        ),
        sunday: stringToChecks(
          PropertyValueSet.getText(KnownProperties.sunday, pvs)!
        )
      };
    default:
      throw new Error("Not a operation time preset name: " + presetName);
  }
}

function stringToChecks(text: string): Checks {
  return Array.from(text)
    .map(char => !!parseInt(char, 10))
    .reduce(
      (soFar, current, index) => {
        soFar[index + 1] = current;
        return soFar;
      },
      {} as MutableChecks
    );
}
