import styled from "styled-components";

// tslint:disable-next-line
export const UserLoginInfoTable = styled.table`
  tr {
    td:first-child {
      text-align: right;
      padding-right: 10px;
      padding-top: 2px;
      font-weight: bold;
    }
  }
`;
