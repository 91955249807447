import * as React from "react";
import styled from "styled-components";
import { Units, UnitsFormat, unitLookup } from "@genesys/uom";
import {
  UsePropertiesSelector,
  usePropertiesSelector
} from "@promaster-sdk/react-property-selectors";
import { H3, TableInteractive } from "@genesys/ui-elements";
import {
  compare,
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import {
  PropertyInfo,
  PropertyItem
} from "../../../../../../properties-selector";
import * as SharedState from "../../../../../../shared-state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Unit } from "uom";
import { AmountFormatSelector } from "../../../../../../amount-format-selector";
import { Quantity } from "@genesys/uom";

// This code is stolen from properties-selector but it only renders the AmountFormatSelector for each property
// I did it instead of adding yet another option to the properties-selector or doing css-black-magic to get what I want in the ope-editor

const PropertiesSelectorTable = styled(TableInteractive)`
  width: auto;
  border-spacing: 0 1em;
`;

export function AmountFormatSelectors({
  showCodes,
  productProperties,
  fieldGroup,
  sharedState,
  selectedProperties,
  onFormatChanged,
  onFormatCleared
}: {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly productProperties: ReadonlyArray<PropertyInfo>;
  readonly sharedState: SharedState.State;
  readonly showCodes: boolean;
  readonly fieldGroup: string;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}): JSX.Element {
  const propertyFormats = sharedState.screenAmounts.getPropertyFormats(
    fieldGroup,
    selectedProperties
  );

  const sel = usePropertiesSelector<PropertyItem, PropertyInfo>({
    showCodes,
    autoSelectSingleValidValue: true,
    valueComparer: compare,
    lockSingleValidValue: true,
    includeHiddenProperties: sharedState.debugSettings.showHiddenProperties,
    sortValidFirst: true,
    filterPrettyPrint: undefined,
    units: Units,
    unitsFormat: UnitsFormat,
    unitLookup,
    propertyFormats,
    properties: productProperties,
    selectedProperties: selectedProperties,
    onChange: undefined,
    getUndefinedValueItem: () => ({
      id: "",
      value: PropertyValue.fromInteger(0),
      sortNo: -1,
      text: "",
      validationFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      descriptionValuesTexts: [],
      rangeFilter: PropertyFilter.Empty
    }),
    getItemValue: item => item.value,
    getItemFilter: item => item.validationFilter,
    getPropertyInfo: p => p,
    getPropertyItems: p => p.items
  });

  return (
    <PropertiesSelectorInner
      sel={sel}
      selectedProperties={selectedProperties}
      fieldGroup={fieldGroup}
      sharedState={sharedState}
      onFormatCleared={onFormatCleared}
      onFormatChanged={onFormatChanged}
    />
  );
}

function PropertiesSelectorInner({
  sel,
  selectedProperties,
  fieldGroup,
  sharedState,
  onFormatChanged,
  onFormatCleared
}: {
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly fieldGroup: string;
  readonly sharedState: SharedState.State;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}) {
  return (
    <PropertiesSelectorTable>
      <tbody>
        {sel.groups
          .filter(group => sel.getGroupProperties(group).length > 0)
          .map(group => (
            <PropertyGroup
              group={group}
              key={group}
              sel={sel}
              selectedProperties={selectedProperties}
              fieldGroup={fieldGroup}
              sharedState={sharedState}
              onFormatCleared={onFormatCleared}
              onFormatChanged={onFormatChanged}
            />
          ))}
      </tbody>
    </PropertiesSelectorTable>
  );
}

function PropertyGroup({
  group,
  sel,
  selectedProperties,
  fieldGroup,
  sharedState,
  onFormatChanged,
  onFormatCleared
}: {
  readonly group: string;
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly fieldGroup: string;
  readonly sharedState: SharedState.State;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}): JSX.Element {
  const groupedProperties = sel.getGroupProperties(group);
  return (
    <>
      <tr>
        <td></td>
        <td>
          <H3 color="dark" weight="normal">
            {""}
          </H3>
        </td>
      </tr>
      {!sel.isGroupClosed(group) &&
        groupedProperties.map(property => (
          <PropertyRow
            key={property.name}
            property={property}
            sel={sel}
            selectedProperties={selectedProperties}
            fieldGroup={fieldGroup}
            sharedState={sharedState}
            onFormatCleared={onFormatCleared}
            onFormatChanged={onFormatChanged}
          />
        ))}
    </>
  );
}

function PropertyRow({
  property,
  sel,
  selectedProperties,
  fieldGroup,
  sharedState,
  onFormatChanged,
  onFormatCleared
}: {
  readonly property: PropertyInfo;
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly fieldGroup: string;
  readonly sharedState: SharedState.State;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}): JSX.Element {
  return (
    <tr key={property.name}>
      <td>
        <AmountFormatSelectorRow
          property={property}
          sel={sel}
          selectedProperties={selectedProperties}
          fieldGroup={fieldGroup}
          sharedState={sharedState}
          onFormatCleared={onFormatCleared}
          onFormatChanged={onFormatChanged}
        />
      </td>
    </tr>
  );
}

function AmountFormatSelectorRow({
  property,
  sel,
  selectedProperties,
  sharedState,
  fieldGroup,
  onFormatChanged,
  onFormatCleared
}: {
  readonly fieldGroup: string;
  readonly sharedState: SharedState.State;
  readonly property: PropertyInfo;
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}): JSX.Element {
  const selector = sel.getPropertySelectorHook(property);

  const amountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    property.name,
    property.quantity as Quantity.Quantity
  );

  switch (selector.type) {
    case "TextBox":
      return <div />;
    case "Discrete":
      return <div />;
    case "AmountField":
      return (
        <AmountFormatSelector
          type="AmountFormatSelectorProps"
          fieldGroup={fieldGroup}
          fieldName={property.name}
          amountFormat={amountFormat}
          conversionParameters={QuantityConversion.createConversionParameters(
            property.conversionParameters ?? [],
            selectedProperties
          )}
          translate={sharedState.translate}
          onFormatCleared={() => onFormatCleared(fieldGroup, property.name)}
          onFormatChanged={(unit, decimalCount) =>
            onFormatChanged(fieldGroup, property.name, unit, decimalCount)
          }
        />
      );
    default:
      return exhaustiveCheck(selector, true);
  }
}
