import styled from "styled-components";

// tslint:disable-next-line
export const LoadingIndicatorBox = styled.div`
  background: linear-gradient(to top, rgb(186, 186, 186), rgb(245, 245, 245));

  border: 1px solid rgb(112, 131, 148);
  border-radius: 3px;

  box-sizing: border-box;
  padding: 10px 15px 15px 15px;
`;
