import * as React from "react";

interface PropertyLabelProps {
  readonly labelText: string;

  readonly isHidden: boolean;
}

// tslint:disable-next-line:function-name
export function PropertyLabel({
  labelText,
  isHidden
}: PropertyLabelProps): JSX.Element {
  return (
    <td>
      <span
        style={{
          border: (isHidden && "1px solid red") || undefined
        }}
      >
        {labelText}
      </span>
    </td>
  );
}
