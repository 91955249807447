import styled from "styled-components";
import { Img } from "@genesys/ui-elements";

export const GeneralSettingsContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;

  > div + div {
    margin-left: 21px;
  }
`;

export const ControllerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LabelContainer = styled.div`
  margin-bottom: 8px;
`;

export const ProductSelectionContainer = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 80px;
`;

export const ProductRangeContainer = styled.div`
  position: relative;
  top: 30px;
  left: 70px;
`;

export const ProductSelectContainer = styled.div`
  margin-top: 30px;
`;

export const QuickSelectContainer = styled.div`
  margin-top: 80px;
`;

export const QuickSelectOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 17px;

  > div + div {
    margin-left: 30px;
  }
`;

export const QuickSelectOptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background: linear-gradient(180deg, #f8f8f8 0%, #fafafa 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  width: 200px;
  padding-left: 26px;
  padding-right: 26px;

  &:hover {
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #f1f5fa;
    background-color: #ffffff;
    background: none;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const UpperText = styled.span`
  text-transform: uppercase;
`;

export const QuickSelectOptionCardImage = styled(Img)`
  margin-top: 22px;
  height: 100px;
  width: auto;
`;

export const Divider = styled.hr`
  background-color: #d2d5d8;
  width: calc(100% + 52px);
  height: 1px;
`;

export const QuickSelectOptionCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  margin-bottom: 20px;
  font-size: 0.8667rem;
  letter-spacing: 0.1333rem;
  line-height: 1.1333rem;
`;

export const QuickSelectOptionCardTitle = styled.div`
  margin-top: 11px;
  margin-bottom: 13px;
  flex-grow: 1;
  text-align: center;
`;

export const FakeSelect = styled.div`
  box-sizing: border-box;
  height: 48px;
  width: 455px;
  border: 1px solid #d2d5d8;
  background-color: #ffffff;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3d3d3d;
    padding-left: 20px;
    display: inline-block;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
`;
