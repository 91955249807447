import * as React from "react";
import * as SharedState from "../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { OverlayLoader } from "@genesys/ui-elements";
import { Root } from "./elements";
import { MainContentView, TopContentView } from "./components";
import { ModalComponent } from "../shared-manager-components";

export function PricingManagerView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  const canSeePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );

  if (!canUsePricing && !canSeePricing) {
    return <Root>Missing Claims</Root>;
  }

  if (!state.productQueryResult) {
    return <OverlayLoader />;
  }

  return (
    <Root>
      {state.modal && (
        <ModalComponent
          sharedState={sharedState}
          header={state.modal.header}
          content={state.modal.content}
          onClose={state.modal.onClose}
          onConfirm={state.modal.onConfirm}
        />
      )}
      <TopContentView
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />

      <MainContentView
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />
    </Root>
  );
}
