import React from "react";
import { PropertyInput } from "../property-input/property-input";
import {
  getUseAmountPropertyInput,
  UseAmountPropertyInputOptions
} from "../property-input";
import {
  getUseAmountFormatSelector,
  AmountFormatSelector,
  UseAmountFormatSelectorOptions
} from "../amount-format-selector";
import { Root } from "./elements";
import { Amount, Quantity, Unit } from "@genesys/uom";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ConversionParameters } from "@genesys/shared/src/quantity-conversion";
import { createFilterPrettyPrint } from "@genesys/client-core/lib/filter-pretty-print";

export type AmountPropertySelectorProps =
  | UseAmountPropertySelectorOptions
  | SimplifiedProps;

export type UseAmountPropertySelectorOptions = {
  readonly type: "with-use-amount-options";
} & UseAmountPropertyInputOptions &
  UseAmountFormatSelectorOptions;

export type SimplifiedProps = {
  readonly type: "with-simplified-props";
  readonly fieldName: string;
  readonly fieldGroup: string;
  readonly readOnly: boolean;
  readonly propertyName: string;
  readonly quantity: Quantity.Quantity;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly extraLabel?: string;
  readonly validationFilter?: PropertyFilter.PropertyFilter;
  readonly conversionParameters?: ConversionParameters;
  readonly translate: LanguageTexts.Translate;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly onFormatCleared: () => void;
  readonly onValueChange: (
    newAmount: Amount.Amount<Quantity.Quantity> | undefined
  ) => void;
  readonly onFormatChanged: (
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
};

export function AmountPropertySelector(
  props: AmountPropertySelectorProps
): JSX.Element {
  const getProps = () => {
    const effectiveProps =
      props.type === "with-use-amount-options"
        ? props
        : parseSimpleProps(props);

    return {
      ...getUseAmountPropertyInput(effectiveProps),
      ...getUseAmountFormatSelector(effectiveProps)
    };
  };

  const {
    value,
    isReadOnly,
    errorMessage,
    onChange,
    type,
    unitItems,
    fieldName,
    fieldGroup,
    extraLabel,
    selectedUnit,
    isUserDefined,
    precisionValues,
    selectedPrecision,
    isOpen: IsOpenAmountFormatSelector,
    onClear,
    onCancel,
    translate,
    onChangeUnit,
    openSelector,
    onChangePrecision
  } = getProps();

  return (
    <Root>
      {!IsOpenAmountFormatSelector && (
        <PropertyInput
          value={value}
          isReadOnly={isReadOnly}
          errorMessage={errorMessage}
          onChange={onChange}
        />
      )}
      <AmountFormatSelector
        type={type}
        unitItems={unitItems}
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        extraLabel={extraLabel}
        selectedUnit={selectedUnit}
        isUserDefined={isUserDefined}
        precisionValues={precisionValues}
        isOpen={IsOpenAmountFormatSelector}
        selectedPrecision={selectedPrecision}
        onClear={onClear}
        onCancel={onCancel}
        translate={translate}
        onChangeUnit={onChangeUnit}
        openSelector={openSelector}
        onChangePrecision={onChangePrecision}
      />
    </Root>
  );
}

function parseSimpleProps(
  simplifiedProps: SimplifiedProps
): UseAmountPropertyInputOptions & UseAmountFormatSelectorOptions {
  const {
    quantity,
    readOnly,
    fieldName,
    fieldGroup,
    extraLabel,
    propertyName,
    propertyValueSet,
    conversionParameters,
    validationFilter = PropertyFilter.Empty,
    translate,
    onValueChange,
    getAmountFormat,
    onFormatChanged,
    onFormatCleared
  } = simplifiedProps;

  const amountFormat = getAmountFormat(fieldGroup, fieldName, quantity);

  const filterPrettyPrint = createFilterPrettyPrint(
    (propertyName: string, translateArg: LanguageTexts.Translate) =>
      translateArg(LanguageTexts.globalPropertyName(propertyName)),
    (
      propertyName: string,
      propertyValue: number,
      translateArg: LanguageTexts.Translate
    ) =>
      translateArg(
        LanguageTexts.globalPropertyName(propertyName + "_" + propertyValue)
      ),
    translate,
    getAmountFormat,
    fieldGroup,
    () => undefined
  );

  return {
    readOnly: readOnly,
    fieldName: fieldName,
    fieldGroup: fieldGroup,
    extraLabel: extraLabel,
    amountFormat: amountFormat,
    propertyName: propertyName,
    inputUnit: amountFormat.unit,
    isLockedByValueSource: false,
    validationFilter: validationFilter,
    propertyValueSet: propertyValueSet,
    isRequiredMessage: "value_is_required",
    notNumericMessage: "value_must_be_numeric",
    conversionParameters: conversionParameters,
    originalQuantity: amountFormat.unit.quantity,
    inputDecimalCount: amountFormat.decimalCount,
    translate: translate,
    onFormatChanged: onFormatChanged,
    onFormatCleared: onFormatCleared,
    filterPrettyPrint: filterPrettyPrint,
    onValueChange: value => {
      if (value === undefined || value.type === "amount") {
        onValueChange(value?.value as Amount.Amount<Quantity.Quantity>);
        return;
      }
    }
  };
}
