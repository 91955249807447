import { Quantity } from "@genesys/uom";
import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import * as CalculationLib from "@munters/calculations";
import { ValueSource } from "./types";
import { PropertyValueSource } from "../properties-selector";

export function runValueSources(
  pvs: PropertyValueSet.PropertyValueSet,
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  climateData: PropertyValueSet.PropertyValueSet,
  definition: ReadonlyArray<{
    readonly name: string;
    readonly valueSources: ReadonlyArray<PropertyValueSource>;
  }>
): PropertyValueSet.PropertyValueSet {
  const pvsWithAdjustedValueSources = sortOutTheValueSources(pvs, definition);
  const calculatedValueSources = applyValueSources(
    pvsWithAdjustedValueSources,
    operatingCases,
    climateData,
    definition
  );

  return calculatedValueSources;
}

function valueSourceSelector(
  valueSource: ValueSource,
  parameter: string | null,
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  operatingCase: PropertyValueSet.PropertyValueSet,
  property: string,
  climateData: PropertyValueSet.PropertyValueSet
): PropertyValue.PropertyValue {
  switch (valueSource) {
    case "ClimateData":
      return climateDataValueSource(parameter, climateData);
    case "ClimatePressure":
      return climatePressureValueSource(climateData);
    case "InheritedFromFirstOpCase":
      return inheritedFromFirstOpCaseValueSource(operatingCases, property);
    case "Expression":
      return expressionValueSource(parameter, operatingCase);
    default:
      return PropertyValueSet.getValue(property, operatingCase);
  }
}

function climateDataValueSource(
  parameters: string | null,
  climateData: PropertyValueSet.PropertyValueSet
): PropertyValue.PropertyValue {
  if (!parameters) {
    throw new Error("This value source requires a parameter");
  }
  return PropertyValueSet.getValue(parameters, climateData);
}

function climatePressureValueSource(
  climateData: PropertyValueSet.PropertyValueSet
): PropertyValue.PropertyValue {
  const customPressure =
    PropertyValueSet.getInteger("custompressure", climateData) === 1;
  return PropertyValue.fromAmount(
    customPressure
      ? PropertyValueSet.getAmount("atmosphericpressure", climateData)!
      : CalculationLib.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValueSet.getAmount<Quantity.Length>("altitude", climateData)!
        )
  );
}

function inheritedFromFirstOpCaseValueSource(
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  property: string
): PropertyValue.PropertyValue {
  const firstOperatingCase = operatingCases
    .map(pss => pss)
    .sort(
      (a, b) =>
        PropertyValueSet.getInteger("sortno", a)! -
        PropertyValueSet.getInteger("sortno", b)!
    )[0];
  return PropertyValueSet.getValue(property, firstOperatingCase);
}

function expressionValueSource(
  parameters: string | null,
  _: PropertyValueSet.PropertyValueSet
): PropertyValue.PropertyValue {
  if (!parameters) {
    throw new Error("This value source requires a parameter");
  }
  throw new Error("Expression value source is not supported yet");
}

function sortOutTheValueSources(
  pvs: PropertyValueSet.PropertyValueSet,
  definition: ReadonlyArray<{
    readonly name: string;
    readonly valueSources: ReadonlyArray<PropertyValueSource>;
  }>
): PropertyValueSet.PropertyValueSet {
  const propertyNames = PropertyValueSet.getPropertyNames(pvs).filter(
    p => !p.startsWith("source_")
  );

  const valueSources = PropertyValueSet.removeProperties(propertyNames, pvs);
  const properties = PropertyValueSet.keepProperties(propertyNames, pvs);

  const newValueSources = propertyNames.reduce((a, b) => {
    const def = definition.find(d => d.name === b);
    if (!def) {
      return a;
    }
    const prevValue = PropertyValueSet.getInteger("source_" + b, valueSources);
    const prevValueSource = def.valueSources.find(vs => vs.value === prevValue);
    const nextValue =
      prevValue !== undefined &&
      prevValueSource &&
      PropertyFilter.isValid(pvs, prevValueSource.propertyFilter)
        ? prevValue
        : (() => {
            const valueSource = def.valueSources.find(vs =>
              PropertyFilter.isValid(pvs, vs.propertyFilter)
            );
            return valueSource ? valueSource.value : undefined;
          })();
    // tslint:disable-next-line
    return nextValue !== undefined && ~nextValue
      ? PropertyValueSet.merge(
          a,
          PropertyValueSet.fromProperty(
            "source_" + b,
            PropertyValue.fromInteger(nextValue)
          )
        )
      : a;
  }, PropertyValueSet.Empty);

  return PropertyValueSet.merge(properties, newValueSources);
}

function applyValueSources(
  pvs: PropertyValueSet.PropertyValueSet,
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  climateData: PropertyValueSet.PropertyValueSet,
  definition: ReadonlyArray<{
    readonly name: string;
    readonly valueSources: ReadonlyArray<PropertyValueSource>;
  }>
): PropertyValueSet.PropertyValueSet {
  const valueSources = PropertyValueSet.getPropertyNames(pvs)
    .filter(p => p.startsWith("source_"))
    .map(p => p.replace("source_", ""))
    .reduce(
      (a, b) => ({
        ...a,
        [b]: definition
          .find(d => d.name === b)!
          .valueSources.find(
            vs => vs.value === PropertyValueSet.getInteger("source_" + b, pvs)!
          )
      }),
      {} as { readonly [key: string]: PropertyValueSource }
    );

  return Object.keys(valueSources).reduce(
    (a, b) =>
      valueSources[b]
        ? PropertyValueSet.merge(
            PropertyValueSet.fromProperty(
              b,
              valueSourceSelector(
                valueSources[b]!.propertyValueSourceId as ValueSource,
                valueSources[b]!.parameters!,
                operatingCases,
                pvs,
                b,
                climateData
              )
            ),
            a
          )
        : a,
    pvs
  );
}
