import styled from "styled-components";

interface Props {
  readonly isCollapsed: boolean;
}

// tslint:disable-next-line:variable-name
export const ExpanderArrow = styled.div<Props>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #adadad;
  background: linear-gradient(0deg, #adadad, #e9e9e9 80%);

  &:hover {
    border: 1px solid #528ebb;
    background: linear-gradient(0deg, #528ebb, #e9e9e9 80%);
  }

  span::before {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    border-color: grey;
    content: "";
    display: inline-block;
    height: 0.35em;
    left: 0.4em;
    position: relative;
    top: ${props => (props.isCollapsed ? "0.3em" : "0.5em")};
    transform: ${props =>
      props.isCollapsed ? "rotate(135deg)" : "rotate(-45deg)"};
    vertical-align: top;
    width: 0.35em;
  }
`;
