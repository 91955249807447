import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import { Root, GroupFiltersContainer, BodyContainer } from "./elements";
import * as Product from "../../product";
import * as System from "../../system";
import * as ProductData from "@genesys/shared/lib/product-data";
import { GroupFilterComponent } from "./components/group-filters-component";
import { ExpandIcon, CollapseIcon, H3 } from "@genesys/ui-elements";
import { OuterContentContainer, InnerContentContainer } from "./elements";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PropertiesSelector from "../../../properties-selector";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";

export function PropertyGroupsView({
  state,
  dispatch,
  sharedState,
  system,
  products
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemType: Product.SystemType;
  readonly system: System.System;
  readonly products: ReadonlyArray<Product.Product>;
}): JSX.Element {
  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const sysProperties = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const allProducts = ProductData.getValidProductsForRange(
    products,
    sysProperties
  ).filter(p => ProductData.filterProductForRange(p, sysProperties));

  const productDataById: Map<string, Product.Product> = new Map(
    allProducts.map(p => [p.id, p] as [string, Product.Product])
  );

  const availableGroups = new Set<string>();

  for (const p of state.propertyGroups) {
    const productData = productDataById.get(p.component.productId);
    if (!productData) {
      continue;
    }

    for (const property of productData.properties) {
      availableGroups.add(property.group);
    }
  }

  return (
    <Root>
      <GroupFiltersContainer>
        <GroupFilterComponent
          sharedState={sharedState}
          availableGroups={Array.from(availableGroups.values())}
          groupFilter={state.groupFilter}
          onClearClick={() => dispatch(Action.clearGroupFilter())}
          onGroupClick={group => dispatch(Action.toggleGroupFilter(group))}
        />
      </GroupFiltersContainer>

      <BodyContainer>
        <OuterContentContainer>
          {state.propertyGroups.map(propertyGroup => {
            const productData = allProducts.find(
              product => product.id === propertyGroup.component.productId
            );

            if (!productData) {
              return undefined;
            }

            const filteredProductData = ProductDataTools.filterProductForRange(
              productData,
              sysProperties
            );

            return (
              <InnerContentContainer key={propertyGroup.component.id}>
                <a
                  onClick={() =>
                    dispatch(Action.toggleCollapsed(propertyGroup.component.id))
                  }
                >
                  <H3
                    weight="normal"
                    color={
                      state.collapsed[propertyGroup.component.id]
                        ? "dark"
                        : "primary"
                    }
                  >
                    {LanguageTexts.getComponentLabel(
                      sharedState.translate,
                      propertyGroup.component.productId,
                      propertyGroup.state.properties
                    )}
                  </H3>

                  {state.collapsed[propertyGroup.component.id] ? (
                    <ExpandIcon />
                  ) : (
                    <CollapseIcon />
                  )}
                </a>

                {!state.collapsed[propertyGroup.component.id] && (
                  <PropertiesSelector.PropertiesSelectorView
                    dispatch={Dispatch.map(
                      action =>
                        Action.dispatchPropertiesSelector(
                          system.id,
                          propertyGroup.component.id,
                          action
                        ),
                      dispatch
                    )}
                    productId={productData.id}
                    sharedState={sharedState}
                    propertiesInfo={filteredProductData.properties}
                    fieldGroup={`Settings.${productData.id}`}
                    isReadonly={system.status >= SystemStatus.LockSuccess}
                    showCodes={showCodes}
                    state={propertyGroup.state}
                    hideGroupNames={false}
                    hidePropertyNames={false}
                    groupFilter={state.groupFilter}
                  />
                )}
              </InnerContentContainer>
            );
          })}
        </OuterContentContainer>
      </BodyContainer>
    </Root>
  );
}
