import React from "react";
import styled from "../../styled-components";
import { ArrowRightLongBlue, ArrowRightLongWhite } from "../../icons";
import { StyledButton, ButtonType, Size } from "./standard-button";

// const ButtonInline = styled.button<{
//   readonly isDisabled: boolean;
// }>`
//   color: #00adf2;
//   ;
//   font-size: 9px;
//   font-weight: bold;
//   letter-spacing: 0.5px;
//   /* line-height: 24px; */
//   display: inline-flex;
//   background-color: white;
//   align-items: center;
//   text-transform: uppercase;
//   opacity: ${props => (props.isDisabled ? "0.5" : "1")};

//   label {
//     margin-right: 2px;
//     ${props => !props.isDisabled && "cursor: pointer"};
//   }

//   img {
//     ${props => !props.isDisabled && "cursor: pointer"};
//     height: 5px;
//     width: 11px;
//     transform: rotate(-90deg);
//     margin-bottom: 0.3px;
//   }
// `;

const ButtonInline = styled(StyledButton)<{
  readonly isDisabled: boolean;
}>`
  padding: 4px 8px;
  font-size: 11px;

  label {
    margin-right: 2px;
    ${props => !props.isDisabled && "cursor: pointer"};
  }
`;

export function InlineButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  return (
    <InlineButtonComp buttonType="Secondary" size="Small" {...props} />
    // <ButtonInline
    //   {...rest}
    //   buttonType="SecondaryBlue"
    //   size="Small"
    //   isDisabled={!!rest.disabled}
    //   onMouseEnter={onMouseEnterhandler}
    //   onMouseLeave={event => {
    //     event.stopPropagation();
    //     event.preventDefault();
    //     // if (state.imgSrcForInline !== ArrowDown) {
    //     //   setState({
    //     //     imgSrcForInline: ArrowRightLongBlue
    //     //   });
    //     // }
    //   }}
    // >
    //   <SomeImage /> <label>{children}</label>
    // </ButtonInline>
  );
}

export const InlineButtonComp = ({
  children,
  buttonType,
  size,
  ...rest
}: {
  readonly buttonType: ButtonType;
  readonly size: Size;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const getImage = () => {
    if (buttonType === "Primary" || buttonType === "Primary-2") {
      return ArrowRightLongWhite;
    }

    return ArrowRightLongBlue;
  };

  const SomeImage = getImage();

  return (
    <ButtonInline
      {...rest}
      buttonType={buttonType}
      size={size}
      isDisabled={!!rest.disabled}
      onMouseLeave={event => {
        event.stopPropagation();
        event.preventDefault();
        // if (state.imgSrcForInline !== ArrowDown) {
        //   setState({
        //     imgSrcForInline: ArrowRightLongBlue
        //   });
        // }
      }}
    >
      <SomeImage /> <label>{children}</label>
    </ButtonInline>
  );
};
