import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { PrimaryColors, Label } from "@genesys/ui-elements";
import { Tab } from "./type";

const Container = styled.ul`
  display: flex;
  list-style: none;
`;

const StyledNormalTab = styled.li`
  margin-right: 25px;
  padding: 10px;
  /* font-weight: bold; */
  letter-spacing: 1px;
  span {
    font-size: 17px;
  }
  cursor: pointer;
`;

const StyledActiveTab = styled(StyledNormalTab)`
  border-bottom: 3px solid ${PrimaryColors.muntersBlue};
`;

export function Tabs(props: {
  readonly currentPageTab: Tab;
  readonly hasCalResult: boolean;
  readonly isCalculating: boolean;
  readonly translate: (textDefinition: LanguageTexts.TextDefinition) => string;
  readonly changePageTab: (tab: Tab) => void;
}) {
  return (
    <Container>
      {renderTabs(
        props.currentPageTab,
        props.hasCalResult,
        props.isCalculating,
        props.translate,
        props.changePageTab
      )}
    </Container>
  );
}

interface TabDefinnition {
  readonly id: string;
  readonly tab: JSX.Element;
  readonly visible: boolean;
}

const renderTabs = (
  currentPageTab: Tab,
  hasCalResult: boolean,
  isCalculating: boolean,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  changePageTab: (tab: Tab) => void
) => {
  const tabDefs: ReadonlyArray<TabDefinnition> = [
    {
      id: "building-and-system-config",
      tab: createTab(
        translate(LanguageTexts.mlcSystemAndBuildingConfig()),
        currentPageTab === "building-and-system-config",
        () => changePageTab("building-and-system-config")
      ),
      visible: true
    },
    {
      id: "moisture-and-heat-load-tab",
      tab: createTab(
        translate(LanguageTexts.mlcMoistureHeatLoadTypes()),
        currentPageTab === "moisture-heat-load-types",
        () => changePageTab("moisture-heat-load-types")
      ),
      visible: true
    },

    {
      id: "result-tab",
      tab: createTab(
        translate(LanguageTexts.results()),
        currentPageTab === "result",
        () => changePageTab("result")
      ),
      visible: hasCalResult || isCalculating
    }
  ].filter(x => x.visible);

  return (
    <>
      {tabDefs.map(tabDef => (
        <React.Fragment key={tabDef.id}>{tabDef.tab}</React.Fragment>
      ))}
    </>
  );
};

const createTab = (
  tabName: string,
  isActive: boolean,
  onClick?: () => void
) => {
  const TabComponent = isActive ? StyledActiveTab : StyledNormalTab;
  return (
    <TabComponent onClick={onClick}>
      <Label weight="normal" color="dark">
        {tabName}
      </Label>
    </TabComponent>
  );
};
