import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemPerformance from "@genesys/client-core/lib/system-performance";
import * as State from "./state";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { H2, WeatherIcon, OperatingCasesSelect } from "@genesys/ui-elements";
import {
  HeadingContainer,
  Container,
  Root,
  OperatingCasesContainer,
  DropDownContainer,
  LeftSideContainer,
  ExtraInfoTableContainer
} from "./elements";
import { Props, PropsWithOpc } from "./types";
import { PerformanceOverview } from "./performance-overview";
import { ExtraInfoTable } from "./extra-info-table";
import { Dispatch } from "@typescript-tea/core";

export function PerformanceOverviewView(props: Props): JSX.Element {
  const { state, sharedState, systemItem } = props;
  // Only render if system status allows it
  if (systemItem.status < SystemStatus.DesignCalculationSuccess) {
    return <></>;
  }

  // Define operating cases and selected case ID
  const opCases = SystemPerformance.toOpCases(
    sharedState.translate,
    systemItem.operatingCases
  );
  const selectedOpCaseId =
    state.selectedOpCaseId &&
    opCases.some(opc => opc.id === state.selectedOpCaseId)
      ? state.selectedOpCaseId
      : opCases[0]?.id;

  return (
    <Root>
      <Container>
        <HeadingContainer>
          <H2 color="primary" weight="bold">
            {sharedState.translate(LanguageTexts.airLocations())}
          </H2>
        </HeadingContainer>
        <DropDownContainer>
          <LeftPanel {...{ ...props, opCases, selectedOpCaseId }} />
          <PerformanceOverview {...{ ...props, opCases, selectedOpCaseId }} />
        </DropDownContainer>
      </Container>
    </Root>
  );
}

// Component for the left panel, including dropdown and extra info table
const LeftPanel = (props: PropsWithOpc) => (
  <LeftSideContainer>
    <OperatingCaseDropDown {...{ ...props }} />
    <ExtraInfoTableContainer>
      <ExtraInfoTable {...{ ...props }} />
    </ExtraInfoTableContainer>
  </LeftSideContainer>
);

// Dropdown for selecting an operating case
const OperatingCaseDropDown = ({
  sharedState,
  opCases,
  selectedOpCaseId,
  dispatch
}: PropsWithOpc) => (
  <OperatingCasesContainer>
    <H2 color="dark" weight="normal">
      <WeatherIcon />
      {sharedState.translate(LanguageTexts.operatingCases())}
    </H2>
    <OperatingCasesSelect
      value={selectedOpCaseId}
      options={opCases.map(op => ({ value: op.id, title: op.name }))}
      onChange={handleCaseSelection(dispatch)}
    />
  </OperatingCasesContainer>
);

// Helper function for handling dropdown selection
const handleCaseSelection = (dispatch: Dispatch<State.Action>) => (e: any) => {
  e.preventDefault();
  dispatch(State.Action.SelectOpCase(e.target.value));
};
