import * as React from "react";
import { Quantity, Units } from "@genesys/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";

export interface PropertiesSelectorContextValue {
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly conversionParametersMap: {
    readonly [propertyName: string]:
      | QuantityConversion.ConversionParameters
      | undefined;
  };
  readonly fieldGroup: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly layoutRendererExtraProps: GenesysPropertiesSelectorTypes.LayoutRendererExtraProps;
  readonly onQuantityChange: (
    propertyName: string,
    quantity: Quantity.Quantity
  ) => void;
  readonly valueSources:
    | {
        readonly [
          propertyName: string
        ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
      }
    | undefined;
  readonly groupFilter: Set<string>;
  readonly showGroups: boolean;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly fieldNameMap: { readonly [key: string]: string };
}

export const propertiesSelectorContext =
  React.createContext<PropertiesSelectorContextValue>({
    activatedQuantitySelectors: {},
    conversionParametersMap: {},
    fieldGroup: "",
    filterPrettyPrint: () => "",
    getAmountFormat: () => {
      return {
        decimalCount: 0,
        unit: Units.Ampere,
        userDefined: false
      };
    },
    layoutRendererExtraProps: {
      fieldGroup: "",
      hidePropertyNames: false,
      onDelete: () => {
        /*  */
      },
      productProperties: [],
      propertyFormats: {},
      sections: [],
      uniqueId: "",
      showDelete: true
    },
    onQuantityChange: () => {
      /*  */
    },
    valueSources: undefined,
    groupFilter: new Set(),
    showGroups: true,
    translatePropertyValue: () => "",
    fieldNameMap: {}
  });
