import * as PropertiesSelector from "../react-properties-selector";

export function createSelectorInfoMap(
  selectors: ReadonlyArray<PropertiesSelector.PropertySelectorRenderInfo>
): {
  readonly [key: string]: PropertiesSelector.PropertySelectorRenderInfo;
} {
  return selectors.reduce(
    (
      soFar: {
        // tslint:disable-next-line:readonly-keyword
        [key: string]: PropertiesSelector.PropertySelectorRenderInfo;
      },
      selectorInfo
    ) => {
      soFar[selectorInfo.propertyName] = selectorInfo;
      return soFar;
    },
    {}
  );
}
