import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { LabelSelector } from "../shared-manager-components";
import { ModalComponent } from "../modal-component";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton } from "@genesys/ui-elements";

const Container = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  button {
    margin-right: 10px;
  }
`;

export function View({
  sharedState,
  state,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <Container>
      {state.moistureloadUrl && (
        <ModalComponent
          sharedState={sharedState}
          header="Moisture Load Created"
          content={"Open in new tab?"}
          cancelLabel={sharedState.translate(LanguageTexts.openInCurrentTab())}
          confirmLabel={sharedState.translate(LanguageTexts.openInNewTab())}
          onClose={() => {
            dispatch(Action.openMoistureload(false));
          }}
          onConfirm={() => {
            dispatch(Action.openMoistureload(true));
          }}
        />
      )}
      <LabelSelector
        inputName={sharedState.translate(LanguageTexts.moistureLoadName())}
        inputValue={state.name}
        inputOnChange={value => dispatch(Action.setName(value))}
        sharedState={sharedState}
        labelEditorInputs={{
          assignedLabels: state.assignedLabels,
          labelAnchor: state.labelAnchor, // check
          labelManagerState: state.labelManagerState,
          onAssign: values => {
            dispatch(Action.setAssignedlables(values));
            dispatch(Action.toggleLabelManager(null));
          },
          dispatchMap: Dispatch.map(Action.dispatchLabelManager, dispatch),
          onToggleLabelManager(element) {
            dispatch(Action.toggleLabelManager(element));
          }
        }}
      />

      <ButtonsContainer>
        <StandardButton
          buttonType="Primary-2"
          size="Small"
          onClick={() => {
            dispatch(Action.createMoistureLoad());
          }}
        >
          {sharedState.translate(LanguageTexts.create())}
        </StandardButton>
      </ButtonsContainer>
    </Container>
  );
}
