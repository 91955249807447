import * as React from "react";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }
  90% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
`;

export function unstyledLoader(props: any) {
  return (
    <div {...props}>
      <div />
      <div />
      <div />
    </div>
  );
}

// tslint:disable-next-line:variable-name
export const Loader = styled(unstyledLoader)`
  transform: scale(0.3);
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;

  top: 0;

  > div {
    position: absolute;
    width: 40px;
    height: 120px;
    top: 40px;
    left: 20px;
    background: #3769c8;
    opacity: 0.6;
    animation: ${pulse} 1s cubic-bezier(0.01, 0.73, 0.28, 0.93) infinite;
  }
  > div:nth-of-type(2) {
    left: 80px;
    opacity: 0.8;
    animation-delay: 0.1s;
  }

  > div:nth-of-type(3) {
    left: 140px;
    opacity: 0.9;
    animation-delay: 0.2s;
  }
`;
