import { Unit, Quantity, Amount } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";

export function createOnPropertyValueSetChange(
  outerOnChange: (
    properties: PropertyValueSet.PropertyValueSet,
    changedProperties: ReadonlyArray<string>
  ) => void,
  originalProductPropertiesMap: Map<
    string,
    GenesysPropertiesSelectorTypes.Property
  >
): (
  properties: PropertyValueSet.PropertyValueSet,
  changedProperties: ReadonlyArray<string>
) => void {
  return (
    properties: PropertyValueSet.PropertyValueSet,
    changedProperties: ReadonlyArray<string>
  ) => {
    const propertiesConvertedToOriginalQuanitities = checkSelectedPropertiesForConvertedQuantities(
      originalProductPropertiesMap,
      properties
    );
    outerOnChange(propertiesConvertedToOriginalQuanitities, changedProperties);
  };
}

export function createOnQuantityChange(
  onPropertyFormatChanged: (
    propertyName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ) => void,
  measureSystem: number,
  quantityDefaults: ScreenAmounts.QuantityDefaultsMap
): (propertyName: string, quantity: Quantity.Quantity) => void {
  return (propertyName: string, quantity: Quantity.Quantity) => {
    // Take the default unit. The user have to change to the desired unit in the new quantity.
    const quantityDefault = QuantityConversion.getQuantityDefault(
      quantityDefaults,
      measureSystem,
      quantity
    );
    onPropertyFormatChanged(
      propertyName,
      quantityDefault.unit,
      quantityDefault.decimalCount
    );
  };
}

function checkSelectedPropertiesForConvertedQuantities(
  originalProductPropertiesMap: Map<
    string,
    GenesysPropertiesSelectorTypes.Property
  >,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getPropertyNames(selectedProperties).reduce(
    (soFar: PropertyValueSet.PropertyValueSet, propertyName: string) => {
      const amount = PropertyValueSet.getAmount(
        propertyName,
        selectedProperties
      ) as Amount.Amount<Quantity.Quantity>;

      if (!amount) {
        return soFar;
      }

      const originalProductProperty = originalProductPropertiesMap.get(
        propertyName
      );

      if (!originalProductProperty) {
        return soFar;
      }

      const originalQuantity = originalProductProperty.quantity;

      if (originalQuantity === amount.unit.quantity) {
        return soFar;
      }

      const convertedAmount = QuantityConversion.convertQuantity(
        amount,
        originalQuantity as Quantity.Quantity,
        originalProductProperty.conversionParameters as QuantityConversion.ConversionParameters
      );
      return PropertyValueSet.setAmount(propertyName, convertedAmount, soFar);
    },
    selectedProperties
  );
}
