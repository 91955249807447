import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const NotLockedSystemBox = styled.div`
  padding: 40px;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 23px 16px 25px;
`;

export const NavigationRoot = styled.div`
  display: flex;

  button {
    margin-left: 16px;
  }
`;

export const FluidCoilsRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
  height: 600px;
`;

export const FluidCoilSelectorContainer = styled.div`
  width: 425px;
  padding: 12px;
  p {
    padding-left: 12px;
  }
`;

export const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
`;

export const PerformanceViewRoot = styled.div`
  padding: 0px 16px;
  min-height: 500px;
`;
