export const All = "All";
export const Empty = "Empty";

export type TraceSpan = {
  readonly serviceName: string;
  readonly spanId: string;
  readonly name: string;
  readonly parentSpanId: string;
  readonly startTimeUnixNano: number;
  readonly endTimeUnixNano: number;
  readonly parallellGroupId: string;
  readonly isFireAndForget: boolean;
  readonly isSummarySpan: boolean;
};

export type LogRowFromServer = {
  readonly timestamp: number;
  readonly serviceName: string;
  readonly body: string;
  readonly category: string;
  readonly errorCode: number;
  readonly caseType: string;
  readonly caseName: string;
  readonly component: string;
};

export type LogRow = LogRowFromServer & {
  readonly id: number;
  readonly formatted: string;
};
