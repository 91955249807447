import * as KnownProperties from "./known-properties";
import * as KnownUserSettings from "./known-user-settings";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import * as GraphQLTypes from "@genesys/graphql-types";
import {
  formatAnnualOccurence,
  integerToAnnualOccurence,
  annualOccurenceToInteger,
  parseFormattedAnnualOccurence,
  ClimateAnnualOccurence
} from "./climate-annual-occurences";
import * as Calculations from "@munters/calculations";
import { getDataPoint, getLocation } from "./functions";
import {
  integerToClimateDataType,
  climateDataTypeToInteger,
  ClimateDataType,
  makeClimateDataType
} from "./climate-data-types";
import { FullLocation } from "./types";

export interface InternalRepresentationBase {
  readonly customPressure?: Amount.Amount<Quantity.Pressure>;
  readonly locationId: string;
}

export interface AshraeClimateCase {
  readonly dataType: ClimateDataType;
  readonly annualOccurence: ClimateAnnualOccurence;
}
export interface ManualClimateCase {
  readonly dataType: ClimateDataType;
  readonly annualOccurence: ClimateAnnualOccurence;
  readonly temperature: Amount.Amount<Quantity.Temperature>;
  readonly humidity: Amount.Amount<Quantity.HumidityRatio>;
  readonly windSpeed: Amount.Amount<Quantity.Velocity>;
}

export interface InternalRepresentationAshrae {
  readonly climateDataSource: "ASHRAE";
  readonly altitude: Amount.Amount<Quantity.Length>;
  readonly cooling: AshraeClimateCase;
  readonly heating: AshraeClimateCase;
}

export interface InternalRepresentationManual {
  readonly climateDataSource: "Manual";
  readonly altitude: Amount.Amount<Quantity.Length>;
  readonly cooling: ManualClimateCase;
  readonly heating: ManualClimateCase;
}

export type InternalRepresentation = InternalRepresentationBase &
  (InternalRepresentationAshrae | InternalRepresentationManual);

export function publicFacingPvsToInternal(
  pvs: PropertyValueSet.PropertyValueSet
): InternalRepresentation {
  const climateDataSource = PropertyValueSet.hasProperty(
    KnownProperties.manualData.wmo,
    pvs
  )
    ? "ASHRAE"
    : "Manual";

  const common = {
    customPressure: PropertyValueSet.getInteger(
      KnownProperties.manualData.customPressure,
      pvs
    )
      ? PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.manualData.atmosphericPressure,
          pvs
        )
      : undefined
  };

  if (climateDataSource === "ASHRAE") {
    return {
      ...common,
      altitude: PropertyValueSet.getAmount<Quantity.Length>(
        KnownProperties.manualData.altitude,
        pvs
      )!,

      locationId: PropertyValueSet.getText(
        KnownProperties.manualData.wmo,
        pvs
      )!,
      climateDataSource,
      cooling: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingDataType,
            pvs
          )!
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingAnnualOccurence,
            pvs
          )!
        )
      },
      heating: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingDataType,
            pvs
          )!
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingAnnualOccurence,
            pvs
          )!
        )
      }
    };
  }

  if (climateDataSource === "Manual") {
    return {
      ...common,
      locationId: PropertyValueSet.getText(
        KnownProperties.manualData.wmo,
        pvs
      )!,
      climateDataSource,
      altitude: PropertyValueSet.getAmount<Quantity.Length>(
        KnownProperties.manualData.altitude,
        pvs
      )!,
      //   TODO: Verify that the values are set, and of the correct quantity.
      cooling: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingDataType,
            pvs
          )!
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.coolingAnnualOccurence,
            pvs
          )!
        ),
        temperature: PropertyValueSet.getAmount<Quantity.Temperature>(
          KnownProperties.manualData.summerTemperature,
          pvs
        )!,
        humidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
          KnownProperties.manualData.summerHumidity,
          pvs
        )!,
        windSpeed:
          PropertyValueSet.getAmount<Quantity.Velocity>(
            KnownProperties.manualData.summerWindSpeed,
            pvs
          ) || Amount.create(0, Units.MeterPerSecond)
      },
      heating: {
        dataType: makeClimateDataType(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingDataType,
            pvs
          )!
        ),
        annualOccurence: parseFormattedAnnualOccurence(
          PropertyValueSet.getText(
            KnownProperties.manualData.heatingAnnualOccurence,
            pvs
          )!
        ),
        temperature: PropertyValueSet.getAmount<Quantity.Temperature>(
          KnownProperties.manualData.winterTemperature,
          pvs
        )!,
        humidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
          KnownProperties.manualData.winterHumidity,
          pvs
        )!,
        windSpeed:
          PropertyValueSet.getAmount<Quantity.Velocity>(
            KnownProperties.manualData.winterWindSpeed,
            pvs
          ) || Amount.create(0, Units.MeterPerHour)
      }
    };
  }

  throw new Error('"' + climateDataSource + '" is not a valid data source.');
}

export function internalAshraeToPublicFacingPvs(
  internal: InternalRepresentationBase & InternalRepresentationAshrae,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >,
  dataPoints: ReadonlyArray<
    NonNullable<
      GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
    >[0]
  >
): PropertyValueSet.PropertyValueSet {
  const common: PropertyValueSet.PropertyValueSet = {
    // A concatenated string of all occurences and datatypes.
    [KnownProperties.manualData.occurrences]: PropertyValue.fromText(
      formatAnnualOccurence(internal.cooling.annualOccurence) +
        " " +
        internal.cooling.dataType +
        ", " +
        formatAnnualOccurence(internal.heating.annualOccurence) +
        " " +
        internal.heating.dataType
    ),
    [KnownProperties.manualData.customPressure]: PropertyValue.fromInteger(
      internal.customPressure === undefined ? 0 : 1
    ),
    [KnownProperties.manualData.coolingAnnualOccurence]: PropertyValue.fromText(
      formatAnnualOccurence(internal.cooling.annualOccurence)
    ),
    [KnownProperties.manualData.heatingAnnualOccurence]: PropertyValue.fromText(
      formatAnnualOccurence(internal.heating.annualOccurence)
    ),
    [KnownProperties.manualData.coolingDataType]: PropertyValue.fromText(
      internal.cooling.dataType
    ),
    [KnownProperties.manualData.heatingDataType]: PropertyValue.fromText(
      internal.heating.dataType
    )
  };

  const coolingDataPoint = getDataPoint(
    dataPoints,
    "cooling",
    internal.cooling.dataType,
    internal.cooling.annualOccurence
  );
  const heatingDataPoint = getDataPoint(
    dataPoints,
    "heating",
    internal.heating.dataType,
    internal.heating.annualOccurence
  );

  const fullLocation = getLocation(internal.locationId, countries);

  return {
    ...common,
    [KnownProperties.manualData.summerTemperature]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.temperature)!
      : PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
    [KnownProperties.manualData.winterTemperature]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.temperature)!
      : PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
    [KnownProperties.manualData.summerHumidity]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.humidity)!
      : PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
    [KnownProperties.manualData.winterHumidity]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.humidity)!
      : PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
    [KnownProperties.manualData.summerWindSpeed]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.windSpeed)!
      : PropertyValue.fromAmount(Amount.create(0, Units.MeterPerSecond)),
    [KnownProperties.manualData.winterWindSpeed]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.windSpeed)!
      : PropertyValue.fromAmount(Amount.create(0, Units.MeterPerSecond)),
    [KnownProperties.manualData.wmo]: PropertyValue.fromText(
      internal.locationId
    ),
    [KnownProperties.manualData.locationName]: PropertyValue.fromText(
      fullLocation.countryData.country.name +
        ", " +
        fullLocation.regionData.region.name +
        ", " +
        fullLocation.locationData.location.name
    ),
    [KnownProperties.manualData.latitudeN]: PropertyValue.fromString(
      fullLocation.locationData.location.latitude
    )!,
    [KnownProperties.manualData.longitudeW]: PropertyValue.fromString(
      fullLocation.locationData.location.longitude
    )!,
    [KnownProperties.manualData.binLocationId]: PropertyValue.fromText(
      fullLocation.locationData.location.binLocationId
    ),
    [KnownProperties.manualData.altitude]: PropertyValue.fromString(
      fullLocation.locationData.location.elevation
    )!,
    [KnownProperties.manualData.atmosphericPressure]: PropertyValue.fromAmount(
      // Go with manually set custom pressure...
      internal.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValue.getAmount<Quantity.Length>(
            PropertyValue.fromString(
              fullLocation.locationData.location.elevation
            )!
          )!
        )
    )
  };
}

export function internalManualToPublicFacingPvs(
  internal: InternalRepresentationBase & InternalRepresentationManual
): PropertyValueSet.PropertyValueSet {
  const common: PropertyValueSet.PropertyValueSet = {
    // A concatenated string of all occurences and datatypes.
    [KnownProperties.manualData.occurrences]: PropertyValue.fromText(
      formatAnnualOccurence(internal.cooling.annualOccurence) +
        " " +
        internal.cooling.dataType +
        ", " +
        formatAnnualOccurence(internal.heating.annualOccurence) +
        " " +
        internal.heating.dataType
    ),
    [KnownProperties.manualData.customPressure]: PropertyValue.fromInteger(
      internal.customPressure === undefined ? 0 : 1
    ),
    [KnownProperties.manualData.coolingAnnualOccurence]: PropertyValue.fromText(
      formatAnnualOccurence(internal.cooling.annualOccurence)
    ),
    [KnownProperties.manualData.heatingAnnualOccurence]: PropertyValue.fromText(
      formatAnnualOccurence(internal.heating.annualOccurence)
    ),
    [KnownProperties.manualData.coolingDataType]: PropertyValue.fromText(
      internal.cooling.dataType
    ),
    [KnownProperties.manualData.heatingDataType]: PropertyValue.fromText(
      internal.heating.dataType
    )
  };

  return {
    ...common,
    // [wmoTemporaryStorageNameInPvs]: PropertyValue.fromText(
    //   internal.locationId
    // ),
    [KnownProperties.manualData.summerTemperature]: PropertyValue.fromAmount(
      internal.cooling.temperature
    ),
    [KnownProperties.manualData.winterTemperature]: PropertyValue.fromAmount(
      internal.heating.temperature
    ),
    [KnownProperties.manualData.summerHumidity]: PropertyValue.fromAmount(
      internal.cooling.humidity
    ),
    [KnownProperties.manualData.winterHumidity]: PropertyValue.fromAmount(
      internal.heating.humidity
    ),
    [KnownProperties.manualData.summerWindSpeed]: PropertyValue.fromAmount(
      internal.cooling.windSpeed
    ),
    [KnownProperties.manualData.winterWindSpeed]: PropertyValue.fromAmount(
      internal.heating.windSpeed
    ),

    // Nonsense values to make the server happy. Calculations will crash without them.
    [KnownProperties.manualData.locationName]: PropertyValue.fromText("Manual"),
    [KnownProperties.manualData.latitudeN]: PropertyValue.fromAmount(
      Amount.create(0, Units.Degrees)
    ),
    [KnownProperties.manualData.longitudeW]: PropertyValue.fromAmount(
      Amount.create(0, Units.Degrees)
    ),

    [KnownProperties.manualData.altitude]: PropertyValue.fromAmount(
      internal.altitude
    ),
    [KnownProperties.manualData.atmosphericPressure]: PropertyValue.fromAmount(
      // Go with manually set custom pressure...
      internal.customPressure ||
        // ...or calculate it from manually entered altitude.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          internal.altitude
        )
    )
  };
}

export function internalCoolingCasePvsToManualClimateCase(
  pvs: PropertyValueSet.PropertyValueSet
): ManualClimateCase {
  return {
    dataType: integerToClimateDataType(
      PropertyValueSet.getInteger(KnownProperties.coolingDataTypeInteger, pvs)!
    ),
    annualOccurence: integerToAnnualOccurence(
      PropertyValueSet.getInteger(
        KnownProperties.coolingAnnualOccurenceInteger,
        pvs
      )!
    ),
    temperature: PropertyValueSet.getAmount<Quantity.Temperature>(
      KnownProperties.manualData.summerTemperature,
      pvs
    )!,
    humidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      KnownProperties.manualData.summerHumidity,
      pvs
    )!,
    windSpeed: PropertyValueSet.getAmount<Quantity.Velocity>(
      KnownProperties.manualData.summerWindSpeed,
      pvs
    )!
  };
}

export function internalCoolingCasePvsToAshraeClimateCase(
  pvs: PropertyValueSet.PropertyValueSet
): AshraeClimateCase {
  return {
    dataType: integerToClimateDataType(
      PropertyValueSet.getInteger(KnownProperties.coolingDataTypeInteger, pvs)!
    ),
    annualOccurence: integerToAnnualOccurence(
      PropertyValueSet.getInteger(
        KnownProperties.coolingAnnualOccurenceInteger,
        pvs
      )!
    )
  };
}

export function internalHeatingCasePvsToManualClimateCase(
  pvs: PropertyValueSet.PropertyValueSet
): ManualClimateCase {
  return {
    dataType: integerToClimateDataType(
      PropertyValueSet.getInteger(KnownProperties.heatingDataTypeInteger, pvs)!
    ),
    annualOccurence: integerToAnnualOccurence(
      PropertyValueSet.getInteger(
        KnownProperties.heatingAnnualOccurenceInteger,
        pvs
      )!
    ),
    temperature: PropertyValueSet.getAmount<Quantity.Temperature>(
      KnownProperties.manualData.winterTemperature,
      pvs
    )!,
    humidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      KnownProperties.manualData.winterHumidity,
      pvs
    )!,
    windSpeed: PropertyValueSet.getAmount<Quantity.Velocity>(
      KnownProperties.manualData.winterWindSpeed,
      pvs
    )!
  };
}

export function internalHeatingCasePvsToAshraeClimateCase(
  pvs: PropertyValueSet.PropertyValueSet
): AshraeClimateCase {
  return {
    dataType: integerToClimateDataType(
      PropertyValueSet.getInteger(KnownProperties.heatingDataTypeInteger, pvs)!
    ),
    annualOccurence: integerToAnnualOccurence(
      PropertyValueSet.getInteger(
        KnownProperties.heatingAnnualOccurenceInteger,
        pvs
      )!
    )
  };
}

export function internalPvsToInternal(
  pvs: PropertyValueSet.PropertyValueSet,
  countries: ReadonlyArray<
    GraphQLTypes.ClimateDataDialog_CountriesQuery["product"]["countries"][0]
  >,
  userDefaultLocationId: string
): InternalRepresentation {
  const climateDataSource =
    PropertyValueSet.getInteger(
      KnownUserSettings.selectedClimateDataSourceIndex,
      pvs
    )! === 0
      ? "ASHRAE"
      : "Manual";

  const common = {
    customPressure: PropertyValueSet.getInteger(
      KnownProperties.manualData.customPressure,
      pvs
    )
      ? PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.manualData.atmosphericPressure,
          pvs
        )
      : undefined
  };

  if (climateDataSource === "ASHRAE") {
    const countryIndex = PropertyValueSet.getInteger(
      KnownUserSettings.selectedCountryIndex,
      pvs
    );
    const regionIndex = PropertyValueSet.getInteger(
      KnownUserSettings.selectedRegionIndex,
      pvs
    );
    const locationIndex = PropertyValueSet.getInteger(
      KnownProperties.locationIndex,
      pvs
    );

    const locationId =
      countryIndex !== undefined &&
      regionIndex !== undefined &&
      locationIndex !== undefined
        ? countries[countryIndex].regions[regionIndex].locations[locationIndex]
            .id
        : userDefaultLocationId;

    return {
      ...common,
      altitude: PropertyValueSet.getAmount<Quantity.Length>(
        KnownProperties.manualData.altitude,
        pvs
      )!,
      locationId,
      climateDataSource,
      cooling: internalCoolingCasePvsToAshraeClimateCase(pvs),
      heating: internalHeatingCasePvsToAshraeClimateCase(pvs)
    };
  }

  if (climateDataSource === "Manual") {
    return {
      ...common,
      locationId: PropertyValueSet.getText(
        KnownProperties.manualData.wmo,
        pvs
      )!,
      climateDataSource,
      altitude: PropertyValueSet.getAmount<Quantity.Length>(
        KnownProperties.manualData.altitude,
        pvs
      )!,
      cooling: internalCoolingCasePvsToManualClimateCase(pvs),
      heating: internalHeatingCasePvsToManualClimateCase(pvs)
    };
  }

  throw new Error('"' + climateDataSource + '" is not a valid data source.');
}

export function ashraeCoolingCaseAndDataPointToInternalCoolingCasePvs(
  coolingCase: AshraeClimateCase,
  coolingDataPoint: NonNullable<
    GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
  >[0]
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.coolingAnnualOccurenceInteger]: PropertyValue.fromInteger(
      annualOccurenceToInteger(coolingCase.annualOccurence)
    ),
    [KnownProperties.coolingDataTypeInteger]: PropertyValue.fromInteger(
      climateDataTypeToInteger(coolingCase.dataType)
    ),
    [KnownProperties.manualData.summerTemperature]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.temperature)!
      : PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
    [KnownProperties.manualData.summerHumidity]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.humidity)!
      : PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
    [KnownProperties.manualData.summerWindSpeed]: coolingDataPoint
      ? PropertyValue.fromString(coolingDataPoint.windSpeed)!
      : PropertyValue.fromAmount(Amount.create(0, Units.MeterPerSecond))
  };
}

export function ashraeHeatingCaseAndDataPointToInternalHeatingCasePvs(
  heatingCase: AshraeClimateCase,
  heatingDataPoint: NonNullable<
    GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
  >[0]
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.heatingAnnualOccurenceInteger]: PropertyValue.fromInteger(
      annualOccurenceToInteger(heatingCase.annualOccurence)
    ),
    [KnownProperties.heatingDataTypeInteger]: PropertyValue.fromInteger(
      climateDataTypeToInteger(heatingCase.dataType)
    ),
    [KnownProperties.manualData.winterTemperature]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.temperature)!
      : PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
    [KnownProperties.manualData.winterHumidity]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.humidity)!
      : PropertyValue.fromAmount(Amount.create(0, Units.GramPerKilogram)),
    [KnownProperties.manualData.winterWindSpeed]: heatingDataPoint
      ? PropertyValue.fromString(heatingDataPoint.windSpeed)!
      : PropertyValue.fromAmount(Amount.create(0, Units.MeterPerSecond))
  };
}

export function internalToInternalAshraePvs(
  internal: InternalRepresentationBase & InternalRepresentationAshrae,
  fullLocation: FullLocation,
  coolingDataPoint: NonNullable<
    GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
  >[0],
  heatingDataPoint: NonNullable<
    GraphQLTypes.ClimateDataDialog_DatapointsQuery["product"]["dataPointsForLocationId"]
  >[0]
): PropertyValueSet.PropertyValueSet {
  const common: PropertyValueSet.PropertyValueSet = {
    [KnownUserSettings.selectedClimateDataSourceIndex]:
      PropertyValue.fromInteger(0),
    [KnownProperties.manualData.customPressure]: PropertyValue.fromInteger(
      internal.customPressure === undefined ? 0 : 1
    )
  };

  const latitude = formatLatitude(
    Amount.valueAs(
      Units.Degrees,
      PropertyValue.getAmount<Quantity.Angle>(
        PropertyValue.fromString(fullLocation.locationData.location.latitude)!
      )!
    )
  );

  const longitude = formatLongitude(
    Amount.valueAs(
      Units.Degrees,
      PropertyValue.getAmount<Quantity.Angle>(
        PropertyValue.fromString(fullLocation.locationData.location.longitude)!
      )!
    )
  );

  return {
    ...common,
    ...ashraeHeatingCaseAndDataPointToInternalHeatingCasePvs(
      internal.heating,
      heatingDataPoint
    ),
    ...ashraeCoolingCaseAndDataPointToInternalCoolingCasePvs(
      internal.cooling,
      coolingDataPoint
    ),
    [KnownUserSettings.selectedCountryIndex]: PropertyValue.fromInteger(
      fullLocation.countryData.index
    ),
    [KnownUserSettings.selectedRegionIndex]: PropertyValue.fromInteger(
      fullLocation.regionData.index
    ),
    [KnownProperties.locationIndex]: PropertyValue.fromInteger(
      fullLocation.locationData.index
    ),
    [KnownProperties.manualData.latitudeN]: PropertyValue.fromText(latitude),
    [KnownProperties.manualData.longitudeW]: PropertyValue.fromText(longitude),
    [KnownProperties.manualData.altitude]: PropertyValue.fromString(
      fullLocation.locationData.location.elevation
    )!,
    [KnownProperties.manualData.atmosphericPressure]: PropertyValue.fromAmount(
      // Go with manually set custom pressure...
      internal.customPressure ||
        // ...or calculate it from the altitude of the location.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          PropertyValue.getAmount<Quantity.Length>(
            PropertyValue.fromString(
              fullLocation.locationData.location.elevation
            )!
          )!
        )
    )
  };
}

export function internalToInternalManualPvs(
  internal: InternalRepresentationBase & InternalRepresentationManual
): PropertyValueSet.PropertyValueSet {
  const common: PropertyValueSet.PropertyValueSet = {
    [KnownUserSettings.selectedClimateDataSourceIndex]:
      PropertyValue.fromInteger(1),
    [KnownProperties.manualData.customPressure]: PropertyValue.fromInteger(
      internal.customPressure === undefined ? 0 : 1
    ),
    // [wmoTemporaryStorageNameInPvs]: PropertyValue.fromText(internal.locationId),
    [KnownProperties.coolingAnnualOccurenceInteger]: PropertyValue.fromInteger(
      annualOccurenceToInteger(internal.cooling.annualOccurence)
    ),
    [KnownProperties.heatingAnnualOccurenceInteger]: PropertyValue.fromInteger(
      annualOccurenceToInteger(internal.heating.annualOccurence)
    ),
    [KnownProperties.coolingDataTypeInteger]: PropertyValue.fromInteger(
      climateDataTypeToInteger(internal.cooling.dataType)
    ),
    [KnownProperties.heatingDataTypeInteger]: PropertyValue.fromInteger(
      climateDataTypeToInteger(internal.heating.dataType)
    )
  };

  return {
    ...common,
    [KnownProperties.manualData.summerTemperature]: PropertyValue.fromAmount(
      internal.cooling.temperature
    ),
    [KnownProperties.manualData.winterTemperature]: PropertyValue.fromAmount(
      internal.heating.temperature
    ),
    [KnownProperties.manualData.summerHumidity]: PropertyValue.fromAmount(
      internal.cooling.humidity
    ),
    [KnownProperties.manualData.winterHumidity]: PropertyValue.fromAmount(
      internal.heating.humidity
    ),
    [KnownProperties.manualData.summerWindSpeed]: PropertyValue.fromAmount(
      internal.cooling.windSpeed
    ),
    [KnownProperties.manualData.winterWindSpeed]: PropertyValue.fromAmount(
      internal.heating.windSpeed
    ),
    [KnownProperties.manualData.altitude]: PropertyValue.fromAmount(
      internal.altitude
    ),
    [KnownProperties.manualData.atmosphericPressure]: PropertyValue.fromAmount(
      // Go with manually set custom pressure...
      internal.customPressure ||
        // ...or calculate it from manually entered altitude.
        Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
          internal.altitude
        )
    )
  };
}
function formatLatitude(latitude: number) {
  return Math.abs(latitude).toFixed(2) + " " + (latitude < 0 ? "S" : "N");
}

function formatLongitude(longitude: number) {
  return Math.abs(longitude).toFixed(2) + " " + (longitude < 0 ? "E" : "W");
}

// tslint:disable-next-line:max-file-line-count
