import styled from "styled-components";

export const Root = styled.div`
  padding: 25px 50px;
`;

export const LoadingRoot = styled.div`
  display: flex;
  justify-content: center;
  height: 500px;
`;

export const ButtonsBox = styled.div`
  padding-top: 15px;

  button {
    margin-right: 10px;
  }
`;
