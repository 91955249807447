import * as React from "react";
import styled from "styled-components";

// tslint:disable-next-line:variable-name
const StyledInput = styled.input`
  vertical-align: middle;
`;

const StyledFieldset = styled.fieldset`
  border: 1px solid #d3d3d3;
  border-radius: 5px;

  font-size: 12px;

  padding: 10px;

  legend {
    padding: 10px;
  }
`;

interface DebugSettings {
  readonly includeServerLog?: boolean;
  readonly showHiddenProperties?: boolean;
  readonly showHiddenResults?: boolean;
  readonly useCalculationWorkers?: boolean;
  readonly showTextDb?: boolean;
  readonly showTextsWithTextId?: boolean;
}

type DSKey = keyof DebugSettings;

interface Props {
  readonly debugSettings: DebugSettings;
  readonly setDebugProperties: (debugSettings: DebugSettings) => void;
}

const settings: ReadonlyArray<{
  readonly label: string;
  readonly propertyName: DSKey;
}> = [
  { label: "Server logging", propertyName: "includeServerLog" },
  { label: "Show hidden properties", propertyName: "showHiddenProperties" },
  { label: "Show hidden results", propertyName: "showHiddenResults" },
  { label: "Use calculation workers", propertyName: "useCalculationWorkers" },
  { label: "ShowTextDb", propertyName: "showTextDb" },
  { label: "ShowTextsWithTextId", propertyName: "showTextsWithTextId" }
];

// tslint:disable-next-line:function-name
export function DebugSettingsSelection({
  debugSettings,
  setDebugProperties
}: Props): JSX.Element {
  const handleCheckboxChange = (propertyName: DSKey) => {
    setDebugProperties({
      ...debugSettings,
      [propertyName]: !debugSettings[propertyName]
    });
  };

  return (
    <StyledFieldset>
      <legend>Debug settings</legend>
      {Object.keys(debugSettings).map(key => {
        const setting = settings.find(
          ({ propertyName }) => propertyName === key
        );
        if (!setting) {
          return null;
        }

        const { label, propertyName } = setting;

        return (
          <div>
            <StyledInput
              type="checkbox"
              key={propertyName}
              checked={debugSettings[propertyName]}
              onChange={() => handleCheckboxChange(propertyName)}
            />

            {label}
          </div>
        );
      })}
    </StyledFieldset>
  );
}
