import * as GraphQLTypes from "../graphql-types";
import { PropertyFilter } from "@genesys/property";

type PropertyValueSourceId = string;
type ValueSourceMap = Map<number, PropertyValueSourceId>;
type SystemType = string;
type PropertyName = string;

interface PropertyValueSource {
  readonly id: string;
  readonly value: number;
  readonly propertyValueSourceId: string;
  readonly parameters: string | null | undefined;
  readonly propertyFilter: PropertyFilter.PropertyFilter;
  readonly claimFilter: string;
}

export interface OpcProperty {
  readonly name: string;
  readonly validationFilter?: PropertyFilter.PropertyFilter;
  readonly valueSources: ReadonlyArray<PropertyValueSource>;
}

export type OpcPropertiesPerSystem = Map<
  SystemType,
  ReadonlyArray<OpcProperty>
>;

export interface PropertyDefSimplified {
  readonly name: string;
  readonly valueSourceMap: ValueSourceMap;
}

export type PropertiesMap = Map<
  SystemType,
  Map<PropertyName, PropertyDefSimplified>
>;

export function createPropertiesMap(
  data: GraphQLTypes.SystemsSummaryProductQuery["product"]["systemTypes"]
): PropertiesMap {
  return new Map(
    data.map(x => [
      x.id,
      new Map(
        x.opc.properties.map(property => [
          property.name,
          {
            name: property.name,
            valueSourceMap: new Map(
              property.valueSources.map(vs => [
                vs.value,
                vs.propertyValueSourceId
              ])
            )
          }
        ])
      )
    ])
  );
}

export function parseOpcProperties(
  data: GraphQLTypes.SystemsSummaryProductQuery["product"]["systemTypes"]
): OpcPropertiesPerSystem {
  return new Map(
    data.map(x => [
      x.id,
      x.opc.properties.map(property => {
        return {
          name: property.name,
          validationFilter: PropertyFilter.fromString(
            property.validationFilter
          ),
          valueSources: property.valueSources.map(valueSource => ({
            id: valueSource.id,
            claimFilter: valueSource.claimFilter,
            propertyFilter: PropertyFilter.fromStringOrEmpty(
              valueSource.propertyFilter || ""
            ),
            propertyValueSourceId: valueSource.propertyValueSourceId,
            value: valueSource.value,
            parameters: valueSource.parameters
          }))
        };
      })
    ])
  );
}
