import styled from "styled-components";

const overlayBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 20;

  background: rgba(0, 0, 0, 0.5);
`;

// tslint:disable-next-line
export const FixedOverlay = styled(overlayBase)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

// tslint:disable-next-line:variable-name
export const AbsoluteOverlay = styled(overlayBase)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
