import * as React from "react";
import * as Types from "../types";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as PsychrometricChart from "../../../../../psychrometric-chart";
import styled from "styled-components";
import { Action, State } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { GenesysSelect } from "@genesys/ui-elements";

const FlexContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;

  > * {
    margin-right: 5px;
  }
`;

const chartTypes: ReadonlyArray<Types.ChartType> = [
  "mollier_SI",
  "mollier_IP",
  "psycrom_SI",
  "psycrom_IP"
];

const ImageContainer = styled.div`
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
  margin-bottom: 5px;
  margin-top: 12px;
  display: inline-block;
  max-width: 650px;
  max-height: 800px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export function BinChartView({
  sharedState,
  selectedChartType,
  state,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly selectedChartType: Types.ChartType;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;
  if (state.psychrometricChartState === undefined) {
    return null;
  }
  return (
    <>
      <FlexContainer>
        <span>{translate(LanguageTexts.chartPreset())}</span>
        <GenesysSelect
          width={6}
          height={33}
          fontSize={13}
          options={chartTypes.map(ct => ({
            value: ct,
            title: translate(LanguageTexts.dynamicText(ct))
          }))}
          value={selectedChartType}
          onChange={e => {
            dispatch(Action.setChartType(e.target.value as Types.ChartType));
          }}
        />
      </FlexContainer>
      <div>
        <ImageContainer>
          <PsychrometricChart.PsychrometricChartView
            state={state.psychrometricChartState}
            sharedState={sharedState}
          />
        </ImageContainer>
      </div>
    </>
  );
}
