import * as React from "react";
import styled from "../../styled-components";

export interface ModalHeaderProps {
  readonly children: React.ReactNode;
  readonly borderBottom: string;
}

const StyledModalHeader = styled.div<{ readonly borderBottom: string }>`
  display: block;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 40px;
  padding-right: 40px;

  border-bottom: ${props => props.borderBottom};
`;

export function ModalHeader(props: ModalHeaderProps): JSX.Element {
  return (
    <StyledModalHeader borderBottom={props.borderBottom}>
      {props.children}
    </StyledModalHeader>
  );
}
