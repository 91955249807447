// These types are only used to satisfy the constraints needed for ReturnType<T> and Parameters<T>
export type RawMap = { readonly [key: string]: RawFn };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RawFn = (...args: ReadonlyArray<unknown>) => any;

// These types are for the constructors map
export type CtorsMap<T extends RawMap> = { [P in keyof T]: CtorFn<P, T[P]> };
export type CtorFn<TType, TCtorFn extends RawFn> = (
  ...args: Parameters<TCtorFn>
) => ReturnType<TCtorFn> extends RawFn
  ? CtorFn<TType, ReturnType<TCtorFn>>
  : { readonly type: TType } & ReturnType<TCtorFn>;

// This type will create the union type from the constructors map
export type CtorsUnion<A extends RawMap> = FinalReturn<ReturnType<A[keyof A]>>;
export type FinalReturn<T> = T extends RawFn ? FinalReturn1<ReturnType<T>> : T;
export type FinalReturn1<T> = T extends RawFn ? FinalReturn2<ReturnType<T>> : T;
export type FinalReturn2<T> = T extends RawFn ? FinalReturn3<ReturnType<T>> : T;
export type FinalReturn3<T> = T extends RawFn ? FinalReturn4<ReturnType<T>> : T;
export type FinalReturn4<T> = T extends RawFn ? FinalReturn5<ReturnType<T>> : T;
export type FinalReturn5<T> = T extends RawFn ? FinalReturn6<ReturnType<T>> : T;
export type FinalReturn6<T> = T extends RawFn ? FinalReturn7<ReturnType<T>> : T;
export type FinalReturn7<T> = T extends RawFn ? FinalReturn8<ReturnType<T>> : T;
export type FinalReturn8<T> = T extends RawFn ? FinalReturn9<ReturnType<T>> : T;
export type FinalReturn9<T> = T extends RawFn
  ? FinalReturn10<ReturnType<T>>
  : T;
export type FinalReturn10<T> = T;

/**
 * Recursively wrap constructor functions as long as they return other functions
 * until an object is returned, then add type to that object and return it
 */
const wrapIt = (key: string, fn: RawFn) => (...args: Array<unknown>) => {
  const theReturn = fn(...args);
  return typeof theReturn === "function"
    ? wrapIt(key, theReturn)
    : { type: key, ...theReturn };
};

/**
 * Create a union type from an object with constructor functions.
 */
export function ctorsUnion<T extends RawMap>(ctorsMap: T): CtorsMap<T> {
  // Transform functions to add key as "type" property in constructed object
  const withType = Object.fromEntries(
    Object.entries(ctorsMap).map(([key, value]) => [key, wrapIt(key, value)])
  );
  return (withType as unknown) as CtorsMap<T>;
}

// Exmaple usage
// tslint:disable-next-line: variable-name
const Action = ctorsUnion({
  Foo: (olle: string) => ({ olle }),
  Bar: (kalle: number) => ({ kalle }),
  Curry: (a: number) => (b: number) => ({ a, b })
});
type Action = CtorsUnion<typeof Action>;
export const nisse = Action.Foo("sdaf");
export const kalle = Action.Bar(1);
export const curry1 = Action.Curry(1);
export const curry2: Action = curry1(2);
export const action: Action = Action.Foo("sadfsdf");
export const action2: Action = Action.Curry(1)(2);
