import gql from "graphql-tag";

export const sketchProviderQuery = gql`
  query MechanicalSketchProduct($systemType: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemType) {
        mechanicalSketchProviders {
          id
          propertyFilter
          mechanicalSketchProvider
        }
      }
    }
  }
`;

export const sketchMetaDataQuery = gql`
  query MechanicalSketchSolidContactUser(
    $mechanicalSketch: MechanicalSketchInputType!
  ) {
    user {
      mechanicalSketchMetaData(input: $mechanicalSketch) {
        components {
          id
          componentId
          label
          productId
          componentLabel
          weight
          inCasing
        }
        casings {
          id
          componentId
          label
          productId
          componentLabel
          weight
          inCasing
        }
      }
    }
  }
`;
