import * as React from "react";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import * as Elements from "@genesys/ui-elements";
import * as DebugSettings from "@genesys/client-core/lib/debug-settings";
import * as SharedState from "../shared-state";
import { Action, State } from "./state";
import { ClearCache } from "./clear-cache";
import { SystemImportComponent } from "./system-import-component";
import { ThrowServerSideException } from "./throw-server-side-error";
import { ImpersonationView } from "./impersonation-view";
import * as Authorization from "@genesys/shared/lib/authorization";
import { clientConfig } from "../config";

const DebugSettingsContainer = styled.div`
  width: 1440px;
  margin: 0 auto;

  display: flex;

  > fieldset {
    margin-right: 10px;
  }

  > button {
    height: 30px;
    margin: auto 0;
  }
`;

export function DebugSettingsView({
  dispatch,
  onClose,
  sharedState,
  state
}: {
  readonly dispatch: Dispatch<Action>;
  readonly onClose: () => void;
  readonly sharedState: SharedState.State;
  readonly state: State;
}): JSX.Element {
  const shouldDisplayImpersonationView =
    clientConfig.oidcAuthority.includes("keycloak") &&
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.impersonate
    );

  return (
    <DebugSettingsContainer>
      {state.showLoader && <Elements.OverlayLoader />}

      <DebugSettings.DebugSettingsSelection
        debugSettings={{
          includeServerLog: sharedState.debugSettings.includeServerLog,
          showHiddenProperties: sharedState.debugSettings.showHiddenProperties,
          showHiddenResults: sharedState.debugSettings.showHiddenResults,
          useCalculationWorkers:
            sharedState.debugSettings.useCalculationWorkers,
          showTextDb: sharedState.debugSettings.showTextsDB,
          showTextsWithTextId: sharedState.debugSettings.showTextsWithTextId
        }}
        // includeServerLog={sharedState.debugSettings.includeServerLog}
        // showHiddenProperties={sharedState.debugSettings.showHiddenProperties}
        // showHiddenReults={sharedState.debugSettings.showHiddenResults}
        // useCalculationWorkers={sharedState.debugSettings.useCalculationWorkers}
        // showTextDb={sharedState.debugSettings.showTextsDB}
        setDebugProperties={debugSettings => {
          dispatch(
            Action.setDebugSettings({
              includeServerLog: debugSettings.includeServerLog!,
              showHiddenProperties: debugSettings.showHiddenProperties!,
              showHiddenResults: debugSettings.showHiddenResults!,
              useCalculationWorkers: debugSettings.useCalculationWorkers!,
              showTextsDB: debugSettings.showTextDb!,
              showTextsWithTextId: debugSettings.showTextsWithTextId!
            })
          );
        }}
      />

      {Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.resetCache
      ) && (
        <ClearCache
          dispatch={dispatch}
          showConfirmModal={state.showConfirmModal}
          serversCleared={state.serversCleared}
        />
      )}

      {Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.developer
      ) && (
        <SystemImportComponent
          missingProps={state.systemModelMissingProps}
          showLoader={state.showLoader}
          onValidationFailed={missingPropps =>
            dispatch(Action.setSystemModelMissingProps(missingPropps))
          }
          importSystem={jsonText => dispatch(Action.importSystem(jsonText))}
        />
      )}

      {shouldDisplayImpersonationView && (
        <ImpersonationView
          input={state.impersonationInput}
          showError={state.showImpersonationError}
          dispatch={dispatch}
        />
      )}

      <ThrowServerSideException
        disableButton={state.disableErrorButton}
        exceptionThrown={state.exceptionThrown}
        onClick={() => dispatch(Action.throwException())}
      />

      <Elements.StandardButton
        size="Small"
        buttonType="Secondary-2"
        onClick={e => {
          e.stopPropagation();
          onClose();
        }}
      >
        Close
      </Elements.StandardButton>
    </DebugSettingsContainer>
  );
}

export function canSeeDebugSettings(sharedState: SharedState.State): boolean {
  return Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.developer
  );
}
