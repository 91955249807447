import {
  updateSettingsToAddTypesToLoad,
  generateActiveLoadsFromMoistureLoadSettings,
  getSelectableloads,
  getLoadsDefMap
} from "./tools";
import { State, UpdatedMoistureLoadSettings } from "./types";
import { PropertyValueSet } from "@genesys/property";

export function reset(
  state: State,
  resetValues: PropertyValueSet.PropertyValueSet
): [State, UpdatedMoistureLoadSettings] {
  const availableLoads = state.loadsState.visibleLoads;

  let newSettings = resetValues;

  const allLoadsDefMap = getLoadsDefMap(state);

  const defaultLoads = generateActiveLoadsFromMoistureLoadSettings(
    allLoadsDefMap,
    resetValues,
    // state.propertyDefinitions!,
    state.initialSelections?.loadOperationTimes!
  );

  for (const load of defaultLoads) {
    const loadDef = allLoadsDefMap.get(load.sourceName)!;
    if (load.heatAdded && load.moistureAdded) {
      newSettings = updateSettingsToAddTypesToLoad(
        resetValues,
        "both",
        loadDef
      );
    } else if (load.heatAdded) {
      newSettings = updateSettingsToAddTypesToLoad(
        resetValues,
        "heat",
        loadDef
      );
    } else {
      newSettings = updateSettingsToAddTypesToLoad(
        resetValues,
        "moisture",
        loadDef
      );
    }
  }

  return [
    {
      ...state,
      loadsState: {
        ...state.loadsState,
        selectableLoads: getSelectableloads(
          availableLoads,
          defaultLoads
          // newSettings,
          // state.propertyDefinitions!
        ),
        activeLoads: defaultLoads
      },

      currentPageTab: "moisture-heat-load-types"
    },
    newSettings
  ];
}
