// import React from "react";
import { TempAndHumColumnGroup } from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SummaryOpcProps } from "./shared";

export function tempAndHumContent({
  column,
  getAndDisplayValueFromOpc
}: Omit<SummaryOpcProps, "column"> & {
  readonly column: TempAndHumColumnGroup["columnName"];
}) {
  switch (column) {
    case "custom-air-humidity": {
      return getAndDisplayValueFromOpc(["customairhumidity", "customairhum"]);
    }
    case "custom-air-temperature": {
      return getAndDisplayValueFromOpc([
        "customairtemperature",
        "customairtemp"
      ]);
    }
    case "outdoor-air-temperature": {
      return getAndDisplayValueFromOpc([
        "outdoorairtemperature",
        "outdoorairtemp"
      ]);
    }
    case "outdoor-air-humidity": {
      return getAndDisplayValueFromOpc(["outdoorairhumidity", "outdoorairhum"]);
    }
    case "return-air-temperature": {
      return getAndDisplayValueFromOpc([
        "returnairtemperature",
        "returnairtemp"
      ]);
    }
    case "return-air-humidity": {
      return getAndDisplayValueFromOpc(["returnairhumidity", "returnairhum"]);
    }
    default: {
      exhaustiveCheck(column);
    }
  }
}
