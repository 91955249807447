import { PropertyValueSet } from "@genesys/property";
import { OperationTime } from "@genesys/client-core/lib/operation-time-dialog/types";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty = undefined;
export function getMoistureLoadSelectionVisibility(
  _moistureLoadClaimValue: number
): boolean {
  return false;
}

// Heat load selection
export const useHeatProperty = undefined;
export function getHeatLoadSelectionVisibility(
  _moistureLoadClaimValue: number
): boolean {
  return false;
}

export const title = "mlcdiffusionthroughmaterials";
export const sourceName = MoistureHeatLoadTypeEnum.DIFFUSION_THROUGH_MATERIALS;
export const illustrationPath =
  "/assets/images/moisture-and-heat-load-types/sources/diffusion-through-materials.jpg";
export const description = "mlcdiffusionthroughmaterialsexplanation";
export const useLoadKey = "mlcusediffusionthroughmaterials";
export const properties = [];
export function getPropertyUpdates(): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.Empty;
}
export function updateLoadOperationTime(): OperationTime | undefined {
  return undefined;
}
