import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div``;

export const SizeSelectionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

export const DehumTableContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const DehumHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  h2 {
    -ms-transform: rotate(270eg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Safari */
    transform: rotate(270deg);
  }
`;

export const PlenumTableContainer = styled.div`
  margin-left: 10px;
`;

export const PlenumHeaderContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const VerticalRowsTable = styled.table`
  tr {
    display: block;
    float: left;
  }

  th,
  td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 90px;
  }
`;
export const DehumTable = styled(VerticalRowsTable)`
  display: flex;
  flex-wrap: nowrap;
`;

export const PlenumTable = styled(VerticalRowsTable)``;

type PlenumRowProps = {
  readonly isSelected: boolean;
  readonly isValid: boolean;
};

export const PlenumRow = styled.tr`
  margin: 0 2px;

  background: ${(props: PlenumRowProps) =>
    !props.isValid
      ? PrimaryColors.lightBlue
      : props.isSelected
      ? "#FFFFFF"
      : "linear-gradient(180deg, #eeeeee 0%, #f0f0f0 100%)"};

  border: 1px solid
    ${(props: PlenumRowProps) =>
      props.isSelected
        ? PrimaryColors.muntersBlue
        : props.isValid
        ? PrimaryColors.green
        : "#f1f5fa"};

  box-shadow: ${(props: PlenumRowProps) =>
    props.isSelected
      ? "0 5px 5px 0 rgba(0,0,0,0.1)"
      : "0 2px 4px 0 rgba(0, 0, 0, 0.03)"};

  &:hover {
    ${(props: PlenumRowProps) =>
      props.isValid
        ? "cursor: pointer; " +
          "background: none;" +
          "box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);"
        : ""}
  }
`;
