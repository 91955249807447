import React from "react";
import styled from "../../styled-components";
// import { ArrowDown, ArrowRight, ArrowUp } from "../../icons";
import { PrimaryColors } from "../../colors";

// // tslint:disable-next-line
// const arrowUp = require("ui-elements/assets/button/arrow-down-blue.svg");
// // tslint:disable-next-line
// const arrowDown = require("ui-elements/assets/button/arrow-up-blue.svg");
// // tslint:disable-next-line
// const arrowRight = require("ui-elements/assets/button/arrow-right-blue.svg");

type MenuButtonWrapperProps = {
  readonly isActive?: boolean;
  readonly fontSize?: string;
};

const MenuButtonWrapper = styled.div<MenuButtonWrapperProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  /* color: #8f9bb3; */
  font-weight: 300;
  letter-spacing: 0;
  /* background: #ffffff; */
  margin-bottom: 0.1em;
  line-height: 17px;
  height: 24px;

  > label {
    color: ${props => (props.isActive ? PrimaryColors.blue600 : "#525252")};
    /* font-size: 19px; */
    font-size: ${props => props.fontSize || "17px"};
    font-weight: ${props => (props.isActive ? "bold" : 500)};
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 7px;

    cursor: pointer;

    &:hover {
      /* border-bottom: 2px solid ${PrimaryColors.blue500}; */
      text-decoration: underline;
      color: ${PrimaryColors.blue500};
      /* color: #4bccff; */
    }
  }

  img {
    text-align: center;
    margin-right: 7px;
    /* height: 14px; */
    width: 14px;
    cursor: pointer;
  }
`;

export function MenuButton(props: {
  readonly onClick?: () => void;
  readonly icon?: (props: {
    readonly width: number;
    readonly height: number;
  }) => JSX.Element;
  readonly hasMenu: boolean;
  readonly show?: boolean;
  readonly isOpen?: boolean;
  readonly children: React.ReactNode;
  readonly isActive?: boolean;
  readonly fontSize?: string;
}): JSX.Element | null {
  const { onClick, isActive, children, show = true } = props;

  if (!show) {
    return null;
  }

  // function getArrow() {
  //   if (!hasMenu) {
  //     return ArrowRight;
  //   }
  //   return isOpen ? ArrowUp : ArrowDown;
  // }

  function onClickHandler(event: React.MouseEvent<HTMLElement>) {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  }

  const Icon = props.icon;
  // const Arrow = getArrow();
  return (
    <MenuButtonWrapper isActive={isActive} onClick={onClickHandler}>
      {Icon && <Icon width={14} height={14} />}
      <label>{children}</label>
      {/* {<Arrow state={isActive ? "active" : undefined} />} */}
    </MenuButtonWrapper>
  );
}
