import styled from "styled-components";

// tslint:disable-next-line
export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 20;
  background: rgba(173, 173, 173, 0.44);
`;
