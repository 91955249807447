import styled from "styled-components";
// import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchFilterDateContainer = styled.div`
  input,
  label {
    display: inline;
    width: initial;
  }

  input {
    vertical-align: middle;
  }
`;
