import styled from "styled-components";
import { dialogBackgroundColor } from "../colors";

// tslint:disable-next-line
export const DialogBodyContainer = styled.div`
  font-size: 12px;
  // text-align: right;

  background: ${dialogBackgroundColor};

  padding: 10px 10px;

  > span {
    display: block;
    text-align: left;
  }
`;
