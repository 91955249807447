import * as React from "react";
import * as PrintParams from "../../print-params";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as MeasureSystemEnum from "@genesys/shared/lib/enums/measure-system";
import * as GraphQLTypes from "../../graphql-types";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../state";
import { CalculationInput, CalculationResults } from "../main-content/result";
import { clientConfig } from "../../config";
import { FetchDocumentComponent } from "@genesys/client-core/lib/fetch-document";
import {
  H3,
  StandardButton,
  CheckBox,
  Printer,
  ToolTipWrapper,
  Pop
} from "@genesys/ui-elements";

const StyledH3 = styled(H3)`
  margin-bottom: 5px;
  font-size: 15px;
`;

const Container = styled.div`
  margin-top: 10px;
  min-width: 100px;
  min-height: 40px;

  padding: 30px;
  padding-top: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  > span {
    margin-right: 5px;
  }
`;

const Error = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 620px;
`;

export function ReportView(props: {
  readonly moistureLoadClaimValue: number;
  readonly calculationInput: CalculationInput | undefined;
  readonly calculationResults: CalculationResults | undefined;
  readonly showResult: boolean;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;

  readonly dispatch: Dispatch<Action>;
}) {
  const { calculationInput, calculationResults, ...rest } = props;

  const labelRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);

  const handleClick = () => {
    setAnchorEl(labelRef.current);
    // setOpen(!isOpen)
  };

  const handleClose = () => {
    // setOpen(false)
    setAnchorEl(null);
  };

  const toggleIsGenerating = () => {
    setIsGenerating(!isGenerating);
  };

  return (
    <>
      <span ref={labelRef}>
        <ToolTipWrapper padding="0px" title={"ML report printouts"}>
          <Printer
            // disabled={isDisabled}
            onClick={() => {
              handleClick();
            }}
            height={"25px"}
          />
        </ToolTipWrapper>
      </span>

      {anchorEl && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          {props.showResult && calculationInput && calculationResults ? (
            <Container>
              <StyledH3>
                {props.sharedState.translate(LanguageTexts.mlcReportoptions())}
              </StyledH3>
              <PrintParams.View
                isDisabled={isGenerating}
                sharedState={props.sharedState}
                state={props.state.printParamsState!}
                dispatch={Dispatch.map(
                  Action.dispatchPrintParams,
                  props.dispatch
                )}
              />
              <FlexContainer>
                <span>
                  <CheckBox
                    isChecked={props.state.additionalOptions.includeAllInputs}
                    disabled={isGenerating}
                    onClick={() => {
                      props.dispatch(
                        Action.setAdditionalReportOptions({
                          ...props.state.additionalOptions,
                          includeAllInputs:
                            !props.state.additionalOptions.includeAllInputs
                        })
                      );
                    }}
                    children={props.sharedState.translate(
                      LanguageTexts.globalPropertyName("mlcincludeallinputs")
                    )}
                  />
                </span>

                <CheckBox
                  isChecked={props.state.additionalOptions.includeCharts}
                  disabled={isGenerating}
                  onClick={() => {
                    props.dispatch(
                      Action.setAdditionalReportOptions({
                        ...props.state.additionalOptions,
                        includeCharts:
                          !props.state.additionalOptions.includeCharts
                      })
                    );
                  }}
                  children={props.sharedState.translate(
                    LanguageTexts.globalPropertyName("mlcincludecharts")
                  )}
                />
              </FlexContainer>

              <GenerateButton
                isGenerating={isGenerating}
                toggleIsGenerating={toggleIsGenerating}
                {...rest}
              />
            </Container>
          ) : (
            <Container>
              <ErrorContainer>
                <Error>
                  {props.sharedState.translate(
                    LanguageTexts.mlcHasNotBeenCalculatedError()
                  )}
                </Error>
              </ErrorContainer>
            </Container>
          )}
        </Pop>
      )}
    </>
  );
}

function GenerateButton({
  sharedState,
  state,
  isGenerating,
  moistureLoadItem,
  toggleIsGenerating
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly isGenerating: boolean;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly toggleIsGenerating: () => void;
}) {
  const routeEndpoint = clientConfig.genesysBackend;
  const printParams = PrintParams.getSelectedPrintParams(
    state.printParamsState
  ) || {
    selectedLanguage: sharedState.user.settings.language,
    selectedPaperSize: (sharedState.user.settings.selectedAmountProfile
      .measureSystem === MeasureSystemEnum.MeasureSystem.SI
      ? PrintParams.paperSizes[0]
      : PrintParams.paperSizes[1]
    ).size,
    selectedHeaderLabel: PrintParams.headerLabels[0]
  };

  return (
    <FetchDocumentComponent
      authentication={{
        type: "Bearer",
        accessToken: sharedState.accessToken
      }}
      url={`${routeEndpoint}/internal/GetMoistureLoadReport`}
      type="download"
      filename={`${sharedState.genesysPrefix.moistureLoadNo(
        moistureLoadItem.moistureloadFile.moistureLoadNo,
        moistureLoadItem.revisionNo
      )}.pdf`}
      params={{
        pageSize: printParams.selectedPaperSize,
        pageOrientation: 0,
        headerLabel: printParams.selectedHeaderLabel.header!,
        culture: printParams.selectedLanguage,
        includeCharts: state.additionalOptions.includeCharts as any,
        includeAllInputs: state.additionalOptions.includeAllInputs as any,
        moistureLoadId: state.moistureLoadId!
      }}
      method="POST"
      onComplete={() => {
        toggleIsGenerating();
      }}
    >
      {onClick => (
        <StandardButton
          buttonType="Primary"
          size="Small"
          disabled={isGenerating}
          onClick={() => {
            onClick();
            toggleIsGenerating();
          }}
        >
          {isGenerating
            ? sharedState.translate(LanguageTexts.generating())
            : sharedState.translate(LanguageTexts.createPDF())}
        </StandardButton>
      )}
    </FetchDocumentComponent>
  );
}
