import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createPlenumSizeSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps): string {
  const svgString = `
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>FB08CA67-3A4D-43D7-9D42-8E5DEA383296</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-67.000000, -1984.000000)">
            <g id="Icons/System-Menu-Items/Plenum-Size" transform="translate(72.000000, 1987.000000)">
                <g id="Rectangle-Copy-18">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <g id="Group-36" transform="translate(30.508726, 30.999924) scale(1, -1) rotate(-269.000000) translate(-30.508726, -30.999924) translate(12.508726, 10.499924)" fill="${color}" fill-rule="nonzero">
                    <path d="M24.3293101,0.848253184 L34.697453,5.64487212 L34.5017507,6.06525318 L35.1499354,5.9027634 L35.1517832,33.8223266 L9.34999979,40.2242608 L9.34975074,39.5572532 L1.25,30.7245277 L1.24975074,6.98325318 L0.248750738,6.231999 L24.3293101,0.848253184 Z M22.3307507,24.1772532 L11.6347507,38.1102532 L32.6677507,32.8912532 L22.3307507,24.1772532 Z M2.74975074,8.10925318 L2.75,30.141 L9.34975074,37.3372532 L9.34975074,13.0652532 L2.74975074,8.10925318 Z M10.8487507,14.4982532 L10.8487507,36.6702532 L21.1827507,23.2092532 L10.8487507,14.4982532 Z M33.6487507,9.43425318 L23.2447507,22.9862532 L33.6507507,31.7572532 L33.6487507,9.43425318 Z M32.8347507,8.03225318 L11.7997507,13.3382532 L22.0977507,22.0182532 L32.8347507,8.03225318 Z M23.2997507,2.61325318 L3.75,6.985 L10.5677507,12.1032532 L23.2997507,8.89125318 L23.2997507,2.61325318 Z M24.7997507,2.71725318 L24.7997507,8.51325318 L32.9057507,6.46825318 L24.7997507,2.71725318 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
