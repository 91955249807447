import styled from "styled-components";

// tslint:disable-next-line
export const ManagerSearchButton = styled.button`
  height: 28px;
  margin-left: 2px;
  width: 70px;
  background-color: rgb(75, 140, 247);
  border-color: rgb(48, 121, 237);
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;

  position: relative;
  img {
    position: absolute;
    top: 25%;
    left: 40%;
  }
`;
