import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton, CheckBox, TextArea } from "@genesys/ui-elements";
import { Action, LoadedState } from "../state";
import { Empty, All, LogRow } from "../types";
import { categoryKey } from "../functions";
import {
  CategoriesBox,
  ExportBox,
  FiltersBox,
  RegexFilterBox,
  LogsRoot,
  RowsContainer,
  FilterSelectionBox,
  AdvancedFilterBox
} from "../elements";

export function LogsView({
  state,
  dispatch
}: {
  readonly state: LoadedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const regex = new RegExp(state.lineFilterRegex, "i");
  const emptyCaseType = state.enabledCaseTypes.has(Empty);
  const emptyCaseName = state.enabledCaseNames.has(Empty);
  const emptyComponent = state.enabledComponents.has(Empty);
  const filteredRows = state.logRows.filter(
    r =>
      state.enabledServicesAndCategories.has(
        categoryKey(r.serviceName, r.category)
      ) &&
      regex.test(r.body) &&
      ((emptyCaseType && r.caseType === "") ||
        state.enabledCaseTypes.has(r.caseType)) &&
      ((emptyCaseName && r.caseName === "") ||
        state.enabledCaseNames.has(r.caseName)) &&
      ((emptyComponent && r.component === "") ||
        state.enabledComponents.has(r.component))
  );

  return (
    <LogsRoot>
      <ExportBox>
        <StandardButton
          size="Small"
          buttonType="Primary"
          onClick={() =>
            dispatch(
              Action.createExportFile(
                filteredRows.map(r => r.formatted).join("\n")
              )
            )
          }
        >
          {"Export"}
        </StandardButton>
        {state.exportFileUrl && (
          <a
            href={state.exportFileUrl}
            style={{ display: "none" }}
            download={`Server Log - ${state.traceIdField}.txt`}
            ref={e => {
              if (e) {
                e.click();
                dispatch(Action.clearExportFile());
              }
            }}
          />
        )}
      </ExportBox>

      <FilterSelectionBox>
        <StandardButton
          size="Small"
          buttonType="Secondary"
          onClick={() => dispatch(Action.toggleShowFilter())}
        >
          {state.showFilter ? "Hide filter" : "Show filter"}
        </StandardButton>
        <StandardButton
          size="Small"
          buttonType="Secondary"
          onClick={() => dispatch(Action.toggleShowCategories())}
        >
          {state.showCategories ? "Hide categories" : "Show categories"}
        </StandardButton>
        <StandardButton
          size="Small"
          buttonType="Secondary"
          onClick={() => dispatch(Action.toggleShowAdvancedFilter())}
        >
          {state.showAdvancedFilter ? "Hide advanced" : "Show advanced"}
        </StandardButton>
        <StandardButton buttonType="Secondary" size="Small">
          {filteredRows.length} / {state.logRows.length}
        </StandardButton>
      </FilterSelectionBox>

      <FiltersBox>
        {state.showFilter && (
          <RegexFilterBox>
            <TextArea
              placeholder="Line filter regex"
              value={state.lineFilterRegexField}
              onChange={e =>
                dispatch(Action.setLineFilterRegexField(e.target.value))
              }
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  dispatch(Action.setLineFilterRegex());
                }
              }}
            />
            <StandardButton
              size="Small"
              buttonType="Primary-2"
              disabled={state.lineFilterRegexField === state.lineFilterRegex}
              onClick={() => {
                dispatch(Action.setLineFilterRegex());
              }}
            >
              {"Apply Filter"}
            </StandardButton>
          </RegexFilterBox>
        )}

        {state.showCategories && (
          <CategoriesBox>
            {Array.from(state.servicesWithCategories.entries()).map(
              ([serviceName, categories]) => (
                <div key={serviceName}>
                  <h2>{serviceName}</h2>
                  <ul>
                    <li>
                      <CheckBox
                        isChecked={state.enabledServicesAndCategories.has(
                          categoryKey(serviceName, All)
                        )}
                        onClick={() => {
                          dispatch(Action.toggleCategory(serviceName, All));
                        }}
                        children="All"
                      />
                    </li>
                    {Array.from(categories.values())
                      .sort((a, b) => a.localeCompare(b))
                      .filter(c => c !== All)
                      .map(c => (
                        <li key={c}>
                          <CheckBox
                            isChecked={state.enabledServicesAndCategories.has(
                              categoryKey(serviceName, c)
                            )}
                            onClick={() => {
                              dispatch(Action.toggleCategory(serviceName, c));
                            }}
                            children={c}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              )
            )}
          </CategoriesBox>
        )}

        {state.showAdvancedFilter && (
          <AdvancedFilterBox>
            <FilterListComponent
              header={"CaseTypes"}
              set={state.caseTypes}
              enabledSet={state.enabledCaseTypes}
              toggle={(id: string) => {
                dispatch(Action.toggleCaseType(id));
              }}
            />
            <FilterListComponent
              header={"Case names"}
              set={state.caseNames}
              enabledSet={state.enabledCaseNames}
              toggle={(id: string) => {
                dispatch(Action.toggleCaseName(id));
              }}
            />
            <FilterListComponent
              header={"Components"}
              set={state.components}
              enabledSet={state.enabledComponents}
              toggle={(id: string) => {
                dispatch(Action.toggleComponent(id));
              }}
            />
          </AdvancedFilterBox>
        )}
      </FiltersBox>

      <LogRows rows={filteredRows} />
    </LogsRoot>
  );
}

function FilterListComponent({
  set,
  header,
  enabledSet,
  toggle
}: {
  readonly header: string;
  readonly set: Set<string>;
  readonly enabledSet: Set<string>;
  readonly toggle: (id: string) => void;
}): JSX.Element {
  return (
    <div key={header}>
      <h2>{header}</h2>
      <ul>
        <li>
          <CheckBox
            isChecked={enabledSet.has(All)}
            onClick={() => {
              toggle(All);
            }}
            children="All"
          />
        </li>
        <li>
          <CheckBox
            isChecked={enabledSet.has(Empty)}
            onClick={() => {
              toggle(Empty);
            }}
            children="Empty"
          />
        </li>
        {Array.from(set.values())
          .sort((a, b) => a.localeCompare(b))
          .filter(c => c !== All && c !== Empty)
          .map(c => (
            <li key={c}>
              <CheckBox
                isChecked={enabledSet.has(c)}
                onClick={() => {
                  toggle(c);
                }}
                children={c}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}

function LogRows({
  rows
}: {
  readonly rows: ReadonlyArray<LogRow>;
}): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    if (ref.current) {
      // Get how many pixels is left of the screen and magic number(trial and error)
      // to avoid page scrollling
      const rootTop = ref.current.getBoundingClientRect().top + 20;
      const rootHeight = window.innerHeight - rootTop;
      ref.current.style.height = `${rootHeight}px`;
    }
  });
  return (
    <div ref={ref} style={{ overflow: "auto" }}>
      <RowsContainer>
        {rows.length === 0 && <div>{"Could not load logs"}</div>}
        {rows.map(r => (
          <p key={r.id}>{r.formatted}</p>
        ))}
      </RowsContainer>
    </div>
  );
}
