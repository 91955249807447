import * as React from "react";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { UsePropertiesSelector } from "@promaster-sdk/react-property-selectors";
import { PropertyValueSet } from "@genesys/property";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { useTextboxPropertySelector } from "@promaster-sdk/react-property-selectors";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Quantity } from "@genesys/uom";
import { DiscretePropertySelector } from "../../discrete-property-selector";
import { AmountPropertySelector } from "../../amount-property-selector";
import { PropertyInfo, PropertyItem } from "../types";
import { PropertyInput } from "../../property-input";

export function PropertySelector({
  sel,
  property,
  properties,
  fieldGroup,
  accessToken,
  translate,
  getAmountFormat
}: {
  readonly fieldGroup: string;
  readonly accessToken: string;
  readonly property: PropertyInfo;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly sel: UsePropertiesSelector<PropertyItem, PropertyInfo>;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly translate: (textDefinition: LanguageTexts.TextDefinition) => string;
}): JSX.Element {
  const checkIfLockedByValueSource = (): boolean => {
    if (property.valueSources.length === 0) {
      return false;
    }

    const sourceInt = PropertyValueSet.getInteger(
      `source_${property.name}`,
      properties
    );

    const valueSource =
      sourceInt !== undefined
        ? property.valueSources.find(vs => vs.value === sourceInt)
        : undefined;

    if (valueSource !== undefined) {
      return valueSource.propertyValueSourceId !== "User";
    }

    return false;
  };

  const isLockedByValueSource = checkIfLockedByValueSource();

  const selector = sel.getPropertySelectorHook(property);

  switch (selector.type) {
    case "TextBox": {
      const textboxOptions = selector.getUseTextboxOptions();
      const { value, readOnly, onChange } =
        useTextboxPropertySelector(textboxOptions).getInputProps();

      const isReadOnly = readOnly || isLockedByValueSource;

      return (
        <PropertyInput
          value={value}
          isReadOnly={isReadOnly}
          onChange={onChange}
        />
      );
    }
    case "Discrete": {
      return (
        <DiscretePropertySelector
          accessToken={accessToken}
          lockedByValueSource={isLockedByValueSource}
          {...selector.getUseDiscreteOptions()}
        />
      );
    }
    case "AmountField": {
      const conversionParameters =
        QuantityConversion.createConversionParameters(
          property.conversionParameters || [],
          properties
        );

      const originalQuantity = property.quantity as Quantity.Quantity;

      const sel = selector.getUseAmountOptions();

      const amountFormat = getAmountFormat(
        fieldGroup,
        sel.fieldName,
        originalQuantity
      );

      return (
        <AmountPropertySelector
          type="with-use-amount-options"
          amountFormat={amountFormat}
          fieldGroup={fieldGroup}
          originalQuantity={originalQuantity}
          conversionParameters={conversionParameters}
          isLockedByValueSource={isLockedByValueSource}
          translate={translate}
          {...sel}
        />
      );
    }
    default:
      return exhaustiveCheck(selector, true);
  }
}
