import * as React from "react";
import styled from "styled-components";
import {
  PropertyValue,
  PropertyFilter,
  PropertyValueSet
} from "@genesys/property";
import { PropertyValueItem } from "../react-properties-selector";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as PropertiesSelector from "../react-properties-selector";
import { Unit, Quantity, Amount } from "@genesys/uom";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as AmountFormatSelector from "../amount-format-selector";
import * as Contexts from "../contexts";
import * as UserProfile from "../user-profile";

// tslint:disable-next-line:variable-name
const TableCell = styled.td<{
  readonly hasErrorMessage: boolean;
}>`
  ${props => props.hasErrorMessage && `color: red;`};
`;

interface ReadonlyPropertySelectorProps {
  readonly conversionParameters:
    | QuantityConversion.ConversionParameters
    | undefined;
  readonly fieldGroup: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly includeCodes: boolean;
  readonly isQuantitySelectorActive: boolean;
  readonly onPropertyFormatChanged: PropertiesSelector.OnPropertyFormatChanged;
  readonly onPropertyFormatCleared: PropertiesSelector.OnPropertyFormatCleared;
  readonly onPropertyFormatSelectorToggled: PropertiesSelector.OnPropertyFormatSelectorToggled;
  readonly onQuantityChange: AmountFormatSelector.OnQuantityChange;
  readonly propertyName: string;
  readonly fieldName: string;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly valueItems: ReadonlyArray<PropertyValueItem>;
  readonly quantity: Quantity.Quantity;
}

// tslint:disable-next-line:function-name
export function ReadonlyPropertySelector({
  conversionParameters,
  filterPrettyPrint,
  includeCodes,
  onPropertyFormatChanged,
  onPropertyFormatCleared,
  onPropertyFormatSelectorToggled,
  isQuantitySelectorActive,
  onQuantityChange,
  propertyName,
  fieldGroup,
  fieldName,
  selectedProperties,
  translatePropertyValue,
  validationFilter,
  valueItems
}: ReadonlyPropertySelectorProps): JSX.Element {
  const propertyValue = PropertyValueSet.get(propertyName, selectedProperties);

  if (propertyValue === undefined) {
    return <td>&nbsp;</td>;
  }
  switch (propertyValue.type) {
    case "text": {
      return <td>{PropertyValue.getText(propertyValue)}</td>;
    }
    case "integer": {
      const value = PropertyValue.getInteger(propertyValue)!;
      const valueItem = valueItems.find(vi =>
        PropertyValue.equals(vi.value, propertyValue)
      );
      const validationMessage =
        getValidationMessage(
          selectedProperties,
          (valueItem && valueItem.property_filter) || PropertyFilter.Empty,
          filterPrettyPrint
        ) || undefined;

      return (
        <TableCell
          hasErrorMessage={validationMessage !== undefined}
          title={validationMessage}
        >
          {translatePropertyValue(propertyName, value)}{" "}
          {includeCodes && `(${value})`}
        </TableCell>
      );
    }
    default: {
      const amount = PropertyValue.getAmount(
        propertyValue
      )! as Amount.Amount<Quantity.Quantity>;
      return (
        <UserProfile.UserProfile>
          {user => (
            <Contexts.propertiesSelectorContext.Consumer>
              {({ getAmountFormat }) => {
                const title = AuthorizationTools.checkPermission(
                  user.applicationClaims,
                  AuthorizationTools.genesysUserClaims.developer
                )
                  ? "GroupName: " + fieldGroup + "\nFieldName: " + fieldName
                  : undefined;

                const amountFormat = getAmountFormat(
                  fieldGroup,
                  fieldName,
                  amount
                );

                const validationMessage =
                  getValidationMessage(
                    selectedProperties,
                    validationFilter,
                    filterPrettyPrint
                  ) || undefined;

                return (
                  <TableCell
                    hasErrorMessage={validationMessage !== undefined}
                    title={validationMessage}
                  >
                    {ProductProperties.getValue(
                      propertyValue,
                      amountFormat,
                      conversionParameters
                    )}{" "}
                    <span title={title}>
                      <AmountFormatSelector.FormatSelectorRenderer
                        AmountFormatSelector={
                          AmountFormatSelector.AmountFormatSelectorWithPadding
                        }
                        conversionParameters={conversionParameters}
                        onFormatChanged={(
                          unit: Unit.Unit<Quantity.Quantity>,
                          decimalCount: number
                        ) => {
                          onPropertyFormatChanged(
                            fieldName,
                            unit,
                            decimalCount
                          );
                        }}
                        onFormatCleared={() => {
                          onPropertyFormatCleared(fieldName);
                        }}
                        onFormatSelectorActiveChanged={(active: boolean) => {
                          onPropertyFormatSelectorToggled(propertyName, active);
                        }}
                        onQuantityChange={onQuantityChange}
                        isQuantitySelectorActive={isQuantitySelectorActive}
                        propertyType={propertyValue.type}
                        selectedDecimalCount={amountFormat.decimalCount}
                        selectedUnit={amountFormat.unit}
                        userSelected={amountFormat.userDefined}
                      />
                    </span>
                  </TableCell>
                );
              }}
            </Contexts.propertiesSelectorContext.Consumer>
          )}
        </UserProfile.UserProfile>
      );
    }
  }
}

function getValidationMessage(
  propertyValueSet: PropertyValueSet.PropertyValueSet,
  validationFilter: PropertyFilter.PropertyFilter,
  filterPrettyPrint: PropertyFiltering.FilterPrettyPrint
): string {
  if (PropertyFilter.isValid(propertyValueSet, validationFilter)) {
    return "";
  } else {
    return filterPrettyPrint(validationFilter);
  }
}
