import * as GraphQLEntityCache from "gql-cache";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type ResultProps<TResult extends GraphQLEntityCache.RootFields> =
  | ErrorResultProps
  | PartialResultProps
  | StaleResultProps<TResult>
  | LoadedResultProps<TResult>;

export interface ErrorResultProps {
  readonly type: "ErrorResultProps";
  readonly error: Error | undefined;
}

export interface PartialResultProps {
  readonly type: "PartialResultProps";
}

export interface StaleResultProps<TResult> {
  readonly type: "StaleResultProps";
  readonly data: TResult;
  readonly entities: GraphQLEntityCache.EntityCache;
}

export interface LoadedResultProps<TResult> {
  readonly type: "LoadedResultProps";
  readonly data: TResult;
  readonly entities: GraphQLEntityCache.EntityCache;
}

export function createResultProps<
  TResult extends GraphQLEntityCache.RootFields
>(
  partial: boolean,
  stale: boolean,
  data: TResult | undefined,
  entities: GraphQLEntityCache.EntityCache | undefined
): ResultProps<TResult> {
  if (partial) {
    return {
      type: "PartialResultProps"
    };
  }

  if (stale) {
    return {
      type: "StaleResultProps",
      data: data!,
      entities: entities!
    };
  }

  return {
    type: "LoadedResultProps",
    data: data!,
    entities: entities!
  };
}

export function isDirty(resultProps: ResultProps<any>): boolean {
  switch (resultProps.type) {
    case "StaleResultProps":
    case "PartialResultProps": {
      return true;
    }
    case "LoadedResultProps":
    case "ErrorResultProps": {
      return false;
    }
    default: {
      exhaustiveCheck(resultProps, true);
      return false;
    }
  }
}
