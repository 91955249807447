import * as React from "react";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as ComponentOrder from "@genesys/shared/lib/component-order-2";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as SystemPerformance from "@genesys/client-core/lib/system-performance";
import * as Product from "../product";
import * as State from "./state";
import { P1 } from "@genesys/ui-elements";
import { PropertyFilter } from "@genesys/property";
import { AmountFormatSelector } from "../../amount-format-selector";
import {
  AirPositionTh,
  OptionTable,
  PerformanceTable,
  ResultTd,
  StyledTh,
  TablesContainer
} from "./elements";
import { ScreenAirPropertiesResult, PropsWithOpc } from "./types";
import {
  getScreenAirPropertiesResultsAndFilteredAirPositions,
  getConversionParameters
} from "./functions";

export const PerformanceOverview = (props: PropsWithOpc) => {
  return <TableView {...props} />;
};

const TableView = ({
  selectedAirPosition,
  sharedState,
  sysProperties,
  systemItem,
  systemType,
  opCases,
  selectedOpCaseId,
  onAirPositionClick,
  dispatch
}: PropsWithOpc) => {
  const connectionPoints = ProductData.getValidProductsForRange(
    systemType.products,
    sysProperties
  ).reduce(
    (soFar: ReadonlyArray<Product.BoxConnectionPoint>, product) =>
      soFar.concat(product.boxConnectionPoints),
    []
  );

  const screenAirProperties = SystemPerformance.toScreenAirProperty(
    systemType.systemType.airPointProperties.filter(ap =>
      PropertyFilterHelpers.isValid(
        ap.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
  );

  const airPositions = ComponentOrder.getAirPositions(
    "UnitConfiguration",
    systemItem.components,
    systemItem.airstreams,
    connectionPoints.map(cp => ({
      connectionType: cp.connectionType,
      hideStatePoint: cp.hideStatePoint,
      diagramType: cp.diagramType,
      productSectionId: cp.productSectionId,
      propertyFilter: cp.propertyFilter,
      id: cp.id
    })),
    false
  );

  const selectedOpCase = opCases.find(opc => opc.id === selectedOpCaseId);

  const fieldGroup = `AirLocationTable.${systemType.systemType.id}`;

  const [screenAirPropertiesResult, effectiveAirPositions] =
    getScreenAirPropertiesResultsAndFilteredAirPositions(
      screenAirProperties,
      airPositions,
      selectedOpCase,
      sharedState.screenAmounts.getAmountFormat,
      fieldGroup
    );

  const conversionParameters = getConversionParameters(
    screenAirPropertiesResult
  );

  const selectedAirPositionIndex = selectedAirPosition
    ? effectiveAirPositions.find(
        ap => selectedAirPosition === String.fromCharCode(ap.label)
      )!.label - 65
    : undefined;

  // Component for rendering a row in the options table
  const renderOptionTableRow = (sap: ScreenAirPropertiesResult) => (
    <tr key={sap.name}>
      <td>
        {sap.amountFormat && (
          <AmountFormatSelector
            type="AmountFormatSelectorProps"
            fieldName={sap.name}
            fieldGroup={fieldGroup}
            amountFormat={sap.amountFormat}
            conversionParameters={
              sap.name === "Flow" || sap.name === "Humidity"
                ? conversionParameters
                : undefined
            }
            translate={sharedState.translate}
            onFormatCleared={() =>
              dispatch(State.Action.FormatCleared(fieldGroup, sap.name))
            }
            onFormatChanged={(unit, decimalCount) =>
              dispatch(
                State.Action.FormatChanged(
                  fieldGroup,
                  sap.name,
                  unit,
                  decimalCount
                )
              )
            }
          />
        )}
      </td>
    </tr>
  );

  // Component for rendering headers in the performance table
  const renderPerformanceTableHeader = (
    ap: ComponentOrder.AirPosition,
    i: number
  ) => {
    const isLastColumn = i === effectiveAirPositions.length - 1;
    const isSelected = selectedAirPosition === String.fromCharCode(ap.label);
    const handleClick = () =>
      onAirPositionClick(
        isSelected ? undefined : String.fromCharCode(ap.label)
      );

    return (
      <AirPositionTh
        key={`${ap.airstreamId}-${ap.label}`}
        isSelected={isSelected}
        onClick={handleClick}
      >
        <hr />
        <P1 weight="bold">{String.fromCharCode(ap.label)}</P1>
        {!isLastColumn && <hr />}
        {isLastColumn && <span style={{ visibility: "hidden" }} />}
      </AirPositionTh>
    );
  };

  // Component for rendering a row in the performance table
  const renderPerformanceTableRow = (sap: ScreenAirPropertiesResult) => (
    <tr key={sap.name}>
      {sap.result.map((result, i) => (
        <ResultTd
          key={`${result}-${i}`}
          isSelected={selectedAirPositionIndex === i}
        >
          <P1 weight="normal" color="secondary">
            {result}
          </P1>
        </ResultTd>
      ))}
    </tr>
  );

  return (
    <TablesContainer>
      {/* Options Table */}
      <OptionTable>
        <thead>
          <tr>
            <StyledTh>
              <hr />
            </StyledTh>
          </tr>
        </thead>
        <tbody>{screenAirPropertiesResult.map(renderOptionTableRow)}</tbody>
      </OptionTable>

      {/* Performance Table */}
      <PerformanceTable>
        <thead>
          <tr>{effectiveAirPositions.map(renderPerformanceTableHeader)}</tr>
        </thead>
        <tbody>
          {screenAirPropertiesResult.map(renderPerformanceTableRow)}
        </tbody>
      </PerformanceTable>
    </TablesContainer>
  );
};
