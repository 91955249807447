import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";
import * as Product from "../product";

const productCodePropertyName = "productcode";
const rangeTypePropertyName = "rangetype";

export function notes(
  system: System.System,
  systemType: Product.SystemType,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;

  const productCodeText =
    PropertyValueSet.getText(
      productCodePropertyName,
      sysComponent.properties
    ) || "";

  const rangeType = PropertyValueSet.getInteger(
    rangeTypePropertyName,
    sysComponent.properties
  );

  const rangeTypeText = translate(
    rangeType !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemType.id,
          rangeTypePropertyName,
          rangeType
        )
      : LanguageTexts.pNull()
  );

  return [rangeTypeText, productCodeText];
}
