export const model = "model";
export const processInletTemperature = "processinlettemperature";
export const processInletHumidity = "processinlethumidity";
export const processInletRelativeHumidity = "processinletrelativehumidity";
export const processInletWetTemperature = "processinletwettemperature";
export const processInletPressure = "processinletpressure";
export const processOutletFlow = "processoutletflow";
export const processOutletTemperature = "processoutlettemperature";
export const processOutletHumidity = "processoutlethumidity";
export const processOutletRelativeHumidity = "processoutletrelativehumidity";
export const processOutletDewPointTemperature =
  "processoutletdewpointtemperature";
export const processOutletPressure = "processoutletpressure";
export const processAvailableStaticPressure = "processavailablestaticpressure";
export const reactOutletFlow = "reactoutletflow";
export const reactAvailableStaticPressure = "reactavailablestaticpressure";
export const reactHeaterPower = "reactheaterpower";
export const totalPower = "totalpower";
export const moistureRemoval = "moistureremoval";
