import * as React from "react";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as Product from "../../product";
import * as System from "../../system";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as SharedState from "../../../shared-state";
import styled from "styled-components";
import { AccessoryRow } from "./accesory-row";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { AccessoryData } from "./types";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";

const Container = styled.div`
  padding: 15px;
  overflow-y: scroll;
`;

export function AccessoriesView({
  products,
  system,
  state,
  component,
  showGeneratedAccessories,
  sharedState,
  groupFilter,
  dispatch
}: {
  readonly system: System.System;
  readonly component: System.Component;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly products: ReadonlyArray<Product.Product>;
  readonly showGeneratedAccessories: boolean;
  readonly groupFilter: ReadonlyArray<string>;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const sysComponent = system.components.find(c => c.productId.endsWith("SYS"));
  const sysProperties = sysComponent?.properties || PropertyValueSet.Empty;
  const allProducts = ProductData.getValidProductsForRange(
    products,
    sysProperties
  ).filter(p => ProductData.filterProductForRange(p, sysProperties));

  const productsMap = new Map<string, Product.Product>();
  for (const product of allProducts) {
    productsMap.set(product.id, product);
  }

  const componentProperties = component?.properties || PropertyValueSet.Empty;
  const accessoriesPropertyName = PropertyValueSet.getPropertyNames(
    componentProperties
  ).filter(pName => isValidAccessory(productsMap, pName));

  const componentAccessories = system.components.filter(
    c => c.accessoryToId === component.id
  );

  const generatedAccessories = showGeneratedAccessories
    ? componentAccessories.filter(a =>
        isGeneratedAccessory(componentProperties, a.productId)
      )
    : [];

  return (
    <Container>
      {accessoriesPropertyName.map(pName => {
        const accessoryProductId = pName.replace("acc_", "").toUpperCase();
        const accessoryProductData = productsMap.get(accessoryProductId);
        const images = products.find(img => img.id === accessoryProductId)
          ?.images;

        if (!accessoryProductData) {
          return null;
        }

        const productProperties = productsMap.get(component.productId)!
          .properties;
        const property = productProperties.find(pp => pp.name === pName);

        if (
          !property ||
          !PropertyFilterHelpers.isValid(
            property.visibilityFilter,
            PropertyFilter.Empty,
            component.properties
          ) ||
          (groupFilter.length > 0 && // Used in system accessories to group items to be shown, for now is empty
            !groupFilter.includes(property.group))
        ) {
          return null;
        }
        const accessoryPropertiesSelectorState =
          state.accessoriesPropertiesSelectorStates[accessoryProductId];

        const accessoryData: AccessoryData = {
          type: "NormalAccessory",
          componentId: component.id,
          accessoryProductData,
          accessoriesPropertiesSelectorStates:
            state.accessoriesPropertiesSelectorStates,
          accessoryProductId,
          propertiesSelectorState: accessoryPropertiesSelectorState,
          parentPropertyName: pName,
          parentProperty: property,
          parentValueSources: property.valueSources,
          parentSelectedProperties: state.parentSelectedProperties
        };

        return (
          <AccessoryRow
            state={state}
            dispatch={dispatch}
            images={images || []}
            system={system}
            sysProperties={sysProperties}
            sharedState={sharedState}
            systemTypeName={system.file.systemTypeId}
            key={pName}
            accessoryData={accessoryData}
          />
        );
      })}
      {generatedAccessories.map(generatedAccessory => {
        const accessoryPropertiesSelectorState =
          state.accessoriesPropertiesSelectorStates[
            generatedAccessory.productId
          ];

        const accessoryProductData = productsMap.get(
          generatedAccessory.productId
        );

        if (!accessoryProductData) {
          return null;
        }

        const accessoryData: AccessoryData = {
          type: "GeneratedAccessory",
          accessoryProductData,
          accessoriesPropertiesSelectorStates:
            state.accessoriesPropertiesSelectorStates,
          accessoryProductId: generatedAccessory.productId,
          propertiesSelectorState: accessoryPropertiesSelectorState
        };

        return (
          <AccessoryRow
            state={state}
            dispatch={dispatch}
            images={[]}
            system={system}
            sysProperties={sysProperties}
            sharedState={sharedState}
            systemTypeName={system.file.systemTypeId}
            key={generatedAccessory.id}
            accessoryData={accessoryData}
          />
        );
      })}
    </Container>
  );
}

function isGeneratedAccessory(
  componentProperties: PropertyValueSet.PropertyValueSet,
  productId: string
): boolean {
  return (
    PropertyValueSet.getInteger(
      `acc_${productId}`.toLowerCase(),
      componentProperties
    ) === undefined
  );
}

function isValidAccessory(
  productsMap: Map<string, Product.Product>,
  pName: string
): boolean {
  if (!pName.startsWith("acc_")) {
    return false;
  }

  const productData = productsMap.get(pName.replace("acc_", "").toUpperCase());
  if (!productData) {
    return false;
  }

  return true;
}
