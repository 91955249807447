import { UnitMap, Unit } from "uom";
import Units from "./units";
function createCaseInsensitiveMap<T extends UnitMap.UnitMap>(
  units: T
): { readonly [P in keyof T]: T[P] } {
  const newUnits = Object.keys(units).reduce(
    (
      soFar: {
        // tslint:disable-next-line: readonly-keyword
        [key: string]: Unit.Unit<unknown>;
      },
      current: string
    ) => {
      const lower = current.toLowerCase();
      soFar[lower] = (Units as UnitMap.UnitMap)[current];
      soFar[current] = (Units as UnitMap.UnitMap)[current];

      return soFar;
    },
    {}
  ) as T;
  return newUnits;
}
const caseInsensitiveMap = createCaseInsensitiveMap(Units);

export const unitLookup: UnitMap.UnitLookup = unitString =>
  (unitString &&
    (caseInsensitiveMap as UnitMap.UnitMap)[unitString.toLowerCase()]) ||
  undefined;
