import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import * as Chart from "@genesys/client-core/lib/energy-result-visualizer/chart";
import * as Types from "./types";
import styled from "styled-components";
import { GenesysSelect, S1 } from "@genesys/ui-elements";

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > * {
    margin-right: 6px;
  }
`;

const ImageContainer = styled.div`
  display: inline-block;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 10%);
  border: 1px solid #f1f5fa;

  :nth-child(2) {
    margin-top: 5px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;

  :nth-child(2) {
    margin-top: 10px;
  }

  > span {
    padding-left: 5px;
    margin-right: 3px;
    width: 75px;
    > h4 {
      word-break: break-word;
    }
  }
`;

const dataTypes: ReadonlyArray<{
  readonly textId: string;
  readonly binType: Types.ClimateCoolingDataType;
}> = [
  { textId: "DryBulbTemperature", binType: "DB/MCWB" },
  { textId: "WetBulbTemperature", binType: "WB/MCDB" },
  { textId: "DewPointTemperature", binType: "DP/MCDB" },
  { textId: "SpecificEnthalpy", binType: "h/MCDB" }
];

export function ChartResultView({
  sharedState,
  chartPresets,
  selectedChartPresetId,
  selectedClimateCoolingDataType,
  climateCoolingDataType,
  binSize,
  energyResults,
  chartPresetOnChange,
  climateCoolingDataTypeOnChange
}: {
  readonly chartPresetOnChange: (id: string) => void;
  readonly climateCoolingDataTypeOnChange: (
    id: Types.ClimateCoolingDataType
  ) => void;
  readonly sharedState: SharedState.State;
  readonly chartPresets: ReadonlyArray<Types.ChartPreset>;
  readonly selectedChartPresetId: string;
  readonly energyResults: ReadonlyArray<Types.EnergyResult>;
  readonly binSize: number;
  readonly selectedClimateCoolingDataType: Types.ClimateCoolingDataType;
  readonly climateCoolingDataType: Types.ClimateCoolingDataType;
}) {
  const translate = sharedState.translate;
  const chartPreset = chartPresets.find(p => p.id === selectedChartPresetId)!;
  const si =
    sharedState.user.settings.selectedAmountProfile.measureSystem === 1;

  const newselectedClimateCoolingDataValue =
    selectedClimateCoolingDataType || dataTypes[0].binType;

  const charts = Chart.getEnergyCharts(
    energyResults,
    chartPreset,
    newselectedClimateCoolingDataValue,
    climateCoolingDataType,
    binSize,
    si,
    translate
  );

  return (
    <>
      <SubContainer>
        <span>{translate(LanguageTexts.chartPreset())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={chartPresets.map(cp => ({
            value: cp.id,
            title: translate(LanguageTexts.dynamicText(cp.name))
          }))}
          value={selectedChartPresetId}
          onChange={e => {
            chartPresetOnChange(e.target.value);
          }}
        />
        <span>{translate(LanguageTexts.chartXAxis())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={dataTypes.map(dt => ({
            value: dt.binType,
            title: translate(LanguageTexts.dynamicText(dt.textId))
          }))}
          value={newselectedClimateCoolingDataValue}
          onChange={e => {
            climateCoolingDataTypeOnChange(
              e.target.value as Types.ClimateCoolingDataType
            );
          }}
        />
      </SubContainer>
      <div>
        {charts.map((chart, ix) => {
          const name = energyResults[ix].name;
          return (
            <BoxContainer key={ix}>
              {name && (
                <span>
                  <S1 color="primary">{translate(name)}</S1>
                </span>
              )}
              <ImageContainer>{chart}</ImageContainer>;
            </BoxContainer>
          );
        })}
      </div>
    </>
  );
}
