import * as React from "react";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import styled from "styled-components";
import { GeneratedAccessory } from "./types";
import { H3, ArrowDown, ArrowUp } from "@genesys/ui-elements";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { PropertyValueSet } from "@genesys/property";
import { Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  h3 {
    margin-right: 10px;
  }
`;

const StyledH3 = styled(H3)`
  display: inline-block;
  font-size: 15px;
  line-height: 1.333rem;
  letter-spacing: 0px;
  color: rgb(2, 52, 66);
  font-weight: normal;
`;

export function GeneratedAccessories({
  parentPropertyName,
  isOpen,
  accessoryData,
  sharedState,
  dispatch,
  systemStatus,
  sysProperties
}: {
  readonly parentPropertyName: string;
  readonly isOpen: boolean;
  readonly dispatch: Dispatch<Action>;
  readonly accessoryData: GeneratedAccessory;
  readonly sharedState: SharedState.State;
  readonly systemStatus: number;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
}): JSX.Element {
  const header = (
    <Header
      onClick={() => {
        dispatch(Action.toggleCollapsed(accessoryData.accessoryProductId));
      }}
    >
      <StyledH3 weight="normal"> {parentPropertyName}</StyledH3>
      {isOpen ? <ArrowUp /> : <ArrowDown />}
    </Header>
  );

  if (!isOpen) {
    return header;
  }

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const filteredProductData = ProductDataTools.filterProductForRange(
    accessoryData.accessoryProductData,
    sysProperties
  );

  return (
    <Container>
      {header}
      <PropertiesSelector.PropertiesSelectorView
        fieldGroup={`Settings.${accessoryData.accessoryProductData.id}`}
        isReadonly={systemStatus >= SystemStatus.LockSuccess}
        propertiesInfo={filteredProductData.properties}
        dispatch={() => undefined}
        state={accessoryData.propertiesSelectorState}
        showCodes={showCodes}
        hidePropertyNames={false}
        sharedState={sharedState}
        productId={accessoryData.accessoryProductData.id}
      />
    </Container>
  );
}
