import * as Download from "./download";

interface XlsxSingleSheetExportProps {
  readonly data: Download.Data;
  readonly filename: string;
  readonly children: (download: () => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function XlsxSingleSheetExport(props: XlsxSingleSheetExportProps) {
  const sheets = [
    {
      name: undefined,
      data: props.data
    }
  ];
  return props.children(() => Download.download(sheets, props.filename));
}
