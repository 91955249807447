import * as React from "react";
import { PropertyValueSet } from "@genesys/property";
import * as GenesysPropertiesSelector from "../../genesys-properties-selector";
import * as GenesysPropertiesSelectorTypes from "../../genesys-properties-selector-types";
import * as GenesysPropertiesSelectorRenderers from "../../genesys-properties-selector-renderers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as KnownProperties from "../known-properties";
import { integerToClimateDataType } from "../climate-data-types";
import { formatAnnualOccurenceInteger } from "../climate-annual-occurences";

interface Props {
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly productDefinitions: Array<GenesysPropertiesSelectorTypes.Property>;
  readonly readOnlyProperties: Array<string>;
  readonly onCaseChange: (pvs: PropertyValueSet.PropertyValueSet) => void;
}

// tslint:disable-next-line:variable-name
export const CaseComponentAshrae = (props: Props) => {
  const climateDataDialogTranslation = {
    propertyName: (propertyName: string, translate: LanguageTexts.Translate) =>
      translate(
        LanguageTexts.globalPropertyName("climatedatadialog_" + propertyName)
      ),
    propertyValue: (
      propertyName: string,
      propertyValue: number,
      translate: LanguageTexts.Translate
    ) => {
      if (
        [
          KnownProperties.coolingDataTypeInteger,
          KnownProperties.heatingDataTypeInteger
        ].indexOf(propertyName) !== -1
      ) {
        return integerToClimateDataType(propertyValue);
      }

      if (
        [
          KnownProperties.coolingAnnualOccurenceInteger,
          KnownProperties.heatingAnnualOccurenceInteger
        ].indexOf(propertyName) !== -1
      ) {
        return formatAnnualOccurenceInteger(propertyValue);
      }

      return translate(
        LanguageTexts.globalPropertyName(
          "climatedatadialog_" + propertyName + "_" + propertyValue
        )
      );
    }
  };

  return (
    <GenesysPropertiesSelector.PropertiesSelectorContainer
      selectedProperties={props.selectedProperties}
      productProperties={props.productDefinitions}
      screenAmountProfileFieldGroup="ClimateDataCaseDialog"
      onChange={pvs => {
        props.onCaseChange(pvs);
      }}
      customTranslation={climateDataDialogTranslation}
      uniqueId="ClimateDataCaseDialog"
      readOnlyProperties={props.readOnlyProperties}
      layoutRenderer={
        GenesysPropertiesSelectorRenderers.columnsStackedWithGroupsLayoutRenderer
      }
    />
  );
};
