import styled from "../../styled-components";

// tslint:disable-next-line
export const TableInteractive = styled.table`
  text-align: left;
  border-spacing: 7px;
  width: 100%;

  tr {
    td {
      color: #959695;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 17px;
      padding-right: 15px;
    }
  }
`;
