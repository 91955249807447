import * as React from "react";
import { Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { AmountPropertySelector } from "../../../amount-property-selector";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import styled from "styled-components";
import { P2 } from "@genesys/ui-elements";
import { GreyDiv } from "../elements";

const Root = styled.div`
  table {
    td {
      padding: 4px 8px 0 0;
    }
  }
`;

export function Power({
  propertyValueSet,
  sharedState,
  dispatch
}: {
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <Root>
      <table>
        <tbody>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                Power
              </P2>
            </td>
            <td>
              <GreyDiv>
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="Power"
                  fieldGroup="Settings.AirPower"
                  fieldName={"power"}
                  readOnly={false}
                  propertyName={"power"}
                  propertyValueSet={propertyValueSet}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "Settings.AirPower",
                        "power",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(
                      Action.onFormatCleared("Settings.AirPower", "power")
                    )
                  }
                  onValueChange={value =>
                    value
                      ? dispatch(
                          Action.onPowerChange(
                            PropertyValueSet.set(
                              "power",
                              PropertyValue.fromAmount(value),
                              propertyValueSet
                            )
                          )
                        )
                      : propertyValueSet
                  }
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              </GreyDiv>
            </td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
}
