import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const createPricingMutation = gql`
  mutation CreatePricing(
    $name: String!
    $labels: [LabelInputType!]!
    $systemId: String
  ) {
    createPricing(name: $name, labels: $labels, systemId: $systemId) {
      id
      pricingId
      revisionNo
      pricingNo
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
