import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLAddRemoveFields from "graphql-add-remove-fields";
import * as GQLCachePatch from "gql-cache-patch";
import { DocumentNode } from "graphql";
import { exhaustiveCheck } from "ts-exhaustive-check";

export interface QueuedMutation<
  TData extends { readonly [key: string]: any } = {},
  TVariables extends { readonly [key: string]: any } = {}
> {
  readonly document: DocumentNode;
  readonly variables: TVariables;
  readonly optimisticResponse?: TData;
  readonly optimisticCachePatches?: ReadonlyArray<GQLCachePatch.CachePatch>;
  readonly tag?: string;
}

export interface MutationsQueue {
  readonly queuedMutations: ReadonlyArray<QueuedMutation>;
}
export type QueueMutationDescription =
  | AddToQueueMutationDescription
  | AddToQueueWithTagMutationDescription
  | AddToQueueReplaceTagMutationDescription
  | AddToQueueReplaceTagAndConcatOptimisticMutationDescription;

export interface AddToQueueMutationDescription {
  readonly type: "AddToQueueMutationDescription";
  readonly mutation: QueuedMutation;
}
export interface AddToQueueWithTagMutationDescription {
  readonly type: "AddToQueueWithTagMutationDescription";
  readonly mutation: QueuedMutation;
  readonly tag: string;
}
export interface AddToQueueReplaceTagMutationDescription {
  readonly type: "AddToQueueReplaceTagMutationDescription";
  readonly mutation: QueuedMutation;
  readonly tag: string;
}

export interface AddToQueueReplaceTagAndConcatOptimisticMutationDescription {
  readonly type: "AddToQueueReplaceTagAndConcatOptimisticMutationDescription";
  readonly mutation: QueuedMutation;
  readonly tag: string;
}

export function queueMutation(
  queueMutationDescription: QueueMutationDescription,
  mutationsQueue: MutationsQueue
): MutationsQueue {
  switch (queueMutationDescription.type) {
    case "AddToQueueMutationDescription": {
      return {
        ...mutationsQueue,
        queuedMutations: mutationsQueue.queuedMutations.concat(
          queueMutationDescription.mutation
        )
      };
    }
    case "AddToQueueWithTagMutationDescription": {
      return {
        ...mutationsQueue,
        queuedMutations: mutationsQueue.queuedMutations.concat({
          ...queueMutationDescription.mutation,
          tag: queueMutationDescription.tag
        })
      };
    }
    case "AddToQueueReplaceTagMutationDescription": {
      return {
        ...mutationsQueue,
        queuedMutations: mutationsQueue.queuedMutations
          .filter(
            qm =>
              qm.tag === undefined || qm.tag !== queueMutationDescription.tag
          )
          .concat({
            ...queueMutationDescription.mutation,
            tag: queueMutationDescription.tag
          })
      };
    }
    case "AddToQueueReplaceTagAndConcatOptimisticMutationDescription": {
      const newQueuedMutations: Array<QueuedMutation> = [];

      if (mutationsQueue.queuedMutations.length > 0) {
        for (const queuedMutation of mutationsQueue.queuedMutations) {
          if (
            queuedMutation.tag === undefined ||
            queuedMutation.tag !== queueMutationDescription.tag
          ) {
            newQueuedMutations.push(queuedMutation);
            continue;
          }

          newQueuedMutations.push({
            ...queueMutationDescription.mutation,
            tag: queueMutationDescription.tag,
            optimisticCachePatches: (
              queuedMutation.optimisticCachePatches || []
            ).concat(
              queueMutationDescription.mutation.optimisticCachePatches || []
            )
          });
        }
      } else {
        newQueuedMutations.push({
          ...queueMutationDescription.mutation,
          tag: queueMutationDescription.tag
        });
      }

      return {
        ...mutationsQueue,
        queuedMutations: newQueuedMutations
      };
    }
    default: {
      exhaustiveCheck(queueMutationDescription);
      return mutationsQueue;
    }
  }
}

export function dequeueMutation(
  mutation: QueuedMutation,
  mutationsQueue: MutationsQueue
): MutationsQueue {
  return {
    ...mutationsQueue,
    queuedMutations: mutationsQueue.queuedMutations.filter(m => m !== mutation)
  };
}

export function optimisticCache(
  mutationsQueue: MutationsQueue,
  entities: GraphQLEntityCache.EntityCache
): GraphQLEntityCache.EntityCache {
  const mutationsWithOptimisticResponse = mutationsQueue.queuedMutations.filter(
    x => x.optimisticResponse || x.optimisticCachePatches
  );

  if (mutationsWithOptimisticResponse.length === 0) {
    return entities;
  }

  let newEntities: GraphQLEntityCache.EntityCache = entities;

  for (const mutation of mutationsWithOptimisticResponse) {
    newEntities = applyOptimisticUpdate(mutation, newEntities);
  }

  return newEntities;
}

function applyOptimisticUpdate(
  {
    document,
    optimisticCachePatches,
    optimisticResponse,
    variables
  }: QueuedMutation,
  cache: GraphQLEntityCache.EntityCache
): GraphQLEntityCache.EntityCache {
  if (optimisticResponse) {
    const mutationWithRequiredFields = GraphQLAddRemoveFields.addFields(
      document as any,
      ["__typename"]
    );

    const normalizedEntities = GraphQLEntityCache.normalize(
      mutationWithRequiredFields,
      variables,
      { data: optimisticResponse! }
    );

    return GraphQLEntityCache.mergeEntityCache(cache, normalizedEntities);
  }

  if (optimisticCachePatches) {
    const [newCache, newStaleEntites] = GQLCachePatch.apply(
      optimisticCachePatches,
      cache,
      getStaleEntities(cache)
    );
    const newCacheWithStaleEntities = setStaleEntities(
      newCache,
      newStaleEntites
    );
    return newCacheWithStaleEntities;
  }

  return cache;
}

const __STALE_ENTITIES = "__STALE_ENTITIES";

export function getStaleEntities(
  cache: GraphQLEntityCache.EntityCache
): GraphQLEntityCache.StaleEntities {
  return (cache[__STALE_ENTITIES] as any) || {};
}

export function setStaleEntities(
  cache: GraphQLEntityCache.EntityCache,
  staleEntities: GraphQLEntityCache.StaleEntities
): GraphQLEntityCache.EntityCache {
  // (cache as any)[__STALE_ENTITIES] = staleEntities;
  return { ...cache, [__STALE_ENTITIES]: staleEntities } as any;
}
