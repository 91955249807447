import styled from "styled-components";

// tslint:disable-next-line
export const InputEditText = styled.input`
  width: auto;

  ::placeholder {
    color: #ccc;
  }
`;
