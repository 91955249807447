import { Quantity, Unit, Amount } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";

export interface AmountFieldFormat {
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly measureSystem: number;
  readonly unit: string;
  readonly decimalCount: number;
}

export interface QuantityFormat {
  readonly measureSystem: number;
  readonly quantity: string;
  readonly unit: string;
  readonly decimalCount: number;
}

export interface AmountFormat {
  readonly unit: Unit.Unit<Quantity.Quantity>;
  readonly decimalCount: number;
  readonly userDefined: boolean;
}

export interface PropertyFormats {
  readonly [key: string]: AmountFormat;
}

export interface QuantityDefaultsMap {
  // tslint:disable-next-line
  readonly [key: string]: { [measureSystem: number]: AmountFormat };
}

export interface FieldDefaultsMap {
  readonly [key: string]: AmountFormat;
}

export interface UserAmountFieldsMap {
  readonly [key: string]: AmountFormat;
}

export type GetPropertyFormats = (
  fieldGroup: string,
  properties: PropertyValueSet.PropertyValueSet
) => PropertyFormats;

export type GetAmountFormat = (
  fieldGroup: string,
  fieldName: string,
  amountOrQuantity: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
) => AmountFormat;

// tslint:disable-next-line:variable-name
export const SIProfileId = "71E5AA44-0C8C-4647-BDC4-996854A15FB9";
// tslint:disable-next-line:variable-name
export const IPProfileId = "7906B262-93DC-4666-A43A-14009374329E";
