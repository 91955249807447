import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { Action, ReportHeaderInfo } from "./state";
import { H3, Input } from "@genesys/ui-elements";

const SpecialPropertiesContainer = styled.div`
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  align-items: center;
  row-gap: 31px;
  column-gap: 5px;
  margin-bottom: 15px;
`;

const PropertiesLabel = styled.div`
  margin-right: 15px;
`;

export function SpecialHeaders({
  sharedState,
  reportHeaderInfo,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly reportHeaderInfo: ReportHeaderInfo;
}) {
  const maxSystemNameLength = 35;
  const maxSalesOrderLength = 35;
  const maxPreparedForLength = 23;
  const maxEndUserLength = 23;

  const errors = getSpecialHeadersErrors(
    reportHeaderInfo,
    sharedState.translate,
    true
  );

  return (
    <>
      <H3> {sharedState.translate(LanguageTexts.header())}</H3>
      <SpecialPropertiesContainer>
        <PropertiesLabel>
          {sharedState.translate(LanguageTexts.systemName()) +
            ` (max ${maxSystemNameLength})`}
        </PropertiesLabel>
        <Input
          hasError={errors.get("system-name")}
          onDebounceValueChange={[
            (value: string) =>
              dispatch(
                Action.setReportHeaderInfo({
                  ...reportHeaderInfo,
                  systemName: value
                })
              ),
            [reportHeaderInfo]
          ]}
          placeholder=""
          value={reportHeaderInfo.systemName}
        />

        <PropertiesLabel>
          {sharedState.translate(LanguageTexts.preparedFor()) +
            ` (max ${maxPreparedForLength})`}
        </PropertiesLabel>
        <Input
          hasError={errors.get("prepared-for")}
          onDebounceValueChange={[
            value => {
              dispatch(
                Action.setReportHeaderInfo({
                  ...reportHeaderInfo,
                  preparedFor: value
                })
              );
            },
            [reportHeaderInfo]
          ]}
          placeholder=""
          value={reportHeaderInfo.preparedFor}
        />

        <PropertiesLabel>
          {sharedState.translate(LanguageTexts.salesOrder()) +
            ` (max ${maxSalesOrderLength})`}
        </PropertiesLabel>
        <Input
          hasError={errors.get("sales-order")}
          onDebounceValueChange={[
            value => {
              dispatch(
                Action.setReportHeaderInfo({
                  ...reportHeaderInfo,
                  salesOrder: value
                })
              );
            },
            [reportHeaderInfo]
          ]}
          placeholder=""
          value={reportHeaderInfo.salesOrder}
        />

        <PropertiesLabel>
          {sharedState.translate(LanguageTexts.endUser()) +
            ` (max ${maxEndUserLength})`}
        </PropertiesLabel>
        <Input
          hasError={errors.get("end-user")}
          onDebounceValueChange={[
            value => {
              dispatch(
                Action.setReportHeaderInfo({
                  ...reportHeaderInfo,
                  endUser: value
                })
              );
            },
            [reportHeaderInfo]
          ]}
          placeholder=""
          value={reportHeaderInfo.endUser}
        />
      </SpecialPropertiesContainer>
    </>
  );
}

export function getSpecialHeadersErrors(
  reportHeaderInfo: ReportHeaderInfo,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  hasSpecialHeaders: boolean
) {
  if (!hasSpecialHeaders) {
    return new Map();
  }

  const maxSystemNameLength = 35;
  const maxSalesOrderLength = 35;
  const maxPreparedForLength = 23;
  const maxEndUserLength = 23;

  return new Map<
    string,
    | {
        readonly title: string;
        readonly message: string;
        readonly type: "error";
      }
    | undefined
  >([
    [
      "system-name",
      (reportHeaderInfo.systemName.length > maxSystemNameLength && {
        type: "error",
        title: "Max Characters Exceeded",
        message:
          "name for " + translate(LanguageTexts.systemName()) + " is too long"
      }) ||
        undefined
    ],
    [
      "prepared-for",
      (reportHeaderInfo.preparedFor.length > maxPreparedForLength && {
        type: "error",
        title: "Max Characters Exceeded",
        message:
          "name for " + translate(LanguageTexts.preparedFor()) + " is too long"
      }) ||
        undefined
    ],
    [
      "sales-order",
      (reportHeaderInfo.salesOrder.length > maxSalesOrderLength && {
        type: "error",
        title: "Max Characters Exceeded",
        message:
          "name for " + translate(LanguageTexts.salesOrder()) + " is too long"
      }) ||
        undefined
    ],
    [
      "end-user",
      (reportHeaderInfo.endUser.length > maxEndUserLength && {
        type: "error",
        title: "Max Characters Exceeded",
        message:
          "name for " + translate(LanguageTexts.endUser()) + " is too long"
      }) ||
        undefined
    ]
  ]);
}
