import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "styled-components";
import { PopOverContainer } from "./shared";

export interface ContextValue {
  readonly isOpen: boolean;
  readonly toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ButtonWithPopUpContext = React.createContext<ContextValue | null>(
  null
);

const ButtonPopContainer = styled(PopOverContainer)`
  position: absolute;
  right: 0;
`;

export function ButtonWithPopUp({
  icon,
  isDisabled,
  popupContent
}: {
  readonly icon: JSX.Element;
  readonly isDisabled: boolean;
  readonly popupContent: React.ReactNode;
}) {
  const [isOpen, toggle] = React.useState(false);

  const providerValue = {
    isOpen,
    toggle
  };

  return (
    <ButtonWithPopUpContext.Provider value={providerValue}>
      <span
        style={{ cursor: !isDisabled ? "pointer" : "auto" }}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          toggle(!isOpen);
        }}
      >
        {icon}
      </span>
      {isOpen && (
        <ClickAwayListener onClickAway={() => toggle(false)}>
          <ButtonPopContainer>{popupContent}</ButtonPopContainer>
        </ClickAwayListener>
      )}
    </ButtonWithPopUpContext.Provider>
  );
}
