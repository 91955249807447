import * as React from "react";
import * as System from "../../../system";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { StaticImgRoot } from "../elements";
import { StaticImage } from "../types";

export function StaticImage({
  staticImages,
  system,
  routeEndpoint,
  sharedState,
  onLoaded
}: {
  readonly staticImages: ReadonlyArray<StaticImage>;
  readonly system: System.System;
  readonly routeEndpoint: string;
  readonly sharedState: SharedState.State;
  readonly onLoaded: () => void;
}): JSX.Element {
  const displayImage: StaticImage | undefined = staticImages.find(
    img => img.format === "PNG" || img.format === "SVG"
  );

  if (displayImage === undefined) {
    return (
      <div>
        {sharedState.translate(LanguageTexts.cannotShowMechanicalSketchMsg())}
      </div>
    );
  }

  return (
    <StaticImgRoot
      accessToken={sharedState.accessToken}
      onLoad={onLoaded}
      src={`${routeEndpoint}${displayImage.url}?niid=${system.statusDate}`}
    />
  );
}
