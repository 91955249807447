import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import gql from "graphql-tag";
import * as GraphQlTypes from "../graphql-types";

const queryProduct = gql`
  query AboutProductQuery {
    product {
      serverInformation {
        id
        serverCommitInfo
        version
        serverName
        userDbInfo
        productDbInfo
      }
    }
  }
`;

export type State = {
  readonly productData: GraphQlTypes.AboutProductQuery | undefined;
};

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    { productData: undefined },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.AboutProductQuery,
      GraphQlTypes.AboutProductQueryVariables,
      Action
    >(queryProduct, {}, Action.productDataRecieved)
  ];
};

export const Action = ctorsUnion({
  productDataRecieved: (productData: GraphQlTypes.AboutProductQuery) => ({
    productData
  }),
  dummy: () => ({})
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "productDataRecieved": {
      return [{ ...state, productData: action.productData }];
    }
    case "dummy": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
