import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 75%;
`;

export const ALertContainer = styled.div`
  width: 70%;
  margin: auto;
  margin-top: 10px;
`;
