import * as React from "react";
import * as Elements from "@genesys/ui-elements";
import * as Types from "./types";
export function FilterListComponent(
  header: string,
  set: Set<string>,
  enabledSet: Set<string>,
  toggle: (id: string) => void
): JSX.Element {
  return (
    <div key={header}>
      <h2>{header}</h2>
      <ul>
        <li>
          <Elements.CheckBox
            isChecked={enabledSet.has(Types.All)}
            onClick={() => {
              toggle(Types.All);
            }}
            children="All"
          />
        </li>
        <li>
          <Elements.CheckBox
            isChecked={enabledSet.has(Types.Empty)}
            onClick={() => {
              toggle(Types.Empty);
            }}
            children="Empty"
          />
        </li>
        {Array.from(set.values())
          .sort((a, b) => a.localeCompare(b))
          .filter(c => c !== Types.All && c !== Types.Empty)
          .map(c => (
            <li key={c}>
              <Elements.CheckBox
                isChecked={enabledSet.has(c)}
                onClick={() => {
                  toggle(c);
                }}
                children={c}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
