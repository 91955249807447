import styled from "styled-components";

// tslint:disable-next-line
export const LargeButton = styled.button`
  background-image: linear-gradient(to top, #e0e0e0, white 60%);

  border-radius: 4px;
  border-width: 1px;
  padding: 4px 5px;
  min-width: 100px;

  font-size: 14px;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    background: linear-gradient(to top, rgb(193, 217, 237), white 90%);
  }
`;
