import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import {
  getClimateSettingsForLocation,
  MapDataPoints,
  getLocationData,
  getClimateSettingsForLocationChange,
  isValidLocation
} from "./functions";
import { dataPointsQuery, locationQuery } from "./queries";
import { Quantity, Unit } from "@genesys/uom";
import { DataPoint, LocationData } from "./types";

// onChangeTrigger is somewhat evil some might say but...
// its being used so that the OPC-selector that uses this can
// get updated with the values without a save button.
// Just make sure whenever climateSettings is changed to toggle it.

export const defaultWmo = "136150";

export type State = {
  readonly onChangeTrigger: boolean;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly countriesData:
    | GraphQLTypes.ClimateSelectorCountriesProductQuery
    | undefined;
  readonly dataPoints: ReadonlyArray<DataPoint> | undefined;
  readonly messages: ReadonlyArray<string> | undefined;
  readonly selectedMonth: string | undefined;
  readonly currentLocation: LocationData | undefined;
};

export const init = (
  climateSettings: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  return [
    {
      climateSettings: climateSettings,
      onChangeTrigger: false,
      countriesData: undefined,
      selectedMonth: undefined,
      dataPoints: undefined,
      messages: undefined,
      currentLocation: undefined
    },
    sharedState.graphQL.queryProductCmd<
      GraphQLTypes.ClimateSelectorCountriesProductQuery,
      GraphQLTypes.ClimateSelectorCountriesProductQueryVariables,
      Action
    >(locationQuery, {}, Action.queryCountryDataReceived)
  ];
};

export const Action = ctorsUnion({
  queryCountryDataReceived: (
    data: GraphQLTypes.ClimateSelectorCountriesProductQuery
  ) => ({ data }),
  queryDataPointsDataReceived: (
    data: GraphQLTypes.ClimateSelectorDataPointsProductQuery,
    messages?: ReadonlyArray<string>
  ) => ({ data, messages }),
  onSettingsChange: (climateSettings: PropertyValueSet.PropertyValueSet) => ({
    climateSettings
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  setSelectedMonth: (mon: string | undefined) => ({ mon })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "queryCountryDataReceived": {
      return [
        { ...state, countriesData: action.data },
        sharedState.graphQL.queryProductCmd<
          GraphQLTypes.ClimateSelectorDataPointsProductQuery,
          GraphQLTypes.ClimateSelectorDataPointsProductQueryVariables,
          Action
        >(
          dataPointsQuery,
          {
            locationId:
              PropertyValueSet.getText("wmo", state.climateSettings!) ||
              sharedState.user.settings.climate.location!
          },
          Action.queryDataPointsDataReceived
        )
      ];
    }
    case "queryDataPointsDataReceived": {
      const isManualDataSource = !PropertyValueSet.hasProperty(
        "wmo",
        state.climateSettings
      );

      const locationId =
        PropertyValueSet.getText("wmo", state.climateSettings!) ||
        sharedState.user.settings.climate.location!;

      const countries = state.countriesData?.product.countries!;

      if (!action.data.product.dataPointsForLocationId) {
        let newSystemClimateSettings = { ...state.climateSettings };

        if (!isManualDataSource) {
          newSystemClimateSettings = getClimateSettingsForLocationChange(
            state.climateSettings,
            countries,
            defaultWmo
          );
        }
        const message = isManualDataSource
          ? undefined
          : [
              `Previously chosen wmo: ${locationId} could not be found. A default wmo (${defaultWmo}) has been chosen, pls confirm or choose a new location`
            ];

        return [
          {
            ...state,
            climateSettings: newSystemClimateSettings,
            onChangeTrigger: !state.onChangeTrigger
          },
          sharedState.graphQL.queryProductCmd<
            GraphQLTypes.ClimateSelectorDataPointsProductQuery,
            GraphQLTypes.ClimateSelectorDataPointsProductQueryVariables,
            Action
          >(
            dataPointsQuery,
            {
              locationId: defaultWmo
            },
            data => Action.queryDataPointsDataReceived(data, message)
          )
        ];
      }

      let currentLocation = getLocationData(
        isValidLocation(countries, locationId) ? locationId : defaultWmo,
        state.countriesData?.product.countries!
      )!;

      const dataPoints = MapDataPoints(
        action.data.product.dataPointsForLocationId
      );
      let newMonth = state.selectedMonth;

      const newSystemClimateSettings = !isManualDataSource
        ? getClimateSettingsForLocation(
            state.climateSettings,
            dataPoints,
            currentLocation.location,
            mon => (newMonth = mon)
          )
        : state.climateSettings;

      return [
        {
          ...state,
          selectedMonth: newMonth,
          dataPoints: dataPoints,
          currentLocation: currentLocation,
          messages: action.messages,
          climateSettings: newSystemClimateSettings,
          onChangeTrigger: !state.onChangeTrigger
        }
      ];
    }
    case "onSettingsChange": {
      const isManualDataSource = !PropertyValueSet.hasProperty(
        "wmo",
        action.climateSettings
      );

      const isNewLocation =
        PropertyValueSet.getText("wmo", action.climateSettings) !==
        PropertyValueSet.getText("wmo", state.climateSettings!);

      // if it is a new location the datapoints needs to be updated
      if (isNewLocation && !isManualDataSource) {
        return [
          {
            ...state,
            climateSettings: action.climateSettings
          },
          sharedState.graphQL.queryProductCmd<
            GraphQLTypes.ClimateSelectorDataPointsProductQuery,
            GraphQLTypes.ClimateSelectorDataPointsProductQueryVariables,
            Action
          >(
            dataPointsQuery,
            {
              locationId: PropertyValueSet.getText(
                "wmo",
                action.climateSettings
              )!
            },
            Action.queryDataPointsDataReceived
          )
        ];
      } else {
        return [
          {
            ...state,
            climateSettings: action.climateSettings,
            onChangeTrigger: !state.onChangeTrigger
          }
        ];
      }
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    case "setSelectedMonth": {
      return [{ ...state, selectedMonth: action.mon }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getClimateSettings(state: State) {
  return state.climateSettings;
}

export function getCurrentLocationData(
  state: State,
  sharedState: SharedState.State
): {
  readonly countryName: string;
  readonly regionName: string;
  readonly locationId: string;
} {
  const locationId =
    PropertyValueSet.getText("wmo", state.climateSettings) ||
    sharedState.user.settings.climate.location ||
    defaultWmo;
  const countriesData = state.countriesData!;

  const locationData = getLocationData(
    locationId,
    countriesData.product.countries
  );
  return {
    countryName: locationData?.country.name || "albania",
    regionName: locationData?.region.name || "albania-all-regions",
    locationId: locationData?.location.id || locationId
  };
}
