import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Input,
  StandardButton,
  P1,
  P2,
  Label,
  HelpIcon,
  ClearIcon,
  GenesysSelect,
  PrimaryColors
} from "@genesys/ui-elements";
import { DatePicker } from "./date-picker";
import { exhaustiveCheck } from "ts-exhaustive-check";

import {
  SearchFilterOptions,
  SearchOption,
  SearchOptionsComponentProps
} from "../types";

const SearchFilterOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button,
  input {
    cursor: pointer;
  }

  button:first-of-type {
    width: 258px;
    margin-top: 13px;
    justify-content: center;
  }
  button:not(button:first-of-type) {
    height: 40px;
    background-color: inherit;
    display: flex;
    align-items: center;
    margin: 10px 50px;
    padding: 5px;
    :hover {
      background: ${PrimaryColors.lightBlue};
      border-radius: 20px;
    }
  }
  button:last-of-type > img:last-of-type {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    :hover {
      background: ${PrimaryColors.lightBlue};
      border-radius: 20px;
    }
  }
`;

const StatusSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchFilterOptionInputContainer = styled.div`
  margin-top: 10px;
  width: 258px;
  input {
    width: inherit;
    height: 24px;
  }
`;

export function SearchFilterView<T>({
  onSearchFilterOptionsToggle,
  onSearchFilterOptionsChange,
  onSearchOptionsSearch,
  onClearSearchOptions,
  onToggleHelpModal,
  sharedState,
  isSearchFilterOptionsOpen,
  searchOptions,
  searchOptionValues
}: SearchOptionsComponentProps<T>) {
  if (!isSearchFilterOptionsOpen) {
    return null;
  }

  const { textFieldOptions } = searchOptionValues;

  const onTextfieldChange = (key: string) => (e: any) => {
    const newTextFieldOptions = {
      ...textFieldOptions,
      [key]: e.target.value
    };
    onSearchFilterOptionsChange({
      ...searchOptionValues,
      textFieldOptions: newTextFieldOptions
    });
  };

  const getSearchKeyFromSearchFilterOptions = () => {
    const options = buildSearchOptions(searchOptionValues);
    const searchKey: string = Object.keys(options)
      .filter(key => !!options[key])
      .map(key => `${key}:"${options[key]}"`)
      .join(" ");

    return searchKey;
  };

  return (
    <ClickAwayListener
      onClickAway={e => {
        const nodeName = (e.target as any).nodeName as string;
        // HACK to prevent the status select box not to close the page
        // For some reason the selectbox triggers and comes here which is not desirable
        if (nodeName === "BODY") {
          return;
        }
        onSearchFilterOptionsToggle(false);
      }}
    >
      <SearchFilterOptionsContainer>
        {searchOptions.map(searchOption => {
          const key = searchOption.option as string;
          const value = textFieldOptions[key] || "";

          if (searchOption.customRenderer) {
            return searchOption.customRenderer(value);
          }

          return (
            <SearchFilterOptionInputContainer key={key}>
              {createSearchInputItem(
                searchOption,
                sharedState,
                textFieldOptions,
                () =>
                  onSearchOptionsSearch(getSearchKeyFromSearchFilterOptions()),
                onTextfieldChange
              )}
            </SearchFilterOptionInputContainer>
          );
        })}
        <DatePicker
          onSearchFilterOptionsChange={onSearchFilterOptionsChange}
          searchOptionValues={searchOptionValues}
          sharedState={sharedState}
        />
        <StandardButton
          size="Large"
          buttonType="Primary"
          onClick={() => {
            onSearchOptionsSearch(getSearchKeyFromSearchFilterOptions());
          }}
        >
          {sharedState.translate(LanguageTexts.search())}
        </StandardButton>

        <button onClick={onClearSearchOptions}>
          <ClearIcon />
          <P1 color="secondary">
            {sharedState.translate(LanguageTexts.clear())}
          </P1>
        </button>

        <button onClick={() => onToggleHelpModal()}>
          <HelpIcon />
          <P2 color="secondary" weight="bold">
            {sharedState.translate(LanguageTexts.helpAboutSearching())}
          </P2>
        </button>
      </SearchFilterOptionsContainer>
    </ClickAwayListener>
  );
}

function buildSearchOptions(searchFilterOptions: SearchFilterOptions): {
  readonly [key: string]: string;
} {
  const maybeWithDate: {
    readonly [key: string]: string;
  } = searchFilterOptions.useDate
    ? {
        date: `(${searchFilterOptions.textFieldOptions["from"]})-(${searchFilterOptions.textFieldOptions["to"]})`
      }
    : {};

  const withoutDate = Object.keys(searchFilterOptions.textFieldOptions)
    .filter(key => key !== "from" && key !== "to")
    .reduce(
      (soFar, key) => ({
        ...soFar,
        [key]: searchFilterOptions.textFieldOptions[key]
      }),
      {}
    );
  return {
    ...withoutDate,
    ...maybeWithDate
  };
}

function createSearchInputItem<K>(
  searchItem: SearchOption<K>,
  sharedState: SharedState.State,
  textFieldOptions: {
    readonly [key: string]: string;
  },
  onSearch: () => void,
  onTextfieldChange: (key: string) => (e: any) => void
) {
  const searchItemKey = searchItem.option as string;

  const value = textFieldOptions[searchItemKey] || "";

  const label = (
    <Label weight="normal" color="secondary">
      {sharedState.translate(LanguageTexts.filterOptionsText(searchItemKey))}
    </Label>
  );

  const createInputBox = (maxWidth?: string) => (
    <Input
      style={{ maxWidth, width: "100%", paddingLeft: "6px" }}
      value={value.trim()}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          onSearch();
        }
      }}
      // onDebounceValueChange={[onTextfieldChange(searchItemKey), [searchItem]]}
      onChange={onTextfieldChange(searchItemKey)}
    />
  );

  const type = searchItem.type;

  switch (type) {
    case "text": {
      return (
        <>
          {label}
          {createInputBox()}
        </>
      );
    }
    case "status-search": {
      return (
        <>
          {label}
          <StatusSearchContainer>
            {createInputBox("116px")}
            <GenesysSelect
              width={70}
              height={22}
              fontSize={12}
              backgroundColor={"#ffffff"}
              value={value}
              options={searchItem.statusOptions}
              onChange={onTextfieldChange(searchItemKey)}
            />
          </StatusSearchContainer>
        </>
      );
    }

    default: {
      return exhaustiveCheck(type);
    }
  }
}
