import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Quantity, Unit } from "@genesys/uom";
import { Cmd } from "@typescript-tea/core";
import { BinVisualizer, BinListViewType, ChartType } from "../../binning-tools";

import {
  getInitialPsychrometricChartSettings,
  getInitialBinSelections,
  getInitialOperationTime,
  buildSettingsDictionary,
  getInitialBinSize,
  getInitialBinType,
  getInitialClimateCoolingDataType,
  getBinFields
} from "./tools";
import gql from "graphql-tag";
import * as LocationSelectorGen2 from "../../location-selector";
import * as GraphQlTypes from "../../graphql-types";
import * as SharedState from "../../shared-state";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as PsychrometricChart from "../../psychrometric-chart";
import * as OperationTimeGen2 from "../../operation-time-manager";
import * as ClimateSelector from "../../climate-selector";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";

const generateBinCasesQuery = gql`
  query BinToolBinCaseGenerator($input: String!) {
    product {
      binDataHourlyVersion
      climateDataBins(input: $input) {
        id
        binId
        midPointTemp
        midPointWetTemp
        midPointDewPointTemp
        midPointSpecificEnthalpy
        midPointHumidityRatio
        midPointHourly
        averageTemp
        humidity
        wind
        binPressure
        binTime
        binTimeJanuary
        binTimeFebruary
        binTimeMarch
        binTimeApril
        binTimeMay
        binTimeJune
        binTimeJuly
        binTimeAugust
        binTimeSeptember
        binTimeOctober
        binTimeNovember
        binTimeDecember
      }
      psycProClimateDataBins(input: $input) {
        id
        binId
        time
        temperature
        humidity
      }
    }
  }
`;

const bindataLocationsQuery = gql`
  query BinLocations($locationId: String!) {
    product {
      dataPointsForLocationId(locationId: $locationId) {
        id
        caseType
        climateDataType
        annualOccurence
        temperature
        humidity
        windSpeed
      }

      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }

      binDataLocations {
        binDataLocationId
        locationName
        regionName
        countryName
        latitude
        longitude
      }
    }
  }
`;

export type State = {
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined;
  readonly locationSelectorState: LocationSelectorGen2.State | undefined;
  readonly binCasesData: GraphQlTypes.BinToolBinCaseGenerator | undefined;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
  readonly operationTimeState: OperationTimeGen2.State | undefined;
  readonly isBinCasesDataLoading: boolean;
  readonly binSelections: PropertyValueSet.PropertyValueSet | undefined;
  readonly selectedBinVisualizer: BinVisualizer;
  readonly selectedBinListView: BinListViewType;
  readonly binDataLocations: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["binDataLocations"][0]
  >;
  readonly selectedChartType: ChartType;
  readonly hourlyDataVersion: string;
};

export const init = (sharedState: SharedState.State): [State, Cmd<Action>?] => {
  return [
    {
      psychrometricChartState: undefined,
      locationSelectorState: undefined,
      binCasesData: undefined,
      binCases: undefined,
      operationTimeState: OperationTimeGen2.init()[0],
      isBinCasesDataLoading: false,
      binSelections: undefined,
      selectedBinListView: "standard",
      selectedChartType: "mollier_IP",
      binDataLocations: [],
      selectedBinVisualizer: "List",
      hourlyDataVersion: ""
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.BinLocations,
      GraphQlTypes.BinLocationsVariables,
      Action
    >(
      bindataLocationsQuery,
      {
        locationId: ClimateSelector.defaultWmo
      },
      data => Action.binLocationsQueryRecieved(data)
    )
  ];
};

export const Action = ctorsUnion({
  binCasesQueryReceived: (
    data: GraphQlTypes.BinToolBinCaseGenerator,
    chartType?: ChartType
  ) => ({
    data,
    chartType
  }),

  binLocationsQueryRecieved: (data: GraphQlTypes.BinLocations) => ({
    data
  }),
  binToolsInputOnSelectionChange: (
    binSelections: PropertyValueSet.PropertyValueSet,
    binCases: ReadonlyArray<SharedEnergyTools.BinCase>
  ) => ({ binSelections, binCases }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  }),
  dispatchLocationSelector: (action: LocationSelectorGen2.Action) => ({
    action
  }),
  dispatchOperationTime: (action: OperationTimeGen2.Action) => ({
    action
  }),
  generateBinCases: (binSelections: PropertyValueSet.PropertyValueSet) => ({
    binSelections
  }),
  setBinCases: (binCases: ReadonlyArray<SharedEnergyTools.BinCase>) => ({
    binCases
  }),
  setBinSelections: (binSelections: PropertyValueSet.PropertyValueSet) => ({
    binSelections
  }),
  setBinVisualizer: (binVisualizer: BinVisualizer) => ({ binVisualizer }),
  setBinListView: (binListView: BinListViewType) => ({ binListView }),
  setChartType: (chartType: ChartType) => ({ chartType }),
  toggleOperationTime: () => ({}),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "binCasesQueryReceived": {
      const binCases = action.data.product.climateDataBins.map(bin => {
        const midPointTemp = bin.midPointTemp
          ? PropertyValue.getAmount<Quantity.Temperature>(
              PropertyValue.fromString(bin.midPointTemp)!
            )!
          : undefined;

        const midPointWetTemp = bin.midPointWetTemp
          ? PropertyValue.getAmount<Quantity.WetTemperature>(
              PropertyValue.fromString(bin.midPointWetTemp)!
            )!
          : undefined;

        const midPointDewPointTemp = bin.midPointDewPointTemp
          ? PropertyValue.getAmount<Quantity.DewPointTemperature>(
              PropertyValue.fromString(bin.midPointDewPointTemp)!
            )!
          : undefined;
        const midPointSpecificEnthalpy = bin.midPointSpecificEnthalpy
          ? PropertyValue.getAmount<Quantity.SpecificEnthalpy>(
              PropertyValue.fromString(bin.midPointSpecificEnthalpy)!
            )!
          : undefined;
        const midPointHumidityRatio = bin.midPointHumidityRatio
          ? PropertyValue.getAmount<Quantity.HumidityRatio>(
              PropertyValue.fromString(bin.midPointHumidityRatio)!
            )!
          : undefined;

        const midPointHourly = bin.midPointHourly
          ? PropertyValue.getAmount<Quantity.Dimensionless>(
              PropertyValue.fromString(bin.midPointHourly)!
            )!
          : undefined;

        return {
          binId: bin.binId,
          midPointTemp: midPointTemp,
          midPointWetTemp: midPointWetTemp,
          midPointDewPointTemp: midPointDewPointTemp,
          midPointSpecificEnthalpy: midPointSpecificEnthalpy,
          midPointHumidityRatio,
          midPointHourly,

          averageTemperature: PropertyValue.getAmount<Quantity.Temperature>(
            PropertyValue.fromString(bin.averageTemp)!
          )!,
          averageHumidity: PropertyValue.getAmount<Quantity.HumidityRatio>(
            PropertyValue.fromString(bin.humidity)!
          )!,
          windSpeed: PropertyValue.getAmount<Quantity.Velocity>(
            PropertyValue.fromString(bin.wind)!
          )!,
          binPressure: PropertyValue.getAmount<Quantity.Pressure>(
            PropertyValue.fromString(bin.binPressure)!
          )!,
          binTime: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTime)!
          )!,
          binTimeJanuary: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJanuary)!
          )!,
          binTimeFebruary: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeFebruary)!
          )!,
          binTimeMarch: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeMarch)!
          )!,
          binTimeApril: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeApril)!
          )!,
          binTimeMay: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeMay)!
          )!,
          binTimeJune: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJune)!
          )!,
          binTimeJuly: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJuly)!
          )!,
          binTimeAugust: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeAugust)!
          )!,
          binTimeSeptember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeSeptember)!
          )!,
          binTimeOctober: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeOctober)!
          )!,
          binTimeNovember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeNovember)!
          )!,
          binTimeDecember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeDecember)!
          )!
        } as SharedEnergyTools.BinCase;
      });

      const binFields = getBinFields(action.data);

      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(
          getInitialPsychrometricChartSettings(
            state,
            action.chartType || "mollier_SI",
            binFields,
            sharedState,
            binCases
          )
        );

      return [
        {
          ...state,
          hourlyDataVersion: action.data.product.binDataHourlyVersion,
          binCases: binCases,
          binCasesData: action.data,
          isBinCasesDataLoading: false,
          psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }

    case "binLocationsQueryRecieved": {
      const binLocationId = LocationSelectorGen2.groupLocations(
        action.data.product.binDataLocations
      )[0].regions[0].locations[0].binDataLocationId;

      const binLocation = action.data.product.binDataLocations.find(
        b => b.binDataLocationId === binLocationId
      );

      const userEnergySettings = buildSettingsDictionary(
        sharedState.user.settings.energySettings
      );

      const defaultClimateSettings = ClimateSelector.getDefaultClimateSettings(
        sharedState,
        PropertyValueSet.Empty,
        action.data.product.dataPointsForLocationId!,
        action.data.product.countries
      );

      const initialBinSize = getInitialBinSize(userEnergySettings);
      const initialBinType = getInitialBinType(defaultClimateSettings);
      const initailCimateCoolingDataType = getInitialClimateCoolingDataType(
        defaultClimateSettings
      );

      const binCoordinate = binLocation
        ? {
            latitude: binLocation.latitude,
            longitude: binLocation.longitude
          }
        : undefined;

      const locationSelectorState = LocationSelectorGen2.init(
        action.data.product.binDataLocations,
        binCoordinate
      );

      const [operationTimeState] = OperationTimeGen2.init(
        getInitialOperationTime()
      );
      const binSelections = getInitialBinSelections(
        initialBinSize,
        operationTimeState!,
        initialBinType,
        initailCimateCoolingDataType,
        sharedState.user.settings.selectedAmountProfile.name === "SI",
        binLocation
      );

      return [
        { ...state, binSelections, locationSelectorState, operationTimeState }
      ];
    }

    case "binToolsInputOnSelectionChange": {
      return [
        {
          ...state,
          binCases: action.binCases,
          binSelections: action.binSelections
        }
      ];
    }

    case "dispatchLocationSelector": {
      if (state.locationSelectorState === undefined) {
        return [state];
      }

      const [locationSelectorState, newBinLocationId] =
        LocationSelectorGen2.update(
          action.action,
          state.locationSelectorState,
          sharedState
        );

      const locationName =
        state.binDataLocations.find(
          l => l.binDataLocationId === newBinLocationId
        )?.locationName ?? "";

      const newBinSelections = PropertyValueSet.setText(
        KnownProperties.binDataLocationId,
        newBinLocationId,
        PropertyValueSet.setText(
          KnownProperties.binLocation,
          locationName,
          state.binSelections ?? PropertyValueSet.Empty
        )
      );

      return [
        {
          ...state,
          binSelections: newBinSelections,
          locationSelectorState,
          binCases: []
        }
      ];
    }

    case "dispatchPsychrometricChart": {
      if (state.psychrometricChartState === undefined) {
        return [state];
      }
      const [psychrometricChartState, psychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.psychrometricChartState,
          sharedState
        );
      return [
        {
          ...state,
          psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        sharedStateCmd
      ];
    }

    case "dispatchOperationTime": {
      if (state.operationTimeState === undefined) {
        return [state];
      }

      const [operationTimeState] = OperationTimeGen2.update(
        action.action,
        state.operationTimeState
      );

      const binSelections = PropertyValueSet.merge(
        PropertyValueSet.setText(
          KnownProperties.binOperationTime,
          OperationTimeGen2.presetName(operationTimeState!),
          OperationTimeGen2.toPropertyValueSet(operationTimeState!)
        ),
        state.binSelections ?? PropertyValueSet.Empty
      );

      return [
        {
          ...state,
          binCases: [],
          binSelections,
          operationTimeState
        }
      ];
    }

    case "generateBinCases": {
      return [
        {
          ...state,
          isBinCasesDataLoading: true,
          binCases: [],
          psychrometricChartState: undefined,
          binSelections: action.binSelections
        },

        generateBinCases(
          action.binSelections,
          sharedState,
          state.selectedChartType
        )
      ];
    }

    case "setBinCases": {
      return [
        {
          ...state,
          binCases: action.binCases
        }
      ];
    }

    case "setBinListView": {
      return [
        {
          ...state,
          selectedBinListView: action.binListView
        }
      ];
    }

    case "setBinSelections": {
      return [
        {
          ...state,
          binSelections: action.binSelections
        }
      ];
    }

    case "setBinVisualizer": {
      const shouldFetchPsychpsychrometricChart =
        action.binVisualizer === "Chart" &&
        state.psychrometricChartState === undefined;
      return [
        {
          ...state,
          isBinCasesDataLoading: shouldFetchPsychpsychrometricChart,
          binCases: shouldFetchPsychpsychrometricChart ? [] : state.binCases,
          selectedBinVisualizer: action.binVisualizer
        },
        shouldFetchPsychpsychrometricChart
          ? generateBinCases(
              state.binSelections,
              sharedState,
              state.selectedChartType
            )
          : undefined
      ];
    }

    case "setChartType": {
      const binFields = getBinFields(state.binCasesData);
      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(
          getInitialPsychrometricChartSettings(
            state,
            action.chartType,
            binFields,
            sharedState,
            state.binCases!
          )
        );
      return [
        {
          ...state,
          psychrometricChartState,
          selectedChartType: action.chartType
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }

    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }

    case "toggleOperationTime": {
      const [operationTimeState] = OperationTimeGen2.init();
      return [
        {
          ...state,
          operationTimeState:
            state.operationTimeState === undefined
              ? operationTimeState
              : state.operationTimeState
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function generateBinCases(
  binSelections: PropertyValueSet.PropertyValueSet | undefined,
  sharedState: SharedState.State,
  chartType?: ChartType
): Cmd<Action> | undefined {
  if (binSelections === undefined) {
    return undefined;
  }
  return sharedState.graphQL.queryProductCmd<
    GraphQlTypes.BinToolBinCaseGenerator,
    GraphQlTypes.BinToolBinCaseGeneratorVariables,
    Action
  >(
    generateBinCasesQuery,
    {
      input: PropertyValueSet.toString(binSelections)
    },
    data => Action.binCasesQueryReceived(data, chartType)
  );
}
// tslint:disable-next-line
