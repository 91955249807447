import * as React from "react";
import styled from "styled-components";

export const unStyledDataCell = (
  props: { readonly alignRight?: boolean } & React.HTMLProps<
    HTMLTableDataCellElement
  >
) => {
  const { alignRight, ...htmlProps } = props;
  return <td {...htmlProps}>{htmlProps.children}</td>;
};

// tslint:disable-next-line
export const DataCell = styled(unStyledDataCell)`
  ${props => (props.alignRight === true ? "text-align: right;" : "")};
`;
