import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setSystemPriceInformationMutation = gql`
  mutation SetSystemPriceInformation(
    $input: SetSystemPriceInformationInputType!
  ) {
    setSystemPriceInformation(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
