import styled from "styled-components";

// tslint:disable-next-line
export const ErrorPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 25px 10px;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 3px;

  max-height: 500px;
  overflow: auto;

  div:first-child {
    margin-right: 20px;
    flex-shrink: 0;
  }

  > p {
    margin-top: 10px;
  }
`;
