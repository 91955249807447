import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

export function SnackBarComponent({
  children,
  show
}: {
  readonly children: React.ReactNode;
  readonly show: boolean;
}) {
  const handleClose = (_event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
  };
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "right",
        vertical: "top"
      }}
      open={show}
      onClose={handleClose}
    >
      <Alert severity="info">{children}</Alert>
    </Snackbar>
  );
}
