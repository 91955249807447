import React from "react";
import styled from "styled-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  Label,
  zIndex,
  P2,
  CheckBox,
  Img,
  PrimaryColors
} from "@genesys/ui-elements";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  ArrowDown,
  MenuDots,
  FavoriteActive,
  FavoriteInactive
} from "@genesys/ui-elements/lib/icons";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as SharedState from "../../shared-state";
import * as StatusIcon from "../../status-icon-manager";
import * as SystemActions from "../../system-actions";
import * as Authorization from "@genesys/shared/lib/authorization";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { SystemManagerUser } from "../../graphql-types";
import { clientConfig } from "../../config";

const Root = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 430px;
  width: 330px;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 16px 16px;
  a {
    cursor: pointer;
  }
  :hover {
    border: 1px solid #3d3d3d;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 10%;
  padding-top: 4px;
`;

const CheckBoxContainer = styled.div`
  position: relative;
  top: -10px;
  margin: 0 224px 0 16px;
`;

const FavoriteContainer = styled.div`
  position: relative;
  top: 2px;
  right: 2px;
`;

const SystemActionsContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  position: absolute;
  border: 1px solid #dddddd;
`;

const SystemOuterContainer = styled.div`
  height: 79.5%;
  cursor: pointer;
  a {
    text-decoration: none;
  }
`;

const SystemImg = styled(Img)`
  height: 114px;
  width: 260px;
  display: block;
  margin: auto;
  object-fit: contain;
`;

const SystemInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0 10px 0;
  height: 80px;
`;

const SystemInfoContainer = styled.div`
  display: flex;
  width: 85%;
  max-height: 33px;
  margin: auto;
  padding: 3px 0;
  p:first-child {
    width: 8em;
    word-break: break-all;
    white-space: normal;
  }
  p:nth-child(2) {
    width: 14em;
    word-break: break-all;
    white-space: normal;
  }
`;

const RevisionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 11.5%;
  padding: 0 20px;
  border-radius: 0 0 9px 9px;
  background-color: #f7f9fc;
`;

const RevisionOptionsContainer = styled.div`
  max-height: 80px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const RevisionsDropDownButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 34px;
  padding: 5px;
  cursor: pointer;
  :hover {
    border-radius: 20px;
    background: ${PrimaryColors.blue25};
  }
`;

const RevisionsDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 105%;
  background-color: #ffffff;
  padding: 10px 0;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
  z-index: ${zIndex.Dropdown};
  position: absolute;
  right: -9px;
  bottom: 40px;
  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    padding: 0 10px;
    margin: 0;
    background-color: inherit;
    cursor: pointer;
    span {
      max-width: 170px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly systemActionsState: SystemActions.State | undefined;
  readonly result: SystemManagerUser["user"]["searchSystems"]["results"][0];
  readonly isRevisionDropdownOpen: boolean;
  readonly isSystemActionsOpen: boolean;
  readonly selectedSystemIds: ReadonlyArray<String>;
  readonly userSelectedRevisions: {
    readonly [systemId: string]: string | undefined;
  };
  readonly onToggleIsFavorite: (systemId: string) => void;
  readonly onToggleRevisionDropdown: (id: string) => void;
  readonly onToggleSystemActions: (id: string) => void;
  readonly onToggleSystemSelected: (id: string) => void;
  readonly onChangeActiveRevision: (
    systemId: string,
    revisionId: string
  ) => void;
}

export function SearchResultGridComponent(props: Props) {
  const {
    dispatch,
    sharedState,
    result,
    isRevisionDropdownOpen,
    isSystemActionsOpen,
    systemActionsState,
    selectedSystemIds,
    onToggleRevisionDropdown,
    onToggleSystemActions,
    onToggleIsFavorite,
    onChangeActiveRevision,
    userSelectedRevisions,
    onToggleSystemSelected
  } = props;

  const routeEndpoint = clientConfig.genesysBackend;

  const formatDate = (date: number) =>
    DateHelper.getDateString(
      DateHelper.fromUTC(date),
      sharedState.user.settings.locale
    );

  const latestSystem = result.systems.reduce(
    (a, b) => (b.revisionNo > a.revisionNo ? b : a),
    result.systems[0]
  );

  const anyRevisionChecked = !!selectedSystemIds.find(
    s => !!result.systems.find(ss => ss.id === s)
  );

  const effectiveRevision = userSelectedRevisions[result.id] || latestSystem.id;

  const effectiveSystem = result.systems.find(s => s.id === effectiveRevision)!;

  const revisionNumber = sharedState.genesysPrefix.genesysNo(
    result.genesysNo,
    effectiveSystem.revisionNo
  );

  const genesysNo = sharedState.genesysPrefix.genesysNo(
    result.genesysNo,
    effectiveSystem.revisionNo
  );

  const isFavorite = sharedState.user.favoritesSystems.find(fs =>
    fs.type === "loaded"
      ? fs.system.id === effectiveSystem.id
      : fs.id === effectiveSystem.id
  );

  const revisionOptions: {
    readonly systemId: string;
    readonly revisionNo: string;
    readonly comment: string;
    readonly statusDate: string;
  }[] = result.systems.map(s => {
    return {
      systemId: s.id,
      revisionNo: s.revisionNo.toString(),
      comment: s.comment || sharedState.translate(LanguageTexts.nocomment()),
      statusDate: formatDate(s.statusDate)
    };
  });

  const maybeToggleSystemActions = (id: string) => {
    if (!systemActionsState) {
      onToggleSystemActions(id);
    } else if (
      systemActionsState &&
      SystemActions.isInMenu(systemActionsState)
    ) {
      onToggleSystemActions(id);
    } else {
      return;
    }
  };

  return (
    <Root>
      <IconsContainer>
        <CheckBoxContainer>
          <CheckBox
            children=""
            isChecked={anyRevisionChecked}
            onClick={() => onToggleSystemSelected(latestSystem.id)}
          />
        </CheckBoxContainer>

        <FavoriteContainer>
          {isFavorite ? (
            <a onClick={() => onToggleIsFavorite(effectiveSystem.id)}>
              <FavoriteActive height="1.4rem" />
            </a>
          ) : (
            <a onClick={() => onToggleIsFavorite(effectiveSystem.id)}>
              <FavoriteInactive height="1.4rem" />
            </a>
          )}
        </FavoriteContainer>

        <div>
          <a
            onMouseEnter={() => {
              maybeToggleSystemActions(result.id);
            }}
            onMouseLeave={() => {
              maybeToggleSystemActions(result.id);
            }}
          >
            <MenuDots height="1.8rem" />
            {isSystemActionsOpen && systemActionsState && (
              <SystemActionsContainer>
                <SystemActions.SystemActionsView
                  dispatch={Dispatch.map(
                    Action.dispatchSystemActions,
                    dispatch
                  )}
                  // sharedState={sharedState}
                  state={systemActionsState!}
                  // systemFile={{
                  //   id: result.id,
                  //   name: result.name,
                  //   labels: result.labels,
                  //   owner: result.owner.userName,
                  //   genesysNo: result.genesysNo,
                  //   systems: result.systems.map(s => ({
                  //     id: s.id,
                  //     revisionNo: s.revisionNo,
                  //     comment:
                  //       s.comment ||
                  //       sharedState.translate(LanguageTexts.nocomment()),
                  //     status: s.status,
                  //     statusDate: s.statusDate
                  //   }))
                  // }}
                  // revisionId={effectiveSystem.id}
                  // isLockRevisionDisabled={true}
                  // showExportButton={Authorization.checkPermission(
                  //   sharedState.user.applicationClaims,
                  //   Authorization.genesysUserClaims.developer
                  // )}
                  // isSaveAndCalculateDisabled={true}
                  // isCreatePricingDisabled={true}
                  systemActions={SystemActions.createActions(
                    {
                      dispatch: Dispatch.map(
                        Action.dispatchSystemActions,
                        dispatch
                      ),
                      sharedState,
                      state: systemActionsState,
                      systemFile: {
                        id: result.id,
                        name: result.name,
                        labels: result.labels,
                        owner: result.owner.userName,
                        genesysNo: result.genesysNo,
                        systems: result.systems.map(s => ({
                          id: s.id,
                          revisionNo: s.revisionNo,
                          comment:
                            s.comment ||
                            sharedState.translate(LanguageTexts.nocomment()),
                          status: s.status,
                          statusDate: s.statusDate
                        }))
                      }
                    },
                    [
                      SystemActions.renameAction,
                      SystemActions.editCommentAction,
                      SystemActions.assignLabelsAction,
                      SystemActions.deleteSystemAction,
                      SystemActions.createNewRevisionAction,
                      SystemActions.transferToUserAction,
                      SystemActions.copyTransferOpenAction,
                      ...(Authorization.checkPermission(
                        sharedState.user.applicationClaims,
                        Authorization.genesysUserClaims.developer
                      )
                        ? [SystemActions.exportSystemAction]
                        : [])
                    ]
                  )}
                />
              </SystemActionsContainer>
            )}
          </a>
        </div>
      </IconsContainer>

      <SystemOuterContainer>
        <a href={`/system/${genesysNo}`}>
          <SystemImg
            accessToken={sharedState.accessToken}
            src={`${routeEndpoint}/internal/SystemPreviewApi/${effectiveSystem.id}?format=SVG&diagramtype=unitconfiguration`}
          />

          <SystemInnerContainer>
            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                G#
              </P2>
              <P2 weight="normal" color="secondary">
                {result.systemTypeId} {revisionNumber}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.name())}
              </P2>
              <P2 weight="normal" color="secondary">
                {result.name.length > 45
                  ? result.name.substring(0, 45) + "..."
                  : result.name}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.comment())}
              </P2>
              <P2 weight="normal" color="secondary">
                {effectiveSystem.comment ||
                  sharedState.translate(LanguageTexts.nocomment())}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.type())}
              </P2>
              <P2 weight="normal" color="secondary">
                {result.systemTypeId}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.owner())}
              </P2>
              <P2 weight="normal" color="secondary">
                {result.owner.userName}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.lastChange())}
              </P2>
              <P2 weight="normal" color="secondary">
                {formatDate(effectiveSystem.statusDate)}
              </P2>
            </SystemInfoContainer>

            <SystemInfoContainer>
              <P2 weight="bold" color="secondary">
                {sharedState.translate(LanguageTexts.status())}
              </P2>
              <P2 weight="normal" color="secondary">
                <StatusIcon.StatusIconManager
                  sharedState={sharedState}
                  statusNumber={effectiveSystem.status}
                  height="21.81px"
                  width="21.81px"
                ></StatusIcon.StatusIconManager>
              </P2>
            </SystemInfoContainer>
          </SystemInnerContainer>
        </a>
      </SystemOuterContainer>

      <RevisionContainer>
        <Label weight="normal" color="secondary">
          {sharedState.translate(LanguageTexts.revisions()).toUpperCase()}
        </Label>

        <RevisionsDropDownButton
          onClick={() => onToggleRevisionDropdown(result.id)}
        >
          <Label weight="normal" color="secondary">
            {result.systems.length}
          </Label>
          <ArrowDown height="0.4rem" />
        </RevisionsDropDownButton>

        {isRevisionDropdownOpen && (
          <ClickAwayListener
            onClickAway={() => {
              onToggleRevisionDropdown(result.id);
            }}
          >
            <RevisionsDropDown>
              <RevisionOptionsContainer>
                {revisionOptions.map(ro => (
                  <button
                    key={ro.systemId}
                    onClick={() => {
                      onChangeActiveRevision(result.id, ro.systemId);
                    }}
                  >
                    <Label weight="bold" color="dark">
                      {ro.revisionNo} &nbsp; {ro.comment}
                    </Label>
                    <Label weight="bold" color="dark">
                      {ro.statusDate}
                    </Label>
                  </button>
                ))}
              </RevisionOptionsContainer>
            </RevisionsDropDown>
          </ClickAwayListener>
        )}
      </RevisionContainer>
    </Root>
  );
}
//tslint:disable-next-line
