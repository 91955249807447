import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchFilterButton = styled.button`
  margin-left: 2px;
  height: 28px;
  border: 1px solid ${Colors.searchFilterBorderColor};
  border-radius: 2px;
  background-color: ${Colors.searchBackground};
  width: 22px;
  vertical-align: bottom;
  cursor: pointer;

  position: relative;
  &:hover {
    border-color: ${Colors.searchFilterBorderHoverColor};
    border-width: 2px;
  }
`;
