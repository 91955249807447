import { PropertySetOption } from "../types";

export const propertySetOptions: ReadonlyArray<PropertySetOption> = [
  {
    propertyName: "reactheatertype",
    value: 1,
    displayName: "Electric"
  },
  {
    propertyName: "reactheatertype",
    value: 2,
    displayName: "Gas"
  },
  {
    propertyName: "reactheatertype",
    value: 3,
    displayName: "Steam"
  },
  {
    propertyName: "voltage",
    value: 23015060,
    displayName: "230/1/50-60"
  },
  {
    propertyName: "voltage",
    value: 230150,
    displayName: "230/1/50"
  },
  {
    propertyName: "voltage",
    value: 400350,
    displayName: "400/3/50"
  },
  {
    propertyName: "prereactheater",
    value: 0,
    displayName: "None"
  },
  {
    propertyName: "newcondenser",
    value: 0,
    displayName: "No"
  },
  {
    propertyName: "newcondenser",
    value: 1,
    displayName: "Yes"
  }
];
