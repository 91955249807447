import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const TriangleArrow = styled.div`
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 6px solid ${Colors.searchFilterBorderHoverColor};
  cursor: pointer;
`;
