import * as React from "react";
import * as ReactRedux from "react-redux";
import * as Contexts from "../contexts";
import * as State from "./state";
import * as Actions from "./actions";

interface RootState {
  readonly ui: {
    readonly debugSettings: {
      readonly includeServerLog: boolean;
      readonly showHiddenProperties: boolean;
      readonly showHiddenResults: boolean;
    };
  };
}

type Props = {
  readonly children: any;
  readonly dispatch: (action: any) => void;
} & State.State;

// tslint:disable-next-line:class-name
class debugSettingsProvider extends React.Component<
  Props,
  Contexts.DebugSettingsContextValue
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      setDebugSettings: this.setDebugSettings,
      includeServerLog: props.includeServerLog,
      showHiddenProperties: props.showHiddenProperties,
      showHiddenResults: props.showHiddenResults
    };
  }

  setDebugSettings = (
    includeServerLog: boolean,
    showHiddenProperties: boolean,
    showHiddenResults: boolean
  ) => {
    this.props.dispatch(
      Actions.setDebugSettings(
        includeServerLog,
        showHiddenProperties,
        showHiddenResults
      )
    );
  };

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    props: Props,
    prevState: Contexts.DebugSettingsContextValue
  ): Partial<Contexts.DebugSettingsContextValue> | null {
    if (
      props.includeServerLog !== prevState.includeServerLog ||
      props.showHiddenProperties !== prevState.showHiddenProperties ||
      props.showHiddenResults !== prevState.showHiddenResults
    ) {
      return {
        includeServerLog: props.includeServerLog,
        showHiddenProperties: props.showHiddenProperties,
        showHiddenResults: props.showHiddenResults
      };
    }

    return null;
  }

  render(): JSX.Element {
    const { children } = this.props;
    return (
      <Contexts.DebugSettingsContext.Provider value={this.state}>
        {children}
      </Contexts.DebugSettingsContext.Provider>
    );
  }
}
function mapStateToProps(state: RootState): State.State {
  return state.ui.debugSettings;
}
// tslint:disable-next-line:variable-name
export const DebugSettingsProvider = ReactRedux.connect(mapStateToProps)(
  debugSettingsProvider
);
