import styled from "styled-components";
// import * as Colors from "../colors";

// tslint:disable-next-line:variable-name
export const ActionMenuLabelEditorSplitContainer = styled.div`
  display: flex;

  div {
    flex-grow: 1;
  }
`;
