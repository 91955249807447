import * as React from "react";
// tslint:disable-next-line:variable-name
export interface DebugSettingsContextValue {
  readonly includeServerLog: boolean;
  readonly showHiddenProperties: boolean;
  readonly showHiddenResults: boolean;
  readonly setDebugSettings: (
    includeServerLog: boolean,
    showHiddenProperties: boolean,
    showHiddenResults: boolean
  ) => void;
}

// tslint:disable-next-line:variable-name
export const DebugSettingsContext = React.createContext<
  DebugSettingsContextValue
>({
  includeServerLog: false,
  showHiddenProperties: false,
  showHiddenResults: false,
  setDebugSettings: () => {
    /*  */
  }
});
