import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";
import { updateSystemNonBlockingMutation } from "./update-system-mutation";

export async function tryRaiseSystemStatusToPriceCalculationSuccess(
  queryUser: SharedState.State["graphQL"]["queryUser"],
  systemId: string
): Promise<string | undefined> {
  const traceId = await queryUser<
    GraphQLTypes.UpdateSystemNonBlocking,
    GraphQLTypes.UpdateSystemNonBlockingVariables
  >(updateSystemNonBlockingMutation, {
    input: {
      forceUpdate: false,
      systemId,
      targetStatus: SystemStatus.PriceCalculationSuccess
    }
  }).then(data => data.updateSystemNonBlocking.traceId || undefined);
  return traceId;
}
