import * as React from "react";
import { PropertyValueSet } from "@genesys/property";
import { Unit, Quantity } from "@genesys/uom";
import * as PropertiesSelector from "../react-properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { DispatchProp } from "../redux-integration";
import * as ScreenAmountProfile from "../screen-amount-profile";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as Actions from "./actions";
import * as Component from "./genesys-properties-selector-component";
import * as GenesysPropertiesSelectorRenderers from "../genesys-properties-selector-renderers";

export type Props = OwnProps & StateProps & DispatchProp<Actions.Action>;

export type OwnProps = {
  readonly lockSingleValidValue?: boolean;
  readonly customTranslation?: {
    readonly propertyName?: (
      propertyName: string,
      translate: LanguageTexts.Translate
    ) => string;
    readonly propertyValue?: (
      propertyName: string,
      propertyValue: number,
      translate: LanguageTexts.Translate
    ) => string;
  };
  readonly hidePropertyNames?: boolean;
  readonly includeHiddenProperties?: boolean;
  readonly includeCodes?: boolean;
  readonly layoutRenderer?: (
    props: PropertiesSelector.LayoutRendererProps
  ) => React.ReactElement<PropertiesSelector.LayoutRendererProps>;
  readonly onChange: (
    properties: PropertyValueSet.PropertyValueSet,
    changedProperties: ReadonlyArray<string>
  ) => void;
  readonly productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>;
  readonly screenAmountProfileFieldGroup: string;
  readonly readOnlyProperties?: ReadonlyArray<string>;
  readonly sections?: {
    readonly [key: string]: { readonly groups: ReadonlyArray<string> };
  };
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly uniqueId: string;
  readonly onDelete?: () => void;
  readonly valueSources?: {
    readonly [
      propertyName: string
    ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  };
  readonly groupFilter?: ReadonlyArray<string>;
  readonly showGroups?: boolean;
  readonly showDelete?: boolean;
};

export type StateProps = {
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly closedGroups: GenesysPropertiesSelectorTypes.ClosedGroups;
};

// tslint:disable-next-line:function-name
export function ContainerComponent({
  lockSingleValidValue = false,
  activatedQuantitySelectors,
  customTranslation,
  dispatch,
  closedGroups,
  hidePropertyNames,
  includeCodes,
  includeHiddenProperties = false,
  layoutRenderer = GenesysPropertiesSelectorRenderers.stackedWithGroupsLayoutRenderer,
  productProperties,
  onChange,
  readOnlyProperties,
  sections,
  selectedProperties,
  screenAmountProfileFieldGroup,
  uniqueId,
  onDelete = () => ({}),
  valueSources,
  groupFilter = [],
  showGroups = true,
  showDelete = false
}: Props) {
  return (
    <ScreenAmountProfile.ScreenAmountProfile>
      {({
        getAmountFormat,
        measureSystem,
        quantityDefaults,
        saveAmountFieldFormat,
        deleteAmountFieldFormat
      }) => {
        const validationErrors =
          validatePropertyValueSetSameAsProductProperties(
            selectedProperties,
            productProperties
          );
        if (validationErrors.length > 0) {
          const stringifiedError = validationErrors.reduce(
            (soFar, current) =>
              soFar +
              `Propery: ${current.propertyName}, was: ${current.actual}, expected: ${current.expected} \n`,
            ""
          );
          throw new Error(
            `PropertValueSet contains properties with different quantities than product property definition \n ${stringifiedError}`
          );
        }

        const propertyFormats = buildPropertyFormats(
          getAmountFormat,
          screenAmountProfileFieldGroup,
          productProperties
        );

        const onPropertyFormatChanged = (
          fieldName: string,
          unit: Unit.Unit<any>,
          decimalCount: number
        ) => {
          saveAmountFieldFormat(
            screenAmountProfileFieldGroup,
            fieldName,
            unit,
            decimalCount
          );
        };

        const onPropertyFormatCleared = (propertyName: string) => {
          deleteAmountFieldFormat(screenAmountProfileFieldGroup, propertyName);
        };
        return (
          <Component.GenesysPropertiesSelectorComponent
            activatedQuantitySelectors={activatedQuantitySelectors}
            lockSingleValidValue={lockSingleValidValue}
            closedGroups={closedGroups}
            customTranslation={customTranslation}
            dispatch={dispatch}
            includeCodes={includeCodes}
            includeHiddenProperties={includeHiddenProperties}
            getAmountFormat={getAmountFormat}
            groupFilter={groupFilter}
            showGroups={showGroups}
            hidePropertyNames={hidePropertyNames}
            layoutRenderer={layoutRenderer}
            originalProductProperties={productProperties}
            measureSystem={measureSystem}
            onChange={onChange}
            onPropertyFormatChanged={onPropertyFormatChanged}
            onPropertyFormatCleared={onPropertyFormatCleared}
            propertyFormats={propertyFormats}
            quantityDefaults={quantityDefaults}
            readOnlyProperties={readOnlyProperties}
            sections={sections}
            selectedProperties={selectedProperties}
            uniqueId={uniqueId}
            onDelete={onDelete}
            fieldGroup={screenAmountProfileFieldGroup}
            valueSources={valueSources}
            showDelete={showDelete}
          />
        );
      }}
    </ScreenAmountProfile.ScreenAmountProfile>
  );
}

function validatePropertyValueSetSameAsProductProperties(
  pvs: PropertyValueSet.PropertyValueSet,
  productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>
): ReadonlyArray<{
  readonly propertyName: string;
  readonly actual: string;
  readonly expected: string;
}> {
  const soFar: Array<{
    readonly propertyName: string;
    readonly actual: string;
    readonly expected: string;
  }> = [];
  for (const pp of productProperties) {
    const property = PropertyValueSet.getAmount(pp.name, pvs);

    if (!property || pp.quantity === property.unit.quantity) {
      continue;
    }

    soFar.push({
      propertyName: pp.name,
      actual: property.unit.quantity as Quantity.Quantity,
      expected: pp.quantity
    });
  }
  return soFar;
}

function buildPropertyFormats(
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  fieldGroup: string,
  productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>
): PropertiesSelector.PropertyFormats {
  const propertyFormats: PropertiesSelector.PropertyFormats =
    productProperties.reduce(
      (soFar, pp) => {
        if (pp.quantity === "Discrete" || pp.quantity === "Text") {
          return soFar;
        }

        const amountFormat = getAmountFormat(
          fieldGroup,
          pp.fieldName || pp.name,
          pp.quantity as Quantity.Quantity
        );

        // PropertyFormats doesn't handle quantity conversion. We do it amount property selector instead.
        if (amountFormat.unit.quantity !== pp.quantity) {
          return soFar;
        }

        soFar[pp.name] = amountFormat;

        return soFar;
      },
      // tslint:disable-next-line:readonly-keyword
      {} as { [key: string]: PropertiesSelector.AmountFormat }
    );

  return propertyFormats;
}
