import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { updateComponentPropertiesMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import { promiseCmd } from "../../../promise-effect-manager";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { tryRaiseSystemStatusToPriceCalculationSuccess } from "../calculate";

export function updateComponentProperties(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<
    ConfiguratorAction,
    { readonly type: "updateComponentProperties" }
  >
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        for (const c of action.component) {
          await sharedState.graphQL.queryUser<
            GraphQLTypes.UpdateComponentProperties,
            GraphQLTypes.UpdateComponentPropertiesVariables
          >(updateComponentPropertiesMutation, {
            input: {
              componentId: c.componentId,
              properties: PropertyValueSet.toString(c.properties),
              systemId
            }
          });
        }

        if (action.calculate) {
          const traceId = await tryRaiseSystemStatusToPriceCalculationSuccess(
            sharedState.graphQL.queryUser,
            systemId
          );
          return traceId;
        }

        return undefined;
      },
      traceId =>
        action.calculate
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculate
      ? [
          ...action.component.map(
            _ => SystemStatus.ValidateConfigurationSuccess
          ),
          SystemStatus.PriceCalculationSuccess
        ]
      : action.component.map(_ => SystemStatus.ValidateConfigurationSuccess)
  ];
}
