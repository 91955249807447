import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as Exporter from "@genesys/client-core/lib/exporter";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { Quantity, Unit } from "@genesys/uom";
import { PropertyValue } from "@genesys/property";
import { BinDataLocation } from "../../location-selector";
import { AmountFormatSelector } from "../../amount-format-selector";
import {
  GenesysSelect,
  StandardButton,
  ReadOnlyTable
} from "@genesys/ui-elements";
import { BinListViewType, BinType } from "../types";
import { ExportButtonsContainer, ViewTypeSelectorContainer } from "./elements";
import { listFieldGroup } from "./list-field-group";
import {
  createBinsExportData,
  createBinList,
  isMonthlyBinTablePossible,
  getLocationExportData
} from "./functions";

export function ListView({
  binCases,
  pressure,
  sharedState,
  exportFileName,
  selectedBinType,
  binDataLocation,
  selectedBinListView,
  setBinListView,
  onFormatChanged,
  onFormatCleared
}: {
  readonly exportFileName: string;
  readonly selectedBinType: BinType;
  readonly sharedState: SharedState.State;
  readonly binDataLocation: BinDataLocation | undefined;
  readonly selectedBinListView: BinListViewType;
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase>;
  readonly pressure?: PropertyValue.PropertyValue;
  readonly setBinListView: (binListView: BinListViewType) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
}) {
  const showMonthly = isMonthlyBinTablePossible(binCases);

  const binListViewOptions = [
    {
      id: "standard",
      title: sharedState.translate(LanguageTexts.binViewStandard())
    },
    {
      id: "withmonths",
      title: sharedState.translate(LanguageTexts.binViewWithMonths())
    }
  ].filter(obj => showMonthly || obj.id !== "withmonths");

  const tableBinList = createBinList(
    selectedBinType,
    selectedBinListView,
    binCases,
    sharedState.translate,
    sharedState.screenAmounts.getAmountFormat
  );

  const getExportDataSheets = (type: "standard" | "withmonths") => {
    const binsSheet = {
      name: "Bins",
      data: createBinsExportData(
        createBinList(
          selectedBinType,
          type,
          binCases,
          sharedState.translate,
          sharedState.screenAmounts.getAmountFormat
        )
      )
    };

    if (selectedBinType === "Generated" || selectedBinType === "Custom") {
      return [
        binsSheet,
        {
          name: "Location",
          data: getLocationExportData(
            binDataLocation,
            pressure,
            sharedState.translate
          )
        }
      ];
    } else {
      return [binsSheet];
    }
  };

  return (
    <>
      <ViewTypeSelectorContainer>
        <GenesysSelect
          width={80}
          height={35}
          value={selectedBinListView}
          options={binListViewOptions.map(b => ({
            value: b.id,
            title: b.title
          }))}
          onChange={e => {
            setBinListView(e.target.value as BinListViewType);
          }}
        />
      </ViewTypeSelectorContainer>
      <ReadOnlyTable
        alignHeader="center"
        alignRows="center"
        rows={tableBinList.formattedRows.map((row, i) => ({
          name: i.toString(),
          results: row.slice(1)
        }))}
        headers={tableBinList.formattedHeader.map(header => ({
          header: header.name,
          visible: true,
          amountFormatSelector: () =>
            header.format === undefined ? null : (
              <AmountFormatSelector
                type="AmountFormatSelectorProps"
                fieldName={header.internalName}
                fieldGroup={listFieldGroup}
                amountFormat={header.format}
                conversionParameters={
                  header.createConversionParameters &&
                  binCases &&
                  binCases.length > 0
                    ? header.createConversionParameters(binCases[0])
                    : undefined
                }
                translate={sharedState.translate}
                onFormatCleared={() =>
                  onFormatCleared(listFieldGroup, header.internalName)
                }
                onFormatChanged={(unit, decimalCount) =>
                  onFormatChanged(
                    listFieldGroup,
                    header.internalName,
                    unit,
                    decimalCount
                  )
                }
              />
            )
        }))}
      />

      <ExportButtonsContainer>
        {(selectedBinType === "Generated" || selectedBinType === "Custom") && (
          <Exporter.XlsxMultiSheetsExport
            sheets={getExportDataSheets("standard")}
            filename={exportFileName}
          >
            {onClick => (
              <StandardButton
                onClick={onClick}
                size={"Small"}
                buttonType={"Secondary"}
              >
                {sharedState.translate(LanguageTexts.exportBinsAsStandard())}
              </StandardButton>
            )}
          </Exporter.XlsxMultiSheetsExport>
        )}

        {(selectedBinType === "Generated" || selectedBinType === "Custom") &&
          showMonthly && (
            <Exporter.XlsxMultiSheetsExport
              filename={exportFileName}
              sheets={getExportDataSheets("withmonths")}
            >
              {onClick => (
                <StandardButton
                  onClick={onClick}
                  size={"Small"}
                  buttonType={"Primary"}
                >
                  {sharedState.translate(LanguageTexts.exportBinsWithMonths())}
                </StandardButton>
              )}
            </Exporter.XlsxMultiSheetsExport>
          )}
      </ExportButtonsContainer>
    </>
  );
}
