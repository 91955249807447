import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const editRevisionCommentMutation = gql`
  mutation EditRevisionComment($systemId: ID!, $comment: String) {
    editRevisionComment(systemId: $systemId, comment: $comment) {
      ...CompleteSystemItem
    }
  }
  ${Fragments.completeSystemItem}
`;
