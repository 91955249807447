import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../../shared-state";
import * as Types from "./types";

export const init = (_sharedState: SharedState.State): Types.State => {
  return {
    selectedGroup: undefined
  };
};

export const Action = ctorsUnion({
  selectGroup: (group: string | undefined) => ({ group }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(action: Action, state: Types.State): Types.State {
  switch (action.type) {
    case "selectGroup":
      return {
        ...state,
        selectedGroup: action.group
      };
    case "dummy":
      return state;
    default:
      return exhaustiveCheck(action, true);
  }
}
