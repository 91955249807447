import * as GQLTypes from "../../../../../graphql-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { Action } from "../../../actions";
import {
  deleteAmountFieldFormatMutation,
  saveAmountFieldFormatMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { UpdateFunction } from "../types";
import { Serialize } from "@genesys/uom";

export const handleClearAmountFormat: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "clearAmountFormat") {
    return [state];
  }
  const key = ScreenAmounts.getUserAmountFormatFieldKey(
    state.user!.settings.selectedAmountProfile.measureSystem,
    action.fieldGroup,
    action.fieldName
  );
  const userAmountFieldsMapShallowCopy = {
    ...oldSharedState.user.settings.userAmountFieldsMap
  };
  delete userAmountFieldsMapShallowCopy[key];
  const newState = {
    ...state,
    user: {
      ...state.user!,
      settings: {
        ...state.user!.settings,
        userAmountFieldsMap: userAmountFieldsMapShallowCopy
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.DeleteAmountFieldFormat,
        GQLTypes.DeleteAmountFieldFormatVariables,
        Action
      >(
        deleteAmountFieldFormatMutation,
        {
          amountFieldFormat: {
            amountFieldProfileId:
              newState.user!.settings.selectedAmountProfile.id,
            fieldGroup: action.fieldGroup,
            fieldName: action.fieldName
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSaveAmountFormat: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "saveAmountFormat") {
    return [state];
  }
  const key = ScreenAmounts.getUserAmountFormatFieldKey(
    state.user!.settings.selectedAmountProfile.measureSystem,
    action.fieldGroup,
    action.fieldName
  );

  const newState = {
    ...state,
    user: {
      ...state.user!,
      settings: {
        ...state.user!.settings,
        userAmountFieldsMap: {
          ...state.user!.settings.userAmountFieldsMap,
          [key]: {
            userDefined: true,
            unit: action.unit,
            decimalCount: action.decimalCount
          }
        }
      }
    }
  };
  const unitString = Serialize.unitToString(action.unit);
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SaveAmountFieldFormat,
        GQLTypes.SaveAmountFieldFormatVariables,
        Action
      >(
        saveAmountFieldFormatMutation,
        {
          amountFieldFormat: {
            amountFieldProfileId:
              newState.user!.settings.selectedAmountProfile.id,
            fieldGroup: action.fieldGroup,
            fieldName: action.fieldName,
            unit: unitString,
            decimalCount: action.decimalCount
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};
