import React, { useState } from "react";
import styled from "../../styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { WarningBox } from "./warning-box";
import { zIndex } from "../../zIndex";

const RelativeDiv = styled.div`
  position: relative;
`;

const ErrorContainer = styled.div`
  width: 325px;
  margin-top: 7px;
  position: absolute;
  z-index: ${zIndex.Dropdown};
`;

function getPlaceHolderStyle(props: Props) {
  return `
opacity: ${props.disabled ? 0.2 : 1};
color: ${props.disabled ? "#3D3D3D" : "#C5D0E5"};
  `;
}

function getBackgroundsColor(type: ErrorType) {
  switch (type) {
    case "warning":
      return "#FFF6E1";
    case "none":
      return "#FFFFFF";
    case "error":
      return "#FEEAEB";
    default:
      return exhaustiveCheck(type); // Checks that all cases are covered
  }
}

function getBorderColor(type: ErrorType) {
  switch (type) {
    case "warning":
      return "1px solid #F0C530";
    case "none":
      return "1px solid #D2D5D8";
    case "error":
      return "1px solid #E8767B";
    default:
      return exhaustiveCheck(type); // Checks that all cases are covered
  }
}

function getFocusBorder(props: Props) {
  if (props.hasError !== "none") {
    return `
     outline: none;
     border: ${getBorderColor(props.hasError)}`;
  } else {
    return `
    outline: none;
     border: 1px solid #010036;`;
  }
}

export const TextBox = styled.input`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 20px;
  padding-top: 3.31px;
  padding-bottom: 3.69px;
  box-sizing: border-box;
  height: 48px;
  width: ${(props: Props) => props.width};
  color: #202b47;
  border: ${(props: Props) =>
    props.disabled ? "1px solid #F0F0F0" : getBorderColor(props.hasError)};
  background: ${(props: Props) =>
    props.disabled
      ? "linear-gradient(180deg, #F8F8F8 0%, #FAFAFA 100%);"
      : getBackgroundsColor(props.hasError)};
  box-shadow: ${(props: Props) =>
    props.disabled ? "0 2px 4px 0 rgba(0,0,0,0.03)" : "none"};

  ::-webkit-input-placeholder {
    ${(props: Props) => getPlaceHolderStyle(props)}
  }
  :-moz-placeholder {
    ${(props: Props) => getPlaceHolderStyle(props)}
  }
  ::-moz-placeholder {
    ${(props: Props) => getPlaceHolderStyle(props)}
  }
  :-ms-input-placeholder {
    ${(props: Props) => getPlaceHolderStyle(props)}
  }
  &:focus {
    ${(props: Props) => getFocusBorder(props)}
  }
`;

interface Props {
  readonly disabled?: boolean;
  readonly hasError: ErrorType;
  readonly width?: string | number;
  readonly errorMessage: string | undefined;
}

export type ErrorType = InputError["type"];

export interface None {
  readonly type: "none";
}

export interface Warning {
  readonly type: "warning";
  readonly title: string;
  readonly message: string;
}

export interface Error {
  readonly type: "error";
  readonly title: string;
  readonly message: string;
}

export type InputError = None | Warning | Error;

// tslint:disable-next-line
export function Input(
  props: {
    readonly hasError?: InputError;
    readonly onDebounceValueChange?: [
      fn: (value: string) => void,
      deps: ReadonlyArray<unknown>,
      time?: number
    ];
  } & React.InputHTMLAttributes<HTMLInputElement>
) {
  const {
    hasError = { type: "none" },
    children,
    value,
    onChange,
    title: errorMessage,
    width = "325px",
    onDebounceValueChange,
    ...rest
  } = props;

  let handleWithDebounce: ((value: string) => void) | undefined = undefined;

  if (onDebounceValueChange) {
    const [fn, deps, time] = onDebounceValueChange;
    handleWithDebounce = React.useMemo(() => debounce(fn, time ?? 350), deps);
  }

  React.useEffect(() => {
    setState({ value });
  }, [props.value]);

  const [state, setState] = useState({
    value
  });

  function internalOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const element = event.target as HTMLInputElement;
    if (onChange) {
      onChange(event);
    }
    if (handleWithDebounce) {
      handleWithDebounce(event.target.value);
    }

    setState({
      value: element.value
    });
  }

  return (
    <RelativeDiv>
      <TextBox
        width={width}
        {...rest}
        onChange={event => internalOnChange(event)}
        value={state.value}
        hasError={hasError.type}
        onBlur={onChange}
        errorMessage={errorMessage}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          // tslint:disable-next-line
          props.onClick ? props.onClick(e) : () => {};
        }}
      />
      <ErrorContainer>
        {hasError.type !== "none" && (
          <WarningBox
            title={hasError.title}
            warningType={hasError.type}
            messageContent={hasError.message}
          />
        )}
      </ErrorContainer>
    </RelativeDiv>
  );
}

function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}
