import gql from "graphql-tag";

export const updateMoistureLoadFileMutation = gql`
  mutation UpdateMoistureLoadFile($input: UpdateMoistureLoadFileInputType!) {
    updateMoistureLoadFile(input: $input) {
      id
      errorMessage
    }
  }
`;
