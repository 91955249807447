import * as React from "react";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as OperationTimeGen2 from "../../../operation-time-manager";
import * as SystemSettings from "../../tools/moisture-load-rules/system-settings";
import * as SystemOverride from "../../components/system-overrides";
import * as BuildingSettings from "../../tools/moisture-load-rules/building-settings";
import * as InfiltrationModelSettings from "../../tools/moisture-load-rules/infiltration-model-settings";
import {
  systemMainSettingsDef,
  buildingDefs,
  buildingDefAdditional,
  additionalSystemSelectionsDef,
  infiltrationModelSelectionsDef
} from "./definitions";
import { createCustomRowRenderer } from "./ps-custom-row-renderer";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import {
  PropertyDefinitions,
  PropertyDefinition,
  SystemOverrides
} from "../../tools";
import { ArrowDown, ArrowUp, H3 } from "@genesys/ui-elements";
import { OperationTimeView } from "../../components/operation-time";
import styled from "styled-components";

const Container = styled.div``;

const StyledH3 = styled(H3)`
  margin-top: 5px;
  font-size: 15px;
`;

const BuildingContainer = styled.div`
  margin-bottom: 15px;
`;

const FlexContainerCenter = styled.div`
  display: flex;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AlignCenter = styled(FlexContainerCenter)`
  align-items: center;
  padding-top: 10px;

  > label {
    margin-right: 17px;
    margin-left: 17px;
    font-size: 14px;
  }

  > span {
    cursor: pointer;
  }
`;

const Label = styled.label`
  color: rgb(0, 177, 247);
  cursor: pointer;
`;

interface Props {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly isDisabled: boolean;
  readonly propertyDefinitions: PropertyDefinitions;
  readonly currentSystemOverrides: SystemOverrides;
  readonly dispatch: Dispatch<Action>;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
}

export function View(props: Props) {
  const {
    sharedState,
    propertySelectorState,
    currentSystemOverrides,
    propertyDefinitions,
    isDisabled,
    dispatch,
    propertieSelectorDispatch
  } = props;

  const renderPropertySelector = (
    propertyDefinitions: PropertyDefinitions,
    customRowRenderer?: PropertiesSelector.RowRenderer,
    fieldGroup: string = "MoistureLoadTool.SystemConfiguration"
  ) => {
    return (
      <PropertiesSelector.PropertiesSelectorView
        dispatch={propertieSelectorDispatch}
        productId={""}
        sharedState={sharedState}
        translatePropertyName={propertyName =>
          sharedState.translate(LanguageTexts.globalPropertyName(propertyName))
        }
        translatePropertyValue={(propertyName, propertyValue) =>
          sharedState.translate(
            LanguageTexts.globalPropertyName(propertyName + "_" + propertyValue)
          )
        }
        propertiesInfo={propertyDefinitions}
        readonlyProperties={propertyDefinitions.reduce(
          (definitionsSoFar, current) => {
            if (
              current.enabled_filter &&
              !PropertyFilter.isValid(
                PropertiesSelector.getSelectedProperties(propertySelectorState),
                current.enabled_filter
              )
            ) {
              definitionsSoFar.push(current.name);
            }
            return definitionsSoFar;
          },
          [] as Array<string>
        )}
        customRowRenderer={customRowRenderer}
        fieldGroup={fieldGroup}
        isReadonly={isDisabled}
        showCodes={false}
        state={propertySelectorState}
      />
    );
  };

  const renderSystemOverride = (
    overridableProperties: ReadonlyArray<string>,
    fieldGroup: string = "MoistureLoadTool.SystemConfiguration"
  ) => {
    return (
      <SystemOverride.View
        useAsPop
        fieldGroups={fieldGroup}
        dispatch={Dispatch.map(Action.dispatchSystemOverride, dispatch)}
        sharedState={sharedState}
        systemOverrides={currentSystemOverrides}
        isDisabled={isDisabled}
        overridableProperties={overridableProperties.reduce(
          (soFar: Array<PropertyDefinition>, propertyName) => {
            const property = propertyDefinitions.find(
              property => property.name === propertyName
            );
            if (property !== undefined) {
              return soFar.concat(property);
            }
            return soFar;
          },
          []
        )}
      />
    );
  };

  return (
    <Container>
      <FlexContainer>
        <div>
          <UseCase
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
          <SystemOperationTime
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
          <InfiltrationModelSelections
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
        </div>
        <div>
          <AdditionalSelections
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
        </div>
        <BuildingContainer>
          <BuildingInfo
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
          <AddtionalBuildingInfo
            {...props}
            renderPropertySelector={renderPropertySelector}
            renderSystemOverride={renderSystemOverride}
          />
        </BuildingContainer>
      </FlexContainer>
    </Container>
  );
}

interface InternalProps extends Props {
  readonly renderPropertySelector: (
    propertyDefinitions: PropertyDefinitions,
    customRowRenderer?: PropertiesSelector.RowRenderer,
    fieldGroup?: string
  ) => JSX.Element;
  readonly renderSystemOverride: (
    overridableProperties: ReadonlyArray<string>,
    fieldGroup?: string
  ) => JSX.Element;
}

function SystemOperationTime(props: InternalProps) {
  const {
    state,
    sharedState,

    isDisabled,

    dispatch
  } = props;

  const labelRef = React.useRef<HTMLLabelElement>(null);

  return (
    <div>
      <StyledH3>
        {sharedState.translate(LanguageTexts.mlcSystemOperationTime())}
      </StyledH3>
      <AlignCenter>
        <label ref={labelRef}>
          {sharedState.translate(
            LanguageTexts.dynamicText(
              "mlc" + OperationTimeGen2.presetName(state.operationTimeState!)
            )
          )}
          :
        </label>
        <OperationTimeView
          labelRef={labelRef}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchOperationTime, dispatch)}
          readonly={isDisabled}
          operationTimeState={state.operationTimeState}
        />
      </AlignCenter>
    </div>
  );
}

function UseCase(props: InternalProps) {
  const {
    sharedState,
    propertySelectorState,
    propertyDefinitions,
    renderPropertySelector,
    renderSystemOverride
  } = props;

  const selectedProperties = PropertiesSelector.getSelectedProperties(
    propertySelectorState
  )!;
  const manualInputKey = SystemSettings.manualInputProperty;

  const systemSettingsmanualInputEnabled = !!(
    manualInputKey &&
    PropertyValueSet.getInteger(manualInputKey, selectedProperties)
  );

  const defs = systemMainSettingsDef(
    sharedState.translate,
    systemSettingsmanualInputEnabled,
    propertyDefinitions
  );

  const systemOverride = systemSettingsmanualInputEnabled
    ? renderSystemOverride(
        SystemSettings.overridableProperties,
        "MoistureLoadTool.SystemConfiguration"
      )
    : null;

  return (
    <div>
      <StyledH3>{sharedState.translate(LanguageTexts.mlcUsecases())}</StyledH3>
      {renderPropertySelector(
        defs,
        createCustomRowRenderer(manualInputKey, systemOverride),
        "MoistureLoadTool.SystemConfiguration"
      )}
    </div>
  );
}

function InfiltrationModelSelections(props: InternalProps) {
  const {
    sharedState,
    propertySelectorState,
    propertyDefinitions,
    renderPropertySelector,
    renderSystemOverride
  } = props;

  const selectedProperties = PropertiesSelector.getSelectedProperties(
    propertySelectorState
  )!;
  const manualInputKey = InfiltrationModelSettings.manualInputProperty;

  const infiltrationModelManualInputEnabled = !!(
    manualInputKey &&
    PropertyValueSet.getInteger(manualInputKey, selectedProperties)
  );
  const def = infiltrationModelSelectionsDef(
    infiltrationModelManualInputEnabled,
    propertyDefinitions
  );

  const systemOverride = infiltrationModelManualInputEnabled
    ? renderSystemOverride(
        InfiltrationModelSettings.overridableProperties,
        "MoistureLoadTool.SystemConfiguration"
      )
    : null;

  return (
    <div>
      <StyledH3>
        {sharedState.translate(
          LanguageTexts.globalPropertyName("mlcinfiltrationmodel")
        )}
      </StyledH3>
      {renderPropertySelector(
        def,
        createCustomRowRenderer(manualInputKey, systemOverride),
        "MoistureLoadTool.SystemConfiguration"
      )}
    </div>
  );
}

function BuildingInfo(props: InternalProps) {
  const { sharedState, propertyDefinitions, renderPropertySelector } = props;
  return (
    <>
      <StyledH3>{sharedState.translate(LanguageTexts.mlcBuilding())}</StyledH3>
      <FlexContainerCenter>
        <img
          height="170"
          src="/assets/images/moisture-and-heat-load-types/building.jpg"
        />
        <div>
          {renderPropertySelector(
            buildingDefs(propertyDefinitions),
            undefined,
            "MoistureLoadTool.BuildingEditor"
          )}
        </div>
      </FlexContainerCenter>
    </>
  );
}

function AddtionalBuildingInfo(props: InternalProps) {
  const {
    state,
    sharedState,
    propertyDefinitions,
    propertySelectorState,
    dispatch,
    renderPropertySelector,
    renderSystemOverride
  } = props;

  const selectedProperties = PropertiesSelector.getSelectedProperties(
    propertySelectorState
  )!;
  const manualInputKey = BuildingSettings.manualInputProperty;

  const manualInputEnabled = !!(
    manualInputKey &&
    PropertyValueSet.getInteger(manualInputKey, selectedProperties)
  );

  const defs = buildingDefAdditional(manualInputEnabled, propertyDefinitions);

  const systemOverride = manualInputEnabled
    ? renderSystemOverride(
        BuildingSettings.overridableProperties,
        "MoistureLoadTool.BuildingEditor"
      )
    : null;

  return (
    <>
      <div>
        <Label onClick={() => dispatch(Action.toggleAdditionalBuilding())}>
          {sharedState.translate(LanguageTexts.mlcBuildingExtended())}
          {state.isOpenAdditionalBuilding ? <ArrowUp /> : <ArrowDown />}
        </Label>
      </div>
      {state.isOpenAdditionalBuilding && (
        <div>
          {renderPropertySelector(
            defs,
            createCustomRowRenderer(manualInputKey, systemOverride),
            "MoistureLoadTool.BuildingEditor"
          )}
        </div>
      )}
    </>
  );
}

function AdditionalSelections(props: InternalProps) {
  const { sharedState, propertyDefinitions, renderPropertySelector } = props;
  return (
    <div>
      <StyledH3>
        {sharedState.translate(
          LanguageTexts.globalPropertyName("mlcadditionalsystemselections")
        )}
      </StyledH3>

      {renderPropertySelector(
        additionalSystemSelectionsDef(propertyDefinitions),
        undefined,
        "MoistureLoadTool.SystemConfiguration"
      )}
    </div>
  );
}
