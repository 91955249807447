import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ActionMenuBackContainer = styled.div`
  position: absolute;
  left: 5px;
  top: 0;
  display: inline-block;
  height: 100%;
  width: 30px;
  padding-top: 5px;
  margin-top: -5px;

  div {
    transform: rotate(90deg);
    display: inline-block;

    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 8px solid ${Colors.actionMenuHeaderColor};
  }
`;
