import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { unitLookup } from "@genesys/uom";
import * as PropertyCompare from "./property-compare";

export {
  Empty,
  toString,
  PropertyFilter,
  equals,
  getReferencedProperties
} from "@promaster-sdk/property/lib/property-filter";

export const isValid = (
  pvs: PropertyValueSet.PropertyValueSet,
  filter: PropertyFilter.PropertyFilter
) => PropertyFilter.isValid(pvs, filter, PropertyCompare.compare);

export const isValidMatchMissing = (
  pvs: PropertyValueSet.PropertyValueSet,
  filter: PropertyFilter.PropertyFilter
) => PropertyFilter.isValidMatchMissing(pvs, filter, PropertyCompare.compare);

export const fromString = (
  filter: string
): PropertyFilter.PropertyFilter | undefined =>
  PropertyFilter.fromString(filter.toLowerCase(), unitLookup);

export const fromStringOrEmpty = (
  filterString: string
): PropertyFilter.PropertyFilter =>
  PropertyFilter.fromStringOrEmpty(filterString.toLowerCase(), unitLookup);

export const isSyntaxValid = (
  filter: string,
  propertyNames?: Array<string> | undefined
): boolean =>
  PropertyFilter.isSyntaxValid(filter.toLowerCase(), unitLookup, propertyNames);
