import * as Guid from "@genesys/shared/lib/guid";
import { TabSubject } from "./tab-subjects";
import * as Tabs from "../tabs";

export interface UpdateTabTitle {
  readonly type: "global-actions/UPDATE_TAB_TITLE";
  readonly id: string;
  readonly title: string;
}

export function updateTabTitle(id: string, title: string): UpdateTabTitle {
  return {
    type: "global-actions/UPDATE_TAB_TITLE",
    id: id,
    title: title
  };
}

export interface RequestCloseTabFromSubjectAction {
  readonly type: "global-actions/REQUEST_CLOSE_TAB_FROM_SUBJECT";
  readonly payload: TabSubject;
}

export function requestCloseTabFromSubject(
  subject: TabSubject
): RequestCloseTabFromSubjectAction {
  return {
    type: "global-actions/REQUEST_CLOSE_TAB_FROM_SUBJECT",
    payload: subject
  };
}

export interface UserLoggedIn {
  readonly type: "global-actions/USER_LOGGED_IN";
  readonly accessToken: string;
}
export function userLoggedIn(accessToken: string): UserLoggedIn {
  return {
    type: "global-actions/USER_LOGGED_IN",
    accessToken
  };
}

export interface LanguageSelected {
  readonly type: "global-actions/LANGUAGE_SELECTED";
  readonly payload: string;
}
export function languageSelected(language: string): LanguageSelected {
  return {
    type: "global-actions/LANGUAGE_SELECTED",
    payload: language
  };
}

export interface LocaleSelected {
  readonly type: "global-actions/LOCALE_SELECTED";
  readonly payload: string;
}
export function localeSelected(locale: string): LocaleSelected {
  return {
    type: "global-actions/LOCALE_SELECTED",
    payload: locale
  };
}

export interface OpenTabSpecification {
  readonly id: Guid.Guid;
  readonly title: string;
  readonly subject: TabSubject;
}

export interface RequestOpenTab {
  readonly type: "global-actions/REQUEST_OPEN_TAB";
  readonly payload: OpenTabSpecification;
}
export function requestOpenTab(tabSpec: OpenTabSpecification): RequestOpenTab {
  return {
    type: "global-actions/REQUEST_OPEN_TAB",
    payload: tabSpec
  };
}

export interface TabRemoved {
  readonly type: "main/TAB_REMOVED";
  readonly payload: TabSubject;
}
export function tabRemoved(subject: TabSubject): TabRemoved {
  return {
    type: "main/TAB_REMOVED",
    payload: subject
  };
}

export interface TabReference {
  readonly title: string;
  readonly openTabSpecification: OpenTabSpecification;
}

export interface TabOpened {
  readonly type: "global-actions/TAB_OPENED";
  readonly payload: TabReference;
}
export function tabOpened(tabReference: TabReference): TabOpened {
  return {
    type: "global-actions/TAB_OPENED",
    payload: tabReference
  };
}

export interface TabActivated {
  readonly type: "global-actions/TAB_ACTIVATED";
  readonly payload: Tabs.Tab;
}
export function tabActivated(tab: Tabs.Tab): TabActivated {
  return {
    type: "global-actions/TAB_ACTIVATED",
    payload: tab
  };
}

export interface RemoveTab {
  readonly type: "global-actions/TAB_REMOVE_TAB";
  readonly payload: Tabs.Tab;
}
export function removeTab(tab: Tabs.Tab): RemoveTab {
  return {
    type: "global-actions/TAB_REMOVE_TAB",
    payload: tab
  };
}

export interface ScreenAmountProfileSelected {
  readonly type: "global-actions/SCREEN_AMOUNT_PROFILE_SELECTED";
  readonly payload: Guid.Guid;
}
export function screenAmountProfileSelected(
  guid: Guid.Guid
): ScreenAmountProfileSelected {
  return {
    type: "global-actions/SCREEN_AMOUNT_PROFILE_SELECTED",
    payload: guid
  };
}

export interface ResetUserSettingsRequested {
  readonly type: "global-actions/RESET_USER_SETTINGS_REQUESTED";
}
export function resetUserSettingsRequested(): ResetUserSettingsRequested {
  return {
    type: "global-actions/RESET_USER_SETTINGS_REQUESTED"
  };
}

export interface ErrorInfo {
  readonly id?: string;
  readonly referenceNo: string;
  readonly message?: string;
  readonly exception: string;
  readonly invalidState: boolean;
}

export interface Error {
  readonly type: "global-actions/ERROR";
  readonly payload: ErrorInfo;
}
export function error(errorInfo: ErrorInfo): Error {
  return {
    type: "global-actions/ERROR",
    payload: errorInfo
  };
}
