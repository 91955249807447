import { Sub } from "@typescript-tea/core";
import * as OidcClientEffectManager from "../oidc-client-effect-manager";
import * as State from "./state";
import * as Main from "../main";

export function subscriptions(
  state: State.State
): Sub<State.Action> | undefined {
  if (!state.user || !state.mainState) {
    return undefined;
  }
  const sharedState = State.buildSharedState(state);
  if (!sharedState) {
    return undefined;
  }

  return Sub.batch([
    Sub.map(
      State.Action.dispatchMain,
      Main.subscriptions(sharedState, state.mainState)
    ),
    OidcClientEffectManager.accessTokenRefreshed(user =>
      State.Action.accessTokenRefreshed(user)
    )
  ]);
}
