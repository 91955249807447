import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import * as Guid from "@genesys/shared/lib/guid";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { View, Label } from "./types";

export type State = {
  readonly labelFilter: string;
  readonly newLabelValue: string;
  readonly labelEditorChosenValues: ReadonlyArray<Label>;
  readonly labelId: string;
  readonly view: View;
};

export const init = (chosenValues: ReadonlyArray<Label>): [State] => {
  return [
    {
      labelFilter: "",
      view: "assign",
      labelId: "",
      newLabelValue: "",
      labelEditorChosenValues: chosenValues
    }
  ];
};

export const Action = ctorsUnion({
  setLabelFilter: (labelFilter: string) => ({ labelFilter }),
  setLabelValue: (value: string) => ({ value }),
  editLabelValue: () => ({}),
  deleteLabel: () => ({}),
  createNewLabel: () => ({}),
  toggleLabel: (id: string, name: string) => ({ id, name }),
  changeView: (view: View, value?: string, id?: string) => ({
    view,
    value,
    id
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "setLabelFilter": {
      return [
        {
          ...state,
          labelFilter: action.labelFilter
        }
      ];
    }
    case "setLabelValue": {
      return [
        {
          ...state,
          newLabelValue: action.value
        }
      ];
    }
    case "deleteLabel": {
      const newValues = state.labelEditorChosenValues.filter(
        label => label.id !== state.labelId
      );
      return [
        {
          ...state,
          view: "assign",
          newLabelValue: "",
          labelId: "",
          labelEditorChosenValues: newValues
        },
        [SharedState.Action.deleteLabel(state.labelId)]
      ];
    }
    case "editLabelValue": {
      const index = state.labelEditorChosenValues.findIndex(
        i => i.id === state.labelId
      );
      const newValues = [...state.labelEditorChosenValues];
      if (index !== -1) {
        newValues[index] = { id: state.labelId, name: state.newLabelValue };
      }

      return [
        {
          ...state,
          view: "assign",
          newLabelValue: "",
          labelId: "",
          labelEditorChosenValues: newValues
        },
        [SharedState.Action.updateLabel(state.labelId, state.newLabelValue)]
      ];
    }

    case "changeView": {
      return [
        {
          ...state,
          view: action.view,
          labelId:
            action.view === "assign"
              ? ""
              : action.id
              ? action.id
              : state.labelId,
          newLabelValue:
            action.view === "assign"
              ? ""
              : action.value
              ? action.value
              : state.newLabelValue
        }
      ];
    }

    case "createNewLabel": {
      const currentValue = state.newLabelValue;
      const id = Guid.guidToString(Guid.createGuid());

      return [
        {
          ...state,
          newLabelValue: "",
          view: "assign",
          labelId: "",
          labelEditorChosenValues: [
            ...state.labelEditorChosenValues,
            { id: id, name: currentValue }
          ]
        },
        [SharedState.Action.createLabel(id, currentValue)]
      ];
    }

    case "toggleLabel": {
      const currentValues = state.labelEditorChosenValues;
      const found = currentValues.find(obj => obj.id === action.id);

      const newValues = found
        ? currentValues.filter(obj => obj.id !== action.id)
        : [...currentValues, { name: action.name, id: action.id }];
      return [
        {
          ...state,
          labelEditorChosenValues: newValues
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getSelectedLabels(state: State) {
  return state.labelEditorChosenValues;
}
