import * as React from "react";
import * as BinTools from "../../binning-tools";
import * as SharedState from "../../shared-state";
import { GetSelectedLocation } from "../../location-selector";
import styled from "styled-components";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

const Container = styled.div`
  border: 1px solid #f1f5fa;
  padding: 15px;
  display: inline-block;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
`;

export function View({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.binSelections === undefined) {
    return null;
  }

  const binInfo = {
    binCases: state.binCases,
    binSelections: state.binSelections,
    isBinCasesDataLoading: state.isBinCasesDataLoading,
    selectedBinListView: state.selectedBinListView,
    selectedBinVisualizer: state.selectedBinVisualizer,
    selectedChartType: state.selectedChartType,
    selectedBinType: "Generated" as BinTools.BinType
  };

  return (
    <Root>
      <Container>
        <BinTools.InputsView
          showGenerateInfo={false}
          showGenerateButton={
            state.binCases === undefined || state.binCases.length === 0
          }
          operationTimeState={state.operationTimeState}
          onClose={() => dispatch(Action.toggleOperationTime())}
          onSelectionChange={newBinSelections => {
            dispatch(
              Action.binToolsInputOnSelectionChange(newBinSelections, [])
            );
          }}
          onGenerateBinCases={() =>
            dispatch(Action.generateBinCases(binInfo.binSelections))
          }
          operationTimeDispatch={Dispatch.map(
            Action.dispatchOperationTime,
            dispatch
          )}
          locationSelectorState={state.locationSelectorState!}
          sharedState={sharedState}
          energySettings={{}} // hmm check later
          isSystemLocked={false}
          hasBinLocationId={true}
          onClick={() => dispatch(Action.toggleOperationTime())}
          locationSelectorDispatch={Dispatch.map(
            Action.dispatchLocationSelector,
            dispatch
          )}
          binInfo={binInfo}
          onFormatCleared={(fieldGroup, fieldName) => {
            dispatch(Action.onFormatCleared(fieldGroup, fieldName));
          }}
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            );
          }}
        />

        {state.binCases && state.binCases.length > 0 && (
          <BinTools.ResultView
            binDataLocation={GetSelectedLocation(state.locationSelectorState)}
            version={state.hourlyDataVersion as "v1" | "v2"}
            setChartType={value => dispatch(Action.setChartType(value))}
            setBinVisualizer={value => {
              dispatch(Action.setBinVisualizer(value));
            }}
            setBinListView={value => dispatch(Action.setBinListView(value))}
            exportFileName={"EnergyBinCases"}
            psychrometricChartState={state.psychrometricChartState}
            sharedState={sharedState}
            onFormatCleared={(fieldGroup, fieldName) => {
              dispatch(Action.onFormatCleared(fieldGroup, fieldName));
            }}
            onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
              dispatch(
                Action.onFormatChanged(
                  fieldGroup,
                  fieldName,
                  unit,
                  decimalCount
                )
              );
            }}
            binInfo={binInfo}
          />
        )}
      </Container>
    </Root>
  );
}
