import styled from "styled-components";

// tslint:disable-next-line
export const StyledTable = styled.table`
  th {
    font-weight: bold;
    padding: 5px;
  }
  tr {
    td {
      padding: 5px;
    }
  }
`;
