import { ErrorSeverity } from "@genesys/primitives";
import { PropertyFilter } from "@genesys/property";
import * as Authorization from "../authorization";
import * as CaseTypeEnum from "../enums/case-type";
import { ComponentMessage, OperatingCaseResult, CaseFilter } from "./types";

export function getClaimFilteredComponentMessages(
  componentsMessages: ReadonlyArray<ComponentMessage>,
  operatingCaseResults: ReadonlyArray<OperatingCaseResult>,
  systemTypeCaseFilter: ReadonlyArray<CaseFilter>,
  productsCaseFilters: ReadonlyArray<{
    readonly productId: string;
    readonly caseFilters: ReadonlyArray<CaseFilter>;
  }>,
  claims: { readonly [context: string]: { readonly [name: string]: string[] } }
) {
  const claimFilteredComponentMessages = componentsMessages.filter(cm => {
    if (
      cm.severity === ErrorSeverity.Exception ||
      cm.severity === ErrorSeverity.Error
    ) {
      return true;
    }

    const productCaseFilter = productsCaseFilters.find(
      pcf => pcf.productId === cm.productId
    )!;

    const effectiveCaseFilter = getEffectiveCaseFilter(
      systemTypeCaseFilter,
      productCaseFilter.caseFilters
    );

    const operatingCaseResult = operatingCaseResults.find(
      opcr =>
        opcr.id === cm.operatingCaseResultId &&
        opcr.componentId === cm.componentId
    );

    if (operatingCaseResult === undefined) {
      // Messages are not always connected to a operating case
      return true;
    }

    const key =
      CaseTypeEnum.CaseType[operatingCaseResult.caseType].toUpperCase() +
      "." +
      operatingCaseResult.calculationType.toUpperCase();

    if (!effectiveCaseFilter.has(key)) {
      return false;
    }

    const claimFilterText = effectiveCaseFilter.get(key)!.claimFilter;
    const claimFilter = PropertyFilter.fromStringOrEmpty(claimFilterText);

    return Authorization.checkClaimFilter(claims, claimFilter);
  });

  return claimFilteredComponentMessages;
}

function getEffectiveCaseFilter(
  systemType: ReadonlyArray<CaseFilter>,
  product: ReadonlyArray<CaseFilter>
): ReadonlyMap<string, CaseFilter> {
  const map = new Map<string, CaseFilter>();

  for (const cf of systemType) {
    const key =
      cf.caseType.toUpperCase() + "." + cf.calculationType.toUpperCase();
    map.set(key, cf);
  }

  for (const cf of product) {
    const key =
      cf.caseType.toUpperCase() + "." + cf.calculationType.toUpperCase();
    map.set(key, cf);
  }

  return map;
}
