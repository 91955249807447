import { encodeSvgStringToImgSrc } from "../../svg-string-img-encoder";

export function createOpenIconSvg(color: string): string {
  const svgString = `
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>E06F3BD6-F15A-4AAE-9C63-28A52D94EAA7</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="20" height="20"></rect>
        <path d="M15.8333333,9.23611111 L10.8333333,9.23611111 L10.8333333,4.23611111 C10.8333333,3.77527778 10.46,3.40277778 10,3.40277778 C9.54,3.40277778 9.16666667,3.77527778 9.16666667,4.23611111 L9.16666667,9.23611111 L4.16666667,9.23611111 C3.70666667,9.23611111 3.33333333,9.60861111 3.33333333,10.0694444 C3.33333333,10.5302778 3.70666667,10.9027778 4.16666667,10.9027778 L9.16666667,10.9027778 L9.16666667,15.9027778 C9.16666667,16.3636111 9.54,16.7361111 10,16.7361111 C10.46,16.7361111 10.8333333,16.3636111 10.8333333,15.9027778 L10.8333333,10.9027778 L15.8333333,10.9027778 C16.2933333,10.9027778 16.6666667,10.5302778 16.6666667,10.0694444 C16.6666667,9.60861111 16.2933333,9.23611111 15.8333333,9.23611111" id="path-3"></path>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---4.-Buttons" transform="translate(-841.000000, -789.000000)">
            <g id="btn-/-secondary-/-small-/-green-icon-copy" transform="translate(833.000000, 784.000000)">
                <g id="Icon-/-Open" transform="translate(8.000000, 5.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Mask"></g>
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <use id="🎨-Icon-Сolor" fill="${color}" xlink:href="#path-3"></use>
                </g>
            </g>
        </g>
    </g>
</svg>
  `;

  return encodeSvgStringToImgSrc(svgString);
}
