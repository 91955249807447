import styled from "styled-components";
// import * as Colors from "../colors";

// tslint:disable-next-line
export const PopupComponentOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 15;
`;
