import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";
import {
  ChartType,
  BinSize,
  BinType,
  ClimateCoolingDataType
} from "../../binning-tools";

import * as PsychrometricChart from "../../psychrometric-chart";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as SharedState from "../../shared-state";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import * as GraphQlTypes from "../../graphql-types";

import { State } from "./state";

export function getInitialPsychrometricChartSettings(
  state: State,
  chartType: ChartType,
  binFields:
    | ReadonlyArray<{
        readonly temperature: Amount.Amount<"Temperature">;
        readonly humidity: Amount.Amount<"HumidityRatio">;
        readonly time: Amount.Amount<"Duration">;
      }>
    | undefined,
  sharedState: SharedState.State,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): PsychrometricChart.InitProps {
  const binSize =
    (PropertyValueSet.getValue(
      KnownProperties.binSize,
      state.binSelections ?? PropertyValueSet.Empty
    ).value as number) ?? 2;

  return {
    source: "custom",
    binCases: binCases.map(bin => ({
      temperature: bin.averageTemperature,
      humidity: bin.averageHumidity,
      time: bin.binTime
    })),
    si: PsychrometricChart.infoFromPreset(chartType).measureSystem === "SI",
    points: [],
    binFields: binFields
      ? binFields.map(f => ({
          ...f,
          temperatureInterval: Amount.create(binSize, Units.Celsius),
          humidityInterval: Amount.create(binSize, Units.GramPerKilogram)
        }))
      : [],
    pressure:
      binCases && binCases.length
        ? binCases[0].binPressure
        : Amount.create(101325, Units.Pascal),
    type: PsychrometricChart.infoFromPreset(chartType).chartType,
    accessToken: sharedState.accessToken,
    limits: false
  };
}

export function getBinFields(
  generateBinCasesQuery: GraphQlTypes.BinToolBinCaseGenerator | undefined
) {
  return generateBinCasesQuery?.product.psycProClimateDataBins.map(b => ({
    temperature: PropertyValue.getAmount<Quantity.Temperature>(
      PropertyValue.fromString(b.temperature)!
    )!,
    humidity: PropertyValue.getAmount<Quantity.HumidityRatio>(
      PropertyValue.fromString(b.humidity)!
    )!,
    time: PropertyValue.getAmount<Quantity.Duration>(
      PropertyValue.fromString(b.time)!
    )!
  }));
}

export function getInitialClimateCoolingDataType(
  climateSettings: PropertyValueSet.PropertyValueSet
): ClimateCoolingDataType {
  return PropertyValueSet.getText(
    KnownProperties.climateCoolingDataType,
    climateSettings
  )! as ClimateCoolingDataType;
}

export function getInitialOperationTime(): OperationTime.OperationTime {
  return OperationTime.presets.allDay;
}

export function getInitialBinSelections(
  selectedBinSize: BinSize,
  selectedOperationTime: OperationTime.OperationTime,
  binType: BinType,
  selectedDataType: ClimateCoolingDataType,
  si: boolean,
  binLocation?: GraphQlTypes.EnergyInputQueryProduct["product"]["binDataLocations"][0]
) {
  const roofTemperatureCompensationSelection = PropertyValueSet.Empty;

  return PropertyValueSet.merge(
    PropertyValueSet.setInteger(
      KnownProperties.binSize,
      selectedBinSize,
      PropertyValueSet.setInteger(
        KnownProperties.binUseEnglishUnits,
        si ? 0 : 1,
        PropertyValueSet.setText(
          KnownProperties.binDataLocationId,
          binLocation?.binDataLocationId || "", // When the location ID does not exist in the new webservice
          PropertyValueSet.setText(
            KnownProperties.binLocation,
            binLocation?.locationName || "", // When the location ID does not exist in the new webservice
            PropertyValueSet.setText(
              KnownProperties.binOperationTime,
              OperationTime.presetName(selectedOperationTime),
              PropertyValueSet.setText(
                KnownProperties.climateCoolingDataType,
                selectedDataType,
                PropertyValueSet.setText(
                  KnownProperties.binType,
                  binType,
                  roofTemperatureCompensationSelection
                )
              )
            )
          )
        )
      )
    ),
    OperationTime.toPropertyValueSet(selectedOperationTime)
  );
}

export function getInitialBinType(
  climateSettings: PropertyValueSet.PropertyValueSet
): BinType {
  return (
    PropertyValueSet.getText("locationname", climateSettings) === "Manual"
      ? "Custom"
      : "Generated"
  ) as BinType;
}

export function getInitialBinSize(userEnergySettings: {
  readonly [key: string]: string;
}): BinSize {
  return ((userEnergySettings[KnownProperties.binSize] &&
    // tslint:disable-next-line:radix
    (parseInt(userEnergySettings[KnownProperties.binSize]) as number)) ||
    2) as BinSize;
}

export function buildSettingsDictionary(
  energySettings: ReadonlyArray<{
    readonly settingName: string;
    readonly settingValue: string;
  }>
): { readonly [key: string]: string } {
  const dictionary = energySettings.reduce(
    // tslint:disable-next-line
    (a: { [key: string]: string }, b) => {
      a[b.settingName.toLowerCase()] = b.settingValue;
      return a;
    },
    {}
  );

  return { ...dictionary };
}
