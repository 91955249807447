import styled from "styled-components";

// tslint:disable-next-line
export const Label = styled.span`
  display: inline-block;
  padding: 2px 10px;
  margin-right: 5px;
  background: linear-gradient(
    to bottom right,
    white 10%,
    rgba(255, 228, 128, 255)
  );
  border: 1px solid #333;
  border-radius: 10px;
  font-size: 12px;
  color: #000;
`;
