import gql from "graphql-tag";
import { DocumentNode } from "graphql";

// tslint:disable-next-line:variable-name
export const CachePatchFragment: DocumentNode = gql`
  fragment CachePatchFragment on CachePatchType {
    __typename
    ... on DeleteEntityCachePatchType {
      deletedId
      typename
    }

    ... on InvalidateFieldCachePatchType {
      id
      typename
      fieldName
    }

    ... on UpdateFieldCachePatchType {
      id
      typename
      fieldName
      entityFieldValue {
        ...EntitiFieldValue
      }
    }

    ... on InsertElementCachePatchType {
      id
      typename
      fieldName
      index
      entityFieldValue {
        ...EntitiFieldValue
      }
    }

    ... on RemoveEntityElementCachePatchType {
      id
      typename
      fieldName
      entityId
    }
  }

  fragment EntitiFieldValue on EntityFieldValueType {
    __typename
    stringValue
    booleanValue
    intValue
    floatValue
    entityFieldValueArray {
      __typename
      stringValue
      booleanValue
      intValue
      floatValue
    }
  }
`;
