import styled from "styled-components";
import * as React from "react";
import * as Colors from "../colors";

export const unStyledManagerLabelContainer = (
  props: { readonly center?: boolean } & React.HTMLProps<HTMLDivElement>
) => {
  const { center, ...htmlProps } = props;
  return <div {...htmlProps}>{htmlProps.children}</div>;
};

// tslint:disable-next-line
export const ManagerLabelContainer = styled(unStyledManagerLabelContainer)`
  border-right: 10px solid ${Colors.searchFilterBorderColor};
  padding-right: 20px;
  flex-shrink: 0;
`;
