import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  DownloadPidDiagramUserQuery,
  PidDiagramBuildResponseCodeEnum,
  PidDiagramDownloadResponseCodeEnum,
  PidDiagramCheckStateResponseCodeEnum
} from "../../../graphql-types";

export function getPdfUrl(queryData: DownloadPidDiagramUserQuery): string {
  const base64Pdf = queryData.user.downloadPidDiagram.base64Pdf;
  const byteCharacters = atob(base64Pdf);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  return url;
}

// I´ll add textIds if they ask for it, this will do for now.

export function parsePidDiagramBuildResponseCodeEnum(
  responseCode: PidDiagramBuildResponseCodeEnum
): { readonly stateDescription: string; readonly isBuilding: boolean } {
  switch (responseCode) {
    case PidDiagramBuildResponseCodeEnum.BUILDING:
      return { stateDescription: "Building", isBuilding: true };
    case PidDiagramBuildResponseCodeEnum.INVALID_GENESYS_ID:
      return { stateDescription: "Invalid genesys ID", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.INVALID_USER:
      return { stateDescription: "Invalid user", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.NOT_LOCKED:
      return { stateDescription: "Not locked", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.SYSTEM_NOT_SUPPORTED:
      return { stateDescription: "System not supported", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.ALREADY_BUILDING:
      return { stateDescription: "Already building", isBuilding: true };
    case PidDiagramBuildResponseCodeEnum.SERVER_ERROR:
      return { stateDescription: "Server error", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.UNABLE_TO_PARSE:
      return { stateDescription: "Unable to parse", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.UNSPECIFIED_ERROR:
      return { stateDescription: "Unspecified error", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.EXCEPTION:
      return { stateDescription: "Exception", isBuilding: false };
    case PidDiagramBuildResponseCodeEnum.UNAUTHORIZED:
      return { stateDescription: "Unauthorized", isBuilding: false };
    default:
      return exhaustiveCheck(responseCode, true);
  }
}

export function parsePidDiagramCheckStateResponseCodeEnum(
  responseCode: PidDiagramCheckStateResponseCodeEnum
): { readonly stateDescription: string; readonly isBuilding: boolean } {
  switch (responseCode) {
    case PidDiagramCheckStateResponseCodeEnum.BUILT:
      return { stateDescription: "Built", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.BUILDING:
      return { stateDescription: "Building", isBuilding: true };
    case PidDiagramCheckStateResponseCodeEnum.NOT_DESIGNED:
      return { stateDescription: "Not designed", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.INVALID_GENESYS_ID:
      return { stateDescription: "Invalid genesys id", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.SERVER_ERROR:
      return { stateDescription: "Server error", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.CUSTOM_ERROR:
      return { stateDescription: "Custom error", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.UNSPECIFIED_ERROR:
      return { stateDescription: "Unspecified error", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.EXCEPTION:
      return { stateDescription: "Exception", isBuilding: false };
    case PidDiagramCheckStateResponseCodeEnum.UNAUTHORIZED:
      return { stateDescription: "Unauthorized", isBuilding: false };
    default:
      return exhaustiveCheck(responseCode, true);
  }
}

export function parsePidDiagramDownloadResponseCodeEnum(
  responseCode: PidDiagramDownloadResponseCodeEnum
): string {
  switch (responseCode) {
    case PidDiagramDownloadResponseCodeEnum.OK:
      return "Ready for download";
    case PidDiagramDownloadResponseCodeEnum.INVALID_GENESYS_ID:
      return "Invalid genesys id";
    case PidDiagramDownloadResponseCodeEnum.UNAUTHORIZED:
      return "Unauthorized";
    case PidDiagramDownloadResponseCodeEnum.NOT_FOUND:
      return "Not found";
    case PidDiagramDownloadResponseCodeEnum.SERVER_ERROR:
      return "Server error";
    case PidDiagramDownloadResponseCodeEnum.UNSPECIFIED_ERROR:
      return "Unspecified error";
    case PidDiagramDownloadResponseCodeEnum.EXCEPTION:
      return "Exception";
    default:
      return exhaustiveCheck(responseCode, true);
  }
}
