import styled from "styled-components";
import * as Elements from "../../elements";

// tslint:disable-next-line
export const Menu = styled(Elements.BareUl)`
  background: linear-gradient(to bottom, #eef0ec, #fcfcfc 80%);
  display: inline-block;

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #000;
    text-decoration: none;
  }
`;
