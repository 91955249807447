import * as React from "react";
import styled from "styled-components";
import { Spinner, zIndex } from "@genesys/ui-elements";
import { State } from "./state";
import { SystemHasPrice, SystemHasNoPrice } from "./views";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import * as System from "../../system";
import * as Product from "../../product";
import * as SharedState from "../../../shared-state";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";

const Container = styled.div`
  padding: 15px;
  min-height: 30px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.FullOverlay};
`;

export function PriceView({
  state,
  system,
  sharedState,
  products,
  systemType,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly products: ReadonlyArray<Product.Product>;
  readonly sharedState: SharedState.State;
  readonly systemType: Product.SystemType;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (state.priceProductQueryData === undefined || state.loadingMoreData) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const selectedOptions = {
    selectedCurrencyCode: state.currencyCode || system.currencyCode,
    selectedSalesOrganisationId:
      state.salesOrganisation || system.salesOrganisationId, // Kolla på D158586-1 t.ex
    // state.priceProductQueryData.product.salesOrganisations.filter(s =>
    //   sharedState.user.salesOrganisations.some(ss => s.id === ss.id)
    // )[0].id, // Kolla om denna är rätt, det stod så här i den gamla "system.salesOrganisationId" men blev fel sel i selectbox
    selectedMasterSalesOrganisationId:
      state.masterSalesOrganisation || system.masterSalesOrganisationId!,
    selectedMasterCurrencyCode:
      state.masterCurrencyCode || system.masterCurrencyCode
  };

  if (state.systemHasPriceQueryData) {
    return (
      <SystemHasPrice
        systemType={systemType}
        state={state}
        dispatch={dispatch}
        selectedOptions={selectedOptions}
        system={system}
        products={products}
        sharedState={sharedState}
      />
    );
  }

  return (
    <Container>
      <SystemHasNoPrice
        system={system}
        systemType={systemType}
        isDisabled={system.status >= SystemStatusEnum.SystemStatus.LockSuccess}
        priceProductData={state.priceProductQueryData}
        sharedState={sharedState}
        dispatch={dispatch}
        selectedOptions={selectedOptions}
      />
    </Container>
  );
}
