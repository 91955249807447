import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import {
  saveOperatingCasesMutation,
  updateComponentPropertiesMutation
} from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { promiseCmd } from "../../../promise-effect-manager";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { tryRaiseSystemStatusToPriceCalculationSuccess } from "../calculate";

export function updateComponentsPropertiesAndSaveOperatingCases(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<
    ConfiguratorAction,
    { readonly type: "updateComponentsPropertiesAndSaveOperatingCases" }
  >
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        for (const c of action.components) {
          await sharedState.graphQL.queryUser<
            GraphQLTypes.UpdateComponentProperties,
            GraphQLTypes.UpdateComponentPropertiesVariables
          >(updateComponentPropertiesMutation, {
            input: {
              componentId: c.componentId,
              properties: PropertyValueSet.toString(c.properties),
              systemId
            }
          });
        }

        await sharedState.graphQL.queryUser<
          GraphQLTypes.SaveOperatingCases,
          GraphQLTypes.SaveOperatingCasesVariables
        >(saveOperatingCasesMutation, {
          input: {
            systemId,
            operatingCases: action.operatingCases.map(oc =>
              PropertyValueSet.toString(oc.settings)
            ),
            climateData: PropertyValueSet.toString(action.climateSettings)
          }
        });

        const traceId = action.calculate
          ? await tryRaiseSystemStatusToPriceCalculationSuccess(
              sharedState.graphQL.queryUser,
              systemId
            )
          : undefined;
        return traceId;
      },
      traceId =>
        action.calculate
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculate
      ? [
          ...action.components.map(
            _ => SystemStatus.ValidateConfigurationSuccess
          ),
          SystemStatus.ValidateConfigurationSuccess,
          SystemStatus.PriceCalculationSuccess
        ]
      : action.components.map(_ => SystemStatus.ValidateConfigurationSuccess)
  ];
}
