import styled from "styled-components";

// tslint:disable-next-line
export const ContainerWithBorder = styled.div`
  display: inline-block;
  border: 2px solid rgb(128, 128, 128);
  border-radius: 10px;

  text-align: center;

  margin: 10px 0 10px 0;

  padding: 15px;
`;
