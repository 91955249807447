import * as React from "react";

import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as PropertiesSelector from "../react-properties-selector";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as AmountFormatSelector from "../amount-format-selector";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as PropertiesSelectorElements from "./elements";
import { ReadonlyPropertySelector } from "./readonly-property-selector";
import * as UserProfile from "../user-profile";
import { Quantity } from "@genesys/uom";

interface ProductPropertySelectorProps {
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly onPropertyFormatChanged: PropertiesSelector.OnPropertyFormatChanged;
  readonly onPropertyFormatCleared: PropertiesSelector.OnPropertyFormatCleared;
  readonly onQuantityChange: AmountFormatSelector.OnQuantityChange;
  readonly onPropertyFormatSelectorToggled: PropertiesSelector.OnPropertyFormatSelectorToggled;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly valueSources: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  readonly selectorInfo: PropertiesSelector.PropertySelectorRenderInfo;
  readonly conversionParameters:
    | QuantityConversion.ConversionParameters
    | undefined;
  readonly isQuantitySelectorActive: boolean;
}

// tslint:disable-next-line:function-name
export function ProductPropertySelector({
  conversionParameters,
  fieldGroup,
  fieldName,
  filterPrettyPrint,
  isQuantitySelectorActive,
  onPropertyFormatChanged,
  onPropertyFormatCleared,
  onPropertyFormatSelectorToggled,
  onQuantityChange,
  valueSources,
  selectorInfo,
  translatePropertyValue
}: ProductPropertySelectorProps): JSX.Element {
  const {
    propertyName,
    selectorComponentProps: {
      selectedProperties,
      includeCodes,
      validationFilter,
      quantity,
      valueItems
    }
  } = selectorInfo;
  if (isReadonly(valueSources, selectedProperties, propertyName)) {
    return (
      <ReadonlyPropertySelector
        fieldName={fieldName}
        conversionParameters={conversionParameters}
        fieldGroup={fieldGroup}
        filterPrettyPrint={filterPrettyPrint}
        includeCodes={includeCodes}
        isQuantitySelectorActive={isQuantitySelectorActive}
        onPropertyFormatChanged={onPropertyFormatChanged}
        onPropertyFormatCleared={onPropertyFormatCleared}
        onPropertyFormatSelectorToggled={onPropertyFormatSelectorToggled}
        onQuantityChange={onQuantityChange}
        propertyName={propertyName}
        quantity={quantity as Quantity.Quantity}
        selectedProperties={selectedProperties}
        translatePropertyValue={translatePropertyValue}
        validationFilter={validationFilter}
        valueItems={valueItems}
      />
    );
  }
  return (
    <UserProfile.UserProfile>
      {user => {
        const title = AuthorizationTools.checkPermission(
          user.applicationClaims,
          AuthorizationTools.genesysUserClaims.developer
        )
          ? "GroupName: " + fieldGroup + "\nFieldName: " + fieldName
          : undefined;
        return (
          <td>
            {
              <span title={title}>
                <PropertiesSelectorElements.DefaultPropertySelector
                  {...selectorInfo.selectorComponentProps}
                />
              </span>
            }
          </td>
        );
      }}
    </UserProfile.UserProfile>
  );
}

function isReadonly(
  valueSources: ReadonlyArray<{
    readonly value: number;
    readonly propertyFilter: PropertyFilter.PropertyFilter;
    readonly propertyValueSourceId: string;
  }>,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  propertyName: string
): boolean {
  if (valueSources.length === 0) {
    return false;
  }

  const sourceInt = PropertyValueSet.getInteger(
    `source_${propertyName}`,
    selectedProperties
  );

  const valueSource =
    sourceInt !== undefined
      ? valueSources.find(vs => vs.value === sourceInt)
      : undefined;
  if (valueSource !== undefined) {
    return valueSource.propertyValueSourceId !== "User";
  }

  return valueSources[0].propertyValueSourceId !== "User";
}
