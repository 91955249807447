import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as DataUpload from "@genesys/client-core/lib/data-upload";
import { StandardButton } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { hasPermissionForMlc } from "../../../../../moisture-load-calculation";
import { importMoistureLoadBinCases } from "../functions/moisture-load-bin-import";
import { MoistureLoadBinCases } from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import * as System from "../../../../system";
import { Action } from "../state";

export function MoistureLoadBinsImportComponent({
  system,
  sharedState,
  isSystemLocked,
  moistureLoadBinQuery,
  dispatch
}: {
  readonly system: System.System;
  readonly isSystemLocked: boolean;
  readonly sharedState: SharedState.State;
  readonly moistureLoadBinQuery: MoistureLoadBinCases | undefined;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (
    system.moistureLoadInfo &&
    hasPermissionForMlc(sharedState.user.applicationClaims)
  ) {
    return (
      <p>
        Bins generated from{" "}
        <a
          target="_blank"
          href={
            "/moisture-load/" +
            sharedState.genesysPrefix.moistureLoadNo(
              moistureLoadBinQuery?.user.moistureLoad?.moistureloadFile
                .moistureLoadNo!,
              moistureLoadBinQuery?.user.moistureLoad?.revisionNo!
            )
          }
        >
          {sharedState.genesysPrefix.moistureLoadNo(
            moistureLoadBinQuery?.user.moistureLoad?.moistureloadFile
              .moistureLoadNo!,
            moistureLoadBinQuery?.user.moistureLoad?.revisionNo!
          )}
        </a>
      </p>
    );
  }

  return (
    <DataUpload.SimpleDataUploadContainer
      onParsed={data => importMoistureLoadBinCases(system, data, dispatch)}
    >
      {onClick => (
        <StandardButton
          size="Small"
          buttonType="Primary"
          disabled={isSystemLocked}
          onClick={onClick}
        >
          {sharedState.translate(LanguageTexts.importText())}
        </StandardButton>
      )}
    </DataUpload.SimpleDataUploadContainer>
  );
}
