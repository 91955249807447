import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import { OpenGreen } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import {
  FilterInput,
  InputContainer,
  RowSection,
  Container,
  UpperSubContainer,
  StyledButton,
  LowerSubContainer,
  ButtonsContainer,
  LabelGroupContainer
} from "./elements";
import { Label, LabelGroup } from "./types";
import { LabelGroupComp, InputBox, CtoButtons } from "./components";

export function LabelManagerView(props: {
  readonly state: State;
  readonly labels: ReadonlyArray<Label>;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onCancel: () => void;
  readonly onAssign: (selectedLabels: ReadonlyArray<Label>) => void;
}) {
  const { state, labels, sharedState, dispatch, onCancel, onAssign } = props;

  // Derived data
  const myLabels = labels;
  const otherLabels = React.useMemo(
    () =>
      state.labelEditorChosenValues.filter(
        chosenLabel => !myLabels.some(myLabel => myLabel.id === chosenLabel.id)
      ),
    [state.labelEditorChosenValues, myLabels]
  );

  const filteredLabels = React.useMemo(
    () =>
      myLabels.filter(
        label =>
          !state.labelFilter.length ||
          label.name.toUpperCase().includes(state.labelFilter.toUpperCase())
      ),
    [myLabels, state.labelFilter]
  );

  const isDisabled = React.useMemo(
    () =>
      !state.newLabelValue.trim().length ||
      labels.some(
        label => label.name.toLowerCase() === state.newLabelValue.toLowerCase()
      ),
    [state.newLabelValue, labels]
  );

  const labelGroups: ReadonlyArray<LabelGroup> = React.useMemo(
    () => [
      {
        id: "other-labels",
        name: sharedState.translate(
          LanguageTexts.globalPropertyName("otherlabels")
        ),
        labels: otherLabels,
        editable: false
      },
      {
        id: "my-labels",
        name: sharedState.translate(
          LanguageTexts.globalPropertyName("mylabels")
        ),
        labels: filteredLabels,
        editable: true
      }
    ],
    [otherLabels, filteredLabels, sharedState]
  );

  return (
    <Container>
      <UpperSubContainer>
        <RenderUpperView {...props} labelGroups={labelGroups} />
      </UpperSubContainer>
      <LowerSubContainer>
        <ButtonsContainer>
          <CtoButtons
            dispatch={dispatch}
            selectedlabels={state.labelEditorChosenValues}
            onCancel={onCancel}
            isDisabled={isDisabled}
            translate={sharedState.translate}
            onAssgin={onAssign}
            view={state.view}
          />
        </ButtonsContainer>
      </LowerSubContainer>
    </Container>
  );
}

const RenderUpperView = ({
  state,
  labelGroups,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly labelGroups: ReadonlyArray<LabelGroup>;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) => {
  if (state.view === "assign") {
    return (
      <>
        <RowSection>
          <FilterInput
            placeholder={sharedState.translate(LanguageTexts.search())}
            value={state.labelFilter}
            onChange={e =>
              dispatch(Action.setLabelFilter(e.currentTarget.value))
            }
          />
          {labelGroups.map(group => (
            <LabelGroupContainer key={group.id}>
              <LabelGroupComp
                group={group}
                dispatch={dispatch}
                labelEditorChosenValues={state.labelEditorChosenValues}
              />
            </LabelGroupContainer>
          ))}
        </RowSection>
        <StyledButton
          icon={OpenGreen}
          size="Small"
          buttonType="Secondary-2"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(Action.changeView("create"));
          }}
        >
          {sharedState.translate(LanguageTexts.createNewLabel())}
        </StyledButton>
      </>
    );
  }

  if (["create", "editOrDelete"].includes(state.view)) {
    return (
      <InputContainer>
        <InputBox
          translate={sharedState.translate}
          view={state.view}
          inputBoxValue={state.newLabelValue}
          onInputBoxChange={value => dispatch(Action.setLabelValue(value))}
          onDelete={() => dispatch(Action.deleteLabel())}
        />
      </InputContainer>
    );
  }

  return null;
};
