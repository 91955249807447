import * as React from "react";
import { State, Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import styled from "styled-components";
import * as PropertiesSelector from "../../../properties-selector";
import { Trash, StandardButton, P1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

const Root = styled.div`
  padding: 20px;
  a {
    cursor: pointer;
  }
`;

const PointContainer = styled.div`
  display: flex;
`;

const SelectorContainer = styled.div`
  display: flex;
  height: 40px;

  input {
    width: 50px;
  }

  table {
    tr {
      display: block;
      float: left;
      td:nth-child(2) {
        display: none;
      }
      td {
        width: 120px;
      }
    }
  }
`;

const DeleteContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const AddContainer = styled.div`
  margin-top: 16px;
`;

const PropertyNamesTable = styled.table`
  td {
    width: 120px;
  }
`;

export function PointsTableComponent({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const showPower =
    (PropertyValueSet.getInteger(
      "showpower",
      PropertiesSelector.getSelectedProperties(state.chartSettings)
    ) as number) !== 0;

  return (
    <Root>
      <PropertyNamesTable>
        <tbody>
          <tr>
            {getPointDefinition(showPower).map(p => (
              <td key={p.name}>
                <P1 weight="normal" color="dark">
                  {sharedState.translate(
                    LanguageTexts.globalPropertyName(p.name)
                  )}
                </P1>
              </td>
            ))}
          </tr>
        </tbody>
      </PropertyNamesTable>

      {state.points.map((point, index) => (
        <PointContainer key={index}>
          <SelectorContainer>
            <PropertiesSelector.PropertiesSelectorView
              dispatch={Dispatch.map(
                action => Action.dispatchPointSelector(index, action),
                dispatch
              )}
              productId={"PsychrometricTool"}
              sharedState={sharedState}
              propertiesInfo={getPointDefinition(showPower)}
              fieldGroup={"Settings.PsychrometricTool"}
              isReadonly={false}
              showCodes={false}
              state={{
                properties: PropertyValueSet.merge(
                  state.pressure,
                  PropertiesSelector.getSelectedProperties(point.state)
                )
              }}
              hidePropertyNames={true}
            />
          </SelectorContainer>

          <DeleteContainer>
            <a onClick={() => dispatch(Action.deletePoint(index))}>
              <Trash height="1.6rem" />
            </a>
          </DeleteContainer>
        </PointContainer>
      ))}
      <AddContainer>
        <StandardButton
          buttonType="Primary"
          size="Small"
          onClick={() => dispatch(Action.addPoint())}
        >
          add
        </StandardButton>
      </AddContainer>
    </Root>
  );
}

function getPointDefinition(
  showPower: boolean
): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return showPower
    ? createPropertiesWithPower()
    : createPropertiesWithoutPower();
}

function createPropertiesWithoutPower(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "temperature",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 2,
      name: "humidity",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 3,
      name: "enthalpy",
      group: "",
      quantity: "SpecificEnthalpy",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    }
  ];
}

function createPropertiesWithPower(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    ...createPropertiesWithoutPower(),
    {
      sortNo: 4,
      name: "sensiblepower",
      group: "",
      quantity: "Power",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 5,
      name: "totalpower",
      group: "",
      quantity: "Power",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    }
  ];
}
