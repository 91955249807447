import * as React from "react";
import * as Types from "./types";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { Root, StyledTable, SelectedDiv, StyledTr } from "./elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { S2, P2, CheckBox, OverlayLoader } from "@genesys/ui-elements";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import { AmountFormatSelector } from "../../amount-format-selector";
import * as GenesysPropertiesSelectorHelpers from "@genesys/client-core/lib/genesys-properties-selector-helpers";
import { getAirflows } from "./functions";

export interface Props {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemProperties: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly isSystemLocked: boolean;
  readonly dispatch: Dispatch<Action>;
}

export function HumSizeSelectorView({
  state,
  sharedState,
  systemProperties,
  operatingCases,
  isSystemLocked,
  dispatch
}: Props): JSX.Element {
  if (!state.data) {
    return <OverlayLoader />;
  }

  const sys = state.data.product.sys;
  const hum = state.data.product.hum;

  const airFlows: Types.AirFlows = getAirflows(operatingCases);

  const propertyW = PropertyValueSet.getAmount<Quantity.Length>(
    "ahucrosssectionwidth",
    systemProperties
  )!;
  const propertyH = PropertyValueSet.getAmount<Quantity.Length>(
    "ahucrosssectionheight",
    systemProperties
  )!;

  const propertyHumidifiers = hum.properties
    .find(p => p.name === "hummodel")!
    .values.map(v => ({
      ...v,
      value: PropertyValue.getInteger(PropertyValue.fromString(v!.value!)!),
      rangeFilter: PropertyFilter.fromString(v!.rangeFilter),
      validationFilter: PropertyFilter.fromString(v!.validationFilter)
    }))
    .filter(
      v =>
        (v!.rangeFilter === undefined ||
          PropertyFilter.isValid(systemProperties, v!.rangeFilter!)) &&
        (v!.validationFilter === undefined ||
          PropertyFilter.isValid(systemProperties, v!.validationFilter!))
    );

  const humidifiers = propertyHumidifiers
    .map(p => ({
      table: {
        cassetteArea: (hum.cassetteArea?.rows || []).find(t =>
          PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                "hummodel",
                PropertyValue.create("integer", p!.value!)
              ),
              systemProperties
            ),
            PropertyFilter.fromStringOrEmpty(t!.propertyFilter || "")
          )
        )!,
        widthHeight: (hum.widthAndHeight?.rows || []).find(t =>
          PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                "hummodel",
                PropertyValue.create("integer", p!.value!)
              ),
              systemProperties
            ),
            PropertyFilter.fromStringOrEmpty(t!.propertyFilter || "")
          )
        )!
      },
      property: p!
    }))
    .map(h => {
      const cassetteAreaValues = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        h.table.cassetteArea.values
      );
      const widthHeightValues = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        h.table.widthHeight.values
      );
      return {
        ...h,
        table: {
          ...h.table,
          cassetteArea: {
            faceArea: PropertyValueSet.getAmount<Quantity.Area>(
              "FaceArea",
              cassetteAreaValues
            )!
          },
          widthHeight: {
            width: PropertyValueSet.getAmount<Quantity.Area>(
              "Width",
              widthHeightValues
            )!,
            height: PropertyValueSet.getAmount<Quantity.Area>(
              "Height",
              widthHeightValues
            )!
          }
        }
      };
    });

  const sizes: Array<Types.Size> = humidifiers.map(h => ({
    model: h.property.value!,
    displayName: GenesysPropertiesSelectorHelpers.createTranslation(
      sharedState.translate,
      hum.id
    ).propertyValue("hummodel", h.property.value!),
    faceArea: h.table.cassetteArea.faceArea,
    minVelocity: Amount.create(
      Amount.valueAs(Units.StandardCubicMeterPerSecond, airFlows.minAirFlow) /
        Amount.valueAs(Units.SquareMeter, h.table.cassetteArea.faceArea!),
      Units.MeterPerSecond
    )!,
    maxVelocity: Amount.create(
      Amount.valueAs(Units.StandardCubicMeterPerSecond, airFlows.maxAirFlow) /
        Amount.valueAs(Units.SquareMeter, h.table.cassetteArea.faceArea!),
      Units.MeterPerSecond
    )!,
    width: h.table.widthHeight.width as Amount.Amount<any>,
    height: h.table.widthHeight.height as Amount.Amount<any>
  }));

  const sizeSelectionMaxVelocityRow = (
    sys.sizeSelectionMaxVelocity?.rows || []
  ).find(s =>
    PropertyFilter.isValid(
      systemProperties,
      PropertyFilter.fromStringOrEmpty(s!.propertyFilter!)
    )
  )!;

  const sizeSelectionMaxVelocityValues = PropertyValueSet.fromStringOrError(
    () => PropertyValueSet.Empty,
    sizeSelectionMaxVelocityRow.values
  );

  const maxVelocity = PropertyValueSet.getAmount<Quantity.Velocity>(
    "MaxVelocity",
    sizeSelectionMaxVelocityValues
  );

  const validSizes = sizes.filter(
    s =>
      Amount.lessOrEqualTo(s.width, propertyW) &&
      Amount.lessOrEqualTo(s.height, propertyH) &&
      Amount.lessOrEqualTo(s.maxVelocity, maxVelocity!)
  );

  const fieldGroup = "SizeSelector";
  const faceAreaAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "Area",
    "Area"
  );
  const minFaceVelocityAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "MinVelocity",
    "Velocity"
  );
  const maxFaceVelocityAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    "MaxVelocity",
    "Velocity"
  );

  if (!state.selectedSize && validSizes.length) {
    dispatch(Action.setSize(validSizes[validSizes.length - 1].model));
  }

  return (
    <Root>
      {validSizes.length ? (
        <StyledTable>
          <thead>
            <tr>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.model())}
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.faceArea())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldName="Area"
                    fieldGroup={fieldGroup}
                    conversionParameters={undefined}
                    amountFormat={faceAreaAmountFormat}
                    translate={sharedState.translate}
                    onFormatCleared={() => {
                      dispatch(Action.FormatCleared(fieldGroup, "Area"));
                    }}
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "Area",
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.minVelocity())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    translate={sharedState.translate}
                    conversionParameters={undefined}
                    fieldGroup={fieldGroup}
                    fieldName="MinVelocity"
                    amountFormat={minFaceVelocityAmountFormat}
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "MinVelocity",
                          unit,
                          decimalCount
                        )
                      )
                    }
                    onFormatCleared={() => {
                      dispatch(Action.FormatCleared(fieldGroup, "MinVelocity"));
                    }}
                  />
                </S2>
              </th>
              <th>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.maxVelocity())}{" "}
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldGroup={fieldGroup}
                    fieldName="MaxVelocity"
                    conversionParameters={undefined}
                    amountFormat={maxFaceVelocityAmountFormat}
                    translate={sharedState.translate}
                    onFormatCleared={() =>
                      dispatch(Action.FormatCleared(fieldGroup, "MaxVelocity"))
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          "MaxVelocity",
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </S2>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {validSizes.map(s => (
              <StyledTr
                isSelected={state.selectedSize === s.model}
                key={s.model}
                onClick={() => {
                  if (!isSystemLocked) {
                    dispatch(Action.setSize(s.model));
                  }
                }}
              >
                <td>
                  <P2 weight="normal" color="secondary">
                    {s.displayName}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.faceArea),
                      faceAreaAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.minVelocity),
                      minFaceVelocityAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  <P2 color="secondary">
                    {getValue(
                      PropertyValue.fromAmount(s.maxVelocity),
                      maxFaceVelocityAmountFormat
                    )}
                  </P2>
                </td>
                <td>
                  {state.selectedSize === s.model && (
                    <SelectedDiv>
                      <P2 weight="normal" color="dark">
                        {sharedState.translate(LanguageTexts.selected())}
                      </P2>
                      {/* tslint:disable-next-line */}
                      <CheckBox
                        isChecked={true}
                        children=""
                        onClick={() => undefined}
                      />
                    </SelectedDiv>
                  )}
                </td>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <div>
          {sharedState.translate(LanguageTexts.noSizesFoundForVelocity())}
        </div>
      )}
    </Root>
  );
}
