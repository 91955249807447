import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createSolidContactsSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps): string {
  const svgString = `

<svg
   width="70px"
   height="70px"
   viewBox="0 0 70 70"
   version="1.1"
   id="svg32"
   sodipodi:docname="solid-contact.svg"
   inkscape:version="1.1 (c68e22c387, 2021-05-23)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:dc="http://purl.org/dc/elements/1.1/">
  <sodipodi:namedview
     id="namedview34"
     pagecolor="#ffffff"
     bordercolor="${borderColor}"
     borderopacity="1.0"
     inkscape:pageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     showgrid="false"
     inkscape:zoom="11.885714"
     inkscape:cx="34.957933"
     inkscape:cy="34.957933"
     inkscape:window-width="2048"
     inkscape:window-height="1129"
     inkscape:window-x="1912"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="Icons/System-Menu-Items/Operating-Cases" />
  <title
     id="title2">F518836B-8F7C-4CD5-9755-B19CEE84E1F8</title>
  <defs
     id="defs21">
    <linearGradient
       x1="50%"
       y1="0%"
       x2="50%"
       y2="100%"
       id="linearGradient-1">
      <stop
         stop-color="${backgroundColor}"
         offset="0%"
         id="stop4" />
      <stop
         stop-color="${backgroundColor}"
         offset="100%"
         id="stop6" />
    </linearGradient>
    <rect
       id="path-2"
       x="0"
       y="0"
       width="60"
       height="60"
       rx="9" />
    <filter
       x="-0.08"
       y="-0.08"
       width="1.16"
       height="1.1933333"
       filterUnits="objectBoundingBox"
       id="filter-3">
      <feMorphology
         radius="0.5"
         operator="dilate"
         in="SourceAlpha"
         result="shadowSpreadOuter1"
         id="feMorphology10" />
      <feOffset
         dx="0"
         dy="2"
         in="shadowSpreadOuter1"
         result="shadowOffsetOuter1"
         id="feOffset12" />
      <feGaussianBlur
         stdDeviation="2"
         in="shadowOffsetOuter1"
         result="shadowBlurOuter1"
         id="feGaussianBlur14" />
      <feComposite
         in="shadowBlurOuter1"
         in2="SourceAlpha"
         operator="out"
         result="shadowBlurOuter1"
         id="feComposite16" />
      <feColorMatrix
         values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0"
         type="matrix"
         in="shadowBlurOuter1"
         id="feColorMatrix18" />
    </filter>
    <linearGradient
       id="linearGradient3890"
       inkscape:swatch="solid">
      <stop
         style="stop-color:#000000;stop-opacity:1;"
         offset="0"
         id="stop3888" />
    </linearGradient>
    <linearGradient
       inkscape:collect="always"
       id="linearGradient1649">
      <stop
         style="stop-color:white;stop-opacity:1;"
         offset="0"
         id="stop1645" />
      <stop
         style="stop-color:white;stop-opacity:1;"
         offset="1"
         id="stop1647" />
    </linearGradient>
    <rect
       x="1.6871981"
       y="2.147343"
       width="24.541063"
       height="23.620773"
       id="rect835" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-9" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect848" />
    <inkscape:perspective
       sodipodi:type="inkscape:persp3d"
       inkscape:vp_x="0 : 526.18109 : 1"
       inkscape:vp_y="0 : 1000 : 0"
       inkscape:vp_z="744.09448 : 526.18109 : 1"
       inkscape:persp3d-origin="372.04724 : 350.78739 : 1"
       id="perspective2669" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient1649"
       id="linearGradient1651"
       x1="-38.381838"
       y1="7.5157004"
       x2="-9.0130887"
       y2="7.5157004"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(1.4178091,0,0,1.4178091,47.172754,-37.03747)" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-5" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect1666" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-5-5" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect1713" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-6" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect1757" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-6-7" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect1796" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect835-6-7-9" />
    <rect
       x="1.687198"
       y="2.1473429"
       width="24.541063"
       height="23.620773"
       id="rect3985" />
  </defs>
  <g
     id="UI-Kit"
     stroke="none"
     stroke-width="1"
     fill="none"
     fill-rule="evenodd">
    <g
       id="UI-Kit---5.Icons-&amp;-Illustrations"
       transform="translate(-423.000000, -2083.000000)">
      <g
         id="Icons/System-Menu-Items/Operating-Cases"
         transform="translate(428.000000, 2086.000000)">
        <g
           id="Rectangle">
          <use
             fill="black"
             fill-opacity="1"
             filter="url(#filter-3)"
             xlink:href="#path-2"
             id="use23" />
          <use
             stroke="${borderColor}"
             stroke-width="1"
             fill="url(#linearGradient-1)"
             fill-rule="evenodd"
             xlink:href="#path-2"
             id="use25" />
        </g>
        <g
           id="g4122"
           transform="matrix(1.1474261,0,0,1.1580331,14.313521,60.660647)">
          <path
             d="m 34.01241,-29.207862 -3.542715,-1.184281 c -0.432898,-1.829127 -1.151645,-3.547217 -2.111601,-5.101749 l 1.666323,-3.340146 c -1.143767,-1.508393 -2.490104,-2.854355 -3.998122,-3.997746 l -3.34052,1.66632 c -1.554158,-0.960328 -3.272248,-1.679825 -5.100999,-2.112348 l -1.184282,-3.54234 c -0.924317,-0.126413 -1.86739,-0.193566 -2.826594,-0.193566 -0.959204,0 -1.901903,0.06714 -2.826594,0.193566 l -1.1842814,3.54234 c -1.8287525,0.432523 -3.5468421,1.15202 -5.1013747,2.112348 l -3.3401456,-1.66632 c -1.508393,1.143391 -2.8543547,2.489353 -3.997371,3.997746 l 1.6663212,3.340146 c -0.9603289,1.554532 -1.6798259,3.272622 -2.1119741,5.101749 l -3.5427151,1.184281 c -0.1264261,0.923942 -0.1935664,1.86739 -0.1935664,2.826219 0,0.958829 0.066779,1.902278 0.1935664,2.826219 l 3.5427151,1.184283 c 0.4325225,1.829126 1.1516452,3.547216 2.1115984,5.101749 l -1.6655713,3.340145 c 1.1430179,1.508393 2.48897957,2.854355 3.9973711,3.9977464 l 3.3401455,-1.6663224 c 1.5545341,0.96033 3.2726241,1.679827 5.1013755,2.1123499 l 1.1842814,3.5419652 c 0.924316,0.1267946 1.867391,0.1939421 2.826593,0.1939421 0.959205,0 1.901903,-0.067147 2.826596,-0.1939421 l 1.18428,-3.5419652 c 1.828753,-0.4325229 3.546843,-1.1520199 5.100999,-2.1119739 l 3.340522,1.6659464 c 1.508017,-1.1433914 2.854355,-2.4893534 3.997371,-3.9977464 l -1.665572,-3.340145 c 0.959955,-1.554533 1.678701,-3.272623 2.111601,-5.101749 l 3.542714,-1.184283 c 0.126413,-0.923941 0.193566,-1.86739 0.193566,-2.826219 0,-0.958829 -0.06714,-1.902277 -0.193941,-2.826219 z M 13.5739,-12.280556 c -7.7876705,0 -14.10108662,-6.31304 -14.10108662,-14.101087 0,-7.788045 6.31341472,-14.101085 14.10108662,-14.101085 7.787671,0 14.101085,6.31304 14.101085,14.101085 0,7.788047 -6.313414,14.101087 -14.101085,14.101087 z"
             id="path29"
             style="fill:url(#linearGradient1651);fill-opacity:1;stroke:${color};stroke-width:0.375128;stroke-opacity:1" />
          <text
             xml:space="preserve"
             id="text833-28"
             style="font-style:normal;font-weight:normal;font-size:10.5833px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;white-space:pre;shape-inside:url(#rect835-5);fill:${color};fill-opacity:1;stroke:${color};stroke-width:0.284252;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             transform="matrix(1.1159348,0,0,0.99815208,-0.84917622,-39.993827)"><tspan
               x="1.6875"
               y="20.279791"
               id="tspan291"><tspan
                 style="font-size:19.7556px;font-family:'Lucida Sans Unicode';-inkscape-font-specification:'Lucida Sans Unicode'"
                 id="tspan289">S</tspan></tspan></text>
          <text
             xml:space="preserve"
             style="font-style:normal;font-weight:normal;font-size:10.8426px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:${color};fill-opacity:1;stroke:${color};stroke-width:0.3;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
             x="11.227158"
             y="-20.239637"
             id="text870-1"
             transform="scale(1.0253472,0.9752794)"><tspan
               id="tspan868-2"
               x="11.227158"
               y="-20.239637"
               style="font-size:20.2397px;fill:${color};fill-opacity:1;stroke:${color};stroke-width:0.3;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
               sodipodi:role="line">C</tspan></text>
        </g>
      </g>
    </g>
  </g>
  <metadata
     id="metadata116">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:title>F518836B-8F7C-4CD5-9755-B19CEE84E1F8</dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
</svg>


`;

  return encodeSvgStringToImgSrc(svgString);
}
