import styled from "styled-components";

export const Root = styled.div``;

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;

  th,
  td {
    height: 37px;
    padding: 0 60px;
    text-align: left;
  }
`;

export const StyledTr = styled.tr`
  background: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected
      ? "#FFFFFF"
      : "linear-gradient(180deg, #eeeeee 0%, #f0f0f0 100%)"};

  border: 1px solid #f1f5fa;

  box-shadow: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected
      ? "0 5px 5px 0 rgba(0,0,0,0.1)"
      : "0 2px 4px 0 rgba(0, 0, 0, 0.03)"};

  &:hover {
    cursor: pointer;
    background: none;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const SelectedDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  p {
    padding-right: 6px;
  }
`;
