import * as React from "react";
import { State, Action } from "../../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { CheckBox, H2, Spinner } from "@genesys/ui-elements";
import * as PropertiesSelector from "../../../properties-selector";
import {
  createNewProductProperties,
  getAllowedRanges,
  filterPropertiesForRange,
  checkboxGroups
} from "../../data";
import * as Authorization from "@genesys/shared/lib/authorization";
import {
  Root,
  ProductRangeContainer,
  PropertiesSelectorContainer,
  PropertyCheckboxContainer,
  Container
} from "./elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

// todo product range language text

export function SelectionLimitationsView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}) {
  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  if (
    state.systemTypes.length === 0 ||
    state.newPropertiesSelectorState === undefined
  ) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const allowedRanges = getAllowedRanges(sharedState, state.systemTypes);

  const newProductProperties = createNewProductProperties(
    sharedState,
    allowedRanges
  );

  const newProductPropertiesWithFilteredRange = filterPropertiesForRange(
    newProductProperties,
    allowedRanges
  );

  return (
    <Root>
      <ProductRangeContainer>
        <H2 color="primary" weight="normal">
          {"Product Range"}
        </H2>
        {state.systemTypes.map(systemType => (
          <CheckBox
            key={systemType.id}
            children={systemType.name + " (" + systemType.id + ")"}
            isChecked={systemType.isSelected}
            onClick={() => dispatch(Action.toggleSystemType(systemType.id))}
          />
        ))}
      </ProductRangeContainer>
      <PropertiesSelectorContainer>
        <H2 color="primary" weight="normal">
          {sharedState.translate(LanguageTexts.initialConfiguration())}
        </H2>
        <PropertiesSelector.PropertiesSelectorView
          fieldGroup={`Settings.SSGNEW`}
          isReadonly={false}
          propertiesInfo={newProductPropertiesWithFilteredRange}
          dispatch={Dispatch.map(
            action => Action.dispatchNewPropertiesSelector(action),
            dispatch
          )}
          state={state.newPropertiesSelectorState}
          hideGroupNames={true}
          showCodes={showCodes}
          hidePropertyNames={false}
          sharedState={sharedState}
          productId={"SSGNEW"}
        />
      </PropertiesSelectorContainer>
      <Container>
        {checkboxGroups.reduce((soFar, current) => {
          const checkboxOptions = state.checkBoxPropertyOptions.filter(
            cpo => cpo.propertyName === current.propertyName
          );

          const checkboxes = (
            <PropertyCheckboxContainer
              isFirstOption={current.propertyName === "reactheatertype"}
              key={current.groupName}
            >
              <H2 color="primary" weight="normal">
                {current.groupName}
              </H2>
              {checkboxOptions.map(option => (
                <CheckBox
                  key={option.type}
                  children={option.displayName}
                  isChecked={option.isSelected}
                  onClick={() =>
                    dispatch(Action.toggleCheckboxPropertyOption(option.type))
                  }
                />
              ))}
            </PropertyCheckboxContainer>
          );

          return soFar.concat([checkboxes]);
        }, [] as ReadonlyArray<JSX.Element>)}
      </Container>
    </Root>
  );
}
