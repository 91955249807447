import { User, UserManager } from "oidc-client";

export type Action =
  | UserSessionEstablished
  | RedirectToSigninStarted
  // These are user manager events
  | UserLoadedEvent
  | UserUnloadedEvent
  | AccessTokenExpiringEvent
  | AccessTokenExpiredEvent
  | SilentRenewErrorEvent
  | UserSignedOutEvent;

export interface UserSessionEstablished {
  readonly type: "UserSessionEstablished";
  readonly user: User;
  readonly userManager: UserManager;
}

export function userSessionEstablished(
  user: User,
  userManager: UserManager
): UserSessionEstablished {
  return {
    type: "UserSessionEstablished",
    user,
    userManager
  };
}

export interface RedirectToSigninStarted {
  readonly type: "RedirectToSigninStarted";
}

export function redirectToSigninStarted(): RedirectToSigninStarted {
  return {
    type: "RedirectToSigninStarted"
  };
}

export interface UserLoadedEvent {
  readonly type: "UserLoadedEvent";
  readonly payload: { readonly user: User };
}

export function userLoaded(user: User): UserLoadedEvent {
  return {
    type: "UserLoadedEvent",
    payload: { user }
  };
}

export interface UserUnloadedEvent {
  readonly type: "UserUnloadedEvent";
  readonly payload: {};
}

export function userUnloaded(): UserUnloadedEvent {
  return {
    type: "UserUnloadedEvent",
    payload: {}
  };
}

export interface SilentRenewErrorEvent {
  readonly type: "SilentRenewErrorEvent";
  readonly payload: { readonly error: Error };
}

export function silentRenewError(error: Error): SilentRenewErrorEvent {
  return {
    type: "SilentRenewErrorEvent",
    payload: { error }
  };
}

export interface UserSignedOutEvent {
  readonly type: "UserSignedOutEvent";
  readonly payload: {};
}

export function userSignedOut(): UserSignedOutEvent {
  return {
    type: "UserSignedOutEvent",
    payload: {}
  };
}

export interface AccessTokenExpiringEvent {
  readonly type: "AccessTokenExpiringEvent";
  readonly payload: {};
}

export function accessTokenExpiring(): AccessTokenExpiringEvent {
  return {
    type: "AccessTokenExpiringEvent",
    payload: {}
  };
}

export interface AccessTokenExpiredEvent {
  readonly type: "AccessTokenExpiredEvent";
  readonly payload: {};
}

export function accessTokenExpired(): AccessTokenExpiredEvent {
  return {
    type: "AccessTokenExpiredEvent",
    payload: {}
  };
}
