import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  Format,
  UnitsFormat,
  Amount,
  Quantity,
  unitLookup
} from "@genesys/uom";
import {
  PropertyFilter,
  PropertyFilterAst,
  PropertyValue
} from "@genesys/property";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";

type FilterPrettyPrint = PropertyFiltering.FilterPrettyPrint;
export { FilterPrettyPrint };

export function createFilterPrettyPrint(
  translatePropertyName: GenesysPropertiesSelectorTypes.TranslatePropertyName,
  translatePropertyValue: GenesysPropertiesSelectorTypes.TranslatePropertyValue,
  translate: LanguageTexts.Translate,
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  fieldGroup: string,
  getConversionParamaters: (
    propertyName: string
  ) => QuantityConversion.ConversionParameters | undefined
  // productPropertiesMap: Map<string, GenesysPropertiesSelectorTypes.Property>
): FilterPrettyPrint {
  const filterPrettyMessages: PropertyFiltering.FilterPrettyPrintMessages = {
    comparisionOperationMessage: (
      op: PropertyFilterAst.ComparisonOperationType,
      left: string,
      right: string
    ): string => {
      switch (op) {
        case "greater": {
          return translate(LanguageTexts.shouldBeGreater(left, right));
        }
        case "greaterOrEqual": {
          return translate(LanguageTexts.shouldBeGreaterOrEqual(left, right));
        }
        case "less": {
          return translate(LanguageTexts.shouldBeLess(left, right));
        }
        case "lessOrEqual": {
          return translate(LanguageTexts.shouldBeLessOrEqual(left, right));
        }
        default: {
          exhaustiveCheck(op);
          return "";
        }
      }
    },
    equalsOperationMessage: (
      op: PropertyFilterAst.EqualsOperationType,
      left: string,
      right: string
    ): string => {
      switch (op) {
        case "equals": {
          return translate(LanguageTexts.shouldBeEquals(left, right));
        }
        case "notEquals": {
          return translate(LanguageTexts.shouldBeNotEquals(left, right));
        }
        default: {
          exhaustiveCheck(op);
          return "";
        }
      }
    },
    rangeMessage: (min: string, max: string) =>
      translate(LanguageTexts.shouldBeBetweenMinMax(min, max)),
    andMessage: () => translate(LanguageTexts.and()),
    orMessage: () => translate(LanguageTexts.or()),
    propertyNameMessage: (propertyName: string) =>
      translatePropertyName(propertyName, translate),
    propertyValueMessage: (
      propertyName: string,
      pv: PropertyValue.PropertyValue
    ) => {
      switch (pv.type) {
        case "integer": {
          return translatePropertyValue(
            propertyName,
            PropertyValue.getInteger(pv)!,
            translate
          );
        }

        case "text": {
          return PropertyValue.getText(pv) || "";
        }

        case "amount": {
          const conversionParamater = getConversionParamaters(propertyName);
          const amountFormat = getAmountFormat(
            fieldGroup,
            propertyName,
            pv.value as Amount.Amount<Quantity.Quantity>
          );
          const format = Format.getUnitFormat(amountFormat.unit, UnitsFormat);
          return `${ProductProperties.getValue(
            pv,
            amountFormat,
            conversionParamater
          )} ${format && format.label}`;
        }
        default: {
          exhaustiveCheck(pv);
          return "missing property type translation";
        }
      }
    },
    nullMessage: () => translate(LanguageTexts.pNull())
  };

  return (pf: PropertyFilter.PropertyFilter) =>
    PropertyFiltering.filterPrettyPrintIndented(
      filterPrettyMessages,
      5,
      " ",
      pf,
      UnitsFormat,
      unitLookup
    );
}
