import * as React from "react";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import styled from "styled-components";
import { FetchDocumentComponent } from "@genesys/client-core/lib/fetch-document";
import { SpecialHeaders, getSpecialHeadersErrors } from "./special-headers";
import { Dispatch } from "@typescript-tea/core";
import { Action, State, ReportHeaderInfo, Report, ReportState } from "./state";
import {
  CheckBox,
  H3,
  zIndex,
  StandardButton,
  Spinner,
  GenesysSelect
} from "@genesys/ui-elements";
import * as MeasureSystemEnum from "@genesys/shared/lib/enums/measure-system";
import * as System from "../../system";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import * as Auth from "@genesys/shared/lib/authorization";
import * as PrintParam from "../../../print-params";
import { clientConfig } from "../../../config";
import { PropertyFilter } from "@genesys/property";
import {
  arraysContainSameElements,
  getParams,
  getSelectedReports,
  getUserSelectedReports,
  headerLabels,
  paperSizes,
  toggleAll,
  userParamRegex,
  getDefaultUserParamValueForReport,
  additionalReportCheck
} from "./tools";
// import { mockReports } from "./delete-me";

const StyledH3 = styled(H3)`
  margin-bottom: 5px;
`;

const Container = styled.div`
  padding-top: 10px;
  padding-left: 25px;

  overflow-y: auto;
`;

const PropertiesHeader = styled.div`
  width: 100;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-height: 65vh;
  margin-bottom: 15px;

  > div {
    margin-bottom: 15px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledButton = styled(StandardButton)`
  margin-right: 12px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.FullOverlay};
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 15px;

  .MuiInputBase-input {
    font-size: 15px;
  }
`;

export function PrintView({
  state,
  sharedState,
  system,
  dispatch
}: {
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;

  const sysProperties = sysComponent.properties;
  const { applicationClaims } = sharedState.user;
  const systemType = system.file.systemTypeId;
  const hasSpecialHeaderInput = ["ICA", "HCD", "LDP"].includes(systemType);
  const routeEndpoint = clientConfig.genesysBackend;

  const reportHeaderInfo: ReportHeaderInfo = state.reportHeaderInfo || {
    systemName: system.file.name,
    preparedFor: "",
    salesOrder: "",
    endUser: ""
  };

  const printParams = PrintParam.getSelectedPrintParams(
    state.printParamsState
  ) || {
    selectedLanguage: sharedState.user.settings.language,
    selectedPaperSize: (sharedState.user.settings.selectedAmountProfile
      .measureSystem === MeasureSystemEnum.MeasureSystem.SI
      ? paperSizes[0]
      : paperSizes[1]
    ).size,
    selectedHeaderLabel: headerLabels[0]
  };

  const visibleReports =
    state.reports?.filter(
      r =>
        PropertyFilter.isValid(sysProperties, r.propertyFilter) &&
        Auth.checkClaimFilter(applicationClaims, r.claimFilter)
    ) || [];

  const userSelectedReports = getUserSelectedReports(
    sharedState,
    system,
    visibleReports
  );

  const isGenerating = Object.values(state.generatingReport).includes(true);

  const IsReportDisbaled = (report: Report) => {
    return (
      isGenerating ||
      system.status < report.status ||
      additionalReportCheck(report, system)
    );
  };

  const selectableReports = visibleReports.filter(x => !IsReportDisbaled(x));

  const isDeveloper = AuthorizationTools.checkPermission(
    applicationClaims,
    AuthorizationTools.genesysUserClaims.developer
  );
  const selectedReports = getSelectedReports(
    state,
    system,
    userSelectedReports
  );
  const showDocx = Auth.checkPermission(
    applicationClaims,
    Auth.genesysUserClaims.canUseDocFormat
  );
  const selectedReportIds = selectedReports.map(x => x.id);
  const currentUserSeletedReportIDs = userSelectedReports?.map(x => x.id) || [];
  const defaultsHaveChanged = !arraysContainSameElements(
    currentUserSeletedReportIDs,
    selectedReportIds
  );

  if (visibleReports.length === 0) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const isAllSelected = selectableReports.length === selectedReports.length;

  const specialHeadersErrors = getSpecialHeadersErrors(
    reportHeaderInfo,
    sharedState.translate,
    hasSpecialHeaderInput
  );

  return (
    <Container>
      <PropertiesHeader>
        <StyledH3>{sharedState.translate(LanguageTexts.settings())}</StyledH3>
        <PrintParam.View
          isDisabled={isGenerating}
          sharedState={sharedState}
          state={state.printParamsState!}
          dispatch={Dispatch.map(Action.dispatchPrintParams, dispatch)}
        />
        {hasSpecialHeaderInput && (
          <SpecialHeaders
            sharedState={sharedState}
            reportHeaderInfo={reportHeaderInfo}
            dispatch={dispatch}
          />
        )}
      </PropertiesHeader>
      {isDeveloper && (
        <CheckBox
          isChecked={isAllSelected}
          disabled={isGenerating}
          onClick={() =>
            toggleAll(
              isAllSelected,
              selectableReports,
              selectedReports,
              state.reportStateMap,
              dispatch
            )
          }
        >
          Select all
        </CheckBox>
      )}
      <StyledH3>
        {sharedState.translate(LanguageTexts.reportoptions())}
      </StyledH3>
      <CheckBoxContainer>
        {visibleReports.map(report =>
          createReportViewItem(
            report as any,
            selectedReports,
            state.reportStateMap,
            sharedState,
            systemType,
            IsReportDisbaled,
            dispatch
          )
        )}
      </CheckBoxContainer>
      <ButtonContainer>
        {createButtons({
          sharedState,
          state,
          system,
          dispatch,
          selectedReports,
          reportHeaderInfo,
          printParams,
          isGenerating,
          routeEndpoint,
          selectableReports,
          showDocx,
          specialHeadersErrors,
          showSavePreferences: defaultsHaveChanged
        })}
      </ButtonContainer>
    </Container>
  );
}

function createReportViewItem(
  report: Report,
  selectedReports: ReadonlyArray<ReportState>,
  reportsStateMap: Map<string, ReportState> | undefined,
  sharedState: SharedState.State,
  systemType: string,
  isReportDisabled: (report: Report) => boolean,
  dispatch: Dispatch<Action>
) {
  const isSelected = (id: string) => !!newReportStateMap.get(id)?.isSelected;

  const newReportStateMap =
    reportsStateMap || new Map(selectedReports.map(x => [x.id, x]));

  const selectedReport = newReportStateMap.get(report.id) || {
    id: report.id,
    userParams: getDefaultUserParamValueForReport(report),
    isSelected: false
  };

  const toggleReportSelection = (id: string) => {
    newReportStateMap.set(id, {
      ...selectedReport,
      isSelected: !isSelected(id)
    });

    dispatch(Action.setReportsState(newReportStateMap));
  };

  const getUserParamData = () => {
    const input = report.userParams || "";
    const match = input.match(userParamRegex);
    if (!match) {
      return null;
    }

    const [_, userParamName, paramValues] = match;
    return { userParamName, paramValues: paramValues.split(",") };
  };

  const renderCheckBox = () => (
    <CheckBox
      isChecked={isSelected(report.id)}
      disabled={isReportDisabled(report)}
      onClick={() => toggleReportSelection(report.id)}
      children={sharedState.translate(
        LanguageTexts.dynamicText(report.textId ?? `report_${report.name}`)
      )}
    />
  );

  const renderParamSelect = (userParamName: string, paramValues: string[]) => (
    <div style={{ marginLeft: "10px" }}>
      <p>
        {sharedState.translate(
          LanguageTexts.reportParam(userParamName, systemType)
        )}
      </p>
      <GenesysSelect
        disabled={!isSelected(report.id)}
        width={80}
        height={35}
        value={selectedReport?.userParams.split("=")[1]}
        onChange={e => {
          newReportStateMap.set(report.id, {
            id: report.id,
            userParams: `${userParamName}=${e.target.value}`,
            isSelected: true
          });
          dispatch(Action.setReportsState(newReportStateMap));
        }}
        options={paramValues.map(value => ({
          title: sharedState.translate(
            LanguageTexts.reportParamValue(userParamName, value, systemType)
          ),
          value
        }))}
      />
    </div>
  );

  const { userParamName, paramValues } = getUserParamData() || {};

  return (
    <FlexContainer key={report.id}>
      <div>{renderCheckBox()}</div>
      <div>
        {userParamName &&
          paramValues &&
          renderParamSelect(userParamName, paramValues)}
      </div>
    </FlexContainer>
  );
}

function createButtons({
  sharedState,
  state,
  system,
  dispatch,
  selectedReports,
  reportHeaderInfo,
  printParams,
  isGenerating,
  routeEndpoint,
  showDocx,
  showSavePreferences,
  specialHeadersErrors,
  selectableReports
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly selectableReports: ReadonlyArray<Report>;
  readonly selectedReports: ReadonlyArray<ReportState>;
  readonly reportHeaderInfo: ReportHeaderInfo;
  readonly printParams: PrintParam.PrintParams;
  readonly isGenerating: boolean;
  readonly routeEndpoint: string;
  readonly showDocx: boolean;
  readonly showSavePreferences: boolean;
  readonly specialHeadersErrors: Map<string, string | undefined>;
}): JSX.Element {
  const { translate, accessToken, genesysPrefix } = sharedState;
  const { generatingReport } = state;
  const systemIdentifiers = {
    genesysNo: system.file.genesysNo,
    revisionNo: system.revisionNo
  };

  const documentParams = {
    reportIds: selectedReports.map(x => x.id),
    userparams: selectedReports.map(x => x.userParams),
    systemIds: [system.id],
    paperSize: printParams.selectedPaperSize,
    culture: printParams.selectedLanguage,
    headerLabel: printParams.selectedHeaderLabel.header,
    multiSystem: "false",
    systemName: encodeURIComponent(reportHeaderInfo.systemName),
    preparedFor: encodeURIComponent(reportHeaderInfo.preparedFor),
    salesOrder: encodeURIComponent(reportHeaderInfo.salesOrder),
    endUser: encodeURIComponent(reportHeaderInfo.endUser)
  };

  const isButtonDisabled = (type: "pdf" | "docx") =>
    selectedReports.length === 0 ||
    Array.from(specialHeadersErrors.values()).some(v => v !== undefined) ||
    generatingReport[type];

  const generateUrl = (output: "PDF" | "DOCX") =>
    `${routeEndpoint}/internal/GetReports${getParams({
      ...documentParams,
      output
    })}`;

  const handleDownloadComplete = (type: "pdf" | "docx") =>
    dispatch(Action.toggleGeneratingReport(type));

  const renderFetchDocumentButton = (
    output: "PDF" | "DOCX",
    buttonType: "Primary",
    onClickAction: () => void
  ) => (
    <FetchDocumentComponent
      authentication={{ type: "Bearer", accessToken }}
      url={generateUrl(output)}
      type="download"
      params={undefined}
      filename={`${genesysPrefix.genesysNo(
        systemIdentifiers.genesysNo,
        systemIdentifiers.revisionNo
      )}.${output.toLowerCase()}`}
      method="GET"
      onComplete={() =>
        handleDownloadComplete(output.toLowerCase() as "pdf" | "docx")
      }
    >
      {onClick => (
        <StyledButton
          buttonType={buttonType}
          size="Small"
          disabled={isButtonDisabled(output.toLowerCase() as "pdf" | "docx")}
          onClick={() => {
            onClick();
            onClickAction();
          }}
        >
          {generatingReport[output.toLowerCase() as "pdf" | "docx"]
            ? translate(LanguageTexts.generating())
            : translate(
                output === "PDF"
                  ? LanguageTexts.createPDF()
                  : LanguageTexts.createDocx()
              )}
        </StyledButton>
      )}
    </FetchDocumentComponent>
  );

  return (
    <>
      <StyledButton
        disabled={isGenerating}
        buttonType="Secondary"
        size="Small"
        onClick={() =>
          toggleAll(
            true,
            selectableReports,
            selectedReports,
            state.reportStateMap,
            dispatch
          )
        }
      >
        {translate(LanguageTexts.clear())}
      </StyledButton>

      {renderFetchDocumentButton("PDF", "Primary", () =>
        dispatch(Action.toggleGeneratingReport("pdf"))
      )}

      {showDocx &&
        renderFetchDocumentButton("DOCX", "Primary", () =>
          dispatch(Action.toggleGeneratingReport("docx"))
        )}
      {showSavePreferences && (
        <StyledButton
          disabled={isGenerating}
          buttonType="Secondary"
          size="Small"
          onClick={() => dispatch(Action.saveCurrentSelectedReporsAsDefaults())}
        >
          {translate(LanguageTexts.saveAsPrecheckedDefault())}
        </StyledButton>
      )}
    </>
  );
}

// tslint:disable-next-line
