import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setUserSettingsClimateMutation = gql`
  mutation SetUserSettingsClimate(
    $userSettingsClimate: UserSettingsClimateInputType!
  ) {
    setUserSettingsClimate(userSettingsClimate: $userSettingsClimate) {
      id
      climate {
        ...CompleteUserClimateSettings
      }
    }
  }
  ${Fragments.completeUserClimateSettings}
`;
