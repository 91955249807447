import { Amount, Quantity, Serialize, Format } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import {
  QuantityDefaultsMap,
  FieldDefaultsMap,
  AmountFormat,
  PropertyFormats,
  UserAmountFieldsMap
} from "./types";

type MutatableMap<T> = { -readonly [P in keyof T]: T[P] };

export function buildPropertyFormatsMap(
  quantityDefaults: QuantityDefaultsMap,
  fieldDefaults: FieldDefaultsMap,
  userAmountFields: UserAmountFieldsMap,
  measureSystem: number,
  screenAmountProfileFieldGroup: string,
  properties: PropertyValueSet.PropertyValueSet
): PropertyFormats {
  const propertyFormats = PropertyValueSet.getPropertyNames(properties).reduce(
    // tslint:disable-next-line:readonly-keyword
    (soFar: { [key: string]: AmountFormat }, propertyName: string) => {
      const amount = PropertyValueSet.getAmount(propertyName, properties);

      if (!amount) {
        return soFar;
      }

      const amountFormat = getAmountFormat(
        quantityDefaults,
        fieldDefaults,
        userAmountFields,
        measureSystem,
        screenAmountProfileFieldGroup,
        propertyName,
        amount as Amount.Amount<Quantity.Quantity>
      );

      soFar[propertyName] = amountFormat;
      return soFar;
    },
    {}
  );
  return propertyFormats;
}

export function getAmountFormat(
  quantityDefaults: QuantityDefaultsMap,
  fieldDefaults: FieldDefaultsMap,
  userAmountFields: UserAmountFieldsMap,
  measureSystemKey: number,
  fieldGroup: string,
  fieldName: string,
  amountOrQuantity: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
): AmountFormat {
  const key = `${convertToMeasureSystemString(
    measureSystemKey
  )}_${fieldGroup}_${fieldName}`;
  const userDefault = userAmountFields[key];

  if (userDefault) {
    return {
      unit: userDefault.unit,
      decimalCount: userDefault.decimalCount,
      userDefined: true
    };
  }

  const fieldDefault = fieldDefaults[key];

  if (fieldDefault) {
    return {
      unit: fieldDefault.unit,
      decimalCount: fieldDefault.decimalCount,
      userDefined: false
    };
  }

  const quantity = isAmount(amountOrQuantity)
    ? amountOrQuantity.unit.quantity
    : amountOrQuantity;

  const quantityDefault =
    quantityDefaults[quantity] && quantityDefaults[quantity][measureSystemKey];

  if (quantityDefault) {
    return {
      unit: quantityDefault.unit,
      decimalCount: quantityDefault.decimalCount,
      userDefined: false
    };
  }

  if (isAmount(amountOrQuantity)) {
    const unit = amountOrQuantity.unit;
    const format = Format.getUnitFormat(unit)!;
    // last resort
    return {
      unit: unit,
      decimalCount: format.decimalCount,
      userDefined: false
    };
  }

  const units = Format.getUnitsForQuantity(amountOrQuantity);
  if (units.length > 0) {
    const unitFormat = Format.getUnitFormat(units[0]);
    return {
      unit: units[0],
      decimalCount: unitFormat !== undefined ? unitFormat.decimalCount : 2,
      userDefined: false
    };
  }

  throw new Error("Unable to determine unit information");
}

export function getUserAmountFormatFieldKey(
  measureSystem: number,
  fieldGroup: string,
  fieldName: string
): string {
  const key = `${convertToMeasureSystemString(
    measureSystem
  )}_${fieldGroup}_${fieldName}`;

  return key;
}

export function createUserAmountFieldsMap(
  userAmountFields: ReadonlyArray<{
    readonly fieldGroup: string;
    readonly fieldName: string;
    readonly measureSystem: number;
    readonly unit: string;
    readonly decimalCount: number;
  }>
): UserAmountFieldsMap {
  return userAmountFields.reduce((soFar, current) => {
    try {
      const key = getUserAmountFormatFieldKey(
        current.measureSystem,
        current.fieldGroup,
        current.fieldName
      );
      const unit = Serialize.stringToUnit(current.unit);
      if (unit === undefined) {
        console.warn("Unknown unit " + current.unit);
        return soFar;
      }
      const quantityFormat: AmountFormat = {
        unit,
        decimalCount:
          current.decimalCount !== undefined ? current.decimalCount : 2,
        userDefined: true
      };
      soFar[key] = quantityFormat;
    } catch (error) {
      console.warn(error);
    }
    return soFar;
  }, {} as MutatableMap<UserAmountFieldsMap>);
}

export function createFieldsDefaultsMap(
  fieldDefaults: ReadonlyArray<{
    readonly fieldGroup: string;
    readonly fieldName: string;
    readonly measureSystem: number;
    readonly unit: string;
    readonly decimalCount: number;
  }>
): FieldDefaultsMap {
  return fieldDefaults.reduce((soFar, current) => {
    try {
      const key = `${convertToMeasureSystemString(current.measureSystem)}_${
        current.fieldGroup
      }_${current.fieldName}`;
      const quantityFormat: AmountFormat = {
        unit: Serialize.stringToUnit(current.unit)!,
        decimalCount:
          current.decimalCount !== undefined ? current.decimalCount : 2,
        userDefined: false
      };
      soFar[key] = quantityFormat;
    } catch (error) {
      console.warn(error);
    }
    return soFar;
  }, {} as MutatableMap<FieldDefaultsMap>);
}

export function createQuantityDefaultsMap(
  quantityDefaults: ReadonlyArray<{
    readonly measureSystem: number;
    readonly quantity: string;
    readonly unit: string;
    readonly decimalCount: number;
  }>
): QuantityDefaultsMap {
  return quantityDefaults.reduce((soFar, current) => {
    try {
      const quantityFormat: AmountFormat = {
        unit: Serialize.stringToUnit(current.unit)!,
        decimalCount: current.decimalCount,
        userDefined: false
      };
      if (soFar[current.quantity]) {
        soFar[current.quantity][current.measureSystem] = quantityFormat;
      } else {
        soFar[current.quantity] = { [current.measureSystem]: quantityFormat };
      }
    } catch (error) {
      console.warn(error);
    }
    return soFar;
  }, {} as MutatableMap<QuantityDefaultsMap>);
}

export function convertToMeasureSystemString(measureSystem: number): string {
  if (measureSystem === 1) {
    return "SI";
  }

  if (measureSystem === 2) {
    return "IP";
  }

  throw new Error("Invalid measure system");
}

export function convertToMeasureSystemInt(measureSystem: string): number {
  if (measureSystem === "SI") {
    return 1;
  }

  if (measureSystem === "IP") {
    return 2;
  }

  throw new Error("Invalid measure system");
}

export function isAmount(
  value: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
): value is Amount.Amount<Quantity.Quantity> {
  if (typeof value === "string") {
    return false;
  }
  return true;
}
