import * as SharedState from "../shared-state";
import * as Types from "./types";
import * as GraphQLTypes from "../graphql-types";
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { querySystems, productQuery } from "./queries";
import { Quantity, Unit } from "@genesys/uom";
import { parseSystems, System, OperatingCase } from "./system";
import { PropertyValueSet } from "@genesys/property";
import { saveOperatingCasesMutation } from "@genesys/client-core/lib/graphql-mutations";
import { promiseCmd } from "../promise-effect-manager";
import { knownSettings } from "./known-settings";
import { getAllSelectableSummaryItems } from "./definitions";
import {
  createPropertiesMap,
  parseOpcProperties,
  PropertiesMap,
  OpcPropertiesPerSystem
} from "./property";

type SummaryType = Types.SummaryType;
type SelectedColumn = Types.SelectedColumn;
type SystemId = string;
type SortNo = number;

interface EditedCell {
  readonly initialValue: string | number;
  readonly value: string | number;
  readonly column: SelectedColumn;
}

interface UiSettings {
  readonly resultTableSrcollBarLeft: number;
}

export type State = {
  readonly selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>;
  readonly systemsToBeSaved: Set<SystemId>;
  readonly selectedOpc: Map<SystemId, SortNo>;
  readonly currentOpcValues: Map<SystemId, Map<SortNo, OperatingCase>>;
  readonly currentClimateSettingValues: Map<
    SystemId,
    PropertyValueSet.PropertyValueSet
  >;
  readonly systems: ReadonlyArray<System> | undefined;
  readonly selectedRows: Set<SystemId>;
  readonly editedRowId: SystemId;
  readonly editedCell: EditedCell | undefined;
  readonly isSaving: boolean;
  readonly uiSettings: UiSettings;
  readonly propertyValueSourceMap: PropertiesMap;
  readonly propertiesPerSystem: OpcPropertiesPerSystem;
};

interface Settings {
  readonly selectedRows: Set<SystemId>;
}

export const init = (
  sharedState: SharedState.State,
  systems: Array<{
    readonly systemId: string;
    readonly systemType: string;
  }>,
  usedSettings: Settings
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const initialSelectedSummaryItems = getInitalSummaryItems(
    sharedState.user.settings.systemSummarySettings.find(
      x => x.settingName === knownSettings.selectedSummaryItems
    )?.settingValue
  );

  return [
    {
      systems: undefined,
      systemsToBeSaved: new Set(),
      selectedSummaryItems: initialSelectedSummaryItems,
      selectedOpc: new Map(),
      selectedRows: usedSettings.selectedRows,
      currentClimateSettingValues: new Map(),
      currentOpcValues: new Map(),
      editedRowId: "",
      editedCell: undefined,
      isSaving: false,
      uiSettings: {
        resultTableSrcollBarLeft: 0
      },
      propertyValueSourceMap: new Map(),
      propertiesPerSystem: new Map()
    },
    promiseCmd(async () => {
      const prom1 = systemsInfoQuery(
        sharedState,
        systems.map(x => x.systemId)
      );
      const prom2 = iniitalProductQuery(
        sharedState,
        Array.from(new Set(systems.map(x => x.systemType)))
      );

      const [userQUery, productQuery] = await Promise.all([prom1, prom2]);

      return { userQUery, productQuery };
    }, Action.initialQueryRecived)
  ];
};

type InitialQuery = {
  readonly userQUery: GraphQLTypes.SystemsInfoQuery;
  readonly productQuery?: GraphQLTypes.SystemsSummaryProductQuery;
};

export const Action = ctorsUnion({
  initialQueryRecived: (data: InitialQuery) => ({ data }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  saveUpdatedSystems: () => ({}),
  setNewClimateValues: (
    systemId: string,
    newClimateValue: PropertyValueSet.PropertyValueSet
  ) => ({ systemId, newClimateValue }),
  setEditedCell: (cell?: EditedCell) => ({ cell }),
  setEditedRow: (rowId: SystemId) => ({ rowId }),
  setNewOpcValues: (
    systemId: string,
    newOpcValues: ReadonlyArray<OperatingCase>
  ) => ({ systemId, newOpcValues }),
  setSelectedSummaryItems: (
    summaryType: SummaryType,
    selectedSummaryColumns: Set<SelectedColumn>
  ) => ({ summaryType, selectedSummaryColumns }),
  setSelectedOpc: (systemId: SystemId, sortNo: number) => ({
    systemId,
    sortNo
  }),
  setSelectedRows: (selectedRows: Set<SystemId>) => ({ selectedRows }),
  updateUISettings: (newSettings: UiSettings) => ({ newSettings })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "initialQueryRecived": {
      const userQuery = action.data.userQUery;
      const productQuery = action.data.productQuery;

      const systems = parseSystems(userQuery.user.systems);
      const initialOpcValues = state.currentOpcValues;
      const initialclimateValues = state.currentClimateSettingValues;

      for (const system of systems) {
        initialOpcValues.set(
          system.id,
          new Map(system.operatingCases.map(x => [x.sortNo, x]))
        );

        initialclimateValues.set(system.id, system.climateSettings);
      }

      const newState: State = {
        ...state,
        systems,
        currentClimateSettingValues: initialclimateValues,
        currentOpcValues: initialOpcValues,
        isSaving: false,
        systemsToBeSaved: new Set()
      };

      if (productQuery) {
        const propertiesMap = createPropertiesMap(
          productQuery.product.systemTypes
        );
        const opcPropertiesPerSystem = parseOpcProperties(
          productQuery.product.systemTypes
        );
        return [
          {
            ...newState,
            propertiesPerSystem: opcPropertiesPerSystem,
            propertyValueSourceMap: propertiesMap
          }
        ];
      }

      return [newState];
    }

    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }

    case "saveUpdatedSystems": {
      return [
        {
          ...state,
          isSaving: true
        },
        promiseCmd(
          async () => {
            const systemsToBeUpdated = Array.from(state.systemsToBeSaved);
            const promises: Array<Promise<unknown>> = [];

            for (const systemId of systemsToBeUpdated) {
              const operatingCases = Array.from(
                state.currentOpcValues.get(systemId)!.values()
              ).filter(x => x.caseType === 0);
              const climateSettings =
                state.currentClimateSettingValues.get(systemId)!;

              promises.push(
                sharedState.graphQL.queryUser<
                  GraphQLTypes.SaveOperatingCases,
                  GraphQLTypes.SaveOperatingCasesVariables
                >(saveOperatingCasesMutation, {
                  input: {
                    systemId: systemId,
                    operatingCases: operatingCases.map(oc =>
                      PropertyValueSet.toString(oc.settings!)
                    ),
                    climateData: PropertyValueSet.toString(climateSettings)
                  }
                })
              );
            }

            await Promise.all(promises);
            return await systemsInfoQuery(
              sharedState,
              state.systems?.map(x => x.id) || []
            );
          },
          res => Action.initialQueryRecived({ userQUery: res })
        )
      ];
    }

    case "setNewClimateValues": {
      const updatedClimateValues = state.currentClimateSettingValues;
      updatedClimateValues.set(action.systemId, action.newClimateValue);

      const systemsToBeSaved = new Set(state.systemsToBeSaved);
      systemsToBeSaved.add(action.systemId);

      return [
        {
          ...state,
          currentClimateSettingValues: updatedClimateValues,
          systemsToBeSaved: systemsToBeSaved
        }
      ];
    }

    case "setEditedCell": {
      return [
        {
          ...state,
          editedCell: action.cell
        }
      ];
    }

    case "setEditedRow": {
      return [
        {
          ...state,
          editedRowId: action.rowId
        }
      ];
    }

    case "setNewOpcValues": {
      const updatedOpcValues = state.currentOpcValues;

      const newSystemOpcValues = new Map(
        action.newOpcValues.map(x => [x.sortNo, x])
      );

      updatedOpcValues.set(action.systemId, newSystemOpcValues);

      const systemsToBeSaved = new Set(state.systemsToBeSaved);
      systemsToBeSaved.add(action.systemId);

      return [
        {
          ...state,
          currentOpcValues: updatedOpcValues,
          systemsToBeSaved: systemsToBeSaved
        }
      ];
    }
    case "setSelectedSummaryItems": {
      const { selectedSummaryItems } = state;
      selectedSummaryItems.set(
        action.summaryType,
        action.selectedSummaryColumns
      );

      // tslint:disable-next-line
      const summaryItemsObj: { [key: string]: string[] } = {};
      for (const [summaryType, selectedColumns] of selectedSummaryItems) {
        summaryItemsObj[summaryType] = Array.from(selectedColumns);
      }

      return [
        { ...state, selectedSummaryItems },
        undefined,
        [
          SharedState.Action.setSystemSummarySettings([
            {
              settingName: knownSettings.selectedSummaryItems,
              settingValue: JSON.stringify(summaryItemsObj)
            }
          ])
        ]
      ];
    }

    case "setSelectedOpc": {
      const selectedOpc = state.selectedOpc;
      selectedOpc.set(action.systemId, action.sortNo);
      return [
        {
          ...state,
          selectedOpc
        }
      ];
    }

    case "setSelectedRows": {
      return [
        {
          ...state,
          selectedRows: action.selectedRows
        }
      ];
    }

    case "updateUISettings": {
      return [
        {
          ...state,
          uiSettings: action.newSettings
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function systemsInfoQuery(
  sharedState: SharedState.State,
  systemIds: ReadonlyArray<string>
) {
  return sharedState.graphQL.queryUser<
    GraphQLTypes.SystemsInfoQuery,
    GraphQLTypes.SystemsInfoQueryVariables
  >(querySystems, { ids: systemIds });
}

function iniitalProductQuery(
  sharedState: SharedState.State,
  systemTypes: ReadonlyArray<string>
) {
  return sharedState.graphQL.queryProduct<
    GraphQLTypes.SystemsSummaryProductQuery,
    GraphQLTypes.SystemsSummaryProductQueryVariables
  >(productQuery, {
    lol: "",
    systemTypeInput: systemTypes.map(x => ({
      systemTypeId: x
    }))
  });
}

function getInitalSummaryItems(value: string | undefined) {
  if (!value) {
    return new Map<SummaryType, Set<SelectedColumn>>();
  }

  const allSelectableSummaryItems = getAllSelectableSummaryItems();
  const map = new Map<SummaryType, Set<SelectedColumn>>();

  const obj: {
    // tslint:disable-next-line
    [key: string]: Array<string>;
  } = JSON.parse(value);

  for (const [key, value] of Object.entries(obj)) {
    const templateSummaryItemColumns = allSelectableSummaryItems.get(
      key as SummaryType
    );
    if (!templateSummaryItemColumns) {
      continue;
    }

    if (
      !isSubset(
        value as Array<SelectedColumn>,
        Array.from(templateSummaryItemColumns)
      )
    ) {
      continue;
    }
    map.set(key as SummaryType, new Set(value as Array<SelectedColumn>));
  }

  return map;
}

function isSubset<T>(subset: T[], superset: T[]): boolean {
  return subset.every(item => superset.includes(item));
}
