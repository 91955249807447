import React, { useState } from "react";
import styled from "../../styled-components";
import { PrimaryColors } from "../../colors";

const StyledTextArea = styled.textarea`
  border: 1px solid ${PrimaryColors.grey50};
  padding: 5px 10px;
  outline: none;

  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;

  &:focus {
    border: 1px solid ${PrimaryColors.darkBlue};
  }
`;

export function TextArea(
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  const { value, onChange, ...rest } = props;

  React.useEffect(() => {
    setState({ value });
  }, [props.value]);

  const [state, setState] = useState({
    value
  });

  function internalOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const element = event.target as HTMLTextAreaElement;
    setState({
      value: element.value
    });

    if (onChange) {
      onChange(event);
    }
  }

  return (
    <StyledTextArea
      onChange={event => internalOnChange(event)}
      value={state.value}
      //onBlur={onChange}
      {...rest}
    />
  );
}
