import { useState } from "react";
import { Unit, Serialize, UnitFormat, UnitMap } from "uom";
import * as GenesysUom from "@genesys/uom";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import {
  UseAmountFormatSelectorOptions,
  UseAmountFormatSelector
} from "./types";
const precisionValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];

export function getUseAmountFormatSelector(
  options: UseAmountFormatSelectorOptions
): UseAmountFormatSelector {
  const {
    fieldName,
    fieldGroup,
    extraLabel,
    amountFormat,
    conversionParameters,
    translate,
    onFormatChanged,
    onFormatCleared
  } = options;

  const selectedUnit = amountFormat.unit;
  const selectedPrecision = amountFormat.decimalCount;
  const isUserDefined = amountFormat.userDefined;

  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen || !onFormatChanged) {
    return {
      type: "UseAmountFormatSelector",
      isOpen,
      fieldName,
      fieldGroup,
      extraLabel,
      isUserDefined,
      unitItems: [],
      precisionValues: [],
      selectedUnit:
        UnitFormat.getUnitFormat(selectedUnit, GenesysUom.UnitsFormat)?.label ??
        "",
      selectedPrecision: selectedPrecision.toString(),
      translate,
      onChangeUnit: () => ({}),
      onChangePrecision: () => ({}),
      onClear: () => ({}),
      onCancel: () => ({}),
      openSelector: () => setIsOpen(true)
    };
  }

  const selectedUnitString = Serialize.unitToString(selectedUnit);

  const units = getUnits(conversionParameters, selectedUnit);

  const unitItems = units.map(unit => ({
    unit: unit,
    value: Serialize.unitToString(unit),
    text: UnitFormat.getUnitFormat(unit, GenesysUom.UnitsFormat)?.label ?? ""
  }));

  return {
    type: "UseAmountFormatSelector",
    isOpen: isOpen,
    unitItems: unitItems,
    fieldName: fieldName,
    fieldGroup: fieldGroup,
    extraLabel: extraLabel,
    isUserDefined: isUserDefined,
    selectedUnit: selectedUnitString,
    precisionValues: precisionValues,
    selectedPrecision: selectedPrecision.toString(),
    translate,
    openSelector: () => ({}),
    onChangeUnit: e => {
      setIsOpen(false);
      const selectedUnit = unitItems.find(
        i => i.value === e.target.value
      )!.unit;
      onFormatChanged(selectedUnit, selectedPrecision);
    },
    onChangePrecision: e => {
      setIsOpen(false);
      onFormatChanged(selectedUnit, Number.parseInt(e.target.value, 10));
    },
    onClear: () => {
      setIsOpen(false);
      if (onFormatCleared) {
        onFormatCleared();
      }
    },
    onCancel: () => setIsOpen(false)
  };
}

function getUnits(
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  selectedUnit: Unit.Unit<any>
): ReadonlyArray<Unit.Unit<GenesysUom.Quantity.Quantity>> {
  const quantities =
    conversionParameters !== undefined
      ? QuantityConversion.getQuantitiesToConvertTo(selectedUnit.quantity)
      : [selectedUnit.quantity];

  const quantityUnits = quantities.flatMap(q =>
    UnitMap.getUnitsForQuantity(q, GenesysUom.Units)
  );
  return quantityUnits as ReadonlyArray<
    Unit.Unit<GenesysUom.Quantity.Quantity>
  >;
}
