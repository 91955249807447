import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import {
  usePropertiesSelector,
  OnPropertiesChanged
} from "@promaster-sdk/react-property-selectors";
import * as ValueSources from "@genesys/shared/lib/value-sources";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { createFilterPrettyPrint } from "@genesys/client-core/lib/filter-pretty-print";
import { H3 } from "@genesys/ui-elements";
import { Unit, Units, UnitsFormat, unitLookup } from "@genesys/uom";
import {
  compare,
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import * as SharedState from "../shared-state";
import { clientConfig } from "../config";
import { Root } from "./elements";
import { Action, State } from "./state";
import { PropertyRow } from "./components/property-row";
import { PropertyInfo, PropertyItem, RowRenderer } from "./types";

type Props = {
  readonly state: State;
  readonly productId: string;
  readonly showCodes: boolean;
  readonly isReadonly: boolean;
  readonly fieldGroup: string;
  readonly sharedState: SharedState.State;
  readonly propertiesInfo: ReadonlyArray<PropertyInfo>;
  readonly hideGroupNames?: boolean;
  readonly hidePropertyNames?: boolean;
  readonly groupFilter?: ReadonlyArray<string>;
  readonly readonlyProperties?: ReadonlyArray<string>;
  readonly customRowRenderer?: RowRenderer;
  readonly dispatch: Dispatch<Action>;
  readonly translatePropertyName?: (propertyName: string) => string;
  readonly translatePropertyValue?: (
    propertyName: string,
    value: number
  ) => string;
};

export function PropertiesSelectorView({
  state,
  showCodes,
  productId,
  isReadonly,
  fieldGroup,
  sharedState,
  groupFilter,
  propertiesInfo: pi,
  hideGroupNames = false,
  readonlyProperties = [],
  hidePropertyNames = false,
  customRowRenderer,
  dispatch,
  translatePropertyName = propertyName =>
    sharedState.translate(
      LanguageTexts.productProperty(productId, propertyName)
    ),
  translatePropertyValue = (propertyName: string, value: number) =>
    sharedState.translate(
      LanguageTexts.productPropertyValue(productId, propertyName, value)
    )
}: Props): JSX.Element {
  const propertiesInfo = pi.map(propertyInfo =>
    propertyInfo.items.length === 0
      ? propertyInfo
      : {
          ...propertyInfo,
          items: propertyInfo.items.map(item => {
            return item.image === undefined || item.image.startsWith("/assets/")
              ? item
              : {
                  ...item,
                  image: `${clientConfig.genesysBackend}${item.image}`
                };
          })
        }
  );

  const readOnlyProperties = isReadonly
    ? propertiesInfo.map(x => x.name)
    : readonlyProperties;

  const propertyFormats = sharedState.screenAmounts.getPropertyFormats(
    fieldGroup,
    state.properties
  );

  const onPropertiesChanged: OnPropertiesChanged = async (
    newSelectedProperties: PropertyValueSet.PropertyValueSet,
    changedPropertyNames: ReadonlyArray<string>
  ) => {
    const valueSourceMap = ValueSources.createValueSourcesDict(
      propertiesInfo,
      newSelectedProperties
    );
    const selectedPropertiesCheckedForValueSources =
      ValueSources.fixValueSources(
        propertiesInfo,
        valueSourceMap,
        newSelectedProperties
      );

    dispatch(
      Action.updateProperties(
        selectedPropertiesCheckedForValueSources,
        changedPropertyNames
      )
    );
  };

  const filterPrettyPrint = createFilterPrettyPrint(
    (propertyName: string) => translatePropertyName(propertyName),
    (propertyName: string, propertyValue: number) =>
      translatePropertyValue(propertyName, propertyValue),
    sharedState.translate,
    sharedState.screenAmounts.getAmountFormat,
    fieldGroup,
    propertyName =>
      QuantityConversion.createConversionParameters(
        propertiesInfo.find(p => p.name === propertyName)
          ?.conversionParameters || [],
        state.properties
      )
  );

  const getUndefinedValueItem = () => ({
    id: "",
    text: "",
    sortNo: -1,
    value: PropertyValue.fromInteger(0),
    descriptionValuesTexts: [],
    conversionParameters: undefined,
    validationFilter: PropertyFilter.Empty,
    rangeFilter: PropertyFilter.Empty
  });

  const onPropertyFormatChanged = (
    propertyName: string,
    unit: Unit.Unit<unknown>,
    decimalCount: number
  ) => {
    dispatch(
      Action.propertyFormatChanged(fieldGroup, propertyName, unit, decimalCount)
    );
  };

  const onPropertyFormatCleared = (propertyName: string) => {
    dispatch(Action.propertyFormatCleared(fieldGroup, propertyName));
  };

  const sel = usePropertiesSelector<PropertyItem, PropertyInfo>({
    showCodes,
    units: Units,
    propertyFormats,
    readOnlyProperties,
    sortValidFirst: true,
    unitsFormat: UnitsFormat,
    properties: propertiesInfo,
    lockSingleValidValue: true,
    autoSelectSingleValidValue: true,
    selectedProperties: state.properties,
    includeHiddenProperties: sharedState.debugSettings.showHiddenProperties,
    getPropertyInfo: p => p,
    getPropertyItems: p => p.items,
    getItemValue: item => item.value,
    getItemFilter: item => item.validationFilter,
    unitLookup,
    valueComparer: compare,
    onChange: onPropertiesChanged,
    filterPrettyPrint: filterPrettyPrint,
    getUndefinedValueItem: getUndefinedValueItem,
    onPropertyFormatChanged: onPropertyFormatChanged,
    onPropertyFormatCleared: onPropertyFormatCleared
  });

  const groupNames = sel.groups.reduce((soFar, current) => {
    return sel.getGroupProperties(current).length > 0 &&
      (!groupFilter ||
        groupFilter.length === 0 ||
        groupFilter.includes(current))
      ? soFar.concat([current])
      : soFar;
  }, [] as ReadonlyArray<string>);

  function GroupHeader({
    groupName
  }: {
    readonly groupName: string;
  }): JSX.Element {
    return (
      <>
        {!hideGroupNames && groupName.length > 0 && (
          <tr>
            <td></td>
            <td>
              {!hidePropertyNames && (
                <H3 color="dark" weight="bold">
                  {sharedState.translate(
                    LanguageTexts.dynamicText(
                      groupName,
                      productId.substring(0, 3)
                    )
                  )}
                </H3>
              )}
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <Root>
      <tbody>
        {groupNames.map(groupName => (
          <>
            <GroupHeader groupName={groupName} />
            {sel.getGroupProperties(groupName).map(property => (
              <PropertyRow
                sel={sel}
                key={property.name}
                property={property}
                showCodes={showCodes}
                fieldGroup={fieldGroup}
                hidePropertyNames={hidePropertyNames}
                accessToken={sharedState.accessToken}
                isReadonly={isReadonly}
                properties={state.properties}
                language={sharedState.user.settings.language}
                applicationClaims={sharedState.user.applicationClaims}
                customRowRenderer={customRowRenderer}
                translate={sharedState.translate}
                onPropertiesChanged={onPropertiesChanged}
                translatePropertyName={translatePropertyName}
                translatePropertyValue={translatePropertyValue}
                getAmountFormat={sharedState.screenAmounts.getAmountFormat}
              />
            ))}
          </>
        ))}
      </tbody>
    </Root>
  );
}
