export * from "./text-definitions";
export {
  TextDefinition,
  TranslationProps,
  Translate,
  StaticText,
  CurlyText
} from "./types";
export { translate } from "./translator";
export { getComponentLabel } from "./utils";
