export type Url = {
  readonly protocol: Protocol;
  readonly host: string;
  readonly port: number | undefined;
  readonly path: string;
  readonly query: string | undefined;
  readonly fragment: string | undefined;
};

export type Protocol = "http:" | "https:";

export function fromString(url: string): Url {
  const myUrl = new URL(url);
  if (myUrl.protocol !== "http:" && myUrl.protocol !== "https:") {
    throw new Error(`Invalid protocol for url ${myUrl.protocol}`);
  }
  const port =
    myUrl.port !== undefined && myUrl.port !== ""
      ? parseInt(myUrl.port, 10)
      : myUrl.protocol === "https:"
      ? 443
      : 80;
  return {
    protocol: myUrl.protocol,
    host: myUrl.host,
    port: port,
    path: myUrl.pathname,
    query: myUrl.search,
    fragment: myUrl.hash
  };
}
