import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { PropertyInfo } from "./types";
import { State } from "./state";

export function getSelectedProperties(
  state: State
): PropertyValueSet.PropertyValueSet {
  return state.properties;
}

export function isSelectionValid(
  state: State,
  productProperties: ReadonlyArray<PropertyInfo>
): boolean {
  return validateProductSelection(productProperties, state.properties);
}

function validateProductSelection(
  properties: ReadonlyArray<{
    readonly name: string;
    readonly quantity: string;
    readonly validationFilter: PropertyFilter.PropertyFilter;
    readonly items: ReadonlyArray<{
      readonly value: PropertyValue.PropertyValue | null | undefined;
      readonly validationFilter: PropertyFilter.PropertyFilter;
    }>;
  }>,
  selectedProperties: PropertyValueSet.PropertyValueSet
): boolean {
  const allValid = properties.every(property => {
    const selectedValue = PropertyValueSet.getValue(
      property.name,
      selectedProperties
    );

    const selectedValueItem =
      property.items &&
      property.items.find(
        value =>
          (value.value === undefined && selectedValue === undefined) ||
          (value.value && PropertyValue.equals(selectedValue, value.value))
      );

    switch (getPropertyType(property.quantity)) {
      case "integer": {
        const isValid = selectedValueItem
          ? PropertyFilter.isValid(
              selectedProperties,
              selectedValueItem.validationFilter
            )
          : false;
        return isValid;
      }
      case "amount": {
        const isValid =
          property.validationFilter &&
          PropertyFilter.isValid(selectedProperties, property.validationFilter);
        return isValid;
      }
      default: {
        return true;
      }
    }
  });

  return allValid;
}

function getPropertyType(quantity: string): PropertyValue.PropertyType {
  switch (quantity) {
    case "Text":
      return "text";
    case "Discrete":
      return "integer";
    default:
      return "amount";
  }
}
