import * as React from "react";
import {
  Dehumidifier,
  PlenumSize,
  DehumidifierInPlenum,
  Component
} from "./types";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import {
  Root,
  SizeSelectionContainer,
  DehumTable,
  PlenumTable,
  DehumHeaderContainer,
  DehumTableContainer,
  PlenumTableContainer,
  PlenumHeaderContainer,
  PlenumRow
} from "./elements";
import { OverlayLoader, P2, S2, H2 } from "@genesys/ui-elements";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { Amount, Quantity } from "@genesys/uom";
import * as GenesysPropertiesSelectorHelpers from "@genesys/client-core/lib/genesys-properties-selector-helpers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { AmountFormatSelector } from "../../amount-format-selector";
import { getValue } from "@genesys/shared/lib/product-properties";
import { getAirFlows } from "./functions";

export interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemProperties: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly components: ReadonlyArray<Component>;
  readonly isSystemLocked: boolean;
}

export function DhuSizeSelectorView({
  state,
  sharedState: { translate, screenAmounts },
  systemProperties,
  operatingCases,
  components,
  isSystemLocked,
  dispatch
}: Props): JSX.Element {
  if (!state.data) {
    return <OverlayLoader />;
  }

  const dhu = state.data.product.dhu;
  const sys = state.data.product.sys;

  const airFlows = getAirFlows(operatingCases, components);

  const dhuTranslation = GenesysPropertiesSelectorHelpers.createTranslation(
    translate,
    dhu.id
  );

  const sysTranslation = GenesysPropertiesSelectorHelpers.createTranslation(
    translate,
    sys.id
  );

  const propertyDehumidifiers = dhu.properties
    .find(p => p.name === "dhmodel")!
    .values.map(v => ({
      ...v,
      value: parseInt(v!.value!, 10),
      rangeFilter: PropertyFilter.fromString(v!.rangeFilter),
      validationFilter: PropertyFilter.fromString(v!.validationFilter)
    }))
    .filter(
      v =>
        v!.rangeFilter === undefined ||
        PropertyFilter.isValid(systemProperties, v!.rangeFilter!)
    );

  const propertyPlenumSizes = sys.properties
    .find(p => p.name === "size")!
    .values.map(v => ({
      ...v,
      value: parseInt(v!.value!, 10),
      rangeFilter: PropertyFilter.fromString(v!.rangeFilter),
      validationFilter: PropertyFilter.fromString(v!.validationFilter)
    }))
    .filter(
      v =>
        (v!.rangeFilter === undefined ||
          PropertyFilter.isValid(systemProperties, v!.rangeFilter!)) &&
        (v!.validationFilter === undefined ||
          PropertyFilter.isValid(systemProperties, v!.validationFilter!))
    );

  const dehumidifierSizes = propertyDehumidifiers.map(d => ({
    table: (dhu.minMaxFlow?.rows || [])
      .map(r => {
        const values = PropertyValueSet.fromStringOrError(
          () => PropertyValueSet.Empty,
          r.values
        );
        return {
          id: r.id,
          propertyFilter: r.propertyFilter,
          minFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
            "MinFlow",
            values
          )!,
          maxFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
            "MaxFlow",
            values
          )!
        };
      })
      .find(t =>
        PropertyFilter.isValid(
          PropertyValueSet.merge(
            PropertyValueSet.fromProperty(
              "dhmodel",
              PropertyValue.create("integer", d!.value!)
            ),
            systemProperties
          ),
          PropertyFilter.fromString(t!.propertyFilter!)!
        )
      )!,
    property: d!
  }));

  const plenumSizes = propertyPlenumSizes.map(d => ({
    table: (sys.minMaxFlow?.rows || [])
      .map(r => {
        const values = PropertyValueSet.fromStringOrError(
          () => PropertyValueSet.Empty,
          r.values
        );
        return {
          id: r.id,
          propertyFilter: r.propertyFilter,
          minFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
            "MinFlow",
            values
          )!,
          maxFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
            "MaxFlow",
            values
          )!
        };
      })
      .find(t =>
        PropertyFilter.isValid(
          PropertyValueSet.merge(
            PropertyValueSet.fromProperty(
              "size",
              PropertyValue.create("integer", d!.value!)
            ),
            systemProperties
          ),
          PropertyFilter.fromString(t!.propertyFilter!)!
        )
      )!,
    property: d!
  }));

  const dehumidifiers: Array<Dehumidifier> = dehumidifierSizes
    .filter(ds => ds.table !== undefined)
    .map(d => ({
      value: d.property.value,
      minFlow: d.table.minFlow,
      maxFlow: d.table.maxFlow,
      displayName: dhuTranslation.propertyValue("dhmodel", d.property.value)
    }));

  const plenums: Array<PlenumSize> = plenumSizes
    .filter(p => p.table !== undefined)
    .map(p => {
      const dehumifierSizes: Array<DehumidifierInPlenum> = dehumidifierSizes
        .filter(d => d.table !== undefined)
        .map(d => ({
          minFlow: d.table.minFlow,
          maxFlow: d.table.maxFlow,
          validInPlenum: PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromString("size" + "=" + p.property.value),
              systemProperties
            ),
            d.property.validationFilter!
          ),
          validForFlows: airFlows.every(
            airFlow =>
              Amount.lessOrEqualTo(d.table.minFlow, airFlow) &&
              Amount.lessOrEqualTo(airFlow, d.table.maxFlow)
          )
        }));

      const isValid =
        airFlows.every(
          airFlow =>
            Amount.lessOrEqualTo(p.table.minFlow, airFlow) &&
            Amount.lessOrEqualTo(airFlow, p.table.maxFlow)
        ) && dehumifierSizes.some(d => d.validInPlenum && d.validForFlows);

      return {
        value: p.property.value,
        displayName: sysTranslation.propertyValue("size", p.property.value),
        minFlow: p.table.minFlow,
        maxFlow: p.table.maxFlow,
        isValid: isValid,
        dehumidifiers: dehumifierSizes
      };
    });

  const fieldGroup = "SizeSelector";
  const fieldName = "Flow";
  const amountFormat = screenAmounts.getAmountFormat(
    fieldGroup,
    fieldName,
    "MassFlow"
  );

  if (!state.selectedSize && plenums.find(p => p.isValid)) {
    dispatch(
      Action.setSize(
        PropertyValueSet.getInteger("size", systemProperties) ||
          plenums.find(p => p.isValid)!.value
      )
    );
  }

  return (
    <Root>
      <SizeSelectionContainer>
        <DehumTableContainer>
          <DehumHeaderContainer>
            <H2 weight="normal" color="dark">
              {translate(LanguageTexts.dehumidifier())}
            </H2>
          </DehumHeaderContainer>
          <DehumTable>
            <thead>
              <tr>
                <th></th>
                {dehumidifiers.map(d => (
                  <th key={d.value}>
                    <S2 weight="normal" color="dark">
                      {d.displayName}
                    </S2>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <AmountFormatSelector
                    type="AmountFormatSelectorProps"
                    fieldName={fieldName}
                    fieldGroup={fieldGroup}
                    amountFormat={amountFormat}
                    conversionParameters={undefined}
                    translate={translate}
                    onFormatCleared={() =>
                      dispatch(Action.FormatCleared(fieldGroup, fieldName))
                    }
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.FormatChanged(
                          fieldGroup,
                          fieldName,
                          unit,
                          decimalCount
                        )
                      )
                    }
                  />
                </td>
                {dehumidifiers.map(d => (
                  <td key={d.value}>
                    <P2 weight="normal" color="secondary">
                      {getValue(
                        PropertyValue.fromAmount(d.minFlow),
                        amountFormat
                      )}
                      {" - "}
                      {getValue(
                        PropertyValue.fromAmount(d.maxFlow),
                        amountFormat
                      )}
                    </P2>
                  </td>
                ))}
              </tr>
            </tbody>
          </DehumTable>
        </DehumTableContainer>

        <PlenumTableContainer>
          <PlenumHeaderContainer>
            <H2 weight="normal" color="dark">
              {translate(LanguageTexts.plenum())}
            </H2>
          </PlenumHeaderContainer>
          <PlenumTable>
            <tbody>
              {plenums.map(p => (
                <PlenumRow
                  key={p.value}
                  isSelected={state.selectedSize === p.value}
                  isValid={p.isValid}
                  onClick={() => {
                    if (!isSystemLocked && p.isValid) {
                      dispatch(Action.setSize(p.value));
                    }
                  }}
                >
                  <td>
                    <P2 weight="normal" color="secondary">
                      {p.displayName}
                      {" < "}
                      {getValue(
                        PropertyValue.fromAmount(p.maxFlow),
                        amountFormat
                      )}
                    </P2>
                  </td>
                  {p.dehumidifiers.map((d, ix) => (
                    <td key={ix}>
                      {" "}
                      {d.validInPlenum && d.validForFlows
                        ? "●"
                        : d.validInPlenum
                        ? "○"
                        : ""}
                    </td>
                  ))}
                </PlenumRow>
              ))}
            </tbody>
          </PlenumTable>
        </PlenumTableContainer>
      </SizeSelectionContainer>
    </Root>
  );
}
