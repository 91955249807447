import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import * as ClimateSelector from "../climate-selector";
import * as SharedState from "../shared-state";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import { Cmd } from "@typescript-tea/core";
import { promiseCmd } from "../promise-effect-manager";
import { OperatingCase, PropertiesSelectorState, ProductData } from "./types";
import * as PropertiesSelector from "../properties-selector";
import { runValueSources } from "./value-sources";
import * as Guid from "@genesys/shared/lib/guid";
import * as GraphQlTypes from "../graphql-types";
import { ConfiguratorAction } from "../system-configurator/shell-system-configurator/configurator-actions";
import { Quantity, Unit } from "@genesys/uom";
import {
  convertMoistureLoadToOPC,
  mapQueryResultToOpc
} from "../moisture-load-calculation";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";
import {
  moistureLoadOperatingCaseQuery,
  moistureLoadSystemSearch,
  queryMissingClimateLocation
} from "./queries";

export type State = {
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly initialClimateSettings: PropertyValueSet.PropertyValueSet;
  readonly climateSelectorState: ClimateSelector.State | undefined;
  readonly isOpenClimateSelector: boolean;
  readonly errorMessage: string | undefined;
  readonly loading: boolean;
  readonly moistureLoadSearchInput: string;
  readonly currentMoistureLoadId: string | undefined;
  readonly valuesHasChanged: boolean;
  readonly productData: ProductData;
  readonly moistureLoadSystemSearch:
    | GraphQlTypes.MoistureLoadSystemSearchQuery
    | undefined;
  readonly moistureLoadQuery:
    | GraphQlTypes.MoistureLoadOperatingCaseQuery
    | undefined;
  readonly propertiesSelectorStates: ReadonlyArray<{
    readonly id: string;
    readonly state: PropertiesSelector.State;
  }>;
};

export const init = (
  climateSettings: PropertyValueSet.PropertyValueSet,
  operatingCases: ReadonlyArray<OperatingCase>,
  sharedState: SharedState.State,
  productData: ProductData,
  moistureLoadId?: string
): [State, Cmd<Action>?] => {
  return [
    {
      climateSettings: climateSettings,
      initialClimateSettings: climateSettings,
      climateSelectorState: undefined,
      valuesHasChanged: false,
      loading: !!moistureLoadId || PropertyValueSet.isEmpty(climateSettings),
      isOpenClimateSelector: false,
      errorMessage: undefined,
      moistureLoadQuery: undefined,
      moistureLoadSystemSearch: undefined,
      currentMoistureLoadId: moistureLoadId,
      productData: productData,
      moistureLoadSearchInput: "",
      propertiesSelectorStates: operatingCases
        .map(opc => {
          return { id: opc.id, state: PropertiesSelector.init(opc.settings) };
        })
        .sort(
          (a, b) =>
            PropertyValueSet.getInteger("sortno", a.state.properties)! -
            PropertyValueSet.getInteger("sortno", b.state.properties)!
        )
    },

    promiseCmd(
      async () => {
        const climateQuery = PropertyValueSet.isEmpty(climateSettings)
          ? sharedState.graphQL.queryProduct<
              GraphQlTypes.OperatingCaseMissingClimateQuery,
              GraphQlTypes.MoistureLoadCalculationProductQueryVariables
            >(queryMissingClimateLocation, {
              locationId: ClimateSelector.defaultWmo
            })
          : Promise.resolve(undefined);

        const moistureLoadQuery = moistureLoadId
          ? sharedState.graphQL.queryUser<
              GraphQlTypes.MoistureLoadOperatingCaseQuery,
              GraphQlTypes.MoistureLoadOperatingCaseQueryVariables
            >(moistureLoadOperatingCaseQuery, {
              id: moistureLoadId
            })
          : Promise.resolve(undefined);

        const [moistureLoadResult, climateResult] = await Promise.all([
          moistureLoadQuery,
          climateQuery
        ]);

        return { moistureLoadResult, climateResult };
      },

      res =>
        Action.initailQueryRecived(res.moistureLoadResult, res.climateResult)
    )
  ];
};

export const Action = ctorsUnion({
  dispatchClimateSelector: (action: ClimateSelector.Action) => ({ action }),
  onOpenClimateSelector: () => ({}),
  onCloseClimateSelector: () => ({}),
  clearSearchResults: () => ({}),
  fetchMoistureLoad: (id: string | undefined) => ({ id }),
  moistureLoadItemReceived: (
    data: GraphQlTypes.MoistureLoadOperatingCaseQuery
  ) => ({ data }),
  searchMoistureLoads: () => ({}),
  setValueHasChanged: (value: boolean) => ({ value }),
  setLoadingState: (value: boolean) => ({ value }),
  moistureLoadSearchQueryRecieved: (
    data: GraphQlTypes.MoistureLoadSystemSearchQuery
  ) => ({ data }),
  initailQueryRecived: (
    moistureLoadResult: GraphQlTypes.MoistureLoadOperatingCaseQuery | undefined,
    climateResult: GraphQlTypes.OperatingCaseMissingClimateQuery | undefined
  ) => ({ moistureLoadResult, climateResult }),
  onMoistureLoadSearchInputChange: (newValue: string) => ({ newValue }),
  onClimateSelectorChange: (
    climateSettings: PropertyValueSet.PropertyValueSet,
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({ climateSettings, productData: productDataProperties }),
  dispatchPropertiesSelector: (
    id: string,
    action: PropertiesSelector.Action,
    productData: ProductData
  ) => ({ id, action, productData }),
  onAddNewOperatingCase: (
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({
    productDataProperties
  }),
  onDeleteOperatingCase: (
    operatingCaseId: string,
    productDataProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) => ({
    operatingCaseId,
    productDataProperties
  }),
  formatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  formatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  setNewMoistureLoadId: (id: string | undefined) => ({ id })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "clearSearchResults": {
      return [
        {
          ...state,
          moistureLoadSearchInput: "",
          errorMessage: undefined,
          moistureLoadSystemSearch: undefined
        }
      ];
    }

    case "fetchMoistureLoad": {
      const fecth = !!action.id;

      if (fecth) {
        return [
          {
            ...state
          },
          sharedState.graphQL.queryUserCmd<
            GraphQlTypes.MoistureLoadOperatingCaseQuery,
            GraphQlTypes.MoistureLoadOperatingCaseQueryVariables,
            Action
          >(
            moistureLoadOperatingCaseQuery,
            {
              id: action.id!
            },
            Action.moistureLoadItemReceived
          )
        ];
      } else {
        return [
          {
            ...state,
            errorMessage: undefined,
            moistureLoadQuery: undefined,
            valuesHasChanged: true
          }
        ];
      }
    }

    case "moistureLoadItemReceived": {
      const moistureLoadRes = action.data;
      const status = moistureLoadRes.user.moistureLoad?.status;

      if (status !== MoistureLoadStatus.LockSuccess) {
        return [
          {
            ...state,
            errorMessage: "moisture load is not locked, pls lock first"
          }
        ];
      }
      const moistureLoadOpcase = mapQueryResultToOpc(
        {
          moistureLoadResult:
            moistureLoadRes.user.moistureLoad!.moistureLoadResult!,
          moistureloadInput:
            moistureLoadRes.user.moistureLoad!.moistureloadInput!
        },
        sharedState.translate
      );

      if (moistureLoadOpcase.opcResult.length === 0) {
        return [
          {
            ...state,
            errorMessage: "No operating cases found in moisture load"
          }
        ];
      }

      const climateSettingsFromMl = PropertyValueSet.fromString(
        moistureLoadOpcase.climateSettings
      );

      const newOperatingCases = convertMoistureLoadToOPC(
        state.propertiesSelectorStates.map(x => x.state.properties),
        moistureLoadOpcase.opcResult,
        state.productData.properties
      );

      return [
        {
          ...state,
          moistureLoadQuery: action.data,
          valuesHasChanged: true,
          climateSettings: climateSettingsFromMl,
          propertiesSelectorStates: newOperatingCases
            .map(opc => {
              return {
                id: opc.id.value,
                state: PropertiesSelector.init(opc.settings)
              };
            })
            .sort(
              (a, b) =>
                PropertyValueSet.getInteger("sortno", a.state.properties)! -
                PropertyValueSet.getInteger("sortno", b.state.properties)!
            )
        }
      ];
    }

    case "dispatchClimateSelector": {
      if (!state.climateSelectorState) {
        return [state];
      }

      const [climateSelectorState, climateSelectorCmd, sharedStateAction] =
        ClimateSelector.update(
          action.action,
          state.climateSelectorState,
          sharedState
        );

      return [
        { ...state, climateSelectorState: climateSelectorState },
        Cmd.map(Action.dispatchClimateSelector, climateSelectorCmd),
        sharedStateAction
      ];
    }
    case "onOpenClimateSelector": {
      if (state.climateSelectorState !== undefined) {
        return [{ ...state, isOpenClimateSelector: true }];
      }

      const [climateSelectorState, climateSelectorCmd] = ClimateSelector.init(
        state.climateSettings,
        sharedState
      );

      return [
        {
          ...state,
          climateSelectorState: climateSelectorState,
          isOpenClimateSelector: true
        },
        Cmd.map(Action.dispatchClimateSelector, climateSelectorCmd)
      ];
    }
    case "onCloseClimateSelector": {
      return [
        {
          ...state,
          isOpenClimateSelector: false
        }
      ];
    }

    case "onMoistureLoadSearchInputChange": {
      return [
        {
          ...state,
          moistureLoadSearchInput: action.newValue
        }
      ];
    }

    case "searchMoistureLoads": {
      return [
        {
          ...state,
          errorMessage: undefined
        },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.MoistureLoadSystemSearchQuery,
          GraphQlTypes.MoistureLoadSystemSearchQueryVariables,
          Action
        >(
          moistureLoadSystemSearch,
          {
            searchText: state.moistureLoadSearchInput
          },
          Action.moistureLoadSearchQueryRecieved
        )
      ];
    }

    case "moistureLoadSearchQueryRecieved": {
      return [
        {
          ...state,
          moistureLoadSystemSearch: action.data
        }
      ];
    }

    case "initailQueryRecived": {
      const initialClimateSettings = action.climateResult
        ? ClimateSelector.getDefaultClimateSettings(
            sharedState,
            PropertyValueSet.Empty,
            action.climateResult.product.dataPointsForLocationId!,
            action.climateResult.product.countries
          )
        : state.climateSettings;

      const defaultProperties = ProductProperties.createDefaultProperties(
        state.productData.properties.map(x => ({
          ...x,
          values: x.items.map(v => ({
            id: v.id,
            value: PropertyValue.toString(v.value)
          }))
        }))
      );

      const maybeNewPropertiesSelectorStates: PropertiesSelectorState[] =
        action.climateResult
          ? state.propertiesSelectorStates.map(propertySelectorState => {
              return {
                id: propertySelectorState.id,
                state: PropertiesSelector.init(
                  runValueSources(
                    PropertyValueSet.merge(
                      defaultProperties,
                      PropertiesSelector.getSelectedProperties(
                        propertySelectorState.state
                      )
                    ),
                    state.propertiesSelectorStates.map(pss =>
                      PropertiesSelector.getSelectedProperties(pss.state)
                    ),
                    initialClimateSettings,
                    state.productData.properties
                  )
                )
              };
            })
          : [...state.propertiesSelectorStates];

      return [
        {
          ...state,
          loading: false,
          propertiesSelectorStates: maybeNewPropertiesSelectorStates,
          valuesHasChanged: !!action.climateResult,
          climateSettings: initialClimateSettings,
          moistureLoadQuery: action.moistureLoadResult
        }
      ];
    }

    case "setValueHasChanged": {
      return [
        {
          ...state,
          valuesHasChanged: action.value
        }
      ];
    }

    case "setLoadingState": {
      return [
        {
          ...state,
          loading: action.value
        }
      ];
    }
    case "onClimateSelectorChange": {
      const newPropertiesSelectorStates: PropertiesSelectorState[] =
        state.propertiesSelectorStates.map(propertySelectorState => {
          return {
            id: propertySelectorState.id,
            state: PropertiesSelector.init(
              runValueSources(
                PropertiesSelector.getSelectedProperties(
                  propertySelectorState.state
                ),
                state.propertiesSelectorStates.map(pss =>
                  PropertiesSelector.getSelectedProperties(pss.state)
                ),
                action.climateSettings,
                action.productData
              )
            )
          };
        });

      const location = ClimateSelector.getCurrentLocationData(
        state.climateSelectorState!,
        sharedState
      );

      const isManualDataSource = !PropertyValueSet.hasProperty(
        "wmo",
        action.climateSettings
      );

      const manualData = isManualDataSource
        ? PropertyValueSet.toString(action.climateSettings)
        : "";
      const climateDataSource = isManualDataSource ? "Manual" : "ASHRAE";

      return [
        {
          ...state,
          climateSettings: action.climateSettings,
          propertiesSelectorStates: newPropertiesSelectorStates,
          valuesHasChanged: !PropertyValueSet.equals(
            state.initialClimateSettings,
            action.climateSettings
          )
        },
        undefined,
        SharedState.Action.setUserSettingsClimate({
          countryName: location.countryName,
          regionName: location.regionName,
          locationId: location.locationId,
          heatingDataType: PropertyValueSet.getText(
            "climateheatingdatatype",
            action.climateSettings
          ),
          coolingDataType: PropertyValueSet.getText(
            "climatecoolingdatatype",
            action.climateSettings
          ),
          heatingOccurence: getAnnualOccurenceAsDpValue(
            PropertyValueSet.getText(
              "heatingannualoccurence",
              action.climateSettings
            )!
          ),
          coolingOccurence: getAnnualOccurenceAsDpValue(
            PropertyValueSet.getText(
              "coolingannualoccurence",
              action.climateSettings
            )!
          ),
          climateDataSource: climateDataSource,
          manualData: manualData
        })
      ];
    }
    case "dispatchPropertiesSelector": {
      const dispatchedSelector = state.propertiesSelectorStates.find(
        pss => pss.id === action.id
      );

      if (!dispatchedSelector) {
        return [state];
      }

      const getUpdatedPropertyValueSet = (
        pvs: PropertyValueSet.PropertyValueSet,
        operatingsCases: PropertyValueSet.PropertyValueSet[]
      ) =>
        runValueSources(
          pvs,
          operatingsCases,
          state.climateSettings,
          action.productData.properties
        );

      const [
        newDispatchedSelectorState,
        newDispatchedSelectorCmd,
        sharedAction
      ] = PropertiesSelector.update(
        action.action,
        dispatchedSelector.state,
        sharedState,
        "SkipCalculateProperties",
        pvs =>
          getUpdatedPropertyValueSet(
            pvs,
            state.propertiesSelectorStates.map(pss =>
              PropertiesSelector.getSelectedProperties(pss.state)
            )
          )
      );

      const newOperatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet> =
        state.propertiesSelectorStates.map(p =>
          p.id === action.id
            ? PropertiesSelector.getSelectedProperties(
                newDispatchedSelectorState
              )
            : PropertiesSelector.getSelectedProperties(p.state)
        );

      const newPropertiesSelectorStates = state.propertiesSelectorStates.map(
        p =>
          p.id === action.id
            ? { id: action.id, state: newDispatchedSelectorState }
            : {
                id: p.id,
                state: PropertiesSelector.init(
                  runValueSources(
                    PropertiesSelector.getSelectedProperties(p.state),
                    newOperatingCases,
                    state.climateSettings,
                    action.productData.properties
                  )
                )
              }
      );

      return [
        {
          ...state,
          valuesHasChanged: true,
          propertiesSelectorStates: newPropertiesSelectorStates
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchPropertiesSelector(
              action.id,
              cmdAction,
              action.productData
            ),
          newDispatchedSelectorCmd
        ),
        sharedAction
      ];
    }

    case "onAddNewOperatingCase": {
      const operatingCase: OperatingCase = {
        id: Guid.guidToString(Guid.createGuid()),
        settings: runValueSources(
          PropertyValueSet.merge(
            PropertyValueSet.setText(
              "casename",
              "",
              PropertyValueSet.fromProperty(
                "sortno",
                PropertyValue.fromInteger(state.propertiesSelectorStates.length)
              )
            ),
            PropertyValueSet.filter(
              kvp => !kvp.key.startsWith("source_"),
              PropertiesSelector.getSelectedProperties(
                state.propertiesSelectorStates[0].state
              )
            )
          ),
          state.propertiesSelectorStates.map(pss =>
            PropertiesSelector.getSelectedProperties(pss.state)
          ),
          state.climateSettings,
          action.productDataProperties
        )
      };

      const newPropertiesSelectorStates = state.propertiesSelectorStates.concat(
        [
          {
            id: operatingCase.id,
            state: PropertiesSelector.init(operatingCase.settings)
          }
        ]
      );

      return [
        {
          ...state,
          propertiesSelectorStates: newPropertiesSelectorStates,
          valuesHasChanged: true
        }
      ];
    }
    case "onDeleteOperatingCase": {
      const newOperatingCases = state.propertiesSelectorStates
        .filter(pps => pps.id !== action.operatingCaseId)
        .map(pps => pps.state.properties);
      const newPropertiesSelectorStates: ReadonlyArray<{
        readonly id: string;
        readonly state: PropertiesSelector.State;
      }> = state.propertiesSelectorStates
        .filter(pps => pps.id !== action.operatingCaseId)
        .map((pps, ix) => ({
          id: pps.id,
          state: {
            ...pps.state,
            selectedProperties: runValueSources(
              PropertyValueSet.merge(
                PropertyValueSet.fromProperty(
                  "sortno",
                  PropertyValue.fromInteger(ix)
                ),
                pps.state.properties
              ),
              newOperatingCases,
              state.climateSettings,
              action.productDataProperties
            )
          }
        }));
      return [
        {
          ...state,
          propertiesSelectorStates: newPropertiesSelectorStates,
          valuesHasChanged: true
        }
      ];
    }
    case "formatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "formatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    case "setNewMoistureLoadId": {
      return [{ ...state, currentMoistureLoadId: action.id }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getOperatingCases(state: State) {
  const newOperatingCases: OperatingCase[] = state.propertiesSelectorStates.map(
    pps => {
      return {
        id: pps.id,
        settings: PropertiesSelector.getSelectedProperties(pps.state)
      };
    }
  );
  return newOperatingCases;
}

export function getClimateSettings(state: State) {
  return state.climateSettings;
}

export function isClimateSelectorOpen(state: State) {
  return state.isOpenClimateSelector;
}

export function getConfigurationActions(
  state: State,
  type: "save" | "saveAndCalculate"
): ReadonlyArray<ConfiguratorAction> {
  const operatingCases = getOperatingCases(state);
  let climateSettings = getClimateSettings(state);

  const isManualDataSource =
    PropertyValueSet.getText("climateDataSource", climateSettings) === "Manual";
  if (isManualDataSource) {
    const manualClimateSettings = PropertyValueSet.fromString(
      PropertyValueSet.getText("manualData", climateSettings)!
    );
    climateSettings = PropertyValueSet.merge(
      manualClimateSettings,
      climateSettings
    );
  }

  return [
    ConfiguratorAction.saveOperatingCases(
      climateSettings,
      operatingCases,
      type === "saveAndCalculate",
      state.currentMoistureLoadId
    )
  ];
}

function getAnnualOccurenceAsDpValue(pvOccurance: string) {
  switch (pvOccurance) {
    case "0.4 %": {
      return "04";
    }
    case "1 %": {
      return "10";
    }
    case "2 %": {
      return "20";
    }
    case "99.6 %": {
      return "996";
    }
    case "99 %": {
      return "99";
    }
    case "04":
    case "10":
    case "20":
    case "996":
    case "99": {
      return pvOccurance;
    }
    default: {
      {
        throw new Error("pvOccurance string not supported: " + pvOccurance);
      }
    }
  }
}
//tslint:disable-next-line
