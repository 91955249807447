import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { OverlayLoader, H1 } from "@genesys/ui-elements";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { DhuSizeSelectorView } from "../../../../size-selectors/dhu-size-selector";

const Root = styled.div`
  h1 {
    padding-bottom: 40px;
  }
`;

export function DhuSizeSelectorStepView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  if (!state) {
    return <OverlayLoader />;
  }

  const validTemplateComponents = state.template.components.filter(tc =>
    PropertyFilter.isValid(state.newProperties, tc.propertyFilter)
  );

  const systemProperties = PropertyValueSet.merge(
    state.newProperties,
    validTemplateComponents.find(t => t.productId.endsWith("SYS"))
      ?.properties || PropertyValueSet.Empty
  );

  return (
    <Root>
      <H1 weight="normal">
        {sharedState.translate(LanguageTexts.selectSystemSize())}
      </H1>

      <DhuSizeSelectorView
        state={state.dhuSizeSelectorState}
        sharedState={sharedState}
        dispatch={Dispatch.map(Action.dispatchDhuSizeSelectorActions, dispatch)}
        systemProperties={systemProperties}
        components={validTemplateComponents}
        operatingCases={state.operatingCases}
        isSystemLocked={false}
      />
    </Root>
  );
}
