import { isDesktop } from "react-device-detect";
import { hasPermissionForMlc } from "../moisture-load-calculation";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../shared-state";
import { Permisssions } from "./types";

export function getPermissions(sharedState: SharedState.State): Permisssions {
  const isDeveloper = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.developer
  );

  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  const canSeePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );

  const canSeeMoistureload = hasPermissionForMlc(
    sharedState.user.applicationClaims
  );

  const canSeeSystemSelectionGuide =
    (isDeveloper ||
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseSystemSelectionGuide
      )) &&
    isDesktop;

  const canSeeMRC =
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canSeeMRC
    ) && isDesktop;

  const canUseDryCap =
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canSeeDryCap
    ) !== undefined;

  return {
    canUsePricing,
    canSeePricing,
    canSeeMoistureload,
    canSeeSystemSelectionGuide,
    canSeeMRC,
    canUseDryCap
  };
}
