import { PropertyValueSet } from "@genesys/property";

export const operatingCasesData = [
  {
    id: "0",
    settings: PropertyValueSet.fromString(
      'sortno=0;atmosphericpressure=101325:Pascal;casename="Summer";outdoordata=0;outdoorairtemperature=20:Celsius;outdoorairhumidity=10:GramPerKilogram;returnairtemperature=20:Celsius;returnairhumidity=10:GramPerKilogram;customairtemperature=20:Celsius;customairhumidity=10:GramPerKilogram;processinletexternalstatic=0:Pascal;processoutletexternalstatic=300:Pascal;reactinletexternalstatic=0:Pascal;reactoutletexternalstatic=300:Pascal;supplyoutletairflow=3000:StandardCubicMeterPerHour;supplytargethumidity=3:GramPerKilogram'
    )
  },
  {
    id: "1",
    settings: PropertyValueSet.fromString(
      'sortno=1;atmosphericpressure=101325:Pascal;casename="Winter";outdoordata=1;outdoorairtemperature=20:Celsius;outdoorairhumidity=10:GramPerKilogram;returnairtemperature=20:Celsius;returnairhumidity=10:GramPerKilogram;customairtemperature=20:Celsius;customairhumidity=10:GramPerKilogram;processinletexternalstatic=0:Pascal;processoutletexternalstatic=300:Pascal;reactinletexternalstatic=0:Pascal;reactoutletexternalstatic=300:Pascal;supplyoutletairflow=3000:StandardCubicMeterPerHour;supplytargethumidity=3:GramPerKilogram'
    )
  }
];
