import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createPropertyGroupsSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps) {
  const svgString = `
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>715C1BF8-8A18-4D5E-9080-F179EE1E6F45</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-67.000000, -2182.000000)">
            <g id="Icons/System-Menu-Items/Property-Groups" transform="translate(72.000000, 2185.000000)">
                <g id="Rectangle-Copy-20">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <g id="Group-39" transform="translate(13.000000, 12.000000)">
                    <path d="M28.7599999,0.240000055 L28.7590001,5.23900005 L33.7599999,5.24000005 L33.7599999,33.7599999 L5.24000005,33.7599999 L5.23900005,28.7590001 L0.240000055,28.7599999 L0.240000055,0.240000055 L28.7599999,0.240000055 Z M32.2400001,6.759 L28.759,6.759 L28.7599999,28.7599999 L6.759,28.759 L6.759,32.2400001 L32.2400001,32.2400001 L32.2400001,6.759 Z M27.24,1.759 L1.759,1.759 L1.759,27.24 L27.24,27.24 L27.24,1.759 Z M6.25999995,17.5 L6.25999995,24.5 L4.74000005,24.5 L4.74000005,17.5 L6.25999995,17.5 Z M24.2599999,17.5 L24.2599999,24.5 L22.7400001,24.5 L22.7400001,17.5 L24.2599999,17.5 Z M12.2599999,20.5 L12.2599999,24.5 L10.7400001,24.5 L10.7400001,20.5 L12.2599999,20.5 Z M18.2599999,20.5 L18.2599999,24.5 L16.7400001,24.5 L16.7400001,20.5 L18.2599999,20.5 Z M24.2599999,4.5 L24.2599999,11.5 L22.7400001,11.5 L22.7400001,4.5 L24.2599999,4.5 Z M6.25999995,4.5 L6.25999995,11.5 L4.74000005,11.5 L4.74000005,4.5 L6.25999995,4.5 Z M18.2599999,4.5 L18.2599999,8.5 L16.7400001,8.5 L16.7400001,4.5 L18.2599999,4.5 Z M12.2599999,4.5 L12.2599999,8.5 L10.7400001,8.5 L10.7400001,4.5 L12.2599999,4.5 Z" id="Shape" fill="${color}" fill-rule="nonzero"></path>
                    <g id="Group-40" transform="translate(5.000000, 17.000000)"></g>
                    <g id="Group-40" transform="translate(14.500000, 8.000000) rotate(-180.000000) translate(-14.500000, -8.000000) translate(5.000000, 4.000000)"></g>
                </g>
            </g>
        </g>
    </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
