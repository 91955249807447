import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const deleteLabelMutation = gql`
  mutation DeleteLabel($labelId: ID!) {
    deleteLabel(labelId: $labelId) {
      id
      labels {
        ...CompleteLabel
      }
    }
  }
  ${Fragments.completeLabel}
`;
