import * as React from "react";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import { Amount, Quantity, Format } from "@genesys/uom";
import { CaseType } from "@genesys/shared/lib/enums/case-type";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as GraphQlTypes from "../../../../graphql-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as System from "../../../system";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import { AmountFormatSelector } from "../../../../amount-format-selector";

export interface CaseResult {
  readonly id: string;
  readonly operatingCaseSortNo: number;
  readonly operatingCaseId: string;
  readonly results: PropertyValueSet.PropertyValueSet;
  readonly resultSortNo: number;
  readonly isDesignCase: boolean;
  readonly displayName: string;
  readonly caseType: CaseType;
  readonly calculationType: GraphQlTypes.ComponentCalculationType;
}

export interface SystemTypeResults {
  readonly id: string;
  readonly resultName: string;
  readonly quantityConversionParams: ReadonlyArray<string>;
}

interface SingleResult {
  readonly type: "single";
  readonly result: PropertyValueSet.PropertyValueSet;
  readonly conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
}

interface MultipleResult {
  readonly type: "multiple";
  readonly result: ReadonlyArray<CaseResult>;
  readonly resultConversionParametersMapByCaseResultId: {
    readonly [caseResultId: string]: {
      readonly [key: string]:
        | QuantityConversion.ConversionParameters
        | undefined;
    };
  };
}

interface ReportTechnicalDataOpCaseRow {
  readonly id: string;
  readonly min_Max?: string | undefined;
  readonly result?: string | undefined;
  readonly propertyFilter?: string | undefined | null;
}

interface ScreenRow {
  readonly id?: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}

interface ScreenResultSummaryCaseOrderRow {
  readonly calculationType: string;
  readonly filter: string;
}

export const knownSoundResultKeys = new Set([
  "InletSoundPowerLevels",
  "OutletSoundPowerLevels",
  "SoundPowerLevelsInlet",
  "SoundPowerLevelsOutlet",
  "SoundPowerToDuct",
  "SoundPowerToSurrounding",
  "SoundPressureToSurrounding1m",
  "SoundPressureToSurrounding3m",
  "SoundPressureToSurrounding6m",
  "SoundAttenuation",
  "RegeneratedSoundPowerLevels"
]);

export function getResultSummaryTabCaseResults(
  translate: LanguageTexts.Translate,
  operatingCases: ReadonlyArray<System.OperatingCase>,
  orderRows: ReadonlyArray<ScreenResultSummaryCaseOrderRow> | null | undefined,
  componentId: string,
  applicationClaims: Authorization.ApplicationClaims
): ReadonlyArray<CaseResult> {
  const designCaseResult = getCaseTypeResults(
    translate,
    operatingCases,
    componentId,
    oc => oc.caseType === CaseType.Design
  )
    .concat()
    .sort(sorter);

  const otherCaseResult = getCaseTypeResults(
    translate,
    operatingCases,
    componentId,
    oc => oc.caseType !== CaseType.Design
  )
    .concat()
    .sort(sorter);

  const calculationTypesToRemove = getCalculationTypesToRemove(
    orderRows,
    applicationClaims
  );

  const defaultSorting = [...designCaseResult, ...otherCaseResult].filter(
    ds =>
      !calculationTypesToRemove.includes(
        getFormatedCalculationType(ds.calculationType)
      )
  );

  if (!orderRows || orderRows.length === 0) {
    return defaultSorting;
  }

  const ordered = new Set<CaseResult>();
  for (const orderRow of orderRows) {
    if (!orderRow.calculationType) {
      continue;
    }

    for (const caseResult of defaultSorting
      .filter(cr =>
        isSameCalculcationType(
          cr.calculationType,
          orderRow.calculationType || ""
        )
      )
      .sort(sorter)) {
      ordered.add(caseResult);
    }
  }

  // Add the rest
  for (const caseResult of defaultSorting) {
    ordered.add(caseResult);
  }

  return Array.from(ordered.values());
}

export function groupScreenRows(
  rows: ReadonlyArray<ScreenRow>
): Map<string, ReadonlyArray<ScreenRow>> {
  const map = new Map<string, Array<ScreenRow>>();
  for (const row of rows) {
    const groupName = row.groupName || "";
    if (!map.has(groupName)) {
      map.set(groupName, []);
    }
    const group = map.get(groupName)!;
    group.push(row);
  }

  return map;
}

function isSameCalculcationType(
  calculationType: GraphQlTypes.ComponentCalculationType,
  stringCalculcationType: string
): boolean {
  const convertedToPascalCase = calculationType
    .toLowerCase()
    .replace(/([a-z]+)_?/g, (_, g1) => g1[0].toUpperCase() + g1.substr(1));

  return convertedToPascalCase === stringCalculcationType;
}

function getCaseTypeResults(
  translate: LanguageTexts.Translate,
  operatingCases: ReadonlyArray<System.OperatingCase>,
  componentId: string,
  filter: (oc: { readonly caseType: number }) => boolean
): ReadonlyArray<CaseResult> {
  return operatingCases
    .filter(filter)
    .reduce((soFar: Array<CaseResult>, current) => {
      const componentResults = current.results
        .filter(r => r.componentId === componentId)
        .map(r => {
          const caseResult: CaseResult = {
            id: r.id,
            operatingCaseId: current.id,
            operatingCaseSortNo: current.sortNo,
            results:
              PropertyValueSet.fromString(r.settings || "") ||
              PropertyValueSet.Empty,
            isDesignCase: r.isDesignCase,
            resultSortNo: r.sortNo,
            caseType: current.caseType,
            calculationType: r.calculationType,
            displayName:
              r.calculationType ===
              GraphQlTypes.ComponentCalculationType.SYSTEM_SIMULATION
                ? current.customCaseName ||
                  translate(LanguageTexts.operatingCaseName(current.caseName))
                : translate(
                    LanguageTexts.componentCalculationType(r.calculationType)
                  )
          };
          return caseResult;
        });
      soFar.push(...componentResults);
      return soFar;
    }, []);
}

export function prepareRows(
  screenRows: ReadonlyArray<ScreenRow>,
  productId: string,
  sharedState: SharedState.State,
  caseResult: SingleResult | MultipleResult,
  dispatch: Dispatch<Action>,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  applicationClaims: Authorization.ApplicationClaims
): ReadonlyArray<{
  readonly name: string;
  readonly amountFormatSelector?: () => JSX.Element | null;
  readonly isHidden: boolean;
  readonly results: string[];
}> {
  const rows: Array<{
    readonly name: string;
    readonly amountFormatSelector?: () => JSX.Element | null;
    readonly results: string[];
    readonly isHidden: boolean;
  }> = [];
  for (const sr of screenRows) {
    const resultKey = sr.name || "";
    const getFieldGroupFieldNameResult = ScreenAmounts.getFieldGroupFieldName(
      productId,
      resultKey
    );

    let propertyValue: PropertyValue.PropertyValue | undefined = undefined;

    if (getFieldGroupFieldNameResult === undefined) {
      continue;
    }

    const finalResults: Array<string> = [];
    let amountFormatSelector: () => JSX.Element | null = () => null;

    const isHidden = !(
      PropertyFilterHelpers.isValid(
        sr.propertyFilter,
        PropertyFilter.Empty,
        selectedProperties
      ) &&
      Authorization.checkClaimFilter(
        applicationClaims,
        PropertyFilterHelpers.createPropertyFilter(
          sr.claimFilter,
          PropertyFilter.Empty
        )
      )
    );

    const { fieldGroup, fieldName } = getFieldGroupFieldNameResult;

    if (caseResult.type === "single") {
      propertyValue = PropertyValueSet.get(resultKey, caseResult.result);
      if (!propertyValue || getFieldGroupFieldNameResult === undefined) {
        continue;
      }
      const result = calculateResult(
        propertyValue,
        resultKey,
        fieldGroup,
        fieldName,
        caseResult.conversionParametersMap,
        sharedState
      );

      const amountFormat = getAnountFormat(
        propertyValue,
        fieldGroup,
        fieldName,
        sharedState
      );

      amountFormatSelector = getAmountFormatSelector(
        amountFormat,
        fieldGroup,
        fieldName,
        caseResult.conversionParametersMap[resultKey],
        sharedState.translate,
        dispatch
      );
      finalResults.push(result);
    }
    let jump: boolean = false;
    if (caseResult.type === "multiple") {
      for (const cr of caseResult.result) {
        jump = false;
        propertyValue = PropertyValueSet.get(resultKey, cr.results);
        if (getFieldGroupFieldNameResult === undefined) {
          jump = true;
          continue;
        }

        if (!propertyValue) {
          finalResults.push("");
          continue;
        }
        const result = calculateResult(
          propertyValue,
          resultKey,
          fieldGroup,
          fieldName,
          caseResult.resultConversionParametersMapByCaseResultId[cr.id],
          sharedState
        );
        const amountFormat = getAnountFormat(
          propertyValue,
          fieldGroup,
          fieldName,
          sharedState
        );
        amountFormatSelector = getAmountFormatSelector(
          amountFormat,
          fieldGroup,
          fieldName,
          caseResult.resultConversionParametersMapByCaseResultId[cr.id][
            resultKey
          ],
          sharedState.translate,
          dispatch
        );
        finalResults.push(result);
      }
    }
    if (jump) {
      continue;
    }
    const row: {
      readonly name: string;
      readonly amountFormatSelector?: () => JSX.Element | null;
      readonly isHidden: boolean;
      readonly results: string[];
    } = {
      name: sharedState.translate(LanguageTexts.perfParam(fieldName)),
      results: finalResults,
      isHidden: isHidden,
      amountFormatSelector: amountFormatSelector
    };
    rows.push(row);
  }

  return rows;
}

function getAmountFormatSelector(
  amountFormat: ScreenAmounts.AmountFormat | undefined,
  fieldGroup: string,
  fieldName: string,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  dispatch: Dispatch<Action>
): () => JSX.Element | null {
  if (amountFormat === undefined) {
    return () => null;
  }

  const formatSelector = () => {
    return (
      <>
        [{" "}
        <AmountFormatSelector
          type="AmountFormatSelectorProps"
          fieldName={fieldName}
          fieldGroup={fieldGroup}
          amountFormat={amountFormat}
          conversionParameters={conversionParameters}
          translate={translate}
          onFormatCleared={() => {
            dispatch(Action.onFormatCleared(fieldGroup, fieldName));
          }}
          onFormatChanged={(unit, decimalCount) => {
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            );
          }}
        />{" "}
        ]
      </>
    );
  };
  return formatSelector;
}

function getAnountFormat(
  propertyValue: PropertyValue.PropertyValue | undefined,
  fieldGroup: string,
  fieldName: string,
  sharedState: SharedState.State
) {
  const amountFormat =
    propertyValue?.type === "amount"
      ? sharedState.screenAmounts.getAmountFormat(
          fieldGroup,
          fieldName,
          propertyValue?.value as Amount.Amount<Quantity.Quantity>
        )
      : undefined;

  return amountFormat;
}

function calculateResult(
  propertyValue: PropertyValue.PropertyValue | undefined,
  resultKey: string,
  fieldGroup: string,
  fieldName: string,
  conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  },
  sharedState: SharedState.State
): string {
  const amountFormat = getAnountFormat(
    propertyValue,
    fieldGroup,
    fieldName,
    sharedState
  );
  const conversionParameters = conversionParametersMap[resultKey];
  let result = propertyValue ? PropertyValue.getText(propertyValue) || "" : "";
  if (amountFormat) {
    result = ProductProperties.getValue(
      propertyValue!,
      amountFormat,
      conversionParameters
    );
  }
  return result;
}

function sorter<
  T extends { readonly isDesignCase: boolean; readonly resultSortNo: number }
>(a: T, b: T): number {
  const aValue = (a.isDesignCase ? 0 : 1000) + a.resultSortNo;
  const bValue = (b.isDesignCase ? 0 : 1000) + b.resultSortNo;

  return aValue - bValue;
}

export function getDefaultSelectedOpCase(
  reportTechnicalDataOpCaseRows: ReadonlyArray<ReportTechnicalDataOpCaseRow>,
  componentProperties: PropertyValueSet.PropertyValueSet,
  caseResults: ReadonlyArray<CaseResult>
): string | undefined {
  const validRows = PropertyFilterHelpers.getValidDataVariantRows(
    reportTechnicalDataOpCaseRows,
    componentProperties
  );

  if (validRows.length === 0) {
    return caseResults[0] && caseResults[0].id;
  }

  const row = validRows[0];

  if (row.min_Max === null || row.min_Max === undefined) {
    return caseResults[0] && caseResults[0].id;
  }

  if (row.result === null || row.result === undefined) {
    return caseResults[0] && caseResults[0].id;
  }

  const max = row.min_Max.toUpperCase() === "MAX";
  const resultName = row.result;
  const designAndRatingCases = caseResults.filter(
    oc =>
      (oc.caseType === CaseType.Design || oc.caseType === CaseType.Rating) &&
      oc.calculationType ===
        GraphQlTypes.ComponentCalculationType.SYSTEM_SIMULATION
  );

  let maxResult: CaseResult | undefined = undefined;
  for (const designAndRatingCase of designAndRatingCases) {
    const result = PropertyValueSet.getAmount(
      resultName,
      designAndRatingCase.results
    ) as Amount.Amount<Quantity.Quantity>;

    if (!result) {
      continue;
    }

    if (!maxResult) {
      maxResult = designAndRatingCase;
      continue;
    }

    const quantity = result.unit.quantity as Quantity.Quantity;
    const unit = Format.getUnitsForQuantity(quantity)[0];

    const prevValue: number = Amount.valueAs(
      unit,
      PropertyValueSet.getAmount(
        resultName,
        maxResult.results
      )! as Amount.Amount<Quantity.Quantity>
    );
    const thisValue = Amount.valueAs(unit, result);

    if (max) {
      maxResult = thisValue > prevValue ? designAndRatingCase : maxResult;
    } else {
      maxResult = thisValue < prevValue ? designAndRatingCase : maxResult;
    }
  }

  return maxResult !== undefined
    ? maxResult.id
    : caseResults[0] && caseResults[0].id;
}

export function getOperatingCaseTabCaseResults(
  translate: LanguageTexts.Translate,
  operatingCases: ReadonlyArray<System.OperatingCase>,
  componentId: string
): ReadonlyArray<CaseResult> {
  const orderedOpCaseResults = getCaseTypeResults(
    translate,
    operatingCases,
    componentId,
    oc =>
      [CaseType.Design, CaseType.Rating, CaseType.Bin].some(
        c => oc.caseType === c
      )
  )
    .filter(
      r =>
        r.calculationType ===
        GraphQlTypes.ComponentCalculationType.SYSTEM_SIMULATION
    )
    .sort((a, b) => (a.operatingCaseSortNo > b.operatingCaseSortNo ? 1 : -1));

  return orderedOpCaseResults;
}

export function createConversionParametersMapByCaseResultId(
  systemTypeResults: ReadonlyArray<SystemTypeResults>,
  caseResults: ReadonlyArray<CaseResult>
): {
  readonly [caseResultId: string]: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
} {
  const conversionParametersMapById: {
    // tslint:disable-next-line:readonly-keyword
    [caseResultId: string]: {
      readonly [key: string]:
        | QuantityConversion.ConversionParameters
        | undefined;
    };
  } = {};
  for (const caseResult of caseResults) {
    const conversionParametersMap: {
      readonly [key: string]:
        | QuantityConversion.ConversionParameters
        | undefined;
    } = systemTypeResults.reduce(
      (
        soFar: {
          // tslint:disable-next-line:readonly-keyword
          [key: string]: QuantityConversion.ConversionParameters | undefined;
        },
        current
      ) => {
        if (current.quantityConversionParams.length === 0) {
          return soFar;
        }
        const splits = current.resultName.split("_");

        if (splits.length !== 2) {
          return soFar;
        }

        const prefix = `${splits[0]}_`;
        const conversionParameters = current.quantityConversionParams.map(
          p => prefix + p
        );

        soFar[current.resultName] =
          QuantityConversion.createConversionParameters(
            conversionParameters,
            caseResult.results
          );
        return soFar;
      },
      {}
    );

    conversionParametersMapById[caseResult.id] = conversionParametersMap;
  }

  return conversionParametersMapById;
}

function getFormatedCalculationType(
  calculationType: GraphQlTypes.ComponentCalculationType
): string {
  return calculationType
    .toLowerCase()
    .replace(/([a-z]+)_?/g, (_, g1) => g1[0].toUpperCase() + g1.substr(1));
}

function getCalculationTypesToRemove(
  orderRows: ReadonlyArray<ScreenResultSummaryCaseOrderRow> | null | undefined,
  applicationClaims: Authorization.ApplicationClaims
): ReadonlyArray<string> {
  return orderRows
    ? orderRows
        .filter(r => {
          return !Authorization.checkClaimFilter(
            applicationClaims,
            PropertyFilter.fromString(r.filter || "")!
          );
        })
        .map(r => {
          return r.calculationType;
        })
    : [];
}

// tslint:disable-next-line
