import * as React from "react";
import { P1 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as SharedState from "../../../../shared-state";
import * as PropertiesSelector from "../../../../properties-selector";
import * as Product from "../../../product";
import { State, Action } from "../state";
import { temperatureProperties } from "../fluid-coils";
import { ProductPropertyInfo, FluidCoilSelectorInfo } from "../types";
import { FluidCoilSelectorContainer, FluidCoilsRoot } from "../elements";

export function FluidCoilsView({
  state,
  sharedState,
  productsData,
  sysProperties,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly productsData: ReadonlyArray<Product.Product>;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const productsPropertyInfo = React.useMemo<
    ReadonlyArray<ProductPropertyInfo>
  >(() => {
    if (productsData.length === 0) {
      return [];
    }

    try {
      const filteredProductsData: ReadonlyArray<Product.Product> =
        productsData.map(p =>
          ProductDataTools.filterProductForRange(p, sysProperties)
        );

      return filteredProductsData.map(p => ({
        productId: p.id,
        propertyInfo: p.properties.filter(p =>
          temperatureProperties.includes(p.name)
        )
      }));
    } catch (_e) {
      return [];
    }
  }, [productsData]);

  if (productsData.length === 0) {
    return <></>;
  }

  const showCodes: boolean = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );
  const canShowProductCodes: boolean = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const selectorsInfo: ReadonlyArray<FluidCoilSelectorInfo> = Object.entries(
    state.fluidCoilSelectors
  ).map(([componentId, v]) => {
    const systemType = v.productId.substring(0, 3);
    const properties = PropertiesSelector.getSelectedProperties(
      v.propertiesSelectorState
    );
    const showProductCodesText = canShowProductCodes ? ` (${v.productId})` : "";
    const label = PropertyValueSet.hasProperty("label", properties)
      ? LanguageTexts.curlyTranslate(
          PropertyValueSet.getText("label", properties)! + showProductCodesText,
          systemType
        )
      : LanguageTexts.curlyTranslate(
          `{${v.productId}}` + showProductCodesText,
          systemType
        );

    return {
      label: label,
      productId: v.productId,
      componentId: componentId,
      propertiesSelectorState: v.propertiesSelectorState
    };
  });

  return (
    <FluidCoilsRoot>
      {selectorsInfo.map(s => (
        <FluidCoilSelectorContainer>
          <P1 weight="normal">{sharedState.translate(s.label)}</P1>
          <PropertiesSelector.PropertiesSelectorView
            isReadonly={false}
            hideGroupNames={true}
            showCodes={showCodes}
            productId={s.productId}
            sharedState={sharedState}
            state={s.propertiesSelectorState}
            fieldGroup={`Settings.${s.productId}`}
            propertiesInfo={
              productsPropertyInfo.find(p => p.productId === s.productId)
                ?.propertyInfo ?? []
            }
            dispatch={Dispatch.map(
              action => Action.dispatchFluidCoilSelector(s.componentId, action),
              dispatch
            )}
          />
        </FluidCoilSelectorContainer>
      ))}
    </FluidCoilsRoot>
  );
}
