import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import * as PropertiesSelector from "../../../properties-selector";
import * as TopMenu from "./top-menu";
import * as KnownProperties from "../../tools/known-properties";
import * as LoadCard from "./load-card";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "styled-components";
import { PropertyDefinitions, SystemOverrides } from "../../tools";
// import { LoadsCard as LoadsCard } from "./loads-card";
import { PropertyValueSet } from "@genesys/property";
import { Dispatch } from "@typescript-tea/core";
import { State, Action, Load } from "./state";
import { LoadOperationTime } from "../type";
import { getAllLoads } from "./state/tools";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
`;

const ApplicationTypeContainer = styled.div`
  width: max-content;

  > table {
    margin-top: -27px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start; */
`;

const FocusBox = styled.div`
  border-radius: 5px;
  /* display: flex;
  justify-content: center; */

  /* border: 2px solid black; */
`;

const MainContentContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    width: 7px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export function View(props: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly isDisabled: boolean;
  readonly propertyDefinitions: PropertyDefinitions;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;

  readonly disableOpcButtons: boolean;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly currentSystemOverrides: SystemOverrides;

  readonly dispatch: Dispatch<Action>;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
}) {
  return (
    <Container>
      <MainContent {...props} />
    </Container>
  );
}

function MainContent({
  state,
  sharedState,
  propertyDefinitions,
  propertySelectorState,
  currentSystemOverrides,
  isDisabled,
  dispatch,
  propertieSelectorDispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;

  readonly propertyDefinitions: PropertyDefinitions;
  readonly currentSystemOverrides: SystemOverrides;
  readonly isDisabled: boolean;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly dispatch: Dispatch<Action>;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
}) {
  const loadsState = state.loadsState;
  function removeSelectedLoad() {
    if (loadsState.selectedLoad === undefined) {
      return;
    }
    dispatch(Action.setSelectedLoad(undefined));
  }

  const activeLoadsOperationTime: LoadOperationTime =
    loadsState.activeLoads.reduce((sofar, current) => {
      return {
        ...sofar,
        [current.sourceName]: current.operationTimeState
      };
    }, {} as LoadOperationTime);

  const onClickaway = (e: any) => {
    if ((e.target as any).nodeName === "BODY") {
      return;
    }
    removeSelectedLoad();
  };

  function getLoadsPreordered(currentSelectLoads: Load[], size: number) {
    const results: Array<Array<JSX.Element>> = [];

    for (const [index, load] of currentSelectLoads.entries()) {
      const indexToGetInnerArray = index % size;
      let innerArray = results[indexToGetInnerArray];

      const isSelectedLoad = loadsState.selectedLoad === load.sourceName;

      const innerBox = (
        <FocusBox
          style={{
            border: isSelectedLoad ? "2.5px solid black" : "2.5px solid white"
          }}
          onClickCapture={e => {
            if ((e.target as any).nodeName !== "INPUT") {
              return;
            }
            dispatch(Action.setSelectedLoad(load.sourceName));
          }}
          onClick={e => {
            if (isSelectedLoad) {
              e.stopPropagation();
            }
            dispatch(Action.setSelectedLoad(load.sourceName));
          }}
        >
          <LoadCard.View
            allPropertyDefinitions={propertyDefinitions}
            currentSystemOverrides={currentSystemOverrides}
            dispatch={dispatch}
            isDisabled={isDisabled}
            load={load}
            propertieSelectorDispatch={propertieSelectorDispatch}
            propertiesSelectorState={propertySelectorState}
            sharedState={sharedState}
            activeLoadsOperationTime={activeLoadsOperationTime}
          />
        </FocusBox>
      );

      if (!innerArray) {
        innerArray = [];
      }

      if (isSelectedLoad) {
        innerArray.push(
          <ClickAwayListener
            isSelected={isSelectedLoad}
            name={load.sourceName}
            onClickAway={onClickaway}
          >
            {innerBox}
          </ClickAwayListener>
        );
      } else {
        innerArray.push(innerBox);
      }

      results[indexToGetInnerArray] = innerArray;
    }

    return results;
  }

  const allLoads = getAllLoads(state);
  const visibleLoads = state.loadsState.visibleLoads;
  const visibleActiveLoads = loadsState.activeLoads.filter(x =>
    visibleLoads.includes(x.sourceName)
  );
  const currentSelectLoads = [
    ...visibleActiveLoads.map(x => ({
      ...x,
      ...allLoads.find(l => l.sourceName === x.sourceName)
    }))
  ];

  const loadSections = getLoadsPreordered(currentSelectLoads as any, 3);

  return (
    <>
      <TopMenu.View
        loadSources={allLoads.filter(
          x =>
            loadsState.selectableLoads.includes(x.sourceName) &&
            visibleLoads.includes(x.sourceName)
        )}
        isDisabled={isDisabled}
        propertySelectorState={propertySelectorState}
        sharedState={sharedState}
        dispatch={dispatch}
        state={state}
      />

      <ApplicationTypeContainer>
        <PropertiesSelector.PropertiesSelectorView
          dispatch={propertieSelectorDispatch}
          translatePropertyName={propertyName =>
            sharedState.translate(
              LanguageTexts.globalPropertyName(propertyName)
            )
          }
          translatePropertyValue={(propertyName, propertyValue) =>
            sharedState.translate(
              LanguageTexts.globalPropertyName(
                propertyName + "_" + propertyValue
              )
            )
          }
          productId={""}
          sharedState={sharedState}
          propertiesInfo={applicationTypeDef(
            propertyDefinitions.find(
              definition => definition.name === KnownProperties.applicationType
            )!
          )}
          fieldGroup={"MoistureLoadTool.BuildingEditor"}
          isReadonly={isDisabled}
          showCodes={false}
          state={propertySelectorState}
        />
      </ApplicationTypeContainer>
      <MainContentContainer>
        <CardsContainer>
          {loadSections.map(loadSection => (
            <div
              style={{
                marginRight: "20px"
              }}
            >
              {loadSection}
            </div>
          ))}
        </CardsContainer>
      </MainContentContainer>
    </>
  );
}

function applicationTypeDef(
  applicationConfigurationDef: PropertiesSelector.PropertyInfo
) {
  return [
    {
      ...applicationConfigurationDef,
      items: [...applicationConfigurationDef.items].sort(
        (a, b) => b.sortNo - a.sortNo
      )
    }
  ];
}
