import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { CheckBoxoptions } from "../tools";
import { Quantity, Unit } from "@genesys/uom";
import {
  AclMessage,
  Modal,
  State,
  MoistureLoadDeveloperSettings
} from "./types";

import * as PropertiesSelector from "../../properties-selector";
import * as GraphQLTypes from "../../graphql-types";
import * as ClimateSettingsEditor from "../components/climate-settings-editor";
import * as IndoorSettingsEditor from "../components/indoor-settings-editor";
import * as Main from "../main-content";
import * as PrintParams from "../../print-params";
import * as MoistureLoadActions from "../../moisture-load-actions";

export const Action = ctorsUnion({
  calculate: () => ({}),
  calculationQueryRecieved: (
    data: GraphQLTypes.SaveMoistureLoadInputsAndCalculate["saveMoistureLoadInputsAndCalculate"],
    userQuery: GraphQLTypes.MoistureLoadCalculationUserQuery
  ) => ({ data, userQuery }),

  dispatchMoistureLoadAction: (action: MoistureLoadActions.Action) => ({
    action: action
  }),
  dispatchClimateSettingsEditor: (action: ClimateSettingsEditor.Action) => ({
    action
  }),
  dispatchIndoorSettingsEditor: (action: IndoorSettingsEditor.Action) => ({
    action
  }),

  dispatchMainContent: (action: Main.Action) => ({
    action
  }),
  dispatchPropertiesSelector: (
    action: PropertiesSelector.Action,
    stateChangedFrom: "use-case-and-building" | "load-types" | "climate" // remove later when sure
  ) => ({
    action,
    stateChangedFrom
  }),

  dispatchPrintParams: (action: PrintParams.Action) => ({ action }),
  lockMoistureLoad: () => ({}),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  setAclMessages: (newMessage: AclMessage | undefined) => ({ newMessage }),
  setAdditionalReportOptions: (newOptions: CheckBoxoptions) => ({ newOptions }),
  setAccesControlList: (
    userNames?: ReadonlyArray<string>,
    claims?: ReadonlyArray<string>
  ) => ({ userNames, claims }),
  setAccessControListMutationRecieved: (
    data: GraphQLTypes.SetMoistureLoadAclMutation
  ) => ({
    data
  }),

  setModal: (modal: Modal | undefined) => ({ modal }),
  setDevSettings: (newSettings: MoistureLoadDeveloperSettings) => ({
    newSettings
  }),
  saveInputs: () => ({}),
  setUserInputSearch: (newValue: string) => ({ newValue }),
  toggleMoistureLoadActions: () => ({}),
  resetSelections: () => ({}),
  userqueryRecivedFromMoistureLoadAction: (
    userQuery: GraphQLTypes.MoistureLoadCalculationUserQuery,
    stateAfterQuery: State
  ) => ({ userQuery, stateAfterQuery }),
  userQueryRecieved: (
    userQuery: GraphQLTypes.MoistureLoadCalculationUserQuery
  ) => ({ userQuery }),
  initialQueryRecieved: (
    userQuery: GraphQLTypes.MoistureLoadCalculationUserQuery,
    productQuery: GraphQLTypes.MoistureLoadCalculationProductQuery
  ) => ({ userQuery, productQuery })
});

export type Action = CtorsUnion<typeof Action>;
