import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { Unit, Quantity } from "@genesys/uom";
import gql from "graphql-tag";
import * as SharedState from "../../../../shared-state";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";
import * as System from "../../../system";
import * as GraphQlTypes from "../../../../graphql-types";
import * as Types from "./types";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import {
  ListPresets,
  ChartPreset,
  ClimateCoolingDataType
} from "../../../../energy-result-visualizer";
import { PropertyValueSet } from "@genesys/property";

export const userQuery = gql`
  query SystemEnergyResultUser($systemId: ID!) {
    user {
      system(id: $systemId) {
        ...EnergyResultSystem
        energySummary {
          energyAnnualConsumption {
            ...CompleteEnergyParam
          }
          energyAnnualCost {
            ...CompleteEnergyCost
          }
          energyEmission {
            ...CompleteEnergyParam
          }
          energyAnnualExtra {
            ...CompleteEnergyParam
          }
          lccCost {
            ...CompleteEnergyCost
          }
        }
      }
    }
  }
  ${Fragments.energyResultSystem}
  ${Fragments.completeEnergyParam}
  ${Fragments.completeEnergyCost}
`;

export const productQuery = gql`
  query SystemEnergyResultProduct($systemTypeInput: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemTypeInput) {
        id
        energyTotals {
          ...EnergyResultEnergyTotalItem
        }
        energyList {
          ...EnergyResultEnergyListItem
        }
        energyChartPresets {
          ...EnergyResultChartPresets
        }
      }
    }
  }
  ${Fragments.energyResultEnergyTotalItem}
  ${Fragments.energyResultEnergyListItem}
  ${Fragments.energyResultChartPresets}
`;

export type State =
  | {
      readonly userQuery: GraphQlTypes.SystemEnergyResultUser | undefined;
      readonly productQuery: GraphQlTypes.SystemEnergyResultProduct | undefined;
      readonly binVisualizer: Types.BinVisualizer;
      readonly listPreset: ListPresets;
      readonly chartPreset: string | undefined;
      readonly binSelections: PropertyValueSet.PropertyValueSet;
      readonly climateCoolingDataType: ClimateCoolingDataType | undefined;
      readonly dataType: ClimateCoolingDataType | undefined;
      readonly energyChartPresets: ReadonlyArray<ChartPreset>;
    }
  | undefined;

export type ActiveTab = "Input" | "Results";

export const init = (
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?] => {
  if (system.status < SystemStatusEnum.SystemStatus.EnergyCalculationSuccess) {
    return [undefined];
  }
  return [
    {
      userQuery: undefined,
      binSelections: PropertyValueSet.Empty,
      productQuery: undefined,
      climateCoolingDataType: undefined,
      dataType: undefined,
      binVisualizer: "List",
      listPreset: "bins",
      chartPreset: undefined,
      energyChartPresets: []
    },

    Cmd.batch([
      sharedState.graphQL.queryUserCmd<
        GraphQlTypes.SystemEnergyResultUser,
        GraphQlTypes.SystemEnergyResultUserVariables,
        Action
      >(
        userQuery,
        {
          systemId: system.id
        },
        Action.userQueryReceived
      ),
      sharedState.graphQL.queryProductCmd<
        GraphQlTypes.SystemEnergyResultProduct,
        GraphQlTypes.SystemEnergyResultProductVariables,
        Action
      >(
        productQuery,
        {
          systemTypeInput: {
            systemTypeId: system.file.systemTypeId
          }
        },
        Action.productQueryReceived
      )
    ])
  ];
};

export const Action = ctorsUnion({
  userQueryReceived: (data: GraphQlTypes.SystemEnergyResultUser) => ({
    data
  }),
  productQueryReceived: (data: GraphQlTypes.SystemEnergyResultProduct) => ({
    data
  }),
  setBinVisualizer: (binVisualizer: Types.BinVisualizer) => ({ binVisualizer }),
  setClimateCoolingType: (dataType: ClimateCoolingDataType) => ({ dataType }),
  setListPreset: (listPreset: ListPresets) => ({ listPreset }),
  setChartPreset: (chartPreset: string) => ({ chartPreset }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, Cmd<Action>?, SharedState.Action?] {
  if (state === undefined) {
    return [state];
  }
  switch (action.type) {
    case "userQueryReceived": {
      const binSelections = PropertyValueSet.fromString(
        action.data.user.system.binSelections || ""
      );

      const dataType = PropertyValueSet.getText(
        KnownProperties.climateCoolingDataType,
        binSelections
      )! as ClimateCoolingDataType;
      return [
        {
          ...state,
          binSelections,
          climateCoolingDataType: dataType,
          dataType: dataType,
          userQuery: action.data
        }
      ];
    }

    case "productQueryReceived": {
      const energyChartPresets =
        action.data.product.systemType.energyChartPresets.map(p =>
          SharedEnergyTools.parseEnergyChartPresets(p)
        );

      return [
        {
          ...state,
          energyChartPresets,
          chartPreset: energyChartPresets[0].id,
          productQuery: action.data
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    case "setBinVisualizer": {
      return [
        {
          ...state,
          binVisualizer: action.binVisualizer
        }
      ];
    }

    case "setListPreset": {
      return [
        {
          ...state,
          listPreset: action.listPreset
        }
      ];
    }

    case "setChartPreset": {
      return [
        {
          ...state,
          chartPreset: action.chartPreset
        }
      ];
    }

    case "setClimateCoolingType": {
      return [
        {
          ...state,
          climateCoolingDataType: action.dataType
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
