import * as React from "react";
import styled from "styled-components";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import {
  Amount,
  Unit,
  Quantity,
  Units,
  UnitFormat,
  UnitsFormat
} from "@genesys/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import {
  AmountInputBox,
  OnFormatChanged,
  OnFormatCleared,
  OnFormatSelectorToggled
} from "../react-properties-selector";
import * as QuantityConverison from "@genesys/shared/lib/quantity-conversion";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as Contexts from "../contexts";
import * as AmountFormatSelector from "../amount-format-selector";
import { QuantitySelector } from "./quantity-selector";

// tslint:disable no-class no-this
// tslint:disable-next-line:variable-name
const ErrorBox = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  margin-left: 5px;
  max-width: 200px;
  background: #dc000c;
  padding: 8px;
  border-radius: 2px;
  color: #fff;
  white-space: pre-wrap;
  font-size: 12px;
  box-shadow: 1px 1px 5px #8a8a8a;
`;

// tslint:disable-next-line:variable-name
const ErrorBoxWrapper = styled.div`
  width: 200px;
`;

// tslint:disable-next-line:variable-name
const ErrorBoxContainer = styled.div`
  position: absolute;
  left: 100%;
`;

export interface AmountPropertySelectorProps {
  readonly propertyName: string;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly inputUnit: Unit.Unit<Quantity.Quantity>;
  readonly inputDecimalCount: number;
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly notNumericMessage: string;
  readonly isRequiredMessage: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly readOnly: boolean;
  readonly onFormatChanged: OnFormatChanged;
  readonly onFormatCleared: OnFormatCleared;
  readonly onFormatSelectorToggled?: OnFormatSelectorToggled;
  readonly onValueChange: (
    newValue: PropertyValue.PropertyValue | undefined
  ) => void;
  readonly debounceTime?: number;
  readonly fieldName: string;
  readonly unitsFormat: {
    readonly [key: string]: UnitFormat.UnitFormat;
  };
  readonly units: {
    readonly [key: string]: Unit.Unit<Quantity.Quantity>;
  };
}

export type AmountPropertySelector =
  React.ComponentClass<AmountPropertySelectorProps>;
export interface CreateAmountPropertySelectorProps {
  readonly AmountFormatSelector: AmountFormatSelector.AmountFormatSelector;
  readonly AmountInputBox: AmountInputBox;
}

interface State {
  readonly hasFocus: boolean;
}
export function createAmountPropertySelector({
  AmountFormatSelector,
  AmountInputBox
}: CreateAmountPropertySelectorProps): AmountPropertySelector {
  return class AmountPropertySelector extends React.Component<
    AmountPropertySelectorProps,
    State
  > {
    constructor(props: AmountPropertySelectorProps) {
      super(props);
      this.state = {
        hasFocus: false
      };
    }

    render(): React.ReactElement<AmountPropertySelectorProps> {
      return (
        <Contexts.propertiesSelectorContext.Consumer>
          {({
            activatedQuantitySelectors,
            conversionParametersMap,
            fieldGroup,
            getAmountFormat,
            onQuantityChange,
            fieldNameMap
          }) => {
            const {
              onValueChange,
              onFormatChanged,
              onFormatCleared,
              onFormatSelectorToggled,
              notNumericMessage,
              isRequiredMessage,
              validationFilter,
              propertyValueSet,
              propertyName,
              filterPrettyPrint,
              inputUnit,
              readOnly,
              debounceTime = 700
            } = this.props;

            const nonConvertedValue:
              | Amount.Amount<Quantity.Quantity>
              | undefined = PropertyValueSet.getAmount(
              propertyName,
              propertyValueSet
            );

            const amountFormat = getAmountFormat(
              fieldGroup,
              fieldNameMap[propertyName],
              inputUnit.quantity
            );
            const conversionParameters = conversionParametersMap[propertyName];

            const validationMessage = [
              humidityValidationMessage(
                humidityValidation(conversionParameters, nonConvertedValue)
              ),
              getValidationMessage(
                propertyValueSet,
                nonConvertedValue,
                validationFilter,
                filterPrettyPrint
              )
            ].reduce((a, b) => (a ? a : b), "");

            return (
              <div style={{ display: "inline-block", position: "relative" }}>
                <AmountInputBox
                  value={getMaybeConvertedValue(
                    conversionParameters,
                    amountFormat,
                    nonConvertedValue
                  )}
                  inputUnit={amountFormat.unit}
                  inputDecimalCount={amountFormat.decimalCount}
                  notNumericMessage={notNumericMessage}
                  isRequiredMessage={isRequiredMessage}
                  errorMessage={validationMessage}
                  readOnly={readOnly}
                  onValueChange={newAmount => {
                    if (newAmount === undefined) {
                      return onValueChange(undefined);
                    }
                    const maybeConverted = getMaybeConvertedBackValue(
                      conversionParameters,
                      newAmount as Amount.Amount<Quantity.Quantity>,
                      inputUnit.quantity
                    );
                    onValueChange(PropertyValue.fromAmount(maybeConverted!));
                  }}
                  debounceTime={debounceTime}
                  onBlur={() => {
                    this.setState({
                      hasFocus: false
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      hasFocus: true
                    });
                  }}
                />
                <AmountFormatSelector
                  selectedUnit={amountFormat.unit}
                  selectedDecimalCount={amountFormat.decimalCount}
                  userSelected={amountFormat.userDefined}
                  onFormatChanged={onFormatChanged}
                  onFormatCleared={onFormatCleared}
                  onFormatSelectorActiveChanged={onFormatSelectorToggled}
                  unitsFormat={UnitsFormat}
                />
                <QuantitySelector
                  conversionParameters={conversionParameters}
                  isQuantitySelectorActive={
                    activatedQuantitySelectors[propertyName]
                  }
                  onQuantityChange={quantity => {
                    onQuantityChange(propertyName, quantity);
                  }}
                  quantity={amountFormat.unit.quantity}
                />
                {validationMessage && this.state.hasFocus && (
                  <ErrorBoxContainer>
                    <ErrorBoxWrapper>
                      <ErrorBox>{validationMessage.trim()}</ErrorBox>
                    </ErrorBoxWrapper>
                  </ErrorBoxContainer>
                )}
              </div>
            );
          }}
        </Contexts.propertiesSelectorContext.Consumer>
      );
    }
  };
}

function getMaybeConvertedValue(
  conversionParameters: QuantityConverison.ConversionParameters | undefined,
  amountFormat: ProductProperties.AmountFormat,
  nonConvertedValue: Amount.Amount<Quantity.Quantity> | undefined
): Amount.Amount<Quantity.Quantity> | undefined {
  if (nonConvertedValue === undefined) {
    return nonConvertedValue;
  }
  if (conversionParameters === undefined) {
    return nonConvertedValue;
  }

  if (amountFormat.unit.quantity === nonConvertedValue.unit.quantity) {
    return nonConvertedValue;
  }

  const convertedValue = QuantityConverison.convertQuantity(
    nonConvertedValue,
    amountFormat.unit.quantity as Quantity.Quantity,
    conversionParameters as QuantityConverison.ConversionParameters
  );

  return convertedValue;
}

function getMaybeConvertedBackValue(
  conversionParameters: QuantityConverison.ConversionParameters | undefined,
  maybeConvertedValue: Amount.Amount<Quantity.Quantity> | undefined,
  expectedQuantity: Quantity.Quantity
): Amount.Amount<Quantity.Quantity> | undefined {
  if (maybeConvertedValue === undefined) {
    return maybeConvertedValue;
  }
  if (conversionParameters === undefined) {
    return maybeConvertedValue;
  }

  if (expectedQuantity === maybeConvertedValue.unit.quantity) {
    return maybeConvertedValue;
  }

  const convertedValue = QuantityConverison.convertQuantity(
    maybeConvertedValue,
    expectedQuantity,
    conversionParameters as QuantityConverison.ConversionParameters
  );

  return convertedValue;
}

function humidityValidationMessage(
  hum: Amount.Amount<Quantity.RelativeHumidity> | undefined
): string {
  if (!hum) {
    return "";
  }
  return "Invalid humidity";
}

function humidityValidation(
  conversionParameters: QuantityConverison.ConversionParameters | undefined,
  value: Amount.Amount<Quantity.Quantity> | undefined
): Amount.Amount<Quantity.RelativeHumidity> | undefined {
  if (!conversionParameters || !value) {
    return undefined;
  }

  const relativeHum =
    value.unit.quantity === "RelativeHumidity"
      ? (value as Amount.Amount<Quantity.RelativeHumidity>)
      : QuantityConverison.convertQuantity(
          value,
          "RelativeHumidity",
          conversionParameters
        );

  return Amount.greaterThan(
    relativeHum,
    Amount.create(0.9995, Units.HumidityFactor)
  )
    ? relativeHum
    : undefined;
}

function getValidationMessage(
  propertyValueSet: PropertyValueSet.PropertyValueSet,
  value: Amount.Amount<Quantity.Quantity> | undefined,
  validationFilter: PropertyFilter.PropertyFilter,
  filterPrettyPrint: PropertyFiltering.FilterPrettyPrint
): string {
  if (!value || !validationFilter) {
    return "";
  }

  if (PropertyFilter.isValid(propertyValueSet, validationFilter)) {
    return "";
  } else {
    return filterPrettyPrint(validationFilter);
  }
}
