import * as React from "react";
import styled from "styled-components";
import { Dispatch, Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import {
  StandardButton,
  GenesysSelect,
  H1,
  P1,
  SettingsIcon,
  OverlayLoader
} from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../graphql-types";
import gql from "graphql-tag";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";

export const InitialSettingsProductDataQuery = gql`
  query InitialSettingsProductDataQuery {
    product {
      languages {
        id
      }
      locales {
        id
        name
      }
      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
          }
        }
      }
    }
  }
`;

// -- STATE

export type State = {
  readonly queryData: GraphQLTypes.InitialSettingsProductDataQuery | undefined;
  readonly language: string | undefined;
  readonly currency: string | undefined;
  readonly locale: string | undefined;
  readonly country: string | undefined;
  readonly region: string | undefined;
  readonly location: string | undefined;
  readonly selectedAmountProfileId: string;
};
export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?] => {
  return [
    {
      queryData: undefined,
      language: undefined,
      currency: undefined,
      locale: undefined,
      country: undefined,
      region: undefined,
      location: undefined,
      selectedAmountProfileId: ScreenAmounts.SIProfileId
    },
    sharedState.graphQL.queryProductCmd<
      GraphQLTypes.InitialSettingsProductDataQuery,
      GraphQLTypes.InitialSettingsProductDataQueryVariables,
      Action
    >(InitialSettingsProductDataQuery, {}, Action.queryDataReceived)
  ];
};

// -- UPDATE

export const Action = ctorsUnion({
  queryDataReceived: (
    queryData: GraphQLTypes.InitialSettingsProductDataQuery
  ) => ({ queryData }),
  changeLanguage: (language: string) => ({ language }),
  changeCurrency: (currency: string) => ({ currency }),
  changeLocale: (locale: string) => ({ locale }),
  changeCountry: (country: string) => ({ country }),
  changeRegion: (region: string) => ({ region }),
  changeLocation: (location: string) => ({ location }),
  changeSelectedAmountProfileId: (selectedAmountProfileId: string) => ({
    selectedAmountProfileId
  }),
  saveInitialSettings: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "queryDataReceived": {
      const { languages, locales, countries } = action.queryData.product;
      return [
        {
          ...state,
          queryData: action.queryData,
          language: languages[0].id,
          currency: sharedState.user.currencies[0].id,
          locale: locales[0].id,
          country: countries[0].name,
          region: countries[0].regions[0].name,
          location: countries[0].regions[0].locations[0].id
        }
      ];
    }
    case "changeLanguage": {
      return [
        {
          ...state,
          language: action.language
        }
      ];
    }
    case "changeCurrency": {
      return [
        {
          ...state,
          currency: action.currency
        }
      ];
    }
    case "changeLocale": {
      return [
        {
          ...state,
          locale: action.locale
        }
      ];
    }
    case "changeCountry": {
      const newRegion = getRegionsForCountry(state, action.country)[0].name;
      const newLocation = getLocationsForCountryRegion(
        state,
        action.country,
        newRegion
      )[0].id;

      return [
        {
          ...state,
          country: action.country,
          region: newRegion,
          location: newLocation
        }
      ];
    }
    case "changeRegion": {
      const newLocation = getLocationsForCountryRegion(
        state,
        state.country!,
        action.region
      )[0].id;
      return [
        {
          ...state,
          region: action.region,
          location: newLocation
        }
      ];
    }
    case "changeLocation": {
      return [
        {
          ...state,
          location: action.location
        }
      ];
    }
    case "changeSelectedAmountProfileId": {
      return [
        {
          ...state,
          selectedAmountProfileId: action.selectedAmountProfileId
        }
      ];
    }
    case "saveInitialSettings": {
      return [
        state,
        undefined,
        [
          SharedState.Action.setUserInitialSettings(
            {
              currency: state.currency!,
              language: state.language!,
              locale: state.locale!
            },
            state.selectedAmountProfileId,
            {
              countryName: state.country!,
              regionName: state.region!,
              locationId: state.location!
            }
          )
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

const Root = styled.div`
  width: 960px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
`;

const LeftContainer = styled.div`
  width: 50%;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const SettingContainer = styled.div`
  margin-top: 16px;
  p {
    margin-bottom: 6px;
  }
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 416px;
  margin-top: 52px;
`;

// -- VIEW

export const InitialSettingsView = ({
  dispatch,
  state,
  sharedState: { translate, user }
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}) => {
  if (!state.queryData?.product) {
    return <OverlayLoader></OverlayLoader>;
  }

  const { languages, locales, countries } = state.queryData.product;

  return (
    <Root>
      <TopContainer>
        <div>
          <SettingsIcon height="1.6rem" />
          <H1 color="primary" weight="normal">
            {translate(LanguageTexts.usersettings())}
          </H1>
        </div>
      </TopContainer>
      <LeftContainer>
        <SettingContainer>
          <P1>{translate(LanguageTexts.language()) + ":"}</P1>
          <GenesysSelect
            value={state.language}
            onChange={e => {
              dispatch(Action.changeLanguage(e.target.value));
            }}
            options={languages.map(option => ({
              value: option.id,
              title: translate(LanguageTexts.languageId(option.id))
            }))}
          />
        </SettingContainer>

        <SettingContainer>
          <P1>{translate(LanguageTexts.currency()) + ":"}</P1>
          <GenesysSelect
            value={state.currency}
            onChange={e => {
              dispatch(Action.changeCurrency(e.target.value));
            }}
            options={user.currencies.map(option => ({
              value: option.id,
              title: "(" + option.id + ") " + option.name
            }))}
          />
        </SettingContainer>

        <SettingContainer>
          <P1>{translate(LanguageTexts.dateTimeNumberFormat()) + ":"}</P1>
          <GenesysSelect
            value={state.locale}
            onChange={e => {
              dispatch(Action.changeLocale(e.target.value));
            }}
            options={locales.map(option => ({
              value: option.id,
              title: option.name
            }))}
          />
        </SettingContainer>

        <SettingContainer>
          <P1>{translate(LanguageTexts.measuresystem()) + ":"}</P1>
          <GenesysSelect
            value={state.selectedAmountProfileId}
            onChange={e => {
              dispatch(Action.changeSelectedAmountProfileId(e.target.value));
            }}
            options={[
              { id: ScreenAmounts.SIProfileId, name: "SI" },
              { id: ScreenAmounts.IPProfileId, name: "IP" }
            ].map(option => ({
              value: option.id,
              title: option.name
            }))}
          />
        </SettingContainer>
      </LeftContainer>

      <RightContainer>
        <SettingContainer>
          <P1>{translate(LanguageTexts.country()) + ":"}</P1>
          <GenesysSelect
            value={state.country}
            onChange={e => {
              dispatch(Action.changeCountry(e.target.value));
            }}
            options={countries.map(option => ({
              value: option.name,
              title: option.name
            }))}
          />
        </SettingContainer>

        <SettingContainer>
          <P1>{translate(LanguageTexts.state()) + ":"}</P1>
          {state.country && (
            <GenesysSelect
              value={state.region}
              onChange={e => {
                dispatch(Action.changeRegion(e.target.value));
              }}
              options={getRegionsForCountry(state, state.country).map(
                option => ({
                  value: option.name,
                  title: option.name
                })
              )}
            />
          )}
        </SettingContainer>

        <SettingContainer>
          <P1>{translate(LanguageTexts.location()) + ":"}</P1>
          {state.country && state.region && (
            <GenesysSelect
              value={state.location}
              onChange={e => {
                dispatch(Action.changeLocation(e.target.value));
              }}
              options={getLocationsForCountryRegion(
                state,
                state.country,
                state.region
              ).map(option => ({
                value: option.id,
                title: option.name
              }))}
            />
          )}
        </SettingContainer>

        <SaveButtonContainer>
          <StandardButton
            buttonType="Primary-2"
            size="Large"
            onClick={() => {
              dispatch(Action.saveInitialSettings());
            }}
          >
            {translate(LanguageTexts.save())}
          </StandardButton>
        </SaveButtonContainer>
      </RightContainer>
    </Root>
  );
};

function getRegionsForCountry(
  state: State,
  countryName: string
): ReadonlyArray<
  GraphQLTypes.InitialSettingsProductDataQuery["product"]["countries"][0]["regions"][0]
> {
  const country = state.queryData?.product.countries.find(
    c => c.name === countryName
  );
  const regions = country?.regions;
  return regions ? regions : [];
}

function getLocationsForCountryRegion(
  state: State,
  countryName: string,
  regionName: string
): ReadonlyArray<
  GraphQLTypes.InitialSettingsProductDataQuery["product"]["countries"][0]["regions"][0]["locations"][0]
> {
  const regions = getRegionsForCountry(state, countryName);
  const region = regions.find(c => c.name === regionName);
  const locations = region?.locations;
  return locations ? locations : [];
}
