import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import { PropertyInfo } from "../../../properties-selector";

export function defaultProperties(
  properties: ReadonlyArray<PropertyInfo>,
  initialPvs: PropertyValueSet.PropertyValueSet = {}
): PropertyValueSet.PropertyValueSet {
  return autoSelectSingleValidValue(
    properties,
    ProductProperties.createDefaultProperties(
      properties.map(p => ({
        id: "",
        name: p.name,
        quantity: p.quantity,
        defaultValues: p.defaultValues,
        values: p.items.map(v => ({
          id: "",
          value: PropertyValue.toString(v.value!)!
        }))
      })),
      true,
      initialPvs
    ),
    ""
  );
}

export function autoSelectSingleValidValue(
  properties: ReadonlyArray<PropertyInfo>,
  newProperties: PropertyValueSet.PropertyValueSet,
  changedProperty: string
): PropertyValueSet.PropertyValueSet {
  return ProductProperties.autoSelectSingleValidValue(
    properties.map(p => ({
      name: p.name,
      quantity: p.quantity,
      validationFilter: PropertyFilter.toString(p.validationFilter),
      values: p.items.map(v => ({
        value: PropertyValue.toString(v.value!)!,
        validationFilter: PropertyFilter.toString(v.validationFilter)
      }))
    })),
    newProperties,
    changedProperty
  );
}
