import gql from "graphql-tag";

export const query = gql`
  query PlenumDehumidifierMatrixSelectorNew(
    $dhu: GetProductInputType!
    $sys: GetProductInputType!
  ) {
    product {
      dhu: product(input: $dhu) {
        id
        properties {
          name
          values {
            value
            validationFilter
            rangeFilter
          }
        }
        minMaxFlow: productDataTable(tableName: "minMaxFlow") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
      sys: product(input: $sys) {
        id
        properties {
          name
          values {
            value
            validationFilter
            rangeFilter
          }
        }
        minMaxFlow: productDataTable(tableName: "minMaxFlow") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
