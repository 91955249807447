import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setAmountProfileMutation = gql`
  mutation SetAmountProfile($amountProfile: AmountProfileInputType!) {
    setAmountProfileSettings(amountProfile: $amountProfile) {
      id
      amountFieldProfiles {
        ...CompleteAmountFieldProfile
      }
    }
  }
  ${Fragments.completeAmountFieldProfile}
`;
