import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../shared-state";
import {
  SidebarLabelsComponent,
  BodyContainer
} from "../../../shared-manager-components";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../../state";
import { SearchParams } from "../../types";
import { ResultTableView } from "./result-table";

export function MainContentView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const setSearchKeyAndSearch = (searchKey: string) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      searchKey: searchKey
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search());
  };

  const queryResult = state.userQueryResult?.user.searchMoistureLoads.results;

  return (
    <BodyContainer>
      <SidebarLabelsComponent
        labels={sharedState.user.labels}
        openLabelPopup={state.openLabelPopup}
        labelInputState={state.labelInputState}
        toggleOpenLabelPopup={labelId =>
          dispatch(Action.toggleOpenLabelPopup(labelId))
        }
        onUpdateLabel={(labelId, labelName) =>
          dispatch(Action.updateLabel(labelId, labelName))
        }
        setLabelInputState={labelInputState =>
          dispatch(Action.setLabelInputState(labelInputState))
        }
        onCreateLabel={labelName => dispatch(Action.createLabel(labelName))}
        deleteLabel={(labelId, labelName) =>
          dispatch(
            Action.openModal({
              type: "confirm-label-delete-modal",
              label: { id: labelId, name: labelName },
              header: sharedState.translate(LanguageTexts.confirmation())
            })
          )
        }
        sharedState={sharedState}
        searchKey={state.searchParams.searchKey}
        onLabelSelected={searchKey => setSearchKeyAndSearch(searchKey)}
      />
      <ResultTableView
        dispatch={dispatch}
        queryResult={queryResult}
        sharedState={sharedState}
        state={state}
      />
    </BodyContainer>
  );
}
