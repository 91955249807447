import * as React from "react";
import { Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { AmountPropertySelector } from "../../../amount-property-selector";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import styled from "styled-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { P2 } from "@genesys/ui-elements";
import { GreyDiv } from "../elements";

const Root = styled.div`
  table {
    td {
      padding: 4px 8px 0 0;
    }
  }
`;

export function AirOut({
  airOutPvs,
  sharedState,
  dispatch
}: {
  readonly airOutPvs: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <Root>
      <table>
        <tbody>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                {sharedState.translate(LanguageTexts.temperature())}
              </P2>
            </td>
            <td>
              <GreyDiv>
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="Temperature"
                  fieldGroup="Settings.AirPower"
                  fieldName={"temperature"}
                  readOnly={false}
                  propertyName={"temperature"}
                  propertyValueSet={airOutPvs}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "Settings.AirPower",
                        "temperature",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(
                      Action.onFormatCleared("Settings.AirPower", "temperature")
                    )
                  }
                  onValueChange={value =>
                    value
                      ? dispatch(
                          Action.onAirOutChange(
                            PropertyValueSet.set(
                              "temperature",
                              PropertyValue.fromAmount(value),
                              airOutPvs
                            )
                          )
                        )
                      : airOutPvs
                  }
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              </GreyDiv>
            </td>
          </tr>

          <tr>
            <td>
              <P2 weight="normal" color="dark">
                {sharedState.translate(LanguageTexts.humidity())}
              </P2>
            </td>
            <td>
              <GreyDiv>
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="HumidityRatio"
                  fieldGroup="Settings.AirPowerAirOut"
                  fieldName={"humidity"}
                  readOnly={false}
                  propertyName={"humidity"}
                  propertyValueSet={airOutPvs}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "Settings.AirPower",
                        "humidity",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(
                      Action.onFormatCleared("Settings.AirPower", "humidity")
                    )
                  }
                  onValueChange={value =>
                    value
                      ? dispatch(
                          Action.onAirOutChange(
                            PropertyValueSet.set(
                              "humidity",
                              PropertyValue.fromAmount(value),
                              airOutPvs
                            )
                          )
                        )
                      : airOutPvs
                  }
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                  conversionParameters={undefined}
                />
              </GreyDiv>
            </td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
}
