import styled from "styled-components";
import { zIndex } from "@genesys/ui-elements";

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1440px;
  margin: 0 auto;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70%;
  margin-top: 20px;
`;

export const MiddleInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const NotesContainer = styled.div`
  h5 {
    margin-bottom: 6px;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
  padding-top: 10px;
`;

export const ResultTable = styled.table`
  td {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const BottomInnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
`;

export const SaveAndLockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  button {
    margin: 10px 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px 10px 20px;

  h5 {
    margin-bottom: 6px;
  }
`;

export const genesysSelectStylingProps = {
  width: 120,
  height: 30,
  fontSize: 16
};

export const PricingIdContainer = styled.div`
  background-color: rgb(247, 249, 252);
  width: 100%;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;

  > label {
    font-weight: 600;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  > a {
    cursor: pointer;
  }
`;

export const PricingActionsContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  position: absolute;
`;

export const PricingInfoContainer = styled.div`
  width: 100%;
  padding-left: 24px;
  padding-right: 5px;
  padding-bottom: 5px;

  margin-top: 10px;

  > label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Error = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

export const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
