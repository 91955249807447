import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../../shared-state";
import * as TopMenu from "../../../top-menu";
import * as SystemActions from "../../../../system-actions";
import * as Energy from "../../../modals/energy";
import * as Navigation from "../../../../navigation-effect-manager";
import { ConfiguratorAction } from "../../../shell-system-configurator/configurator-actions";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { RestActions } from "../../action";
import { State, UpdateReturnType } from "../../types";
import { onSave, onSaveAndCalculate } from "./save";
import { initModal } from "./init-modal";
import { enableScrolling } from "../shared";
import { Cmd } from "@typescript-tea/core";

export function updateRest(
  action: RestActions,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  const createBaseUrlNavigationCmd = () => {
    return Navigation.pushUrlWithoutReload(
      `/system/${sharedState.genesysPrefix.genesysNo(
        state.system.file.genesysNo,
        state.system.revisionNo
      )}`
    );
  };

  switch (action.type) {
    case "setGenericDialogState": {
      return [{ ...state, genericDialogState: action.state }];
    }
    case "setDiagramHoverState": {
      return [{ ...state, diagramHoverState: action.state }];
    }
    case "setSelectedAirPosition": {
      return [{ ...state, selectedAirPosition: action.airPosition }];
    }
    case "toggleSystemActions": {
      if (!state.systemActionsState) {
        const [systemActionsState] = SystemActions.init();
        return [{ ...state, systemActionsState: systemActionsState }];
      } else {
        return [{ ...state, systemActionsState: undefined }];
      }
    }

    case "toggleAlertSlider":
      return [
        {
          ...state,
          alertSliderExpanded: !state.alertSliderExpanded
        }
      ];

    case "toggleConfirmLockRevisonDialog":
      return [
        {
          ...state,
          showConfirmLockRevision: !state.showConfirmLockRevision
        }
      ];
    case "togglesEnergyBinSelectionsValidationError":
      return [
        {
          ...state,
          showEnergyBinSelectionsValidationError:
            !state.showEnergyBinSelectionsValidationError
        }
      ];

    case "setSelectedComponent":
      return [
        {
          ...state,
          diagramMode: action.id
            ? {
                type: "SelectionMode",
                selectedComponentId: action.id
              }
            : {
                type: "DefaultMode"
              }
        }
      ];
    case "setSelectedComponentMenu":
      return [
        {
          ...state,
          diagramMode: {
            type: "SelectionMenuMode",
            selectedComponentId: action.id,
            anchor: action.menu
          }
        }
      ];
    case "setAddComponent":
      return [
        {
          ...state,
          diagramMode: {
            type: "CreatingMode",
            componentId: action.componentId,
            productId: action.productId,
            sectionsLeftToAdd: action.productSectionIds,
            selectedPositions: []
          },
          topMenu: TopMenu.init(sharedState)
        }
      ];
    case "addComponentSelectPosition": {
      if (action.productSectionIds.length) {
        return [
          {
            ...state,
            diagramMode: {
              type: "CreatingMode",
              componentId: action.componentId,
              productId: action.productId,
              sectionsLeftToAdd: action.productSectionIds,
              selectedPositions: action.selectedPositions
            }
          }
        ];
      }

      return [
        {
          ...state,
          diagramMode: {
            type: "DefaultMode"
          }
        },
        [
          ConfiguratorAction.addComponent({
            componentId: action.componentId,
            productId: action.productId,
            componentSectionPositions: action.selectedPositions.map(s => ({
              airstreamId: s.airstreamId,
              beforeSectionId: s.beforeSectionId,
              productSectionId: s.productSectionId
            }))
          })
        ]
      ];
    }
    case "setMoveComponent":
      return [
        {
          ...state,
          diagramMode: {
            type: "MovingMode",
            componentId: action.componentId,
            sectionsLeftToMove: action.sections,
            selectedPositions: []
          }
        }
      ];
    case "moveComponentSelectPosition": {
      if (action.sections.length) {
        return [
          {
            ...state,
            diagramMode: {
              type: "MovingMode",
              componentId: action.componentId,
              sectionsLeftToMove: action.sections,
              selectedPositions: action.selectedPositions
            }
          }
        ];
      }

      return [
        {
          ...state,
          diagramMode: {
            type: "DefaultMode"
          }
        },
        [
          ConfiguratorAction.moveComponent({
            componentId: action.componentId,
            componentSectionPositions: action.selectedPositions
          })
        ]
      ];
    }
    case "deleteComponent":
      return [
        {
          ...state,
          diagramMode: {
            type: "DefaultMode"
          }
        },
        [
          ConfiguratorAction.deleteComponent({
            componentId: action.componentId
          })
        ]
      ];
    case "calculate":
      return [
        state,
        [ConfiguratorAction.calculateSystem()],
        undefined
        // createBaseUrlNavigationCmd()
      ];

    case "tryCalculateEnergyComparison": {
      return [state, [ConfiguratorAction.calculateEnergyComparison()]];
    }
    case "tryCalculateEnergy": {
      if (state.system.status < SystemStatus.PriceCalculationSuccess) {
        return [state];
      }

      if (
        !state.system.binSelections ||
        !state.system.binSelections.includes("cost")
      ) {
        return [{ ...state, showEnergyBinSelectionsValidationError: true }];
      }

      return [state, [ConfiguratorAction.calculateEnergy()]];
    }
    case "lock":
      return [
        {
          ...state
        },
        [ConfiguratorAction.lockSystem()]
      ];
    case "closeModal": {
      enableScrolling();
      return [
        { ...state, modal: { type: "NoModal" } },
        undefined,
        undefined,
        createBaseUrlNavigationCmd()
      ];
    }
    case "saveAndCalculate": {
      enableScrolling();

      // Execute save and calculate logic
      const [updatedState, configuratorActions, sharedState, cmd] =
        onSaveAndCalculate(state);

      // Create navigation command
      const navigationCmd = createBaseUrlNavigationCmd();

      // Return updated state and commands
      return [
        updatedState,
        configuratorActions,
        sharedState,
        Cmd.batch([navigationCmd, cmd])
      ];
    }
    case "saveModal": {
      enableScrolling();

      const [updatedState, configuratorActions, sharedState, cmd] =
        onSave(state);

      const navigationCmd = createBaseUrlNavigationCmd();
      return [
        updatedState,
        configuratorActions,
        sharedState,
        Cmd.batch([navigationCmd, cmd])
      ];
    }
    case "setExchangeComponent":
      return [
        {
          ...state,
          diagramMode: { type: "DefaultMode" },
          exchangeComponentDialog: action.exchangeComponent
        }
      ];
    case "exchangeComponent":
      return [
        {
          ...state,
          exchangeComponentDialog: undefined
        },
        [ConfiguratorAction.exchangeComponent(action.input)]
      ];
    case "setActiveAmountField": {
      return [
        state,
        undefined,
        [SharedState.Action.setActiveAmountFieldProfile(action.amountProfileId)]
      ];
    }
    case "saveAndCalculateEnergy": {
      const modal = state.modal;

      if (modal.type !== "Energy") {
        return [state];
      }

      const energySettings = Energy.getEnergyInformation(modal.state);
      return [
        { ...state, modal: { type: "NoModal" } },
        [
          ConfiguratorAction.saveEnergy(
            energySettings.energySettings,
            energySettings.binSelections,
            energySettings.binOperatingCases,
            true,
            true
          )
        ],
        undefined,
        createBaseUrlNavigationCmd()
      ];
    }

    case "openModal": {
      const [modal, cmd] = initModal(
        action.initModal,
        sharedState,
        state.productData,
        state.system
      );
      return [
        {
          ...state,
          modal,
          topMenu: TopMenu.init(sharedState),
          diagramMode: {
            type: "DefaultMode"
          }
        },
        undefined,
        undefined,
        cmd
      ];
    }
    case "openSystemConfirmation": {
      if (action.openInNewTab) {
        window.open(action.url);
      } else {
        window.location.href = action.url;
      }
      return [{ ...state, actionCompleteUrl: undefined }];
    }
    case "expandGroupException":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            exceptions: new Set([
              ...state.expandedGroups.exceptions,
              action.groupKey
            ])
          }
        }
      ];
    case "collapseGroupException":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            exceptions: new Set(
              [...state.expandedGroups.exceptions].filter(
                e => e !== action.groupKey
              )
            )
          }
        }
      ];
    case "expandGroupError":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            errors: new Set([...state.expandedGroups.errors, action.groupKey])
          }
        }
      ];
    case "collapseGroupError":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            errors: new Set(
              [...state.expandedGroups.errors].filter(
                e => e !== action.groupKey
              )
            )
          }
        }
      ];
    case "expandGroupWarning":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            warnings: new Set([
              ...state.expandedGroups.warnings,
              action.groupKey
            ])
          }
        }
      ];
    case "collapseGroupWarning":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            warnings: new Set(
              [...state.expandedGroups.warnings].filter(
                e => e !== action.groupKey
              )
            )
          }
        }
      ];
    case "expandGroupCriticalWarning":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            criticalWarnings: new Set([
              ...state.expandedGroups.criticalWarnings,
              action.groupKey
            ])
          }
        }
      ];
    case "collapseGroupCriticalWarning":
      return [
        {
          ...state,
          expandedGroups: {
            ...state.expandedGroups,
            criticalWarnings: new Set(
              [...state.expandedGroups.criticalWarnings].filter(
                e => e !== action.groupKey
              )
            )
          }
        }
      ];
    default:
      return exhaustiveCheck(action, true);
  }
}

// tslint:disable-next-line: max-file-line-count
