import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQLTypes from "../graphql-types";
import {
  deleteSystemFileMutation,
  updateSystemFileMutation,
  createNewSystemRevisionMutation,
  copySystemAndTransferToSelfMutation,
  editRevisionCommentMutation,
  recoverSystemFileMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { SystemLabel, Mode } from "./types";
import * as LabelManager from "../label-manager/state";
import * as PricingWizard from "../pricing-wizard";
import * as Navigation from "../navigation-effect-manager";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import gql from "graphql-tag";

const updateSystemMutation = gql`
  mutation UpdateSystem($input: UpdateSystemInputType!) {
    updateSystem(input: $input) {
      __typename
      ... on UpdateSystemSuccessResultType {
        status
      }

      ... on UpdateSystemRejectResultType {
        rejectReason
      }
    }
  }
`;

export const exportSystemModelQuery = gql`
  query GetSystemModel($id: ID!) {
    user {
      system(id: $id) {
        id
        currencyCode
        masterCurrencyCode
        salesOrganisationId
        masterSalesOrganisationId
        climateSettings
        binSelections
        revisionNo
        systemFile {
          systemTypeId
          genesysNo
        }
        airstreams {
          id
          componentSections {
            id
          }
        }
        components {
          id
          accessoryToId
          productId
          properties
          sections {
            id
            casingComponentSectionId
            componentId
            movable
            productSectionId
            productSectionSortNo
            sortNo
            systemAirStreamId
          }
          sortNo
        }
        operatingCases {
          id
          sortNo
          binData
          caseName
          caseType
          customCaseName
          isAutoGenerated
          mandatory
          settings
          sourceStateSource
        }
      }
    }
  }
`;

export type State = {
  readonly labelManagerState: LabelManager.State | undefined;
  readonly pricingWizardState: PricingWizard.State | undefined;
  readonly input: string;
  readonly mode: Mode;
  readonly originPrefix: string | undefined;
  readonly fileName: string;
  readonly fileInfo:
    | {
        readonly blob: Blob;
        readonly fileUrl: string;
      }
    | undefined;
};

export const init = (): [State] => {
  return [
    {
      labelManagerState: undefined,
      pricingWizardState: undefined,
      input: "",
      mode: "menu",
      originPrefix: undefined,
      fileName: "system-info",
      fileInfo: undefined
    }
  ];
};

export const Action = ctorsUnion({
  setMode: (mode: Mode) => ({ mode }),
  setInput: (input: string) => ({ input }),
  actionComplete: (actionType?: ActionCompleteUrl) => ({ actionType }),
  updateSystemFile: (input: GraphQLTypes.UpdateSystemFileVariables) => ({
    input
  }),
  removeFileInfo: () => ({}),
  createNewSystemRevision: (
    systemFileId: string,
    comment: string,
    shouldOpen: boolean
  ) => ({
    systemFileId,
    comment,
    shouldOpen
  }),
  deleteSystems: (systemFileIds: string[]) => ({ systemFileIds }),
  fetchSystemModel: (id: string, fileName: string) => ({
    id,
    fileName
  }),
  systemModelQueryRecieved: (data: GraphQLTypes.GetSystemModel) => ({
    data
  }),
  copySystemAndTransferToSelf: (systemId: string) => ({ systemId }),
  editRevisionComment: (systemId: string, comment: string) => ({
    systemId,
    comment
  }),
  openSystem: (genesysNo: number, revisionNo: number) => ({
    genesysNo,
    revisionNo
  }),
  initLabelManager: (systemLabels: ReadonlyArray<SystemLabel>) => ({
    systemLabels
  }),
  dispatchLabelManager: (action: LabelManager.Action) => ({ action }),
  initPricingWizard: () => ({}),
  dispatchPricingWizard: (action: PricingWizard.Action) => ({ action }),
  lockRevision: (systemId: string) => ({ systemId }),
  saveAndCalculate: (systemId: string) => ({ systemId }),
  recoverSystemFile: (systemFileId: string) => ({ systemFileId }),
  updateSystemFileComplete: (res: GraphQLTypes.UpdateSystemFile) => ({ res })
});
export type Action = CtorsUnion<typeof Action>;

export type ActionCompleteUrl = {
  readonly type: "revision" | "copy-transfer";
  readonly url: string;
};

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?,
  boolean?,
  ActionCompleteUrl?
] {
  switch (action.type) {
    case "setMode": {
      return [{ ...state, mode: action.mode }];
    }
    case "setInput": {
      return [{ ...state, input: action.input }];
    }
    case "actionComplete": {
      return [
        { ...state, mode: "menu", input: "" },
        undefined,
        undefined,
        true,
        action.actionType
      ];
    }

    case "fetchSystemModel": {
      return [
        {
          ...state,
          mode: "loading",
          originPrefix: action.fileName.charAt(0),
          fileName: action.fileName
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.GetSystemModel,
          GraphQLTypes.GetSystemModelVariables,
          Action
        >(
          exportSystemModelQuery,
          { id: action.id },
          Action.systemModelQueryRecieved
        )
      ];
    }

    case "systemModelQueryRecieved": {
      const beforeDownload = (res: GraphQLTypes.GetSystemModel) => ({
        system: res.user.system,
        originPrefix: state.originPrefix
      });

      const createUrl = (blob: Blob): string => {
        return URL.createObjectURL(blob);
      };

      const blob = new Blob([JSON.stringify(beforeDownload(action.data))]);
      const url = createUrl(blob);

      return [
        {
          ...state,
          mode: "menu",
          input: "",
          fileInfo: { ...state.fileInfo, blob: blob, fileUrl: url }
        }
      ];
    }
    case "updateSystemFile": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdateSystemFile,
          GraphQLTypes.UpdateSystemFileVariables,
          Action
        >(updateSystemFileMutation, action.input, data =>
          Action.updateSystemFileComplete(data)
        )
      ];
    }
    case "createNewSystemRevision": {
      if (action.shouldOpen) {
        return [
          { ...state, mode: "loading" },
          sharedState.graphQL.queryUserCmd<
            GraphQLTypes.CreateNewSystemRevision,
            GraphQLTypes.CreateNewSystemRevisionVariables,
            Action
          >(
            createNewSystemRevisionMutation,
            { systemFileId: action.systemFileId, comment: action.comment },
            data => {
              const url = `/system/${sharedState.genesysPrefix.genesysNo(
                data.createNewSystemRevision.genesysNo,
                data.createNewSystemRevision.systems.reduce(
                  (a, b) => (b.revisionNo > a.revisionNo ? b : a),
                  data.createNewSystemRevision.systems[0]
                ).revisionNo || 1
              )}`;

              return Action.actionComplete({ type: "revision", url });
            }
          )
        ];
      } else {
        return [
          { ...state, mode: "loading" },
          sharedState.graphQL.queryUserCmd<
            GraphQLTypes.CreateNewSystemRevision,
            GraphQLTypes.CreateNewSystemRevisionVariables,
            Action
          >(
            createNewSystemRevisionMutation,
            { systemFileId: action.systemFileId, comment: action.comment },
            () => Action.actionComplete()
          )
        ];
      }
    }
    case "deleteSystems": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeleteSystemFile,
          GraphQLTypes.DeleteSystemFileVariables,
          Action
        >(
          deleteSystemFileMutation,
          {
            systemFileIds: action.systemFileIds
          },
          () => Action.actionComplete()
        )
      ];
    }
    case "copySystemAndTransferToSelf": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CopySystemAndTransferToSelfMutation,
          GraphQLTypes.CopySystemAndTransferToSelfMutationVariables,
          Action
        >(
          copySystemAndTransferToSelfMutation,
          { systemId: action.systemId, systemName: state.input },
          data => {
            const url = `/system/${sharedState.genesysPrefix.genesysNo(
              data.copySystemAndTransferToSelf.genesysNo,
              data.copySystemAndTransferToSelf.systems.find(
                s => s.id === action.systemId
              )?.revisionNo || 1
            )}`;

            return Action.actionComplete({ type: "copy-transfer", url });
          }
        )
      ];
    }
    case "editRevisionComment": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.EditRevisionComment,
          GraphQLTypes.EditRevisionCommentVariables,
          Action
        >(
          editRevisionCommentMutation,
          { systemId: action.systemId, comment: action.comment },
          () => Action.actionComplete()
        )
      ];
    }
    case "openSystem": {
      const url = `/system/${sharedState.genesysPrefix.genesysNo(
        action.genesysNo,
        action.revisionNo
      )}`;
      return [state, Navigation.pushUrl(url)];
    }
    case "initLabelManager": {
      const [labelManagerState] = LabelManager.init(action.systemLabels);
      return [{ ...state, labelManagerState: labelManagerState }];
    }
    case "dispatchLabelManager": {
      if (!state.labelManagerState) {
        return [state];
      }

      const [labelManagerState, sharedStateActions] = LabelManager.update(
        action.action,
        state.labelManagerState
      );

      return [
        { ...state, labelManagerState: labelManagerState },
        undefined,
        sharedStateActions
      ];
    }
    case "initPricingWizard": {
      const [pricingWizardState] = PricingWizard.init(sharedState);
      return [{ ...state, pricingWizardState: pricingWizardState }];
    }
    case "dispatchPricingWizard": {
      if (!state.pricingWizardState) {
        return [state];
      }
      const [pricingWizardState, pricingWizardCmd, sharedStateAction] =
        PricingWizard.update(
          action.action,
          state.pricingWizardState,
          sharedState
        );
      return [
        { ...state, pricingWizardState },
        Cmd.map(Action.dispatchPricingWizard, pricingWizardCmd),
        sharedStateAction
      ];
    }
    case "lockRevision": {
      return [
        { ...state, mode: "locking" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdateSystem,
          GraphQLTypes.UpdateSystemVariables,
          Action
        >(
          updateSystemMutation,
          {
            input: {
              forceUpdate: false,
              systemId: action.systemId,
              targetStatus: SystemStatus.LockSuccess
            }
          },
          () => Action.actionComplete()
        )
      ];
    }
    case "saveAndCalculate": {
      return [
        { ...state, mode: "calculating" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdateSystem,
          GraphQLTypes.UpdateSystemVariables,
          Action
        >(
          updateSystemMutation,
          {
            input: {
              forceUpdate: false,
              systemId: action.systemId,
              targetStatus: SystemStatus.PriceCalculationSuccess
            }
          },
          () => Action.actionComplete()
        )
      ];
    }
    case "removeFileInfo": {
      return [
        {
          ...state,
          fileInfo: undefined
        }
      ];
    }

    case "recoverSystemFile": {
      return [
        { ...state, mode: "loading" },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.RecoverSystemFileMutation,
          GraphQLTypes.RecoverSystemFileMutationVariables,
          Action
        >(
          recoverSystemFileMutation,
          {
            systemFileId: action.systemFileId
          },
          () => Action.actionComplete()
        )
      ];
    }

    case "updateSystemFileComplete": {
      const resType = action.res.updateSystemFile.__typename;
      if (resType === "UpdateFailedType") {
        return [
          { ...state, mode: "menu", input: "" },
          undefined,
          [
            SharedState.Action.addAlertMessageToQueue({
              messageType: "error",
              content: `Update failed: ${action.res.updateSystemFile.errorMessage}`,
              timeout: 5000
            })
          ]
        ];
      }
      return [
        { ...state, mode: "menu", input: "" },
        undefined,
        undefined,
        true
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function isInMenu(state: State) {
  return state.mode === "menu";
}

// tslint:disable-next-line
