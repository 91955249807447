import styled from "styled-components";
import { LoadingIndicatorBox } from "./box";

// tslint:disable-next-line
export const LoadingIndicator = styled(LoadingIndicatorBox)`
  width: 180px;
  height: 60px;

  span {
    display: block;
    font-size: 11px;
  }

  div {
    margin-top: 5px;
  }
`;
