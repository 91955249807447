import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";

export interface ContextValue {
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
  readonly translate: (textDefinition: LanguageTexts.TextDefinition) => string;
}

export const ActionsContext = React.createContext<ContextValue | null>(null);
