import styled from "styled-components";

// tslint:disable-next-line
export const MenuItemSelected = styled.div`
  position: absolute;

  top: 8px;
  left: 8px;

  width: 5px;
  height: 5px;

  border-radius: 50%;

  background-image: radial-gradient(circle at right top, #fff 0%, #000 60%);
`;
