import React from "react";
import styled, { keyframes } from "../../styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { PrimaryColors } from "../../colors";
// tslint:disable-next-line
const warning = require("ui-elements/assets/system-config-alert/warning.svg");
// tslint:disable-next-line
const remove = require("ui-elements/assets/system-config-alert/remove.svg");
// tslint:disable-next-line
const info = require("ui-elements/assets/alert-accordion/info.svg");

const fadeIn = keyframes`
from { opacity: 0; }
      to { opacity: 1; }
`;

const Container = styled.div`
  display: inline-flex;
`;

const AlertTabContainer = styled.div`
  height: 100vh;
  width: 366px;
  border-radius: 0 10px 10px 0;
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
  overflow-y: auto;

  animation: ${fadeIn} 500ms ease-in-out both;
  /* animation-duration: 3s; */

  ::-webkit-scrollbar {
    width: 9px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(221, 228, 240);
    opacity: 1;
    border-radius: 5.5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 12px;
  margin-top: 11px;
  padding-bottom: 10px;

  img {
    cursor: pointer;
  }
`;

const AlerTabBody = styled.div``;

const AlertButtonContainer = styled.div`
  margin-top: ${(props: Props) => (props.expanded ? "200px" : "0")};
  min-height: 47px;
  width: 68px;
  position: relative;

  transition: left 250ms;

  ${(props: Props) => (!props.expanded ? "cursor: pointer;" : "")}

  > div {
    margin-bottom: 10px;
  }
`;

const StyledDiv = styled.div`
  position: relative;
`;

const CounterContainer = styled.div`
  height: 20px;
  width: 36px;
  border-radius: 10px;
  background-color: #3d3d3d;
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  label {
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    display: block;
  }
`;

const AlertButton = styled.div<{ readonly color: string }>`
  height: 40px;
  width: 47px;
  border-radius: 0 19.5px 19.5px 0;
  background-color: ${props => props.color};
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  readonly expanded: boolean;
}

export function AlertSlider({
  expanded,
  toggle,
  noOfCriticalWarnings,
  noOfWarnings,
  noOfInfo,
  noOfErrors,
  children
}: {
  readonly expanded: boolean;
  readonly children: JSX.Element;
  readonly noOfCriticalWarnings: number;
  readonly noOfWarnings: number;
  readonly noOfInfo: number;
  readonly noOfErrors: number;
  readonly toggle: () => void;
}) {
  return (
    <ClickAwayListener
      onClickAway={
        expanded
          ? toggle
          : () => {
              return undefined;
            }
      }
    >
      <Container>
        {expanded && (
          <AlertTabContainer>
            <CloseContainer>
              <img
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  toggle();
                }}
                src={remove}
              />
            </CloseContainer>

            <AlerTabBody>{children}</AlerTabBody>
          </AlertTabContainer>
        )}
        <AlertButtonContainer
          expanded={expanded}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!expanded) {
              toggle();
            }
          }}
        >
          {!!noOfErrors && (
            <StyledDiv>
              <AlertButton color={PrimaryColors.red}>
                <img src={warning} />
              </AlertButton>
              <CounterContainer>
                <label>{noOfErrors <= 9 ? noOfErrors : "9+"}</label>
              </CounterContainer>
            </StyledDiv>
          )}
          {!!noOfCriticalWarnings && (
            <StyledDiv>
              <AlertButton color={PrimaryColors.orange}>
                <img src={warning} />
              </AlertButton>
              <CounterContainer>
                <label>
                  {noOfCriticalWarnings <= 9 ? noOfCriticalWarnings : "9+"}
                </label>
              </CounterContainer>
            </StyledDiv>
          )}
          {!!noOfWarnings && (
            <StyledDiv>
              <AlertButton color={PrimaryColors.gold}>
                <img src={warning} />
              </AlertButton>
              <CounterContainer>
                <label>{noOfWarnings <= 9 ? noOfWarnings : "9+"}</label>
              </CounterContainer>
            </StyledDiv>
          )}

          {!!noOfInfo && (
            <StyledDiv>
              <AlertButton color={"#2487F5"}>
                <img src={info} />
              </AlertButton>
              <CounterContainer>
                <label>{noOfInfo <= 9 ? noOfInfo : "9+"}</label>
              </CounterContainer>
            </StyledDiv>
          )}
        </AlertButtonContainer>
      </Container>
    </ClickAwayListener>
  );
}
