import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import * as GraphQLTypes from "../../graphql-types";
import { State, Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import {
  H1,
  TableIcon,
  GridIcon,
  Confirm,
  SystemsIcon
} from "@genesys/ui-elements";
import {
  SearchOptionsContainer,
  TopBarContainer,
  TopLeftContainer,
  ViewSelectionAnchor
} from "../elements";
import { SearchParams } from "../types";
import {
  SearchOptionsComponent,
  SearchOption
} from "../../shared-manager-components";

export function TopBarView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const ref = React.useRef<HTMLElement>(null);

  return (
    <TopBarContainer>
      <TopLeftContainer>
        <TableIcon height="1.6rem" />
        <H1 weight="normal">
          {sharedState.translate(LanguageTexts.systemsmanager())}
        </H1>
        <ViewSelectionAnchor
          isSelected={state.viewMode === "table"}
          onClick={() => dispatch(Action.setViewMode("table"))}
        >
          <SystemsIcon height="1.0rem" />
        </ViewSelectionAnchor>
        <ViewSelectionAnchor
          ref={ref as any}
          isSelected={state.viewMode === "grid"}
          onClick={() => dispatch(Action.setViewMode("grid"))}
        >
          <GridIcon height="1.0rem" />
        </ViewSelectionAnchor>
        <PopUpDialogue
          forwardRef={ref}
          state={state}
          sharedState={sharedState}
          dispatch={dispatch}
        />
      </TopLeftContainer>
      <SearchOptionsView
        state={state}
        sharedState={sharedState}
        dispatch={dispatch}
      />
    </TopBarContainer>
  );
}

function PopUpDialogue({
  state,
  sharedState,
  forwardRef,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly forwardRef: React.RefObject<HTMLElement>;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state.systemActionIsCompleteUrl) {
    return null;
  }
  return (
    <>
      <Confirm
        cancelTranslated={sharedState.translate(
          LanguageTexts.openInCurrentTab()
        )}
        confirmTranslated={sharedState.translate(LanguageTexts.openInNewTab())}
        headerText={
          state.systemActionIsCompleteUrl.type === "copy-transfer"
            ? sharedState.translate(LanguageTexts.copyOfSystemCompletedMsg())
            : sharedState.translate(LanguageTexts.revisionCompletedMsg())
        }
        labelRef={forwardRef}
        showDialog={!!state.systemActionIsCompleteUrl}
        onConfirm={() => {
          dispatch(
            Action.openSystemConfirmation(
              state.systemActionIsCompleteUrl!.url!,
              true
            )
          );
        }}
        onCancel={() =>
          dispatch(
            Action.openSystemConfirmation(
              state.systemActionIsCompleteUrl!.url!,
              false
            )
          )
        }
      />
    </>
  );
}

function SearchOptionsView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;

  const setSearchKeyAndSearch = (searchKey: string) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      searchKey: searchKey
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search(searchParams, undefined, 1));
  };

  const setScopeAndSearch = (scope: GraphQLTypes.SystemSearchScope) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      scope: scope
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search(searchParams, undefined, 1));
  };

  return (
    <SearchOptionsContainer>
      <SearchOptionsComponent
        onToggleHelpModal={() =>
          dispatch(Action.openModal({ mode: "help-about-searching" }))
        }
        searchOptionValues={state.searchFilterOptions}
        onSearch={() =>
          dispatch(Action.search(state.searchParams, undefined, 1))
        }
        sharedState={sharedState}
        searchKey={state.searchParams.searchKey}
        isSearchFilterOptionsOpen={state.isSearchFilterOptionsOpen}
        selectedScope={state.searchParams.scope}
        searchOptions={getSearchOptions(state.searchParams.scope)}
        scopes={[
          {
            value: GraphQLTypes.SystemSearchScope.MY,
            title: translate(LanguageTexts.mySystems())
          },
          {
            value: GraphQLTypes.SystemSearchScope.ALL,
            title: translate(LanguageTexts.allSystems())
          }
        ]}
        onScopeChange={scope =>
          setScopeAndSearch(scope as GraphQLTypes.SystemSearchScope)
        }
        onSearchKeyChanged={searchKey =>
          dispatch(
            Action.setSearchParams({
              ...state.searchParams,
              searchKey: searchKey
            })
          )
        }
        onSearchFilterOptionsToggle={value =>
          dispatch(Action.toggleIsSearchFilterOptionsOpen(value))
        }
        onSearchFilterOptionsChange={searchFilterOptions =>
          dispatch(Action.setSearchFilterOptions(searchFilterOptions))
        }
        onSearchOptionsSearch={searchOptions =>
          setSearchKeyAndSearch(searchOptions)
        }
        onClearSearchOptions={() => dispatch(Action.clearSearchFilter())}
      />
    </SearchOptionsContainer>
  );
}

interface SystemSearchOptions {
  readonly gno: string;
  readonly label: string;
  readonly name: string;
  readonly type: string;
  readonly status: string;
  readonly pno: string;
  readonly product: string;
  readonly org: string;
}

const commonSearchOptions: ReadonlyArray<SearchOption<SystemSearchOptions>> = [
  { textId: "gno", option: "gno", type: "text" },
  { textId: "label", option: "label", type: "text" },
  { textId: "name", option: "name", type: "text" },
  { textId: "type", option: "type", type: "text" },
  { textId: "pno", option: "pno", type: "text" },
  { textId: "product", option: "product", type: "text" }
];

const statusOptions: { readonly value: string; readonly title: string }[] = [
  { value: "0", title: "Created" },
  { value: "80", title: "Calculated" },
  { value: "90", title: "Locked" },
  { value: "100", title: "Ordered" }
];

function getSearchOptions(
  selectedScope: GraphQLTypes.SystemSearchScope
): ReadonlyArray<SearchOption<SystemSearchOptions>> {
  if (selectedScope === GraphQLTypes.SystemSearchScope.MY) {
    return [
      ...commonSearchOptions,
      {
        textId: "Status",
        option: "status",
        type: "status-search",
        statusOptions
      }
    ] as ReadonlyArray<SearchOption<SystemSearchOptions>>;
  }

  return [
    ...commonSearchOptions,
    { textId: "owner", option: "owner", type: "text" },
    { textId: "org", option: "org", type: "text" },
    { textId: "status", option: "status", type: "status-search", statusOptions }
  ] as ReadonlyArray<SearchOption<SystemSearchOptions>>;
}
