import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../shared-state";
import {
  StandardButton,
  H1,
  H2,
  OverlayLoader,
  SettingsIcon,
  ClearIcon,
  P1,
  GenesysSelect
} from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { ConfirmationDialog } from "./confirmation-dialog";
import { Action, State } from "./state";
import {
  Root,
  TopContainer,
  LeftContainer,
  ResetButton,
  RightContainer,
  SettingContainer
} from "./elements";

export function UserSettingsView({
  dispatch,
  state,
  sharedState: { user, translate }
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  if (!state || !state.queryData) {
    return <OverlayLoader />;
  }
  const { languages, locales } = state.queryData.product;

  const primarySalesOrganisationFromClaim = AuthorizationTools.getClaimValues(
    user.applicationClaims,
    AuthorizationTools.genesysUserClaims.primarySalesOrganisation
  );

  const defaultSalesOrganisation = state.defaultSalesOrganisation
    ? state.defaultSalesOrganisation
    : user.settings.defaultSalesOrganisation
    ? user.settings.defaultSalesOrganisation
    : primarySalesOrganisationFromClaim
    ? primarySalesOrganisationFromClaim[0]
    : user.salesOrganisations[0].id;

  return (
    <Root>
      {state.isConfimationDialogOpen && (
        <ConfirmationDialog
          translate={translate}
          close={() => dispatch(Action.toggleConfirmationDialog())}
          resetDefaultSettings={() => {
            dispatch(Action.resetDefaultSettings());
          }}
        />
      )}

      <TopContainer>
        <div>
          <SettingsIcon height="1.6rem" />
          <H1 color="primary" weight="normal">
            {translate(LanguageTexts.usersettings())}
          </H1>
        </div>
        <StandardButton
          buttonType="Primary-2"
          size="Large"
          onClick={() => {
            dispatch(Action.saveDefaultSettings());
          }}
          disabled={!state.settingsHasChanged}
        >
          {translate(LanguageTexts.save())}
        </StandardButton>
      </TopContainer>

      <LeftContainer>
        <H2 color="dark" weight="normal">
          {translate(LanguageTexts.userDefaults())}
        </H2>

        <SettingContainer>
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.language()) + ":"}
          </P1>
          <GenesysSelect
            value={state.language || user.settings.language}
            onChange={e => {
              dispatch(Action.changeLanguage(e.target.value));
            }}
            options={[
              ...(AuthorizationTools.checkPermission(
                user.applicationClaims,
                AuthorizationTools.genesysUserClaims.showLanguageCodes
              )
                ? [
                    {
                      title: translate(LanguageTexts.languagecodes()),
                      value: "lngcod"
                    }
                  ]
                : []),
              ...languages.map(option => ({
                value: option.id,
                title: translate(LanguageTexts.languageId(option.id))
              }))
            ]}
          />
        </SettingContainer>

        <SettingContainer>
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.currency()) + ":"}
          </P1>
          <GenesysSelect
            value={state.currency || user.settings.currency}
            onChange={e => {
              dispatch(Action.changeCurrency(e.target.value));
            }}
            options={user.currencies.map(option => ({
              value: option.id,
              title: "(" + option.id + ") " + option.name
            }))}
          />
        </SettingContainer>
        <SettingContainer>
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.dateTimeNumberFormat()) + ":"}
          </P1>
          <GenesysSelect
            value={state.locale || user.settings.locale}
            onChange={e => {
              dispatch(Action.changeLocale(e.target.value));
            }}
            options={locales.map(option => ({
              value: option.id,
              title: option.name
            }))}
          />
        </SettingContainer>
        <SettingContainer>
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.defaultSalesOrganisation()) + ":"}
          </P1>
          <GenesysSelect
            value={defaultSalesOrganisation}
            onChange={e => {
              dispatch(
                Action.changeDefaultSalesOrganisationOrg(e.target.value)
              );
            }}
            options={user.salesOrganisations.map(option => ({
              value: option.id,
              title: "(" + option.id + ") " + option.name
            }))}
          />
        </SettingContainer>

        <ResetButton
          onClick={() => {
            dispatch(Action.toggleConfirmationDialog());
          }}
        >
          <ClearIcon height="1.6rem" />
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.resetuserprofile())}
          </P1>
        </ResetButton>
      </LeftContainer>

      <RightContainer>
        <H2 color="dark" weight="normal">
          {translate(LanguageTexts.screenamountprofiles())}
        </H2>

        <SettingContainer>
          <P1 color="secondary" weight="normal">
            {translate(LanguageTexts.measuresystem()) + ":"}
          </P1>
          <GenesysSelect
            value={
              state.selectedAmountProfileId ||
              user.settings.selectedAmountProfile.id
            }
            onChange={e => {
              dispatch(Action.changeselectedAmountProfileId(e.target.value));
            }}
            options={user.settings.amountFieldProfiles.map(option => ({
              value: option.id,
              title: option.name
            }))}
          />
        </SettingContainer>
      </RightContainer>
    </Root>
  );
}
