import {
  PropertyFilter,
  PropertyValueSet,
  PropertyValue
} from "@genesys/property";
import { Physics } from "@munters/calculations";
import * as GraphQlTypes from "../../../graphql-types";
import * as Types from "./types";
import { Amount, Quantity, Units } from "@genesys/uom";
import * as KnownProperties from "./known-properties";
import * as PropertiesSelector from "../../../properties-selector";

export function parseSizeInfoPerformance(
  sizeInfoPerfomance:
    | GraphQlTypes.DataCenterEditorProductQuery["product"]["sys"]["sizeInfoPerformance"]
    | null
    | undefined
): ReadonlyArray<Types.SizeInfoPerformance> {
  if (!sizeInfoPerfomance) {
    return [];
  }
  const maxPowerRows = sizeInfoPerfomance.rows.reduce((soFar, current) => {
    const pvs = PropertyValueSet.fromString(current.values);
    const maxProcessFlow = PropertyValueSet.getAmount<Quantity.MassFlow>(
      "MaxProcessFlow",
      pvs
    );
    const maxPower = PropertyValueSet.getAmount<Quantity.Power>(
      "MaxPower",
      pvs
    );
    const filter = PropertyFilter.fromString(
      PropertyValueSet.getText("Filter", pvs) ?? ""
    );

    if (!maxProcessFlow || !maxPower || !filter) {
      return soFar;
    } else {
      return soFar.concat([
        {
          propertyFilter: filter,
          maxProcessFlow: maxProcessFlow,
          maxPower: maxPower
        }
      ]);
    }
  }, [] as ReadonlyArray<Types.SizeInfoPerformance>);

  return maxPowerRows;
}

export function getOptions(
  sizes: ReadonlyArray<Types.Size>,
  designProperties: PropertyValueSet.PropertyValueSet,
  altitude: Amount.Amount<Quantity.Length>,
  atmosphericpressure: Amount.Amount<Quantity.Pressure>
): ReadonlyArray<Types.Option> {
  const iteLoad = PropertyValueSet.getAmount<Quantity.Power>(
    KnownProperties.iteLoad,
    designProperties
  )!;

  const supplyTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.supplyTargetTemperature,
    designProperties
  )!;

  const returnTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.returnAirTemperature,
    designProperties
  )!;

  const returnHumidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    KnownProperties.returnAirHumidity,
    designProperties
  )!;

  const totalVolumeFlow = getTotalVolumeFlow(
    iteLoad,
    supplyTargetTemp,
    returnTargetTemp,
    altitude,
    1
  );

  const redundantUnits = PropertyValueSet.getInteger(
    KnownProperties.numberOfRedundantUnits,
    designProperties
  )!;

  return sizes.map(size => {
    const nominalAirflow = Physics.FlowConversion.massFlowToVolumeFlow(
      returnTargetTemp,
      returnHumidity,
      atmosphericpressure,
      size.maxProcessFlow
    );

    const numberOfUnits = getRequiredNumberOfUnits(
      totalVolumeFlow,
      returnTargetTemp,
      returnHumidity,
      atmosphericpressure,
      size
    );

    const volumeFlowPerUnit = Amount.divide(totalVolumeFlow, numberOfUnits);
    const volumeFlowPerUnitWithRedundancy = Amount.divide(
      totalVolumeFlow,
      numberOfUnits + redundantUnits
    );
    const powerPerUnits = Amount.divide(iteLoad, numberOfUnits);
    const powerPerUnitWithRedundancy = Amount.divide(
      iteLoad,
      numberOfUnits + redundantUnits
    );

    return {
      sizeValue: size.sizeValue,
      numberOfUnits: numberOfUnits,
      nominalAirflow: nominalAirflow,
      volumeFlowPerUnit: volumeFlowPerUnit,
      volumeFlowPerUnitWithRedundancy: volumeFlowPerUnitWithRedundancy,
      powerPerUnit: powerPerUnits,
      powerPerUnitWithRedundancy: powerPerUnitWithRedundancy
    };
  });
}

export function getUpdatedOpcSelectors(
  designSelector: PropertiesSelector.State,
  opcSelectors: ReadonlyArray<{
    readonly id: string;
    readonly state: PropertiesSelector.State;
  }>,
  climateSettings: PropertyValueSet.PropertyValueSet,
  size: Types.Size,
  isNumberOfUnitsSourceUser: boolean
) {
  const designProperties =
    PropertiesSelector.getSelectedProperties(designSelector);

  const iteLoad = PropertyValueSet.getAmount<Quantity.Power>(
    KnownProperties.iteLoad,
    designProperties
  )!;

  const newOpcSelectors = opcSelectors.map(opcs => {
    const propertiesToMergeFromDesign = [KnownProperties.returnAirHumidity]
      .concat(
        PropertyValueSet.getInteger(
          "source_" + KnownProperties.supplyTargetTemperature,
          PropertiesSelector.getSelectedProperties(opcs.state)
        )! === 1
          ? []
          : [KnownProperties.supplyTargetTemperature]
      )
      .concat(
        PropertyValueSet.getInteger(
          "source_" + KnownProperties.returnAirTemperature,
          PropertiesSelector.getSelectedProperties(opcs.state)
        )! === 1
          ? []
          : [KnownProperties.returnAirTemperature]
      );

    const opcPvs = PropertyValueSet.merge(
      PropertyValueSet.keepProperties(
        propertiesToMergeFromDesign,
        designProperties
      ),
      PropertiesSelector.getSelectedProperties(opcs.state)
    );

    const supplyTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
      KnownProperties.supplyTargetTemperature,
      opcPvs
    )!;

    const returnTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
      KnownProperties.returnAirTemperature,
      opcPvs
    )!;

    const returnHumidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      KnownProperties.returnAirHumidity,
      opcPvs
    )!;

    const iteLoadFactor =
      Amount.valueAs(
        Units.Percent,
        PropertyValueSet.getAmount<Quantity.Dimensionless>(
          KnownProperties.iteLoadPercent,
          opcPvs
        )!
      ) / 100;

    const totalVolumeFlow = getTotalVolumeFlow(
      iteLoad,
      supplyTargetTemp,
      returnTargetTemp,
      PropertyValueSet.getAmount<Quantity.Length>(
        KnownProperties.altitude,
        climateSettings
      )!,
      iteLoadFactor
    );

    const requiredNumberOfUnits = isNumberOfUnitsSourceUser
      ? PropertyValueSet.getInteger(
          KnownProperties.numberOfNormalUnits,
          designProperties
        )!
      : getRequiredNumberOfUnits(
          totalVolumeFlow,
          returnTargetTemp,
          returnHumidity,
          PropertyValueSet.getAmount<Quantity.Pressure>(
            KnownProperties.atmosphericPressure,
            climateSettings
          )!,
          size
        );

    const numberOfNormalUnitsOperating = PropertyValue.create(
      "integer",
      requiredNumberOfUnits
    );

    const sortNo = PropertyValueSet.getInteger(KnownProperties.sortNo, opcPvs)!;

    if (sortNo === 0) {
      const targetPowerPerUnit = Amount.divide(iteLoad, requiredNumberOfUnits);
      const unitAirflow = Amount.divide(totalVolumeFlow, requiredNumberOfUnits);

      return {
        ...opcs,
        state: PropertiesSelector.init(
          PropertyValueSet.set(
            KnownProperties.numberOfNormalUnitsOperating,
            numberOfNormalUnitsOperating,
            PropertyValueSet.set(
              KnownProperties.totalNumberOfUnitsOperating,
              numberOfNormalUnitsOperating,
              PropertyValueSet.setAmount<Quantity.Power>(
                KnownProperties.targetPowerPerUnit,
                targetPowerPerUnit,
                PropertyValueSet.setAmount<Quantity.VolumeFlow>(
                  KnownProperties.unitAirflow,
                  unitAirflow,
                  opcPvs
                )
              )
            )
          )
        )
      };
    } else {
      const isNumberOfRedundantUnitsValueSourceUser =
        PropertyValueSet.getInteger(
          "source_" + KnownProperties.numberOfRedundantUnitsOperating,
          opcPvs
        )! === 1;

      const numberOfRedundantUnitsInt = isNumberOfRedundantUnitsValueSourceUser
        ? PropertyValueSet.getInteger(
            KnownProperties.numberOfRedundantUnitsOperating,
            opcPvs
          )!
        : PropertyValueSet.getInteger(
            KnownProperties.numberOfRedundantUnits,
            designProperties
          )!;

      const numberOfRedundantUnitsPv = PropertyValue.create(
        "integer",
        numberOfRedundantUnitsInt
      );

      const targetPowerPerUnit = Amount.divide(
        Amount.times(iteLoad, iteLoadFactor),
        requiredNumberOfUnits + numberOfRedundantUnitsInt
      );
      const unitAirflow = Amount.divide(
        totalVolumeFlow,
        requiredNumberOfUnits + numberOfRedundantUnitsInt
      );
      return {
        ...opcs,
        state: PropertiesSelector.init(
          PropertyValueSet.set(
            KnownProperties.numberOfNormalUnitsOperating,
            numberOfNormalUnitsOperating,
            PropertyValueSet.set(
              KnownProperties.numberOfRedundantUnitsOperating,
              numberOfRedundantUnitsPv,
              PropertyValueSet.set(
                KnownProperties.totalNumberOfUnitsOperating,
                PropertyValue.fromInteger(
                  requiredNumberOfUnits + numberOfRedundantUnitsInt
                ),
                PropertyValueSet.setAmount<Quantity.Power>(
                  KnownProperties.targetPowerPerUnit,
                  targetPowerPerUnit,
                  PropertyValueSet.setAmount<Quantity.VolumeFlow>(
                    KnownProperties.unitAirflow,
                    unitAirflow,
                    opcPvs
                  )
                )
              )
            )
          )
        )
      };
    }
  });

  return newOpcSelectors;
}

export function getUpdatedCustomOpcSelector(
  designSelector: PropertiesSelector.State,
  opcSelector: PropertiesSelector.State,
  climateSettings: PropertyValueSet.PropertyValueSet,
  size: Types.Size
): PropertiesSelector.State {
  const iteLoad = PropertyValueSet.getAmount<Quantity.Power>(
    KnownProperties.iteLoad,
    PropertiesSelector.getSelectedProperties(designSelector)
  )!;

  const opcPvs = PropertiesSelector.getSelectedProperties(opcSelector);

  const supplyTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.supplyTargetTemperature,
    opcPvs
  )!;
  const returnTargetTemp = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.returnAirTemperature,
    opcPvs
  )!;
  const returnHumidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    KnownProperties.returnAirHumidity,
    opcPvs
  )!;

  const iteLoadFactor =
    Amount.valueAs(
      Units.Percent,
      PropertyValueSet.getAmount<Quantity.Dimensionless>(
        KnownProperties.iteLoadPercent,
        opcPvs
      )!
    ) / 100;

  const totalVolumeFlow = getTotalVolumeFlow(
    iteLoad,
    supplyTargetTemp,
    returnTargetTemp,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.altitude,
      climateSettings
    )!,
    iteLoadFactor
  );

  const isNumberOfUnitsSourceUser =
    PropertyValueSet.getInteger(
      "source_" + KnownProperties.numberOfNormalUnitsOperating,
      opcPvs
    ) === 1;

  const numberOfNormalUnitsOperating = isNumberOfUnitsSourceUser
    ? PropertyValueSet.getInteger(
        KnownProperties.numberOfNormalUnitsOperating,
        opcPvs
      )!
    : getRequiredNumberOfUnits(
        totalVolumeFlow,
        returnTargetTemp,
        returnHumidity,
        PropertyValueSet.getAmount<Quantity.Pressure>(
          KnownProperties.atmosphericPressure,
          climateSettings
        )!,
        size
      );

  const numberOfRedundantUnitsOperating = PropertyValueSet.getInteger(
    KnownProperties.numberOfRedundantUnitsOperating,
    opcPvs
  )!;

  const targetPowerPerUnit = Amount.divide(
    Amount.times(iteLoad, iteLoadFactor),
    numberOfNormalUnitsOperating + numberOfRedundantUnitsOperating
  );

  const unitAirflow = Amount.divide(
    totalVolumeFlow,
    numberOfNormalUnitsOperating + numberOfRedundantUnitsOperating
  );

  return PropertiesSelector.init(
    PropertyValueSet.setAmount<Quantity.Power>(
      KnownProperties.targetPowerPerUnit,
      targetPowerPerUnit,
      PropertyValueSet.setAmount<Quantity.VolumeFlow>(
        KnownProperties.unitAirflow,
        unitAirflow,
        PropertyValueSet.set(
          KnownProperties.numberOfNormalUnitsOperating,
          PropertyValue.fromInteger(numberOfNormalUnitsOperating),
          PropertyValueSet.set(
            KnownProperties.totalNumberOfUnitsOperating,
            PropertyValue.fromInteger(
              numberOfNormalUnitsOperating + numberOfRedundantUnitsOperating
            ),
            opcPvs
          )
        )
      )
    )
  );
}

export function getHasValuesChanged(
  initialSettings: {
    readonly designSelector: PropertiesSelector.State;
    readonly opcSelectors: ReadonlyArray<{
      readonly id: string;
      readonly state: PropertiesSelector.State;
    }>;
    readonly selectedSize: number | undefined;
  },
  designSelector: PropertiesSelector.State,
  opcSelectors: ReadonlyArray<{
    readonly id: string;
    readonly state: PropertiesSelector.State;
  }>,
  selectedSize: number | undefined
) {
  if (selectedSize === undefined) {
    return false;
  }

  if (selectedSize !== initialSettings.selectedSize) {
    return true;
  }

  if (
    !PropertyValueSet.equals(
      PropertiesSelector.getSelectedProperties(designSelector),
      PropertiesSelector.getSelectedProperties(initialSettings.designSelector)
    )
  ) {
    return true;
  }

  if (
    opcSelectors.length !== initialSettings.opcSelectors.length ||
    opcSelectors.some(opc => {
      const opcPvs = PropertiesSelector.getSelectedProperties(opc.state);
      const initialOpcSelector = initialSettings.opcSelectors.find(
        iopc => iopc.id === opc.id
      );
      const initialOpcPvs = initialOpcSelector
        ? PropertiesSelector.getSelectedProperties(initialOpcSelector.state)
        : PropertyValueSet.Empty;
      return !PropertyValueSet.equals(opcPvs, initialOpcPvs);
    })
  ) {
    return true;
  }

  return false;
}

export function getTotalVolumeFlow(
  iteLoad: Amount.Amount<Quantity.Power>,
  supplyTargetTemp: Amount.Amount<Quantity.Temperature>,
  returnTargetTemp: Amount.Amount<Quantity.Temperature>,
  altitude: Amount.Amount<Quantity.Length>,
  iteLoadFactor: number
) {
  const altitudeFactor = Math.pow(
    1 - 6.8754 * Math.pow(10, -6) * Amount.valueAs(Units.Foot, altitude),
    -5.2559
  );

  const totalVolumeFlow = Amount.create(
    (5.9605 *
      altitudeFactor *
      (Amount.valueAs(Units.Fahrenheit, supplyTargetTemp) + 459.67) *
      Amount.valueAs(Units.KiloWatt, Amount.times(iteLoad, iteLoadFactor))) /
      (Amount.valueAs(Units.Fahrenheit, returnTargetTemp) -
        Amount.valueAs(Units.Fahrenheit, supplyTargetTemp)),
    Units.CubicFeetPerMinute
  );

  return totalVolumeFlow;
}

export function getRequiredNumberOfUnits(
  totalVolumeFlow: Amount.Amount<Quantity.VolumeFlow>,
  returnTargetTemp: Amount.Amount<Quantity.Temperature>,
  returnHumidity: Amount.Amount<Quantity.HumidityRatio>,
  atmosphericpressure: Amount.Amount<Quantity.Pressure>,
  size: Types.Size
) {
  const maxProcessVolumeFlow = Physics.FlowConversion.massFlowToVolumeFlow(
    returnTargetTemp,
    returnHumidity,
    atmosphericpressure,
    size.maxProcessFlow
  );

  const numberOfUnits = Math.ceil(
    Amount.valueAs(Units.CubicFeetPerMinute, totalVolumeFlow) /
      Amount.valueAs(Units.CubicFeetPerMinute, maxProcessVolumeFlow)
  );
  return numberOfUnits;
}

//tslint:disable-next-line
