import { PropertyValueSet } from "@genesys/property";
import * as Building from "./building-settings";
import * as EmissionFromCombustion from "./load-definititions/emission-from-combustion";
import * as EmissionFromPeople from "./load-definititions/emission-from-people";
import * as EvaporationFromMaterials from "./load-definititions/evaporation-from-materials";
import * as EvaporationOfWater from "./load-definititions/evaporation-of-water";

import * as IntentionalVentilation from "./load-definititions/intentional-ventilation";
import * as StaticMoistureLoad from "./load-definititions/static-moisture-load";
import * as StaticHeatLoad from "./load-definititions/static-heat-load";
import * as UnintendedVentilation from "./load-definititions/unintentional-ventilation";
import * as RoomControlSettings from "./room-control-settings";
import * as InfiltrationModelSettings from "./infiltration-model-settings";
import * as SystemSettings from "./system-settings";
import * as HeatTransmission from "./load-definititions/heat-transmission";
import * as OpenDoor from "./load-definititions/open-door";

import { OperatingCaseGroupEnum } from "../../../graphql-types";
import { getIfIntegerChanged } from "../helper-functions";
import { getTopLevelOverrides } from "./application-settings";
import { InternalGetPropertyUpdatesFunction } from "../property-updates";

export interface InternalGetCaseOverrideUpdatesFunction {
  (
    prevSelections: PropertyValueSet.PropertyValueSet,
    currentSelections: PropertyValueSet.PropertyValueSet,
    currentOverride: PropertyValueSet.PropertyValueSet,
    defaultsToSet: PropertyValueSet.PropertyValueSet
  ): PropertyValueSet.PropertyValueSet;
}

export const caseOverridesData: ReadonlyArray<{
  readonly manualInputProperty: string;
  readonly properties: string[];
  readonly propertyUpdateFunction: InternalGetPropertyUpdatesFunction;
  readonly overrideDefault: {
    readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
  };
}> = [
  {
    manualInputProperty: Building.manualInputProperty,
    properties: Building.allProperties,
    propertyUpdateFunction: Building.getPropertyUpdates,
    overrideDefault: Building.caseOverridesDefault
  },
  {
    manualInputProperty: SystemSettings.manualInputProperty,
    properties: SystemSettings.properties,
    propertyUpdateFunction: SystemSettings.getPropertyUpdates,
    overrideDefault: SystemSettings.caseOverridesDefault
  },
  {
    manualInputProperty: InfiltrationModelSettings.manualInputProperty,
    properties: InfiltrationModelSettings.properties,
    propertyUpdateFunction: InfiltrationModelSettings.getPropertyUpdates,
    overrideDefault: InfiltrationModelSettings.caseOverridesDefault
  },
  {
    manualInputProperty: HeatTransmission.manualInputProperty,
    properties: HeatTransmission.properties,
    propertyUpdateFunction: HeatTransmission.getPropertyUpdates,
    overrideDefault: HeatTransmission.caseOverridesDefault
  },
  {
    manualInputProperty: EmissionFromCombustion.manualInputProperty,
    properties: EmissionFromCombustion.properties,
    propertyUpdateFunction: EmissionFromCombustion.getPropertyUpdates,
    overrideDefault: EmissionFromCombustion.caseOverridesDefault
  },
  {
    manualInputProperty: EmissionFromPeople.manualInputProperty,
    properties: EmissionFromPeople.properties,
    propertyUpdateFunction: EmissionFromPeople.getPropertyUpdates,
    overrideDefault: EmissionFromPeople.caseOverridesDefault
  },
  {
    manualInputProperty: EvaporationFromMaterials.manualInputProperty,
    properties: EvaporationFromMaterials.properties,
    propertyUpdateFunction: EvaporationFromMaterials.getPropertyUpdates,
    overrideDefault: EvaporationFromMaterials.caseOverridesDefault
  },
  {
    manualInputProperty: EvaporationOfWater.manualInputProperty,
    properties: EvaporationOfWater.properties,
    propertyUpdateFunction: EvaporationOfWater.getPropertyUpdates,
    overrideDefault: EvaporationOfWater.caseOverridesDefault
  },
  {
    manualInputProperty: IntentionalVentilation.manualInputProperty,
    properties: IntentionalVentilation.properties,
    propertyUpdateFunction: IntentionalVentilation.getPropertyUpdates,
    overrideDefault: IntentionalVentilation.caseOverridesDefault
  },
  {
    manualInputProperty: StaticMoistureLoad.manualInputProperty,
    properties: StaticMoistureLoad.properties,
    propertyUpdateFunction: StaticMoistureLoad.getPropertyUpdates,
    overrideDefault: StaticMoistureLoad.caseOverridesDefault
  },
  {
    manualInputProperty: StaticHeatLoad.manualInputProperty,
    properties: StaticHeatLoad.properties,
    propertyUpdateFunction: StaticHeatLoad.getPropertyUpdates,
    overrideDefault: StaticHeatLoad.caseOverridesDefault
  },
  {
    manualInputProperty: UnintendedVentilation.manualInputProperty,
    properties: UnintendedVentilation.properties,
    propertyUpdateFunction: UnintendedVentilation.getPropertyUpdates,
    overrideDefault: UnintendedVentilation.caseOverridesDefault
  },
  {
    manualInputProperty: RoomControlSettings.manualInputProperty,
    properties: RoomControlSettings.properties,
    propertyUpdateFunction: RoomControlSettings.getPropertyUpdates,
    overrideDefault: RoomControlSettings.caseOverridesDefault
  },
  {
    manualInputProperty: OpenDoor.manualInputProperty,
    properties: OpenDoor.properties,
    propertyUpdateFunction: OpenDoor.getPropertyUpdates,
    overrideDefault: OpenDoor.caseOverridesDefault
  }
];

export function getCaseOverrideUpdates(
  operatingCaseGroup: OperatingCaseGroupEnum,
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  currentOverride: PropertyValueSet.PropertyValueSet,
  defaultsToSet: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  // Om basproperties manual input = 0 -> se till att overrides för en lasttyp följer grundpropertyvaluesetet
  // om bas properties manual input = 1 -> se till att i overriden så är manual input = 0 och

  let totalDefaults = getTopLevelOverrides(defaultsToSet);
  totalDefaults = PropertyValueSet.merge(
    Building.getPropertyUpdates(
      prevSelections,
      PropertyValueSet.merge(defaultsToSet, currentSelections)
    ),
    totalDefaults
  );

  //   Apply the rule based changes.
  caseOverridesData.forEach(caseOverridesInfo => {
    const result = getCaseOverridesForSource(
      prevSelections,
      currentSelections,
      currentOverride,
      defaultsToSet,
      caseOverridesInfo.manualInputProperty,
      caseOverridesInfo.properties,
      caseOverridesInfo.propertyUpdateFunction,
      caseOverridesInfo.overrideDefault[operatingCaseGroup]
    );

    totalDefaults = PropertyValueSet.merge(result, totalDefaults);
  });

  return totalDefaults;
}

// returns loads updated properties
export function getCaseOverridesForSource(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet,
  currentOverride: PropertyValueSet.PropertyValueSet,
  defaults: PropertyValueSet.PropertyValueSet,
  manualInputProperty: string,
  properties: string[],
  propertyUpdateFunction: InternalGetPropertyUpdatesFunction,
  caseOverrideDefault: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const manualInputValue = getIfIntegerChanged(
    prevSelections,
    currentSelections,
    manualInputProperty
  );

  if (manualInputValue === 0) {
    // If just turned off -> set same as main moisture load settings

    return PropertyValueSet.keepProperties(
      properties,
      PropertyValueSet.merge(defaults, currentSelections)
    );
  } else if (manualInputValue === 1) {
    // if just tuned on -> set same as main moisture load settings and apply the case override defaults
    // Here - if a loadtype has been set to active, set its defaults as well.

    return PropertyValueSet.setInteger(
      manualInputProperty,
      0,
      PropertyValueSet.keepProperties(
        properties,
        PropertyValueSet.merge(
          caseOverrideDefault,
          PropertyValueSet.merge(defaults, currentSelections)
        )
      )
    );
  }

  if (
    PropertyValueSet.getInteger(manualInputProperty, currentSelections) === 0
  ) {
    // If not overriden -> set same as main moisture load settings

    return PropertyValueSet.keepProperties(
      properties,
      PropertyValueSet.merge(defaults, currentSelections)
    );
  }

  defaults = PropertyValueSet.merge(
    propertyUpdateFunction(
      currentOverride,
      PropertyValueSet.merge(defaults, currentOverride)
    ),
    defaults
  );

  // If already overridden -> update with help of defaults
  return PropertyValueSet.keepProperties(
    properties,
    PropertyValueSet.merge(defaults, currentOverride)
  );
}
