import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GraphQLEntityCache from "gql-cache";
import * as GQLCachePatch from "gql-cache-patch";
import * as GraphQLMutationsQueue from "@genesys/graphql-mutations-queue";
import * as Actions from "./actions-user";

export interface State {
  readonly entities: GraphQLEntityCache.EntityCache;
  readonly mutationQueues: {
    readonly [key: string]: GraphQLMutationsQueue.MutationsQueueState;
  };
}
const initialState: State = {
  entities: {},
  mutationQueues: {}
};

export function reducerQueryUser(
  state: State = initialState,
  action: Actions.UserAction
): State {
  switch (action.type) {
    case "query-user/MergeUserEntities": {
      return {
        ...state,
        entities: mergeUserEntities(
          state.entities,
          action.payload.entities,
          action.payload.cachePatches
        )
      };
    }

    case "query-user/queue-mutations": {
      const queue =
        state.mutationQueues[action.queueId] ||
        GraphQLMutationsQueue.EmptyQueue;

      const updatedQueue = GraphQLMutationsQueue.reducer(
        queue,
        GraphQLMutationsQueue.queueMany(action.payload)
      );

      return {
        ...state,
        mutationQueues: {
          ...state.mutationQueues,
          [action.queueId]: updatedQueue
        }
      };
    }

    case "query-user/de-queue-mutations": {
      const queue =
        state.mutationQueues[action.queueId] ||
        GraphQLMutationsQueue.EmptyQueue;

      const updatedQueue = GraphQLMutationsQueue.reducer(
        queue,
        GraphQLMutationsQueue.dequeueMany(action.payload)
      );

      return {
        ...state,
        mutationQueues: {
          ...state.mutationQueues,
          [action.queueId]: updatedQueue
        }
      };
    }

    case "query-user/discard-mutations-queue": {
      return {
        ...state,
        mutationQueues: {
          ...state.mutationQueues,
          [action.queueId]: GraphQLMutationsQueue.EmptyQueue
        }
      };
    }

    case "query-user/MergeUserEntitiesAndDeuqueQueuedMutations": {
      const queue =
        state.mutationQueues[action.payload.queueId] ||
        GraphQLMutationsQueue.EmptyQueue;

      const updatedQueue = GraphQLMutationsQueue.reducer(
        queue,
        GraphQLMutationsQueue.dequeueMany(action.payload.mutationsToDequeue)
      );
      return {
        ...state,
        entities: mergeUserEntities(
          state.entities,
          action.payload.entities,
          action.payload.cachePatches
        ),
        mutationQueues: {
          ...state.mutationQueues,
          [action.payload.queueId]: updatedQueue
        }
      };
    }

    case "query-user/ApplyCachePathes": {
      return {
        ...state,
        entities: mergeUserEntities(state.entities, {}, action.payload)
      };
    }

    default:
      exhaustiveCheck(action);
      return state;
  }
}

function mergeUserEntities(
  stateEntities: GraphQLEntityCache.EntityCache,
  newEntities: GraphQLEntityCache.EntityCache,
  cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>
): GraphQLEntityCache.EntityCache {
  const mergedCache = GraphQLEntityCache.mergeEntityCache(
    stateEntities,
    newEntities
  );

  const [cachePatchesAppliedCache, staleEntities] = GQLCachePatch.apply(
    cachePatches,
    mergedCache,
    GraphQLMutationsQueue.getStaleEntities(mergedCache)
  );

  const updatedStaleEntities = GraphQLEntityCache.updateStale(
    newEntities,
    staleEntities
  );

  return GraphQLMutationsQueue.setStaleEntities(
    cachePatchesAppliedCache,
    updatedStaleEntities
  );
}
