import * as LabelManager from "../label-manager/state";
import * as GraphQLTypes from "../graphql-types";
import * as SharedState from "../shared-state";
import * as Types from "./types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import {
  deleteMoistureLoadFilesMutation,
  createNewMoistureLoadRevisionMutation,
  updateMoistureLoadFileMutation,
  copyMoistureLoadToNewFile
} from "@genesys/client-core/lib/graphql-mutations";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type State = {
  readonly labelManagerState: LabelManager.State | undefined;
  readonly input: string;
  readonly content: Types.ContentType;
  readonly status: Types.StatusType;

  // readonly mode: Mode;
  // readonly hasPerformedMutation: boolean;
};

export const init = (): [State] => {
  return [
    {
      labelManagerState: undefined,
      input: "",
      content: { type: "list" },
      status: { type: "ready" }
    }
  ];
};

export const Action = ctorsUnion({
  actionCompleted: (actionStatus: Types.StatusType) => ({ actionStatus }), // action
  copyMoistureLoadToNewFile: (moistureLoadId: string) => ({ moistureLoadId }), // action
  //   copyMoistureLoadComplete: (moistureLoadNo: number) => ({ moistureLoadNo }), // action
  createNewRevision: (moistureLoadFileID: string, name: string) => ({
    // action
    moistureLoadFileID,
    name
  }),
  dispatchLabelManager: (action: LabelManager.Action) => ({ action }),
  deleteMoistureLoad: (moistureLoadFileIds: ReadonlyArray<string>) => ({
    // action
    moistureLoadFileIds
  }),
  setContent: (content: Types.ContentType) => ({ content }),
  updateMoistureLoadFile: (
    input: GraphQLTypes.UpdateMoistureLoadFileVariables,
    updateType: Types.UpdateMoistureLoadFileType
  ) => ({ input, updateType })
  //   setInput: (input: string) => ({ input })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "actionCompleted": {
      return [
        {
          ...state,
          content: { type: "list" },
          status: action.actionStatus
        }
      ];
    }

    case "createNewRevision": {
      const status = { type: "in-progress" } as Types.StatusType;
      return [
        {
          ...state,
          content: { type: "list" },
          status
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CreateNewMoistureLoadRevision,
          GraphQLTypes.CreateNewMoistureLoadRevisionVariables,
          Action
        >(
          createNewMoistureLoadRevisionMutation,
          {
            moistureLoadFileId: action.moistureLoadFileID,
            name: action.name
          },
          data =>
            Action.actionCompleted({
              type: "new-revision-created",
              moistureLoadNo: data.createNewMoistureLoadRevision.moistureLoadNo,
              revisionNo: data.createNewMoistureLoadRevision.revisionNo
            })
        )
      ];
    }

    case "deleteMoistureLoad": {
      const status = { type: "in-progress" } as Types.StatusType;
      return [
        {
          ...state,
          status
        },

        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeleteMoistureLoadFiles,
          GraphQLTypes.DeleteMoistureLoadFilesVariables,
          Action
        >(
          deleteMoistureLoadFilesMutation,
          {
            moistureLoadFileIds: action.moistureLoadFileIds
          },
          data =>
            Action.actionCompleted(
              data.deleteMoistureLoadFiles.deletionSuccessful
                ? {
                    type: "moisture-load-deleted",
                    hasError: false
                  }
                : {
                    type: "moisture-load-deleted",
                    hasError: true,
                    message: data.deleteMoistureLoadFiles.errorMessage
                  }
            )
        )
      ];
    }

    case "dispatchLabelManager": {
      const [labelManagerState, sharedStateActions] = LabelManager.update(
        action.action,
        state.labelManagerState!
      );

      return [
        {
          ...state,
          labelManagerState: labelManagerState
        },
        undefined,
        sharedStateActions
      ];
    }

    case "copyMoistureLoadToNewFile": {
      const status = { type: "in-progress" } as Types.StatusType;
      return [
        {
          ...state,
          status
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CopyMoistureLoadToNewFile,
          GraphQLTypes.CopyMoistureLoadToNewFileVariables,
          Action
        >(
          copyMoistureLoadToNewFile,
          {
            moistureLoadId: action.moistureLoadId
          },
          data => {
            return Action.actionCompleted({
              type: "moisture-load-copied-to-new-file",
              moistureLoadNo: data.copyMoistureLoadToNewFile.moistureLoadNo
            });
          }
        )
      ];
    }

    case "setContent": {
      if (action.content.type === "label") {
        const [labelManagerState] = LabelManager.init(
          action.content.moistureLoadFile.labels
        );

        return [
          {
            ...state,
            status: { type: "ready" },
            content: action.content,
            labelManagerState
          }
        ];
      }

      return [
        {
          ...state,
          status: { type: "ready" },
          content: action.content
        }
      ];
    }

    case "updateMoistureLoadFile": {
      const status = { type: "in-progress" } as Types.StatusType;
      return [
        {
          ...state,
          content: { type: "list" },
          status
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.UpdateMoistureLoadFile,
          GraphQLTypes.UpdateMoistureLoadFileVariables,
          Action
        >(updateMoistureLoadFileMutation, action.input, data =>
          Action.actionCompleted({
            type: "moisture-load-file-updated",
            errorMessage: data.updateMoistureLoadFile.errorMessage,
            updateType: action.updateType
          })
        )
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
