import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";

export function validateProductSelection(
  properties: ReadonlyArray<{
    readonly name: string;
    readonly quantity: string;
    readonly validation_filter: PropertyFilter.PropertyFilter;
    readonly value: ReadonlyArray<{
      readonly value: PropertyValue.PropertyValue;
      readonly property_filter: PropertyFilter.PropertyFilter;
    }>;
  }>,
  selectedProperties: PropertyValueSet.PropertyValueSet
): boolean {
  const allValid = properties.every(property => {
    const selectedValue = PropertyValueSet.getValue(
      property.name,
      selectedProperties
    );

    const selectedValueItem =
      property.value &&
      property.value.find(
        value =>
          (value.value === undefined && selectedValue === undefined) ||
          (value.value && PropertyValue.equals(selectedValue, value.value))
      );

    switch (getPropertyType(property.quantity)) {
      case "integer": {
        const isValid = selectedValueItem
          ? PropertyFilter.isValid(
              selectedProperties,
              selectedValueItem.property_filter
            )
          : false;
        return isValid;
      }
      case "amount": {
        const isValid =
          property.validation_filter &&
          PropertyFilter.isValid(
            selectedProperties,
            property.validation_filter
          );
        return isValid;
      }
      default: {
        return true;
      }
    }
  });

  return allValid;
}

function getPropertyType(quantity: string): PropertyValue.PropertyType {
  switch (quantity) {
    case "Text":
      return "text";
    case "Discrete":
      return "integer";
    default:
      return "amount";
  }
}
