import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const recoverSystemFileMutation = gql`
  mutation RecoverSystemFileMutation($systemFileId: ID!) {
    recoverSystemFile(systemFileId: $systemFileId) {
      ...CompleteSystemFile
    }
  }
  ${Fragments.completeSystemFile}
`;
