import { PropertyValueSet } from "@genesys/property";
import * as Types from "./types";
import { Amount, Quantity, Units } from "@genesys/uom";

export function getAirflows(
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>
): Types.AirFlows {
  const airFlows = operatingCases.map(opc => {
    const outletFlowPropertyName = PropertyValueSet.hasProperty(
      "supplyoutletairflow",
      opc
    )
      ? "supplyoutletairflow"
      : "reactoutletairflow";

    const outletFlow = PropertyValueSet.hasProperty(outletFlowPropertyName, opc)
      ? PropertyValueSet.getAmount<Quantity.MassFlow>(
          outletFlowPropertyName,
          opc
        )!
      : Amount.create(0, Units.KilogramPerSecond);

    return outletFlow;
  });

  const sortedAirFlows = airFlows.sort((a, b) =>
    Amount.lessThan(a, b) ? -1 : 1
  );

  return {
    minAirFlow: sortedAirFlows[0],
    maxAirFlow: sortedAirFlows[sortedAirFlows.length - 1]
  };
}
