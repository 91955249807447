import * as Types from "./types";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { PropertyItem } from "../properties-selector";
import { Quantity, Amount, Units } from "@genesys/uom";

export function getBinDataLocationDefinitions(
  groupedLocations: Types.GroupedLocations,
  selectedCountryIndex: number,
  selectedRegionIndex: number,
  propertyNames: {
    readonly countryIndex: string;
    readonly regionIndex: string;
    readonly locationIndex: string;
  }
) {
  const country = groupedLocations[selectedCountryIndex];
  const region = country.regions[selectedRegionIndex];

  function customTranslation(propertyName: string, propertyValue: number) {
    switch (propertyName) {
      case propertyNames.countryIndex:
        return groupedLocations[propertyValue].countryName;

      case propertyNames.regionIndex:
        return groupedLocations[selectedCountryIndex].regions[propertyValue]
          .regionName;

      case propertyNames.locationIndex:
        return groupedLocations[selectedCountryIndex].regions[
          selectedRegionIndex
        ].locations[propertyValue].locationName;

      default:
        return "";
    }
  }

  return [
    {
      sortNo: 0,
      name: propertyNames.countryIndex,
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: [],
      valueSources: [],
      items: groupedLocations.map((_, index) =>
        makeOption(index, propertyNames.countryIndex, customTranslation)
      )
    },
    {
      sortNo: 1,
      name: propertyNames.regionIndex,
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: [],
      valueSources: [],
      items: country.regions.map((_, index) =>
        makeOption(index, propertyNames.regionIndex, customTranslation)
      )
    },
    {
      sortNo: 2,
      name: propertyNames.locationIndex,
      group: "",
      quantity: "Discrete",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: [],
      valueSources: [],
      items: region.locations.map((_, index) =>
        makeOption(index, propertyNames.locationIndex, customTranslation)
      )
    }
  ];
}

export function groupLocations(
  locations: ReadonlyArray<Types.BinDataLocation>
): Types.GroupedLocations {
  return makeUnique(
    locations
      .map(location => location.countryName)
      .sort((a, b) => a.localeCompare(b))
  ).map(countryName => ({
    countryName,
    regions: makeUnique(
      locations
        .filter(location => location.countryName === countryName)
        .sort((a, b) => a.regionName.localeCompare(b.regionName))
        .map(location => location.regionName)
    ).map(regionName => ({
      regionName,
      locations: locations
        .filter(
          location =>
            location.countryName === countryName &&
            location.regionName === regionName
        )
        .sort((a, b) => a.locationName.localeCompare(b.locationName))
    }))
  }));
}
export function makeUnique(
  array: ReadonlyArray<string>
): ReadonlyArray<string> {
  return Array.from(new Set(array));
}

export function getDefaultLocation(
  binLocations: ReadonlyArray<Types.BinDataLocation>,
  coordinate?: Types.Coordinate
) {
  return coordinate
    ? closestPointOnSphere(binLocations, coordinate)
    : binLocations[0];
}

export function getCoordinateFromClimateDataProperties(
  climateDataProperties: PropertyValueSet.PropertyValueSet
): Types.Coordinate | undefined {
  const latAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    manualData.latitudeN,
    climateDataProperties
  );
  const latitude = latAmount && Amount.valueAs(Units.Degrees, latAmount);
  const longAmount = PropertyValueSet.getAmount<Quantity.Angle>(
    manualData.longitudeW,
    climateDataProperties
  );
  const longitude = longAmount && Amount.valueAs(Units.Degrees, longAmount);
  const coordinate =
    latitude !== undefined && longitude !== undefined
      ? {
          latitude,
          longitude
        }
      : undefined;

  return coordinate;
}

export type GroupedLocations = ReadonlyArray<{
  readonly countryName: string;
  readonly regions: ReadonlyArray<{
    readonly regionName: string;
    readonly locations: ReadonlyArray<Types.BinDataLocation>;
  }>;
}>;

function closestPointOnSphere<T extends Types.Coordinate>(
  locations: ReadonlyArray<T>,
  to: Types.Coordinate
): T {
  let closestLoction = locations[0];
  let closestDistance = Infinity;

  for (const location of locations) {
    const distance: number = geodesicDistance(to, location);

    if (distance < closestDistance) {
      closestLoction = location;
      closestDistance = distance;
    }
  }

  return closestLoction;
}

export const manualData = {
  wmo: "wmo", // Id of the location
  locationName: "locationname",
  occurrences: "occurrences",
  altitude: "altitude",
  customPressure: "custompressure",
  atmosphericPressure: "atmosphericpressure",
  summerTemperature: "summertemperature",
  winterTemperature: "wintertemperature",
  summerHumidity: "summerhumidity",
  winterHumidity: "winterhumidity",
  summerWindSpeed: "summerwindspeed",
  winterWindSpeed: "winterwindspeed",
  coolingAnnualOccurence: "coolingannualoccurence",
  heatingAnnualOccurence: "heatingannualoccurence",
  latitudeN: "climatelatituden",
  longitudeW: "climatelongitudew",
  binLocationId: "climatebinLocationid",
  coolingDataType: "climatecoolingdatatype",
  heatingDataType: "climateheatingdatatype"
};

function geodesicDistance(a: Types.Coordinate, b: Types.Coordinate) {
  const dlon = radians(b.longitude - a.longitude);
  const dlat = radians(b.latitude - a.latitude);

  const alpha =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(radians(a.latitude)) *
      Math.cos(radians(b.latitude)) *
      (Math.sin(dlon / 2) * Math.sin(dlon / 2));
  const angle = 2 * Math.atan2(Math.sqrt(alpha), Math.sqrt(1 - alpha));

  return angle * 6378.16;
}

function radians(x: number) {
  return (x * Math.PI) / 180;
}

function makeOption(
  i: number,
  propertyName: string,
  translate: (propertyName: string, propertyValue: number) => string,
  propertyFilter?: PropertyFilter.PropertyFilter
): PropertyItem {
  return {
    id: propertyName + i,
    sortNo: i,
    value: {
      type: "integer",
      value: i
    },
    text: translate(propertyName, i),
    validationFilter: propertyFilter || PropertyFilter.Empty,
    descriptionValuesTexts: [],
    rangeFilter: PropertyFilter.Empty
  };
}
