import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import { Label } from "@genesys/ui-elements";
import { Action } from "./state";
import { ActionsContext, ContextValue } from "./context";

interface ActionProps {
  readonly onClick?: () => void;
}

function menuItem(
  action: Action,
  textDefinition: LanguageTexts.TextDefinition,
  onClick?: () => void
) {
  const { dispatch, translate } = React.useContext<ContextValue>(
    ActionsContext as any
  );

  return (
    <button
      onClick={() => {
        dispatch(action);
        if (onClick) {
          onClick();
        }
      }}
    >
      <Label weight="bold" color="secondary">
        {translate(textDefinition)}
      </Label>
    </button>
  );
}

export function CopyTransferOpen(
  props: ActionProps & { readonly moistureLoadId: string }
) {
  return menuItem(
    Action.copyMoistureLoadToNewFile(props.moistureLoadId),
    LanguageTexts.copyTransferOpen(),
    props.onClick
  );
}

export function Rename(
  props: ActionProps & { readonly moistureLoadFile: Types.MoistureLoadFile }
) {
  return menuItem(
    Action.setContent({
      type: "rename",
      moistureLoadFile: props.moistureLoadFile
    }),
    LanguageTexts.rename(),
    props.onClick
  );
}

export function CreateNewRevision(
  props: ActionProps & { readonly moistureLoadFile: Types.MoistureLoadFile }
) {
  return menuItem(
    Action.setContent({
      type: "revision",
      moistureLoadFile: props.moistureLoadFile
    }),
    LanguageTexts.createnewrevision(),
    props.onClick
  );
}

export function AssignLabels(
  props: ActionProps & { readonly moistureLoadFile: Types.MoistureLoadFile }
) {
  return menuItem(
    Action.setContent({
      type: "label",
      moistureLoadFile: props.moistureLoadFile
    }),
    LanguageTexts.assignlabels(),
    props.onClick
  );
}

export function Delete(
  props: ActionProps & { readonly moistureLoadFileId: string }
) {
  return menuItem(
    Action.setContent({
      type: "delete",
      moistureLoadFileId: props.moistureLoadFileId
    }),
    LanguageTexts.globalPropertyName("delete"),
    props.onClick
  );
}

export function CopyLatestRevToNewFile(
  props: ActionProps & { readonly moistureLoadFile: Types.MoistureLoadFile }
) {
  return menuItem(
    Action.copyMoistureLoadToNewFile(
      props.moistureLoadFile.latestMoistureLoad.id
    ),
    LanguageTexts.copylatestrevision(),
    props.onClick
  );
}

export function TransferToUser(
  props: ActionProps & { readonly moistureLoadFile: Types.MoistureLoadFile }
) {
  return menuItem(
    Action.setContent({
      type: "transfer",
      moistureLoadFile: props.moistureLoadFile
    }),
    LanguageTexts.transfertouser(),
    props.onClick
  );
}

export function CustomAction(props: {
  readonly onClick: () => void;
  readonly text: string;
}) {
  return (
    <button
      onClick={() => {
        props.onClick();
      }}
    >
      <Label weight="bold" color="secondary">
        {props.text}
      </Label>
    </button>
  );
}
