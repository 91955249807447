import * as Actions from "./actions";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { OperationTime } from "./types";
import { presets } from "./presets";

export type State = OperationTime | undefined;

const initialState: State = presets.officeHours;

export function reducer(
  state: State = initialState,
  action: Actions.Action
): State {
  switch (action.type) {
    case "operation-time-dialog/CHANGE":
      return action.operationTime;
    case "operation-time-dialog/DUMMY":
      return;
    default:
      exhaustiveCheck(action);
      return state;
  }
}
