import * as React from "react";
import { Action, AbstractImageState } from "../state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../../shared-state";
import { PropertyValue } from "@genesys/property";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import { Quantity, UnitFormat, UnitsFormat } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AbstractImage from "abstract-image";
import {
  Spinner,
  StandardButton,
  OverlayLoader,
  GenesysSelect
} from "@genesys/ui-elements";
import { Root, ExportBox, SpinnerBox, FlowDiagramBox } from "../elements";
import * as FetchDocument from "@genesys/client-core/lib/fetch-document";
import { clientConfig } from "../../../../config";
import { exportOptions } from "../export-options";

export function AbstractImagePidView({
  state,
  sharedState,
  systemTypeId,
  dispatch
}: {
  readonly state: AbstractImageState;
  readonly systemTypeId: string;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!state.systemInfo) {
    return (
      <SpinnerBox>
        <Spinner />
      </SpinnerBox>
    );
  }

  const getAmountFormatString = (
    fieldGroup: string,
    fieldName: string,
    quantity: Quantity.Quantity,
    property: PropertyValue.PropertyValue
  ) => {
    const amountFormat = sharedState.screenAmounts.getAmountFormat(
      fieldGroup,
      fieldName,
      quantity
    );

    const unitFormat = UnitFormat.getUnitFormat(
      amountFormat.unit,
      UnitsFormat
    )?.label;

    return `${getValue(property, amountFormat)} ${unitFormat}`;
  };

  const diagram = FlowDiagram.buildDiagram(
    false,
    "PidDiagram",
    false,
    state.flowDiagramProducts,
    state.systemInfo.flowDiagram,
    (productId, propertyName, propertyValue) =>
      sharedState.translate(
        LanguageTexts.productPropertyValue(
          productId,
          propertyName,
          propertyValue
        )
      ),
    translationKey =>
      sharedState.translate(
        LanguageTexts.dynamicText(translationKey, systemTypeId)
      ),
    translationKey =>
      sharedState.translate(
        LanguageTexts.curlyTranslate(translationKey, systemTypeId)
      ),
    getAmountFormatString,
    40
  );

  const abstractDiagram = FlowDiagram.buildAbstractImage(
    diagram,
    [],
    [],
    [],
    state.symbols,
    40
  );

  const svgDiagram = AbstractImage.createReactSvg(abstractDiagram);

  return (
    <Root>
      {state.isLoading && <OverlayLoader />}

      <ExportBox>
        <GenesysSelect
          width={50}
          height={28}
          fontSize={14}
          options={exportOptions}
          value={state.selectedFormat.apiValue}
          onChange={e => dispatch(Action.setFormat(e.target.value))}
        />
        <FetchDocument.FetchDocumentComponent
          authentication={{
            type: "Bearer",
            accessToken: sharedState.accessToken
          }}
          params={{
            format: state.selectedFormat.apiValue,
            systemId: state.systemInfo.id
          }}
          type={"download"}
          filename={
            state.systemInfo.name + "." + state.selectedFormat.fileExtension
          }
          url={`${clientConfig.genesysBackend}/internal/GetPidDiagram`}
          onComplete={() => dispatch(Action.setIsLoading(false))}
        >
          {onClick => (
            <StandardButton
              buttonType="Primary"
              size="Small"
              onClick={() => {
                dispatch(Action.setIsLoading(true));
                onClick();
              }}
            >
              {sharedState.translate(LanguageTexts.exportText())}
            </StandardButton>
          )}
        </FetchDocument.FetchDocumentComponent>
      </ExportBox>

      <FlowDiagramBox>{svgDiagram}</FlowDiagramBox>
    </Root>
  );
}
