import gql from "graphql-tag";

export const updateSystemMutation = gql`
  mutation UpdateSystemStatus($input: UpdateSystemInputType!) {
    updateSystem(input: $input) {
      __typename
      ... on UpdateSystemSuccessResultType {
        status
        traceId
      }

      ... on UpdateSystemRejectResultType {
        rejectReason
        traceId
      }
    }
  }
`;

export const updateSystemNonBlockingMutation = gql`
  mutation updateSystemNonBlocking($input: UpdateSystemInputType!) {
    updateSystemNonBlocking(input: $input) {
      __typename
      traceId
    }
  }
`;
