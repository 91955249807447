import * as React from "react";
import { LoadedState } from "../state";
import {
  GroupedParallellNodesElement,
  StandardTimerLogElement
} from "./timer-elements";
import { TimerViewRoot } from "../elements";

export function TimersView({
  state
}: {
  readonly state: LoadedState;
}): JSX.Element {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  React.useLayoutEffect(() => {
    if (ref.current) {
      const rootTop = ref.current.getBoundingClientRect().top + 20;
      const rootHeight = window.innerHeight - rootTop;
      ref.current.style.height = `${rootHeight}px`;
    }
  });

  const singleNodeTimerLogs = state.topLevelNodes.singleNodes.map(sn => (
    <StandardTimerLogElement singleNode={sn} shouldBeExpanded={true} />
  ));

  const multipleParallellNodeTimerLog = state.topLevelNodes
    .multipleParallellNode && (
    <GroupedParallellNodesElement
      node={state.topLevelNodes.multipleParallellNode}
    />
  );

  return (
    <TimerViewRoot>
      {singleNodeTimerLogs.length === 0 &&
        multipleParallellNodeTimerLog !== undefined && (
          <div>Could not load timers</div>
        )}

      {singleNodeTimerLogs.map((timerLog, ix) => (
        <div key={"key" + ix}>{timerLog}</div>
      ))}

      {multipleParallellNodeTimerLog && (
        <div>{multipleParallellNodeTimerLog}</div>
      )}
    </TimerViewRoot>
  );
}
