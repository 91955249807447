import gql from "graphql-tag";

export const systemItemFragment = gql`
  fragment SystemManagerUserResultSystem on SystemItemType {
    id
    comment
    revisionNo
    status
    statusDate
    systemLabel
    lastModifiedBy {
      id
      userName
    }
  }
`;

export const searchResult = gql`
  fragment SystemManagerUserSearchResult on SystemFileType {
    id
    name
    genesysNo
    systemTypeId
    systemLabel
    isDeleted

    deletedBy {
      id
      userName
    }

    systems {
      ...SystemManagerUserResultSystem
    }

    labels {
      id
      name
    }

    owner {
      id
      userName
    }
  }
  ${systemItemFragment}
`;

export const queryUser = gql`
  query SystemManagerUser($filter: SystemSearchFilterInputType!) {
    user {
      searchSystems(filter: $filter) {
        id
        totalResults
        results {
          ...SystemManagerUserSearchResult
        }
      }
    }
  }

  ${searchResult}
`;

export const queryProduct = gql`
  query SystemManagerProduct {
    product {
      genesysNumberPrefixes {
        id
        genesysNo
      }
      systemTypes {
        id
        systemTypeStatus
      }
    }
  }
`;
