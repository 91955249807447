import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { promiseCmd } from "../../../promise-effect-manager";
import { getIfIntegerChanged, getPropertiesChanged } from "../../tools";
import { State, Action, UpdateReturnType } from "./types";
import {
  saveAndCalcMoistureLoadMutation,
  lockMoistureLoad,
  updateMoistureLoadInputsMutation,
  setMoistureLoadACLMutation
} from "@genesys/client-core/lib/graphql-mutations";
import { buildInitialState } from "../build-states";
import {
  getLoadsOperationTimes,
  getNewSystemOverrides,
  fechUserQuery,
  createMoistureLoadInputSettingsForCalc
} from "../shared";
import { handleMLActionStatus } from "./actions-handler";
import { updatedPropertiesPreHook } from "./tools";
import * as SharedState from "../../../shared-state";
import * as PropertiesSelector from "../../../properties-selector";
import * as GraphQLTypes from "../../../graphql-types";
import * as ClimateSettingsEditor from "../../components/climate-settings-editor";
import * as IndoorSettingsEditor from "../../components/indoor-settings-editor";
import * as Main from "../../main-content";
import * as PrintParams from "../../../print-params";
import * as MoistureLoadActions from "../../../moisture-load-actions";
import * as KnownProperties from "../../tools/known-properties";

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  switch (action.type) {
    case "calculationQueryRecieved": {
      return [
        {
          ...state,
          calculating: false,
          userQuery: action.userQuery,
          developerSettings: {
            ...state.developerSettings,
            logsTraceId: action.data.logTraceId
          },
          calcResult: action.data
        }
      ];
    }

    case "calculate": {
      const binDataInfo = ClimateSettingsEditor.getBinDataInfo(
        state.climateSettingsEditorState!
      );
      if (!binDataInfo) {
        return [state];
      }

      const loadsOperationTime = getLoadsOperationTimes(state);
      const caseOverrides = Object.keys(
        GraphQLTypes.OperatingCaseGroupEnum
      ).map((operatingCaseGroup: GraphQLTypes.OperatingCaseGroupEnum) => ({
        operatingCaseGroup: operatingCaseGroup,
        moistureLoadSettings: PropertyValueSet.toString(
          state.currentSystemOverrides![operatingCaseGroup]
        )
      }));

      const inputSettings = createMoistureLoadInputSettingsForCalc(
        state,
        binDataInfo,
        loadsOperationTime,
        caseOverrides
      );

      return [
        {
          ...state,
          calculating: true,
          calcResult: undefined,
          mainContentState: Main.moveToResultPage(state.mainContentState!)
        },

        promiseCmd(
          async () => {
            const res = await sharedState.graphQL.queryUser<
              GraphQLTypes.SaveMoistureLoadInputsAndCalculate,
              GraphQLTypes.SaveMoistureLoadInputsAndCalculateVariables
            >(saveAndCalcMoistureLoadMutation, {
              moistureLoadId: state.moistureLoadId!,
              moistureLoadinputs: {
                logAllSteps: state.developerSettings.showLogs,
                showDevView: state.developerSettings.showDevResults,
                ...inputSettings
              }
            });

            const newUserQuery = await fechUserQuery(
              sharedState,
              state.moistureLoadNo,
              state.revisionNo
            );

            return {
              calculationResults: res,
              userQuery: newUserQuery
            };
          },
          data =>
            Action.calculationQueryRecieved(
              data.calculationResults.saveMoistureLoadInputsAndCalculate,
              data.userQuery
            )
        )
      ];
    }

    case "dispatchMoistureLoadAction": {
      if (!state.moistureloadActionState) {
        return [state];
      }
      const [
        moistureloadActionState,
        moistureloadActionsCmd,
        sharedStateActions
      ] = MoistureLoadActions.update(
        action.action,
        state.moistureloadActionState,
        sharedState
      );

      const [updatedState, cmd, newSharedStateActions] = handleMLActionStatus(
        moistureloadActionState.status,
        { ...state, moistureloadActionState },
        sharedState
      );
      let combinedActions: Array<SharedState.Action | undefined> = [];
      if (sharedStateActions) {
        combinedActions = [...sharedStateActions];
      }

      if (newSharedStateActions) {
        combinedActions = [...combinedActions, ...newSharedStateActions];
      }

      return [
        updatedState,
        Cmd.batch([
          Cmd.map(Action.dispatchMoistureLoadAction, moistureloadActionsCmd),
          cmd
        ]),
        combinedActions
      ];
    }

    case "dispatchClimateSettingsEditor": {
      const [
        climateAndRoomEditorState,
        climateAndRoomEditorCmd,
        sharedStateAction,
        resultIsInvalid
      ] = ClimateSettingsEditor.update(
        action.action,
        state.climateSettingsEditorState!,
        sharedState
      );

      return [
        {
          ...state,
          inputsUpdated: !!resultIsInvalid,
          calcResult: resultIsInvalid ? undefined : state.calcResult,
          mainContentState: resultIsInvalid
            ? Main.removeResult(state.mainContentState!)
            : state.mainContentState!,
          climateSettingsEditorState: climateAndRoomEditorState
        },
        Cmd.map(Action.dispatchClimateSettingsEditor, climateAndRoomEditorCmd),
        sharedStateAction
      ];
    }

    case "dispatchIndoorSettingsEditor": {
      const [indoorSettingsEditorState, currentSystemOverrides] =
        IndoorSettingsEditor.update(
          action.action,
          state.indoorSettingsEditorState!,
          sharedState,
          state.currentSystemOverrides!
        );
      return [
        {
          ...state,
          calcResult: undefined,
          inputsUpdated: true,
          mainContentState: Main.removeResult(state.mainContentState!),
          currentSystemOverrides: currentSystemOverrides,
          indoorSettingsEditorState
        }
      ];
    }

    case "dispatchMainContent": {
      const [
        updatedMainState,
        newSysteOverrides,
        updatedMoistureLoadSettings,
        resultIsInvalid,
        mainContentCmd
      ] = Main.update(
        action.action,
        state.mainContentState!,
        sharedState,
        PropertiesSelector.getSelectedProperties(
          state.propertiesSelectorState!
        ),
        state.currentSystemOverrides!
      );

      return [
        {
          ...state,
          inputsUpdated: state.inputsUpdated
            ? state.inputsUpdated
            : !!resultIsInvalid,
          calcResult: resultIsInvalid ? undefined : state.calcResult,
          mainContentState: updatedMainState,
          propertiesSelectorState: {
            ...state.propertiesSelectorState,
            properties: updatedMoistureLoadSettings
          },
          // currentSystemOverrides: newSysteOverrides
          currentSystemOverrides: getNewSystemOverrides(
            PropertiesSelector.getSelectedProperties(
              state.propertiesSelectorState!
            ),
            updatedMoistureLoadSettings,
            newSysteOverrides!,
            PropertyValueSet.Empty
          )
        },
        Cmd.map(Action.dispatchMainContent, mainContentCmd)
      ];
    }
    case "dispatchPropertiesSelector": {
      const oldProperties = PropertiesSelector.getSelectedProperties(
        state.propertiesSelectorState!
      );

      const [
        newPropertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.propertiesSelectorState!,
        sharedState,
        "SkipCalculateProperties",
        selectedProperties => {
          return updatedPropertiesPreHook(
            oldProperties,
            selectedProperties,
            state.propertyDefinitions!
          );
        }
      );

      let updatedProperties = PropertiesSelector.getSelectedProperties(
        newPropertiesSelectorState!
      );

      const applicationTypeChanged = getIfIntegerChanged(
        oldProperties,
        updatedProperties,
        KnownProperties.applicationType
      );
      const resultStateRemoved = Main.removeResult(state.mainContentState!);

      let updatedMainState = resultStateRemoved;

      updatedMainState = Main.updateSelectedLoadState(
        updatedMainState,
        updatedProperties
      );

      if (applicationTypeChanged !== undefined) {
        updatedMainState = Main.updateForApplicationTypeChange(
          resultStateRemoved,
          updatedProperties
        );
      }

      const changedPropertyNames = getPropertiesChanged(
        oldProperties,
        updatedProperties
      );

      return [
        {
          ...state,
          calcResult: undefined,
          inputsUpdated: true,

          currentSystemOverrides: getNewSystemOverrides(
            oldProperties,
            updatedProperties,
            state.currentSystemOverrides!,
            PropertyValueSet.keepProperties(
              changedPropertyNames,
              updatedProperties
            )
          ),
          mainContentState: updatedMainState,
          propertiesSelectorState: newPropertiesSelectorState
        },
        Cmd.map(
          actionMap =>
            Action.dispatchPropertiesSelector(
              actionMap,
              action.stateChangedFrom
            ),
          propertiesSelectorCmd
        ),
        [propertiesSelectorSharedAction]
      ];
    }

    case "dispatchPrintParams": {
      const [printParamsState] = PrintParams.update(
        action.action,
        state.printParamsState!
      );

      return [
        {
          ...state,
          printParamsState
        }
      ];
    }

    case "lockMoistureLoad": {
      return [
        {
          ...state,
          showLoader: true
        },
        promiseCmd(
          async () => {
            await sharedState.graphQL.queryUser<
              GraphQLTypes.LockMoistureLoad,
              GraphQLTypes.LockMoistureLoadVariables
            >(lockMoistureLoad, {
              moistureLoadId: state.moistureLoadId!
            });

            const newUserQuery = await fechUserQuery(
              sharedState,
              state.moistureLoadNo,
              state.revisionNo
            );

            return newUserQuery;
          },
          data => Action.userQueryRecieved(data)
        )
      ];
    }

    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }

    case "saveInputs": {
      const binDataInfo = ClimateSettingsEditor.getBinDataInfo(
        state.climateSettingsEditorState!
      );
      if (!binDataInfo) {
        return [state];
      }

      const loadsOperationTimes = getLoadsOperationTimes(state);
      const caseOverrides = Object.keys(
        GraphQLTypes.OperatingCaseGroupEnum
      ).map((operatingCaseGroup: GraphQLTypes.OperatingCaseGroupEnum) => ({
        operatingCaseGroup: operatingCaseGroup,
        moistureLoadSettings: PropertyValueSet.toString(
          state.currentSystemOverrides![operatingCaseGroup]
        )
      }));

      return [
        {
          ...state,
          inputsUpdated: false,
          showLoader: true
        },
        promiseCmd(
          async () => {
            await sharedState.graphQL.queryUser<
              GraphQLTypes.SaveMoistureLoadInputs,
              GraphQLTypes.SaveMoistureLoadInputsVariables
            >(updateMoistureLoadInputsMutation, {
              moistureLoadId: state.moistureLoadId!,
              moistureLoadinputs: {
                ...createMoistureLoadInputSettingsForCalc(
                  state,
                  binDataInfo,
                  loadsOperationTimes,
                  caseOverrides
                )
              }
            });

            const newUserQuery = await fechUserQuery(
              sharedState,
              state.moistureLoadNo,
              state.revisionNo
            );

            return newUserQuery;
          },
          data => Action.userQueryRecieved(data)
        )
      ];
    }

    case "setAclMessages": {
      return [
        {
          ...state,
          aclMessage: action.newMessage
        }
      ];
    }

    case "setAdditionalReportOptions": {
      return [
        {
          ...state,
          additionalOptions: {
            ...action.newOptions
          }
        }
      ];
    }

    case "setAccesControlList": {
      return [
        {
          ...state,
          showLoader: true
        },

        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SetMoistureLoadAclMutation,
          GraphQLTypes.SetMoistureLoadAclMutationVariables,
          Action
        >(
          setMoistureLoadACLMutation,
          {
            input: {
              moistureLoadFileId: state.moistureLoadFileId!,
              allowedClaims: action.claims,
              allowedUsers: action.userNames
            }
          },
          Action.setAccessControListMutationRecieved
        )
      ];
    }

    case "setAccessControListMutationRecieved": {
      return [
        {
          ...state,
          aclMessage: action.data.setMoistureLoadACL.errorMessage
            ? {
                type: "error",
                message: action.data.setMoistureLoadACL.errorMessage
              }
            : undefined
        },
        promiseCmd(
          async () => {
            const newUserQuery = await fechUserQuery(
              sharedState,
              state.moistureLoadNo,
              state.revisionNo
            );

            return newUserQuery;
          },
          data => Action.userQueryRecieved(data)
        )
      ];
    }

    case "setModal": {
      return [
        {
          ...state,
          modal: action.modal
        }
      ];
    }

    case "setDevSettings": {
      return [
        {
          ...state,
          developerSettings: action.newSettings
        }
      ];
    }

    case "setUserInputSearch": {
      return [
        {
          ...state,
          userInputSearch: action.newValue
        }
      ];
    }

    case "toggleMoistureLoadActions": {
      return [
        {
          ...state,
          actionMenuIsOpen: !state.actionMenuIsOpen
        }
      ];
    }

    case "resetSelections": {
      const [
        mainContentStateReseted,
        updatedMoistureLoadsettingsFromLoadTypes
      ] = Main.onResetSelections(
        state.mainContentState!,
        state.initialSelections!.moistureLoadSettings
      );

      const newResetedProperties = PropertyValueSet.merge(
        state.initialSelections!.moistureLoadSettings,
        updatedMoistureLoadsettingsFromLoadTypes
      );

      return [
        {
          ...state,
          currentSystemOverrides: getNewSystemOverrides(
            PropertiesSelector.getSelectedProperties(
              state.propertiesSelectorState!
            ),
            newResetedProperties,
            state.currentSystemOverrides!,
            PropertyValueSet.Empty
          ),
          propertiesSelectorState: {
            ...state.propertiesSelectorState,
            properties: newResetedProperties
          },

          mainContentState: mainContentStateReseted
        }
      ];
    }

    case "userQueryRecieved": {
      return [
        {
          ...state,
          userQuery: action.userQuery,
          showLoader: false
        }
      ];
    }

    case "userqueryRecivedFromMoistureLoadAction": {
      const newState = action.stateAfterQuery;
      return [
        {
          ...newState,
          showLoader: false,
          userQuery: action.userQuery
        }
      ];
    }

    case "initialQueryRecieved": {
      return buildInitialState(
        action.productQuery,
        action.userQuery,
        sharedState,
        state
      );
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

// tslint:disable-next-line
