import * as PropertiesSelector from "../../../properties-selector";
import * as React from "react";

export function createCustomRowRenderer(
  manualInputKey: string,
  systemOverride: JSX.Element | null
): PropertiesSelector.RowRenderer {
  const psCustomRowRenderer: PropertiesSelector.RowRenderer = (
    property: PropertiesSelector.PropertyInfo,
    jsxElements: PropertiesSelector.RowJsxElements
  ) => {
    const { descriptionJsx, selectorJsx, propertyLabelJsx } = jsxElements;

    return (
      <tr key={property.name}>
        <td>{descriptionJsx}</td>
        <td>{propertyLabelJsx}</td>
        <td>{selectorJsx}</td>
        <td>{manualInputKey === property.name && systemOverride}</td>
      </tr>
    );
  };
  return psCustomRowRenderer;
}
