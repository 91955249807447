import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const saveBinSelectionAndCases = gql`
  mutation SaveBinSelectionAndCases(
    $input: SaveBinSelectionsAndCasesInputType!
  ) {
    saveBinSelectionsAndCases(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
