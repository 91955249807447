import * as React from "react";
import styled from "styled-components";
import { zIndex } from "@genesys/ui-elements";

const Container = styled.div`
  margin: 5px;
  display: inline-block;
`;

const PopUpContainer = styled.div``;

const Popup = styled.div`
  position: absolute;
  z-index: ${zIndex.Dropdown};
  background-color: white;
  border-radius: 5px;
  border: 2px solid red;
  padding: 10px;
  font-size: 14px;
  min-width: 250px;
`;

export function PopUp({
  item,
  content,
  canShowPopUp
}: {
  readonly item: JSX.Element;
  readonly content: JSX.Element;
  readonly canShowPopUp: boolean;
}) {
  const [isOpen, setState] = React.useState(false);
  const ref = React.createRef<HTMLElement>();
  return (
    <Container>
      <PopUpContainer>
        <span
          ref={ref as any}
          onMouseEnter={event => {
            event.stopPropagation();
            event.preventDefault();

            if (ref.current) {
              ref.current.addEventListener("mouseleave", () => {
                setState(false);
              });
            }
            setState(true);
          }}
        >
          {item}
        </span>
        <div style={{ position: "relative" }}>
          {isOpen && canShowPopUp && <Popup>{content}</Popup>}
        </div>
      </PopUpContainer>
    </Container>
  );
}
