import { Sub } from "@typescript-tea/core";
import * as Authorization from "@genesys/shared/lib/authorization";
import { listenSystemEvent } from "../../nats-ctors";
import * as State from "./state";
import * as SharedState from "../../shared-state";

export function subscriptions(
  sharedState: SharedState.State,
  state: State.State
): Sub<State.Action> | undefined {
  if (state.mode !== "loaded") {
    return undefined;
  }
  const { file, id } = state.loadedState.system;

  const isGenesysDeveloper = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.developer
  );

  const productSearchPermissions =
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.systemTypeClaims.search
    ) || [];

  const salesOrgs =
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.salesorgsearch
    ) || [];

  return Sub.batch<State.Action>([
    listenSystemEvent(
      isGenesysDeveloper,
      sharedState.user.userName,
      file.systemTypeId,
      productSearchPermissions,
      salesOrgs,
      file.owner.name,
      id,
      State.Action.GotSystemEvent
    )
  ]);
}
