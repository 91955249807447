import { exhaustiveCheck } from "ts-exhaustive-check";
import * as GraphQLEntityCache from "gql-cache";
import * as Actions from "./actions-product";

const initialState = {};

export function reducerQueryProduct(
  state: GraphQLEntityCache.EntityCache = initialState,
  action: Actions.ProductAction
): GraphQLEntityCache.EntityCache {
  switch (action.type) {
    case "query-product/MergeProductEntities": {
      return GraphQLEntityCache.mergeEntityCache(state, action.payload);
    }
    case "query-product/Dummy": {
      return state;
    }
    default:
      exhaustiveCheck(action);
      return state;
  }
}
