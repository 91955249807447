export type ClimateAnnualOccurence = "04" | "10" | "20" | "996" | "99";

const integerEncodingFixedPointFactor = 10;

// This is bullshit.
const translationTable: {
  readonly [annualOccurence in ClimateAnnualOccurence]: number | undefined
} = {
  "04": 0.4,
  "10": 1,
  "20": 2,
  "996": 99.6,
  "99": 99
};

export function annualOccurenceToInteger(
  annualOccurence: ClimateAnnualOccurence
): number {
  const annualOccurenceFloat = translationTable[annualOccurence];

  if (!annualOccurenceFloat) {
    throw new Error("annualOccurence string not supported: " + annualOccurence);
  }

  return annualOccurenceFloat * integerEncodingFixedPointFactor;
}

export function integerToAnnualOccurence(
  annualOccurenceInteger: number
): ClimateAnnualOccurence {
  let annualOccurenceFloat =
    annualOccurenceInteger / integerEncodingFixedPointFactor;

  const entry = (Object.entries(translationTable) as ReadonlyArray<
    [ClimateAnnualOccurence, number | undefined]
  >).find(
    ([_, annualOccurenceValue]) => annualOccurenceValue === annualOccurenceFloat
  );

  const annualOccurence: ClimateAnnualOccurence | undefined = entry && entry[0];

  if (!annualOccurence) {
    throw new Error(
      "annualOccurence value not supported: " +
        annualOccurenceInteger / integerEncodingFixedPointFactor
    );
  }

  return annualOccurence;
}

export function formatAnnualOccurenceInteger(value: number): string {
  return value / integerEncodingFixedPointFactor + " %";
}

export function formatAnnualOccurence(value: ClimateAnnualOccurence): string {
  return formatAnnualOccurenceInteger(annualOccurenceToInteger(value));
}

export function parseFormattedAnnualOccurence(formatted: string) {
  const annualOccurenceFloat = parseFloat(
    formatted.substring(0, formatted.length - 2)
  );

  return integerToAnnualOccurence(
    annualOccurenceFloat * integerEncodingFixedPointFactor
  );
}

export function makeClimateAnnualOccurence(
  potentialClimateAnnualOccurence: string
): ClimateAnnualOccurence {
  if (
    !translationTable[potentialClimateAnnualOccurence as ClimateAnnualOccurence]
  ) {
    throw new Error(
      "annualOccurence not supported: " + potentialClimateAnnualOccurence
    );
  }

  return potentialClimateAnnualOccurence as ClimateAnnualOccurence;
}
