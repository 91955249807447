import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

export const InnerContainer = styled.div`
  display: flex;
`;

export const PropertyNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 31px 13px 0 0;
`;

export const AirStreamContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    position: relative;
    left: 35px;
    top: 20px;
  }
`;

export const CalculateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const AirOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    position: relative;
    left: 35px;
    top: 20px;
  }
  table {
    margin-top: 35px;
  }
`;
