import { Amount, Quantity } from "@genesys/uom";
import { Color } from "abstract-image";
import { Point, Polygon, AmountObject, Props, ChartPreset } from "./types";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { clientConfig } from "../config";

export async function getSrc(props: Props) {
  const blob = await getBlob(getBody(props), props.accessToken);
  const src = window.URL.createObjectURL(blob);
  return src;
}

async function getBlob(body: string, accessToken: string) {
  const routeEndpoint = clientConfig.genesysBackend;

  const response = await fetch(
    `${routeEndpoint}/internal/GetPsychrometricChartSvg`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${accessToken}`
      },
      credentials: "include",
      body
    }
  );

  return response.blob();
}

function getBody(props: Props) {
  return JSON.stringify(
    props.source === "custom"
      ? {
          mollier: props.type === "mollier",
          si: props.si,
          points: props.points.map(amountObjectToPvsString),
          pressure: PropertyValue.toString(
            PropertyValue.fromAmount(props.pressure)
          ),
          binCases: props.binCases.map(amountObjectToPvsString),
          binFields: props.binFields.map(amountObjectToPvsString),
          culture: "en-US",
          limits: props.limits,
          ...(props.temperatureMin && {
            temperatureMin: PropertyValue.toString(
              PropertyValue.fromAmount(props.temperatureMin)
            )
          }),
          ...(props.temperatureMax && {
            temperatureMax: PropertyValue.toString(
              PropertyValue.fromAmount(props.temperatureMax)
            )
          }),
          ...(props.humidityMax && {
            humidityMax: PropertyValue.toString(
              PropertyValue.fromAmount(props.humidityMax)
            )
          })
        }
      : {
          mollier: props.type === "mollier",
          si: props.si,
          systemId: props.systemId,
          operatingCaseId: props.operatingCaseId,
          culture: "en-US",
          limits: props.limits,
          ...(props.temperatureMin && {
            temperatureMin: PropertyValue.toString(
              PropertyValue.fromAmount(props.temperatureMin)
            )
          }),
          ...(props.temperatureMax && {
            temperatureMax: PropertyValue.toString(
              PropertyValue.fromAmount(props.temperatureMax)
            )
          }),
          ...(props.humidityMax && {
            humidityMax: PropertyValue.toString(
              PropertyValue.fromAmount(props.humidityMax)
            )
          })
        }
  );
}

export function createPoint<
  Tx extends Amount.Amount<Quantity.Quantity>,
  Ty extends Amount.Amount<Quantity.Quantity>
>(x: Tx, y: Ty, xAxis: number = 0, yAxis: number = 0): Point<Tx, Ty> {
  return {
    x,
    y,
    xAxis,
    yAxis
  };
}

export function createRectangle<
  Tx extends Quantity.Quantity,
  Ty extends Quantity.Quantity
>(
  midPoint: Point<Amount.Amount<Tx>, Amount.Amount<Ty>>,
  size: Point<Amount.Amount<Tx>, Amount.Amount<Ty>>,
  color: Color
): Polygon<Amount.Amount<Tx>, Amount.Amount<Ty>> {
  return {
    points: [
      createPoint(
        Amount.minus(midPoint.x, size.x),
        Amount.minus(midPoint.y, size.y)
      ),
      createPoint(
        Amount.plus(midPoint.x, size.x),
        Amount.minus(midPoint.y, size.y)
      ),
      createPoint(
        Amount.plus(midPoint.x, size.x),
        Amount.plus(midPoint.y, size.y)
      ),
      createPoint(
        Amount.minus(midPoint.x, size.x),
        Amount.plus(midPoint.y, size.y)
      )
    ],
    color
  };
}

export function createPolygon<
  Tx extends Amount.Amount<Quantity.Quantity>,
  Ty extends Amount.Amount<Quantity.Quantity>
>(points: ReadonlyArray<Point<Tx, Ty>>, color: Color): Polygon<Tx, Ty> {
  return {
    points,
    color
  };
}

export function amountObjectToPvsString<T>(object: AmountObject<T>) {
  return PropertyValueSet.toString(
    Object.keys(object).reduce(
      (soFar, currentKey: string) =>
        PropertyValueSet.setAmount(
          currentKey as string,
          object[currentKey as keyof T],
          soFar
        ),
      PropertyValueSet.Empty
    )
  );
}

export function infoFromPreset(preset: ChartPreset): {
  readonly measureSystem: "SI" | "IP";
  readonly chartType: "mollier" | "psychrometric";
} {
  const measureSystem =
    preset === "mollier_SI" || preset === "psycrom_SI" ? "SI" : "IP";
  const chartType =
    preset === "mollier_SI" || preset === "mollier_IP"
      ? "mollier"
      : "psychrometric";

  return {
    measureSystem,
    chartType
  };
}
