import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValueSet } from "@genesys/property";

export const ConfiguratorAction = ctorsUnion({
  saveOperatingCases: (
    climateSettings: PropertyValueSet.PropertyValueSet,
    operatingCases: ReadonlyArray<{
      readonly id: string;
      readonly settings: PropertyValueSet.PropertyValueSet;
    }>,
    calculate?: boolean,
    newMoistureLoadId?: string
  ) => ({ climateSettings, operatingCases, calculate, newMoistureLoadId }),
  saveNotes: (
    componentId: string,
    internalNotes: string,
    externalNotes: string
  ) => ({
    componentId,
    internalNotes,
    externalNotes
  }),
  saveEnergy: (
    energySettings: { readonly [key: string]: string },
    binSelections: PropertyValueSet.PropertyValueSet,
    binOperatingCases: ReadonlyArray<{
      readonly binIndex: number;
      readonly settings: PropertyValueSet.PropertyValueSet;
      readonly binData: PropertyValueSet.PropertyValueSet;
    }>,
    calculate?: boolean,
    calculateEnergy?: boolean
  ) => ({
    energySettings,
    binSelections,
    binOperatingCases,
    calculate,
    calculateEnergy
  }),
  updateComponentAccessories: (
    accessory: ReadonlyArray<{
      readonly parentComponentId: string;
      readonly parentComponentProperties: string;
      readonly accessoriesProperties: ReadonlyArray<{
        readonly productId: string;
        readonly properties: string;
      }>;
    }>,
    calculate?: boolean
  ) => ({
    accessory,
    calculate
  }),
  updateComponentProperties: (
    component: ReadonlyArray<{
      readonly componentId: string;
      readonly properties: PropertyValueSet.PropertyValueSet;
    }>,
    calculate?: boolean
  ) => ({
    component,
    calculate
  }),
  updateComponentsPropertiesAndSaveOperatingCases: (
    components: ReadonlyArray<{
      readonly componentId: string;
      readonly properties: PropertyValueSet.PropertyValueSet;
    }>,
    climateSettings: PropertyValueSet.PropertyValueSet,
    operatingCases: ReadonlyArray<{
      readonly id: string;
      readonly settings: PropertyValueSet.PropertyValueSet;
    }>,
    calculate?: boolean
  ) => ({ components, climateSettings, operatingCases, calculate }),
  addComponent: (input: {
    readonly componentId: string;
    readonly productId: string;
    readonly componentSectionPositions: ReadonlyArray<{
      readonly productSectionId: string;
      readonly beforeSectionId: string;
      readonly airstreamId: string;
    }>;
  }) => ({ input }),
  moveComponent: (input: {
    readonly componentId: string;
    readonly componentSectionPositions: ReadonlyArray<{
      readonly productSectionId: string;
      readonly beforeSectionId: string;
      readonly airstreamId: string;
    }>;
  }) => ({ input }),
  deleteComponent: (input: { readonly componentId: string }) => ({ input }),
  calculateSystem: () => ({}),
  lockSystem: () => ({}),
  exchangeComponent: (input: {
    readonly componentId: string;
    readonly productId: string;
    readonly componentSectionPositions: ReadonlyArray<{
      readonly productSectionId: string;
      readonly beforeSectionId: string;
      readonly airstreamId: string;
    }>;
  }) => ({ input }),
  setSystemPricingInformation: (
    systemId: string,
    salesOrganisationId: string,
    currencyCode: string,
    masterCurrencyCode: string,
    masterOrganisationId: string,
    calculate: boolean
  ) => ({
    systemId,
    salesOrganisationId,
    currencyCode,
    masterCurrencyCode,
    masterOrganisationId,
    calculate
  }),
  calculateEnergy: (binCaseIds?: ReadonlyArray<string>) => ({ binCaseIds }),
  calculateEnergyComparison: () => ({}),
  deleteMCompareComponent: (systemId: string) => ({ systemId }),
  addOrUpdateMCompareComponent: (
    productId: string,
    settings: PropertyValueSet.PropertyValueSet
  ) => ({ productId, settings })
});
export type ConfiguratorAction = CtorsUnion<typeof ConfiguratorAction>;
