// import React from "react";
import { FlowsAndPressureColumnGroup } from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SummaryOpcProps } from "./shared";

export function flowsAndPressureContent({
  column,
  getAndDisplayValueFromOpc
}: Omit<SummaryOpcProps, "column"> & {
  readonly column: FlowsAndPressureColumnGroup["columnName"];
}) {
  switch (column) {
    case "supply-outlet-flow": {
      return getAndDisplayValueFromOpc([
        "supplyoutletflow",
        "supplyoutletairflow"
      ]);
    }

    case "post-mix-flow": {
      return getAndDisplayValueFromOpc(["postmixingboxairflow"]);
    }

    case "pre-mix-flow": {
      return getAndDisplayValueFromOpc(["premixingboxairflow"]);
    }

    case "process-inlet-flow": {
      return getAndDisplayValueFromOpc(["processinletairflow"]);
    }
    case "post-mixing-inlet-pressure": {
      return getAndDisplayValueFromOpc(["postmixinginletexternalstatic"]);
    }
    case "pre-mixing-inlet-pressure": {
      return getAndDisplayValueFromOpc(["premixinginletexternalstatic"]);
    }
    case "process-inlet-pressure": {
      return getAndDisplayValueFromOpc(["processinletexternalstatic"]);
    }
    case "process-outlet-pressure": {
      return getAndDisplayValueFromOpc(["processoutletexternalstatic"]);
    }
    case "react-inlet-pressure": {
      return getAndDisplayValueFromOpc(["reactinletexternalstatic"]);
    }

    case "react-outlet-pressure": {
      return getAndDisplayValueFromOpc(["reactoutletexternalstatic"]);
    }

    default: {
      exhaustiveCheck(column);
    }
  }
}
