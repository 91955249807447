import styled from "styled-components";

interface ExtraProps {
  readonly locked: boolean;
  readonly weight: "bold" | "normal";
}
// tslint:disable-next-line
export const MainMenuItem = styled.li<ExtraProps>`
  position: relative;
  display: inline-block;
  font-weight: ${props => props.weight};
  color: rgb(102, 102, 102);

  padding: 0 5px;

  ${props =>
    props.locked
      ? `opacity: 0.5;`
      : `
  &:hover {
    background: white;
    cursor: pointer;
  }`};
`;
