import * as React from "react";

// tslint:disable-next-line
export const RadioButton: React.FunctionComponent<
  React.HTMLProps<HTMLInputElement>
> = props => {
  const { ...unstyledRadioButtonProps } = props;
  const modifiedProps = { ...unstyledRadioButtonProps, type: "radio" };

  return <input {...modifiedProps}>{props.children}</input>;
};
