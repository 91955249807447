import * as React from "react";
import styled from "styled-components";
import { InfoWhite } from "@genesys/ui-elements";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorTitle = styled.p`
  font-size: 1.3rem;
  color: white;
  margin-left: 7px;
`;

const ErrorMessageContainer = styled.div`
  background-color: #feeaeb;
  color: #202b47;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  margin-top: 13px;
  text-align: left;
  margin-left: 5px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ErrorContainer = styled.div`
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  background-color: #e8767b;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  width: 600px;
  min-height: 200px;
`;

export type ApplicationError =
  | { readonly type: "none" }
  | {
      readonly type: "error";
      readonly errorMessage: string;
      readonly errorTitle: string;
    };

interface Props {
  readonly children: React.ReactElement;
}

export class ErrorBoundary extends React.Component<Props, ApplicationError> {
  constructor(props: Props) {
    super(props);
    this.dispatchError = this.dispatchError.bind(this);
    this.uhandledRejectionEventListener =
      this.uhandledRejectionEventListener.bind(this);
    this.state = { type: "none" };
  }

  dispatchError(error: Error) {
    this.setState(() => ({
      type: "error",
      errorTitle: error.name,
      errorMessage: error.message
    }));
  }

  componentDidCatch(error: Error) {
    this.dispatchError(error);
  }

  componentDidMount(): void {
    // Listen for errors
    window.addEventListener(
      "unhandledrejection",
      this.uhandledRejectionEventListener
    );

    const dispatchError = this.dispatchError;
    // Listen for
    window.onerror = (
      message: string,
      source: string,
      lineno: number,
      colno: number,
      error: Error | undefined
    ) => {
      console.error(message);
      console.error(source);
      console.error(lineno);
      console.error(colno);
      console.error(error);
      dispatchError(error || new Error(message));
      return true;
    };
  }
  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.uhandledRejectionEventListener
    );
  }

  private uhandledRejectionEventListener(event: PromiseRejectionEvent) {
    console.error(event);
    this.dispatchError(
      new Error("Ooops an Exception occured. View console for more details")
    );
  }

  render() {
    if (this.state.type === "error") {
      return (
        <Container>
          <ErrorContainer>
            <TitleContainer>
              <InfoWhite />
              <ErrorTitle>{this.state.errorTitle} Exception</ErrorTitle>
            </TitleContainer>

            <ErrorMessageContainer>
              <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
            </ErrorMessageContainer>
          </ErrorContainer>
        </Container>
      );
    }
    return this.props.children;
  }
}
