import * as React from "react";
import styled from "styled-components";
import { Quantity } from "@genesys/uom";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { Component } from "../quantity-selector";

interface QuantitySelectorProps {
  readonly conversionParameters:
    | QuantityConversion.ConversionParameters
    | undefined;
  readonly isQuantitySelectorActive: boolean;
  readonly onQuantityChange: (quantity: Quantity.Quantity) => void;
  readonly quantity: Quantity.Quantity;
}

// tslint:disable-next-line:variable-name
const StyledQuantitySelector = styled(Component)`
  display: inline-block;
`;

// tslint:disable-next-line:function-name
export function QuantitySelector({
  conversionParameters,
  isQuantitySelectorActive,
  onQuantityChange,
  quantity
}: QuantitySelectorProps): JSX.Element {
  if (conversionParameters === undefined || !isQuantitySelectorActive) {
    return <div />;
  }
  return (
    <StyledQuantitySelector
      selectedQuantity={quantity}
      onChange={onQuantityChange}
    />
  );
}
