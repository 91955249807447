import * as React from "react";

// tslint:disable-next-line
export const Checkbox: React.FunctionComponent<
  React.HTMLProps<HTMLInputElement>
> = props => {
  const { ...unStyledCheckboxProps } = props;

  const modifiedProps = { ...unStyledCheckboxProps, type: "checkbox" };

  return <input {...modifiedProps}>{props.children}</input>;
};
