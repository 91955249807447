import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton, P1, Spinner } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as SharedState from "../../../../shared-state";
import { EContactState, Action } from "../state";
import { ButtonsBox, Root, LoadingRoot } from "../elements";

export function EContactPidView({
  state,
  systemId,
  sharedState,
  systemStatus,
  genesysNoWithRevisionNo,
  dispatch
}: {
  readonly state: EContactState;
  readonly systemId: string;
  readonly systemStatus: number;
  readonly sharedState: SharedState.State;
  readonly genesysNoWithRevisionNo: string;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const isSystemReady =
    systemStatus >= SystemStatusEnum.SystemStatus.PriceCalculationSuccess;

  if (!isSystemReady) {
    return (
      <Root>
        <P1 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.systemNotLocked())}
        </P1>
      </Root>
    );
  }

  if (state.isLoading) {
    return (
      <LoadingRoot>
        <Spinner />
      </LoadingRoot>
    );
  }

  const isDiagramReady = state.url !== undefined;

  const showRefreshButton = state.isBuilding && !isDiagramReady;

  return (
    <Root>
      <P1 weight="normal" color="dark">
        {sharedState.translate(LanguageTexts.buildState()) +
          ": " +
          state.stateDescription}
      </P1>

      {state.isBuilding && (
        <P1 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.longRunningTask())}
        </P1>
      )}

      <ButtonsBox>
        {!isDiagramReady && (
          <>
            {!state.isBuilding && (
              <StandardButton
                size="Small"
                buttonType="Primary"
                onClick={() => dispatch(Action.requestBuild(systemId))}
              >
                {sharedState.translate(LanguageTexts.requestBuild())}
              </StandardButton>
            )}

            {showRefreshButton && (
              <StandardButton
                size="Small"
                buttonType="Primary"
                onClick={() => dispatch(Action.getBuildState(systemId))}
              >
                {sharedState.translate(LanguageTexts.refresh())}
              </StandardButton>
            )}
          </>
        )}

        {isDiagramReady && (
          <StandardButton
            size="Small"
            buttonType="Primary"
            onClick={() => {
              const link = document.createElement("a");
              link.href = state.url!;
              link.download = `PID ${genesysNoWithRevisionNo}.pdf`;
              link.click();
            }}
          >
            {sharedState.translate(LanguageTexts.download())}
          </StandardButton>
        )}
      </ButtonsBox>
    </Root>
  );
}
