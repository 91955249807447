import { PropertyValue } from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as ComponentOrder from "@genesys/shared/lib/component-order-2";
import * as Calculations from "@munters/calculations";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as SystemPerformance from "@genesys/client-core/lib/system-performance";
import { Quantity, Amount, Unit } from "@genesys/uom";
import { ScreenAirPropertiesResult } from "./types";

export function getScreenAirPropertiesResultsAndFilteredAirPositions(
  screenAirProperties: ReadonlyArray<SystemPerformance.ScreenAirProperty>,
  airPositions: ReadonlyArray<ComponentOrder.AirPosition>,
  selectedOpCase: SystemPerformance.OpCase | undefined,
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  fieldGroup: string
): [
  ReadonlyArray<ScreenAirPropertiesResult>,
  ReadonlyArray<ComponentOrder.AirPosition>
] {
  if (!selectedOpCase) {
    return [[], []];
  }

  const screenAirPropertiesResult: Array<ScreenAirPropertiesResult> = [];

  const set: Set<ComponentOrder.AirPosition> = new Set();
  const resultsByComponentId =
    SystemPerformance.getResultByComponentId(selectedOpCase);
  for (const sap of screenAirProperties) {
    const result: string[] = [];

    for (const ap of airPositions) {
      const airResult = SystemPerformance.getAirAt(resultsByComponentId, ap);
      if (!airResult) {
        result.push("");
        set.add(ap);
        continue;
      }

      const [airAt, atmosphericpressure] = airResult;
      const airProperty = sap.airProperty;

      const format = getAmountFormat(
        fieldGroup,
        sap.name,
        SystemPerformance.getQuantityForAirProperty(airProperty)
      );

      const density =
        Calculations.Physics.AirCalculation.calculateDensityOfMoistAirMixture(
          airAt
        );

      const conversionParams: QuantityConversion.ConversionParameters = {
        pressure: airAt.pressure,
        temperature: airAt.temperature,
        density
      };

      const getValue = (amount: Amount.Amount<Quantity.Quantity>) =>
        ProductProperties.getValue(
          PropertyValue.create("amount", amount),
          format,
          conversionParams
        );

      result.push(
        SystemPerformance.getAirPropertyValue(
          airProperty,
          getValue,
          airAt,
          atmosphericpressure
        )
      );

      set.add(ap);
    }

    const amountFormat = getAmountFormat(fieldGroup, sap.name, sap.quantity);
    screenAirPropertiesResult.push({ name: sap.name, amountFormat, result });
  }
  const newAirPositions: Array<ComponentOrder.AirPosition> = Array.from(set);
  return [screenAirPropertiesResult, newAirPositions];
}

export function getConversionParameters(
  screenAirPropertiesResult: ReadonlyArray<ScreenAirPropertiesResult>
) {
  const temperatureProperty = screenAirPropertiesResult.find(
    sap => sap.name === "Temperature"
  );

  const pressureProperty = screenAirPropertiesResult.find(
    sap => sap.name === "RelativePressure"
  );

  const conversionParams = {
    pressure: pressureProperty
      ? {
          decimalCount: 0,
          value: 0,
          unit: pressureProperty.amountFormat!.unit as Unit.Unit<"Pressure">
        }
      : undefined,
    temperature: temperatureProperty
      ? {
          decimalCount: 0,
          value: 0,
          unit: temperatureProperty.amountFormat!
            .unit as Unit.Unit<"Temperature">
        }
      : undefined
  };

  return conversionParams;
}
