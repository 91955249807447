import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { PrimaryColors } from "@genesys/ui-elements";
import { Quantity } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";

import {
  SummaryItem,
  SelectedColumn,
  SummaryType,
  StandardSummaryItem,
  TargetColumnGroup,
  FlowsAndPressureColumnGroup,
  TempAndHumColumnGroup,
  ClimateColumnGroup,
  Column
} from "./types";

const targetSelectableColumns: Array<{
  readonly columnName: TargetColumnGroup["columnName"];
  readonly displayName: string;
  readonly quantity: Quantity.Quantity | undefined;
  readonly isEditable: boolean;
}> = [
  {
    columnName: "target-humidity",
    displayName: "Target Humidity",
    quantity: "HumidityRatio",
    isEditable: true
  },
  {
    columnName: "target-temperature",
    displayName: "Target Temp",
    quantity: "Temperature",
    isEditable: false
  },
  {
    columnName: "supply-target-dewpoint",
    displayName: "Supply Target DP",
    quantity: "DewPointTemperature",
    isEditable: false
  },
  {
    columnName: "pre-cooler-setpoint-1",
    displayName: "Pre Cooler SP 1",
    quantity: "Temperature",
    isEditable: true
  },
  {
    columnName: "pre-cooler-setpoint-2",
    displayName: "Pre Cooler SP 2",
    quantity: "Temperature",
    isEditable: true
  },
  {
    columnName: "pre-heater-setpoint",
    displayName: "Pre Heater SP",
    quantity: "Temperature",
    isEditable: true
  },
  {
    columnName: "react-preheater-setpoint",
    displayName: "React Pre Heater SP",
    quantity: "Temperature",
    isEditable: true
  }
];

const flowsAndPressureSelectableColumns: Array<{
  readonly columnName: FlowsAndPressureColumnGroup["columnName"];
  readonly displayName: string;
  readonly quantity: Quantity.Quantity | undefined;
  readonly isEditable: boolean;
}> = [
  {
    columnName: "pre-mix-flow",
    displayName: "Pre-mix Flow",
    quantity: "MassFlow",
    isEditable: true
  },
  {
    columnName: "post-mix-flow",
    displayName: "Post-mix Flow",
    quantity: "MassFlow",
    isEditable: true
  },
  {
    columnName: "process-inlet-flow",
    displayName: "Process-In Flow",
    quantity: "MassFlow",
    isEditable: true
  },
  {
    columnName: "supply-outlet-flow",
    displayName: "Supply-Out Flow",
    quantity: "MassFlow",
    isEditable: true
  },
  {
    columnName: "post-mixing-inlet-pressure",
    displayName: "Post Mixing In",
    quantity: "Pressure",
    isEditable: true
  },

  {
    columnName: "pre-mixing-inlet-pressure",
    displayName: "Pre Mixing In",
    quantity: "Pressure",
    isEditable: true
  },
  {
    columnName: "process-inlet-pressure",
    displayName: "Process In",
    quantity: "Pressure",
    isEditable: true
  },
  {
    columnName: "process-outlet-pressure",
    displayName: "Process Out",
    quantity: "Pressure",
    isEditable: true
  },
  {
    columnName: "react-inlet-pressure",
    displayName: "React In",
    quantity: "Pressure",
    isEditable: true
  }
];

const climateSelectableColumns: Array<{
  readonly columnName: ClimateColumnGroup["columnName"];
  readonly displayName: string;
  readonly quantity?: Quantity.Quantity;
  readonly isEditable: boolean;
}> = [
  { columnName: "country", displayName: "Country", isEditable: true },
  { columnName: "state", displayName: "State", isEditable: true },
  { columnName: "location", displayName: "Location", isEditable: true },
  {
    columnName: "elevation",
    displayName: "Elevation",
    quantity: "Length",
    isEditable: true
  },
  {
    columnName: "atmospheric-pressure",
    displayName: "Atmospheric Pressure",
    quantity: "Pressure",
    isEditable: true
  },
  {
    columnName: "cooling-temp",
    displayName: "Cooling Temp",
    quantity: "Temperature",
    isEditable: false
  },
  {
    columnName: "cooling-hum",
    displayName: "Cooling Hum",
    quantity: "HumidityRatio",
    isEditable: false
  },
  {
    columnName: "heating-temp",
    displayName: "Heating Temp",
    quantity: "Temperature",
    isEditable: false
  },
  {
    columnName: "heating-hum",
    displayName: "Heating hum",
    quantity: "HumidityRatio",
    isEditable: false
  }
];

const tempAndHumSelectableColumns: Array<{
  readonly columnName: TempAndHumColumnGroup["columnName"];
  readonly displayName: string;
  readonly quantity?: Quantity.Quantity;
  readonly isEditable: boolean;
}> = [
  {
    columnName: "custom-air-temperature",
    displayName: "Custom temp",
    quantity: "Temperature",
    isEditable: true
  },
  {
    columnName: "custom-air-humidity",
    displayName: "Custom Hum",
    quantity: "HumidityRatio",
    isEditable: true
  },
  {
    columnName: "outdoor-air-temperature",
    displayName: "Outdoor Temp",
    quantity: "Temperature",
    isEditable: true
  },
  {
    columnName: "outdoor-air-humidity",
    displayName: "Outdoor Hum",
    quantity: "HumidityRatio",
    isEditable: true
  },
  {
    columnName: "return-air-temperature",
    displayName: "Return Temp",
    quantity: "Temperature",
    isEditable: true
  },

  {
    columnName: "return-air-humidity",
    displayName: "Return Hum",
    quantity: "HumidityRatio",
    isEditable: true
  }
];

function isOpcVisible(
  selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>
): boolean {
  const targetColumns = selectedSummaryItems.get("target") || new Set();
  const flowsAndPressuresColumns =
    selectedSummaryItems.get("flows-and-pressure") || new Set();

  const tempsAndHumsColumns =
    selectedSummaryItems.get("temperature-and-humidity") || new Set();

  const columns = [
    ...Array.from(targetColumns),
    ...Array.from(flowsAndPressuresColumns),
    ...Array.from(tempsAndHumsColumns)
  ];
  if (columns.length === 0) {
    return false;
  }
  return true;
}

function createTargetSummaryItem(
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SummaryItem {
  const fieldGroup = "TargetSystemSummary";

  return {
    type: "target",
    title: "Targets",
    fieldGroup: fieldGroup,
    backgroundColor: PrimaryColors.gold,
    dynamicAddedColumns: [],
    selectableColumns: targetSelectableColumns.map(x => ({
      type: "selectable",
      columnName: x.columnName,
      displayName: x.displayName,
      isEditable: x.isEditable,
      format:
        x.quantity && getAmountFormat(fieldGroup, x.columnName, x.quantity)
    }))
  };
}

function createFLowsAndPressureSummaryItem(
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SummaryItem {
  const fieldGroup = "FlowsAndPressureSummary";

  return {
    type: "flows-and-pressure",
    title: "Flows And Pressures",
    backgroundColor: PrimaryColors.blue50,
    dynamicAddedColumns: [],
    fieldGroup,
    selectableColumns: flowsAndPressureSelectableColumns.map(x => ({
      type: "selectable",
      columnName: x.columnName,
      displayName: x.displayName,
      isEditable: x.isEditable,
      format:
        x.quantity && getAmountFormat(fieldGroup, x.columnName, x.quantity)
    }))
  };
}

function createClimateSummaryItem(
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SummaryItem {
  const fieldGroup = "ClimateSystemSummary";

  return {
    type: "climate",
    title: "Climate",
    backgroundColor: "#005a6a",
    dynamicAddedColumns: [],
    fieldGroup,
    selectableColumns: climateSelectableColumns.map(x => ({
      type: "selectable",
      columnName: x.columnName,
      displayName: x.displayName,
      isEditable: x.isEditable,
      format:
        x.quantity && getAmountFormat(fieldGroup, x.columnName, x.quantity)
    }))
  };
}

function createTempAndHumSummaryItem(
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SummaryItem {
  const fieldGroup = "TempAndHumSystemSummary";

  return {
    type: "temperature-and-humidity",
    title: "Temp And Hum",
    fieldGroup,
    backgroundColor: PrimaryColors.lightRed,
    dynamicAddedColumns: [],
    selectableColumns: tempAndHumSelectableColumns.map(x => ({
      type: "selectable",
      columnName: x.columnName,
      displayName: x.displayName,
      isEditable: x.isEditable,
      format:
        x.quantity && getAmountFormat(fieldGroup, x.columnName, x.quantity)
    }))
  };
}

export function createSummaryItemsDef(
  _selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>,
  getAmountFormat: ScreenAmounts.GetAmountFormat
): ReadonlyArray<SummaryItem> {
  return [
    createClimateSummaryItem(getAmountFormat),
    createTargetSummaryItem(getAmountFormat),
    createFLowsAndPressureSummaryItem(getAmountFormat),
    createTempAndHumSummaryItem(getAmountFormat)
  ];
}

export function getDefaultSummaryItem(
  selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>
): StandardSummaryItem {
  return {
    type: "standard",
    title: "",
    backgroundColor: "white",
    fieldGroup: "SystemSummary",
    dynamicAddedColumns: [
      {
        type: "dynamic",
        columnName: "edit",
        displayName: "",
        sortNo: 0,
        isVisible: true,
        isEditable: false
      },
      {
        type: "dynamic",
        columnName: "opc",
        displayName: "Choose Opc",
        sortNo: 4,
        isVisible: isOpcVisible(selectedSummaryItems),
        isEditable: false
      }
    ],
    selectableColumns: [
      {
        type: "selectable",
        columnName: "g-no",
        displayName: "G No",
        isEditable: false
      },
      {
        type: "selectable",
        columnName: "system-name",
        displayName: "System Name",
        sortNo: 1,
        isEditable: true
      },
      {
        type: "selectable",
        columnName: "system-type",
        displayName: "System type",
        sortNo: 2,
        isEditable: false
      },
      {
        type: "selectable",
        columnName: "system-status",
        displayName: "Status",
        sortNo: 3,
        isEditable: false
      }
    ]
  };
}

export function getAllSelectableSummaryItems() {
  const selectableSummaryTypes: Array<SummaryType> = [
    "climate",
    "flows-and-pressure",
    "target",
    "temperature-and-humidity"
  ];
  const map = new Map<SummaryType, Set<Column["columnName"]>>();

  for (const summaryType of selectableSummaryTypes) {
    map.set(summaryType, new Set(getSelectableColumns(summaryType)));
  }

  return map;
}

function getSelectableColumns(selectableSummaryTypes: SummaryType) {
  switch (selectableSummaryTypes) {
    case "climate": {
      return climateSelectableColumns.map(x => x.columnName);
    }
    case "flows-and-pressure": {
      return flowsAndPressureSelectableColumns.map(x => x.columnName);
    }

    case "target": {
      return targetSelectableColumns.map(x => x.columnName);
    }

    case "temperature-and-humidity": {
      return tempAndHumSelectableColumns.map(x => x.columnName);
    }
    default: {
      exhaustiveCheck(selectableSummaryTypes);
    }
  }
}
