import gql from "graphql-tag";

export const deleteMoistureLoadFilesMutation = gql`
  mutation DeleteMoistureLoadFiles($moistureLoadFileIds: [ID!]!) {
    deleteMoistureLoadFiles(moistureLoadFileIds: $moistureLoadFileIds) {
      errorMessage
      deletionSuccessful
    }
  }
`;
