import { css } from "styled-components";

export const headingTextColor = css`rgb(128, 128, 128)`;
export const tabNavBorderColor = css`rgb(169, 169, 169);`;

export const dialogBackgroundColor = css`rgb(233, 236, 238);`;

// System/Pricing manager
export const searchBackground = "#F1F1F1";
export const searchFilterBorderColor = "rgb(217,217,217)";
export const searchFilterBorderHoverColor = "rgb(198,198,198)";

// Action menu
export const actionMenuHeaderColor = "rgb(147, 147, 147)";

export const backgroundColor = "#fff";
