import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type S2Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledS2 = styled.h5<S2Props>`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.133rem;
  letter-spacing: 0;

  ${({ weight = "normal", color = "dark" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function S2(props: S2Props): JSX.Element {
  return <StyledS2 {...props} />;
}
