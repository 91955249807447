import gql from "graphql-tag";

export const recentAndFavoritesQuery = gql`
  query WikiJsQuery {
    user {
      wikiJsPages(tags: ["news"], limit: 5) {
        id
        title
        description
        path
        markdownContent
      }
    }
  }
`;

export const dashboardProductQuery = gql`
  query DashboardProductQuery {
    product {
      systemTypes {
        id
        plenumSizeProperty
      }
    }
  }
`;
