import * as React from "react";
import styled from "../../styled-components";
import { zIndex } from "../../zIndex";

// tslint:disable-next-line: no-require-imports no-var-requires
const spinnerUrl = require("ui-elements/assets/spinners/animated-spinner.svg");
// const deleteSvg =  require("ui-elements/assets/action/trash.svg");

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.FullOverlay};
`;

export function OverlayLoader({}: {}): JSX.Element {
  return (
    <OuterContainer>
      <img src={spinnerUrl} />
    </OuterContainer>
  );
}
