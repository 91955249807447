import * as VersionChecker from "../../version-checker";
import * as TermsAndCondtions from "../../terms-and-conditions";
import * as Navigation from "../../navigation-effect-manager";
import * as OidcClientEffectManager from "../../oidc-client-effect-manager";
import * as Main from "../../main";
import * as InitialSettings from "../../initial-settings";
import * as SharedState from "../../shared-state";
import * as GQLTypes from "../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({
    urlRequest
  }),
  getNextMessage: () => ({}),
  requestNewTranslationOnUrlChange: (
    url: Navigation.Url,
    data: GQLTypes.TranslationQuery
  ) => ({ url, data }),
  dispatchMain: (action: Main.Action) => ({ action }),
  dispatchTermsAndConditions: (action: TermsAndCondtions.Action) => ({
    action
  }),
  dispatchInitialSettings: (action: InitialSettings.Action) => ({ action }),
  dispatchVersionChecker: (action: VersionChecker.Action) => ({ action }),
  userQueryReceived: (data: GQLTypes.RootUserQuery) => ({ data }),
  initialQueriesReceived: (
    productQuery: GQLTypes.RootProductQuery,
    userQuery: GQLTypes.RootUserQuery
  ) => ({ productQuery, userQuery }),
  translationQueryReceived: (
    data: GQLTypes.TranslationQuery,
    lang: string
  ) => ({
    data,
    lang
  }),
  sharedStateActionMutationCompleted: (reloadUserData: boolean) => ({
    reloadUserData
  }),
  recentAndFavoritesLoaded: (
    recentSystems: ReadonlyArray<SharedState.BreifSystem>,
    favoriteSystems: ReadonlyArray<SharedState.BreifSystem>
  ) => ({
    recentSystems,
    favoriteSystems
  }),
  accessTokenRefreshed: (user: OidcClientEffectManager.User | undefined) => ({
    user
  })
});
export type Action = CtorsUnion<typeof Action>;
