import * as React from "react";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import { Action, State } from "./state";
import { ComponentEditorDetailsContent } from "./details/";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as System from "../../system";
import { PrimaryColors } from "@genesys/ui-elements";
import { ComponentMessages } from "./component-messages";
import Badge from "@mui/material/Badge";
import {
  getOperatingCaseTabCaseResults,
  getResultSummaryTabCaseResults
} from "./details/shared-tools";
import { ActiveTab } from ".";
import * as GraphQlTypes from "../../../graphql-types";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as ComponentSpecs from "./details/component-spec";
import {
  OperatingCaseResult,
  ComponentMessage
} from "@genesys/shared/src/components-messages";

const ComponentEditorRoot = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
  /* overflow-y: auto; */
  margin-bottom: 10px;

  & > div:not(div:nth-child(2)) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 16px;

    flex-grow: 1;
  }
`;
const ComponentEditorPropertiesSelectorRoot = styled.div`
  width: 615px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 7px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const StyledLi = styled.li<{
  readonly active: boolean;
}>`
  ${props =>
    props.active && `border-bottom: 3px solid ${PrimaryColors.muntersBlue}; `}
`;
const ComponentEditorSeparator = styled.div`
  content: "";
  border-left: 3px solid rgb(247, 247, 247);
`;
const ComponentEditorDetailsRoot = styled.div`
  width: 760px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 7px;
    height: 9px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const ComponentEditorDetailsNav = styled.ul`
  display: flex;
  list-style: none;

  li {
    margin-right: 25px;
    padding: 10px;
    cursor: pointer;
  }
`;

export function ComponentEditorView({
  dispatch,
  sharedState,
  productData,
  sysProperties,
  system,
  component,
  state,
  componentsMessages
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly system: System.System;
  readonly component: System.Component;
  readonly productData: Product.Product | undefined;
  readonly componentsMessages: ReadonlyArray<ComponentMessage>;
}): JSX.Element {
  const systemStatus: SystemStatus = system.status;

  const productProperties = React.useMemo<
    ReadonlyArray<PropertiesSelector.PropertyInfo>
  >(() => {
    if (productData === undefined) {
      return [];
    }
    const filteredProductData = ProductDataTools.filterProductForRange(
      productData,
      sysProperties
    );

    return filteredProductData.properties;
  }, [productData]).filter(p => !p.name.startsWith("acc_"));

  if (!productData) {
    return <ComponentEditorRoot>Error product not found</ComponentEditorRoot>;
  }
  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const hasProductImage =
    state.detailsState.summaryData?.product.product.images.find(
      i =>
        i.imageUsage === GraphQlTypes.ImageUsage.DESIGN_PERF_PARAMS &&
        i.name === "Design" &&
        PropertyFilterHelpers.isValid(
          i.propertyFilter,
          PropertyFilter.Empty,
          component.properties
        )
    ) !== undefined;

  // Not to happy about showResultSummary, showOpCaseResults and getActiveTab()
  // but its a way to hide them when there are no results for those tabs.
  const showResultSummary =
    hasProductImage ||
    getResultSummaryTabCaseResults(
      sharedState.translate,
      system.operatingCases,
      [],
      component!.id,
      sharedState.user.applicationClaims
    ).filter(r => Object.keys(r.results).length > 0).length > 0;
  const showOpCaseResults =
    getOperatingCaseTabCaseResults(
      sharedState.translate,
      system.operatingCases,
      component.id
    ).filter(r => Object.keys(r.results).length > 0).length > 0;

  const hasSpecificationImage =
    state.detailsState.summaryData?.product.product.images.find(
      i => i.imageUsage === GraphQlTypes.ImageUsage.SPECIFICATIONS
    ) !== undefined;

  const componentSpecRows = ComponentSpecs.getValidRows(
    state.detailsState?.summaryData?.product.product.screen_Specifications,
    sharedState,
    component.properties
  );

  const showCompoentSpec =
    (!!componentSpecRows.length &&
      !PropertyValueSet.isEmpty(component.componentSpec)) ||
    hasSpecificationImage;

  const activeTab = getActiveTab(
    state.activeTab,
    showResultSummary,
    showOpCaseResults,
    showCompoentSpec
  );

  return (
    <ComponentEditorRoot>
      <ComponentEditorPropertiesSelectorRoot>
        <ComponentMessages
          sharedState={sharedState}
          systemTypeId={system.file.systemTypeId}
          products={[productData]}
          componentMessages={componentsMessages.filter(
            cm => cm.componentId === component.id
          )}
          operatingCaseResults={system.operatingCases.reduce<
            ReadonlyArray<OperatingCaseResult>
          >((a, b) => {
            return [
              ...a,
              ...b.results.map(r => ({
                id: r.id,
                componentId: r.componentId,
                caseType: b.caseType,
                calculationType: r.calculationType,
                operatingCase: {
                  id: b.id,
                  caseName: b.caseName,
                  customCaseName: b.customCaseName ?? undefined,
                  caseType: b.caseType,
                  sortNo: b.sortNo
                }
              }))
            ];
          }, [])}
        />
        <PropertiesSelector.PropertiesSelectorView
          dispatch={Dispatch.map(Action.dispatchPropertiesSelector, dispatch)}
          productId={productData.id}
          sharedState={sharedState}
          propertiesInfo={productProperties}
          fieldGroup={`Settings.${productData.id}`}
          isReadonly={systemStatus >= SystemStatus.LockSuccess}
          showCodes={showCodes}
          state={state.propertiesSelectorState}
        />
      </ComponentEditorPropertiesSelectorRoot>
      <ComponentEditorSeparator />
      <ComponentEditorDetailsRoot>
        <ComponentEditorDetailsNav>
          {showResultSummary && (
            <StyledLi
              active={activeTab === "results-summary"}
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                if (activeTab !== "results-summary") {
                  dispatch(Action.changeActiveTab("results-summary"));
                }
              }}
            >
              <Badge color="secondary" variant="standard">
                {sharedState.translate(LanguageTexts.resultsSummary())}
              </Badge>
            </StyledLi>
          )}
          {showOpCaseResults && (
            <StyledLi
              active={activeTab === "op-case-results"}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                if (activeTab !== "op-case-results") {
                  dispatch(Action.changeActiveTab("op-case-results"));
                }
              }}
            >
              <Badge color="secondary" variant="standard">
                {sharedState.translate(LanguageTexts.opCaseResults())}
              </Badge>
            </StyledLi>
          )}
          {showCompoentSpec && (
            <StyledLi
              active={activeTab === "component-spec"}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                if (activeTab !== "component-spec") {
                  dispatch(Action.changeActiveTab("component-spec"));
                }
              }}
            >
              <Badge
                color="secondary"
                variant={
                  component.externalNotes.length > 0 ||
                  component.internalNotes.length > 0
                    ? "dot"
                    : "standard"
                }
              >
                {sharedState.translate(LanguageTexts.componentSpec())}
              </Badge>
            </StyledLi>
          )}
          <StyledLi
            active={activeTab === "notes"}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              if (activeTab !== "notes") {
                dispatch(Action.changeActiveTab("notes"));
              }
            }}
          >
            <Badge
              color="secondary"
              variant={
                component.externalNotes.length > 0 ||
                component.internalNotes.length > 0
                  ? "dot"
                  : "standard"
              }
            >
              {sharedState.translate(LanguageTexts.notes())}
            </Badge>
          </StyledLi>
        </ComponentEditorDetailsNav>
        <ComponentEditorDetailsContent
          componentSpecRows={componentSpecRows}
          sharedState={sharedState}
          state={state.detailsState}
          dispatch={Dispatch.map(Action.dispatchDetails, dispatch)}
          component={component}
          system={system}
          activeTab={activeTab}
        />
      </ComponentEditorDetailsRoot>
    </ComponentEditorRoot>
  );
}

function getActiveTab(
  activeTab: ActiveTab,
  showResultSummary: boolean,
  showOpCaseResults: boolean,
  showCompoentSpec: boolean
): ActiveTab {
  if (activeTab === "results-summary" && showResultSummary) {
    return "results-summary";
  } else if (activeTab === "op-case-results" && showOpCaseResults) {
    return "op-case-results";
  } else if (activeTab === "component-spec" && showCompoentSpec) {
    return "component-spec";
  } else if (activeTab === "notes") {
    return "notes";
  } else if (showCompoentSpec) {
    return "component-spec";
  } else {
    return "notes";
  }
}
