import { UpdateFunction } from "../types";

export const handleAddAlertMessageToQueue: UpdateFunction = (action, state) => {
  if (action.type !== "addAlertMessageToQueue") {
    return [state];
  }
  return [
    {
      ...state,
      alertMessagesQueue: state.alertMessagesQueue.concat(action.message)
    }
  ];
};
