import * as React from "react";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { GenesysSelect, ArrowDown, ArrowUp } from "@genesys/ui-elements";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { Action, State } from "./state";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  row-gap: 3px;
  margin-top: 15px;
`;

const Label = styled.label`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const FlexContainerCenter = styled.div`
  display: flex;
  align-items: center;
`;

const StyledP = styled.p`
  color: #00adf2;
  font-size: 13px;
  margin-right: 5px;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
`;

const genesysSelectStylingProps = {
  width: 87,
  height: 36,
  fontSize: 14
};

export function View({
  readonly,
  state,
  sharedState,
  dispatch,
  customTranslation
}: {
  readonly readonly?: boolean;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly customTranslation?: (arg: LanguageTexts.StaticText) => string;
}) {
  const translate = customTranslation || sharedState.translate;

  return (
    <FlexContainer>
      <Container>
        <label>{translate(LanguageTexts.globalPropertyName("country"))}</label>
        <GenesysSelect
          {...genesysSelectStylingProps}
          disabled={readonly}
          options={state.countries.map((g, ix) => ({
            value: ix,
            title: g.countryName
          }))}
          value={state.selectedCountryIndex}
          onChange={e => {
            dispatch(Action.setSelectedCountry(parseInt(e.target.value, 10)));
          }}
        />
        <label>{translate(LanguageTexts.globalPropertyName("state"))}</label>
        {state.countries[state.selectedCountryIndex].regions.length > 1 ? (
          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={readonly}
            options={state.countries[state.selectedCountryIndex].regions.map(
              (g, ix) => ({
                value: ix,
                title: g.regionName
              })
            )}
            value={state.selectedRegionIndex}
            onChange={e => {
              dispatch(Action.setSelectedRegion(parseInt(e.target.value, 10)));
            }}
          />
        ) : (
          <Label>
            {state.countries[state.selectedCountryIndex].regions[0].regionName}
          </Label>
        )}
        <label>{translate(LanguageTexts.globalPropertyName("location"))}</label>
        {state.countries[state.selectedCountryIndex].regions[
          state.selectedRegionIndex
        ].locations.length > 1 ? (
          <GenesysSelect
            {...genesysSelectStylingProps}
            disabled={readonly}
            options={state.countries[state.selectedCountryIndex].regions[
              state.selectedRegionIndex
            ].locations.map((g, ix) => ({
              value: ix,
              title: g.locationName
            }))}
            value={state.selectedLocationIndex}
            onChange={e => {
              dispatch(
                Action.setSelectedLocation(parseInt(e.target.value, 10))
              );
            }}
          />
        ) : (
          <Label>
            {
              state.countries[state.selectedCountryIndex].regions[
                state.selectedRegionIndex
              ].locations[0].locationName
            }
          </Label>
        )}
      </Container>
      <AdditionalInfo sharedState={sharedState} state={state} />
    </FlexContainer>
  );
}

function AdditionalInfo({
  state,
  sharedState
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
}) {
  const selectedLocation =
    state.countries[state.selectedCountryIndex].regions[
      state.selectedRegionIndex
    ].locations[state.selectedLocationIndex];

  const [isOpen, setIsopen] = React.useState(true);

  const canSeeWmo = AuthorizationTools.checkPermission(
    sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.developer
  );

  return (
    <StyledDiv>
      <FlexContainerCenter>
        <StyledP
          onClick={() => {
            setIsopen(!isOpen);
          }}
        >
          Additional info
        </StyledP>
        {isOpen ? <ArrowUp /> : <ArrowDown />}
      </FlexContainerCenter>

      {isOpen && (
        <Container>
          <span>Lat: </span>
          <span>{selectedLocation.latitude}</span>

          <span>Long: </span>
          <span>{selectedLocation.longitude}</span>

          {canSeeWmo && (
            <>
              <span>Wmo: </span>
              <span>{selectedLocation.binDataLocationId}</span>
            </>
          )}
        </Container>
      )}
    </StyledDiv>
  );
}
