import * as React from "react";
import { Quantity } from "@genesys/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as PropertiesSelector from "../react-properties-selector";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as PropertiesSelectorElements from "./elements";
import * as Elements from "../elements";
import * as LocalElements from "./elements";
import * as Contexts from "../contexts";
import { ProductPropertyRowRenderer } from "./row-renderer";

interface Props {
  readonly fieldGroup: string;
  readonly filterPrettyPrint: PropertyFiltering.FilterPrettyPrint;
  readonly hidePropertyNames: boolean;
  readonly onDelete: () => void;
  readonly uniqueId: string;
  readonly renderGroup: GenesysPropertiesSelectorTypes.RenderGroup;
  readonly translatePropertyValue: (
    propertyName: string,
    propertyValue: number
  ) => string;
  readonly valueSourcesDict: {
    readonly [
      propertyName: string
    ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  };
  readonly selectorInfoMap: {
    readonly [key: string]: PropertiesSelector.PropertySelectorRenderInfo;
  };
  readonly conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly onQuantityChange: (
    propertyName: string,
    quantity: Quantity.Quantity
  ) => void;
  readonly showDelete: boolean;
}
// tslint:disable-next-line:function-name
export function GroupWithDeleteButtonRenderer({
  activatedQuantitySelectors,
  conversionParametersMap,
  fieldGroup,
  filterPrettyPrint,
  hidePropertyNames,
  onDelete,
  onQuantityChange,
  renderGroup,
  selectorInfoMap,
  uniqueId,
  valueSourcesDict,
  translatePropertyValue,
  showDelete
}: Props) {
  return (
    <Contexts.TranslateContext.Consumer key={`${uniqueId}-${renderGroup.key}`}>
      {translate => (
        <PropertiesSelectorElements.PropertySelectorGroup>
          {renderGroup.key !== GenesysPropertiesSelectorTypes.NO_GROUP && (
            <Elements.ExpanderWithArrow
              onToggle={renderGroup.onToggle}
              isCollapsed={renderGroup.isCollapsed}
              text={renderGroup.translatedName}
            />
          )}
          <PropertiesSelectorElements.PropertySelectorGroupRows
            hidden={renderGroup.isCollapsed}
          >
            <PropertiesSelectorElements.StyledPropertySelectorGroupTable>
              <tbody>
                {renderGroup.properties.map(renderProperty => {
                  const selectorInfo =
                    selectorInfoMap[renderProperty.propertyName];
                  return (
                    <ProductPropertyRowRenderer
                      fieldName={renderProperty.fieldName}
                      key={`${renderGroup.key}-${renderProperty.propertyName}`}
                      fieldGroup={fieldGroup}
                      hidePropertyName={hidePropertyNames}
                      selectorInfo={selectorInfo}
                      valueSourcesDict={valueSourcesDict}
                      conversionParameters={
                        conversionParametersMap[renderProperty.propertyName]
                      }
                      isQuantitySelectorActive={
                        !!activatedQuantitySelectors[
                          renderProperty.propertyName
                        ]
                      }
                      onQuantityChange={quantity =>
                        onQuantityChange(renderProperty.propertyName, quantity)
                      }
                      translatePropertyValue={translatePropertyValue}
                      filterPrettyPrint={filterPrettyPrint}
                    />
                  );
                })}
                <tr>
                  {!hidePropertyNames && (
                    <>
                      <td />
                      <td />
                    </>
                  )}
                  <td>
                    <LocalElements.DeleteButtonContainer>
                      {showDelete && (
                        <Elements.DeleteButton
                          text={translate(LanguageTexts.deleteText())}
                          onClick={onDelete}
                        />
                      )}
                    </LocalElements.DeleteButtonContainer>
                  </td>
                  <td />
                </tr>
              </tbody>
            </PropertiesSelectorElements.StyledPropertySelectorGroupTable>
          </PropertiesSelectorElements.PropertySelectorGroupRows>
        </PropertiesSelectorElements.PropertySelectorGroup>
      )}
    </Contexts.TranslateContext.Consumer>
  );
}
