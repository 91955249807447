import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import { RadioButton, P1 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { BinType } from "../../types";
import { Action } from "../state";
import { UserEnergySettings } from "../../types";
import { BinTypeSelectorRoot } from "../elements";

const binTypes: ReadonlyArray<BinType> = [
  "Generated",
  "Custom",
  "MoistureLoad"
];

export function BinTypeSelectorComponent({
  binSelections,
  isSystemLocked,
  energySettings,
  selectedBinType,
  hasMoistureLoadInfo,
  translate,
  dispatch
}: {
  readonly isSystemLocked: boolean;
  readonly selectedBinType: BinType;
  readonly hasMoistureLoadInfo: boolean;
  readonly energySettings: UserEnergySettings;
  readonly binSelections: PropertyValueSet.PropertyValueSet;
  readonly translate: LanguageTexts.Translate;
  readonly dispatch: Dispatch<Action>;
}) {
  const handleBinTypeSelection = (binType: BinType) => {
    if (binType === selectedBinType) {
      return;
    } else if (binType === "MoistureLoad" && hasMoistureLoadInfo) {
      dispatch(Action.getMoistureLoadBinCases());
    } else {
      dispatch(Action.setBinCases([]));
    }
    dispatch(
      Action.setEnergySettingsAndBinSelections(
        energySettings,
        PropertyValueSet.setText(
          KnownProperties.binType,
          binType,
          binSelections
        )
      )
    );
  };

  return (
    <BinTypeSelectorRoot>
      {binTypes.map(b => {
        return (
          <RadioButton
            isDisabled={isSystemLocked}
            key={b}
            onChange={() => handleBinTypeSelection(b)}
            checked={b === selectedBinType}
          >
            <P1 weight="normal" color="dark">
              {translate(LanguageTexts.dynamicText("bincase_" + b))}
            </P1>
          </RadioButton>
        );
      })}
    </BinTypeSelectorRoot>
  );
}
