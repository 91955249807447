import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import { AbstractImagePidView, EContactPidView } from "./views";

export function PidView({
  state,
  systemId,
  sharedState,
  systemTypeId,
  systemStatus,
  genesysNoWithRevisionNo,
  dispatch
}: {
  readonly state: State;
  readonly systemTypeId: string;
  readonly systemId: string;
  readonly systemStatus: number;
  readonly genesysNoWithRevisionNo: string;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  switch (state.type) {
    case "abstract-image": {
      return (
        <AbstractImagePidView
          state={state}
          systemTypeId={systemTypeId}
          sharedState={sharedState}
          dispatch={dispatch}
        />
      );
    }
    case "e-contact": {
      return (
        <EContactPidView
          state={state}
          systemId={systemId}
          sharedState={sharedState}
          systemStatus={systemStatus}
          genesysNoWithRevisionNo={genesysNoWithRevisionNo}
          dispatch={dispatch}
        />
      );
    }
  }
}
