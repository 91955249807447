import { PropertyValueSet } from "@genesys/property";
import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Location } from "@genesys/ui-elements/lib/icons";
import * as SharedState from "../shared-state";
import styled from "styled-components";
import { H3, P1 } from "@genesys/ui-elements";
import { getValue } from "@genesys/shared/lib/product-properties";
import { AmountFormatSelector } from "../amount-format-selector";

import { Quantity, Unit } from "@genesys/uom";

export interface Props {
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly title: string;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}

const Root = styled.div`
  max-width: 340px;

  td:first-child {
    display: flex;
    align-items: center;
    align-content: center;
    padding-right: 14px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  color: #646f86;
`;

export function ClimateDataPresenter({
  climateSettings,
  sharedState,
  title,
  onFormatChanged,
  onFormatCleared
}: Props) {
  const locationName =
    PropertyValueSet.getText("locationname", climateSettings) || "";
  const occurrences =
    PropertyValueSet.getText("occurrences", climateSettings) || "";

  const getFormattedValue = (
    fieldName: string,
    quantityType: Quantity.Quantity
  ) => {
    const fieldGroup = "ClimateDataLocationDialog";
    const format = sharedState.screenAmounts.getAmountFormat(
      fieldGroup,
      fieldName,
      quantityType
    );
    return getValue(PropertyValueSet.get(fieldName, climateSettings)!, format);
  };

  const altitudeValue = getFormattedValue("altitude", "Length");
  const pressureValue = getFormattedValue("atmosphericpressure", "Pressure");

  const isManualDataSource = !PropertyValueSet.hasProperty(
    "wmo",
    climateSettings
  );
  const isManualPressure =
    PropertyValueSet.getInteger("custompressure", climateSettings) === 1;

  const translate = sharedState.translate;

  const format = (
    fieldGroup: string,
    fieldName: string,
    quantity: Quantity.Quantity
  ) => {
    const amountFormat = sharedState.screenAmounts.getAmountFormat(
      fieldGroup,
      fieldName,
      quantity
    );

    return (
      <AmountFormatSelector
        type="AmountFormatSelectorProps"
        fieldName={fieldName}
        fieldGroup={fieldGroup}
        amountFormat={amountFormat}
        conversionParameters={undefined}
        translate={translate}
        onFormatCleared={() => onFormatCleared(fieldGroup, fieldName)}
        onFormatChanged={(unit, decimalCount) =>
          onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        }
      />
    );
  };

  return (
    <Root>
      <table>
        <tbody>
          <tr>
            <td>
              <Location height="1.2rem" />
            </td>
            <td>
              <H3 color="dark" weight="bold">
                {title}
              </H3>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <P1 color="secondary" weight="normal">
                {locationName}
              </P1>
              <FlexContainer>
                {!isManualPressure && (
                  <>
                    <P1 color="secondary" weight="normal">
                      {altitudeValue} &nbsp;
                    </P1>
                    {format("ClimateDataLocationDialog", "altitude", "Length")}

                    <P1 color="secondary" weight="normal">
                      &nbsp; / &nbsp;
                    </P1>

                    <P1 color="secondary" weight="normal">
                      {pressureValue} &nbsp;
                    </P1>
                    {format(
                      "ClimateDataLocationDialog",
                      "atmosphericpressure",
                      "Pressure"
                    )}
                  </>
                )}
              </FlexContainer>

              {!isManualDataSource && (
                <P1 color="secondary" weight="normal">
                  {occurrences}
                </P1>
              )}

              {isManualPressure && (
                <FlexContainer>
                  <P1 color="secondary" weight="normal">
                    {translate(LanguageTexts.CustomPressure())},&nbsp;
                  </P1>

                  <P1 color="secondary" weight="normal">
                    {pressureValue} &nbsp;
                  </P1>

                  {format(
                    "ClimateDataLocationDialog",
                    "atmosphericpressure",
                    "Pressure"
                  )}
                </FlexContainer>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
}
