import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as GraphQlTypes from "../../../../graphql-types";
import * as PropertiesSelector from "../../../../properties-selector";
import * as System from "../../../system";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { ElectricityEmissionValueSource } from "../types";

export function getElectricityEmissionValueSource(
  emissionsSelectorState: PropertiesSelector.State,
  binCountry: string,
  co2EmissionIndexes: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["co2EmissionIndexes"][0]
  >
): ElectricityEmissionValueSource {
  const binCountryCO2Indexes = co2EmissionIndexes
    .filter(c => c.country.toLowerCase() === binCountry.toLowerCase())
    .sort((a, b) => (a.year < b.year ? 1 : -1));

  const binCountryMostRecentIndex =
    binCountryCO2Indexes.length !== 0
      ? parseInt(binCountryCO2Indexes[0].emissionIndex, 10)
      : undefined;

  const defaultIndex = parseInt(
    co2EmissionIndexes.find(c => c.country.toLowerCase() === "default")
      ?.emissionIndex ?? "50",
    10
  );

  const electricityEmissionIndex = Amount.valueAs(
    Units.GramPerKiloWattHour,
    PropertyValueSet.getAmount<Quantity.Emission>(
      KnownProperties.CO2EmissionElectric,
      PropertiesSelector.getSelectedProperties(emissionsSelectorState)
    )!
  );

  const electricityEmissionValueSource: ElectricityEmissionValueSource =
    electricityEmissionIndex === binCountryMostRecentIndex
      ? "bin-country-default"
      : electricityEmissionIndex === defaultIndex && !binCountryMostRecentIndex
      ? "default"
      : "edited";

  return electricityEmissionValueSource;
}

export function initialiseEmissionsSelector(
  system: System.System,
  defaultElectricEmissionFactor: number | undefined
): PropertiesSelector.State | undefined {
  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );

  const defaultPropertiesenergyEmissions = getEnergyEmissionDefaultProperties(
    systemBinSelections,
    defaultElectricEmissionFactor
  );

  return PropertiesSelector.init(defaultPropertiesenergyEmissions);
}

export function getEmissionPropertiesDefinitions(
  showElectricity: boolean,
  showCoolingWater: boolean,
  showGas: boolean,
  showHeatingWater: boolean,
  showSteam: boolean,
  _showHumidifierWater: boolean
) {
  const emissionPropertiesDefinitions: ReadonlyArray<PropertiesSelector.PropertyInfo> =
    [
      {
        name: KnownProperties.CO2EmissionElectric,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: showElectricity
          ? PropertyFilter.Empty
          : PropertyFilter.fromString("1=0")!,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      },
      {
        name: KnownProperties.CO2EmissionGas,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: showGas
          ? PropertyFilter.Empty
          : PropertyFilter.fromString("1=0")!,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      },
      {
        name: KnownProperties.CO2EmissionSteam,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: showSteam
          ? PropertyFilter.Empty
          : PropertyFilter.fromString("1=0")!,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      },
      {
        name: KnownProperties.CO2EmissionCoolingWater,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: showCoolingWater
          ? PropertyFilter.Empty
          : PropertyFilter.fromString("1=0")!,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      },
      {
        name: KnownProperties.CO2EmissionHeatingWater,
        group: "",
        quantity: "Emission",
        sortNo: 0,
        items: [],
        validationFilter: PropertyFilter.Empty,
        visibilityFilter: showHeatingWater
          ? PropertyFilter.Empty
          : PropertyFilter.fromString("1=0")!,
        valueSources: [],
        conversionParameters: [],
        descriptionTexts: [],
        defaultValues: []
      }
    ];

  return emissionPropertiesDefinitions;
}

export function getDefaultElectricEmissionFactor(
  binCountry: string,
  co2EmissionIndexes: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["co2EmissionIndexes"][0]
  >
): number {
  const binCountryCO2Indexes = co2EmissionIndexes
    .filter(c => c.country.toLowerCase() === binCountry.toLowerCase())
    .sort((a, b) => (a.year < b.year ? 1 : -1));

  const binCountryMostRecentIndex =
    binCountryCO2Indexes.length !== 0
      ? parseInt(binCountryCO2Indexes[0].emissionIndex, 10)
      : undefined;

  const defaultElectricEmissionFactor = binCountryMostRecentIndex
    ? binCountryMostRecentIndex
    : parseInt(
        co2EmissionIndexes.find(c => c.country.toLowerCase() === "default")
          ?.emissionIndex ?? "50",
        10
      );

  return defaultElectricEmissionFactor;
}

function getEnergyEmissionDefaultProperties(
  binSelections: PropertyValueSet.PropertyValueSet,
  defaultElectricEmissionFactor: number | undefined
): PropertyValueSet.PropertyValueSet {
  const getEmissionFactorPropertyValue = (
    propertyName: string,
    defaultAmountValue: Amount.Amount<unknown>
  ) => {
    let emissionFactor =
      PropertyValueSet.get(propertyName, binSelections) ??
      PropertyValue.fromAmount(defaultAmountValue);
    return emissionFactor;
  };

  let properties = PropertyValueSet.Empty;

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionElectric,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionElectric,
      Amount.create(
        defaultElectricEmissionFactor ?? 10,
        Units.GramPerKiloWattHour
      )
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionGas,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionGas,
      Amount.create(50.17, Units.GramPerMegaJoule)
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionSteam,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionSteam,
      Amount.create(62.72, Units.GramPerMegaJoule)
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionCoolingWater,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionCoolingWater,
      Amount.create(0, Units.GramPerMegaJoule)
    ),
    properties
  );

  properties = PropertyValueSet.set(
    KnownProperties.CO2EmissionHeatingWater,
    getEmissionFactorPropertyValue(
      KnownProperties.CO2EmissionHeatingWater,
      Amount.create(0, Units.GramPerMegaJoule)
    ),
    properties
  );

  return properties;
}
