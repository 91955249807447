import * as PaperSize from "@genesys/shared/lib/enums/paper-size";
import * as PaperSizeEnum from "@genesys/shared/lib/enums/paper-size";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

export type State = {
  readonly printParams: PrintParams | undefined;
  readonly languages: ReadonlyArray<{ readonly id: string }>;
};
export interface PrintParams {
  readonly selectedLanguage: string;
  readonly selectedPaperSize: PaperSize.PaperSize;
  readonly selectedHeaderLabel: { readonly header: string | undefined };
}

export interface PaperSizeInfo {
  readonly size: PaperSizeEnum.PaperSize;
  readonly displayName: string;
}

export const init = (
  languages: ReadonlyArray<{ readonly id: string }>,
  initialPrintParams?: PrintParams
): readonly [State] => {
  return [
    {
      printParams: initialPrintParams,
      languages
    }
  ];
};

export const Action = ctorsUnion({
  setPrintParams: (params: PrintParams) => ({ params }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(action: Action, state: State): [State] {
  switch (action.type) {
    case "setPrintParams":
      return [
        {
          ...state,
          printParams: action.params
        }
      ];

    case "dummy":
      return [
        {
          ...state
        }
      ];

    default:
      exhaustiveCheck(action);
  }
}

export function getSelectedPrintParams(state: State | undefined) {
  return state?.printParams;
}
