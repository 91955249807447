import styled from "styled-components";

// tslint:disable-next-line
export const SubMenuMarker = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  border-left: 4px solid #000;
`;
