import * as React from "react";
import styled from "styled-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ResultTable from "../../../../shared-manager-components/search-result-table";
import * as GraphQLTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import { State, Action } from "../../../state";
import { Dispatch } from "@typescript-tea/core";
import { P2 } from "@genesys/ui-elements";
import { ColumnDefinition, SystemRow } from "../../../types";
import { SearchResultGridComponent } from "../../search-result-grid-component";
import {
  getHeaderCellContent,
  getFileAndRevisionContent
} from "./cell-contents";

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export function Result({
  state,
  sharedState,
  dataColumns,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dataColumns: ReadonlyArray<ColumnDefinition>;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.viewMode === "grid") {
    return (
      <GridContainer>
        {state.queryUserResult?.user.searchSystems.results.map(result => (
          <SearchResultGridComponent
            key={result.id}
            dispatch={dispatch}
            sharedState={sharedState}
            result={result}
            systemActionsState={state.systemActionsState?.state}
            isRevisionDropdownOpen={state.expandedRevisions.includes(result.id)}
            isSystemActionsOpen={
              state.systemActionsState?.systemId === result.id
            }
            selectedSystemIds={state.selectedSystemIds}
            userSelectedRevisions={state.userSelectedRevisions}
            onToggleSystemSelected={id =>
              dispatch(Action.toggleSystemSelected(id))
            }
            onToggleIsFavorite={id => dispatch(Action.toggleFavoriteSystem(id))}
            onToggleRevisionDropdown={id => dispatch(Action.toggleRevision(id))}
            onToggleSystemActions={id =>
              dispatch(Action.toggleOpenSystemActions(id))
            }
            onChangeActiveRevision={(systemId, revisionId) =>
              dispatch(Action.updateUserSelectedRevisions(systemId, revisionId))
            }
          />
        ))}
      </GridContainer>
    );
  }

  const allSystemIds =
    state.queryUserResult?.user.searchSystems.results.reduce(
      (a, b) => a.concat(b.systems.map(s => s.id)),
      [] as ReadonlyArray<string>
    ) || [];

  const isAllSelected = allSystemIds.length === state.selectedSystemIds.length;

  return (
    <ResultTable.Container>
      <ResultTable.TableHeader
        headerIsChecked={isAllSelected}
        checkBoxOnClick={() =>
          isAllSelected
            ? dispatch(Action.clearAllSelectedSystems())
            : dispatch(Action.selectAllSystems())
        }
        cellValues={dataColumns.map(columnDef =>
          getHeaderCellContent(
            state,
            columnDef.columnName,
            sharedState,
            dispatch
          )
        )}
      />
      {state.queryUserResult?.user.searchSystems.results.map(resultRow => {
        const isRevisionExpanded = state.expandedRevisions.includes(
          resultRow.id
        );

        return (
          <ResultTable.TableBodyRowContainer
            key={resultRow.id}
            isRevisionExpanded={isRevisionExpanded}
          >
            {createResultTableRow(
              sharedState,
              state,
              dataColumns,
              resultRow,
              isRevisionExpanded,
              dispatch
            )}

            {resultRow.systems.map(rev => {
              return createRevisionRow(
                state,
                sharedState,
                isRevisionExpanded,
                dataColumns,
                resultRow,
                rev,
                dispatch
              );
            })}
          </ResultTable.TableBodyRowContainer>
        );
      })}
    </ResultTable.Container>
  );
}

function createRevisionRow(
  state: State,
  sharedState: SharedState.State,
  isRevisionExpanded: boolean,
  dataColumns: readonly ColumnDefinition[],
  resultRow: GraphQLTypes.SystemManagerUserSearchResult,
  rev: GraphQLTypes.SystemManagerUserResultSystem,
  dispatch: (action: Action) => void
) {
  const isActionsOpen = state.systemActionsState?.systemId === rev.id;
  const revisionRow: SystemRow = {
    type: "revision",
    id: rev.id,
    comment: rev.comment,
    systems: resultRow.systems,
    lastModifiedBy: rev.lastModifiedBy,
    isDeleted: resultRow.isDeleted,
    systemFileId: resultRow.id,
    systemTypeId: resultRow.systemTypeId,
    systemLabel: rev.systemLabel,
    genesysNo: resultRow.genesysNo,
    revisionNo: rev.revisionNo,
    status: rev.status,
    statusDate: rev.statusDate,
    isActionMenuOpen: isActionsOpen,
    isRevisionExpanded: isRevisionExpanded
  };

  return (
    <ResultTable.RevisionRow
      key={rev.id}
      isActionsOpen={isActionsOpen}
      isRowChecked={state.selectedSystemIds.includes(rev.id)}
      isRevisionExpanded={isRevisionExpanded}
      cells={dataColumns.map(columnDef =>
        getFileAndRevisionContent(
          state,
          columnDef.columnName,
          sharedState,
          dispatch,
          revisionRow
        )
      )}
      checkBoxOnClick={() => {
        dispatch(Action.toggleSystemSelected(rev.id));
      }}
    />
  );
}

function createResultTableRow(
  //   allRevisionsChecked: boolean,
  sharedState: SharedState.State,
  state: State,
  dataColumns: readonly ColumnDefinition[],
  resultRow: GraphQLTypes.SystemManagerUserSearchResult,
  isRevisionExpanded: boolean,
  dispatch: (action: Action) => void
) {
  const isActionsOpen = state.systemActionsState?.systemId === resultRow.id;
  const latestSystem = resultRow.systems.reduce(
    (a, b) => (b.revisionNo > a.revisionNo ? b : a),
    resultRow.systems[0]
  );

  const anyRevisionChecked = !!state.selectedSystemIds.find(
    s => !!resultRow.systems.find(ss => ss.id === s)
  );

  const systemFileRow: SystemRow = {
    type: "file",
    id: resultRow.id,
    labels: resultRow.labels,
    name: resultRow.name,
    owner: resultRow.owner,
    isDeleted: resultRow.isDeleted,
    systemTypeId: resultRow.systemTypeId,
    systemLabel: resultRow.systemLabel,
    systems: resultRow.systems,
    latestSystem: latestSystem,
    genesysNo: resultRow.genesysNo,
    isActionMenuOpen: isActionsOpen,
    isRevisionExpanded: isRevisionExpanded
  };

  return (
    <ResultTable.TableRow
      key={resultRow.id}
      revisionsHeader={
        systemFileRow.isRevisionExpanded &&
        createRevisionHeader(
          dataColumns.findIndex(x => x.columnName === "g-no") + 1,
          dataColumns.length + 1,
          sharedState
        )
      }
      isRowChecked={anyRevisionChecked}
      cells={dataColumns.map(columnDef =>
        getFileAndRevisionContent(
          state,
          columnDef.columnName,
          sharedState,
          dispatch,
          systemFileRow
        )
      )}
      isActionsOpen={systemFileRow.isActionMenuOpen}
      checkBoxOnClick={() => {
        dispatch(Action.toggleSystemSelected(latestSystem.id));
      }}
    />
  );
}

function createRevisionHeader(
  revisionCell: number,
  totalNumberOfcells: number,
  sharedState: SharedState.State
) {
  return (
    <tr>
      {Array(totalNumberOfcells)
        .fill(null)
        .map((_u, ix) => (
          <td>
            {ix === revisionCell ? (
              <P2 color="dark" weight="bold">
                {sharedState.translate(LanguageTexts.revisions())}
              </P2>
            ) : (
              ""
            )}
          </td>
        ))}
    </tr>
  );
}
