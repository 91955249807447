import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 720px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const CasesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  padding-top: 10px;
`;

export function climateSelectorCustomRenderer(
  locationComponent: JSX.Element,
  caseComponents: ReadonlyArray<JSX.Element>,
  _buttonComponent: JSX.Element,
  _licensingComponent: JSX.Element
) {
  return (
    <Container>
      <FlexContainer>
        {locationComponent}
        <CasesContainer>
          {caseComponents.map(caseComponent => caseComponent)}
        </CasesContainer>
      </FlexContainer>
    </Container>
  );
}
