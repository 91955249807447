import * as React from "react";
import styled from "styled-components";
import { ProductImageView, ResultSummaryProduct } from "./product-image";
import { Amount, Quantity } from "@genesys/uom";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { AmountPropertyValue } from "@genesys/property/src/property-value";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as SharedState from "../../../../../shared-state";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as GraphQlTypes from "../../../../../graphql-types";
import { AmountFormatSelector } from "../../../../../amount-format-selector";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import {
  PropertyFilter,
  PropertyValueSet,
  PropertyValue
} from "@genesys/property";

const Container = styled.div`
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;

const StyledLabel = styled.label`
  padding-right: 5px;
`;

const ImageValueContainer = styled.div`
  position: absolute;
  font-size: 11px;
  padding-left: 7px;
  padding-right: 7px;

  display: flex;
  align-items: center;
  line-height: 11px;
`;

export function ProductImagePerfParamsView({
  productData,
  selectedProperties,
  sharedState,
  resultConversionParametersMap,
  caseResult,
  systemTypeId,
  dispatch
}: {
  readonly productData: ResultSummaryProduct;
  readonly resultConversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly caseResult: PropertyValueSet.PropertyValueSet;
  readonly systemTypeId: string;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element | null {
  const image = ProductImageView({
    product: productData,
    selectedProperties: selectedProperties,
    sharedState: sharedState
  });

  if (!image) {
    return null;
  }

  const applicationClaims = sharedState.user.applicationClaims;
  const propertyConversionParametersMap = createPropertyConversionParameters(
    productData.properties,
    selectedProperties
  );
  return (
    <Container>
      {image}
      {productData.imageDataMappings
        .filter(
          m =>
            PropertyFilterHelpers.isValid(
              m.propertyFilter,
              PropertyFilter.Empty,
              selectedProperties
            ) &&
            Authorization.checkClaimFilter(
              applicationClaims,
              PropertyFilterHelpers.createPropertyFilter(
                m.claimFilter,
                PropertyFilter.Empty
              )
            )
        )
        .map((m, i) => {
          switch (m.valueSource) {
            case "Property":
              return (
                <div key={`${productData.id}${i}`}>
                  {getProperty(
                    productData.id,
                    m.valueSourceParameter,
                    dispatch,
                    selectedProperties,
                    propertyConversionParametersMap,
                    sharedState.translate,
                    sharedState.screenAmounts.getAmountFormat,
                    m.x,
                    m.y
                  )}
                </div>
              );
            case "Result":
              return (
                <div key={`${productData.id}${i}`}>
                  {getResult(
                    productData.id,
                    m.valueSourceParameter,
                    dispatch,
                    caseResult,
                    resultConversionParametersMap,
                    sharedState.screenAmounts.getAmountFormat,
                    sharedState.translate,
                    m.x,
                    m.y
                  )}
                </div>
              );
            case "FreeText":
              return (
                <div key={`${productData.id}${i}`}>
                  {getFreeText(m.valueSourceParameter, m.x, m.y)}
                </div>
              );
            case "TextId":
              return (
                <div key={`${productData.id}${i}`}>
                  {getTextId(
                    m.valueSourceParameter,

                    m.x,
                    m.y,
                    systemTypeId,
                    sharedState.translate
                  )}
                </div>
              );

            default:
              return <>{m.sortNo}</>;
          }
        })}
    </Container>
  );
}

function createPropertyConversionParameters(
  properties: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["properties"][0]
  >,
  selectedProperties: PropertyValueSet.PropertyValueSet
): {
  readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
} {
  return properties.reduce(
    (
      soFar: {
        // tslint:disable-next-line:readonly-keyword
        [key: string]: QuantityConversion.ConversionParameters | undefined;
      },
      current
    ) => {
      if (current.quantityConversionParams.length === 0) {
        return soFar;
      }
      soFar[current.name] = QuantityConversion.createConversionParameters(
        current.quantityConversionParams,
        selectedProperties
      );
      return soFar;
    },
    {}
  );
}

function getProperty(
  productId: string,
  propertyName: string,
  dispatch: Dispatch<Action>,
  selectedProperties: PropertyValueSet.PropertyValueSet,
  conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  },
  translate: LanguageTexts.Translate,
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  xPos: number,
  yPos: number
): JSX.Element {
  const propertyValue = PropertyValueSet.get(propertyName, selectedProperties);
  if (!propertyValue) {
    return <></>;
  }

  switch (propertyValue.type) {
    case "amount":
      const fieldGroup = `Settings.${productId}`;
      const fieldName = propertyName;

      const conversionParameters = conversionParametersMap[propertyName];
      const amountFormat = getAmountFormat(
        fieldGroup,
        fieldName,
        PropertyValue.getAmount(
          propertyValue
        )! as Amount.Amount<Quantity.Quantity>
      );

      return placeAmount(
        propertyValue,
        amountFormat,
        dispatch,
        fieldGroup,
        fieldName,
        conversionParameters,
        xPos,
        yPos,
        translate
      );
    case "integer":
      return placeText(
        translate(
          LanguageTexts.productPropertyValue(
            productId,
            propertyName,
            PropertyValue.getInteger(propertyValue)!
          )
        ),

        xPos,
        yPos
      );
    case "text":
      return placeText(propertyValue.value, xPos, yPos);
    default:
      return <></>;
  }
}

function getResult(
  productId: string,
  resultName: string,
  dispatch: Dispatch<Action>,
  results: PropertyValueSet.PropertyValueSet,
  conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  },
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  xPos: number,
  yPos: number
): JSX.Element {
  const propertyValue = PropertyValueSet.get(resultName, results);
  if (!propertyValue) {
    return <></>;
  }

  switch (propertyValue.type) {
    case "amount":
      const getFieldGroupFieldNameResult = ScreenAmounts.getFieldGroupFieldName(
        productId,
        resultName
      );
      if (getFieldGroupFieldNameResult === undefined) {
        return <></>;
      }

      const { fieldGroup, fieldName } = getFieldGroupFieldNameResult;
      const conversionParameters = conversionParametersMap[resultName];
      const amountFormat = getAmountFormat(
        fieldGroup,
        fieldName,
        PropertyValue.getAmount(
          propertyValue
        )! as Amount.Amount<Quantity.Quantity>
      );

      return placeAmount(
        propertyValue,
        amountFormat,
        dispatch,
        fieldGroup,
        fieldName,
        conversionParameters,
        xPos,
        yPos,
        translate
      );
    case "integer":
      return placeText(propertyValue.value.toString(), xPos, yPos);
    case "text":
      return placeText(propertyValue.value, xPos, yPos);
    default:
      return <></>;
  }
}

function placeAmount(
  propertyValue: AmountPropertyValue,
  amountFormat: ScreenAmounts.AmountFormat,
  dispatch: Dispatch<Action>,
  fieldGroup: string,
  fieldName: string,
  conversionParameters: QuantityConversion.ConversionParameters | undefined,
  xPos: number,
  yPos: number,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string
): JSX.Element {
  return (
    <ImageValueContainer
      style={{
        left: xPos,
        top: yPos
      }}
    >
      <div>
        <StyledLabel>
          {ProductProperties.getValue(
            propertyValue,
            amountFormat,
            conversionParameters
          )}
        </StyledLabel>
      </div>
      <div>
        <AmountFormatSelector
          type="AmountFormatSelectorProps"
          fieldName={fieldName}
          fieldGroup={fieldGroup}
          amountFormat={amountFormat}
          conversionParameters={conversionParameters}
          translate={translate}
          onFormatChanged={(unit, decimalCount) => {
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            );
          }}
          onFormatCleared={() => {
            dispatch(Action.onFormatCleared(fieldGroup, fieldName));
          }}
        />
      </div>
    </ImageValueContainer>
  );
}

function getTextId(
  textId: string,
  xPos: number,
  yPos: number,
  systemTypeId: string,
  translate: LanguageTexts.Translate
): JSX.Element {
  return placeText(
    translate(LanguageTexts.dynamicText(textId, systemTypeId)),
    xPos,
    yPos
  );
}

function getFreeText(
  freeText: string,
  xPos: number,
  yPos: number
): JSX.Element {
  return placeText(freeText, xPos, yPos);
}

function placeText(text: string, xPos: number, yPos: number): JSX.Element {
  return (
    <ImageValueContainer style={{ left: xPos, top: yPos }}>
      <StyledLabel>{text}</StyledLabel>
    </ImageValueContainer>
  );
}
