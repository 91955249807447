import * as React from "react";
import { State, Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { AmountPropertySelector } from "../../../amount-property-selector";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import styled from "styled-components";
import * as PropertiesSelector from "../../../properties-selector";
import { P1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { GreyDiv } from "../elements";

const Root = styled.div`
  padding: 20px;
`;

const Table = styled.table`
  td {
    min-width: 78px;
  }
`;

export function PressureComponent({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const showPower =
    (PropertyValueSet.getInteger(
      "showpower",
      PropertiesSelector.getSelectedProperties(state.chartSettings)
    ) as number) !== 0;

  return (
    <Root>
      <Table>
        <tbody>
          <tr>
            <td>
              <P1 weight="normal" color="dark">
                {sharedState.translate(
                  LanguageTexts.globalPropertyName("pressure")
                )}
              </P1>
            </td>
            <td>
              <GreyDiv>
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="Pressure"
                  fieldGroup="PsychrometricTool"
                  fieldName={"pressure"}
                  readOnly={false}
                  propertyName={"pressure"}
                  propertyValueSet={state.pressure}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "PsychrometricTool",
                        "pressure",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(
                      Action.onFormatCleared("PsychrometricTool", "pressure")
                    )
                  }
                  onValueChange={value =>
                    value
                      ? dispatch(
                          Action.setPressure(
                            PropertyValueSet.set(
                              "pressure",
                              PropertyValue.fromAmount(value),
                              state.pressure
                            )
                          )
                        )
                      : state.pressure
                  }
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              </GreyDiv>
            </td>
          </tr>
          {showPower && (
            <tr>
              <td>
                <P1 weight="normal" color="dark">
                  {sharedState.translate(
                    LanguageTexts.globalPropertyName("airflow")
                  )}
                </P1>
              </td>
              <td>
                <GreyDiv>
                  <AmountPropertySelector
                    type="with-simplified-props"
                    translate={sharedState.translate}
                    quantity="MassFlow"
                    fieldGroup="PsychrometricTool"
                    fieldName={"airflow"}
                    readOnly={false}
                    propertyName={"airflow"}
                    propertyValueSet={state.airflow}
                    onFormatChanged={(unit, decimalCount) =>
                      dispatch(
                        Action.onFormatChanged(
                          "PsychrometricTool",
                          "airflow",
                          unit,
                          decimalCount
                        )
                      )
                    }
                    onFormatCleared={() =>
                      dispatch(
                        Action.onFormatCleared("PsychrometricTool", "airflow")
                      )
                    }
                    onValueChange={value =>
                      value
                        ? dispatch(
                            Action.setAirFlow(
                              PropertyValueSet.set(
                                "airflow",
                                PropertyValue.fromAmount(value),
                                state.airflow
                              )
                            )
                          )
                        : state.airflow
                    }
                    getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                  />
                </GreyDiv>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Root>
  );
}
