import gql from "graphql-tag";

export const query = gql`
  query HumidifierSizeSelectorNew(
    $hum: GetProductInputType!
    $sys: GetProductInputType!
  ) {
    product {
      hum: product(input: $hum) {
        id
        properties {
          id
          name
          values {
            value
            validationFilter
            rangeFilter
          }
        }
        cassetteArea: productDataTable(tableName: "cassetteArea") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
        widthAndHeight: productDataTable(tableName: "widthAndHeight") {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
      sys: product(input: $sys) {
        id
        sizeSelectionMaxVelocity: productDataTable(
          tableName: "sizeSelectionMaxVelocity"
        ) {
          id
          tableName
          rows {
            id
            propertyFilter
            values
          }
        }
      }
    }
  }
`;
