import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type H3Props = Partial<Options> & {
  readonly children: React.ReactNode;
};
// tslint:disable-next-line
const StyledH3 = styled.h3<H3Props>`
  font-size: 1.067rem;
  font-weight: 500;
  line-height: 1.333rem;
  letter-spacing: 0;
  ${({ weight = "bold", color = "primary" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function H3(props: H3Props): JSX.Element {
  return <StyledH3 {...props} />;
}
