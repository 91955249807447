import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as Types from "./types";
import * as Product from "./product";
import * as System from "./system";
import * as SequenceRules from "./sequence-rules";
import * as PlacementRules from "./placement-rules";
import { v4 } from "uuid";

export function getOpenPositions(
  componentSectionToBeMovedOrPlacedId: string,
  componentSectionToBeMovedOrPlacedProductSectionId: string,
  positionValidation: Product.PositionValidation,
  productSectionPlacementRules: ReadonlyArray<Product.PlacementRule>,
  productSectionSequenceRules: ReadonlyArray<Product.SequenceRule>,
  sysProps: PropertyValueSet.PropertyValueSet,
  airstreams: ReadonlyArray<System.Airstream>,
  ports: ReadonlyArray<FlowDiagram.Types.Port>
): ReadonlyArray<Types.OpenPosition> {
  const openPositions: Array<Types.OpenPosition> = [];

  const rulesRegexes = SequenceRules.createSequenceRegexes(
    sysProps,
    productSectionSequenceRules
  );

  for (const airstream of airstreams) {
    let previousSectiondId: string | undefined = undefined;

    for (const section of airstream.componentSections) {
      if (
        section.id !== componentSectionToBeMovedOrPlacedId &&
        (previousSectiondId === undefined ||
          previousSectiondId !== componentSectionToBeMovedOrPlacedId) &&
        isPlacementValid(
          positionValidation,
          productSectionPlacementRules,
          rulesRegexes,
          sysProps,
          airstream,
          componentSectionToBeMovedOrPlacedId,
          componentSectionToBeMovedOrPlacedProductSectionId,
          section.id
        )
      ) {
        const outlet =
          previousSectiondId !== undefined
            ? ports.find(
                p =>
                  p.connectionType === "Outlet" &&
                  p.componentSectionId === previousSectiondId
              )
            : undefined;

        const inlet = ports.find(
          p =>
            p.connectionType === "Inlet" && p.componentSectionId === section.id
        );
        const position: Types.OpenPosition = {
          airstreamId: airstream.id,
          inlet,
          outlet
        };

        openPositions.push(position);
      }

      previousSectiondId = section.id;
    }
  }

  return openPositions;
}

export function getOpenPositionById(
  openPositions: ReadonlyArray<Types.OpenPosition>,
  id: string
): Types.OpenPosition | undefined {
  const parts = id.split(".");
  if (parts.length !== 3) {
    return undefined;
  }
  const [, inlet, outlet] = parts;
  return openPositions.find(
    op =>
      (op.inlet && op.inlet.componentSectionId) === inlet &&
      (op.outlet && op.outlet.componentSectionId) === outlet
  );
}

export function getComponentReplacements(
  component: System.Component,
  products: ReadonlyArray<Product.Product>,
  positionValidation: Product.PositionValidation,
  placementRules: ReadonlyArray<Product.PlacementRule>,
  sequenceRules: ReadonlyArray<Product.SequenceRule>,
  sysProps: PropertyValueSet.PropertyValueSet,
  airstreams: ReadonlyArray<System.Airstream>
) {
  const componentSectionIds = component.sections.map(
    s => s.productSectionId.split(".")[1]
  );
  const product = products.find(p => p.id === component.productId)!;
  const rulesRegexes = SequenceRules.createSequenceRegexes(
    sysProps,
    sequenceRules
  );

  return products.filter(testProduct => {
    if (
      testProduct.id !== product.id &&
      testProduct.optional &&
      testProduct.sections.length === componentSectionIds.length &&
      testProduct.productType === Product.PropertyTypeEnum.FUNCTION &&
      testProduct.sections.every(s =>
        componentSectionIds.includes(s.id.split(".")[1])
      ) &&
      PropertyFilter.isValid(sysProps, testProduct.rangeFilter)
    ) {
      return component.sections.every(current => {
        const productSection = products
          .find(p => p.id === testProduct.id)!
          .sections.find(
            ps => ps.id.split(".")[1] === current.productSectionId.split(".")[1]
          );

        if (!productSection) {
          return false;
        }

        const airstream =
          (current.systemAirStreamId &&
            airstreams.find(a => a.id === current.systemAirStreamId)) ||
          undefined;

        if (!airstream) {
          return false;
        }

        const afterSection =
          productSection &&
          airstream &&
          airstream.componentSections[
            airstream.componentSections.findIndex(x => x.id === current.id) + 1
          ];

        if (!afterSection) {
          return false;
        }
        return isReplacementValid(
          positionValidation,
          placementRules,
          rulesRegexes,
          sysProps,
          airstream,
          current.id,
          productSection.id,
          afterSection.id
        );
      });
    }
    return false;
  });
}

function isPlacementValid(
  positionValidation: Product.PositionValidation,
  productSectionPlacementRules: ReadonlyArray<Product.PlacementRule>,
  productSectionSequenceRules: ReadonlyArray<RegExp>,
  sysProps: PropertyValueSet.PropertyValueSet,
  airstream: System.Airstream,
  componentSectionToBeMovedOrPlacedId: string,
  componentSectionToBeMovedOrPlacedProductSectionId: string,
  beforeSectionId: string | undefined
): boolean {
  const sections = airstream.componentSections.filter(
    cs => cs.id !== componentSectionToBeMovedOrPlacedId
  );

  const index =
    beforeSectionId !== undefined
      ? sections.map(cs => cs.id).indexOf(beforeSectionId)
      : sections.length;

  const stream = sections.map(cs => cs.productSectionId);
  stream.splice(index, 0, componentSectionToBeMovedOrPlacedProductSectionId);

  switch (positionValidation) {
    case Product.PositionValidationEnum.PLACEMENT: {
      return (
        PlacementRules.getInvalidPlacementPositions(
          sysProps,
          productSectionPlacementRules,
          stream
        ).length === 0
      );
    }
    case Product.PositionValidationEnum.SEQUENCE: {
      return (
        SequenceRules.getInvalidSequencePositions(
          productSectionSequenceRules,
          stream
        ).length === 0
      );
    }
    case Product.PositionValidationEnum.NONE: {
      throw new Error(
        "Could not find position validator for positionValidation.NONE"
      );
    }
    default: {
      exhaustiveCheck(positionValidation, true);
      return false;
    }
  }
}

export function isReplacementValid(
  positionValidation: Product.PositionValidation,
  productSectionPlacementRules: ReadonlyArray<Product.PlacementRule>,
  productSectionSequenceRules: ReadonlyArray<RegExp>,
  sysProps: PropertyValueSet.PropertyValueSet,
  airstream: System.Airstream,
  componentSectionToBeRemovedId: string,
  componentSectionToBePlacedProductSectionId: string,
  beforeSectionId: string | undefined
): boolean {
  const sections = airstream.componentSections.filter(
    cs => cs.id !== componentSectionToBeRemovedId
  );

  const index =
    beforeSectionId !== undefined
      ? sections.map(cs => cs.id).indexOf(beforeSectionId)
      : sections.length;

  const stream = sections.map(cs => cs.productSectionId);
  stream.splice(index, 0, componentSectionToBePlacedProductSectionId);

  switch (positionValidation) {
    case Product.PositionValidationEnum.PLACEMENT: {
      return (
        PlacementRules.getInvalidPlacementPositions(
          sysProps,
          productSectionPlacementRules,
          stream
        ).length === 0
      );
    }
    case Product.PositionValidationEnum.SEQUENCE: {
      return (
        SequenceRules.getInvalidSequencePositions(
          productSectionSequenceRules,
          stream
        ).length === 0
      );
    }
    case Product.PositionValidationEnum.NONE: {
      throw new Error(
        "Could not find position validator for positionValidation.NONE"
      );
    }
    default: {
      exhaustiveCheck(positionValidation, true);
      return false;
    }
  }
}

export function isThereAnOpenPositionForSectionId(
  componentSectionToBeMovedOrPlacedProductSectionId: string,
  positionValidation: Product.PositionValidation,
  productSectionPlacementRules: ReadonlyArray<Product.PlacementRule>,
  sysProps: PropertyValueSet.PropertyValueSet,
  airstreams: ReadonlyArray<System.Airstream>,
  sequenceRegexes: ReadonlyArray<RegExp>
): boolean {
  for (const airstream of airstreams) {
    for (const section of airstream.componentSections) {
      if (
        isPlacementValid(
          positionValidation,
          productSectionPlacementRules,
          sequenceRegexes,
          sysProps,
          airstream,
          v4(),
          componentSectionToBeMovedOrPlacedProductSectionId,
          section.id
        )
      ) {
        return true;
      }
    }
  }

  return false;
}

export function getSequenceRegexes(
  sysProperties: PropertyValueSet.PropertyValueSet,
  productSectionSequenceRules: ReadonlyArray<Product.SequenceRule>
): ReadonlyArray<RegExp> {
  const rulesRegexes = SequenceRules.createSequenceRegexes(
    sysProperties,
    productSectionSequenceRules
  );

  return rulesRegexes;
}
