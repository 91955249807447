import { PropertyValueSet } from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { Component, ComponentSection } from "./types";

export function getAirFlows(
  operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  components: ReadonlyArray<Component>
) {
  const dhuComponent = components.find(c => c.productId.endsWith("DHU"));

  if (dhuComponent === undefined) {
    return [];
  }

  const dhuProcessSection = dhuComponent.sections.find(s =>
    s.productSectionId.endsWith(".Process")
  )!;

  const processAirStream = components
    .reduce((sofar: Array<ComponentSection>, current) => {
      if (current.sections.length === 0) {
        return sofar;
      }

      sofar.push(
        ...current.sections.filter(
          cs => cs.airstream === dhuProcessSection.airstream
        )
      );
      return sofar;
    }, [])
    .sort((a, b) => (a.sortNo - b.sortNo < 0 ? -1 : 1));

  return operatingCases.map(oc =>
    getAirFlowFromOperatingCase(oc, dhuProcessSection, processAirStream)
  );
}

function getAirFlowFromOperatingCase(
  opcProperties: PropertyValueSet.PropertyValueSet,
  dhuProcessSection: ComponentSection,
  processAirStream: ComponentSection[]
): Amount.Amount<Quantity.MassFlow> {
  const outletFlowPropertyName = processAirStream[
    processAirStream.length - 1
  ].productSectionId.endsWith("SUC.1")
    ? "supplyoutletairflow"
    : "reactoutletairflow";

  const outletFlow = PropertyValueSet.hasProperty(
    outletFlowPropertyName,
    opcProperties
  )
    ? PropertyValueSet.getAmount<Quantity.MassFlow>(
        outletFlowPropertyName,
        opcProperties
      )!
    : Amount.create(0, Units.KilogramPerSecond);

  const postMixingBoxSection = processAirStream.find(
    cs =>
      cs.sortNo > dhuProcessSection.sortNo &&
      (cs.productSectionId.endsWith("SMA.1") ||
        cs.productSectionId.endsWith("SME.1"))
  );

  const postMixingFlow =
    postMixingBoxSection === undefined
      ? Amount.create(0, Units.KilogramPerSecond)
      : PropertyValueSet.getAmount<Quantity.MassFlow>(
          "postmixingboxairflow",
          opcProperties
        )!;

  const postBleedOffSection = processAirStream.find(
    cs =>
      cs.sortNo > dhuProcessSection.sortNo &&
      cs.productSectionId.endsWith("BOB.Process")
  );

  const bleedOffFlow =
    postBleedOffSection === undefined
      ? Amount.create(0, Units.KilogramPerSecond)
      : PropertyValueSet.getAmount<Quantity.MassFlow>(
          "bleedoffairflow",
          opcProperties
        )!;

  return Amount.plus(Amount.minus(outletFlow, postMixingFlow), bleedOffFlow);
}
