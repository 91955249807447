import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createArrowDownSvg(color: string): string {
  const svgString = `

<svg width="11px" height="6px" viewBox="0 0 11 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>93793EB6-167F-46D5-8D71-7071577D2F2E</title>
    <defs>
        <polygon id="path-1" points="0 0.125 5 5.125 10 0.125"></polygon>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-148.000000, -1160.000000)">
            <g id="Icons/Actions/Dropdown" transform="translate(148.640000, 1160.000000)">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <use id="Shape" fill="${color}" xlink:href="#path-1"></use>
            </g>
        </g>
    </g>
</svg>
`;

  return encodeSvgStringToImgSrc(svgString);
}
