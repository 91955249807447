import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import { ModalComponent } from "../../modal-component";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { P1 } from "@genesys/ui-elements";

export function ModalContent({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state.modal) {
    return null;
  }

  type OnCancel = () => void;
  type OnConfirn = () => void;

  const getModalContentAndActions = (): [
    React.ReactNode,
    OnConfirn,
    OnCancel?
  ] => {
    const modal = state.modal!;
    switch (modal.type) {
      case "info-modal": {
        return [modal.info, () => dispatch(Action.closeModal())];
      }

      case "open-ml-modal": {
        const url = modal.url;
        return [
          "Open in new tab ?",
          () => (window.location.href = url),
          () => {
            window.open(url);
            dispatch(Action.closeModal());
          }
        ];
      }

      case "confirm-ml-delete-modal": {
        const selectedMoistureLoadFileId = modal.selectedMoistureLoadFilesID;
        return [
          sharedState.translate(
            LanguageTexts.deleteAllSelectedMsg(modal.selectedRevisions)
          ),
          () => dispatch(Action.closeModal()),
          () => {
            dispatch(Action.deleteMoistureLoads(selectedMoistureLoadFileId));
          }
        ];
      }

      case "confirm-label-delete-modal": {
        const labelId = modal.label!.id;
        return [
          sharedState.translate(
            LanguageTexts.deleteAllSelectedMsg(
              state.selectedMoistureLoadIds.length
            )
          ),
          () => dispatch(Action.closeModal()),
          () => {
            dispatch(Action.deleteLabel(labelId));
            dispatch(Action.closeModal());
          }
        ];
      }
      case "help-about-searching": {
        return [
          <>
            <P1 weight="bold">Search logic:</P1>
            <P1 weight="normal">
              One or more search conditions of any type (see syntax) can be
              used. Search conditions are combined and all supplied conditions
              must be fulfilled to get a hit.
            </P1>
            <P1 weight="bold">Search syntax:</P1>
            <P1 weight="normal">
              label:xxx - Moisture Loads with specified label attached.
              mno:M(Site)00000x - Moisture loads with specified moisture load
              number. owner:XxxXxx - Moisture loads owned by specified login
              name
            </P1>
            <P1 weight="bold">Examples: </P1>
            <P1 weight="normal">
              'label:test label:project1' - Searches all moisture load files
              with label 'test' AND label 'project1'
            </P1>
          </>,
          () => dispatch(Action.closeModal())
        ];
      }

      default:
        return exhaustiveCheck(modal, true);
    }
  };

  const [content, onCancel, onConfirm] = getModalContentAndActions();

  return (
    <ModalComponent
      sharedState={sharedState}
      header={state.modal.header}
      content={content}
      cancelLabel={state.modal.cancelLabel}
      confirmLabel={state.modal.confirmLabel}
      onClose={onCancel}
      onConfirm={onConfirm}
    />
  );
}
