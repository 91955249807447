import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../../../../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { ResultSummaryProduct } from "./visualizers";
import { prepareRows, CaseResult } from "./shared-tools";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";

const Container = styled.div<{ readonly noOfColumns: number }>`
  display: grid;
  grid-template-columns: ${props => getGenerateGridColumns(props.noOfColumns)};
`;

const GridColumn = styled.div<{
  readonly hasBlueBackground: boolean;
  readonly isHidden: boolean | undefined;
}>`
  color: #373737;
  background-color: ${props => props.hasBlueBackground && "#f7f9fc"};
  ${props => props.isHidden && "border: 1px solid red;"}
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  font-size: 13px; ;
`;

const Label = styled.div`
  letter-spacing: 0;
`;

const HeaderLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  padding-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export function OpCaseResults({
  sharedState,
  selectedProperties,
  productId,
  productData,
  caseResult,
  resultConversionParametersMapByCaseResultId,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly caseResult: ReadonlyArray<CaseResult>;
  readonly productId: string;
  readonly dispatch: Dispatch<Action>;
  readonly productData: ResultSummaryProduct;
  readonly resultConversionParametersMapByCaseResultId: {
    readonly [caseResultId: string]: {
      readonly [key: string]:
        | QuantityConversion.ConversionParameters
        | undefined;
    };
  };
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
}): JSX.Element | null {
  const validRows = (productData.screen_OpCaseResults?.rows || [])
    .map(r => ({
      propertyFilter: r.propertyFilter,
      values: PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        r.values
      )
    }))
    .filter(
      r =>
        sharedState.debugSettings.showHiddenResults ||
        (PropertyFilterHelpers.isValid(
          r.propertyFilter,
          PropertyFilter.Empty,
          selectedProperties
        ) &&
          Authorization.checkClaimFilter(
            sharedState.user.applicationClaims,
            PropertyFilterHelpers.createPropertyFilter(
              PropertyValueSet.getText("ClaimFilter", r.values),
              PropertyFilter.Empty
            )
          ))
    );

  if (validRows.length === 0) {
    return null;
  }

  const groupedRows = groupRows(validRows);

  return (
    <Container noOfColumns={caseResult.length}>
      {Array.from(groupedRows.entries()).map(([groupName, rows], i) => {
        const remainingHeaders =
          i === 0
            ? caseResult.map(r => r.displayName)
            : generateEmptyArr(caseResult.length);
        const resultRows = prepareRows(
          rows,
          productId,
          sharedState,
          {
            type: "multiple",
            result: caseResult,
            resultConversionParametersMapByCaseResultId:
              resultConversionParametersMapByCaseResultId
          },
          dispatch,
          selectedProperties,
          sharedState.user.applicationClaims
        ).filter(r => r.results.some(r2 => r2 !== ""));

        const allHeaders = [
          sharedState.translate(LanguageTexts.globalPropertyName(groupName)),
          ...remainingHeaders
        ];

        const gridColumns: Array<JSX.Element> = [];
        const gridHeaders = allHeaders.map((header, i) => [
          <HeaderLabel key={i}>{header}</HeaderLabel>
        ]);

        let index = 0;
        for (const r of resultRows) {
          const hasBlueBackground = index % 2 === 0;
          gridColumns.push(
            <GridColumn
              isHidden={r.isHidden}
              key={`${i}${r.name}${index}`}
              hasBlueBackground={hasBlueBackground}
            >
              <Label>{r.name}</Label>
              {""}
              {r.amountFormatSelector && r.amountFormatSelector()}
            </GridColumn>
          );

          const resultForRow = r.results;
          let j = 0;
          for (const res of resultForRow) {
            gridColumns.push(
              <GridColumn
                isHidden={false}
                hasBlueBackground={hasBlueBackground}
                key={`${i}${j}${index}`}
              >
                <Label>{res}</Label>
              </GridColumn>
            );
            j++;
          }

          index++;
        }
        const rowsForSection = [...gridHeaders, ...gridColumns];
        return rowsForSection;
      })}
    </Container>
  );
}

function generateEmptyArr(length: number) {
  const strArr = [];
  for (let i = 0; i < length; i++) {
    strArr.push(" ");
  }

  return strArr;
}

function getGenerateGridColumns(col: number) {
  let style = "3fr";

  for (let i = 0; i < col; i++) {
    style += " 1fr";
  }

  return style;
}

function groupRows(
  rows: ReadonlyArray<{
    readonly propertyFilter: string | undefined | null;
    readonly values: PropertyValueSet.PropertyValueSet;
  }>
): Map<
  string,
  ReadonlyArray<{
    readonly propertyFilter: string | undefined | null;
    readonly claimFilter: string | undefined | null;
    readonly groupName: string | undefined;
    readonly name: string | undefined;
  }>
> {
  const map = new Map<
    string,
    Array<{
      readonly propertyFilter: string | undefined | null;
      readonly claimFilter: string | undefined | null;
      readonly groupName: string | undefined;
      readonly name: string | undefined;
    }>
  >();

  for (const row of rows) {
    const groupName = PropertyValueSet.getText("GroupName", row.values) || "";
    const name = PropertyValueSet.getText("Name", row.values) || "";
    const claimFilter =
      PropertyValueSet.getText("ClaimFilter", row.values) || "";
    const group = map.get(groupName);
    const convertedRow = {
      groupName,
      name,
      claimFilter,
      propertyFilter: row.propertyFilter
    };
    if (!group) {
      map.set(groupName, [convertedRow]);
    } else {
      group.push(convertedRow);
    }
  }

  return map;
}
