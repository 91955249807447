import * as React from "react";
import styled from "styled-components";
import { ArrowUp, ArrowDown } from "@genesys/ui-elements";

export const OrderByContainer = styled.div<{ readonly current: boolean }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 3px;

  ${props => props.current && "border-bottom: 2px solid #646F86; "}

  span {
    margin-left: 8px;
    margin-top: -3px;
  }
`;

export function OrderyByHeader({
  sortedColumn,
  headerElement,
  isDescending,
  setOrderByColumn
}: {
  readonly headerElement: JSX.Element;
  readonly sortedColumn: boolean;
  readonly isDescending: boolean;
  readonly setOrderByColumn: () => void;
}) {
  return (
    <OrderByContainer current={sortedColumn} onClick={() => setOrderByColumn()}>
      {headerElement}{" "}
      <span>
        {isDescending ? (
          <ArrowDown height="0.5rem" />
        ) : (
          <ArrowUp height="0.5rem" />
        )}
      </span>
    </OrderByContainer>
  );
}
