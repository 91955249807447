import * as Guid from "@genesys/shared/lib/guid";
import * as GraphQLTypes from "@genesys/graphql-types";
import moment from "moment";

export type TabSubject =
  | AboutTabSubject
  | StartTabSubject
  | AmountProfileSettingsTabSubject
  | UnsupportedBrowserTabSubject
  | DefaultSettingsTabSubject
  | PropertiesSelectorExampleSubject
  | AirMixingToolTabSubject
  | AirPowerToolTabSubject
  | UsageStatisticsTabSubject
  | AdvancedProductSearchTabSubject
  | EffectiveClaimsTabSubject
  | PsychrometricChartTabSubject
  | HumidityConversionTabSubject
  | CoilProToolTabSubject
  | FanProToolTabSubject
  | OilOnToolTabSubject
  | PricingManagerSubject
  | SystemManagerSubject
  | PricingEditorSubject
  | LabelsManagerSubject
  | NewSystemWizardSubject
  | CreateNewPricingSubject
  | DataCenterWizardSubject
  | ProductPricePreviewTabSubject
  | ProjectSelectionTabSubject
  | SystemSummaryTabSubject
  | SystemEditorSubject
  | DebugTabSubject
  | NevadaTabSubject
  | JohanTestSubject
  | SystemTypeValidationTabSubject
  | ProductValidationTabSubject
  | RotorProductValidationTabSubject
  | ProductPropertySearchTabSubject
  | SystemTypePropertySearchTabSubject
  | VariantListingTabSubject
  | SystemCompareTabSubject
  | MlcUsageStatisticsTabSubject
  | TAndCAcceptanceStatusTabSubject
  | TranslationSearchTabSubject;

export interface TAndCAcceptanceStatusTabSubject {
  readonly type: "TAndCAcceptanceStatusTabSubject";
}

export function createTAndCAcceptanceStatusTabSubject(): TAndCAcceptanceStatusTabSubject {
  return {
    type: "TAndCAcceptanceStatusTabSubject"
  };
}

interface SystemCompareTabSubject {
  readonly type: "SystemCompareTabSubject";
}

export function createSystemCompareTabSubject(): SystemCompareTabSubject {
  return {
    type: "SystemCompareTabSubject"
  };
}

export interface NevadaTabSubject {
  readonly type: "NevadaTabSubject";
}

export function createNevadaTestSubject(): NevadaTabSubject {
  return {
    type: "NevadaTabSubject"
  };
}

export function createSystemSummaryTabSubject(
  systemIds: ReadonlyArray<string>
): SystemSummaryTabSubject {
  return {
    type: "SystemSummary",
    systemIds: systemIds
  };
}

export interface SystemSummaryTabSubject {
  readonly type: "SystemSummary";
  readonly systemIds: ReadonlyArray<string>;
}

export function createProjectSelectionTabSubject(
  systemIds: ReadonlyArray<string>
): ProjectSelectionTabSubject {
  return {
    type: "ProjectSelectionTabSubject",
    systemIds: systemIds
  };
}

export interface ProjectSelectionTabSubject {
  readonly type: "ProjectSelectionTabSubject";
  readonly systemIds: ReadonlyArray<string>;
}

export function createDataCenterWizardSubject(): DataCenterWizardSubject {
  return {
    type: "DataCenterWizardSubject"
  };
}

export interface DataCenterWizardSubject {
  readonly type: "DataCenterWizardSubject";
}

export function createCreateNewPricingSubject(
  systemId: Guid.Guid | undefined
): CreateNewPricingSubject {
  return {
    type: "CreateNewPricingSubject",
    systemId: systemId
  };
}

export interface CreateNewPricingSubject {
  readonly type: "CreateNewPricingSubject";
  readonly systemId: Guid.Guid | undefined;
}

export function createPricingEditorSubject(
  id: Guid.Guid
): PricingEditorSubject {
  return {
    type: "PricingEditorSubject",
    id: id
  };
}

export interface PricingEditorSubject {
  readonly type: "PricingEditorSubject";
  readonly id: Guid.Guid;
}

export function createSystemManagerSubject(
  searchKey: string,
  scope: GraphQLTypes.SystemSearchScope
): SystemManagerSubject {
  return {
    type: "SystemManagerSubject",
    searchKey: searchKey,
    scope: scope,
    time: moment().unix()
  };
}

export interface SystemManagerSubject {
  readonly type: "SystemManagerSubject";
  readonly searchKey: string;
  readonly scope: GraphQLTypes.SystemSearchScope;
  readonly time: number;
}

export function createPricingManagerSubject(): PricingManagerSubject {
  return {
    type: "PricingManagerSubject"
  };
}

export interface PricingManagerSubject {
  readonly type: "PricingManagerSubject";
}

export function createCoilProToolTabSubject(): CoilProToolTabSubject {
  return {
    type: "CoilProToolTabSubject"
  };
}

export interface CoilProToolTabSubject {
  readonly type: "CoilProToolTabSubject";
}

export function createFanProToolTabSubject(): FanProToolTabSubject {
  return {
    type: "FanProToolTabSubject"
  };
}

export interface FanProToolTabSubject {
  readonly type: "FanProToolTabSubject";
}

export function createOilonToolTabSubject(): OilOnToolTabSubject {
  return {
    type: "OilonToolTabSubject"
  };
}

export interface OilOnToolTabSubject {
  readonly type: "OilonToolTabSubject";
}

export function createHumidityConversionTabSubject(): HumidityConversionTabSubject {
  return {
    type: "HumidityConversionTabSubject"
  };
}

export interface HumidityConversionTabSubject {
  readonly type: "HumidityConversionTabSubject";
}

export function createPsychrometricChartTabSubject(): PsychrometricChartTabSubject {
  return {
    type: "PsychrometricChartTabSubject"
  };
}

export interface PsychrometricChartTabSubject {
  readonly type: "PsychrometricChartTabSubject";
}

export interface EffectiveClaimsTabSubject {
  readonly type: "EffectiveClaimsTabSubject";
}

export function createEffectiveClaimsTabSubject(): EffectiveClaimsTabSubject {
  return {
    type: "EffectiveClaimsTabSubject"
  };
}

export interface UsageStatisticsTabSubject {
  readonly type: "UsageStatisticsTabSubject";
}

export function createUsageStatisticsTabSubject(): UsageStatisticsTabSubject {
  return {
    type: "UsageStatisticsTabSubject"
  };
}

export interface MlcUsageStatisticsTabSubject {
  readonly type: "MlcUsageStatisticsTabSubject";
}

export function createMlcUsageStatisticsTabSubject(): MlcUsageStatisticsTabSubject {
  return {
    type: "MlcUsageStatisticsTabSubject"
  };
}

export interface AdvancedProductSearchTabSubject {
  readonly type: "AdvancedProductSearchTabSubject";
}

export function createAdvancedProductSearchTabSubject(): AdvancedProductSearchTabSubject {
  return {
    type: "AdvancedProductSearchTabSubject"
  };
}

export interface AirPowerToolTabSubject {
  readonly type: "AirPowerToolTabSubject";
}

export function createAirPowerToolTabSubject(): AirPowerToolTabSubject {
  return {
    type: "AirPowerToolTabSubject"
  };
}

export interface AirMixingToolTabSubject {
  readonly type: "AirMixingToolTabSubject";
}

export function createAirMixingToolTabSubject(): AirMixingToolTabSubject {
  return {
    type: "AirMixingToolTabSubject"
  };
}

export interface UnsupportedBrowserTabSubject {
  readonly type: "UnsupportedBrowserTabSubject";
}

export function createUnsupportedBrowserTabSubject(): UnsupportedBrowserTabSubject {
  return {
    type: "UnsupportedBrowserTabSubject"
  };
}

export interface AmountProfileSettingsTabSubject {
  readonly type: "AmountProfileSettingsTabSubject";
}

export function createAmountProfileSettingsTabSubject(): AmountProfileSettingsTabSubject {
  return {
    type: "AmountProfileSettingsTabSubject"
  };
}

export interface DefaultSettingsTabSubject {
  readonly type: "DefaultSettingsTabSubject";
}

export function createDefaultSettingTabSubject(): DefaultSettingsTabSubject {
  return {
    type: "DefaultSettingsTabSubject"
  };
}

export interface AboutTabSubject {
  readonly type: "AboutTabSubject";
}

export function createAboutTabSubject(): AboutTabSubject {
  return {
    type: "AboutTabSubject"
  };
}

export interface StartTabSubject {
  readonly type: "StartTabSubject";
}

export function createStartTabSubject(): StartTabSubject {
  return {
    type: "StartTabSubject"
  };
}

export interface JohanTestSubject {
  readonly type: "JohanTestSubject";
}

export function createJohanTestSubject(): JohanTestSubject {
  return {
    type: "JohanTestSubject"
  };
}

export interface PropertiesSelectorExampleSubject {
  readonly type: "PropertiesSelectorExampleSubject";
}

export function createPropertiesSelectorExampleSubject(): PropertiesSelectorExampleSubject {
  return {
    type: "PropertiesSelectorExampleSubject"
  };
}

export interface LabelsManagerSubject {
  readonly type: "LabelsManagerSubject";
}

export function createLabelsManagerSubject(): LabelsManagerSubject {
  return {
    type: "LabelsManagerSubject"
  };
}

export interface NewSystemWizardSubject {
  readonly type: "NewSystemWizardSubject";
  readonly id: Guid.Guid;
}

export function createNewSystemWizardSubject(
  id?: Guid.Guid
): NewSystemWizardSubject {
  return {
    type: "NewSystemWizardSubject",
    id: id || Guid.createGuid()
  };
}

export interface SystemEditorSubject {
  readonly type: "SystemEditorSubject";
  readonly id: Guid.Guid;
}

export function createSystemEditorSubject(id: Guid.Guid): SystemEditorSubject {
  return {
    type: "SystemEditorSubject",
    id: id
  };
}

export interface ProductPricePreviewTabSubject {
  readonly type: "ProductPricePreviewTabSubject";
}

export function createProductPricePreviewTabSubject(): ProductPricePreviewTabSubject {
  return {
    type: "ProductPricePreviewTabSubject"
  };
}

export interface DebugTabSubject {
  readonly type: "DebugTabSubject";
}

export function createDebugTabSubject(): DebugTabSubject {
  return {
    type: "DebugTabSubject"
  };
}

export interface SystemTypeValidationTabSubject {
  readonly type: "SystemTypeValidationTabSubject";
}

export function createSystemTypeValidationTabSubject(): SystemTypeValidationTabSubject {
  return {
    type: "SystemTypeValidationTabSubject"
  };
}

export interface ProductValidationTabSubject {
  readonly type: "ProductValidationTabSubject";
}

export function createProductValidationTabSubject(): ProductValidationTabSubject {
  return {
    type: "ProductValidationTabSubject"
  };
}

export interface RotorProductValidationTabSubject {
  readonly type: "RotorProductValidationTabSubject";
}

export function createRotorProductValidationTabSubject(): RotorProductValidationTabSubject {
  return {
    type: "RotorProductValidationTabSubject"
  };
}

export interface ProductPropertySearchTabSubject {
  readonly type: "ProductPropertySearchTabSubject";
}

export function createProductPropertySearchTabSubject(): ProductPropertySearchTabSubject {
  return {
    type: "ProductPropertySearchTabSubject"
  };
}

export interface SystemTypePropertySearchTabSubject {
  readonly type: "SystemTypePropertySearchTabSubject";
}

export function createSystemTypePropertySearchTabSubjectt(): SystemTypePropertySearchTabSubject {
  return {
    type: "SystemTypePropertySearchTabSubject"
  };
}

export interface VariantListingTabSubject {
  readonly type: "VariantListingTabSubject";
}

export function createVariantListingTabSubjectt(): VariantListingTabSubject {
  return {
    type: "VariantListingTabSubject"
  };
}

export interface TranslationSearchTabSubject {
  readonly type: "TranslationSearchTabSubject";
}

export function createTranslationSearchTabSubject(): TranslationSearchTabSubject {
  return {
    type: "TranslationSearchTabSubject"
  };
}
