import { OperationTime } from "./state";

export const binOperationTime = "binoperationtime";
export const months = "months";
export const monday = "monday";
export const tuesday = "tuesday";
export const wednesday = "wednesday";
export const thursday = "thursday";
export const friday = "friday";
export const saturday = "saturday";
export const sunday = "sunday";

export const monthRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const hourRange = [
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  ...[13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
];

export type MutableValues = {
  // tslint:disable-next-line:readonly-keyword
  [row: string]: boolean;
};

export const allMonthsYear = monthRange.reduce((soFar, current) => {
  soFar[current] = true;
  return soFar;
}, {} as MutableValues);
export const noMonthsYear = monthRange.reduce((soFar, current) => {
  soFar[current] = false;
  return soFar;
}, {} as MutableValues);
export const allMonthsYearExceptJuly = monthRange.reduce((soFar, current) => {
  soFar[current] = current !== 7;
  return soFar;
}, {} as MutableValues);

export const allHoursDay = hourRange.reduce((soFar, current) => {
  soFar[current] = true;
  return soFar;
}, {} as MutableValues);
export const noHoursDay = hourRange.reduce((soFar, current) => {
  soFar[current] = false;
  return soFar;
}, {} as MutableValues);
export const factoryHoursDay = hourRange.reduce((soFar, current) => {
  soFar[current] = current >= 7 && current <= 16;
  return soFar;
}, {} as MutableValues);

export const twoShiftDay = hourRange.reduce((soFar, current) => {
  soFar[current] = current >= 7 && current <= 23;
  return soFar;
}, {} as MutableValues);
export const officeHoursDay = hourRange.reduce((soFar, current) => {
  soFar[current] = current >= 8 && current <= 17;
  return soFar;
}, {} as MutableValues);

const allDay: OperationTime = {
  months: allMonthsYear,
  monday: allHoursDay,
  tuesday: allHoursDay,
  wednesday: allHoursDay,
  thursday: allHoursDay,
  friday: allHoursDay,
  saturday: allHoursDay,
  sunday: allHoursDay
};

const noHours: OperationTime = {
  months: noMonthsYear,
  monday: noHoursDay,
  tuesday: noHoursDay,
  wednesday: noHoursDay,
  thursday: noHoursDay,
  friday: noHoursDay,
  saturday: noHoursDay,
  sunday: noHoursDay
};

const factoryHours: OperationTime = {
  months: allMonthsYear,
  monday: factoryHoursDay,
  tuesday: factoryHoursDay,
  wednesday: factoryHoursDay,
  thursday: factoryHoursDay,
  friday: factoryHoursDay,
  saturday: noHoursDay,
  sunday: noHoursDay
};

const officeHours: OperationTime = {
  months: allMonthsYear,
  monday: officeHoursDay,
  tuesday: officeHoursDay,
  wednesday: officeHoursDay,
  thursday: officeHoursDay,
  friday: officeHoursDay,
  saturday: noHoursDay,
  sunday: noHoursDay
};
const twoShiftHours: OperationTime = {
  months: allMonthsYear,
  monday: twoShiftDay,
  tuesday: twoShiftDay,
  wednesday: twoShiftDay,
  thursday: twoShiftDay,
  friday: twoShiftDay,
  saturday: noHoursDay,
  sunday: noHoursDay
};

export const presets = {
  allDay,
  noHours,
  factoryHours,
  officeHours,
  twoShiftHours
};
export function getPresetKey(operationTime: OperationTime) {
  for (const key of Object.keys(presets) as ReadonlyArray<PresetKey>) {
    const preset = presets[key as keyof typeof presets];
    if (JSON.stringify(operationTime) === JSON.stringify(preset)) {
      return key;
    }
  }
  return undefined;
}

export type PresetKey = keyof typeof presets;
