import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import { H3, CheckBox, ArrowDown, ArrowUp, Img } from "@genesys/ui-elements";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { DiscretePropertySelectorOptions } from "@promaster-sdk/react-property-selectors";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { clientConfig } from "../../../config";
import * as SharedState from "../../../shared-state";
import * as PropertiesSelector from "../../../properties-selector";
import * as System from "../../system";
import * as Product from "../../product";
import * as GraphQlTypes from "../../../graphql-types";
import { GeneratedAccessories } from "./generated-accessories";
import { Action, State } from "./state";
import { AccessoryData, NormalAccessory } from "./types";
import { DiscretePropertySelector } from "../../../discrete-property-selector";

type PropertyItem = {
  readonly sortNo: number;
  readonly image?: string;
  readonly value: PropertyValue.PropertyValue | undefined | null;
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly text: string;
};

const Container = styled.div`
  margin-bottom: 15px;
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
`;

const StyledH3 = styled(H3)`
  display: inline-block;
  font-size: 14px;
  line-height: 1.333rem;
  letter-spacing: 0px;
  color: rgb(2, 52, 66);
  font-weight: normal;
`;

const ComboBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PropertySelectorContainer = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-right: 10px;
  }
`;

// const RowContainer = styled.div`
// display: flex;
// align-items: center;
// `

export function AccessoryRow({
  accessoryData,
  systemTypeName,
  sharedState,
  sysProperties,
  system,
  images,
  state,
  dispatch
}: {
  readonly accessoryData: AccessoryData;
  readonly dispatch: Dispatch<Action>;
  readonly systemTypeName: string;
  readonly sharedState: SharedState.State;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly system: System.System;
  readonly state: State;
  readonly images: ReadonlyArray<Product.Image>;
}): JSX.Element {
  if (accessoryData.type === "GeneratedAccessory") {
    return (
      <Container>
        <GeneratedAccessories
          systemStatus={system.status}
          parentPropertyName={sharedState.translate(
            LanguageTexts.product(accessoryData.accessoryProductId)
          )}
          sharedState={sharedState}
          sysProperties={sysProperties}
          accessoryData={accessoryData}
          dispatch={dispatch}
          isOpen={!!state.collapsed[accessoryData.accessoryProductId]}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Row
        accessoryData={accessoryData}
        dispatch={dispatch}
        images={images}
        sharedState={sharedState}
        sysProperties={sysProperties}
        systemStatus={system.status}
        systemTypeName={systemTypeName}
        isOpen={!!state.collapsed[accessoryData.accessoryProductId]}
      />
    </Container>
  );
}

function Row({
  isOpen,
  sharedState,
  sysProperties,
  systemStatus,
  systemTypeName,
  images,
  accessoryData,
  dispatch
}: {
  readonly isOpen: boolean;
  readonly accessoryData: NormalAccessory;
  readonly sharedState: SharedState.State;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly systemStatus: number;
  readonly systemTypeName: string;
  readonly dispatch: Dispatch<Action>;
  readonly images: ReadonlyArray<Product.Image>;
}): JSX.Element {
  const translateParentPropertyName = sharedState.translate(
    LanguageTexts.systemTypeProperty(
      systemTypeName,
      accessoryData.parentPropertyName
    )
  );
  const parentValue = PropertyValueSet.getInteger(
    accessoryData.parentPropertyName,
    accessoryData.parentSelectedProperties
  )!;

  const sourceInt = PropertyValueSet.getInteger(
    `source_${accessoryData.parentPropertyName}`,
    accessoryData.parentSelectedProperties
  );

  const parentvalidValueSources = accessoryData.parentValueSources.filter(vs =>
    PropertyFilter.isValid(
      accessoryData.parentSelectedProperties,
      vs.propertyFilter
    )
  );

  const effectiveValueSourceValue =
    (sourceInt !== undefined &&
      parentvalidValueSources.find(vs => vs.value === sourceInt)) ||
    (parentvalidValueSources[0] !== undefined
      ? parentvalidValueSources[0]
      : undefined);

  const isDisabled =
    systemStatus >= SystemStatus.LockSuccess ||
    accessoryData.parentProperty.items.filter(v =>
      PropertyFilterHelpers.isValid(
        v.validationFilter,
        PropertyFilter.Empty,
        accessoryData.parentSelectedProperties
      )
    ).length === 1 ||
    (effectiveValueSourceValue !== undefined &&
      effectiveValueSourceValue.propertyValueSourceId !== "User");

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const valueSource = (
    <PropertiesSelector.ValueSourceSelector
      isLocked={false}
      onChange={(
        selectedProperties: PropertyValueSet.PropertyValueSet,
        propertyName: string
      ) => {
        dispatch(
          Action.parentPropertiesChanged(selectedProperties, propertyName)
        );
      }}
      propertyName={accessoryData.parentPropertyName}
      properties={accessoryData.parentSelectedProperties}
      showCodes={showCodes}
      valueSources={parentvalidValueSources}
    />
  );

  const checkBoxOrDiscretePropertySelector =
    accessoryData.parentProperty.items.length === 2 ? (
      <StyledSpan>
        <CheckBox
          isChecked={parentValue > 0}
          disabled={isDisabled}
          onClick={() => {
            const newValue = parentValue > 0 ? 0 : 1;
            dispatch(
              Action.toggleAccessory(
                accessoryData.accessoryProductData.id,
                newValue
              )
            );
          }}
          children={
            <StyledSpan
              onClick={() => {
                dispatch(
                  Action.toggleCollapsed(accessoryData.accessoryProductId)
                );
              }}
            >
              <StyledH3 weight="normal">{translateParentPropertyName}</StyledH3>
            </StyledSpan>
          }
        />
        {isOpen ? <ArrowDown /> : <ArrowUp />}
        {valueSource}
      </StyledSpan>
    ) : (
      <ComboBoxContainer>
        <DiscretePropertySelector
          {...getDiscreteComnboBoxPropertySelectorProps(
            dispatch,
            accessoryData,
            showCodes
          )}
          disabled={isDisabled}
          lockedByValueSource={false}
          accessToken={sharedState.accessToken}
        ></DiscretePropertySelector>
        <StyledSpan
          style={{ marginLeft: "5px" }}
          onClick={() => {
            dispatch(Action.toggleCollapsed(accessoryData.accessoryProductId));
          }}
        >
          <StyledH3 weight="normal"> {translateParentPropertyName}</StyledH3>
          {isOpen ? <ArrowDown /> : <ArrowUp />}
          {valueSource}
        </StyledSpan>
      </ComboBoxContainer>
    );

  const header = <Header>{checkBoxOrDiscretePropertySelector}</Header>;

  if (isOpen || parentValue <= 0) {
    return header;
  }
  const filteredProductData = ProductDataTools.filterProductForRange(
    accessoryData.accessoryProductData,
    sysProperties
  );

  const image = images.find(
    i =>
      i.imageUsage === GraphQlTypes.ImageUsage.DESIGN_PERF_PARAMS &&
      PropertyFilterHelpers.isValid(
        i.propertyFilter,
        PropertyFilter.Empty,
        PropertiesSelector.getSelectedProperties(
          accessoryData.propertiesSelectorState
        )
      )
  );

  return (
    <div>
      {header}
      <PropertySelectorContainer>
        <PropertiesSelector.PropertiesSelectorView
          fieldGroup={`Settings.${accessoryData.accessoryProductData.id}`}
          isReadonly={systemStatus >= SystemStatus.LockSuccess}
          propertiesInfo={filteredProductData.properties}
          dispatch={Dispatch.map(
            action =>
              Action.dispatchPropertiesSelector(
                accessoryData.accessoryProductId,
                accessoryData.componentId,
                action
              ),
            dispatch
          )}
          state={accessoryData.propertiesSelectorState}
          showCodes={showCodes}
          hidePropertyNames={false}
          sharedState={sharedState}
          productId={accessoryData.accessoryProductData.id}
        />
        <ImageRender accessToken={sharedState.accessToken} image={image} />
      </PropertySelectorContainer>
    </div>
  );
}

function ImageRender({
  image,
  accessToken
}: {
  readonly image: Product.Image | undefined;
  readonly accessToken: string;
}) {
  if (!image) {
    return null;
  }
  return (
    <div style={{ width: "45%" }}>
      <Img
        accessToken={accessToken}
        src={
          clientConfig.genesysBackend +
          image.url +
          `${
            image.imageFormat === GraphQlTypes.ImageFormat.SVG
              ? "?useFixedSize=true"
              : ""
          }`
        }
      />
    </div>
  );
}

function getDiscreteComnboBoxPropertySelectorProps(
  dispatch: Dispatch<Action>,
  accessoryData: NormalAccessory,
  showCodes: boolean
): DiscretePropertySelectorOptions<PropertyItem> {
  return {
    // Defines a single property
    propertyName: accessoryData.parentPropertyName, // The name of the property
    items: accessoryData.parentProperty.items.map((v, i) => ({
      value: v.value,
      sortNo: i,
      text: v.value.value.toString(),
      validationFilter: v.validationFilter
    })), // Where the items are to be gotten from
    propertyValueSet: accessoryData.parentSelectedProperties,
    onValueChange: pv => {
      dispatch(
        Action.toggleAccessory(
          accessoryData.accessoryProductData.id,
          pv?.value as any
        )
      );
    },
    getUndefinedValueItem: () => ({
      value: undefined,
      sortNo: 0,
      text: "Choose property 1",
      validationFilter: PropertyFilter.Empty
    }),
    showCodes: showCodes,
    sortValidFirst: true,
    filterPrettyPrint: () => "",
    getItemValue: item => item.value,
    getItemFilter: item => item.validationFilter
  };
}
