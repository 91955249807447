import * as React from "react";

// tslint:disable-next-line:function-name
export function MiniSpinner({ size }: { readonly size: number }) {
  return (
    <img
      height={`${size}px`}
      width={`${size}px`}
      src="/assets/images/mini-spinner.png"
    />
  );
}
