import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AbstractChart from "abstract-chart";
import * as AbstractImage from "abstract-image";
import * as Colors from "./chart-colors";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { PropertyValueSet } from "@genesys/property";
import { presets, Presets, ChartPreset, AxisPreset } from "./chart-presets";
import {
  Amount,
  Units,
  Unit,
  Serialize,
  Quantity,
  Format,
  UnitsFormat
} from "@genesys/uom";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > span {
    margin-right: 5px;
  }
`;

const ListContainer = styled.ul`
  > li {
    list-style: none;
  }
`;

const FlexContainer = styled.div`
  display: flex;

  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
  margin-bottom: 10px;
  padding: 15px;
`;

const ColorDiv = styled.div`
  background: ${(props: { readonly color: string }) => props.color};

  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 1em;
  border-radius: 50%;
`;

const chartPresets = Object.keys(presets).map(x => x);

export function ChartView({
  results,
  selectedChartPreset,
  sharedState,
  dispatch
}: {
  readonly results: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly sharedState: SharedState.State;
  readonly selectedChartPreset: Presets;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;

  const { presetAxisY1, presetAxisY2 } = parsePreset(
    presets[selectedChartPreset]
  );

  const chartLines = [
    ...(presetAxisY1.axisType === "Line"
      ? generateChartLines(results, presetAxisY1, "left", translate)
      : []),
    ...(presetAxisY2.axisType === "Line"
      ? generateChartLines(results, presetAxisY2, "right", translate)
      : [])
  ];

  // This won't work with multiple stacks. But that wouldn't make sense to render anyway.
  const chartStack =
    presetAxisY1.axisType === "SignedStackedArea"
      ? generateChartStack(results, presetAxisY1, translate)
      : presetAxisY2.axisType === "SignedStackedArea"
      ? generateChartStack(results, presetAxisY2, translate)
      : undefined;

  const chartStackRange = chartStack
    ? getStackRange(chartStack.points, point => point.ys)
    : { min: 0, max: 0 };

  const yRangeLeft =
    presetAxisY1.axisType === "SignedStackedArea"
      ? chartStackRange
      : getRange(
          chartLines.filter(line => line.yAxis === "left"),
          point => point.y
        );

  const yRangeRight =
    presetAxisY2.axisType === "SignedStackedArea"
      ? chartStackRange
      : getRange(
          chartLines.filter(line => line.yAxis === "right"),
          point => point.y
        );

  const yRightPad = (yRangeRight.max - yRangeRight.min) * 0.1;
  const yLeftPad = (yRangeLeft.max - yRangeLeft.min) * 0.1;

  const xRange = getRange(chartLines, point => point.x);
  const chart = AbstractChart.createChart({
    chartLines,
    chartStack,
    xAxisBottom: AbstractChart.createLinearAxis(
      xRange.min,
      xRange.max,
      "Outdoor air temperature [°C]"
    ),
    yAxisLeft: AbstractChart.createLinearAxis(
      yRangeLeft.min - yLeftPad,
      yRangeLeft.max + yLeftPad,
      presetAxisY1.label
    ),
    yAxisRight: AbstractChart.createLinearAxis(
      yRangeRight.min - yRightPad,
      yRangeRight.max + yRightPad,
      presetAxisY2.label
    ),
    labelLayout: "center"
  });

  const image = AbstractChart.renderChart(chart);

  return (
    <>
      <SubContainer>
        <span>{translate(LanguageTexts.chartPreset())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={chartPresets.map(cp => ({
            value: cp,
            title: presets[cp as Presets].name
          }))}
          value={selectedChartPreset}
          onChange={e => {
            dispatch(Action.setChartPreset(e.target.value as Presets));
          }}
        />
      </SubContainer>

      <FlexContainer>
        <div>{AbstractImage.createReactSvg(image)}</div>

        <ListContainer>
          {chartStack
            ? chartStack.config
                // Hide legends for empty stacks.
                .filter((_, index) =>
                  chart.chartStack.points.some(point => point.ys[index] !== 0)
                )
                .map((config, index) => (
                  <li key={"stack" + index}>
                    <ColorDiv
                      color={"#" + AbstractImage.toString6Hex(config.color)}
                    />
                    {config.label}
                  </li>
                ))
                .reverse()
            : null}
          {chartLines.map((config, index) => (
            <li key={"line" + index}>
              <ColorDiv
                color={"#" + AbstractImage.toString6Hex(config.color)}
              />
              {config.label}
            </li>
          ))}
        </ListContainer>
      </FlexContainer>
    </>
  );
}

function getRange(
  series: AbstractChart.ChartLine[],
  axisSelector: (point: AbstractImage.Point) => number
) {
  const axisValues = series
    .map(serie => serie.points.map(axisSelector))
    .reduce((soFar, current) => {
      return [...soFar, ...current];
    }, [] as ReadonlyArray<number>);
  return { min: Math.min(...axisValues), max: Math.max(...axisValues) };
}

interface ResultPreset {
  readonly resultName: string;
  readonly selectedColor: AbstractImage.Color;
}

function getStackRange(
  points: ReadonlyArray<AbstractChart.StackPoints>,
  axisSelector: (point: AbstractChart.StackPoints) => ReadonlyArray<number>
) {
  const axisValues = points
    .map(axisSelector)
    .map(stackedValues => {
      let posSum = 0;
      let negSum = 0;
      for (const value of stackedValues) {
        if (value > 0) {
          posSum += value;
        } else {
          negSum += value;
        }
      }
      return [negSum, posSum];
    })
    .reduce((soFar, current) => {
      return [...soFar, ...current];
    }, [] as ReadonlyArray<number>);
  return { min: Math.min(...axisValues), max: Math.max(...axisValues) };
}

function generateChartStack(
  diagramResults: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  preset: {
    readonly results: ReadonlyArray<ResultPreset>;
    readonly siUnit: Unit.Unit<Quantity.Quantity>;
  },
  translate: LanguageTexts.Translate
): AbstractChart.ChartStack {
  const points = diagramResults.map(moistureLoadCaseResult => ({
    x: Amount.valueAs(
      Units.Celsius,
      PropertyValueSet.getAmount<Quantity.Temperature>(
        "mlcoutdoortemperature",
        moistureLoadCaseResult
      )!
    ),
    ys: preset.results.map(result =>
      PropertyValueSet.hasProperty(result.resultName, moistureLoadCaseResult)
        ? Amount.valueAs(
            preset.siUnit,
            PropertyValueSet.getAmount(
              result.resultName,
              moistureLoadCaseResult
            )! as Amount.Amount<Quantity.Quantity>
          )
        : 0
    )
  }));

  const stack = AbstractChart.createChartStack({
    points,
    xAxis: "bottom",
    yAxis: "left",
    config: preset.results.map(result =>
      AbstractChart.createChartStackConfig({
        color: result.selectedColor,
        label: translate(
          LanguageTexts.globalPropertyName("pp_" + result.resultName)
        )
      })
    )
  });

  return stack;
}

function generateChartLines(
  diagramResults: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  preset: {
    readonly results: ReadonlyArray<ResultPreset>;
    readonly siUnit: Unit.Unit<Quantity.Quantity>;
  },
  yAxis: "left" | "right",
  translate: LanguageTexts.Translate
): Array<AbstractChart.ChartLine> {
  const unitFormat = Format.getUnitFormat(preset.siUnit, UnitsFormat)!;
  let series = preset.results.map(result => {
    return AbstractChart.createChartLine({
      points: diagramResults.map(moistureLoadCaseResult => {
        const y = (PropertyValueSet.getAmount(
          result.resultName,
          moistureLoadCaseResult
        ) || Amount.create(0, Units.Hour)) as Amount.Amount<Quantity.Quantity>;

        return {
          x: Amount.valueAs(
            Units.Celsius,
            PropertyValueSet.getAmount<Quantity.Temperature>(
              "mlcoutdoortemperature",
              moistureLoadCaseResult
            )!
          ),
          y: Amount.valueAs(preset.siUnit, y)
        };
      }),
      color: result.selectedColor,
      label:
        translate(LanguageTexts.globalPropertyName("pp_" + result.resultName)) +
        " [" +
        unitFormat.label +
        "]",
      xAxis: "bottom",
      yAxis: yAxis
    });
  });

  return series;
}

function parsePreset(preset: ChartPreset) {
  return {
    presetAxisY1: parsePresetAxis(preset.y1),
    presetAxisY2: parsePresetAxis(preset.y2)
  };
}

function parsePresetAxis(axisPreset: AxisPreset) {
  const unit = Serialize.stringToUnit(axisPreset.unitSi);
  if (unit === undefined) {
    throw new Error("Bad preset unit: " + axisPreset.unitSi);
  }

  const format = Format.getUnitFormat(unit, UnitsFormat);

  return {
    axisType: axisPreset.axisType,
    label: axisPreset.label + " [" + (format && format.label) + "]",
    results: parsePresetAxisResults(axisPreset),
    siUnit: unit
  };
}

function parsePresetAxisResults(
  presets: AxisPreset
): ReadonlyArray<ResultPreset> {
  const resultPresets = presets.results;
  const presetDatas = resultPresets.map(resultPreset => {
    const [resultName, colorId] = resultPreset.split(",");
    return {
      resultName,
      selectedColor:
        Colors.seriesColors[colorId as keyof typeof Colors.seriesColors] ||
        Colors.seriesColors.Red1
    };
  });

  return presetDatas
    .map(presetData => {
      return {
        resultName: presetData.resultName,
        isSelected: true,
        selectedColor: presetData.selectedColor
      };
    })
    .reverse();
}
