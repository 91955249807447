import { exhaustiveCheck } from "ts-exhaustive-check";
import gql from "graphql-tag";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQlTypes from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

const getBuilderStateUserQuery = gql`
  query GetBuildInRevitStateUserQuery($input: BuildInRevitStateInputType!) {
    user {
      getBuildInRevitState(input: $input) {
        state
        stateDescription
        modelLink
        imageData
      }
    }
  }
`;

const requestBuildMutation = gql`
  mutation RequestBuildInRevitMutation($input: RequestBuildInRevitInputType!) {
    requestBuildInRevit(input: $input)
  }
`;

export type State = {
  readonly stateNumber: number;
  readonly stateDescription: string;
  readonly modelLink: string;
  readonly image: string | undefined;
  readonly error: boolean;
  readonly operatingCaseId: string;
  readonly isLoading: boolean;
};

export const init = (
  sharedState: SharedState.State,
  systemTypeId: string,
  operatingCaseId: string
): [State, Cmd<Action>] => {
  return [
    {
      stateNumber: 0,
      stateDescription: "",
      modelLink: "",
      image: undefined,
      error: false,
      isLoading: true,
      operatingCaseId: operatingCaseId
    },
    sharedState.graphQL.queryUserCmd<
      GraphQlTypes.GetBuildInRevitStateUserQuery,
      GraphQlTypes.GetBuildInRevitStateUserQueryVariables,
      Action
    >(
      getBuilderStateUserQuery,
      {
        input: {
          systemId: systemTypeId
        }
      },
      Action.buildStateDataRecieved
    )
  ];
};

export const Action = ctorsUnion({
  getBuildState: (systemId: string) => ({ systemId }),
  buildStateDataRecieved: (
    data: GraphQlTypes.GetBuildInRevitStateUserQuery
  ) => ({ data }),
  requestBuild: (systemId: string) => ({ systemId }),
  errorBuild: () => ({}),
  changeOperatingCase: (operatingCaseId: string) => ({ operatingCaseId })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "getBuildState": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.GetBuildInRevitStateUserQuery,
          GraphQlTypes.GetBuildInRevitStateUserQueryVariables,
          Action
        >(
          getBuilderStateUserQuery,
          {
            input: {
              systemId: action.systemId
            }
          },
          Action.buildStateDataRecieved
        )
      ];
    }
    case "buildStateDataRecieved": {
      return [
        {
          ...state,
          stateNumber: action.data.user.getBuildInRevitState.state,
          stateDescription:
            action.data.user.getBuildInRevitState.stateDescription,
          modelLink: action.data.user.getBuildInRevitState.modelLink,
          image: action.data.user.getBuildInRevitState.imageData || undefined,
          isLoading: false
        }
      ];
    }

    case "requestBuild": {
      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.RequestBuildInRevitMutation,
          GraphQlTypes.RequestBuildInRevitMutationVariables,
          Action
        >(
          requestBuildMutation,
          {
            input: {
              systemId: action.systemId,
              operatingCaseId: state.operatingCaseId
            }
          },
          data =>
            data.requestBuildInRevit
              ? Action.getBuildState(action.systemId)
              : Action.errorBuild()
        )
      ];
    }
    case "errorBuild": {
      return [{ ...state, error: true, isLoading: false }];
    }
    case "changeOperatingCase": {
      return [{ ...state, operatingCaseId: action.operatingCaseId }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
