import { CtorsUnion, ctorsUnion } from "ctors-union";
import { home } from "./home";

// -- SUBSCRIPTIONS

export type NatsSub<A> = ListenSubjects<A, unknown> | ListenConnectionStatus<A>;

/**
 * Listen to a subjects
 */
export type ListenSubjects<A, TMsg> = {
  readonly home: typeof home;
  readonly type: "ListenSubjects";
  readonly subjects: ReadonlyArray<string>;
  readonly decode: (buf: Uint8Array) => TMsg;
  readonly gotMsg: (msg: TMsg) => A;
};

export function listenSubjects<A, TMsg>(
  subjects: ReadonlyArray<string>,
  decode: (buf: Uint8Array) => TMsg,
  gotMsg: (msg: TMsg) => A
): ListenSubjects<A, TMsg> {
  return {
    home,
    type: "ListenSubjects",
    subjects,
    decode,
    gotMsg
  };
}

/**
 * Changes in connection status
 */
export type ListenConnectionStatus<A> = {
  readonly home: typeof home;
  readonly type: "ListenConnectionStatus";
  readonly changed: (status: ConnectionStatus) => A;
};

export const ConnectionStatus = ctorsUnion({
  ConnectionStatusonnected: () => ({}),
  ConnectionStatusDisconnected: () => ({})
});
export type ConnectionStatus = CtorsUnion<typeof ConnectionStatus>;

export function listenConnectionStatus<A>(
  changed: (status: ConnectionStatus) => A
): ListenConnectionStatus<A> {
  return {
    home,
    type: "ListenConnectionStatus",
    changed
  };
}
