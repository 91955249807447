import * as React from "react";
import styled from "styled-components";

// tslint:disable-next-line
const StyledImg = styled.img`
  width: 800px;
`;

// tslint:disable-next-line
const StyledContainer = styled.div`
  text-align: center;
`;

// tslint:disable-next-line:function-name
export function WelcomeLogo({ src }: React.HTMLProps<HTMLImageElement>) {
  return (
    <StyledContainer>
      <StyledImg src={src} />
    </StyledContainer>
  );
}
