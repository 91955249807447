import * as GraphQL from "graphql";
import * as GraphQLRequest from "@genesys/graphql-request";
import * as GraphQLEntityCache from "gql-cache";
import * as GraphQLAddRemoveFields from "graphql-add-remove-fields";
import { CachePatch } from "gql-cache-patch";
import { MutationsQueue, QueuedMutation } from "./mutations-queue";
import { cachePatchesKey } from "./types";
import { getCachePatchesFromResponse } from "./server-patches";

export interface SentMutationResult<TResponse = unknown> {
  readonly mutation: QueuedMutation;
  readonly newEntities: GraphQLEntityCache.EntityCache;
  readonly cachePatches: ReadonlyArray<CachePatch>;
  readonly response: TResponse;
  readonly error: Error | undefined;
}

export async function sendMutations(
  mutationsQueue: MutationsQueue,
  objectToId: GraphQLEntityCache.GetObjectId,
  includeServerLog: boolean,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string }
): Promise<ReadonlyArray<SentMutationResult>> {
  const sentMutationResults: Array<SentMutationResult> = [];
  for (const m of mutationsQueue.queuedMutations) {
    const sentMutationResult = await sendMutation(
      m,
      objectToId,
      includeServerLog,
      endpoint,
      authorization
    );
    sentMutationResults.push(sentMutationResult);
  }

  return sentMutationResults;
}

async function sendMutation(
  queuedMutation: QueuedMutation,
  objectToId: GraphQLEntityCache.GetObjectId,
  includeServerLog: boolean,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string }
): Promise<SentMutationResult> {
  const mutationWithRequiredFields = GraphQLAddRemoveFields.addFields(
    queuedMutation.document as any,
    ["__typename"]
  );

  const response = await GraphQLRequest.request<GraphQLEntityCache.RootFields>(
    GraphQL.print(mutationWithRequiredFields),
    queuedMutation.variables,
    includeServerLog,
    endpoint,
    authorization
  );

  const queryWithoutCachePatches = GraphQLAddRemoveFields.removeFields(
    mutationWithRequiredFields,
    [cachePatchesKey]
  );
  const cachePatches = getCachePatchesFromResponse(response, objectToId);
  const normalizedEntities = GraphQLEntityCache.normalize(
    queryWithoutCachePatches,
    queuedMutation.variables,
    {
      data: response
    },
    objectToId
  );

  return {
    mutation: queuedMutation,
    newEntities: normalizedEntities,
    cachePatches: cachePatches,
    response,
    error: undefined
  };
}
