import React from "react";
import { System } from "../system";
import { ClimateColumnGroup, AddtionalTools, CellContent } from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import { formatCell, createInputBox } from "./shared";
import { Action } from "../state";
import { runValueSources } from "../../operating-case-selector";

export function getClimateContent(
  columnDef: ClimateColumnGroup,
  system: System,
  tools: AddtionalTools
): CellContent {
  const columnName = columnDef.columnName;

  const { state, dispatch } = tools;
  const climateSettings = state.currentClimateSettingValues.get(system.id)!;

  const saveChanges = (
    propertyName: string,
    pv: PropertyValue.PropertyValue
  ) => {
    const allOpc = system.operatingCases.filter(x => x.caseType === 0);
    const newCLimateSetings = PropertyValueSet.set(
      propertyName,
      pv,
      climateSettings
    );
    const updatedOpcValues = allOpc.map(x => {
      const settingsAfterValueSourceUpdate = runValueSources(
        x.settings,
        allOpc.map(x => x.settings),
        newCLimateSetings,
        state.propertiesPerSystem.get(system.systemTypeId)!
      );
      return {
        ...x,
        settings: settingsAfterValueSourceUpdate
      };
    });

    dispatch(Action.setNewClimateValues(system.id, newCLimateSetings));
    dispatch(Action.setNewOpcValues(system.id, updatedOpcValues));
  };

  const fullLocationName =
    PropertyValueSet.getText("locationname", climateSettings) || "";
  const wmo = PropertyValueSet.getText("wmo", climateSettings);

  const {
    country,
    location,
    state: locationState
  } = getCountryAndLocation(wmo, fullLocationName);

  const getAndDisplayClimateValues = (name: string, formatValue: boolean) => {
    const propertyValue = PropertyValueSet.get(name, climateSettings);

    if (!propertyValue) {
      return {
        value: ""
      };
    }

    if (!columnDef.format) {
      return {
        value: "Missing Format"
      };
    }

    const value = getValue(propertyValue, columnDef.format);

    if (!formatValue) {
      return { value };
    }

    const unit =
      propertyValue.type === "amount" ? columnDef!.format!.unit! : undefined;

    const componentForEditMode = createInputBox(
      state.editedCell?.value || "",
      state,
      true,
      value =>
        saveChanges(
          name,
          PropertyValue.create(
            propertyValue.type,
            unit ? Amount.create(value as number, unit) : value
          )
        ),
      dispatch
    );

    const normalView = <>{value}</>;

    return formatCell(
      {
        value,
        componentForEditMode,
        normalView
      },
      system,
      columnDef,
      tools,
      name,
      climateSettings
    );
  };

  switch (columnName) {
    case "country": {
      return { value: country };
    }

    case "location": {
      return { value: location };
    }

    case "state": {
      return { value: locationState };
    }

    case "atmospheric-pressure": {
      const isCustomPressure =
        PropertyValueSet.getInteger("custompressure", climateSettings) === 1;
      return getAndDisplayClimateValues(
        "atmosphericpressure",
        isCustomPressure
      );
    }
    case "cooling-temp": {
      return getAndDisplayClimateValues("summertemperature", !wmo);
    }

    case "cooling-hum": {
      return getAndDisplayClimateValues("summerhumidity", !wmo);
    }

    case "heating-temp": {
      return getAndDisplayClimateValues("wintertemperature", !wmo);
    }

    case "heating-hum": {
      return getAndDisplayClimateValues("winterhumidity", !wmo);
    }

    case "elevation": {
      return getAndDisplayClimateValues("altitude", !wmo);
    }

    default: {
      exhaustiveCheck(columnName);
    }
  }
}

function getCountryAndLocation(
  wmo: string | undefined,
  fullLocationName: string
) {
  if (!wmo) {
    return {
      country: "Manual",
      location: "Manual",
      state: "Manual"
    };
  }
  const split = fullLocationName.split(",");

  const country = split[0];
  const state = split[1];
  const location = split[2];

  return {
    country,
    state,
    location
  };
}
