import * as SharedState from "../../shared-state";
import * as Product from "../product";
import * as System from "../system";
import * as TopMenu from "../top-menu";
import * as PerformanceOverview from "../performance-overview";
import { Cmd } from "@typescript-tea/core";
import { State, OnInitModal, Modal } from "./types";
import { DispatchActions } from "./action";
import { initModal } from "./update/update-rest/init-modal";

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  productData: Product.ProductData,
  onInitModal?: OnInitModal
): readonly [State, SharedState.Action, Cmd<DispatchActions>?] => {
  let initialModal: Modal = { type: "NoModal" };
  let modalCmd: Cmd<DispatchActions> | undefined = undefined;

  if (onInitModal) {
    const getInitModalType = () => {
      if (onInitModal.type === "ComponentEditor") {
        return {
          type: "ComponentEditor",
          selectedComponentId: onInitModal.componentId
          // systemId: system.id
        };
      } else if (onInitModal.type === "Accessories") {
        return {
          type: "Accessories",
          selectedComponentId: onInitModal.componentId
          // systemId: system.id
        };
      }

      return {
        type: onInitModal.type
        // systemId: system.id
      };
    };
    const [modal, cmd] = initModal(
      getInitModalType() as any,
      sharedState,
      productData,
      system,
      false
    );

    initialModal = modal;
    modalCmd = cmd;
  }

  return [
    {
      system: system,
      alertSliderExpanded: system.components.length > 0,
      showConfirmLockRevision: false,
      showEnergyBinSelectionsValidationError: false,
      productData: productData,
      modal: initialModal,
      topMenu: TopMenu.init(sharedState),
      diagramMode: {
        type: "DefaultMode"
      },
      performanceOverview: PerformanceOverview.init(),
      actionCompleteUrl: undefined,
      overlay: { type: "OverlayNone" },
      exchangeComponentDialog: undefined,
      runningMutations: 0,
      systemActionsState: undefined,
      diagramHoverState: "no-hover",
      genericDialogState: undefined,
      selectedAirPosition: undefined,
      expandedGroups: {
        exceptions: new Set(),
        errors: new Set(),
        criticalWarnings: new Set(),
        warnings: new Set()
      }
    },
    SharedState.Action.updateLastOpenedSystems(system.id),
    modalCmd
  ];
};
