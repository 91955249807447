import * as SharedState from "../shared-state";
import * as LabelManager from "../label-manager";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../graphql-types";
import * as Navigation from "../navigation-effect-manager";
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { createMoistureLoadMutation } from "@genesys/client-core/lib/graphql-mutations";

export type State = {
  readonly assignedLabels: ReadonlyArray<LabelManager.Label>;
  readonly labelManagerState: LabelManager.State | undefined;
  readonly labelAnchor: Element | null;
  readonly moistureloadUrl: string | undefined;
  readonly name: string;
};

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      assignedLabels: [],
      labelManagerState: undefined,
      labelAnchor: null,
      moistureloadUrl: undefined,
      name: sharedState.translate(LanguageTexts.newMoistureLoad())
    }
  ];
};

export const Action = ctorsUnion({
  createMoistureLoad: () => ({}),
  dispatchLabelManager: (action: LabelManager.Action) => ({ action }),
  openMoistureload: (openInNewTab: boolean) => ({ openInNewTab }),
  setAssignedlables: (labels: ReadonlyArray<LabelManager.Label>) => ({
    labels
  }),
  setMoistureLoadUrl: (moistureloadNo: number, revisionNo: number) => ({
    moistureloadNo,
    revisionNo
  }),
  setName: (name: string) => ({ name }),
  toggleLabelManager: (labelAnchor: Element | null) => ({ labelAnchor })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "createMoistureLoad": {
      return [
        state,
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.CreateMoistureLoad,
          GraphQLTypes.CreateMoistureLoadVariables,
          Action
        >(
          createMoistureLoadMutation,
          {
            input: {
              name: state.name,
              labels: state.assignedLabels
            }
          },
          data =>
            Action.setMoistureLoadUrl(
              data.createMoistureLoad.moistureLoadNo,
              data.createMoistureLoad.revisionNo
            )
        )
      ];
    }

    case "dispatchLabelManager": {
      if (!state.labelManagerState) {
        return [state];
      }

      const [labelManagerState, sharedStateActions] = LabelManager.update(
        action.action,
        state.labelManagerState
      );

      return [
        { ...state, labelManagerState: labelManagerState },
        undefined,
        sharedStateActions
      ];
    }

    case "openMoistureload": {
      if (action.openInNewTab) {
        window.open(state.moistureloadUrl);
        return [{ ...state, moistureloadUrl: undefined }];
      }

      return [
        { ...state, moistureloadUrl: undefined },
        Navigation.pushUrl(state.moistureloadUrl!)
      ];
    }

    case "setAssignedlables": {
      return [{ ...state, assignedLabels: action.labels }];
    }

    case "setMoistureLoadUrl": {
      const url = `/moisture-load/${sharedState.genesysPrefix.moistureLoadNo(
        action.moistureloadNo,
        action.revisionNo
      )}`;
      return [{ ...state, moistureloadUrl: url }];
    }

    case "setName": {
      return [{ ...state, name: action.name }];
    }

    case "toggleLabelManager": {
      if (!state) {
        return [state];
      }

      const [labelManagerState] = LabelManager.init(state.assignedLabels);

      return [
        {
          ...state,
          labelAnchor: action.labelAnchor,
          labelManagerState: state.labelManagerState
            ? undefined
            : labelManagerState
        }
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
