import {
  ListenSubjects,
  listenSubjects
} from "../nats-effect-manager/nats-sub";
import { SystemEvent } from "../system-events";

export function listenSystemEvent<A>(
  isGenesysDeveloper: boolean,
  currentUserName: string,
  systemTypeId: string,
  productSearchPermissions: ReadonlyArray<string>,
  salesOrgs: ReadonlyArray<string>,
  ownerUserName: string,
  systemId: string,
  gotEvent: (event: SystemEvent) => A
): ListenSubjects<A, SystemEvent> {
  // This functions enumarates the correct listen subjects.
  // Which effective permissions the user can listen to is forced by the nats JWT
  // which is generated on the client backend.
  // Important that the salesorganisation part is a wild card in every
  // case except when we need to go by salesOrgSearch subject

  // What we need to look for
  // 1. I'm the owner of this system
  // 2. I'm a user with systemTypeSearch permissions
  // 3. I'm a developer
  // 4. I'm a user with salesOrgSearch permissions

  // If the user is the owner of the system then listen or
  // If the user has productSearch permission then listen or
  // If the user is a genesys developer then listen
  // This is the default majority case
  if (
    currentUserName.toLowerCase() === ownerUserName.toLowerCase() ||
    productSearchPermissions.some(
      p => p.toLowerCase() === systemTypeId.toLowerCase()
    ) ||
    isGenesysDeveloper
  ) {
    return listenSubjects(
      [
        `genesys.systems.${systemTypeId}.*.${ownerUserName}.${systemId}.>`.toLowerCase()
      ],
      buf => JSON.parse(new TextDecoder().decode(buf)),
      gotEvent
    );
  }

  // Otherwise listen for events on all the sales organisation a user has permission on.
  // In case the user change the salesorganisation id of a system but is not the owner
  // or doesn't have productSearchPermission or is not a genesys developer.
  // This is an edge case
  // genesys.systems.systemtypeid.salesorg.owneruserid.systemid
  const subjects = salesOrgs.map(s =>
    `genesys.systems.${systemTypeId}.${s}.${ownerUserName}.${systemId}.>`.toLowerCase()
  );
  return listenSubjects(
    subjects,
    buf => JSON.parse(new TextDecoder().decode(buf)),
    gotEvent
  );
}

type ClearCacheEvent = {
  readonly serverName: string;
};
export function listenClearCache<A>(
  gotEvent: (event: ClearCacheEvent) => A
): ListenSubjects<A, ClearCacheEvent> {
  // genesys.systems.systemtypeid.salesorg.owneruserid.systemid
  const subject = `genesys.clearcache.cleared`;
  return listenSubjects(
    [subject],
    buf => JSON.parse(new TextDecoder().decode(buf)),
    gotEvent
  );
}
