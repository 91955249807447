import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type LabelProps = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledLabel = styled.span<LabelProps>`
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0;
  ${({ weight = "light", color = "dark" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function Label(props: LabelProps): JSX.Element {
  return <StyledLabel {...props} />;
}
