import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import { runValueSources } from "./value-sources";
import * as Guid from "@genesys/shared/lib/guid";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { OpcProductProperty } from "./types";

export function createInitialOperatingCases(
  climateData: PropertyValueSet.PropertyValueSet,
  opcTemplateComponents: ReadonlyArray<{
    readonly id: string;
    readonly properties: PropertyValueSet.PropertyValueSet;
  }>,
  opcProductProperties: ReadonlyArray<OpcProductProperty>,
  translate: LanguageTexts.Translate
): ReadonlyArray<{
  readonly id: Guid.Guid;
  readonly templateComponentId: string;
  readonly settings: PropertyValueSet.PropertyValueSet;
}> {
  const properties = [...opcTemplateComponents]
    .sort(
      (a, b) =>
        PropertyValueSet.getInteger("sortno", a.properties)! -
        PropertyValueSet.getInteger("sortno", b.properties)!
    )
    .map(x => {
      let props = x.properties;
      const caseNamePv = PropertyValueSet.getText("casename", props);

      const regex = /\{\{([a-zA-Z0-9]*)\}\}/;

      if (caseNamePv && regex.test(caseNamePv)) {
        const result = caseNamePv.match(regex);
        if (result && result.length > 1) {
          props = PropertyValueSet.setText(
            "casename",
            translate(LanguageTexts.globalPropertyName(result[1])).trim(),
            props
          );
        }
      }

      return {
        ...x,
        properties: props
      };
    });

  const propertyData = opcProductProperties.map(p => ({
    name: p.name,
    quantity: p.quantity,
    defaultValues: p.defaultValues,
    values: p.items
  }));

  const defaultProperties =
    ProductProperties.createDefaultProperties(propertyData);

  const propertyDefinitions = propertyData.map(p => ({
    name: p.name,
    valueSources: opcProductProperties
      .find(pp => pp.name === p.name)!
      .valueSources.map(vs => ({
        id: "",
        value: vs.value,
        propertyValueSourceId: vs.propertyValueSourceId,
        parameters: vs.parameters !== null ? vs.parameters : null,
        propertyFilter: PropertyFilter.fromStringOrEmpty(vs.propertyFilter),
        claimFilter: vs.claimFilter
      }))
  }));

  return properties.reduce(
    (a, b) =>
      a.concat({
        id: Guid.createGuid(),
        templateComponentId: b.id,
        settings: runValueSources(
          PropertyValueSet.merge(b.properties, defaultProperties),
          a.map(aa => aa.settings),
          climateData,
          propertyDefinitions
        )
      }),
    [] as ReadonlyArray<{
      readonly id: Guid.Guid;
      readonly templateComponentId: string;
      readonly settings: PropertyValueSet.PropertyValueSet;
    }>
  );
}
