import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  OuterContainer,
  InnerContainer,
  AirInOuterContainer,
  CalculateContainer,
  AirInAndPowerContainer,
  PropertyNamesContainer,
  AirInInnerContainer
} from "./elements";
import { StandardButton, H2, P1 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PropertiesSelector from "../../properties-selector";
import { PropertyFilter } from "@genesys/property";
import { AirOut } from "./components/air-out";
import { Power } from "./components/power";

export function AirPowerToolView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  return (
    <Root>
      <OuterContainer>
        <H2 weight="normal" color="primary">
          {sharedState.translate(LanguageTexts.airPowerTool())}
        </H2>
        <InnerContainer>
          <AirInOuterContainer>
            <H2 weight="normal" color="dark">
              Air In
            </H2>

            <AirInInnerContainer>
              <PropertyNamesContainer>
                <P1 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.temperature())}
                </P1>

                <P1 weight="normal" color="dark">
                  {sharedState.translate(LanguageTexts.humidity())}
                </P1>

                <P1 weight="normal" color="dark">
                  Flow
                </P1>

                <P1 weight="normal" color="dark">
                  Pressure
                </P1>
              </PropertyNamesContainer>

              <PropertiesSelector.PropertiesSelectorView
                dispatch={Dispatch.map(
                  action => Action.dispatchPropertiesSelector(action),
                  dispatch
                )}
                productId={"AirPower"}
                sharedState={sharedState}
                propertiesInfo={airInDefinition()}
                fieldGroup={"Settings.AirPower"}
                isReadonly={false}
                showCodes={false}
                state={state.airIn}
                hidePropertyNames={true}
              />
            </AirInInnerContainer>
          </AirInOuterContainer>

          <CalculateContainer>
            <StandardButton
              size="Large"
              buttonType="Primary"
              onClick={() => dispatch(Action.calculateAirOut())}
            >
              Calculate air out
            </StandardButton>
            <StandardButton
              size="Large"
              buttonType="Primary"
              onClick={() => dispatch(Action.calculatePower())}
            >
              Calculate power
            </StandardButton>
          </CalculateContainer>

          <AirInAndPowerContainer>
            <H2 weight="normal" color="dark">
              Air Out
            </H2>

            <AirOut
              airOutPvs={state.airOut}
              sharedState={sharedState}
              dispatch={dispatch}
            />

            <H2 weight="normal" color="dark">
              Power
            </H2>

            <Power
              propertyValueSet={state.power}
              sharedState={sharedState}
              dispatch={dispatch}
            />
          </AirInAndPowerContainer>
        </InnerContainer>
      </OuterContainer>
    </Root>
  );
}

function airInDefinition(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "temperature",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 2,
      name: "humidity",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 3,
      name: "flow",
      group: "",
      quantity: "MassFlow",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      defaultValues: [],
      valueSources: [],
      descriptionTexts: []
    },
    {
      sortNo: 4,
      name: "pressure",
      group: "",
      quantity: "Pressure",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      defaultValues: [],
      valueSources: [],
      descriptionTexts: []
    }
  ];
}
