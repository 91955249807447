import * as React from "react";
import { isDesktop } from "react-device-detect";
import * as SharedState from "../../../shared-state";
import { State, Action } from "../../state";
import { Dispatch } from "@typescript-tea/core";
import { SystemManagerBody } from "../../elements";
import { SidebarLabelsComponent } from "../../../shared-manager-components";
import { SearchParams } from "../../types";
import { ResultView } from "./result";

export function MainContentView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const setSearchKeyAndSearch = (searchKey: string) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      searchKey: searchKey
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search(undefined, 1));
  };

  return (
    <SystemManagerBody>
      {isDesktop && (
        <SidebarLabelsComponent
          sharedState={sharedState}
          labels={sharedState.user.labels}
          labelInputState={state.labelInputState}
          searchKey={state.searchParams.searchKey}
          onLabelSelected={searchKey => setSearchKeyAndSearch(searchKey)}
          onCreateLabel={labelName => dispatch(Action.createLabel(labelName))}
          deleteLabel={(labelId, labelName) =>
            dispatch(
              Action.openModal({
                mode: "confirmLabelDelete",
                label: { id: labelId, name: labelName }
              })
            )
          }
          onUpdateLabel={(labelId, labelName) =>
            dispatch(Action.updateLabel(labelId, labelName))
          }
          setLabelInputState={labelInputState =>
            dispatch(Action.setLabelInputState(labelInputState))
          }
          openLabelPopup={state.openLabelPopup}
          toggleOpenLabelPopup={labelId =>
            dispatch(Action.toggleOpenLabelPopup(labelId))
          }
        />
      )}
      <ResultView sharedState={sharedState} state={state} dispatch={dispatch} />
    </SystemManagerBody>
  );
}
