import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as SharedState from "../../../../shared-state";
import * as OperatingCaseSelector from "../../../../operating-case-selector";
import * as Product from "../../../product";
import { Action } from "../state";

export function OperatingCasesView({
  opcProduct,
  sharedState,
  sysProperties,
  operatingCaseSelectorState,
  dispatch
}: {
  readonly opcProduct: Product.Product;
  readonly sharedState: SharedState.State;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly operatingCaseSelectorState: OperatingCaseSelector.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <OperatingCaseSelector.OperatingCasesView
      systemStatus={87}
      productData={opcProduct}
      sharedState={sharedState}
      disableAddAndDelete={true}
      sysProperties={sysProperties}
      state={operatingCaseSelectorState}
      dispatch={Dispatch.map(Action.dispatchOperatingCasesSelector, dispatch)}
    />
  );
}
