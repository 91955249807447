import * as SharedState from "../../../shared-state";
import { CombinedActions, DispatchActions, RestActions } from "../action";
import { updateRest } from "./update-rest/update-rest";
import { updateDispatches } from "./update-dispatches";
import { State, UpdateReturnType } from "../types";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function update(
  action: CombinedActions,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  switch (action.sub) {
    case "dispatchSub": {
      return updateDispatches(action as DispatchActions, state, sharedState);
    }
    case "restSub": {
      return updateRest(action as RestActions, state, sharedState);
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
