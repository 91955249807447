import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type H1Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledH1 = styled.h1<H1Props>`
  font-size: 1.333rem;
  /* font-weight: 500; */
  line-height: 1.333rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  ${({ weight = "bold", color = "primary" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function H1(props: H1Props): JSX.Element {
  return <StyledH1 {...props} />;
}
