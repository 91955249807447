import styled from "styled-components";

export const Root = styled.div`
  width: 1440px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Navigation = styled.ul`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  list-style-type: none;
  margin: 0;
  padding: 30px 0;
`;

export const Tab = styled.li`
  H1 {
    padding-bottom: 6px;
  }

  border-bottom: ${(props: { readonly isActive: boolean }) =>
    props.isActive ? "3px solid #00ADF2" : "none"};
  cursor: pointer;
`;

export const PagesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepNavigationContainer = styled.div`
  button {
    margin: 0 8px;
  }
`;
