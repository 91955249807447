import React from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  ActiveStatusImg,
  Container,
  IconsContainer,
  LastUpdated,
  MiddleContainer,
  SkeletonContainer,
  StatusImg,
  SystemActionsContainer,
  SystemActionsIconContainer,
  SystemImage,
  SystemImgContainer,
  SystemInfo,
  ErrorContainer
} from "../favorite-system-card/elements";
import { ImageDisplayer, MenuDots, P1 } from "@genesys/ui-elements";
import * as SystemActions from "../../../system-actions";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../../state";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as StatusIcon from "../../../status-icon-manager";
import { clientConfig } from "../../../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { SystemActionsStateMap } from "../../types";

// tslint:disable-next-line
const active: string = require("ui-elements/assets/system-card/active.svg");

interface Loading {
  readonly type: "loading";
}

interface Loaded {
  readonly type: "loaded";
  readonly cardIndex: number;
  readonly sharedState: SharedState.State;
  readonly favoriteSystem: SharedState.BriefSystemInfo;
  readonly systemActionsState: SystemActionsStateMap | undefined;
  readonly onToggleIFavorite: () => void;
  readonly onToggleSystemActions: (cardIndex: number) => void;
  readonly dispatch: Dispatch<Action>;
}

interface Error {
  readonly type: "error";
  readonly showErrorDetails: () => void;
}

type Props = Loaded | Loading | Error;

export function FavoriteSystemCard(props: Props) {
  const maxSystemNameLength = 100;

  if (props.type === "loading") {
    return (
      <SkeletonContainer>
        <Skeleton variant="rectangular" width={145} height={103} />
        <Skeleton variant="rectangular" width={350} height={50} />
        <Skeleton variant="rectangular" width={90} height={22} />
      </SkeletonContainer>
    );
  }

  if (props.type === "error") {
    return (
      <ErrorContainer onClick={props.showErrorDetails}>
        <P1 color="white" weight="bold">
          Unable to load system
        </P1>
      </ErrorContainer>
    );
  }

  const {
    cardIndex,
    favoriteSystem,
    systemActionsState,
    sharedState,
    onToggleIFavorite,
    onToggleSystemActions,
    dispatch
  } = props;

  const maybeToggleSystemActions = (cardIndex: number) => {
    if (!systemActionsState) {
      onToggleSystemActions(cardIndex);
    } else if (
      systemActionsState &&
      SystemActions.isInMenu(systemActionsState.state)
    ) {
      onToggleSystemActions(cardIndex);
    } else {
      return;
    }
  };

  const formatDate = (date: number) =>
    DateHelper.getDateString(
      DateHelper.fromUTC(date),
      sharedState.user.settings.locale
    );

  const lastUpdated = formatDate(favoriteSystem.statusDate);

  return (
    <Container>
      <SystemImgContainer>
        <ImageDisplayer
          accessToken={sharedState.accessToken}
          routeEndpoint={clientConfig.genesysBackend}
          effectiveSystemId={favoriteSystem.id}
        >
          <SystemImage
            accessToken={sharedState.accessToken}
            src={clientConfig.getWizardImageUrl(favoriteSystem.id)}
          />
        </ImageDisplayer>
      </SystemImgContainer>
      <MiddleContainer>
        <SystemInfo>
          {favoriteSystem.systemFileName.length <= maxSystemNameLength
            ? favoriteSystem.systemFileName
            : favoriteSystem.systemFileName.substring(0, maxSystemNameLength) +
              "..."}
        </SystemInfo>
        <LastUpdated>
          {sharedState.translate(LanguageTexts.lastChange()) + " "}
          {lastUpdated}
        </LastUpdated>
      </MiddleContainer>

      <IconsContainer>
        <ActiveStatusImg
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onToggleIFavorite();
          }}
          src={active}
        ></ActiveStatusImg>
        <StatusImg>
          <StatusIcon.StatusIconManager
            sharedState={sharedState}
            statusNumber={favoriteSystem.status}
            height="21.81px"
            width="21.81px"
          />
        </StatusImg>

        <SystemActionsIconContainer
          onMouseEnter={() => {
            maybeToggleSystemActions(cardIndex);
          }}
          onMouseLeave={() => {
            maybeToggleSystemActions(cardIndex);
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <MenuDots height="1.4rem" />
          {systemActionsState?.cardIndex === cardIndex && (
            <SystemActionsContainer>
              <SystemActions.SystemActionsView
                dispatch={Dispatch.map(Action.dispatchSystemActions, dispatch)}
                state={systemActionsState.state}
                systemActions={SystemActions.createActions(
                  {
                    dispatch: Dispatch.map(
                      Action.dispatchSystemActions,
                      dispatch
                    ),
                    sharedState,
                    state: systemActionsState.state,
                    systemFile: {
                      id: favoriteSystem.systemFileId,
                      name: favoriteSystem.name,
                      labels: favoriteSystem.labels,
                      owner: favoriteSystem.owner,
                      systems: favoriteSystem.systems,
                      genesysNo: favoriteSystem.genesysNo
                    }
                  },
                  [
                    SystemActions.openInNewTabAction,
                    SystemActions.renameAction,
                    SystemActions.assignLabelsAction,
                    SystemActions.createNewRevisionAction,
                    SystemActions.transferToUserAction,
                    SystemActions.copyTransferOpenAction
                  ]
                )}
              />
            </SystemActionsContainer>
          )}
        </SystemActionsIconContainer>
      </IconsContainer>
    </Container>
  );
}
