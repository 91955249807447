import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  DownloadWiringDiagramUserQuery,
  WiringDiagramBuildResponseCodeEnum,
  WiringDiagramDownloadResponseCodeEnum,
  WiringDiagramCheckStateResponseCodeEnum
} from "../../../graphql-types";

export function getPdfUrl(queryData: DownloadWiringDiagramUserQuery): string {
  const base64Pdf = queryData.user.downloadWiringDiagram.base64Pdf;
  const byteCharacters = atob(base64Pdf);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  return url;
}

// I´ll add textIds if they ask for it, this will do for now.

export function parseWiringDiagramBuildResponseCodeEnum(
  responseCode: WiringDiagramBuildResponseCodeEnum
): { readonly stateDescription: string; readonly isBuilding: boolean } {
  switch (responseCode) {
    case WiringDiagramBuildResponseCodeEnum.BUILDING:
      return { stateDescription: "Building", isBuilding: true };
    case WiringDiagramBuildResponseCodeEnum.INVALID_GENESYS_ID:
      return { stateDescription: "Invalid genesys ID", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.INVALID_USER:
      return { stateDescription: "Invalid user", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.NOT_LOCKED:
      return { stateDescription: "Not locked", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.SYSTEM_NOT_SUPPORTED:
      return { stateDescription: "System not supported", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.ALREADY_BUILDING:
      return { stateDescription: "Already building", isBuilding: true };
    case WiringDiagramBuildResponseCodeEnum.SERVER_ERROR:
      return { stateDescription: "Server error", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.UNABLE_TO_PARSE:
      return { stateDescription: "Unable to parse", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.UNSPECIFIED_ERROR:
      return { stateDescription: "Unspecified error", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.EXCEPTION:
      return { stateDescription: "Exception", isBuilding: false };
    case WiringDiagramBuildResponseCodeEnum.UNAUTHORIZED:
      return { stateDescription: "Unauthorized", isBuilding: false };
    default:
      return exhaustiveCheck(responseCode, true);
  }
}

export function parseWiringDiagramCheckStateResponseCodeEnum(
  responseCode: WiringDiagramCheckStateResponseCodeEnum
): { readonly stateDescription: string; readonly isBuilding: boolean } {
  switch (responseCode) {
    case WiringDiagramCheckStateResponseCodeEnum.BUILT:
      return { stateDescription: "Built", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.BUILDING:
      return { stateDescription: "Building", isBuilding: true };
    case WiringDiagramCheckStateResponseCodeEnum.NOT_DESIGNED:
      return { stateDescription: "Not designed", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.INVALID_GENESYS_ID:
      return { stateDescription: "Invalid genesys id", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.SERVER_ERROR:
      return { stateDescription: "Server error", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.CUSTOM_ERROR:
      return { stateDescription: "Custom error", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.UNSPECIFIED_ERROR:
      return { stateDescription: "Unspecified error", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.EXCEPTION:
      return { stateDescription: "Exception", isBuilding: false };
    case WiringDiagramCheckStateResponseCodeEnum.UNAUTHORIZED:
      return { stateDescription: "Unauthorized", isBuilding: false };
    default:
      return exhaustiveCheck(responseCode, true);
  }
}

export function parseWiringDiagramDownloadResponseCodeEnum(
  responseCode: WiringDiagramDownloadResponseCodeEnum
): string {
  switch (responseCode) {
    case WiringDiagramDownloadResponseCodeEnum.OK:
      return "Ready for download";
    case WiringDiagramDownloadResponseCodeEnum.INVALID_GENESYS_ID:
      return "Invalid genesys id";
    case WiringDiagramDownloadResponseCodeEnum.UNAUTHORIZED:
      return "Unauthorized";
    case WiringDiagramDownloadResponseCodeEnum.NOT_FOUND:
      return "Not found";
    case WiringDiagramDownloadResponseCodeEnum.SERVER_ERROR:
      return "Server error";
    case WiringDiagramDownloadResponseCodeEnum.UNSPECIFIED_ERROR:
      return "Unspecified error";
    case WiringDiagramDownloadResponseCodeEnum.EXCEPTION:
      return "Exception";
    default:
      return exhaustiveCheck(responseCode, true);
  }
}
