import React from "react";
import styled from "styled-components";
import { Action, State } from "../state";
import { PopUp } from "../components";
import { System, OperatingCase } from "../system";
import { Dispatch } from "@typescript-tea/core";
import { Column, AddtionalTools, CellContent } from "./types";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import { Amount } from "@genesys/uom";
import { getValue } from "@genesys/shared/lib/product-properties";
import { runValueSources } from "../../operating-case-selector";
import { OpcProperty } from "../property";
import { createFilterPrettyPrint } from "@genesys/client-core/lib/filter-pretty-print";
import { zIndex } from "@genesys/ui-elements";
import { MenuContainer } from "../../shared-manager-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

const ErrorInnerContainer = styled.div`
  background-color: #e8767b;
  padding-left: 5px;
  padding-bottom: 4px;
  padding-top: 4px;
`;

const WarningMessage = styled.p`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  white-space: normal;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 10px;
`;
const CellContainer = styled.div`
  height: 100%;
  width: 70px;
`;

const ErrorContainer = styled.div`
  width: 130px;
  position: absolute;
  z-index: ${zIndex.Dropdown};
`;

export interface ComponentProps {
  readonly children: React.ReactNode;
}

interface Cell {
  readonly value: string | number;
  readonly normalView: JSX.Element;
  readonly componentForEditMode: (
    props: RequiredPropertyValidationProps
  ) => JSX.Element;
}
const NormalViewWithPropertyValidation =
  withPropertyValidation(NormalVieWrapper);

export function formatCell(
  cell: Cell,
  system: System,
  column: Column,
  tools: AddtionalTools,
  propertyName: string,
  pvs: PropertyValueSet.PropertyValueSet
): CellContent {
  const { state, dispatch } = tools;
  const { componentForEditMode: EditView, normalView, value } = cell;

  const checkIfLockedByValueSource = (propertyName: string) => {
    const valueSourceName = `source_${propertyName}`;
    const val = PropertyValueSet.getInteger(valueSourceName, pvs);

    if (val === undefined) {
      return false;
    }

    const propertyDefinition = state.propertyValueSourceMap
      .get(system.systemTypeId)
      ?.get(propertyName);
    const valueSourceId = propertyDefinition?.valueSourceMap.get(val);

    return valueSourceId !== undefined && valueSourceId !== "User";
  };

  const isLockedByValueSource = checkIfLockedByValueSource(propertyName);

  const isSelectedEditRow = state.editedRowId === system.id;

  const canEdit =
    system.isEditable && isSelectedEditRow && !isLockedByValueSource;

  const columnName = column.columnName;
  const editMode = canEdit && columnName === state.editedCell?.column;

  const renderEditableCell = () => (
    <CellContainer>
      <EditView
        propertyName={propertyName}
        pvs={pvs}
        system={system}
        tools={tools}
        cell={cell}
      />
    </CellContainer>
  );

  const renderLockedCell = () => (
    <PopUp canShowPopUp={true} popContent={<div>Locked by value source</div>}>
      <div style={{ border: "1px dotted red" }}>{normalView}</div>
    </PopUp>
  );

  const renderNormalCell = () => (
    <div
      style={{
        boxShadow:
          canEdit && !editMode ? "0 5px 20px 2px rgb(15 10 5 / 20%)" : "none",
        border: canEdit && !editMode ? "2px solid #f1f5fa" : "none"
      }}
    >
      <NormalViewWithPropertyValidation
        propertyName={propertyName}
        pvs={pvs}
        system={system}
        tools={tools}
        cell={cell}
      />
    </div>
  );

  const renderCell = () => {
    if (editMode) {
      return renderEditableCell();
    } else if (isSelectedEditRow && isLockedByValueSource) {
      return renderLockedCell();
    } else {
      return renderNormalCell();
    }
  };

  const cellOnClick = (e: React.MouseEvent) => {
    if (!canEdit) {
      return;
    }
    e.stopPropagation();
    dispatch(
      Action.setEditedCell({
        column: columnName,
        initialValue: value,
        value
      })
    );
  };

  return {
    value: renderCell(),
    cellOnClick
  };
}

export function createInputBox(
  value: string | number,
  state: State,
  isNumeric: boolean,
  onSave: (value: string | number) => void,
  dispatch: Dispatch<Action>
) {
  const extraAttributes = isNumeric
    ? { type: "number", inputMode: "decimal" }
    : {};

  return withPropertyValidation(
    (props: { readonly validationMessage: string }) => {
      const onEditEnd = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (e.target.value === state.editedCell?.initialValue) {
          return dispatch(Action.setEditedCell());
        }
        const value = isNumeric ? parseFloat(e.target.value) : e.target.value;
        onSave(value);
        dispatch(Action.setEditedCell());
      };

      const ref = React.createRef<HTMLElement>();

      return (
        <span>
          <StyledInput
            ref={ref as any}
            {...extraAttributes}
            style={{
              backgroundColor: props.validationMessage ? "#f3babd" : "inherit"
            }}
            autoFocus={true}
            type="number"
            inputMode="decimal"
            value={value}
            onKeyDown={e => e.key === "Enter" && onEditEnd(e as any)}
            onChange={e => {
              const value = isNumeric
                ? parseFloat(e.target.value)
                : e.target.value;
              onSave(value);
              dispatch(
                Action.setEditedCell({
                  ...state.editedCell!,
                  value: e.target.value
                })
              );
            }}
            onClick={e => e.stopPropagation()}
            onBlur={onEditEnd}
          />
          {props.validationMessage && (
            <MenuContainer menuIsOpen={true} menuParentRef={ref as any}>
              <span style={{ position: "relative" }}>
                <ErrorContainer>
                  <ErrorInnerContainer>
                    <WarningMessage>{props.validationMessage}</WarningMessage>
                  </ErrorInnerContainer>
                </ErrorContainer>
              </span>
            </MenuContainer>
          )}
        </span>
      );
    }
  );
}

export interface SummaryOpcProps {
  readonly column: Column["columnName"];
  readonly columnDef: Column;
  readonly system: System;
  readonly tools: AddtionalTools;
  readonly getAndDisplayValueFromOpc: (
    name: ReadonlyArray<string>
  ) => CellContent;
}

export function withOPCSelectionLogic<T extends SummaryOpcProps>(
  wrappedFn: (args: T) => CellContent
) {
  return (
    props: Omit<SummaryOpcProps, "getAndDisplayValueFromOpc" | "column">
  ) => {
    const { columnDef, system, tools } = props;

    const column = columnDef.columnName;
    const allOpc = system.operatingCases.filter(x => x.caseType === 0);
    const { state, dispatch } = tools;
    const selectedSortNo =
      state.selectedOpc.get(system.id) || allOpc[0]?.sortNo;

    if (selectedSortNo === undefined) {
      return { value: "No Opc found" };
    }

    const selectedOpc = state.currentOpcValues
      .get(system.id)
      ?.get(selectedSortNo)!;

    const pvs = selectedOpc.settings;

    const climateSettings = state.currentClimateSettingValues.get(system.id)!;

    const updateOpcSettings = (
      opc: OperatingCase,
      updatedSettings: PropertyValueSet.PropertyValueSet,
      allOpc: OperatingCase[],
      climateSettings: PropertyValueSet.PropertyValueSet,
      propertiesForSystem: ReadonlyArray<OpcProperty>
    ) => {
      if (opc.sortNo === selectedSortNo) {
        return {
          ...opc,
          settings: updatedSettings
        };
      }

      const newSettings = runValueSources(
        opc.settings,
        allOpc.map(x => x.settings),
        climateSettings,
        propertiesForSystem!
      );

      return {
        ...opc,
        settings: newSettings
      };
    };

    const saveChanges = (
      propertyName: string,
      pv: PropertyValue.PropertyValue
    ) => {
      const newSettings = PropertyValueSet.set(propertyName, pv, pvs);

      const updatedSelectedOpc = updateOpcSettings(
        selectedOpc,
        runValueSources(
          newSettings,
          allOpc.map(x => x.settings),
          climateSettings,
          state.propertiesPerSystem.get(system.systemTypeId)!
        ),
        allOpc,
        climateSettings,
        state.propertiesPerSystem.get(system.systemTypeId)!
      );

      const remainingOpcs = allOpc.filter(x => x.sortNo !== selectedSortNo);

      let allOpcUpdated = [...remainingOpcs, updatedSelectedOpc];

      const otherOpcUpdated = remainingOpcs.map(opc =>
        updateOpcSettings(
          opc,
          newSettings,
          allOpcUpdated,
          climateSettings,
          state.propertiesPerSystem.get(system.systemTypeId)!
        )
      );

      allOpcUpdated = [...otherOpcUpdated, updatedSelectedOpc];

      dispatch(Action.setNewOpcValues(system.id, allOpcUpdated));
    };

    const getAndDisplayValueFromOpc = (names: ReadonlyArray<string>) => {
      for (const name of names) {
        const propertyValue = PropertyValueSet.get(name, pvs);

        if (!propertyValue) {
          continue;
        }

        let value: string = propertyValue.value.toString();
        const isAmount = propertyValue.type === "amount";

        if (isAmount) {
          if (!columnDef.format) {
            return { value: "Missing Format" };
          }
          value = getValue(propertyValue, columnDef.format);
        }

        const componentForEditMode = createInputBox(
          state.editedCell?.value || "",
          state,
          true,
          value =>
            saveChanges(
              name,
              PropertyValue.create(
                propertyValue.type,
                isAmount
                  ? Amount.create(value as number, columnDef!.format!.unit!)
                  : value
              )
            ),
          dispatch
        );

        const normalView = <>{value}</>;

        return formatCell(
          {
            value,
            componentForEditMode,
            normalView
          },
          system,
          columnDef,
          tools,
          name,
          pvs
        );
      }

      return { value: "" };
    };
    return wrappedFn({ ...(props as T), column, getAndDisplayValueFromOpc });
  };
}

interface ValidationProps {
  readonly propertyName: string;
  readonly tools: AddtionalTools;
  readonly pvs: PropertyValueSet.PropertyValueSet;
  readonly system: System;
  readonly cell: Cell;
  readonly validationMessage: string;
}
type RequiredPropertyValidationProps = Omit<
  ValidationProps,
  "validationMessage"
>;

function withPropertyValidation<T extends ValidationProps>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: RequiredPropertyValidationProps) => {
    const { system, tools, propertyName, pvs } = props;
    const { state } = tools;
    const fliterPrint = createFilterPrettyPrint(
      (propertyName: string, translateArg: LanguageTexts.Translate) =>
        translateArg(
          LanguageTexts.systemTypeProperty(system.systemTypeId, propertyName)
        ),
      (
        propertyName: string,
        propertyValue: number,
        translateArg: LanguageTexts.Translate
      ) =>
        translateArg(
          LanguageTexts.systemTypePropertyValue(
            system.systemTypeId,
            propertyName,
            propertyValue
          )
        ),
      tools.sharedState.translate,
      tools.sharedState.screenAmounts.getAmountFormat,
      "",
      () => undefined
    );
    const filter =
      state.propertiesPerSystem
        .get(system.systemTypeId)!
        .find(x => x.name === propertyName)?.validationFilter ||
      PropertyFilter.Empty;

    const message = PropertyFilter.isValid(pvs, filter)
      ? ""
      : fliterPrint(filter);

    return <WrappedComponent {...(props as T)} validationMessage={message} />;
  };
}

function NormalVieWrapper({
  cell,
  validationMessage
}: {
  readonly cell: Cell;
  readonly validationMessage: string;
}) {
  const { normalView } = cell;

  if (validationMessage) {
    return (
      <PopUp canShowPopUp={true} popContent={<p>{validationMessage}</p>}>
        <div style={{ backgroundColor: "#f3babd" }}>{normalView}</div>
      </PopUp>
    );
  }

  return normalView;
}
