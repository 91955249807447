import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Quantity } from "@genesys/uom";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as SharedState from "../../../../../shared-state";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as System from "../../../../system";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import * as BinGeneration from "../state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export function buildBinOperatingCases(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined,
  editedOperatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>
):
  | {
      readonly binIndex: number;
      readonly settings: PropertyValueSet.PropertyValueSet;
      readonly binData: PropertyValueSet.PropertyValueSet;
    }[]
  | undefined {
  if (!binCases) {
    return undefined;
  }
  const binCasesAsPvs = binCases.map(b => binCaseToPvs(b));

  return binCasesAsPvs.map((b, ix) => ({
    binIndex: PropertyValueSet.getInteger(KnownProperties.id, b)!,
    settings: editedOperatingCases[ix],
    binData: b
  }));
}

export function getBinFields(
  state: BinGeneration.State,
  generateBinCasesQuery: GraphQlTypes.GenerateBinCases | undefined
) {
  const binType = PropertyValueSet.getValue(
    KnownProperties.binType,
    state.binSelections ?? PropertyValueSet.Empty
  ).value as string;

  return binType === "Generated"
    ? generateBinCasesQuery?.product.psycProClimateDataBins.map(b => ({
        temperature: PropertyValue.getAmount<Quantity.Temperature>(
          PropertyValue.fromString(b.temperature)!
        )!,
        humidity: PropertyValue.getAmount<Quantity.HumidityRatio>(
          PropertyValue.fromString(b.humidity)!
        )!,
        time: PropertyValue.getAmount<Quantity.Duration>(
          PropertyValue.fromString(b.time)!
        )!
      }))
    : [];
}

export function binCaseToPvs(
  bin: SharedEnergyTools.BinCase
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // Standard values
  pvs = PropertyValueSet.setInteger(KnownProperties.id, bin.binId, pvs);
  pvs = PropertyValueSet.setAmount(
    KnownProperties.airTemperature,
    bin.averageTemperature,
    pvs
  );
  pvs = PropertyValueSet.setAmount(
    KnownProperties.airHumidity,
    bin.averageHumidity,
    pvs
  );
  pvs = PropertyValueSet.setAmount(
    KnownProperties.binPressure,
    bin.binPressure,
    pvs
  );
  pvs = PropertyValueSet.setAmount(KnownProperties.time, bin.binTime, pvs);

  // Mid point values
  pvs = bin.midPointTemp
    ? PropertyValueSet.setAmount(
        KnownProperties.airMidPointTemperature,
        bin.midPointTemp,
        pvs
      )
    : pvs;
  pvs = bin.midPointWetTemp
    ? PropertyValueSet.setAmount(
        KnownProperties.airMidPointWetTemperature,
        bin.midPointWetTemp,
        pvs
      )
    : pvs;
  pvs = bin.midPointDewPointTemp
    ? PropertyValueSet.setAmount(
        KnownProperties.airMidPointDewPointTemperature,
        bin.midPointDewPointTemp,
        pvs
      )
    : pvs;
  pvs = bin.midPointSpecificEnthalpy
    ? PropertyValueSet.setAmount(
        KnownProperties.airMidPointSpecificEnthalpy,
        bin.midPointSpecificEnthalpy,
        pvs
      )
    : pvs;
  pvs = bin.midPointHumidityRatio
    ? PropertyValueSet.setAmount(
        KnownProperties.airMidPointHumidityRatio,
        bin.midPointHumidityRatio,
        pvs
      )
    : pvs;

  // Moisture load values
  pvs = bin.returnAirHumidity
    ? PropertyValueSet.setAmount(
        KnownProperties.returnAirHumidity,
        bin.returnAirHumidity,
        pvs
      )
    : pvs;
  pvs = bin.returnAirTemperature
    ? PropertyValueSet.setAmount(
        KnownProperties.returnAirTemperature,
        bin.returnAirTemperature,
        pvs
      )
    : pvs;
  pvs = bin.buildingMoistureLoad
    ? PropertyValueSet.setAmount(
        KnownProperties.buildingMoistureLoad,
        bin.buildingMoistureLoad,
        pvs
      )
    : pvs;
  pvs = bin.totalHeatLoad
    ? PropertyValueSet.setAmount(
        KnownProperties.totalHeatLoad,
        bin.totalHeatLoad,
        pvs
      )
    : pvs;
  pvs = bin.targetHumidity
    ? PropertyValueSet.setAmount(
        KnownProperties.supplyTargetHumidity,
        bin.targetHumidity,
        pvs
      )
    : pvs;
  pvs = bin.targetTemperature
    ? PropertyValueSet.setAmount(
        KnownProperties.supplyTargetTemperature,
        bin.targetTemperature,
        pvs
      )
    : pvs;

  // Months
  pvs = bin.binTimeJanuary
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeJanuary,
        bin.binTimeJanuary,
        pvs
      )
    : pvs;
  pvs = bin.binTimeFebruary
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeFebruary,
        bin.binTimeFebruary,
        pvs
      )
    : pvs;
  pvs = bin.binTimeMarch
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeMarch,
        bin.binTimeMarch,
        pvs
      )
    : pvs;
  pvs = bin.binTimeApril
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeApril,
        bin.binTimeApril,
        pvs
      )
    : pvs;
  pvs = bin.binTimeMay
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeMay,
        bin.binTimeMay,
        pvs
      )
    : pvs;
  pvs = bin.binTimeJune
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeJune,
        bin.binTimeJune,
        pvs
      )
    : pvs;
  pvs = bin.binTimeJuly
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeJuly,
        bin.binTimeJuly,
        pvs
      )
    : pvs;
  pvs = bin.binTimeAugust
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeAugust,
        bin.binTimeAugust,
        pvs
      )
    : pvs;
  pvs = bin.binTimeSeptember
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeSeptember,
        bin.binTimeSeptember,
        pvs
      )
    : pvs;
  pvs = bin.binTimeOctober
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeOctober,
        bin.binTimeOctober,
        pvs
      )
    : pvs;
  pvs = bin.binTimeNovember
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeNovember,
        bin.binTimeNovember,
        pvs
      )
    : pvs;
  pvs = bin.binTimeDecember
    ? PropertyValueSet.setAmount(
        KnownProperties.binTimeDecember,
        bin.binTimeDecember,
        pvs
      )
    : pvs;

  return pvs;
}

export function getOperatingCaseSettings(
  system: System.System
): PropertyValueSet.PropertyValueSet {
  const opeComponents = system.components
    .filter(c => c.productId.endsWith("OPE"))
    .map(c => ({
      ...c,
      properties: c.properties
    }))
    .sort((a, b) =>
      (PropertyValueSet.hasProperty(KnownProperties.binId, a.properties)
        ? PropertyValueSet.getInteger(KnownProperties.binId, a.properties)!
        : 99) <
      (PropertyValueSet.hasProperty(KnownProperties.binId, b.properties)
        ? PropertyValueSet.getInteger(KnownProperties.binId, b.properties)!
        : 99)
        ? -1
        : 1
    );

  const firstOpeComponent = opeComponents[0];

  if (firstOpeComponent) {
    return firstOpeComponent.properties;
  } else {
    const opcComponents = system.components
      .filter(c => c.productId.endsWith("OPC"))
      .map(c => ({
        ...c,
        properties: c.properties
      }))
      .sort((a, b) =>
        (PropertyValueSet.hasProperty(KnownProperties.sortNo, a.properties)
          ? PropertyValueSet.getInteger(KnownProperties.sortNo, a.properties)!
          : 99) <
        (PropertyValueSet.hasProperty(KnownProperties.sortNo, b.properties)
          ? PropertyValueSet.getInteger(KnownProperties.sortNo, b.properties)!
          : 99)
          ? -1
          : 1
      );

    const firstOpcComponent = opcComponents[0];

    let caseSettings = PropertyValueSet.Empty;

    if (firstOpcComponent) {
      caseSettings = firstOpcComponent.properties;
    }

    const binCases = system.operatingCases
      .filter(oc => oc.caseType === CaseTypeEnum.CaseType.Bin)
      .map(oc => ({
        ...oc,
        settings: PropertyValueSet.fromString(oc.settings || "")
      }))
      .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1));

    const firstBinCase = binCases[0];
    if (firstBinCase) {
      caseSettings = PropertyValueSet.merge(
        caseSettings,
        firstBinCase.settings
      );
    }

    const metaDataProperties = PropertyValueSet.getPropertyNames(
      caseSettings
    ).filter(p => p.startsWith("source_") || p.startsWith("acc_"));
    caseSettings = PropertyValueSet.removeProperties(
      metaDataProperties,
      caseSettings
    );

    return caseSettings;
  }
}

export function updateBinData( // iaf det blir aktuellt igen
  binData: PropertyValueSet.PropertyValueSet,
  operatingCaseProperties: PropertyValueSet.PropertyValueSet,
  outdoorTemperaturePropertyName: string,
  outdoorHumidityPropertyName: string
) {
  const airTemperature = PropertyValueSet.get(
    outdoorTemperaturePropertyName,
    operatingCaseProperties
  );

  const airHumidity = PropertyValueSet.get(
    outdoorHumidityPropertyName,
    operatingCaseProperties
  );

  const binPressure = PropertyValueSet.get(
    KnownProperties.atmosphericPressure,
    operatingCaseProperties
  );

  let newBindata = binData;
  newBindata = airTemperature
    ? PropertyValueSet.set(
        KnownProperties.airTemperature,
        airTemperature,
        newBindata
      )
    : newBindata;
  newBindata = airHumidity
    ? PropertyValueSet.set(KnownProperties.airHumidity, airHumidity, newBindata)
    : newBindata;
  newBindata = binPressure
    ? PropertyValueSet.set(KnownProperties.binPressure, binPressure, newBindata)
    : newBindata;

  return newBindata;
}

export function getOutdoorAirTemperaturePropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.outdoorTemperature,
    operatingCaseProperties
  )
    ? KnownProperties.outdoorTemperature
    : PropertyValueSet.hasProperty(
        KnownProperties.outdoorsTemp,
        operatingCaseProperties
      )
    ? KnownProperties.outdoorsTemp
    : KnownProperties.outdoorAirTemperature;
}

export function getOutdoorAirHumidityPropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.outdoorHumidity,
    operatingCaseProperties
  )
    ? KnownProperties.outdoorHumidity
    : PropertyValueSet.hasProperty(
        KnownProperties.outdoorsHum,
        operatingCaseProperties
      )
    ? KnownProperties.outdoorsHum
    : KnownProperties.outdoorAirHumidity;
}

export function getSupplyOutletAirFlowPropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.supplyOutletFlow,
    operatingCaseProperties
  )
    ? KnownProperties.supplyOutletFlow
    : KnownProperties.supplyOutletAirFlow;
}

export function getTargetHumidityPropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.supplyTargetHumidity,
    operatingCaseProperties
  )
    ? KnownProperties.supplyTargetHumidity
    : KnownProperties.targetHum;
}

export function getTargetTemperaturePropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.supplyTargetTemperature,
    operatingCaseProperties
  )
    ? KnownProperties.supplyTargetTemperature
    : KnownProperties.targetTemp;
}

export function getReturnAirTemperaturePropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.returnTemp,
    operatingCaseProperties
  )
    ? KnownProperties.returnTemp
    : PropertyValueSet.hasProperty(
        KnownProperties.preferredReturnTemperature,
        operatingCaseProperties
      )
    ? KnownProperties.preferredReturnTemperature
    : KnownProperties.returnAirTemperature;
}

export function getReturnAirHumidityPropertyName(
  operatingCaseProperties: PropertyValueSet.PropertyValueSet
): string {
  return PropertyValueSet.hasProperty(
    KnownProperties.returnHum,
    operatingCaseProperties
  )
    ? KnownProperties.returnHum
    : KnownProperties.returnAirHumidity;
}

export function buildSettingsDictionary(
  energySettings: ReadonlyArray<{
    readonly settingName: string;
    readonly settingValue: string;
  }>
): { readonly [key: string]: string } {
  const dictionary = energySettings.reduce(
    // tslint:disable-next-line
    (a: { [key: string]: string }, b) => {
      a[b.settingName.toLowerCase()] = b.settingValue;
      return a;
    },
    {}
  );

  return { ...dictionary };
}

export function getMoistureLoadHeaders(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: string[],
  sharedState: SharedState.State,
  fieldGroupName: string
): ReadonlyArray<string> {
  const uniquePropertieValues = getUniquePropertieValues(results);
  return distinctPropertyNames.map(propertyName => {
    const name = sharedState.translate(
      LanguageTexts.globalPropertyName("pp_" + propertyName + "_st")
    );

    const propertyValue = uniquePropertieValues[propertyName];

    const amountFormat =
      propertyValue && propertyValue.type === "amount"
        ? sharedState.screenAmounts.getAmountFormat(
            fieldGroupName,
            propertyName,
            PropertyValue.getAmount(propertyValue)!
          )
        : undefined;
    const amountName = amountFormat?.unit.name;

    return amountName ? `${name} (${amountName})` : name;
  });
}

function getUniquePropertieValues(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>
) {
  return results.reduce(
    (
      propertyValueMap: {
        // tslint:disable-next-line:readonly-keyword
        [propertyName: string]: PropertyValue.PropertyValue;
      },
      result
    ) => {
      for (const propertyName of PropertyValueSet.getPropertyNames(result)) {
        propertyValueMap[propertyName] = PropertyValueSet.get(
          propertyName,
          result
        )!;
      }

      return propertyValueMap;
    },
    {}
  ) as {
    readonly [propertyName: string]: PropertyValue.PropertyValue;
  };
}

// tslint:disable-next-line
