import styled from "styled-components";

export const Root = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const ProductRangeContainer = styled(Container)`
  H2 {
    padding-bottom: 24px;
  }
`;

export const PropertiesSelectorContainer = styled(Container)`
  H2 {
    padding-left: 14px;
  }
`;

export const PropertyCheckboxContainer = styled.div`
  H2 {
    padding-bottom: 9px;
    padding-top: ${(props: { readonly isFirstOption: boolean }) =>
      props.isFirstOption ? "0px" : "9px"};
  }
`;
