import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";
import * as Product from "../product";

const rangeTypePropertyName = "rangetype";
const dhmodelPropertyName = "dhmodel";

export function notes(
  system: System.System,
  systemType: Product.SystemType,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;

  const dhuComponent = system.components.find(c =>
    c.productId.endsWith("DHU")
  )!;

  const systemTypeId = systemType.id;

  const rangeType = PropertyValueSet.getInteger(
    rangeTypePropertyName,
    sysComponent.properties
  );
  const rangeTypeText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, rangeTypePropertyName)
  )}: ${translate(
    rangeType !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          rangeTypePropertyName,
          rangeType
        )
      : LanguageTexts.pNull()
  )}`;

  const dhmodel = PropertyValueSet.getInteger(
    dhmodelPropertyName,
    dhuComponent.properties
  );
  const dhText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, dhmodelPropertyName)
  )}: ${translate(
    dhmodel !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          dhmodelPropertyName,
          dhmodel
        )
      : LanguageTexts.pNull()
  )}`;

  return [rangeTypeText, dhText];
}
