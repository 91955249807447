import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as MoistureLoadActions from "../../../../moisture-load-actions";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "../../../state";
import { MoistureLoadRow } from "../../../types";

export function MenuContent({
  state,
  sharedState,
  mlRow,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly mlRow: MoistureLoadRow;
  readonly dispatch: Dispatch<Action>;
}) {
  if (mlRow.type === "moisture-load-file") {
    const moistureLoadFile = {
      id: mlRow.id,
      mno: mlRow.moistureLoadNo,
      labels: mlRow.labels,
      latestMoistureLoad: {
        id: mlRow.latestRevisionId,
        name: mlRow.name,
        status: mlRow.status,
        revisionNo: mlRow.latestRevision
      },
      name: mlRow.name,
      owner: mlRow.owner
    };
    return (
      <MoistureLoadActions.MenuContainer
        sharedState={sharedState}
        state={state.moistureloadActionState}
        dispatch={Dispatch.map(Action.dispatchMoistureLoadAction, dispatch)}
      >
        <MoistureLoadActions.CreateNewRevision
          moistureLoadFile={moistureLoadFile}
        />
        <MoistureLoadActions.Delete moistureLoadFileId={moistureLoadFile.id} />
        <MoistureLoadActions.Rename moistureLoadFile={moistureLoadFile} />
        <MoistureLoadActions.AssignLabels moistureLoadFile={moistureLoadFile} />
        <MoistureLoadActions.CopyLatestRevToNewFile
          moistureLoadFile={moistureLoadFile}
        />
        <MoistureLoadActions.TransferToUser
          moistureLoadFile={moistureLoadFile}
        />
      </MoistureLoadActions.MenuContainer>
    );
  } else if (mlRow.type === "moisture-load-revision") {
    return (
      <MoistureLoadActions.MenuContainer
        sharedState={sharedState}
        state={state.moistureloadActionState}
        dispatch={Dispatch.map(Action.dispatchMoistureLoadAction, dispatch)}
      >
        <MoistureLoadActions.CopyTransferOpen moistureLoadId={mlRow.id} />
      </MoistureLoadActions.MenuContainer>
    );
  }

  return null;
}
