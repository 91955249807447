import { Cmd, Sub, EffectManager } from "@typescript-tea/core";
import { diffLogger } from "../program-logger/diff-logger";
import { Logger } from "../types";

export interface OnEffectsEntry<ProgramAction, SelfState> {
  readonly cmds: readonly Cmd<ProgramAction>[];
  readonly subs: readonly Sub<ProgramAction>[];
  readonly prevState: SelfState;
  readonly nextState: SelfState;
  readonly error: Error | undefined;
  readonly started: number;
  readonly startedTime: Date;
  readonly took: number;
}

const colors = {
  title: "inherit",
  cmds: "#DB7093",
  subs: "#03A9F4",
  prevState: "#9E9E9E",
  nextState: "#4CAF50",
  error: "#F20404"
};

export function printBuffer<ProgramAction, SelfAction, SelfState>(
  buffer: readonly OnEffectsEntry<ProgramAction, SelfState>[],
  logger: Logger,
  manager: EffectManager<string, ProgramAction, SelfAction>,
  showDiff: boolean
): void {
  // const logger = console;

  buffer.forEach((logEntry, key) => {
    const { started, startedTime, cmds, subs, prevState, error } = logEntry;
    let { took, nextState } = logEntry;
    const nextEntry = buffer[key + 1];

    if (nextEntry && nextEntry.started && started) {
      nextState = nextEntry.prevState;
      took = nextEntry.started - started;
    }

    // Format title
    const formattedTime = formatTime(startedTime);
    const titleCSS = ["color: gray; font-weight: lighter;"];
    titleCSS.push(`color: ${colors.title};`);
    titleCSS.push("color: gray; font-weight: lighter;");
    titleCSS.push("color: gray; font-weight: lighter;");
    const title = `onEffects %c${
      manager.home
    } %c@ ${formattedTime} %c(in ${took.toFixed(2)} ms)`;

    // Render group with title
    try {
      logger.groupCollapsed(`%c${title}`, ...titleCSS);
    } catch (e) {
      logger.log(title);
    }

    // prev state
    logger.log(
      "%c prev state",
      `color: ${colors.prevState}; font-weight: bold`,
      prevState
    );

    // error
    if (error) {
      logger.log(
        "%c error     ",
        `color: ${colors.error}; font-weight: bold;`,
        error
      );
    }

    // cmd
    for (const cmd of cmds) {
      logger.log(
        `%c cmd %c${cmd.type}`,
        `color: ${colors.cmds}; font-weight: bold`,
        "color: gray; font-weight: lighter;",
        cmd
      );
    }

    // sub
    for (const sub of subs) {
      logger.log(
        `%c sub %c${sub.type}`,
        `color: ${colors.subs}; font-weight: bold`,
        "color: gray; font-weight: lighter;",
        sub
      );
    }

    // next state
    logger.log(
      "%c next state",
      `color: ${colors.nextState}; font-weight: bold`,
      nextState
    );

    // diff
    if (showDiff) {
      diffLogger(prevState, nextState, logger);
    }

    try {
      logger.groupEnd();
    } catch (e) {
      logger.log("—— log end ——");
    }
  });
}

const repeat = (str: string, times: number): string =>
  new Array(times + 1).join(str);

const pad = (num: number, maxLength: number): string =>
  repeat("0", maxLength - num.toString().length) + num;

function formatTime(time: Date): string {
  return `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2
  )}.${pad(time.getMilliseconds(), 3)}`;
}
