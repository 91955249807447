import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const setUserSettingsCultureMutation = gql`
  mutation SetUserSettingsCulture(
    $userSettingsCulture: UserSettingsCultureInputType!
  ) {
    setUserSettingsCulture(userSettingsCulture: $userSettingsCulture) {
      id
      culture {
        ...CompleteUserCultureSettings
      }
    }
  }
  ${Fragments.completeUserCultureSettings}
`;
