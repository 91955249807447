import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import { Root, GroupFiltersContainer, BodyContainer } from "./elements";
import * as Product from "../../product";
import * as System from "../../system";
import * as ProductData from "@genesys/shared/lib/product-data";
import { OuterContentContainer, InnerContentContainer } from "./elements";
import { ExpandIcon, CollapseIcon, H3 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { PropertyValueSet } from "@genesys/property";
import { GroupFilterComponent } from "./components/group-filters-component";
import * as Accessories from "../accessories";

export function SystemAccessoriesView({
  state,
  dispatch,
  sharedState,
  system,
  products
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemType: Product.SystemType;
  readonly system: System.System;
  readonly products: ReadonlyArray<Product.Product>;
}): JSX.Element {
  const sysProperties = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!.properties;

  const allProducts = ProductData.getValidProductsForRange(
    products,
    sysProperties
  ).filter(p => ProductData.filterProductForRange(p, sysProperties));

  const productDataById: Map<string, Product.Product> = new Map(
    allProducts.map(p => [p.id, p] as [string, Product.Product])
  );

  const availableGroups = new Set<string>();

  for (const p of allProducts) {
    const productData = productDataById.get(p.id);
    if (!productData) {
      continue;
    }

    const accessoryProperties = productData.properties.filter(p =>
      p.name.startsWith("acc_")
    );

    for (const accessoryProperty of accessoryProperties) {
      if (!accessoryProperty.group.includes("hidden")) {
        availableGroups.add(accessoryProperty.group);
      }
    }
  }

  return (
    <Root>
      <GroupFiltersContainer>
        <GroupFilterComponent
          sharedState={sharedState}
          availableGroups={Array.from(availableGroups.values())}
          groupFilter={state.groupFilter}
          onClearClick={() => dispatch(Action.clearGroupFilter())}
          onGroupClick={group => dispatch(Action.toggleGroupFilter(group))}
        />
      </GroupFiltersContainer>

      <BodyContainer>
        <OuterContentContainer>
          {state.systemAccessories.map(systemAccessory => (
            <InnerContentContainer key={systemAccessory.parentComponent.id}>
              <a
                onClick={() =>
                  dispatch(
                    Action.toggleCollapsed(systemAccessory.parentComponent.id)
                  )
                }
              >
                <H3
                  weight="normal"
                  color={
                    state.collapsed[systemAccessory.parentComponent.id]
                      ? "dark"
                      : "primary"
                  }
                >
                  {LanguageTexts.getComponentLabel(
                    sharedState.translate,
                    systemAccessory.parentComponent.productId,
                    PropertyValueSet.Empty
                  )}
                </H3>

                {state.collapsed[systemAccessory.parentComponent.id] ? (
                  <ExpandIcon />
                ) : (
                  <CollapseIcon />
                )}
              </a>

              {!state.collapsed[systemAccessory.parentComponent.id] && (
                <Accessories.AccessoriesView
                  key={systemAccessory.parentComponent.id}
                  state={systemAccessory.state}
                  system={system}
                  products={products}
                  component={systemAccessory.parentComponent}
                  showGeneratedAccessories={true}
                  sharedState={sharedState}
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchAccessories(
                        system.id,
                        systemAccessory.parentComponent.id,
                        action
                      ),
                    dispatch
                  )}
                  groupFilter={state.groupFilter}
                />
              )}
            </InnerContentContainer>
          ))}
        </OuterContentContainer>
      </BodyContainer>
    </Root>
  );
}
