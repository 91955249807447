import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createMenuDotsSvg(color: string): string {
  const svgString = `
    <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>B131DDA9-EA31-429A-802F-A9E82F8E927B</title>
    <defs>
        <path d="M2.5,17.5 C3.88,17.5 5,18.62 5,20 C5,21.38 3.88,22.5 2.5,22.5 C1.12,22.5 0,21.38 0,20 C0,18.62 1.12,17.5 2.5,17.5 Z M2.5,8.75 C3.88,8.75 5,9.87 5,11.25 C5,12.63 3.88,13.75 2.5,13.75 C1.12,13.75 0,12.63 0,11.25 C0,9.87 1.12,8.75 2.5,8.75 Z M2.5,0 C3.88,0 5,1.12 5,2.5 C5,3.88 3.88,5 2.5,5 C1.12,5 0,3.88 0,2.5 C0,1.12 1.12,0 2.5,0 Z" id="path-1"></path>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-199.000000, -1147.000000)">
            <g id="Icons/Actions/Menu" transform="translate(199.400000, 1147.000000)">
                <g id="Icon-/-Details" transform="translate(12.500000, 3.750000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <use id="🎨-Icon-Сolor" fill="${color}" xlink:href="#path-1"></use>
                </g>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
