import gql from "graphql-tag";

export const locationQuery = gql`
  query ClimateSelectorCountriesProductQuery {
    product {
      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }
    }
  }
`;

export const dataPointsQuery = gql`
  query ClimateSelectorDataPointsProductQuery($locationId: String!) {
    product {
      dataPointsForLocationId(locationId: $locationId) {
        id
        caseType
        climateDataType
        annualOccurence
        temperature
        humidity
        windSpeed
      }
    }
  }
`;
