import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const updateSystemFileMutation = gql`
  mutation UpdateSystemFile($systemFile: SystemFileInputType!) {
    updateSystemFile(systemFile: $systemFile) {
      __typename
      ... on SystemFileType {
        ...CompleteSystemFile
      }
      ... on UpdateFailedType {
        errorMessage
      }
    }
  }
  ${Fragments.completeSystemFile}
`;
