// import React from "react";
import { TargetColumnGroup } from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SummaryOpcProps } from "./shared";

export function targetContent({
  column,
  getAndDisplayValueFromOpc
}: Omit<SummaryOpcProps, "column"> & {
  readonly column: TargetColumnGroup["columnName"];
}) {
  switch (column) {
    case "target-humidity": {
      return getAndDisplayValueFromOpc(["supplytargethumidity"]);
    }

    case "target-temperature": {
      return getAndDisplayValueFromOpc(["supplytargettemperature"]);
    }

    case "supply-target-dewpoint": {
      return getAndDisplayValueFromOpc(["supplytargetdewpoint"]);
    }
    case "pre-cooler-setpoint-1": {
      return getAndDisplayValueFromOpc(["precoolersetpoint1"]);
    }

    case "pre-cooler-setpoint-2": {
      return getAndDisplayValueFromOpc(["precoolersetpoint2"]);
    }
    case "pre-heater-setpoint": {
      return getAndDisplayValueFromOpc(["preheatersetpoint"]);
    }

    case "react-preheater-setpoint": {
      return getAndDisplayValueFromOpc(["reactpreheatersetpoint"]);
    }

    default: {
      exhaustiveCheck(column);
    }
  }
}
