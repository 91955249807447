import { exhaustiveCheck } from "ts-exhaustive-check";

export interface ClientConfigFromServer {
  readonly oidcAuthority: string;
  readonly oidcClientId: string;
  readonly oidcScope: string;
  readonly oidcResponseType: string;
  readonly environment: Environment;
  readonly genesysBackend: string;
  readonly wikiJsBaseUrl: string;
  readonly sentryDsn: string;
  readonly clientVersion: {
    readonly tag: string;
    readonly commit: string;
  };
  readonly natsTokenExpirationSeconds: number;
}

export type ClientConfig = ClientConfigFromServer & {
  readonly getWizardImageUrl: (systemId: string) => string;
  readonly graphqlEndpoint: string;
  readonly defaultBrowserTitle: string;
  readonly oidcRedirectUri: string;
};
export type Environment = "Localhost" | "Develop" | "Staging" | "Production";

const fromServerConfig: ClientConfigFromServer = (window as any).clientConfig;
export const clientConfig: ClientConfig = {
  ...fromServerConfig,
  graphqlEndpoint:
    fromServerConfig.genesysBackend.replace(/\/$/, "") + "/graphql",
  getWizardImageUrl: (systemId: string) =>
    `${fromServerConfig.genesysBackend}/internal/imagesapi/wizard-image/${systemId}`,
  defaultBrowserTitle: defaultBrowserTitle(fromServerConfig.environment),
  oidcRedirectUri: getRedirectUri(fromServerConfig.oidcAuthority)
};

export function getModalBorderColor(env: Environment): string {
  switch (env) {
    case "Localhost":
      return "5px Solid #A020F0";
    case "Develop": {
      return "5px Solid #46D580";
    }
    case "Staging": {
      return "5px Solid #F0C530";
    }

    case "Production": {
      return "3px solid #f7f7f7;";
    }

    default: {
      return exhaustiveCheck(env);
    }
  }
}

function defaultBrowserTitle(environment: Environment): string {
  switch (environment) {
    case "Develop":
    case "Production":
    case "Staging": {
      return `Genesys (${environment})`;
    }
    case "Localhost":
      return "Genesys (Utvecklings Miljö)";
    default:
      return exhaustiveCheck(environment, true);
  }
}

function getRedirectUri(oidcAuthority: string): string {
  if (oidcAuthority.indexOf("keycloak") > -1) {
    return window.location.origin.replace(/\/$/, "") + "/signinoidc";
  }

  return window.location.origin;
}
