import styled from "styled-components";
import * as React from "react";

interface BlockContainerProps {
  readonly blockSize: Size;
  readonly color?: string;
}

interface Size {
  readonly w: number;
  readonly h: number;
}

// tslint:disable-next-line
const UnStyledContainer: React.FunctionComponent<
  BlockContainerProps & React.HTMLProps<HTMLDivElement>
> = props => {
  const { blockSize, color, ...unStyledContainerProps } = props;

  return <div {...unStyledContainerProps}>{props.children}</div>;
};

// tslint:disable-next-line
export const BlockContainer = styled(UnStyledContainer)`
  width: ${props => props.blockSize.w}px;
  height: ${props => props.blockSize.h}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: ${props => props.color || "#fff"};
`;
