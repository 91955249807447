export const manualData = {
  wmo: "wmo", // Id of the location
  locationName: "locationname",
  occurrences: "occurrences",
  altitude: "altitude",
  customPressure: "custompressure",
  atmosphericPressure: "atmosphericpressure",
  summerTemperature: "summertemperature",
  winterTemperature: "wintertemperature",
  summerHumidity: "summerhumidity",
  winterHumidity: "winterhumidity",
  summerWindSpeed: "summerwindspeed",
  winterWindSpeed: "winterwindspeed",
  coolingAnnualOccurence: "coolingannualoccurence",
  heatingAnnualOccurence: "heatingannualoccurence",
  latitudeN: "climatelatituden",
  longitudeW: "climatelongitudew",
  binLocationId: "climatebinLocationid",
  coolingDataType: "climatecoolingdatatype",
  heatingDataType: "climateheatingdatatype"
};

export const locationIndex = "location";
export const coolingDataTypeInteger = "coolingdatatypeinteger";
export const heatingDataTypeInteger = "heatingdatatypeinteger";
export const coolingAnnualOccurenceInteger = "coolingannualoccurenceinteger";
export const heatingAnnualOccurenceInteger = "heatingannualoccurenceinteger";
