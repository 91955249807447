import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createHomeIconSvg(color: string): string {
  const svgString = `
  <svg
  width="38"
  height="37"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  overflow="hidden"
>
  <g transform="translate(-1215 -61)">
    <path
      d="M2.53103 20.0255 5.032 22.5261 6.9375 20.7215 6.9375 33.1366 16.5729 33.1404 16.5729 23.517 20.4271 23.517 20.4271 33.142 30.0625 33.1458 30.0625 20.7054 31.9776 22.5168 34.469 20.0259 18.5 4.85625ZM29.2917 32.375 21.1979 32.3715 21.1979 22.7461 15.8021 22.7461 15.8021 32.3692 7.70833 32.3661 7.70833 19.9916 18.5 9.77185 29.2917 19.9765ZM5.04665 21.4507 3.63564 20.0417 18.5 5.91846 33.3644 20.0417 31.9626 21.4415 18.5 8.71042Z"
      fill="${color}"
      transform="matrix(1.02703 0 0 1 1215 61)"
    />
  </g>
</svg>

  
  `;

  return encodeSvgStringToImgSrc(svgString);
}
