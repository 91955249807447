import gql from "graphql-tag";

export const userQuery = gql`
  query PricingEditorUserQuery($pricingNo: Int!, $revisionNo: Int!) {
    user {
      pricingByPricingNo(pricingNo: $pricingNo, revisionNo: $revisionNo) {
        id
        comment
        status
        currencyCode
        owner {
          id
          name
        }
        exchangeRateTemplateId
        masterMode
        name
        salesOrganisationId
        masterSalesOrganisationId
        revisionNo

        pricingFile {
          id
          pricingNo
          name
          labels {
            id
            name
          }

          owner {
            id
            userName
          }
        }
        pricingRows {
          id
          type
          visualizerCode
          rowNo
          articleNo
          description
          quantity
          unit
          systemId
          systemTypeId
          costPerUnit
          transferPricePerUnit
          listPricePerUnit
          masterPricePerUnit
          pid
        }
      }

      pricingArticles {
        id
        salesOrganisationId
        articleNo
        description
        quantity
        unit
        costPerUnit
        transferPricePerUnit
        listPricePerUnit
        sortNo
        currencyCode
        templateGroup
      }
    }
  }
`;

export const productQuery = gql`
  query PricingEditorProductQuery {
    product {
      currencyRates {
        id
        currency {
          id
        }
        currencyExchangeRateTemplate {
          id
          name
        }
        exchangeRate
        validFrom
      }
      genesysNumberPrefixes {
        id
        genesysNo
        pricingNo
      }
      languages {
        id
      }
    }
  }
`;

export const searchQuery = gql`
  query PricingSystemSearchQuery($searchText: String!) {
    user {
      systemPricingSearch(searchText: $searchText) {
        id
        name
        genesysNo
        revisionNo
      }
    }
  }
`;

export const validationQuery = gql`
  query PricingSystemValidationQuery($id: String!) {
    user {
      systemPricingInformationMaster(systemId: $id) {
        ...SystemPricingInformationMaster
      }
    }
  }

  fragment SystemPricingInformationMaster on SystemPricingInformationMasterType {
    error
    systemPricingInformation {
      id
      name
      systemTypeId
      marginCost
      transferPrice
      listPrice
      masterSalesPrice
      currencyCode
      masterCurrencyCode
      salesOrganisationId
      masterSalesOrganisationId
      exchangeRateTemplateId
      pid
    }
  }
`;
