import * as React from "react";
import styled from "styled-components";
import { StyledTable } from "@genesys/ui-elements";

const StyledStyleDTable = styled(StyledTable)`
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

interface Props {
  readonly alignHeader?: "left" | "center" | "right";
  readonly alignRows?: "left" | "center" | "right";
  readonly children: React.ReactNode;
}

export function TableContainer({
  alignHeader = "left",
  alignRows = "left",
  children
}: Props) {
  return (
    <StyledStyleDTable alignRows={alignRows} alignHeader={alignHeader}>
      {children}
    </StyledStyleDTable>
  );
}

export function TableHeader({
  children
}: {
  readonly children: React.ReactNode;
}) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}

export function TableBody({
  children
}: {
  readonly children: React.ReactNode;
}) {
  return <tbody>{children}</tbody>;
}

export function TableRow({ children }: { readonly children: React.ReactNode }) {
  return <tr>{children}</tr>;
}

export function HeaderItem({
  children
}: {
  readonly children: React.ReactNode;
}) {
  return <th>{children}</th>;
}

export function CellItem({ children }: { readonly children: React.ReactNode }) {
  return <td>{children}</td>;
}
