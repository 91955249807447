import * as React from "react";
import * as Redux from "redux";
import * as GraphQLEntityCache from "gql-cache";
import * as GQLCachePatch from "gql-cache-patch";
import * as MutationsQueue from "@genesys/graphql-mutations-queue";
import * as Contexts from "../contexts";
import * as GraphQLEntityState from "../graphql-entity-state";
import { SentMutationResult } from "@genesys/graphql-mutations-queue";

interface ProviderProps {
  readonly dispatch: Redux.Dispatch<any>;
  readonly mutationQueues: {
    readonly [key: string]: MutationsQueue.MutationsQueueState;
  };
  readonly includeServerLog: boolean;
  readonly clientConfigContextValue: Contexts.ClientConfigContextValue;
  readonly children: React.ReactNode;
}

export class MutationsQueueProvider extends React.Component<
  ProviderProps,
  Contexts.MutationsQueueContextValue
> {
  queue = (
    queueId: string,
    queueMutationDescriptions: ReadonlyArray<MutationsQueue.QueueMutationDescription>
  ) => {
    this.props.dispatch(
      GraphQLEntityState.queueMutations(queueId, queueMutationDescriptions)
    );
  };
  deQueue = (
    queueId: string,
    queuedMutations: ReadonlyArray<MutationsQueue.QueuedMutation>
  ) => {
    this.props.dispatch(
      GraphQLEntityState.deQueueMutations(queueId, queuedMutations)
    );
  };

  flushQueue = async (
    queueId: string
  ): Promise<ReadonlyArray<SentMutationResult>> => {
    const queueState =
      this.state.mutationQueues[queueId] || MutationsQueue.EmptyQueue;

    const results = await MutationsQueue.sendMutations(
      queueState,
      GraphQLEntityState.getObjectToId,
      this.props.includeServerLog,
      this.props.clientConfigContextValue.graphqlEndpoint,
      this.props.clientConfigContextValue.authorization
    );

    const mutationsToDequeue = results.map(m => m.mutation);
    let allNewEntities: GraphQLEntityCache.EntityCache = {};
    let cachePatches: Array<GQLCachePatch.CachePatch> = [];
    for (const r of results) {
      allNewEntities = { ...allNewEntities, ...r.newEntities };
      cachePatches.push(...r.cachePatches);
    }

    this.props.dispatch(
      GraphQLEntityState.mergeUserEntitiesAndDeuqueQueuedMutations(
        allNewEntities,
        cachePatches,
        queueId,
        mutationsToDequeue
      )
    );

    return results;
  };

  discardQueue = (queueId: string) => {
    this.props.dispatch(GraphQLEntityState.discardMutationsQueue(queueId));
  };

  applyPatches = (cachePatches: ReadonlyArray<GQLCachePatch.CachePatch>) => {
    this.props.dispatch(GraphQLEntityState.applyCachePathes(cachePatches));
  };

  constructor(props: ProviderProps) {
    super(props);
    this.state = {
      queue: this.queue,
      deQueue: this.deQueue,
      discardQueue: this.discardQueue,
      flushQueue: this.flushQueue,
      applyPatches: this.applyPatches,
      mutationQueues: {}
    };
  }

  // tslint:disable-next-line:function-name
  static getDerivedStateFromProps(
    props: ProviderProps,
    state: Contexts.MutationsQueueContextValue
  ): Partial<Contexts.MutationsQueueContextValue> | null {
    if (props.mutationQueues !== state.mutationQueues) {
      return {
        mutationQueues: props.mutationQueues
      };
    }

    return null;
  }
  render() {
    return (
      <Contexts.mutationsQueueContext.Provider value={this.state}>
        {React.Children.only(this.props.children)}
      </Contexts.mutationsQueueContext.Provider>
    );
  }
}
