import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

const moistureLoadInputFragment = gql`
  fragment MoistureLoadInput on MoistureLoadCalcInputSettingsType {
    id
    settings
    caseOverridesDesignSummer
    caseOverridesDesignWinter
    caseOverridesJanuary
    caseOverridesFebruary
    caseOverridesMarch
    caseOverridesApril
    caseOverridesMay
    caseOverridesJune
    caseOverridesJuly
    caseOverridesAugust
    caseOverridesSeptember
    caseOverridesOctober
    caseOverridesNovember
    caseOverridesDecember
    binSize
    binDataLocation
    coolingDataType
    climateSettings
    systemOperationTime
    diffusionThroughMaterialsOperationTime
    evaporationOfWaterOperationTime
    evaporationFromMaterialsOperationTime
    emissionFromCombustionOperationTime
    emissionFromPeopleOperationTime
    heatTransmissionOperationTime
    intentionalVentilationOperationTime
    staticMoistureLoadOperationTime
    staticHeatLoadOperationTime
    unintendedVentilationOperationTime
    openDoorOperationTime
  }
`;

export const queryUser = gql`
  query MoistureLoadCalculationUserQuery(
    $moistureLoadNo: Int!
    $revisionNo: Int!
  ) {
    user {
      userProfile {
        id
        userSettings {
          id
          climate {
            id
            coolingDataType
          }

          moistureLoadToolUserSettings {
            ...MoistureLoadInput
          }
        }
      }

      moistureLoadByMoistureLoadNo(
        moistureLoadNo: $moistureLoadNo
        revisionNo: $revisionNo
      ) {
        id
        name
        revisionNo
        status
        statusDate

        systemInformation {
          id
          revisionNo
          genesysNo
        }

        moistureloadFile {
          id
          moistureLoadNo
          name
          aclUsers
          aclClaims
          isDeleted
          latestMoistureLoad {
            id
            name
            revisionNo
            status
          }
          owner {
            id
            userName
          }

          labels {
            id
            name
          }
        }
        moistureloadInput {
          ...MoistureLoadInput
        }

        moistureLoadResult {
          ...MoistureLoadResultFragment
        }
      }
    }
  }
  ${moistureLoadInputFragment}
  ${Fragments.moistureLoadResultFragment}
`;

export const queryMoistureLoadProduct = gql`
  query MoistureLoadCalculationProductQuery($locationId: String!) {
    product {
      languages {
        id
      }
      dataPointsForLocationId(locationId: $locationId) {
        id
        caseType
        climateDataType
        annualOccurence
        temperature
        humidity
        windSpeed
      }
      binDataLocations {
        binDataLocationId
        locationName
        regionName
        countryName
        latitude
        longitude
      }

      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }

      moistureLoadRules {
        id
        defaultSettings
        propertyDefinitions {
          id
          moistureLoadPropertySelectorType
          propertyName
          defaultValue
          validationFilter
          items {
            value
            validationFilter
            showDescription
          }
          falsePropertyFilter
          truePropertyFilter
          visibilityFilter
          enabledFilter
          showDescription
        }
      }
    }
  }
`;

export const calculationQuery = gql`
  query MoistureLoadCalculationResult($input: MoistureLoadInputType!) {
    user {
      moistureLoadCalculation(input: $input) {
        success
        logTraceId
        moistureLoadSettingsErrors {
          resultGroup
          moistureLoadSettings
          errors
        }
        result {
          ...MoistureLoadResultFragment
        }
      }
    }
  }

  ${Fragments.moistureLoadResultFragment}
`;
