import { exhaustiveCheck } from "ts-exhaustive-check";
import { State, Action, UpdateReturnType } from "./types";
import { fechUserQueryCmd } from "../shared";
import { queryUser } from "../../queries";
import * as SharedState from "../../../shared-state";
import * as GraphQLTypes from "../../../graphql-types";
import * as MoistureLoadActions from "../../../moisture-load-actions";

export function handleMLActionStatus(
  status: MoistureLoadActions.StatusType,
  state: State,
  sharedState: SharedState.State
): UpdateReturnType {
  const type = status.type;

  switch (type) {
    case "moisture-load-deleted":

    case "ready": {
      return [{ ...state }];
    }

    case "moisture-load-file-updated": {
      const newState = {
        ...state,
        showLoader: false
      };

      const searchResult = fechUserQueryCmd(
        sharedState,
        state.moistureLoadNo,
        state.revisionNo
      );
      let alertMessage: SharedState.AlertMessage | undefined = undefined;

      if (status.errorMessage) {
        alertMessage = {
          content: status.errorMessage,
          messageType: "error",
          timeout: 10000
        };
      } else if (status.updateType && status.updateType.type === "transfer") {
        alertMessage = {
          content: `${status.updateType.transferTo.substring(
            0,
            status.updateType.transferTo.indexOf("-")
          )} succesfully transfered to ${status.updateType.transferTo}`,
          messageType: "success",
          timeout: 10000
        };
      }
      return [
        newState,
        searchResult,
        alertMessage
          ? [SharedState.Action.addAlertMessageToQueue(alertMessage)]
          : []
      ];
    }
    case "moisture-load-copied-to-new-file": {
      return [
        {
          ...state,
          showLoader: false,
          modal: {
            type: "copied-to-new-file",
            moistureLoadNo: status.moistureLoadNo
          }
        }
      ];
    }
    case "new-revision-created": {
      return [
        { ...state },

        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.MoistureLoadCalculationUserQuery,
          GraphQLTypes.MoistureLoadCalculationUserQueryVariables,
          Action
        >(
          queryUser,
          {
            moistureLoadNo: state.moistureLoadNo,
            revisionNo: state.revisionNo
          },
          data =>
            Action.userqueryRecivedFromMoistureLoadAction(data, {
              ...state,
              actionMenuIsOpen: false,
              modal: {
                type: "open-revision",
                moistureLoadNo: status.moistureLoadNo,
                revision: status.revisionNo
              }
            })
        )
      ];
    }
    case "label-manager-unmounted":
      return [
        {
          ...state
        },
        fechUserQueryCmd(sharedState, state.moistureLoadNo, state.revisionNo)
      ];
    case "in-progress": {
      return [
        {
          ...state,
          actionMenuIsOpen: false,
          showLoader: true
        }
      ];
    }

    default:
      return exhaustiveCheck(type, true);
  }
}
