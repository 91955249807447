import * as PropertiesSelector from "../../properties-selector";
import * as Authorization from "@genesys/shared/lib/authorization";
import { PropertyFilter } from "@genesys/property";
import { SystemType } from "../types";

export function getAllowedRanges(
  systemTypes: ReadonlyArray<SystemType>,
  applicationClaims: Authorization.ApplicationClaims
) {
  return (
    Authorization.getClaimValues(
      applicationClaims,
      Authorization.systemTypeClaims.create
    ) || []
  ).reduce((soFar, current) => {
    const splits = current.split(";");
    const systemId = splits[0];
    if (!systemTypes.find(st => st.id === systemId)) {
      return soFar;
    }
    const filter = splits[1];
    const rangeType = parseInt(filter.split("rangetype=")[1].charAt(0), 10);
    return soFar.includes(rangeType) ? soFar : soFar.concat([rangeType]);
  }, [] as ReadonlyArray<number>);
}

export function filterPropertiesForRange(
  productProperties: ReadonlyArray<PropertiesSelector.PropertyInfo>,
  allowedRanges: ReadonlyArray<number>,
  applicationClaims: Authorization.ApplicationClaims
) {
  const isDeveloper = Authorization.checkPermission(
    applicationClaims,
    Authorization.genesysUserClaims.developer
  );

  if (isDeveloper) {
    return productProperties;
  }

  return productProperties.map(p => {
    if (p.name !== "rangetype") {
      return p;
    } else {
      return {
        ...p,
        items: p.items.filter(i =>
          allowedRanges.some(ar => ar === (i.value!.value as number))
        )
      };
    }
  });
}

export function createNewProductProperties(
  allowedRanges: readonly number[],
  applicationClaims: Authorization.ApplicationClaims
): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  const validateRangeValue = (value: number) => {
    const isDeveloper = Authorization.checkPermission(
      applicationClaims,
      Authorization.genesysUserClaims.developer
    );
    if (isDeveloper) {
      return true;
    }
    return allowedRanges.includes(value);
  };
  return [
    {
      name: "rangetype",
      quantity: "Discrete",
      group: "mainselections",
      sortNo: 10,
      defaultValues: [],
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [
        {
          sortNo: 0,
          value: { type: "integer", value: 1 },
          validationFilter: PropertyFilter.Empty,
          text: "Released",
          descriptionValuesTexts: []
        },
        {
          sortNo: 1,
          value: { type: "integer", value: 4 },
          validationFilter: PropertyFilter.Empty,
          text: "OEM",
          descriptionValuesTexts: []
        }
      ].filter(item => validateRangeValue(item.value?.value)) as any,
      valueSources: [],
      conversionParameters: [],
      descriptionTexts: []
    }
  ];
}
