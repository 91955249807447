import * as React from "react";
import { Pop } from "../popover";

export function HasPopOver({
  children,
  anchorEl,
  onClose = () => undefined,
  setAnchorEl
}: {
  readonly children: React.ReactNode;
  readonly anchorEl: Element | null;
  readonly onClose?: () => void;
  readonly setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}) {
  const handleClose = () => {
    onClose();
    setAnchorEl(null);
  };
  return (
    <>
      {anchorEl && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          {children}
        </Pop>
      )}
    </>
  );
}
