import styled from "styled-components";
import * as React from "react";
import * as Colors from "../colors";

type Props = {
  readonly left: number;
  readonly top: number;
  readonly myRef: (el: HTMLDivElement) => void;
} & React.HTMLProps<HTMLDivElement>;

// tslint:disable-next-line:function-name
function UnStyledDiv(props: Props): JSX.Element {
  const { left, top, myRef, ...restProps } = props;
  return (
    <div ref={myRef} {...restProps}>
      {props.children}
    </div>
  );
}

// tslint:disable-next-line
export const PopupComponentContainer = styled(UnStyledDiv)`
  position: relative;
  top: ${props => props.top}px;
  left: ${props => props.left}px;

  min-width: 220px;

  display: inline-block;
  padding: 10px;
  border: 1px solid ${Colors.searchFilterBorderColor};
  border-radius: 1px;

  box-shadow: 3px 3px 5px #eee;

  background: white;

  h1 {
    text-align: center;
    font-weight: bold;
    color: ${Colors.actionMenuHeaderColor};
    font-size: 17px;

    padding-bottom: 10px;
    border-bottom: 1px solid ${Colors.searchFilterBorderColor};

    display: block;
    position: relative;
  }
`;
