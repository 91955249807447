import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as LabelManager from "../label-manager";
import styled from "styled-components";
import { StandardButton, H3 } from "@genesys/ui-elements";
import { Action, State } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { ActionsContext } from "./context";
import { InputComponent } from "../shared-manager-components/input";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";

const Container = styled.div``;

const RevisionLockMessageContainer = styled.div`
  padding: 15px 20px;
  width: 370px;
  border: 1px solid #dddddd;
  button {
    margin: 15px 10px 0 0;
  }
  h3 {
    white-space: normal;
  }
`;

const ListContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #dddddd; */
  white-space: nowrap;

  button {
    background-color: inherit;
    width: 100%;
    height: 25px;
    margin: 0;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

const ConfirmDeleteContainer = styled.div`
  padding: 15px 20px;
  border: 1px solid #dddddd;

  div {
    display: flex;
    button {
      margin: 15px 10px 0 0;
    }
  }
`;

export function MenuContainer({
  children,
  state,
  sharedState,
  dispatch
}: {
  readonly children: React.ReactNode;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;
  const providerValue = {
    state,
    dispatch,
    translate
  };

  React.useEffect(() => {
    return () => dispatch(Action.setContent({ type: "list" }));
  }, []);

  return (
    <Container>
      <ActionsContext.Provider value={providerValue}>
        <ListContent state={state}>{children}</ListContent>
        <RevisionContent
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
        />
        <RenameContent
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
        />
        <LabelManagerContent
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
        />
        <DeleteContent
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
        />
        <TransferContent
          dispatch={dispatch}
          sharedState={sharedState}
          state={state}
        />
      </ActionsContext.Provider>
    </Container>
  );
}

interface SubContentProps {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}

function TransferContent({ state, sharedState, dispatch }: SubContentProps) {
  const content = state.content;

  if (content.type !== "transfer") {
    return null;
  }

  const [input, setInput] = React.useState("");
  const moistureLoadFile = content.moistureLoadFile;

  return (
    <InputComponent
      placeHolder={"Username of user"}
      sharedState={sharedState}
      header={sharedState.translate(LanguageTexts.transfertouser())}
      inputValue={input}
      onChange={e => setInput(e.currentTarget.value)}
      onCancel={() => dispatch(Action.setContent({ type: "list" }))}
      // customTranslation={staticText => {
      //   return customTranlate(staticText, sharedState.translate);
      // }}
      onSave={() =>
        dispatch(
          Action.updateMoistureLoadFile(
            {
              input: {
                id: moistureLoadFile.id,
                labelIds: moistureLoadFile.labels.map(x => x.id),
                name: moistureLoadFile.name,
                owner: input
              }
            },
            {
              type: content.type,
              transferTo: input,
              fileNo: sharedState.genesysPrefix.moistureLoadNo(
                moistureLoadFile.mno,
                1
              )
            }
          )
        )
      }
    />
  );
}

function DeleteContent({ state, sharedState, dispatch }: SubContentProps) {
  const content = state.content;

  if (content.type !== "delete") {
    return null;
  }

  return (
    <ConfirmDeleteContainer>
      <H3 color="dark" weight="normal">
        {sharedState.translate(LanguageTexts.confirmdelete())}
      </H3>
      <div>
        <StandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={() => dispatch(Action.setContent({ type: "list" }))}
        >
          {sharedState.translate(LanguageTexts.cancel())}
        </StandardButton>

        <StandardButton
          buttonType="Primary-2"
          size="Small"
          onClick={() =>
            dispatch(Action.deleteMoistureLoad([content.moistureLoadFileId]))
          }
        >
          {sharedState.translate(LanguageTexts.confirm())}
        </StandardButton>
      </div>
    </ConfirmDeleteContainer>
  );
}

function ListContent({
  children,
  state
}: {
  readonly children: React.ReactNode;
  readonly state: State;
}) {
  if (state.content.type !== "list") {
    return null;
  }

  return <ListContainer>{children}</ListContainer>;
}

function RenameContent({ state, sharedState, dispatch }: SubContentProps) {
  const contentType = state.content;
  if (contentType.type !== "rename") {
    return null;
  }

  const moistureLoadFile = contentType.moistureLoadFile;
  const [input, setInput] = React.useState(moistureLoadFile.name);

  return (
    <InputComponent
      placeHolder={"New moisture load file name"}
      sharedState={sharedState}
      header={sharedState.translate(LanguageTexts.rename())}
      inputValue={input}
      onChange={e => setInput(e.currentTarget.value)}
      onCancel={() => dispatch(Action.setContent({ type: "list" }))}
      // customTranslation={staticText => {
      //   return customTranlate(staticText, sharedState.translate);
      // }}
      onSave={() =>
        dispatch(
          Action.updateMoistureLoadFile(
            {
              input: {
                id: moistureLoadFile.id,
                labelIds: moistureLoadFile.labels.map(x => x.id),
                name: input,
                owner: moistureLoadFile.owner
              }
            },
            { type: contentType.type }
          )
        )
      }
    />
  );
}

function LabelManagerContent({
  state,
  sharedState,
  dispatch
}: SubContentProps) {
  const contentType = state.content;
  if (!state.labelManagerState || contentType.type !== "label") {
    return null;
  }

  const moistureLoadFile = contentType.moistureLoadFile;

  return (
    <LabelManager.LabelManagerView
      labels={sharedState.user.labels}
      sharedState={sharedState}
      state={state.labelManagerState}
      onCancel={() => {
        dispatch(Action.setContent({ type: "list" }));
      }}
      onAssign={selectedLabels => {
        dispatch(
          Action.updateMoistureLoadFile(
            {
              input: {
                id: moistureLoadFile.id,
                labelIds: selectedLabels.map(l => l.id),
                name: moistureLoadFile!.name,
                owner: moistureLoadFile.owner
              }
            },
            { type: contentType.type }
          )
        );
      }}
      dispatch={Dispatch.map(Action.dispatchLabelManager, dispatch)}
    />
  );
}

function RevisionContent({ state, sharedState, dispatch }: SubContentProps) {
  const contentType = state.content;
  if (contentType.type !== "revision") {
    return null;
  }

  const goToListMode = () => dispatch(Action.setContent({ type: "list" }));

  const moistureLoadFile = contentType.moistureLoadFile;
  const latestRevisionIsLocked =
    moistureLoadFile.latestMoistureLoad.status ===
    MoistureLoadStatus.LockSuccess;
  if (!latestRevisionIsLocked) {
    return (
      <RevisionLockMessageContainer>
        <H3 color="dark" weight="normal">
          {sharedState.translate(LanguageTexts.latestRevNeedsToBeLocked())}
        </H3>

        <StandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={goToListMode}
        >
          {sharedState.translate(LanguageTexts.ok())}
        </StandardButton>
      </RevisionLockMessageContainer>
    );
  }

  const [input, setInput] = React.useState("");

  return (
    <InputComponent
      placeHolder={sharedState.translate(LanguageTexts.createnewrevision())}
      sharedState={sharedState}
      header={"New revision name"}
      inputValue={input}
      onChange={e => setInput(e.currentTarget.value)}
      onCancel={goToListMode}
      // customTranslation={staticText => {
      //   return customTranlate(staticText, sharedState.translate);
      // }}
      onSave={() => {
        dispatch(Action.createNewRevision(moistureLoadFile!.id, input));
      }}
    />
  );
}

// function customTranlate(
//   staticText: LanguageTexts.StaticText,
//   translate: (textDefinition: LanguageTexts.TextDefinition) => string
// ) {
//   const textId = "mlc" + staticText.textId;
//   return translate({
//     type: "StaticText",
//     textId
//   });
// }
