import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as Routes from "../routes";
import { clientConfig } from "../config";
import * as SharedState from "../shared-state";
import * as Navigation from "../navigation-effect-manager";
import { createTopLevelNodes } from "./timers-view/timer-grouping";
import { LogRow, TraceSpan, TopLevelNodes, LogRowFromServer } from "./types";
import {
  createFetchCmd,
  getFilterMaps,
  toggleAdvancedSet,
  toggleCategoriesSelection
} from "./functions";

export type State = NoSearchState | FetchingState | LoadedState;

export type NoSearchState = {
  readonly type: "NoSearchState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
};

export type FetchingState = {
  readonly type: "FetchingState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
};

export type LoadedState = {
  readonly type: "LoadedState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
  readonly servicesWithCategories: Map<string, Set<string>>;
  readonly logRows: ReadonlyArray<LogRow>;
  readonly traceSpans: ReadonlyArray<TraceSpan>;
  readonly topLevelNodes: TopLevelNodes;
  readonly enabledServicesAndCategories: Set<string>;
  readonly lineFilterRegexField: string;
  readonly lineFilterRegex: string;
  readonly showFilter: boolean;
  readonly showCategories: boolean;
  readonly showAdvancedFilter: boolean;
  readonly caseTypes: Set<string>;
  readonly enabledCaseTypes: Set<string>;
  readonly caseNames: Set<string>;
  readonly enabledCaseNames: Set<string>;
  readonly components: Set<string>;
  readonly enabledComponents: Set<string>;
  readonly exportFileUrl: string | undefined;
  readonly view: "logs" | "timers";
};

export const init = (
  location: Routes.LogViewerLocation,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  const traceIdField =
    location.type === "withTraceId" ? location.params.traceId : "";

  if (traceIdField.length === 0) {
    return [
      {
        type: "NoSearchState",
        location: location,
        traceIdField: traceIdField
      }
    ];
  }

  return [
    {
      type: "FetchingState",
      location: location,
      traceIdField: traceIdField
    },
    createFetchCmd(
      clientConfig.genesysBackend,
      sharedState.accessToken,
      traceIdField
    )
  ];
};

export const Action = ctorsUnion({
  TelemetryReceived: (
    traceSpans: ReadonlyArray<TraceSpan>,
    rows: ReadonlyArray<LogRowFromServer>
  ) => ({ traceSpans, rows }),
  RetrieveTelemetry: () => ({}),
  toggleCategory: (serviceName: string, category: string) => ({
    serviceName,
    category
  }),
  toggleCaseType: (caseType: string) => ({ caseType }),
  toggleCaseName: (caseName: string) => ({ caseName }),
  toggleComponent: (component: string) => ({ component }),
  setLineFilterRegexField: (regex: string) => ({ regex }),
  setLineFilterRegex: () => ({}),
  toggleShowFilter: () => ({}),
  toggleShowCategories: () => ({}),
  toggleShowAdvancedFilter: () => ({}),
  createExportFile: (text: string) => ({ text }),
  clearExportFile: () => ({}),
  setView: (view: "logs" | "timers") => ({ view })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): readonly [State, Cmd<Action>?] {
  switch (action.type) {
    case "TelemetryReceived": {
      const {
        servicesWithCategories,
        enabledServicesAndCategories,
        caseTypes,
        caseNames,
        components
      } = getFilterMaps(action.rows);

      return [
        {
          ...state,
          type: "LoadedState",
          logRows: action.rows.map((r, i) => ({
            ...r,
            id: i,
            formatted: `${new Date(
              r.timestamp / 1000 / 1000
            ).toLocaleTimeString()} - (${r.serviceName}) [${r.category}] [${
              r.caseType
            }] [${r.caseName}] [${r.component}]: ${r.body}`
          })),
          traceSpans: action.traceSpans,
          topLevelNodes: createTopLevelNodes(action.traceSpans),
          servicesWithCategories,
          enabledServicesAndCategories,
          lineFilterRegexField: "",
          lineFilterRegex: "",
          showFilter: false,
          showCategories: false,
          showAdvancedFilter: false,
          caseTypes: caseTypes,
          enabledCaseTypes: caseTypes,
          caseNames: caseNames,
          enabledCaseNames: caseNames,
          components: components,
          enabledComponents: components,
          exportFileUrl: undefined,
          view: "logs"
        }
      ];
    }
    case "RetrieveTelemetry": {
      return [
        { ...state, type: "FetchingState" },
        Navigation.pushUrl("/log-viewer/" + state.traceIdField)
      ];
    }
    case "toggleCategory": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledServicesAndCategories: toggleCategoriesSelection(
            state.servicesWithCategories,
            state.enabledServicesAndCategories,
            action.serviceName,
            action.category
          )
        }
      ];
    }
    case "toggleCaseType": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledCaseTypes: toggleAdvancedSet(
            state.caseTypes,
            state.enabledCaseTypes,
            action.caseType
          )
        }
      ];
    }
    case "toggleCaseName": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledCaseNames: toggleAdvancedSet(
            state.caseNames,
            state.enabledCaseNames,
            action.caseName
          )
        }
      ];
    }
    case "toggleComponent": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledComponents: toggleAdvancedSet(
            state.components,
            state.enabledComponents,
            action.component
          )
        }
      ];
    }
    case "setLineFilterRegex": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, lineFilterRegex: state.lineFilterRegexField }];
    }
    case "setLineFilterRegexField": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, lineFilterRegexField: action.regex }];
    }
    case "toggleShowFilter": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [
        {
          ...state,
          showFilter: !state.showFilter
        }
      ];
    }
    case "toggleShowCategories": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, showCategories: !state.showCategories }];
    }
    case "toggleShowAdvancedFilter": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, showAdvancedFilter: !state.showAdvancedFilter }];
    }
    case "createExportFile": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      const blob = new Blob([action.text]);
      const url = URL.createObjectURL(blob);
      return [{ ...state, exportFileUrl: url }];
    }
    case "clearExportFile": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, exportFileUrl: undefined }];
    }
    case "setView": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, view: action.view }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
