export enum MoistureLoadStatus {
  Created = 0,

  SaveFailed = 31,
  Saved = 32,

  CalculationFailed = 51,
  CalculationSuccess = 52,

  LockFailed = 89,
  LockSuccess = 90
}
