import * as React from "react";
import { State } from "./state";
import * as SharedState from "../shared-state";
import { Img, Spinner } from "@genesys/ui-elements";
import styled from "styled-components";

const StyledImg = styled(Img)`
  max-width: 720px;
  max-height: 900px;
`;

export function PsychrometricChartView({
  state,
  sharedState
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  return state.src ? (
    <StyledImg accessToken={sharedState.accessToken} src={state.src} />
  ) : (
    <Spinner />
  );
}
