import * as React from "react";
// import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import { ModalComponent } from "../../modal-component";
import { State, Action, Modal } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function ModalContent({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state.modal) {
    return null;
  }

  const [contentDescription, onCancel, onConfirm] = getModalContentAndActions(
    state.modal!,
    sharedState,
    dispatch
  );

  return (
    <ModalComponent
      sharedState={sharedState}
      header={contentDescription.title}
      content={contentDescription.content}
      cancelLabel={contentDescription.cancelLabel}
      confirmLabel={contentDescription.confirmLabel}
      onClose={onCancel}
      onConfirm={onConfirm}
    />
  );
}

type OnCancel = () => void;
type OnConfirn = () => void;

interface ContentDescription {
  readonly title: string;
  readonly content: React.ReactNode;
  readonly cancelLabel?: string;
  readonly confirmLabel?: string;
}

function getModalContentAndActions(
  modal: Modal,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
): [ContentDescription, OnConfirn, OnCancel?] {
  switch (modal.type) {
    case "info": {
      return [
        {
          title: "InFormation",
          content: modal.info
        },
        () => dispatch(Action.setModal(undefined))
      ];
    }

    case "copied-to-new-file": {
      const url = `/moisture-load/${sharedState.genesysPrefix.moistureLoadNo(
        modal.moistureLoadNo,
        1
      )}`;

      return [
        {
          title: "Revision Copied SuccesFully",
          content: "Open in new tab?",
          cancelLabel: "No",
          confirmLabel: "Yes open in new tab"
        },
        () => (window.location.href = url),
        () => {
          window.open(url);
          dispatch(Action.setModal(undefined));
        }
      ];
    }

    case "open-revision": {
      const url = `/moisture-load/${sharedState.genesysPrefix.moistureLoadNo(
        modal.moistureLoadNo,
        modal.revision
      )}`;
      return [
        {
          title: "New Revision Created",
          content: "Open in new tab?",
          cancelLabel: "No",
          confirmLabel: "Yes open in new tab"
        },
        () => (window.location.href = url),
        () => {
          window.open(url);
          dispatch(Action.setModal(undefined));
        }
      ];
    }

    default:
      return exhaustiveCheck(modal, true);
  }
}
