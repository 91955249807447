import styled from "styled-components";

// tslint:disable-next-line
export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 10px;

  > button {
    margin-left: 5px;
  }
`;
