import styled from "styled-components";

// tslint:disable-next-line
export const H2 = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;

  text-align: left;
`;
