import * as React from "react";
import { Size } from "./types";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import {
  Root,
  TargetPowerContainer,
  InnerDiv,
  SizeCardContainer,
  CardsContainer,
  ImgContainer
} from "./elements";
import { OverlayLoader, Img, P1, CheckBox } from "@genesys/ui-elements";
import * as GenesysPropertiesSelectorHelpers from "@genesys/client-core/lib/genesys-properties-selector-helpers";
import {
  PropertyFilter,
  PropertyValueSet,
  PropertyValue
} from "@genesys/property";
import { Amount, Quantity, Units } from "@genesys/uom";
import { AmountFormatSelector } from "../../amount-format-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { getValue } from "@genesys/shared/lib/product-properties";
import { clientConfig } from "../../config";

export function DataCenterSizeSelectorView({
  state,
  sharedState,
  systemProperties,
  operatingCases,
  systemTypeId,
  isSystemLocked,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemProperties: PropertyValueSet.PropertyValueSet;
  readonly operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly systemTypeId: string;
  readonly isSystemLocked: boolean;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!state.data) {
    return <OverlayLoader />;
  }

  const sys = state.data.product.sys;
  const fieldGroup = "SizeSelector" + systemTypeId;
  const sizeProperty = "epxsize";
  const targetPower = operatingCases
    .map(oc => PropertyValueSet.getAmount<Quantity.Power>("targetpower", oc)!)
    .sort(
      (a, b) => Amount.valueAs(Units.Watt, b) - Amount.valueAs(Units.Watt, a)
    )[0];

  const translation = GenesysPropertiesSelectorHelpers.createTranslation(
    sharedState.translate,
    sys.id
  );

  const powerFieldName = "Power";
  const powerAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    powerFieldName,
    "Power"
  );

  const flowFieldName = "Flow";
  const flowAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    flowFieldName,
    "MassFlow"
  );

  const lengthFieldName = "Length";
  const lengthAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    lengthFieldName,
    "Length"
  );

  const surfaceFieldName = "Surface";
  const surfaceAmountFormat = sharedState.screenAmounts.getAmountFormat(
    fieldGroup,
    surfaceFieldName,
    "Area"
  );

  const propertyEpx = sys.properties
    .find(p => p.name === sizeProperty)!
    .values.map(v => ({
      ...v,
      value: parseInt(v!.value!, 10),
      rangeFilter: PropertyFilter.fromStringOrEmpty(v!.rangeFilter),
      validationFilter: PropertyFilter.fromStringOrEmpty(v!.validationFilter)
    }))
    .filter(
      v =>
        v!.rangeFilter === undefined ||
        PropertyFilter.isValid(systemProperties, v!.rangeFilter!)
    );

  const epx = propertyEpx
    .map(p => ({
      table: {
        sizeInfoMechanical: (sys.sizeInfoMechanical?.rows || []).find(t =>
          PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                sizeProperty,
                PropertyValue.create("integer", p!.value!)
              ),
              systemProperties
            ),
            PropertyFilter.fromStringOrEmpty(t!.propertyFilter || "")!
          )
        )!,
        sizeInfoPerformance: (sys.sizeInfoPerformance?.rows || []).find(t =>
          PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                sizeProperty,
                PropertyValue.create("integer", p!.value!)
              ),
              systemProperties
            ),
            PropertyFilter.fromStringOrEmpty(t!.propertyFilter || "")!
          )
        )!
      },
      image: sys.images.find(
        i =>
          i.name === "CoverSheetImage" &&
          PropertyFilter.isValid(
            PropertyValueSet.merge(
              PropertyValueSet.fromProperty(
                sizeProperty,
                PropertyValue.create("integer", p!.value!)
              ),
              systemProperties
            ),
            PropertyFilter.fromStringOrEmpty(i.propertyFilter || "")!
          )
      ),
      property: p!
    }))
    .map(e => {
      const sizeInfoMechanicalValues = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        e.table.sizeInfoMechanical.values
      );
      const sizeInfoPerformanceValues = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        e.table.sizeInfoPerformance.values
      );
      return {
        ...e,
        table: {
          ...e.table,
          sizeInfoMechanical: {
            length: PropertyValueSet.getAmount<Quantity.Length>(
              "Length",
              sizeInfoMechanicalValues
            ),
            width: PropertyValueSet.getAmount<Quantity.Length>(
              "Width",
              sizeInfoMechanicalValues
            ),
            height: PropertyValueSet.getAmount<Quantity.Length>(
              "Height",
              sizeInfoMechanicalValues
            )
          },
          sizeInfoPerformance: {
            minFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
              "MinProcessFlow",
              sizeInfoPerformanceValues
            ),
            maxFlow: PropertyValueSet.getAmount<Quantity.MassFlow>(
              "MaxProcessFlow",
              sizeInfoPerformanceValues
            ),
            maxPower: PropertyValueSet.getAmount<Quantity.Power>(
              "MaxPower",
              sizeInfoPerformanceValues
            )
          }
        }
      };
    });

  const sizes: Array<Size> = epx.map(e => ({
    epx: e.property.value,
    displayName: translation.propertyValue(sizeProperty, e.property.value),
    length: e.table.sizeInfoMechanical.length!,
    width: e.table.sizeInfoMechanical.width!,
    height: e.table.sizeInfoMechanical.height!,
    surface: Amount.create(
      Amount.valueAs(Units.Meter, e.table.sizeInfoMechanical.length!) *
        Amount.valueAs(Units.Meter, e.table.sizeInfoMechanical.width!),
      Units.SquareMeter
    ),
    minFlow: e.table.sizeInfoPerformance.minFlow!,
    maxFlow: e.table.sizeInfoPerformance.maxFlow!,
    maxPower: e.table.sizeInfoPerformance.maxPower!,
    imageUrl: e.image!.url
  }));

  if (!state.selectedSize && sizes.length) {
    dispatch(
      Action.setSize(
        PropertyValueSet.getInteger(sizeProperty, systemProperties) ||
          sizes[0].epx
      )
    );
  }

  return (
    <Root>
      <TargetPowerContainer>
        <P1 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.targetPower())}
          {": "}
          {getValue(PropertyValue.fromAmount(targetPower), powerAmountFormat)}
          &nbsp;
        </P1>
        <AmountFormatSelector
          type="AmountFormatSelectorProps"
          fieldGroup={fieldGroup}
          fieldName={powerFieldName}
          amountFormat={powerAmountFormat}
          conversionParameters={undefined}
          translate={sharedState.translate}
          onFormatCleared={() => {
            dispatch(Action.FormatCleared(fieldGroup, powerFieldName));
          }}
          onFormatChanged={(unit, decimalCount) => {
            dispatch(
              Action.FormatChanged(
                fieldGroup,
                powerFieldName,
                unit,
                decimalCount
              )
            );
          }}
        />
      </TargetPowerContainer>
      <CardsContainer>
        {sizes.map(s => {
          const isSelected = state.selectedSize === s.epx;
          return (
            <SizeCardContainer
              isSelected={isSelected}
              onClick={() => {
                if (!isSystemLocked) {
                  dispatch(Action.setSize(s.epx));
                }
              }}
              key={s.epx}
            >
              <p>{s.displayName}</p>

              <ImgContainer>
                <Img
                  src={clientConfig.genesysBackend + s.imageUrl}
                  accessToken={sharedState.accessToken}
                  width={200}
                  height={"auto"}
                />
              </ImgContainer>

              <InnerDiv>
                <P1 weight="normal" color="dark">
                  {"< "}
                  {getValue(
                    PropertyValue.fromAmount(s.maxPower),
                    powerAmountFormat
                  )}
                  &nbsp;
                </P1>
                <AmountFormatSelector
                  type="AmountFormatSelectorProps"
                  fieldGroup={fieldGroup}
                  fieldName={powerFieldName}
                  amountFormat={powerAmountFormat}
                  conversionParameters={undefined}
                  translate={sharedState.translate}
                  onFormatCleared={() =>
                    dispatch(Action.FormatCleared(fieldGroup, powerFieldName))
                  }
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.FormatChanged(
                        fieldGroup,
                        powerFieldName,
                        unit,
                        decimalCount
                      )
                    )
                  }
                />
              </InnerDiv>

              <InnerDiv>
                <P1 weight="normal" color="dark">
                  {getValue(
                    PropertyValue.fromAmount(s.minFlow),
                    flowAmountFormat
                  )}
                  {" - "}
                  {getValue(
                    PropertyValue.fromAmount(s.maxFlow),
                    flowAmountFormat
                  )}
                  &nbsp;
                </P1>
                <AmountFormatSelector
                  type="AmountFormatSelectorProps"
                  fieldGroup={fieldGroup}
                  fieldName={flowFieldName}
                  amountFormat={flowAmountFormat}
                  conversionParameters={undefined}
                  translate={sharedState.translate}
                  onFormatCleared={() =>
                    dispatch(Action.FormatCleared(fieldGroup, flowFieldName))
                  }
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.FormatChanged(
                        fieldGroup,
                        flowFieldName,
                        unit,
                        decimalCount
                      )
                    )
                  }
                />
              </InnerDiv>

              <InnerDiv>
                <P1 weight="normal" color="dark">
                  {getValue(
                    PropertyValue.fromAmount(s.length),
                    lengthAmountFormat
                  )}
                  {" x "}
                  {getValue(
                    PropertyValue.fromAmount(s.width),
                    lengthAmountFormat
                  )}
                  {" x "}
                  {getValue(
                    PropertyValue.fromAmount(s.height),
                    lengthAmountFormat
                  )}
                </P1>
                <AmountFormatSelector
                  type="AmountFormatSelectorProps"
                  fieldGroup={fieldGroup}
                  fieldName={lengthFieldName}
                  conversionParameters={undefined}
                  amountFormat={lengthAmountFormat}
                  translate={sharedState.translate}
                  onFormatCleared={() =>
                    dispatch(Action.FormatCleared(fieldGroup, lengthFieldName))
                  }
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.FormatChanged(
                        fieldGroup,
                        lengthFieldName,
                        unit,
                        decimalCount
                      )
                    )
                  }
                />
                <P1 weight="normal" color="dark">
                  &nbsp;
                  {"/ "}
                  {getValue(
                    PropertyValue.fromAmount(s.surface),
                    surfaceAmountFormat
                  )}
                  &nbsp;
                </P1>
                <AmountFormatSelector
                  type="AmountFormatSelectorProps"
                  fieldGroup={fieldGroup}
                  fieldName={surfaceFieldName}
                  conversionParameters={undefined}
                  amountFormat={surfaceAmountFormat}
                  translate={sharedState.translate}
                  onFormatCleared={() =>
                    dispatch(Action.FormatCleared(fieldGroup, surfaceFieldName))
                  }
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.FormatChanged(
                        fieldGroup,
                        surfaceFieldName,
                        unit,
                        decimalCount
                      )
                    )
                  }
                />
              </InnerDiv>

              <InnerDiv>
                <CheckBox
                  isChecked={isSelected}
                  children={
                    isSelected
                      ? sharedState.translate(LanguageTexts.selected())
                      : sharedState.translate(LanguageTexts.select())
                  }
                  onClick={() => {
                    if (!isSystemLocked) {
                      dispatch(Action.setSize(s.epx));
                    }
                  }}
                />
              </InnerDiv>
            </SizeCardContainer>
          );
        })}
      </CardsContainer>
    </Root>
  );
}
