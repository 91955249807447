import * as React from "react";
import * as SharedState from "../../shared-state";
import { InfoGray, P1, P2 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  DhCapacityInfoRoot,
  PopupContainer,
  Popup,
  PaddingBottomContainer,
  MessageContainer
} from "../elements";

export function DhCapacityInfo({
  sharedState
}: {
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);

  return (
    <DhCapacityInfoRoot>
      <PopupContainer
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}
      >
        <InfoGray height={20} />
        {isOpen && (
          <Popup borderColor={"blue"} width={"narrow"}>
            <PaddingBottomContainer>
              <MessageContainer>
                <P2>{sharedState.translate(LanguageTexts.dhCapacityInfo())}</P2>
              </MessageContainer>
            </PaddingBottomContainer>
          </Popup>
        )}
      </PopupContainer>
      <P1 color="dark" weight="bold">
        {"DH Capacity"}
      </P1>
    </DhCapacityInfoRoot>
  );
}
