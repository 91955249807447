import gql from "graphql-tag";

export const SystemTypeQuery = gql`
  query WizardSystemSettingsSystemType {
    product {
      activeSystemTypes {
        id
        name
        systemTypeStatus
        description
        sys {
          properties {
            sortNo
            quantity
            defaultValues {
              value
              propertyFilter
            }
            name
            groupName
            visibilityFilter
            validationFilter
            values {
              sortNo
              image
              value
              validationFilter
              rangeFilter
            }
            quantityConversionParams
          }
        }
      }
    }
  }
`;

export const ImagesQuery = gql`
  query WizardImages($input: [GetSystemTypeInputType!]!) {
    product {
      systemTypes(input: $input) {
        id
        sys {
          images {
            imageUsage
            url
            propertyFilter
          }
        }
      }
    }
  }
`;

export const NewProductQuery = gql`
  query WizardSystemSettingsNewProduct($input: GetProductInputType!) {
    product {
      product(input: $input) {
        properties {
          sortNo
          quantity
          defaultValues {
            value
            propertyFilter
          }
          name
          groupName
          visibilityFilter
          validationFilter
          quantityConversionParams
          values {
            sortNo
            image
            value
            validationFilter
            rangeFilter
            descriptionTexts {
              id
              propertyFilter
              language
              text
            }
          }
          descriptionTexts {
            id
            propertyFilter
            language
            text
          }
        }
      }
    }
  }
`;

export const moistureLoadQuery = gql`
  query WizardSystemSettingsMoistureLoadQuery(
    $moistureLoadNo: Int!
    $revisionNo: Int!
  ) {
    user {
      moistureLoadByMoistureLoadNo(
        moistureLoadNo: $moistureLoadNo
        revisionNo: $revisionNo
      ) {
        id
        moistureloadFile {
          id
          name
          labels {
            id
            name
          }
        }
      }
    }
  }
`;
