/* tslint:disable */
import { exhaustiveCheck } from "ts-exhaustive-check";
import { User, UserManager } from "oidc-client";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import * as Actions from "./oidc-client-action";
import { OidcClientCmd, mapCmd } from "./oidc-client-cmd";
import {
  OidcClientSub,
  ListenUserSession,
  AccessTokenRefreshed,
  mapSub
} from "./oidc-client-sub";
import { home } from "./home";

export function createEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: () => () => undefined,
    onEffects,
    onSelfAction
  };
}

export type SigninStatus =
  | StatusSigninNotStarted
  | StatusRedirectingToSignin
  | StatusSignedIn
  | StatusSignedOut
  | StatusError;

export interface StatusSigninNotStarted {
  readonly type: "StatusSigninNotStarted";
}

export interface StatusRedirectingToSignin {
  readonly type: "StatusRedirectingToSignin";
}

export interface StatusSignedIn {
  readonly type: "StatusSignedIn";
  readonly user: User;
  readonly userManager: UserManager;
  readonly cancelUserManagerSubscription: () => void;
}

export interface StatusSignedOut {
  readonly type: "StatusSignedOut";
}

export interface StatusError {
  readonly type: "StatusError";
  readonly error: Error;
}

export interface OidcClientState<ActionApp> {
  readonly status: SigninStatus;
  readonly userSubs: ReadonlyArray<ListenUserSession<ActionApp>>;
  readonly refreshUserSubs: ReadonlyArray<AccessTokenRefreshed<ActionApp>>;
}

function init<ActionApp>(): OidcClientState<ActionApp> {
  return {
    status: { type: "StatusSigninNotStarted" },
    userSubs: [],
    refreshUserSubs: []
  };
}

export function onEffects<ActionApp>(
  _dispatchApp: Dispatch<ActionApp>,
  dispatchSelf: Dispatch<Actions.Action>,
  cmds: ReadonlyArray<OidcClientCmd<ActionApp>>,
  subs: ReadonlyArray<OidcClientSub<ActionApp>>,
  state: OidcClientState<ActionApp> = init()
): OidcClientState<ActionApp> {
  let newState = state;
  const userSubs: Array<ListenUserSession<ActionApp>> = [];
  const refreshUserSubs: Array<AccessTokenRefreshed<ActionApp>> = [];
  // Handle subs
  for (const sub of subs) {
    switch (sub.type) {
      case "ListenUserSession": {
        userSubs.push(sub);
        break;
      }
      case "AccessTokenRefreshed": {
        refreshUserSubs.push(sub);
        break;
      }
      default:
        exhaustiveCheck(sub);
    }
  }
  // Set the new subs in state
  newState = { ...state, userSubs, refreshUserSubs };
  // Handle commands
  for (const cmd of cmds) {
    switch (cmd.type) {
      case "Login": {
        // Cancel previous user manager subscription if any
        if (
          newState.status.type === "StatusSignedIn" &&
          newState.status.cancelUserManagerSubscription
        ) {
          newState.status.cancelUserManagerSubscription();
        }
        // Get existing user or redirect to identity provider
        const userManager = new UserManager(cmd.settings);
        getUserOrRedirectToSignin(userManager, cmd.redirectState, dispatchSelf);
        break;
      }
      case "ProcessSigninCallback": {
        // Cancel previous user manager subscription if any
        if (
          newState.status.type === "StatusSignedIn" &&
          newState.status.cancelUserManagerSubscription
        ) {
          newState.status.cancelUserManagerSubscription();
        }
        // Process with new user manager
        const userManager = new UserManager(cmd.settings);
        processSigninCallback(userManager, dispatchSelf);
        break;
      }
      case "Logout": {
        // Can only sign out if singed in
        if (newState.status.type === "StatusSignedIn") {
          newState.status.cancelUserManagerSubscription();
          // This effect will close the app since it does a full redirect
          // logout(newState.status.userManager);
          //newState.status.userManager.removeUser(); // removes the user data from sessionStorage
          newState.status.userManager.signoutRedirect(); // redirects the user to identityserver with question "logout from identityserver"
        }
        break;
      }
      default: {
        exhaustiveCheck(cmd, true);
      }
    }
  }
  return newState;
}

export function onSelfAction<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  dispatchSelf: Dispatch<Actions.Action>,
  action: Actions.Action,
  state: OidcClientState<ActionApp> = init()
): OidcClientState<ActionApp> {
  switch (action.type) {
    case "UserSessionEstablished": {
      // Tell our subscribers
      // const appActions: Array<ActionApp> = [];
      for (const sub of state.userSubs) {
        // appActions.push(sub.userChanged(action.user));
        dispatchApp(sub.userChanged(action.user));
      }
      // Set new state and subscribe to user manager changes
      const cancel = startUserManagerSubscription(
        action.userManager,
        dispatchSelf
      );
      return {
        ...state,
        status: {
          type: "StatusSignedIn",
          user: action.user,
          userManager: action.userManager,
          cancelUserManagerSubscription: cancel
        }
      };
    }
    case "RedirectToSigninStarted": {
      return { ...state, status: { type: "StatusRedirectingToSignin" } };
    }
    case "UserLoadedEvent": {
      if (state.status.type !== "StatusSignedIn") {
        return state;
      }
      for (const sub of state.refreshUserSubs) {
        dispatchApp(sub.userChanged(action.payload.user));
      }
      return {
        ...state,
        status: {
          ...state.status,
          user: action.payload.user
        }
      };
    }
    case "UserUnloadedEvent": {
      // Tell our subscribers
      for (const sub of state.userSubs) {
        dispatchApp(sub.userChanged(undefined));
      }
      return {
        ...state,
        status: {
          type: "StatusSignedOut"
        }
      };
    }
    case "AccessTokenExpiringEvent": {
      return state;
    }
    case "AccessTokenExpiredEvent": {
      // Tell our subscribers
      for (const sub of state.userSubs) {
        dispatchApp(sub.userChanged(undefined));
      }
      return { ...state, status: { type: "StatusSignedOut" } };
    }
    case "SilentRenewErrorEvent": {
      // Tell our subscribers
      for (const sub of state.userSubs) {
        dispatchApp(sub.userChanged(undefined));
      }
      return {
        ...state,
        status: { type: "StatusError", error: action.payload.error }
      };
    }
    case "UserSignedOutEvent": {
      // Tell our subscribers
      for (const sub of state.userSubs) {
        dispatchApp(sub.userChanged(undefined));
      }
      return { ...state, status: { type: "StatusSignedOut" } };
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function startUserManagerSubscription(
  userManager: UserManager,
  dispatchSelf: Dispatch<Actions.Action>
): () => void {
  // userLoaded
  const userLoaded = (user: User): void =>
    dispatchSelf(Actions.userLoaded(user));
  userManager.events.addUserLoaded(userLoaded);
  // userUnloaded
  const userUnloaded = (): void => dispatchSelf(Actions.userUnloaded());
  userManager.events.addUserUnloaded(userUnloaded);
  // silentRenewError
  const silentRenewError = (error: Error): void =>
    dispatchSelf(Actions.silentRenewError(error));
  userManager.events.addSilentRenewError(silentRenewError);
  // userSignedOut
  const userSignedOut = (): void => dispatchSelf(Actions.userSignedOut());
  userManager.events.addUserSignedOut(userSignedOut);
  // accessTokenExpiring
  const accessTokenExpiring = (): void =>
    dispatchSelf(Actions.accessTokenExpiring());
  userManager.events.addAccessTokenExpiring(accessTokenExpiring);
  // accessTokenExpired
  const accessTokenExpired = (): void =>
    dispatchSelf(Actions.accessTokenExpired());
  userManager.events.addAccessTokenExpired(accessTokenExpired);

  return (): void => {
    // eslint-disable-next-line no-unused-expressions
    userLoaded && userManager.events.removeUserLoaded(userLoaded);
    // eslint-disable-next-line no-unused-expressions
    userUnloaded && userManager.events.removeUserUnloaded(userUnloaded);
    // eslint-disable-next-line no-unused-expressions
    silentRenewError &&
      userManager.events.removeSilentRenewError(silentRenewError);
    userManager.events.removeUserSignedOut(userSignedOut);
    userManager.events.removeAccessTokenExpiring(accessTokenExpiring);
    userManager.events.removeAccessTokenExpired(accessTokenExpired);
  };
}

export function createOidcEffectManager(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub,
    setup: () => () => undefined,
    onEffects,
    onSelfAction
  };
}

/**
 * This command will check for an existing user session (stored in session storage)
 * If an existing session is found, it will set that user in state by triggering the
 * GetUserSuccess action (since no UserManagerEvent is triggered we need an explicit action)
 * If no existing session is found, it will redirect to the signin site by calling
 * UserManager, and it will also send the  RedirectToSigninStarted action so that
 * the state can reflect that we are in redirect mode.
 */
async function getUserOrRedirectToSignin(
  userManager: UserManager,
  redirectState: {},
  dispatchSelf: Dispatch<Actions.Action>
): Promise<void> {
  const user = await userManager.getUser();
  // If no user session was found or it was expired, then redirect to signin site
  if (!user || user.expired) {
    // Use args to store originally requested url so we can redirect to it later
    const extraQueryParams = getExtraQueryParams();
    userManager.signinRedirect({ extraQueryParams, state: redirectState });
    dispatchSelf(Actions.redirectToSigninStarted());
  } else {
    // If we find an existing user session, the user object will not have state becuase
    // the state is only set during redirectToSignin/Callback
    dispatchSelf(Actions.userSessionEstablished(user, userManager));
  }
}

// https://github.com/IdentityModel/oidc-client-js/issues/315#issuecomment-386128359
// Allows to bypass default keycloak idp to normal user login with username and password
function getExtraQueryParams(): { readonly kc_idp_hint: string } | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  const kcIdpHint = urlParams.get("kc_idp_hint");
  if (kcIdpHint) {
    return { kc_idp_hint: kcIdpHint };
  }

  return undefined;
}

/**
 * This command will process the callback to the application by the signin server.
 * The callback has the token in the url fragment, it will be processed by
 * the UserManager which in turn will raise a UserLoaded event. So there
 * is no need to have any actions raised for this effect, instead we handle
 * the UserLoaded event via our UserManager subscription.
 */
async function processSigninCallback(
  userManager: UserManager,
  // onSuccess: (redirectUrl: string | undefined) => AppAction,
  // dispatchApp: Dispatch<AppAction>,
  dispatchSelf: Dispatch<Actions.Action>
): Promise<void> {
  const user = await userManager.signinRedirectCallback();
  dispatchSelf(Actions.userSessionEstablished(user, userManager));

  // The user object returned from processing signin callback will have a state property
  // for the state that was passed into userManager.signinRedirect({state: ...}) and
  // in this state we store the URL to go to after login callback has been processed
  // const redirectUrl = user.state && user.state.redirectUrl;
  // dispatchApp(onSuccess(redirectUrl));
}
