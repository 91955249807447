import * as React from "react";

interface Props {
  readonly data: string;
  readonly fileName: string;
  readonly children: (onClick: () => void) => JSX.Element;
}

interface State {
  readonly url: string | undefined;
  readonly blob: Blob | undefined;
}

export class Downloader extends React.Component<Props, State> {
  anchorElement: HTMLAnchorElement | null;

  constructor(props: Props) {
    super(props);

    const blob = createBlob(props.data);
    const newUrl = blob ? createUrl(blob) : undefined;
    this.state = {
      url: newUrl,
      blob: blob
    };
  }

  componentWillReceiveProps(nextProps: Readonly<Props>) {
    const url = this.state.url;
    if (url !== undefined) {
      URL.revokeObjectURL(url);
    }

    const blob = createBlob(nextProps.data);
    const newUrl = blob ? createUrl(blob) : undefined;
    this.setState(() => ({
      url: newUrl,
      blob: blob
    }));
  }

  componentDidUpdate() {
    if (!this.props.data) {
      this.download();
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <a
          style={{ display: "none" }}
          ref={el => {
            this.anchorElement = el;
          }}
          href={this.state.url}
          download={this.props.fileName}
        />
        {this.props.children(() => {
          this.download();
        })}
      </div>
    );
  }

  download = () => {
    if (!!(window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(
        this.state.blob,
        this.props.fileName
      );
      return;
    }

    if (this.anchorElement) {
      this.anchorElement.click();
    }
  };
}

export function createUrl(blob: Blob): string {
  return URL.createObjectURL(blob);
}

export function createBlob(data: string): Blob | undefined {
  if (data === undefined) {
    return undefined;
  }
  const blob = new Blob([data]);
  return blob;
}
