import * as React from "react";
import { Quantity } from "@genesys/uom";

import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";

export interface Props {
  readonly selectedQuantity: Quantity.Quantity;
  readonly onChange: (quantity: Quantity.Quantity) => void;
  readonly className?: string;
}

// tslint:disable-next-line:function-name
export function Component({
  onChange,
  selectedQuantity,
  className
}: Props): JSX.Element {
  const convertableToQuantities = QuantityConversion.getQuantitiesToConvertTo(
    selectedQuantity
  );

  return (
    <div className={className}>
      <select
        value={selectedQuantity}
        onChange={evt => onChange(evt.target.value as Quantity.Quantity)}
      >
        {convertableToQuantities.map(q => (
          <option key={q}>{q}</option>
        ))}
      </select>
    </div>
  );
}
