import * as React from "react";
import { Unit, Units } from "@genesys/uom";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";

export interface AmountFieldProfile {
  readonly id: string;
  readonly name: string;
  readonly measureSystem: number;
  readonly isEditable: boolean;
}

export type SaveAmountFieldFormat = (
  fieldGroup: string,
  fieldName: string,
  unit: Unit.Unit<any>,
  decimalCount: number
) => void;

export type DeleteAmountFieldFormat = (
  fieldGroup: string,
  fieldName: string
) => void;

export type UpdateAmountFieldProfile = (
  id: string,
  name: string,
  measureSystem: number
) => void;

export type SetActiveAmountFieldProfile = (id: string) => void;

// tslint:disable-next-line:variable-name
export interface ScreenAmountProfileContextValue {
  readonly measureSystem: number;
  readonly selectedAmountProfileId: string;
  readonly quantityDefaults: ScreenAmounts.QuantityDefaultsMap;
  readonly fieldDefaults: ScreenAmounts.FieldDefaultsMap;
  readonly userAmountFields: ScreenAmounts.UserAmountFieldsMap;
  readonly getPropertyFormats: ScreenAmounts.GetPropertyFormats;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly saveAmountFieldFormat: SaveAmountFieldFormat;
  readonly deleteAmountFieldFormat: DeleteAmountFieldFormat;
  readonly amountFieldProfiles: ReadonlyArray<AmountFieldProfile>;
  readonly updateAmountFieldProfile: UpdateAmountFieldProfile;
  readonly setActiveAmountFieldProfile: SetActiveAmountFieldProfile;
}

// tslint:disable-next-line:variable-name
export const ScreenAmountProfileContext =
  React.createContext<ScreenAmountProfileContextValue>({
    measureSystem: 1,
    selectedAmountProfileId: ScreenAmounts.SIProfileId,
    amountFieldProfiles: [],
    quantityDefaults: {},
    fieldDefaults: {},
    userAmountFields: {},
    getPropertyFormats: () => ({}),
    getAmountFormat: () => ({
      decimalCount: 2,
      unit: Units.Celsius,
      userDefined: false
    }),
    saveAmountFieldFormat: () => {
      /*  */
    },
    deleteAmountFieldFormat: () => {
      /*  */
    },
    updateAmountFieldProfile: () => {
      /*  */
    },
    setActiveAmountFieldProfile: () => {
      /*  */
    }
  });
