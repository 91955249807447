import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import {
  ResultSummaryProduct,
  FanChartView,
  PerParams,
  SoundView
} from "./visualizers";
import { CaseResult } from "./shared-tools";
import { OperatingCasesSelect, S1 } from "@genesys/ui-elements";
import { ProductImagePerfParamsView } from "./visualizers/";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import styled from "styled-components";
import * as GraphQlTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { knownSoundResultKeys } from "./shared-tools";

export interface ScreenResultSummaryCaseOrderRow {
  readonly calculationType: string;
}

interface ScreenRow {
  readonly id: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}

const Container = styled.div`
  > div {
    margin-bottom: 15px;
  }
`;

const OperatingCasesContainer = styled.div`
  display: flex;
`;

export function ResultSummary({
  sharedState,
  screenResultSummary,
  component,
  results,
  productData,
  dispatch,
  caseResult,
  resultConversionParametersMapByCaseResultId,
  effectiveSelectedCaseResultId
}: {
  readonly component: System.Component;
  readonly results: ReadonlyArray<CaseResult>;
  readonly caseResult: CaseResult;
  readonly sharedState: SharedState.State;
  readonly screenResultSummary: GraphQlTypes.ResultSummmaryQuery["product"]["product"]["screen_ResultSummary"];
  readonly effectiveSelectedCaseResultId: string | undefined;
  readonly productData: ResultSummaryProduct;
  readonly resultConversionParametersMapByCaseResultId: {
    readonly [caseResultId: string]: {
      readonly [key: string]:
        | QuantityConversion.ConversionParameters
        | undefined;
    };
  };
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const screenRows = (screenResultSummary?.rows || []).map(r => {
    const values = PropertyValueSet.fromStringOrError(
      () => PropertyValueSet.Empty,
      r.values
    );
    const row: ScreenRow = {
      id: r.id,
      groupName: PropertyValueSet.getText("GroupName", values),
      name: PropertyValueSet.getText("Name", values),
      claimFilter: PropertyValueSet.getText("ClaimFilter", values),
      propertyFilter: r.propertyFilter
    };
    return row;
  });

  const soundRows: Array<ScreenRow> = [];
  const rest: Array<ScreenRow> = [];

  const selectedProperties = component.properties;
  const applicationClaims = sharedState.user.applicationClaims;

  for (const sr of screenRows) {
    const resultKey = sr.name || "";
    const propName = resultKey.substr(resultKey.indexOf("_") + 1);
    const isSoundResult = knownSoundResultKeys.has(propName);

    const isValid =
      sharedState.debugSettings.showHiddenResults ||
      (PropertyFilterHelpers.isValid(
        sr.propertyFilter,
        PropertyFilter.Empty,
        selectedProperties
      ) &&
        Authorization.checkClaimFilter(
          applicationClaims,
          PropertyFilterHelpers.createPropertyFilter(
            sr.claimFilter,
            PropertyFilter.Empty
          )
        ));

    if (isValid) {
      if (isSoundResult) {
        soundRows.push(sr);
      } else {
        rest.push(sr);
      }
    }
  }

  return (
    <Container>
      <OperatingCasesContainer>
        <OperatingCasesSelect
          disableBodyScrollLock={true}
          value={effectiveSelectedCaseResultId}
          options={results.map(opc => ({
            value: opc.id,
            title: opc.displayName
          }))}
          onChange={e => {
            dispatch(Action.selectCaseResultId(e.target.value));
          }}
        />
      </OperatingCasesContainer>
      <div>
        <S1>{caseResult.displayName}</S1>
      </div>
      <ProductImagePerfParamsView
        systemTypeId={component.productId.substring(0, 3)}
        resultConversionParametersMap={
          resultConversionParametersMapByCaseResultId[caseResult.id]
        }
        dispatch={dispatch}
        caseResult={caseResult.results}
        sharedState={sharedState}
        productData={productData}
        selectedProperties={component.properties}
      />
      <FanChartView
        sharedState={sharedState}
        casResultId={caseResult.id}
        language={sharedState.user.settings.language}
      />
      <PerParams
        dispatch={dispatch}
        conversionParametersMap={
          resultConversionParametersMapByCaseResultId[caseResult.id]
        }
        productId={productData.id}
        validRows={rest}
        caseResult={caseResult.results}
        sharedState={sharedState}
        selectedProperties={component.properties}
      />
      <SoundView
        results={caseResult.results}
        sharedState={sharedState}
        soundRows={soundRows}
      />
    </Container>
  );
}
