import * as React from "react";
import { isDesktop } from "react-device-detect";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { State, Action } from "../../state";
import { Dispatch } from "@typescript-tea/core";
import {
  SidebarLabelsComponent,
  BodyContainer
} from "../../../shared-manager-components";
import { SearchParams } from "../../types";
import { ResultTableView } from "./result-table";

export function MainContentView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const setSearchKeyAndSearch = (searchKey: string) => {
    const searchParams: SearchParams = {
      ...state.searchParams,
      searchKey: searchKey
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search());
  };

  return (
    <BodyContainer>
      {isDesktop && (
        <SidebarLabelsComponent
          labels={sharedState.user.labels}
          openLabelPopup={state.openLabelPopup}
          labelInputState={state.labelInputState}
          toggleOpenLabelPopup={labelId =>
            dispatch(Action.toggleOpenLabelPopup(labelId))
          }
          onUpdateLabel={(labelId, labelName) =>
            dispatch(Action.updateLabel(labelId, labelName))
          }
          setLabelInputState={labelInputState =>
            dispatch(Action.setLabelInputState(labelInputState))
          }
          onCreateLabel={labelName => dispatch(Action.createLabel(labelName))}
          deleteLabel={(labelId, labelName) =>
            dispatch(
              Action.openModal({
                content: sharedState.translate(
                  LanguageTexts.deleteLabelMsg(labelName)
                ),
                header: sharedState.translate(LanguageTexts.confirmation()),
                onClose: () => dispatch(Action.closeModal()),
                onConfirm: () => {
                  dispatch(Action.deleteLabel(labelId));
                  dispatch(Action.closeModal());
                },
                label: { id: labelId, name: labelName }
              })
            )
          }
          sharedState={sharedState}
          searchKey={state.searchParams.searchKey}
          onLabelSelected={searchKey => setSearchKeyAndSearch(searchKey)}
        />
      )}
      <ResultTableView
        dispatch={dispatch}
        sharedState={sharedState}
        state={state}
      />
    </BodyContainer>
  );
}
