import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { BaseState } from "../../state";
import { SystemOverrides } from "../../tools";
import * as SharedState from "../../../shared-state";
import * as OperationTimeGen2 from "../../../operation-time-manager";
import * as SystemOverride from "../../components/system-overrides";
import * as SystemSettings from "../../tools/moisture-load-rules/system-settings";

export type State = BaseState & {
  readonly isOpenAdditionalBuilding: boolean;
  readonly operationTimeState: OperationTimeGen2.State;
  readonly systemOverridesState: SystemOverride.State;
};

export const init = (
  baseState: BaseState,
  initalSystemOperatonTime: OperationTimeGen2.OperationTime
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      ...baseState,
      isOpenAdditionalBuilding: false,
      operationTimeState: OperationTimeGen2.init(initalSystemOperatonTime)[0],
      systemOverridesState: SystemOverride.init(baseState)[0]
    }
  ];
};

export const Action = ctorsUnion({
  dispatchOperationTime: (action: OperationTimeGen2.Action) => ({
    action
  }),
  dispatchSystemOverride: (action: SystemOverride.Action) => ({ action }),
  toggleAdditionalBuilding: () => ({})
});

export type Action = CtorsUnion<typeof Action>;
type ResultsInvalid = boolean;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  currentSystemOverrides: SystemOverrides
): readonly [State, SystemOverrides, Cmd<Action>?, ResultsInvalid?] {
  switch (action.type) {
    case "dispatchOperationTime": {
      if (state.operationTimeState === undefined) {
        return [state, currentSystemOverrides];
      }

      const [operationTimeState] = OperationTimeGen2.update(
        action.action,
        state.operationTimeState
      );
      return [
        {
          ...state,
          operationTimeState
        },
        currentSystemOverrides,
        undefined,
        true
      ];
    }

    case "dispatchSystemOverride": {
      const [systemOverridesState, updatedSystemOverrides] =
        SystemOverride.update(
          action.action,
          state.systemOverridesState,
          sharedState,
          currentSystemOverrides,
          SystemSettings.getPropertyUpdates
        );

      return [
        {
          ...state,
          systemOverridesState: systemOverridesState
        },
        updatedSystemOverrides,
        undefined,
        true
      ];
    }

    case "toggleAdditionalBuilding": {
      return [
        {
          ...state,
          isOpenAdditionalBuilding: !state.isOpenAdditionalBuilding
        },
        currentSystemOverrides
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function reset(
  state: State,
  _resetValues: PropertyValueSet.PropertyValueSet
): [State] {
  return [
    {
      ...state
    }
  ];
}
