import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createArrowUpSvg(color: string): string {
  const svgString = `
    <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
     <title>93793EB6-167F-46D5-8D71-7071577D2F2E</title>
     <defs>
      <polygon points="0 0.125 5 5.125 10 0.125" id="path-1"/>
     </defs>
     <g>
      <title>Layer 1</title>
      <g transform="rotate(-180 5.6399993896484375,2.625) " fill-rule="evenodd" fill="none" id="UI-Kit">
       <g id="UI-Kit---5.Icons-&amp;-Illustrations">
        <g id="Icons/Actions/Dropdown">
         <mask transform="translate(148.63999938964844,1160) translate(-148,-1160) " id="mask-2">
          <use id="svg_1" xlink:href="#path-1"/>
         </mask>
         <use x="0.64" y="0" xlink:href="#path-1" fill="${color}" id="Shape"/>
        </g>
       </g>
      </g>
     </g>
    </svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
