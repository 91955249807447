import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { setSystemPriceInformationMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import { promiseCmd } from "../../../promise-effect-manager";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";
import { tryRaiseSystemStatusToPriceCalculationSuccess } from "../calculate";

export function setSystemPricingInformation(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<
    ConfiguratorAction,
    { readonly type: "setSystemPricingInformation" }
  >
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        await sharedState.graphQL.queryUser<
          GraphQLTypes.SetSystemPriceInformation,
          GraphQLTypes.SetSystemPriceInformationVariables
        >(setSystemPriceInformationMutation, {
          input: {
            systemId: action.systemId,
            currencyCode: action.currencyCode,
            masterCurrencyCode: action.masterCurrencyCode,
            salesOrganisationId: action.salesOrganisationId,
            masterOrganisationId: action.masterOrganisationId
          }
        });
        if (action.calculate) {
          const traceId = await tryRaiseSystemStatusToPriceCalculationSuccess(
            sharedState.graphQL.queryUser,
            systemId
          );
          return traceId;
        }
        return undefined;
      },
      traceId =>
        action.calculate
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculate
      ? [
          SystemStatus.PriceCalculationSuccess,
          SystemStatus.ValidateConfigurationSuccess
        ]
      : [SystemStatus.ValidateConfigurationSuccess]
  ];
}
