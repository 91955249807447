import * as GraphQlTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import * as System from "../../system";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValue } from "@genesys/property";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Opc, CondensationAnalysisForOpc } from "./types";
import { Quantity, Unit } from "@genesys/uom";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";

export const query = gql`
  query CondensationAnalysis($systemId: ID!) {
    user {
      system(id: $systemId) {
        operatingCases {
          id
          caseName
          customCaseName
          caseType
          settings
          sortNo
          condensationAnalysisForOpc {
            opcId
            dewPointTemperature
            surfaceTempsForAirPoint {
              temp
              riskLevel
              airPoint {
                label
                airFlowState {
                  flow
                  volumeFlow
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type State = {
  readonly data: ReadonlyArray<Opc> | undefined;
  readonly errorStatus: ErrorStatus;
};

export type ErrorStatus = "none" | "not-calulated" | "ope-not-calcualted";

export const init = (
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?] => {
  const errorStatus = getErrorStatus(system);
  return [
    { data: undefined, errorStatus: errorStatus },
    errorStatus === "none"
      ? sharedState.graphQL.queryUserCmd<
          GraphQlTypes.CondensationAnalysis,
          GraphQlTypes.CondensationAnalysisVariables,
          Action
        >(
          query,
          {
            systemId: system.id
          },
          Action.initialQueryRecieved
        )
      : undefined
  ];
};

export const Action = ctorsUnion({
  initialQueryRecieved: (data: GraphQlTypes.CondensationAnalysis) => ({ data }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "initialQueryRecieved": {
      const parsedData = action.data.user.system.operatingCases.map(parseOpc);
      return [{ ...state, data: parsedData }];
    }

    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function parseOpc(
  rawOpc: GraphQlTypes.CondensationAnalysis["user"]["system"]["operatingCases"][0]
): Opc {
  return {
    id: rawOpc.id,
    caseName: rawOpc.caseName,
    customCaseName: rawOpc.customCaseName,
    caseType: rawOpc.caseType,
    sortNo: rawOpc.sortNo,
    condensationAnalysisForOpc: parseCondensationAnalysis(
      rawOpc.condensationAnalysisForOpc
    )
  };
}
function parseCondensationAnalysis(
  con: GraphQlTypes.CondensationAnalysis["user"]["system"]["operatingCases"][0]["condensationAnalysisForOpc"]
): CondensationAnalysisForOpc | undefined {
  if (!con) {
    return undefined;
  }
  return {
    opcId: con.opcId,
    dewPointTemperature: PropertyValue.fromString(con.dewPointTemperature)!,
    surfaceTempsForAirPoint: con.surfaceTempsForAirPoint!.map(x => ({
      riskLevel: x.riskLevel,
      temp: PropertyValue.fromString(x.temp)!,
      airPoint: {
        label: x.airPoint.label,
        airFlowState: {
          flow: PropertyValue.fromString(x.airPoint.airFlowState.flow)!,
          volumeFlow: PropertyValue.fromString(
            x.airPoint.airFlowState.volumeFlow
          )!
        }
      }
    }))
  };
}

function getErrorStatus(system: System.System): ErrorStatus {
  const status = system.status;

  if (status < SystemStatus.PriceCalculationSuccess) {
    return "not-calulated";
  }

  const calculateEnergy = system.operatingCases.some(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );

  if (calculateEnergy) {
    const ope = system.operatingCases.filter(
      x => x.caseType === CaseTypeEnum.CaseType.Bin
    );

    const opeIsCaluclated = ope.every(x => x.results.length > 0);

    if (!opeIsCaluclated) {
      return "ope-not-calcualted";
    }
  }

  return "none";
}
