import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import {
  SystemActionCreatorProps,
  SystemActionRendererProps,
  SystemAction,
  ActionType,
  SystemFile
} from "./types";
import {
  renderRenameContent,
  editCommentContent,
  assignLabelsContent,
  createRevisionContent,
  confirmDeleteContent,
  createPricingContent,
  copyTransferContent,
  transferContent
} from "./system-actions-renderer";

interface ActionDefinition {
  readonly type: ActionType;
  readonly labelKey: LanguageTexts.TextDefinition;
  readonly isDisabled?: (props: {
    readonly systemStatus: number;
    readonly showRecoverSystemFile?: boolean;
  }) => boolean;
  readonly onClick: (props: {
    readonly dispatch: Dispatch<Action>;
    readonly systemId: string;
    readonly systemFile: SystemFile;
    readonly sharedState: SharedState.State;
  }) => void;
  readonly renderer?: (props: SystemActionRendererProps) => JSX.Element | null;
}

export function createActions(
  props: SystemActionCreatorProps,
  actionDefs: ReadonlyArray<ActionDefinition>
): ReadonlyArray<SystemAction> {
  return actionDefs.map(actionDef => createAction(props, actionDef));
}

export const openInNewTabAction: ActionDefinition = {
  type: "open-in-new-tab",
  labelKey: LanguageTexts.openInNewTab(),
  isDisabled: ({ showRecoverSystemFile }) => !!showRecoverSystemFile,
  onClick: ({ systemFile, systemId, sharedState }) => {
    const systemInfo = systemFile.systems.find(s => s.id === systemId)!;
    const revisonNumber = systemInfo.revisionNo;
    const urlForSystem = `/system/${sharedState.genesysPrefix.genesysNo(
      systemFile.genesysNo,
      revisonNumber
    )}`;

    window.open(urlForSystem);
  }
};

export const saveAndCalculateAction: ActionDefinition = {
  type: "save-and-calculate",
  labelKey: LanguageTexts.saveAndCalculate(),
  isDisabled: ({ systemStatus }) =>
    systemStatus >= SystemStatusEnum.SystemStatus.LockSuccess,
  onClick: props => {
    const { dispatch, systemId } = props;
    dispatch(Action.saveAndCalculate(systemId));
  }
};

export const lockRevisionAction: ActionDefinition = {
  type: "lock-revision",
  labelKey: LanguageTexts.lockRevision(),
  isDisabled: ({ systemStatus }) =>
    systemStatus >= SystemStatusEnum.SystemStatus.LockSuccess,
  onClick: props => {
    const { dispatch, systemId } = props;
    dispatch(Action.lockRevision(systemId));
  }
};

export const renameAction: ActionDefinition = {
  type: "rename",
  labelKey: LanguageTexts.rename(),
  onClick: props => {
    const { dispatch, systemFile } = props;
    dispatch(Action.setInput(systemFile.name));
    dispatch(Action.setMode("rename"));
  },
  renderer: props => renderRenameContent(props)
};

export const editCommentAction: ActionDefinition = {
  type: "edit-comment",
  labelKey: LanguageTexts.editComment(),
  onClick: props => {
    const { dispatch, systemId, systemFile } = props;
    dispatch(
      Action.setInput(
        systemFile.systems.find(s => s.id === systemId)?.comment || ""
      )
    );
    dispatch(Action.setMode("edit-comment"));
  },
  renderer: editCommentContent
};

export const assignLabelsAction: ActionDefinition = {
  type: "assign-labels",
  labelKey: LanguageTexts.assignlabels(),
  onClick: ({ dispatch, systemFile }) => {
    dispatch(Action.initLabelManager(systemFile.labels));
    dispatch(Action.setMode("assign-labels"));
  },
  renderer: assignLabelsContent
};

export const deleteSystemAction: ActionDefinition = {
  type: "confirm-delete",
  labelKey: LanguageTexts.deleteText(),
  onClick: ({ dispatch }) => {
    dispatch(Action.setMode("confirm-delete"));
  },
  renderer: confirmDeleteContent
};

export const createNewRevisionAction: ActionDefinition = {
  type: "create-revision",
  labelKey: LanguageTexts.createnewrevision(),
  onClick: ({ dispatch }) => {
    dispatch(Action.setInput(""));
    dispatch(Action.setMode("create-revision"));
  },
  renderer: createRevisionContent
};

export const transferToUserAction: ActionDefinition = {
  type: "transfer",
  labelKey: LanguageTexts.transfertouser(),
  onClick: ({ dispatch, systemFile }) => {
    dispatch(Action.setInput(systemFile.owner));
    dispatch(Action.setMode("transfer"));
  },
  renderer: transferContent
};

export const copyTransferOpenAction: ActionDefinition = {
  type: "copy-transfer",
  labelKey: LanguageTexts.copyTransferOpen(),
  onClick: ({ dispatch, systemFile }) => {
    dispatch(Action.setInput("Copy of " + systemFile.name));
    dispatch(Action.setMode("copy-transfer"));
  },
  renderer: copyTransferContent
};

export const createPricingAction: ActionDefinition = {
  type: "create-pricing",
  labelKey: LanguageTexts.createPricingAndAdd(),
  onClick: ({ dispatch }) => {
    dispatch(Action.initPricingWizard());
    dispatch(Action.setMode("create-pricing"));
  },
  renderer: createPricingContent
};

export const recoverSystemAction: ActionDefinition = {
  type: "recover-system",
  labelKey: LanguageTexts.recoverSystem(),
  onClick: ({ dispatch, systemFile }) => {
    dispatch(Action.recoverSystemFile(systemFile.id));
  }
};

export const exportSystemAction: ActionDefinition = {
  type: "export-system",
  labelKey: LanguageTexts.exportText(),
  onClick: ({ dispatch, systemFile, systemId, sharedState }) => {
    const systemInfo = systemFile.systems.find(s => s.id === systemId)!;
    const revisonNumber = systemInfo.revisionNo;
    const fileName =
      sharedState.genesysPrefix.genesysNo(systemFile.genesysNo, revisonNumber) +
      ".json";

    dispatch(Action.fetchSystemModel(systemId, fileName));
  }
};

function createAction(
  props: SystemActionCreatorProps,
  config: ActionDefinition
): SystemAction {
  const { sharedState, systemFile, showRecoverSystemFile, dispatch } = props;

  const systemId = getSystemId(props);
  const systemInfo = systemFile.systems.find(s => s.id === systemId)!;
  const systemStatus = systemInfo.status;

  return {
    type: config.type,
    label: sharedState.translate(config.labelKey),
    isDisabled: config.isDisabled
      ? config.isDisabled({
          systemStatus,
          showRecoverSystemFile
        })
      : false,

    onClick: () => {
      config.onClick({
        dispatch,
        systemId,
        systemFile,
        sharedState
      });
    },
    renderer: config.renderer
      ? () =>
          config.renderer!({
            ...props,
            systemId,
            systemStatus
          })
      : undefined
  };
}

function getSystemId(props: SystemActionCreatorProps): string {
  const { revisionId, systemFile } = props;
  const latestRevision = systemFile.systems.reduce(
    (a, b) => (b.revisionNo > a.revisionNo ? b : a),
    systemFile.systems[0]
  );

  return revisionId ? revisionId : latestRevision.id;
}
