import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import { Spinner, P1, InfoWhite, H3 } from "@genesys/ui-elements";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import { State, Action } from "./state";
import { AmountPropertySelector } from "../../../../amount-property-selector";
import {
  ErrorContainer,
  Root,
  InformationContianer,
  SelectorsContainer,
  LeftSideContainer,
  CostTypeTable,
  NarrowTd,
  WideTd
} from "./elements";
import { getAmountPropertySelectorOptions } from "./functions";

export function CostSelectionView({
  state,
  sharedState,
  dispatch,
  system
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly system: System.System;
}) {
  if (!state.costSettings) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const currency = sharedState.user.currencies.find(
    c => c.id === system.currencyCode
  );

  const currencySymbol = currency
    ? currency.signBefore || currency.signAfter!
    : undefined;

  if (currencySymbol === undefined) {
    return (
      <Root>
        <ErrorContainer>
          <p>Unable to show cost details, problem with currency</p>
        </ErrorContainer>
      </Root>
    );
  }

  const rightSideProperties = [
    SharedEnergyTools.maintenanceCost,
    SharedEnergyTools.maintenanceCostPriceInc,
    SharedEnergyTools.disposalCost,
    SharedEnergyTools.lccYears,
    SharedEnergyTools.interest,
    SharedEnergyTools.systemPrice
  ];

  const leftSideItems = state.energyItems.filter(
    i => !rightSideProperties.includes(i.name.toLowerCase())
  );

  const electricityItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("electricity")
  );
  const gasItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("gas")
  );
  const coolingItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("coolingwater")
  );
  const heatingItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("heatingwater")
  );
  const steamItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("steam")
  );
  const humidifierItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("humidifier")
  );
  const waterItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("water")
  );
  const sewageItems = leftSideItems.filter(i =>
    i.name.toLowerCase().startsWith("sewage")
  );

  const rightSideItems = state.energyItems.filter(i =>
    rightSideProperties.includes(i.name.toLowerCase())
  );

  const CostTypeGroup = (
    title: string,
    items: ReadonlyArray<SharedEnergyTools.EnergyItem>,
    dontSeperatePriceIncrease?: boolean
  ) => {
    if (items.filter(i => i.shouldBeVisible).length === 0) {
      return <></>;
    }

    const getPropertyProps = (item: SharedEnergyTools.EnergyItem) =>
      getAmountPropertySelectorOptions(
        dispatch,
        sharedState,
        item,
        state.costSettings!,
        system.status >= SystemStatus.LockSuccess || item.readonly === true
      );

    const priceIncreaseItem = items.find(i =>
      i.name.toLowerCase().endsWith(KnownProperties.priceInc)
    )!;
    const otherItems = items.filter(
      i =>
        (!i.name.toLowerCase().endsWith(KnownProperties.priceInc) ||
          dontSeperatePriceIncrease) &&
        i.shouldBeVisible
    );

    return (
      <CostTypeTable>
        <tbody>
          <tr>
            <WideTd>
              <H3 color="dark" weight="normal">
                {title}
              </H3>
            </WideTd>
          </tr>
          {otherItems.map((item, i) => {
            return (
              <tr>
                <WideTd key={item.name}>
                  {sharedState.translate(LanguageTexts.dynamicText(item.name))}
                </WideTd>
                <NarrowTd>
                  <AmountPropertySelector
                    key={i + item.name}
                    extraLabel={item.cost ? currencySymbol : ""}
                    {...getPropertyProps(item)}
                  />
                </NarrowTd>

                {!dontSeperatePriceIncrease && i === otherItems.length - 1 && (
                  <WideTd key={priceIncreaseItem.name}>
                    {sharedState.translate(
                      LanguageTexts.dynamicText(priceIncreaseItem.name)
                    )}
                  </WideTd>
                )}

                {!dontSeperatePriceIncrease && i === otherItems.length - 1 && (
                  <NarrowTd>
                    <AmountPropertySelector
                      key={i + priceIncreaseItem.name}
                      extraLabel={priceIncreaseItem.cost ? currencySymbol : ""}
                      {...getPropertyProps(priceIncreaseItem)}
                    />
                  </NarrowTd>
                )}
              </tr>
            );
          })}
        </tbody>
      </CostTypeTable>
    );
  };

  return (
    <Root>
      <InformationContianer>
        <InfoWhite />
        <P1 color="white" weight="normal">
          {sharedState.translate(LanguageTexts.costAndEmissionsInformation())}
        </P1>
      </InformationContianer>
      <SelectorsContainer>
        <LeftSideContainer>
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.electricity()),
            electricityItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.gasHeaters()),
            gasItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.chilledWaterSystem()),
            coolingItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.hotWaterSystem()),
            heatingItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.steamHeatSystem()),
            steamItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.humidifier()),
            humidifierItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.water()),
            waterItems
          )}
          {CostTypeGroup(
            sharedState.translate(LanguageTexts.sewage()),
            sewageItems
          )}
        </LeftSideContainer>

        {CostTypeGroup("", rightSideItems, true)}
      </SelectorsContainer>
    </Root>
  );
}
