import { CheckboxPropertyOption, CheckboxGroup } from "../types";

/*
TODO: languagetexts för groupName
*/

export const checkboxGroups: ReadonlyArray<CheckboxGroup> = [
  {
    groupName: "React Heater",
    propertyName: "reactheatertype"
  },
  {
    groupName: "Pre React Heater",
    propertyName: "prereactheater"
  }
];

export const checkboxPropertyOptions: ReadonlyArray<CheckboxPropertyOption> = [
  {
    type: "reactheater-electric",
    isSelected: true,
    propertyName: "reactheatertype",
    value: 1,
    displayName: "Electric"
  },
  {
    type: "reactheater-gas",
    isSelected: false,
    propertyName: "reactheatertype",
    value: 2,
    displayName: "Gas"
  },
  {
    type: "reactheater-steam",
    isSelected: false,
    propertyName: "reactheatertype",
    value: 3,
    displayName: "Steam"
  },
  {
    type: "prereactheater-none",
    isSelected: true,
    propertyName: "prereactheater",
    value: 0,
    displayName: "None"
  },
  {
    type: "prereactheater-fluid",
    isSelected: false,
    propertyName: "prereactheater",
    value: 1,
    displayName: "Fluid"
  }
];
