import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { BinaryToggle, S1 } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as System from "../../system";
import * as CostSelection from "./cost-selection";
import * as EmissionSelection from "./emissions-selection";
import { Root, Navigation, Tab, ToggleContainer } from "./elements";
import * as BinGeneration from "./bin-generation";
import { hasSettingsChanged } from "./functions";
import { State, Action } from "./state";
import * as Result from "./results";
import { TabType } from "./types";

export function EnergyView({
  state,
  sharedState,
  dispatch,
  system
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly system: System.System;
}) {
  const handleTabClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    tabType: TabType
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (state.activeTab !== tabType) {
      dispatch(Action.setActiveTab(tabType));
    }
  };

  const isSystemLocked = system.status >= SystemStatus.SystemStatus.LockSuccess;

  return (
    <Root>
      {isSystemLocked ? (
        <S1>
          {sharedState.translate(
            LanguageTexts.systemIsLockedCantCalculateEnergy()
          )}
        </S1>
      ) : (
        <ToggleContainer>
          <S1>
            {sharedState.translate(
              LanguageTexts.calculateEnergyForThisSystem()
            )}
          </S1>
          <BinaryToggle
            disabled={isSystemLocked}
            isOn={state.shouldCalculateEnergy}
            onClick={() => dispatch(Action.toggleCalculateEnergy())}
          />
        </ToggleContainer>
      )}

      {state.shouldCalculateEnergy && (
        <>
          <Navigation>
            <Tab
              onClick={event => handleTabClick(event, "bin-generation")}
              isActive={state.activeTab === "bin-generation"}
            >
              {sharedState.translate(LanguageTexts.binCases())}
            </Tab>
            <Tab
              onClick={event => handleTabClick(event, "bin-editor")}
              isActive={state.activeTab === "bin-editor"}
            >
              {sharedState.translate(LanguageTexts.operatingCases())}
            </Tab>
            <Tab
              onClick={event => handleTabClick(event, "cost")}
              isActive={state.activeTab === "cost"}
            >
              {sharedState.translate(LanguageTexts.cost())}
            </Tab>
            <Tab
              onClick={event => handleTabClick(event, "emissions")}
              isActive={state.activeTab === "emissions"}
            >
              {sharedState.translate(LanguageTexts.emissions())}
            </Tab>
            <Tab
              onClick={event => handleTabClick(event, "results")}
              isActive={state.activeTab === "results"}
            >
              {sharedState.translate(LanguageTexts.results())}
            </Tab>
          </Navigation>

          {state.activeTab === "bin-generation" &&
            state.binGenerationState &&
            state.locationSelectorState && (
              <BinGeneration.BinGenerationView
                state={state.binGenerationState}
                sharedState={sharedState}
                dispatch={Dispatch.map(Action.dispatchBinGeneration, dispatch)}
                system={system}
                dispatchLocationSelector={Dispatch.map(
                  Action.dispatchLocationSelector,
                  dispatch
                )}
                locationSelectorState={state.locationSelectorState}
              />
            )}

          {state.activeTab === "bin-editor" && state.binGenerationState && (
            <BinGeneration.OperatingCasesEditor
              state={state.binGenerationState}
              sharedState={sharedState}
              dispatch={Dispatch.map(Action.dispatchBinGeneration, dispatch)}
              system={system}
            />
          )}

          {state.activeTab === "cost" &&
            state.costSelectionState &&
            state.productData && (
              <CostSelection.CostSelectionView
                state={state.costSelectionState}
                sharedState={sharedState}
                dispatch={Dispatch.map(Action.dispatchCostSelection, dispatch)}
                system={system}
              />
            )}

          {state.activeTab === "emissions" &&
            state.emissionsSelectionState &&
            state.locationSelectorState &&
            state.productData && (
              <EmissionSelection.EmissionsSelectionView
                state={state.emissionsSelectionState}
                sharedState={sharedState}
                dispatch={Dispatch.map(
                  Action.dispatchEmissionsSelection,
                  dispatch
                )}
                system={system}
                co2EmissionIndexes={
                  state.productData.product.co2EmissionIndexes
                }
                binCountry={
                  state.locationSelectorState.countries[
                    state.locationSelectorState.selectedCountryIndex
                  ].countryName
                }
              />
            )}

          {state.activeTab === "results" && (
            <Result.ResultsView
              system={system}
              dispatch={Dispatch.map(Action.dispatchResult, dispatch)}
              sharedState={sharedState}
              state={
                isSystemLocked || !hasSettingsChanged(state)
                  ? state.resultState
                  : undefined
              }
            />
          )}
        </>
      )}
    </Root>
  );
}
