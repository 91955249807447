import * as React from "react";
import { Action } from "../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { AmountPropertySelector } from "../../../amount-property-selector";
import { PropertyValueSet } from "@genesys/property";
import { Quantity } from "@genesys/uom";
import styled from "styled-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { P2, P1 } from "@genesys/ui-elements";

const Root = styled.div`
  table {
    td {
      color: #959695;
      padding: 4px 8px 0 0;
    }
  }
`;

export function MixResult({
  propertyValueSet,
  sharedState,
  dispatch
}: {
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "temperature",
    propertyValueSet
  )!;

  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "pressure",
    propertyValueSet
  )!;

  const showValue = !PropertyValueSet.isEmpty(propertyValueSet);

  return (
    <Root>
      <table>
        <tbody>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                {sharedState.translate(LanguageTexts.temperature())}
              </P2>
            </td>
            <td>
              {showValue && (
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="Temperature"
                  fieldGroup="AirMixer"
                  fieldName={"temperature"}
                  readOnly={true}
                  propertyName={"temperature"}
                  propertyValueSet={propertyValueSet}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "AirMixer",
                        "temperature",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(Action.onFormatCleared("AirMixer", "temperature"))
                  }
                  // tslint:disable-next-line
                  onValueChange={() => {}}
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              )}
            </td>
          </tr>

          <tr>
            <td>
              <P2 weight="normal" color="dark">
                {sharedState.translate(LanguageTexts.humidity())}
              </P2>
            </td>
            <td>
              {showValue && (
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="HumidityRatio"
                  fieldGroup="AirMixer"
                  fieldName={"humidity"}
                  readOnly={true}
                  propertyName={"humidity"}
                  propertyValueSet={propertyValueSet}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "AirMixer",
                        "humidity",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(Action.onFormatCleared("AirMixer", "humidity"))
                  }
                  // tslint:disable-next-line
                  onValueChange={() => {}}
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                  conversionParameters={{
                    temperature: temperature,
                    pressure: pressure
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                Pressure
              </P2>
            </td>
            <td>
              {showValue && (
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="Pressure"
                  fieldGroup="AirMixer"
                  fieldName={"pressure"}
                  readOnly={true}
                  propertyName={"pressure"}
                  propertyValueSet={propertyValueSet}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "AirMixer",
                        "pressure",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(Action.onFormatCleared("AirMixer", "pressure"))
                  }
                  // tslint:disable-next-line
                  onValueChange={() => {}}
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                Flow
              </P2>
            </td>
            <td>
              {showValue && (
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  quantity="MassFlow"
                  fieldGroup="AirMixer"
                  fieldName={"flow"}
                  readOnly={true}
                  propertyName={"flow"}
                  propertyValueSet={propertyValueSet}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        "AirMixer",
                        "flow",
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(Action.onFormatCleared("AirMixer", "flow"))
                  }
                  // tslint:disable-next-line
                  onValueChange={() => {}}
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <P2 weight="normal" color="dark">
                Condensation
              </P2>
            </td>
            <td>
              {showValue && (
                <P1 weight="normal" color="light">
                  {PropertyValueSet.getInteger(
                    "condensation",
                    propertyValueSet
                  ) === 1
                    ? "Yes"
                    : "No"}
                </P1>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
}
