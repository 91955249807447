import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValue } from "@genesys/property";
import { BinDataLocation } from "../../location-selector";
import { BinListViewType, BinType } from "../types";
import { listFieldGroup } from "./list-field-group";

export function createBinList(
  selectedBinType: BinType,
  selectedBinListView: BinListViewType,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>,
  translate: LanguageTexts.Translate,
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SharedEnergyTools.EnergyResultTable {
  switch (selectedBinType) {
    case "Generated":
    case "Custom": {
      if (selectedBinListView === "standard") {
        return SharedEnergyTools.createStandardBinList(
          binCases,
          translate,
          getAmountFormat,
          listFieldGroup
        );
      }

      return SharedEnergyTools.createBinListWithMonths(
        binCases,
        translate,
        getAmountFormat,
        listFieldGroup
      );
    }
    case "MoistureLoad": {
      if (selectedBinListView === "standard") {
        return SharedEnergyTools.createMoistureLoadBinList(
          binCases,
          translate,
          getAmountFormat,
          listFieldGroup
        );
      }

      return SharedEnergyTools.createMoistureLoadBinListMonthly(
        binCases,
        translate,
        getAmountFormat,
        listFieldGroup
      );
    }
    default:
      return exhaustiveCheck(selectedBinType, true);
  }
}

export function createBinsExportData(
  table: SharedEnergyTools.EnergyResultTable
): string[][] {
  return [
    table.formattedHeader.map(
      h => h.name + (h.format ? " (" + h.format.unit.name + ")" : "")
    )
  ].concat(table.formattedRows);
}

export function isMonthlyBinTablePossible(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): boolean {
  return binCases.every(
    br =>
      br.binTimeJanuary !== undefined &&
      br.binTimeFebruary !== undefined &&
      br.binTimeMarch !== undefined &&
      br.binTimeApril !== undefined &&
      br.binTimeMay !== undefined &&
      br.binTimeJune !== undefined &&
      br.binTimeJuly !== undefined &&
      br.binTimeAugust !== undefined &&
      br.binTimeSeptember !== undefined &&
      br.binTimeOctober !== undefined &&
      br.binTimeNovember !== undefined &&
      br.binTimeDecember !== undefined
  );
}

export function getLocationExportData(
  binDataLocation: BinDataLocation | undefined,
  binPressure: PropertyValue.PropertyValue | undefined,
  translate: LanguageTexts.Translate
): ReadonlyArray<ReadonlyArray<string>> {
  if (binDataLocation === undefined) {
    return [[]];
  }
  const {
    countryName,
    regionName,
    locationName,
    latitude,
    longitude,
    binDataLocationId
  } = binDataLocation;

  return [
    [translate(LanguageTexts.country()), countryName],
    [translate(LanguageTexts.state()), regionName],
    [translate(LanguageTexts.location()), locationName],
    [translate(LanguageTexts.latitude()), latitude.toString()],
    [translate(LanguageTexts.longitude()), longitude.toString()],
    ["wmo", binDataLocationId],
    [
      translate(LanguageTexts.binPressure()),
      binPressure ? PropertyValue.toString(binPressure) : "Undefined"
    ],
    [translate(LanguageTexts.elevation()), ""]
  ];
}
