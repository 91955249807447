import { PropertyValueSet } from "@promaster-sdk/property";
import { unitLookup } from "@genesys/uom";
import * as PropertyCompare from "./property-compare";

export {
  Empty,
  toString,
  getText,
  getInteger,
  getAmount,
  PropertyKeyValuePair,
  PropertyValueSet,
  count,
  filter,
  fromProperty,
  get,
  getPropertyNames,
  getValue,
  getValuesOfType,
  hasProperty,
  isEmpty,
  keepProperties,
  map,
  merge,
  removeProperties,
  removeProperty,
  set,
  setAmount,
  setInteger,
  setText,
  setValues,
  toStringInSpecifiedOrder
} from "@promaster-sdk/property/lib/property-value-set";

export const equals = (
  a: PropertyValueSet.PropertyValueSet,
  b: PropertyValueSet.PropertyValueSet
) => PropertyValueSet.equals(a, b, PropertyCompare.compare);

export function fromString(
  encodedValueSet: string
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.fromString(encodedValueSet, unitLookup);
}

export function fromStringOrError(
  onError: (encodedValueSet: string) => PropertyValueSet.PropertyValueSet,
  encodedValueSet: string
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.fromStringOrError(
    onError,
    encodedValueSet,
    unitLookup
  );
}
