import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { OverlayLoader } from "@genesys/ui-elements";
import { SystemAction } from "./types";
import { MenuItem } from "./components/menu-item";
import { Root, Menu } from "./elements";

interface SystemActionsViewProps {
  readonly state: State;
  readonly systemActions: ReadonlyArray<SystemAction>;
  readonly dispatch: Dispatch<Action>;
}

export function SystemActionsView({
  state,
  systemActions,
  dispatch
}: SystemActionsViewProps): JSX.Element {
  if (!state) {
    return <OverlayLoader />;
  }

  const handleFileDownload = (link: HTMLAnchorElement | null) => {
    if (link) {
      link.click();
      dispatch(Action.removeFileInfo());
    }
  };

  const rendererMap = new Map<string, () => JSX.Element | null>(
    systemActions
      .filter(action => action.renderer)
      .map(action => [action.type, action.renderer!])
  );
  if (state.mode === "loading") {
    return <OverlayLoader />;
  }

  if (state.mode !== "menu") {
    const renderer = rendererMap.get(state.mode);
    if (renderer) {
      return <Root>{renderer()}</Root>;
    }
    console.error(`No renderer found for mode: ${state.mode}`);
  }

  return (
    <Root>
      <Menu>
        {systemActions.map(action => (
          <MenuItem
            key={action.type}
            label={action.label}
            onClick={action.onClick}
            isDisabled={action.isDisabled}
          />
        ))}
      </Menu>
      {state.fileInfo && (
        <a
          style={{ display: "none" }}
          ref={handleFileDownload}
          href={state.fileInfo.fileUrl}
          download={state.fileName}
        />
      )}
    </Root>
  );
}
