import gql from "graphql-tag";
import * as GraphQLTypes from "@genesys/graphql-types";
import * as GraphQLRequest from "@genesys/graphql-request";
import { PropertyValueSet } from "@genesys/property";

const getCalculatedPropertiesQuery = gql`
  query GetCalculatedProperties(
    $getCalculatedPropertiesInput: GetCalculatedPropertiesInputType!
  ) {
    user {
      getCalculatedProperties(input: $getCalculatedPropertiesInput)
    }
  }
`;

export async function getCalculatedProperties(
  includeServerLog: boolean,
  systemId: string,
  componentId: string,
  properties: PropertyValueSet.PropertyValueSet,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string },
  ignorePropertyName?: string
): Promise<PropertyValueSet.PropertyValueSet> {
  const serializedProperties = PropertyValueSet.toString(properties);
  const {
    user: { getCalculatedProperties }
  } = await GraphQLRequest.request<
    GraphQLTypes.GetCalculatedProperties,
    GraphQLTypes.GetCalculatedPropertiesVariables
  >(
    getCalculatedPropertiesQuery,
    {
      getCalculatedPropertiesInput: {
        componentId: componentId,
        componentProperties: serializedProperties,
        systemId
      }
    },
    includeServerLog,
    endpoint,
    authorization
  );
  const calculatedProperties = PropertyValueSet.fromString(
    getCalculatedProperties
  );
  if (!ignorePropertyName) {
    return calculatedProperties;
  }

  return updatePropertiesExcept(
    properties,
    calculatedProperties,
    ignorePropertyName
  );
}

const getCalculatedPropertiesForAccessoryQuery = gql`
  query GetCalculatedPropertiesForAccessory(
    $getCalculatedPropertiesForAccessoryInput: GetCalculatedPropertiesForAccessoryInputType!
  ) {
    user {
      getCalculatedPropertiesForAccessory(
        input: $getCalculatedPropertiesForAccessoryInput
      )
    }
  }
`;

export async function getCalculatedPropertiesForAccessory(
  includeServerLog: boolean,
  systemId: string,
  parentComponentId: string,
  accessoryProductId: string,
  accessorySelectedProperties: PropertyValueSet.PropertyValueSet,
  endpoint: string,
  authorization:
    | { readonly type: "cookies" }
    | { readonly type: "bearer"; readonly accessToken: string },
  ignorePropertyName?: string
): Promise<PropertyValueSet.PropertyValueSet> {
  const serializedProperties = PropertyValueSet.toString(
    accessorySelectedProperties
  );
  const {
    user: { getCalculatedPropertiesForAccessory }
  } = await GraphQLRequest.request<
    GraphQLTypes.GetCalculatedPropertiesForAccessory,
    GraphQLTypes.GetCalculatedPropertiesForAccessoryVariables
  >(
    getCalculatedPropertiesForAccessoryQuery,
    {
      getCalculatedPropertiesForAccessoryInput: {
        accessoryProductId,
        accessorySelectedProperties: serializedProperties,
        parentComponentId,
        systemId
      }
    },
    includeServerLog,
    endpoint,
    authorization
  );

  const calculatedProperties = PropertyValueSet.fromString(
    getCalculatedPropertiesForAccessory
  );
  if (!ignorePropertyName) {
    return calculatedProperties;
  }

  return updatePropertiesExcept(
    accessorySelectedProperties,
    calculatedProperties,
    ignorePropertyName
  );
}

function updatePropertiesExcept(
  first: PropertyValueSet.PropertyValueSet,
  second: PropertyValueSet.PropertyValueSet,
  ignorePropertyName: string
): PropertyValueSet.PropertyValueSet {
  const pv = PropertyValueSet.get(ignorePropertyName, first);
  if (!pv) {
    return second;
  }

  return PropertyValueSet.set(ignorePropertyName, pv, second);
}
