import * as React from "react";
import * as Types from "../types";
import * as Tools from "../tools";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as System from "../../../../system";
import styled from "styled-components";
import { BinSettingsView } from "./bin-settings";
import { BinCasesView } from "./bin-cases";
import { RadioButton } from "@genesys/ui-elements";
import { Action, State } from "../state";
import { Dispatch } from "@typescript-tea/core";
const Container = styled.div`
  padding-top: 10px;
`;
const StyledLabel = styled.label`
  font-size: 15px;
  margin-right: 8px;
  margin-left: 3px;
`;

const binTypes: ReadonlyArray<Types.BinType> = ["Generated", "Custom"];

export function BinSelectorView({
  sharedState,
  selectedBinType,
  state,
  system,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly selectedBinType: string;
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
}) {
  const selectedOperationTime = Tools.getOperationTime(
    state.systemBinSelections
  );
  const selectedDataType = Tools.getDataType(
    state.systemBinSelections,
    state.climateSettings
  );
  const selectedBinSize = Tools.getBinSize(state.systemBinSelections);

  return (
    <Container>
      <div>
        {binTypes.map(b => {
          return (
            <RadioButton
              isDisabled={true}
              key={b}
              onChange={() => undefined}
              checked={b === selectedBinType}
            >
              <StyledLabel>
                {sharedState.translate(
                  LanguageTexts.dynamicText("bincase_" + b)
                )}
              </StyledLabel>
            </RadioButton>
          );
        })}
      </div>
      {selectedBinType === "Generated" && (
        <>
          <BinSettingsView
            climateSettings={state.climateSettings}
            binLocations={state.productQueryData!.product.binDataLocations}
            selectedBinSize={selectedBinSize}
            selectedDataType={selectedDataType}
            selectedOperationTime={selectedOperationTime}
            sharedState={sharedState}
          />
        </>
      )}
      {state.binCases && state.binCases.length > 0 && (
        <BinCasesView
          state={state}
          system={system}
          binCases={state.binCases}
          sharedState={sharedState}
          dispatch={dispatch}
        />
      )}
    </Container>
  );
}
