import { ConversionType } from "./types";
import { Quantity } from "@genesys/uom";

export const quantityFromAndToRegistry = new Map<
  ConversionType,
  Array<Quantity.Quantity>
>([
  ["Flow", ["VolumeFlow", "MassFlow"]],
  [
    "Humidity",
    [
      "HumidityRatio",
      "RelativeHumidity",
      "SpecificEnthalpy",
      "WetTemperature",
      "DewPointTemperature"
    ]
  ]
]);
