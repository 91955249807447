import * as Helper from "./helper";
import * as Types from "./types";

interface NodeState {
  readonly node: Types.Node;
  readonly sortState: SortState;
  readonly incomingConnections: string[];
}

interface SortState {
  readonly index: number;
  readonly lowLink: number;
}

interface DrawingSorterState {
  readonly drawingCycles: DrawingCyclesList;
  readonly stack: string[];
  readonly index: number;
  readonly nodeStatesPerId: {
    readonly [nodeId: string]: NodeState;
  };
}

interface DrawingCyclesList {
  readonly list: DrawingCycle[];
}

interface DrawingCycle {
  readonly list: NodeState[];
}
type Direction = "Backward" | "Forward";
export function GetDrawingOrder(diagram: Types.Diagram): ReadonlyArray<string> {
  const allConnections = Helper.getConnections(diagram.nodes, diagram.edges);

  const connectionsToVisit = allConnections.concat();
  const outlets = diagram.nodes.filter(n => {
    const incoming = allConnections.filter(c => c.toNodeId === n.id).length;
    const outgoing = allConnections.filter(c => c.fromNodeId === n.id).length;
    return incoming === 1 && outgoing === 0;
  });
  const inlets = diagram.nodes.filter(n => {
    const incoming = allConnections.filter(c => c.toNodeId === n.id).length;
    const outgoing = allConnections.filter(c => c.fromNodeId === n.id).length;
    return incoming === 0 && outgoing === 1;
  });
  let nodeStatePerId: {
    // tslint:disable-next-line
    [nodeId: string]: NodeState;
  } = {};

  while (connectionsToVisit.length > 0) {
    const remainingStartConnections = connectionsToVisit
      .filter(c =>
        outlets.find(n => n.productId.endsWith("SUC") && n.id === c.toNodeId)
      )
      .concat(
        connectionsToVisit.filter(c =>
          outlets.find(n => n.productId.endsWith("SOC") && n.id === c.toNodeId)
        )
      )
      .concat(
        connectionsToVisit.filter(c => outlets.find(n => n.id === c.toNodeId))
      )
      .concat(
        connectionsToVisit.filter(c => inlets.find(n => n.id === c.fromNodeId))
      );

    const startConnection = remainingStartConnections[0];
    if (!startConnection) {
      throw new Error("connection can't be null");
    }

    let startDirection: Direction = "Backward";

    if (outlets.find(o => o.id === startConnection.toNodeId)) {
      startDirection = "Backward";
    } else {
      startDirection = "Forward";
    }
    const connectionStack: {
      readonly fromNodeId: string;
      readonly toNodeId: string;
    }[] = [];
    connectionStack.push(startConnection);

    const reverseConnectionStack: {
      readonly fromNodeId: string;
      readonly toNodeId: string;
    }[] = [];

    while (connectionStack.length > 0 || reverseConnectionStack.length > 0) {
      let currentConnection: {
        readonly fromNodeId: string;
        readonly toNodeId: string;
      };
      let currentDirection: Direction = "Backward";
      if (connectionStack.length > 0) {
        currentConnection = connectionStack.pop()!;
        currentDirection = startDirection;
      } else if (reverseConnectionStack.length > 0) {
        currentDirection =
          startDirection === "Forward" ? "Backward" : "Forward";
        currentConnection = reverseConnectionStack.pop()!;
      }
      const index = connectionsToVisit.findIndex(
        c =>
          c.fromNodeId === currentConnection.fromNodeId &&
          c.toNodeId === currentConnection.toNodeId
      );

      if (index === -1) {
        continue;
      }
      connectionsToVisit.splice(index, 1);

      const fromNode = diagram.nodes.find(
        n => n.id === currentConnection.fromNodeId
      )!;
      if (!nodeStatePerId[fromNode.id]) {
        nodeStatePerId[fromNode.id] = {
          node: fromNode,
          sortState: { index: -1, lowLink: -1 },
          incomingConnections: []
        };
      }

      const toNode = diagram.nodes.find(
        n => n.id === currentConnection.toNodeId
      )!;
      if (!nodeStatePerId[toNode.id]) {
        nodeStatePerId[toNode.id] = {
          node: toNode,
          sortState: { index: -1, lowLink: -1 },
          incomingConnections: []
        };
      }

      if (currentDirection === "Backward") {
        nodeStatePerId[fromNode.id] = {
          ...nodeStatePerId[fromNode.id],
          incomingConnections: nodeStatePerId[
            fromNode.id
          ].incomingConnections.concat(toNode.id)
        };
        for (const backwardConnection of connectionsToVisit.filter(
          c => fromNode.id === c.toNodeId
        )) {
          if (currentDirection === startDirection) {
            connectionStack.push(backwardConnection);
          } else {
            reverseConnectionStack.push(backwardConnection);
          }
        }
        for (const forwardConnection of connectionsToVisit.filter(
          c => fromNode.id === c.fromNodeId
        )) {
          if (currentDirection === startDirection) {
            reverseConnectionStack.push(forwardConnection);
          } else {
            connectionStack.push(forwardConnection);
          }
        }
      } else {
        nodeStatePerId[toNode.id] = {
          ...nodeStatePerId[toNode.id],
          incomingConnections: nodeStatePerId[
            toNode.id
          ].incomingConnections.concat(fromNode.id)
        };

        for (const backwardConnection of connectionsToVisit.filter(
          c => toNode.id === c.toNodeId
        )) {
          if (currentDirection === startDirection) {
            reverseConnectionStack.push(backwardConnection);
          } else {
            connectionStack.push(backwardConnection);
          }
        }
        for (const forwardConnection of connectionsToVisit.filter(
          c => toNode.id === c.fromNodeId
        )) {
          if (currentDirection === startDirection) {
            connectionStack.push(forwardConnection);
          } else {
            reverseConnectionStack.push(forwardConnection);
          }
        }
      }
    }
  }
  const drawingCyclesList = CreateDrawingCyclesList(nodeStatePerId);

  const orderedNodeIds = drawingCyclesList.list.reduce(
    (soFar: string[], current) => {
      soFar = soFar.concat(current.list.map(l => l.node.id));
      return soFar;
    },
    []
  ) as ReadonlyArray<string>;

  return orderedNodeIds.concat(
    diagram.nodes
      .map(n => n.id)
      .filter(id => !orderedNodeIds.find(oid => oid === id))
  );
}

function CreateDrawingCyclesList(initialNodeStates: {
  readonly [nodeId: string]: NodeState;
}): DrawingCyclesList {
  let state: DrawingSorterState = {
    drawingCycles: { list: [] },
    index: 0,
    stack: [],
    nodeStatesPerId: initialNodeStates
  };

  const nodeKeys = Object.keys(state.nodeStatesPerId).map(i => i);

  for (const nodeId of nodeKeys) {
    let nodeState = state.nodeStatesPerId[nodeId];
    if (nodeState.sortState.index < 0) {
      state = strongConnect(nodeId, state);
    }
  }

  return state.drawingCycles;
}

function strongConnect(
  currentNodeId: string,
  currentState: DrawingSorterState
): DrawingSorterState {
  let newNodeStates = currentState.nodeStatesPerId as {
    // tslint:disable-next-line
    [nodeId: string]: NodeState;
  };
  let index = currentState.index;
  let stack = currentState.stack;
  let drawingCycles = currentState.drawingCycles;

  newNodeStates[currentNodeId] = {
    ...newNodeStates[currentNodeId],
    sortState: {
      ...newNodeStates[currentNodeId].sortState,
      index: index,
      lowLink: index
    }
  };

  index++;
  stack.push(currentNodeId);
  const incomingConnectionIds =
    newNodeStates[currentNodeId].incomingConnections;
  for (const incomingConnectionId of incomingConnectionIds) {
    if (newNodeStates[incomingConnectionId].sortState.index < 0) {
      const newState = strongConnect(incomingConnectionId, {
        drawingCycles: drawingCycles,
        nodeStatesPerId: newNodeStates,
        stack: stack,
        index: index
      });
      newNodeStates = newState.nodeStatesPerId;
      drawingCycles = newState.drawingCycles;
      index = newState.index;
      stack = newState.stack;

      newNodeStates[currentNodeId] = {
        ...newNodeStates[currentNodeId],
        sortState: {
          ...newNodeStates[currentNodeId].sortState,
          lowLink: Math.min(
            newNodeStates[currentNodeId].sortState.lowLink,
            newNodeStates[incomingConnectionId].sortState.lowLink
          )
        }
      };
    } else if (stack.find(i => i === incomingConnectionId)) {
      newNodeStates[currentNodeId] = {
        ...newNodeStates[currentNodeId],
        sortState: {
          ...newNodeStates[currentNodeId].sortState,
          lowLink: Math.min(
            newNodeStates[currentNodeId].sortState.lowLink,
            newNodeStates[incomingConnectionId].sortState.index
          )
        }
      };
    }
  }

  if (
    newNodeStates[currentNodeId].sortState.lowLink ===
    newNodeStates[currentNodeId].sortState.index
  ) {
    let scc: DrawingCycle = { list: [] };
    let temp: string | undefined = undefined;
    do {
      temp = stack.pop();
      if (temp !== undefined) {
        scc = { ...scc, list: scc.list.concat(newNodeStates[temp]) };
      }
    } while (currentNodeId !== temp);

    drawingCycles = {
      ...drawingCycles,
      list: drawingCycles.list.concat(scc)
    };
  }
  return {
    drawingCycles: drawingCycles,
    nodeStatesPerId: newNodeStates,
    index: index,
    stack: stack
  };
}
