import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  padding: 16px;
  overflow: auto;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  h4 {
    margin-right: 12px;
  }
`;

export const Navigation = styled.ul`
  display: flex;
  list-style: none;
  padding: 8px 0 16px 0;
`;

export const Tab = styled.li`
  margin: 0px 16px;
  padding: 8px 0;
  cursor: pointer;

  ${(props: { readonly isActive: boolean }) =>
    props.isActive && `border-bottom: 3px solid ${PrimaryColors.muntersBlue};`};
`;
