import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValueSet } from "@genesys/property";
import * as ValueSources from "@genesys/shared/lib/value-sources";
import * as ValueSourceQueries from "@genesys/client-core/lib/value-source-queries";
import * as SharedState from "../../../shared-state";
import * as PropertiesSelector from "../../../properties-selector";
import { clientConfig } from "../../../config";
import * as System from "../../system";
import * as Product from "../../product";
import { promiseCmd } from "../../../promise-effect-manager";

export type State = {
  readonly parentComponentId: string;
  readonly parentSelectedProperties: PropertyValueSet.PropertyValueSet;
  readonly accessoriesPropertiesSelectorStates: {
    readonly [key: string]: PropertiesSelector.State;
  };
  readonly collapsed: {
    readonly [productId: string]: true | undefined;
  };
  readonly valuesHasChanged: boolean;
};

export const init = (
  parentComponent: System.Component,
  system: System.System,
  products: ReadonlyArray<Product.Product>
): [State] => {
  const componentAccessories = system.components.filter(
    c => c.accessoryToId === parentComponent.id
  );
  return [
    {
      parentComponentId: parentComponent.id,
      parentSelectedProperties: parentComponent.properties,
      accessoriesPropertiesSelectorStates:
        getAccessoriesSelectedPropertiesByProductIdOnInit(
          componentAccessories,
          products
        ),
      collapsed: {},
      valuesHasChanged: false
    }
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (
    productId: string,
    componentId: string,
    action: PropertiesSelector.Action
  ) => ({
    action,
    componentId,
    productId
  }),
  toggleCollapsed: (productId: string) => ({ productId }),
  toggleAccessory: (productId: string, value: number) => ({ productId, value }),
  parentPropertiesChanged: (
    selectedProperties: PropertyValueSet.PropertyValueSet,
    propertyNameChanged: string
  ) => ({ selectedProperties, propertyNameChanged }),
  parentValueSourcesCalculated: (
    propertyNameChanged: string,
    selectedProperties: PropertyValueSet.PropertyValueSet
  ) => ({ selectedProperties, propertyNameChanged }),
  accessoryValueSourcesCalculated: (
    productId: string,
    selectedProperties: PropertyValueSet.PropertyValueSet
  ) => ({ selectedProperties, productId })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  systemId: string
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.accessoriesPropertiesSelectorStates[action.productId],
        sharedState,
        {
          type: "Accessory",
          systemId,
          parentComponentId: state.parentComponentId,
          productId: action.productId
        }
      );

      return [
        {
          ...state,
          accessoriesPropertiesSelectorStates: {
            ...state.accessoriesPropertiesSelectorStates,
            [action.productId]: propertiesSelectorState
          },
          valuesHasChanged: true
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchPropertiesSelector(
              action.productId,
              action.componentId,
              cmdAction
            ),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    case "toggleCollapsed": {
      return [
        {
          ...state,
          collapsed: {
            ...state.collapsed,
            [action.productId]: state.collapsed[action.productId]
              ? undefined
              : true
          }
        }
      ];
    }
    case "parentPropertiesChanged": {
      return [
        {
          ...state,
          parentSelectedProperties: action.selectedProperties,
          valuesHasChanged: true
        },
        promiseCmd<Action, PropertyValueSet.PropertyValueSet>(
          async () => {
            const calculatedProperties =
              await ValueSourceQueries.getCalculatedProperties(
                sharedState.debugSettings.includeServerLog,
                systemId,
                state.parentComponentId,
                action.selectedProperties,
                clientConfig.graphqlEndpoint,
                { type: "bearer", accessToken: sharedState.accessToken },
                action.propertyNameChanged
              );
            return calculatedProperties;
          },
          selectedProperties =>
            Action.parentValueSourcesCalculated(
              action.propertyNameChanged,
              selectedProperties
            )
        )
      ];
    }
    case "toggleAccessory": {
      const propertyName = `acc_${action.productId}`.toLowerCase();
      const beforeValueSourcesProperties = PropertyValueSet.setInteger(
        propertyName,
        action.value,
        state.parentSelectedProperties
      );
      return [
        {
          ...state,
          parentSelectedProperties: beforeValueSourcesProperties,
          valuesHasChanged: true
        },
        promiseCmd<Action, PropertyValueSet.PropertyValueSet>(
          async () => {
            const calculatedProperties =
              await ValueSourceQueries.getCalculatedProperties(
                sharedState.debugSettings.includeServerLog,
                systemId,
                state.parentComponentId,
                beforeValueSourcesProperties,
                clientConfig.graphqlEndpoint,
                { type: "bearer", accessToken: sharedState.accessToken },
                propertyName
              );
            return calculatedProperties;
          },
          selectedProperties =>
            Action.parentValueSourcesCalculated(
              propertyName,
              selectedProperties
            )
        )
      ];
    }
    case "parentValueSourcesCalculated": {
      const accessoryEnabled =
        PropertyValueSet.getInteger(
          action.propertyNameChanged,
          action.selectedProperties
        )! > 0;

      const accessoryProductId = action.propertyNameChanged
        .replace("source_acc_", "")
        .replace("acc_", "")
        .toUpperCase();
      return [
        {
          ...state,
          parentSelectedProperties: action.selectedProperties,
          valuesHasChanged: true
        },
        (accessoryEnabled &&
          promiseCmd<Action, PropertyValueSet.PropertyValueSet>(
            async () => {
              const calculatedProperties =
                await ValueSourceQueries.getCalculatedPropertiesForAccessory(
                  sharedState.debugSettings.includeServerLog,
                  systemId,
                  state.parentComponentId,
                  accessoryProductId,
                  PropertiesSelector.getSelectedProperties(
                    state.accessoriesPropertiesSelectorStates[
                      accessoryProductId
                    ]
                  ),
                  clientConfig.graphqlEndpoint,
                  { type: "bearer", accessToken: sharedState.accessToken },
                  undefined
                );
              return calculatedProperties;
            },
            selectedProperties =>
              Action.accessoryValueSourcesCalculated(
                accessoryProductId,
                selectedProperties
              )
          )) ||
          undefined
      ];
    }
    case "accessoryValueSourcesCalculated": {
      const oldPropertiesSelectorState =
        state.accessoriesPropertiesSelectorStates[action.productId];

      const [newPropertiesSelectorState] = PropertiesSelector.update(
        PropertiesSelector.Action.updateProperties(
          action.selectedProperties,
          []
        ),
        oldPropertiesSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        {
          ...state,
          accessoriesPropertiesSelectorStates: {
            ...state.accessoriesPropertiesSelectorStates,
            [action.productId]: newPropertiesSelectorState
          },
          valuesHasChanged: true
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function getAccessoriesSelectedPropertiesByProductIdOnInit(
  componentAccessories: ReadonlyArray<System.Component>,
  products: ReadonlyArray<Product.Product>
): {
  readonly [key: string]: PropertiesSelector.State;
} {
  return products.reduce(
    (
      soFar: {
        // tslint:disable-next-line:readonly-keyword
        [key: string]: PropertiesSelector.State;
      },
      current
    ) => {
      const accessory = componentAccessories.find(
        a => a.productId === current.id
      );

      if (
        accessory !== undefined &&
        accessory.properties !== undefined &&
        accessory.properties !== null
      ) {
        soFar[current.id] = PropertiesSelector.init(accessory.properties);
        return soFar;
      }

      const valuesSourceKeys = ValueSources.createValueSourcesDict(
        current.properties,
        PropertyValueSet.Empty
      );
      const emptyWithValueSource = Object.keys(valuesSourceKeys)
        .map(key => `source_${key}=0`)
        .join(";");

      soFar[current.id] = PropertiesSelector.init(
        PropertyValueSet.fromString(emptyWithValueSource)
      );
      return soFar;
    },
    {}
  );
}
export function getComponentProperties(state: State) {
  return {
    parentComponentId: state.parentComponentId,
    parentComponentProperties: PropertyValueSet.toString(
      state.parentSelectedProperties
    ),
    accessoriesProperties: getEnabledProductsProperties(
      getEnabledProducts(state.parentSelectedProperties),
      state
    )
  };
}

function getEnabledProductsProperties(
  enabledProducts: ReadonlyArray<string>,
  state: State
): ReadonlyArray<{
  readonly productId: string;
  readonly properties: string;
}> {
  return enabledProducts.map(productId => ({
    productId,
    properties: PropertyValueSet.toString(
      PropertiesSelector.getSelectedProperties(
        state.accessoriesPropertiesSelectorStates[productId]
      )
    )
  }));
}

function getEnabledProducts(
  parentProperties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<string> {
  return PropertyValueSet.getPropertyNames(parentProperties)
    .filter(
      pName =>
        pName.startsWith("acc_") &&
        PropertyValueSet.getInteger(pName, parentProperties)! > 0
    )
    .map(pName => pName.replace("acc_", "").toUpperCase());
}
