import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Exporter from "@genesys/client-core/lib/exporter";
import * as GraphQLTypes from "../../../../graphql-types";
import styled from "styled-components";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { Unit, Quantity } from "@genesys/uom";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import {
  ReadOnlyTable,
  ToolTipWrapper,
  StandardButton
} from "@genesys/ui-elements";

const Container = styled.div``;

const FlexContainer = styled.div`
  display: flex;
`;

const StyledDiv = styled.div`
  font-size: 13px;
  /* font-weight: 500; */
  color: #959695;
`;

const fieldGroupName = "MoistureLoadResult.ListVisualizer";

export function View({
  results,
  sharedState,
  moistureLoadItem,
  onFormatChanged,
  onFormatCleared
}: {
  readonly results: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly sharedState: SharedState.State;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const distinctPropertyNames = results.reduce(
    (propertyNames: string[], pvs) => {
      propertyNames = propertyNames.concat(
        PropertyValueSet.getPropertyNames(pvs)
      );
      return Array.from(new Set(propertyNames));
    },
    []
  );
  return (
    <Container>
      <Exporter.XlsxSingleSheetExport
        data={createDataForExcelExporter(
          createRows(results, distinctPropertyNames, sharedState),
          createHeaders(
            results,
            distinctPropertyNames,
            sharedState,
            onFormatChanged,
            onFormatCleared
          )
        )}
        filename={
          sharedState.genesysPrefix.moistureLoadNo(
            moistureLoadItem.moistureloadFile.moistureLoadNo,
            moistureLoadItem.revisionNo
          ) + "-dev-results"
        }
      >
        {onClick => (
          <div style={{ margin: "10px" }}>
            <StandardButton
              buttonType={"Secondary"}
              size={"Small"}
              onClick={onClick}
            >
              {sharedState.translate(
                LanguageTexts.globalPropertyName("export")
              )}
            </StandardButton>
          </div>
        )}
      </Exporter.XlsxSingleSheetExport>
      <ReadOnlyTable
        headers={createHeaders(
          results,
          distinctPropertyNames,
          sharedState,
          onFormatChanged,
          onFormatCleared
        )}
        rows={createRows(results, distinctPropertyNames, sharedState)}
      />
    </Container>
  );
}

function createDataForExcelExporter(
  rows: ReadonlyArray<{
    readonly results: ReadonlyArray<string>;
  }>,
  headers: ReadonlyArray<{
    readonly header: React.ReactNode;
    readonly name: string;
    readonly visible: boolean;
    readonly amountName: string | undefined;
    readonly amountFormatSelector: () => JSX.Element | null;
  }>
) {
  return [headers.map(x => x.name), ...rows.map(x => x.results)];
}

function createRows(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: ReadonlyArray<string>,
  sharedState: SharedState.State
) {
  return results.map(res => {
    const results = distinctPropertyNames.map(propertyName => {
      const propertyValue = PropertyValueSet.getValue(propertyName, res);
      if (propertyValue !== undefined) {
        if (propertyValue.type === "amount") {
          return getValue(
            propertyValue,
            sharedState.screenAmounts.getAmountFormat(
              fieldGroupName,
              propertyName,
              PropertyValue.getAmount(propertyValue)!
            )
          );
        }
        return propertyValue.value.toString();
      }
      return "";
    });

    return {
      results: results
    };
  });
}

function createHeaders(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: string[],
  sharedState: SharedState.State,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
): ReadonlyArray<{
  readonly header: React.ReactNode;
  readonly name: string;
  readonly visible: boolean;
  readonly amountName: string | undefined;
  readonly amountFormatSelector: () => JSX.Element | null;
}> {
  const uniquePropertieValues = getUniquePropertieValues(results);
  return distinctPropertyNames.map(propertyName => {
    const name = sharedState.translate(
      LanguageTexts.globalPropertyName("pp_" + propertyName + "_st")
    );

    const propertyValue = uniquePropertieValues[propertyName];

    const amountFormat =
      propertyValue.type === "amount"
        ? sharedState.screenAmounts.getAmountFormat(
            fieldGroupName,
            propertyName,
            PropertyValue.getAmount(propertyValue)!
          )
        : undefined;

    return {
      name,
      amountName: amountFormat?.unit.name,
      header: (
        <ToolTipWrapper
          padding="0px"
          title={sharedState.translate(
            LanguageTexts.globalPropertyName("pp_" + propertyName)
          )}
        >
          <StyledDiv>{name}</StyledDiv>
        </ToolTipWrapper>
      ),
      visible: true,
      amountFormatSelector: () => {
        if (!amountFormat) {
          return null;
        }

        return (
          <FlexContainer>
            <span> [ </span>
            <AmountFormatSelector
              type="AmountFormatSelectorProps"
              fieldName={propertyName}
              fieldGroup={fieldGroupName}
              amountFormat={amountFormat}
              conversionParameters={undefined}
              translate={sharedState.translate}
              onFormatCleared={() => {
                onFormatCleared(fieldGroupName, propertyName);
              }}
              onFormatChanged={(unit, decimalCount) => {
                onFormatChanged(
                  fieldGroupName,
                  propertyName,
                  unit,
                  decimalCount
                );
              }}
            />
            <span> ] </span>
          </FlexContainer>
        );
      }
    };
  });
}

function getUniquePropertieValues(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>
) {
  return results.reduce(
    (
      propertyValueMap: {
        // tslint:disable-next-line:readonly-keyword
        [propertyName: string]: PropertyValue.PropertyValue;
      },
      result
    ) => {
      for (const propertyName of PropertyValueSet.getPropertyNames(result)) {
        propertyValueMap[propertyName] = PropertyValueSet.get(
          propertyName,
          result
        )!;
      }

      return propertyValueMap;
    },
    {}
  ) as {
    readonly [propertyName: string]: PropertyValue.PropertyValue;
  };
}
