import * as GQLTypes from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { promiseCmd } from "../../../../../promise-effect-manager";
import { Action } from "../../../actions";
import {
  setActiveAmountProfileMutation,
  updateLastOpenedSystemsMutation,
  toggleFavoriteSystemMutation,
  setUserSettingsPsychrometricChartMutation,
  setUserSettingsSystemsSummaryMutation,
  toggleReportFor5ystemType
} from "@genesys/client-core/lib/graphql-mutations";
import { buildloadRecentAndFavoritesCmd } from "../../../tools";
import { UpdateFunction } from "../types";
import { knownSettings as SystemSummaryKnownSettings } from "../../../../../system-summary";

export const handleRemoveSystemsFromLastOpenedSystemsAndFavorites: UpdateFunction =
  (action, state) => {
    if (action.type !== "removeSystemsFromLastOpenedSystemsAndFavorites") {
      return [state];
    }
    if (state.user) {
      state = {
        ...state,
        user: {
          ...state.user,
          lastOpenedSystems: state.user.lastOpenedSystems.filter(
            x =>
              !action.systemIds.includes(
                x.type === "loaded" ? x.system.id : x.id
              )
          ),
          favoritesSystems: state.user.lastOpenedSystems.filter(
            x =>
              !action.systemIds.includes(
                x.type === "loaded" ? x.system.id : x.id
              )
          )
        }
      };
    }
    return [state];
  };

export const handleSetSystemSummarySettings: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setSystemSummarySettings") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        systemSummarySettings: action.systemSummarySettings
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsSystemsSummary,
        GQLTypes.SetUserSettingsSystemsSummaryVariables,
        Action
      >(
        setUserSettingsSystemsSummaryMutation,
        {
          newsettings: {
            selectedSummaryItems:
              action.systemSummarySettings.find(
                x =>
                  x.settingName ===
                  SystemSummaryKnownSettings.selectedSummaryItems
              )?.settingValue || ""
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetPsychrometricChartSettings: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setPsychrometricChartSettings") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        psychrometricChartSettings: {
          chartType:
            action.settings.chartType ??
            state.user!.settings.psychrometricChartSettings.chartType,
          limits:
            action.settings.limits ??
            state.user!.settings.psychrometricChartSettings.limits,
          humidityMax:
            action.settings.humidityMax ??
            state.user!.settings.psychrometricChartSettings.humidityMax,
          temperatureMin:
            action.settings.temperatureMin ??
            state.user!.settings.psychrometricChartSettings.temperatureMin,
          temperatureMax:
            action.settings.temperatureMax ??
            state.user!.settings.psychrometricChartSettings.temperatureMax
        }
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsPsychrometricChart,
        GQLTypes.SetUserSettingsPsychrometricChartVariables,
        Action
      >(
        setUserSettingsPsychrometricChartMutation,
        {
          newsettings: action.settings
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetActiveAmountFieldProfile: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setActiveAmountFieldProfile") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const selectedAmountFieldProfile =
    state.user.settings.amountFieldProfiles.find(ap => ap.id === action.id);
  if (!selectedAmountFieldProfile) {
    console.error("Couldn't find amount profile");
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        selectedAmountProfile: selectedAmountFieldProfile
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetActiveAmountProfile,
        GQLTypes.SetActiveAmountProfileVariables,
        Action
      >(
        setActiveAmountProfileMutation,
        {
          amountProfileId: action.id
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleUpdateLastCreatedSystemType: UpdateFunction = (
  action,
  state
) => {
  if (action.type !== "updateLastCreatedSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      lastCreatedSystemType: action.systemType
    }
  };
  return [newState];
};

export const handleUpdateCreateSystemType: UpdateFunction = (action, state) => {
  if (action.type !== "updateCreateSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const createSystemType = action.systemTypes.reduce((a, b) => {
    const maybeEntry = a[b];
    return {
      ...a,
      [b]: maybeEntry !== undefined ? maybeEntry + 1 : 1
    };
  }, state.user.createSystemType);

  const newState = {
    ...state,
    user: {
      ...state.user,
      createSystemType: createSystemType
    }
  };
  return [newState];
};

export const handleToggleFavoritesSystems: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "toggleFavoritesSystems") {
    return [state];
  }

  if (!state.user) {
    return [state];
  }
  const oldSystems = state.user.favoritesSystems;
  const favoriteSystem = oldSystems.find(
    s => (s.type === "loaded" ? s.system.id : s.id) === action.systemId
  );
  const recentSystem = state.user.lastOpenedSystems.find(
    s => (s.type === "loaded" ? s.system.id : s.id) === action.systemId
  );

  const newFavoritesSystems = favoriteSystem
    ? oldSystems.filter(
        s => (s.type === "loaded" ? s.system.id : s.id) !== action.systemId
      )
    : oldSystems.concat(
        recentSystem ?? {
          type: "loading",
          id: action.systemId
        }
      );

  const newState = {
    ...state,
    user: {
      ...state.user,
      favoritesSystems: newFavoritesSystems
    }
  };
  const cmds = [
    oldSharedState.graphQL.queryUserCmd<
      GQLTypes.ToggleFavoriteSystem,
      GQLTypes.ToggleFavoriteSystemVariables,
      Action
    >(
      toggleFavoriteSystemMutation,
      {
        systemId: action.systemId
      },
      () => Action.sharedStateActionMutationCompleted(false)
    ),
    buildloadRecentAndFavoritesCmd(
      {
        ...oldSharedState,
        user: {
          ...oldSharedState.user,
          favoritesSystems: newFavoritesSystems
        }
      },
      false
    )
  ];

  return [newState, cmds];
};

export const handleUpdateLastOpenedSystems: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "updateLastOpenedSystems") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const lastOpenedSystems = state.user.lastOpenedSystems.filter(l => {
    switch (l.type) {
      case "loaded":
        return l.system.id !== action.systemId;
      case "error":
      case "loading":
        return l.id !== action.systemId;
      default:
        return exhaustiveCheck(l);
    }
  });
  const newState = {
    ...state,
    user: {
      ...state.user,
      lastOpenedSystems: [
        {
          type: "loading",
          id: action.systemId
        } as any,
        ...lastOpenedSystems.slice(0, 9)
      ]
    }
  };

  const cmds = [
    oldSharedState.graphQL.queryUserCmd<
      GQLTypes.UpdateLastOpenedSystems,
      GQLTypes.UpdateLastOpenedSystemsVariables,
      Action
    >(
      updateLastOpenedSystemsMutation,
      {
        systemId: action.systemId
      },
      () => Action.sharedStateActionMutationCompleted(false)
    ),
    buildloadRecentAndFavoritesCmd(
      {
        ...oldSharedState,
        user: {
          ...oldSharedState.user,
          lastOpenedSystems: lastOpenedSystems
        }
      },
      false
    )
  ];

  return [newState, cmds];
};

export const handleToggleReportForSystemType: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "toggleReportForSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const systemTypeReportSettings = state.user.settings.reportSettings.find(
    x => x.systemType === action.systemTypeId
  );
  let newReportSetting: ReadonlyArray<SharedState.ReportSetting> = [];
  let todoIds: Array<string> = [];

  if (systemTypeReportSettings === undefined) {
    todoIds = [...action.ids];
    newReportSetting = [
      ...state.user.settings.reportSettings,
      {
        systemType: action.systemTypeId,
        ids: action.ids
      }
    ];
  } else {
    const systemTypeCurrentIds = systemTypeReportSettings.ids;
    const newIDs = action.ids.filter(
      y => !systemTypeCurrentIds.some(x => y === x)
    );
    const deletedIds = systemTypeCurrentIds.filter(
      x => !action.ids.includes(x)
    );
    // const newID = action.ids.filter(
    //   y => !systemTypeReportSettings.ids.some(x => y === x)
    // );
    todoIds = [...newIDs, ...deletedIds];
    newReportSetting = [
      ...state.user.settings.reportSettings.filter(
        x => x.systemType !== action.systemTypeId
      ),
      {
        systemType: action.systemTypeId,
        ids: systemTypeCurrentIds
          .filter(x => !deletedIds.includes(x))
          .concat(newIDs)
      }
    ];
  }

  // if (
  //   action.currentId === undefined &&
  //   systemTypeReportSettings !== undefined
  // ) {
  //   todoIds = [...systemTypeReportSettings.ids];
  // }
  return [
    {
      ...state,
      user: {
        ...state.user,
        settings: {
          ...state.user?.settings,
          reportSettings: newReportSetting
        }
      }
    },
    [
      promiseCmd<Action, {}>(
        async () => {
          for (const id of todoIds) {
            await oldSharedState.graphQL.queryUser<
              GQLTypes.ToggleReportFor5ystemType,
              GQLTypes.ToggleReportFor5ystemTypeVariables
            >(toggleReportFor5ystemType, {
              reportInfo: {
                id: id,
                systemType: action.systemTypeId
              }
            });
          }
          return {};
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleLoadLastOpenedSystemsAndFavorites: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "loadLastOpenedSystemsAndFavorites") {
    return [state];
  }
  return [state, [buildloadRecentAndFavoritesCmd(oldSharedState, true)]];
};
