import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as Types from "../types";
import * as OperationTimeGen2 from "../../../../../operation-time-manager";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { PropertyValueSet } from "@genesys/property";
import {
  View as LocationSelectorView,
  BinDataLocation,
  getDefaultLocation,
  groupLocations,
  getCoordinateFromClimateDataProperties
} from "../../../../../location-selector";

const Container = styled.div``;
const OperationTimeContainer = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-right: 7px;
  }

  img {
    cursor: pointer;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  > * {
    margin-right: 5px;
  }
`;

const dataTypes: ReadonlyArray<Types.DataType> = [
  "DB/MCWB",
  "WB/MCDB",
  "DP/MCDB",
  "h/MCDB"
];
const binSizes: ReadonlyArray<Types.BinSize> = [1, 2, 5];

export function BinSettingsView({
  sharedState,
  selectedOperationTime,
  selectedDataType,
  selectedBinSize,
  binLocations,
  climateSettings
}: {
  readonly sharedState: SharedState.State;
  readonly selectedOperationTime: OperationTimeGen2.OperationTime;
  readonly selectedDataType: string;
  readonly selectedBinSize: number;
  readonly binLocations: ReadonlyArray<BinDataLocation>;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
}) {
  const translate = sharedState.translate;
  const labelRef = React.useRef<HTMLLabelElement>(null);

  const locationCoordinate =
    getCoordinateFromClimateDataProperties(climateSettings);

  const defaultLocation = getDefaultLocation(binLocations, locationCoordinate);
  const countries = groupLocations(binLocations);
  const country = countries.find(
    item => item.countryName === defaultLocation.countryName
  )!;

  const region = country.regions.find(
    item => item.regionName === defaultLocation.regionName
  )!;

  const location = region.locations.find(
    item => item.locationName === defaultLocation.locationName
  )!;

  const locationSelectorvalues = {
    selectedCountryIndex: countries.indexOf(country),
    selectedRegionIndex: country.regions.indexOf(region),
    selectedLocationIndex: region.locations.indexOf(location),
    countries,
    binLocationIndexMapper: new Map()
  };

  return (
    <Container>
      <OperationTimeContainer>
        <label ref={labelRef}>
          {translate(LanguageTexts.operationTime())}:
        </label>
        <label>
          {" "}
          {translate(
            LanguageTexts.dynamicText(
              OperationTimeGen2.presetName(selectedOperationTime)
            )
          )}
        </label>
      </OperationTimeContainer>
      <FlexContainer>
        <span>{translate(LanguageTexts.dataType())}</span>
        <GenesysSelect
          width={13}
          height={33}
          fontSize={13}
          disabled={true}
          value={selectedDataType}
          options={dataTypes.map(d => ({
            title: d,
            value: d
          }))}
        />

        <span> {translate(LanguageTexts.binSize())}</span>

        <GenesysSelect
          width={13}
          height={33}
          fontSize={13}
          disabled={true}
          value={selectedBinSize.toString()}
          options={binSizes.map(bz => ({
            value: bz.toString(),
            title: bz.toString()
          }))}
        />
      </FlexContainer>
      <LocationSelectorView
        state={locationSelectorvalues}
        sharedState={sharedState}
        dispatch={() => undefined}
        readonly={true}
      />
    </Container>
  );
}
