import { Unit, UnitFormat, UnitMap } from "uom";
import * as UnitsFormat from "./units-format";
import Units from "./units";
import * as Quantity from "./quantity";

export function getUnitFormat(
  unit: Unit.Unit<any>,
  unitsFormat = UnitsFormat
): UnitFormat.UnitFormat | undefined {
  return UnitFormat.getUnitFormat(unit, unitsFormat);
}

export function getUnitsForQuantity(
  quantity: string
): Array<Unit.Unit<Quantity.Quantity>> {
  return UnitMap.getUnitsForQuantity(quantity, Units) as Array<
    Unit.Unit<Quantity.Quantity>
  >;
}
