import { PropertyFilter } from "@genesys/property";
import { PropertyInfo } from "../../properties-selector";

/*
TODO: 
- Ligger textIds med p_SSG_casename etc och skräpar i munter_texts nu när vi inte har en riktigt product för detta
*/

interface ProductData {
  readonly id: string;
  readonly properties: ReadonlyArray<PropertyInfo>;
}

export const opcProductData: ProductData = {
  id: "SSGOPC",
  properties: [
    {
      name: "processoutletexternalstatic",
      quantity: "Pressure",
      group: "mainselections",
      sortNo: 100,
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [],
      defaultValues: [],
      descriptionTexts: [],
      valueSources: [
        {
          id: "5y5fd279-fe5e-4c56-b96d-5b62c69cf4ae",
          claimFilter: "",
          propertyFilter: PropertyFilter.fromString("sortno!=0")!,
          propertyValueSourceId: "InheritedFromFirstOpCase",
          value: 0,
          parameters: ""
        },
        {
          id: "2tcdb773-24r3-40a9-a79b-8e3d2d2d5af0",
          claimFilter: "",
          propertyFilter: PropertyFilter.Empty,
          propertyValueSourceId: "User",
          value: 1,
          parameters: ""
        }
      ],
      conversionParameters: []
    },
    {
      name: "reactoutletexternalstatic",
      quantity: "Pressure",
      group: "mainselections",
      sortNo: 120,
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      items: [],
      defaultValues: [],
      descriptionTexts: [],
      valueSources: [
        {
          id: "0y5iy229-fm8e-4c54-b16d-5b62c69cf4ae",
          claimFilter: "",
          propertyFilter: PropertyFilter.fromString("sortno!=0")!,
          propertyValueSourceId: "InheritedFromFirstOpCase",
          value: 0,
          parameters: ""
        },
        {
          id: "2tldx773-21p3-41t9-a79b-8e3d2d2d5af0",
          claimFilter: "",
          propertyFilter: PropertyFilter.Empty,
          propertyValueSourceId: "User",
          value: 1,
          parameters: ""
        }
      ],
      conversionParameters: []
    }
  ]
};
