import React from "react";
import styled from "../../styled-components";
import { Label } from "../../atoms/typography";

// tslint:disable-next-line
export const StyledTable = styled.table<{
  readonly alignHeader: "left" | "center" | "right";
  readonly alignRows: "left" | "center" | "right";
}>`
  letter-spacing: 0;
  font-size: 13px;

  th {
    text-align: ${props => props.alignHeader};
    padding-left: 7px;
    vertical-align: top;
  }

  tr:nth-child(odd) {
    td {
      background-color: #f7f9fc;
    }
  }
  tr {
    td {
      /* color: #959695; */
      font-size: 13px;
      line-height: 17px;
      text-align: ${props => props.alignRows};
      height: 23px;
      padding-left: 15px;
      padding-right: 10px;
    }
  }
`;

const StyledDiv = styled.div`
  span:nth-child(2) {
    white-space: nowrap;
  }
`;

const StyledLabel = styled(Label)<{
  readonly isHidden: boolean | undefined;
}>`
  ${props => props.isHidden && "border: 1px solid red;"}
`;

interface Row {
  readonly name?: React.ReactNode;
  readonly amountFormatSelector?: RenderAmountFormatSelector;
  readonly isBold?: boolean;
  readonly isHidden?: boolean;
  readonly results: ReadonlyArray<string | JSX.Element>;
}

interface Props {
  readonly rows: ReadonlyArray<Row>;
  readonly alignHeader?: "left" | "center" | "right";
  readonly alignRows?: "left" | "center" | "right";
  readonly headers?: ReadonlyArray<{
    readonly header: React.ReactNode;
    readonly visible: boolean;
    readonly amountFormatSelector?: RenderAmountFormatSelector;
  }>;
}

type RenderAmountFormatSelector = () => JSX.Element | null;

export function ReadOnlyTable({
  headers,
  rows,
  alignHeader = "left",
  alignRows = "left"
}: Props) {
  return (
    <StyledTable alignRows={alignRows} alignHeader={alignHeader}>
      {headers && headers.length > 0 && (
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th
                key={i}
                style={{ visibility: header.visible ? "visible" : "hidden" }}
              >
                <StyledDiv>
                  <span> {header.header} </span>
                  <span>
                    {header.amountFormatSelector
                      ? header.amountFormatSelector()
                      : null}
                  </span>
                </StyledDiv>
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>
        {rows.map((row, i) => (
          <tr key={`${i}`}>
            {row.name && (
              <td>
                <StyledLabel
                  isHidden={row.isHidden}
                  color="light"
                  weight={row.isBold ? "bold" : "normal"}
                >
                  {row.name}
                  {row.amountFormatSelector ? row.amountFormatSelector() : null}
                </StyledLabel>
              </td>
            )}

            {row.results.map((result, i) => (
              <td key={`${result}${i}`}>
                <Label color="light" weight={row.isBold ? "bold" : "normal"}>
                  {result}
                </Label>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}
