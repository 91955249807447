import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  width: 960px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 18px 0;
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
`;

export const LeftContainer = styled.div`
  width: 50%;
`;

export const RightContainer = styled.div`
  width: 50%;
  height: 180px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 24px 0px 0px 30px;
`;

export const SettingContainer = styled.div`
  margin-top: 36px;
  p {
    margin-bottom: 6px;
  }
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  height: 26px;
  margin-top: 36px;
  padding: 0 6px 0 2px;
  border-radius: 10px;
  background-color: white;
  :hover {
    cursor: pointer;
    background-color: ${PrimaryColors.lightBlue};
  }
`;
