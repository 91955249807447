import gql from "graphql-tag";

export const setSystemMoistureLoadId = gql`
  mutation SetSystemMoistureLoadId($moistureLoadId: ID, $systemId: ID!) {
    setSystemMoistureLoadId(
      moistureLoadId: $moistureLoadId
      systemId: $systemId
    )
  }
`;
