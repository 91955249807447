import React from "react";
import styled from "../../styled-components";

// tslint:disable-next-line
const Span = styled.span`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #8f9bb3;
  background-color: #f7f9fc;

  :hover {
    ${(props: Props) => !props.disabled && " background-color: #ccc;"};
  }

  :after {
    content: "";
    position: absolute;

    top: 2px;
    left: 2.3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props: Props) => (props.checked ? "#00ADF2" : "#f7f9fc")};
  }
`;
// tslint:disable-next-line
const LabelContainer = styled.label<{ readonly disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  ${props => !props.disabled && " color: #3d3d3d;"};
  ${props => props.disabled && " opacity: 0.4;"};
  letter-spacing: 0;
  line-height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

// tslint:disable-next-line
const StyledCheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface Props {
  readonly checked: boolean;
  readonly disabled: boolean;
}

export interface Values {
  readonly value: string;
  readonly name: string;
}

// tslint:disable-next-line
export function RadioButton({
  children,
  isDisabled,
  onChange,
  checked
}: {
  readonly children: React.ReactNode;
  readonly checked: boolean;
  readonly isDisabled?: boolean;
  readonly onChange: () => void;
}) {
  return (
    <LabelContainer disabled={!!isDisabled}>
      {children}
      <StyledCheckBox
        type="radio"
        onClick={event => {
          if (isDisabled) {
            return;
          }
          event.preventDefault();
          event.stopPropagation();
          onChange();
        }}
      />
      <Span disabled={!!isDisabled} checked={checked}></Span>
    </LabelContainer>
  );
}
