import * as GraphQLTypes from "@genesys/graphql-types";
import * as Authorization from "@genesys/shared/lib/authorization";

export function getPrimarySalesOrganisation(
  salesOrganisations: ReadonlyArray<
    GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["salesOrganisations"][0]
  >,
  defaultSalesOrganisation: string | undefined,
  applicationClaims: Authorization.ApplicationClaims
):
  | GraphQLTypes.UserProfileProviderUserData["user"]["userProfile"]["salesOrganisations"][0]
  | undefined {
  const primarySalesOrganisationFromClaim = Authorization.getClaimValues(
    applicationClaims,
    Authorization.genesysUserClaims.primarySalesOrganisation
  );
  return (
    salesOrganisations.find(so => so.id === defaultSalesOrganisation) ||
    salesOrganisations.find(
      so => so.id === primarySalesOrganisationFromClaim![0]
    )
  );
}
