import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { Unit, Quantity } from "@genesys/uom";
import * as PropertiesSelector from "../../../../properties-selector";
import * as GraphQlTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import {
  initialiseEmissionsSelector,
  getDefaultElectricEmissionFactor
} from "./functions";
import { getEnergyTotalItemsWithVisibility } from "../functions";

export type State = {
  readonly emissionsSelectorState: PropertiesSelector.State | undefined;
  readonly initialSettings: PropertyValueSet.PropertyValueSet;
  readonly showElectricity: boolean;
  readonly showGas: boolean;
  readonly showSteam: boolean;
  readonly showHeatingWater: boolean;
  readonly showCoolingWater: boolean;
  readonly showHumidifierWater: boolean;
};

export const init = (
  system: System.System,
  productData: GraphQlTypes.EnergyInputQueryProduct,
  binCountry: string
): [State] => {
  const energyItems = getEnergyTotalItemsWithVisibility(
    productData.product.systemType.energyTotals,
    system.components
  );

  const showElectricity = energyItems.some(
    ei =>
      ei.shouldBeVisible &&
      ei.costType === GraphQlTypes.EnergyCostType.ELECTRICITY_COST
  );

  const showGas = energyItems.some(
    ei =>
      ei.shouldBeVisible && ei.costType === GraphQlTypes.EnergyCostType.GAS_COST
  );

  const showSteam = energyItems.some(
    ei =>
      ei.shouldBeVisible &&
      ei.costType === GraphQlTypes.EnergyCostType.STEAM_COST
  );

  const showHeatingWater = energyItems.some(
    ei =>
      ei.shouldBeVisible &&
      ei.costType === GraphQlTypes.EnergyCostType.HEATING_WATER_COST
  );

  const showCoolingWater = energyItems.some(
    ei =>
      ei.shouldBeVisible &&
      ei.costType === GraphQlTypes.EnergyCostType.COOLING_WATER_COST
  );

  const showHumidifierWater = energyItems.some(
    ei =>
      ei.shouldBeVisible &&
      ei.costType === GraphQlTypes.EnergyCostType.HUMIDIFIER_WATER_COST
  );

  const defaultElectricEmissionFactor = getDefaultElectricEmissionFactor(
    binCountry,
    productData.product.co2EmissionIndexes
  );

  const emissionsSelectorState = initialiseEmissionsSelector(
    system,
    defaultElectricEmissionFactor
  );

  const initialSettings = emissionsSelectorState
    ? PropertiesSelector.getSelectedProperties(emissionsSelectorState)
    : PropertyValueSet.Empty;

  return [
    {
      emissionsSelectorState: emissionsSelectorState,
      initialSettings: initialSettings,
      showElectricity: showElectricity,
      showCoolingWater: showCoolingWater,
      showGas: showGas,
      showHeatingWater: showHeatingWater,
      showSteam: showSteam,
      showHumidifierWater: showHumidifierWater
    }
  ];
};

export const Action = ctorsUnion({
  dispatchEmissionsPropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchEmissionsPropertiesSelector": {
      if (state.emissionsSelectorState === undefined) {
        return [state];
      }

      const [
        propertiesSelectorStates,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.emissionsSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          emissionsSelectorState: propertiesSelectorStates
        },
        Cmd.map(
          Action.dispatchEmissionsPropertiesSelector,
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

export function getEnergyEmissionsSettings(state: State) {
  if (!state.emissionsSelectorState) {
    return PropertyValueSet.Empty;
  }
  return PropertiesSelector.getSelectedProperties(state.emissionsSelectorState);
}

export function getInitialEmissionSettings(state: State) {
  return state.initialSettings;
}
