import * as React from "react";
import { WorkspaceArea } from "../elements";
import * as Diagram from "./diagram";
import * as PerfomanceOverview from "../performance-overview";

export interface Props {
  readonly diagramProps: Diagram.DiagramProps;
  readonly performanceOverviewProps: PerfomanceOverview.Props;
}

export function WorkArea(props: Props): JSX.Element {
  return (
    <WorkspaceArea>
      <Diagram.Diagram {...props.diagramProps} />
      <PerfomanceOverview.PerformanceOverviewView
        {...props.performanceOverviewProps}
      />
    </WorkspaceArea>
  );
}
