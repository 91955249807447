import * as uuid from "uuid";

export interface Guid {
  readonly value: string;
}

export function guidToString(guid: Guid): string {
  return guid.value;
}

export function guidFromString(guid: string): Guid {
  return {
    value: guid
  };
}

export function createEmptyGuid(): Guid {
  return {
    value: "00000000-0000-0000-0000-000000000000"
  };
}

export function createGuid(): Guid {
  return {
    value: uuid.v4()
  };
}

export function guidEquals(a: Guid | string, b: Guid | string) {
  const g1 = isGuid(a) ? a : guidFromString(a);
  const g2 = isGuid(b) ? b : guidFromString(b);
  return g1.value.toUpperCase() === g2.value.toUpperCase();
}

function isGuid(value: any): value is Guid {
  if (value.value && typeof value.value === "string") {
    return true;
  }

  return false;
}
