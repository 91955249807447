import gql from "graphql-tag";

export const deleteSystemFileMutation = gql`
  mutation DeleteSystemFile($systemFileIds: [ID!]!) {
    deleteSystemFile(systemFileIds: $systemFileIds) {
      errorMessage
      deletionSuccessful
    }
  }
`;
