import gql from "graphql-tag";

export const querySystems = gql`
  query SystemsInfoQuery($ids: [ID!]!) {
    user {
      systems(ids: $ids) {
        id
        status
        climateSettings
        statusDate
        revisionNo

        systemFile {
          id
          systemTypeId
          genesysNo
          name
        }

        components {
          id
        }

        operatingCases {
          id
          sortNo
          caseType
          caseName
          customCaseName
          settings
        }
      }
    }
  }
`;

export const productQuery = gql`
  query SystemsSummaryProductQuery(
    $systemTypeInput: [GetSystemTypeInputType!]!
    $lol: String!
  ) {
    product {
      systemTypes(input: $systemTypeInput) {
        id
        opc(productId: $lol) {
          id
          properties {
            id
            productId
            sortNo
            name
            validationFilter
            valueSources {
              id
              value
              propertyValueSourceId
              parameters
              propertyFilter
              claimFilter
            }
          }
        }
      }
    }
  }
`;
