import * as React from "react";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import { Img } from "@genesys/ui-elements";
import { clientConfig } from "../../../../../config";

export interface ResultSummaryProduct {
  readonly id: string;
  readonly images: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["images"][0]
  >;
  readonly screen_OpCaseResults?:
    | GraphQlTypes.ResultSummmaryQuery["product"]["product"]["screen_OpCaseResults"]
    | null;

  readonly properties: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["properties"][0]
  >;
  readonly report_TechnicalData_OpCase?:
    | GraphQlTypes.ResultSummmaryQuery["product"]["product"]["report_TechnicalData_OpCase"]
    | null
    | undefined;
  readonly screen_ResultSummary_CaseOrder?:
    | GraphQlTypes.ResultSummmaryQuery["product"]["product"]["screen_ResultSummary_CaseOrder"]
    | null
    | undefined;
  readonly imageDataMappings: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["imageDataMappings"][0]
  >;
  readonly caseFilter?:
    | GraphQlTypes.ResultSummmaryQuery["product"]["product"]["caseFilter"]
    | null
    | undefined;
}

interface Props {
  readonly product: ResultSummaryProduct;
  readonly sharedState: SharedState.State;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
}

export function ProductImageView(props: Props) {
  const routeEndpoint = clientConfig.genesysBackend;
  const image = props.product.images.find(
    i =>
      i.imageUsage === GraphQlTypes.ImageUsage.DESIGN_PERF_PARAMS &&
      i.name === "Design" &&
      PropertyFilterHelpers.isValid(
        i.propertyFilter,
        PropertyFilter.Empty,
        props.selectedProperties
      )
  );

  if (!image) {
    return null;
  }

  return (
    <Img
      accessToken={props.sharedState.accessToken}
      src={
        routeEndpoint +
        image.url +
        `${
          image.imageFormat === GraphQlTypes.ImageFormat.SVG
            ? "?useFixedSize=true"
            : ""
        }`
      }
    />
  );
}
