import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type P2Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledP2 = styled.p<P2Props>`
  font-size: 0.867rem;
  font-weight: 300;
  line-height: 1.133rem;
  letter-spacing: 0;

  ${({ weight = "light", color = "dark" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function P2(props: P2Props): JSX.Element {
  return <StyledP2 {...props} />;
}
