import gql from "graphql-tag";

export const productQuery = gql`
  query EnergyInputQueryProduct($systemType: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemType) {
        id

        energyTotals {
          id
          name
          sortNo
          costType
          quantityId
          visibilityFilter
        }
      }

      co2EmissionIndexes {
        year
        country
        emissionIndex
      }

      binDataLocations {
        binDataLocationId
        locationName
        regionName
        countryName
        latitude
        longitude
      }
      binDataHourlyVersion
    }
  }
`;

export const moistureLoadBinsQuery = gql`
  query MoistureLoadBinCases($id: ID!) {
    user {
      moistureLoad(id: $id) {
        id
        revisionNo
        moistureloadFile {
          id
          moistureLoadNo
        }
        moistureLoadResult {
          diagramResults
        }
      }
    }
  }
`;

export const generateBinCasesQuery = gql`
  query GenerateBinCases($input: String!) {
    product {
      climateDataBins(input: $input) {
        id
        binId
        midPointTemp
        midPointWetTemp
        midPointDewPointTemp
        midPointSpecificEnthalpy
        midPointHumidityRatio
        midPointHourly
        averageTemp
        humidity
        wind
        binPressure
        binTime
        binTimeJanuary
        binTimeFebruary
        binTimeMarch
        binTimeApril
        binTimeMay
        binTimeJune
        binTimeJuly
        binTimeAugust
        binTimeSeptember
        binTimeOctober
        binTimeNovember
        binTimeDecember
      }
      psycProClimateDataBins(input: $input) {
        id
        binId
        time
        temperature
        humidity
      }
    }
  }
`;
