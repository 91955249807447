import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "../language-texts";
import { removeWhitespaces } from "./remove-white-spaces";

const rangeTypePropertyName = "rangetype";
const dhmodelPropertyName = "dhmodel";

export function getText(
  translate: LanguageTexts.Translate,
  sysComponentProperties: string | undefined,
  dhuComponentProperties: string | undefined,
  systemTypeId: string,
  plenumSizeProperty: string
): string {
  if (!sysComponentProperties) {
    return "";
  }

  const sysProps = PropertyValueSet.fromString(sysComponentProperties);
  const size = PropertyValueSet.getInteger(plenumSizeProperty, sysProps);
  const sizeText = removeWhitespaces(
    `${translate(
      LanguageTexts.systemTypeProperty(systemTypeId, plenumSizeProperty)
    )}=${translate(
      size !== undefined
        ? LanguageTexts.systemTypePropertyValue(
            systemTypeId,
            plenumSizeProperty,
            size
          )
        : LanguageTexts.pNull()
    )}`
  );

  const rangeType = PropertyValueSet.getInteger(
    rangeTypePropertyName,
    sysProps
  );
  const rangeTypeText = removeWhitespaces(
    translate(
      rangeType !== undefined
        ? LanguageTexts.systemTypePropertyValue(
            systemTypeId,
            rangeTypePropertyName,
            rangeType
          )
        : LanguageTexts.pNull()
    )
  );

  if (!dhuComponentProperties) {
    return [rangeTypeText, sizeText].join(", ");
  }

  const dhuProps = PropertyValueSet.fromString(dhuComponentProperties);
  const dhmodel = PropertyValueSet.getInteger(dhmodelPropertyName, dhuProps);
  const dhText = removeWhitespaces(
    `${translate(
      LanguageTexts.systemTypeProperty(systemTypeId, dhmodelPropertyName)
    )}=${translate(
      dhmodel !== undefined
        ? LanguageTexts.systemTypePropertyValue(
            systemTypeId,
            dhmodelPropertyName,
            dhmodel
          )
        : LanguageTexts.pNull()
    )}`
  );

  return [rangeTypeText, sizeText, dhText].join(", ");
}
