import * as Actions from "./actions";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as R from "ramda";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";

const initialState: GenesysPropertiesSelectorTypes.State = {};

export function reducer(
  state: GenesysPropertiesSelectorTypes.State = initialState,
  action: Actions.Action
): GenesysPropertiesSelectorTypes.State {
  switch (action.type) {
    case "genesys-properties-selector/TOGGLE_GROUP": {
      const settings = state[action.propertiesSelectorId];

      if (!settings) {
        const propertiesSelectorSettings: GenesysPropertiesSelectorTypes.Settings = {
          ...getInitialSettings(),
          closedGroups: { [action.groupName]: true }
        };
        return R.assocPath(
          [action.propertiesSelectorId],
          propertiesSelectorSettings,
          state
        );
      }

      if (!settings.closedGroups[action.groupName]) {
        return R.assocPath(
          [action.propertiesSelectorId, "closedGroups", action.groupName],
          true,
          state
        );
      }

      return R.dissocPath(
        [action.propertiesSelectorId, "closedGroups", action.groupName],
        state
      );
    }
    case "genesys-properties-selector/TOGGLE_QUANTITY_SELECTOR": {
      const settings = state[action.propertiesSelectorId];

      if (!settings) {
        const propertiesSelectorSettings: GenesysPropertiesSelectorTypes.Settings = {
          ...getInitialSettings(),
          activatedQuantitySelectors: { [action.propertyName]: true }
        };
        return R.assocPath(
          [action.propertiesSelectorId],
          propertiesSelectorSettings,
          state
        );
      }

      if (!settings.activatedQuantitySelectors[action.propertyName]) {
        return R.assocPath(
          [
            action.propertiesSelectorId,
            "activatedQuantitySelectors",
            action.propertyName
          ],
          true,
          state
        );
      }

      return R.dissocPath(
        [
          action.propertiesSelectorId,
          "activatedQuantitySelectors",
          action.propertyName
        ],
        state
      );
    }
    default: {
      exhaustiveCheck(action);
    }
  }
  return state;
}

function getInitialSettings(): GenesysPropertiesSelectorTypes.Settings {
  return {
    closedGroups: {},
    activatedQuantitySelectors: {}
  };
}
