import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  width: 380px;

  a {
    display: block;
    margin-bottom: 10px;
    color: inherit;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const fadeText = keyframes`

from {color: #16181e;}
  to {color: #f3bf15;}

`;

export const HeaderLabel = styled.label<{
  readonly highlighted: boolean;
}>`
  color: #16181e;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;

  ${props =>
    props.highlighted &&
    css`
      /* color: #f3bf15; */
      animation: ${fadeText} 1s infinite;
    `}
`;

export const Content = styled.div``;

export const StyledP = styled.p`
  /* color: #646f86; */
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    display: inline-block;
    color: #2b89ed;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const StyledDiv = styled.div`
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const fadeBorder = keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color:  #f3bf15;
  }
  100% {
    border-color: transparent;
  }
`;

export const NewsItem = styled.div<{
  readonly highlighted: boolean;
}>`
  margin-bottom: 10px;
  ${props =>
    props.highlighted &&
    css`
      border: 1.5px solid yellow;
      border-radius: 9px;
      animation: ${fadeBorder} 2s infinite;
    `}
`;
