export type Action = SetDebugSettings | Dummy;

interface SetDebugSettings {
  readonly type: "SetDebugSettings";
  readonly includeServerLog: boolean;
  readonly showHiddenProperties: boolean;
  readonly showHiddenResults: boolean;
}

export function setDebugSettings(
  includeServerLog: boolean,
  showHiddenProperties: boolean,
  showHiddenResults: boolean
): SetDebugSettings {
  return {
    type: "SetDebugSettings",
    includeServerLog,
    showHiddenProperties,
    showHiddenResults
  };
}

interface Dummy {
  readonly type: "Dummy";
}
