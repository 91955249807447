import styled from "styled-components";

// tslint:disable-next-line
export const ActionMenuSaveButton = styled.button`
  background: rgba(30, 139, 33, 1);
  display: block;
  height: 30px;
  width: 70px;
  color: #fff;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  margin-top: 10px;

  &:disabled {
    opacity: 0.5;
  }
`;
