import * as React from "react";
import { Quantity, Unit } from "@genesys/uom";
import { PropertyValue } from "@genesys/property";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as ScreenAmountProfile from "../screen-amount-profile";
import { FormatSelectorRenderer } from "./format-selector-renderer";
import { AmountFormatSelectorWithPadding } from "./elements";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as UserProfile from "../user-profile";
import { AmountFormatSelector } from "./amount-format-selector";

type OwnProps = {
  readonly propertyType: PropertyValue.PropertyType | undefined;
  readonly conversionParameters?: QuantityConversion.ConversionParameters;
} & {
  readonly amountFormat: ScreenAmounts.AmountFormat;
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly AmountFormatSelector?: AmountFormatSelector;
};
interface StateProps {
  readonly isQuantitySelectorActive: boolean;
}
interface StateInjectedProps {
  readonly state: StateProps;
  readonly setState: (newState: StateProps) => StateProps;
}

export type Props = OwnProps & StateInjectedProps;

// tslint:disable-next-line:function-name
export function FormatSelectorWrapper({
  AmountFormatSelector = AmountFormatSelectorWithPadding,
  amountFormat,
  conversionParameters,
  fieldGroup,
  fieldName,
  propertyType,
  setState,
  state
}: Props): JSX.Element {
  return (
    <UserProfile.UserProfile>
      {user => (
        <ScreenAmountProfile.ScreenAmountProfile>
          {({
            deleteAmountFieldFormat,
            saveAmountFieldFormat,
            measureSystem,
            quantityDefaults
          }) => {
            const onFormatChanged = (
              unit: Unit.Unit<any>,
              decimalCount: number
            ) => {
              saveAmountFieldFormat(fieldGroup, fieldName, unit, decimalCount);
            };

            const onFormatCleared = () => {
              deleteAmountFieldFormat(fieldGroup, fieldName);
            };

            const onQuantityChange = (quantity: Quantity.Quantity) => {
              const quantityDefault = QuantityConversion.getQuantityDefault(
                quantityDefaults,
                measureSystem,
                quantity
              );
              onFormatChanged(
                quantityDefault.unit,
                quantityDefault.decimalCount
              );
            };

            const title = AuthorizationTools.checkPermission(
              user.applicationClaims,
              AuthorizationTools.genesysUserClaims.showFieldDefaultUnits
            )
              ? "GroupName: " + fieldGroup + "\nFieldName: " + fieldName
              : undefined;

            return (
              <span title={title}>
                <FormatSelectorRenderer
                  AmountFormatSelector={AmountFormatSelector}
                  conversionParameters={conversionParameters}
                  isQuantitySelectorActive={state.isQuantitySelectorActive}
                  propertyType={propertyType}
                  onFormatChanged={onFormatChanged}
                  onFormatCleared={onFormatCleared}
                  onFormatSelectorActiveChanged={active => {
                    setState({
                      isQuantitySelectorActive: active
                    });
                  }}
                  onQuantityChange={onQuantityChange}
                  selectedDecimalCount={amountFormat.decimalCount}
                  selectedUnit={amountFormat.unit}
                  userSelected={amountFormat.userDefined}
                />
              </span>
            );
          }}
        </ScreenAmountProfile.ScreenAmountProfile>
      )}
    </UserProfile.UserProfile>
  );
}

const initialState: StateProps = { isQuantitySelectorActive: false };

const formatSelectorWithState = class extends React.Component<
  OwnProps,
  StateProps
> {
  constructor(props: OwnProps) {
    super(props);
    this.state = initialState;
  }
  render() {
    return (
      <FormatSelectorWrapper
        {...this.props}
        state={this.state}
        setState={this.setState.bind(this)}
      />
    );
  }
};

// tslint:disable-next-line:variable-name
export const FormatSelectorWithMutations = formatSelectorWithState;
