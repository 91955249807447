import * as React from "react";
import * as SharedState from "../../shared-state";
import * as Routes from "../../routes";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  ToolTipWrapper,
  SystemsIcon,
  WithMenu,
  HelpIcon,
  SettingsIcon,
  ProfileIcon
} from "@genesys/ui-elements";
import { StyledA, Home, OpenIcon } from "../elements";
import { UserManager } from "oidc-client";
import { clientConfig } from "../../config";

export function genesysLogoContent(
  logoClicked: () => void,
  enviromentStlye: { readonly logo: JSX.Element }
) {
  return (
    <li>
      <div
        onClick={e => {
          e.stopPropagation();
          logoClicked();
        }}
      >
        {enviromentStlye.logo}
      </div>
    </li>
  );
}

export function homeLogoContent(
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
) {
  return (
    <ToolTipWrapper
      padding="0px"
      title={sharedState.translate(LanguageTexts.globalPropertyName("home"))}
    >
      <StyledA href="/">
        <Home
          state={activeRoute === "Dashboard" ? "active" : undefined}
          height={"29px"}
        />
      </StyledA>
    </ToolTipWrapper>
  );
}

export function newSystemLogoContent(
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
) {
  return (
    <ToolTipWrapper
      padding="0px"
      title={sharedState.translate(LanguageTexts.newsystem())}
    >
      <StyledA href="/new-system">
        <OpenIcon
          height={"33px"}
          width={"38px"}
          state={activeRoute === "Wizard" ? "active" : undefined}
        />
      </StyledA>
    </ToolTipWrapper>
  );
}

export function manageSystemsLogoContent(
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
) {
  return (
    <ToolTipWrapper
      padding="0px"
      title={sharedState.translate(LanguageTexts.systemsmanager())}
    >
      <StyledA href="/system-manager">
        <SystemsIcon
          height={"20px"}
          state={activeRoute === "SystemManager" ? "active" : undefined}
        />
      </StyledA>
    </ToolTipWrapper>
  );
}

export function helpIconContent(sharedState: SharedState.State) {
  return (
    <WithMenu
      icon={<HelpIcon height="23px" />}
      values={[
        {
          value: sharedState.translate(LanguageTexts.wiki()),
          onClick: () => window.open("https://wiki.genesys.munters.com/")
        },
        {
          value: sharedState.translate(LanguageTexts.changeLog()),
          onClick: () =>
            window.open(
              `${clientConfig.wikiJsBaseUrl.replace("/graphql", "/Changelogs")}`
            )
        },
        {
          value: sharedState.translate(LanguageTexts.about()),
          onClick: e =>
            e.ctrlKey ? window.open("/about") : (location.href = "/about")
          // : dispatch(Action.navigate("/about"))
        }
      ]}
    />
  );
}

export function userSettingsIconContent(
  sharedState: SharedState.State,
  activeRoute: Routes.MainLocation["type"]
) {
  return (
    <ToolTipWrapper
      padding="0px"
      title={sharedState.translate(LanguageTexts.usersettings())}
    >
      <StyledA href="/user-settings">
        <SettingsIcon
          state={activeRoute === "UserSettings" ? "active" : undefined}
          height="24px"
        />
      </StyledA>
    </ToolTipWrapper>
  );
}

export function profileIconContent(sharedState: SharedState.State) {
  return (
    <WithMenu
      icon={<ProfileIcon height="25px" />}
      values={[
        {
          value: `${sharedState.translate(LanguageTexts.loggedinuser())}: ${
            sharedState.user.userName
          }`,
          onClick: () => undefined
        },
        {
          value: sharedState.translate(LanguageTexts.requestAccessLevel()),
          onClick: () => window.open("https://stsusers.munters.com/")
        },
        {
          value: sharedState.translate(LanguageTexts.logout()),
          onClick: async () => {
            const manager = new UserManager({
              authority: clientConfig.oidcAuthority,
              client_id: clientConfig.oidcClientId,
              redirect_uri: clientConfig.oidcRedirectUri,
              post_logout_redirect_uri: window.location.origin,
              response_type: clientConfig.oidcResponseType,
              scope: clientConfig.oidcScope,
              monitorSession: false,
              loadUserInfo: false
            });
            await manager.signoutRedirect();
          }
        }
      ]}
    />
  );
}
