import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import { changedFromBoth0, changedToBoth0 } from "../../helper-functions";
import { EvaporationOfWaterIcon } from "@genesys/ui-elements";
import { LoadOperationTime } from "../../types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";

//Icon
export const Icon = EvaporationOfWaterIcon;

// Visibiliy
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty =
  KnownProperties.useEvaporationOfWaterMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useEvaporationOfWaterHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}
export const title = "mlcevaporationofwater";
export const sourceName = MoistureHeatLoadTypeEnum.EVAPORATION_OF_WATER;

export const description = "mlceevaporationofwaterexplanation";
export const useLoadKey = "mlcuseevaporationofwater";

export const manualInputProperty =
  KnownProperties.evaporationOfWaterManualInput;

export const properties = [
  KnownProperties.useEvaporationOfWaterMoistureLoad,
  KnownProperties.useEvaporationOfWaterHeatLoad,
  KnownProperties.evaporationOfWaterManualInput,
  KnownProperties.waterSurfaceArea,
  KnownProperties.waterSurfaceTemperature,
  KnownProperties.airActivityNearSurface,
  KnownProperties.useManualActivityWaterAlpha,
  KnownProperties.activityWaterAlpha
];
export const overridableProperties = [
  KnownProperties.waterSurfaceTemperature,
  KnownProperties.airActivityNearSurface,
  KnownProperties.useManualActivityWaterAlpha,
  KnownProperties.activityWaterAlpha
];
export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(15, Units.Celsius)
    )
  },
  DESIGN_WINTER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(5, Units.Celsius)
    )
  },
  JANUARY: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(5, Units.Celsius)
    )
  },
  FEBRUARY: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(5, Units.Celsius)
    )
  },
  MARCH: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(5, Units.Celsius)
    )
  },
  APRIL: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(7, Units.Celsius)
    )
  },
  MAY: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(7, Units.Celsius)
    )
  },
  JUNE: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(12, Units.Celsius)
    )
  },
  JULY: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(12, Units.Celsius)
    )
  },
  AUGUST: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(15, Units.Celsius)
    )
  },
  SEPTEMBER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(15, Units.Celsius)
    )
  },
  OCTOBER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(12, Units.Celsius)
    )
  },
  NOVEMBER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(7, Units.Celsius)
    )
  },
  DECEMBER: {
    [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
      Amount.create(7, Units.Celsius)
    )
  }
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.allDay;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;
  if (
    changedFromBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEvaporationOfWaterMoistureLoad,
      KnownProperties.useEvaporationOfWaterHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(1),
      defaults
    );
  } else if (
    changedToBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEvaporationOfWaterMoistureLoad,
      KnownProperties.useEvaporationOfWaterHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(0),
      defaults
    );
  }

  defaults = PropertyValueSet.merge(
    setWaterAlpha(PropertyValueSet.merge(defaults, currentSelections)!),
    defaults
  );

  return defaults;
}

function setMoistureAndHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.waterSurfaceArea]: PropertyValue.fromAmount(
          Amount.create(0, Units.SquareMeter)
        ),
        [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
          Amount.create(0, Units.Celsius)
        ),
        [KnownProperties.airActivityNearSurface]: PropertyValue.fromInteger(0),
        [KnownProperties.useManualActivityWaterAlpha]:
          PropertyValue.fromInteger(0),
        [KnownProperties.evaporationOfWaterManualInput]:
          PropertyValue.fromInteger(0)
      };
    case 1:
      return {
        [KnownProperties.waterSurfaceArea]: PropertyValue.fromAmount(
          Amount.create(10, Units.SquareMeter)
        ),
        [KnownProperties.waterSurfaceTemperature]: PropertyValue.fromAmount(
          Amount.create(15, Units.Celsius)
        ),
        [KnownProperties.airActivityNearSurface]: PropertyValue.fromInteger(2),
        [KnownProperties.useManualActivityWaterAlpha]:
          PropertyValue.fromInteger(0),
        [KnownProperties.evaporationOfWaterManualInput]:
          PropertyValue.fromInteger(0)
      };
    default:
      throw new Error("Invalid value.");
  }
}

function setWaterAlpha(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualActivityWaterAlpha,
    selections
  )
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.activityWaterAlpha]: PropertyValue.fromAmount(
          getAlfaValuePerAirActivity(
            PropertyValueSet.getInteger(
              KnownProperties.airActivityNearSurface,
              selections
            ) || 0
          )
        )
      };
}

function getAlfaValuePerAirActivity(value: number) {
  return Amount.create(
    [0, 2, 4, 6][value] || 0,
    Units.WattPerSquareMeterPerKelvin
  );
}
