import * as React from "react";
import * as SharedState from "../shared-state";
import * as ClimateSettingsEditor from "./components/climate-settings-editor";
import * as TopMenuContent from "./top-menu-content";
import * as PropertiesSelector from "../properties-selector";
import * as Main from "./main-content";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { PropertyValueSet } from "@genesys/property";
import { createCalculationinputForReport } from "./tools";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";
import { ModalContent } from "./components";
import { clientConfig } from "../config";
import {
  OverlayLoader,
  H3,
  HelpIcon,
  ToolTipWrapper
} from "@genesys/ui-elements";

const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const NameHeader = styled(H3)`
  margin-top: 2px;
  margin-bottom: 8px;
  letter-spacing: 10px;
  font-size: 17px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const Error = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

export const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export function View({
  state,
  sharedState,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.errorMessage) {
    return (
      <ErrorContainer>
        <Error>{state.errorMessage}</Error>
      </ErrorContainer>
    );
  }

  if (!state.userQuery || !state.productQueryMoistureLoad) {
    return <OverlayLoader />;
  }

  const moistureLoadItem = state.userQuery.user.moistureLoadByMoistureLoadNo!;

  const isDeleted = moistureLoadItem.moistureloadFile.isDeleted;

  const isDisabled =
    state.calculating ||
    moistureLoadItem?.status === MoistureLoadStatus.LockSuccess ||
    isDeleted;

  const savedMoistureLoadInputs = moistureLoadItem?.moistureloadInput;

  const climateSettings =
    ClimateSettingsEditor.getClimateSettings(
      state.climateSettingsEditorState!
    ) ||
    PropertyValueSet.fromString(savedMoistureLoadInputs?.climateSettings || "");

  const calculationInput = createCalculationinputForReport(
    PropertiesSelector.getSelectedProperties(state.propertiesSelectorState!),
    state
  );

  const wikiJsUrl = clientConfig.wikiJsBaseUrl.replace("/graphql", "");
  const moistureLoadWikiPage = wikiJsUrl + "/MoistureLoadCalculation";

  return (
    <>
      {state.showLoader && <OverlayLoader />}
      <Container>
        <NameHeader>
          MOISTURE LOAD CALC{" "}
          <ToolTipWrapper padding="0px" title={"Go to wiki page"}>
            <HelpIcon onClick={() => window.open(moistureLoadWikiPage)} />
          </ToolTipWrapper>{" "}
        </NameHeader>
        <ModalContent
          state={state}
          sharedState={sharedState}
          dispatch={dispatch}
        />
        <TopMenuContent.View
          isDeleted={isDeleted}
          state={state}
          sharedState={sharedState}
          climateSettings={climateSettings}
          dispatch={dispatch}
          isDisabled={isDisabled}
          moistureLoadItem={moistureLoadItem}
          calculationInput={calculationInput}
        />
        <Main.View
          climateSettings={climateSettings}
          calculationInput={calculationInput}
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            )
          }
          onFormatCleared={(fieldGroup, fieldName) =>
            dispatch(Action.onFormatCleared(fieldGroup, fieldName))
          }
          isDisabled={isDisabled}
          dispatch={Dispatch.map(Action.dispatchMainContent, dispatch)}
          sharedState={sharedState}
          state={state.mainContentState!}
          currentSystemOverrides={state.currentSystemOverrides!}
          disableOpcButtons={
            moistureLoadItem?.status !== MoistureLoadStatus.LockSuccess
          }
          isCalculating={state.calculating}
          moistureLoadClaimValue={state.moistureLoadClaimValue!}
          moistureLoadItem={state.userQuery?.user.moistureLoadByMoistureLoadNo!}
          propertieSelectorDispatch={Dispatch.map(
            action => Action.dispatchPropertiesSelector(action, "load-types"),
            dispatch
          )}
          propertiesSelectorState={state.propertiesSelectorState!}
          propertyDefinitions={state.propertyDefinitions!}
          calcResult={state.calcResult}
        />
      </Container>
    </>
  );
}
