import * as React from "react";
import styled from "styled-components";

// tslint:disable-next-line
const Label = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  background: linear-gradient(
    to bottom right,
    white 10%,
    rgba(255, 228, 128, 255)
  );
  border: 1px solid #333;
  border-radius: 10px;
  font-size: 12px;
  color: #000;
`;
// tslint:disable-next-line:variable-name
const TextContainer = styled.div`
  padding: 5px 6px;
`;
// tslint:disable-next-line:variable-name
const DeleteContainer = styled.div`
  padding-right: 5px;
  color: #808080;
  font-size: 10px;

  &:hover {
    cursor: pointer;
    color: #f00;
  }
`;

interface Props {
  readonly text: string;
  readonly onDelete: () => void;
}

// tslint:disable-next-line:function-name
export function DeleteableLabel({ onDelete, text }: Props): JSX.Element {
  return (
    <Label>
      <TextContainer>{text}</TextContainer>
      <DeleteContainer onClick={onDelete}>X</DeleteContainer>
    </Label>
  );
}
