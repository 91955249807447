import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  OuterContainer,
  PropertyNamesContainer,
  InnerContainer,
  OutputContainer
} from "./elements";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as PropertiesSelector from "../../properties-selector";
import { Quantity } from "@genesys/uom";
import { P1, H2 } from "@genesys/ui-elements";
import { AmountPropertySelector } from "../../amount-property-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export function HumidityConversionToolView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "temperature",
    PropertiesSelector.getSelectedProperties(state.input)
  )!;

  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "pressure",
    PropertiesSelector.getSelectedProperties(state.input)
  )!;

  return (
    <Root>
      <OuterContainer>
        <H2 weight="normal" color="primary">
          {sharedState.translate(LanguageTexts.humidityConversion())}
        </H2>
        <InnerContainer>
          <PropertyNamesContainer>
            <P1 weight="normal" color="dark">
              Pressure
            </P1>
            <P1 weight="normal" color="dark">
              Temperature
            </P1>
            <P1 weight="normal" color="dark">
              Humidity
            </P1>
            <P1 weight="normal" color="dark">
              Density
            </P1>
          </PropertyNamesContainer>
          <PropertiesSelector.PropertiesSelectorView
            dispatch={Dispatch.map(Action.dispatchInput, dispatch)}
            productId={"HumidityConversionInput"}
            sharedState={sharedState}
            fieldGroup={"HumidityConversionInput"}
            propertiesInfo={humidityInputDefinition()}
            isReadonly={false}
            showCodes={false}
            state={state.input}
            hidePropertyNames={true}
          />

          <OutputContainer>
            {humidityOutputDefinition().map(h => (
              <AmountPropertySelector
                type="with-simplified-props"
                key={h.name}
                translate={sharedState.translate}
                quantity="HumidityRatio"
                fieldGroup="HumidityConversionOutput"
                fieldName={h.name}
                readOnly={true}
                propertyName={h.name}
                propertyValueSet={state.output}
                onFormatChanged={(unit, decimalCount) =>
                  dispatch(
                    Action.onFormatChanged(
                      "HumidityConversionOutput",
                      h.name,
                      unit,
                      decimalCount
                    )
                  )
                }
                onFormatCleared={() =>
                  dispatch(
                    Action.onFormatCleared("HumidityConversionOutput", h.name)
                  )
                }
                //tslint:disable-next-line
                onValueChange={() => {}}
                conversionParameters={{
                  temperature: temperature,
                  pressure: pressure
                }}
                getAmountFormat={sharedState.screenAmounts.getAmountFormat}
              />
            ))}
          </OutputContainer>
        </InnerContainer>
      </OuterContainer>
    </Root>
  );
}

function humidityInputDefinition(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "pressure",
      group: "",
      quantity: "Pressure",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 2,
      name: "temperature",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 3,
      name: "humidity",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 4,
      name: "density",
      group: "",
      quantity: "Density",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    }
  ];
}

function humidityOutputDefinition(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "humidity1",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 2,
      name: "humidity2",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 3,
      name: "humidity3",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 4,
      name: "humidity4",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 5,
      name: "humidity5",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    }
  ];
}
