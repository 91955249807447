import * as React from "react";
import { P1 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import * as Product from "../../../product";
import * as Diagram from "../../../components/diagram";
import * as PerformanceOverview from "../../../performance-overview";
import { State, Action } from "../state";
import { CenteringContainer, PerformanceViewRoot } from "../elements";
import { ComponentMessages } from "../../component-editor/component-messages";
import { OperatingCaseResult } from "@genesys/shared/lib/components-messages";

export function PerformanceView({
  state,
  system,
  productData,
  sharedState,
  sysProperties,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly sharedState: SharedState.State;
  readonly productData: Product.ProductData;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  // tslint:disable-next-line
  const emptyFunc = () => {};

  const operatingCaseResults: ReadonlyArray<OperatingCaseResult> =
    state.operatingCaseResults.map(opc => ({
      id: opc.id,
      calculationType: opc.results[0].calculationType,
      operatingCase: {
        caseName: opc.caseName,
        customCaseName: opc.customCaseName ?? undefined,
        id: "",
        sortNo: 0
      },
      caseType: opc.caseType,
      componentId: ""
    }));

  return (
    <PerformanceViewRoot>
      {state.componentsMessages.length > 0 && (
        <ComponentMessages
          componentMessages={state.componentsMessages}
          sharedState={sharedState}
          products={productData.products}
          systemTypeId={productData.systemType.id}
          operatingCaseResults={operatingCaseResults}
          showProductName={true}
        />
      )}

      {state.calculationResultedInException && (
        <CenteringContainer>
          <P1 weight="bold">
            {sharedState.translate(LanguageTexts.serviceCalculationFailed())}
          </P1>
        </CenteringContainer>
      )}

      {state.operatingCaseResults.length > 0 && (
        <div>
          <Diagram.Diagram
            system={system}
            productData={productData}
            sharedState={sharedState}
            mode={{ type: "DefaultMode" }}
            diagramHoverState={"no-hover"}
            selectedAirPosition={undefined}
            claimFilteredComponentsMessages={[]}
            symbols={productData.systemType.symbols}
            openNotes={emptyFunc}
            addToDiagram={emptyFunc}
            editComponent={emptyFunc}
            moveComponent={emptyFunc}
            selectComponent={emptyFunc}
            openAccessories={emptyFunc}
            deleteComponent={emptyFunc}
            exchangeComponent={emptyFunc}
            selectComponentMenu={emptyFunc}
            setDiagramHoverState={emptyFunc}
            moveComponentSection={emptyFunc}
            setGenericDialogState={emptyFunc}
            setSelectedAirPosition={emptyFunc}
          />
          <PerformanceOverview.PerformanceOverviewView
            systemType={productData}
            sharedState={sharedState}
            sysProperties={sysProperties}
            selectedAirPosition={undefined}
            state={state.performanceOverviewState}
            systemItem={{
              ...system,
              operatingCases: state.operatingCaseResults
            }}
            onAirPositionClick={emptyFunc}
            dispatch={Dispatch.map(Action.dispatchPeformanceOverview, dispatch)}
          />
        </div>
      )}
    </PerformanceViewRoot>
  );
}
