import * as AirPositions from "./air-positions";

type Component = AirPositions.Component & {
  readonly accessoryToId?: string | undefined;
  readonly productId: string;
  readonly sortNo: number;
};

export function getComponentOrder(
  diagramType: "FlowDiagram" | "UnitConfiguration",
  components: ReadonlyArray<Component>,
  airstreams: ReadonlyArray<AirPositions.Airstream>,
  connectionPoints: ReadonlyArray<AirPositions.ConnectionPoint>
): ReadonlyArray<string> {
  const componentOrder = new Set<string>();
  const airPositions = AirPositions.getAirPositionInternal(
    diagramType,
    components,
    airstreams,
    connectionPoints,
    false,
    true
  );

  for (const airPosition of airPositions) {
    const idsToAdd: Array<string> = [];

    if (airPosition.prevComponentId !== undefined) {
      idsToAdd.push(airPosition.prevComponentId);
    }

    if (airPosition.nextComponentId !== undefined) {
      idsToAdd.push(airPosition.nextComponentId);
    }

    for (const idToAdd of idsToAdd) {
      if (!componentOrder.has(idToAdd)) {
        componentOrder.add(idToAdd);
        for (const accessory of components.filter(
          c => c.accessoryToId === idToAdd
        )) {
          componentOrder.add(accessory.id);
        }
      }
    }
  }

  //Get all casing components.
  const casingComponents = components
    .filter(
      c =>
        !componentOrder.has(c.id) &&
        !c.accessoryToId &&
        c.productId.endsWith("UCS")
    )
    .sort(c => c.sortNo);

  for (const component of casingComponents) {
    componentOrder.add(component.id);
    for (const accessory of components.filter(
      c => c.accessoryToId === component.id
    )) {
      componentOrder.add(accessory.id);
    }
  }

  //Get all remaining components not yet added .
  const remainingComponents = components
    .filter(c => !componentOrder.has(c.id) && !c.accessoryToId)
    .sort(
      (a, b) => a.sortNo - b.sortNo || a.productId.localeCompare(b.productId)
    );

  for (const component of remainingComponents) {
    componentOrder.add(component.id);
    for (const accessory of components.filter(
      c => c.accessoryToId === component.id
    )) {
      componentOrder.add(accessory.id);
    }
  }

  return Array.from(componentOrder);
}
