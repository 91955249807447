import * as React from "react";
import * as R from "ramda";
import { PropertyValueSet, PropertyFilter, compare } from "@genesys/property";
import { Unit, UnitsFormat, Units, unitLookup } from "@genesys/uom";
import * as PropertiesSelector from "../react-properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { DispatchProp, Dispatch } from "../redux-integration";
import * as Contexts from "../contexts";
import * as GenesysPropertiesSelectorTypes from "../genesys-properties-selector-types";
import * as Actions from "./actions";
import { createFilterPrettyPrint } from "../filter-pretty-print";
import { createOnQuantityChange } from "./quantity-conversion";
import { ContextProvider } from "./context-provider";

interface OwnProps {
  readonly activatedQuantitySelectors: GenesysPropertiesSelectorTypes.ActivatedQuantitySelectors;
  readonly lockSingleValidValue: boolean;
  readonly closedGroups: GenesysPropertiesSelectorTypes.ClosedGroups;
  readonly customTranslation?: GenesysPropertiesSelectorTypes.CustomTranslation;
  readonly includeHiddenProperties: boolean;
  readonly includeCodes?: boolean;
  readonly hidePropertyNames?: boolean;
  readonly layoutRenderer: (
    props: PropertiesSelector.LayoutRendererProps
  ) => JSX.Element;
  readonly measureSystem: number;
  readonly onChange: (
    properties: PropertyValueSet.PropertyValueSet,
    changedProperties: ReadonlyArray<string>
  ) => void;
  readonly onPropertyFormatChanged: (
    fieldName: string,
    unit: Unit.Unit<any>,
    decimalCount: number
  ) => void;
  readonly onPropertyFormatCleared: (fieldName: string) => void;
  readonly originalProductProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>;
  readonly getAmountFormat: ScreenAmounts.GetAmountFormat;
  readonly propertyFormats: PropertiesSelector.PropertyFormats;
  readonly quantityDefaults: ScreenAmounts.QuantityDefaultsMap;
  readonly readOnlyProperties?: ReadonlyArray<string>;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly sections:
    | { readonly [key: string]: { readonly groups: ReadonlyArray<string> } }
    | undefined;
  readonly uniqueId: string;
  readonly onDelete: () => void;
  readonly fieldGroup: string;
  readonly valueSources?: {
    readonly [
      propertyName: string
    ]: ReadonlyArray<GenesysPropertiesSelectorTypes.ValueSource>;
  };
  readonly groupFilter: ReadonlyArray<string>;
  readonly showGroups: boolean;
  readonly showDelete: boolean;
}

export type Props = OwnProps & DispatchProp<Actions.Action>;

// tslint:disable-next-line:function-name
export function GenesysPropertiesSelectorComponent({
  lockSingleValidValue,
  activatedQuantitySelectors,
  closedGroups,
  customTranslation,
  dispatch,
  hidePropertyNames = false,
  includeCodes,
  includeHiddenProperties,
  layoutRenderer,
  measureSystem,
  onChange,
  onPropertyFormatChanged,
  onPropertyFormatCleared,
  originalProductProperties,
  getAmountFormat,
  groupFilter,
  propertyFormats,
  quantityDefaults,
  readOnlyProperties = [],
  sections,
  selectedProperties,
  uniqueId,
  onDelete,
  fieldGroup,
  valueSources,
  showGroups,
  showDelete
}: Props) {
  return (
    <Contexts.TranslateContext.Consumer>
      {translate => {
        const translateGroupName = (groupName: string) => {
          return translate(LanguageTexts.globalPropertyName(groupName));
        };

        const defaultTranslation = {
          propertyName: (
            propertyName: string,
            translateArg: LanguageTexts.Translate
          ) => translateArg(LanguageTexts.globalPropertyName(propertyName)),
          propertyValue: (
            propertyName: string,
            propertyValue: number,
            translateArg: LanguageTexts.Translate
          ) =>
            translateArg(
              LanguageTexts.globalPropertyName(
                propertyName + "_" + propertyValue
              )
            ),
          propertyDescription: (
            propertyName: string,
            translateArg: LanguageTexts.Translate
          ) =>
            translateArg(LanguageTexts.globalPropertyDescription(propertyName)),
          propertyValueDescription: (
            propertyName: string,
            propertyValue: number,
            translateArg: LanguageTexts.Translate
          ) =>
            translateArg(
              LanguageTexts.globalPropertyDescription(
                propertyName + "_" + propertyValue
              )
            )
        };

        const translation = customTranslation
          ? { ...defaultTranslation, ...customTranslation }
          : defaultTranslation;

        const conversionParamaterByPropertyName =
          originalProductProperties.reduce(
            (soFar, current) =>
              soFar.set(current.name, current.conversionParameters),
            new Map<
              string,
              QuantityConversion.ConversionParameters | undefined
            >()
          );

        const renderProperties = createRenderProperties(
          includeHiddenProperties,
          originalProductProperties,
          selectedProperties
        );

        // Get sorder before filtering visible properties
        const sortedGroupKeys = R.uniq(
          originalProductProperties
            .concat()
            .sort((a, b) => a.sort_no - b.sort_no)
            .map(p => p.group || GenesysPropertiesSelectorTypes.NO_GROUP)
        );
        const renderGroups = createRenderGroups(
          dispatch,
          translateGroupName,
          uniqueId,
          closedGroups,
          renderProperties,
          sortedGroupKeys
        );
        const renderSections = createRenderSections(renderGroups, sections);
        const extraProps: GenesysPropertiesSelectorTypes.LayoutRendererExtraProps =
          {
            //
            uniqueId,
            productProperties: originalProductProperties,
            sections: renderSections,
            hidePropertyNames,
            propertyFormats,
            onDelete,
            fieldGroup,
            showDelete
          };

        const filterPrettyPrint = createFilterPrettyPrint(
          translation.propertyName,
          translation.propertyValue,
          translate,
          getAmountFormat,
          fieldGroup,
          propertyName => conversionParamaterByPropertyName.get(propertyName)
        );

        const fieldNameMap = renderProperties.reduce(
          (a, b) => {
            a[b.propertyName] = b.fieldName || b.propertyName;
            return a;
          },
          //tslint:disable-next-line
          {} as { [key: string]: string }
        ) as { readonly [key: string]: string };

        const mappedOnPropertyFormatChanged = (
          propertyName: string,
          unit: Unit.Unit<any>,
          decimalCount: number
        ) =>
          onPropertyFormatChanged(
            fieldNameMap[propertyName],
            unit,
            decimalCount
          );
        const mappedOnPropertyFormatCleared = (propertyName: string) =>
          onPropertyFormatCleared(fieldNameMap[propertyName]);

        const propertiesSelectorProps: PropertiesSelector.PropertiesSelectorProps =
          {
            //
            units: Units,
            unitLookup,
            lockSingleValidValue,
            includeHiddenProperties,
            includeCodes,
            selectedProperties,
            filterPrettyPrint,
            productProperties: originalProductProperties,
            propertyFormats,
            readOnlyProperties,
            onChange: onChange,
            onPropertyFormatCleared: mappedOnPropertyFormatCleared,
            onPropertyFormatChanged: mappedOnPropertyFormatChanged,
            onPropertyFormatSelectorToggled: (propertyName: string) => {
              dispatch(Actions.toggleQuantitySelector(uniqueId, propertyName));
            },
            translatePropertyName: (propertyName: string) => {
              return translation.propertyName(propertyName, translate);
            },
            translatePropertyValue: (propertyName: string, value: number) => {
              return translation.propertyValue(propertyName, value, translate);
            },
            translateGroupName,
            LayoutRenderer: layoutRenderer,
            unitsFormat: UnitsFormat,
            comparer: compare
          };

        return (
          <ContextProvider
            activatedQuantitySelectors={activatedQuantitySelectors}
            fieldGroup={fieldGroup}
            filterPrettyPrint={filterPrettyPrint}
            getAmountFormat={getAmountFormat}
            groupFilter={groupFilter}
            showGroups={showGroups}
            layoutRendererExtraProps={extraProps}
            productProperties={originalProductProperties}
            onQuantityChange={createOnQuantityChange(
              onPropertyFormatChanged,
              measureSystem,
              quantityDefaults
            )}
            valueSources={valueSources}
            translatePropertyValue={(
              propertyName: string,
              propertyValue: number
            ) =>
              translation.propertyValue(propertyName, propertyValue, translate)
            }
            translatePropertyDescription={(propertyName: string) =>
              translation.propertyDescription(propertyName, translate)
            }
            translatePropertyValueDescription={(
              propertyName: string,
              propertyValue: number
            ) =>
              translation.propertyValueDescription(
                propertyName,
                propertyValue,
                translate
              )
            }
            fieldNameMap={fieldNameMap}
          >
            <PropertiesSelector.PropertiesSelector
              {...propertiesSelectorProps}
            />
          </ContextProvider>
        );
      }}
    </Contexts.TranslateContext.Consumer>
  );
}

function createRenderSections(
  renderGroups: ReadonlyArray<GenesysPropertiesSelectorTypes.RenderGroup>,
  sections:
    | { readonly [key: string]: { readonly groups: ReadonlyArray<string> } }
    | undefined
): ReadonlyArray<GenesysPropertiesSelectorTypes.RenderSection> {
  if (sections === undefined) {
    const renderSection: GenesysPropertiesSelectorTypes.RenderSection = {
      key: GenesysPropertiesSelectorTypes.NO_GROUP,
      groups: renderGroups
    };
    return [renderSection];
  }

  return Object.keys(sections).reduce(
    (
      soFar: Array<GenesysPropertiesSelectorTypes.RenderSection>,
      sectionKey
    ) => {
      const section = sections[sectionKey];
      const sectionGroups = section.groups.reduce(
        (
          groups: Array<GenesysPropertiesSelectorTypes.RenderGroup>,
          groupKey
        ) => {
          groups.push(...renderGroups.filter(rg => rg.key === groupKey));
          return groups;
        },
        []
      );

      const renderSection: GenesysPropertiesSelectorTypes.RenderSection = {
        key: sectionKey,
        groups: sectionGroups
      };

      soFar.push(renderSection);
      return soFar;
    },
    []
  );
}

function createRenderGroups(
  dispatch: Dispatch<Actions.Action>,
  translateGroupName: (groupKey: string) => string,
  uniqueId: string,
  closedGroups: GenesysPropertiesSelectorTypes.ClosedGroups,
  renderProperties: GenesysPropertiesSelectorTypes.RenderProperty[],
  sortedGroupKeys: ReadonlyArray<string>
): ReadonlyArray<GenesysPropertiesSelectorTypes.RenderGroup> {
  const renderGroups: ReadonlyArray<GenesysPropertiesSelectorTypes.RenderGroup> =
    sortedGroupKeys.reduce((soFar, groupKey) => {
      const renderGroup: GenesysPropertiesSelectorTypes.RenderGroup = {
        key: groupKey,
        isCollapsed: !!closedGroups[groupKey],
        properties: renderProperties.filter(rp => rp.group === groupKey),
        onToggle: () => {
          dispatch(Actions.toggleGroup(uniqueId, groupKey));
        },
        translatedName: translateGroupName(groupKey)
      };

      if (renderGroup.properties.length > 0) {
        soFar.push(renderGroup);
      }
      return soFar;
    }, [] as Array<GenesysPropertiesSelectorTypes.RenderGroup>);

  return renderGroups;
}

function createRenderProperties(
  includeHiddenProperties: boolean,
  productProperties: ReadonlyArray<GenesysPropertiesSelectorTypes.Property>,
  selectedProperties: PropertyValueSet.PropertyValueSet
): Array<GenesysPropertiesSelectorTypes.RenderProperty> {
  const renderProperties: Array<GenesysPropertiesSelectorTypes.RenderProperty> =
    productProperties
      .filter(
        pp =>
          includeHiddenProperties ||
          PropertyFilter.isValid(selectedProperties, pp.visibility_filter)
      )
      .map(property => {
        const renderProperty: GenesysPropertiesSelectorTypes.RenderProperty = {
          sortNo: property.sort_no,
          propertyName: property.name,
          group: property.group
            ? property.group
            : GenesysPropertiesSelectorTypes.NO_GROUP,
          fieldName: property.fieldName || property.name
        };
        return renderProperty;
      })
      .sort((a, b) => a.sortNo - b.sortNo);
  return renderProperties;
}
