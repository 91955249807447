import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
`;

export const Container = styled.div`
  margin: 0 auto;
`;

export const HeadingContainer = styled.div`
  width: 281px;
  height: 43px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropDownContainer = styled.div`
  position: relative;
  bottom: 8px;
  /* background-color: #ffffff; */
  display: inline-flex;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 0 10px 10px 10px;
`;

export const OperatingCasesContainer = styled.div`
  width: 170px;
  margin: 0px 36px 0px 18px;
  h2 {
    display: flex;
    align-items: center;
    margin-top: 15px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
`;

export const TablesContainer = styled.div`
  display: flex;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${PrimaryColors.muntersBlue};
    opacity: 1;
    border-radius: 10px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${PrimaryColors.lightBlue};
  }
`;

export const PerformanceTable = styled.table`
  border-collapse: collapse;
  thead {
    tr {
      display: flex;
      height: 60px;
    }
    th {
      width: 71px;
      display: flex;
      align-items: center;

      :last-child {
        padding-right: 17px;
      }
    }

    p {
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      padding-top: 3px;
    }
    hr {
      border-top: 3px solid black;
      flex-grow: 1;
    }
  }
  tbody {
    tr {
      display: flex;
      height: 33px;
    }
    td {
      min-width: 71px;
      display: flex;
      align-items: center;
      border: 1px solid ${PrimaryColors.blue25};
      color: ${PrimaryColors.grey75};
      justify-content: center;
    }
  }
`;

export const StyledTh = styled.th`
  padding: 0;
  :last-child {
    padding-right: 0 !important;
  }
`;

export const OptionTable = styled(PerformanceTable)`
  thead {
    th {
      width: 100%;
    }
  }

  tbody {
    tr {
      width: auto;
    }
    td {
      min-width: 111px;
      border: 1px solid ${PrimaryColors.blue25};
      border-left: 0;
      color: black;
    }
  }
`;

export const AirPositionTh = styled.th`
  border-radius: 30px 30px 0 0;
  background-color: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? PrimaryColors.blue25 : "inherit"};
  cursor: pointer;
`;

export const ResultTd = styled.td`
  background-color: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? PrimaryColors.blue25 : "inherit"};
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  max-width: 380px;
`;

export const ExtraInfoTableContainer = styled.div`
  margin-top: 20px;
`;
