import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const ManagerSearchFilterContainer = styled.div`
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;

  padding: 10px;

  background: white;

  border-radius: 1px;
  border: 1px solid ${Colors.searchFilterBorderColor};

  z-index: 2;

  label {
    display: block;
    padding-bottom: 3px;
  }

  input {
    height: 22px;
    font-size: 14px;
  }

  div {
    padding-bottom: 10px;
  }
`;
