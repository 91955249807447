import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type S1Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledS1 = styled.h4<S1Props>`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.133rem;
  letter-spacing: 0;

  ${({ weight = "normal", color = "dark" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function S1(props: S1Props): JSX.Element {
  return <StyledS1 {...props} />;
}
