import styled from "styled-components";
import * as React from "react";
import { H2 } from "../headings/h2";

interface Props {
  readonly heading: string;
  readonly text: string;
}

const unStyled: React.FunctionComponent<Props> = props => {
  const { heading, text, ...restProps } = props;
  return (
    <div {...restProps}>
      <H2>{heading}</H2>
      <span>{text}</span>
    </div>
  );
};

// tslint:disable-next-line
export const HeadingWithText = styled(unStyled)`
  padding-bottom: 15px;
  width: 100%; // IE-fix
  span {
    line-height: 1.5rem;
  }
`;
