import * as React from "react";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  ResultTableWrapper,
  HeaderGroupType,
  TableBody,
  TableHeader
} from "../components";
import { VisibleColumn, HeaderColumnValue, CellContent } from "../types";
import { State, Action } from "../state";
import { cellContent } from "../cell-content";

export function ResultView({
  state,
  sharedState,
  visbibleColumns,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly visbibleColumns: ReadonlyArray<VisibleColumn>;
}) {
  const systems = state.systems!;
  const headerValues = getHeaderValues(visbibleColumns);
  const headerGroupValues = headerValues.reduce((sofar, current) => {
    const dynamicColumns = current.columns.filter(x => x.type === "dynamic");
    const selectableColumns = current.columns.filter(
      x => x.type === "selectable"
    );

    const columns = [selectableColumns, dynamicColumns];
    const newValues: Array<HeaderGroupType> = columns
      .filter(x => x.length > 0)
      .map((x, i) => ({
        value: i === 0 ? current.headerGroup : "",
        colSpan: x.length,
        backgroundColor: current.backgroundColor
      }));

    return [...sofar, ...newValues];
  }, [] as Array<HeaderGroupType>);

  const cellMap = new Map<string, CellContent>();

  systems.forEach(system => {
    visbibleColumns.forEach(colGroup => {
      colGroup.columns.forEach(col => {
        const cellKey = `${system.id}-${col.columnName}`;
        const cell = cellContent(col, colGroup.group, system, {
          sharedState,
          state,
          dispatch
        });
        cellMap.set(cellKey, cell);
      });
    });
  });

  return (
    <ResultTableWrapper
      updateScrollBarPos={val => {
        dispatch(
          Action.updateUISettings({
            ...state.uiSettings,
            resultTableSrcollBarLeft: val
          })
        );
      }}
      scrollBarPositionLeft={state.uiSettings.resultTableSrcollBarLeft}
    >
      <TableHeader
        groupNames={headerGroupValues}
        headerValues={headerValues.flatMap(headerGroup =>
          headerGroup.columns.map(col => ({
            value: col.displayName,
            format: col.format,
            fieldName: col.columnName,
            fieldGroup: headerGroup.fieldGroup
          }))
        )}
        sharedState={sharedState}
        onFormatCleared={(fieldGroup, fieldName) => {
          dispatch(Action.onFormatCleared(fieldGroup, fieldName));
        }}
        onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
          dispatch(
            Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
          );
        }}
      />
      <TableBody
        rowOnClick={rowId => {
          if (state.selectedRows.has(rowId)) {
            const values = state.selectedRows;
            values.delete(rowId);

            return dispatch(Action.setSelectedRows(values));
          }

          dispatch(Action.setSelectedRows(state.selectedRows.add(rowId)));
        }}
        rows={systems.map(system => ({
          id: system.id,
          isEditable: system.isEditable,
          highlightForEdit:
            system.isEditable && state.editedRowId === system.id,
          isSelected: state.selectedRows.has(system.id),
          cellOnClick: e => {
            const cellId = (e as any).target.value;
            const onClick = cellMap.get(`${system.id}-${cellId}`)?.cellOnClick;
            if (onClick) {
              onClick(e);
            }
          },
          cells: visbibleColumns.flatMap(colGroup => {
            return colGroup.columns.flatMap(col => {
              return {
                id: col.columnName,
                value: cellMap.get(`${system.id}-${col.columnName}`)!.value
              };
            });
          })
        }))}
      />
    </ResultTableWrapper>
  );
}

function getHeaderValues(
  visibleColums: ReadonlyArray<VisibleColumn>
): ReadonlyArray<HeaderColumnValue> {
  return visibleColums.map(colGroup => {
    return {
      headerGroup: colGroup.title,
      backgroundColor: colGroup.backgroundColor,
      columns: colGroup.columns,
      fieldGroup: colGroup.fieldGroup
    };
  });
}
