import styled from "styled-components";
// import * as Colors from "../colors";

// tslint:disable-next-line:variable-name
export const ActionMenuContentContainer = styled.div`
  padding: 5px;

  li {
    padding: 10px 0;
    font-size: 16px;
  }
`;
