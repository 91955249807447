import * as React from "react";
import styled from "styled-components";
import { ValueSourceImage } from "./elements";
import * as WindowClicker from "../window-clicker";

// tslint:disable-next-line:variable-name
const ValueSourceDropdownRoot = styled.div`
  position: relative;
  display: inline-block;
  float: left;

  font-size: 12px;
  cursor: pointer;
  user-select: none;
`;

// tslint:disable-next-line:variable-name
const ValueSourceDropdownOptionsContainer = styled.div`
  position: absolute;
  top: 100%;

  background: #fff;
  border: 1px solid #b4b4b4;
  z-index: 1001;
  padding: 1px;
  div {
    padding: 2px;

    display: flex;
    align-items: center;
    justify-content: left;

    &:hover {
      background-color: rgba(202, 229, 238, 1);
    }
  }
`;

// tslint:disable-next-line:variable-name
export const ValueSourceImageSelectedContainer = styled.div`
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  padding: 3px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: left;
`;

interface ValueSourceDropdownOption {
  readonly value: string;
  readonly label: string;
  readonly isItemValid: boolean;
  readonly imageUrl: string;
}

interface Props {
  readonly selected: string;
  readonly onChange: (value: string) => void;
  readonly options: ReadonlyArray<ValueSourceDropdownOption>;
  readonly showCodes: boolean;
}

interface State {
  readonly activated: boolean;
}

export class ValueSourceDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activated: false
    };
  }

  render(): JSX.Element {
    const selected = this.props.options.find(
      o => o.value === this.props.selected
    );
    const { showCodes } = this.props;
    if (!selected) {
      return <ValueSourceDropdownRoot>not selected</ValueSourceDropdownRoot>;
    }
    return (
      <WindowClicker.Component
        onClick={() => this.setState({ activated: false })}
      >
        <ValueSourceDropdownRoot>
          <ValueSourceImageSelectedContainer
            onClick={() => {
              this.setState({
                activated: true
              });
            }}
          >
            <ValueSourceImage src={selected.imageUrl} />
            {showCodes && <span> ({selected.value})</span>}
          </ValueSourceImageSelectedContainer>
          {this.state.activated && (
            <>
              <ValueSourceDropdownOptionsContainer>
                {this.props.options.map(o => (
                  <div
                    key={o.value}
                    onClick={() => {
                      this.setState({ activated: false });
                      if (o.value === selected.value) {
                        return;
                      }

                      this.props.onChange(o.value);
                    }}
                  >
                    <ValueSourceImage src={o.imageUrl} />
                    {showCodes && <span> ({o.value})</span>}
                  </div>
                ))}
              </ValueSourceDropdownOptionsContainer>
            </>
          )}
        </ValueSourceDropdownRoot>
      </WindowClicker.Component>
    );
  }
}

// export class ValueSourceDropdown extends React.Component<Props, State> {
//   constructor(props: Props) {
//     super(props);
//     this.state = {
//       activated: false
//     };
//   }

//   render(): JSX.Element {
//     const selected = this.props.options.find(
//       o => o.value === this.props.selected
//     );
//     if (!selected) {
//       return <ValueSourceDropdownRoot>not selected</ValueSourceDropdownRoot>;
//     }
//     return (
//       <ValueSourceDropdownRoot>
//         <ValueSourceImageSelectedContainer
//           onClick={e => {
//             e.stopPropagation();
//             this.setState({
//               activated: true
//             });
//           }}
//         >
//           <ValueSourceImage src={selected.imageUrl} />
//           <span> ({selected.value})</span>
//         </ValueSourceImageSelectedContainer>
//         {this.state.activated && (
//           <>
//             <ValueSourceDropdownOptions
//               onClose={() => {
//                 this.setState({
//                   activated: false
//                 });
//               }}
//             >
//               {this.props.options.map(o => (
//                 <div
//                   key={o.value}
//                   onClick={() => {
//                     if (o.value === selected.value) {
//                       return;
//                     }
//                     this.props.onChange(o.value);
//                   }}
//                 >
//                   <ValueSourceImage src={o.imageUrl} />
//                   <span> ({o.value})</span>
//                 </div>
//               ))}
//             </ValueSourceDropdownOptions>
//           </>
//         )}
//       </ValueSourceDropdownRoot>
//     );
//   }
// }

// interface ValueSourceDropdownOptionsProps {
//   readonly onClose: () => void;
// }

// class ValueSourceDropdownOptions extends React.Component<
//   ValueSourceDropdownOptionsProps,
//   {}
// > {
//   onClose = () => {
//     this.props.onClose();
//   };
//   componentDidMount() {
//     window.addEventListener("click", this.onClose);
//   }
//   componentWillUnmount() {
//     window.removeEventListener("click", this.onClose);
//   }
//   render(): JSX.Element {
//     return (
//       <ValueSourceDropdownOptionsContainer>
//         {this.props.children}
//       </ValueSourceDropdownOptionsContainer>
//     );
//   }
// }
