import * as React from "react";

interface Props {
  readonly url: string;
  readonly method?: "GET" | "POST";
  readonly params:
    | {
        readonly [key: string]:
          | string
          | ReadonlyArray<string>
          | number
          | ReadonlyArray<number>
          | undefined;
      }
    | undefined;
  readonly filename: string;
  readonly children: (onClick: () => void) => JSX.Element;
  readonly onComplete: () => void;
  readonly type: "download" | "open";
  readonly authentication?:
    | { readonly type: "Cookies" }
    | { readonly type: "Bearer"; readonly accessToken: string };
}

interface State {
  readonly fileInfo:
    | {
        readonly blob: Blob;
        readonly fileUrl: string;
      }
    | undefined;
}

// tslint:disable-next-line:function-name
export function FetchDocumentComponent(props: Props) {
  return <InternalComponent {...props} />;
}

class InternalComponent extends React.Component<Props, State> {
  // tslint:disable-next-line
  anchorElement: HTMLAnchorElement | null;

  constructor(props: Props) {
    super(props);

    this.state = resetState();
  }

  componentDidUpdate() {
    if (!this.state.fileInfo) {
      return;
    }

    this.download();
    this.props.onComplete();
  }

  componentWillReceiveProps(_props: Props) {
    this.setState(resetState());
  }

  generateGet(context: InternalComponent) {
    const url =
      context.props.url +
      (context.props.params
        ? Object.keys(context.props.params)
            .reduce(
              (a, b) =>
                context.props.params![b] !== undefined
                  ? a +
                    (Array.isArray(context.props.params![b])
                      ? (context.props.params![b] as Array<string>).reduce(
                          (aa, bb: string) => aa + "&" + b + "=" + bb,
                          ""
                        )
                      : "&" + b + "=" + context.props.params![b])
                  : a,
              ""
            )
            .replace("&", "?")
        : "");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(this.props.authentication || { type: "Cookies" })
      },
      credentials: getMaybeCredentials(
        this.props.authentication || { type: "Cookies" }
      )
    }).then(response => response.blob().then(blob => this.generateBlob(blob)));
  }

  generatePost(context: InternalComponent) {
    fetch(context.props.url, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(this.props.authentication || { type: "Cookies" })
      },
      credentials: getMaybeCredentials(
        this.props.authentication || { type: "Cookies" }
      ),
      body: JSON.stringify(context.props.params)
    }).then(response => response.blob().then(blob => this.generateBlob(blob)));
  }

  download(): void {
    if (!!(window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveBlob(
        this.state.fileInfo!.blob,
        this.props.filename
      );
      return;
    }

    if (this.props.type === "download") {
      this.anchorElement!.click();
      this.setState({
        ...this.state,
        fileInfo: undefined
      });
    } else {
      window.open(this.state.fileInfo!.fileUrl, "_blank");
    }
  }

  generateBlob(blob: Blob) {
    const url = createUrl(blob);
    this.setState({
      ...this.state,
      fileInfo: {
        ...this.state.fileInfo,
        blob: blob,
        fileUrl: url
      }
    });
  }

  render() {
    return (
      <>
        {this.props.children(() =>
          this.props.method === "GET" || this.props.method === undefined
            ? this.generateGet(this)
            : this.generatePost(this)
        )}
        {this.state.fileInfo && (
          <a
            style={{ display: "none" }}
            ref={el => {
              this.anchorElement = el;
            }}
            href={this.state.fileInfo.fileUrl}
            download={this.props.filename}
          />
        )}
      </>
    );
  }
}

function createUrl(blob: Blob): string {
  return URL.createObjectURL(blob);
}

function resetState(): State {
  return {
    fileInfo: undefined
  };
}

function getAuthHeaders(
  authentication:
    | { readonly type: "Cookies" }
    | { readonly type: "Bearer"; readonly accessToken: string }
): Record<string, string> {
  return authentication.type === "Bearer"
    ? { Authorization: `Bearer ${authentication.accessToken}` }
    : {};
}

function getMaybeCredentials(authentication: {
  readonly type: "Cookies" | "Bearer";
}): "include" | undefined {
  return authentication.type === "Cookies" ? "include" : undefined;
}
