import * as React from "react";
import * as OperatingCaseSelector from "../../../operating-case-selector";
import { State, Action } from "../../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { getOpcProductData } from "../../data";
import { Root, ALertContainer } from "./elements";
import { Spinner } from "@genesys/ui-elements";
import Alert from "@mui/lab/Alert";
import * as PropertiesSelector from "../../../properties-selector";

export function RequirementsView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}) {
  if (
    state.operatingCaseSelectorState === undefined ||
    state.newPropertiesSelectorState === undefined
  ) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  const opcProductData = getOpcProductData(
    PropertiesSelector.getSelectedProperties(state.newPropertiesSelectorState),
    sharedState.translate
  );

  // const isUsingOperatingCasesFromMoistureLoad =
  //   state.initialMoistureLoadOPCs !== undefined &&
  //   JSON.stringify(
  //     OperatingCaseSelector.getOperatingCases(state.initialMoistureLoadOPCs)
  //   ) ===
  //     JSON.stringify(
  //       OperatingCaseSelector.getOperatingCases(
  //         state.operatingCaseSelectorState
  //       )
  //     );

  return (
    <Root>
      <div>
        {state.moistureLoadMessage && (
          <ALertContainer>
            <Alert severity={state.moistureLoadMessage.type}>
              <p style={{ fontSize: "16px" }}>
                {state.moistureLoadMessage.message}
              </p>
            </Alert>
          </ALertContainer>
        )}
        <OperatingCaseSelector.OperatingCasesView
          dispatch={Dispatch.map(
            Action.dispatchOperatingCasesSelector,
            dispatch
          )}
          state={state.operatingCaseSelectorState}
          sharedState={sharedState}
          productData={opcProductData}
          sysProperties={PropertyValueSet.Empty}
          systemStatus={0}
          disableAddAndDelete={true}
        />
      </div>
    </Root>
  );
}
