import * as React from "react";
import { read, utils, Sheet, WorkBook } from "xlsx";
import * as FileUpload from "../file-upload";

export interface XlsxPageUploaderProps {
  readonly onParsed: (
    pages: ReadonlyArray<ReadonlyArray<ReadonlyArray<string>>>
  ) => void;
  readonly children: (onClick: () => void) => JSX.Element;
}

// tslint:disable-next-line:function-name
export function XlsxPageUploader(props: XlsxPageUploaderProps): JSX.Element {
  return (
    <FileUpload.FileUploadContainer
      onFileUploaded={createParseFile(props.onParsed)}
      children={props.children}
      accept=".xlsx"
    />
  );
}

function createParseFile(
  onParsed: (pages: ReadonlyArray<ReadonlyArray<ReadonlyArray<string>>>) => void
) {
  return (file: File) => parseXlsx(file, onParsed);
}

function parseXlsx(
  file: File,
  onParsed: (
    result: ReadonlyArray<ReadonlyArray<ReadonlyArray<string>>>
  ) => void
) {
  const fileReader = new FileReader();
  fileReader.onload = evt => {
    const textResult: string = (evt.target as any).result;
    const workbook = read(textResult, { type: "binary" });
    const data = parseWorkbook(workbook);
    onParsed(data);
  };
  fileReader.readAsBinaryString(file);
}

function parseWorksheet(sheet: Sheet): ReadonlyArray<ReadonlyArray<string>> {
  const json = utils.sheet_to_json(sheet, { header: 1, defval: "" });

  return (json as any).map((r: any) => r.map((c: any) => c.toString()));
}

function parseWorkbook(
  workbook: WorkBook
): ReadonlyArray<ReadonlyArray<ReadonlyArray<string>>> {
  return workbook.SheetNames.map(s => parseWorksheet(workbook.Sheets[s]));
}
