import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const exchangeComponentMutation = gql`
  mutation ExchangeComponent($input: ExchangeComponentInputType!) {
    exchangeComponent(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
