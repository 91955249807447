import {
  PropertyFilter,
  PropertyValueSet,
  PropertyValue
} from "@genesys/property";
import * as KnownProperties from "./known-properties";
import * as GraphQlTypes from "../../../graphql-types";
import * as Types from "./types";
import { Amount, Quantity, Units } from "@genesys/uom";

export function getSupplyOutletAirflowPropertyName(
  pvs: PropertyValueSet.PropertyValueSet
): string | undefined {
  return PropertyValueSet.hasProperty(KnownProperties.supplyOutletAirflow, pvs)
    ? KnownProperties.supplyOutletAirflow
    : PropertyValueSet.hasProperty(KnownProperties.supplyOutletFlow, pvs)
    ? KnownProperties.supplyOutletFlow
    : undefined;
}

export function parseMinMaxFlows(
  minMaxFlow:
    | GraphQlTypes.MultipleSystemsEditorProductQuery["product"]["dhu"]["minMaxFlow"]
    | null
    | undefined
): ReadonlyArray<Types.MinMaxFlow> {
  if (!minMaxFlow) {
    return [];
  }
  const minMaxFlows = minMaxFlow.rows.reduce((soFar, current) => {
    const pvs = PropertyValueSet.fromString(current.values);
    const minFlow = PropertyValueSet.getAmount<"MassFlow">("MinFlow", pvs);
    const maxFlow = PropertyValueSet.getAmount<"MassFlow">("MaxFlow", pvs);
    const filter = PropertyFilter.fromString(
      PropertyValueSet.getText("Filter", pvs) ?? ""
    );

    if (!minFlow || !maxFlow || !filter) {
      return soFar;
    } else {
      return soFar.concat([
        {
          filter: filter,
          minFlow: minFlow,
          maxFlow: maxFlow
        }
      ]);
    }
  }, [] as ReadonlyArray<Types.MinMaxFlow>);

  return minMaxFlows;
}

export function getOptions(
  modelPropertyName: string,
  models: ReadonlyArray<{
    readonly value: number;
    readonly propertyFilter: PropertyFilter.PropertyFilter;
  }>,
  minMaxFlows: ReadonlyArray<Types.MinMaxFlow>,
  dhuProperties: PropertyValueSet.PropertyValueSet,
  minOpcFlow: Amount.Amount<Quantity.MassFlow>,
  maxOpcFlow: Amount.Amount<Quantity.MassFlow>
): ReadonlyArray<Types.Option> {
  return models
    .filter(model =>
      PropertyFilter.isValid(dhuProperties, model.propertyFilter)
    )
    .map(model => {
      const minMaxFlow = minMaxFlows.find(m =>
        PropertyFilter.isValid(
          PropertyValueSet.set(
            modelPropertyName,
            PropertyValue.fromInteger(model.value),
            dhuProperties
          ),
          m.filter
        )
      );

      if (!minMaxFlow) {
        return {
          model: model.value,
          invalidFlow: undefined,
          numberOfUnits: 0
        };
      }

      const isMinFlowInvalid = Amount.lessThan(minOpcFlow, minMaxFlow.minFlow);
      const isMaxFlowInvalid = Amount.greaterThan(
        maxOpcFlow,
        minMaxFlow.maxFlow
      );

      if (!isMinFlowInvalid && !isMaxFlowInvalid) {
        return {
          model: model.value,
          invalidFlow: undefined,
          numberOfUnits: 1
        };
      }

      if (isMinFlowInvalid) {
        return {
          model: model.value,
          invalidFlow: {
            type: "invalid-min-flow",
            systemMinFlow: minMaxFlow.minFlow,
            systemMaxFlow: minMaxFlow.maxFlow,
            opcMinFlow: minOpcFlow,
            opcMaxFlow: maxOpcFlow
          },
          numberOfUnits: 0
        };
      }

      if (isMaxFlowInvalid) {
        const opcMinFlow = Amount.valueAs(
          Units.StandardCubicMeterPerHour,
          minOpcFlow
        );
        const opcMaxFlow = Amount.valueAs(
          Units.StandardCubicMeterPerHour,
          maxOpcFlow
        );

        const sysMinFlow = Amount.valueAs(
          Units.StandardCubicMeterPerHour,
          minMaxFlow.minFlow
        );
        const sysMaxFlow = Amount.valueAs(
          Units.StandardCubicMeterPerHour,
          minMaxFlow.maxFlow
        );

        const quantityRequired = Math.ceil(opcMaxFlow / sysMaxFlow);

        const dividedOpcMinFlow = opcMinFlow / quantityRequired;

        if (dividedOpcMinFlow < sysMinFlow) {
          return {
            model: model.value,
            invalidFlow: {
              type: "invalid-flow",
              systemMinFlow: minMaxFlow.minFlow,
              systemMaxFlow: minMaxFlow.maxFlow,
              opcMinFlow: Amount.create(
                dividedOpcMinFlow,
                Units.StandardCubicMeterPerHour,
                0
              ),
              opcMaxFlow: maxOpcFlow
            },
            numberOfUnits: 0
          };
        } else {
          return {
            model: model.value,
            invalidFlow: undefined,
            numberOfUnits: quantityRequired
          };
        }
      }

      return {
        model: model.value,
        invalidFlow: undefined,
        numberOfUnits: 1
      };
    });
}
