import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { GenesysSelect, Label, P1 } from "@genesys/ui-elements";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as PsychrometricChart from "../../../psychrometric-chart";

const Root = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
`;

const LeftContainer = styled.div`
  width: 30%;
  padding: 36px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  width: 630px;
  height: 800px;
`;

const MarginContainer = styled.div`
  margin-top: 16px;
`;

const CalculateSystemContainer = styled.div`
  padding: 25px 50px;
`;

const genesysSelectStylingProps = {
  width: 260,
  height: 24,
  fontSize: 14
};

export function PsychrometricChartPresenterView({
  state,
  dispatch,
  sharedState,
  systemId,
  systemStatus,
  operatingCasesOptions
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemId: string;
  readonly systemStatus: number;
  readonly operatingCasesOptions: ReadonlyArray<{
    readonly value: string;
    readonly title: string;
  }>;
}): JSX.Element {
  const makeChartPreset = (
    potentialChartPreset: string
  ): PsychrometricChart.ChartPreset => {
    return chartPresets.includes(
      potentialChartPreset as PsychrometricChart.ChartPreset
    )
      ? (potentialChartPreset as PsychrometricChart.ChartPreset)
      : state.chartPreset;
  };

  const chartPresets: ReadonlyArray<PsychrometricChart.ChartPreset> = [
    "mollier_SI",
    "mollier_IP",
    "psycrom_SI",
    "psycrom_IP"
  ];

  return (
    <Root>
      {systemStatus >=
      SystemStatusEnum.SystemStatus.RatingCalculationSuccess ? (
        <>
          <LeftContainer>
            <Label weight="normal" color="secondary">
              {sharedState.translate(LanguageTexts.operatingCases())}
            </Label>
            <GenesysSelect
              {...genesysSelectStylingProps}
              value={state.operatingCaseId}
              options={operatingCasesOptions}
              onChange={e =>
                dispatch(Action.setOperatingCase(e.target.value, systemId))
              }
            />

            <MarginContainer>
              <Label weight="normal" color="secondary">
                {sharedState.translate(LanguageTexts.unit())}
              </Label>
              <GenesysSelect
                {...genesysSelectStylingProps}
                value={state.chartPreset}
                options={chartPresets.map(cp => ({
                  value: cp,
                  title: sharedState.translate(
                    LanguageTexts.globalPropertyName(cp)
                  )
                }))}
                onChange={e =>
                  dispatch(
                    Action.setChartPreset(
                      makeChartPreset(e.target.value),
                      systemId
                    )
                  )
                }
              />
            </MarginContainer>
          </LeftContainer>
          <RightContainer>
            <PsychrometricChart.PsychrometricChartView
              state={state.psychrometricChartState}
              sharedState={sharedState}
            />
          </RightContainer>
        </>
      ) : (
        <CalculateSystemContainer>
          <P1 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.calculateToShowDiagram())}
          </P1>
        </CalculateSystemContainer>
      )}
    </Root>
  );
}
