import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as System from "../system-configurator/system";
import { ConfiguratorAction } from "../system-configurator/shell-system-configurator/configurator-actions";

export type State = {
  readonly systemId: string;
  readonly systemStatus: number;
  readonly component: System.Component;
  readonly hasChanged: boolean;
  readonly internalNotes: Note;
  readonly externalNotes: Note;
};

type Note = {
  readonly noteType: NoteType;
  readonly text: string;
  readonly isReadonly: boolean;
};

type NoteType = "internal" | "external";

export const init = (
  systemId: string,
  systemStatus: System.System["status"],
  component: System.Component
): [State, Cmd<Action>?] => {
  const internalNotes: Note = {
    noteType: "internal",
    text: component.internalNotes,
    isReadonly: true
  };
  const externalNotes: Note = {
    noteType: "external",
    text: component.externalNotes,
    isReadonly: true
  };
  return [
    {
      systemId,
      systemStatus,
      component,
      externalNotes,
      hasChanged: false,
      internalNotes
    }
  ];
};

export const Action = ctorsUnion({
  toggleNoteIsReadonly: (noteType: NoteType) => ({ noteType }),
  onNoteTextChange: (noteType: NoteType, text: string) => ({ noteType, text })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "toggleNoteIsReadonly": {
      if (!state) {
        return [state];
      }

      switch (action.noteType) {
        case "internal":
          return [
            {
              ...state,
              internalNotes: {
                ...state.internalNotes,
                isReadonly: !state.internalNotes.isReadonly
              }
            }
          ];
        case "external":
          return [
            {
              ...state,
              externalNotes: {
                ...state.externalNotes,
                isReadonly: !state.externalNotes.isReadonly
              }
            }
          ];
        default:
          return exhaustiveCheck(action.noteType, true);
      }
    }
    case "onNoteTextChange": {
      if (!state) {
        return [state];
      }

      switch (action.noteType) {
        case "internal":
          return [
            {
              ...state,
              internalNotes: { ...state.internalNotes, text: action.text },
              hasChanged:
                state.internalNotes.text !== action.text || state.hasChanged
            }
          ];
        case "external":
          return [
            {
              ...state,
              externalNotes: { ...state.externalNotes, text: action.text },
              hasChanged:
                state.externalNotes.text !== action.text || state.hasChanged
            }
          ];
        default:
          return exhaustiveCheck(action.noteType, true);
      }
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getNotesConfigurationActions(
  state: State
): ConfiguratorAction | undefined {
  if (
    state.internalNotes.text === state.component.internalNotes &&
    state.externalNotes.text === state.component.externalNotes
  ) {
    return undefined;
  }

  return ConfiguratorAction.saveNotes(
    state.component.id,
    state.internalNotes.text,
    state.externalNotes.text
  );
}

export function hasChanged(state: State): boolean {
  return state.hasChanged;
}
