import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as PropertiesSelector from "../../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as KnownProperties from "./known-properties";
import {
  Root,
  SupplyFlowSelectorsContainer,
  ModelOptionsTable as VariantOptionsTable,
  CheckBoxContainer,
  Popup,
  PopupContainer,
  PopupRoot,
  PaddingContainer,
  P2WithCursor,
  CasesContainer,
  PropertyNamesContainer,
  CardContainer,
  PropertiesSelectorJsxContainer
} from "./elements";
import { Amount } from "uom";
import { Units } from "uom-units";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import * as Types from "./types";
import * as System from "../../system";
import * as ProductDataTools from "@genesys/shared/lib/product-data";
import { Quantity } from "@genesys/uom";
import { P1, P2, CheckBox } from "@genesys/ui-elements";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { getSupplyOutletAirflowPropertyName, getOptions } from "./functions";

export function MultipleSystemsEditorView({
  state,
  sharedState,
  dispatch,
  systemStatus,
  opcProduct,
  dhuProduct,
  sysComponent,
  dhuComponent
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemStatus: number;
  readonly opcProduct: Product.Product | undefined;
  readonly dhuProduct: Product.Product | undefined;
  readonly sysComponent: System.Component | undefined;
  readonly dhuComponent: System.Component | undefined;
}): JSX.Element {
  if (!opcProduct || !dhuProduct || !sysComponent || !dhuComponent) {
    return (
      <div>
        {!opcProduct && <div>missing opc product</div>}
        {!dhuProduct && <div>missing dhu product</div>}
        {!sysComponent && <div>missing sys component</div>}
        {!dhuComponent && <div>missing dhu component</div>}
      </div>
    );
  }

  const supplyOutletAirflowPropertyName = getSupplyOutletAirflowPropertyName(
    state.flowSelectors.length > 0
      ? PropertiesSelector.getSelectedProperties(state.flowSelectors[0].state)
      : PropertyValueSet.Empty
  );

  const modelPropertyName = KnownProperties.dhmodel;

  if (
    !state.selectedModel ||
    !state.numberOfUnits ||
    !supplyOutletAirflowPropertyName
  ) {
    return (
      <div>
        {!state.selectedModel && <div>missing selected model</div>}
        {!state.numberOfUnits && <div>missing number of units</div>}
        {!supplyOutletAirflowPropertyName && (
          <div>missing supply outlet air flow</div>
        )}
      </div>
    );
  }

  const opcProductWithTotalSupplyOutletAirflow = {
    ...opcProduct,
    properties: opcProduct.properties.map(p => {
      if (p.name === supplyOutletAirflowPropertyName) {
        return {
          ...p,
          name: KnownProperties.totalSupplyOutletAirflow,
          valueSources: p.valueSources.reduce((soFar, current) => {
            if (current.propertyValueSourceId === "Locked") {
              return soFar;
            } else if (current.propertyValueSourceId === "User") {
              return soFar.concat([
                { ...current, propertyFilter: PropertyFilter.Empty }
              ]);
            } else if (
              current.propertyValueSourceId === "InheritedFromFirstOpCase"
            ) {
              return soFar.concat([
                {
                  ...current,
                  propertyFilter: PropertyFilter.fromString("sortno!=0")!
                }
              ]);
            } else {
              return soFar.concat([current]);
            }
          }, [] as ReadonlyArray<PropertiesSelector.PropertyValueSource>)
        };
      } else {
        return p;
      }
    })
  };

  const flowPropertyInfo =
    opcProductWithTotalSupplyOutletAirflow.properties.filter(
      p =>
        p.name === KnownProperties.totalSupplyOutletAirflow ||
        p.name === KnownProperties.caseName ||
        p.name === KnownProperties.sortNo
    );

  const totalSupplyOutletAirflows = state.flowSelectors.map(
    flowSelector =>
      PropertyValueSet.getAmount<Quantity.MassFlow>(
        KnownProperties.totalSupplyOutletAirflow,
        PropertiesSelector.getSelectedProperties(flowSelector.state)
      )!
  );

  const minSupplyAirflow = totalSupplyOutletAirflows.sort(
    (a, b) =>
      Amount.valueAs(Units.StandardCubicMeterPerHour, a) -
      Amount.valueAs(Units.StandardCubicMeterPerHour, b)
  )[0];

  const maxSupplyAirflow = totalSupplyOutletAirflows.sort(
    (a, b) =>
      Amount.valueAs(Units.StandardCubicMeterPerHour, b) -
      Amount.valueAs(Units.StandardCubicMeterPerHour, a)
  )[0];

  const rangeFilteredDhuProductData = ProductDataTools.filterProductForRange(
    dhuProduct,
    sysComponent.properties
  );

  const models = rangeFilteredDhuProductData.properties
    .find(p => p.name === modelPropertyName)
    ?.items.map(v => ({
      value: PropertyValue.getInteger(v.value)!,
      propertyFilter: v.validationFilter
    }));

  const options = getOptions(
    modelPropertyName,
    models ?? [],
    state.minMaxFlows,
    dhuComponent.properties,
    minSupplyAirflow,
    maxSupplyAirflow
  );

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  return (
    <Root>
      <SupplyFlowSelectorsContainer>
        <CasesContainer>
          <PropertyNamesContainer>
            <PropertiesSelectorJsxContainer isFirstCard={true}>
              <PropertiesSelector.PropertiesSelectorView
                dispatch={Dispatch.map(
                  action =>
                    Action.dispatchFlowSelector(
                      state.flowSelectors[0].id,
                      action,
                      opcProductWithTotalSupplyOutletAirflow
                    ),
                  dispatch
                )}
                productId={opcProduct.id}
                sharedState={sharedState}
                propertiesInfo={flowPropertyInfo}
                fieldGroup={`Settings.${opcProduct.id}`}
                isReadonly={systemStatus >= SystemStatus.LockSuccess}
                showCodes={showCodes}
                state={state.flowSelectors[0].state}
              />
            </PropertiesSelectorJsxContainer>
          </PropertyNamesContainer>

          {state.flowSelectors.map(fs => (
            <CardContainer key={fs.id}>
              <PropertiesSelectorJsxContainer isFirstCard={false}>
                <PropertiesSelector.PropertiesSelectorView
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchFlowSelector(
                        fs.id,
                        action,
                        opcProductWithTotalSupplyOutletAirflow
                      ),
                    dispatch
                  )}
                  productId={opcProduct.id}
                  sharedState={sharedState}
                  propertiesInfo={flowPropertyInfo}
                  fieldGroup={`Settings.${opcProduct.id}`}
                  isReadonly={systemStatus >= SystemStatus.LockSuccess}
                  showCodes={showCodes}
                  state={fs.state}
                  hidePropertyNames={true}
                />
              </PropertiesSelectorJsxContainer>
            </CardContainer>
          ))}
        </CasesContainer>
      </SupplyFlowSelectorsContainer>

      <div>
        <VariantOptionsTable>
          <thead>
            <tr>
              <th></th>
              <th>
                <P1 color="dark" weight="bold">
                  {sharedState.translate(
                    LanguageTexts.productProperty(
                      dhuProduct.id,
                      modelPropertyName
                    )
                  )}
                </P1>
              </th>
              <th>
                <P1 color="dark" weight="bold">
                  Validation
                </P1>
              </th>
              <th>
                <P1 color="dark" weight="bold">
                  {sharedState.translate(
                    LanguageTexts.productProperty(
                      sysComponent.productId,
                      KnownProperties.numberOfUnits
                    )
                  )}
                </P1>
              </th>
            </tr>
          </thead>

          {options.map(option => (
            <tbody key={option.model}>
              <tr>
                <td>
                  <CheckBoxContainer>
                    <CheckBox
                      isChecked={state.selectedModel === option.model}
                      children=""
                      onClick={() =>
                        dispatch(Action.setSelectModelAndNumberOfUnits(option))
                      }
                      disabled={option.invalidFlow !== undefined}
                    />
                  </CheckBoxContainer>
                </td>
                <td>
                  <P2 color="secondary" weight="normal">
                    {sharedState.translate(
                      LanguageTexts.productPropertyValue(
                        dhuProduct.id,
                        modelPropertyName,
                        option.model
                      )
                    )}
                  </P2>
                </td>
                <td>
                  <PreValidation variantOption={option} />
                </td>
                <td>
                  <P2 color="secondary" weight="normal">
                    {option.numberOfUnits}
                  </P2>
                </td>
              </tr>
            </tbody>
          ))}
        </VariantOptionsTable>
      </div>
    </Root>
  );
}

function PreValidation({
  variantOption
}: {
  readonly variantOption: Types.Option;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);

  const popup = (
    label: string,
    width: "wide" | "narrow",
    content: JSX.Element
  ) => {
    return (
      <PopupRoot>
        <P2 color="secondary" weight="normal">
          {label}
        </P2>
        <PopupContainer
          onMouseEnter={() => setState(true)}
          onMouseLeave={() => setState(false)}
        >
          <P2WithCursor color="secondary" weight="normal">
            (Show details)
          </P2WithCursor>
          {isOpen && (
            <Popup borderColor="blue" width={width}>
              {content}
            </Popup>
          )}
        </PopupContainer>
      </PopupRoot>
    );
  };

  if (variantOption.invalidFlow) {
    switch (variantOption.invalidFlow.type) {
      case "invalid-min-flow":
        return (
          <P2 color="secondary" weight="normal">
            {`Supply outlet airflow (${Math.trunc(
              Amount.valueAs(
                Units.StandardCubicMeterPerHour,
                variantOption.invalidFlow.opcMinFlow
              )
            )} Sm³/h) is lower than variant min flow (${Math.trunc(
              Amount.valueAs(
                Units.StandardCubicMeterPerHour,
                variantOption.invalidFlow.systemMinFlow
              )
            )} Sm³/h`}
          </P2>
        );
      case "invalid-flow":
        return popup(
          "Invalid flow",
          "narrow",
          <PaddingContainer>
            <P2 color="secondary" weight="normal">
              {`Supply outlet airflow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  variantOption.invalidFlow.opcMaxFlow
                )
              )} Sm³/h) is higher than variant max flow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  variantOption.invalidFlow.systemMaxFlow
                )
              )} Sm³/h) and dividing it between more units would result in an airflow of: ${Math.ceil(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  variantOption.invalidFlow.opcMinFlow
                )
              )} Sm³/h, which is lower than variant min flow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  variantOption.invalidFlow.systemMinFlow
                )
              )} Sm³/h`}
            </P2>
          </PaddingContainer>
        );
      default:
        return exhaustiveCheck(variantOption.invalidFlow.type, true);
    }
  } else {
    return (
      <P2 color="secondary" weight="normal">
        Valid
      </P2>
    );
  }
}
