import * as React from "react";
import * as LocalElements from "./elements";
import { CheckBox } from "@genesys/ui-elements";

export function Container({
  children
}: {
  readonly children: React.ReactNode;
}) {
  return <LocalElements.Table>{children}</LocalElements.Table>;
}

export function TableHeader({
  cellValues,
  headerIsChecked,
  checkBoxOnClick
}: {
  readonly cellValues: ReadonlyArray<JSX.Element>;
  readonly headerIsChecked: boolean;
  readonly checkBoxOnClick: () => void;
}) {
  return (
    <thead>
      <tr>
        <th>{checkbox(headerIsChecked, checkBoxOnClick)}</th>
        {cellValues.map((x, i) => {
          return <th key={i}>{x}</th>;
        })}
      </tr>
    </thead>
  );
}

export function TableBodyRowContainer({
  isRevisionExpanded,
  children
}: {
  readonly children: React.ReactNode;
  readonly isRevisionExpanded: boolean;
}) {
  return (
    <LocalElements.TableBody isRevisionsExpanded={isRevisionExpanded}>
      {children}
    </LocalElements.TableBody>
  );
}

export function TableRow({
  cells,
  isActionsOpen,
  isRowChecked,
  revisionsHeader,
  checkBoxOnClick
}: {
  readonly cells: ReadonlyArray<React.ReactNode>;
  readonly isActionsOpen: boolean;
  readonly isRowChecked: boolean;
  readonly revisionsHeader?: React.ReactNode;
  readonly checkBoxOnClick: () => void;
}) {
  return (
    <>
      <LocalElements.StyledRow isActionsOpen={isActionsOpen}>
        <td>{checkbox(isRowChecked, checkBoxOnClick)}</td>
        {cells.map((x, i) => {
          return <td key={i}>{x}</td>;
        })}
      </LocalElements.StyledRow>
      {revisionsHeader}
    </>
  );
}

export function RevisionRow({
  cells,
  isRevisionExpanded,
  isRowChecked,
  isActionsOpen,
  checkBoxOnClick
}: {
  readonly cells: ReadonlyArray<React.ReactNode>;
  readonly isRevisionExpanded: boolean;
  readonly isActionsOpen: boolean;
  readonly isRowChecked: boolean;
  readonly checkBoxOnClick: () => void;
}) {
  if (!isRevisionExpanded) {
    return null;
  }
  return (
    <LocalElements.StyledRow isActionsOpen={isActionsOpen}>
      <td>{checkbox(isRowChecked, checkBoxOnClick)}</td>
      {cells.map((x, i) => {
        return <td key={i}>{x}</td>;
      })}
    </LocalElements.StyledRow>
  );
}

function checkbox(isRowChecked: boolean, onClick: () => void) {
  return (
    <LocalElements.CheckBoxContainer>
      <CheckBox children="" isChecked={isRowChecked} onClick={onClick} />
    </LocalElements.CheckBoxContainer>
  );
}
