import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as LocationSelector from "@genesys/client-core/lib/location-selector";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import { Cmd } from "@typescript-tea/core";
import * as LocationSelectorGen2 from "../../../location-selector";
import * as GraphQlTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as System from "../../system";
import * as BinGenerationFunctions from "./bin-generation/functions/functions";
import * as CostSelection from "./cost-selection";
import * as EmissionsSelection from "./emissions-selection";
import * as BinGeneration from "./bin-generation";
import { UserEnergySettings } from "./types";
import { State, Action } from "./state";

export function initializeState(
  sharedState: SharedState.State,
  productData: GraphQlTypes.EnergyInputQueryProduct,
  system: System.System,
  energyProduct: Product.Product | undefined,
  initialShouldCalculateEnergy: boolean
): [State, Cmd<Action>?] {
  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );
  const climateSettings = system.climateSettings;

  const binLocationId =
    getLocationId(
      systemBinSelections,
      climateSettings,
      productData.product.binDataLocations
    ) ||
    LocationSelector.groupLocations(productData.product.binDataLocations)[0]
      .regions[0].locations[0].binDataLocationId;

  const binLocation = productData.product.binDataLocations.find(
    b => b.binDataLocationId === binLocationId
  );

  const binCoordinate = binLocation
    ? {
        latitude: binLocation.latitude,
        longitude: binLocation.longitude
      }
    : undefined;

  const locationSelectorState = LocationSelectorGen2.init(
    productData.product.binDataLocations,
    binCoordinate
  );

  const binCountry =
    locationSelectorState.countries[locationSelectorState.selectedCountryIndex]
      .countryName;

  const [CostSelectionState] = CostSelection.init(
    sharedState,
    system,
    productData
  );
  const costSettings = CostSelection.getCostSettings(CostSelectionState);

  const [EmissionsSelectionState] = EmissionsSelection.init(
    system,
    productData,
    binCountry
  );
  const emissionsSettings = EmissionsSelection.getEnergyEmissionsSettings(
    EmissionsSelectionState
  );

  const [binGenerationState, binGenerationCmd] = BinGeneration.init(
    sharedState,
    system,
    binLocation,
    energyProduct,
    emissionsSettings,
    costSettings,
    productData.product.binDataHourlyVersion
  );

  return [
    {
      productData: productData,
      locationSelectorState: locationSelectorState,
      binGenerationState: binGenerationState,
      costSelectionState: CostSelectionState,
      emissionsSelectionState: EmissionsSelectionState,
      resultState: undefined,
      activeTab: "bin-generation",
      initialShouldCalculateEnergy: initialShouldCalculateEnergy,
      shouldCalculateEnergy: true,
      energyProduct: energyProduct
    },
    Cmd.map(Action.dispatchBinGeneration, binGenerationCmd)
  ];
}

export function getEnergyInformation(state: State) {
  if (
    !state.binGenerationState ||
    !state.costSelectionState ||
    !state.emissionsSelectionState
  ) {
    return {
      energySettings: {},
      binSelections: PropertyValueSet.Empty,
      binOperatingCases: []
    };
  }

  const completeBinSelections = getCompleteBinSelections(
    state.binGenerationState,
    state.costSelectionState,
    state.emissionsSelectionState
  );

  const binCases = BinGeneration.getBinCases(state.binGenerationState);
  const operatingCases = BinGeneration.getOperatingCases(
    state.binGenerationState
  );

  const binOperatingCases = BinGenerationFunctions.buildBinOperatingCases(
    binCases,
    operatingCases
  );

  const userCostSettings = CostSelection.getUserCostSettings(
    state.costSelectionState
  );

  const energySettings: UserEnergySettings = {
    ...state.binGenerationState.userEnergySettingsBin,
    ...userCostSettings
  };

  return {
    energySettings: energySettings,
    binSelections: completeBinSelections,
    binOperatingCases: binOperatingCases ?? []
  };
}

export function hasSettingsChanged(state: State) {
  if (
    !state.binGenerationState ||
    !state.costSelectionState ||
    !state.emissionsSelectionState
  ) {
    return false;
  }

  const initialBinGenerationSettings =
    BinGeneration.getInitialBinSelectionAndOperatingCases(
      state.binGenerationState
    );

  if (!initialBinGenerationSettings) {
    return false;
  }

  const initialCostSettings = CostSelection.getInitialCostSettings(
    state.costSelectionState
  );

  const initialEmissionSettings = EmissionsSelection.getInitialEmissionSettings(
    state.emissionsSelectionState
  );

  const initialCompleteBinSelections = PropertyValueSet.merge(
    PropertyValueSet.merge(
      initialBinGenerationSettings.initialBinSelections,
      initialCostSettings
    ),
    initialEmissionSettings
  );

  const completeBinSelections = getCompleteBinSelections(
    state.binGenerationState,
    state.costSelectionState,
    state.emissionsSelectionState
  );

  const hasBinSelectionsChanged = !PropertyValueSet.equals(
    initialCompleteBinSelections,
    completeBinSelections
  );

  if (hasBinSelectionsChanged) {
    return true;
  }

  const operatingCases = BinGeneration.getOperatingCases(
    state.binGenerationState
  );

  const hasAnyOperatingCaseChanged =
    operatingCases.length !==
      initialBinGenerationSettings.initialOperatingCases.length ||
    operatingCases.some(
      (opc, ix) =>
        !PropertyValueSet.equals(
          opc,
          initialBinGenerationSettings.initialOperatingCases[ix]
        )
    );

  return hasAnyOperatingCaseChanged;
}

export function isLoadingBinCases(state: State) {
  return state.binGenerationState?.isBinCasesDataLoading;
}

export function hasBinCases(state: State) {
  if (!state.binGenerationState) {
    return false;
  }
  return !!BinGeneration.getBinCases(state.binGenerationState)?.length;
}

export function hasEnergyCalculationBeenTurnedOff(state: State) {
  return (
    state.initialShouldCalculateEnergy !== state.shouldCalculateEnergy &&
    !state.shouldCalculateEnergy
  );
}

function getCompleteBinSelections(
  binGenerationState: BinGeneration.State,
  costSelectionState: CostSelection.State,
  emissionsSelectionState: EmissionsSelection.State
) {
  const binSelections =
    BinGeneration.getBinSelections(binGenerationState) ??
    PropertyValueSet.Empty;

  const energyEmissionsSettings = EmissionsSelection.getEnergyEmissionsSettings(
    emissionsSelectionState
  );
  const costSettings = CostSelection.getCostSettings(costSelectionState);

  const completeBinSelections = PropertyValueSet.merge(
    binSelections,
    PropertyValueSet.merge(energyEmissionsSettings, costSettings)
  );

  return completeBinSelections;
}

function getLocationId(
  binSelection: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet,
  binDataLocations: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["binDataLocations"][0]
  >
): string | undefined {
  const locationId = PropertyValueSet.getText(
    KnownProperties.binDataLocationId,
    binSelection
  );

  if (locationId !== undefined) {
    return locationId;
  }

  if (PropertyValueSet.getText("locationname", climateSettings) === "Manual") {
    return LocationSelector.groupLocations(binDataLocations)[0].regions[0]
      .locations[0].binDataLocationId;
  } else {
    const coordinates =
      LocationSelector.getCoordinateFromClimateDataProperties(climateSettings);
    return (
      LocationSelector.getDefaultLocation(binDataLocations, coordinates)
        .binDataLocationId ||
      LocationSelector.groupLocations(binDataLocations)[0].regions[0]
        .locations[0].binDataLocationId
    );
  }
}

export function getEnergyTotalItemsWithVisibility(
  energyItems: ReadonlyArray<
    GraphQlTypes.EnergyInputQueryProduct["product"]["systemType"]["energyTotals"][0]
  >,
  components: ReadonlyArray<System.Component>
): ReadonlyArray<
  GraphQlTypes.EnergyInputQueryProduct["product"]["systemType"]["energyTotals"][0] & {
    readonly shouldBeVisible: boolean;
  }
> {
  return energyItems.reduce((soFar, current) => {
    if (
      current.costType === GraphQlTypes.EnergyCostType.NO_COST ||
      soFar.some(s => s.costType === current.costType && s.shouldBeVisible)
    ) {
      return soFar;
    } else {
      if (current.visibilityFilter.length === 0) {
        return soFar.concat([{ ...current, shouldBeVisible: true }]);
      }

      const visibilityFilters = current.visibilityFilter
        .split("],[")
        .map(v => v.replace("[", "").replace("]", ""))
        .reduce(
          (soFar, current) => {
            const osplit = current.split(";");
            const productFilterCombinations = osplit[0].split("|").map(id => ({
              productId: id,
              filter:
                osplit.length > 1
                  ? PropertyFilter.fromString(
                      osplit[1].substring(0, osplit[1].length)
                    )
                  : undefined
            }));
            return soFar.concat(productFilterCombinations);
          },
          [] as ReadonlyArray<{
            readonly productId: string;
            readonly filter: any;
          }>
        );

      const shouldBeVisible = visibilityFilters.some(v => {
        const component = components.find(c =>
          c.productId.endsWith(v.productId)
        );

        return (
          component !== undefined &&
          (v.filter === undefined ||
            PropertyFilter.isValid(component.properties, v.filter))
        );
      });

      return soFar.concat([{ ...current, shouldBeVisible: shouldBeVisible }]);
    }
  }, [] as Array<GraphQlTypes.EnergyInputQueryProduct["product"]["systemType"]["energyTotals"][0] & { readonly shouldBeVisible: boolean }>);
}
