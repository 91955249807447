import * as React from "react";
import * as GraphQLQuery from "../graphql-query";
import * as GraphQLTypes from "@genesys/graphql-types";
import {
  userProfileQuery,
  locationQuery,
  dataPointsQuery,
  ClimateDataDialog
} from "./container-component";
import * as MutationsQueueUser from "../mutations-queue-user";
import { PropertyValueSet } from "@genesys/property";
import { getDefaults } from "./functions";
import {
  InternalRepresentation,
  internalAshraeToPublicFacingPvs,
  internalManualToPublicFacingPvs
} from "./internal-representation";

export interface Props {
  readonly showWindVelocity?: boolean;
  // Create this with `getDefaults` and/or populate it with your own data.
  readonly state: InternalRepresentation;
  // ...then mutate it each time it changes.
  readonly onChange: (state: InternalRepresentation) => void;
  // Once the user is done, you can have the nicely formatted data.
  readonly onConfirm: (climateData: PropertyValueSet.PropertyValueSet) => void;
  readonly onAbort: () => void;
}

// tslint:disable-next-line:variable-name
export const Container = (props: Props) => {
  return (
    <MutationsQueueUser.MutationsQueue>
      {({ queueAndFlush }) => (
        <GraphQLQuery.QueryProductSimple<
          GraphQLTypes.ClimateDataDialog_CountriesQuery,
          GraphQLTypes.ClimateDataDialog_CountriesQueryVariables
        >
          query={locationQuery}
          variables={{}}
        >
          {locationData => {
            return props.state.climateDataSource === "ASHRAE" ? (
              <GraphQLQuery.QueryProductSimple<
                GraphQLTypes.ClimateDataDialog_DatapointsQuery,
                GraphQLTypes.ClimateDataDialog_DatapointsQueryVariables
              >
                query={dataPointsQuery}
                variables={{
                  locationId: props.state.locationId
                }}
              >
                {dataPointsData => {
                  return (
                    <ClimateDataDialog
                      showWindVelocity={!!props.showWindVelocity}
                      state={props.state}
                      dataPoints={
                        dataPointsData.product.dataPointsForLocationId!
                      }
                      countries={locationData.product.countries}
                      queueAndFlush={queueAndFlush}
                      onChange={props.onChange}
                      onConfirm={props.onConfirm}
                      onAbort={props.onAbort}
                    />
                  );
                }}
              </GraphQLQuery.QueryProductSimple>
            ) : (
              <ClimateDataDialog
                showWindVelocity={!!props.showWindVelocity}
                state={props.state}
                dataPoints={[]}
                countries={locationData.product.countries}
                queueAndFlush={queueAndFlush}
                onChange={props.onChange}
                onConfirm={props.onConfirm}
                onAbort={props.onAbort}
              />
            );
          }}
        </GraphQLQuery.QueryProductSimple>
      )}
    </MutationsQueueUser.MutationsQueue>
  );
};

// A more convenient component for getting only the defaults.
// tslint:disable-next-line:variable-name
export const DefaultClimateData = (props: {
  readonly children: (
    defaultClimateData: PropertyValueSet.PropertyValueSet,
    defaultClimateDataInternal: InternalRepresentation
  ) => JSX.Element;
}) => {
  return (
    <GraphQLQuery.QueryUserSimple<
      GraphQLTypes.ClimateDataDialog_UserProfileQuery,
      GraphQLTypes.ClimateDataDialog_UserProfileQueryVariables
    >
      query={userProfileQuery}
      variables={{}}
    >
      {userProfileData => (
        <GraphQLQuery.QueryProductSimple<
          GraphQLTypes.ClimateDataDialog_CountriesQuery,
          GraphQLTypes.ClimateDataDialog_CountriesQueryVariables
        >
          query={locationQuery}
          variables={{}}
        >
          {locationData => {
            const defaultState = getDefaults(
              locationData.product.countries,
              userProfileData.user.userProfile.userSettings.climate
            );

            return defaultState.climateDataSource === "ASHRAE" ? (
              <GraphQLQuery.QueryProductSimple<
                GraphQLTypes.ClimateDataDialog_DatapointsQuery,
                GraphQLTypes.ClimateDataDialog_DatapointsQueryVariables
              >
                query={dataPointsQuery}
                variables={{
                  locationId: defaultState.locationId
                }}
              >
                {dataPointsData =>
                  props.children(
                    internalAshraeToPublicFacingPvs(
                      defaultState,
                      locationData.product.countries,
                      dataPointsData.product.dataPointsForLocationId!
                    ),
                    defaultState
                  )
                }
              </GraphQLQuery.QueryProductSimple>
            ) : (
              props.children(
                internalManualToPublicFacingPvs(defaultState),
                defaultState
              )
            );
          }}
        </GraphQLQuery.QueryProductSimple>
      )}
    </GraphQLQuery.QueryUserSimple>
  );
};
