import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import gql from "graphql-tag";
import * as GraphQLTypes from "../graphql-types";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";

export const UserSettingsOptionsQuery = gql`
  query UserSettingsProductDataQuery {
    product {
      languages {
        id
      }
      locales {
        id
        name
      }
    }
  }
`;

export type State = {
  readonly queryData: GraphQLTypes.UserSettingsProductDataQuery | undefined;
  readonly language: string | undefined;
  readonly currency: string | undefined;
  readonly locale: string | undefined;
  readonly defaultSalesOrganisation: string | undefined;
  readonly selectedAmountProfileId: string | undefined;
  readonly settingsHasChanged: boolean;
  readonly isConfimationDialogOpen: boolean;
};
export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      queryData: undefined,
      language: undefined,
      currency: undefined,
      locale: undefined,
      defaultSalesOrganisation: undefined,
      selectedAmountProfileId: undefined,
      settingsHasChanged: false,
      isConfimationDialogOpen: false
    },
    sharedState.graphQL.queryProductCmd<
      GraphQLTypes.UserSettingsProductDataQuery,
      GraphQLTypes.UserSettingsProductDataQueryVariables,
      Action
    >(UserSettingsOptionsQuery, {}, Action.queryDataReceived),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  queryDataReceived: (
    queryData: GraphQLTypes.UserSettingsProductDataQuery
  ) => ({ queryData }),
  changeLanguage: (language: string) => ({ language }),
  changeCurrency: (currency: string) => ({ currency }),
  changeLocale: (locale: string) => ({ locale }),
  changeDefaultSalesOrganisationOrg: (defaultSalesOrganisation: string) => ({
    defaultSalesOrganisation
  }),
  changeselectedAmountProfileId: (selectedAmountProfileId: string) => ({
    selectedAmountProfileId
  }),
  saveDefaultSettings: () => ({}),
  resetDefaultSettings: () => ({}),
  toggleConfirmationDialog: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "changeLanguage": {
      return [
        {
          ...state,
          language: action.language,
          settingsHasChanged: true
        }
      ];
    }
    case "changeCurrency": {
      return [
        {
          ...state,
          currency: action.currency,
          settingsHasChanged: true
        }
      ];
    }
    case "changeLocale": {
      return [
        {
          ...state,
          locale: action.locale,
          settingsHasChanged: true
        }
      ];
    }
    case "changeDefaultSalesOrganisationOrg": {
      return [
        {
          ...state,
          defaultSalesOrganisation: action.defaultSalesOrganisation,
          settingsHasChanged: true
        }
      ];
    }
    case "changeselectedAmountProfileId": {
      return [
        {
          ...state,
          selectedAmountProfileId: action.selectedAmountProfileId,
          settingsHasChanged: true
        }
      ];
    }
    case "saveDefaultSettings": {
      const {
        language,
        currency,
        locale,
        defaultSalesOrganisation,
        selectedAmountProfileId
      } = state;

      const sharedStateActions: Array<SharedState.Action> = [];

      if (language !== sharedState.user.settings.language) {
        sharedStateActions.push(
          SharedState.Action.setUserLanguage(
            language || sharedState.user.settings.language
          )
        );
      }
      if (currency !== sharedState.user.settings.currency) {
        sharedStateActions.push(
          SharedState.Action.setUserCurrency(
            currency || sharedState.user.settings.currency
          )
        );
      }
      if (locale !== sharedState.user.settings.locale) {
        sharedStateActions.push(
          SharedState.Action.setUserLocale(
            locale || sharedState.user.settings.locale
          )
        );
      }
      if (
        !sharedState.user.settings.defaultSalesOrganisation ||
        defaultSalesOrganisation !==
          sharedState.user.settings.defaultSalesOrganisation
      ) {
        const primarySalesOrganisationFromClaim =
          AuthorizationTools.getClaimValues(
            sharedState.user.applicationClaims,
            AuthorizationTools.genesysUserClaims.primarySalesOrganisation
          );

        const defaultSalesOrganisation = state.defaultSalesOrganisation
          ? state.defaultSalesOrganisation
          : primarySalesOrganisationFromClaim
          ? primarySalesOrganisationFromClaim[0]
          : sharedState.user.salesOrganisations[0].id;

        sharedStateActions.push(
          SharedState.Action.setUserDefaultSalesOrganisation(
            defaultSalesOrganisation || primarySalesOrganisationFromClaim![0]
          )
        );
      }
      if (
        selectedAmountProfileId !==
        sharedState.user.settings.selectedAmountProfile.id
      ) {
        sharedStateActions.push(
          SharedState.Action.setActiveAmountFieldProfile(
            selectedAmountProfileId ||
              sharedState.user.settings.selectedAmountProfile.id
          )
        );
      }

      return [
        {
          ...state,
          language: language,
          currency: currency,
          locale: locale,
          defaultSalesOrganisation: defaultSalesOrganisation,
          selectedAmountProfileId: selectedAmountProfileId,
          settingsHasChanged: false
        },
        undefined,
        sharedStateActions
      ];
    }
    case "resetDefaultSettings": {
      return [
        {
          ...state,
          language: undefined,
          currency: undefined,
          locale: undefined,
          defaultSalesOrganisation: undefined,
          selectedAmountProfileId: undefined,
          settingsHasChanged: false
        },
        undefined,
        [SharedState.Action.resetUserSettings()]
      ];
    }
    case "queryDataReceived": {
      return [
        {
          ...state,
          queryData: action.queryData
        }
      ];
    }
    case "toggleConfirmationDialog": {
      return [
        { ...state, isConfimationDialogOpen: !state.isConfimationDialogOpen }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
