import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LabelManager from "../../../label-manager";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Dispatch } from "@typescript-tea/core";
import { Pop, ArrowDown } from "@genesys/ui-elements";
import { Action } from "./state";
import { FakeSelect } from "./elements";

export function LabelEditorView({
  sharedState,
  state,
  assignedLabels,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly state: LabelManager.State | undefined;
  readonly assignedLabels: ReadonlyArray<LabelManager.Label>;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element | null {
  const labels = sharedState.user.labels;

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<Element>) => {
    dispatch(Action.toggleLabelManager());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    dispatch(Action.toggleLabelManager());
    setAnchorEl(null);
  };

  let displayedLabels: ReadonlyArray<LabelManager.Label> = [];

  if (state) {
    displayedLabels = LabelManager.getSelectedLabels(state!);
  } else {
    displayedLabels = assignedLabels;
  }

  return (
    <>
      <FakeSelect onClick={handleClick}>
        <label>
          {!displayedLabels.length
            ? sharedState.translate(LanguageTexts.chooseLabels())
            : displayedLabels.map((obj, i) => {
                const value =
                  i < displayedLabels.length - 1 ? obj.name + "; " : obj.name;
                return value;
              })}
        </label>
        <ArrowDown />
      </FakeSelect>
      {anchorEl && state && (
        <Pop
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          <LabelManager.LabelManagerView
            onAssign={values => {
              dispatch(Action.setAssignedlables(values));
              setAnchorEl(null);
            }}
            state={state}
            dispatch={Dispatch.map(Action.dispatchLabelManager, dispatch)}
            onCancel={handleClose}
            sharedState={sharedState}
            labels={labels}
          />
        </Pop>
      )}
    </>
  );
}
