import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createMechanicalSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps) {
  const svgString = `
    <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>1083341E-F51F-4243-910D-8BB95DFFC197</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-423.000000, -1786.000000)">
            <g id="Icons/System-Menu-Items/Mechanical-Sketch" transform="translate(428.000000, 1789.000000)">
                <g id="Rectangle-Copy-14">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <g id="Group" transform="translate(13.000000, 11.000000)" fill="${color}" fill-rule="nonzero">
                    <path d="M1.37757591,0.958710379 L4.00869744,3.74971038 L34.5,3.75 L34.5,5.25 L5.42369744,5.24971038 L8.02733166,8.01070458 L6.92145319,9.05350488 L4.24969744,6.21971038 L4.25,35.5 L2.75,35.5 L2.74969744,4.62871038 L0.271697443,2.00151069 L1.37757591,0.958710379 Z M31.75,12.25 L31.75,32.75 L10.25,32.75 L10.25,12.25 L31.75,12.25 Z M12.0016974,13.7497104 L11.75,13.75 L11.75,31.25 L30.25,31.25 L30.25,13.75 L29.9976974,13.7497104 L21.3516974,22.5057104 L29.3757661,30.6332656 L29.0199773,30.9845694 L20.9996974,22.8617104 L12.9800227,30.9845694 L12.6242339,30.6332656 L20.6476974,22.5057104 L12.0016974,13.7497104 Z M29.2936974,13.7497104 L12.7056974,13.7497104 L20.9996974,22.1497104 L29.2936974,13.7497104 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
    
    `;

  return encodeSvgStringToImgSrc(svgString);
}
