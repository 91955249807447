import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "../language-texts";
import { removeWhitespaces } from "./remove-white-spaces";

const epxSizePropertyName = "epxsize";

export function getText(
  translate: LanguageTexts.Translate,
  epxComponentProperties: string | undefined,
  systemTypeId: string
): string {
  if (!epxComponentProperties) {
    return "";
  }

  const epxProps = PropertyValueSet.fromString(epxComponentProperties);
  const epxSize = PropertyValueSet.getInteger(epxSizePropertyName, epxProps);
  const epxText = `${translate(
    LanguageTexts.systemTypeProperty(systemTypeId, epxSizePropertyName)
  )}=${translate(
    epxSize !== undefined
      ? LanguageTexts.systemTypePropertyValue(
          systemTypeId,
          epxSizePropertyName,
          epxSize
        )
      : LanguageTexts.pNull()
  )}`;

  return removeWhitespaces(epxText);
}
