import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as Types from "./types";
import * as OperatingCaseSelector from "../operating-case-selector";
import { PropertyItem, PropertyInfo } from "../properties-selector";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { createInitialOperatingCases } from "../operating-case-selector";
import { Amount } from "uom";
import { Units } from "uom-units";
import { v4 } from "uuid";
import {
  checkboxPropertyOptions,
  checkboxGroups,
  newPropertiesOverrides
} from "./data";
import { State } from "./state";
import { Quantity } from "@genesys/uom";

export function hasVariantsGenerationDataChanged(state: State) {
  if (!state.operatingCaseSelectorState) {
    return false;
  }

  return (
    state.latestDataUsedToGenerateVariants === undefined ||
    JSON.stringify(state.latestDataUsedToGenerateVariants.systemTypes) !==
      JSON.stringify(state.systemTypes) ||
    JSON.stringify(state.latestDataUsedToGenerateVariants.newPropertiesSet) !==
      JSON.stringify(state.newPropertiesSet) ||
    JSON.stringify(
      OperatingCaseSelector.getOperatingCases(
        state.latestDataUsedToGenerateVariants.operatingCaseSelectorState
      )
    ) !==
      JSON.stringify(
        OperatingCaseSelector.getOperatingCases(
          state.operatingCaseSelectorState
        )
      ) ||
    JSON.stringify(
      OperatingCaseSelector.getClimateSettings(
        state.latestDataUsedToGenerateVariants.operatingCaseSelectorState
      )
    ) !==
      JSON.stringify(
        OperatingCaseSelector.getClimateSettings(
          state.operatingCaseSelectorState
        )
      )
  );
}

export function getAllVariants(
  newPropertiesSet: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  systemTypes: ReadonlyArray<Types.SystemType>,
  operatingCaseSelectorState: OperatingCaseSelector.State,
  translate: LanguageTexts.Translate
): ReadonlyArray<Types.SystemVariantsGroup> {
  const selectedSystemTypes = systemTypes.filter(st => st.isSelected);
  const requirementsOpcs = OperatingCaseSelector.getOperatingCases(
    operatingCaseSelectorState
  ).map(opc => opc.settings);
  const climateSettings = OperatingCaseSelector.getClimateSettings(
    operatingCaseSelectorState
  );

  const variants: ReadonlyArray<Types.SystemVariantsGroup> =
    newPropertiesSet.reduce((soFarSystemVariants, currentNewProperties, ix) => {
      const variantsFromCurrent = selectedSystemTypes.reduce(
        (soFarSystems, currentSystem) => {
          const dhmodelItems = getDhmodels(
            currentSystem.newMappings,
            currentNewProperties,
            currentSystem.newProperties
          );

          const systemRequirementsFilter = currentSystem.newMappings.find(
            row => row.name === "systemrequirements"
          )?.filter;

          const isSystemRequirementsMet = !systemRequirementsFilter
            ? false
            : PropertyFilter.isValid(
                currentNewProperties,
                systemRequirementsFilter
              );

          const systemTypeVariants: ReadonlyArray<
            Types.SystemVariant | undefined
          > = dhmodelItems.map(dhmodel => {
            if (!isSystemRequirementsMet) {
              return {
                identifier: v4(),
                systemType: currentSystem.id,
                variant: dhmodel.text,
                warnings: [],
                newProperties: undefined,
                operatingCases: [PropertyValueSet.Empty],
                divergentSysComponent: undefined,
                invalidFlow: undefined,
                invalidProperties: [],
                invalidSystemRequirements: {
                  propertyFilter: systemRequirementsFilter,
                  selectionNewProperties: currentNewProperties
                },
                shouldCalculate: false,
                calculationResults: undefined,
                quantity: 0
              } as Types.SystemVariant;
            }

            const modifiedNewPropertiesDefinitions =
              modifyNewPropertiesDefinitions(currentSystem);

            const selectedProperties = mapSelectionToSystemTypeProperties(
              currentSystem.newMappings,
              modifiedNewPropertiesDefinitions,
              currentNewProperties,
              dhmodel
            );

            if (selectedProperties.invalidProperties.length > 0) {
              return {
                identifier: v4(),
                systemType: currentSystem.id,
                variant: dhmodel.text,
                warnings: [],
                newProperties: undefined,
                operatingCases: [PropertyValueSet.Empty],
                divergentSysComponent: undefined,
                invalidFlow: undefined,
                invalidProperties: selectedProperties.invalidProperties,
                invalidSystemRequirements: undefined,
                shouldCalculate: false,
                calculationResults: undefined,
                quantity: 0
              } as Types.SystemVariant;
            }

            const newProperties = createDefaultProperties(
              selectedProperties.validProperties
            );

            const inValidFlowAndQuantity = validateFlowsAndGetQuantity(
              currentSystem,
              newProperties,
              requirementsOpcs
            );

            const systemOperatingCases = getOperatingCasesForVariant(
              currentSystem,
              newProperties,
              climateSettings,
              translate
            );

            const requirementsOpcsTranslatedAndSupplyAirDividedByQuantity =
              mapRequirementsOPCsToSystemOPCs(
                divideSupplyAirflowWithQuantity(
                  requirementsOpcs,
                  inValidFlowAndQuantity.quantity
                ),
                currentSystem.opcProperties
              );

            const operatingCases =
              requirementsOpcsTranslatedAndSupplyAirDividedByQuantity.map(
                (sopc, ix) =>
                  PropertyValueSet.merge(sopc, systemOperatingCases[ix])
              );

            const divergantSysComponent =
              getSysTemplateComponentWithNumberOfUnits(
                currentSystem,
                newProperties,
                inValidFlowAndQuantity.quantity
              );

            return {
              identifier: v4(),
              systemType: currentSystem.id,
              variant: dhmodel.text,
              warnings: [],
              newProperties: newProperties,
              operatingCases: operatingCases,
              divergentSysComponent: divergantSysComponent,
              invalidFlow: inValidFlowAndQuantity.invalidFlow,
              invalidProperties: [],
              invalidSystemRequirements: undefined,
              shouldCalculate: false,
              calculationResults: undefined,
              quantity: inValidFlowAndQuantity.quantity
            } as Types.SystemVariant;
          });

          return soFarSystems.concat(
            systemTypeVariants
              .filter(s => s !== undefined)
              .map(s => s as Types.SystemVariant)
          );
        },
        [] as ReadonlyArray<Types.SystemVariant>
      );

      return soFarSystemVariants.concat({
        systemVariants: variantsFromCurrent,
        variantDetails: getVariantDetails(currentNewProperties),
        isExpanded: ix === 0 ? true : false
      });
    }, [] as ReadonlyArray<Types.SystemVariantsGroup>);

  return variants;
}

function getSysTemplateComponentWithNumberOfUnits(
  system: Types.SystemType,
  newProperties: PropertyValueSet.PropertyValueSet,
  quantity: number
) {
  const sysTemplate = system.sysTemplates
    .find(t =>
      PropertyFilterHelpers.isValid(
        t.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    )!
    .components.map(t => ({
      id: t.id,
      properties: PropertyValueSet.fromString(t.properties ?? ""),
      visibleProperties: t.visibleProperties ?? [],
      propertyFilter: t.propertyFilter
    }))
    .find(c =>
      PropertyFilterHelpers.isValid(
        c.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    );

  const divergantSysComponent = !sysTemplate
    ? undefined
    : {
        id: sysTemplate.id,
        properties: PropertyValueSet.keepProperties(
          sysTemplate.visibleProperties.concat(["numberofunits"]),
          PropertyValueSet.merge(
            PropertyValueSet.merge(
              PropertyValueSet.fromString(`numberofunits=${quantity}`),
              sysTemplate.properties
            ),
            PropertyValueSet.merge(
              newProperties,
              createDefaultProperties(system.sysProperties)
            )
          )
        )
      };

  return divergantSysComponent;
}

function divideSupplyAirflowWithQuantity(
  requirementsOpcs: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  quantity: number
) {
  return requirementsOpcs.map(opc => {
    const supplyOutletAirflow = PropertyValueSet.getAmount<Quantity.MassFlow>(
      "supplyoutletairflow",
      opc
    )!;
    const newSupplyOutletAirflow = Amount.divide(supplyOutletAirflow, quantity);
    return PropertyValueSet.setAmount(
      "supplyoutletairflow",
      newSupplyOutletAirflow,
      opc
    );
  });
}

function mapRequirementsOPCsToSystemOPCs(
  requirementsOpcs: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  systemOPCProperties: ReadonlyArray<PropertyInfo>
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const getSystemPropertyName = (
    possiblePropertyNames: ReadonlyArray<string>
  ) => {
    return possiblePropertyNames.find(ppn =>
      systemOPCProperties.some(p => p.name === ppn)
    );
  };

  const outdoorAirTemperatureName = getSystemPropertyName([
    "outdoorairtemperature",
    "outdoortemperature"
  ]);
  const outdoorAirHumidityName = getSystemPropertyName([
    "outdoorairhumidity",
    "outdoorhumidity"
  ]);
  const returnAirTemperatureName = getSystemPropertyName([
    "returnairtemperature",
    "returntemp"
  ]);
  const returnAirHumidityName = getSystemPropertyName([
    "returnairhumidity",
    "returnhum"
  ]);
  const customAirTemperatureName = getSystemPropertyName([
    "customairtemperature"
  ]);
  const customAirHumidityName = getSystemPropertyName(["customairhumidity"]);
  const processInletExternalStaticName = getSystemPropertyName([
    "processinletexternalstatic"
  ]);
  const processOutletExternalStaticName = getSystemPropertyName([
    "processoutletexternalstatic"
  ]);
  const reactInletExternalStaticName = getSystemPropertyName([
    "reactinletexternalstatic"
  ]);
  const reactOutletExternalStaticName = getSystemPropertyName([
    "reactoutletexternalstatic"
  ]);
  const supplyOutletAirFlowName = getSystemPropertyName([
    "supplyoutletairflow",
    "supplyoutletflow"
  ]);
  const supplyTargetHumidityName = getSystemPropertyName([
    "supplytargethumidity",
    "targethum"
  ]);

  const propertiesMapping: ReadonlyArray<{
    readonly requirementPropertyName: string;
    readonly systemPropertyName: string | undefined;
  }> = [
    {
      requirementPropertyName: "outdoorairtemperature",
      systemPropertyName: outdoorAirTemperatureName
    },
    {
      requirementPropertyName: "source_outdoorairtemperature",
      systemPropertyName: outdoorAirTemperatureName
        ? "source_" + outdoorAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "outdoorairhumidity",
      systemPropertyName: outdoorAirHumidityName
    },
    {
      requirementPropertyName: "source_outdoorairhumidity",
      systemPropertyName: outdoorAirHumidityName
        ? "source_" + outdoorAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "returnairtemperature",
      systemPropertyName: returnAirTemperatureName
    },
    {
      requirementPropertyName: "source_returnairtemperature",
      systemPropertyName: returnAirTemperatureName
        ? "source_" + returnAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "returnairhumidity",
      systemPropertyName: returnAirHumidityName
    },
    {
      requirementPropertyName: "source_returnairhumidity",
      systemPropertyName: returnAirHumidityName
        ? "source_" + returnAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "customairtemperature",
      systemPropertyName: customAirTemperatureName
    },
    {
      requirementPropertyName: "source_customairtemperature",
      systemPropertyName: customAirTemperatureName
        ? "source_" + customAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "customairhumidity",
      systemPropertyName: customAirHumidityName
    },
    {
      requirementPropertyName: "source_customairhumidity",
      systemPropertyName: customAirHumidityName
        ? "source_" + customAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "processinletexternalstatic",
      systemPropertyName: processInletExternalStaticName
    },
    {
      requirementPropertyName: "source_processinletexternalstatic",
      systemPropertyName: processInletExternalStaticName
        ? "source_" + processInletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "processoutletexternalstatic",
      systemPropertyName: processOutletExternalStaticName
    },
    {
      requirementPropertyName: "source_processoutletexternalstatic",
      systemPropertyName: processOutletExternalStaticName
        ? "source_" + processOutletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "reactinletexternalstatic",
      systemPropertyName: reactInletExternalStaticName
    },
    {
      requirementPropertyName: "source_reactinletexternalstatic",
      systemPropertyName: reactInletExternalStaticName
        ? "source_" + reactInletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "reactoutletexternalstatic",
      systemPropertyName: reactOutletExternalStaticName
    },
    {
      requirementPropertyName: "source_reactoutletexternalstatic",
      systemPropertyName: reactOutletExternalStaticName
        ? "source_" + reactOutletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "supplyoutletairflow",
      systemPropertyName: supplyOutletAirFlowName
    },
    {
      requirementPropertyName: "source_supplyoutletairflow",
      systemPropertyName: supplyOutletAirFlowName
        ? "source_" + supplyOutletAirFlowName
        : undefined
    },
    {
      requirementPropertyName: "supplytargethumidity",
      systemPropertyName: supplyTargetHumidityName
    },
    {
      requirementPropertyName: "source_supplytargethumidity",
      systemPropertyName: supplyTargetHumidityName
        ? "source_" + supplyTargetHumidityName
        : undefined
    }
  ];

  const requirementsOPCs = requirementsOpcs.map(opc => {
    return propertiesMapping.reduce((soFar, current) => {
      const property = PropertyValueSet.get(
        current.requirementPropertyName,
        soFar
      );
      if (property && current.systemPropertyName) {
        return PropertyValueSet.set(
          current.systemPropertyName,
          property,
          PropertyValueSet.removeProperty(
            current.requirementPropertyName,
            soFar
          )
        );
      } else {
        return soFar;
      }
    }, opc);
  });

  return requirementsOPCs;
}

function getDhmodels(
  newMappings: ReadonlyArray<Types.NewMapping>,
  selectionNewProperties: PropertyValueSet.PropertyValueSet,
  systemNewProperties: ReadonlyArray<PropertyInfo>
) {
  const validDhmodels = newMappings
    .filter(
      r =>
        PropertyFilter.isValid(selectionNewProperties, r.filter) &&
        r.name === "dhmodel"
    )
    .map(dh => dh.value);
  const dhmodel = systemNewProperties.find(p => p.name === "dhmodel");
  const dhmodelItems = dhmodel
    ? dhmodel.items.filter(
        i =>
          i.value &&
          validDhmodels.some(
            v => parseInt(v, 10) === PropertyValue.getInteger(i.value!)
          )
      )
    : [];

  return dhmodelItems;
}

function mapSelectionToSystemTypeProperties(
  newMappings: ReadonlyArray<Types.NewMapping>,
  systemProperties: ReadonlyArray<PropertyInfo>,
  selectionProperties: PropertyValueSet.PropertyValueSet,
  dhmodelPropertyItem: PropertyItem
) {
  const newSystemProperties = systemProperties.reduce(
    (soFar, current) => {
      const currentConfiguration = createDefaultProperties(
        soFar.validProperties
      );

      // Dhmodel is special-case since its what we are looping on outside of this function and if we run it with the others it might find more than one valid value
      if (current.name === "dhmodel") {
        const isDhmodelValid =
          dhmodelPropertyItem.value &&
          PropertyFilter.isValid(
            currentConfiguration,
            current.validationFilter
          );

        if (isDhmodelValid) {
          return {
            ...soFar,
            validProperties: soFar.validProperties.concat([
              {
                ...current,
                defaultValues: [
                  {
                    value: dhmodelPropertyItem.value,
                    propertyFilter: PropertyFilter.Empty
                  }
                ]
              }
            ])
          };
        } else {
          return {
            ...soFar,
            invalidProperties: soFar.invalidProperties.concat([
              {
                type: "not-valid-with-configuration",
                propertyName: current.name,
                configuration: currentConfiguration,
                propertyValue: dhmodelPropertyItem.value!,
                propertyFilter: current.validationFilter
              }
            ])
          };
        }
      }

      // Find all mapping rows for property
      const mappingRows = newMappings.filter(r => r.name === current.name);

      // If no mapping rows exists we assume its not relevant and we leave it in the set unchanged
      if (mappingRows.length === 0) {
        return {
          ...soFar,
          validProperties: soFar.validProperties.concat([current])
        };
      } else {
        const mappingRow = mappingRows.find(r =>
          PropertyFilter.isValid(selectionProperties, r.filter)
        );

        // If there is no valid mapping
        if (!mappingRow) {
          return {
            ...soFar,
            invalidProperties: soFar.invalidProperties.concat([
              {
                type: "no-valid-mapping",
                propertyName: current.name,
                selectionNewProperties: selectionProperties,
                mappingRowsFilter: mappingRows.map(r => r.filter)
              }
            ])
          };
        }

        // Either the value is hardcoded in the mapping table or it is the same as in the selection set.
        const value = mappingRow.value.startsWith("{")
          ? PropertyValueSet.getValue(
              mappingRow.value.substring(1, mappingRow.value.length - 1),
              selectionProperties
            )
          : PropertyValue.create("integer", parseInt(mappingRow.value, 10));

        const maybeMappedValueInItems = current.items.find(i =>
          i.value ? PropertyValue.equals(i.value, value) : false
        );

        if (!maybeMappedValueInItems) {
          return {
            ...soFar,
            invalidProperties: soFar.invalidProperties.concat([
              {
                type: "undefined-value",
                propertyName: current.name,
                propertyValue: value
              }
            ])
          };
        }

        const isValueValid = PropertyFilter.isValid(
          currentConfiguration,
          maybeMappedValueInItems.validationFilter
        );

        if (isValueValid) {
          return {
            ...soFar,
            validProperties: soFar.validProperties.concat([
              {
                ...current,
                defaultValues: [
                  { value: value, propertyFilter: PropertyFilter.Empty }
                ]
              }
            ])
          };
        } else {
          return {
            ...soFar,
            invalidProperties: soFar.invalidProperties.concat([
              {
                type: "not-valid-with-configuration",
                propertyName: current.name,
                configuration: currentConfiguration,
                propertyValue: value,
                propertyFilter: maybeMappedValueInItems.validationFilter
              }
            ])
          };
        }
      }
    },
    {
      validProperties: [] as ReadonlyArray<PropertyInfo>,
      invalidProperties: [] as ReadonlyArray<Types.InvalidProperty>
    }
  );

  return newSystemProperties;
}

function getOperatingCasesForVariant(
  systemType: Types.SystemType,
  newProperties: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet,
  translate: LanguageTexts.Translate
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const opcTemplates = systemType.opcTemplates
    .find(t =>
      PropertyFilterHelpers.isValid(
        t.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    )!
    .components.filter(c =>
      PropertyFilterHelpers.isValid(
        c.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    );

  if (opcTemplates.length === 0 || systemType.opcProperties.length === 0) {
    return [];
  }

  const opcTemplateComponents: ReadonlyArray<{
    readonly id: string;
    readonly properties: PropertyValueSet.PropertyValueSet;
  }> = opcTemplates.map(opc => ({
    id: opc.id,
    properties: PropertyValueSet.fromString(opc.properties || "")
  }));

  const opcProductProperties = systemType.opcProperties.map(p => ({
    name: p.name,
    quantity: p.quantity,
    defaultValues: p.defaultValues,
    valueSources: p.valueSources.map(v => ({
      id: v.id,
      value: v.value,
      propertyValueSourceId: v.propertyValueSourceId,
      propertyFilter: PropertyFilter.toString(v.propertyFilter),
      claimFilter: "",
      parameters: v.parameters
    })),
    items: p.items
      .filter(v =>
        PropertyFilterHelpers.isValid(
          v.validationFilter,
          PropertyFilter.Empty,
          newProperties
        )
      )
      .map(i => ({
        id: i.id!,
        value: PropertyValue.toString(i.value!)
      }))
  }));

  const processedOpcs = createPreProcessedOpcs(
    newProperties,
    systemType.opcPreProcessRows
  );

  const operatingCases = createInitialOperatingCases(
    climateSettings,
    opcTemplateComponents,
    opcProductProperties,
    translate
  ).map(p => ({
    ...p,
    settings: PropertyValueSet.merge(processedOpcs, p.settings)
  }));

  return operatingCases.map(opc => opc.settings);
}

function createDefaultProperties(
  properties: ReadonlyArray<PropertyInfo>
): PropertyValueSet.PropertyValueSet {
  return ProductProperties.autoSelectSingleValidValue(
    properties.map(p => ({
      name: p.name,
      quantity: p.quantity,
      validationFilter: PropertyFilter.toString(p.validationFilter),
      values: p.items.map(v => ({
        value: PropertyValue.toString(v.value!)!,
        validationFilter: PropertyFilter.toString(v.validationFilter)
      }))
    })),
    ProductProperties.createDefaultProperties(
      properties.map(p => ({
        id: "",
        name: p.name,
        quantity: p.quantity,
        defaultValues: p.defaultValues,
        values: p.items.map(v => ({
          id: "",
          value: PropertyValue.toString(v.value!)!
        }))
      })),
      true
    ),
    ""
  );
}

function validateFlowsAndGetQuantity(
  system: Types.SystemType,
  newProperties: PropertyValueSet.PropertyValueSet,
  requirementsOpcs: ReadonlyArray<PropertyValueSet.PropertyValueSet>
): {
  readonly invalidFlow: Types.InvalidFlow | undefined;
  readonly quantity: number;
} {
  const minMaxFlows = system.minMaxFlows.find(m =>
    PropertyFilter.isValid(newProperties, m.filter)
  );

  if (!minMaxFlows) {
    return { invalidFlow: undefined, quantity: 1 };
  }

  const minOpcFlow = requirementsOpcs
    .map(
      opc => PropertyValueSet.getAmount<"MassFlow">("supplyoutletairflow", opc)!
    )
    .sort(
      (a, b) =>
        Amount.valueAs(Units.StandardCubicMeterPerHour, a) -
        Amount.valueAs(Units.StandardCubicMeterPerHour, b)
    )[0];
  const maxOpcFlow = requirementsOpcs
    .map(
      opc => PropertyValueSet.getAmount<"MassFlow">("supplyoutletairflow", opc)!
    )
    .sort(
      (a, b) =>
        Amount.valueAs(Units.StandardCubicMeterPerHour, b) -
        Amount.valueAs(Units.StandardCubicMeterPerHour, a)
    )[0];

  const isMinFlowInvalid = Amount.lessThan(minOpcFlow, minMaxFlows.minFlow);
  const isMaxFlowInvalid = Amount.greaterThan(maxOpcFlow, minMaxFlows.maxFlow);

  if (!isMinFlowInvalid && !isMaxFlowInvalid) {
    return { invalidFlow: undefined, quantity: 1 };
  }

  if (isMinFlowInvalid) {
    return {
      invalidFlow: {
        type: "invalid-min-flow",
        systemMinFlow: minMaxFlows.minFlow,
        systemMaxFlow: minMaxFlows.maxFlow,
        opcMinFlow: minOpcFlow,
        opcMaxFlow: maxOpcFlow
      },
      quantity: 0
    };
  }

  if (isMaxFlowInvalid) {
    const opcMinFlow = Amount.valueAs(
      Units.StandardCubicMeterPerHour,
      minOpcFlow
    );
    const opcMaxFlow = Amount.valueAs(
      Units.StandardCubicMeterPerHour,
      maxOpcFlow
    );

    const sysMinFlow = Amount.valueAs(
      Units.StandardCubicMeterPerHour,
      minMaxFlows.minFlow
    );
    const sysMaxFlow = Amount.valueAs(
      Units.StandardCubicMeterPerHour,
      minMaxFlows.maxFlow
    );

    const quantityRequired = Math.ceil(opcMaxFlow / sysMaxFlow);

    const dividedOpcMinFlow = opcMinFlow / quantityRequired;

    if (dividedOpcMinFlow < sysMinFlow) {
      return {
        invalidFlow: {
          type: "invalid-flow",
          systemMinFlow: minMaxFlows.minFlow,
          systemMaxFlow: minMaxFlows.maxFlow,
          opcMinFlow: Amount.create(
            dividedOpcMinFlow,
            Units.StandardCubicMeterPerHour,
            0
          ),
          opcMaxFlow: maxOpcFlow
        },
        quantity: 0
      };
    } else {
      return { invalidFlow: undefined, quantity: quantityRequired };
    }
  }

  return { invalidFlow: undefined, quantity: 1 };
}

function getVariantDetails(newProperties: PropertyValueSet.PropertyValueSet) {
  const checkboxProperties = checkboxGroups.reduce(
    (soFar, currentCheckboxGroup) => {
      const propertyValue = PropertyValueSet.getInteger(
        currentCheckboxGroup.propertyName,
        newProperties
      );
      const propertyDisplayName = checkboxPropertyOptions.find(
        cpo =>
          cpo.propertyName === currentCheckboxGroup.propertyName &&
          cpo.value === propertyValue
      )?.displayName;

      if (!propertyDisplayName) {
        return soFar;
      }

      return soFar.concat([
        currentCheckboxGroup.groupName + ": " + propertyDisplayName
      ]);
    },
    [] as ReadonlyArray<string>
  );

  return checkboxProperties;
}

function modifyNewPropertiesDefinitions(
  systemType: Types.SystemType
): ReadonlyArray<PropertyInfo> {
  const modifiedNewPropertiesDefinitions = systemType.newProperties.map(np => {
    const propertyOverride = newPropertiesOverrides.find(
      npo => npo.systemType === systemType.id && npo.propertyName === np.name
    );

    if (propertyOverride === undefined) {
      return np;
    }

    return {
      ...np,
      items: np.items.map(i => {
        if (i.value?.value === propertyOverride.propertyValue.value) {
          return {
            ...i,
            validationFilter: propertyOverride.replacementFilter
          };
        }
        return i;
      })
    };
  });

  return modifiedNewPropertiesDefinitions;
}

export function createPreProcessedOpcs(
  newProperties: PropertyValueSet.PropertyValueSet,
  preProcessRows: ReadonlyArray<Types.OpcPreProcessRow>
) {
  return preProcessRows.reduce((soFar, current) => {
    if (PropertyFilter.isValid(newProperties, current.propertyFilter)) {
      return PropertyValueSet.setInteger(
        current.propertyName,
        current.IfValidValue,
        soFar
      );
    } else if (current.IfNotValidValue !== undefined) {
      return PropertyValueSet.setInteger(
        current.propertyName,
        current.IfNotValidValue,
        soFar
      );
    } else {
      return soFar;
    }
  }, PropertyValueSet.Empty);
}

//tslint:disable-next-line
