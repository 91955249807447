import { UpdateFunction } from "../types";

export const handleSetDebugSettings: UpdateFunction = (action, state) => {
  if (action.type !== "setDebugSettings") {
    return [state];
  }
  return [
    {
      ...state,
      debugSettings: {
        showTextsWithTextId: action.showTextsWithTextId,
        showTextsDB: action.showTextsDB,
        includeServerLog: action.includeServerLog,
        showHiddenResults: action.showHiddenResults,
        showHiddenProperties: action.showHiddenProperties,
        useCalculationWorkers: action.useCalculationWorkers
      }
    }
  ];
};
