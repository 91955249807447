import styled, { StyledInterface } from "styled-components";

export * from "styled-components";
export {
  css,
  keyframes,
  createGlobalStyle,
  ThemeProvider
} from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defExport = styled as any;
const defaultStyled: StyledInterface =
  typeof defExport === "function"
    ? defExport
    : typeof defExport.default === "object"
    ? defExport.default?.default
    : defExport.default;

if (typeof defaultStyled !== "function") {
  console.error("styled-components default export was: ", styled);
  console.error(
    "styled-components default export was converted to: ",
    defaultStyled
  );
  throw new Error("Could not re-export styled-components default export.");
}

// eslint-disable-next-line import/no-default-export
export { defaultStyled as default, defaultStyled as styled };
