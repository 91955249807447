import gql from "graphql-tag";

const princingFragment = gql`
  fragment PricingManagerResultPricing on PricingType {
    id
    name
    revisionNo
    status
    statusDate
  }
`;

const searchResultFragment = gql`
  fragment PricingManagerResultSearchResult on PricingFileType {
    id
    name
    pricingNo

    latestPricing {
      id
      name
      revisionNo
      status
      statusDate
    }

    pricings {
      ...PricingManagerResultPricing
    }

    labels {
      id
      name
    }

    owner {
      id
      userName
    }
  }

  ${princingFragment}
`;

export const queryUser = gql`
  query PricingManagerUserQuery($filter: PricingSearchFilterInputType!) {
    user {
      searchPricings(filter: $filter) {
        id
        totalResults
        results {
          ...PricingManagerResultSearchResult
        }
      }
    }
  }

  ${searchResultFragment}
`;

export const queryProduct = gql`
  query PricingManagerProductQuery {
    product {
      genesysNumberPrefixes {
        id
        pricingNo
      }
    }
  }
`;
