import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as System from "../../system";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import * as Details from "./details";
import { CaseFilter } from "../../types";

export type State = {
  readonly activeTab: ActiveTab;
  readonly propertiesSelectorState: PropertiesSelector.State;
  readonly initialSelectorState: PropertiesSelector.State;
  readonly valuesHasChanged: boolean;
  readonly propertiesHasChanged: boolean;
  readonly detailsState: Details.State;
  readonly systemTypeCaseFilter: ReadonlyArray<CaseFilter>;
};

export type ActiveTab =
  | "results-summary"
  | "op-case-results"
  | "notes"
  | "component-spec";

export const init = (
  sharedState: SharedState.State,
  systemTypid: string,
  component: System.Component,
  systemId: string,
  systemStatus: number,
  activeTab: ActiveTab,
  systemTypeCaseFilter: ReadonlyArray<CaseFilter>
): [State, Cmd<Action>] => {
  const propertiesSelectorState = PropertiesSelector.init(component.properties);
  const [detailsState, detailsCmd] = Details.init(
    sharedState,
    systemTypid,
    systemId,
    systemStatus,
    component,
    systemTypeCaseFilter
  );
  return [
    {
      propertiesSelectorState,
      valuesHasChanged: false,
      propertiesHasChanged: false,
      initialSelectorState: propertiesSelectorState,
      activeTab: activeTab,
      detailsState: detailsState,
      systemTypeCaseFilter: systemTypeCaseFilter
    },
    Cmd.batch<Action>([Cmd.map(Action.dispatchDetails, detailsCmd)])
  ];
};

export const Action = ctorsUnion({
  changeActiveTab: (tab: ActiveTab) => ({ tab }),
  dispatchPropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchDetails: (action: Details.Action) => ({
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  systemId: string,
  componentId: string
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const propertiesHasChanged = !PropertyValueSet.equals(
        PropertiesSelector.getSelectedProperties(state.initialSelectorState),
        PropertiesSelector.getSelectedProperties(state.propertiesSelectorState)
      );
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.propertiesSelectorState,
        sharedState,
        { type: "Component", systemId, componentId }
      );
      return [
        {
          ...state,
          propertiesSelectorState,
          propertiesHasChanged,
          valuesHasChanged:
            propertiesHasChanged || state.detailsState.notesState.hasChanged
        },
        Cmd.map(Action.dispatchPropertiesSelector, propertiesSelectorCmd),
        propertiesSelectorSharedAction
      ];
    }
    case "changeActiveTab": {
      return [{ ...state, activeTab: action.tab }];
    }
    case "dispatchDetails": {
      const [detailsState, detailsSharedStateACtion] = Details.update(
        action.action,
        state.detailsState
      );
      return [
        {
          ...state,
          detailsState: detailsState,
          valuesHasChanged:
            detailsState.notesState.hasChanged || state.propertiesHasChanged
        },
        ,
        detailsSharedStateACtion
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getConfiguratorActions(
  state: State,
  componentId: string,
  userAction: "Save" | "SaveAndCalculate"
): ReadonlyArray<ConfiguratorAction | undefined> {
  const saveAction = ConfiguratorAction.updateComponentProperties(
    [
      {
        componentId: componentId,
        properties: PropertiesSelector.getSelectedProperties(
          state.propertiesSelectorState
        )
      }
    ],
    userAction === "SaveAndCalculate"
  );

  return [
    Details.getDetailsConfigurationActions(state.detailsState),
    state.propertiesHasChanged ? saveAction : undefined
  ];
}
