import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import { getIfIntegerChanged } from "../../helper-functions";
import { StaticHeatLoadIcon } from "@genesys/ui-elements";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import { LoadOperationTime } from "../../types";
//Icon
export const Icon = StaticHeatLoadIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

/// Moisture load selection
export const useMoistProperty = undefined;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useStaticHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcstaticheatload";
export const sourceName = MoistureHeatLoadTypeEnum.STATIC_HEAT_LOAD;
export const description = "mlcstaticheatloadexplanation";
export const useLoadKey = "mlcusestaticheatload";

export const manualInputProperty = KnownProperties.staticHeatLoadManualInput;

export const properties = [
  KnownProperties.useStaticHeatLoad,

  KnownProperties.staticHeatLoadManualInput,
  KnownProperties.staticLoadsHeatLoad
];
export const overridableProperties = [KnownProperties.staticLoadsHeatLoad];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let allUpdates = getPropertyDefaults(prevProperties, selectedProperties);

  return allUpdates;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.factoryHours;
}
function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  const useStaticHeatLoad = getIfIntegerChanged(
    prevSelections,
    currentSelections,
    KnownProperties.useStaticHeatLoad
  );
  if (useStaticHeatLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      {
        [KnownProperties.staticLoadsHeatLoad]: PropertyValue.fromAmount(
          Amount.create(0, Units.KiloWatt)
        )
      },
      defaults
    );
    defaults = PropertyValueSet.merge(
      {
        [KnownProperties.staticHeatLoadManualInput]:
          PropertyValue.fromInteger(0)
      },
      defaults
    );
  }

  return defaults;
}
