import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../../shared-state";
import { BaseState } from "../../step-state-base";
import { PropertyValueSet } from "@genesys/property";
import * as DhuSizeSelector from "../../../../size-selectors/dhu-size-selector";

export type State =
  | ({
      readonly dhuSizeSelectorState: DhuSizeSelector.State;
    } & BaseState)
  | undefined;

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [State, Cmd<Action>?] => {
  const [dhuSizeSelectorState, dhuSizeSelectorCmd] = DhuSizeSelector.init(
    sharedState,
    base.systemTypeId
  );
  return [
    {
      ...base,
      ok: false,
      dhuSizeSelectorState: dhuSizeSelectorState
    },
    Cmd.map(Action.dispatchDhuSizeSelectorActions, dhuSizeSelectorCmd)
  ];
};

export const Action = ctorsUnion({
  dispatchDhuSizeSelectorActions: (action: DhuSizeSelector.Action) => ({
    action
  }),
  dummy: () => ({})
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  switch (action.type) {
    case "dispatchDhuSizeSelectorActions": {
      if (!state?.dhuSizeSelectorState) {
        return [state];
      }

      const [
        dhuSizeSelectorState,
        dhuSizeSelectorCmd,
        sharedStateAction,
        didSizeChanged
      ] = DhuSizeSelector.update(action.action, state.dhuSizeSelectorState);

      const size = DhuSizeSelector.getSize(dhuSizeSelectorState);

      if (didSizeChanged && size) {
        return [
          {
            ...state,
            dhuSizeSelectorState: dhuSizeSelectorState,
            ok: true,
            newProperties: PropertyValueSet.setInteger(
              "size",
              size,
              state.newProperties
            )
          }
        ];
      } else {
        return [
          {
            ...state,
            dhuSizeSelectorState: dhuSizeSelectorState
          },
          Cmd.map(Action.dispatchDhuSizeSelectorActions, dhuSizeSelectorCmd),
          sharedStateAction ? [sharedStateAction] : []
        ];
      }
    }
    case "dummy":
      return [state];
    default:
      exhaustiveCheck(action, true);
  }
}
