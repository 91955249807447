import * as React from "react";
import styled from "../../styled-components";
import Portal from "@mui/material/Portal";
import { zIndex } from "../../zIndex";

export interface ModalProps {
  readonly children: NonNullable<React.ReactNode>;
  readonly onClose: () => void;
  readonly maxWidth: number;
}

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }: { readonly maxWidth: number }) => maxWidth}px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
`;

const Root = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10px;
  z-index: ${zIndex.Modal};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.2;
  z-index: ${zIndex.Modal};
`;

export function Modal(props: ModalProps): JSX.Element {
  const { maxWidth, onClose, ...restProps } = props;
  return (
    <Portal>
      <Overlay></Overlay>
      <Root
        onClick={e => {
          if (e.currentTarget === e.target) {
            e.stopPropagation();
            e.preventDefault();
            onClose();
          }
        }}
      >
        <StyledModal {...restProps} maxWidth={props.maxWidth} />
      </Root>
    </Portal>
  );
}
