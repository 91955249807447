import React from "react";
import { getSystemTypeStatusText } from "@genesys/shared/lib/system-status-text";
import { P2 } from "@genesys/ui-elements";
import * as SharedState from "../../shared-state";
import { State } from "./state";

export function View({
  state,
  sharedState,
  plenumSizeProperty,
  systemTypeId
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemTypeId: string;
  readonly plenumSizeProperty: string;
}): JSX.Element {
  if (state.type === "Loading") {
    return <>...</>;
  }

  const systemStatusString = getSystemTypeStatusText(
    sharedState.translate,
    state.components,
    {
      id: systemTypeId,
      plenumSizeProperty: plenumSizeProperty
    }
  );
  const systemStatusStringArray = systemStatusString.split(",");

  const systemStatusStringPartOne =
    systemStatusStringArray.length > 2
      ? systemStatusStringArray.slice(0, 2).toString()
      : systemStatusString;

  const systemStatusStringPartTwo =
    systemStatusStringArray.length > 2
      ? systemStatusStringArray.slice(2).toString()
      : undefined;

  return (
    <>
      <P2 weight="normal" color="secondary">
        {systemStatusStringPartOne}
      </P2>
      {systemStatusStringPartTwo && (
        <P2 weight="normal" color="secondary">
          {systemStatusStringPartTwo}
        </P2>
      )}
    </>
  );
}
