import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as Routes from "../routes";
import { clientConfig } from "../config";
import * as SharedState from "../shared-state";
import { promiseCmd } from "../promise-effect-manager";
import * as Navigation from "../navigation-effect-manager";
import { createTopLevelNodes, TopLevelNodes } from "./timer-grouping";
import * as Types from "./types";

export function categoryKey(serviceName: string, category: string): string {
  return `${serviceName};${category}`;
}

export type State = NoSearchState | FetchingState | LoadedState;

export type NoSearchState = {
  readonly type: "NoSearchState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
  readonly isHelpOpen: boolean;
};

export type FetchingState = {
  readonly type: "FetchingState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
  readonly isHelpOpen: boolean;
};

export type LoadedState = {
  readonly type: "LoadedState";
  readonly location: Routes.LogViewerLocation;
  readonly traceIdField: string;
  readonly isHelpOpen: boolean;
  readonly servicesWithCategories: Map<string, Set<string>>;
  readonly logRows: ReadonlyArray<Types.LogRow>;
  readonly traceSpans: ReadonlyArray<Types.TraceSpan>;
  readonly topLevelNodes: TopLevelNodes;
  readonly enabledServicesAndCategories: Set<string>;
  readonly lineFilterRegexField: string;
  readonly lineFilterRegex: string;
  readonly showFilter: boolean;
  readonly showCategoryFilter: boolean;
  readonly showAdvancedFilter: boolean;
  readonly caseTypes: Set<string>;
  readonly enabledCaseTypes: Set<string>;
  readonly caseNames: Set<string>;
  readonly enabledCaseNames: Set<string>;
  readonly components: Set<string>;
  readonly enabledComponents: Set<string>;
  readonly exportFileUrl: string | undefined;
  readonly view: "logs" | "timers";
};

export const init = (
  location: Routes.LogViewerLocation,
  sharedState: SharedState.State
): [State, Cmd<Action>?] => {
  const traceIdField =
    location.type === "withTraceId" ? location.params.traceId : "";

  if (traceIdField.length === 0) {
    return [
      {
        type: "NoSearchState",
        location: location,
        traceIdField: traceIdField,
        isHelpOpen: false
      }
    ];
  }

  return [
    {
      type: "FetchingState",
      location: location,
      traceIdField: traceIdField,
      isHelpOpen: false
    },
    createFetchCmd(
      clientConfig.genesysBackend,
      sharedState.accessToken,
      traceIdField
    )
  ];
};

// -- UPDATE

export const Action = ctorsUnion({
  TelemetryReceived: (
    traceSpans: ReadonlyArray<Types.TraceSpan>,
    rows: ReadonlyArray<Types.LogRowFromServer>
  ) => ({ traceSpans, rows }),
  RetrieveTelemetry: () => ({}),
  toggleCategory: (serviceName: string, category: string) => ({
    serviceName,
    category
  }),
  toggleCaseType: (caseType: string) => ({ caseType }),
  toggleCaseName: (caseName: string) => ({ caseName }),
  toggleComponent: (component: string) => ({ component }),
  setLineFilterRegexField: (regex: string) => ({ regex }),
  setLineFilterRegex: () => ({}),
  toggleFilterContainer: () => ({}),
  toggleCategoriesContainer: () => ({}),
  toggleAdvancedContainer: () => ({}),
  createExportFile: (text: string) => ({ text }),
  clearExportFile: () => ({}),
  setIsHelpOpen: (isOpen: boolean) => ({ isOpen }),
  setView: (view: "logs" | "timers") => ({ view })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.State
): readonly [State, Cmd<Action>?] {
  switch (action.type) {
    case "TelemetryReceived": {
      const {
        servicesWithCategories,
        enabledServicesAndCategories,
        caseTypes,
        caseNames,
        components
      } = getFilterMaps(action.rows);

      return [
        {
          ...state,
          type: "LoadedState",
          logRows: action.rows.map((r, i) => ({
            ...r,
            id: i,
            formatted: `${new Date(
              r.timestamp / 1000 / 1000
            ).toLocaleTimeString()} - (${r.serviceName}) [${r.category}] [${
              r.caseType
            }] [${r.caseName}] [${r.component}]: ${r.body}`
          })),
          traceSpans: action.traceSpans,
          topLevelNodes: createTopLevelNodes(action.traceSpans),
          servicesWithCategories,
          enabledServicesAndCategories,
          lineFilterRegexField: "",
          lineFilterRegex: "",
          showFilter: false,
          showCategoryFilter: false,
          showAdvancedFilter: false,
          caseTypes: caseTypes,
          enabledCaseTypes: caseTypes,
          caseNames: caseNames,
          enabledCaseNames: caseNames,
          components: components,
          enabledComponents: components,
          exportFileUrl: undefined,
          view: "logs"
        }
      ];
    }
    case "RetrieveTelemetry": {
      return [
        { ...state, type: "FetchingState" },
        Navigation.pushUrl("/log-viewer/" + state.traceIdField)
      ];
    }
    case "toggleCategory": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledServicesAndCategories: toggleCategoriesSelection(
            state.servicesWithCategories,
            state.enabledServicesAndCategories,
            action.serviceName,
            action.category
          )
        }
      ];
    }
    case "toggleCaseType": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledCaseTypes: toggleAdvancedSet(
            state.caseTypes,
            state.enabledCaseTypes,
            action.caseType
          )
        }
      ];
    }
    case "toggleCaseName": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledCaseNames: toggleAdvancedSet(
            state.caseNames,
            state.enabledCaseNames,
            action.caseName
          )
        }
      ];
    }
    case "toggleComponent": {
      if (state.type !== "LoadedState") {
        return [state];
      }

      return [
        {
          ...state,
          enabledComponents: toggleAdvancedSet(
            state.components,
            state.enabledComponents,
            action.component
          )
        }
      ];
    }
    case "setLineFilterRegex": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, lineFilterRegex: state.lineFilterRegexField }];
    }
    case "setLineFilterRegexField": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, lineFilterRegexField: action.regex }];
    }
    case "toggleFilterContainer": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [
        {
          ...state,
          showFilter: !state.showFilter,
          showAdvancedFilter: state.showFilter
            ? state.showAdvancedFilter
            : false,
          showCategoryFilter: state.showFilter
            ? state.showCategoryFilter
            : false
        }
      ];
    }
    case "toggleCategoriesContainer": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, showCategoryFilter: !state.showCategoryFilter }];
    }
    case "toggleAdvancedContainer": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, showAdvancedFilter: !state.showAdvancedFilter }];
    }
    case "createExportFile": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      const blob = new Blob([action.text]);
      const url = URL.createObjectURL(blob);
      return [{ ...state, exportFileUrl: url }];
    }
    case "clearExportFile": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, exportFileUrl: undefined }];
    }
    case "setIsHelpOpen": {
      return [{ ...state, isHelpOpen: action.isOpen }];
    }
    case "setView": {
      if (state.type !== "LoadedState") {
        return [state];
      }
      return [{ ...state, view: action.view }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

function getFilterMaps(rows: ReadonlyArray<Types.LogRowFromServer>): {
  readonly servicesWithCategories: Map<string, Set<string>>;
  readonly enabledServicesAndCategories: Set<string>;
  readonly caseTypes: Set<string>;
  readonly caseNames: Set<string>;
  readonly components: Set<string>;
} {
  const servicesWithCategories = new Map<string, Set<string>>();
  const enabledServicesAndCategories = new Set<string>();
  const caseNames = new Set<string>();
  const caseTypes = new Set<string>();
  const components = new Set<string>();
  caseNames.add(Types.All);
  caseNames.add(Types.Empty);
  caseTypes.add(Types.All);
  caseTypes.add(Types.Empty);
  components.add(Types.All);
  components.add(Types.Empty);
  for (const r of rows) {
    servicesWithCategories.set(
      r.serviceName,
      (servicesWithCategories.get(r.serviceName) ?? new Set<string>()).add(
        r.category
      )
    );
    enabledServicesAndCategories.add(categoryKey(r.serviceName, Types.All));
    enabledServicesAndCategories.add(categoryKey(r.serviceName, r.category));
    if (r.caseName !== "") {
      caseNames.add(r.caseName);
    }
    if (r.caseType !== "") {
      caseTypes.add(r.caseType);
    }
    if (r.component !== "") {
      components.add(r.component);
    }
  }
  return {
    servicesWithCategories,
    enabledServicesAndCategories,
    caseTypes,
    caseNames,
    components
  };
}

function toggleAdvancedSet(
  set: Set<string>,
  enabledSet: Set<string>,
  key: string
): Set<string> {
  if (key === Types.All) {
    return toggleAdvancedAll(set, enabledSet);
  } else {
    const newEnableSet = new Set(enabledSet);
    toggleEnabled(newEnableSet, key);
    return newEnableSet;
  }
}

function toggleAdvancedAll(
  set: Set<string>,
  enabledSet: Set<string>
): Set<string> {
  const newEnabledSet = new Set(enabledSet);
  toggleEnabled(newEnabledSet, Types.All);
  for (const key of set || []) {
    if (newEnabledSet.has(Types.All)) {
      newEnabledSet.add(key);
    } else {
      newEnabledSet.delete(key);
    }
  }

  return newEnabledSet;
}

function toggleEnabled(enabledSet: Set<string>, key: string): void {
  if (enabledSet.has(key)) {
    enabledSet.delete(key);
  } else {
    enabledSet.add(key);
  }
}

function toggleCategoriesSelection(
  groupMap: Map<string, Set<string>>,
  enabledSet: Set<string>,
  groupKey: string,
  key: string
) {
  if (key === Types.All) {
    const enabledServicesAndCategories = toggleAllCategoriesInGroup(
      groupMap,
      enabledSet,
      groupKey
    );
    return enabledServicesAndCategories;
  } else {
    const newEnableSet = new Set(enabledSet);
    toggleEnabledCategories(newEnableSet, groupKey, key);
    return newEnableSet;
  }
}

function toggleAllCategoriesInGroup(
  groupMap: Map<string, Set<string>>,
  enabledSet: Set<string>,
  groupKey: string
): Set<string> {
  const newEnabledSet = new Set(enabledSet);
  toggleEnabledCategories(newEnabledSet, groupKey, Types.All);
  for (const key of groupMap.get(groupKey) || []) {
    if (newEnabledSet.has(categoryKey(groupKey, Types.All))) {
      newEnabledSet.add(categoryKey(groupKey, key));
    } else {
      newEnabledSet.delete(categoryKey(groupKey, key));
    }
  }

  return newEnabledSet;
}

function toggleEnabledCategories(
  enabledSet: Set<string>,
  groupKey: string,
  key: string
): void {
  const setKey = categoryKey(groupKey, key);
  if (enabledSet.has(setKey)) {
    enabledSet.delete(setKey);
  } else {
    enabledSet.add(setKey);
  }
}

function createFetchCmd(
  genesysBackend: string,
  accessToken: string,
  traceId: string
): Cmd<Action> {
  return promiseCmd(
    async () => {
      let logRows: ReadonlyArray<Types.LogRowFromServer> = [];
      let traceSpans: ReadonlyArray<Types.TraceSpan> = [];

      try {
        logRows = await fetch(
          `${genesysBackend}/internal/CalculationLogsApi?traceId=${traceId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then(d => d.json());
      } catch (e) {
        console.log(e);
      }

      try {
        traceSpans = await fetch(
          `${genesysBackend}/internal/TraceApi?traceId=${traceId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        ).then(d => d.json());
      } catch (e) {
        console.log(e);
      }

      return { logRows, traceSpans };
    },
    data => Action.TelemetryReceived(data.traceSpans, data.logRows)
  );
}
