import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { Action } from "../state";
import { updateSystemMutation } from "../update-system-mutation";

export function lockSystem(
  sharedState: SharedState.State,
  systemId: string
): [Cmd<Action>, SystemStatus] {
  return [
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.UpdateSystemStatus,
      GraphQLTypes.UpdateSystemStatusVariables,
      Action
    >(
      updateSystemMutation,
      {
        input: {
          forceUpdate: false,
          systemId,
          targetStatus: SystemStatus.LockSuccess
        }
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.LockSuccess
  ];
}
