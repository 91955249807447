import * as React from "react";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch } from "@typescript-tea/core";
import {
  StandardButton,
  DropDownButton,
  H1,
  HelpIcon,
  ToolTipWrapper
} from "@genesys/ui-elements/";
import { ProcessButton } from "./process-button";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Modal, ModalHeader } from "@genesys/ui-elements/lib/organisms/modal";
import { PropertyValueSet } from "@genesys/property";
import * as SharedState from "../../shared-state";
import {
  ComponentEditorHeader,
  ButtonController,
  ButtonContainer,
  TitleContainer
} from "../elements";
import * as Types from "../types";
import * as Product from "../product";
import * as System from "../system";
import * as ComponentEditor from "../modals/component-editor";
import * as OperatingCases from "../../operating-case-selector";
import * as Printouts from "../modals/printouts";
import * as MechanicalSketch from "../modals/mechanical-sketch";
import * as BuildInSc from "../modals/build-in-solid-contacts";
import * as BuildInRevit from "../modals/build-in-revit";
import * as PsychrometricChart from "../modals/psychrometric-chart-presenter";
import * as Pid from "../modals/pid";
import * as Price from "../modals/price";
import * as Accessories from "../modals/accessories";
import * as PropertyGroups from "../modals/property-groups";
import * as SystemAccessories from "../modals/system-accessories";
import * as NotesAndDocuments from "../modals/notes-and-documents";
import * as Energy from "../modals/energy";
import * as PlenumSize from "../modals/plenum-size";
import * as MCompare from "../modals/m-compare";
import * as MultipleSystemsEditor from "../modals/multiple-systems-editor";
import * as DataCenterEditor from "../modals/data-center-editor";
import * as CondensationAnalysis from "../modals/condensation-analysis";
import * as LockedSystemCalculation from "../modals/locked-system-calculation";
import * as WiringDiagram from "../modals/wiring-diagram";
import { Action } from "../state";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as ComponentsMessages from "@genesys/shared/lib/components-messages";
import { clientConfig, getModalBorderColor } from "../../config";

export interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly title: string;
  readonly onSave: () => void;
  readonly onSaveAndCalculate: () => void;
  readonly calculate: () => void;
  readonly onSaveAndCalculateEnergy: () => void;
  readonly onClose: () => void;
  readonly modal: Types.Modal;
  readonly sharedState: SharedState.State;
  readonly system: System.System;
  readonly systemType: Product.SystemType;
  readonly products: ReadonlyArray<Product.Product>;
  readonly productData: Product.ProductData;
  readonly componentsMessages: ReadonlyArray<ComponentsMessages.ComponentMessage>;
}

export function WorkModal(props: Props): JSX.Element | null {
  if (props.modal.type === "NoModal") {
    return null;
  }

  const title = props.modal.title;
  const wikijsUrl = props.modal.wikiJsUrl;

  return (
    <Modal onClose={props.onClose} maxWidth={1400}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <ComponentEditorHeader>
          <TitleContainer>
            <H1 color="dark" weight="bold">
              {title}
            </H1>
            {wikijsUrl && (
              <ToolTipWrapper
                padding="0px"
                title={props.sharedState.translate(
                  LanguageTexts.goToProductPage()
                )}
              >
                <HelpIcon onClick={() => window.open(wikijsUrl)} />
              </ToolTipWrapper>
            )}
          </TitleContainer>

          <ButtonController>
            <CtaButtons
              calculate={props.calculate}
              modal={props.modal}
              onClose={props.onClose}
              onSave={props.onSave}
              onSaveAndCalculate={props.onSaveAndCalculate}
              onSaveAndCalculateEnergy={props.onSaveAndCalculateEnergy}
              systemStatus={props.system.status}
              translate={props.sharedState.translate}
            />
          </ButtonController>
        </ComponentEditorHeader>
      </ModalHeader>
      <ModalContent
        productData={props.productData}
        dispatch={props.dispatch}
        modal={props.modal}
        sharedState={props.sharedState}
        system={props.system}
        systemType={props.systemType}
        products={props.products}
        componentsMessages={props.componentsMessages}
      />
    </Modal>
  );
}

function ModalContent({
  dispatch,
  modal,
  sharedState,
  products,
  system,
  systemType,
  componentsMessages,
  productData
}: {
  readonly dispatch: Dispatch<Action>;
  readonly modal: Exclude<Types.Modal, { readonly type: "NoModal" }>;
  readonly sharedState: SharedState.State;
  readonly system: System.System;
  readonly products: ReadonlyArray<Product.Product>;
  readonly systemType: Product.SystemType;
  readonly productData: Product.ProductData;
  readonly componentsMessages: ReadonlyArray<ComponentsMessages.ComponentMessage>;
}): JSX.Element {
  switch (modal.type) {
    case "ComponentEditor": {
      const component = system.components.find(
        c => c.id === modal.selectedComponentId
      );
      const sysComponent = system.components.find(c =>
        c.productId.endsWith("SYS")
      );
      if (!component) {
        return <div>Component not found</div>;
      }
      if (!sysComponent) {
        return <div>SYS component not found</div>;
      }
      const singleProduct = products.find(p => p.id === component.productId);

      return (
        <ComponentEditor.ComponentEditorView
          dispatch={Dispatch.map(Action.dispatchComponentEditor, dispatch)}
          state={modal.state}
          sharedState={sharedState}
          productData={singleProduct}
          sysProperties={sysComponent.properties}
          system={system}
          component={component}
          componentsMessages={componentsMessages}
        />
      );
    }
    case "OperatingCases": {
      const opcProduct = products.find(product => product.id.endsWith("OPC"));

      if (!opcProduct) {
        return <div>OPC product not found</div>;
      }

      const opcComponent = system.components.find(c =>
        c.productId.endsWith("OPC")
      );
      if (!opcComponent) {
        return <div>OPC component not found</div>;
      }

      const sysComponent = system.components.find(c =>
        c.productId.endsWith("SYS")
      );

      if (!sysComponent) {
        return <div>SYS component not found</div>;
      }

      return (
        <OperatingCases.OperatingCasesView
          useMoistureLoadSelector={true}
          dispatch={Dispatch.map(Action.dispatchOperatingCases, dispatch)}
          state={modal.state}
          sharedState={sharedState}
          productData={opcProduct}
          sysProperties={sysComponent.properties}
          systemStatus={system.status}
        />
      );
    }
    case "Print": {
      return (
        <Printouts.PrintView
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPrintouts, dispatch)}
          system={system}
          state={modal.state}
        />
      );
    }
    case "MechanicalSketch": {
      return (
        <MechanicalSketch.MechanicalSketchView
          system={system}
          state={modal.state}
          sharedState={sharedState}
          openComponent={compId => {
            dispatch(
              Action.openModal({
                type: "ComponentEditor",
                selectedComponentId: compId
              })
            );
          }}
          dispatch={Dispatch.map(Action.dispatchMechanicalSketch, dispatch)}
        />
      );
    }
    case "Price": {
      return (
        <Price.PriceView
          systemType={systemType}
          dispatch={Dispatch.map(Action.dispatchPrice, dispatch)}
          sharedState={sharedState}
          products={products}
          state={modal.state}
          system={system}
        />
      );
    }
    case "Accessories": {
      const component = system.components.find(
        c => c.id === modal.selectedComponentId
      );

      if (!component) {
        return <div>Component not found</div>;
      }

      return (
        <Accessories.AccessoriesView
          showGeneratedAccessories={true}
          products={products}
          dispatch={Dispatch.map(Action.dispatchAccessories, dispatch)}
          component={component}
          system={system}
          sharedState={sharedState}
          state={modal.state}
          groupFilter={[]}
        ></Accessories.AccessoriesView>
      );
    }
    case "BuildInSc":
      return (
        <BuildInSc.BuildInScView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchBuildInSc, dispatch)}
          systemId={system.id}
          systemStatus={system.status}
        />
      );
    case "BuildInRevit": {
      const operatingCasesOptions = system.operatingCases.map(opcc => {
        return {
          value: opcc.id,
          title: opcc.customCaseName || opcc.caseName
        };
      });
      return (
        <BuildInRevit.BuildInRevitView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchBuildInRevit, dispatch)}
          systemId={system.id}
          systemStatus={system.status}
          operatingCasesOptions={operatingCasesOptions}
        />
      );
    }
    case "PsychrometricChart": {
      const operatingCasesOptions = system.operatingCases.map(opcc => {
        return {
          value: opcc.id,
          title: opcc.customCaseName || opcc.caseName
        };
      });

      return (
        <PsychrometricChart.PsychrometricChartPresenterView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPsychrometricChart, dispatch)}
          systemId={system.id}
          systemStatus={system.status}
          operatingCasesOptions={operatingCasesOptions}
        />
      );
    }
    case "Pid": {
      const genesysNoWithRevisionNo =
        system.file.genesysNo + "-" + system.revisionNo;

      return (
        <Pid.PidView
          state={modal.state}
          systemId={system.id}
          sharedState={sharedState}
          systemTypeId={systemType.id}
          systemStatus={system.status}
          genesysNoWithRevisionNo={genesysNoWithRevisionNo}
          dispatch={Dispatch.map(Action.dispatchPid, dispatch)}
        />
      );
    }
    case "PropertyGroup": {
      return (
        <PropertyGroups.PropertyGroupsView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPropertyGroups, dispatch)}
          systemType={systemType}
          system={system}
          products={products}
        />
      );
    }
    case "SystemAccessories": {
      return (
        <SystemAccessories.SystemAccessoriesView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchSystemAccessories, dispatch)}
          systemType={systemType}
          system={system}
          products={products}
        />
      );
    }
    case "MCompare": {
      const energyProduct = products.find(
        obj => obj.id === system.file.systemTypeId + "OPE"
      );

      const mcProduct = products.find(
        obj => obj.id === system.file.systemTypeId + "MCS"
      );

      if (!energyProduct || !mcProduct) {
        return <div>Energy component not found</div>;
      }
      return (
        <MCompare.MCompareView
          mcsproductData={mcProduct}
          productData={energyProduct}
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchMCompare, dispatch)}
          system={system}
        />
      );
    }
    case "Energy": {
      const energyProduct = products.find(
        obj => obj.id === system.file.systemTypeId + "OPE"
      );
      if (!energyProduct) {
        return <div>Energy component not found</div>;
      }
      return (
        <Energy.EnergyView
          dispatch={Dispatch.map(Action.dispatchEnergy, dispatch)}
          sharedState={sharedState}
          state={modal.state}
          system={system}
        ></Energy.EnergyView>
      );
    }
    case "PlenumSize": {
      const properties =
        systemType.id !== "ECC"
          ? system.components.find(c => c.productId.endsWith("SYS"))!.properties
          : system.components.find(c => c.productId.endsWith("HDE"))!
              .properties;

      const operatingCases = system.operatingCases
        .filter(o => o.settings)
        .map(opc => PropertyValueSet.fromString(opc.settings!));

      const components = system.components.map(c => ({
        id: c.id,
        productId: c.productId,
        properties: c.properties,
        label: c.label,
        sections: c.sections.map(s => ({
          id: s.id,
          airstream: s.systemAirStreamId || "",
          productSectionId: s.productSectionId,
          sortNo: s.sortNo
        }))
      }));

      return (
        <PlenumSize.PlenumSizeSelectorView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPlenumSize, dispatch)}
          operatingCases={operatingCases}
          properties={properties}
          components={components}
          isSystemLocked={system.status >= SystemStatus.LockSuccess}
        />
      );
    }
    case "NotesAndDocuments": {
      return (
        <NotesAndDocuments.NotesAndDocumentsView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchNotesAndDocuments, dispatch)}
        />
      );
    }
    case "MultipleSystemsEditor": {
      const opcProduct = products.find(p => p.id.endsWith("OPC"));
      const dhuProduct = products.find(p => p.id.endsWith("DHU"));
      const sysComponent = system.components.find(c =>
        c.productId.endsWith("SYS")
      );
      const dhuComponent = system.components.find(c =>
        c.productId.endsWith("DHU")
      );

      return (
        <MultipleSystemsEditor.MultipleSystemsEditorView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(
            Action.dispatchMultipleSystemsEditor,
            dispatch
          )}
          systemStatus={system.status}
          opcProduct={opcProduct}
          dhuProduct={dhuProduct}
          sysComponent={sysComponent}
          dhuComponent={dhuComponent}
        />
      );
    }
    case "DataCenterEditor": {
      const sysProduct = products.find(p => p.id.endsWith("SYS"))!;
      const opcProduct = products.find(p => p.id.endsWith("OPC"))!;

      return (
        <DataCenterEditor.DataCenterEditorView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchDataCenterEditor, dispatch)}
          systemStatus={system.status}
          sysProduct={sysProduct}
          opcProduct={opcProduct}
        />
      );
    }

    case "CondensationAnalysis": {
      const sysComp = system.components.find(c => c.productId.endsWith("SYS"))!;
      const surrounding = PropertyValueSet.getInteger(
        "systemplacement",
        sysComp.properties
      )!;
      return (
        <CondensationAnalysis.View
          systemTypeId={system.file.systemTypeId}
          sharedState={sharedState}
          state={modal.state}
          surrounding={surrounding}
          dispatch={Dispatch.map(Action.dispatchCondensationAnalysis, dispatch)}
        />
      );
    }
    case "LockedSystemCalculation": {
      const sysProperties =
        system.components.find(c => c.productId.endsWith("SYS"))?.properties ??
        PropertyValueSet.Empty;

      const opcProduct = products.find(p => p.id.endsWith("OPC"))!;

      return (
        <LockedSystemCalculation.LockedSystemCalculationView
          opcProduct={opcProduct}
          state={modal.state}
          sharedState={sharedState}
          systemId={system.id}
          sysProperties={sysProperties}
          system={system}
          productData={productData}
          dispatch={Dispatch.map(
            Action.dispatchLockedSystemCalculation,
            dispatch
          )}
        />
      );
    }
    case "WiringDiagram": {
      const genesysNoWithRevisionNo =
        system.file.genesysNo + "-" + system.revisionNo;

      return (
        <WiringDiagram.WiringDiagramView
          state={modal.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchWiringDiagram, dispatch)}
          systemId={system.id}
          systemStatus={system.status}
          genesysNoWithRevisionNo={genesysNoWithRevisionNo}
        />
      );
    }
    default: {
      return exhaustiveCheck(modal, true);
    }
  }
}

function CtaButtons({
  modal,
  onClose,
  onSave,
  onSaveAndCalculate,
  calculate,
  onSaveAndCalculateEnergy,
  systemStatus,
  translate
}: {
  readonly modal: Exclude<Types.Modal, { readonly type: "NoModal" }>;
  readonly onClose: () => void;
  readonly onSave: () => void;
  readonly calculate: () => void;
  readonly onSaveAndCalculate: () => void;
  readonly onSaveAndCalculateEnergy: () => void;
  readonly systemStatus: number;
  readonly translate: LanguageTexts.Translate;
}): JSX.Element {
  if (systemStatus >= SystemStatus.LockSuccess) {
    return (
      <StandardButton buttonType="Primary-2" size="Large" onClick={onClose}>
        {translate(LanguageTexts.close())}
      </StandardButton>
    );
  }
  switch (modal.type) {
    case "LockedSystemCalculation":
    case "PsychrometricChart":
    case "MechanicalSketch":
    case "BuildInRevit":
    case "BuildInSc":
    case "WiringDiagram":
    case "Pid":
    case "CondensationAnalysis":
    case "Print": {
      return (
        <StandardButton buttonType="Primary-2" size="Large" onClick={onClose}>
          {translate(LanguageTexts.close())}
        </StandardButton>
      );
    }
    case "DataCenterEditor":
    case "MultipleSystemsEditor":
    case "Price":
    case "Accessories":
    case "PlenumSize":
    case "OperatingCases":
    case "PropertyGroup":
    case "SystemAccessories":
    case "ComponentEditor": {
      return (
        <ButtonContainer>
          <ProcessButton
            onCalculate={calculate}
            canCalculate={
              !modal.state.valuesHasChanged &&
              systemStatus < SystemStatus.PriceCalculationSuccess
            }
            translate={translate}
            onClose={onClose}
            onSave={onSave}
            canSave={modal.state.valuesHasChanged}
            onSaveAndCalculate={onSaveAndCalculate}
          />
        </ButtonContainer>
      );
    }
    case "Energy": {
      return (
        <EnergyButton
          onSaveAndCalculate={onSaveAndCalculate}
          onSaveAndCalculateEnergy={onSaveAndCalculateEnergy}
          modal={modal}
          onClose={onClose}
          onSave={onSave}
          translate={translate}
        />
      );
    }
    case "MCompare":
    case "NotesAndDocuments": {
      if (!modal.state.canSave) {
        return (
          <StandardButton buttonType="Primary-2" size="Large" onClick={onClose}>
            {translate(LanguageTexts.close())}
          </StandardButton>
        );
      } else {
        return (
          <>
            <StandardButton
              buttonType="Secondary-2"
              size="Large"
              onClick={onClose}
            >
              {translate(LanguageTexts.cancel())}
            </StandardButton>
            <StandardButton
              buttonType="Primary-2"
              size="Large"
              onClick={onSave}
            >
              {translate(LanguageTexts.save())}
            </StandardButton>
          </>
        );
      }
    }
    default: {
      return exhaustiveCheck(modal, true);
    }
  }
}

function EnergyButton({
  onClose,
  onSave,
  translate,
  onSaveAndCalculate,
  onSaveAndCalculateEnergy,
  modal
}: {
  readonly onClose: () => void;
  readonly onSave: () => void;
  readonly onSaveAndCalculate: () => void;
  readonly onSaveAndCalculateEnergy: () => void;
  readonly translate: LanguageTexts.Translate;
  readonly modal: Exclude<Types.Modal, { readonly type: "NoModal" }>;
}) {
  if (modal.type !== "Energy") {
    return null;
  }

  const hasEnergyCalculationBeenTurnedOff =
    Energy.hasEnergyCalculationBeenTurnedOff(modal.state);
  const hasSettingsChanged = Energy.hasSettingsChanged(modal.state);
  const hasBinCases = Energy.hasBinCases(modal.state);
  const isLoadingBinCases = Energy.isLoadingBinCases(modal.state);
  const canSave =
    hasEnergyCalculationBeenTurnedOff || (hasSettingsChanged && hasBinCases);
  const canCalculateEnergy =
    !hasEnergyCalculationBeenTurnedOff && hasSettingsChanged && hasBinCases;

  let options = [
    {
      value: translate(LanguageTexts.save()),
      onClickHandler: () => {
        onSave();
      }
    },
    {
      value: translate(LanguageTexts.saveAndCalculate()),
      onClickHandler: onSaveAndCalculate
    }
  ];

  if (canCalculateEnergy) {
    options.push({
      value: translate(LanguageTexts.saveAndCalculateEnergy()),
      onClickHandler: onSaveAndCalculateEnergy
    });
  }

  return (
    <>
      <>
        <StandardButton
          buttonType={canSave ? "Secondary-2" : "Primary-2"}
          size="Large"
          onClick={onClose}
        >
          {translate(canSave ? LanguageTexts.cancel() : LanguageTexts.close())}
        </StandardButton>
        {canSave && <DropDownButton buttonType="Primary-2" options={options} />}
      </>
      {isLoadingBinCases && (
        <>
          <StandardButton
            buttonType="Primary-2"
            disabled={true}
            size="Large"
            onClick={onSave}
          >
            {"loading bins"}
          </StandardButton>
        </>
      )}
    </>
  );
}

// tslint:disable-next-line
