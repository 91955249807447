import { exhaustiveCheck } from "ts-exhaustive-check";
import { Quantity, Amount } from "@genesys/uom";
import * as Calculations from "@munters/calculations";
import * as GraphQlTypes from "@genesys/graphql-types";
import * as ComponentOrder from "@genesys/shared/lib/component-order";
import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ComponentCalculationType } from "@genesys/graphql-types";

export function getAirPropertyValue(
  airProperty: GraphQlTypes.AirProperty,
  getValue: (amount: Amount.Amount<Quantity.Quantity>) => string,
  airAt: Calculations.Physics.AirFlowState.AirFlowState,
  atmosphericpressure: Amount.Amount<"Pressure">
) {
  let value: string | undefined = undefined;
  switch (airProperty) {
    case GraphQlTypes.AirProperty.ABSOLUTE_PRESSURE: {
      value = getValue(airAt.pressure);
      break;
    }
    case GraphQlTypes.AirProperty.HUMIDITY_RATIO: {
      value = getValue(airAt.humidity);
      break;
    }
    case GraphQlTypes.AirProperty.MASS_FLOW: {
      value = getValue(airAt.flow);
      break;
    }
    case GraphQlTypes.AirProperty.RELATIVE_HUMIDITY: {
      value = getValue(airAt.relativeHumidity);
      break;
    }
    case GraphQlTypes.AirProperty.TEMPERATURE: {
      value = getValue(airAt.temperature);
      break;
    }
    case GraphQlTypes.AirProperty.RELATIVE_PRESSURE: {
      value = getValue(Amount.minus(airAt.pressure, atmosphericpressure));
      break;
    }
    case GraphQlTypes.AirProperty.DENSITY: {
      value = getValue(Calculations.Physics.AirFlowState.density(airAt));
      break;
    }
    case GraphQlTypes.AirProperty.DEW_POINT_TEMPERATURE: {
      value = getValue(
        Calculations.Physics.AirFlowState.dewPointTemperature(airAt)
      );
      break;
    }
    case GraphQlTypes.AirProperty.WET_TEMPERATURE: {
      value = getValue(Calculations.Physics.AirFlowState.wetTemperature(airAt));
      break;
    }
    case GraphQlTypes.AirProperty.VOLUME_FLOW: {
      value = getValue(
        Calculations.Physics.FlowConversion.massFlowToVolumeFlow(
          airAt.temperature,
          airAt.humidity,
          airAt.pressure,
          airAt.flow
        )
      );
      break;
    }
    default: {
      exhaustiveCheck(airProperty);
    }
  }
  return value;
}

export function getQuantityForAirProperty(
  airProperty: GraphQlTypes.AirProperty
): Quantity.Quantity {
  switch (airProperty) {
    case GraphQlTypes.AirProperty.ABSOLUTE_PRESSURE:
    case GraphQlTypes.AirProperty.RELATIVE_PRESSURE: {
      return "Pressure";
    }
    case GraphQlTypes.AirProperty.DENSITY: {
      return "Density";
    }
    case GraphQlTypes.AirProperty.DEW_POINT_TEMPERATURE: {
      return "DewPointTemperature";
    }
    case GraphQlTypes.AirProperty.WET_TEMPERATURE: {
      return "WetTemperature";
    }
    case GraphQlTypes.AirProperty.HUMIDITY_RATIO: {
      return "HumidityRatio";
    }
    case GraphQlTypes.AirProperty.MASS_FLOW: {
      return "MassFlow";
    }
    case GraphQlTypes.AirProperty.RELATIVE_HUMIDITY: {
      return "RelativeHumidity";
    }
    case GraphQlTypes.AirProperty.TEMPERATURE: {
      return "Temperature";
    }
    case GraphQlTypes.AirProperty.VOLUME_FLOW: {
      return "VolumeFlow";
    }
    default: {
      exhaustiveCheck(airProperty);
      throw new Error(`Unkown airpropery: ${airProperty}`);
    }
  }
}

export function getAirAt(
  resultsByComponentId: ResultSystemSimulationByComponentId,
  airPosition: ComponentOrder.AirPosition
):
  | [Calculations.Physics.AirFlowState.AirFlowState, Amount.Amount<"Pressure">]
  | undefined {
  const sectionConnectionType = airPosition.sectionConnectionType;

  const opCase =
    sectionConnectionType === ComponentOrder.SectionConnectionType.Inlet
      ? resultsByComponentId.get(airPosition.nextComponentId!)
      : resultsByComponentId.get(airPosition.prevComponentId!);

  if (!opCase) {
    return undefined;
  }

  const { input, output } = opCase;

  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    sectionConnectionType === ComponentOrder.SectionConnectionType.Inlet
      ? `${airPosition.nextProductSectionId}_AirInTemperature`
      : `${airPosition.prevProductSectionId}_AirOutTemperature`,
    output
  );
  const humidity = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
    sectionConnectionType === ComponentOrder.SectionConnectionType.Inlet
      ? `${airPosition.nextProductSectionId}_AirInHumidity`
      : `${airPosition.prevProductSectionId}_AirOutHumidity`,
    output
  );
  const flow = PropertyValueSet.getAmount<Quantity.MassFlow>(
    sectionConnectionType === ComponentOrder.SectionConnectionType.Inlet
      ? `${airPosition.nextProductSectionId}_AirInFlow`
      : `${airPosition.prevProductSectionId}_AirOutFlow`,
    output
  );
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    sectionConnectionType === ComponentOrder.SectionConnectionType.Inlet
      ? `${airPosition.nextProductSectionId}_AirInPressure`
      : `${airPosition.prevProductSectionId}_AirOutPressure`,
    output
  );

  if (!temperature || !humidity || !flow || !pressure) {
    return undefined;
  }

  const atmospheric = PropertyValueSet.getAmount<Quantity.Pressure>(
    "atmosphericpressure",
    input
  )!;

  return [
    Calculations.Physics.AirFlowState.create(
      temperature,
      humidity,
      pressure,
      flow
    ),
    atmospheric
  ];
}

export interface OpCase {
  readonly id: string;
  readonly name: string;
  readonly results: ReadonlyArray<{
    readonly id: string;
    readonly componentId: string;
    readonly calculationType: ComponentCalculationType;
    readonly settings?: string | null;
  }>;
  readonly input: string;
}

export function toOpCases(
  translate: LanguageTexts.Translate,
  operatingCases: ReadonlyArray<{
    readonly id: string;
    readonly caseName: string;
    readonly customCaseName?: string | null;
    readonly settings?: string | null;
    readonly results: ReadonlyArray<{
      readonly id: string;
      readonly componentId: string;
      readonly calculationType: GraphQlTypes.ComponentCalculationType;
      readonly settings?: string | null;
    }>;
  }>
): ReadonlyArray<OpCase> {
  const opCases: ReadonlyArray<OpCase> = operatingCases.map(
    ({ id, caseName, customCaseName, results, settings }) => ({
      id,
      name:
        caseName === "Custom" && !!customCaseName
          ? customCaseName
          : translate(LanguageTexts.operatingCaseName(caseName)),
      results,
      input: settings || ""
    })
  );

  return opCases;
}

export type ResultSystemSimulationByComponentId = Map<
  string,
  {
    readonly input: PropertyValueSet.PropertyValueSet;
    readonly output: PropertyValueSet.PropertyValueSet;
  }
>;

export function getResultByComponentId(
  operatingCase: OpCase
): ResultSystemSimulationByComponentId {
  const input = PropertyValueSet.fromString(operatingCase.input);
  const inletOutletMap: ResultSystemSimulationByComponentId =
    operatingCase.results.reduce(
      (soFar: ResultSystemSimulationByComponentId, result) => {
        if (result.calculationType !== "SYSTEM_SIMULATION") {
          return soFar;
        }

        return soFar.set(result.componentId, {
          input,
          output: PropertyValueSet.fromString(result.settings || "")
        });
      },
      new Map()
    );

  return inletOutletMap;
}

export interface ScreenAirProperty {
  readonly airProperty: GraphQlTypes.AirProperty;
  readonly name: string;
  readonly quantity: Quantity.Quantity;
}

export function toScreenAirProperty(
  airProperties: ReadonlyArray<{
    readonly name: string;
    readonly airProperty: GraphQlTypes.AirProperty;
  }>
): ReadonlyArray<ScreenAirProperty> {
  const screenAirProperties: ReadonlyArray<ScreenAirProperty> =
    airProperties.map(({ airProperty, name }) => ({
      airProperty,
      name,
      quantity: getQuantityForAirProperty(airProperty)
    }));

  return screenAirProperties;
}
