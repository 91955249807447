import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import styled from "styled-components";
import { Action, State } from "../../../../state";
import { Columns, OrderByColumn } from "../../../../types";
import { Dispatch } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { OrderyByHeader } from "../../../../../shared-manager-components";
import { S2 } from "@genesys/ui-elements";

const NormalHeaderContainer = styled.div`
  padding-bottom: 3px;
`;

function NormalHeader({ text }: { readonly text: string }) {
  return <NormalHeaderContainer>{headerElement(text)}</NormalHeaderContainer>;
}

function headerElement(text: string) {
  return (
    <S2 color="dark" weight="bold">
      {text}
    </S2>
  );
}

export function getHeaderCellContent(
  state: State,
  cellName: Columns,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
) {
  const currentColumn = state.searchParams.orderByColumns[0];

  const setOrderByColumn = (column: OrderByColumn) => {
    const currentOrderByColumns = state.searchParams.orderByColumns.find(
      p => p.name === column
    );

    const newOrderByColumns = currentOrderByColumns
      ? [
          {
            ...currentOrderByColumns,
            isDescending: !currentOrderByColumns.isDescending
          }
        ]
      : [
          {
            name: column,
            isDescending: false
          }
        ];

    const searchParams = {
      ...state.searchParams,
      orderByColumns: newOrderByColumns
    };
    dispatch(Action.setSearchParams(searchParams));
    dispatch(Action.search(searchParams));
  };

  switch (cellName) {
    case "g-no": {
      return (
        <OrderyByHeader
          headerElement={headerElement("G #")}
          sortedColumn={currentColumn.name === cellName}
          isDescending={currentColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "actions-menu": {
      return <></>;
    }

    case "favorites": {
      return <></>;
    }

    case "system-name": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.name())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={currentColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "system-type": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.type())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={currentColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "owner": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.owner())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={currentColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "date-changed": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.changed())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={currentColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "status": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.status())} />
      );
    }

    case "labels": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.labels())} />
      );
    }

    case "revisions": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.revisions())} />
      );
    }

    default:
      exhaustiveCheck(cellName);
  }
}
