import styled from "styled-components";
import * as React from "react";

export const unStyledFlexContainerStack = (
  props: {
    readonly alignItems?: string;
    readonly centerVertically?: boolean;
    readonly center?: boolean;
    readonly justifyContent?: string;
  } & React.HTMLProps<HTMLDivElement>
) => {
  const { centerVertically, center, alignItems, ...htmlProps } = props;
  return <div {...htmlProps}>{htmlProps.children}</div>;
};

// tslint:disable-next-line
export const FlexContainerStack = styled(unStyledFlexContainerStack)`
  display: flex;
  ${props =>
    props.alignItems
      ? "align-items: " + props.alignItems + ";"
      : props.center === undefined || props.center
        ? "align-items: center;"
        : ""} justify-content: ${props =>
  props.justifyContent ||
  (props.centerVertically === undefined || props.centerVertically
    ? "center"
    : "flex-start")}; flex-direction: column;
`;
