import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as BinTools from "../../../../../binning-tools";
import * as SharedState from "../../../../../shared-state";
import * as LocationSelectorGen2 from "../../../../../location-selector";
import * as OperationTimeGen2 from "../../../../../operation-time-manager";
import { Action } from "../state";
import { UserEnergySettings } from "../../types";

export function BinGenerationComponent({
  binInfo,
  sharedState,
  isSystemLocked,
  energySettings,
  climateSettings,
  operationTimeState,
  atmosphericPressure,
  locationSelectorState,
  dispatch,
  dispatchLocationSelector
}: {
  readonly isSystemLocked: boolean;
  readonly binInfo: BinTools.BinInfo;
  readonly sharedState: SharedState.State;
  readonly energySettings: UserEnergySettings;
  readonly operationTimeState: OperationTimeGen2.State;
  readonly locationSelectorState: LocationSelectorGen2.State;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly atmosphericPressure: PropertyValue.PropertyValue | undefined;
  readonly dispatch: Dispatch<Action>;
  readonly dispatchLocationSelector: Dispatch<LocationSelectorGen2.Action>;
}): JSX.Element {
  const binLocationId = PropertyValueSet.getText(
    KnownProperties.binDataLocationId,
    binInfo.binSelections
  );
  const hasBinLocationId =
    binLocationId !== undefined && binLocationId.length > 0;

  const climateSettingsLocationId = PropertyValueSet.getText(
    "wmo",
    climateSettings
  );

  const showDifferentLocationsWarning = !!(
    binLocationId && binLocationId !== climateSettingsLocationId
  );

  const showGenerateButton =
    binInfo.binCases === undefined || binInfo.binCases.length === 0;

  return (
    <BinTools.InputsView
      binInfo={binInfo}
      showGenerateInfo={true}
      pressure={atmosphericPressure}
      sharedState={sharedState}
      energySettings={energySettings}
      isSystemLocked={isSystemLocked}
      hasBinLocationId={hasBinLocationId}
      operationTimeState={operationTimeState}
      locationSelectorState={locationSelectorState}
      showGenerateButton={showGenerateButton}
      locationSelectorDispatch={dispatchLocationSelector}
      showDIfferentLocationWarning={showDifferentLocationsWarning}
      onClose={() => dispatch(Action.toggleOperationTime())}
      onClick={() => dispatch(Action.toggleOperationTime())}
      onGenerateBinCases={() =>
        dispatch(Action.generateBinCases(binInfo.binSelections))
      }
      onFormatCleared={(fieldGroup, fieldName) => {
        dispatch(Action.onFormatCleared(fieldGroup, fieldName));
      }}
      onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
        dispatch(
          Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        );
      }}
      operationTimeDispatch={Dispatch.map(
        Action.dispatchOperationTime,
        dispatch
      )}
      onSelectionChange={(newBinSelections, newEnergySettings) => {
        dispatch(
          Action.binToolsInputOnSelectionChange(
            newEnergySettings,
            newBinSelections,
            []
          )
        );
      }}
    />
  );
}
