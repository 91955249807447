import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createProfileSvg(color: string): string {
  const svgString = `
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>351BF768-8BD6-4DA6-BBD6-1E420D155FEA</title>
    <g id="Page-Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Design---Dashboard" transform="translate(-1386.000000, -20.000000)" fill="${color}">
            <g id="Icon-/-Profile" transform="translate(1386.000000, 20.000000)">
                <path d="M19,20 C19,20.552 18.553,21 18,21 C17.447,21 17,20.552 17,20 C17,17.243 14.757,15 12,15 C9.243,15 7,17.243 7,20 C7,20.552 6.553,21 6,21 C5.447,21 5,20.552 5,20 C5,16.14 8.141,13 12,13 C15.859,13 19,16.14 19,20 M12,5 C13.103,5 14,5.897 14,7 C14,8.103 13.103,9 12,9 C10.897,9 10,8.103 10,7 C10,5.897 10.897,5 12,5 M12,11 C14.206,11 16,9.206 16,7 C16,4.794 14.206,3 12,3 C9.794,3 8,4.794 8,7 C8,9.206 9.794,11 12,11" id="🎨-Icon-Сolor"></path>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
