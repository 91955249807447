import { PropertyFilterAst, PropertyValueSet } from "@promaster-sdk/property";
import * as PropertyCompare from "./property-compare";

export {
  AddExpr,
  AddExprOperationType,
  AndExpr,
  BooleanExpr,
  ComparisonExpr,
  ComparisonOperationType,
  CompiledFilterFunction,
  EmptyExpr,
  EqualsExpr,
  EqualsOperationType,
  Expr,
  IdentifierExpr,
  MulExpr,
  MulExprOperationType,
  NullExpr,
  OrExpr,
  PropertyValueExpr,
  UnaryExpr,
  UnaryExprOperationType,
  ValueExpr,
  ValueRangeExpr,
  compileAst,
  compileToLambdas,
  compileToString,
  findProperties,
  newAddExpr,
  newAndExpr,
  newComparisonExpr,
  newEmptyExpr,
  newEqualsExpr,
  newIdentifierExpr,
  newMulExpr,
  newNullExpr,
  newOrExpr,
  newUnaryExpr,
  newValueExpr,
  newValueRangeExpr,
  parse
} from "@promaster-sdk/property/lib/property-filter-ast";

export const evaluateAst = (
  e: PropertyFilterAst.BooleanExpr,
  properties: PropertyValueSet.PropertyValueSet,
  matchMissingIdentifiers: boolean
) =>
  PropertyFilterAst.evaluateAst(
    e,
    properties,
    matchMissingIdentifiers,
    PropertyCompare.compare
  );
