import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const updateSystemManagerSettingsMutation = gql`
  mutation UpdateSystemManagerSettings(
    $systemManagerSettings: SystemManagerSettingsInputType!
  ) {
    updateSystemManagerSettings(systemManagerSettings: $systemManagerSettings) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
