import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { updateComponenNotesMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";

export function saveNotes(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<ConfiguratorAction, { readonly type: "saveNotes" }>
): [Cmd<Action>, SystemStatus | undefined] {
  return [
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.UpdateComponentNotes,
      GraphQLTypes.UpdateComponentNotesVariables,
      Action
    >(
      updateComponenNotesMutation,
      {
        input: {
          systemId,
          componentId: action.componentId,
          externalNotes: action.externalNotes,
          internalNotes: action.internalNotes
        }
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.ValidateConfigurationSuccess
  ];
}
