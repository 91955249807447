import * as GQLTypes from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { promiseCmd } from "../../../../../promise-effect-manager";
import { Action } from "../../../actions";
import {
  setActiveAmountProfileMutation,
  updateLastOpenedSystemsMutation,
  toggleFavoriteSystemMutation,
  setUserSettingsPsychrometricChartMutation,
  setUserSettingsSystemsSummaryMutation,
  toggleReportFor5ystemType,
  updateReportSettingsParam
} from "@genesys/client-core/lib/graphql-mutations";
import { buildloadRecentAndFavoritesCmd } from "../../../tools";
import { UpdateFunction } from "../types";
import { knownSettings as SystemSummaryKnownSettings } from "../../../../../system-summary";

export const handleRemoveSystemsFromLastOpenedSystemsAndFavorites: UpdateFunction =
  (action, state) => {
    if (action.type !== "removeSystemsFromLastOpenedSystemsAndFavorites") {
      return [state];
    }
    if (state.user) {
      state = {
        ...state,
        user: {
          ...state.user,
          lastOpenedSystems: state.user.lastOpenedSystems.filter(
            x =>
              !action.systemIds.includes(
                x.type === "loaded" ? x.system.id : x.id
              )
          ),
          favoritesSystems: state.user.lastOpenedSystems.filter(
            x =>
              !action.systemIds.includes(
                x.type === "loaded" ? x.system.id : x.id
              )
          )
        }
      };
    }
    return [state];
  };

export const handleSetSystemSummarySettings: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setSystemSummarySettings") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        systemSummarySettings: action.systemSummarySettings
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsSystemsSummary,
        GQLTypes.SetUserSettingsSystemsSummaryVariables,
        Action
      >(
        setUserSettingsSystemsSummaryMutation,
        {
          newsettings: {
            selectedSummaryItems:
              action.systemSummarySettings.find(
                x =>
                  x.settingName ===
                  SystemSummaryKnownSettings.selectedSummaryItems
              )?.settingValue || ""
          }
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetPsychrometricChartSettings: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setPsychrometricChartSettings") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        psychrometricChartSettings: {
          chartType:
            action.settings.chartType ??
            state.user!.settings.psychrometricChartSettings.chartType,
          limits:
            action.settings.limits ??
            state.user!.settings.psychrometricChartSettings.limits,
          humidityMax:
            action.settings.humidityMax ??
            state.user!.settings.psychrometricChartSettings.humidityMax,
          temperatureMin:
            action.settings.temperatureMin ??
            state.user!.settings.psychrometricChartSettings.temperatureMin,
          temperatureMax:
            action.settings.temperatureMax ??
            state.user!.settings.psychrometricChartSettings.temperatureMax
        }
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetUserSettingsPsychrometricChart,
        GQLTypes.SetUserSettingsPsychrometricChartVariables,
        Action
      >(
        setUserSettingsPsychrometricChartMutation,
        {
          newsettings: action.settings
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleSetActiveAmountFieldProfile: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "setActiveAmountFieldProfile") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const selectedAmountFieldProfile =
    state.user.settings.amountFieldProfiles.find(ap => ap.id === action.id);
  if (!selectedAmountFieldProfile) {
    console.error("Couldn't find amount profile");
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        selectedAmountProfile: selectedAmountFieldProfile
      }
    }
  };
  return [
    newState,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.SetActiveAmountProfile,
        GQLTypes.SetActiveAmountProfileVariables,
        Action
      >(
        setActiveAmountProfileMutation,
        {
          amountProfileId: action.id
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleUpdateLastCreatedSystemType: UpdateFunction = (
  action,
  state
) => {
  if (action.type !== "updateLastCreatedSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const newState = {
    ...state,
    user: {
      ...state.user,
      lastCreatedSystemType: action.systemType
    }
  };
  return [newState];
};

export const handleUpdateCreateSystemType: UpdateFunction = (action, state) => {
  if (action.type !== "updateCreateSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const createSystemType = action.systemTypes.reduce((a, b) => {
    const maybeEntry = a[b];
    return {
      ...a,
      [b]: maybeEntry !== undefined ? maybeEntry + 1 : 1
    };
  }, state.user.createSystemType);

  const newState = {
    ...state,
    user: {
      ...state.user,
      createSystemType: createSystemType
    }
  };
  return [newState];
};

export const handleToggleFavoritesSystems: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "toggleFavoritesSystems") {
    return [state];
  }

  if (!state.user) {
    return [state];
  }
  const oldSystems = state.user.favoritesSystems;
  const favoriteSystem = oldSystems.find(
    s => (s.type === "loaded" ? s.system.id : s.id) === action.systemId
  );
  const recentSystem = state.user.lastOpenedSystems.find(
    s => (s.type === "loaded" ? s.system.id : s.id) === action.systemId
  );

  const newFavoritesSystems = favoriteSystem
    ? oldSystems.filter(
        s => (s.type === "loaded" ? s.system.id : s.id) !== action.systemId
      )
    : oldSystems.concat(
        recentSystem ?? {
          type: "loading",
          id: action.systemId
        }
      );

  const newState = {
    ...state,
    user: {
      ...state.user,
      favoritesSystems: newFavoritesSystems
    }
  };
  const cmds = [
    oldSharedState.graphQL.queryUserCmd<
      GQLTypes.ToggleFavoriteSystem,
      GQLTypes.ToggleFavoriteSystemVariables,
      Action
    >(
      toggleFavoriteSystemMutation,
      {
        systemId: action.systemId
      },
      () => Action.sharedStateActionMutationCompleted(false)
    ),
    buildloadRecentAndFavoritesCmd(
      {
        ...oldSharedState,
        user: {
          ...oldSharedState.user,
          favoritesSystems: newFavoritesSystems
        }
      },
      false
    )
  ];

  return [newState, cmds];
};

export const handleUpdateLastOpenedSystems: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "updateLastOpenedSystems") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const lastOpenedSystems = state.user.lastOpenedSystems.filter(l => {
    switch (l.type) {
      case "loaded":
        return l.system.id !== action.systemId;
      case "error":
      case "loading":
        return l.id !== action.systemId;
      default:
        return exhaustiveCheck(l);
    }
  });
  const newState = {
    ...state,
    user: {
      ...state.user,
      lastOpenedSystems: [
        {
          type: "loading",
          id: action.systemId
        } as any,
        ...lastOpenedSystems.slice(0, 9)
      ]
    }
  };

  const cmds = [
    oldSharedState.graphQL.queryUserCmd<
      GQLTypes.UpdateLastOpenedSystems,
      GQLTypes.UpdateLastOpenedSystemsVariables,
      Action
    >(
      updateLastOpenedSystemsMutation,
      {
        systemId: action.systemId
      },
      () => Action.sharedStateActionMutationCompleted(false)
    ),
    buildloadRecentAndFavoritesCmd(
      {
        ...oldSharedState,
        user: {
          ...oldSharedState.user,
          lastOpenedSystems: lastOpenedSystems
        }
      },
      false
    )
  ];

  return [newState, cmds];
};

export const handleToggleReportForSystemType: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "toggleReportForSystemType") {
    return [state];
  }
  if (!state.user) {
    return [state];
  }
  const systemTypeReportSettings = state.user.settings.reportSettings.find(
    x => x.systemType === action.systemTypeId
  );
  let newReportSetting: ReadonlyArray<SharedState.UserReportSettings> = [];
  let reportsToToggle: Array<SharedState.ReportSetting> = [];
  let reportsToUpdateSettings: Array<SharedState.ReportSetting> = [];

  if (systemTypeReportSettings === undefined) {
    reportsToToggle = [...action.reports];
    newReportSetting = [
      ...state.user.settings.reportSettings,
      {
        systemType: action.systemTypeId,
        reports: action.reports
      }
    ];
  } else {
    const systemTypeCurrentReports = systemTypeReportSettings.reports;

    const reports = action.reports;
    const reportIDs = reports.map(x => x.reportId);
    const newReports = reports
      .filter(
        y => !systemTypeCurrentReports.some(x => y.reportId === x.reportId)
      )
      .map(x => ({
        reportId: x.reportId,
        reportSettingParams: x.reportSettingParams.filter(
          x => x.value !== undefined || x.paramName !== ""
        )
      }));
    const deletedReports = systemTypeCurrentReports.filter(
      x => !reportIDs.includes(x.reportId)
    );

    reportsToToggle = [...newReports, ...deletedReports];
    reportsToUpdateSettings = action.reports.filter(
      x =>
        !reportsToToggle.map(y => y.reportId).includes(x.reportId) &&
        x.reportSettingParams.filter(
          x => x.value !== undefined || x.paramName !== ""
        ).length > 0
    );
    const reportsToUpdateSettingsId = reportsToUpdateSettings.map(
      x => x.reportId
    );
    newReportSetting = [
      ...state.user.settings.reportSettings.filter(
        x => x.systemType !== action.systemTypeId
      ),
      {
        systemType: action.systemTypeId,
        reports: systemTypeCurrentReports
          .filter(x => !deletedReports.includes(x))
          .filter(x => !reportsToUpdateSettingsId.includes(x.reportId))
          .concat(newReports)
          .concat(reportsToUpdateSettings)
      }
    ];
  }

  return [
    {
      ...state,
      user: {
        ...state.user,
        settings: {
          ...state.user?.settings,
          reportSettings: newReportSetting
        }
      }
    },
    [
      promiseCmd<Action, {}>(
        async () => {
          for (const report of reportsToToggle) {
            await oldSharedState.graphQL.queryUser<
              GQLTypes.ToggleReportFor5ystemType,
              GQLTypes.ToggleReportFor5ystemTypeVariables
            >(toggleReportFor5ystemType, {
              reportInfo: {
                report: {
                  reportId: report.reportId,
                  reportSettingParams: report.reportSettingParams.map(x => ({
                    paramName: x.paramName,
                    value: x.value
                  }))
                },
                systemType: action.systemTypeId
              }
            });
          }

          for (const report of reportsToUpdateSettings) {
            await oldSharedState.graphQL.queryUser<
              GQLTypes.UpdateReportSettingsParam,
              GQLTypes.UpdateReportSettingsParamVariables
            >(updateReportSettingsParam, {
              reportParamSettings: {
                systemType: action.systemTypeId,
                reportId: report.reportId,
                reportSettingParams: report.reportSettingParams.map(x => ({
                  paramName: x.paramName,
                  value: x.value
                }))
              }
            });
          }
          return {};
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};

export const handleLoadLastOpenedSystemsAndFavorites: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "loadLastOpenedSystemsAndFavorites") {
    return [state];
  }
  return [state, [buildloadRecentAndFavoritesCmd(oldSharedState, true)]];
};

// tslint:disable-next-line
