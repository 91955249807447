import * as React from "react";
import * as SharedState from "../shared-state";
import * as AirMixing from "./air-mixing/view";
import * as AirPower from "./air-power/view";
import * as HumidityConversion from "./humidity-conversion/view";
import * as PsychrometricChart from "./psychrometric-chart/view";
import * as BinPageTool from "./weather-data-binning";
import * as DryCap from "./dry-cap";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

export function ToolsView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  switch (state.type) {
    case "air-mixing": {
      return (
        <AirMixing.AirMixingToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchAirMixing, dispatch)}
        />
      );
    }
    case "air-power": {
      return (
        <AirPower.AirPowerToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchAirPower, dispatch)}
        />
      );
    }
    case "humidity-conversion": {
      return (
        <HumidityConversion.HumidityConversionToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchHumidityConversion, dispatch)}
        />
      );
    }
    case "psychrometric-chart": {
      return (
        <PsychrometricChart.PsychrometricChartToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPsychrometricChart, dispatch)}
        />
      );
    }
    case "weather-data-binning": {
      return (
        <BinPageTool.View
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchBinPage, dispatch)}
          state={state.state}
        />
      );
    }
    case "dry-cap": {
      return (
        <DryCap.DryCapView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchDryCap, dispatch)}
        />
      );
    }
    default:
      return (
        <div>
          <p>This is not the tool page you are looking for</p>
        </div>
      );
  }
}
