import gql from "graphql-tag";

export const queryUser = gql`
  query MoistureLoadManagerUserQuery(
    $filter: MoistureLoadSearchFilterInputType!
  ) {
    user {
      searchMoistureLoads(filter: $filter) {
        id
        totalResults
        results {
          id
          name
          moistureLoadNo
          owner {
            id
            userName
          }

          labels {
            id
            name
          }

          latestMoistureLoad {
            id
            name
            revisionNo
            status
            statusDate
          }

          moistureLoads {
            id
            name
            revisionNo
            status
            statusDate
          }
        }
      }
    }
  }
`;
