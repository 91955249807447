import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { ComponentMessage } from "@genesys/shared/lib/components-messages";
import * as SharedState from "../../../shared-state";
import * as GraphQlTypes from "../../../graphql-types";
import * as PropertiesSelector from "../../../properties-selector";
import * as OperatingCaseSelector from "../../../operating-case-selector";
import * as System from "../../system";
import * as PerformanceOverview from "../../performance-overview";
import { calculateLockedSystem } from "./queries";
import { View, FluidCoilSelectors } from "./types";

export type State = {
  readonly selectedView: View;
  readonly isLoading: boolean;
  readonly calculationResultedInException: boolean;
  readonly fluidCoilSelectors: FluidCoilSelectors;
  readonly performanceOverviewState: PerformanceOverview.State;
  readonly operatingCaseSelectorState: OperatingCaseSelector.State;
  readonly operatingCaseResults: ReadonlyArray<System.OperatingCase>;
  readonly componentsMessages: ReadonlyArray<ComponentMessage>;
};

export const init = (
  sharedState: SharedState.State,
  productData: OperatingCaseSelector.ProductData,
  climateSettings: PropertyValueSet.PropertyValueSet,
  fluidCoilsComponents: ReadonlyArray<System.Component>,
  operatingCases: ReadonlyArray<OperatingCaseSelector.OperatingCase>
): [State, Cmd<Action>?] => {
  const fluidCoilSelectors = fluidCoilsComponents.reduce(
    (acc, component) => ({
      ...acc,
      [component.id]: {
        productId: component.productId,
        propertiesSelectorState: PropertiesSelector.init(component.properties)
      }
    }),
    {} as FluidCoilSelectors
  );

  const [operatingCasesState] = OperatingCaseSelector.init(
    climateSettings,
    operatingCases,
    sharedState,
    productData
  );

  return [
    {
      isLoading: false,
      componentsMessages: [],
      operatingCaseResults: [],
      calculationResultedInException: false,
      selectedView: "operating-cases",
      fluidCoilSelectors: fluidCoilSelectors,
      operatingCaseSelectorState: operatingCasesState,
      performanceOverviewState: PerformanceOverview.init()
    }
  ];
};

export const Action = ctorsUnion({
  dispatchOperatingCasesSelector: (action: OperatingCaseSelector.Action) => ({
    action
  }),
  dispatchFluidCoilSelector: (
    componentId: string,
    action: PropertiesSelector.Action
  ) => ({
    componentId,
    action
  }),
  dispatchPeformanceOverview: (action: PerformanceOverview.Action) => ({
    action
  }),
  calculate: (systemId: string) => ({ systemId }),
  parseResult: (
    result: GraphQlTypes.CalculateLockedSystem["user"]["calculateLockedSystem"]
  ) => ({
    result
  }),
  setView: (view: View) => ({ view })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchOperatingCasesSelector": {
      if (!state.operatingCaseSelectorState) {
        return [state];
      }

      const [
        operatingCaseSelectorState,
        operatingCaseSelectorCmd,
        operatingCaseSelectorSharedStateAction
      ] = OperatingCaseSelector.update(
        action.action,
        state.operatingCaseSelectorState,
        sharedState
      );

      return [
        {
          ...state,
          operatingCaseResults: [],
          operatingCaseSelectorState: operatingCaseSelectorState
        },
        Cmd.map(
          Action.dispatchOperatingCasesSelector,
          operatingCaseSelectorCmd
        ),
        operatingCaseSelectorSharedStateAction
      ];
    }
    case "dispatchFluidCoilSelector": {
      const fluidCoilSelector = state.fluidCoilSelectors[action.componentId];

      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        fluidCoilSelector.propertiesSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          operatingCaseResults: [],
          fluidCoilSelectors: {
            ...state.fluidCoilSelectors,
            [action.componentId]: {
              ...state.fluidCoilSelectors[action.componentId],
              propertiesSelectorState: propertiesSelectorState
            }
          }
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchFluidCoilSelector(action.componentId, cmdAction),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    case "dispatchPeformanceOverview": {
      const [performanceOverviewState, performanceOverviewSharedStateAction] =
        PerformanceOverview.update(
          action.action,
          state.performanceOverviewState
        );
      return [
        {
          ...state,
          performanceOverviewState: performanceOverviewState
        },
        undefined,
        performanceOverviewSharedStateAction
      ];
    }
    case "calculate": {
      const components = Object.entries(state.fluidCoilSelectors).map(
        ([componentId, v]) => ({
          id: componentId,
          properties: PropertyValueSet.toString(
            PropertiesSelector.getSelectedProperties(v.propertiesSelectorState)
          )
        })
      );

      const climateData = PropertyValueSet.toString(
        OperatingCaseSelector.getClimateSettings(
          state.operatingCaseSelectorState
        )
      );

      const operatingCases = OperatingCaseSelector.getOperatingCases(
        state.operatingCaseSelectorState
      ).map(opc => ({
        id: opc.id,
        properties: PropertyValueSet.toString(opc.settings)
      }));

      return [
        { ...state, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.CalculateLockedSystem,
          GraphQlTypes.CalculateLockedSystemVariables,
          Action
        >(
          calculateLockedSystem,
          {
            lockedSystemInput: {
              components: components,
              climateData: climateData,
              systemId: action.systemId,
              operatingCases: operatingCases
            }
          },
          data => Action.parseResult(data.user.calculateLockedSystem)
        )
      ];
    }
    case "parseResult": {
      const operatingCaseResults = action.result.operatingCases.map(opc => ({
        id: opc.id,
        sortNo: opc.sortNo,
        results: opc.results,
        binData: opc.binData,
        settings: opc.settings,
        caseType: opc.caseType,
        caseName: opc.caseName,
        customCaseName: opc.customCaseName
      }));

      const componentsMessages: ReadonlyArray<ComponentMessage> =
        action.result.componentsWithErrors.flatMap(c =>
          c.messages.map(m => ({
            id: m.id,
            componentId: c.id,
            productId: c.productId,
            properties: PropertyValueSet.fromString(c.properties ?? ""),
            code: m.messageCode,
            severity: m.messageSeverity,
            parameters:
              (m.messageParameters &&
                PropertyValueSet.fromString(m.messageParameters)) ||
              PropertyValueSet.Empty,
            operatingCaseResultId: m.operatingCaseResultId ?? undefined
          }))
        );

      return [
        {
          ...state,
          calculationResultedInException:
            action.result.calculationResultedInException,
          isLoading: false,
          selectedView: "performance",
          operatingCaseResults: operatingCaseResults,
          componentsMessages: componentsMessages
        }
      ];
    }
    case "setView": {
      return [{ ...state, selectedView: action.view }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
