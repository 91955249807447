import { Amount, Quantity } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import { ConversionParameters } from "../types";

export function specificEnthalpyConversions<
  TQuantityTo extends Quantity.Quantity
>(
  fromAmount: Amount.Amount<Quantity.SpecificEnthalpy>,
  to: TQuantityTo,
  conversionParameters: ConversionParameters
): Amount.Amount<TQuantityTo> {
  const pressureAmount = conversionParameters.pressure as Amount.Amount<
    Quantity.Pressure
  >;
  const temperatureAmount = conversionParameters.temperature as Amount.Amount<
    Quantity.Temperature
  >;

  switch (to) {
    case "RelativeHumidity": {
      return Physics.RP1485.AmountConversions.specificEnthalpyToRelativeHumidity(
        pressureAmount,
        temperatureAmount,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    case "HumidityRatio": {
      return Physics.RP1485.AmountConversions.specificEnthalpyToHumidityRatio(
        pressureAmount,
        temperatureAmount,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    case "DewPointTemperature": {
      return Physics.RP1485.AmountConversions.specificEnthalpyToDewPointTemperature(
        pressureAmount,
        temperatureAmount,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    case "WetTemperature": {
      return Physics.RP1485.AmountConversions.specificEnthalpyToWetTemperature(
        pressureAmount,
        temperatureAmount,
        fromAmount
      ) as Amount.Amount<TQuantityTo>;
    }
    default: {
      throw new Error(`Can't convert ${fromAmount.unit.quantity} to ${to}`);
    }
  }
}
