import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import {
  saveOperatingCasesMutation,
  setSystemMoistureLoadId
} from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import { promiseCmd } from "../../../promise-effect-manager";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import * as BinningTools from "../../../binning-tools";
import { Action } from "../state";
import { saveBinSelectionsAndEnergy } from "../save-bin-selections-and-energy";
import { tryRaiseSystemStatusToPriceCalculationSuccess } from "../calculate";

export function createSaveOperatingCasesCmd(
  sharedState: SharedState.State,
  action: Extract<ConfiguratorAction, { readonly type: "saveOperatingCases" }>,
  systemId: string,
  binSelections: string | null | undefined,
  systemMoistureLoadId: string | null | undefined
): [Cmd<Action>, ReadonlyArray<SystemStatus>] {
  const mutations: Array<[Promise<unknown>, SystemStatus]> = [];
  if (systemMoistureLoadId !== action.newMoistureLoadId) {
    const systemBinSelections = PropertyValueSet.fromString(
      binSelections || ""
    );

    if (!PropertyValueSet.isEmpty(systemBinSelections)) {
      const selectedBinType = PropertyValueSet.getValue(
        KnownProperties.binType,
        systemBinSelections
      ).value as BinningTools.BinType;

      if (selectedBinType === "MoistureLoad") {
        mutations.push([
          saveBinSelectionsAndEnergy(
            sharedState,
            systemId,
            PropertyValueSet.Empty,
            [],
            {}
          ),
          SystemStatus.ValidateConfigurationSuccess
        ]);
      }
    }

    mutations.push([
      sharedState.graphQL.queryUser<
        GraphQLTypes.SetSystemMoistureLoadId,
        GraphQLTypes.SetSystemMoistureLoadIdVariables
      >(setSystemMoistureLoadId, {
        systemId: systemId,
        moistureLoadId: action.newMoistureLoadId
      }),
      SystemStatus.ValidateConfigurationSuccess
    ]);
  }

  mutations.push([
    sharedState.graphQL.queryUser<
      GraphQLTypes.SaveOperatingCases,
      GraphQLTypes.SaveOperatingCasesVariables
    >(saveOperatingCasesMutation, {
      input: {
        systemId: systemId,
        operatingCases: action.operatingCases.map(oc =>
          PropertyValueSet.toString(oc.settings)
        ),
        climateData: PropertyValueSet.toString(action.climateSettings)
      }
    }),
    SystemStatus.ValidateConfigurationSuccess
  ]);
  return [
    promiseCmd<Action, string | undefined>(
      async () => {
        for (const promise of mutations.map(x => x[0])) {
          await promise;
        }
        const traceId = action.calculate
          ? await tryRaiseSystemStatusToPriceCalculationSuccess(
              sharedState.graphQL.queryUser,
              systemId
            )
          : undefined;
        return traceId;
      },
      traceId =>
        action.calculate
          ? Action.updateSystemNonBlockingStarted(traceId)
          : Action.systemMutationComplete(undefined, traceId)
    ),
    action.calculate
      ? [...mutations.map(x => x[1]), SystemStatus.PriceCalculationSuccess]
      : mutations.map(x => x[1])
  ];
}
