import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as Navigation from "../navigation-effect-manager";

export type MenuType = "pricing" | "moisture-load" | "tools" | "system";

export type State = {
  readonly openMenu: MenuType | undefined;
};

export const init = (): readonly [State] => {
  return [
    {
      openMenu: undefined
    }
  ];
};

export const Action = ctorsUnion({
  setOpenMenu: (menuType: MenuType | undefined) => ({ menuType }),
  navigate: (url: string) => ({ url })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "setOpenMenu": {
      return [{ ...state, openMenu: action.menuType }];
    }
    case "navigate": {
      return [state, Navigation.pushUrl(action.url)];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
