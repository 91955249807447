import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const copyPricingToNewPricingFileMutation = gql`
  mutation CopyPricingToNewPricingFile($pricingId: ID!) {
    copyPricingToNewPricingFile(pricingId: $pricingId) {
      ...CompletePricingFile
    }
  }
  ${Fragments.completePricingFile}
`;
