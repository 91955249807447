// tslint:disable:variable-name
import * as React from "react";
import styled from "styled-components";
import { ExpanderArrow } from "./expander-arrow";

const PositionedArrow = styled(ExpanderArrow)`
  position: absolute;
  top: 20%;
  left: 0;
`;
interface Props {
  readonly isCollapsed: boolean;
  readonly onToggle: () => void;
  readonly text: string;
  readonly className?: string;
}

// tslint:disable-next-line:function-name
export function ExpanderWithArrow({
  isCollapsed,
  onToggle,
  text,
  className
}: Props): JSX.Element {
  return (
    <Expander className={className}>
      <ExpanderClickableSurface onClick={onToggle}>
        <PositionedArrow isCollapsed={isCollapsed}>
          <span />
        </PositionedArrow>
        <GroupHeading>{text}</GroupHeading>
      </ExpanderClickableSurface>
    </Expander>
  );
}

const Expander = styled.div`
  padding: 10px 0 10px 25px;
  position: relative;
`;

const ExpanderClickableSurface = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const GroupHeading = styled.h3`
  font-size: 12px;
`;
