import * as React from "react";
import Popover from "@mui/material/Popover";
import styled from "../../styled-components";

export interface PopoverProps {
  readonly anchor: Element;
  readonly onClose: () => void;
  readonly children: React.ReactNode;
  readonly anchorOrigin?: Anchor;
  readonly transformOrigin?: Anchor;
}

interface Anchor {
  readonly vertical: Verticals;
  readonly horizontal: Horizontals;
}

type Verticals = number | "top" | "bottom" | "center";
type Horizontals = number | "left" | "right" | "center";

const StyledPop = styled(Popover)`
  .MuiPopover-paper {
    overflow: visible;
  }
`;

export function Pop(props: PopoverProps) {
  return (
    <StyledPop
      open={true}
      onClose={props.onClose}
      anchorEl={props.anchor}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: props.anchorOrigin?.vertical || "bottom",
        horizontal: props.anchorOrigin?.horizontal || "center"
      }}
      transformOrigin={{
        vertical: props.transformOrigin?.vertical || "top",
        horizontal: props.transformOrigin?.horizontal || "center"
      }}
    >
      {props.children}
    </StyledPop>
  );
}
