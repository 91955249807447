import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PropertiesSelector from "../../../../properties-selector";
import * as System from "../../../system";
import * as Product from "../../../product";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as Tools from "./tools";
import * as Types from "./types";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import styled from "styled-components";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { S1, Spinner } from "@genesys/ui-elements";
import { EnergyPriceSelectorView, BinSelectorView } from "./sub-views";
import { PropertyValueSet } from "@genesys/property";

const Container = styled.div``;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

const BaseGridItem = styled.div`
  padding: 15px;
  padding-top: 12px;
  border-radius: 5px;
  box-shadow: 0 0 18px 0 rgb(0 0 0 / 10%);
  margin-top: 10px;

  > span {
    display: flex;
    align-items: center;
    > h4 {
      margin-right: 5px;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;

  > div {
    width: 50%;
  }
`;

const LongGridItem = styled(BaseGridItem)`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
`;

const SpinnerContainer = styled.div`
  height: 557px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeader = styled.p`
  color: #023442;
  font-weight: normal;
  font-size: 16px;
`;

const StyledS1 = styled(StyledHeader)`
  margin-bottom: 10px;
`;

export function View({
  sharedState,
  state,
  system,
  mcsproductData,
  productData,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly productData: Product.Product;
  readonly mcsproductData: Product.Product;
}) {
  if (
    state.productQueryData === undefined ||
    state.productQueryData2 === undefined
  ) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const disabled = system.status >= SystemStatus.LockSuccess;
  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;
  const propertiesDefinitions = Tools.getPropertiesDefinitions(
    mcsproductData,
    productData,
    sysComponent.properties
  );

  const binType = getBinType(state.systemBinSelections);

  return (
    <Container>
      <GridContainer>
        <BaseGridItem>
          <span>
            <S1 color="primary">
              {sharedState.translate(LanguageTexts.mCompareSystem())}
            </S1>
          </span>
          <PropertiesSelector.PropertiesSelectorView
            fieldGroup={`Settings.${system.file.systemTypeId}`}
            isReadonly={disabled}
            propertiesInfo={propertiesDefinitions.competitorSystemPropertiesDef}
            dispatch={Dispatch.map(
              action =>
                Action.dispatchPropertiesSelector("competitorSystem", action),
              dispatch
            )}
            state={state.propertiesSelectorStates!.competitorSystem}
            hideGroupNames={false}
            showCodes={showCodes}
            hidePropertyNames={false}
            sharedState={sharedState}
            productId={system.file.systemTypeId}
          />
        </BaseGridItem>

        <LongGridItem>
          <span>
            <S1 color="primary">
              {sharedState.translate(LanguageTexts.annualOperatingCases())}
            </S1>
          </span>

          <BinSelectorView
            dispatch={dispatch}
            system={system}
            selectedBinType={binType}
            sharedState={sharedState}
            state={state}
          />
        </LongGridItem>

        <BaseGridItem>
          <span>
            <S1 color="primary">
              {sharedState.translate(LanguageTexts.annualOperationInputs())}
            </S1>
          </span>
          <FlexContainer>
            <div>
              <PropertiesSelector.PropertiesSelectorView
                fieldGroup={`Settings.${productData.id}`}
                isReadonly={disabled}
                propertiesInfo={
                  propertiesDefinitions.operatingcaseSelectorPropertiesDef
                }
                dispatch={Dispatch.map(
                  action =>
                    Action.dispatchPropertiesSelector(
                      "operatingCaseSelector",
                      action
                    ),
                  dispatch
                )}
                state={state.propertiesSelectorStates!.operatingCaseSelector}
                hideGroupNames={true}
                showCodes={showCodes}
                hidePropertyNames={false}
                sharedState={sharedState}
                productId={productData.id}
              />

              <StyledHeader color="primary">Emissions</StyledHeader>
              <PropertiesSelector.PropertiesSelectorView
                fieldGroup={`Settings.${productData.id}`}
                isReadonly={disabled}
                propertiesInfo={
                  propertiesDefinitions.energyEmissionPropertiesDef
                }
                dispatch={Dispatch.map(
                  action =>
                    Action.dispatchPropertiesSelector("energyEmission", action),
                  dispatch
                )}
                state={state.propertiesSelectorStates?.energyEmission!}
                hideGroupNames={true}
                showCodes={showCodes}
                hidePropertyNames={false}
                sharedState={sharedState}
                productId={"EnergyEmission"}
              />
            </div>
            <div>
              <StyledS1 color="primary">EnergyPrice</StyledS1>
              <EnergyPriceSelectorView
                readonly={disabled}
                dispatch={dispatch}
                energyItems={state.energyItems}
                sharedState={sharedState}
                state={state}
                energyPrice={state.energyPrice!}
                system={system}
              />
            </div>
          </FlexContainer>
        </BaseGridItem>
      </GridContainer>
    </Container>
  );
}

function getBinType(
  binSelection: PropertyValueSet.PropertyValueSet
): Types.BinType {
  return (
    (PropertyValueSet.getText(
      KnownProperties.binType,
      binSelection
    ) as Types.BinType) || "Generated"
  );
}
