import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { LoadDef } from "../types";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import { PageTabs, LoadType } from "./types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import * as SystemOverride from "../../../components/system-overrides";

export const Action = ctorsUnion({
  addLoad: (load: LoadDef) => ({ load }),
  addTypeToLoad: (
    loadName: MoistureHeatLoadTypeEnum,
    typeOfLoad: "moisture" | "heat"
  ) => ({ loadName, typeOfLoad }),
  // changeTopMenuTab: (tab: TopMenuTabs) => ({ tab }),
  changePageTab: (tab: PageTabs) => ({ tab }),
  // deleteLoad: (loadName: MoistureHeatLoadTypeEnum) => ({ loadName }),
  dispatchOperationTime: (
    action: OperationTimeGen2.Action,
    loadName: MoistureHeatLoadTypeEnum
  ) => ({
    action,
    loadName
  }),
  dispatchSystemOverride: (
    action: SystemOverride.Action,
    loadName: MoistureHeatLoadTypeEnum
  ) => ({ action, loadName }),
  setSelectedLoad: (selectedLoad: MoistureHeatLoadTypeEnum | undefined) => ({
    selectedLoad
  }),
  removeTypesFromLoad: (
    loadName: MoistureHeatLoadTypeEnum,
    typeOfLoad: LoadType
  ) => ({ loadName, typeOfLoad })
});

export type Action = CtorsUnion<typeof Action>;
