import * as React from "react";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import * as SharedState from "../../shared-state";
import styled from "styled-components";
import { StyledTable, PrimaryColors } from "@genesys/ui-elements";
import { AmountFormatSelector } from "../../amount-format-selector";
import { Quantity, Unit } from "@genesys/uom";

const Container = styled.div`
  --col-width-1: 30px;
  --col-width-2: 95px;
  --col-width-3: 200px;
  --col-width-4: 100px;
  --col-width-5: 50px;

  width: 100%;
  position: relative;
  overflow: auto;
  white-space: nowrap;

  table {
    border-collapse: collapse;
    margin-bottom: 7px;
  }

  .sticky-col {
    position: sticky;
    background-color: white;
    z-index: 1;
  }

  th:first-child,
  td:first-child {
    width: var(--col-width-1);
    min-width: var(--col-width-1);
    max-width: var(--col-width-1);
    left: 0;
    position: sticky;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: var(--col-width-2);
    min-width: var(--col-width-2);
    max-width: var(--col-width-2);
    left: var(--col-width-1);
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: var(--col-width-3);
    min-width: var(--col-width-3);
    max-width: var(--col-width-3);
    left: calc(var(--col-width-1) + var(--col-width-2));
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: var(--col-width-4);
    min-width: var(--col-width-4);
    max-width: var(--col-width-4);
    left: calc(var(--col-width-1) + var(--col-width-2) + var(--col-width-3));
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: var(--col-width-5);
    min-width: var(--col-width-5);
    max-width: var(--col-width-5);
    left: calc(
      var(--col-width-1) + var(--col-width-2) + var(--col-width-3) +
        var(--col-width-4)
    );
  }

  ::-webkit-scrollbar {
    height: 8px;
    margin-top: 5px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(177, 182, 192);
    opacity: 1;
    border-radius: 5.5px;
    margin-top: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const StyledTh = styled.th<{
  readonly backgroundColor: string;
}>`
  background-color: ${props => props.backgroundColor};
`;

const HeaderGroupContainer = styled.div<{
  readonly backgroundColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
  height: 60px;
  color: white;
  background-color: ${props => props.backgroundColor};
  font-size: 13px;
  letter-spacing: 8px;
  overflow: hidden;
`;

export interface ResultHeaderType {
  readonly value: string;
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly format?: ScreenAmounts.AmountFormat;
}

export function ResultTableWrapper({
  children,
  scrollBarPositionLeft,
  updateScrollBarPos
}: {
  readonly children: React.ReactNode;
  readonly scrollBarPositionLeft: number;
  readonly updateScrollBarPos: (newPos: number) => void;
}) {
  const timerLength = 500; //ms

  const [timerId, setTimerId] = React.useState<NodeJS.Timeout | null>(null);
  const scrollbarRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Set the initial scroll position
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollLeft = scrollBarPositionLeft; // initial scroll position (in pixels)
    }
  }, []);

  const handleScroll = () => {
    if (timerId !== null) {
      clearTimeout(timerId);
    }
    const newTimer = setTimeout(() => {
      updateScrollBarPos(scrollbarRef.current?.scrollLeft || 0);
    }, timerLength);

    setTimerId(newTimer);
  };

  return (
    <Container ref={scrollbarRef} onScroll={handleScroll}>
      <StyledTable alignHeader="center" alignRows="center" className="table">
        {children}
      </StyledTable>
    </Container>
  );
}

export interface HeaderGroupType {
  readonly value: string;
  readonly colSpan: number;
  readonly backgroundColor: string;
}

export function TableHeader({
  sharedState,
  groupNames,
  headerValues,
  onFormatChanged,
  onFormatCleared
}: {
  readonly sharedState: SharedState.State;
  readonly groupNames: ReadonlyArray<HeaderGroupType>;
  readonly headerValues: ReadonlyArray<ResultHeaderType>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  return (
    <thead>
      <tr>
        {groupNames.map((x, i) => {
          return (
            <StyledTh
              key={x.value + i}
              style={{ minWidth: "unset" }}
              backgroundColor={x.backgroundColor}
              scope="col"
              colSpan={x.colSpan}
            >
              <HeaderGroupContainer backgroundColor={x.backgroundColor}>
                {x.value.toUpperCase()}
              </HeaderGroupContainer>
            </StyledTh>
          );
        })}
      </tr>
      <tr>
        {headerValues.map((header, i) => {
          const amountFormatSelector = () => {
            if (!header.format) {
              return null;
            }
            return (
              <>
                [
                <AmountFormatSelector
                  type="AmountFormatSelectorProps"
                  amountFormat={header.format}
                  fieldName={header.fieldName}
                  fieldGroup={header.fieldGroup}
                  conversionParameters={undefined}
                  translate={sharedState.translate}
                  onFormatCleared={() =>
                    onFormatCleared(header.fieldGroup, header.fieldName)
                  }
                  onFormatChanged={(unit, decimalCount) =>
                    onFormatChanged(
                      header.fieldGroup,
                      header.fieldName,
                      unit,
                      decimalCount
                    )
                  }
                />
                ]
              </>
            );
          };
          return (
            <th
              key={header.value + i}
              className={isStaticColumn(i) ? "sticky-col" : undefined}
            >
              <p>{header.value} </p> {amountFormatSelector()}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

interface Row {
  readonly id: string;
  readonly isSelected: boolean;
  readonly isEditable: boolean;
  readonly highlightForEdit: boolean;
  readonly cellOnClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  readonly cells: ReadonlyArray<Cell>;
}

interface Cell {
  readonly id: string;
  readonly value: React.ReactNode;
}

export function TableBody({
  rows,
  rowOnClick
}: {
  readonly rows: ReadonlyArray<Row>;
  readonly rowOnClick: (rowId: string) => void;
}) {
  const handleTableRowClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const tr = (e as any).target.closest("tr");

    if (!tr) {
      return;
    }

    const id = tr.getAttribute("data-id");
    rowOnClick(id);
  };

  const handleTableCellClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: Row
  ) => {
    const td = (e as any).target.closest("td");
    if (!td) {
      return;
    }
    const id = td.getAttribute("data-id");
    (e as any).target.value = id;
    row.cellOnClick(e);
  };

  return (
    <tbody onClick={handleTableRowClick}>
      {rows.map(row => (
        <tr
          key={row.id}
          data-id={row.id}
          style={{
            opacity: row.isEditable ? 1 : 0.5,
            border: row.highlightForEdit
              ? `2px dotted ${PrimaryColors.green}`
              : "none"
          }}
          onClick={e => {
            handleTableCellClick(e, row);
          }}
        >
          {row.cells.map((cell, i) => (
            <td
              key={cell.id}
              data-id={cell.id}
              style={{
                backgroundColor: row.isSelected ? PrimaryColors.blue25 : ""
              }}
              className={isStaticColumn(i) ? "sticky-col" : undefined}
            >
              {cell.value}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

function isStaticColumn(index: number) {
  return index < 4;
}
