import * as GQLTypes from "../../../../../graphql-types";
import { UpdateFunction } from "../types";
import { Action } from "../../../actions";
import { updateSeenNewsItemMutation } from "@genesys/client-core/lib/graphql-mutations";

export const handleUpdateSeenNewsItem: UpdateFunction = (
  action,
  state,
  oldSharedState
) => {
  if (action.type !== "updateSeenNewsItem") {
    return [state];
  }
  if (state.user) {
    state = {
      ...state,
      user: {
        ...state.user,
        seenNewsItems: state.user.seenNewsItems
          .concat(action.newsItemIds)
          .sort()
          .reverse()
          .slice(0, 5)
      }
    };
  }
  return [
    state,
    [
      oldSharedState.graphQL.queryUserCmd<
        GQLTypes.UpdateSeenNewsItemMutation,
        GQLTypes.UpdateSeenNewsItemMutationVariables,
        Action
      >(
        updateSeenNewsItemMutation,
        {
          id: action.newsItemIds
        },
        () => Action.sharedStateActionMutationCompleted(false)
      )
    ]
  ];
};
