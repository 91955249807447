import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import {
  OperatingCaseGroupEnum,
  MoistureHeatLoadTypeEnum,
  MaxLoadTypeEnum,
  RoomWarningEnum,
  FlowSourceEnum,
  Scalars
} from "../../graphql-types";
import * as PropertiesSelector from "../../properties-selector";
import * as OperationTimeGen2 from "../../operation-time-manager";

type SelectorType = "ComboBox" | "RadioGroup" | "Checkbox";
export type PropertyDefinition = PropertiesSelector.PropertyInfo & {
  readonly true_filter?: PropertyFilter.PropertyFilter;
  readonly enabled_filter?: PropertyFilter.PropertyFilter;
  readonly selector_type?: SelectorType;
  readonly showDescription?: boolean;
  readonly showValuesDescription: number[] | undefined;
};

export type PropertyDefinitions = ReadonlyArray<PropertyDefinition>;

export interface SystemOverrides {
  readonly [key: string]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.DESIGN_SUMMER]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.DESIGN_WINTER]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.JANUARY]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.FEBRUARY]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.MARCH]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.APRIL]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.MAY]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.JUNE]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.JULY]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.AUGUST]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.SEPTEMBER]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.OCTOBER]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.NOVEMBER]: PropertyValueSet.PropertyValueSet;
  readonly [OperatingCaseGroupEnum.DECEMBER]: PropertyValueSet.PropertyValueSet;
}

export type SettingsCollection = {
  readonly moistureLoadSettings: PropertyValueSet.PropertyValueSet;
  readonly systemOverrides: SystemOverrides;
  readonly loadOperationTimes: LoadOperationTime | null | undefined;
  readonly systemOperationTime: OperationTimeGen2.OperationTime;
  // readonly loadOperationTimes: {
  //   readonly [loadName in MoistureHeatLoadTypeEnum]: OperationTimeGen2.OperationTime;
  // };
  readonly climateData: PropertyValueSet.PropertyValueSet;
  readonly binData: BinData;
};

export type LoadOperationTime = {
  readonly [loadName in MoistureHeatLoadTypeEnum]: OperationTimeGen2.OperationTime;
};

export type BinDataType = "DB/MCWB" | "WB/MCDB" | "DP/MCDB" | "h/MCDB";
export type BinSize = 1 | 2 | 5;
export type BinData = {
  readonly locationId: string;
  readonly binSize: BinSize;
  readonly dataType: BinDataType;
};

export interface CheckBoxoptions {
  readonly includeAllInputs: boolean;
  readonly includeCharts: boolean;
}

export interface MoistureLoadQueryResult {
  readonly moistureloadInput: MoistureloadInput;
  readonly moistureLoadResult: MoistureLoadResult;
}

export interface MlOpcaseResult {
  readonly opcResult: ReadonlyArray<{
    readonly caseName: string;
    readonly settings: string;
  }>;
  readonly climateSettings: string;
}

interface MoistureloadInput {
  readonly id: string;
  readonly climateSettings: string;
}

interface MoistureLoadResult {
  readonly maxLoadResults: ReadonlyArray<MaxLoadResults>;
}

interface MaxLoadResults {
  readonly id: string;
  readonly selectedFlowId?: number | null;
  readonly caseType: MaxLoadTypeEnum;
  readonly needResourceString: string;
  readonly caseName: string;
  readonly usedRoomControlSettings: Scalars["PropertyValueSet"];
  readonly operatingCaseResults: Scalars["PropertyValueSet"];
  readonly roomWarnings: ReadonlyArray<RoomWarningEnum>;
  readonly flowSuggestions: ReadonlyArray<FlowSuggestions>;
}

interface FlowSuggestions {
  readonly id: number;
  readonly flowSourceId: FlowSourceEnum;
  readonly isValid: boolean;
  readonly result: Scalars["PropertyValueSet"];
}
