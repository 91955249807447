// VAD GÖR DENNA FIL ?
// Kanske kan lägga den under shared??

import * as React from "react";
import styled from "styled-components";
import { clientConfig } from "../../../../../config";
import * as SharedState from "../../../../../shared-state";

interface Props {
  readonly casResultId: string;
  readonly language: string;
  readonly sharedState: SharedState.State;
}

type BlobRegisterEntry =
  | BlobRegisterEntryLoaded
  | BlobRegisterEntryNothing
  | undefined;

interface BlobRegisterEntryLoaded {
  readonly type: "BlobRegisterEntryLoaded";
  readonly url: string;
}

interface BlobRegisterEntryNothing {
  readonly type: "BlobRegisterEntryNothing";
}

interface State {
  readonly blobRegister: {
    readonly [opCaseResultId: string]: BlobRegisterEntry;
  };
}

const Container = styled.div`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;

export class FanChartView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      blobRegister: {}
    };
  }

  render(): JSX.Element | null {
    const cacheEntry = this.state.blobRegister[
      getCacheKey(this.props.casResultId, this.props.language)
    ];
    if (
      cacheEntry === undefined ||
      cacheEntry.type === "BlobRegisterEntryNothing"
    ) {
      return null;
    }

    return (
      <Container>
        <img src={cacheEntry.url} />
      </Container>
    );
  }

  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate(prevProps: Props) {
    const oldCacheKey = getCacheKey(prevProps.casResultId, prevProps.language);
    const newCacheKey = getCacheKey(
      this.props.casResultId,
      this.props.language
    );

    const cacheEntry = this.state.blobRegister[
      getCacheKey(this.props.casResultId, this.props.language)
    ];

    if (oldCacheKey !== newCacheKey && cacheEntry === undefined) {
      this.loadChart();
    }
  }

  async loadChart() {
    const routeEndpoint = clientConfig.genesysBackend;
    const response = await fetch(
      routeEndpoint +
        `/internal/GetFanChartApi/${this.props.casResultId}?culture=${this.props.language}`,
      {
        headers: {
          Authorization: `Bearer ${this.props.sharedState.accessToken}`
        }
      }
    );
    if (!response.ok) {
      this.setState(prevState => ({
        ...prevState,
        blobRegister: {
          ...prevState.blobRegister,
          [getCacheKey(this.props.casResultId, this.props.language)]: {
            type: "BlobRegisterEntryNothing"
          }
        }
      }));
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    this.setState(prevState => ({
      ...prevState,
      blobRegister: {
        ...prevState.blobRegister,
        [getCacheKey(this.props.casResultId, this.props.language)]: {
          type: "BlobRegisterEntryLoaded",
          url
        }
      }
    }));
  }
}

function getCacheKey(opCaseResultId: string, culture: string): string {
  return `${opCaseResultId};${culture}`;
}
