import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import {
  changedFromBoth0,
  changedToBoth0,
  getIfIntegerChanged
} from "../../helper-functions";
import { EmissionFromPeopleIcon } from "@genesys/ui-elements";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import { LoadOperationTime } from "../../types";
//Icon
export const Icon = EmissionFromPeopleIcon;

// VIsibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty =
  KnownProperties.useEmissionFromPeopleMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 1;
export const useHeatProperty = KnownProperties.useEmissionFromPeopleHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcemissionfrompeople";
export const sourceName = MoistureHeatLoadTypeEnum.EMISSION_FROM_PEOPLE;
export const illustrationPath =
  "/assets/images/moisture-and-heat-load-types/sources/emission-from-people.jpg";
export const description = "mlcemissionfrompeopleexplanation1";
export const useLoadKey = "mlcuseemissionfrompeople";

export const manualInputProperty =
  KnownProperties.emissionFromPeopleManualInput;

export const properties = [
  KnownProperties.useEmissionFromPeopleMoistureLoad,
  KnownProperties.useEmissionFromPeopleHeatLoad,
  KnownProperties.emissionFromPeopleManualInput,
  KnownProperties.numberOfPersons,
  KnownProperties.activityLevel,
  KnownProperties.useManualEmissionPerPerson,
  KnownProperties.emissionPerPerson,
  KnownProperties.useManualHeatLoadPerPerson,
  KnownProperties.heatLoadPerPerson
];

export const overridableProperties = [
  KnownProperties.numberOfPersons,
  KnownProperties.activityLevel,
  KnownProperties.useManualEmissionPerPerson,
  KnownProperties.emissionPerPerson,
  KnownProperties.useManualHeatLoadPerPerson,
  KnownProperties.heatLoadPerPerson
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.factoryHours;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  if (
    changedFromBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEmissionFromPeopleMoistureLoad,
      KnownProperties.useEmissionFromPeopleHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(1),
      defaults
    );
  } else if (
    changedToBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEmissionFromPeopleMoistureLoad,
      KnownProperties.useEmissionFromPeopleHeatLoad
    )
  ) {
    defaults = PropertyValueSet.merge(
      setMoistureAndHeatLoadDefaults(0),
      defaults
    );
  }

  let useMoistureLoad = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useEmissionFromPeopleMoistureLoad
  );
  if (useMoistureLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      setMoistureLoadDefaults(useMoistureLoad!),
      defaults
    );
  }

  let useHeatLoad = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.useEmissionFromPeopleHeatLoad
  );
  if (useHeatLoad !== undefined) {
    defaults = PropertyValueSet.merge(
      setHeatLoadDefaults(useHeatLoad!),
      defaults
    );
  }

  defaults = PropertyValueSet.merge(
    setMoistureLoadPerPerson(
      PropertyValueSet.merge(defaults, currentSelections)!
    ),
    defaults
  );

  defaults = PropertyValueSet.merge(
    setHeatLoadPerPerson(PropertyValueSet.merge(defaults, currentSelections)!),
    defaults
  );

  return defaults;
}

function setMoistureAndHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.emissionFromPeopleManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.numberOfPersons]: PropertyValue.fromAmount(
          Amount.create(0, Units.One)
        ),
        [KnownProperties.activityLevel]: PropertyValue.fromInteger(0)
      };

    case 1:
      return {
        [KnownProperties.emissionFromPeopleManualInput]:
          PropertyValue.fromInteger(0),
        [KnownProperties.numberOfPersons]: PropertyValue.fromAmount(
          Amount.create(5, Units.One)
        ),
        [KnownProperties.activityLevel]: PropertyValue.fromInteger(2)
      };
    default:
      throw new Error("Invalid value.");
  }
}
function setMoistureLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
      return {
        [KnownProperties.useManualEmissionPerPerson]:
          PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
      return {
        [KnownProperties.useManualHeatLoadPerPerson]:
          PropertyValue.fromInteger(0)
      };

    default:
      throw new Error("Invalid value.");
  }
}

function setMoistureLoadPerPerson(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualEmissionPerPerson,
    selections
  ) === 1
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.emissionPerPerson]: PropertyValue.fromAmount(
          getActivityLevelMoistureLoadsPerPerson(
            PropertyValueSet.getInteger(
              KnownProperties.activityLevel,
              selections
            ) || 0
          )
        )
      };
}

function getActivityLevelMoistureLoadsPerPerson(value: number) {
  return Amount.create([0, 40, 100, 200][value] || 0, Units.GramPerHour);
}

function setHeatLoadPerPerson(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualHeatLoadPerPerson,
    selections
  ) === 1
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.heatLoadPerPerson]: PropertyValue.fromAmount(
          getActivityLevelHeatLoadsPerPerson(
            PropertyValueSet.getInteger(
              KnownProperties.activityLevel,
              selections
            ) || 0
          )
        )
      };
}

function getActivityLevelHeatLoadsPerPerson(value: number) {
  return Amount.create([0, 100, 200, 400][value] || 0, Units.Watt);
}
