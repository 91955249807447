import * as React from "react";
import * as GraphQlTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  Container,
  Header,
  Content,
  HeaderLabel,
  NewsItem,
  StyledDiv,
  StyledP
} from "./elements";
import { InlineButton, NewInHelpDeskCard } from "@genesys/ui-elements";
import { clientConfig } from "../../../config";
// import ReactMarkdown from 'react-markdown'

export function NewsInHelpDeskView({
  wikijsData,
  sharedState,
  onNewsItemClicked,
  onShowAllNewsClicked
}: {
  readonly wikijsData: GraphQlTypes.WikiJsQuery | undefined;
  readonly sharedState: SharedState.State;
  readonly onNewsItemClicked: (id: number) => void;
  readonly onShowAllNewsClicked: (notSeenIds: ReadonlyArray<number>) => void;
}): JSX.Element | null {
  const skelettons: Array<JSX.Element> = [];
  const translate = sharedState.translate;
  if (wikijsData === undefined) {
    for (let i = 0; i < 5; i++) {
      skelettons.push(
        <a key={i}>
          <NewInHelpDeskCard />
        </a>
      );
    }
  }

  const hasUnseenNews = wikijsData
    ? wikijsData.user.wikiJsPages.some(
        w => !sharedState.user.seenNewsItems.includes(parseInt(w.id, 10))
      )
    : false;

  return (
    <Container>
      <Header>
        <HeaderLabel highlighted={hasUnseenNews}>
          {translate(LanguageTexts.newInHelpDeskHeader())}
        </HeaderLabel>
        <InlineButton
          onClick={() => {
            if (wikijsData) {
              const notSeenIds = wikijsData.user.wikiJsPages
                .filter(
                  w =>
                    !sharedState.user.seenNewsItems.includes(parseInt(w.id, 10))
                )
                .map(w => parseInt(w.id, 10));
              onShowAllNewsClicked(notSeenIds);
              window.open(
                `${clientConfig.wikiJsBaseUrl.replace(
                  "/graphql",
                  "/Latest-News"
                )}`
              );
            }
          }}
        >
          {sharedState.translate(LanguageTexts.viewAll()).toUpperCase()}
        </InlineButton>
      </Header>
      <Content>
        {wikijsData
          ? wikijsData.user.wikiJsPages.map(w => {
              const content = w.markdownContent;
              const imgSrc = getFirstImageInMarkdownContent(content || "");
              const url = clientConfig.wikiJsBaseUrl.replace("/graphql", "");
              const notSeen = !sharedState.user.seenNewsItems.includes(
                parseInt(w.id, 10)
              );
              return (
                <NewsItem highlighted={notSeen} key={w.title}>
                  <NewInHelpDeskCard
                    title={w.title}
                    imageSrc={imgSrc}
                    contents={
                      <div>
                        <StyledDiv>
                          <StyledP>{w.description}</StyledP>
                        </StyledDiv>

                        {w.path.length !== 0 && (
                          <StyledP>
                            <a
                              href="#"
                              onClick={event => {
                                event.preventDefault();
                                window.open(`${url}/${w.path}`);
                                if (notSeen) {
                                  onNewsItemClicked(parseInt(w.id, 10));
                                }
                              }}
                            >
                              Click here
                            </a>{" "}
                            to read more
                          </StyledP>
                        )}
                      </div>
                    }
                  />
                </NewsItem>
              );
            })
          : skelettons}
      </Content>
    </Container>
  );
}

function getFirstImageInMarkdownContent(
  _markdownText: string
): string | undefined {
  const reg = /!\[(.*?)\]\((.*?)\)/gim;
  const matches = reg.exec(_markdownText);
  return matches?.[2];
}
