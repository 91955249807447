import * as React from "react";
import * as OperationTimeGen2 from "../../operation-time-manager";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Pop } from "@genesys/ui-elements";
import { Edit } from "@genesys/ui-elements";

export function OperationTimeView({
  operationTimeState,
  readonly,
  sharedState,
  labelRef,
  allowedSelections,
  dispatch
}: {
  readonly operationTimeState: OperationTimeGen2.State;
  readonly readonly: boolean;
  readonly labelRef: React.RefObject<HTMLLabelElement>;
  readonly sharedState: SharedState.State;
  readonly allowedSelections?: OperationTimeGen2.State;
  readonly dispatch: Dispatch<OperationTimeGen2.Action>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    setAnchorEl(labelRef.current);
    // dispatch(Action.toggleOperationTime());
  };

  const handleClose = () => {
    // dispatch(Action.toggleOperationTime());
    setAnchorEl(null);
  };

  return (
    <>
      <span onClick={handleClick}>
        <Edit />
      </span>
      {anchorEl && operationTimeState && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          <OperationTimeGen2.OperationTimeView
            sharedState={sharedState}
            readonly={readonly}
            operationTime={operationTimeState}
            allowedSelections={allowedSelections}
            dispatch={dispatch}
            onClose={handleClose}
          />
        </Pop>
      )}
    </>
  );
}
