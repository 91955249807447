import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LocationSelectorGen2 from "../../../location-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import {
  EditBlue,
  Pop,
  GenesysSelect,
  StandardButton
} from "@genesys/ui-elements";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { BinDataType, BinSize } from "../../tools";
import { climateSelectorCustomRenderer } from "./climate-selector-custom-renderer";
import * as ClimateSelector from "../../../climate-selector";

interface ContextValue {
  readonly labelRef: React.MutableRefObject<null>;
  readonly anchorEl: Element | null;
  readonly setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}

const Context = React.createContext<ContextValue | null>(null);

const dataTypes: ReadonlyArray<BinDataType> = [
  "DB/MCWB",
  "WB/MCDB",
  "DP/MCDB",
  "h/MCDB"
];
const SubContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 6px;
  }
`;

const Label = styled.label`
  color: rgb(0, 177, 247);
  font-size: 18px;
`;

const Container = styled.div`
  padding: 45px;
  padding-top: 10px;
  font-size: 13px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const StyledDiv = styled.div`
  /* > div {
    margin-top: -40px;
  } */
`;

const MainContentContainer = styled.div``;

const binSizes: ReadonlyArray<BinSize> = [1, 2, 5];

export function Wrapper({ children }: { readonly children: React.ReactNode }) {
  const labelRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const providerValue = {
    anchorEl,
    labelRef,
    setAnchorEl
  };

  return (
    <>
      <Context.Provider value={providerValue}>{children}</Context.Provider>
    </>
  );
}

export function Button({ style }: { readonly style?: React.CSSProperties }) {
  const { labelRef, setAnchorEl } = React.useContext<ContextValue>(
    Context as any
  );
  return (
    <span style={style} ref={labelRef}>
      <EditBlue
        onClick={() => {
          setAnchorEl(labelRef.current);
        }}
        height="15px"
      />
    </span>
  );
}

interface Props {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly isDisabled: boolean;
  readonly dispatch: Dispatch<Action>;
}

export function PopUpContent(props: Props) {
  const { anchorEl, setAnchorEl } = React.useContext<ContextValue>(
    Context as any
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { sharedState, state } = props;

  if (
    !anchorEl ||
    !state.locationSelectorState ||
    !state.climateSelectorState
  ) {
    return null;
  }

  return (
    <Pop
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchor={anchorEl}
      onClose={handleClose}
    >
      <Container>
        <ButtonContainer>
          <StandardButton
            onClick={handleClose}
            buttonType="Primary-2"
            size="Small"
          >
            {sharedState.translate(LanguageTexts.close())}
          </StandardButton>
        </ButtonContainer>
        <MainContentContainer>
          <ClimateDataLocation {...props} />
          <BinDataLocation {...props} />
        </MainContentContainer>
      </Container>
    </Pop>
  );
}

function ClimateDataLocation(props: Props) {
  const { sharedState, isDisabled, state, dispatch } = props;

  return (
    <StyledDiv>
      <HeaderContainer>
        <Label>
          {sharedState.translate(
            LanguageTexts.globalPropertyName("mlcclimatedatalocation")
          )}
        </Label>
      </HeaderContainer>

      <ClimateSelector.ClimateSelectorView
        state={state.climateSelectorState!}
        isDisabled={isDisabled}
        sharedState={sharedState}
        showWindVelocity={false}
        dispatch={Dispatch.map(Action.dispatchClimateSelector, dispatch)}
        customRenderer={climateSelectorCustomRenderer}
      />
    </StyledDiv>
  );
}

function BinDataLocation(props: Props) {
  const { sharedState, isDisabled, state, dispatch } = props;
  return (
    <div>
      <HeaderContainer>
        <Label>
          {" "}
          {sharedState.translate(LanguageTexts.mlcBinDataLocation())}
        </Label>
      </HeaderContainer>

      <DataAndBinType {...props} />
      <LocationSelectorGen2.View
        state={state.locationSelectorState!}
        sharedState={sharedState}
        dispatch={Dispatch.map(Action.dispatchLocationSelector, dispatch)}
        readonly={isDisabled || state.binDataSelectorDisabled}
      />
    </div>
  );
}

function DataAndBinType(props: Props) {
  const { sharedState, isDisabled, state, dispatch } = props;

  return (
    <SubContainer>
      <span>{sharedState.translate(LanguageTexts.dataType())}</span>
      <GenesysSelect
        width={15}
        height={30}
        fontSize={13}
        disabled={isDisabled}
        value={state.binData!.dataType}
        onChange={e => {
          dispatch(
            Action.setBinData({
              locationId: state.binData!.locationId,
              binSize: state.binData!.binSize,
              dataType: e.target.value as BinDataType
            })
          );
        }}
        options={dataTypes.map(dt => ({
          value: dt,
          title: dt
        }))}
      />
      <span> {sharedState.translate(LanguageTexts.binSize())}</span>
      <GenesysSelect
        width={15}
        height={30}
        fontSize={13}
        disabled={isDisabled}
        value={state.binData!.binSize.toString()}
        onChange={e => {
          dispatch(
            Action.setBinData({
              locationId: state.binData!.locationId,
              binSize: parseInt(e.target.value, 10) as BinSize,
              dataType: state.binData!.dataType
            })
          );
        }}
        options={binSizes.map(bz => ({
          value: bz.toString(),
          title: bz.toString()
        }))}
      />
    </SubContainer>
  );
}
