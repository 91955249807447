import React from "react";
import { TableCell, TableRow, TableHead } from "../../atoms/table";
import { StyledTable } from "./styled-table";
import { PrimaryColors } from "../../colors";
import { GenericTableColumnDefinition, GenericTableRow, Props } from "./types";

export function GenericTable<T extends Record<keyof T, string | number>, A>(
  props: Props<T, A>
) {
  const alignHeader = props.defaultHeaderAlignment || "left";
  const alignRows = props.defaultRowAlignment || "left";

  return (
    <StyledTable alignRows={alignRows} alignHeader={alignHeader}>
      <TableHeader {...props} />
      <TableBody {...props} />
    </StyledTable>
  );
}

const ungroupedKey = "internal-ungrouped-key";

function TableBody<T extends Record<string, string | number>, A>(
  props: Props<T, A>
) {
  const { data } = props;
  const groupbyNames = data.reduce<Record<string, GenericTableRow<T, A>[]>>(
    (acc, row) => {
      const groupKey = row.group || ungroupedKey;

      // Initialize the group if it doesn't exist
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      // Add the row to the respective group
      acc[groupKey].push(row);

      return acc;
    },
    {}
  );

  const renderGroupHeader = (groupName: string) => (
    <TableRow>
      <TableCell colSpan={props.columns.length} bold>
        {groupName}
      </TableCell>
    </TableRow>
  );

  return (
    <tbody>
      {Object.keys(groupbyNames).map(groupName => {
        const group = groupbyNames[groupName];
        return (
          <React.Fragment key={groupName}>
            {renderGroupHeader(ungroupedKey === groupName ? "" : groupName)}
            {group.map((row, i) => (
              <GenericTableRow
                hasBlueBackground={i % 2 === 0}
                key={row.rowId}
                row={row}
                {...props}
              />
            ))}
          </React.Fragment>
        );
      })}
    </tbody>
  );
}
function GenericTableRow<T extends Record<string, string | number>, A>({
  row,
  columns,
  hasBlueBackground,
  higlightedRowStyle,
  rowOnClick,
  rowOnHover
}: {
  readonly row: GenericTableRow<T, A>;
  readonly columns: ReadonlyArray<GenericTableColumnDefinition<T, A>>;
  readonly hasBlueBackground?: boolean;
  readonly higlightedRowStyle?: React.CSSProperties;
  readonly rowOnClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: GenericTableRow<T, A>
  ) => void;
  readonly rowOnHover?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: GenericTableRow<T, A>
  ) => void;
}) {
  return (
    <TableRow
      rowStyle={getHighlitedRowStyle(
        !!row.highlighted,
        !!hasBlueBackground,
        higlightedRowStyle
      )}
      onClick={event => {
        if (rowOnClick) {
          rowOnClick(event, row);
        }
      }}
      onMouseOver={event => {
        if (rowOnHover) {
          rowOnHover(event, row);
        }
      }}
    >
      {columns.map(column => {
        const cell = row.rowValues[column.key];
        const defaultCellRender = (val: string | number) => val;
        const cellRenderer = column.customCellRenderer || defaultCellRender;

        return (
          <TableCell
            key={cell.cellId}
            align={column.align}
            bold={cell.isBold || row.isBold}
          >
            {!cell.isHidden && cellRenderer(cell.value, cell.attributes)}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function TableHeader<T extends Record<string, string | number>, A>({
  columns
}: Props<T, A>) {
  return (
    <thead>
      <TableRow>
        {columns.map((column, i) => (
          <TableHead width={column.width} align={column.align} key={i}>
            {column.header}
          </TableHead>
        ))}
      </TableRow>
    </thead>
  );
}

function getHighlitedRowStyle(
  rowIsHighlighted: boolean,
  hasBlueBackground: boolean,
  higlightedRowStyle?: React.CSSProperties
): React.CSSProperties {
  if (rowIsHighlighted) {
    if (higlightedRowStyle) {
      return higlightedRowStyle;
    }
    return { backgroundColor: PrimaryColors.blue25 };
  }

  if (hasBlueBackground) {
    return { backgroundColor: "#f7f9fc" };
  }

  return {};
}
