import gql from "graphql-tag";

export const toggleReportFor5ystemType = gql`
  mutation ToggleReportFor5ystemType($reportInfo: ReportInputType!) {
    toggleReportsForsystemType(reportInfo: $reportInfo) {
      id
      reportSettings {
        systemType
        reports {
          reportId
          reportSettingParams {
            paramName
            value
          }
        }
      }
    }
  }
`;
