import * as Calculations from "@munters/calculations";
import { Port, ConnectionType } from "./types";

interface BoxConnectionPoint {
  readonly productSectionId: string;
  readonly connectionType: string;
  readonly positionX: number;
  readonly positionY: number;
  readonly mirrorX: boolean;
  readonly mirrorY: boolean;
  readonly rotation: number;
}

export function createFlowDiagramPort(
  componentSectionId: string,
  boxConnectionPoint: BoxConnectionPoint
): Port {
  const portTransform = Calculations.Math.Matrix2.composite(
    boxConnectionPoint.rotation,
    boxConnectionPoint.mirrorX,
    boxConnectionPoint.mirrorY,
    boxConnectionPoint.positionX,
    boxConnectionPoint.positionY
  );
  return {
    componentSectionId: componentSectionId,
    productSectionId: boxConnectionPoint.productSectionId,
    connectionType: boxConnectionPoint.connectionType as ConnectionType,
    transform: portTransform
  };
}
