import * as React from "react";
import { P2, P1 } from "@genesys/ui-elements";
import {
  PopupRoot,
  Popup,
  P2WithCursor,
  PopupContainer,
  PaddingBottomContainer
} from "../elements";
import * as Types from "../types";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function PreValidation({
  systemVariant
}: {
  readonly systemVariant: Types.SystemVariant;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);

  const popup = (
    label: string,
    width: "wide" | "narrow",
    content: JSX.Element
  ) => {
    return (
      <PopupRoot>
        <P2 color="secondary" weight="normal">
          {label}
        </P2>
        <PopupContainer
          onMouseEnter={() => setState(true)}
          onMouseLeave={() => setState(false)}
        >
          <P2WithCursor color="secondary" weight="normal">
            (Show details)
          </P2WithCursor>
          {isOpen && (
            <Popup borderColor="blue" width={width}>
              {content}
            </Popup>
          )}
        </PopupContainer>
      </PopupRoot>
    );
  };

  if (systemVariant.invalidSystemRequirements) {
    const propertyFilter =
      systemVariant.invalidSystemRequirements.propertyFilter;
    const selectionNewProperties =
      systemVariant.invalidSystemRequirements.selectionNewProperties;

    return popup(
      "System not compatible with Selection limitiations: ",
      "wide",
      propertyFilter ? (
        <PaddingBottomContainer>
          <div>
            <P2 color="dark" weight="normal">
              {`System requirements: `}
            </P2>
            <P2 color="secondary" weight="normal">
              {PropertyFilter.toString(propertyFilter)}
            </P2>
          </div>
          <div>
            <P2 color="dark" weight="normal">
              {`Selection limitations: `}
            </P2>
            <P2 color="secondary" weight="normal">
              {PropertyValueSet.toString(selectionNewProperties)}
            </P2>
          </div>
        </PaddingBottomContainer>
      ) : (
        <PaddingBottomContainer>
          <P2 color="dark" weight="normal">
            {`Missing systemrequirements row in promaster`}
          </P2>
        </PaddingBottomContainer>
      )
    );
  } else if (systemVariant.invalidProperties.length > 0) {
    return popup(
      "Invalid Properties: ",
      "wide",
      <div>
        {systemVariant.invalidProperties.map((p, ix) => {
          switch (p.type) {
            case "not-valid-with-configuration":
              return (
                <PaddingBottomContainer key={p.propertyName + ix}>
                  <div>
                    <P1 color="dark" weight="normal">{`${
                      p.propertyName
                    }=${PropertyValue.toString(
                      p.propertyValue
                    )} is not valid with generated configuration`}</P1>
                  </div>
                  <div>
                    <P2
                      color="secondary"
                      weight="normal"
                    >{`Property filter: ${PropertyFilter.toString(
                      p.propertyFilter
                    )}`}</P2>
                  </div>
                  <div>
                    <P2
                      color="secondary"
                      weight="normal"
                    >{`Generated configuration: ${PropertyValueSet.toString(
                      p.configuration
                    )}`}</P2>
                  </div>
                </PaddingBottomContainer>
              );
            case "undefined-value":
              return (
                <PaddingBottomContainer key={p.propertyName + ix}>
                  <P1 color="dark" weight="normal">{`${
                    p.propertyName
                  }=${PropertyValue.toString(
                    p.propertyValue
                  )} is not defined for this system`}</P1>
                </PaddingBottomContainer>
              );
            case "no-valid-mapping":
              return (
                <PaddingBottomContainer key={p.propertyName + ix}>
                  <div>
                    <P1 color="dark" weight="normal">
                      {`No valid mapping found for: ${p.propertyName}`}
                    </P1>
                  </div>
                  <div>
                    <P2 color="secondary" weight="normal">
                      {`Selection limitations: ${PropertyValueSet.toString(
                        p.selectionNewProperties
                      )}`}
                    </P2>
                  </div>
                  <div>
                    <P2 color="secondary" weight="normal">
                      Mapping rows:{" "}
                    </P2>
                    {p.mappingRowsFilter.map(f => (
                      <P2 color="secondary" weight="normal">
                        {PropertyFilter.toString(f)}
                      </P2>
                    ))}
                  </div>
                </PaddingBottomContainer>
              );
            default:
              return exhaustiveCheck(p, true);
          }
        })}
      </div>
    );
  } else if (systemVariant.invalidThreshold) {
    switch (systemVariant.invalidThreshold.type) {
      case "missing-data":
        return (
          <P2 color="secondary" weight="normal">
            {`Missing dehumidification threshold data for model`}
          </P2>
        );
      case "invalid-treshold":
        return (
          <P2 color="secondary" weight="normal">
            {`DH capacity less than system total moisture load`}
          </P2>
        );
      default:
        return exhaustiveCheck(systemVariant.invalidThreshold.type, true);
    }
  } else if (systemVariant.calculatedDehumCapacity.type === "failed") {
    return (
      <P2 color="secondary" weight="normal">
        {`DH Capacity calculation failed`}
      </P2>
    );
  } else {
    return (
      <P2 color="secondary" weight="normal">
        Passed pre-validation
      </P2>
    );
  }
}
