import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValueSet } from "@genesys/property";
import { SystemOverrides } from "../tools";
import * as SharedState from "../../shared-state";
// import * as GraphQLTypes from "../../../graphql-types";
import * as UseCaseAndBuildingConfig from "./use-case-and-building-config";
import * as LoadTypes from "./load-types";
import * as Result from "./result";
import * as OperationTimeGen2 from "../../operation-time-manager";
import { BaseState, LoadOperationTime } from "./type";

// import * as Navigation from "../navigation-effect-manager";
import { Tab } from "./type";

export type State = {
  readonly currentTab: Tab;
  readonly useCaseAndBuildingConfigState:
    | UseCaseAndBuildingConfig.State
    | undefined;
  readonly loadTypesContentState: LoadTypes.State | undefined;
  readonly resultState: Result.State;
};
export const init = (
  baseState: BaseState,
  moistureLoadSettings: PropertyValueSet.PropertyValueSet,
  initalSystemOperatonTime: OperationTimeGen2.OperationTime,
  initialLoadOperationTimes: LoadOperationTime | null | undefined
): readonly [State] => {
  const [useCaseAndBuildingConfigState] = UseCaseAndBuildingConfig.init(
    baseState,
    initalSystemOperatonTime
  );

  const [loadTypesContentState] = LoadTypes.init(
    baseState,
    moistureLoadSettings,
    initialLoadOperationTimes as any
  );
  return [
    {
      currentTab: "building-and-system-config",
      //   calcResult: undefined,
      useCaseAndBuildingConfigState: useCaseAndBuildingConfigState,
      loadTypesContentState: loadTypesContentState,
      resultState: Result.init()[0]
    }
  ];
};

export const Action = ctorsUnion({
  changePageTab: (tab: Tab) => ({ tab }),
  dispatchLoadTypesContent: (action: LoadTypes.Action) => ({ action }),
  dispatchUseCaseAndBuildingConfig: (
    action: UseCaseAndBuildingConfig.Action
  ) => ({ action }),
  dispatchResult: (action: Result.Action) => ({ action })
});
export type Action = CtorsUnion<typeof Action>;

type UpdatedSystemOverrides = SystemOverrides;
type UpdatedSPropertieSelectorState = PropertyValueSet.PropertyValueSet;
type ResultIsInvalid = boolean;
type UpdateReturnType = readonly [
  State,
  UpdatedSystemOverrides,
  UpdatedSPropertieSelectorState,
  ResultIsInvalid,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
];

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  propertiesSelectorState: PropertyValueSet.PropertyValueSet,
  currentSystemOverrides: SystemOverrides
): UpdateReturnType {
  switch (action.type) {
    case "changePageTab":
      return handleTabChange(
        action.tab,
        state,
        currentSystemOverrides,
        propertiesSelectorState
      );

    case "dispatchLoadTypesContent":
      return handleLoadTypesContentUpdate(
        action.action,
        state,
        sharedState,
        propertiesSelectorState,
        currentSystemOverrides
      );

    case "dispatchResult":
      return handleResultUpdate(
        action.action,
        state,
        sharedState,
        currentSystemOverrides,
        propertiesSelectorState
      );

    case "dispatchUseCaseAndBuildingConfig":
      return handleUseCaseAndBuildingConfigUpdate(
        action.action,
        state,
        sharedState,
        currentSystemOverrides,
        propertiesSelectorState
      );

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getUseCaseAndBuildingConfigState(state: State) {
  return state.useCaseAndBuildingConfigState;
}

type UpdatedMoistureLoadSettings = PropertyValueSet.PropertyValueSet;
export function onResetSelections(
  state: State,
  initialMLSettings: PropertyValueSet.PropertyValueSet
): [State, UpdatedMoistureLoadSettings] {
  const [resetedUseCaseState] = UseCaseAndBuildingConfig.reset(
    state.useCaseAndBuildingConfigState!,
    initialMLSettings
  );

  const [
    resetedLoadTypesContentState,
    updatedMoistureLoadsettingsFromLoadTypes
  ] = LoadTypes.reset(state.loadTypesContentState!, initialMLSettings);

  return [
    {
      ...state,
      useCaseAndBuildingConfigState: resetedUseCaseState,
      loadTypesContentState: resetedLoadTypesContentState
    },
    updatedMoistureLoadsettingsFromLoadTypes
  ];
}

export function removeResult(state: State): State {
  return {
    ...state,
    currentTab:
      state.currentTab === "result"
        ? "moisture-heat-load-types"
        : state.currentTab,
    resultState: {
      ...state.resultState
      // selectedFlowIndex: {}
    }
  };
}

export function moveToResultPage(state: State): State {
  return {
    ...state,
    currentTab: "result",
    resultState: {
      ...state.resultState,
      selectedFlowIndex: {}
    }
  };
}

export function updateSelectedAndAvailableLoads(
  state: State,
  selectedLoadsState: LoadTypes.SelectedLoadsState
): State {
  return {
    ...state,
    loadTypesContentState: {
      ...state.loadTypesContentState!,
      loadsState: {
        ...selectedLoadsState
      }

      // currentSelectedLoads: newSelectedLoads
    }
  };
}

export function updateSelectedLoadState(
  state: State,
  updatedProperties: PropertyValueSet.PropertyValueSet
): State {
  const updatedLoadState = LoadTypes.getUpdatedSelectedLoadState(
    state.loadTypesContentState!,
    updatedProperties
  );

  return {
    ...state,
    loadTypesContentState: {
      ...state.loadTypesContentState!,
      loadsState: {
        ...updatedLoadState
      }
    }
  };
}

export function updateForApplicationTypeChange(
  state: State,
  updatedProperties: PropertyValueSet.PropertyValueSet
) {
  const selectedLoadsState = LoadTypes.getSelectedLoadState(
    state.loadTypesContentState!,
    updatedProperties
  );
  return updateSelectedAndAvailableLoads(state, selectedLoadsState);
}

function handleTabChange(
  tab: Tab,
  state: State,
  currentSystemOverrides: SystemOverrides,
  propertiesSelectorState: PropertyValueSet.PropertyValueSet
): UpdateReturnType {
  return [
    {
      ...state,
      currentTab: tab,
      loadTypesContentState: LoadTypes.setSelectedLoad(
        state.loadTypesContentState!,
        undefined
      )
    },
    currentSystemOverrides,
    propertiesSelectorState,
    false
  ];
}

function handleLoadTypesContentUpdate(
  action: LoadTypes.Action,
  state: State,
  sharedState: SharedState.State,
  propertiesSelectorState: PropertyValueSet.PropertyValueSet,
  currentSystemOverrides: SystemOverrides
): UpdateReturnType {
  const [
    loadTypesContentState,
    updatedMoistureLoadSettings,
    updatedSystemOverrides,
    loadTypesContentCmd,
    resultInvalid
  ] = LoadTypes.update(
    action,
    state.loadTypesContentState!,
    sharedState,
    propertiesSelectorState,
    currentSystemOverrides!
  );

  return [
    {
      ...state,
      //   calcResult: resultInvalid ? undefined : state.calcResult,
      loadTypesContentState
    },
    updatedSystemOverrides,
    updatedMoistureLoadSettings,
    !!resultInvalid,
    Cmd.map(Action.dispatchLoadTypesContent, loadTypesContentCmd)
  ];
}

function handleResultUpdate(
  action: Result.Action,
  state: State,
  sharedState: SharedState.State,
  currentSystemOverrides: SystemOverrides,
  propertiesSelectorState: PropertyValueSet.PropertyValueSet
): UpdateReturnType {
  const [resultState, resultCmd] = Result.update(
    action,
    state.resultState!,
    sharedState
  );
  return [
    { ...state, resultState },
    currentSystemOverrides,
    propertiesSelectorState,
    false,
    Cmd.map(Action.dispatchResult, resultCmd)
  ];
}

function handleUseCaseAndBuildingConfigUpdate(
  action: UseCaseAndBuildingConfig.Action,
  state: State,
  sharedState: SharedState.State,
  currentSystemOverrides: SystemOverrides,
  propertiesSelectorState: PropertyValueSet.PropertyValueSet
): UpdateReturnType {
  const [
    useCaseAndBuildingConfigState,
    updatedSystemOverrides,
    useCaseAndBuildingConfigCmd,
    resultInvalid
  ] = UseCaseAndBuildingConfig.update(
    action,
    state.useCaseAndBuildingConfigState!,
    sharedState,
    currentSystemOverrides
  );

  return [
    {
      ...state,
      useCaseAndBuildingConfigState,
      loadTypesContentState: LoadTypes.getUpdatedResultState(
        state.loadTypesContentState!,
        resultInvalid!!
      )
    },
    updatedSystemOverrides,
    propertiesSelectorState,
    !!resultInvalid,
    Cmd.map(
      Action.dispatchUseCaseAndBuildingConfig,
      useCaseAndBuildingConfigCmd
    )
  ];
}
