import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { promiseCmd } from "../promise-effect-manager";
import { clientConfig } from "../config";
import { UserManager, User } from "oidc-client";
import * as SharedState from "../shared-state";
import * as Navigation from "../navigation-effect-manager";
import * as GraphQLTypes from "../graphql-types";

const clearCacheMutation = gql`
  mutation ClearCacheMutation {
    clearProductCache
  }
`;

const importMutation = gql`
  mutation SystemImportMutation($json: String!) {
    importSystemModel(serializedModel: $json) {
      id
      systemFile {
        id
        name
      }
    }
  }
`;

const importedSystemQuery = gql`
  query ImportedSystemQuery($id: ID!) {
    user {
      system(id: $id) {
        revisionNo
        systemFile {
          genesysNo
        }
      }
    }
  }
`;

const checkUserTandCAcceptanceQuery = gql`
  query CheckUserTandCAcceptanceQuery($userName: String!) {
    user {
      hasUserHasAcceptedTandC(userName: $userName)
    }
  }
`;

export type State = {
  readonly showLoader: Boolean;
  readonly showConfirmModal: Boolean;
  readonly serversCleared: ReadonlyArray<string>;
  readonly impersonationInput: string;
  readonly showImpersonationError: boolean;
  readonly systemModelMissingProps: ReadonlyArray<string>;
  readonly exceptionThrown: boolean;
  readonly disableErrorButton: boolean;
};

export const init = (_sharedState: SharedState.State): State => {
  return {
    showLoader: false,
    showConfirmModal: false,
    serversCleared: [],
    impersonationInput: "",
    showImpersonationError: false,
    exceptionThrown: false,
    disableErrorButton: false,
    systemModelMissingProps: []
  };
};

// -- UPDATE

export const Action = ctorsUnion({
  cacheCleared: (serverName: string) => ({ serverName }),
  cacheClearingStarted: () => ({}),
  clearCache: () => ({}),
  execptionThrown: () => ({}),
  throwException: () => ({}),
  fetchImpersonationDetailsCompleted: (success: boolean) => ({ success }),
  setDebugSettings: (debugSettings: SharedState.DebugSettings) => debugSettings,
  importSystem: (jsonText: string) => ({ jsonText }),
  impersonateUser: (userName: string) => ({ userName }),
  systemImported: (data: GraphQLTypes.SystemImportMutation) => ({
    data
  }),
  openSystem: (genesysNo: number, revisionNo: number) => ({
    genesysNo,
    revisionNo
  }),
  setShowConfirmModal: (showConfirmModal: Boolean) => ({ showConfirmModal }),
  setImpersonationInput: (input: string) => ({ input }),
  setSystemModelMissingProps: (systemModel: ReadonlyArray<string>) => ({
    systemModel
  }),
  setShowImpersonationError: (showError: boolean) => ({ showError })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "setDebugSettings": {
      return [
        state,
        undefined,
        [
          SharedState.Action.setDebugSettings({
            includeServerLog: action.includeServerLog,
            showHiddenProperties: action.showHiddenProperties,
            showHiddenResults: action.showHiddenResults,
            useCalculationWorkers: action.useCalculationWorkers,
            showTextsDB: action.showTextsDB,
            showTextsWithTextId: action.showTextsWithTextId
          })
        ]
      ];
    }
    case "clearCache": {
      return [
        state,
        Cmd.batch([
          sharedState.graphQL.queryUserCmd(
            clearCacheMutation,
            {},
            Action.cacheClearingStarted
          )
        ])
      ];
    }
    case "cacheCleared": {
      return [
        {
          ...state,
          serversCleared: state.serversCleared.concat(action.serverName)
        }
      ];
    }

    case "execptionThrown": {
      return [{ ...state, disableErrorButton: false, exceptionThrown: true }];
    }

    case "throwException": {
      return [
        {
          ...state,
          exceptionThrown: false,
          disableErrorButton: true
        },

        promiseCmd(
          async () => {
            const routeEndpoint = clientConfig.genesysBackend;
            await fetch(`${routeEndpoint}/internal/GetInternalException`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: `Bearer ${sharedState.accessToken}`
              },
              credentials: "include"
            });
            return {};
          },
          () => Action.execptionThrown()
        )
      ];
    }

    case "fetchImpersonationDetailsCompleted": {
      if (action.success) {
        location.reload();
      }

      return [
        {
          ...state,
          showImpersonationError: !action.success
        }
      ];
    }

    case "importSystem": {
      return [
        { ...state, showLoader: true, systemModelMissingProps: [] },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.SystemImportMutation,
          GraphQLTypes.SystemImportMutationVariables,
          Action
        >(
          importMutation,
          {
            json: action.jsonText
          },
          data => Action.systemImported(data)
        )
      ];
    }

    case "impersonateUser": {
      const fetchImpersonationDetails = async () => {
        const manager = new UserManager({
          authority: clientConfig.oidcAuthority,
          client_id: clientConfig.oidcClientId,
          redirect_uri: clientConfig.oidcRedirectUri,
          response_type: clientConfig.oidcResponseType,
          scope: clientConfig.oidcScope,
          monitorSession: false,
          loadUserInfo: false,
          automaticSilentRenew: clientConfig.oidcResponseType === "code"
        });

        const user = await manager.getUser();

        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user?.access_token
          }
        };

        const response = await fetch(
          `/impersonate?username=${action.userName}`,
          options
        );

        if (response.ok) {
          const userResponse = await response.text();
          const user = User.fromStorageString(userResponse);

          manager.storeUser(user);
          // location.reload();
        }

        return response.ok;
      };
      return [
        {
          ...state
          // showLoader: true
        },
        promiseCmd(async () => {
          const res = await sharedState.graphQL.queryUser<
            GraphQLTypes.CheckUserTandCAcceptanceQuery,
            GraphQLTypes.CheckUserTandCAcceptanceQueryVariables
          >(checkUserTandCAcceptanceQuery, {
            userName: action.userName
          });

          const hasAccepted = res.user.hasUserHasAcceptedTandC;

          if (hasAccepted) {
            return fetchImpersonationDetails();
          }
          return false;
        }, Action.fetchImpersonationDetailsCompleted)
      ];
    }

    case "systemImported": {
      if (action.data.importSystemModel) {
        return [
          { ...state, showLoader: false },
          sharedState.graphQL.queryUserCmd<
            GraphQLTypes.ImportedSystemQuery,
            GraphQLTypes.ImportedSystemQueryVariables,
            Action
          >(
            importedSystemQuery,
            {
              id: action.data.importSystemModel.id
            },
            data =>
              Action.openSystem(
                data.user.system.systemFile.genesysNo,
                data.user.system.revisionNo
              )
          )
        ];
      } else {
        return [{ ...state, showLoader: false }];
      }
    }
    case "openSystem": {
      const url = `/system/${sharedState.genesysPrefix.genesysNo(
        action.genesysNo,
        action.revisionNo
      )}`;
      return [
        state,
        Navigation.pushUrl(url),
        [SharedState.Action.loadLastOpenedSystemsAndFavorites()]
      ];
    }

    case "setSystemModelMissingProps": {
      return [
        {
          ...state,
          systemModelMissingProps: action.systemModel
        }
      ];
    }
    case "setShowConfirmModal": {
      return [{ ...state, showConfirmModal: action.showConfirmModal }];
    }
    case "cacheClearingStarted": {
      return [state];
    }
    case "setImpersonationInput": {
      return [
        {
          ...state,
          showImpersonationError: false,
          impersonationInput: action.input
        }
      ];
    }
    case "setShowImpersonationError": {
      return [{ ...state, showImpersonationError: action.showError }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
