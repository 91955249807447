export const exportOptions: ReadonlyArray<{
  readonly value: string;
  readonly title: string;
}> = [
  {
    value: "SVG",
    title: "SVG"
  },
  {
    value: "DXF2D",
    title: "DXF"
  }
];
