import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PropertiesSelector from "../../../../properties-selector";
import * as SharedState from "../../../../shared-state";
import * as SystemOverride from "../../../components/system-overrides";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import {
  PropertyDefinitions,
  SystemOverrides,
  PropertyDefinition
} from "../../../tools";
import { PropertyFilter } from "@genesys/property";
import { Action, Load } from "../state";

const PropertyContainer = styled.div`
  padding-left: 10px;
  display: flex;

  > span {
    margin-top: -1px;
  }

  > table {
    margin-top: -20px;
  }
`;

export function Body({
  sharedState,
  load,
  isDisabled,
  currentSystemOverrides,
  propertiesSelectorState,
  manualInputEnabled,
  allPropertyDefinitions,
  dispatch,
  propertieSelectorDispatch
}: {
  readonly sharedState: SharedState.State;
  readonly load: Load;
  readonly isDisabled: boolean;
  readonly currentSystemOverrides: SystemOverrides;
  readonly propertiesSelectorState: PropertiesSelector.State;
  readonly manualInputEnabled: boolean;
  readonly allPropertyDefinitions: PropertyDefinitions;
  readonly dispatch: Dispatch<Action>;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
}) {
  const propertyDefinitionsForLoad = getPropertyDefinitionsForLoad(
    load,
    manualInputEnabled,
    allPropertyDefinitions
  );
  return (
    <PropertyContainer>
      <PropertiesSelector.PropertiesSelectorView
        dispatch={propertieSelectorDispatch}
        translatePropertyName={propertyName =>
          sharedState.translate(LanguageTexts.globalPropertyName(propertyName))
        }
        translatePropertyValue={(propertyName, propertyValue) =>
          sharedState.translate(
            LanguageTexts.globalPropertyName(propertyName + "_" + propertyValue)
          )
        }
        productId={""}
        sharedState={sharedState}
        propertiesInfo={propertyDefinitionsForLoad}
        readonlyProperties={propertyDefinitionsForLoad.reduce(
          (definitionsSoFar, current) => {
            if (
              current.enabled_filter &&
              !PropertyFilter.isValid(
                PropertiesSelector.getSelectedProperties(
                  propertiesSelectorState
                ),
                current.enabled_filter
              )
            ) {
              definitionsSoFar.push(current.name);
            }
            return definitionsSoFar;
          },
          [] as Array<string>
        )}
        fieldGroup={`MoistureLoadTool.${load.screenAmountProfileFieldGroup}`}
        isReadonly={isDisabled}
        showCodes={false}
        state={propertiesSelectorState}
      />

      {manualInputEnabled && (
        <SystemOverride.View
          useAsPop
          fieldGroups={load.screenAmountProfileFieldGroup}
          dispatch={Dispatch.map(
            action => Action.dispatchSystemOverride(action, load.sourceName),
            dispatch
          )}
          sharedState={sharedState}
          systemOverrides={currentSystemOverrides}
          isDisabled={isDisabled}
          overridableProperties={load.properties.overridableProperties.reduce(
            (soFar: Array<PropertyDefinition>, propertyName) => {
              const property = allPropertyDefinitions.find(
                property => property.name === propertyName
              );
              if (property !== undefined) {
                return soFar.concat(property);
              }
              return soFar;
            },
            []
          )}
        />
      )}
    </PropertyContainer>
  );
}

function getPropertyDefinitionsForLoad(
  load: Load,
  manualInputEnabled: boolean,
  propertyDefinitions: PropertyDefinitions
) {
  const manualInputKey = load.properties.manualInputKey;
  if (manualInputEnabled) {
    return propertyDefinitions.filter(
      x =>
        load.properties.loadProperties.includes(x.name) &&
        (x.name === manualInputKey ||
          load.properties.overridableProperties.every(op => op !== x.name)) &&
        !isHeatOrMoistureUseCheckbox(
          x.name,
          load.properties.heatProperty,
          load.properties.moistureProperty
        )
    );
  } else {
    return propertyDefinitions.filter(x => {
      return (
        load.properties.loadProperties.includes(x.name) &&
        !isHeatOrMoistureUseCheckbox(
          x.name,
          load.properties.heatProperty,
          load.properties.moistureProperty
        )
      );
    });
  }
}

function isHeatOrMoistureUseCheckbox(
  propertyName: string,
  heatProperty: string | undefined,
  moistureProperty: string | undefined
) {
  return !!(
    (heatProperty && propertyName === heatProperty) ||
    (moistureProperty && propertyName === moistureProperty)
  );
}
