import * as Types from "./types";
import * as GraphQLTypes from "../graphql-types";
import * as Parsing from "./parsing";

export function MapGraphQlPricingArticles(
  pricingArticles: ReadonlyArray<
    GraphQLTypes.PricingEditorUserQuery["user"]["pricingArticles"][0]
  >
): ReadonlyArray<Types.PricingArticle> {
  return pricingArticles.map(
    pa =>
      ({
        id: pa.id,
        salesOrganisationId: pa.salesOrganisationId,
        articleNo: pa.articleNo,
        description: pa.description,
        quantity: pa.quantity,
        unit: pa.unit,
        costPerUnit: pa.costPerUnit,
        transferPricePerUnit: pa.transferPricePerUnit,
        listPricePerUnit: pa.listPricePerUnit,
        sortNo: pa.sortNo,
        currencyCode: pa.currencyCode,
        templateGroup: pa.templateGroup ? pa.templateGroup : undefined
      } as Types.PricingArticle)
  );
}

export function mapGraphQlPricing(
  pricing: NonNullable<
    GraphQLTypes.PricingEditorUserQuery["user"]["pricingByPricingNo"]
  >
): Types.Pricing {
  return {
    id: pricing.id,
    labels: pricing.pricingFile.labels,
    pricingFileId: pricing.pricingFile.id,
    pricingFileName: pricing.pricingFile.name,
    pricingNo: pricing.pricingFile.pricingNo,
    revisionNo: pricing.revisionNo,
    comment: pricing.comment || undefined,
    status: pricing.status,
    currencyCode: pricing.currencyCode,
    ownerName: pricing.owner.name,
    ownerUserName: pricing.pricingFile.owner.userName,
    pricingFileOwnerId: pricing.pricingFile.owner.id,
    pricingFileOwnerUsername: pricing.pricingFile.owner.userName,
    exchangeRateTemplateId: pricing.exchangeRateTemplateId
      ? pricing.exchangeRateTemplateId
      : undefined,
    masterMode: pricing.masterMode,
    salesOrganisationId: pricing.salesOrganisationId || undefined,
    masterSalesOrganisationId: pricing.masterSalesOrganisationId || undefined,
    name: pricing.name,
    rows: pricing.pricingRows.map(r => ({
      id: r.id,
      type: r.type as Types.PricingRowType,
      visualizerCode: r.visualizerCode,
      rowNo: r.rowNo,
      articleNo: r.articleNo,
      description: r.description,
      pid: Parsing.parseNullableString(r.pid),
      quantity: r.quantity,
      unit: r.unit,
      systemId: r.systemId ? r.systemId : undefined,
      systemTypeId: r.systemTypeId ? r.systemTypeId : undefined,
      costPerUnit: Parsing.parseCostPricePerUnit(
        r.costPerUnit,
        r.type as Types.PricingRowType,
        r.systemTypeId
      ),
      listPricePerUnit: Parsing.parseNullableNumber(r.listPricePerUnit),
      transferPricePerUnit: Parsing.parseNullableNumber(r.transferPricePerUnit),
      masterPricePerUnit: Parsing.parseNullableNumber(r.masterPricePerUnit),
      listPriceFactor: undefined,
      transferPriceFactor: Parsing.parseTransferPriceFactor(
        false,
        r.costPerUnit,
        r.transferPricePerUnit,
        r.masterPricePerUnit,
        r.type as Types.PricingRowType,
        r.systemTypeId
      ),
      masterPriceFactor: Parsing.parseTransferPriceFactor(
        true,
        r.costPerUnit,
        r.transferPricePerUnit,
        r.masterPricePerUnit,
        r.type as Types.PricingRowType,
        r.systemTypeId
      )
    }))
  };
}

export function mapCellChangeToRow(
  cell: Types.EditCell,
  row: Types.PricingRowState,
  newText: string,
  masterMode: boolean
): Types.PricingRowState {
  const parsedNumber = parseFloat(newText);
  const newNumber = (defaultNumber: number | undefined) =>
    !Number.isNaN(parsedNumber) ? parsedNumber : defaultNumber;

  switch (cell.cell.type) {
    case "RowNo":
      return {
        ...row,
        rowNo: newNumber(row.rowNo)!
      };
    case "Description":
      return {
        ...row,
        description: newText
      };
    case "Quantity":
      return {
        ...row,
        quantity: newNumber(row.quantity)!
      };
    case "CostPerUnit":
      const costPerUnit = newNumber(row.costPerUnit);
      return {
        ...row,
        costPerUnit: costPerUnit,
        transferPricePerUnit:
          costPerUnit !== undefined
            ? costPerUnit * row.transferPriceFactor!
            : row.transferPricePerUnit,
        masterPricePerUnit:
          costPerUnit !== undefined
            ? costPerUnit * row.masterPriceFactor!
            : row.transferPricePerUnit
      };
    case "TransferPriceFactor":
      if (masterMode && row.type === "system") {
        const newFactor = newNumber(row.masterPriceFactor);
        return {
          ...row,
          masterPriceFactor: newFactor,
          masterPricePerUnit:
            newFactor !== undefined
              ? row.costPerUnit! * newFactor
              : row.masterPricePerUnit
        };
      }
      const newTransferPriceFactor = newNumber(row.transferPriceFactor);
      return {
        ...row,
        transferPriceFactor: newTransferPriceFactor,
        transferPricePerUnit:
          newTransferPriceFactor !== undefined
            ? row.costPerUnit! * newTransferPriceFactor
            : row.transferPricePerUnit
      };

    case "ListPriceFactor": {
      if (masterMode && row.type === "system") {
        const newFactor = newNumber(row.masterPriceFactor);
        return {
          ...row,
          masterPriceFactor: newFactor,
          masterPricePerUnit:
            newFactor !== undefined
              ? row.transferPricePerUnit! * newFactor
              : row.masterPricePerUnit
        };
      }
      const newListPriceFactor = newNumber(row.listPricePerUnit);
      return {
        ...row,
        listPriceFactor: newListPriceFactor,
        listPricePerUnit:
          newListPriceFactor !== undefined
            ? row.transferPricePerUnit! * newListPriceFactor
            : row.listPricePerUnit
      };
    }

    case "TransferPricePerUnit":
      if (masterMode) {
        const masterPricePerUnit = newNumber(row.masterPricePerUnit);
        return {
          ...row,
          masterPricePerUnit: masterPricePerUnit,
          masterPriceFactor:
            masterPricePerUnit !== undefined
              ? masterPricePerUnit / row.costPerUnit!
              : row.transferPriceFactor
        };
      }
      const transferPricePerUnit = newNumber(row.transferPricePerUnit);
      return {
        ...row,
        transferPricePerUnit: newNumber(row.transferPricePerUnit),
        transferPriceFactor:
          transferPricePerUnit !== undefined
            ? transferPricePerUnit / row.costPerUnit!
            : row.transferPriceFactor
      };

    case "ListPricePerUnit":
      if (masterMode) {
        const masterPricePerUnit = newNumber(row.masterPricePerUnit);
        return {
          ...row,
          masterPricePerUnit: masterPricePerUnit,
          masterPriceFactor:
            masterPricePerUnit !== undefined
              ? masterPricePerUnit / row.transferPricePerUnit!
              : row.listPriceFactor
        };
      }
      const listPricePerUnit = newNumber(row.listPricePerUnit);
      return {
        ...row,
        listPricePerUnit: listPricePerUnit,
        listPriceFactor:
          listPricePerUnit !== undefined
            ? listPricePerUnit / row.transferPricePerUnit!
            : row.listPriceFactor
      };

    default:
      return row;
  }
}

export function mapToSaveParams(
  pricing: Types.Pricing
): GraphQLTypes.UpdatePricingVariables {
  return {
    pricing: {
      id: pricing.id,
      comment: pricing.comment,
      currencyCode: pricing.currencyCode,
      masterMode: pricing.masterMode,
      salesOrganisationId: pricing.salesOrganisationId,
      masterSalesOrganisationId: pricing.masterSalesOrganisationId,
      name: pricing.name,
      ownerUserName: pricing.ownerUserName,
      status: pricing.status,
      rows: pricing.rows.map(r => ({
        id: r.id,
        pricingId: pricing.id,
        articleNo: r.articleNo,
        description: r.description,
        transferPricePerUnit: r.transferPricePerUnit,
        listPricePerUnit: r.listPricePerUnit,
        masterPricePerUnit: r.masterPricePerUnit,
        systemId: r.systemId ? r.systemId : null,
        rowNo: r.rowNo,
        type: r.type,
        visualizerCode: r.visualizerCode,
        quantity: r.quantity,
        unit: r.unit,
        costPerUnit: r.costPerUnit,
        pid: r.pid
      }))
    }
  };
}
