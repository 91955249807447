import * as React from "react";

export interface Props {
  readonly children: JSX.Element;
  readonly onClick: () => void;
}

interface State {}

export class Component extends React.Component<Props, State> {
  node: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);
  }

  onClick = (e: any) => {
    if (!this.node || !this.node.contains(e.target)) {
      this.props.onClick();
    }
  };

  componentDidMount() {
    window.addEventListener("click", this.onClick);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.onClick);
  }

  render(): JSX.Element {
    return <div ref={ref => (this.node = ref)}>{this.props.children}</div>;
  }
}
