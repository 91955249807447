import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type H2Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledH2 = styled.h2<H2Props>`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.333rem;
  letter-spacing: 0;
  ${({ weight = "bold", color = "primary" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function H2(props: H2Props): JSX.Element {
  return <StyledH2 {...props} />;
}
