import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import { queryUser, queryProduct } from "./queries";
import * as PricingActions from "../pricing-actions";
import * as Guid from "@genesys/shared/lib/guid";
import { SearchParams, Modal } from "./types";
import { deletePricingFilesMutation } from "@genesys/client-core/lib/graphql-mutations";
import { LabelInputState } from "../shared-manager-components";

export const itemsPerPage = 16;

export type State = {
  readonly userQueryResult: GraphQLTypes.PricingManagerUserQuery | undefined;
  readonly productQueryResult:
    | GraphQLTypes.PricingManagerProductQuery
    | undefined;
  readonly expandedRevisions: ReadonlyArray<string>;
  readonly openPricingActions: string | undefined;
  readonly pricingActionsState: PricingActions.State | undefined;
  readonly selectedPricingIds: ReadonlyArray<string>;
  readonly modal: Modal | undefined;
  readonly currentPage: number;
  readonly searchParams: SearchParams;
  readonly isSearchFilterOptionsOpen: boolean;
  readonly isHelpModalOpen: boolean;
  readonly openLabelPopup: string | undefined;
  readonly labelInputState: LabelInputState;
};

export const init = (
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      userQueryResult: undefined,
      productQueryResult: undefined,
      expandedRevisions: [],
      openPricingActions: undefined,
      openLabelPopup: undefined,
      selectedPricingIds: [],
      modal: undefined,

      pricingActionsState: undefined,
      currentPage: 1,
      searchParams: {
        scope: GraphQLTypes.PricingSearchScopeEnum.MY,
        searchKey: "",
        orderByColumn: {
          isDescending: true,
          name: "date-changed"
        }
      },
      isSearchFilterOptionsOpen: false,
      isHelpModalOpen: false,
      labelInputState: undefined
    },
    Cmd.batch<Action>([
      sharedState.graphQL.queryUserCmd<
        GraphQLTypes.PricingManagerUserQuery,
        GraphQLTypes.PricingManagerUserQueryVariables,
        Action
      >(
        queryUser,
        {
          filter: {
            startRow: 0,
            endRow: itemsPerPage,
            searchKey: "",
            searchScope: GraphQLTypes.PricingSearchScopeEnum.MY,
            orderByColumn: {
              isDescending: true,
              name: "date-changed"
            }
          }
        },
        Action.userQueryDataReceived
      ),
      sharedState.graphQL.queryProductCmd<
        GraphQLTypes.PricingManagerProductQuery,
        GraphQLTypes.PricingManagerProductQueryVariables,
        Action
      >(queryProduct, {}, Action.productQueryDataReceived)
    ]),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  clearSearchFilter: () => ({}),
  createLabel: (labelName: string) => ({ labelName }),
  closeModal: () => ({}),
  changePage: (pageNumber: number) => ({ pageNumber }),
  deletePricings: (pricingFileIds: ReadonlyArray<string>) => ({
    pricingFileIds
  }),
  deleteLabel: (labelId: string) => ({ labelId }),
  dispatchPricingActions: (action: PricingActions.Action) => ({ action }),
  setSearchParams: (searchParams: SearchParams) => ({ searchParams }),
  setSelectedPricings: (selectedPricings: ReadonlyArray<string>) => ({
    selectedPricings
  }),
  search: (messageOnDone?: SharedState.AlertMessage) => ({ messageOnDone }),

  setLabelInputState: (state: LabelInputState) => ({ state }),

  openModal: (modal: Modal) => ({ modal }),
  productQueryDataReceived: (
    data: GraphQLTypes.PricingManagerProductQuery
  ) => ({ data }),
  toggleIsSearchFilterOptionsOpen: (isOpen: boolean) => ({ isOpen }),
  toggleRevision: (resultId: string) => ({ resultId }),
  toggleOpenPricingActions: (id: string) => ({ id }),
  toggleHelpModal: () => ({}),
  toggleSelectedPricings: (pricingId: string) => ({ pricingId }),
  toggleOpenLabelPopup: (labelId: string | undefined) => ({ labelId }),
  updateLabel: (labelId: string, labelName: string) => ({ labelId, labelName }),
  userQueryDataReceived: (data: GraphQLTypes.PricingManagerUserQuery) => ({
    data
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "changePage": {
      return [
        {
          ...state,
          currentPage: action.pageNumber,
          userQueryResult: undefined
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.PricingManagerUserQuery,
          GraphQLTypes.PricingManagerUserQueryVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow: action.pageNumber * itemsPerPage - itemsPerPage,
              endRow: action.pageNumber * itemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumn: state.searchParams.orderByColumn
            }
          },
          Action.userQueryDataReceived
        )
      ];
    }

    case "closeModal": {
      return [{ ...state, modal: undefined }];
    }

    case "clearSearchFilter": {
      return [
        {
          ...state,
          searchParams: {
            ...state.searchParams,
            searchKey: ""
          }
        }
      ];
    }

    case "createLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [
          SharedState.Action.createLabel(
            Guid.guidToString(Guid.createGuid()),
            action.labelName
          )
        ]
      ];
    }

    case "deleteLabel": {
      return [
        state,
        undefined,
        [SharedState.Action.deleteLabel(action.labelId)]
      ];
    }

    case "dispatchPricingActions": {
      if (!state.pricingActionsState) {
        return [state];
      }

      const [
        pricingActionsState,
        pricingActionsCmd,
        sharedStateActions,
        isDone
      ] = PricingActions.update(
        action.action,
        state.pricingActionsState,
        sharedState
      );

      if (isDone) {
        return [
          {
            ...state,
            userQueryResult: undefined,
            currentPage: 1,
            openPricingActions: undefined,
            pricingActionsState: undefined
          },
          Cmd.batch<Action>([
            Cmd.map(Action.dispatchPricingActions, pricingActionsCmd),
            sharedState.graphQL.queryUserCmd<
              GraphQLTypes.PricingManagerUserQuery,
              GraphQLTypes.PricingManagerUserQueryVariables,
              Action
            >(
              queryUser,
              {
                filter: {
                  startRow: 0,
                  endRow: itemsPerPage,
                  searchKey: state.searchParams.searchKey,
                  searchScope: state.searchParams.scope,
                  orderByColumn: state.searchParams.orderByColumn
                }
              },
              Action.userQueryDataReceived
            )
          ]),
          sharedStateActions
        ];
      } else {
        return [
          {
            ...state,
            pricingActionsState: pricingActionsState
          },
          Cmd.map(Action.dispatchPricingActions, pricingActionsCmd),
          sharedStateActions
        ];
      }
    }

    case "deletePricings": {
      return [
        { ...state, selectedPricingIds: [], modal: undefined },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.DeletePricingFiles,
          GraphQLTypes.DeletePricingFilesVariables,
          Action
        >(
          deletePricingFilesMutation,
          {
            pricingFileIds: action.pricingFileIds
          },
          data =>
            Action.search(
              data.deletePricingFiles
                ? {
                    content: "Pricing(s) Succesfully deleted",
                    messageType: "success",
                    timeout: 2000
                  }
                : {
                    content: "Deletion failed",
                    messageType: "error",
                    timeout: 10000
                  }
            )
        )
      ];
    }

    case "openModal": {
      return [
        {
          ...state,
          modal: action.modal
        }
      ];
    }

    case "productQueryDataReceived": {
      return [{ ...state, productQueryResult: action.data }];
    }
    case "setSearchParams": {
      return [{ ...state, searchParams: action.searchParams }];
    }
    case "setSelectedPricings": {
      return [
        {
          ...state,
          selectedPricingIds: action.selectedPricings
        }
      ];
    }
    case "search": {
      return [
        {
          ...state,
          userQueryResult: undefined,
          currentPage: 1,
          expandedRevisions: []
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.PricingManagerUserQuery,
          GraphQLTypes.PricingManagerUserQueryVariables,
          Action
        >(
          queryUser,
          {
            filter: {
              startRow: 0,
              endRow: itemsPerPage,
              searchKey: state.searchParams.searchKey,
              searchScope: state.searchParams.scope,
              orderByColumn: state.searchParams.orderByColumn
            }
          },
          Action.userQueryDataReceived
        ),
        [
          action.messageOnDone
            ? SharedState.Action.addAlertMessageToQueue(action.messageOnDone)
            : undefined
        ]
      ];
    }

    case "setLabelInputState": {
      return [{ ...state, labelInputState: action.state }];
    }
    case "toggleIsSearchFilterOptionsOpen": {
      return [
        {
          ...state,
          isSearchFilterOptionsOpen: action.isOpen
        }
      ];
    }

    case "toggleRevision": {
      const newExpandedRevisions = state.expandedRevisions.includes(
        action.resultId
      )
        ? state.expandedRevisions.filter(rev => rev !== action.resultId)
        : state.expandedRevisions.concat(action.resultId);

      return [{ ...state, expandedRevisions: newExpandedRevisions }];
    }
    case "toggleOpenPricingActions": {
      let newPricingActionsState: PricingActions.State | undefined =
        state.pricingActionsState;

      if (action.id && action.id !== state.openPricingActions) {
        const [pricingActionsState] = PricingActions.init();
        newPricingActionsState = pricingActionsState;
      }

      return [
        {
          ...state,
          openPricingActions: action.id,
          pricingActionsState: newPricingActionsState
        }
      ];
    }

    case "toggleHelpModal": {
      return [{ ...state, isHelpModalOpen: !state.isHelpModalOpen }];
    }

    case "toggleSelectedPricings": {
      const newSelectedPricingIds = state.selectedPricingIds.includes(
        action.pricingId
      )
        ? state.selectedPricingIds.filter(id => id !== action.pricingId)
        : state.selectedPricingIds.concat(action.pricingId);

      return [
        {
          ...state,
          selectedPricingIds: newSelectedPricingIds
        }
      ];
    }

    case "toggleOpenLabelPopup": {
      return [{ ...state, openLabelPopup: action.labelId }];
    }

    case "userQueryDataReceived": {
      const getSearchFilterOptionsFromSearchKey = (searchKey: string) => {
        const options: Record<string, string> = {};

        const regex = /(\w+):"([^"]+)"/g;
        let match;
        // tslint:disable-next-line
        while ((match = regex.exec(searchKey)) !== null) {
          const [, key, value] = match;
          options[key] = value.trim();
        }

        return options;
      };

      const searchFilterOptions = getSearchFilterOptionsFromSearchKey(
        state.searchParams.searchKey
      );

      if (
        // making revisions expanded when searching for a specific pricing revision
        searchFilterOptions["pno"] !== undefined &&
        searchFilterOptions["pno"].includes("-") &&
        action.data.user.searchPricings.totalResults
      ) {
        const pricingId = action.data.user.searchPricings.results[0].id;

        return [
          {
            ...state,
            userQueryResult: action.data,
            expandedRevisions: [pricingId]
          }
        ];
      }
      return [{ ...state, userQueryResult: action.data }];
    }

    case "updateLabel": {
      return [
        {
          ...state,
          labelInputState: undefined
        },
        undefined,
        [SharedState.Action.updateLabel(action.labelId, action.labelName)]
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
