import pathToRegexp from "path-to-regexp";
import { PathParams } from "./url-parser";

export type UrlBuilder<TLocation> = (location: TLocation) => string;

export type ParamsFromLocation<TLocation> = (location: TLocation) => PathParams;

export function createUrlBuilder<TLocation>(
  path: string,
  paramsFromLocation: ParamsFromLocation<TLocation>
): UrlBuilder<TLocation> {
  const toUrl = pathToRegexp.compile(path);
  return (location: TLocation) => toUrl(paramsFromLocation(location));
}

// export function combineUrlBuilders<TLocation>(urlBuilders: readonly UrlBuilder<TLocation>[]): UrlBuilder<TLocation> {
//   return (location: TLocation) => {
//     let totalUrl = "";
//     for (const ub of urlBuilders) {
//       totalUrl += ub(location as TLocation);
//     }
//     return totalUrl;
//   };
// }
