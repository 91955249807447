import * as React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";
import { StandardButton, H2, P1 } from "@genesys/ui-elements/";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { clientConfig, getModalBorderColor } from "../../config";

export interface MessageDialogProps {
  readonly title: LanguageTexts.TextDefinition;
  readonly message: LanguageTexts.TextDefinition;
  readonly onClose: () => void;
  readonly onConfirm?: () => void;
  readonly translate: LanguageTexts.Translate;
}

const ButtonsContainer = styled.div`
  margin: 0 0 20px 40px;
  button {
    margin-right: 20px;
  }
`;

export function GenericDialog({
  title,
  message,
  translate,
  onClose,
  onConfirm
}: MessageDialogProps): JSX.Element {
  return (
    <Modal maxWidth={500} onClose={() => onClose()}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <H2 color="dark" weight="normal">
          {translate(title)}
        </H2>
      </ModalHeader>
      <ModalContent>
        <P1 color="dark" weight="normal">
          {translate(message)}
        </P1>
      </ModalContent>
      <ButtonsContainer>
        <StandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={() => onClose()}
        >
          {!onConfirm
            ? translate(LanguageTexts.ok())
            : translate(LanguageTexts.cancel())}
        </StandardButton>
        {onConfirm && (
          <StandardButton
            buttonType="Primary-2"
            size="Small"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {translate(LanguageTexts.confirm())}
          </StandardButton>
        )}
      </ButtonsContainer>
    </Modal>
  );
}
