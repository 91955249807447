import * as OidcClient from "oidc-client";
import * as VersionChecker from "../../version-checker";
import * as GQLTypes from "../../graphql-types";
import * as Navigation from "../../navigation-effect-manager";
import * as OidcClientEffectManager from "../../oidc-client-effect-manager";
import { Cmd } from "@typescript-tea/core";
import { getGraphQLUser, getGraphQLProduct } from "../../effects";
import { productQuery, userQuery } from "../queries";
import { promiseCmd } from "../../promise-effect-manager";
import { State } from "./types";
import { Action } from "./actions";

export const init = ({
  accessToken,
  url,
  userManagerSettings
}: {
  readonly accessToken: string;
  readonly url: Navigation.Url;
  readonly userManagerSettings: OidcClient.UserManagerSettings;
}): readonly [State, Cmd<Action> | undefined] => {
  const [versionCheckerState, versionCheckerCmds] = VersionChecker.init();
  return [
    {
      accessToken,
      url,
      mainState: undefined,
      selectedLang: "en-US",
      alertMessagesQueue: [],
      initialSettingsState: undefined,
      versionCheckerState: versionCheckerState,
      showInitialSettings: false,
      user: undefined,
      translationTexts: undefined,
      queriesLoading: [],
      fieldDefaults: undefined,
      quantityDefaults: undefined,
      genesysPrefixes: undefined,
      debugSettings: {
        includeServerLog: false,
        showTextsWithTextId: false,
        showTextsDB: false,
        showHiddenProperties: false,
        showHiddenResults: false,
        useCalculationWorkers: true
      },
      routes: undefined,
      termsAndConditionsState: undefined
    },
    Cmd.batch<Action>([
      promiseCmd<
        Action,
        {
          readonly productResult: GQLTypes.RootProductQuery;
          readonly userResult: GQLTypes.RootUserQuery;
        }
      >(
        async () => {
          const productResult = await getGraphQLProduct<
            GQLTypes.RootProductQuery,
            GQLTypes.RootProductQueryVariables
          >(accessToken, productQuery, {}, false);
          const userResult = await getGraphQLUser<
            GQLTypes.RootUserQuery,
            GQLTypes.RootUserQueryVariables
          >(accessToken, userQuery, {}, false, false);
          return { productResult, userResult };
        },
        result =>
          Action.initialQueriesReceived(result.productResult, result.userResult)
      ),
      Cmd.map(Action.dispatchVersionChecker, versionCheckerCmds),
      OidcClientEffectManager.login(
        {
          ...userManagerSettings,
          authority: userManagerSettings.authority || "",
          client_id: userManagerSettings.client_id || "",
          scope: userManagerSettings.scope || "",
          redirect_uri: userManagerSettings.redirect_uri || "",
          filterProtocolClaims:
            userManagerSettings.filterProtocolClaims || false,
          automaticSilentRenew:
            userManagerSettings.automaticSilentRenew || false,
          monitorSession: userManagerSettings.monitorSession || false
        },
        {}
      )
    ])
  ];
};
