import gql from "graphql-tag";
import * as Fragments from "@genesys/shared/lib/graphql-fragments";

export const deleteMCompareComponentMutation = gql`
  mutation deleteMCompareComponent($input: DeleteMCompareComponentInputType!) {
    deleteMCompareComponent(input: $input) {
      id
      cachePatches {
        ...CachePatchFragment
      }
    }
  }
  ${Fragments.CachePatchFragment}
`;
