import React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Label, P2, P1 } from "@genesys/ui-elements";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Skeleton from "@mui/material/Skeleton";
import {
  ArrowUpBlue,
  ArrowDownBlue,
  MenuDots,
  FavoriteActive,
  ImageDisplayer,
  FavoriteInactive
} from "@genesys/ui-elements";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as StatusIcon from "../status-icon-manager";
import * as SystemActions from "../system-actions";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { clientConfig } from "../config";
import * as SharedState from "../shared-state";
import {
  SystemImage,
  SystemActionsContainer,
  SkeletonContainer,
  IconsContainer,
  RevisionContainer,
  RevisionOptionsContainer,
  RevisionsDropDown,
  RevisionsDropDownButton,
  Root,
  ErrorCard,
  ErrorTextContainer,
  SkeletonIconsContainer,
  SkeletonSystemImage,
  SystemContainer,
  TextContainer,
  TextsContainer,
  SystemsLabel
} from "./elements";
import * as SystemStatusText from "./system-status";

export function View(props: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly plenumSizeProperty: string;
  readonly recentSystem: SharedState.BreifSystem;
  readonly dispatch: Dispatch<Action>;
  readonly showErrorDetails: () => void;
}) {
  const {
    recentSystem,
    sharedState,
    state,
    plenumSizeProperty,
    dispatch,
    showErrorDetails
  } = props;

  if (recentSystem.type === "loading") {
    return <LoadingSkeleton />;
  }
  if (recentSystem.type === "error") {
    return <ErrorCardView showErrorDetails={showErrorDetails} />;
  }

  const effectiveRevision =
    state.userSelectedRevision || recentSystem.system.id;
  const effectiveSystem = recentSystem.system.systems.find(
    s => s.id === effectiveRevision
  )!;
  const isFavorite = sharedState.user.favoritesSystems.some(fs =>
    fs.type === "loaded"
      ? fs.system.id === effectiveSystem.id
      : fs.id === effectiveSystem.id
  );
  const revisionOptions = getRevisionOptions(
    recentSystem,
    sharedState
    // effectiveSystem
  );

  return (
    <Root>
      <Icons
        effectiveSystem={effectiveSystem}
        isFavorite={isFavorite}
        dispatch={dispatch}
        state={state}
        sharedState={sharedState}
        recentSystem={recentSystem}
      />
      <SystemInfo
        recentSystem={recentSystem}
        effectiveSystem={effectiveSystem}
        sharedState={sharedState}
        plenumSizeProperty={plenumSizeProperty}
        state={state}
      />
      <RevisionDropdown
        revisionOptions={revisionOptions}
        state={state}
        dispatch={dispatch}
        sharedState={sharedState}
      />
    </Root>
  );
}

// Subcomponent for the loading skeleton
const LoadingSkeleton = () => (
  <SkeletonContainer>
    <SkeletonIconsContainer>
      <Skeleton variant="rectangular" width={87} height={30} />
    </SkeletonIconsContainer>
    <SkeletonSystemImage>
      <Skeleton variant="rectangular" width={161} height={114} />
    </SkeletonSystemImage>
    <Skeleton variant="rectangular" width={248} height={66} />
    <Skeleton
      style={{ marginTop: "7px" }}
      variant="rectangular"
      width={248}
      height={33}
    />
  </SkeletonContainer>
);

// Subcomponent for the error card
const ErrorCardView = ({
  showErrorDetails
}: {
  readonly showErrorDetails: () => void;
}) => (
  <ErrorCard onClick={showErrorDetails}>
    <ErrorTextContainer>
      <P1 color="white" weight="bold">
        Unable to load system
      </P1>
    </ErrorTextContainer>
  </ErrorCard>
);

// Subcomponent for icons (favorite, status, and menu actions)
const Icons = ({
  effectiveSystem,
  isFavorite,
  state,
  sharedState,
  recentSystem,
  dispatch
}: {
  readonly effectiveSystem: SharedState.BriefSystemInfoRevision;
  readonly isFavorite: boolean;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly recentSystem: SharedState.BriefSystemLoaded;
  readonly dispatch: Dispatch<Action>;
}) => {
  const onToggleIsFavorite = () =>
    dispatch(Action.toggleFavorites(effectiveSystem.id));
  const openSystemActions = (e: any) => {
    if (!state.systemActionsState) {
      const coordinates = {
        x: e.target.getBoundingClientRect().x,
        y: e.target.getBoundingClientRect().y
      };
      dispatch(Action.openSystemActions(coordinates));
    } else {
      return;
    }
  };
  const closeSystemActions = () => {
    if (
      state.systemActionsState &&
      SystemActions.isInMenu(state.systemActionsState.state)
    ) {
      dispatch(Action.closeSystemActions());
    } else {
      return;
    }
  };

  return (
    <IconsContainer>
      <div onClick={onToggleIsFavorite}>
        {isFavorite ? (
          <FavoriteActive height="1.2rem" />
        ) : (
          <FavoriteInactive height="1.2rem" />
        )}
      </div>
      <div>
        <StatusIcon.StatusIconManager
          sharedState={sharedState}
          statusNumber={effectiveSystem.status}
          height="21.81px"
          width="21.81px"
        />
      </div>
      <div onMouseEnter={openSystemActions} onMouseLeave={closeSystemActions}>
        <MenuDots height="1.4rem" />
        {state.systemActionsState && (
          <SystemActionsMenu
            state={state}
            dispatch={dispatch}
            recentSystem={recentSystem}
            sharedState={sharedState}
          />
        )}
      </div>
    </IconsContainer>
  );
};

const SystemActionsMenu = ({
  state,
  recentSystem,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly recentSystem: SharedState.BriefSystemLoaded;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) => {
  return (
    <SystemActionsContainer
      x={state.systemActionsState!.coordinates?.x || 0}
      y={state.systemActionsState!.coordinates?.y || 0}
    >
      <SystemActions.SystemActionsView
        dispatch={Dispatch.map(Action.dispatchSystemActions, dispatch)}
        state={state.systemActionsState!.state}
        systemActions={SystemActions.createActions(
          {
            dispatch: Dispatch.map(Action.dispatchSystemActions, dispatch),
            sharedState,
            state: state.systemActionsState!.state,
            systemFile: {
              id: recentSystem.system.systemFileId,
              name: recentSystem.system.name,
              labels: recentSystem.system.labels,
              owner: recentSystem.system.owner,
              systems: recentSystem.system.systems,
              genesysNo: recentSystem.system.genesysNo
            }
          },
          [
            SystemActions.openInNewTabAction,
            SystemActions.renameAction,
            SystemActions.assignLabelsAction,
            SystemActions.createNewRevisionAction,
            SystemActions.transferToUserAction,
            SystemActions.copyTransferOpenAction
          ]
        )}
      />
    </SystemActionsContainer>
  );
};

// Subcomponent for system information display
const SystemInfo = ({
  recentSystem,
  effectiveSystem,
  sharedState,
  plenumSizeProperty,
  state
}: {
  readonly recentSystem: SharedState.BriefSystemLoaded;
  readonly effectiveSystem: SharedState.BriefSystemInfoRevision;
  readonly sharedState: SharedState.State;
  readonly plenumSizeProperty: string;
  readonly state: State;
}) => {
  const genesysNo = sharedState.genesysPrefix.genesysNo(
    recentSystem.system.genesysNo,
    effectiveSystem.revisionNo
  );
  const formatDate = (date: number) =>
    DateHelper.getDateString(
      DateHelper.fromUTC(date),
      sharedState.user.settings.locale
    );

  return (
    <SystemContainer>
      <a href={`/system/${genesysNo}`}>
        <ImageDisplayer
          routeEndpoint={clientConfig.genesysBackend}
          accessToken={sharedState.accessToken}
          effectiveSystemId={effectiveSystem.id}
          margin={{ marginLeft: "-10px" }}
        >
          <SystemImage
            accessToken={sharedState.accessToken}
            src={clientConfig.getWizardImageUrl(recentSystem.system.id)}
          />
        </ImageDisplayer>

        <TextsContainer>
          <TextContainer>
            <SystemsLabel weight="bold" color="dark">
              {recentSystem.system.systemTypeId} {genesysNo}
            </SystemsLabel>
          </TextContainer>
          <TextContainer>
            <P2 weight="normal" color="dark">
              {recentSystem.system.name}
            </P2>
          </TextContainer>
          <TextContainer>
            <P2 weight="normal" color="secondary">
              {sharedState.translate(LanguageTexts.lastChange())}{" "}
              {formatDate(effectiveSystem.statusDate)}
            </P2>
          </TextContainer>
          <TextContainer>
            <P2 weight="normal" color="secondary">
              {effectiveSystem.salesOrganisationId}
            </P2>
            <SystemStatusText.View
              plenumSizeProperty={plenumSizeProperty}
              systemTypeId={recentSystem.system.systemTypeId}
              sharedState={sharedState}
              state={state.systemStatusTextState}
            />
          </TextContainer>
        </TextsContainer>
      </a>
    </SystemContainer>
  );
};

// Subcomponent for revision dropdown
const RevisionDropdown = ({
  revisionOptions,
  state,
  sharedState,
  dispatch
}: {
  readonly revisionOptions: {
    readonly systemId: string;
    readonly revisionNo: string;
    readonly comment?: string | null;
    readonly statusDate: string;
  }[];
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
}) => {
  const onToggleRevisionDropdown = () =>
    dispatch(Action.toggleOpenRevisonDropdown());
  const onChangeActiveRevision = (systemId: string) =>
    dispatch(Action.changeActiveRevision(systemId));

  const renderArrow = () => {
    const arrowHeight = "0.4rem";
    return state.isRevisionDropdownOpen ? (
      <ArrowUpBlue height={arrowHeight} />
    ) : (
      <ArrowDownBlue height={arrowHeight} />
    );
  };
  return (
    <RevisionContainer>
      <Label weight="normal" color="secondary">
        {sharedState.translate(LanguageTexts.revisions()).toUpperCase()}
      </Label>
      <RevisionsDropDownButton onClick={onToggleRevisionDropdown}>
        <Label weight="normal" color="secondary">
          {revisionOptions.length}
        </Label>
        {renderArrow()}
      </RevisionsDropDownButton>

      {state.isRevisionDropdownOpen && (
        <ClickAwayListener onClickAway={onToggleRevisionDropdown}>
          <RevisionsDropDown>
            <RevisionOptionsContainer>
              {revisionOptions.map(r => (
                <button
                  key={r.systemId}
                  onClick={() => onChangeActiveRevision(r.systemId)}
                >
                  <Label weight="bold" color="dark">
                    {r.revisionNo} &nbsp; {r.comment}
                  </Label>
                  <Label weight="bold" color="dark">
                    {r.statusDate}
                  </Label>
                </button>
              ))}
            </RevisionOptionsContainer>
          </RevisionsDropDown>
        </ClickAwayListener>
      )}
    </RevisionContainer>
  );
};

// Helper function to get revision options
const getRevisionOptions = (
  recentSystem: SharedState.BriefSystemLoaded,
  sharedState: SharedState.State
  // effectiveSystem: any
) => {
  return recentSystem.system.systems.map(s => ({
    systemId: s.id,
    revisionNo: s.revisionNo.toString(),
    comment: s.comment,
    statusDate: DateHelper.getDateString(
      DateHelper.fromUTC(s.statusDate),
      sharedState.user.settings.locale
    )
  }));
};
