import { zIndex } from "@genesys/ui-elements";
import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px 20px 0;
  background-color: inherit;

  overflow: auto;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
`;

export const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 30px 0 0 40px;

  > button {
    margin-left: 30px;
  }
`;

export const ClimateSelectorContainer = styled.div`
  width: 100%;
  padding: 15px 50px;
`;

export const CasesContainer = styled.div`
  width: 100%;
  display: flex;
  margin-left: 40px;
  background-color: inherit;

  overflow: auto;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  min-height: 467px;
  margin: 0 20px 20px 0;
  white-space: nowrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: white;

  h3 {
    color: white;
  }
`;

export const PropertyNamesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  min-height: 467px;
  margin-right: 20px;
  white-space: nowrap;
  z-index: ${zIndex.OperatingCasePropertyNames};
  background-color: inherit;
`;

export const PropertiesSelectorJsxContainer = styled.div`
  position: relative;
  padding-bottom: 80px;

  table {
    min-width: 238px;
    tbody {
      tr:nth-child(1) {
        td {
          height: 0;
          h3 {
            display: none;
          }
        }
      }
      tr {
        td {
          height: 30px;
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 3 : 1}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 155px;
          max-width: 155px;
          padding-left: 10px;

          > div {
            max-width: 155px;
          }
        }

        td:nth-child(${(props: { readonly isFirstCard: boolean }) =>
              props.isFirstCard ? 4 : 2}) {
          display: ${(props: { readonly isFirstCard: boolean }) =>
            props.isFirstCard ? "none" : "static"};
          width: 58px;
          padding: 0 10px 0 0;

          * {
            max-width: 58px;
          }

          div {
            div {
              div {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 50px;
  width: 100%;

  position: absolute;
  bottom: 0px;
  left: 0px;

  a {
    position: relative;
    right: 204px;

    img {
      cursor: pointer;
    }
  }
`;

export const AddNewCaseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 30px;
  margin: 28px;
  * {
    height: 30px;
  }
`;

export const MoistureLoadContainer = styled.div`
  margin-left: 40px;
  margin-top: 30px;
`;
