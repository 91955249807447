import { Cmd } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { moveComponentMutation } from "@genesys/client-core/lib/graphql-mutations";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import { ConfiguratorAction } from "../configurator-actions";
import { Action } from "../state";

export function moveComponent(
  sharedState: SharedState.State,
  systemId: string,
  action: Extract<ConfiguratorAction, { readonly type: "moveComponent" }>
): [Cmd<Action>, SystemStatus] {
  return [
    sharedState.graphQL.queryUserCmd<
      GraphQLTypes.MoveComponent,
      GraphQLTypes.MoveComponentVariables,
      Action
    >(
      moveComponentMutation,
      {
        input: {
          ...action.input,
          systemId
        }
      },
      () => Action.systemMutationComplete(undefined, undefined)
    ),
    SystemStatus.ValidateConfigurationSuccess
  ];
}
