import * as React from "react";
import {
  QuickSelectOptionCard,
  QuickSelectOptionCardFooter,
  Divider,
  QuickSelectOptionCardTitle,
  QuickSelectOptionCardImage,
  UpperText
} from "./elements";
import { S2, P2, CheckBox } from "@genesys/ui-elements";
import { clientConfig } from "../../../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface QuickSelectOptionProps {
  readonly name: string;
  readonly image: string;
  readonly onClick: () => void;
  readonly accessToken: string;
  readonly selected: boolean;
  readonly translate: LanguageTexts.Translate;
}

export function QuickSelectOption(props: QuickSelectOptionProps): JSX.Element {
  return (
    <QuickSelectOptionCard onClick={props.onClick}>
      <QuickSelectOptionCardImage
        accessToken={props.accessToken}
        src={clientConfig.genesysBackend + props.image}
      />
      <QuickSelectOptionCardTitle>
        <P2 weight="bold">{props.name}</P2>
      </QuickSelectOptionCardTitle>
      <Divider />
      <QuickSelectOptionCardFooter>
        <CheckBox
          children=""
          isChecked={props.selected}
          onClick={props.onClick}
        />
        <UpperText>
          <S2 weight="light">
            {props.translate(
              props.selected ? LanguageTexts.selected() : LanguageTexts.select()
            )}
          </S2>
        </UpperText>
      </QuickSelectOptionCardFooter>
    </QuickSelectOptionCard>
  );
}
