import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { Quantity, Amount, Unit } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import * as PropertiesSelector from "../../properties-selector";

export type State = {
  readonly airIn: PropertiesSelector.State;
  readonly airOut: PropertyValueSet.PropertyValueSet;
  readonly power: PropertyValueSet.PropertyValueSet;
};

export const init = (): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  return [
    {
      airIn: PropertiesSelector.init(
        PropertyValueSet.fromString(
          "temperature=20:Celsius;humidity=3:GramPerKilogram;pressure=101325:Pascal;flow=300:StandardCubicMeterPerHour"
        )
      ),
      airOut: PropertyValueSet.fromString(
        "temperature=10:Celsius;humidity=3:GramPerKilogram;pressure=101325:Pascal"
      ),
      power: PropertyValueSet.fromString("power=2000:Watt")
    }
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  onAirOutChange: (pvs: PropertyValueSet.PropertyValueSet) => ({
    pvs
  }),
  onPowerChange: (pvs: PropertyValueSet.PropertyValueSet) => ({
    pvs
  }),
  calculatePower: () => ({}),
  calculateAirOut: () => ({}),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const [
        propertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.airIn,
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        { ...state, airIn: propertiesSelectorState },
        Cmd.map(
          action => Action.dispatchPropertiesSelector(action),
          propertiesSelectorCmd
        ),
        [propertiesSelectorSharedAction]
      ];
    }
    case "onAirOutChange": {
      return [
        {
          ...state,
          airOut: action.pvs
        }
      ];
    }
    case "onPowerChange": {
      return [
        {
          ...state,
          power: action.pvs
        }
      ];
    }
    case "calculatePower": {
      const airInProperties = PropertiesSelector.getSelectedProperties(
        state.airIn
      );

      const temperatureIn = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperature",
        airInProperties
      ) as Amount.Amount<Quantity.Temperature>;
      const humidityIn = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "humidity",
        airInProperties
      ) as Amount.Amount<Quantity.HumidityRatio>;
      const pressureIn = PropertyValueSet.getAmount<Quantity.Pressure>(
        "pressure",
        airInProperties
      ) as Amount.Amount<Quantity.Pressure>;
      const flow = PropertyValueSet.getAmount<Quantity.MassFlow>(
        "flow",
        airInProperties
      ) as Amount.Amount<Quantity.MassFlow>;

      const temperatureOut = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperature",
        state.airOut
      ) as Amount.Amount<Quantity.Temperature>;
      const humidityOut = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "humidity",
        state.airOut
      ) as Amount.Amount<Quantity.HumidityRatio>;

      const power =
        Physics.AirCalculation.calculateAirTotalPowerForAirFlowAirInAirOut(
          temperatureIn,
          humidityIn,
          pressureIn,
          temperatureOut,
          humidityOut,
          pressureIn,
          flow
        );
      const finalPower = Amount.lessThan(temperatureOut, temperatureIn)
        ? Amount.neg(power)
        : power;

      return [
        {
          ...state,
          power: PropertyValueSet.fromProperty(
            "power",
            PropertyValue.fromAmount(finalPower)
          )
        }
      ];
    }
    case "calculateAirOut": {
      const airInProperties = PropertiesSelector.getSelectedProperties(
        state.airIn
      );

      const temperatureIn = PropertyValueSet.getAmount<Quantity.Temperature>(
        "temperature",
        airInProperties
      ) as Amount.Amount<Quantity.Temperature>;
      const humidityIn = PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        "humidity",
        airInProperties
      ) as Amount.Amount<Quantity.HumidityRatio>;
      const pressureIn = PropertyValueSet.getAmount<Quantity.Pressure>(
        "pressure",
        airInProperties
      ) as Amount.Amount<Quantity.Pressure>;
      const flow = PropertyValueSet.getAmount<Quantity.MassFlow>(
        "flow",
        airInProperties
      ) as Amount.Amount<Quantity.MassFlow>;
      const power = PropertyValueSet.getAmount<Quantity.Power>(
        "power",
        state.power
      ) as Amount.Amount<Quantity.Power>;

      const airOut =
        Physics.AirCalculation.applyTotalPowerAsEnthalpyChangeOnFlowingAir(
          temperatureIn,
          humidityIn,
          pressureIn,
          pressureIn,
          flow,
          power
        );
      const temperatureOut = PropertyValue.fromAmount(airOut.temperature);
      const humidityOut = PropertyValue.fromAmount(airOut.humidity);

      return [
        {
          ...state,
          airOut: PropertyValueSet.merge(
            PropertyValueSet.fromProperty("temperature", temperatureOut),
            PropertyValueSet.fromProperty("humidity", humidityOut)
          )
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        [
          SharedState.Action.saveAmountFormat(
            action.fieldGroup,
            action.fieldName,
            action.unit,
            action.decimalCount
          )
        ]
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        [
          SharedState.Action.clearAmountFormat(
            action.fieldGroup,
            action.fieldName
          )
        ]
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
