import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { ConfiguratorAction } from "../configurator-actions";
import * as SharedState from "../../../shared-state";
import * as Types from "../types";
import { Action, State } from "../state";
import { createSaveOperatingCasesCmd } from "./save-operating-cases";
import { updateComponentAccessories } from "./update-component-accessories";
import { addComponent } from "./add-component";
import { moveComponent } from "./move-component";
import { deleteComponent } from "./delete-component";
import { calculateSystem } from "./calculate-system";
import { calculateEnergyComparison } from "./calculate-energy-comparison";
import { lockSystem } from "./lock-system";
import { exchangeComponent } from "./exchange-component";
import { saveNotes } from "./save-notes";
import { updateComponentProperties } from "./update-component-properties";
import { updateComponentsPropertiesAndSaveOperatingCases } from "./update-component-properties-and-save-operating-cases";
import { saveEnergy } from "./save-energy";
import { deleteMCompareComponent } from "./delete-m-compare-component";
import { addOrUpdateMCompareComponent } from "./add-or-update-m-compare-component";
import { setSystemPricingInformation } from "./set-system-pricing-information";
import { calculateEnergy } from "./calculate-energy";

export function handleConfiguratorActions(
  actions: ReadonlyArray<ConfiguratorAction | undefined> | undefined,
  state: Types.SystemLoadedState,
  sharedState: SharedState.State
): [State, ReadonlyArray<Cmd<Action> | undefined>?] {
  if (actions === undefined || actions.length === 0) {
    return [state];
  }
  const cmdsWithStatus: Array<
    [Cmd<Action>, SystemStatus | ReadonlyArray<SystemStatus> | undefined]
  > = [];

  for (const action of actions) {
    if (action === undefined) {
      continue;
    }

    switch (action.type) {
      case "saveOperatingCases": {
        cmdsWithStatus.push(
          createSaveOperatingCasesCmd(
            sharedState,
            action,
            state.loadedState.system.id,
            state.loadedState.system.binSelections,
            state.loadedState.system.moistureLoadInfo?.id
          )
        );
        break;
      }
      case "updateComponentAccessories": {
        cmdsWithStatus.push(
          updateComponentAccessories(
            sharedState,
            state.loadedState.system.id,
            action
          )
        );
        break;
      }
      case "addComponent": {
        cmdsWithStatus.push(
          addComponent(sharedState, state.loadedState.system.id, action)
        );
        break;
      }
      case "moveComponent": {
        cmdsWithStatus.push(
          moveComponent(sharedState, state.loadedState.system.id, action)
        );
        break;
      }
      case "deleteComponent": {
        cmdsWithStatus.push(
          deleteComponent(sharedState, state.loadedState.system.id, action)
        );
        break;
      }
      case "calculateSystem": {
        cmdsWithStatus.push(
          calculateSystem(sharedState, state.loadedState.system.id)
        );
        break;
      }

      case "calculateEnergyComparison": {
        cmdsWithStatus.push(
          calculateEnergyComparison(sharedState, state.loadedState.system.id)
        );
        break;
      }

      case "calculateEnergy": {
        const cmdWithStatus = calculateEnergy(
          sharedState,
          state.loadedState.system.id
        );
        cmdsWithStatus.push(cmdWithStatus);
        break;
      }
      case "lockSystem": {
        cmdsWithStatus.push(
          lockSystem(sharedState, state.loadedState.system.id)
        );
        break;
      }
      case "exchangeComponent": {
        cmdsWithStatus.push(
          exchangeComponent(sharedState, state.loadedState.system.id, action)
        );
        break;
      }
      case "setSystemPricingInformation": {
        cmdsWithStatus.push(
          setSystemPricingInformation(
            sharedState,
            state.loadedState.system.id,
            action
          )
        );
        break;
      }
      case "saveNotes": {
        cmdsWithStatus.push(
          saveNotes(sharedState, state.loadedState.system.id, action)
        );
        break;
      }
      case "updateComponentProperties": {
        cmdsWithStatus.push(
          updateComponentProperties(
            sharedState,
            state.loadedState.system.id,
            action
          )
        );
        break;
      }
      case "updateComponentsPropertiesAndSaveOperatingCases": {
        cmdsWithStatus.push(
          updateComponentsPropertiesAndSaveOperatingCases(
            sharedState,
            state.loadedState.system.id,
            action
          )
        );
        break;
      }

      case "saveEnergy": {
        cmdsWithStatus.push(
          saveEnergy(
            sharedState,
            state.loadedState.system.id,
            state.loadedState.system.status,
            action
          )
        );
        break;
      }

      case "deleteMCompareComponent": {
        cmdsWithStatus.push(
          deleteMCompareComponent(sharedState, state.loadedState.system.id)
        );
        break;
      }
      case "addOrUpdateMCompareComponent": {
        cmdsWithStatus.push(
          addOrUpdateMCompareComponent(
            sharedState,
            state.loadedState.system.id,
            action
          )
        );
        break;
      }
      default: {
        return exhaustiveCheck(action, true);
      }
    }
  }

  const targetStatuses = cmdsWithStatus
    .flatMap(x => x[1])
    .map(x => x || SystemStatus.ValidateConfigurationSuccess);
  return [
    {
      ...state,
      loadedState: {
        ...state.loadedState,
        overlay: { type: "OverlayDefault", targetStatuses },
        runningMutations: cmdsWithStatus.length
      }
    },
    cmdsWithStatus.map(x => x[0])
  ];
}
