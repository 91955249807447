import gql from "graphql-tag";
import * as SharedState from "../../../../shared-state";
import * as PropertiesSelector from "../../../../properties-selector";
import * as System from "../../../system";
import * as Product from "../../../product";
import * as Tools from "./tools";
import * as Types from "./types";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as GraphQlTypes from "../../../../graphql-types";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as PsychrometricChart from "../../../../psychrometric-chart";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools/";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import { Cmd } from "@typescript-tea/core";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  getDefaultLocation,
  getCoordinateFromClimateDataProperties
} from "../../../../location-selector";
import { Unit, Amount, Quantity, Units } from "@genesys/uom";

const productQuery = gql`
  query MCompareInputQueryProduct($systemType: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemType) {
        id
        energyTotals {
          id
          name
          sortNo
          costType
          quantityId
          visibilityFilter
        }

        energyList {
          id
          name
          sortNo
        }
      }
      binDataLocations {
        binDataLocationId
        locationName
        regionName
        countryName
        latitude
        longitude
      }
    }
  }
`;

const productQuery2 = gql`
  query MCompareInputQueryProduct2($input: String!) {
    product {
      psycProClimateDataBins(input: $input) {
        id
        binId
        time
        temperature
        humidity
      }
    }
  }
`;

export type State = {
  readonly propertiesSelectorStates:
    | {
        readonly [key in PropertieSelectors]: PropertiesSelector.State;
      }
    | undefined;

  readonly productQueryData: GraphQlTypes.MCompareInputQueryProduct | undefined;
  readonly productQueryData2:
    | GraphQlTypes.MCompareInputQueryProduct2
    | undefined;

  readonly energyPrice: PropertyValueSet.PropertyValueSet | undefined;
  readonly energyItems: ReadonlyArray<Types.EnergyItem>;
  readonly systemBinSelections: PropertyValueSet.PropertyValueSet;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly binVisualizer: Types.BinVisualizer;
  readonly chartType: Types.ChartType;
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined;
  readonly binListView: Types.BinListView;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
};

export type ActiveTab = "Input" | "Results";
type PropertieSelectors =
  | "competitorSystem"
  | "operatingCaseSelector"
  | "energyEmission";

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  products: ReadonlyArray<Product.Product>
): [State, Cmd<Action>?] => {
  return [
    {
      propertiesSelectorStates: initialisePropertySelectors(system, products),
      energyItems: [],
      energyPrice: undefined,
      binCases: [],
      binVisualizer: "List",
      binListView: "standard",
      psychrometricChartState: undefined,
      systemBinSelections: PropertyValueSet.Empty,
      climateSettings: PropertyValueSet.Empty,
      chartType: "mollier_SI",
      productQueryData: undefined,
      productQueryData2: undefined
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.MCompareInputQueryProduct,
      GraphQlTypes.MCompareInputQueryProductVariables,
      Action
    >(
      productQuery,
      {
        systemType: {
          systemTypeId: system.file.systemTypeId
        }
      },
      Action.productQueryReceived
    )
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (
    name: PropertieSelectors,
    action: PropertiesSelector.Action
  ) => ({ action, name }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  }),
  productQueryReceived: (data: GraphQlTypes.MCompareInputQueryProduct) => ({
    data
  }),
  productQuery2Received: (data: GraphQlTypes.MCompareInputQueryProduct2) => ({
    data
  }),
  setBinVisualizer: (binVisualizer: Types.BinVisualizer) => ({ binVisualizer }),
  setBinListView: (binListView: Types.BinListView) => ({ binListView }),
  setChartType: (chartType: Types.ChartType) => ({ chartType })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?, SharedState.Action?] {
  const systemTypeId = system.file.systemTypeId;
  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );
  const climateSettings = system.climateSettings;

  switch (action.type) {
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }

    case "productQueryReceived": {
      const energyItems = [
        ...Tools.getCostEnergyItems(
          action.data.product.systemType.energyTotals
        ),
        ...Tools.getStaticEnergyItems()
      ];

      const settings = Tools.getSettings(
        sharedState.user.settings.energySettings,
        system
      );

      const energyPrice = Tools.getEnergyPriceDefaultProperties(
        energyItems,
        systemTypeId,
        settings.energyUserSettings,
        systemBinSelections
      );

      const selectedOperationTime = Tools.getOperationTime(
        state.systemBinSelections
      );
      const selectedDataType = Tools.getDataType(
        systemBinSelections,
        climateSettings
      );
      const selectedBinSize = Tools.getBinSize(
        systemBinSelections,
        settings.energyUserSettings
      );
      const locationCoordinate =
        getCoordinateFromClimateDataProperties(climateSettings);

      const binType = Tools.getBinType(systemBinSelections);
      const binCases = Tools.getBinCasesFromSystem(system);

      const binLocationId = getDefaultLocation(
        action.data.product.binDataLocations,
        locationCoordinate
      ).binDataLocationId;

      const binSelections = PropertyValueSet.merge(
        PropertyValueSet.setInteger(
          KnownProperties.binSize,
          selectedBinSize,
          PropertyValueSet.setText(
            KnownProperties.binDataLocationId,
            binLocationId,
            PropertyValueSet.setText(
              KnownProperties.binOperationTime,
              OperationTimeGen2.presetName(selectedOperationTime),
              PropertyValueSet.setText(
                KnownProperties.climateCoolingDataType,
                selectedDataType,
                PropertyValueSet.setText(
                  KnownProperties.binType,
                  binType,
                  PropertyValueSet.Empty
                )
              )
            )
          )
        ),
        OperationTime.toPropertyValueSet(selectedOperationTime)
      );

      return [
        {
          ...state,
          energyItems,
          climateSettings,
          binCases,
          systemBinSelections,
          energyPrice,
          productQueryData: action.data
        },
        sharedState.graphQL.queryProductCmd<
          GraphQlTypes.MCompareInputQueryProduct2,
          GraphQlTypes.MCompareInputQueryProduct2Variables,
          Action
        >(
          productQuery2,
          {
            input: PropertyValueSet.toString(binSelections)
          },
          Action.productQuery2Received
        )
      ];
    }

    case "productQuery2Received": {
      return [{ ...state, productQueryData2: action.data }];
    }

    case "dispatchPropertiesSelector": {
      if (state.propertiesSelectorStates === undefined) {
        return [state];
      }
      const [
        propertiesSelectorStates,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.propertiesSelectorStates[action.name],
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        {
          ...state,
          propertiesSelectorStates: {
            ...state.propertiesSelectorStates,
            [action.name]: propertiesSelectorStates
          }
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchPropertiesSelector(action.name, cmdAction),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }

    case "dispatchPsychrometricChart": {
      if (state.psychrometricChartState === undefined) {
        return [state];
      }
      const [psychrometricChartState, psychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.psychrometricChartState,
          sharedState
        );
      return [
        {
          ...state,
          psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        sharedStateCmd
      ];
    }

    case "setBinVisualizer": {
      if (action.binVisualizer === "Chart") {
        const binType = Tools.getBinType(systemBinSelections);
        const [psychrometricChartState, psychrometricChartCmd] = lololol(
          binType,
          state,
          sharedState,
          state.chartType
        );
        return [
          {
            ...state,
            psychrometricChartState,
            binVisualizer: action.binVisualizer
          },
          Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
        ];
      }

      return [
        {
          ...state,
          binVisualizer: action.binVisualizer
        }
      ];
    }

    case "setBinListView": {
      return [
        {
          ...state,
          binListView: action.binListView
        }
      ];
    }

    case "setChartType": {
      const binType = Tools.getBinType(systemBinSelections);

      const [psychrometricChartState, psychrometricChartCmd] = lololol(
        binType,
        state,
        sharedState,
        action.chartType
      );
      // const [
      //   psychrometricChartState,
      //   psychrometricChartCmd
      // ] = PsychrometricChart.init(
      //   getPsychrometricChartInitSettings(
      //     state,
      //     action.chartType,
      //     binFields,
      //     sharedState,
      //     state.binCases!
      //   )
      // );
      return [
        {
          ...state,
          psychrometricChartState,
          chartType: action.chartType
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

function lololol(
  binType: Types.BinType,
  state: State,
  sharedState: SharedState.State,
  chartType: Types.ChartType
): [PsychrometricChart.State, Cmd<PsychrometricChart.Action> | undefined] {
  const binFields = getBinFields(state, binType);
  const [psychrometricChartState, psychrometricChartCmd] =
    PsychrometricChart.init(
      getPsychrometricChartInitSettings(
        state,
        chartType,
        binFields,
        sharedState,
        state.binCases!
      )
    );
  return [psychrometricChartState, psychrometricChartCmd];
}

function getBinFields(state: State, binType: Types.BinType) {
  return binType === "Generated"
    ? state.productQueryData2!.product.psycProClimateDataBins.map(b => ({
        temperature: PropertyValue.getAmount<Quantity.Temperature>(
          PropertyValue.fromString(b.temperature)!
        )!,
        humidity: PropertyValue.getAmount<Quantity.HumidityRatio>(
          PropertyValue.fromString(b.humidity)!
        )!,
        time: PropertyValue.getAmount<Quantity.Duration>(
          PropertyValue.fromString(b.time)!
        )!
      }))
    : [];
}

function initialisePropertySelectors(
  system: System.System,
  products: ReadonlyArray<Product.Product>
) {
  const product = products.find(
    obj => obj.id === system.file.systemTypeId + "MCS"
  );
  const sysComponent = system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;

  let startValues = {
    competitorSystem: PropertiesSelector.init(PropertyValueSet.Empty),
    operatingCaseSelector: PropertiesSelector.init(PropertyValueSet.Empty),
    energyEmission: PropertiesSelector.init(PropertyValueSet.Empty)
  };

  if (product) {
    const mcs = ProductData.filterProductForRange(
      product,
      sysComponent.properties
    );
    const defaultPropertiesCompSys =
      Tools.getCompetitorSettings(system) ||
      ProductProperties.createDefaultProperties(
        mcs.properties.map(m => ({
          name: m.name,
          quantity: m.quantity,
          values: m.items as any,
          defaultValues: m.defaultValues
        })),
        true
      );
    startValues = {
      ...startValues,
      competitorSystem: PropertiesSelector.init(defaultPropertiesCompSys)
    };
  }

  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );

  const defaultPropsOpc = Tools.getCaseSettings(system);

  const defualtEnergyEmission = Tools.getDefaultEnergyEmissionProperties(
    systemBinSelections,
    10
  );

  return {
    ...startValues,
    operatingCaseSelector: PropertiesSelector.init(defaultPropsOpc),
    energyEmission: PropertiesSelector.init(defualtEnergyEmission)
  };
}

function getPsychrometricChartInitSettings(
  state: State,
  chartType: Types.ChartType,
  binFields:
    | ReadonlyArray<{
        readonly temperature: Amount.Amount<"Temperature">;
        readonly humidity: Amount.Amount<"HumidityRatio">;
        readonly time: Amount.Amount<"Duration">;
      }>
    | undefined,
  sharedState: SharedState.State,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): PsychrometricChart.InitProps {
  const selectedBinSize = Tools.getBinSize(state.systemBinSelections);
  return {
    source: "custom",
    binCases: binCases.map(bin => ({
      temperature: bin.averageTemperature,
      humidity: bin.averageHumidity,
      time: bin.binTime
    })),
    si: PsychrometricChart.infoFromPreset(chartType).measureSystem === "SI",
    points: [],
    binFields: binFields
      ? binFields.map(f => ({
          ...f,
          temperatureInterval: Amount.create(selectedBinSize, Units.Celsius),
          humidityInterval: Amount.create(
            selectedBinSize!,
            Units.GramPerKilogram
          )
        }))
      : [],
    pressure:
      binCases && binCases.length
        ? binCases[0].binPressure
        : Amount.create(101325, Units.Pascal),
    type: PsychrometricChart.infoFromPreset(chartType).chartType,
    accessToken: sharedState.accessToken,
    limits: false
  };
}

// tslint:disable-next-line
