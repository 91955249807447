import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const SpecialActionContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  margin-top: 28px;
  margin-left: 2px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;

  background: white;

  border-radius: 1px;
  border: 1px solid ${Colors.searchFilterBorderColor};

  z-index: 2;

  div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
