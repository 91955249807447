import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";

export function createPrinterSvg(color: string): string {
  const svgString = `
    <svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>DC15E027-3611-4052-B0D7-B283D95D7739</title>
    <defs>
        <path d="M22.5,16.66625 C22.5,17.12625 22.14375,17.5 21.70375,17.5 L20.625,17.5 L20.625,15 C20.625,13.62125 19.50375,12.5 18.125,12.5 L6.875,12.5 C5.49625,12.5 4.375,13.62125 4.375,15 L4.375,17.5 L3.29625,17.5 C2.85625,17.5 2.5,17.12625 2.5,16.66625 L2.5,8.33375 C2.5,7.87375 2.85625,7.5 3.29625,7.5 L5,7.5 L7.5,7.5 L17.5,7.5 L20,7.5 L21.70375,7.5 C22.14375,7.5 22.5,7.87375 22.5,8.33375 L22.5,16.66625 Z M6.875,20 L6.875,15 L18.125,15 L18.1275,20 L6.875,20 Z M7.5175,2.5 L17.5,2.5 L17.5,5 L7.50875,5 L7.5175,2.5 Z M21.70375,5 L20,5 L20,2.5 C20,1.12125 18.9725,0 17.70875,0 L7.29125,0 C6.0275,0 5,1.12125 5,2.5 L5,5 L3.29625,5 C1.47875,5 0,6.495 0,8.33375 L0,16.66625 C0,18.505 1.47875,20 3.29625,20 L4.375,20 C4.375,21.37875 5.49625,22.5 6.875,22.5 L18.125,22.5 C19.50375,22.5 20.625,21.37875 20.625,20 L21.70375,20 C23.52125,20 25,18.505 25,16.66625 L25,8.33375 C25,6.495 23.52125,5 21.70375,5 L21.70375,5 Z" id="path-1"></path>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-138.000000, -566.000000)">
            <g id="Group-3" transform="translate(78.000000, 566.000000)">
                <g id="Icons/System-Toolbar/Print" transform="translate(60.720000, 0.000000)">
                    <g id="Icon-/-Printer" transform="translate(2.500000, 3.750000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-1"></use>
                        </mask>
                        <use id="🎨-Icon-Сolor" fill="${color}" xlink:href="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    `;

  return encodeSvgStringToImgSrc(svgString);
}
