import {
  SummaryItem,
  SelectedColumn,
  SummaryType,
  StandardSummaryItem,
  ColumnGroup,
  VisibleColumn,
  DynamicColumn,
  Column
} from "../types";

import { getDefaultSummaryItem } from "../definitions";

type Dynamic = DynamicColumn & Column;

export function getVisibleColumns(
  summaryItemsDef: ReadonlyArray<SummaryItem>,
  selectedSummaryItems: Map<SummaryType, Set<SelectedColumn>>
): ReadonlyArray<VisibleColumn> {
  const defaultItem = getDefaultSummaryItem(selectedSummaryItems);

  const dynamicColumns: Array<Dynamic> = defaultItem.dynamicAddedColumns.filter(
    x => x.isVisible
  );

  const baseColumnGroup: VisibleColumn = {
    title: "",
    group: "standard" as StandardSummaryItem["type"],
    fieldGroup: defaultItem.fieldGroup,
    backgroundColor: defaultItem.backgroundColor,
    columns: createColumns(
      dynamicColumns,
      defaultItem.selectableColumns as Column[]
    )
  };

  const otherColumnGroups = summaryItemsDef
    .map(itemDef => {
      const selectedColumns =
        selectedSummaryItems.get(itemDef.type) || new Set<SelectedColumn>();

      const dynamicColumns = itemDef.dynamicAddedColumns as Array<Dynamic>;

      const visibleDynamicCol = dynamicColumns.filter(x => x.isVisible);
      const visibleSelectableCol = (
        itemDef.selectableColumns as Array<ColumnGroup>
      ).filter(col => selectedColumns.has(col.columnName));

      return {
        title: itemDef.title,
        group: itemDef.type,
        fieldGroup: itemDef.fieldGroup,
        backgroundColor: itemDef.backgroundColor,
        columns: createColumns(visibleDynamicCol, visibleSelectableCol)
      };
    })
    .filter(x => x.columns.length > 0);

  return [baseColumnGroup, ...otherColumnGroups];
}

function createColumns(
  visibleDynamicCol: Dynamic[],
  visibleSelectableCol: Column[]
): ReadonlyArray<Column> {
  const mapedDynamicCol = visibleDynamicCol.map(x => ({
    type: x.type,
    columnName: x.columnName,
    displayName: x.displayName,
    format: x.format,
    sortNo: x.sortNo,
    isVisible: x.isVisible,
    isEditable: x.isEditable
  }));

  return [...mapedDynamicCol, ...visibleSelectableCol].sort(
    (a, b) => (a.sortNo || 0) - (b.sortNo || 0)
  );
}
