import styled from "styled-components";

// tslint:disable-next-line
export const DialogHeaderContainer = styled.div`
  font-size: 12px;
  font-weight: bold;

  display: flex;
  justify-content: space-between;

  background-image: linear-gradient(to top, #e0e0e0, white 60%);

  padding: 5px 5px;
  border-bottom: 1px solid #fff;

  > span {
    cursor: pointer;
  }
`;
