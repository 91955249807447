import styled from "styled-components";
// import { dialogBackgroundColor } from "../colors";

interface Props {
  readonly message: boolean;
}

// tslint:disable-next-line
export const DialogInformationContainer = styled.div<Props>`
  ${props =>
    props.message
      ? ""
      : `
  background: white;
  border: 1px solid #a2adb8;
  padding: 5px;
  `};

  white-space: pre-wrap;
  text-align: left;
`;
