import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import * as SharedState from "../shared-state";
import {
  Root,
  StyledStandardButton,
  CasesContainer,
  ButtonsContainer,
  LicensingContainer,
  FlexContainer,
  LocationContainer
} from "./elements";
import { CaseComponent } from "./components/case-component";
import { LocationComponent } from "./components/location-component";
import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

type Renderer = (
  locationComponent: JSX.Element,
  caseComponents: ReadonlyArray<JSX.Element>,
  buttonComponent: JSX.Element,
  licensingComponent: JSX.Element
) => JSX.Element;

export function ClimateSelectorView({
  state,
  sharedState,
  showWindVelocity,
  isDisabled,
  dispatch,
  onChange,
  onClose,
  customRenderer
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly isDisabled: boolean;
  readonly showWindVelocity: boolean;
  readonly dispatch: Dispatch<Action>;
  readonly onChange?: () => void;
  readonly onClose?: () => void;
  readonly customRenderer?: Renderer;
}): JSX.Element {
  if (!state.dataPoints || !state.countriesData || !state.currentLocation) {
    return <></>;
  }

  const isManualDataSource = !PropertyValueSet.hasProperty(
    "wmo",
    state.climateSettings
  );

  React.useEffect(() => {
    (async () => {
      if (onChange) {
        onChange();
      }
    })();
  }, [state.onChangeTrigger]);

  const renderer = customRenderer || renderClimateSelectorView;
  const buttonsComponent = (
    <>
      {onClose !== undefined && (
        <StyledStandardButton
          buttonType="Secondary-2"
          size="Small"
          onClick={onClose}
        >
          {sharedState.translate(LanguageTexts.close())}
        </StyledStandardButton>
      )}
    </>
  );

  const licensingComponent = (
    <p>
      &copy; 2021 ASHRAE,
      <a target="_blank" href="https://ashrae.org">
        www.ashrae.org
      </a>{" "}
      Used with permission
    </p>
  );

  const locationComponent = (
    <LocationComponent
      translate={sharedState.translate}
      isDisabled={isDisabled}
      messages={state.messages}
      currentLocation={state.currentLocation}
      sharedState={sharedState}
      climateSettings={state.climateSettings}
      countries={state.countriesData.product.countries}
      isManualDataSource={isManualDataSource}
      onSettingChange={newClimateSettings =>
        dispatch(Action.onSettingsChange(newClimateSettings))
      }
      onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
        dispatch(
          Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        );
      }}
      onFormatCleared={(fieldGroup, fieldName) => {
        dispatch(Action.onFormatCleared(fieldGroup, fieldName));
      }}
    />
  );

  const caseComponents = [
    <CaseComponent
      translate={sharedState.translate}
      currentLocation={state.currentLocation.location}
      isDisabled={isDisabled}
      sharedState={sharedState}
      climateSettings={state.climateSettings}
      dataPoints={state.dataPoints}
      isManualDataSource={isManualDataSource}
      showWindVelocity={showWindVelocity}
      selectedMonth={state.selectedMonth}
      caseType="C"
      onMonthChange={mon => {
        dispatch(Action.setSelectedMonth(mon));
      }}
      onSettingChange={(
        newClimateSettings: PropertyValueSet.PropertyValueSet
      ) => dispatch(Action.onSettingsChange(newClimateSettings))}
      onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
        dispatch(
          Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        );
      }}
      onFormatCleared={(fieldGroup, fieldName) => {
        dispatch(Action.onFormatCleared(fieldGroup, fieldName));
      }}
    />,

    <CaseComponent
      translate={sharedState.translate}
      currentLocation={state.currentLocation.location}
      isDisabled={isDisabled}
      sharedState={sharedState}
      climateSettings={state.climateSettings}
      dataPoints={state.dataPoints}
      isManualDataSource={isManualDataSource}
      showWindVelocity={showWindVelocity}
      selectedMonth={state.selectedMonth}
      onMonthChange={mon => {
        dispatch(Action.setSelectedMonth(mon));
      }}
      caseType="H"
      onSettingChange={(
        newClimateSettings: PropertyValueSet.PropertyValueSet
      ) => dispatch(Action.onSettingsChange(newClimateSettings))}
      onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
        dispatch(
          Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
        );
      }}
      onFormatCleared={(fieldGroup, fieldName) => {
        dispatch(Action.onFormatCleared(fieldGroup, fieldName));
      }}
    />
  ];

  return renderer(
    locationComponent,
    caseComponents,
    buttonsComponent,
    licensingComponent
  );
}

const renderClimateSelectorView: Renderer = (
  locationComponent,
  caseComponents,
  buttonComponent,
  licensingComponent
) => {
  return (
    <Root>
      <FlexContainer>
        <LocationContainer>{locationComponent}</LocationContainer>
        <CasesContainer>
          {caseComponents.map(caseComponent => caseComponent)}
        </CasesContainer>
      </FlexContainer>
      <ButtonsContainer>{buttonComponent}</ButtonsContainer>
      <LicensingContainer>{licensingComponent}</LicensingContainer>
    </Root>
  );
};
