import gql from "graphql-tag";

export const copyMoistureLoadToNewFile = gql`
  mutation CopyMoistureLoadToNewFile($moistureLoadId: ID!) {
    copyMoistureLoadToNewFile(moistureLoadId: $moistureLoadId) {
      id
      moistureLoadNo
    }
  }
`;
