export type ClimateDataType =
  | "h/MCDB"
  | "DB/MCWB"
  | "WB/MCDB"
  | "DB"
  | "DP/MCDB";

const climateDataTypes: ReadonlyArray<ClimateDataType> = [
  "h/MCDB",
  "DB/MCWB",
  "WB/MCDB",
  "DB",
  "DP/MCDB"
];

export function climateDataTypeToInteger(
  climateDataType: ClimateDataType
): number {
  const index = climateDataTypes.indexOf(climateDataType);

  if (index === -1) {
    throw new Error("Unknown climate data type: " + climateDataType);
  }

  return index;
}

export function integerToClimateDataType(
  climateDataTypeInteger: number
): ClimateDataType {
  const climateDataType = climateDataTypes[climateDataTypeInteger];

  if (!climateDataType) {
    throw new Error(
      "Unknown climate data type index: " + climateDataTypeInteger
    );
  }

  return climateDataType;
}

export function makeClimateDataType(
  potentialClimateDataType: string
): ClimateDataType {
  if (!climateDataTypes.includes(potentialClimateDataType as ClimateDataType)) {
    throw new Error("Unknown climate data type: " + potentialClimateDataType);
  }

  return potentialClimateDataType as ClimateDataType;
}
