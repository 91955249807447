import styled from "styled-components";
import * as Colors from "../colors";

// tslint:disable-next-line
export const SpecialActionButton = styled.button`
  margin-left: 2px;
  height: 28px;
  border: 2px solid ${Colors.searchFilterBorderColor};
  border-radius: 2px;
  background-color: ${Colors.searchBackground};
  vertical-align: bottom;
  cursor: pointer;
  min-width: 100px;

  &:hover {
    border-color: ${Colors.searchFilterBorderHoverColor};
    border-width: 2px;
  }
`;
