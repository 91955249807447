import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import * as Product from "./product";
type Expr = SequenceExpr | OrExpr | QuantifierExpr | IdentityExpr;

type SequenceExpr = {
  readonly type: "SequenceExpr";
  readonly children: Expr[];
};

type OrExpr = {
  readonly type: "OrExpr";
  readonly alternatives: Expr[];
};

type QuantifierExpr = {
  readonly type: "QuantifierExpr";
  readonly exprToQuantify: Expr;
  readonly quantifier: string;
};

type IdentityExpr = {
  readonly type: "IdentityExpr";
  readonly identity: string;
};

const parenthesesRegex = /[a-z0-9]{2,}/gi;

export function createSequenceRegexes(
  sysProps: PropertyValueSet.PropertyValueSet,
  sequenceRules: ReadonlyArray<Product.SequenceRule>
): ReadonlyArray<RegExp> {
  const rules = sequenceRules
    .filter(
      psp =>
        !sysProps || PropertyFilter.isValid(sysProps, psp.rangePropertyFilter)
    )
    .map(r => ({
      ...r,
      sequence: cleanupSequence(r.sequence)
    }));

  const ruleRegexes: Array<RegExp> = [];
  for (const sequence of rules
    .filter(
      r => r.name === null || r.name === undefined || r.name.trim() === ""
    )
    .map(r => r.sequence)) {
    let oldSequence: string;
    let newSequence = sequence;
    do {
      oldSequence = newSequence;
      newSequence = rules
        .filter(s => s.name !== null || s.name !== undefined || s.name !== "")
        .reduce(
          (seq, subSeq) =>
            seq.replace("(" + subSeq.name + ")", "(" + subSeq.sequence + ")"),
          oldSequence
        );
    } while (newSequence !== oldSequence);

    const optimizedRule = optimizeSequenceRules(
      newSequence.split(" ").join("")
    );

    ruleRegexes.push(new RegExp(optimizedRule));
  }
  return ruleRegexes;
}

export function getInvalidSequencePositions(
  rulesRegexes: ReadonlyArray<RegExp>,
  sections: ReadonlyArray<string>
): ReadonlyArray<number> {
  const errors: Array<number> = [];
  if (isValidSequence(rulesRegexes, sections)) {
    return errors;
  }

  if (errors.length === 0) {
    errors.push(0);
  }
  return errors;
}

export function isValidSequence(
  rules: ReadonlyArray<RegExp>,
  sequence: ReadonlyArray<string>
): boolean {
  const productSectionSequence = sequence.join("").split(".").join("");
  return rules.some(r => {
    return matches(productSectionSequence, r);
  });
}

function matches(s: string, r: RegExp) {
  const matches = s.match(r);
  if (!matches) {
    return false;
  }

  return matches[0] === s;
}

function optimizeSequenceRules(s: string): string {
  if (!openingAndClosingValid(s, "(", ")")) {
    throw Error("Sequence rule parsing error. Contact support!!!");
  }
  if (!openingAndClosingValid(s, "{", "}")) {
    throw Error("Sequence rule parsing error. Contact support!!!");
  }

  const optimizedRules = Parse(s);
  if (optimizedRules.length !== 1) {
    throw Error("Sequence rule parsing error. Contact support!!!");
  }

  const mainExpression = optimizedRules[0];
  if (mainExpression.type === "SequenceExpr") {
    return "^" + writeRule(mainExpression, false) + "$";
  }

  return "^" + writeRule(mainExpression, true) + "$";
}

function cleanupSequence(sequence: string): string {
  sequence = sequence.split(".").join("");
  sequence = sequence.replace(parenthesesRegex, m => "(" + m + ")");

  return sequence;
}

function Parse(s: string): ReadonlyArray<Expr> {
  if (matches(s, parenthesesRegex)) {
    return [
      {
        type: "IdentityExpr",
        identity: s
      }
    ];
  }

  let list: Expr[] = [];
  let indicesWithOrSignsAfter: number[] = [];
  //tslint:disable-next-line
  const quantifiers: { [id: number]: string } = {};
  let index = 0;

  do {
    switch (s[index]) {
      case "(": {
        const result = findFirstOpeningAndClosing(
          s.substring(index, s.length),
          "(",
          ")"
        );
        if (!result.ok) {
          throw Error("Sequence rule parsing error. Contact support!!!");
        }
        let startIndex = result.startIndex + index;
        let endIndex = result.endIndex + index;
        list = list.concat(Parse(s.substring(startIndex + 1, endIndex)));
        index = endIndex + 1;
        break;
      }
      case "|":
        indicesWithOrSignsAfter = indicesWithOrSignsAfter.concat(
          list.length - 1
        );
        index++;
        break;
      case "*":
        quantifiers[list.length - 1] = "*";
        index++;
        break;
      case "+":
        quantifiers[list.length - 1] = "+";
        index++;
        break;
      case "?":
        quantifiers[list.length - 1] = "?";
        index++;
        break;
      case "{": {
        const result = findFirstOpeningAndClosing(s, "{", "}");
        if (!result.ok) {
          throw Error("Sequence rule parsing error. Contact support!!!");
        }
        let startIndex = result.startIndex + index;
        let endIndex = result.endIndex + index;
        quantifiers[list.length - 1] = s.substring(startIndex + 1, endIndex);

        index = endIndex + 1;
        break;
      }
      default:
        throw Error("Sequence rule parsing error. Contact support!!!");
    }
  } while (index <= s.length - 1);

  let sequence: Expr[] = [];
  let currentOrExpression: OrExpr | undefined = undefined;

  for (let i = 0; i < list.length; i++) {
    let expression = list[i];
    const quantifier = quantifiers[i];
    if (quantifier !== undefined) {
      expression = {
        type: "QuantifierExpr",
        exprToQuantify: expression,
        quantifier: quantifier
      };
    }

    if (indicesWithOrSignsAfter.includes(i)) {
      // if first or no CurrentOrExpression
      if (i === 0 || currentOrExpression === undefined) {
        currentOrExpression = { type: "OrExpr", alternatives: [expression] };
      } else {
        // If im within an Or expression, push expression to current or expression
        currentOrExpression = {
          type: "OrExpr",
          alternatives: currentOrExpression.alternatives.concat(expression)
        };
      }
    } else {
      if (i === 0 || currentOrExpression === undefined) {
        if (expression.type === "SequenceExpr") {
          sequence = sequence.concat(expression.children);
        } else {
          sequence = sequence.concat(expression);
        }
      } else {
        // If im within an Or expression, push expression to current expression
        currentOrExpression = {
          type: "OrExpr",
          alternatives: currentOrExpression.alternatives.concat(expression)
        };

        if (currentOrExpression.alternatives.some(a => a.type === "OrExpr")) {
          const newAlternatives = currentOrExpression.alternatives.reduce(
            (soFar: Expr[], current) => {
              if (current.type === "OrExpr") {
                soFar = soFar.concat(current.alternatives);
              } else {
                soFar = soFar.concat(current);
              }
              return soFar;
            },
            []
          ) as Expr[];
          currentOrExpression = {
            type: "OrExpr",
            alternatives: newAlternatives
          };
        }

        sequence = sequence.concat(currentOrExpression);
        currentOrExpression = undefined;
      }
    }
  }

  if (sequence.length === 1) {
    return sequence;
  } else {
    return [
      {
        type: "SequenceExpr",
        children: sequence
      }
    ];
  }
}

function writeRule(rule: Expr, useParentheses: boolean): string {
  switch (rule.type) {
    case "IdentityExpr":
      return writeIdentityRule(rule, useParentheses);
    case "OrExpr":
      return writeOrRule(rule, useParentheses);
    case "QuantifierExpr":
      return writeQuantifierRuleWithPossessiveShorthand(rule, useParentheses);
    case "SequenceExpr":
      return writeSequenceRule(rule, useParentheses);
    default:
      throw new Error("Unkown regex type.");
  }
}

function writeIdentityRule(
  rule: IdentityExpr,
  useParentheses: boolean
): string {
  return `${useParentheses ? "(?:" : ""}${rule.identity}${
    useParentheses ? ")" : ""
  }`;
}

function writeQuantifierRuleWithPossessiveShorthand(
  rule: QuantifierExpr,
  useParentheses: boolean
): string {
  return (
    `${useParentheses ? "(?:" : ""}` +
    "(?:" +
    `${writeRule(rule.exprToQuantify, false)}` +
    ")" +
    `${rule.quantifier}` +
    // "+" +
    `${useParentheses ? ")" : ""}`
  );
}

function writeOrRule(rule: OrExpr, useParentheses: boolean): string {
  const alternatives = rule.alternatives
    .map(a => writeRule(a, false))
    .join("|");

  return `${useParentheses ? "(?:" : ""}${alternatives}${
    useParentheses ? ")" : ""
  }`;
}

function writeSequenceRule(
  rule: SequenceExpr,
  useParentheses: boolean
): string {
  const children = rule.children
    .map(a => {
      if (a.type === "QuantifierExpr") {
        return writeRule(a, false);
      }
      return writeRule(a, true);
    })
    .join("");

  return `${useParentheses ? "(?:" : ""}${children}${
    useParentheses ? ")" : ""
  }`;
}

function openingAndClosingValid(
  s: string,
  openingChar: string,
  closingChar: string
): boolean {
  const openings = charOccurances(s, openingChar);
  const closings = charOccurances(s, closingChar);
  return openings === closings;
}

function charOccurances(str: string, char: string): number {
  let c = 0;
  for (let i = 0; i <= str.length - 1; i++) {
    if (str[i] === char) {
      ++c;
    }
  }
  return c;
}

function findFirstOpeningAndClosing(
  s: string,
  openingChar: string,
  closingChar: string
): {
  readonly ok: boolean;
  readonly startIndex: number;
  readonly endIndex: number;
} {
  if (!openingAndClosingValid(s, openingChar, closingChar)) {
    return {
      ok: false,
      startIndex: -1,
      endIndex: -1
    };
  }

  const startIndex = s.indexOf(openingChar)!;
  let endIndex = startIndex;
  let depth = 1;
  for (let i = startIndex + 1; i <= s.length - 1; i++) {
    if (s[i] === openingChar) {
      depth++;
    } else if (s[i] === closingChar) {
      depth--;
    }

    if (depth === 0) {
      endIndex = i;
      break;
    }
  }

  return {
    ok: true,
    startIndex: startIndex,
    endIndex: endIndex
  };
}
