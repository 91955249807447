import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Trash, Input } from "@genesys/ui-elements";
import { TrashContainer } from "../elements";
import { View } from "../types";

export function InputBox({
  inputBoxValue,
  view,
  onInputBoxChange,
  onDelete,
  translate
}: {
  readonly inputBoxValue: string;
  readonly view: View;
  readonly onInputBoxChange: (value: string) => void;
  readonly onDelete: () => void;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  return (
    <>
      <label>{translate(LanguageTexts.labelName())}</label>

      <Input
        placeholder={translate(LanguageTexts.newLabel())}
        onDebounceValueChange={[
          value => {
            onInputBoxChange(value);
          },
          []
        ]}
        value={inputBoxValue}
      />
      {view === "editOrDelete" && (
        <TrashContainer>
          <label onClick={onDelete}>
            <Trash />
          </label>
        </TrashContainer>
      )}
    </>
  );
}
