import * as React from "react";
import { isDesktop } from "react-device-detect";
import * as Types from "./types";
import * as SharedState from "../../../shared-state";
import * as Authorization from "@genesys/shared/lib/authorization";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import { LabelEditorView } from "./label-editor";
import {
  H1,
  H3,
  OverlayLoader,
  Input,
  Label,
  GenesysSelect
} from "@genesys/ui-elements";
import {
  ControllerContainer,
  GeneralSettingsContainer,
  LabelContainer,
  ProductSelectionContainer,
  ProductSelectContainer,
  QuickSelectContainer,
  QuickSelectOptionsContainer,
  ProductRangeContainer
} from "./elements";
import { QuickSelectOption } from "./quick-select-option";
import { PropertiesSelectorView } from "../../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
}

const maxQuickSystemTypes = isDesktop ? 5 : 4;

export function SystemSettings(props: Props): JSX.Element {
  if (!props.state) {
    return <OverlayLoader />;
  }

  const userPopularSystems = Object.keys(
    props.sharedState.user.createSystemType
  )
    .reduce(
      (a, b) =>
        a.concat({
          systemType: b,
          counter: props.sharedState.user.createSystemType[b]!
        }),
      [] as Array<{
        readonly systemType: string;
        readonly counter: number;
      }>
    )
    .sort((a, b) =>
      a.counter < b.counter ? 1 : a.counter > b.counter ? -1 : 0
    )
    .map(s => s.systemType);

  const quickSystemTypes = userPopularSystems
    .concat(
      props.state.data.systemTypes
        .map(st => st.id)
        .filter(st => !userPopularSystems.includes(st))
    )
    .slice(0, maxQuickSystemTypes);

  const showCodes = Authorization.checkPermission(
    props.sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  return (
    <div>
      <H1 weight="normal" color="primary">
        {props.sharedState.translate(LanguageTexts.systemSettings())}
      </H1>
      <GeneralSettingsContainer>
        <ControllerContainer>
          <LabelContainer>
            <Label weight="bold" color="light">
              {props.sharedState.translate(LanguageTexts.systemName())}
            </Label>
          </LabelContainer>
          <Input
            type="text"
            value={props.state.name}
            width={"455px"}
            placeholder={props.sharedState.translate(
              LanguageTexts.enterFileNameHere()
            )}
            onChange={e => props.dispatch(Action.setName(e.target.value))}
          />
        </ControllerContainer>
        <ControllerContainer>
          <LabelContainer>
            <Label weight="bold" color="light">
              {props.sharedState.translate(LanguageTexts.labels())}
            </Label>
          </LabelContainer>
          <LabelEditorView
            assignedLabels={props.state.assignedLabels}
            state={props.state.labelManagerState}
            dispatch={props.dispatch}
            sharedState={props.sharedState}
          ></LabelEditorView>
        </ControllerContainer>
      </GeneralSettingsContainer>

      <QuickSelectContainer>
        <H3 weight="normal" color="dark">
          {props.sharedState.translate(LanguageTexts.quickSelect())}
        </H3>
        <QuickSelectOptionsContainer>
          {quickSystemTypes
            .reduce((a, b) => {
              const st = props.state!.data.systemTypes.find(s => s.id === b);
              return st ? a.concat(st) : a;
            }, [] as ReadonlyArray<Types.SystemType>)
            .map(st => (
              <QuickSelectOption
                key={st.id}
                image={st.image}
                name={st.name}
                onClick={() => props.dispatch(Action.setSystemTypeId(st.id))}
                accessToken={props.sharedState.accessToken}
                selected={props.state!.systemTypeId === st.id}
                translate={props.sharedState.translate}
              />
            ))}
        </QuickSelectOptionsContainer>
      </QuickSelectContainer>

      <ProductSelectionContainer>
        <div>
          <H1 weight="normal" color="primary">
            {props.sharedState.translate(LanguageTexts.selectProductRange())}
          </H1>
          <ProductSelectContainer>
            <GenesysSelect
              value={props.state.systemTypeId}
              onChange={e =>
                props.dispatch(Action.setSystemTypeId(e.target.value))
              }
              options={props.state.data.systemTypes.map(st => ({
                value: st.id,
                title: st.name + " (" + st.id + ")"
              }))}
            />
          </ProductSelectContainer>
        </div>

        <ProductRangeContainer>
          {props.state.newProduct && (
            <PropertiesSelectorView
              state={{
                properties: props.state.newProperties
              }}
              dispatch={Dispatch.map(
                Action.dispatchRangePropertiesSelector,
                props.dispatch
              )}
              productId={props.state.systemTypeId + "NEW"}
              propertiesInfo={props.state.newProduct.properties}
              fieldGroup={`Settings.${props.state.systemTypeId}NEW`}
              isReadonly={false}
              sharedState={props.sharedState}
              showCodes={showCodes}
            />
          )}
        </ProductRangeContainer>
      </ProductSelectionContainer>
    </div>
  );
}
