import styled from "styled-components";
import { headingTextColor } from "../colors";

// tslint:disable-next-line
export const SectionHeader = styled.h2`
  font-size: 18px;
  font-weight: 300;
  color: ${headingTextColor};
  border-bottom: 2px solid ${headingTextColor};
  padding-bottom: 5px;
  display: block;
`;
