import * as AbstractImage from "abstract-image";

export const seriesColors = {
  Red1: { a: 255, r: 255, g: 0, b: 0 },
  Red2: { a: 255, r: 150, g: 0, b: 59 },
  Red3: { a: 255, r: 255, g: 110, b: 110 },
  Red4: { a: 255, r: 255, g: 59, b: 0 },
  Blue1: { a: 255, r: 0, g: 0, b: 255 },
  Blue2: { a: 255, r: 0, g: 59, b: 150 },
  Blue3: { a: 255, r: 110, g: 110, b: 255 },
  Blue4: { a: 255, r: 59, g: 0, b: 255 },
  Yellow1: { a: 255, r: 255, g: 255, b: 0 },
  Yellow2: { a: 255, r: 200, g: 200, b: 0 },
  Yellow3: { a: 255, r: 220, g: 180, b: 0 },
  Green1: { a: 255, r: 0, g: 255, b: 0 },
  Green2: { a: 255, r: 128, g: 255, b: 0 },
  Green3: { a: 255, r: 0, g: 128, b: 0 },
  Black: { a: 255, r: 0, g: 0, b: 0 }
};

export const backgroundColor = AbstractImage.white;
export const tempLines = AbstractImage.gray;
export const energyLines = AbstractImage.gray;
export const binTimeLines = AbstractImage.gray;
export const chartBounds = AbstractImage.black;
export const energyCurve = AbstractImage.red;
export const binTimeCurve = AbstractImage.blue;
