import * as React from "react";
import * as Authorization from "@genesys/shared/lib/authorization";
// tslint:disable-next-line:variable-name
export interface UserProfileContextValue {
  readonly name: string;
  readonly userName: string;
  readonly lastLogin: number;
  readonly lastCreatedSystemType: string | undefined;
  readonly lastSelectedLabels: ReadonlyArray<string>;
  readonly currency: string;
  readonly language: string;
  readonly locale: string;
  readonly applicationClaims: Authorization.ApplicationClaims;
  readonly currencies: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly signBefore?: string;
    readonly signAfter?: string;
  }>;
  readonly defaultSalesOrganisation: string | undefined;
  readonly defaultLocationId: string | undefined;
  readonly salesOrganisations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly setCulture: (settings: {
    readonly currency?: string;
    readonly language?: string;
    readonly locale?: string;
  }) => void;
  readonly setInitialSettings: (
    culture: {
      readonly currency: string;
      readonly language: string;
      readonly locale: string;
    },
    activeAmountProfileId: string,
    climate: {
      readonly countryName: string;
      readonly regionName: string;
      readonly locationId: string;
    }
  ) => void;
  readonly setDefaultSalesOrganisation: (
    defaultSalesOrganisationCode?: string | null
  ) => void;
  readonly updateWizardSettings: (
    lastCreatedSystemType: string,
    lastSelectedLabels: ReadonlyArray<string>
  ) => void;
  readonly resetUserSettings: () => void;
}

// tslint:disable-next-line:variable-name
export const UserProfileContext = React.createContext<UserProfileContextValue>({
  name: "dummy",
  userName: "dummy",
  applicationClaims: {},
  currencies: [],
  currency: "EUR",
  language: "en-GB",
  locale: "en-GB",
  lastCreatedSystemType: undefined,
  lastSelectedLabels: [],
  defaultSalesOrganisation: undefined,
  salesOrganisations: [],
  lastLogin: 0,
  defaultLocationId: undefined,
  setCulture: () => {
    /*  */
  },
  setInitialSettings: () => {
    /*  */
  },
  setDefaultSalesOrganisation: () => {
    /*  */
  },
  resetUserSettings: () => {
    /*  */
  },
  updateWizardSettings: () => {
    /*  */
  }
});
