import { PropertyValue } from "@genesys/property";
import { Amount, Unit, Quantity } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as QuantityConversion from "../quantity-conversion";

export interface AmountFormat {
  readonly unit: Unit.Unit<unknown>;
  readonly decimalCount: number;
}

export function getValue(
  propertyValue: PropertyValue.PropertyValue,
  amountFormat: AmountFormat,
  conversionParameters?: QuantityConversion.ConversionParameters,
  useMaxDecimalCount?: true
): string {
  switch (propertyValue.type) {
    case "text": {
      return PropertyValue.getText(propertyValue)!;
    }
    case "integer": {
      return PropertyValue.getInteger(propertyValue)!.toString();
    }
    case "amount": {
      const amount = PropertyValue.getAmount(
        propertyValue
      )! as Amount.Amount<Quantity.Quantity>;

      const { selectedUnit, selectedDecimalCount } = getFormat(
        amountFormat,
        amount
      );

      const effectiveDecimalCount = useMaxDecimalCount
        ? 16
        : selectedDecimalCount;

      const maybeConvertedAmount =
        selectedUnit.quantity !== amount.unit.quantity
          ? QuantityConversion.convertQuantity(
              amount as Amount.Amount<Quantity.Quantity>,
              selectedUnit.quantity,
              conversionParameters as QuantityConversion.ConversionParameters
            )
          : amount;

      const value = Amount.valueAs(selectedUnit, maybeConvertedAmount);
      const valueWithCorrectDecimalCount = value.toFixed(effectiveDecimalCount);
      return valueWithCorrectDecimalCount;
    }
    default:
      exhaustiveCheck(propertyValue);
      return "Should never reach here";
  }
}

export function getFormat(
  amountFormat: AmountFormat | undefined,
  amount: Amount.Amount<Quantity.Quantity>
): {
  readonly selectedUnit: Unit.Unit<Quantity.Quantity>;
  readonly selectedDecimalCount: number;
} {
  const selectedUnit = amountFormat ? amountFormat.unit : amount.unit;
  const selectedDecimalCount = amountFormat ? amountFormat.decimalCount : 2;

  return {
    selectedUnit: selectedUnit as Unit.Unit<Quantity.Quantity>,
    selectedDecimalCount
  };
}
